import React, { Component } from 'react'
import challengefill from "../img/challengefill.png";
import teamIcon from "../img/team.png";
import schedulematches from "../img/schedulematches.png";
import location from "../img/location.png";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import Map from '../Map';
import * as myConstclass from '../ConstFile';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-bootstrap/Modal';
import { message } from 'antd';
import 'antd/dist/antd.css';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

const MATCH_TYPE_QUARTER_FINAL = "Quarter final";
const MATCH_TYPE_SEMI_FINAL = "Semi final";
const MATCH_TYPE_FINAL = "Final";

const quarterFinals = [
    {
        pairId: 1, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 3, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 4, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const semiFinals = [
    {
        pairId: 1, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const final = {
    pairId: 1, matchId: null,
    firstId: null, secondId: null,
    ended: false, draw: false,
    date: null, time: null,
    rankId: 0,
    location: null, city: null,
    state: null, country: null,
    latitude: null, longitude: null
};
const organizeMatch = {
    ttType: null,
    // tt2Matches: null,
    tt3Matches: null,
    // quarterFinals: null,
    // semiFinals: null,
    finals: null,
}

const Pool1 = [], Pool2 = [], Pool3 = [], Pool4 = [], Pool5 = [];
let PoolA, PoolB, PoolC, PoolD, PoolE;
let datePickerId = 0;
var organiseList1Odd = [];
var organiseList2Odd = [];
var organiseList3Odd = [];
var organiseList4Odd = [];
var organiseList5Odd = [];

let firstName, secondName, firstPic, secondPic;

var organiseList1 = [];
var organiseList2 = [];
var organiseList3 = [];
var organiseList4 = [];
export default class DOrganiseMatchTT3Create extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDateTime: false,
            tDateTime: [new Date()],
            dateDate: myConstclass.getDateYYYYmmdd(new Date()),
            dateTime: myConstclass.getTimeFromDate(new Date()),
            fields: {},
            errors: {},
            touched: {},
            acceptedPairState: [],
            showQuarter: false,
            showQuarterList: false,
            showSemi: false,
            showSemiList: false,
            showFinal: false,
            defaultQuarter: false,
            defaultSemi: false,
            FinaldateObj: null,
            locationType: '',
            showPoolBasedLeagueModal: false,
            showOrganiseCreateButton: false,
            showEvenBuddy: false,
            showOddBuddy: false,
            tickCountForEven: 0,
            tickCountForOdd: 0,
            selectForEven: [],
            selectForOdd1: [],
            selectForOdd2: [],
            selectForOdd3: [],
            selectForOdd4: [],
            selectForOdd5: [],
            NumberOfPool: 0,
            PlayerPerPool: 0,
            forOdd: false,
            filterSelection: [],
            showReshuffle: false,

        }

        this.semiORfinal = this.semiORfinal.bind(this);
        this.quarterORsemi = this.quarterORsemi.bind(this);
        this.poolType = this.poolType.bind(this);
        this.selectBuddyForEventPool = this.selectBuddyForEventPool.bind(this);
        this.savePlayerForEven = this.savePlayerForEven.bind(this);
        this.selectForOddPool = this.selectForOddPool.bind(this);
        this.savePlayerForOdd = this.savePlayerForOdd.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };
    reShuffle = () => {
        if (this.state.selectForEven) {
            let players = [...this.state.selectForEven];
            players.sort(() => Math.random() - 0.5);
            this.setState({ selectForEven: players })
        }
        else {

        }

        while (Pool1.length > 0) {
            Pool1.pop();
        }
        while (Pool2.length > 0) {
            Pool2.pop();
        }
        while (Pool3.length > 0) {
            Pool3.pop();
        }
        while (Pool4.length > 0) {
            Pool4.pop();
        }
        while (Pool5.length > 0) {
            Pool5.pop();
        }

    }
    componentDidMount() {
        this.setState({ showPoolBasedLeagueModal: true })
        if (this.props.tournamentData.tournamentMode === 'buddy') {
            let user = [...this.props.usersInTournament];
            console.log(user)
            let userArray = [];

            user.forEach(user => {
                this.props.tournamentData.accepted.forEach(accepted => {
                    if (accepted === user.contactNo) {
                        let userList = {};
                        userList.name = user.name;
                        userList.Id = user.contactNo;
                        userList.Img = user.profilePic;
                        userArray = [...userArray, userList]
                    }
                });

            })


            console.log(userArray)
            this.setState({ userTeamList: userArray, userTeamListForOdd: userArray })
        }
        else {
            let team = [...this.props.teamsInTournament];
            let teamArray = [];
            team.map(team => {
                this.props.tournamentData.accepted.forEach(accepted => {
                    if (accepted === team.teamId) {
                        let teamList = {};
                        teamList.name = team.teamName;
                        teamList.Id = team.teamId;
                        teamList.Img = team.teamIcon;
                        teamArray.push(teamList)
                    }
                });
            });
            this.setState({ userTeamList: teamArray })
        }


        // let acceptedPair = [];
        // Array.prototype.pairs = function (func) {
        //     for (var i = 0; i < this.length - 1; i++) {
        //         for (var j = i; j < this.length - 1; j++) {
        //             func([this[i], this[j + 1]]);
        //         }
        //     }
        // }
        // this.props.tournamentData.accepted.pairs(function (pair) {
        //     let data = {
        //         firstId: pair[0],
        //         secondId: pair[1],
        //         dateObj: null,
        //     }
        //     acceptedPair.push(data);
        // });
        // this.setState({ acceptedPairState: acceptedPair });
    }

    selectBuddyForEventPool(event) {

        let checkedArray = this.state.selectForEven;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                selectForEven: checkedArray,
                tickCountForEven: checkedArray.length,
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                selectForEven: checkedArray,
                tickCountForEven: checkedArray.length,
            });
        }

        console.log(checkedArray)
    }
    selectForOddPool(event) {
        let checkedArray;
        if (this.state.poolNo === 'pool1') {
            checkedArray = this.state.selectForOdd1;
        }
        if (this.state.poolNo === 'pool2') {
            checkedArray = this.state.selectForOdd2;
        }
        if (this.state.poolNo === 'pool3') {
            checkedArray = this.state.selectForOdd3;
        }
        if (this.state.poolNo === 'pool4') {
            checkedArray = this.state.selectForOdd4;
        }
        if (this.state.poolNo === 'pool5') {
            checkedArray = this.state.selectForOdd5;
        }

        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            if (this.state.poolNo === 'pool1') {
                this.setState({
                    selectForOdd1: checkedArray
                });
            }
            if (this.state.poolNo === 'pool2') {
                this.setState({
                    selectForOdd2: checkedArray
                });
            }
            if (this.state.poolNo === 'pool3') {
                this.setState({
                    selectForOdd3: checkedArray
                });
            }
            if (this.state.poolNo === 'pool4') {
                this.setState({
                    selectForOdd4: checkedArray
                });
            }
            if (this.state.poolNo === 'pool5') {
                this.setState({
                    selectForOdd5: checkedArray
                });
            }
            this.setState({
                tickCountForOdd: checkedArray.length,
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            if (this.state.poolNo === 'pool1') {
                this.setState({
                    selectForOdd1: checkedArray
                });
            }
            if (this.state.poolNo === 'pool2') {
                this.setState({
                    selectForOdd2: checkedArray
                });
            }
            if (this.state.poolNo === 'pool3') {
                this.setState({
                    selectForOdd3: checkedArray
                });
            }
            if (this.state.poolNo === 'pool4') {
                this.setState({
                    selectForOdd4: checkedArray
                });
            }
            if (this.state.poolNo === 'pool5') {
                this.setState({
                    selectForOdd5: checkedArray
                });
            }
            this.setState({
                tickCountForOdd: checkedArray.length,
            });
        }

    }
    handleClose() {
        this.setState({ showOddBuddySelection: false })
    }
    savePlayerForEven(e) {
        if (this.state.tickCountForEven < 6) {
            this.setState({ errorInPlayerSelection: "please Select atleast 6 players" });
        }

        else if (this.state.tickCountForEven < 8 && this.state.tickCountForEven != 6) {
            this.setState({ errorInPlayerSelection: "Please select 6 players only" });
        }
        else if (this.state.tickCountForEven < 10 && this.state.tickCountForEven != 6 && this.state.tickCountForEven != 8) {
            this.setState({ errorInPlayerSelection: "Please select 6 or 8 players only" });
        }
        else if (this.state.tickCountForEven < 12 && this.state.tickCountForEven != 6 && this.state.tickCountForEven != 8 && this.state.tickCountForEven != 10) {
            this.setState({ errorInPlayerSelection: "Please select 6,8 or 10  players only" });
        }
        else if (this.state.tickCountForEven < 20 && this.state.tickCountForEven != 6 && this.state.tickCountForEven != 8 && this.state.tickCountForEven != 10 && this.state.tickCountForEven != 12 && this.state.tickCountForEven != 16) {
            this.setState({ errorInPlayerSelection: "Please select 6,8,10,12 or 16 players only" });
        }
        else if (this.state.tickCountForEven < 24 && this.state.tickCountForEven != 6 && this.state.tickCountForEven != 8 && this.state.tickCountForEven != 10 && this.state.tickCountForEven != 12 && this.state.tickCountForEven != 16 && this.state.tickCountForEven != 20) {
            this.setState({ errorInPlayerSelection: "Please select 6,8,10,12,16 or 20 players only" });
        }
        else if (this.state.tickCountForEven != 6 && this.state.tickCountForEven != 8 && this.state.tickCountForEven != 10 && this.state.tickCountForEven != 12 && this.state.tickCountForEven != 16 && this.state.tickCountForEven != 20 && this.state.tickCountForEven != 24) {
            this.setState({ errorInPlayerSelection: "Please select 6,8,10,12,16,20 or 24 players only" });
        }
        else {
            this.setState({ errorInPlayerSelection: null, showEvenBuddy: false, showReshuffle: true, showOrganiseCreateButton: true });
            var pools = [];
            if (this.state.tickCountForEven === 6) {
                this.setState({ NumberOfPool: 2, PlayerPerPool: 3, showPoolAB: true, showSemi: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 8) {
                this.setState({ NumberOfPool: 2, PlayerPerPool: 4, showPoolAB: true, showSemi: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 10) {
                this.setState({ NumberOfPool: 2, PlayerPerPool: 5, showPoolAB: true, showSemi: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 12) {
                this.setState({ NumberOfPool: 4, PlayerPerPool: 3, showPoolAB: true, showPoolABCD: true, showQuarter: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 16) {
                this.setState({ NumberOfPool: 4, PlayerPerPool: 4, showPoolAB: true, showPoolABCD: true, showQuarter: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 20) {
                this.setState({ NumberOfPool: 4, PlayerPerPool: 5, showPoolAB: true, showPoolABCD: true, showQuarter: true, showFinal: true });
            }
            else if (this.state.tickCountForEven === 24) {
                this.setState({ NumberOfPool: 4, PlayerPerPool: 6, showPoolAB: true, showPoolABCD: true, showQuarter: true, showFinal: true });
            }
        }
    }

    savePlayerForOdd(e) {

        if (this.state.tickCountForOdd < 3) {
            this.setState({ errorInPlayerSelection: "please Select atleast 3 players", pool1PlayerSelected: false, });
        }
        else {
            this.setState({ showOrganiseCreateButton: true })
            if (this.props.tournamentData.accepted.length > 8) {
                if (this.props.tournamentData.accepted.length < 15) {
                    this.setState({ NumberOfPool: 3, showSemi: true, showFinal: true })
                } else {
                    this.setState({ NumberOfPool: 5 })
                }
            }
            if (this.state.poolNo === 'pool1') {
                let userTeam = [...this.state.userTeamList];
                let selectForOdd = [...this.state.selectForOdd1];
                selectForOdd.forEach(element => {
                    userTeam = userTeam.filter((item) => item.Id !== element);
                });
                this.setState({ userTeamListForOdd: userTeam, errorInPlayerSelection: null, pool1PlayerSelected: true, showOddBuddy: true, showOddBuddySelection: false, });
            }
            if (this.state.poolNo === 'pool2') {
                let userTeam = [...this.state.userTeamListForOdd];
                let selectForOdd = [...this.state.selectForOdd2];
                selectForOdd.forEach(element => {
                    userTeam = userTeam.filter((item) => item.Id !== element);
                });
                this.setState({ pool2List: true, userTeamListForOdd: userTeam, errorInPlayerSelection: null, pool2PlayerSelected: true, showOddBuddy: true, showOddBuddySelection: false, });
            }
            if (this.state.poolNo === 'pool3') {
                let userTeam = [...this.state.userTeamListForOdd];
                let selectForOdd = [...this.state.selectForOdd3];
                selectForOdd.forEach(element => {
                    userTeam = userTeam.filter((item) => item.Id !== element);
                });
                this.setState({ pool3List: true, userTeamListForOdd: userTeam, errorInPlayerSelection: null, pool3PlayerSelected: true, showOddBuddy: true, showOddBuddySelection: false, });
            }
            if (this.state.poolNo === 'pool4') {
                let userTeam = [...this.state.userTeamListForOdd];
                let selectForOdd = [...this.state.selectForOdd4];
                selectForOdd.forEach(element => {
                    userTeam = userTeam.filter((item) => item.Id !== element);
                });
                this.setState({ pool4List: true, userTeamListForOdd: userTeam, errorInPlayerSelection: null, pool4PlayerSelected: true, showOddBuddy: true, showOddBuddySelection: false, });
            }
            if (this.state.poolNo === 'pool5') {
                let userTeam = [...this.state.userTeamListForOdd];
                let selectForOdd = [...this.state.selectForOdd5];
                selectForOdd.forEach(element => {
                    userTeam = userTeam.filter((item) => item.Id !== element);
                });
                this.setState({ pool5List: true, userTeamListForOdd: userTeam, errorInPlayerSelection: null, pool5PlayerSelected: true, showOddBuddy: true, showOddBuddySelection: false, });
            }

        }
    }
    poolType(e) {

        if (e.target.value === 'even') {
            this.setState({ showEvenBuddy: true, showPoolBasedLeagueModal: false })
        }
        else {
            if (this.props.tournamentData.accepted.length > 8) {
                if (this.props.tournamentData.accepted.length < 15) {
                    this.setState({ showOddBuddy: true, showPoolBasedLeagueModal: false })
                } else {
                    this.setState({ showFor5Pool: true, showOddBuddy: true, showPoolBasedLeagueModal: false })
                }
            }
            else {
                message.info('Minimum 9 players/teams required for organise odd pools');
            }
        }
    }
    selectBuddyForOddPool = (poolNo) => {
        if (poolNo === 'pool1') {
            this.setState({ showOddBuddySelection: true, showOddBuddy: true, poolNo: poolNo, forOdd: true, })
        }
        if (poolNo === 'pool2') {
            this.setState({ showOddBuddySelection: true, showOddBuddy: true, poolNo: poolNo, forOdd: true, })
        }
        if (poolNo === 'pool3') {
            this.setState({ showOddBuddySelection: true, showOddBuddy: true, poolNo: poolNo, forOdd: true, })
        }
        if (poolNo === 'pool4') {
            this.setState({ showOddBuddySelection: true, showOddBuddy: true, poolNo: poolNo, forOdd: true, })
        }
        if (poolNo === 'pool5') {
            this.setState({ showOddBuddySelection: true, showOddBuddy: true, poolNo: poolNo, forOdd: true, })
        }
    }
    quarterORsemi(e) {
        if (e.target.value === 'quarter-final') {
            this.setState({ showQuarterList: true, showSemiList: true })
        }
        if (e.target.value === 'semi-final') {
            this.setState({ showQuarterList: false })
        }
    }
    semiORfinal(e) {
        if (e.target.value === 'semi-final') {
            this.setState({ showSemiList: true })
        }
        if (e.target.value === 'final') {
            this.setState({ showSemiList: false })
        }
    }
    setTdateTime = (date) => {
        let alldata = this.state.acceptedPairState;
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);

        if (this.state.clickPoolNo === 1) {
            Pool1[this.state.Dateindex].dateObj = date;
            Pool1[this.state.Dateindex].date = dateFormate;
            Pool1[this.state.Dateindex].time = dateTime;
        }
        else if (this.state.clickPoolNo === 2) {
            Pool2[this.state.Dateindex].dateObj = date;
            Pool2[this.state.Dateindex].date = dateFormate;
            Pool2[this.state.Dateindex].time = dateTime;
        }
        else if (this.state.clickPoolNo === 3) {
            Pool3[this.state.Dateindex].dateObj = date;
            Pool3[this.state.Dateindex].date = dateFormate;
            Pool3[this.state.Dateindex].time = dateTime;
        }

        // this.setState({
        //     acceptedPairState: alldata
        // });
        this.setState({
            dateDate: dateFormate, dateTime: dateTime
        });
    };
    setTdateTimeFinal = (date) => {
        this.setState({ FinaldateObj: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        final.date = dateFormate;
        final.time = dateTime;
    }
    setTdateTimeSemiFinal1 = (date) => {
        this.setState({ SemiFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[0].date = dateFormate;
        semiFinals[0].time = dateTime;
    }
    setTdateTimeSemiFinal2 = (date) => {
        this.setState({ SemiFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[1].date = dateFormate;
        semiFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal1 = (date) => {
        this.setState({ QuarterFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[0].date = dateFormate;
        quarterFinals[0].time = dateTime;
    }
    setTdateTimeQuarterFinal2 = (date) => {
        this.setState({ QuarterFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[1].date = dateFormate;
        quarterFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal3 = (date) => {
        this.setState({ QuarterFinaldateObj3: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[2].date = dateFormate;
        quarterFinals[2].time = dateTime;
    }
    setTdateTimeQuarterFinal4 = (date) => {
        this.setState({ QuarterFinaldateObj4: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[3].date = dateFormate;
        quarterFinals[3].time = dateTime;
    }
    callbackFunction = (childData) => {

        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        if (this.state.locationType === 'final') {
            final.location = childData.address;
            final.city = childData.city;
            final.state = childData.address;
            final.country = childData.country;
            final.latitude = childData.latValue;
            final.longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'semi-final') {

            semiFinals[this.state.Locationindex].location = childData.address;
            semiFinals[this.state.Locationindex].city = childData.city;
            semiFinals[this.state.Locationindex].state = childData.address;
            semiFinals[this.state.Locationindex].country = childData.country;
            semiFinals[this.state.Locationindex].latitude = childData.latValue;
            semiFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'quarter-final') {

            quarterFinals[this.state.Locationindex].location = childData.address;
            quarterFinals[this.state.Locationindex].city = childData.city;
            quarterFinals[this.state.Locationindex].state = childData.address;
            quarterFinals[this.state.Locationindex].country = childData.country;
            quarterFinals[this.state.Locationindex].latitude = childData.latValue;
            quarterFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else {

            if (this.state.clickPoolNo === 1) {

                Pool1[this.state.Locationindex].location = childData.address;
                Pool1[this.state.Locationindex].city = childData.city;
                Pool1[this.state.Locationindex].state = childData.address;
                Pool1[this.state.Locationindex].country = childData.country;
                Pool1[this.state.Locationindex].latitude = childData.latValue;
                Pool1[this.state.Locationindex].longitude = childData.lngValue;
            }
            if (this.state.clickPoolNo === 2) {
                Pool2[this.state.Locationindex].location = childData.address;
                Pool2[this.state.Locationindex].city = childData.city;
                Pool2[this.state.Locationindex].state = childData.address;
                Pool2[this.state.Locationindex].country = childData.country;
                Pool2[this.state.Locationindex].latitude = childData.latValue;
                Pool2[this.state.Locationindex].longitude = childData.lngValue;
            }
            if (this.state.clickPoolNo === 3) {
                Pool3[this.state.Locationindex].location = childData.address;
                Pool3[this.state.Locationindex].city = childData.city;
                Pool3[this.state.Locationindex].state = childData.address;
                Pool3[this.state.Locationindex].country = childData.country;
                Pool3[this.state.Locationindex].latitude = childData.latValue;
                Pool3[this.state.Locationindex].longitude = childData.lngValue;
            }
            if (this.state.clickPoolNo === 4) {
                Pool4[this.state.Locationindex].location = childData.address;
                Pool4[this.state.Locationindex].city = childData.city;
                Pool4[this.state.Locationindex].state = childData.address;
                Pool4[this.state.Locationindex].country = childData.country;
                Pool4[this.state.Locationindex].latitude = childData.latValue;
                Pool4[this.state.Locationindex].longitude = childData.lngValue;
            }
            console.log(Pool1)
            // let alldata = this.state.acceptedPairState;
            // alldata[this.state.Locationindex].location = childData.address;
            // alldata[this.state.Locationindex].city = childData.city;
            // alldata[this.state.Locationindex].state = childData.address;
            // alldata[this.state.Locationindex].country = childData.country;
            // alldata[this.state.Locationindex].latitude = childData.latValue;
            // alldata[this.state.Locationindex].longitude = childData.lngValue;
        }
    }
    datepicker = (id, poolNo) => {

        this.setState({ Dateindex: id, clickPoolNo: poolNo })
    }

    location = (id, poolNo) => {
        this.setState({ Locationindex: id, clickPoolNo: poolNo })
    }
    locationType = (id, type) => {
        this.setState({ Locationindex: id, locationType: type })
    }
    organiseTournament = () => {

        let formLeagueError = false;
        let formQuarterError = false;
        let formSemiError = false;
        let formFinalError = false;
        let pool = [];
        if (this.state.NumberOfPool === 2 || this.state.NumberOfPool === 3 || this.state.NumberOfPool === 4 || this.state.NumberOfPool === 5) {
            Pool1.map((acceptedPair, index) => {
                acceptedPair.pairId = index + 1;
                acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                acceptedPair.ended = false;
                acceptedPair.draw = false;
                acceptedPair.rankId = 0;
                delete acceptedPair.dateObj;
            })
            let pointList1Array = [];
            PoolA.forEach(id => {
                let pointList1Obj = {};
                pointList1Obj.teamId = id;
                pointList1Obj.matchesPlayed = 0;
                pointList1Obj.matchesWon = 0;
                pointList1Obj.matchesLost = 0;
                pointList1Obj.matchesDraw = 0;
                pointList1Obj.totalPoints = 0;
                pointList1Obj.netRunRate = 0;
                pointList1Array.push(pointList1Obj);
            });
            console.log(Pool1)
            pool.push({ poolNo: 1, matches: Pool1, pointsTable: { pointsList: pointList1Array } });
            Pool2.map((acceptedPair, index) => {
                acceptedPair.pairId = index + 1;
                acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                acceptedPair.ended = false;
                acceptedPair.draw = false;
                acceptedPair.rankId = 0;
                delete acceptedPair.dateObj;
            })

            let pointList2Array = [];
            PoolB.forEach(id => {
                let pointList2Obj = {};
                pointList2Obj.teamId = id;
                pointList2Obj.matchesPlayed = 0;
                pointList2Obj.matchesWon = 0;
                pointList2Obj.matchesLost = 0;
                pointList2Obj.matchesDraw = 0;
                pointList2Obj.totalPoints = 0;
                pointList2Obj.netRunRate = 0;
                pointList2Array.push(pointList2Obj);
            });
            pool.push({ poolNo: 2, matches: Pool2, pointsTable: { pointsList: pointList2Array } });

            if (this.state.NumberOfPool === 3) {
                Pool3.map((acceptedPair, index) => {
                    acceptedPair.pairId = index + 1;
                    acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                    acceptedPair.ended = false;
                    acceptedPair.draw = false;
                    acceptedPair.rankId = 0;
                    delete acceptedPair.dateObj;
                })

                let pointList3Array = [];
                console.log(PoolC)
                PoolC.forEach(id => {
                    let pointList3Obj = {};
                    pointList3Obj.teamId = id;
                    pointList3Obj.matchesPlayed = 0;
                    pointList3Obj.matchesWon = 0;
                    pointList3Obj.matchesLost = 0;
                    pointList3Obj.matchesDraw = 0;
                    pointList3Obj.totalPoints = 0;
                    pointList3Obj.netRunRate = 0;
                    pointList3Array.push(pointList3Obj);
                });
                pool.push({ poolNo: 3, matches: Pool3, pointsTable: { pointsList: pointList3Array } });
            }
            if (this.state.NumberOfPool === 4 || this.state.NumberOfPool === 5) {
                Pool3.map((acceptedPair, index) => {
                    acceptedPair.pairId = index + 1;
                    acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                    acceptedPair.ended = false;
                    acceptedPair.draw = false;
                    acceptedPair.rankId = 0;
                    delete acceptedPair.dateObj;
                })

                let pointList3Array = [];
                PoolC.forEach(id => {
                    let pointList3Obj = {};
                    pointList3Obj.teamId = id;
                    pointList3Obj.matchesPlayed = 0;
                    pointList3Obj.matchesWon = 0;
                    pointList3Obj.matchesLost = 0;
                    pointList3Obj.matchesDraw = 0;
                    pointList3Obj.totalPoints = 0;
                    pointList3Obj.netRunRate = 0;
                    pointList3Array.push(pointList3Obj);
                });
                pool.push({ poolNo: 3, matches: Pool3, pointsTable: { pointsList: pointList3Array } });
                Pool4.map((acceptedPair, index) => {
                    acceptedPair.pairId = index + 1;
                    acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                    acceptedPair.ended = false;
                    acceptedPair.draw = false;
                    acceptedPair.rankId = 0;
                    delete acceptedPair.dateObj;
                })

                let pointList4Array = [];
                PoolD.forEach(id => {
                    let pointList4Obj = {};
                    pointList4Obj.teamId = id;
                    pointList4Obj.matchesPlayed = 0;
                    pointList4Obj.matchesWon = 0;
                    pointList4Obj.matchesLost = 0;
                    pointList4Obj.matchesDraw = 0;
                    pointList4Obj.totalPoints = 0;
                    pointList4Obj.netRunRate = 0;
                    pointList4Array.push(pointList4Obj);
                });
                pool.push({ poolNo: 4, matches: Pool4, pointsTable: { pointsList: pointList4Array } });
            }
            if (this.state.NumberOfPool === 5) {
                Pool5.map((acceptedPair, index) => {
                    acceptedPair.pairId = index + 1;
                    acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
                    acceptedPair.ended = false;
                    acceptedPair.draw = false;
                    acceptedPair.rankId = 0;
                    delete acceptedPair.dateObj;
                })
                let pointList5Array = [];
                PoolE.forEach(id => {
                    let pointList5Obj = {};
                    pointList5Obj.teamId = id;
                    pointList5Obj.matchesPlayed = 0;
                    pointList5Obj.matchesWon = 0;
                    pointList5Obj.matchesLost = 0;
                    pointList5Obj.matchesDraw = 0;
                    pointList5Obj.totalPoints = 0;
                    pointList5Obj.netRunRate = 0;
                    pointList5Array.push(pointList5Obj);
                });
                pool.push({ poolNo: 5, matches: Pool5, pointsTable: { pointsList: pointList5Array } });
            }
        }
        console.log(pool)

        Pool1.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })
        Pool2.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })
        Pool3.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })
        Pool4.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })
        Pool5.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })




        if (this.state.showQuarterList) {
            quarterFinals.map(qf => {
                if (qf.date !== undefined && qf.date !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
                if (qf.city !== undefined && qf.city !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
            })
        }
        if (this.state.showSemiList) {
            semiFinals.map(sf => {
                if (sf.date !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
                if (sf.city !== undefined && sf.city !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
            })
        }
        if (final.date !== null) {
            formFinalError = false;
        }
        else {

            formFinalError = true;
        }
        if (final.city !== undefined && final.city !== null) {
            formFinalError = false;
        }
        else {
            formFinalError = true;
        }
        console.log(pool)
        console.log(final)

        console.log(formLeagueError)
        console.log(formQuarterError)
        console.log(formSemiError)
        console.log(formFinalError)
        if (formLeagueError == true || formQuarterError == true || formSemiError || formFinalError == true) {
            $('#organiseTornamentError').show();
            $('#organiseTornamentError').delay(2000).fadeOut('slow');
        }
        else {
            let quarterData;
            if (this.state.showQuarterList) {
                quarterData = [...quarterFinals];
            }
            else {
                quarterData = null;
            }
            let semiData;
            if (this.state.showSemiList) {
                semiData = [...semiFinals];
            }
            else {
                semiData = null;
            }

            organizeMatch.ttType = 3;
            // organizeMatch.tt2Matches = null;
            organizeMatch.tt3Matches = pool;
            if (quarterData !== null) {
                organizeMatch.quarterFinals = quarterData;
            }
            if (semiData !== null) {
                organizeMatch.semiFinals = semiData;
            }
            organizeMatch.finals = final;
            console.log(organizeMatch)
            const apiUrl = rshApi + "/demo-tournaments/organize-tt23";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        tournamentId: that.props.tournamentData.tournamentId,
                        organizeMatch: organizeMatch,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)
                    $('#organiseTornamentSuccess').show();
                    $('#organiseTornamentSuccess').delay(2000).fadeOut('slow');

                    // this.props.afterOrganiseList();

                }).catch(function (error) {

                })

            var tournament = that.props.tournamentData;
            let matchType;
            if (that.state.showQuarterList) {
                matchType = "Quarter final";
            }
            else if (that.state.showSemiList) {
                matchType = "Semi final";
            }
            else {
                matchType = "final";
            }
            tournament.matchType = matchType;
            tournament.scheduledTournament = true;
            delete tournament.id;
            var apiUrl1 = rshApi + "/demo-tournaments/update-tournament";
            fetch(apiUrl1, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournament": tournament,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    that.props.afterOrganiseList();
                });
        }
    }
    render() {

        let datetime = this.state.tDateTime;
        if (this.state.forOdd) {

            if (this.state.NumberOfPool === 3 || this.state.NumberOfPool === 5) {
                Array.prototype.pairs = function (func) {
                    for (let i = 0; i < this.length - 1; i++) {
                        for (let j = i; j < this.length - 1; j++) {
                            func([this[i], this[j + 1]]);
                        }
                    }
                }

                // PoolA = [...this.state.selectForOdd1];
                // console.log(PoolA)
                if (Pool1.length > 0) {

                }
                else {
                    PoolA = this.state.selectForOdd1;
                    this.state.selectForOdd1.pairs(function (pair) {
                        let data = {
                            firstId: pair[0],
                            secondId: pair[1],
                            dateObj: null,
                        }

                        Pool1.push(data);
                    });
                }
                if (this.state.pool2List) {
                    if (Pool2.length > 0) {

                    }
                    else {
                        PoolB = this.state.selectForOdd2;
                        this.state.selectForOdd2.pairs(function (pair) {
                            let data = {
                                firstId: pair[0],
                                secondId: pair[1],
                                dateObj: null,
                            }
                            Pool2.push(data);
                        });
                        console.log(Pool2)
                    }
                }
                if (this.state.pool3List) {
                    if (Pool3.length > 0) {

                    }
                    else {
                        PoolC = this.state.selectForOdd3;

                        this.state.selectForOdd3.pairs(function (pair) {
                            let data = {
                                firstId: pair[0],
                                secondId: pair[1],
                                dateObj: null,
                            }
                            Pool3.push(data);
                        });

                    }
                }


                if (this.state.NumberOfPool === 5) {
                    if (this.state.pool4List) {
                        if (Pool4.length > 0) {

                        }
                        else {
                            PoolD = this.state.selectForOdd4;
                            this.state.selectForOdd4.pairs(function (pair) {
                                let data = {
                                    firstId: pair[0],
                                    secondId: pair[1],
                                    dateObj: null,
                                }
                                Pool4.push(data);
                            });
                        }
                    }
                    if (this.state.pool5List) {
                        if (Pool5.length > 0) {

                        }
                        else {
                            PoolE = this.state.selectForOdd5;
                            this.state.selectForOdd5.pairs(function (pair) {
                                let data = {
                                    firstId: pair[0],
                                    secondId: pair[1],
                                    dateObj: null,
                                }
                                Pool5.push(data);
                            });
                        }
                    }
                }
            }
            organiseList1Odd = [];
            Pool1.map((pair, index) => {

                datePickerId++;
                let newId = pair.firstId + pair.secondId
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === pair.firstId) {
                            firstName = team.teamName;
                            firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                        if (team.teamId === pair.secondId) {
                            secondName = team.teamName;
                            secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === pair.firstId) {
                            firstName = user.name;
                            firstPic = user.profilePic;
                        }
                        if (user.contactNo === pair.secondId) {
                            secondName = user.name;
                            secondPic = user.profilePic;
                        }
                    });
                }
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
                var organise = <li class="leauge_matchdiv" key={index} >
                    <div class="teamdiv">
                        <ul class="live_teamul live_teamul1 straight_ul">
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                                <label class=" straight_player">{firstName}</label>
                            </li>
                            <li>
                                <img class="vsicon" src={challengefill} alt="" />
                            </li>
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                                <label class="straight_player">{secondName}</label>
                            </li>
                        </ul>
                        <div class="straight_inputs" >
                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 1)}>
                                <DatePicker
                                    selected={pair.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />

                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, 1)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>
                    </div>
                </li>
                organiseList1Odd.push(organise)

            })
            organiseList2Odd = [];
            Pool2.map((pair, index) => {

                datePickerId++;
                let newId = pair.firstId + pair.secondId
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === pair.firstId) {
                            firstName = team.teamName;
                            firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                        if (team.teamId === pair.secondId) {
                            secondName = team.teamName;
                            secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === pair.firstId) {
                            firstName = user.name;
                            firstPic = user.profilePic;
                        }
                        if (user.contactNo === pair.secondId) {
                            secondName = user.name;
                            secondPic = user.profilePic;
                        }
                    });
                }
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
                var organise = <li class="leauge_matchdiv" key={index} >
                    <div class="teamdiv">
                        <ul class="live_teamul live_teamul1 straight_ul">
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                                <label class=" straight_player">{firstName}</label>
                            </li>
                            <li>
                                <img class="vsicon" src={challengefill} alt="" />
                            </li>
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                                <label class="straight_player">{secondName}</label>
                            </li>
                        </ul>
                        <div class="straight_inputs" >
                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 2)}>
                                <DatePicker
                                    selected={pair.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />

                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, 2)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>
                    </div>
                </li>
                organiseList2Odd.push(organise)

            })
            organiseList3Odd = [];
            Pool3.map((pair, index) => {

                datePickerId++;
                let newId = pair.firstId + pair.secondId
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === pair.firstId) {
                            firstName = team.teamName;
                            firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                        if (team.teamId === pair.secondId) {
                            secondName = team.teamName;
                            secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === pair.firstId) {
                            firstName = user.name;
                            firstPic = user.profilePic;
                        }
                        if (user.contactNo === pair.secondId) {
                            secondName = user.name;
                            secondPic = user.profilePic;
                        }
                    });
                }
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
                var organise = <li class="leauge_matchdiv" key={index} >
                    <div class="teamdiv">
                        <ul class="live_teamul live_teamul1 straight_ul">
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                                <label class=" straight_player">{firstName}</label>
                            </li>
                            <li>
                                <img class="vsicon" src={challengefill} alt="" />
                            </li>
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                                <label class="straight_player">{secondName}</label>
                            </li>
                        </ul>
                        <div class="straight_inputs" >
                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 3)}>
                                <DatePicker
                                    selected={pair.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />

                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, 3)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>
                    </div>
                </li>
                organiseList3Odd.push(organise)

            })
            organiseList4Odd = [];
            Pool4.map((pair, index) => {

                datePickerId++;
                let newId = pair.firstId + pair.secondId
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === pair.firstId) {
                            firstName = team.teamName;
                            firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                        if (team.teamId === pair.secondId) {
                            secondName = team.teamName;
                            secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === pair.firstId) {
                            firstName = user.name;
                            firstPic = user.profilePic;
                        }
                        if (user.contactNo === pair.secondId) {
                            secondName = user.name;
                            secondPic = user.profilePic;
                        }
                    });
                }
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
                var organise = <li class="leauge_matchdiv" key={index} >
                    <div class="teamdiv">
                        <ul class="live_teamul live_teamul1 straight_ul">
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                                <label class=" straight_player">{firstName}</label>
                            </li>
                            <li>
                                <img class="vsicon" src={challengefill} alt="" />
                            </li>
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                                <label class="straight_player">{secondName}</label>
                            </li>
                        </ul>
                        <div class="straight_inputs" >
                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 4)}>
                                <DatePicker
                                    selected={pair.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />

                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, 4)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>
                    </div>
                </li>
                organiseList4Odd.push(organise)

            })
            organiseList5Odd = [];
            Pool5.map((pair, index) => {

                datePickerId++;
                let newId = pair.firstId + pair.secondId
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === pair.firstId) {
                            firstName = team.teamName;
                            firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                        if (team.teamId === pair.secondId) {
                            secondName = team.teamName;
                            secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === pair.firstId) {
                            firstName = user.name;
                            firstPic = user.profilePic;
                        }
                        if (user.contactNo === pair.secondId) {
                            secondName = user.name;
                            secondPic = user.profilePic;
                        }
                    });
                }
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
                var organise = <li class="leauge_matchdiv" key={index} >
                    <div class="teamdiv">
                        <ul class="live_teamul live_teamul1 straight_ul">
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                                <label class=" straight_player">{firstName}</label>
                            </li>
                            <li>
                                <img class="vsicon" src={challengefill} alt="" />
                            </li>
                            <li>
                                <span class="lt_img"
                                    style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                                <label class="straight_player">{secondName}</label>
                            </li>
                        </ul>
                        <div class="straight_inputs" >
                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 5)}>
                                <DatePicker
                                    selected={pair.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />

                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, 5)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>
                    </div>
                </li>
                organiseList5Odd.push(organise)

            })
        }





        // if (this.state.showPoolAB) {
        if (this.state.NumberOfPool === 2) {

            Array.prototype.pairs = function (func) {
                for (var i = 0; i < this.length - 1; i++) {
                    for (var j = i; j < this.length - 1; j++) {
                        func([this[i], this[j + 1]]);
                    }
                }
            }

            if (this.state.PlayerPerPool === 3) {

                PoolA = this.state.selectForEven.slice(0, 3);
                PoolB = this.state.selectForEven.slice(3, 6);
                console.log(PoolA);
                console.log(PoolB);
            }
            if (this.state.PlayerPerPool === 4) {
                PoolA = this.state.selectForEven.slice(0, 4);
                PoolB = this.state.selectForEven.slice(4, 8);

            }
            if (this.state.PlayerPerPool === 5) {
                PoolA = this.state.selectForEven.slice(0, 5);
                PoolB = this.state.selectForEven.slice(5, 10);

            }

            if (Pool1.length > 0) {

            }
            else {

                PoolA.pairs(function (pair) {
                    let data = {
                        firstId: pair[0],
                        secondId: pair[1],
                        dateObj: null,
                    }
                    Pool1.push(data);
                });
            }

            // Pool1.filter((item, index) =>
            //     Pool1.indexOf(item) === index
            // )
            // Pool1.reduce((unique, item) => {
            //     return unique.includes(item) ? unique : [...unique, item]
            // }, []);
            if (Pool2.length > 0) {

            }
            else {
                PoolB.pairs(function (pair) {
                    let data = {
                        firstId: pair[0],
                        secondId: pair[1],
                        dateObj: null,
                    }
                    Pool2.push(data);
                });
            }
        }
        else if (this.state.NumberOfPool === 4) {
            Array.prototype.pairs = function (func) {
                for (var i = 0; i < this.length - 1; i++) {
                    for (var j = i; j < this.length - 1; j++) {
                        func([this[i], this[j + 1]]);
                    }
                }
            }

            if (this.state.PlayerPerPool === 3) {
                PoolA = this.state.selectForEven.slice(0, 3);
                PoolB = this.state.selectForEven.slice(3, 6);
                PoolC = this.state.selectForEven.slice(6, 9);
                PoolD = this.state.selectForEven.slice(9, 12);

            }
            if (this.state.PlayerPerPool === 4) {
                PoolA = this.state.selectForEven.slice(0, 4);
                PoolB = this.state.selectForEven.slice(4, 8);
                PoolC = this.state.selectForEven.slice(12, 18);
                PoolD = this.state.selectForEven.slice(18, 24);

            }
            if (this.state.PlayerPerPool === 5) {
                PoolA = this.state.selectForEven.slice(0, 5);
                PoolB = this.state.selectForEven.slice(5, 10);
                PoolC = this.state.selectForEven.slice(12, 18);
                PoolD = this.state.selectForEven.slice(18, 24);

            }
            if (this.state.PlayerPerPool === 6) {
                PoolA = this.state.selectForEven.slice(0, 6);
                PoolB = this.state.selectForEven.slice(6, 12);
                PoolC = this.state.selectForEven.slice(12, 18);
                PoolD = this.state.selectForEven.slice(18, 24);

            }
            PoolA.pairs(function (pair) {
                let data = {
                    firstId: pair[0],
                    secondId: pair[1],
                    dateObj: null,
                }
                Pool1.push(data);
            });
            PoolB.pairs(function (pair) {
                let data = {
                    firstId: pair[0],
                    secondId: pair[1],
                    dateObj: null,
                }
                Pool2.push(data);
            });
            PoolC.pairs(function (pair) {
                let data = {
                    firstId: pair[0],
                    secondId: pair[1],
                    dateObj: null,
                }
                Pool3.push(data);
            });
            PoolD.pairs(function (pair) {
                let data = {
                    firstId: pair[0],
                    secondId: pair[1],
                    dateObj: null,
                }
                Pool4.push(data);
            });
        }
        //pool1
        organiseList1 = [];
        Pool1.map((pair, index) => {

            datePickerId++;
            let newId = pair.firstId + pair.secondId
            if (this.props.tournamentMode === 'team') {
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === pair.firstId) {
                        firstName = team.teamName;
                        firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === pair.secondId) {
                        secondName = team.teamName;
                        secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                });
            }
            else {
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
            }
            this.props.usersInTournament.map(user => {
                if (user.contactNo === pair.firstId) {
                    firstName = user.name;
                    firstPic = user.profilePic;
                }
                if (user.contactNo === pair.secondId) {
                    secondName = user.name;
                    secondPic = user.profilePic;
                }
            });

            var organise = <li class="leauge_matchdiv" key={index} >
                <div class="teamdiv">
                    <ul class="live_teamul live_teamul1 straight_ul">
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                            <label class=" straight_player">{firstName}</label>
                        </li>
                        <li>
                            <img class="vsicon" src={challengefill} alt="" />
                        </li>
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                            <label class="straight_player">{secondName}</label>
                        </li>
                    </ul>
                    <div class="straight_inputs" >
                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 1)}>
                            <DatePicker
                                selected={pair.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />

                            <img src={schedulematches} alt="" />
                        </div>
                        <div class="fixture_date fixture_location" onClick={() => this.location(index, 1)}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>
                    </div>
                </div>
            </li>
            organiseList1.push(organise)

        })

        //Pool2
        organiseList2 = [];
        Pool2.map((pair, index) => {

            datePickerId++;
            let newId = pair.firstId + pair.secondId
            if (this.props.tournamentMode === 'team') {
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === pair.firstId) {
                        firstName = team.teamName;
                        firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === pair.secondId) {
                        secondName = team.teamName;
                        secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                });
            }
            else {
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
            }
            this.props.usersInTournament.map(user => {
                if (user.contactNo === pair.firstId) {
                    firstName = user.name;
                    firstPic = user.profilePic;
                }
                if (user.contactNo === pair.secondId) {
                    secondName = user.name;
                    secondPic = user.profilePic;
                }
            });
            var organise = <li class="leauge_matchdiv" key={index} >
                <div class="teamdiv">
                    <ul class="live_teamul live_teamul1 straight_ul">
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                            <label class=" straight_player">{firstName}</label>
                        </li>
                        <li>
                            <img class="vsicon" src={challengefill} alt="" />
                        </li>
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                            <label class="straight_player">{secondName}</label>
                        </li>
                    </ul>
                    <div class="straight_inputs" >
                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 2)}>
                            <DatePicker
                                selected={pair.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />

                            <img src={schedulematches} alt="" />
                        </div>
                        <div class="fixture_date fixture_location" onClick={() => this.location(index, 2)}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>
                    </div>
                </div>
            </li>
            organiseList2.push(organise)

        })
        //Pool3
        Pool3.map((pair, index) => {

            datePickerId++;
            let newId = pair.firstId + pair.secondId
            if (this.props.tournamentMode === 'team') {
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === pair.firstId) {
                        firstName = team.teamName;
                        firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === pair.secondId) {
                        secondName = team.teamName;
                        secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                });
            }
            else {
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
            }
            this.props.usersInTournament.map(user => {
                if (user.contactNo === pair.firstId) {
                    firstName = user.name;
                    firstPic = user.profilePic;
                }
                if (user.contactNo === pair.secondId) {
                    secondName = user.name;
                    secondPic = user.profilePic;
                }
            });
            var organise = <li class="leauge_matchdiv" key={index} >
                <div class="teamdiv">
                    <ul class="live_teamul live_teamul1 straight_ul">
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                            <label class=" straight_player">{firstName}</label>
                        </li>
                        <li>
                            <img class="vsicon" src={challengefill} alt="" />
                        </li>
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                            <label class="straight_player">{secondName}</label>
                        </li>
                    </ul>
                    <div class="straight_inputs" >
                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 3)}>
                            <DatePicker
                                selected={pair.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />

                            <img src={schedulematches} alt="" />
                        </div>
                        <div class="fixture_date fixture_location" onClick={() => this.location(index, 3)}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>
                    </div>
                </div>
            </li>
            organiseList3.push(organise)

        })

        //Pool4
        Pool4.map((pair, index) => {

            datePickerId++;
            let newId = pair.firstId + pair.secondId
            if (this.props.tournamentMode === 'team') {
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === pair.firstId) {
                        firstName = team.teamName;
                        firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === pair.secondId) {
                        secondName = team.teamName;
                        secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                });
            }
            else {
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic;
                    }
                });
            }
            this.props.usersInTournament.map(user => {
                if (user.contactNo === pair.firstId) {
                    firstName = user.name;
                    firstPic = user.profilePic;
                }
                if (user.contactNo === pair.secondId) {
                    secondName = user.name;
                    secondPic = user.profilePic;
                }
            });
            var organise = <li class="leauge_matchdiv" key={index} >
                <div class="teamdiv">
                    <ul class="live_teamul live_teamul1 straight_ul">
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                            <label class=" straight_player">{firstName}</label>
                        </li>
                        <li>
                            <img class="vsicon" src={challengefill} alt="" />
                        </li>
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                            <label class="straight_player">{secondName}</label>
                        </li>
                    </ul>
                    <div class="straight_inputs" >
                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, 4)}>
                            <DatePicker
                                selected={pair.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />

                            <img src={schedulematches} alt="" />
                        </div>
                        <div class="fixture_date fixture_location" onClick={() => this.location(index, 4)}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>
                    </div>
                </div>
            </li>
            organiseList4.push(organise)

        })
        // }


        return (
            <React.Fragment>

                {
                    this.state.showPoolBasedLeagueModal ? <div class="schedule_pad straight_pad">
                        <div class="cc_inner_box">
                            <label class="cc_box-head">Pool Type</label>
                            <div class="schedulebox">
                                <div class="styled-input-single createinput">
                                    <input type="radio" name="fieldset-5" id="evenPool" value="even" onChange={this.poolType} />
                                    <label for="evenPool">Even Pool (2,4,8 Pools)</label>
                                </div>
                                <div class="styled-input-single createinput">
                                    <input type="radio" name="fieldset-5" id="oddPool" value="odd" onChange={this.poolType} />
                                    <label for="oddPool">Odd Pool (3,5 Pools)</label>
                                </div>

                            </div>
                        </div>
                    </div> : ""
                }



                {
                    this.state.showEvenBuddy ? <ul className="buddieslist_ul ct_buddies">
                        {this.state.tickCountForEven ? <p>You have selected - {this.state.tickCountForEven} players</p> : ""}
                        {
                            this.state.userTeamList.map((items, index2) => {
                                let pic = items.Img;
                                return <li className="deselect" key={index2}>
                                    <a href="#">
                                        <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                        <label>{items.name}</label>

                                    </a>
                                    <div className="styled-input-single">
                                        <input type="checkbox" onChange={this.selectBuddyForEventPool} name="fieldset-6" id={"checkBox" + index2} value={items.Id} />

                                        <label htmlFor={"checkBox" + index2}></label>
                                    </div>
                                </li>
                            })
                        }
                        <div className="modal-footer">
                            {this.state.errorInPlayerSelection ? <p>{this.state.errorInPlayerSelection}</p> : ""}   <button type="button" onClick={() => this.savePlayerForEven()} className="cmnbutton">Done</button>
                        </div>
                    </ul> : ""
                }

                {this.state.showOddBuddySelection ? <Modal show={this.state.showOddBuddySelection} animation={false} onHide={this.handleClose} dialogClassName="reslutmodel">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={this.handleClose}>&times;</button>
                            <h4 className="modal-title">Please Select Players For Tournament</h4>
                        </div>
                        <div className="modal-body">
                            <ul className="buddieslist_ul ct_buddies">
                                {this.state.tickCountForOdd ? <p>You have selected - {this.state.tickCountForOdd} players</p> : ""}
                                {
                                    this.state.userTeamListForOdd.map((items, index2) => {
                                        let pic = items.Img;
                                        return <li className="deselect" key={index2}>
                                            <a href="#">
                                                <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                                <label>{items.name}</label>

                                            </a>
                                            <div className="styled-input-single">
                                                <input type="checkbox" onChange={this.selectForOddPool} name="fieldset-6" id={"checkBox" + index2} value={items.Id} />

                                                <label htmlFor={"checkBox" + index2}></label>
                                            </div>
                                        </li>
                                    })
                                }
                                <div className="modal-footer">
                                    {this.state.errorInPlayerSelection ? <p>{this.state.errorInPlayerSelection}</p> : ""}   <button type="button" onClick={() => this.savePlayerForOdd()} className="cmnbutton">Done</button>
                                </div>
                            </ul>
                        </div>
                    </div>
                </Modal> : ""}
                {/* {
                    this.state.showOddBuddySelection ? <ul className="buddieslist_ul ct_buddies">
                        {this.state.tickCountForOdd ? <p>You have selected - {this.state.tickCountForOdd} players</p> : ""}
                        {
                            this.state.userTeamListForOdd.map((items, index2) => {
                                let pic = items.Img;
                                return <li className="deselect" key={index2}>
                                    <a href="#">
                                        <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                        <label>{items.name}</label>

                                    </a>
                                    <div className="styled-input-single">
                                        <input type="checkbox" onChange={this.selectForOddPool} name="fieldset-6" id={"checkBox" + index2} value={items.Id} />

                                        <label htmlFor={"checkBox" + index2}></label>
                                    </div>
                                </li>
                            })
                        }
                        <div className="modal-footer">
                            {this.state.errorInPlayerSelection ? <p>{this.state.errorInPlayerSelection}</p> : ""}   <button type="button" onClick={() => this.savePlayerForOdd()} className="cmnbutton">Done</button>
                        </div>
                    </ul> : ""
                } */}
                {this.state.showReshuffle ? <div class="">
                    <button className="createnext" onClick={() => this.reShuffle()}>Re-Shuffle</button>
                </div> : ""}
                {this.state.showOddBuddy ? <div class="back_white">

                    <div class="pool-number">
                        <label> POOL -1</label>
                    </div>
                    {this.state.pool1PlayerSelected ?
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="completedmatches">
                                <ul class="scoreboxul tourcompleted_match">
                                    {organiseList1Odd}
                                    {/* {organiseListPool1Odd} */}
                                </ul>
                            </div>
                        </div>
                        : <div class="organized_tour-btn"><button onClick={() => this.selectBuddyForOddPool("pool1")}>SELECT BUDDIES</button></div>
                    }
                    <div class="pool-number">
                        <label> POOL -2</label>
                    </div>
                    {this.state.pool2PlayerSelected ?
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="completedmatches">
                                <ul class="scoreboxul tourcompleted_match">
                                    {organiseList2Odd}
                                    {/* {organiseListPool2Odd} */}
                                </ul>
                            </div>
                        </div>
                        : <div class="organized_tour-btn"><button onClick={() => this.selectBuddyForOddPool("pool2")}>SELECT BUDDIES</button></div>}
                    <div class="pool-number">
                        <label> POOL -3</label>
                    </div>
                    {this.state.pool3PlayerSelected ?
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="completedmatches">
                                <ul class="scoreboxul tourcompleted_match">
                                    {organiseList3Odd}
                                    {/* {organiseListPool3Odd} */}
                                </ul>
                            </div>
                        </div>
                        : <div class="organized_tour-btn"><button onClick={() => this.selectBuddyForOddPool("pool3")}>SELECT BUDDIES</button></div>}
                    {this.state.showFor5Pool ? <React.Fragment>
                        <div class="pool-number">
                            <label> POOL -4</label>
                        </div>
                        {this.state.pool4PlayerSelected ?
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">
                                        {organiseList4Odd}
                                        {/* {organiseListPool4Odd} */}
                                    </ul>
                                </div>
                            </div>
                            :
                            <div class="organized_tour-btn"><button onClick={() => this.selectBuddyForOddPool("pool4")}>SELECT BUDDIES</button></div>}
                        <div class="pool-number">
                            <label> POOL -5</label>
                        </div>
                        {this.state.pool5PlayerSelected ?
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">
                                        {organiseList5Odd}
                                        {/* {organiseListPool5Odd} */}
                                    </ul>
                                </div>
                            </div>
                            : <div class="organized_tour-btn"><button onClick={() => this.selectBuddyForOddPool("pool5")}>SELECT BUDDIES</button></div>}
                    </React.Fragment> : ''}
                </div>
                    : ''}


                {this.state.showPoolAB ? <div class="back_white">
                    <div class="pool-number">
                        <label> POOL -1</label>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                {organiseList1}
                            </ul>
                        </div>
                    </div>
                </div> : ''}
                {this.state.showPoolAB ? <div class="back_white">
                    <div class="pool-number">
                        <label> POOL -2</label>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                {organiseList2}
                            </ul>
                        </div>
                    </div>
                </div> : ''}

                {this.state.showPoolABCD ? <div class="back_white">
                    <div class="pool-number">
                        <label> POOL -3</label>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                {organiseList3}
                            </ul>
                        </div>
                    </div>
                </div> : ''}


                {this.state.showPoolABCD ? <div class="back_white">
                    <div class="pool-number">
                        <label> POOL -4</label>

                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                {organiseList4}
                            </ul>
                        </div>
                    </div>
                </div> : ''}


                {
                    this.state.showQuarter ?
                        <div class="schedule_pad straight_pad">
                            <div class="cc_inner_box">
                                <label class="cc_box-head">Format</label>
                                <div class="schedulebox">
                                    <React.Fragment>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="quarter-final" id="quarter-final" defaultChecked={this.state.defaultQuarter} onChange={this.quarterORsemi} />
                                            <label for="quarter-final">Quarter Final</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="semi-final" id="semi-final" onChange={this.quarterORsemi} />
                                            <label for="semi-final">Semi Final</label>
                                        </div>

                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                        : ''
                }




                {
                    this.state.showQuarterList ?
                        <React.Fragment>
                            <div class="semi_headingdiv quater-final">
                                <label >Quarter Final</label>
                            </div>
                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj1}
                                                                onChange={this.setTdateTimeQuarterFinal1}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(0, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj2}
                                                                onChange={this.setTdateTimeQuarterFinal2}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj3}
                                                                onChange={this.setTdateTimeQuarterFinal3}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(2, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj4}
                                                                onChange={this.setTdateTimeQuarterFinal4}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(3, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment> : ""
                }
                {
                    this.state.showSemi ?
                        <div class="schedule_pad straight_pad">
                            <div class="cc_inner_box">
                                <label class="cc_box-head">Format</label>
                                <div class="schedulebox">
                                    <React.Fragment>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="semi-final" id="semi-final" defaultChecked={this.state.defaultSemi} onChange={this.semiORfinal} />
                                            <label for="semi-final">Semi Final</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="final" id="final" onChange={this.semiORfinal} />
                                            <label for="final">Final</label>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    this.state.showSemiList ?
                        <React.Fragment>
                            <div class="semi_headingdiv semi-final">
                                <label >Semi Final</label>
                            </div>

                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.SemiFinaldateObj1}
                                                                onChange={this.setTdateTimeSemiFinal1}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(0, 'semi-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.SemiFinaldateObj2}
                                                                onChange={this.setTdateTimeSemiFinal2}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'semi-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : ""
                }
                {
                    this.state.showFinal ?
                        <React.Fragment>
                            <div class="semi_headingdiv final">
                                <label >Final</label>
                            </div>

                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.FinaldateObj}
                                                                onChange={this.setTdateTimeFinal}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'final')} >
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </React.Fragment> : ""
                }
                <div>
                    {this.state.showOrganiseCreateButton ? <center>
                        <button type="button" onClick={() => this.organiseTournament()} class="createnext" type="submit">Organise Tournament</button>
                    </center> : ""}
                    <div id="organiseTornamentSuccess" style={{ display: 'none', margin: '5px' }} className="alert alert-success " role="alert">
                        <strong>Tournament Organised successfully !</strong>
                    </div>
                    <div id="organiseTornamentError" style={{ display: 'none', margin: '5px' }} className="alert alert-danger " role="alert">
                        <strong>please Fill All information !</strong>
                    </div>
                </div>

            </React.Fragment >
        )
    }
}
