import React, { Component } from "react";
//import SearchField from 'react-search-field';
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import GetCurrentTimestamp from "./GetCurrentTimestamp";
import { Date } from "prismic-reactjs";
import showDefaultTeam from "./img/team.png";
import camera from "./img/camera.png";
import searchnew from "./img/searchnew.png";
import challenegbg from "./img/challenegbg.png";
import confirmation from "./img/confirmation.png";
import editgreen from "./img/editgreen.png";
import moredark from "./img/moredark.png";
import InviteTeamQrcode from "./QrCode/InviteTeamQrcode";
import cancelred from "./img/cancelred.png";
import maleIcon from "./img/male.png";
import femaleIcon from "./img/female.png";
import Modal from "react-bootstrap/Modal";
//import ModalDialog from 'react-bootstrap/ModalDialog';
import ConfigUrl from "./ConfigUrl";
import ImgDropAndCrop from "./ImgDropAndCrop";
let allBuddies = [],
  maleUser = [],
  femaleUser = [],
  mixUser = [];
let disTeam = false;
const ConfigApiUrl = ConfigUrl("apiUrl");
const rshApi = ConfigApiUrl;

function searchingFor(term) {
  return function (x) {
    return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
  };
}
function searchingFor1(term1) {
  return function (x) {
    return x.name.toLowerCase().includes(term1.toLowerCase()) || !term1;
  };
}

class TeamProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      users: [],
      takeOnlyContact: "",
      teamMemberCount: "",
      teamMembers: [],
      allBuddies: [],
      takeContact: "",
      takeName: "",
      multiUser: [],
      tickCount: [],
      theOwnership: "",
      theOwnership2: "",
      disableTeam: "",
      selectBuddyContact: "",
      selectBuddyConName: "",
      pictures: "",
      term: "", //for searchbar
      term1: "", //for searchbar
      moduleType: "team",
      showQr: false,
      teamQR: null,
    };
    this.state.user = JSON.parse(localStorage.getItem("result"));
    this.searchHandler = this.searchHandler.bind(this);
    this.searchHandler1 = this.searchHandler1.bind(this);
    this.liRefs = [React.createRef()];
  }

  //Searching Bar
  searchHandler(event) {
    this.setState({ term: event.target.value });
  }
  searchHandler1(event) {
    this.setState({ term1: event.target.value });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(window.location.pathname);
    if (window.location.pathname === "/page/team/profile") {
      return {
        moduleType: "page",
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.getTeamFunction();
    setTimeout(() => {
      this.getBuddyFunction();
      if (this.state.moduleType === "team") {
        this.getBuddyFunction();
      } else {
        this.getFollowerFunction();
      }
    }, 600);
  }

  //get-teams API is called from here
  getTeamFunction = () => {
    var urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    let teamid = urlParams.get("id");
    console.log(teamid);
    const apiUrl = rshApi + "/teams/get-team";
    const that = this;
    fetch(apiUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          teamId: teamid,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        that.setState({
          teamQR: result.data.team.qrCode,
          teams: result.data.team,
          users: result.data.users,
          teamMembers: result.data.team.teamMembers,
          teamMemberCount: result.data.team.teamMembers
            ? result.data.team.teamMembers.length
            : 0,
        });
      });
  };
  //get page Follower
  getFollowerFunction = () => {
    const getAllBuddyfromUser = rshApi + "/pages/page-profile";
    const that = this;
    fetch(getAllBuddyfromUser, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          pageId: this.state.teams.pageId,
          contactNo: this.state.user.contactNo,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);

        that.setState({
          // teams: result.data.team,
          //users: result.data.users,
          allBuddies: result.data.pageFollowers,
          // teamMembers: result.data.pageFollowers,
          //   teamMemberCount: result.data.pageFollowers ? result.data.pageFollowers.length : 0,
        });
      });
  };
  //get-buddies API is called from here
  getBuddyFunction = () => {
    const getAllBuddyfromUser = rshApi + "/users/get-buddies";
    const that = this;
    fetch(getAllBuddyfromUser, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          buddyId: this.state.user.contactNo, // "user who is currently logged in"
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        //console.log(result);
        if (!result.error) {
          maleUser = result.data.filter((user) => {
            return user.gender === "Male";
          });
          femaleUser = result.data.filter((user) => {
            return user.gender === "Female";
          });
          mixUser = result.data.filter((user) => {
            return user.gender === "Female" || user.gender === "Male";
          });
          allBuddies = result.data;

          if (that.state.teams.gender === "Male") {
            that.setState({
              allBuddies: maleUser,
            });
          } else if (that.state.teams.gender === "Female") {
            that.setState({
              allBuddies: femaleUser,
            });
          } else {
            that.setState({
              allBuddies: mixUser,
            });
          }
        }
      });
  };

  //exit team function
  exitTeam = () => {
    var urlParams = new URLSearchParams(window.location.search);
    let teamid = urlParams.get("id");
    const exitTeam = rshApi + "/teams/exit-team";
    fetch(exitTeam, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          teamId: teamid,
          memberId: this.state.user.contactNo, // "user who is currently logged in"
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        window.location.href = "/teamslist";
      });
  };

  //code to change captain
  becomeCap = (takeOnlyContact) => {
    const that = this;
    that.setState({
      takeOnlyContact: takeOnlyContact,
    });
  };

  becomeCap2 = (contactNo) => {
    var urlParams = new URLSearchParams(window.location.search);
    let teamid = urlParams.get("id");
    const changeCapURL = rshApi + "/teams/assign-team-captain";
    const that = this;
    fetch(changeCapURL, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          teamId: teamid,
          captainId: contactNo,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        //console.log(result);
        that.setState({
          teamID: result.data.teamId,
          newCaptain: result.data.captain,
        });
        that.getTeamFunction();
        console.log(that.state.teamID);
        console.log(that.state.newCaptain);
      });
  };

  //code to deletebuddieslist_ul ct_buddies tp_selectedbuddieslist_ul ct_buddies tp_selected from team
  takeRemoveDetail = (takeContact, takeName) => {
    const that = this;
    that.setState({
      takeContact: takeContact,
      takeName: takeName,
    });
  };

  deleteTeamMembers = (playerRemoveID) => {
    var urlParams = new URLSearchParams(window.location.search);
    let teamid = urlParams.get("id");
    const removeTeamMem = rshApi + "/teams/remove-player";
    const that = this;
    fetch(removeTeamMem, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          teamId: teamid,
          memberId: playerRemoveID,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        //console.log(result);
        that.setState({
          teamID: result.data.teamId,
          newTeamMembers: result.data.teamMembers,
        });
        that.getTeamFunction();
        console.log(that.state.teamID);
        console.log(that.state.newTeamMembers);
      });
  };

  //code to add players to team
  takeUsers = (event) => {
    let checkedArray = this.state.multiUser;
    let selectedValue = event.target.value;

    if (event.target.checked === true) {
      checkedArray.push(selectedValue);
      this.setState({
        multiUser: checkedArray,
        tickCount: checkedArray.length,
      });
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({
        multiUser: checkedArray,
        tickCount: checkedArray.length,
      });
    }
  };

  sendUsers = () => {
    if (this.state.multiUser.length > 0) {
      var urlParams = new URLSearchParams(window.location.search);
      let teamid = urlParams.get("id");
      const addTeamMem = rshApi + "/teams/add-to-team";
      const that = this;
      fetch(addTeamMem, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            teamId: teamid,
            memberIds: this.state.multiUser,
          },
        }),
      })
        .then(function (response) {
          return trackPromise(response.json());
        })
        .then(function (result) {
          //console.log(result);
          that.setState({
            teamID: result.data.teamId,
            latestTeamMembers: result.data.teamMembers,
          });
          that.getTeamFunction();
          console.log(that.state.teamID);
          console.log(that.state.latestTeamMembers);
          window.location.href = "/teamprofilepage/index.html?id=" + teamid;
        });
    } else {
    }
  };

  //code to change owner
  becomeOwner = (selectBuddy, Buddyname) => {
    this.setState({
      selectBuddyContact: selectBuddy,
      selectBuddyConName: Buddyname,
    });
    console.log(this.state.selectBuddyContact);
  };

  theOwnership = (e) => {
    // document.getElementById('hideBuddies').style.visibility = "visible";
    document.getElementById("hideBuddies").style.display = "block";
    let ownerShip = e.target.value;

    if (e.target.checked === true && ownerShip === "ownerAndMember") {
      this.setState({
        theOwnership: ownerShip,
        disableTeam: "",
      });
    }
  };

  theOwnership2 = (event) => {
    // document.getElementById('hideBuddies').style.visibility = "visible";
    document.getElementById("hideBuddies").style.display = "block";
    let ownerShip2 = event.target.value;

    if (event.target.checked === true && ownerShip2 === "ownerAndRemove") {
      this.setState({
        theOwnership2: ownerShip2,
        disableTeam: "",
      });
    }
  };

  disableTeam = (events) => {
    // document.getElementById('hideBuddies').style.visibility = "hidden";
    document.getElementById("hideBuddies").style.display = "none";
    let disabling = events.target.value;

    if (events.target.checked === true && disabling === "disableTheTeam") {
      this.setState({
        disableTeam: disabling,
        theOwnership: "",
        theOwnership2: "",
      });
    }
  };

  becometheOwner = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var teamid = urlParams.get("id");

    if (this.state.theOwnership === "ownerAndMember") {
      const changeOwnership = rshApi + "/teams/request-new-owner";
      fetch(changeOwnership, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            teamId: teamid,
            ownerId: this.state.selectBuddyContact,
            overFlowActionValue: "Change the owner/keep me as member",
          },
        }),
      });
    }

    if (this.state.theOwnership2 === "ownerAndRemove") {
      const changeOwnership2 = rshApi + "/teams/request-new-owner";
      fetch(changeOwnership2, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            teamId: teamid,
            ownerId: this.state.selectBuddyContact,
            overFlowActionValue: "Change the owner/remove me from team",
          },
        }),
      });
    }

    if (this.state.disableTeam === "disableTheTeam") {
      const disableTeam = rshApi + "/teams/deactivate-team";
      fetch(disableTeam, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            teamId: teamid,
          },
        }),
      })
        .then(function (response) {
          return trackPromise(response.json());
        })
        .then(function (result) {
          window.location.href = "/teamslist";
        });
      disTeam = true;
    }
    this.setState({
      showingAlertss: true,
    });
    setTimeout(() => {
      this.setState({
        showingAlertss: false,
      });
    }, 1500);
  };

  //submit pic
  handleLanguage = (langValue) => {
    this.setState({ imageCrop: langValue });
  };

  submitPicture = () => {
    const apiUrl = rshApi + "upload-file";
    let dataurl = this.state.imageCrop;
    //console.log(dataurl)
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    var file = dataURLtoFile(dataurl, "image.jpg");

    var teamdata = this.state.teams;

    if (file !== undefined) {
      const currentTimeStamp = GetCurrentTimestamp();
      var data = {
        data: {
          folder: "teams/" + teamdata.teamId,
          fileName: "IMG_" + currentTimeStamp,
        },
      };
      let that = this;
      var form = new FormData();
      form.append("file", file);
      form.append("data", JSON.stringify(data));
      fetch(apiUrl, {
        method: "post",
        async: true,
        crossDomain: true,
        processData: false,
        "Content-Type": "false",
        body: form,
      })
        .then(function (response) {
          return trackPromise(response.json());
        })
        .then(function (result) {
          console.log(result.data.downloadUrl);
          teamdata.teamIcon = result.data.downloadUrl;
          that.setState({ teams: teamdata });
          uploadPictures(result.data.downloadUrl);
        });

      delete teamdata.id;
      console.log(teamdata);
      function uploadPictures(data) {
        teamdata.teamIcon = data;

        fetch(rshApi + "/teams/update-team", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              team: teamdata,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            console.log(result);
          });
      }
    }
  };

  QrCode1 = () => {
    this.setState({ showQr: true });
  };

  QrCode2 = () => {
    this.setState({ showQr: false });
  };

  setQr = (setQr) => {
    this.setState({ teamQR: setQr });
  }

  render() {
    let userCopy = [];
    this.state.users.map((user) => {
      for (let i = 0; i < this.state.teamMemberCount; i++) {
        if (user.contactNo === this.state.teamMembers[i]) {
          userCopy.push(user);
        }
      }
    });

    var isTeamMember = false;
    var theOwner = false;

    //showing date
    const timestamp = Date(this.state.teams.createdOn);
    var formattedTimestamp = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);

    const { term, users } = this.state;
    const { term1, allBuddies } = this.state;

    return (
      <div>
        {this.state.showQr ? (
          <InviteTeamQrcode
            teamQr={this.state.teamQR}
            teamObj={this.state.teams}
            closeQr={this.QrCode2}
            setQr={this.setQr}
          />
        ) : null}
        <Modal
          size="md"
          show={this.state.showingAlertss}
          //onHide={this.closeSports}
          animation={false}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="modal-body modal-bodyErrorPopup">
            <p className="alert alert-success">
              {disTeam ? (
                <strong>Team has been disabled.</strong>
              ) : (
                <strong>
                  Ownership invitation has been sent to{" "}
                  {this.state.selectBuddyConName}.
                </strong>
              )}
            </p>
          </div>
        </Modal>

        {/* Modal content */}
        <div id="assigncaptain" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Captain Selection</h4>
              </div>
              <div className="modal-body">
                <div className="ac_search">
                  <form>
                    <input
                      onChange={this.searchHandler}
                      value={term}
                      type="search"
                      placeholder="Search"
                    />
                    <Link to={"#"}>
                      <img src={searchnew} alt="true" />
                    </Link>
                  </form>
                </div>
                <ul className="playerlist">
                  {userCopy.filter(searchingFor(term)).map((item, index) => {
                    if (item.contactNo !== this.state.teams.captain) {
                      if (item.profilePic === null) {
                        switch (item.gender) {
                          case "Male":
                            return (
                              <li key={item.name}>
                                <span
                                  className="assignimg"
                                  style={{
                                    backgroundImage: "url(" + maleIcon + ")",
                                  }}
                                ></span>
                                <label className="ac_name">{item.name}</label>
                                <div className="styled-input-single createinput">
                                  <input
                                    type="radio"
                                    name="fieldset-5"
                                    id={"checkbox-example" + index}
                                  />
                                  <label
                                    onClick={() =>
                                      this.becomeCap(item.contactNo)
                                    }
                                    htmlFor={"checkbox-example" + index}
                                  ></label>
                                </div>
                              </li>
                            );

                          default:
                            return (
                              <li key={item.name}>
                                <span
                                  className="assignimg"
                                  style={{
                                    backgroundImage: "url(" + femaleIcon + ")",
                                  }}
                                ></span>
                                <label className="ac_name">{item.name}</label>
                                <div className="styled-input-single createinput">
                                  <input
                                    type="radio"
                                    name="fieldset-5"
                                    id={"checkbox-example" + index}
                                  />
                                  <label
                                    onClick={() =>
                                      this.becomeCap(item.contactNo)
                                    }
                                    htmlFor={"checkbox-example" + index}
                                  ></label>
                                </div>
                              </li>
                            );
                        }
                      } else {
                        return (
                          <li key={item.name}>
                            <span
                              className="assignimg"
                              style={{
                                backgroundImage: "url(" + item.profilePic + ")",
                              }}
                            ></span>
                            <label className="ac_name">{item.name}</label>
                            <div className="styled-input-single createinput">
                              <input
                                type="radio"
                                name="fieldset-5"
                                id={"checkbox-example" + index}
                              />
                              <label
                                onClick={() => this.becomeCap(item.contactNo)}
                                htmlFor={"checkbox-example" + index}
                              ></label>
                            </div>
                          </li>
                        );
                      }
                    } else {
                      if (item.profilePic === null) {
                        switch (item.gender) {
                          case "Male":
                            return (
                              <li key={item.name}>
                                <span
                                  className="assignimg"
                                  style={{
                                    backgroundImage: "url(" + maleIcon + ")",
                                  }}
                                ></span>
                                <label className="ac_name">{item.name}</label>
                                <div className="styled-input-single createinput">
                                  <input
                                    type="radio"
                                    name="fieldset-5"
                                    id={"checkbox-example" + index}
                                  />
                                  <label
                                    onClick={() =>
                                      this.becomeCap(item.contactNo)
                                    }
                                    htmlFor={"checkbox-example" + index}
                                  ></label>
                                </div>
                              </li>
                            );

                          default:
                            return (
                              <li key={item.name}>
                                <span
                                  className="assignimg"
                                  style={{
                                    backgroundImage: "url(" + femaleIcon + ")",
                                  }}
                                ></span>
                                <label className="ac_name">{item.name}</label>
                                <div className="styled-input-single createinput">
                                  <input
                                    type="radio"
                                    name="fieldset-5"
                                    id={"checkbox-example" + index}
                                  />
                                  <label
                                    onClick={() =>
                                      this.becomeCap(item.contactNo)
                                    }
                                    htmlFor={"checkbox-example" + index}
                                  ></label>
                                </div>
                              </li>
                            );
                        }
                      } else {
                        return (
                          <li key={item.name}>
                            <span
                              className="assignimg"
                              style={{
                                backgroundImage: "url(" + item.profilePic + ")",
                              }}
                            ></span>
                            <label className="ac_name">{item.name}</label>
                            <div className="styled-input-single createinput">
                              <input
                                type="radio"
                                name="fieldset-5"
                                id={"checkbox-example" + index}
                              />
                              <label
                                onClick={() => this.becomeCap(item.contactNo)}
                                htmlFor={"checkbox-example" + index}
                              ></label>
                            </div>
                          </li>
                        );
                      }
                    }
                  })}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.becomeCap2(this.state.takeOnlyContact)}
                  type="button"
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <section className="d_midsec createmidsec">
            <div className="divpost">
              <span className="teamprofile_span">Team Profile</span>
              <div
                className="createteam_div"
                style={{ backgroundImage: "url(" + challenegbg + ")" }}
              >
                <button id="uploadCover" style={{
                  display: "none"
                }}>Change Picture</button>
                <div className="uploadimg_div">
                  {(() => {
                    switch (this.state.teams.teamIcon) {
                      case null:
                        return (
                          <span
                            style={{
                              backgroundImage: "url(" + showDefaultTeam + ")",
                            }}
                          ></span>
                        );

                      default:
                        return (
                          <span
                            style={{
                              backgroundImage:
                                "url(" + this.state.teams.teamIcon + ")",
                            }}
                          ></span>
                        );
                    }
                  })()}
                  {(() => {
                    switch (
                    this.state.user.contactNo === this.state.teams.createdBy
                    ) {
                      case true:
                        return (
                          <Link
                            data-toggle="modal"
                            data-target="#openCropModalTP"
                          >
                            <img src={camera} alt="" />
                          </Link>
                        );
                      default:
                        return "";
                    }
                  })()}
                </div>

                <div>
                  {(() => {
                    switch (this.state.teams.gender) {
                      case "Male":
                        return (
                          <div>
                            <p
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                                marginTop: "6px",
                              }}
                            >
                              {this.state.teams.sportsName} - Men's{" "}
                            </p>
                          </div>
                        );

                      case "Female":
                        return (
                          <div>
                            <p
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                                marginTop: "6px",
                              }}
                            >
                              {this.state.teams.sportsName} - Women's{" "}
                            </p>
                          </div>
                        );

                      default:
                        return (
                          <div>
                            <p
                              style={{
                                fontWeight: "500",
                                textAlign: "center",
                                marginTop: "6px",
                              }}
                            >
                              {this.state.teams.sportsName} - Mix{" "}
                            </p>
                          </div>
                        );
                    }
                  })()}

                  {this.state.teams.createdBy === this.state.user.contactNo ? (
                    <button className="teamQrButton" onClick={this.QrCode1}><i class="fa fa-qrcode"></i>
                      Invite Team Via QR
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            {(() => {
              switch (
              this.state.user.contactNo === this.state.teams.createdBy
              ) {
                case true:
                  return (
                    <div className="editteamsec">
                      {/* <button><img src={editgreen} alt="" /> Edit</button> */}
                      <span data-toggle="modal" data-target="#selectoption2">
                        <img src={moredark} alt="" />
                      </span>
                    </div>
                  );
                default:
                  return "";
              }
            })()}

            <div className="createteamform">
              <ul className="nav nav-tabs teamprofiletab">
                <li className="active">
                  <a data-toggle="tab" href="#teaminfo">
                    Team Info
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#teamplayers">
                    Team Players
                  </a>
                </li>
              </ul>

              <div className="tab-content tp_content">
                <div id="teaminfo" className="tab-pane fade in active">
                  <div className="teambox">
                    <ul>
                      <li>
                        <span>Team Name</span>
                        <label>{this.state.teams.teamName}</label>
                      </li>

                      <li>
                        <span>Sports</span>
                        <label>{this.state.teams.sportsName}</label>
                      </li>

                      <li>
                        <span>Created On</span>
                        <label>{formattedTimestamp}</label>
                      </li>

                      <li>
                        {(() => {
                          switch (this.state.teams.active) {
                            case true:
                              return (
                                <div>
                                  <span>Status</span>
                                  <label>Active</label>
                                </div>
                              );
                            default:
                              return (
                                <div>
                                  <span>Status</span>
                                  <label>Not Active</label>
                                </div>
                              );
                          }
                        })()}
                      </li>
                    </ul>
                  </div>

                  <div className="teambox">
                    <ul>
                      <li>
                        <span>Manager</span>
                        {this.state.users.map((item) => {
                          if (item.contactNo === this.state.teams.createdBy) {
                            return (
                              <div key={item.name}>
                                <label>{item.name}</label>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </li>

                      <li>
                        <span>Players</span>
                        <label>{this.state.teamMemberCount}</label>
                      </li>

                      <li>
                        <span>Captain</span>

                        {this.state.users.slice(0, 1).map((item) => {
                          if (
                            this.state.user.contactNo ===
                            this.state.teams.createdBy
                          ) {
                            theOwner = true;
                          }
                          if (theOwner === true) {
                            return (
                              <div key={item.contactNo}>
                                {this.state.teams.captain === null ? (
                                  <div>
                                    <label>Not Yet Assigned</label>
                                    <span>
                                      <button
                                        className="assigncaptain"
                                        data-toggle="modal"
                                        data-target="#assigncaptain"
                                      >
                                        Assign Captain
                                      </button>
                                    </span>{" "}
                                  </div>
                                ) : (
                                  <div>
                                    {this.state.users.map((item) => {
                                      if (
                                        item.contactNo ===
                                        this.state.teams.captain
                                      ) {
                                        return (
                                          <div key={item.name}>
                                            <label>{item.name}</label>
                                            <span>
                                              <button
                                                className="assigncaptain"
                                                data-toggle="modal"
                                                data-target="#assigncaptain"
                                              >
                                                Change Captain
                                              </button>
                                            </span>
                                          </div>
                                        );
                                      }
                                      return "";
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return "";
                        })}

                        {this.state.users.map((item) => {
                          if (
                            item.contactNo === this.state.user.contactNo &&
                            theOwner === false
                          ) {
                            isTeamMember = true;
                          }
                          return "";
                        })}

                        {isTeamMember ? (
                          this.state.teams.captain === null ? (
                            <div>
                              <label>Not Yet Assigned</label>
                              <span>
                                <button
                                  className="assigncaptain"
                                  data-toggle="modal"
                                  data-target="#deleteplayer2"
                                >
                                  Exit Team
                                </button>
                              </span>
                            </div>
                          ) : (
                            <div>
                              {this.state.users.map((item) => {
                                if (
                                  item.contactNo === this.state.teams.captain
                                ) {
                                  return (
                                    <div key={item.name}>
                                      <label>{item.name}</label>
                                      <span>
                                        <button
                                          className="assigncaptain"
                                          data-toggle="modal"
                                          data-target="#deleteplayer2"
                                        >
                                          Exit Team
                                        </button>
                                      </span>
                                    </div>
                                  );
                                } else {
                                  return "";
                                }
                              })}{" "}
                            </div>
                          )
                        ) : this.state.teams.captain === null ? (
                          <div></div>
                        ) : (
                          <div>
                            {this.state.users.map((item) => {
                              if (!theOwner) {
                                if (
                                  item.contactNo === this.state.teams.captain
                                ) {
                                  return (
                                    <div key={item.name}>
                                      <label>{item.name}</label>
                                    </div>
                                  );
                                }
                              }
                            })}
                          </div>
                        )}
                      </li>

                      <li>
                        <span>Location</span>
                        <label>{this.state.teams.location}</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="teamplayers" className="tab-pane fade">
                  <div>
                    <div className="team_addmembers">
                      <span className="teammember">
                        Total <span>{this.state.teamMemberCount}</span> Team
                        Members
                      </span>

                      {(() => {
                        switch (
                        this.state.user.contactNo ===
                        this.state.teams.createdBy
                        ) {
                          case true:
                            return (
                              <button
                                data-toggle="modal"
                                data-target="#team_addplayers"
                              >
                                Add Players
                              </button>
                            );
                          default:
                            return "";
                        }
                      })()}
                    </div>
                    <div className="">
                      {(() => {
                        switch (
                        this.state.user.contactNo ===
                        this.state.teams.createdBy
                        ) {
                          case true:
                            return (
                              <ul className="buddieslist_ul ct_buddies tp_selected">
                                {userCopy.map((item) => {
                                  var theOwner = false;
                                  if (
                                    item.contactNo ===
                                    this.state.teams.createdBy
                                  ) {
                                    theOwner = true;
                                  }
                                  if (theOwner === true) {
                                    if (
                                      item.contactNo !==
                                      this.state.teams.captain
                                    ) {
                                      if (item.profilePic === null) {
                                        switch (item.gender) {
                                          case "Male":
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span className="team_buddy-remov"
                                                    style={{
                                                      backgroundImage:
                                                        "url(" + maleIcon + ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label className="team-buddyTyp">Member</label>
                                                </a>
                                              </li>
                                            );

                                          default:
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        femaleIcon +
                                                        ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label className="team-buddyTyp">Member</label>
                                                </a>
                                              </li>
                                            );
                                        }
                                      } else {
                                        return (
                                          <li key={item.name}>
                                            <a
                                              href={
                                                "/buddies?id=" + item.contactNo
                                              }
                                            >
                                              <span
                                                style={{
                                                  backgroundImage:
                                                    "url(" +
                                                    item.profilePic +
                                                    ")",
                                                }}
                                              ></span>
                                              <label>{item.name}</label>{" "}
                                              <label className="team-buddyTyp">Member</label>
                                            </a>
                                          </li>
                                        );
                                      }
                                    } else {
                                      if (item.profilePic === null) {
                                        switch (item.gender) {
                                          case "Male":
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" + maleIcon + ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label>Captain</label>
                                                </a>
                                              </li>
                                            );

                                          default:
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        femaleIcon +
                                                        ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label>Captain</label>
                                                </a>
                                              </li>
                                            );
                                        }
                                      } else {
                                        return (
                                          <li key={item.name}>
                                            <a
                                              href={
                                                "/buddies?id=" + item.contactNo
                                              }
                                            >
                                              <span
                                                style={{
                                                  backgroundImage:
                                                    "url(" +
                                                    item.profilePic +
                                                    ")",
                                                }}
                                              ></span>
                                              <label>{item.name}</label>{" "}
                                              <label>Captain</label>
                                            </a>
                                          </li>
                                        );
                                      }
                                    }
                                  }

                                  if (
                                    item.contactNo !== this.state.teams.captain
                                  ) {
                                    if (item.profilePic === null) {
                                      switch (item.gender) {
                                        case "Male":
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + maleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label className="team-buddyTyp">Member</label>
                                              </a>

                                              <span
                                                onClick={() =>
                                                  this.takeRemoveDetail(
                                                    item.contactNo,
                                                    item.name
                                                  )
                                                }
                                                className="deletebuddy team_buddy-remov"
                                                data-toggle="modal"
                                                data-target="#deleteplayer1"
                                              >
                                                <img src={cancelred} alt="" />
                                              </span>
                                            </li>
                                          );

                                        default:
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + femaleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label className="team-buddyTyp">Member</label>
                                              </a>

                                              <span
                                                onClick={() =>
                                                  this.takeRemoveDetail(
                                                    item.contactNo,
                                                    item.name
                                                  )
                                                }
                                                className="deletebuddy team_buddy-remov"
                                                data-toggle="modal"
                                                data-target="#deleteplayer1"
                                              >
                                                <img src={cancelred} alt="" />
                                              </span>
                                            </li>
                                          );
                                      }
                                    } else {
                                      return (
                                        <li key={item.name}>
                                          <a
                                            href={
                                              "/buddies?id=" + item.contactNo
                                            }
                                          >
                                            <span
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  item.profilePic +
                                                  ")",
                                              }}
                                            ></span>
                                            <label>{item.name}</label>{" "}
                                            <label className="team-buddyTyp">Member</label>
                                          </a>

                                          <span
                                            onClick={() =>
                                              this.takeRemoveDetail(
                                                item.contactNo,
                                                item.name
                                              )
                                            }
                                            className="deletebuddy team_buddy-remov"
                                            data-toggle="modal"
                                            data-target="#deleteplayer1"
                                          >
                                            <img src={cancelred} alt="" />
                                          </span>
                                        </li>
                                      );
                                    }
                                  } else {
                                    if (item.profilePic === null) {
                                      switch (item.gender) {
                                        case "Male":
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + maleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label>Captain</label>
                                              </a>

                                              {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                            </li>
                                          );

                                        default:
                                          return (
                                            <li key={item.name}>
                                              <a href={"#"}>
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + femaleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label>Captain</label>
                                              </a>

                                              {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                            </li>
                                          );
                                      }
                                    } else {
                                      return (
                                        <li key={item.name}>
                                          <a href={"#"}>
                                            <span
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  item.profilePic +
                                                  ")",
                                              }}
                                            ></span>
                                            <label>{item.name}</label>{" "}
                                            <label>Captain</label>
                                          </a>

                                          {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                        </li>
                                      );
                                    }
                                  }
                                })}
                              </ul>
                            );
                          default:
                            return (
                              <ul className="buddieslist_ul ct_buddies tp_selected">
                                {this.state.users.map((item) => {
                                  var theOwner = false;
                                  if (
                                    item.contactNo ===
                                    this.state.teams.createdBy
                                  ) {
                                    theOwner = true;
                                  }
                                  if (theOwner === true) {
                                    if (
                                      item.contactNo !==
                                      this.state.teams.captain
                                    ) {
                                      if (item.profilePic === null) {
                                        switch (item.gender) {
                                          case "Male":
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" + maleIcon + ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label className="team-buddyTyp">Member</label>
                                                </a>
                                              </li>
                                            );

                                          default:
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        femaleIcon +
                                                        ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label className="team-buddyTyp">Member</label>
                                                </a>
                                              </li>
                                            );
                                        }
                                      } else {
                                        return (
                                          <li key={item.name}>
                                            <a href={"#"}>
                                              <span
                                                style={{
                                                  backgroundImage:
                                                    "url(" +
                                                    item.profilePic +
                                                    ")",
                                                }}
                                              ></span>
                                              <label>{item.name}</label>{" "}
                                              <label className="team-buddyTyp">Member</label>
                                            </a>
                                          </li>
                                        );
                                      }
                                    } else {
                                      if (item.profilePic === null) {
                                        switch (item.gender) {
                                          case "Male":
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" + maleIcon + ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label>Captain</label>
                                                </a>
                                              </li>
                                            );

                                          default:
                                            return (
                                              <li key={item.name}>
                                                <a
                                                  href={
                                                    "/buddies?id=" +
                                                    item.contactNo
                                                  }
                                                >
                                                  <span
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        femaleIcon +
                                                        ")",
                                                    }}
                                                  ></span>
                                                  <label>{item.name}</label>{" "}
                                                  <label>Captain</label>
                                                </a>
                                              </li>
                                            );
                                        }
                                      } else {
                                        return (
                                          <li key={item.name}>
                                            <a
                                              href={
                                                "/buddies?id=" + item.contactNo
                                              }
                                            >
                                              <span
                                                style={{
                                                  backgroundImage:
                                                    "url(" +
                                                    item.profilePic +
                                                    ")",
                                                }}
                                              ></span>
                                              <label>{item.name}</label>{" "}
                                              <label>Captain</label>
                                            </a>
                                          </li>
                                        );
                                      }
                                    }
                                  }

                                  if (
                                    item.contactNo !== this.state.teams.captain
                                  ) {
                                    if (item.profilePic === null) {
                                      switch (item.gender) {
                                        case "Male":
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + maleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label className="team-buddyTyp">Member</label>
                                              </a>
                                            </li>
                                          );

                                        default:
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + femaleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label className="team-buddyTyp">Member</label>
                                              </a>
                                            </li>
                                          );
                                      }
                                    } else {
                                      return (
                                        <li key={item.name}>
                                          <a
                                            href={
                                              "/buddies?id=" + item.contactNo
                                            }
                                          >
                                            <span
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  item.profilePic +
                                                  ")",
                                              }}
                                            ></span>
                                            <label>{item.name}</label>{" "}
                                            <label className="team-buddyTyp">Member</label>
                                          </a>
                                        </li>
                                      );
                                    }
                                  } else {
                                    if (item.profilePic === null) {
                                      switch (item.gender) {
                                        case "Male":
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + maleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label>Captain</label>
                                              </a>
                                            </li>
                                          );

                                        default:
                                          return (
                                            <li key={item.name}>
                                              <a
                                                href={
                                                  "/buddies?id=" +
                                                  item.contactNo
                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundImage:
                                                      "url(" + femaleIcon + ")",
                                                  }}
                                                ></span>
                                                <label>{item.name}</label>{" "}
                                                <label>Captain</label>
                                              </a>
                                            </li>
                                          );
                                      }
                                    } else {
                                      return (
                                        <li key={item.name}>
                                          <a
                                            href={
                                              "/buddies?id=" + item.contactNo
                                            }
                                          >
                                            <span
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  item.profilePic +
                                                  ")",
                                              }}
                                            ></span>
                                            <label>{item.name}</label>{" "}
                                            <label>Captain</label>
                                          </a>
                                        </li>
                                      );
                                    }
                                  }
                                })}
                              </ul>
                            );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Modal content */}
        <div id="team_addplayers" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close showclose"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                <h4 className="modal-title">Add Players</h4>
              </div>

              <div className="modal-body">
                <div className="ct_searchbuddies">
                  <form>
                    <input
                      onChange={this.searchHandler1}
                      value={term1}
                      type="search"
                      placeholder="Search"
                    />
                    <Link to={"#"}>
                      <img src={searchnew} alt="true" />
                    </Link>
                  </form>
                </div>

                <label className="addbuddies_count">
                  You have Selected :{" "}
                  <label>{this.state.tickCount} Buddies</label>
                </label>

                <ul className="buddieslist_ul ct_buddies">
                  {allBuddies
                    .filter(searchingFor1(term1))
                    .map((item, index1) => {
                      var disableData = false;
                      this.state.users.map((item2) => {
                        if (item2.contactNo === item.contactNo) {
                          disableData = true;
                        }
                        return true;
                      });
                      let defaultChecking = false;
                      this.state.multiUser.map((mu) => {
                        if (mu === item.contactNo) {
                          defaultChecking = true;
                        }
                      });

                      if (disableData === true) {
                        if (item.profilePic === null) {
                          switch (item.gender) {
                            case "Male":
                              return (
                                <li className="deselect" key={index1}>
                                  <a>
                                    <span
                                      style={{
                                        backgroundImage:
                                          "url(" + maleIcon + ")",
                                      }}
                                    ></span>
                                    <label>{item.name}</label>
                                  </a>
                                  <div className="styled-input-single">
                                    <input
                                      disabled
                                      checked
                                      onChange={this.takeUsers.bind(this)}
                                      type="checkbox"
                                      name="fieldset-5"
                                      id={"checkBox" + index1}
                                      value={item.contactNo}
                                    />

                                    <label
                                      htmlFor={"checkBox" + index1}
                                    ></label>
                                  </div>
                                </li>
                              );

                            default:
                              return (
                                <li className="deselect" key={index1}>
                                  <a>
                                    <span
                                      style={{
                                        backgroundImage:
                                          "url(" + femaleIcon + ")",
                                      }}
                                    ></span>
                                    <label>{item.name}</label>
                                  </a>
                                  <div className="styled-input-single">
                                    <input
                                      disabled
                                      checked
                                      onChange={this.takeUsers.bind(this)}
                                      type="checkbox"
                                      name="fieldset-5"
                                      id={"checkBox" + index1}
                                      value={item.contactNo}
                                    />

                                    <label
                                      htmlFor={"checkBox" + index1}
                                    ></label>
                                  </div>
                                </li>
                              );
                          }
                        } else {
                          return (
                            <li className="deselect" key={index1}>
                              <a>
                                <span
                                  style={{
                                    backgroundImage:
                                      "url(" + item.profilePic + ")",
                                  }}
                                ></span>
                                <label>{item.name}</label>
                              </a>
                              <div className="styled-input-single">
                                <input
                                  disabled
                                  checked
                                  onChange={this.takeUsers.bind(this)}
                                  type="checkbox"
                                  name="fieldset-5"
                                  id={"checkBox" + index1}
                                  value={item.contactNo}
                                />

                                <label htmlFor={"checkBox" + index1}></label>
                              </div>
                            </li>
                          );
                        }
                      } else {
                        if (item.profilePic === null) {
                          switch (item.gender) {
                            case "Male":
                              return (
                                <li
                                  onClick={(e) =>
                                    this.liRefs[item.contactNo].click()
                                  }
                                  key={index1}
                                >
                                  <a>
                                    <span
                                      style={{
                                        backgroundImage:
                                          "url(" + maleIcon + ")",
                                      }}
                                    ></span>
                                    <label>{item.name}</label>
                                  </a>
                                  <div className="styled-input-single">
                                    <input
                                      ref={(el) =>
                                        (this.liRefs[item.contactNo] = el)
                                      }
                                      checked={defaultChecking}
                                      onChange={this.takeUsers.bind(this)}
                                      type="checkbox"
                                      name="fieldset-5"
                                      id={"checkBox" + index1}
                                      value={item.contactNo}
                                    />

                                    <label
                                      onClick={(e) =>
                                        this.liRefs[item.contactNo].click()
                                      }
                                      htmlFor={"checkBox" + index1}
                                    ></label>
                                  </div>
                                </li>
                              );

                            default:
                              return (
                                <li
                                  onClick={(e) =>
                                    this.liRefs[item.contactNo].click()
                                  }
                                  key={index1}
                                >
                                  <a>
                                    <span
                                      style={{
                                        backgroundImage:
                                          "url(" + femaleIcon + ")",
                                      }}
                                    ></span>
                                    <label>{item.name}</label>
                                  </a>
                                  <div className="styled-input-single">
                                    <input
                                      ref={(el) =>
                                        (this.liRefs[item.contactNo] = el)
                                      }
                                      checked={defaultChecking}
                                      onChange={this.takeUsers.bind(this)}
                                      type="checkbox"
                                      name="fieldset-5"
                                      id={"checkBox" + index1}
                                      value={item.contactNo}
                                    />

                                    <label
                                      onClick={(e) =>
                                        this.liRefs[item.contactNo].click()
                                      }
                                      htmlFor={"checkBox" + index1}
                                    ></label>
                                  </div>
                                </li>
                              );
                          }
                        } else {
                          return (
                            <li
                              onClick={(e) =>
                                this.liRefs[item.contactNo].click()
                              }
                              key={index1}
                            >
                              <a>
                                <span
                                  style={{
                                    backgroundImage:
                                      "url(" + item.profilePic + ")",
                                  }}
                                ></span>
                                <label>{item.name}</label>
                              </a>
                              <div className="styled-input-single">
                                <input
                                  ref={(el) =>
                                    (this.liRefs[item.contactNo] = el)
                                  }
                                  checked={defaultChecking}
                                  onChange={this.takeUsers.bind(this)}
                                  type="checkbox"
                                  name="fieldset-5"
                                  id={"checkBox" + index1}
                                  value={item.contactNo}
                                />

                                <label
                                  onClick={(e) =>
                                    this.liRefs[item.contactNo].click()
                                  }
                                  htmlFor={"checkBox" + index1}
                                ></label>
                              </div>
                            </li>
                          );
                        }
                      }
                    })}
                </ul>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => this.sendUsers()}
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal content UPLOAD AND CROP */}
        <div id="openCropModalTP" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Upload Image</h4>
              </div>

              <div className="modal-body">
                <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
              </div>

              <div className="modal-footer">
                <button
                  onClick={() => this.submitPicture()}
                  type="button"
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Crop & Upload
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal content */}
        <div id="deleteplayer1" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Delete Players</h4>
              </div>

              <div className="modal-body">
                <div className="confirmationdiv">
                  <img src={confirmation} alt="" />
                  <label>
                    Do your really want to remove {this.state.takeName} from
                    team ?
                  </label>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  onClick={() => this.deleteTeamMembers(this.state.takeContact)}
                  type="button"
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Yes Remove
                </button>
                <button
                  type="button"
                  className="cmnbutton btnclose"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal content */}
        <div id="deleteplayer2" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Delete Players</h4>
              </div>

              <div className="modal-body">
                <div className="confirmationdiv">
                  <img src={confirmation} alt="" />
                  <label>Do your really want to exit from this team ?</label>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  onClick={() => this.exitTeam()}
                  type="button"
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Yes Remove
                </button>
                <button
                  type="button"
                  className="cmnbutton btnclose"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal content */}
        <div id="selectoption2" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
                <h4 className="modal-title">Select Option</h4>
              </div>
              <div className="modal-body">
                <ul className="selectul">
                  <li>
                    <div className="styled-input-single createinput">
                      <input
                        onChange={this.theOwnership.bind(this)}
                        type="radio"
                        name="fieldset-5"
                        id="checkbox1"
                        value="ownerAndMember"
                      />
                      <label htmlFor="checkbox1">
                        Change the owner/keep me as member
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="styled-input-single createinput">
                      <input
                        onChange={this.theOwnership2.bind(this)}
                        type="radio"
                        name="fieldset-5"
                        id="checkbox2"
                        value="ownerAndRemove"
                      />
                      <label htmlFor="checkbox2">
                        Change the owner/remove me from the team
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="styled-input-single createinput">
                      <input
                        onChange={this.disableTeam.bind(this)}
                        type="radio"
                        name="fieldset-5"
                        id="checkbox3"
                        value="disableTheTeam"
                      />
                      <label htmlFor="checkbox3">Deactive the team</label>
                    </div>
                  </li>
                </ul>
                <ul
                  id="hideBuddies"
                  style={{ display: "none" }}
                  className="playerlist"
                >
                  {this.state.allBuddies.map((item, indexes) => {
                    if (item.profilePic === null) {
                      switch (item.gender) {
                        case "Male":
                          return (
                            <li key={item.name}>
                              <span
                                className="assignimg"
                                style={{
                                  backgroundImage: "url(" + maleIcon + ")",
                                }}
                              ></span>
                              <label className="ac_name">{item.name}</label>
                              <div className="styled-input-single createinput">
                                <input
                                  type="radio"
                                  name="buddieslist fieldset-5"
                                  id={"checkbox-examples" + indexes}
                                />
                                <label
                                  onClick={() =>
                                    this.becomeOwner(item.contactNo, item.name)
                                  }
                                  htmlFor={"checkbox-examples" + indexes}
                                ></label>
                              </div>
                            </li>
                          );

                        default:
                          return (
                            <li key={item.name}>
                              <span
                                className="assignimg"
                                style={{
                                  backgroundImage: "url(" + femaleIcon + ")",
                                }}
                              ></span>
                              <label className="ac_name">{item.name}</label>
                              <div className="styled-input-single createinput">
                                <input
                                  type="radio"
                                  name="buddieslist fieldset-5"
                                  id={"checkbox-examples" + indexes}
                                />
                                <label
                                  onClick={() =>
                                    this.becomeOwner(item.contactNo, item.name)
                                  }
                                  htmlFor={"checkbox-examples" + indexes}
                                ></label>
                              </div>
                            </li>
                          );
                      }
                    } else {
                      return (
                        <li key={item.name}>
                          <span
                            className="assignimg"
                            style={{
                              backgroundImage: "url(" + item.profilePic + ")",
                            }}
                          ></span>
                          <label className="ac_name">{item.name}</label>
                          <div className="styled-input-single createinput">
                            <input
                              type="radio"
                              name="buddieslist fieldset-5"
                              id={"checkbox-examples" + indexes}
                            />
                            <label
                              onClick={() =>
                                this.becomeOwner(item.contactNo, item.name)
                              }
                              htmlFor={"checkbox-examples" + indexes}
                            ></label>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.becometheOwner()}
                  type="button"
                  className="cmnbutton"
                  data-dismiss="modal"
                >
                  Done
                </button>
                <button
                  type="button"
                  className="cmnbutton btnclose"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamProfile;
