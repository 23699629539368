import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import userMaleIcon from "./img/male.png";
import likedark from "./img/likedark.png";
import locationCric from "./img/location.png";
import share from "./img/share.png";
import comment from "./img/comment.png";
import Modal1 from 'react-bootstrap/Modal';
import CommentList from "./CommentList";
import LikeList from './LikeList';
import { confirmAlert } from 'react-confirm-alert';
import userFemaleIcon from "./img/female.png";
import matchbg from "./img/matchbg.png";
import challengefill from "./img/challengefill.png";
import happiness from './img/happiness.png';
import { Link } from 'react-router-dom';
import more from "./img/more.png";
import likebox from "./img/likebox.png";
import Emoji from './Emoji';
import likegreen from "./img/likegreen.png";
import danger from './img/danger.png';
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

var dynamicFeedsid2;

class LoginFeedsCricket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            cricketdata: {},
            userData: [],
            competionTitle: '',
            teamA: {},
            teamB: {},
            venue: {},
            isShowEmoji: false,
            commentData: '',
            matchDate: '',
            reasonSelection2: '',
            commentData: '',
            feedReport: false,
            showingReportAlert2: false,
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
        //const { history } = this.props;
    }

    componentDidMount() {
        this.loadMore();
    }

    //report feed coding
    newId2 = (id) => {
        this.setState({ feedReport: true })
        dynamicFeedsid2 = id;
    }

    submitReport2 = () => {
        this.setState({ feedReport: false })
        const that = this;
        if (this.state.reasonSelection2 !== '') {
            const reports = userApi + "/newfeeds/toggle-report-feed";
            fetch(reports, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "reportedBy": that.state.user.contactNo,
                        "newFeedsId": dynamicFeedsid2,
                        "cause": that.state.reasonSelection2,
                        "reported": true,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    that.props.pReportCB(dynamicFeedsid2);
                    if (result.data === 'success') {
                        //document.getElementById('reportedCric').style = 'block';
                        that.setState({
                            showingReportAlert2: true
                        });
                        setTimeout(() => {
                            that.setState({
                                showingReportAlert2: false
                            });
                        }, 2000);
                    }
                });
        }
    }

    undoReport2 = () => {
        const reports = userApi + "/newfeeds/toggle-report-feed";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "reportedBy": this.state.user.contactNo,
                    "newFeedsId": this.props.newFeedsId,
                    "cause": this.state.reasonSelection2,
                    "reported": false,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                this.props.pReportCB(this.props.newFeedsId);

            });
    }

    //reason selection coding
    clearInputModal2 = () => {
        this.setState({ feedReport: false })
        document.getElementById('reportForm2').reset();
        this.setState({
            reasonSelection2: ''
        });
    }

    reasonSelection2 = (e) => {
        let reasonSelection2 = e.target.value;
        if (e.target.checked === true) {
            switch (reasonSelection2) {
                case 'scam':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                case 'offensive':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                case 'violence':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                case 'prohibited':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                case 'spam':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                case 'falsenews':
                    this.setState({
                        reasonSelection2: reasonSelection2
                    });

                    break

                default:
                    this.setState({
                        reasonSelection2: 'others'
                    });
            }

        }
    }

    childEmojicallback = (emojiObj) => {

        let input = this.state.commentData;
        input += emojiObj.emoji;
        this.setState({ commentData: input });


    }
    commentFunction = (postId) => {
        this.setState({ isShowEmoji: false })
        if (this.state.commentData) {
            this.state.user = JSON.parse(localStorage.getItem("result"));
            let dummyUser = {
                contactNo: this.state.user.contactNo,
                gender: this.state.user.gender,
                name: this.state.user.name,
                profilePic: this.state.user.profilePic,
            }
            this.setState({ likeListUser: [dummyUser] })
            const contactNumber = this.state.user.contactNo;
            var timestamp = Math.floor(Date.now());
            var postData = this.state.cricketdata;
            if (postData.match_id === postId) {
                if (postData.reactedBuddies === null) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "userType": null,
                        "reactedType": "comment",
                        "comment": this.state.commentData,
                        "timestamp": timestamp,
                    }];
                    //    delete postData.id;   //Always use this in update case 
                    let that = this;
                    postData.reactedBuddies = reactedBuddies;
                    fetch(userApi + "/live-cricket/add-action", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "id": postData.id,
                                "userId": contactNumber,
                                "action": "commented",
                                "comment": this.state.commentData,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            that.setState({ commentData: '', cricketdata: postData });
                            that.showAllComments(postId)
                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "userType": null,
                        "reactedType": "comment",
                        "comment": this.state.commentData,
                        "timestamp": timestamp,
                    };

                    delete postData.id; //Always use this in update case 
                    let that = this;
                    postData.reactedBuddies.push(reactedBuddies);
                    fetch(userApi + "/live-cricket/add-action", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "id": postData.id,
                                "userId": contactNumber,
                                "action": "commented",
                                "comment": this.state.commentData,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            that.setState({ commentData: '' });
                            that.showAllComments(postId)
                        });
                }
            }

            this.setState({ cricketdata: postData });
        }
        // this.loadComponent();
    }
    likeFunction = (postId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        // this.setState({ user: JSON.parse(localStorage.getItem("result")) });
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        // var postAll = [];
        var postData = this.state.cricketdata;

        if (postData.match_id === postId) {
            if (postData.reactedBuddies === null) {
                var reactedBuddies = [{
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                }];
                //   delete postData.id;   //Always use this in update case 
                postData.reactedBuddies = reactedBuddies;
                fetch(userApi + "/live-cricket/add-action", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "id": postData.id,
                            "userId": contactNumber,
                            "action": "reacted",
                            "comment": null,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {


                    });
            }
            else {
                reactedBuddies = {
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                };

                // delete postData.id; //Always use this in update case 
                //   postData.reactedBuddies = reactedBuddies;
                postData.reactedBuddies = reactedBuddies;
                //  let that = this;
                fetch(userApi + "/live-cricket/add-action", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "id": postData.id,
                            "userId": contactNumber,
                            "action": "reacted",
                            "comment": null,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                        // that.loadMore();
                    });
            }
        }
        // return true;   
        this.setState({ cricketdata: postData });
        // this.loadComponent();
    }
    showAllComments = (postId) => {
        let reactedBuddies = [];

        if (this.state.cricketdata.match_id === postId) {
            reactedBuddies = this.state.cricketdata.reactedBuddies;
        }
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (postId) => {
        let reactedBuddies = [];

        if (this.state.cricketdata.match_id === postId) {
            reactedBuddies = this.state.cricketdata.reactedBuddies;
        }


        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }

        confirmAlert({
            customUI: () => {
                return (
                    <div>
                        <LikeList data={data} />
                    </div>
                );
            }
        })
    }
    feedReportHandel = () => {
        this.setState({ feedReport: false })
    }
    loadMore = () => {
        let that = this;
        var apiUrl = userApi + '/live-cricket/get-match';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    id: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {

            var timpFull = result.data.match.timestamp_start * 1000;

            that.setState({ cricketdata: result.data.match, competionTitle: result.data.match.competition.title, teamA: result.data.match.teama, teamB: result.data.match.teamb, venue: result.data.match.venue, userData: result.data.users, timpFull: timpFull });
        });
    }
    render() {
        var userIcon = false;
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(this.state.timpFull);


        var reportValue = this.props.reported;
        if (reportValue === false) {
            return <React.Fragment>

                {/* Start Report Abuse */}
                <Modal1
                    size="md"
                    show={this.state.feedReport}
                    onHide={() => this.feedReportHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    {/* <div id="reportabusePost" className="modal fade" role="dialog"> */}
                    {/* <div className="modal-dialog"> */}
                    {/* Modal content Report Feeds */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={() => this.clearInputModal2()} type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Report this feeds</h4>
                        </div>
                        <div className="modal-body">
                            <label className="reportlabel">Take a moment and tell us what's wrong with this post</label>
                            <form id="reportForm">
                                <ul className="reportul">
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost1" value="scam" />
                                            <label htmlFor="resportPost1">Scam</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost2" value="offensive" />
                                            <label htmlFor="resportPost2">Offensive </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost3" value="violence" />
                                            <label htmlFor="resportPost3">Violence </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost4" value="others" />
                                            <label htmlFor="resportPost4">Others</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost5" value="spam" />
                                            <label htmlFor="resportPost5">Spam</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost6" value="falsenews" />
                                            <label htmlFor="resportPost6">False-news</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection2.bind(this)} type="radio" name="fieldset-1" id="resportPost7" value="prohibited" />
                                            <label htmlFor="resportPost7">Prohibited </label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <p id="reportedPost" style={{ display: 'none' }} className={`alert alert-success ${this.state.showingReportAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                <strong>Feed Reported!</strong>
                            </p>
                            <button onClick={() => this.submitReport2()} type="button" className="cmnbutton">Submit</button>
                            <button onClick={() => this.clearInputModal2()} type="button" className="btndark cmnbutton" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    {/* End Report Abuse */}
                </Modal1>
                {/* End Report Abuse */}

                {this.state.userData.map((userData) => {
                    var buttonDisable = false;
                    var likeCount = 0; var commentCount = 0; var buddyName = null;
                    if (this.state.cricketdata.reactedBuddies !== null) {
                        this.state.cricketdata.reactedBuddies.map(z => {
                            if (z.reactedType === "cheer") {
                                if (z.buddyId === this.state.user.contactNo) {
                                    buttonDisable = true;
                                }
                                this.state.userData.map((buddy) => {
                                    if (buddy.contactNo === z.buddyId) {
                                        buddyName = buddy.name;
                                    }
                                    return buddyName;
                                })
                                likeCount++;
                            }
                            if (z.reactedType === "comment") {
                                commentCount++;
                            }
                            return likeCount;
                        });
                        if (buddyName === null) {
                            this.state.cricketdata.reactedBuddies.map((z) => {
                                this.state.userData.map((user) => {
                                    if (user.contactNo === z.buddyId) {
                                        buddyName = user.name;

                                    }
                                    return buddyName;
                                })
                                return true;
                            })
                        }
                    }
                    if (userData.contactNo === this.state.cricketdata.createdBy) {
                        if (userData.profilePic === null) {
                            if (userData.gender === "Male") {
                                userIcon = userMaleIcon;
                            }
                            else {
                                userIcon = userFemaleIcon;
                            }
                        }
                        else {
                            userIcon = userData.profilePic;
                        }

                        {/* checking more button on logged in user */ }
                        if (this.state.user.contactNo !== this.props.sentBy) {
                            this.moreButton = <button className="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" /></button>;
                        }
                        return <div className="divpost sharepostdiv" id="accordion" key={this.state.cricketdata.match_id}>
                            <div className="userdetail" key={this.state.cricketdata.match_id}>
                                <ul className="postuserul" >
                                    <li className="p_userli">
                                        <span className="p_userimg" style={{
                                            backgroundImage: "url(" + userIcon + ")"
                                        }}> </span>
                                        {/* <label>{this.state.cricketdata.name}</label> */}
                                        <label>{userData.name}</label>
                                        <span className="p_usertime">{formattedTimestamp}</span><span className="p_usertime"></span><span className="p_usertime">{this.state.cricketdata.location}</span></li>
                                    <li className="p_usermore">
                                        <div className="dropdown dropaccount">
                                            {this.moreButton}
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <Link onClick={(e) => this.newId2(this.props.newFeedsId)} data-toggle="modal" data-target="#reportabuse2"> Report Abuse </Link>
                                                    {/*onClick={(e) => this.newId2(this.props.newFeedsId)}*/}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="divpost livematch_feed" style={{
                                backgroundImage: "url(" + matchbg + ")"
                            }}>
                                <Link to={"/ScoreCard/" + this.state.cricketdata.match_id + ""} className="livescoretag" > Live Score</Link>
                                <label className="livematch_name">{this.state.competionTitle} <span>{this.state.cricketdata.format_str}</span></label>
                                <ul className="livematch_ul" >
                                    <li>
                                        <span style={{
                                            backgroundImage: "url(" + this.state.teamA.logo_url + ")"
                                        }
                                        }></span>
                                        <label>{this.state.teamA.name}</label>
                                        <label className="teamscore_live">{this.state.teamA.scores_full}</label>
                                    </li>
                                    <li><img src={challengefill} alt="" /></li>
                                    <li>
                                        <span style={{
                                            backgroundImage: "url(" + this.state.teamB.logo_url + ")"
                                        }
                                        }></span>
                                        <label>{this.state.teamB.name}</label>
                                        <label className="teamscore_live">{this.state.teamB.scores_full}</label>
                                    </li>
                                </ul>
                                <label className="livematch_date">{formattedTimestamp}</label>
                                <p className="matchdesc">{this.state.cricketdata.status_note}</p>
                                <p className="matchlocation"><img src={locationCric} alt="" />{this.state.venue.name}</p>
                            </div>

                            <div className="postlikediv">
                                {(() => {
                                    switch (likeCount) {
                                        case 1:
                                            switch (commentCount) {
                                                case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span >   liked this </span>
                                                </span>;
                                                default: return <span className="userlikeinner"> <span ><img src={likebox} alt="" /> <b>{buddyName}</b> liked this</span><span key={this.state.cricketdata.match_id} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.cricketdata.match_id)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.cricketdata.match_id} > {commentCount} comments</span>
                                                </span>
                                            }
                                        case 0:
                                            switch (commentCount) {
                                                case 0: return '';
                                                default: return <span className="userlikeinner"><span key={this.state.cricketdata.match_id} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.cricketdata.match_id)}> {commentCount} comments</span>
                                                </span>;
                                            }
                                        default:
                                            switch (commentCount) {
                                                case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.cricketdata.match_id)}> and {likeCount - 1}more liked this </span>
                                                </span>;
                                                default: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.cricketdata.match_id)}> and {likeCount - 1}more liked this</span><span key={this.state.cricketdata.match_id} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.cricketdata.match_id)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.cricketdata.match_id}> {commentCount} comments</span>
                                                </span>
                                            }
                                    }
                                })()}
                                <ul className="commentul">
                                    {buttonDisable ? <li className="commentli commentinner">
                                        <img src={likegreen} className="likeuser" alt="" />
                                        <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount}</span></span>
                                    </li> : <li className="commentli commentinner" onClick={(e) => this.likeFunction(this.state.cricketdata.match_id)}>
                                        <img src={likedark} className="likeuser" alt="" />
                                        <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount} </span></span>
                                    </li>}
                                    <li key={this.state.cricketdata.match_id} className="commentinner commentinner2" id="comment1">
                                        <img src={comment} className="commentimg" alt="" />
                                        <span data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.cricketdata.match_id} onClick={(e) => this.setState({ commentList: false })}>Comment<span className=" spancount">{commentCount}</span></span> </li>
                                    <li className="commentinner commentinner3" id="" >
                                        <Link to="#" className="" data-toggle="modal" data-target="#myModal"><img src={share} className="commentimg" alt="" />
                                            <span>Share<span className="spancount"></span></span></Link> </li>
                                </ul>
                                <div className="postcomment" id={this.state.cricketdata.match_id}> <span className="" style={{
                                    backgroundImage: "url(" + userMaleIcon + ")"
                                }}></span>
                                    {this.state.commentList ? <CommentList data={this.state.data} /> : <div></div>}
                                    <div key={this.state.cricketdata.match_id} className="postcomment_div" style={{ marginTop: '10px' }}><Link to="#"><img onClick={() => this.setState({ isShowEmoji: !this.state.isShowEmoji })} src={happiness} alt="" /></Link>
                                        <textarea id={this.state.cricketdata.match_id} value={this.state.commentData} onChange={event => this.setState({ commentData: event.target.value })}></textarea>
                                        <button onClick={(e) => this.commentFunction(this.state.cricketdata.match_id)}>Post</button>
                                    </div>
                                    {this.state.isShowEmoji ? <Emoji parentEmojiCallback={this.childEmojicallback} /> : ''}
                                </div>
                            </div>
                        </div>;
                    }
                    return true;
                })}
            </React.Fragment>
        }
        else {
            return <React.Fragment>
                <div className="divpost">
                    <ul className="reportfeed">
                        <li className="reportli1"><img src={danger} /></li>
                        <li className="reportli2">
                            <label>Feeds Reported</label>
                            <p>We would not show you this post again</p>
                        </li>
                        <li className="reportli3"><button onClick={() => this.undoReport2()}>Undo</button></li>
                    </ul>
                </div>
            </React.Fragment>
        }
    }
}

export default LoginFeedsCricket;