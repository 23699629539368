import React, { Component } from 'react';
import logoshield from './img/logoshield-d.png';
import loginbg from './img/loginbg-d.jpg';
import { isAndroid, isIOS } from 'react-device-detect';
import { Link } from "react-router-dom";

// import { BrowserHistory } from 'react-history';
class DownloadAppPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    openApp() {

        if (isAndroid) {
            window.open("https://play.google.com/store/apps/details?id=com.sportsapp.ssp");
        }
        else if (isIOS) {
            window.open("https://apps.apple.com/app/id1437392923");
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.sportsapp.ssp");
        }


    }
    backButton() {
        window.history.back();
    }
    render() {
        return (
            <div>

                <section>
                    <div className="container-fluid  padzero-d">

                        <div className="loginbg-d" style={{ backgroundImage: "url(" + loginbg + ")" }}>
                            <div className="center-d">
                                <Link to={"#"} className="loginlogo_text-d"><img src={logoshield} /></Link>

                                <div className="logintext-d">
                                    <h1>DOWNLOAD APPLICATION </h1>
                                    <p>These features currently available on Android and IOS App. To use the full features Please download the app now.</p>
                                    <div className="downloaddiv-d">
                                        <button onClick={() => this.openApp()} className="download-d" type="button">Download App</button>
                                        <button onClick={() => this.backButton()} className="backbtn-d" type="button" >Back</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
            </div>

        );
    }
}

export default DownloadAppPage;
