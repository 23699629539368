import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import play from '../img/play.png';
import $ from 'jquery';
var d104ScoreCard = {}, score;
var numberOfAttempt;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var maxWeights = [];
var cricketApi = require("../cricketApi.js");

export default class Tournament_d104_view_TT4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD104State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
    }


    componentDidMount() {


        let d104ScoreCard = [];
        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {
                if (this.props.clickedIndex === index) {
                    if (tt4.attempts >= 0) {

                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d104ScoreCard) {
                                d104ScoreCard.push({ userId: ml.playerId, points: ml.d104ScoreCard.points })

                            }
                            else {
                                d104ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                }
            })
        }
        this.setState({ scoreCardD104State: d104ScoreCard })
        console.log(this.state.scoreCardD104State)

    }
    render() {
        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.tt4.tournamentId;


        return (
            <React.Fragment>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul className="winnerdropdown_ul">
                            {this.state.scoreCardD104State.map((accepted, index) => {
                                var name, contactNo, point, pointColor;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;
                                        point = cricketApi.msToTime(point);
                                    }
                                    if (accepted.disqualified === true) {
                                        pointColor = { color: 'red' };
                                        point = 'disqualify';
                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" readOnly style={pointColor} value={point} />
                                </li>
                            })}
                        </ul> : <div className="broadcast_video">
                            <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                            <h3>Match has not started yet.</h3>
                        </div>
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

