import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import finallogo from '../img/finallogo.png';
import NumericInput from 'react-numeric-input';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import male from "../img/male.png";
import teamIcons from '../img/team.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import * as myUtilclass from '../FixtureUtil';
import * as myTournamentUtilclass from '../DemoTournament/DTournamentsUtil';
let byPic, toPic;
var score;
let pairObj;
var selectedWinners = undefined;
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};
export default class DTournament_archery_tt1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSets: 5,
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107ArcheryScoreCard: {},
            score: false,
            localType: '',
            localSet: '',
            currentArchaeryPointBy: 0,
            currentArchaeryPointTo: 0,
            byPoints: [],
            toPoints: [],
            selectWinnerModal: false,
            byWinner: false,
            toWinner: false,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);
        this.done = this.done.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false, setModalIsOpen: false });
    }
    done(event) {
        this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var archaryPoint = parseInt(document.getElementsByName("point")[0].value);
        var roundData = this.state.rounds;
        roundData.map(round => {
            if (round.position === parseInt(this.state.localSet)) {
                if (this.state.localType === 'by') {
                    round.challengedByPoints = archaryPoint;
                    this.setState({ currentArchaeryPointBy: archaryPoint })
                }
                else if (this.state.localType === 'to') {
                    round.challengedToPoints = archaryPoint;
                    this.setState({ currentArchaeryPointTo: archaryPoint })
                }
            }
        })
        this.setState({ rounds: roundData })
    }




    doneMatch() {

        var winner = this.state.winner;

        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winner !== undefined) {
            winner = this.state.winner;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107ArcheryScoreCard: this.state.d107ArcheryScoreCard,
        }

        DUpdateTournamentsScore(score);



        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }

        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }




    changeDataa(event) {

        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            if (selectedValue === 'challengedBy') {
                selectedWinners = this.state.byName;
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
                d107ArcheryScoreCard.toArcheryScoreCard.winner = false;
                this.setState({ byWinner: true, toWinner: false, winner: pairObj.firstId })
            }
            else {
                selectedWinners = this.state.toName;
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
                d107ArcheryScoreCard.byArcheryScoreCard.winner = false;
                this.setState({ toWinner: true, byWinner: false, winner: pairObj.secondId })
            }

        }

    }









    backState = () => {
        // $("#setAttemptModal .close").click();
        window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }

    saveIntialSet = () => {
        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }

        this.setState({ matchPairObj: pairObj });
        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'teams') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }
        this.setState({ byName: byName, toName: toName, score: false });


        if (this.state.numberOfSets !== null) {
            var i = 0;
            var numberOfSets = parseInt(this.state.numberOfSets);
            var round = [];
            var header = {
                position: 0,
                challengedById: null,
                challengedByPoints: 0,
                challengedToId: null,
                challengedToPoints: 0,
                saved: false,
                tableViewType: 0,
                toUndoList: null,
                winnerId: null,
            }
            round.push(header);
            for (i = 1; i <= (numberOfSets); i++) {
                var roundData = {
                    position: i,
                    challengedById: pairObj.firstId,
                    challengedByPoints: 0,
                    challengedToId: pairObj.secondId,
                    challengedToPoints: 0,
                    saved: false,
                    tableViewType: 1,
                    toUndoList: null,
                    winnerId: null,
                }

                round.push(roundData);
            }
            var footer = {
                position: 6,
                challengedById: null,
                challengedByPoints: 0,
                challengedToId: null,
                challengedToPoints: 0,
                saved: false,
                tableViewType: 2,
                toUndoList: null,
                winnerId: null,
            }
            round.push(footer);
            var rounds = round;
            const that = this;
            var d107ScoreCard = {
                noOfSets: numberOfSets,

                setNo: 1,
                strokeNo: 0,
                byArcheryScoreCard: {
                    id: pairObj.firstId,
                    points: null,
                    winner: false
                },
                toArcheryScoreCard: {
                    id: pairObj.secondId,
                    points: null,
                    winner: false
                },
                rounds
            }
            score = {

                tournamentId: that.props.tournament.tournamentId,
                matchId: that.props.matchId,
                d107ArcheryScoreCard: d107ScoreCard,
            }

            DUpdateTournamentsScore(score);
            that.setState({ rounds: rounds, d107ArcheryScoreCard: d107ScoreCard });
        }
        else {
            this.setState({ setError: true });
        }
        // this.getScore();


    }

    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }

    endEvent() {
    }

    getScore() {
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        that.saveIntialSet();
                    }
                    else {
                        if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                            that.setState({ byPoints: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points })
                        }
                        if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                            that.setState({ toPoints: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points })
                        }
                        that.setState({ d107ArcheryScoreCard: result.data.score.d107ArcheryScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107ArcheryScoreCard.rounds, numberOfSets: result.data.score.d107ArcheryScoreCard.noOfSets, setNo: result.data.score.d107ArcheryScoreCard.setNo, strokeNo: result.data.score.d107ArcheryScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id === user.contactNo) {
                                    byName = user.name
                                }
                                if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id === user.contactNo) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            result.data.teams.map(user => {
                                if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id === user.teamId) {
                                    byName = user.name
                                }
                                if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id === user.teamId) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                    }
                });
        }
    }




    makePoint() {

        var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
        var setNo = d107ArcheryScoreCard.setNo;
        let okDetector = false;
        d107ArcheryScoreCard.rounds.map(round => {
            if (round.position === setNo) {
                if (round.challengedByPoints === 0 || round.challengedToPoints === 0) {
                    okDetector = false;
                }
                else {
                    okDetector = true;
                }
            }
        })

        if (!okDetector) {
            this.setState({ showfillcheckerError: true })
        }
        else {
            var toAdd = this.state.d107ArcheryScoreCard.rounds.filter(round => {
                return round.position === d107ArcheryScoreCard.setNo
            })
            var toRemove = [...toAdd];

            var stroke = d107ArcheryScoreCard.strokeNo;
            var undoList = [{
                points: 0,
                strokeNo: stroke += 1,
                type: "challengedBy",
            }, {
                points: 0,
                strokeNo: stroke += 1,
                type: "challengedTo"
            }];


            d107ArcheryScoreCard.strokeNo = stroke;

            if (this.state.currentArchaeryPointBy > this.state.currentArchaeryPointTo) {
                let point = 2;
                undoList[0].points = 2;

                if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.byArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.byArcheryScoreCard.points = [point];
                }
                if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.toArcheryScoreCard.points.push(0);
                }
                else {
                    d107ArcheryScoreCard.toArcheryScoreCard.points = [0];
                }
            }
            else if (this.state.currentArchaeryPointTo > this.state.currentArchaeryPointBy) {
                let point = 2;

                undoList[1].points = 2;
                if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.toArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.toArcheryScoreCard.points = [point];
                }
                if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.byArcheryScoreCard.points.push(0);
                }
                else {
                    d107ArcheryScoreCard.byArcheryScoreCard.points = [0];
                }
            }
            else {
                let point = 1;
                undoList[0].points = 1;
                undoList[1].points = 1;
                if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.byArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.byArcheryScoreCard.points = [point];
                }
                if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.toArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.toArcheryScoreCard.points = [point];
                }
            }
            d107ArcheryScoreCard.rounds.map(round => {
                if (round.position === d107ArcheryScoreCard.setNo) {
                    round.toUndoList = undoList;

                }
            })
            if (setNo < 5) {
                d107ArcheryScoreCard.setNo = setNo + 1;
            }
            if (setNo === 5) {
                let ByPointSum = d107ArcheryScoreCard.byArcheryScoreCard.points.reduce((a, b) => a + b);
                let ToPointSum = d107ArcheryScoreCard.toArcheryScoreCard.points.reduce((a, b) => a + b);

                if (ByPointSum > ToPointSum) {
                    this.setState({ winnerModalOpen: true, byWinner: true, winner: d107ArcheryScoreCard.byArcheryScoreCard.id });
                    var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                    d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
                }
                if (ToPointSum > ByPointSum) {
                    this.setState({ winnerModalOpen: true, toWinner: true, winner: d107ArcheryScoreCard.toArcheryScoreCard.id });
                    var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                    d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
                }
                if (ToPointSum === ByPointSum) {
                    var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                    d107ArcheryScoreCard.byArcheryScoreCard.winner = false;
                    var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                    d107ArcheryScoreCard.toArcheryScoreCard.winner = false;
                    this.setState({ selectWinnerModal: true })
                }
            }
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107ArcheryScoreCard: d107ArcheryScoreCard,
            }
            DUpdateTournamentsScore(score);
            this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });
            this.setState({ byPoints: d107ArcheryScoreCard.byArcheryScoreCard.points });
            this.setState({ toPoints: d107ArcheryScoreCard.toArcheryScoreCard.points })
            // setTimeout(() => { this.getScore() }, 1000);
            // console.log(d107ArcheryScoreCard)
        }
    }



    tirBreakerUndo = () => {

        this.setState({ selectWinnerModal: false });
        var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;

        var toAdd = this.state.d107ArcheryScoreCard.rounds.filter(round => {
            return round.position === d107ArcheryScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107ArcheryScoreCard.strokeNo;
        var toUndo;
        var toUndoList = [];
        if (toAdd[0].toUndoList !== null) {
            toUndoList = toAdd[0].toUndoList;
        }
        toUndoList.map(list => {
            if (list.strokeNo === stroke) {

                toUndo = list;
            }
        })
        toUndoList = toUndoList.filter((x) => x != toUndo)
        toAdd[0].toUndoList = toUndoList;

        if (toAdd[0].toUndoList.length === 0) {
            if (d107ArcheryScoreCard.setNo > 1) {
                d107ArcheryScoreCard.setNo -= 1;
                d107ArcheryScoreCard.setNo = d107ArcheryScoreCard.setNo;
            }
        }
        let setWonsBy = d107ArcheryScoreCard.byArcheryScoreCard.points;
        if (setWonsBy.length > 0) {

            if (setWonsBy.length == 0) {
                setWonsBy = null;
                d107ArcheryScoreCard.byArcheryScoreCard.points = setWonsBy;
            } else {
                d107ArcheryScoreCard.byArcheryScoreCard.points.pop();
            }

        }
        let setWonsTo = d107ArcheryScoreCard.toArcheryScoreCard.points;
        if (setWonsTo.length > 0) {
            if (setWonsTo.length == 0) {
                setWonsTo = null;
                d107ArcheryScoreCard.toArcheryScoreCard.points = setWonsTo;
            } else {
                d107ArcheryScoreCard.toArcheryScoreCard.points.pop();
            }
        }

        d107ArcheryScoreCard.strokeNo = stroke;
        var score = {



            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107ArcheryScoreCard: d107ArcheryScoreCard,
        }
        console.log(d107ArcheryScoreCard);
    }


    ToundoList = () => {
        console.log(this.state.d107ArcheryScoreCard);
        this.setState({ winnerModalOpen: false });
        var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
        var toAdd = this.state.d107ArcheryScoreCard.rounds.filter(round => {
            return round.position === d107ArcheryScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107ArcheryScoreCard.strokeNo;
        var TypeMode;
        var toUndo;
        var toUndoList = [];
        if (toAdd[0].toUndoList !== null) {
            toUndoList = toAdd[0].toUndoList;
        }
        toUndoList.map(list => {
            if (list.strokeNo === stroke) {
                TypeMode = list.type;
                toUndo = list;
            }
        })

        toUndoList = toUndoList.filter((x) => x != toUndo)
        toAdd[0].toUndoList = toUndoList;


        if (TypeMode === undefined) {


            let roundData = this.state.rounds;
            roundData.map(round => {
                if (round.position === parseInt(d107ArcheryScoreCard.setNo)) {
                    round.challengedByPoints = 0;
                    round.challengedToPoints = 0;

                }
            })


            if (toAdd[0].toUndoList.length === 0) {

                if (d107ArcheryScoreCard.setNo > 1) {
                    d107ArcheryScoreCard.setNo -= 1;
                    d107ArcheryScoreCard.setNo = d107ArcheryScoreCard.setNo;
                }
                let roundData = this.state.rounds;

                roundData.map(round => {
                    if (round.position === parseInt(d107ArcheryScoreCard.setNo)) {
                        this.setState({ currentArchaeryPointBy: round.challengedByPoints ? round.challengedByPoints : 0 });
                        this.setState({ currentArchaeryPointTo: round.challengedToPoints ? round.challengedToPoints : 0 });

                    }
                })
                this.setState({ rounds: roundData })

            }

            let setWonsBy = d107ArcheryScoreCard.byArcheryScoreCard.points;
            if (setWonsBy !== null) {
                if (setWonsBy.length > 0) {

                    if (setWonsBy.length == 0) {
                        setWonsBy = null;
                        d107ArcheryScoreCard.byArcheryScoreCard.points = setWonsBy;
                    } else {
                        d107ArcheryScoreCard.byArcheryScoreCard.points.pop();
                    }

                }
            }



            let setWonsTo = d107ArcheryScoreCard.toArcheryScoreCard.points;
            if (setWonsTo !== null) {
                if (setWonsTo.length > 0) {

                    if (setWonsTo.length == 0) {
                        setWonsTo = null;
                        d107ArcheryScoreCard.toArcheryScoreCard.points = setWonsTo;
                    } else {
                        d107ArcheryScoreCard.toArcheryScoreCard.points.pop();
                    }
                }
            }

        }
        else if (TypeMode === "challengedBy") {
            if (toAdd[0].challengedByPoints > 0) {
                toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;

                toAdd[0].challengedByPoints = 0;

                stroke -= 1;

            }
        }
        else if (TypeMode === "challengedTo") {
            if (toAdd[0].challengedToPoints > 0) {
                toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;

                toAdd[0].challengedToPoints = 0;
                stroke -= 1;

            }
        }

        d107ArcheryScoreCard.strokeNo = stroke;
        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107ArcheryScoreCard: d107ArcheryScoreCard,
        }
        DUpdateTournamentsScore(score);
        // this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });

        console.log(d107ArcheryScoreCard);
        // setTimeout(() => { this.getScore() }, 1000);
    }



    editWinner = () => {

        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {

        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }

        this.setState({ matchPairObj: pairObj });
        let byName, toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();

    }


    openFunc = (type, localset) => {
        this.setState({ setModalIsOpen: true, localType: type, localSet: localset });
        // this.setState({ row: index, setIndex: ind });
        // this.state.scoreCardD101State.map((x, index) => {
        //     if (x.points === null) {
        //         if (ind === 0) {

        //             this.setState({ setModalIsOpen: true });
        //         }
        //     }
        //     if (x.points !== null) {
        //         if ((x.points.length + 1) > ind) {

        //             this.setState({ setModalIsOpen: true });
        //         }
        //     }
        // });
    }
    render() {
        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSets); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.showfillcheckerError}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Please Fill input value!</h3></div>


                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ showfillcheckerError: false })} >Close</button></div>
                </Modal>



                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Scores Are Same please choose the winner!</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-1" onClick={this.changeDataa.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-2" onClick={this.changeDataa.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toName}</label>
                        </li>
                    </ul>
                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.doneMatch}>Done</button><button className="btn btn-inverse" onClick={this.doneMatch}>Done</button><button className="btn btn-inverse" onClick={() => this.setState({ selectWinnerModal: false })} >Close</button></div>
                </Modal>


                <Modal isOpen={this.state.setModalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">  <div ref={subtitle => this.subtitle = subtitle}>
                        <div ref={subtitle => this.subtitle = subtitle}> <h3> Archery Point picker</h3></div>
                        <NumericInput mobile min={0}
                            max={30}
                            name="point"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Point"
                            required
                            size={25}
                            value={15}
                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }}
                        /> <br></br>

                    </div>
                    <br></br>
                    <div className="button_div" >
                        {/* <button className="btn btn-danger" type="button" onClick={this.disqualify} style={{ 'margin': '2px' }}>Disqualify</button> */}
                        <button className="btn btn-inverse" onClick={this.closeModal} style={{ 'margin': '2px' }}>Cancel</button>
                        <button className="cmnbutton" type="button" onClick={this.done} style={{ 'margin': '2px' }}>Done</button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.byPoints.length ? this.state.byPoints.reduce((a, b) => a + b) : 0} </p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toPoints.length ? this.state.toPoints.reduce((a, b) => a + b) : 0} </p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <div data-backdrop="static" id="setWon" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Confirmation !</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} id="setWonClick" type="button" data-toggle="modal" data-target="#setWon"></button>

                <button style={{ display: 'none' }} id="openDp4" type="button" data-toggle="modal" data-target="#winningScoreModal"></button>
                <div data-backdrop="static" id="winningScoreModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Set Winning Score</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="11set" onClick={this.changeData.bind(this)} value="11" />
                                            <label htmlFor="11set">11 Sets</label>
                                        </div>
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="21set" onClick={this.changeData.bind(this)} value="21" />
                                            <label htmlFor="21set">21 sets</label>
                                        </div>
                                        {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                            <span >please Select Set</span>
                                        </div> : ''}
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveSet()}>Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Challange Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>
                                <ul class="resultul">
                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <span class="resuserimg" style={{ backgroundImage: "url(" + finallogo + ")" }}></span>
                                            <div class="resinfo">
                                                <label> </label>
                                                <small>Score: </small>
                                            </div>

                                            <div className="styled-input-single" style={{ 'position': 'relative' }}>

                                                <input type="radio" name="selectWinner" id="" defaultChecked={this.state.checked} />
                                                <label htmlFor=""></label>
                                            </div>
                                        </a>
                                    </li>
                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                <a data-dismiss="modal" class="cmnbutton editbtn" onClick={() => this.ToUndoList()} >Edit</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />

                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points = null;

                                                let EnableInput = true;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    EnableInput = false;
                                                }
                                                let localSetNo = index + 1;



                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints;
                                                    }
                                                })

                                                return <td key={index}><input value={points} disabled={EnableInput} class="inputsize-d" onClick={() => this.openFunc('by', localSetNo)} /></td>
                                            })}

                                            <td><input value={this.state.byPoints.length ? this.state.byPoints.reduce((a, b) => a + b) : 0} class="inputsize-d" /></td>
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let localSetNo = index + 1;
                                                let EnableInput = true;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    EnableInput = false;
                                                }
                                                this.state.rounds.map((round, inde) => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input disabled={EnableInput} onClick={() => this.openFunc("to", localSetNo)} value={points} class="inputsize-d" /></td>
                                            })}
                                            <td><input value={this.state.toPoints.length ? this.state.toPoints.reduce((a, b) => a + b) : 0} class="inputsize-d" /></td>
                                        </tr>
                                        <tr>
                                            <td> Action </td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    showAddButton = true;
                                                }

                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })
                                                return <td>
                                                    {showAddButton ? <button onClick={() => this.makePoint()} type="button" class="addpointtennis">OK</button> : <button style={{ backgroundColor: 'grey' }} disable="true" type="button" class="addpointtennis">OK</button>}

                                                </td>
                                            })}
                                            <td></td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}


