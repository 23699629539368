import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./Header";
import SectionLeft from "./SectionLeft";
import SectionRight from "./SectionRight";
import more from "./img/more.png";
import sad from "./img/sad.png";
import notification from "./img/notification.png";
import buddiesFillIcon from "./img/buddiesfill.png";
import teamFillIcon from "./img/teamfill.png";
import challengeFillIcon from "./img/challengefill.png";
import fixtureFillIcon from "./img/fixturefill.png";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from "./ConfigUrl";
const ConfigApiUrl = ConfigUrl("apiUrl");
const rshApi = ConfigApiUrl;

const actionStatusRejected = {
  status: false,
  text: "Rejected",
};

const actionStatusAccepted = {
  status: true,
  text: "Accepted",
};

class AllNotificationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notiCounts: "",
      showNotification: [],
      usersDetails: [],
      indexUpdate: 0,
      indexPerUpdate: 10,
      accept: "Accept",
      reject: "Reject",
    };
    this.state.user = JSON.parse(localStorage.getItem("result"));
  }

  componentDidMount() {
    this.showNoti();
    this.notiCount();
    // this.indexUpdate();
  }

  // notification count API
  notiCount = () => {
    const getNotiCounts = rshApi + "/notify/unread-counts";
    const that = this;
    fetch(getNotiCounts, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          contactNo: this.state.user.contactNo, //current logged-in user
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        that.setState({
          notiCounts: result.data,
        });
      });
  };

  // notification read API (read/not-read)
  nowRead = (gotNoti) => {
    const markedRead = rshApi + "/notify/mark-as-read";
    const that = this;
    fetch(markedRead, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          contactNo: this.state.user.contactNo, //current logged-in user
          notificationId: gotNoti,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function () {
        that.setState({
          notiCounts: that.state.notiCounts - 1,
        });
      });

    setTimeout(() => this.showNoti(), 500);
  };

  // accept and reject API
  acceptrejectLink = (takeTHEid, takeModuleName, takeAcceptReject) => {
    if (takeAcceptReject === "Accepted") {
      if (takeModuleName === "tournaments") {
        var newNotification = [...this.state.showNotification];
        newNotification.map((notification) => {
          if (notification.notificationId === takeTHEid) {
            notification.actionableStatus = actionStatusAccepted;
          }
        });
        let that = this;
        const acceptLinks = rshApi + "/notify/react-on-tournament-invitation";
        fetch(acceptLinks, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              notificationId: takeTHEid,
              type: takeAcceptReject,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              showNotification: newNotification,
            });
          });
      }

      if (takeModuleName === "teams") {
        var newNotification = [...this.state.showNotification];
        newNotification.map((notification) => {
          if (notification.notificationId === takeTHEid) {
            notification.actionableStatus = actionStatusAccepted;
          }
        });

        let that = this;
        const acceptLinks = rshApi + "/teams/react-on-ownership";
        fetch(acceptLinks, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              notificationId: takeTHEid,
              action: takeAcceptReject,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              showNotification: newNotification,
            });
          });
      }
    }

    if (takeAcceptReject === "Rejected") {
      if (takeModuleName === "tournaments") {
        var newNotification = [...this.state.showNotification];
        newNotification.map((notification) => {
          if (notification.notificationId === takeTHEid) {
            notification.actionableStatus = actionStatusRejected;
          }
        });

        let that = this;
        const acceptLinks = rshApi + "/notify/react-on-tournament-invitation";
        fetch(acceptLinks, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              notificationId: takeTHEid,
              type: takeAcceptReject,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              showNotification: newNotification,
            });
          });
      }

      if (takeModuleName === "teams") {
        var newNotification = [...this.state.showNotification];
        newNotification.map((notification) => {
          if (notification.notificationId === takeTHEid) {
            notification.actionableStatus = actionStatusRejected;
          }
        });

        let that = this;
        const acceptLinks = rshApi + "/teams/react-on-ownership";
        fetch(acceptLinks, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              notificationId: takeTHEid,
              action: takeAcceptReject,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              showNotification: newNotification,
            });
          });
      }
    }
  };

  // notification delete API
  nowDelete = (gotDelete) => {
    const notiDeleted = rshApi + "/notify/delete-notification";
    fetch(notiDeleted, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          contactNo: this.state.user.contactNo, //current logged-in user
          notificationId: gotDelete,
        },
      }),
    });
    setTimeout(() => this.showNoti(), 500);
  };

  // show all notification API
  showNoti = () => {
    const getNoti = rshApi + "/notify/get-notifications";
    const that = this;
    fetch(getNoti, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          contactNo: this.state.user.contactNo, //current logged-in user
          index: 0,
          itemsPerIndex: 10,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        that.setState({
          showNotification: result.data.notifications,
          usersDetails: result.data.users,
          indexUpdate: 0,
        });
        console.log(that.state.showNotification);
        console.log(that.state.usersDetails);
      });
  };

  // updating index value by 1 number
  indexUpdate = () => {
    var indexincrease = this.state.indexUpdate;
    indexincrease = indexincrease + 1;
    this.setState({
      indexUpdate: indexincrease,
    });

    const that = this;
    const getNoti = rshApi + "/notify/get-notifications";
    fetch(getNoti, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          contactNo: that.state.user.contactNo, //current logged-in user
          index: indexincrease,
          itemsPerIndex: that.state.indexPerUpdate,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        setTimeout(() => {
          that.setState({
            showNotification: that.state.showNotification.concat(
              result.data.notifications
            ),
            usersDetails: that.state.usersDetails.concat(result.data.users),
          });
        }, 2000);
        console.log(that.state.showNotification);
        console.log(that.state.usersDetails);
      });
  };

  gotoTourProfile = (theid) => {
    window.location = "tournamentprofile/index.html?id=" + theid;
  };

  render() {
    if (this.props.location.state) {
      var { hideShowNotifications } = this.props.location.state;
    }

    var todaysDate = new Date(new Date().setDate(new Date().getDate()));
    var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
    // var previousDate = new Date(new Date().setDate(new Date().getDate() - 2));
    let hidebutton = false;
    if (this.state.notiCounts === 0 || this.state.notiCounts < 0) {
      hidebutton = true;
    }

    return (
      <div>
        <Header hideShowNotifications={hideShowNotifications} />
        <div className="container containerbottom non-session-d">
          <SectionLeft />
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
            <section className="d_midsec">
              <h3 class="commanhead fullnoti_heading">
                Notifications{" "}
                <Link to={"#"} className="unreadnoti">
                  Unread : {this.state.notiCounts}
                </Link>
              </h3>
              <ul class="notiul fullnoti_ul res-noiti-list">
                {/* <InfiniteScroll
                                    dataLength={this.state.showNotification.length}
                                    next={this.indexUpdate}
                                    hasMore={true}
                                    loader={<h4>Loading...</h4>}
                                > */}
                {this.state.showNotification.map((x) => {
                  var notificationData = false;

                  var isTeamPic;
                  var isChallengePic;
                  var isTourPic;
                  var isPic;
                  var isPic2;
                  var isPicTrue = false;

                  var notifyDateTime = "";
                  var notifyDate = new Date(x.sentOn);
                  notifyDate.toDateString();
                  if (notifyDate.toDateString() === todaysDate.toDateString()) {
                    notifyDateTime = (
                      <span className="largenoti-date">Today</span>
                    );
                  }
                  if (
                    notifyDate.toDateString() === yesterdayDate.toDateString()
                  ) {
                    notifyDateTime = (
                      <span className="largenoti-date">Yesterday</span>
                    );
                  }
                  if (
                    notifyDate.toDateString() !== todaysDate.toDateString() &&
                    notifyDate.toDateString() !== yesterdayDate.toDateString()
                  ) {
                    notifyDateTime = (
                      <span className="largenoti-date">
                        {notifyDate.toDateString()}
                      </span>
                    );
                  }

                  this.state.usersDetails.map((y) => {
                    if (x.sentBy === y.contactNo) {
                      notificationData = true;
                      if (y.profilePic !== null) {
                        isPicTrue = true;
                        isPic = y.profilePic;
                        isTeamPic = teamFillIcon;
                        isChallengePic = challengeFillIcon;
                        isTourPic = fixtureFillIcon;
                      }
                      if (y.profilePic === null) {
                        isPicTrue = false;
                        isPic2 = buddiesFillIcon;
                        isTeamPic = teamFillIcon;
                        isChallengePic = challengeFillIcon;
                        isTourPic = fixtureFillIcon;
                      }
                    }
                    return true;
                  });
                  /* Buddies */

                  if (notificationData === true) {
                    if (x.moduleName === "Buddies") {
                      if (isPicTrue === true) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/buddies?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/buddies?id=" + x.moduleId}
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (x.moduleName === "Buddies") {
                      if (isPicTrue === false) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/buddies?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/buddies?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  /* TEAMS PLAIN */
                  if (notificationData === true) {
                    if (
                      x.moduleName === "teams" &&
                      x.notificationType === "plain"
                    ) {
                      switch (x.readed) {
                        case true:
                          return (
                            <li>
                              <a
                                href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                class="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage: "url(" + isTeamPic + ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </a>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action"></div>
                            </li>
                          );
                        default:
                          return (
                            <li
                              style={{ backgroundColor: "#e7fdec" }}
                              onClick={() => this.nowRead(x.notificationId)}
                            >
                              <a
                                href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                className="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage: "url(" + isTeamPic + ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </a>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action"></div>
                            </li>
                          );
                      }
                    }
                  }

                  /* TEAMS ACTION */
                  if (notificationData === true) {
                    if (
                      x.moduleName === "teams" &&
                      x.notificationType === "actionable"
                    ) {
                      if (isPicTrue === true) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action">
                                  {/* <Link class="linkview">View</Link> */}
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink.bind(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action">
                                  {/* <Link class="linkview">View</Link> */}
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (
                      x.moduleName === "teams" &&
                      x.notificationType === "actionable"
                    ) {
                      if (isPicTrue === false) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action">
                                  {/* <Link class="linkview">View</Link> */}
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/teamprofilepage/index.html?id=" + x.moduleId}
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action">
                                  {/* <Link class="linkview">View</Link> */}
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  /* CHALLENGE PLAIN*/
                  if (notificationData === true) {
                    if (x.moduleName === "challenges") {
                      if (isPicTrue === true) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={
                                    "/challengeprofile/index.html?id=" +
                                    x.moduleId
                                  }
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={
                                    "/challengeprofile/index.html?id=" +
                                    x.moduleId
                                  }
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (x.moduleName === "challenges") {
                      if (isPicTrue === false) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={
                                    "/challengeprofile/index.html?id=" +
                                    x.moduleId
                                  }
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={
                                    "/challengeprofile/index.html?id=" +
                                    x.moduleId
                                  }
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  /* Tournament PLAIN*/
                  if (notificationData === true) {
                    if (
                      x.moduleName === "tournaments" &&
                      x.notificationType === "plain"
                    ) {
                      switch (x.readed) {
                        case true:
                          return (
                            <li>
                              <a
                                href={"/TournamentProfile?id=" + x.moduleId}
                                class="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage: "url(" + isTourPic + ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </a>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action"></div>
                            </li>
                          );
                        default:
                          return (
                            <li
                              style={{ backgroundColor: "#e7fdec" }}
                              onClick={() => this.nowRead(x.notificationId)}
                            >
                              <a
                                href={"/TournamentProfile?id=" + x.moduleId}
                                className="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage: "url(" + isTourPic + ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </a>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action">
                                <Link
                                  onClick={() =>
                                    this.gotoTourProfile(x.moduleId)
                                  }
                                  class="linkview"
                                >
                                  View
                                </Link>
                              </div>
                            </li>
                          );
                      }
                    }
                  }

                  /* Tournament ACTION*/
                  if (notificationData === true) {
                    if (
                      x.moduleName === "tournaments" &&
                      x.notificationType === "actionable"
                    ) {
                      if (isPicTrue === true) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/TournamentProfile?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}{" "}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="largesize-action">
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.gotoTourProfile(x.moduleId)
                                            }
                                            class="linkview"
                                          >
                                            View
                                          </Link>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/TournamentProfile?id=" + x.moduleId}
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="largesize-action">
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.gotoTourProfile(x.moduleId)
                                            }
                                            class="linkview"
                                          >
                                            View
                                          </Link>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (
                      x.moduleName === "tournaments" &&
                      x.notificationType === "actionable"
                    ) {
                      if (isPicTrue === false) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <a
                                  href={"/TournamentProfile?id=" + x.moduleId}
                                  class="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="largesize-action">
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.gotoTourProfile(x.moduleId)
                                            }
                                            class="linkview"
                                          >
                                            View
                                          </Link>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <a
                                  href={"/TournamentProfile?id=" + x.moduleId}
                                  className="notilink"
                                >
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}{" "}
                                  </label>
                                </a>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="largesize-action">
                                  {(() => {
                                    if (x.actionableStatus !== null) {
                                      if (x.actionableStatus.status === true) {
                                        return (
                                          <Link class="linkaccept">
                                            Accepted
                                          </Link>
                                        );
                                      }
                                      if (x.actionableStatus.status === false) {
                                        return (
                                          <Link class="linkreject">
                                            Rejected
                                          </Link>
                                        );
                                      }
                                    } else {
                                      return (
                                        <div>
                                          <Link
                                            onClick={() =>
                                              this.gotoTourProfile(x.moduleId)
                                            }
                                            class="linkview"
                                          >
                                            View
                                          </Link>
                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Accepted"
                                              )
                                            }
                                            class="linkaccept"
                                          >
                                            {this.state.accept}
                                          </Link>

                                          <Link
                                            onClick={() =>
                                              this.acceptrejectLink(
                                                x.notificationId,
                                                x.moduleName,
                                                "Rejected"
                                              )
                                            }
                                            class="linkreject"
                                          >
                                            {this.state.reject}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  /* Posts*/
                  if (notificationData === true) {
                    if (x.moduleName === "posts") {
                      if (isPicTrue === true) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <Link to={"#"} class="notilink">
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </Link>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <Link to={"#"} className="notilink">
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </Link>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (x.moduleName === "posts") {
                      if (isPicTrue === false) {
                        switch (x.readed) {
                          case true:
                            return (
                              <li>
                                <Link to={"#"} class="notilink">
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </Link>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                          default:
                            return (
                              <li
                                style={{ backgroundColor: "#e7fdec" }}
                                onClick={() => this.nowRead(x.notificationId)}
                              >
                                <Link to={"#"} className="notilink">
                                  <span
                                    class="notiimg"
                                    style={{
                                      backgroundImage: "url(" + isPic2 + ")",
                                    }}
                                  ></span>
                                  <label class="notiname" href="#">
                                    {x.notificationConfig.content}{" "}
                                    {notifyDateTime}
                                  </label>
                                </Link>
                                <div class="dropdown dropaccount messagedrop">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                  >
                                    {" "}
                                    <img src={more} alt="" />
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li
                                      onClick={() =>
                                        this.nowDelete(x.notificationId)
                                      }
                                    >
                                      <Link to={"#"}>Delete</Link>
                                    </li>
                                  </ul>
                                </div>
                                <div class="noti_action"></div>
                              </li>
                            );
                        }
                      }
                    }
                  }

                  if (notificationData === true) {
                    if (x.moduleName === "page") {
                      switch (x.readed) {
                        case true:
                          return (
                            <li>
                              <Link
                                to={`/page/profile/${x.moduleId}`}
                                class="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      x.notificationConfig.notificationIcon +
                                      ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </Link>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action"></div>
                            </li>
                          );
                        default:
                          return (
                            <li
                              style={{ backgroundColor: "#e7fdec" }}
                              onClick={() => this.nowRead(x.notificationId)}
                            >
                              <Link
                                to={`/page/profile/${x.moduleId}`}
                                className="notilink"
                              >
                                <span
                                  class="notiimg"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      x.notificationConfig.notificationIcon +
                                      ")",
                                  }}
                                ></span>
                                <label class="notiname" href="#">
                                  {x.notificationConfig.content}{" "}
                                  {notifyDateTime}
                                </label>
                              </Link>
                              <div class="dropdown dropaccount messagedrop">
                                <button
                                  class="btn dropdown-toggle"
                                  type="button"
                                  data-toggle="dropdown"
                                >
                                  {" "}
                                  <img src={more} alt="" />
                                </button>
                                <ul class="dropdown-menu">
                                  <li
                                    onClick={() =>
                                      this.nowDelete(x.notificationId)
                                    }
                                  >
                                    <Link to={"#"}>Delete</Link>
                                  </li>
                                </ul>
                              </div>
                              <div class="noti_action"></div>
                            </li>
                          );
                      }
                    }
                  }
                  return "";
                })}

                {this.state.showNotification.length !== 0 ? (
                  <React.Fragment></React.Fragment>
                ) : hidebutton ? (
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    <img src={sad} alt="" />
                    <br /> <label> sorry you don't have any notification</label>
                    <br />{" "}
                  </div>
                ) : (
                  <div></div>
                )}
                {/* </InfiniteScroll> */}
              </ul>

              <Link onClick={() => this.indexUpdate()} class="notiloadmore">
                Load More
              </Link>
            </section>
          </div>

          <SectionRight />
        </div>
      </div>
    );
  }
}

export default AllNotificationPage;
