import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import * as TournamentsUtil from '../DemoTournament/DTournamentsUtil';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import { message } from 'antd';
import play from '../img/play.png';
import $ from 'jquery';
var scoreCardD104 = [];
var d104ScoreCard = {}, score;
var numberOfAttempt;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
let organiseMatchObj = {};
let tId = null;
var points = [];
var selectedWinner;
var maxWeights = [];
var cricketApi = require("../cricketApi.js");
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class Tournament_d104_Update_tt4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerPoint: '',
            errorShow: false,
            maxPoint: [],
            checked: true,
            timeInput: 0,
            hr: 0,
            min: 0,
            sec: 0,
            ms: 0,
            playerTime: '',
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD104State: [],
            maxPoint: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
        this.changeTime = this.changeTime.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.endGame = this.endEvent.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.disqualify = this.disqualify.bind(this);


    }
    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, setModalIsOpen: false, fillScoreModalOpen: false, winnerModalOpen: false });
    }

    doneMatch() {
        let wId = null;
        if (maxWeights.length === 1) {
            wId = maxWeights[0].userId;
        }
        else {
            wId = this.state.selectWinner;
        }
        tId = this.props.challenge.tournamentId;
        let mId = tId + '-' + wId;
        delete organiseMatchObj.id;

        if (organiseMatchObj.finals.ended) {
            var moment = require('moment');
            var now = moment().format();
            var timpstampData = new Date(now).valueOf();
            var tournament = this.props.challenge;
            delete tournament.id;
            tournament.status = "completed";
            tournament.modifiedOn = timpstampData;
            tournament.tournamentWinnerId = wId;

            TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
                console.log(data)
                this.setState({ winnerModalOpen: false })
                TournamentsUtil.ttWinnerFeeds(tId, mId, wId).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        // window.location = "/tournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
                    })
                })
            })
        }
        else {
            TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
                this.setState({ winnerModalOpen: false })
                window.location = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
            })
        }
    }

    increment(type) {
        if (type === 'hr') {
            if (this.state.hr < 99) {
                this.setState({ hr: this.state.hr + 1 })
            }
        }
        if (type === 'min') {

            if (this.state.min < 59) {
                this.setState({ min: this.state.min + 1 })
            }
        }
        if (type === 'sec') {

            if (this.state.sec < 59) {
                this.setState({ sec: this.state.sec + 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms < 999) {
                this.setState({ ms: this.state.ms + 1 })
            }
        }
    }
    decrement(type) {
        if (type === 'hr') {
            if (this.state.hr > 0) {
                this.setState({ hr: this.state.hr - 1 })
            }
        }
        if (type === 'min') {
            if (this.state.min > 0) {
                this.setState({ min: this.state.min - 1 })
            }
        }
        if (type === 'sec') {
            if (this.state.sec > 0) {
                this.setState({ sec: this.state.sec - 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms > 0) {
                this.setState({ ms: this.state.ms - 1 })
            }
        }
    }
    changeTime(e) {

        if (e.target.name === 'hr') {
            if (parseInt(e.target.value) > 99) {
                this.setState({ [e.target.name]: 99 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'min') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'sec') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'ms') {
            if (parseInt(e.target.value) > 999) {
                this.setState({ [e.target.name]: 999 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    endEvent() {
        let scoreFill = this.state.scoreCardD104State;
        let allFill = true;
        numberOfAttempt = this.props.tt4.attempts;
        scoreFill.map(score => {
            if (score.points.length == numberOfAttempt) {

                allFill = true;
            }
            else {
                allFill = false;
            }
        })
        if (allFill) {
            if (this.props.matchType === -2) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)

                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                points = [];
                points = this.state.scoreCardD104State;
                organiseMatchObj.tt4Matches.map((tt4, index) => {
                    if (index === this.props.clickedIndex) {
                        tt4.ended = true;
                    }
                });

                console.log(points)
                this.setState({ winnerModalOpen: true });
            }
            if (this.props.matchType === -3) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)
                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                points = [];
                // maxDistance = this.state.scoreCardD104State;
                organiseMatchObj.finals.ended = true;
                const max = this.state.scoreCardD104State.reduce((prev, current) => (prev.points > current.points) ? prev : current)
                this.state.scoreCardD104State.map((x, index) => {
                    if (x.points === max.points) {
                        points.push(x);
                    }
                });

                console.log(points)
                this.setState({ winnerModalOpen: true });
            }
        }
        else {
            message.info('Please Fill All Field');
        }
    }

    disqualify(event) {
        $("#myModal .close").click();
        //  this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var weight = 0;
        console.log(this.state.setIndex)
        console.log(this.state.scoreCardD104State);
        let scoreCardD104State = this.state.scoreCardD104State;
        scoreCardD104State.map((x, index) => {
            if (index === this.state.setIndex) {
                x.disqualified = true;
                x.points = 3661001;

            }
        });
        this.setState({ scoreCardD104State: scoreCardD104State })


        organiseMatchObj = this.props.tt4;
        console.log(organiseMatchObj)
        if (this.props.matchType === -2) {
            organiseMatchObj.tt4Matches.map((tt4, index) => {
                tt4.matchesList.map((ml, ind) => {
                    if (ind === this.state.setIndex) {
                        if (scoreCardD104State[ind].points) {
                            ml.index = 0;
                            ml.d104ScoreCard = {
                                points: scoreCardD104State[ind].points, disqualified: true
                            }
                        }
                        else {
                            ml.d104ScoreCard = null
                        }
                    }
                })

            })
        }
        console.log(organiseMatchObj)
        if (this.props.matchType === -3) {

            organiseMatchObj.finals.matchesList.map((ml, ind) => {
                if (scoreCardD104State[ind].points) {
                    ml.index = scoreCardD104State[ind].points.length;
                    ml.d104ScoreCard = {
                        points: scoreCardD104State[ind].points, disqualified: true
                    }
                }
                else {
                    ml.d104ScoreCard = null
                }
            })
        }
        tId = this.props.challenge.tournamentId;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
    }



    savePoint() {
        var scoreCardD104State = this.state.scoreCardD104State;
        var hr, min, sec, ms;
        if (this.state.hr < 10) {
            hr = '0' + this.state.hr;
        }
        else {
            hr = this.state.hr;
        }
        if (this.state.min < 10) {
            min = '0' + this.state.min;
        }
        else {
            min = this.state.min;
        }
        if (this.state.sec < 10) {
            sec = '0' + this.state.sec;
        }
        else {
            sec = this.state.sec;
        }
        if (this.state.ms < 10) {
            ms = '00' + this.state.ms;
        }
        else if ((this.state.ms >= 10) && (this.state.ms < 100)) {
            ms = '0' + this.state.ms;
        }
        else {
            ms = this.state.ms;
        }
        // this.setState({ playerTime: hr + ':' + min + ':' + sec + '.' + ms })
        var playerTime = hr + ':' + min + ':' + sec + '.' + ms;
        var unixTimeZero = Date.parse('01 Jan 1970 ' + playerTime + ' UTC');
        let that = this;
        scoreCardD104State.map((point, index) => {
            if (index === this.state.setIndex) {
                point.points = unixTimeZero;
                point.disqualified = false;
            }
        });

        $("#myModal .close").click();

        this.setState({ scoreCardD104State: scoreCardD104State })
        organiseMatchObj = this.props.tt4;
        if (this.props.matchType === -2) {
            organiseMatchObj.tt4Matches.map((tt4, index) => {
                if (index === this.props.clickedIndex) {

                    tt4.matchesList.map((ml, ind) => {
                        if (scoreCardD104State[ind].points) {
                            ml.index = scoreCardD104State[ind].points.length;
                            ml.d104ScoreCard = {

                                points: scoreCardD104State[ind].points, disqualified: false
                            }
                        }
                        else {
                            ml.d104ScoreCard = null
                        }
                    })
                }
            })
        }
        if (this.props.matchType === -3) {
            if (Object.keys(organiseMatchObj).length !== 0) {
                console.log(organiseMatchObj)
            }
            else {
                organiseMatchObj = this.props.tt4;
            }
            points = [];
            // maxDistance = this.state.scoreCardD101State;
            organiseMatchObj.finals.ended = true;
            const max = this.state.scoreCardD104State.reduce((prev, current) => (prev.points > current.points) ? prev : current)
            this.state.scoreCardD104State.map((x, index) => {
                if (x.points === max.points) {
                    points.push(x);
                }
            });

            console.log(points)
            //this.setState({ winnerModalOpen: true });
        }
        tId = this.props.challenge.tournamentId;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
        console.log(organiseMatchObj)
    }
    componentDidMount() {

        let d104ScoreCard = [];

        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {

                if (this.props.clickedIndex === index) {
                    if (tt4.attempts >= 0) {
                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d104ScoreCard) {
                                d104ScoreCard.push({ userId: ml.playerId, points: ml.d104ScoreCard.points, disqualified: ml.d104ScoreCard.disqualified })
                            }
                            else {
                                d104ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                }
            })
        }
        if (this.props.matchType === -3) {
            let tt4F = this.props.tt4;
            tt4F.finals.attempts = numberOfAttempt;
            tt4F.finals.attempts = 0;
            if (tt4F.finals.attempts >= 0) {
                numberOfAttempt = tt4F.finals.attempts;
                this.setState({ modalIsOpen: false });

                tt4F.finals.matchesList.map(ml => {
                    if (ml.d104ScoreCard) {

                        d104ScoreCard.push({ userId: ml.playerId, points: ml.d104ScoreCard.points, disqualified: ml.d104ScoreCard.disqualified })
                    }
                    else {
                        d104ScoreCard.push({ userId: ml.playerId, points: null })
                    }
                })
            }
        }
        console.log(d104ScoreCard)
        console.log(this.props.tt4)
        this.setState({ scoreCardD104State: d104ScoreCard })


    }

    render() {

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {/* <div ref={subtitle => this.subtitle = subtitle}>
                            <h3>   winner </h3>
                            {maxWeights.map(weight => {
                                return <h3> {weight.userId} is  winner </h3>
                            })}
                        </div> */}
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">

                                {points.map((weight, index) => {
                                    var userName;
                                    selectedWinner = weight.userId;
                                    this.props.profileUsers.map(user => {

                                        if (weight.userId === user.contactNo) {
                                            userName = user.name
                                        }
                                    })
                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <div class="resinfo">
                                                <label>{userName} </label>
                                                <small> Score- {weight.points} </small>
                                            </div>


                                        </a>
                                    </li>
                                })}
                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={this.closeModal}>Back</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>

                <div id="myModal1" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Enter Point</h4>
                            </div>
                            <div className="modal-body">
                                <center>
                                    <h5>Please Fill Score Point</h5></center>
                            </div>
                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen" data-toggle="modal" data-target="#myModal1" />

                <div id="myModal2" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Challange Winner</h4>
                            </div>
                            <div className="modal-body">
                                <div className="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul className="resultul">
                                    {this.state.maxPoint.map((weight, index) => {

                                        var userName, userPic, point;
                                        selectedWinner = weight.userId;
                                        this.props.profileUsers.map(user => {

                                            if (weight.userId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic;
                                            }
                                        })
                                        point = cricketApi.msToTime(weight.points);

                                        return <li style={{ 'display': 'flex' }}>
                                            <a className="winnerselect">
                                                <span className="resuserimg" style={{ backgroundImage: "url(" + userPic + ")" }}></span>
                                                <div className="resinfo">
                                                    <label>{userName} </label>
                                                    <small>Score:{point} </small>
                                                </div>

                                                <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                    {/* <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} /> */}
                                                    <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                    <label htmlFor={index}></label>
                                                </div>
                                            </a>
                                        </li>
                                    })}

                                </ul>

                            </div>

                            <div className="modal-footer table_actions">
                                <a data-dismiss="modal" className="cmnbutton editbtn">Edit</a>
                                <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />


                <div id="myModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Time Picker</h4>
                            </div>
                            <div className="modal-body">

                                <div className="master">
                                    <div className="number">
                                        <span className="minus" name="hr" onClick={() => this.decrement('hr')}>-</span>
                                        <input class="d104Input" type="number" min="0" max="200" name="hr" onChange={this.changeTime} value={this.state.hr} maxLength={2} />
                                        <span className="plus" name="hr" onClick={() => this.increment('hr')}>+</span>
                                        <label className="heading">Hr</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="min" onClick={() => this.decrement('min')}>-</span>
                                        <input class="d104Input" min="0" max="59" type="number" name="min" onChange={this.changeTime} value={this.state.min} maxLength={2} />
                                        <span className="plus" name="min" onClick={() => this.increment('min')}>+</span>
                                        <label className="heading">min</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="sec" onClick={() => this.decrement('sec')}>-</span>
                                        <input class="d104Input" min="0" max="59" type="number" name="sec" onChange={this.changeTime} value={this.state.sec} maxLength={2} />
                                        <span className="plus" name="sec" onClick={() => this.increment('sec')}>+</span>
                                        <label className="heading">sec</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="ms" onClick={() => this.decrement('ms')}>-</span>
                                        <input class="d104Input" min="0" max="999" type="number" name="ms" onChange={this.changeTime} value={this.state.ms} maxLength={2} />
                                        <span className="plus" name="ms" onClick={() => this.increment('ms')}>+</span>
                                        <label className="heading">ms</label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer table_actions">
                                <a className="cmnbutton" onClick={() => this.disqualify()}>disqualify</a>

                                <button className="cmnbutton backbtn fr" type="button" data-dismiss="modal" >cancel</button>
                                <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.savePoint()} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video">
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul className="winnerdropdown_ul">
                            {this.state.scoreCardD104State.map((accepted, index) => {
                                var name, contactNo, point, pointColor;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;

                                        if (accepted.disqualified) {

                                            pointColor = { color: 'red' };
                                            point = 'disqualify';
                                        }
                                        else {
                                            point = accepted.points;
                                            point = cricketApi.msToTime(point);
                                        }

                                    }
                                    {/* if (accepted.disqualified) {
                                        pointColor = { color: 'red' };
                                        point = 'disqualify';
                                    } */}
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" readOnly style={pointColor} value={point} data-toggle="modal" data-target="#myModal" onClick={() => this.setState({ setIndex: index, errorShow: false, hr: 0, min: 0, sec: 0, ms: 0 })} />
                                </li>
                            })}
                        </ul>
                        <div className="table_actions winner_actions">
                            <button className="cmnbutton backbtn fl">Back</button>
                            <a className="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

