import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import * as myTournamentUtilclass from '../TournamentsUtil';
import * as myUtilclass from '../FixtureUtil';
var score;
var that = this;

let pairObj;
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const tournamentAction = "ended";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
export default class Tournament_d102_tt1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            matchPairObj: {},
        };

        this.closeModal = this.closeModal.bind(this);

        this.playerName = this.props.tournament.accepted.map(accepted => {
            this.props.profileUsers.map(user => {
                if (user.contactNo === accepted) {
                    return <option value={user.contactNo}>{user.name}</option>
                }
            })
        })
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    winnerDone = () => {
        if (this.state.winner == "notselected") {
            this.setState({ modalIsOpen: false });
        }
        else if (this.state.winner === undefined) {
            this.setState({ modalIsOpen: false });
        }
        else {
            this.setState({ modalIsOpen: true });
        }
    }

    matchDone() {

        let that = this;
        var D102ScoreCard = {
            winnerId: this.state.winner,
            ids: this.props.tournament.accepted,
        }
        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d102ScoreCard: D102ScoreCard,
        }

        UpdateTournamentsScore(score);

        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }

        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }

    }



    getScore() {
        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": this.props.tournament.tournamentId,
                    "matchId": this.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.score === null) {
                    that.saveIntialSet();
                }
                //window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
            });
    }

    saveIntialSet = () => {
        if (this.props.tt1.fixtureType1) {
            myUtilclass.getMatchPair(this.props.tt1, null, null, true, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            myUtilclass.getMatchPair(this.props.tt1, poolNo, null, true, this.props.matchId);
        }
    }




    componentDidMount() {

        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName, toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }

    render() {

        var matchIdSpilit = this.props.matchId;
        var ar = matchIdSpilit.split('-'); // split string on comma space
        console.log(ar)

        const backLinks = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
        return (
            <React.Fragment>

                {this.state.modalIsOpen ? <div id="myModal" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">End Event</h4>
                            </div>
                            <div class="modal-body">
                                <label class="alertmsg">
                                    <i class="fa fa-exclamation-circle"></i>
                                    End event declear the winner and you will no longer be available to edit
                         </label>

                            </div>
                            <div class="modal-footer table_actions">
                                <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.matchDone()} >Confirm</button>
                            </div>
                        </div>
                    </div>
                </div> :

                    <div id="myModal" class="modal fade reslutmodel" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">End Event</h4>
                                </div>
                                <div class="modal-body">
                                    <label class="alertmsg">
                                        <i class="fa fa-exclamation-circle"></i>
                                        Please Select a player First
                         </label>

                                </div>
                                {/* <div class="modal-footer table_actions">
                                    <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                    <button class="cmnbutton btnsubmitright" type="button" >Confirm</button>
                                </div> */}
                            </div>
                        </div>
                    </div>}

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video " style={{ backgroundImage: "url(" + news + ")" }} > */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                        <ul className="winnerdropdown_ul">
                            <li>
                                <label>Winner</label>
                                <select onChange={event => this.setState({ winner: event.target.value })}>
                                    <option value={"notselected"}  >Select Winner</option>

                                    {ar.map(ar => {
                                        var name = null, contactNo = null;
                                        this.props.profileUsers.map(user => {
                                            if (user.contactNo === ar) {
                                                name = user.name;
                                                contactNo = user.contactNo;
                                            }
                                        })
                                        if (name) {
                                            return <option value={contactNo}>{name}</option>
                                        }

                                    })}
                                </select>
                            </li>
                        </ul>

                        <div className="table_actions">
                            <a href={backLinks} className="cmnbutton">Back</a>
                            <button className="cmnbutton" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.winnerDone()}>End Games</button>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}
