module.exports = {
    live: function () {
        return "https://rest.entitysport.com/v2/matches/?status=3&token=7172e8628aaf3155484181f32a328f58&per_page=";
    },
    completed: function () {
        return "https://rest.entitysport.com/v2/matches/?status=2&token=7172e8628aaf3155484181f32a328f58&per_page=";
    },
    upcoming: function () {
        return "https://rest.entitysport.com/v2/matches/?status=1&token=7172e8628aaf3155484181f32a328f58";
    },
    msToTime: function (duration) {
        var seconds = parseInt((duration / 1000) % 60);
        var minutes = parseInt((duration / (1000 * 60)) % 60);
        var hours = parseInt((duration / (1000 * 60 * 60)) % 24);

        var milliseconds = parseInt((duration % (1000)));
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        milliseconds = (milliseconds < 10) ? "00" + milliseconds : (milliseconds < 100 ? "0" + milliseconds : milliseconds);
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    },

    msToTime1: function (duration) {
        var seconds = parseInt((duration / 1000) % 60);
        var minutes = parseInt((duration / (1000 * 60)) % 60);
        var hours = parseInt((duration / (1000 * 60 * 60)) % 200);
        //   var day = parseInt((duration / (1000 * 60 * 60 * 24)));
        var milliseconds = parseInt((duration % (1000)));
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        milliseconds = (milliseconds < 10) ? "00" + milliseconds : (milliseconds < 100 ? "0" + milliseconds : milliseconds);
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    }
};   