import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;


export default class Tournament_d107_Volleyball_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            rounds: [],
            teams: [],
        };

    }
    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);

        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {


                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": this.props.matchId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    //users:result.data.users
                    teams: result.data.teams
                })
                if (result.data.score !== null) {
                    if (result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== undefined) {
                        if (result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
                            that.setState({ bySetWon: result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length, byWinner: result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.winner });

                        }
                        else {
                            that.setState({ bySetWon: 0, byWinner: result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.winner });

                        }
                        that.setState({ matchStatus: true });
                    }
                    if (result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== undefined) {
                        if (result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
                            that.setState({ toSetWon: result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length, toWinner: result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.winner });

                        }
                        else {
                            that.setState({ toSetWon: 0, toWinner: result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.winner });

                        }
                        that.setState({ matchStatus: true });
                    }


                    that.setState({ rounds: result.data.score.d107VolleyballScoreCard.rounds, scorecard: result.data.score.d107VolleyballScoreCard })


                    that.state.teams.map(team => {
                        if (team.teamId === result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.teamId) {
                            that.setState({ byVolleyballScoreCard: team.teamName });
                        }
                        if (team.teamId === result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.teamId) {
                            that.setState({ toVolleyballScoreCard: team.teamName });
                        }
                    })

                }
            });
    }
    render() {
        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
        if (this.state.matchStatus) {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>
                                                <th>Sets/Won</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byVolleyballScoreCard}</td>
                                                <td>{this.state.bySetWon}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toVolleyballScoreCard}</td>
                                                <td>{this.state.toSetWon}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h4 class="scorehead">Match Score</h4>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>

                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <th> Set{i} </th>
                                                    }

                                                })}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.byVolleyballScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedByPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toVolleyballScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedToPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
    }
}