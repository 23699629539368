import React, { Component } from 'react';
import male from "./img/male.png";
import female from "./img/female.png";
import searchnew from "./img/searchnew.png";


class LikeList extends Component {

    componentDidMount() {
        console.log(this.props.data.reactBudd);
        console.log(this.props.data.users);
    }
    render() {
        var userName;
        var userPic;
        var userGender;
        return (

            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">All liked Buddies</h4>
                    </div>
                    <div class="replydiv likedbuddy">

                        <div class="modal-body">
                            <div class="ac_search">
                                <input placeholder="Search buddies" />
                                <a><img src={searchnew} alt="" /></a>
                            </div>
                            <ul class="playerlist">
                                {this.props.data.reactBudd.map(rb => {
                                    if (rb.reactedType === 'cheer') {
                                        this.props.data.users.map(user => {
                                            if (rb.buddyId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic;
                                                userGender = user.gender;

                                            } return true;
                                        })
                                        if (userPic !== null) {
                                            return (

                                                <li key={rb.timestamp}>
                                                    <a class="assignimg" href={`/index.html?id=${rb.buddyId}`}
                                                        style={{
                                                            backgroundImage: "url(" + userPic + ")"
                                                        }}
                                                    >

                                                    </a>
                                                    <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>
                                                    <button class="alreadybuddy likedbuddybtn">Buddy</button>
                                                </li>
                                            );
                                        }
                                        else {
                                            if (userGender === 'Male') {
                                                return (
                                                    <li key={rb.timestamp}>
                                                        <a class="assignimg" href={`/index.html?id=${rb.buddyId}`}
                                                            style={{
                                                                backgroundImage: "url(" + male + ")"
                                                            }}
                                                        >

                                                        </a>
                                                        <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>
                                                        <button class="alreadybuddy likedbuddybtn">Buddy</button>
                                                    </li>
                                                );
                                            }
                                            else {
                                                return (
                                                    <li key={rb.timestamp}>
                                                        <a class="assignimg" href={`/index.html?id=${rb.buddyId}`}
                                                            style={{
                                                                backgroundImage: "url(" + female + ")"
                                                            }}
                                                        >

                                                        </a>
                                                        <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>
                                                        <button class="alreadybuddy likedbuddybtn">Buddy</button>
                                                    </li>
                                                );
                                            }
                                        }

                                    }
                                    return true;
                                })
                                }
                            </ul>
                        </div>

                    </div>

                </div>
            </div>




            /*  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
            <div style={{ border: '2px #000 solid', width: '200px', textAlign: 'center' }}>
                <h3> Reacted buddies </h3>
                <hr></hr>
                <ul className="aboutbuddies">
                    {this.props.data.reactBudd.map(rb => {
                        if (rb.reactedType === 'cheer') {
                            this.props.data.users.map(user => {
                                if (rb.buddyId === user.contactNo) {
                                    userName = user.name;
                                    userPic = user.profilePic;
                                    userGender = user.gender;

                                } return true;
                            })
                            if (userPic !== null) {
                                return (
                                    <li key={rb.timestamp}>
                                        <a href={`/index.html?id=${rb.buddyId}`}
                                            style={{
                                                backgroundImage: "url(" + userPic + ")"
                                            }}
                                        >

                                        </a>
                                        <span style={{ fontWeight: '800' }}>{userName}</span>
                                    </li>
                                );
                            }
                            else {
                                if (userGender === 'Male') {
                                    return (
                                        <li key={rb.timestamp}>
                                            <a href={`/index.html?id=${rb.buddyId}`}
                                                style={{
                                                    backgroundImage: "url(" + male + ")"
                                                }}
                                            >

                                            </a>
                                            <span style={{ fontWeight: '800' }}>{userName}</span>
                                        </li>
                                    );
                                }
                                else {
                                    return (
                                        <li key={rb.timestamp}>
                                            <a href={`/index.html?id=${rb.buddyId}`}
                                                style={{
                                                    backgroundImage: "url(" + female + ")"
                                                }}
                                            >

                                            </a>
                                            <span style={{ fontWeight: '800' }}>{userName}</span>
                                        </li>
                                    );
                                }
                            }

                        }
                        return true;
                    })
                    }

                </ul>
            </div>
        </div> */
        );
    }
}

export default LikeList;
