import React, { Component } from 'react'
import challengefill from "../img/challengefill.png";
import teamIcon from "../img/team.png";
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import scheduleMatches from "../img/schedulematches.png";
import shuffle from "../img/shuffle.png";
import location from "../img/location.png";
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import Map from '../Map';
import { message } from 'antd';
import * as myConstclass from '../ConstFile';
import * as myTournamentUtilclass from '../DemoTournament/DTournamentsUtil';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
let name, pic, errorMessage, teamLength = 0, teamUserSelect = false;
let fixture1Ui, fixture2Ui1, fixture2Ui2, fixture2Ui3, fixture2Ui4, fixture2UiSF, fixture2UiF;
let poolLength;
const fixture = {
    participant: null,
    type: null,
    fixtureType1: null,
    fixtureType2: null,
};
const fixtureFinal = {
    pairId: 1,
    date: null, time: null,
    ended: false,
    fixHeight: 0,
    location: null,
    city: null,
    state: null,
    country: null,
    latitude: null,
    longitude: null,
}
const semifinalList = [{
    pairId: 1,
    date: null, time: null,
    ended: false,
    fixHeight: 0,
    location: null,
    city: null,
    state: null,
    country: null,
    latitude: null,
    longitude: null,
}, {
    pairId: 2,
    date: null, time: null,
    ended: false,
    fixHeight: 0,
    location: null,
    city: null,
    state: null,
    country: null,
    latitude: null,
    longitude: null,
}]
export default class DOrganiseMatchTT1Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTournamentType: false,
            showScheduleButton: true,
            teamUserArray: [],
            multiTeamsNext: [],
            fixtureType: 1,
            errorTrue: false,
            fixtureListIndex: null,
            fixturePairIndex: null,
            fixture2Poolnu: 2,
        }
    }
    componentDidMount() {

        console.log(this.state.tt1Matches)
    }
    scheduleMatch = () => {
        this.setState({ showTournamentType: true, showScheduleButton: false })
    }
    changeFixture2Pool = (e) => {
        this.setState({ fixture2Poolnu: e.target.value })
    }
    closePoolOption = () => {

        if (this.state.fixture2Poolnu) {
            this.setState({ showUesrTeamForNextMatch: false, showPoolOption: false })
        }
        else {
            this.setState({ showUesrTeamForNextMatch: false, showTournamentType: true, multiTeamsNext: [], errorTrue: false, showPoolOption: false })
        }

    }
    selectPoolForFixture2 = () => {
        this.setState({ showPoolOption: false })
        let teamMembers = [...this.state.multiTeamsNext];
        let n = null;
        if (this.state.fixture2Poolnu == 2) {
            n = teamMembers.length / 2;
            poolLength = 2;
        }
        else {
            n = teamMembers.length / 4;
            poolLength = 4;
        }
        if (poolLength == 2) {
            this.setState({ show2Pool: true, showFinal: true, showSemi: false, });
        }
        else {
            this.setState({ show2Pool: true, show4Pool: true, showSemi: true, showFinal: true });
        }
        let tId = this.props.tournament.tournamentId;
        let poolList = [];
        let idPosition = 0;
        for (let i = 0; i < poolLength;) {
            let fixtureList = [];
            let columNo = 0;
            for (let j = n; j > 1; j = j / 2) {
                let fixtureListObj = {};
                let fixturePairs = [];
                let fixturePairsObj = {
                    pairId: null, matchId: null,
                    firstId: null, secondId: null,
                    date: null, time: null,
                    winnerId: null,
                    ended: false,
                    fixHeight: 840,
                    location: null,
                    city: null,
                    state: null,
                    country: null,
                    latitude: null,
                    longitude: null,
                };
                var pairNo = 0;
                for (let k = 0; k < j / 2; k++) {
                    let no = pairNo;
                    fixturePairsObj.pairId = ++no;
                    if (columNo == 0) {
                        fixturePairsObj.firstId = teamMembers[idPosition];
                        ++idPosition;
                        fixturePairsObj.secondId = teamMembers[idPosition];
                        ++idPosition;
                        fixturePairsObj.matchId = tId + '-' + fixturePairsObj.firstId + '-' + fixturePairsObj.secondId;
                    }
                    else {
                        fixturePairsObj.firstId = null;
                        fixturePairsObj.secondId = null;
                        fixturePairsObj.matchId = null;
                    }
                    if (fixturePairs.length > 0) {
                        fixturePairs = [...fixturePairs, fixturePairsObj];
                    }
                    else {
                        fixturePairs = [{ ...fixturePairsObj }];
                    }
                    pairNo = no;
                }
                columNo++;
                fixtureList.push({ 'fixturePairs': fixturePairs });
            }
            let fl = { 'poolId': ++i, 'fixtureList': fixtureList };
            poolList.push(fl);
        }
        fixture.fixtureType2 = { 'fixturePoolList': poolList };
        fixture.fixtureType2.finalMatch = fixtureFinal;
        if (poolLength = 4) {
            fixture.fixtureType2.semifinalList = semifinalList;
        }
        console.log(fixture)
        this.setState({ showFixture2: true })
    }
    saveUserTeamForNext = (event) => {
        let checkedArray = [...this.state.multiTeamsNext];
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                multiTeamsNext: checkedArray,
                // tickCountForNext: checkedArray.length,
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiTeamsNext: checkedArray,
                // tickCountForNext: checkedArray.length,
            });
        }
    }
    changeFixtureType = (event) => {

        this.setState({ fixtureType: event.target.value })
    }
    saveTeamUserForMatch = () => {
        let teamMembers = [...this.state.multiTeamsNext];
        let tId = this.props.tournament.tournamentId;
        teamLength = this.state.multiTeamsNext.length;
        if ((teamLength % 4) !== 0) {
            errorMessage = 'Please Select Atleast 4 ' + this.props.tournamentMode;
            this.setState({ errorTrue: true })
        }
        else {
            teamUserSelect = true;
            this.setState({ showOrganiseCreateButton: true, errorTrue: false, showUesrTeamForNextMatch: false })
            if (this.state.fixtureType == 1) {
                fixture.fixtureType1 = {};
                fixture.type = 1;
                fixture.participant = teamLength;
            }
            else {
                fixture.fixtureType2 = {};
                fixture.type = 2;
                fixture.participant = teamLength;
            }
            if (fixture.type == 1) {
                this.setState({ showFixture1: true })
                let fixtureList = [];
                let idPosition = 0;
                for (let j = teamLength; j > 1; j = j / 2) {
                    let fixtureListObj = {};
                    let fixturePairs = [];
                    let fixturePairsObj = {
                        pairId: null, matchId: null,
                        firstId: null, secondId: null,
                        date: null, time: null,
                        winnerId: null,
                        ended: false,
                        fixHeight: 840,
                        location: null,
                        city: null,
                        state: null,
                        country: null,
                        latitude: null,
                        longitude: null,
                    };
                    var pairNo = 0;
                    for (let k = 0; k < j / 2; k++) {
                        let no = pairNo;
                        fixturePairsObj.pairId = ++no;
                        fixturePairsObj.firstId = teamMembers[idPosition];
                        ++idPosition;
                        fixturePairsObj.secondId = teamMembers[idPosition];
                        ++idPosition;

                        if (fixturePairsObj.firstId) {
                            fixturePairsObj.matchId = tId + '-' + fixturePairsObj.firstId + '-' + fixturePairsObj.secondId;
                        }
                        else {
                            //  fixturePairsObj.matchId = null;
                            fixturePairsObj.firstId = null;
                            fixturePairsObj.secondId = null;
                        }
                        if (fixturePairs.length > 0) {
                            fixturePairs = [...fixturePairs, fixturePairsObj];
                        }
                        else {
                            fixturePairs = [{ ...fixturePairsObj }];
                        }
                        pairNo = no;
                    }
                    fixtureList.push({ 'fixturePairs': fixturePairs });
                }
                fixture.fixtureType1 = { 'fixtureList': fixtureList };
            }
            else {
                let teamMembers = [...this.state.multiTeamsNext];
                if (teamMembers.length == 8) {
                    poolLength = 2;
                    if (poolLength == 2) {
                        this.setState({ show2Pool: true, showSemi: false, showFinal: true });
                    }
                    let poolList = [];
                    let idPosition = 0;
                    for (let i = 0; i < 2;) {
                        let fixtureList = [];
                        let columNo = 0;
                        for (let j = 4; j > 1; j = j / 2) {
                            let fixtureListObj = {};
                            let fixturePairs = [];
                            let fixturePairsObj = {
                                pairId: null, matchId: null,
                                firstId: null, secondId: null,
                                date: null, time: null,
                                winnerId: null,
                                ended: false,
                                fixHeight: 840,
                                location: null,
                                city: null,
                                state: null,
                                country: null,
                                latitude: null,
                                longitude: null,
                            };
                            var pairNo = 0;
                            for (let k = 0; k < j / 2; k++) {
                                let no = pairNo;
                                fixturePairsObj.pairId = ++no;
                                if (columNo == 0) {
                                    fixturePairsObj.firstId = teamMembers[idPosition];
                                    ++idPosition;
                                    fixturePairsObj.secondId = teamMembers[idPosition];
                                    ++idPosition;
                                    fixturePairsObj.matchId = tId + '-' + fixturePairsObj.firstId + '-' + fixturePairsObj.secondId;
                                }
                                else {
                                    fixturePairsObj.firstId = null;
                                    fixturePairsObj.secondId = null;
                                    fixturePairsObj.matchId = null;
                                }
                                if (fixturePairs.length > 0) {
                                    fixturePairs = [...fixturePairs, fixturePairsObj];
                                }
                                else {
                                    fixturePairs = [{ ...fixturePairsObj }];
                                }
                                pairNo = no;
                            }
                            columNo++;
                            fixtureList.push({ 'fixturePairs': fixturePairs });
                        }
                        let fl = { 'poolId': ++i, 'fixtureList': fixtureList };
                        poolList.push(fl);
                    }
                    fixture.fixtureType2 = { 'fixturePoolList': poolList };
                    fixture.fixtureType2.finalMatch = fixtureFinal;
                    this.setState({ showFixture2: true })
                }
                else {
                    this.setState({ showPoolOption: true });
                }
            }
        }
    }
    saveFixtureType = () => {
        let teamUserArray = [];
        if (this.props.tournamentMode == 'team') {
            this.props.tournament.accepted.map((accepted, mIndex) => {
                let teamUserObj = { name: null, pic: null, id: null };
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === accepted) {
                        teamUserObj.name = team.teamName;
                        teamUserObj.id = team.teamId;
                        teamUserObj.pic = team.teamIcon;
                        teamUserObj.gender = team.gender;
                        teamUserArray.push(teamUserObj)
                    }
                })
            })
        }
        else {
            this.props.tournament.accepted.map((accepted, mIndex) => {
                let teamUserObj = { name: null, pic: null, id: null };
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === accepted) {
                        teamUserObj.name = user.name;
                        teamUserObj.id = user.contactNo;
                        teamUserObj.pic = user.profilePic;
                        teamUserObj.gender = user.gender;
                        teamUserArray.push(teamUserObj)
                    }
                })
            })
        }
        this.setState({ teamUserArray: teamUserArray, showUesrTeamForNextMatch: true, showFixture1: false, showTournamentType: false, showScheduleButton: false })
    }
    closeTeamUserForMatch() {
        if (teamUserSelect) {
            this.setState({ showUesrTeamForNextMatch: false })
        }
        else {
            this.setState({ showUesrTeamForNextMatch: false, showTournamentType: true, multiTeamsNext: [], errorTrue: false })
        }
    }
    getName = (id) => {
        if (this.props.tournamentMode === 'team') {
            let team = this.props.teamsInTournament.find(team => {
                if (id === team.teamId) {
                    return team;
                }
            })
            return team;
        }
        else {
            let user = this.props.usersInTournament.find(user => {
                if (id === user.contactNo) {
                    return user;
                }
            })
            return user;
        }
    }


    setTdateTime = (date) => {
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        if (this.state.fixtureType === 1) {
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].date = dateFormate;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].time = dateTime;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].dateObj = date;
        }
        else {
            if (this.state.poolIndex == 'SF') {
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].date = dateFormate;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].time = dateTime;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].dateObj = date;
            }
            else if (this.state.poolIndex == 'F') {
                fixture.fixtureType2.finalMatch.date = dateFormate;
                fixture.fixtureType2.finalMatch.time = dateTime;
                fixture.fixtureType2.finalMatch.dateObj = date;
            }
            else {
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].date = dateFormate;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].time = dateTime;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].dateObj = date;
            }
        }
        this.setState({ dateDate: dateFormate, dateTime: dateTime });
    }
    datepicker = (index, ind) => {
        this.setState({ fixtureListIndex: index, fixturePairIndex: ind })
    }
    location = (index, ind) => {
        this.setState({ fixtureListIndex: index, fixturePairIndex: ind })
    }
    datepickerF2 = (poolIndex, index, ind) => {
        this.setState({ poolIndex: poolIndex, fixtureListIndex: index, fixturePairIndex: ind })
    }
    locationF2 = (poolIndex, index, ind) => {
        this.setState({ poolIndex: poolIndex, fixtureListIndex: index, fixturePairIndex: ind })
    }
    datepickerSF = (ind) => {
        this.setState({ poolIndex: "SF", fixturePairIndex: ind })
    }
    locationSF = (ind) => {
        this.setState({ poolIndex: "SF", fixturePairIndex: ind })
    }
    datepickerF = (poolIndex, index, ind) => {
        this.setState({ poolIndex: "F", fixturePairIndex: ind })
    }
    locationF = (poolIndex, index, ind) => {
        this.setState({ poolIndex: "F", fixturePairIndex: ind })
    }

    callbackFunction = (childData) => {
        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        if (this.state.fixtureType === 1) {
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].location = childData.address;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].city = childData.city;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].state = childData.address;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].country = childData.country;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].latitude = childData.latValue;
            fixture.fixtureType1.fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].longitude = childData.lngValue;
        }
        else {
            if (this.state.poolIndex == 'SF') {

                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].location = childData.address;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].city = childData.city;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].state = childData.state;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].country = childData.country;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].latitude = childData.latValue;
                fixture.fixtureType2.semifinalList[this.state.fixturePairIndex].longitude = childData.lngValue;
            }
            else if (this.state.poolIndex == 'F') {
                fixture.fixtureType2.finalMatch.location = childData.address;
                fixture.fixtureType2.finalMatch.city = childData.city;
                fixture.fixtureType2.finalMatch.state = childData.state;
                fixture.fixtureType2.finalMatch.country = childData.country;
                fixture.fixtureType2.finalMatch.latitude = childData.latValue;
                fixture.fixtureType2.finalMatch.longitude = childData.lngValue;
            }
            else {
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].location = childData.address;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].city = childData.city;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].state = childData.state;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].country = childData.country;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].latitude = childData.latValue;
                fixture.fixtureType2.fixturePoolList[this.state.poolIndex].fixtureList[this.state.fixtureListIndex].fixturePairs[this.state.fixturePairIndex].longitude = childData.lngValue;
            }
        }
    }
    organiseTournament() {
        if (fixture.fixtureType1) {
            fixture.fixtureType1.fixtureList.map((fl, index) => {
                fl.fixturePairs.map((fp, ind) => {
                    delete fp.dateObj;
                });
            });
            let that = this;
            myTournamentUtilclass.createFixture(this.props.tournament.tournamentId, fixture).then(data => {
                window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            })
        }
        else {
            if (poolLength == 2) {
                fixture.fixtureType2.fixturePoolList[0].fixtureList.map((fl, index) => {
                    fl.fixturePairs.map((fp, ind) => {
                        delete fp.dateObj;
                    });
                });
                fixture.fixtureType2.fixturePoolList[1].fixtureList.map((fl, index) => {
                    fl.fixturePairs.map((fp, ind) => {
                        delete fp.dateObj;
                    });
                });
            }
            if (poolLength == 4) {
                fixture.fixtureType2.fixturePoolList[2].fixtureList.map((fl, index) => {
                    fl.fixturePairs.map((fp, ind) => {
                        delete fp.dateObj;
                    });
                });
                fixture.fixtureType2.fixturePoolList[3].fixtureList.map((fl, index) => {
                    fl.fixturePairs.map((fp, ind) => {
                        delete fp.dateObj;
                    });
                });
            }
            if (fixture.fixtureType2.semifinalList) {
                delete fixture.fixtureType2.semifinalList[0].dateObj;
                delete fixture.fixtureType2.semifinalList[1].dateObj;
            }
            delete fixture.fixtureType2.finalMatch.dateObj;
            let that = this;
            myTournamentUtilclass.createFixture(this.props.tournament.tournamentId, fixture).then(data => {
                window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            })
        }
    }

    render() {
        let disablePool = true;
        if (this.props.tournament.accepted) {
            if (this.props.tournament.accepted.length > 7) {
                disablePool = false;
            }
            else {
                disablePool = true;
            }
        }


        if (this.state.fixtureType === 1) {
            if (fixture.fixtureType1) {
                fixture1Ui = fixture.fixtureType1.fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);

                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepicker(index, ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.location(index, ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>

                            </li>
                        })}
                    </ul>
                })
            }
        }
        else {

            if (poolLength == 2 || poolLength == 4) {
                fixture2Ui1 = fixture.fixtureType2.fixturePoolList[0].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepickerF2(0, index, ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.locationF2(0, index, ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>
                            </li>
                        })}
                    </ul>
                })


                fixture2Ui2 = fixture.fixtureType2.fixturePoolList[1].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepickerF2(1, index, ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.locationF2(1, index, ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>
                            </li>
                        })}
                    </ul>
                })

            }
            if (poolLength == 4) {
                fixture2Ui3 = fixture.fixtureType2.fixturePoolList[2].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepickerF2(2, index, ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.locationF2(2, index, ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>

                            </li>
                        })}
                    </ul>
                })

                fixture2Ui4 = fixture.fixtureType2.fixturePoolList[3].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepickerF2(3, index, ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.locationF2(3, index, ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>

                            </li>
                        })}
                    </ul>
                })

            }

            if (this.state.showSemi) {

                if (fixture.fixtureType2.semifinalList) {

                    fixture2UiSF = fixture.fixtureType2.semifinalList.map((fp, ind) => {
                        let firstNameObj = null;
                        let secondNameObj = null;
                        let firstName;
                        let secondName;
                        let bracketNumber = ind + 1;
                        let classOfBracket = 'bracket bracket-' + bracketNumber;
                        if (this.props.tournamentMode === 'team') {
                            firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                        }
                        else {
                            firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                        }

                        return <ul className="bracket bracket-1">
                            <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date" onClick={() => this.datepickerSF(ind)} >
                                    <DatePicker
                                        selected={fp.dateObj}
                                        onChange={this.setTdateTime}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        minDate={new Date()}
                                        placeholderText="Click to select a date"
                                    />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location" onClick={() => this.locationSF(ind)}>
                                    <Map parentCallback={this.callbackFunction} />
                                    <img src={location} alt="" />
                                </div>

                            </li>
                        </ul>
                    })
                }
            }


            if (this.state.showFinal) {
                let fp = fixture.fixtureType2.finalMatch;
                let bracketNumber = 1;
                let classOfBracket = 'bracket bracket-' + bracketNumber;
                let firstNameObj = null;
                let secondNameObj = null;
                let firstName;
                let secondName;
                if (this.props.tournamentMode === 'team') {
                    firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                    secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                }
                else {
                    firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                    secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                }

                fixture2UiF = <ul className="bracket bracket-1">
                    <li className="team-item">
                        <div className="fixture_team"><span
                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                            <label>{firstName}</label>
                        </div>
                        <div className="fixture_team"><span
                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                            <label>{secondName}</label>
                        </div>
                        <div className="fixture_date" onClick={() => this.datepickerF(0, 0, 0)} >
                            <DatePicker
                                selected={fp.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />
                            <img src={scheduleMatches} alt="" />
                        </div>
                        <div className="fixture_date fixture_location" onClick={() => this.locationF(0, 0, 0)}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>

                    </li>
                </ul>
            }
        }

        return (
            <>
                <Modal
                    size="md"
                    show={this.state.showPoolOption}
                    animation={false}
                    onHide={() => this.closePoolOption()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closePoolOption()}>&times;</button>
                        <Modal.Title id="example-modal-sizes-title-md">
                            Select Pool
                    </Modal.Title>
                    </Modal.Header>
                    <div id="hidingIt2" className="schedule_pad">
                        <div className="cc_inner_box">
                            <label className="cc_box-head">Select Number Of Pool</label>
                            <div className="schedulebox">
                                <div className="styled-input-single createinput">
                                    <input type="radio" name="fieldset-5" id="checkbox-example-1" value="2" onChange={this.changeFixture2Pool.bind(this)} defaultChecked />
                                    <label for="checkbox-example-1">2 Pool</label>
                                </div>
                                <div className="styled-input-single createinput">
                                    <input type="radio" name="fieldset-5" id="checkbox-example-2" disabled={disablePool} value="4" onChange={this.changeFixture2Pool.bind(this)} />
                                    <label for="checkbox-example-2">4 Pool</label>
                                </div>
                                <hr className="tourhr" />
                                <button className="tour_player cmnbutton" onClick={() => this.selectPoolForFixture2()}
                                >Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="md"
                    show={this.state.showUesrTeamForNextMatch}
                    animation={false}
                    onHide={() => this.closeTeamUserForMatch()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeTeamUserForMatch()}>&times;</button>
                        <Modal.Title id="example-modal-sizes-title-md">
                            Select Buddies
                    </Modal.Title>
                    </Modal.Header>
                    <div className="modal-body">
                        <div className="ct_searchbuddies">
                        </div>
                        <ul className="buddieslist_ul ct_buddies">
                            {this.state.teamUserArray.map((items, index2) => {
                                let pic;
                                if (this.props.tournamentMode === 'team') {
                                    pic = items.pic ? items.pic : items.gender ? teamIcon : teamIcon;
                                }
                                else {
                                    pic = items.pic ? items.pic : items.gender ? maleIcon : femaleIcon;
                                }
                                var disableData1 = false;

                                return <li className="deselect" key={index2}>
                                    <a href="javascript:function() { return false; }" >
                                        <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                        <label>{items.name}</label>

                                    </a>
                                    <div className="styled-input-single">
                                        <input onChange={this.saveUserTeamForNext.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.id} />

                                        <label htmlFor={"checkBox" + index2}></label>
                                    </div>
                                </li>
                            })
                            }

                        </ul>
                        {this.state.errorTrue ? <label style={{ 'color': "red" }}>{errorMessage}</label> : ''}
                        <div className="modal-footer">
                            <button type="button" onClick={() => this.saveTeamUserForMatch()} className="cmnbutton">Done</button>
                        </div>

                    </div>
                </Modal>
                {this.state.showScheduleButton ? <div id="hidingIt" class="organized_tour-btn">
                    <button onClick={() => this.scheduleMatch()}>Schedule Matches</button>
                </div> : ''}

                {this.state.showTournamentType ? <div id="hidingIt2" className="schedule_pad">
                    <div className="cc_inner_box">
                        <label className="cc_box-head">Select Tournament Type</label>
                        <div className="schedulebox">
                            <div className="styled-input-single createinput">
                                <input type="radio" name="fieldset-5" id="checkbox-example-1" value="1" onChange={this.changeFixtureType.bind(this)} defaultChecked />
                                <label for="checkbox-example-1">Knockout</label>
                            </div>
                            <div className="styled-input-single createinput">
                                <input type="radio" name="fieldset-5" id="checkbox-example-2" disabled={disablePool} value="2" onChange={this.changeFixtureType.bind(this)} />
                                <label for="checkbox-example-2">Pool Based Knockout</label>
                            </div>

                            <hr className="tourhr" />
                            <button className="tour_player cmnbutton" onClick={() => this.saveFixtureType()}
                            >Select {this.props.tournamentMode}
                            </button>
                        </div>
                    </div>
                </div> : ''}
                {this.state.showFixture1 ?
                    <div class="tournament-container scrollbar" id="style-4">
                        <div class="tournament-brackets">
                            {fixture1Ui}
                        </div>
                    </div>
                    : ''}
                {this.state.showFixture2 ?
                    <>
                        {this.state.show2Pool ? <> <div class="semi_headingdiv semi-final">
                            <label >Pool 1</label>
                        </div>
                            <div class="tournament-container scrollbar" id="style-4">
                                <div class="tournament-brackets">
                                    {fixture2Ui1}
                                </div>
                            </div>
                            <div class="semi_headingdiv semi-final">
                                <label >Pool 2</label>
                            </div>
                            <div class="tournament-container scrollbar" id="style-4">
                                <div class="tournament-brackets">
                                    {fixture2Ui2}
                                </div>
                            </div></> : null}
                        {this.state.show4Pool ? <>  <div class="semi_headingdiv semi-final">
                            <label >Pool 3</label>
                        </div>
                            <div class="tournament-container scrollbar" id="style-4">
                                <div class="tournament-brackets">
                                    {fixture2Ui3}
                                </div>
                            </div>
                            <div class="semi_headingdiv semi-final">
                                <label >Pool 4</label>
                            </div>
                            <div class="tournament-container scrollbar" id="style-4">
                                <div class="tournament-brackets">
                                    {fixture2Ui4}
                                </div>
                            </div></> : null}
                    </> : ''}
                {this.state.showSemi ?
                    <>  <div class="semi_headingdiv semi-final">
                        <label >Pool Semi Final</label>
                    </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2UiSF}
                            </div>
                        </div>
                    </> : null
                }
                {this.state.showFinal ?
                    <>  <div class="semi_headingdiv semi-final">
                        <label >Pool Final</label>
                    </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2UiF}
                            </div>
                        </div>
                    </> : null
                }

                {
                    this.state.showOrganiseCreateButton ? <center>  <button type="button" onClick={() => this.organiseTournament()} class="createnext" type="submit">Organise Tournament</button>
                    </center> : ""
                }
            </>
        )
    }
}
