
import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import noBroadcast from '../img/noBroadcast.jpg';
import challengebg from "../img/challenegbg.png";
import openchallenge from "../img/openchallenge.png";
import challengefill from "../img/challengefill.png";
import userMaleIcon from "../img/male.png";
import play from '../img/play.png';
import teamIcon from '../img/team.png';
import location from "../img/location.png";
import matchbg from '../img/matchbg.png';
import winnerlogo from '../img/winnerlogo.png';
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
import challengevs from '../img/challengevs.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const userApi = ConfigApiUrl;
var userPic1;
var userName1;
var userPic;
var userName;
const that = this;

export default class Feed_d107Badminton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            userData1: [],
            teamData: [],
            acceptedChallenge: [],
            challengeData: {},
            scoreCardD104s: [],
            d107BadmintonScoreCard: {},
            bySetWon: [],
            toSetWon: [],

        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    componentDidMount() {

        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.props.challengeData.challengeId,
                    "matchId": that.props.challengeData.challengeId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.score !== null) {
                    that.setState({ d107BadmintonScoreCard: result.data.score.d107BadmintonScoreCard, userData1: result.data.users, teamData: result.data.teams, byBadmintonScoreCard: result.data.score.d107BadmintonScoreCard.byBadmintonScoreCard, toBadmintonScoreCard: result.data.score.d107BadmintonScoreCard.toBadmintonScoreCard })
                    that.setState({ byWinner: result.data.score.d107BadmintonScoreCard.byBadmintonScoreCard.winner })
                    that.setState({ toWinner: result.data.score.d107BadmintonScoreCard.toBadmintonScoreCard.winner })

                    if (result.data.score.d107BadmintonScoreCard.byBadmintonScoreCard) {
                        that.setState({ bySetWon: result.data.score.d107BadmintonScoreCard.byBadmintonScoreCard.setsWon ? result.data.score.d107BadmintonScoreCard.byBadmintonScoreCard.setsWon.length : 0 });

                    }
                    if (result.data.score.d107BadmintonScoreCard.toBadmintonScoreCard) {
                        that.setState({
                            toSetWon: result.data.score.d107BadmintonScoreCard.toBadmintonScoreCard.setsWon ? result.data.score.d107BadmintonScoreCard.toBadmintonScoreCard.setsWon.length : 0

                        })
                    }
                }
                else {

                    that.setState({ toSetWon: 0, bySetWon: 0 })
                }

            });


        that.setState({ challengeData: that.props.challengeData, acceptedChallenge: that.props.challengeData.accepted })

    }
    openChallengeProfile = (cid) => {
        window.location.href = "/challengeprofile/index.html?id=" + cid;
    }

    render() {
        let user, user1, pic, pic1, winners = false, winners1 = false;

        if (this.props.challengeData.challengeType === 'team') {
            this.props.teamData.map(team => {
                if (this.props.challengeData.accepted) {
                    if (team.teamId === this.props.challengeData.accepted[0]) {
                        user = team.teamName;
                        pic = team.teamIcon ? team.teamIcon : teamIcon;
                        if (this.state.byWinner === true) {
                            winners = true;
                        }
                    }
                    if (team.teamId === this.props.challengeData.accepted[1]) {
                        user1 = team.teamName;
                        pic1 = team.teamIcon ? team.teamIcon : teamIcon;
                        if (this.state.toWinner === true) {
                            winners = true;
                        }
                    }
                }
            })
        }
        else {
            this.props.userData.map(use => {
                if (this.props.challengeData.accepted) {
                    if (use.contactNo === this.props.challengeData.accepted[0]) {
                        user = use.name;

                        pic = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                    if (use.contactNo === this.props.challengeData.accepted[1]) {
                        user1 = use.name;
                        pic1 = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                }

            })
        }



        var OpenChallengeAccess = false;
        if ((this.state.challengeData.challengeAction === 'requested') && (this.state.challengeData.openChallenge === true)) {
            OpenChallengeAccess = true;
        }

        var acceptedUser = false;
        var oppositions = this.state.challengeData.oppositions ? this.state.challengeData.oppositions : [];
        oppositions.map(c => {
            if (this.state.userData1.contactNo === c) {
                acceptedUser = true;
            }
        })

        if (this.state.challengeData.openChallenge === true) {


            return (<React.Fragment>
                <div class="openchallenge-d">
                    <h5>Open Challenge</h5>
                    <img class="open_challengeimg" src={openchallenge} />
                    <p> {userName} has created an open challenge</p>
                    <span>Sports-{this.state.challengeData.sportsName}</span>

                    {this.state.showApply ? <button onClick={() => this.applyChallenge(this.state.challengeData.challengeId)} id="openchallenge-btn" type="btn" class="openchallenge-btn"> Accept Now</button> : acceptedUser ? <button id="openchallenge-btn" type="btn" class="openchallenge-btn"> Applied </button> : ''}

                </div>


            </React.Fragment>)
        }



        return (
            <React.Fragment>
                <div class="divpost">
                    <div class="teampost_inner" style={{
                        backgroundImage: "url(" + challengebg + ")"
                    }}>
                        <label class="teamhead">Challenge - {this.props.challengeData.sportsName}</label>
                        <ul class="postteam">
                            {this.state.userData1.map(u => {

                                if (this.state.byBadmintonScoreCard.id === u.contactNo) {
                                    user = u.name;
                                    if (this.state.byWinner === true) {
                                        winners = true;
                                    }
                                    if (u.profilePic !== null) {
                                        pic = u.profilePic;
                                    }
                                    else {
                                        if (u.gender === 'Female') {
                                            pic = femaleIcon;
                                        }
                                        else {
                                            pic = maleIcon;
                                        }
                                    }
                                }

                                if (this.state.toBadmintonScoreCard.id === u.contactNo) {
                                    user1 = u.name;
                                    if (this.state.toWinner === true) {
                                        winners1 = true;
                                    }
                                    if (u.profilePic !== null) {
                                        pic1 = u.profilePic;
                                    }
                                    else {
                                        if (u.gender === 'Female') {
                                            pic1 = femaleIcon;
                                        }
                                        else {
                                            pic1 = maleIcon;
                                        }
                                    }
                                }
                            })}
                            <li>
                                {winners ? <React.Fragment>
                                    <img src={winnerlogo} class="imgwinner" alt="" />
                                </React.Fragment> : ''}

                                <span class="teamimg" style={{
                                    backgroundImage: "url(" + pic + ")"
                                }}></span>
                                <label class="teamname">{user}</label>

                                {winners ? <React.Fragment>
                                    <label class="teamname_winner">WINNER</label>
                                </React.Fragment> : ''}

                            </li>
                            <li>
                                <img src={challengevs} class="vsimg" alt="" />
                                <span class="matchscore_text">Match Score</span><label class="matchscore">{this.state.bySetWon}-{this.state.toSetWon}</label>
                            </li>
                            <li>
                                {winners1 ? <React.Fragment>
                                    <img src={winnerlogo} class="imgwinner" alt="" />
                                </React.Fragment> : ''}

                                <span class="teamimg" style={{
                                    backgroundImage: "url(" + pic1 + ")"
                                }}></span>
                                <label class="teamname">{user1}</label>

                                {winners1 ? <React.Fragment>
                                    <label class="teamname_winner">WINNER</label>
                                </React.Fragment> : ''}

                            </li>

                        </ul>

                        <label class="matchdate"><span>Match Date : </span>{this.state.challengeData.challengeDate} <span>Time : {this.state.challengeData.challengeTime}</span></label>
                        <p class="teamlocation"><img src={location} alt="" />{this.state.challengeData.location}</p>

                        <div class="actiondiv">
                            <button onClick={() => this.openChallengeProfile(this.state.challengeData.challengeId)}>View Score</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>


        );
    }
}

