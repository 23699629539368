import React, { useState, useEffect } from 'react'
import * as pageApi from './ApiUtil';
import more from "./img/more.png";
import likedark from './img/likedark.png';
import comment from './img/comment.png';
import { Link } from 'react-router-dom';
import pageIconM from './img/pageIconM.png'
function PageFeed(props) {
    const [page, setPage] = useState({})
    const [users, setUsers] = useState([])
    const [isFollowed, setisFollowed] = useState(false)
    const [isLiked, setisLiked] = useState(false)
    const [feedReport, setFeedReport] = useState(false)
    // const reactedObj = {
    //     buddyId:,
    //     comment:,
    //     reactedType:
    // }


    useEffect(() => {
        console.log(props)
        pageApi.getPageProfile(props.feeds.moduleId).then(res => {
            console.log(res)
            if (res.data) {
                setisFollowed(res.data.isFollowed)
                setisLiked(res.data.isLiked)
                setPage(res.data.page)
                setUsers(res.data.users)
            }
        });
        return () => {

        }
    }, [])
    let createdByName = null;
    let createdByPic = null;
    users.map(userp => {
        if (userp.contactNo === page.createdBy) {
            createdByName = userp.name;
            createdByPic = userp.profilePic;
        }
    })
    const profileRedirection = (e) => {

        window.location = "/page/profile/" + page.id;
    }
    const newId = (e) => {
        setFeedReport(true);
    }
    const pageFollow = () => {
        pageApi.pageToggleFollow(page.id, isFollowed).then(res => {
            setisFollowed(!res.data.isFollowed)
        });
    }
    const pageLike = () => {
        pageApi.pageToggleLike(page.id, isLiked).then(res => {
            setisLiked(!res.data.isLiked)
        });
    }


    return (<>

        <div className="divpost sharepostdiv" id="accordion" key={page.id}><div className="userdetail">
            <ul className="postuserul" >
                <li className="p_userli">
                    <span onClick={profileRedirection} className="p_userimg" style={{
                        backgroundImage: "url(" + createdByPic + ")"
                    }}>
                    </span>
                    <label className="cursorPointer" onClick={profileRedirection}>{createdByName}</label>

                    <p className="p_usertime">{pageApi.getDateByTimeStamp(page.createdOn)}</p>

                    {/* <span className="p_usertime">{page.location}</span> */}
                </li>
                <li className="p_usermore">
                    <div className="dropdown dropaccount">
                        <button className="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" /></button>
                        <ul className="dropdown-menu">
                            <li>
                                <Link onClick={newId} data-toggle="modal" > Report Abuse </Link>

                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        </div>

        <div class="openchallenge-d page-feed-bg">
            <ul class="challenge_single_feeds" onClick={profileRedirection}>
                <li class="feeds_opponents_list single_opponent-d pagefeedss">
                    <img src={page.icon ? page.icon : pageIconM} alt="" />
                    <label>{page.name}</label>
                    <p class="page-feed-info">{createdByName} has Created a new sports page {page.name} </p>
                </li>
            </ul>

            <p class="openchallenge-location">Follow and like this page</p>
            {isLiked ? <button type="btn" class="openchallenge-btn pagefeedss-btn"> Liked</button> : <button type="btn" class="openchallenge-btn pagefeedss-btn" onClick={pageLike} > Like</button>}
            {isFollowed ? <button type="btn" class="openchallenge-btn pagefeedss-btn" style={{ backgroundColor: '#f4a219' }} > Followed </button> : <button type="btn" class="openchallenge-btn pagefeedss-btn" style={{ backgroundColor: '#f4a219' }} onClick={pageFollow}> Follow </button>}
        </div>
        <div class="postlikediv">
            <ul class="commentul">
                <li class="commentli"><img src={likedark} class="likeuser" alt="" /> <span
                    class="likespan">Like</span>
                </li>
                <li class="commentinner"><img src={comment} class="commentimg" /> <span>Comment<span
                    class="spancount">50</span></span></li>
            </ul>
        </div>
    </>
    )
}

export default PageFeed
