import React, { Component } from 'react'
import Modal from 'react-modal';
import * as TournamentsUtil from '../DemoTournament/DTournamentsUtil';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import { message } from 'antd';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
var numberOfAttempt;
var selectedWinner;
var d103ScoreCard = {}, score, points;
let organiseMatchObj = {};
var points = [];
let tId = null;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d103_Update_tt4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD103State: [],
            maxPoint: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
        this.inputPoint = this.inputPoint.bind(this);
        this.endGame = this.endEvent.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, setModalIsOpen: false, fillScoreModalOpen: false, winnerModalOpen: false });
    }

    doneMatch() {
        let wId = null;
        if (points.length === 1) {
            wId = points[0].userId;

        }
        else {
            wId = this.state.selectWinner;

        }
        tId = this.props.challenge.tournamentId;
        let mId = tId + '-' + wId;
        delete organiseMatchObj.id;

        if (organiseMatchObj.finals.ended) {
            var moment = require('moment');
            var now = moment().format();
            var timpstampData = new Date(now).valueOf();
            var tournament = this.props.challenge;
            delete tournament.id;
            tournament.status = "completed";
            tournament.modifiedOn = timpstampData;
            tournament.tournamentWinnerId = wId;

            TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
                console.log(data)
                this.setState({ winnerModalOpen: false })
                TournamentsUtil.ttWinnerFeeds(tId, mId, wId).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        window.location = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
                    })
                })
            })
        }
        else {
            TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
                this.setState({ winnerModalOpen: false })
                window.location = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
            })
        }
    }
    endEvent() {
        let scoreFill = this.state.scoreCardD103State;
        let allFill = true;
        numberOfAttempt = this.props.tt4.attempts;
        scoreFill.map(score => {
            if (score.points.length == numberOfAttempt) {

                allFill = true;
            }
            else {
                allFill = false;
            }
        })
        if (allFill) {
            if (this.props.matchType === -2) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)

                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                points = [];
                points = this.state.scoreCardD103State;
                organiseMatchObj.tt4Matches.map((tt4, index) => {
                    if (index === this.props.clickedIndex) {
                        tt4.ended = true;


                    }
                });

                console.log(points)
                this.setState({ winnerModalOpen: true });
            }
            if (this.props.matchType === -3) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)
                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                points = [];
                points = this.state.scoreCardD103State;
                organiseMatchObj.finals.ended = true;
                const max = this.state.scoreCardD103State.reduce((prev, current) => (prev.points > current.points) ? prev : current)
                this.state.scoreCardD103State.map((x, index) => {
                    if (x.points === max.points) {
                        points.push(x);
                    }
                });
                //console.log(points)
                this.setState({ winnerModalOpen: true });
            }
        }
        else {
            message.info('Please Fill All Field');
        }
    }
    componentDidMount() {

        let d103ScoreCard = [];

        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {

                if (this.props.clickedIndex === index) {
                    if (tt4.attempts >= 0) {
                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d103ScoreCard) {
                                d103ScoreCard.push({ userId: ml.playerId, points: ml.d103ScoreCard.points })
                            }
                            else {
                                d103ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                }
            })
        }
        if (this.props.matchType === -3) {
            if (this.props.tt4.finals.attempts >= 0) {
                numberOfAttempt = this.props.tt4.finals.attempts;
                this.setState({ modalIsOpen: false });
                this.props.tt4.finals.matchesList.map(ml => {

                    if (ml.d103ScoreCard) {

                        d103ScoreCard.push({ userId: ml.playerId, points: ml.d103ScoreCard.points })
                    }
                    else {

                        d103ScoreCard.push({ userId: ml.playerId, points: null })
                    }
                })
            }
            else {
                this.setState({ modalIsOpen: true });
            }
            console.log(d103ScoreCard)
        }
        this.setState({ scoreCardD103State: d103ScoreCard })
        console.log(this.state.scoreCardD103State)

    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }
    savePoint() {
        var scoreCardD103State = this.state.scoreCardD103State;

        if (this.state.playerPoint === '') {
            //  $('#errorId').show();
            this.setState({ errorShow: true });
        }
        else {
            let that = this;
            scoreCardD103State.map((point, index) => {
                if (index === this.state.setIndex) {
                    point.points = parseFloat(this.state.playerPoint);
                }
            });

            $("#myModal .close").click();
            this.setState({ playerPoint: '', organiseMatchObj: scoreCardD103State });
        }
        this.setState({ scoreCardD103State: scoreCardD103State })
        console.log(scoreCardD103State)

        organiseMatchObj = this.props.tt4;
        if (this.props.matchType === -2) {
            organiseMatchObj.tt4Matches.map((tt4, index) => {
                if (index === this.props.clickedIndex) {
                    tt4.matchesList.map((ml, ind) => {
                        if (scoreCardD103State[ind].points) {
                            ml.index = scoreCardD103State[ind].points.length;
                            ml.d103ScoreCard = {

                                points: scoreCardD103State[ind].points, points: scoreCardD103State[ind].points
                            }
                        }
                        else {
                            ml.d103ScoreCard = null
                        }
                    })
                }
            })
        }
        if (this.props.matchType === -3) {

            organiseMatchObj.finals.matchesList.map((ml, ind) => {
                if (scoreCardD103State[ind].points) {
                    ml.index = scoreCardD103State[ind].points.length;
                    ml.d103ScoreCard = {

                        points: scoreCardD103State[ind].points, points: scoreCardD103State[ind].points
                    }
                }
                else {
                    ml.d103ScoreCard = null
                }
            })
        }
        tId = this.props.challenge.tournamentId;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
        console.log(organiseMatchObj)
    }

    render() {

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {/* <div ref={subtitle => this.subtitle = subtitle}>
                            <h3>   winner </h3>
                            {maxWeights.map(weight => {
                                return <h3> {weight.userId} is  winner </h3>
                            })}
                        </div> */}
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">

                                {points.map((weight, index) => {
                                    var userName;
                                    selectedWinner = weight.userId;
                                    this.props.profileUsers.map(user => {

                                        if (weight.userId === user.contactNo) {
                                            userName = user.name
                                        }
                                    })
                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <div class="resinfo">
                                                <label>{userName} </label>
                                                <small> score:{weight.points} </small>
                                            </div>


                                        </a>
                                    </li>
                                })}
                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={this.closeModal}>Back</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>
                <div id="myModal1" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Enter Point</h4>
                            </div>
                            <div class="modal-body">
                                <center>
                                    <h5>Please Fill Score Point</h5></center>
                            </div>
                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen" data-toggle="modal" data-target="#myModal1" />

                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Challange Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul class="resultul">
                                    {this.state.maxPoint.map((weight, index) => {

                                        var userName, userPic;
                                        selectedWinner = weight.userId;
                                        this.props.profileUsers.map(user => {

                                            if (weight.userId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic;
                                            }
                                        })

                                        return <li style={{ 'display': 'flex' }}>
                                            <a class="winnerselect">
                                                <span class="resuserimg" style={{ backgroundImage: "url(" + userPic + ")" }}></span>
                                                <div class="resinfo">
                                                    <label>{userName} </label>
                                                    <small>Score:{weight.points} </small>
                                                </div>

                                                <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                    {/* <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} /> */}
                                                    <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                    <label htmlFor={index}></label>
                                                </div>
                                            </a>
                                        </li>
                                    })}

                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                <a data-dismiss="modal" class="cmnbutton editbtn">Edit</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />


                <div id="myModal" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Enter Point</h4>
                            </div>
                            <div class="modal-body">
                                <ul class="winnerdropdown_ul">
                                    <li>
                                        <input min="1" step="0.01" value={this.state.playerPoint} type="number" onChange={this.inputPoint} placeholder="Enter points" />
                                        {this.state.errorShow ? <h6 id="errorId" style={{ color: 'red' }} className="errorClass"> Please Enter Point First</h6> : ''}
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer table_actions">
                                <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.savePoint()} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        {/* <div class="broadcast_video">
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul class="winnerdropdown_ul">
                            {this.state.scoreCardD103State.map((accepted, index) => {
                                var name, contactNo, point;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;

                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter points" value={point} data-toggle="modal" data-target="#myModal" onClick={() => this.setState({ setIndex: index, errorShow: false })} />
                                </li>
                            })}
                        </ul>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl">Back</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
