import React, { Component } from "react";
import "./DefaultStyle.css";
import "./IndexStyle.css";
import sportsimg from "./img/sportsimg.svg";
import logoandshield from "./img/logoandshield.svg";
import flagicon from "./img/flagicon.svg";
import loginphone from "./img/loginphone.svg";
import lockimginput from "./img/lockimginput.svg";
import signinicon from "./img/signinicon.svg";
import facebookbtnicon from "./img/facebookbtnicon.svg";
import googlebtnicon from "./img/googlebtnicon.svg";
import playstoreimg from "./img/playstoreimg.svg";
import applestoreimg from "./img/applestoreimg.svg";
import loginBgIcon from "./img/loginbg.jpg";
import loginpattern from "./img/loginpattern.svg";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import AccountKit from "react-facebook-account-kit";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "react-flags-select/scss/react-flags-select.scss";
import ConfigUrl from "./ConfigUrl";
import { FacebookIcon } from "react-share";

const ConfigApiUrl = ConfigUrl("apiUrl");
const rshApi = ConfigApiUrl;

const countryApi = "https://restcountries.eu/rest/v2/";
const userApi = rshApi;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      country: "",
      username: "",
      password: "",
      submitted: false,
      loading: false,
      alpha2Code: [],
      altSpellings: [],
      countryCode: [],
      countryName: [],
      error: "",
    };
    this.logout();
    this.callSocialPlugin = this.callSocialPlugin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  logout = () => {
    localStorage.removeItem("result");
    localStorage.removeItem("logedIn");
  };

  componentDidMount() {
    const that = this;
    const apiUrl = countryApi + "all";
    fetch(apiUrl, {
      method: "get",
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        that.setState({ countryData: result });
        that.state.countryData
          .slice(0, that.state.countryData.length)
          .map((item, key) => that.state.alpha2Code.push(item.alpha2Code));
        that.setState({ countryCode: that.state.alpha2Code });
      });
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    e.preventDefault();
    const that = this;
    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { history } = this.props;
    const country = document.getElementsByClassName("country-label")[0]
      .innerHTML;
    const countryCodeUrl = countryApi + "name/" + country + "?fullText=true";
    fetch(countryCodeUrl, {
      method: "get",
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        if (!(country && username && password)) {
          return;
        } else {
          fetch(userApi + "users/authenticate-user", {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                contactNo: "+" + result[0].callingCodes[0] + username,
                password: password,
              },
            }),
          })
            .then(function (response) {
              return trackPromise(response.json());
            })
            .then(function (result) {
              console.log(result);
              if (result.error === true) {
                that.setState({ error: result.data });
              } else {
                localStorage.setItem("result", JSON.stringify(result.data));
                localStorage.setItem("logedIn", "yes");
                // history.push('/');
                window.location = "/";
              }
            });
        }
      });
  }
  loadSignUpSuccess(respBody) {
    console.log(respBody);
  }
  callSocialPlugin(response, type) {
    let that = this;
    const { history } = this.props;
    console.log(response);
    console.log(type);
    if (type === "google") {
      var data = {
        platformType: type,
        userId: response.profileObj.googleId.toString(),
        name: response.profileObj.name,
        email: response.profileObj.email,
        profilePic: response.profileObj.imageUrl,
        deviceId: null,
      };
    }
    if (type === "facebook") {
      var data = {
        platformType: type,
        userId: response.userID.toString(),
        name: response.name,
        email: response.email ? response.email : "NA",
        profilePic: null,
        deviceId: null,
      };
    }

    fetch(userApi + "/users/create-account", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        //   console.log(result);
        if (result.error === true) {
          that.setState({ error: result.data });
        } else {
          if (
            result.data.gender !== "NA" &&
            result.data.address !== "NA" &&
            result.data.password !== "NA" &&
            result.data.mobileVerified !== false &&
            result.data.name !== "NA" &&
            result.data.email !== "NA"
          ) {
            localStorage.setItem("result", JSON.stringify(result.data));
            localStorage.setItem("logedIn", "yes");
            // history.push('/');
            window.location = "/";
          } else {
            localStorage.setItem(
              "nonSessionUserData",
              JSON.stringify(result.data)
            );
            localStorage.setItem("logedIn", "yes");
            //  history.push('/');
            window.location = "/";
          }
        }
      });
  }

  render() {
    const responseFacebook = (response) => {
      var type = "facebook";
      this.callSocialPlugin(response, type);
    };

    const responseGoogle = (response) => {
      var type = "google";
      this.callSocialPlugin(response, type);
    };
    const { username, password, submitted, loading } = this.state;
    const loginBgStyle = {
      backgroundImage: "url(" + loginBgIcon + ")",
    };
    if (this.state.countryData.length > 0)
      this.items = this.state.countryData
        .slice(0, this.state.countryData.length)
        .map((item, key) => (
          <option key={key + 1} value={item.callingCodes[0]}>
            {item.name}
          </option>
        ));
    else
      this.items = this.state.countryData.slice(0, 0).map((item, key) => (
        <option key={key + 1} value={item.callingCodes[0]}>
          {item.name}
        </option>
      ));
    if (this.state.error)
      this.errorText = (
        <li>
          <div className="form-group">
            <div className="alert alert-danger login-page-alert">
              <strong>Error!</strong> {this.state.error}
            </div>
          </div>
        </li>
      );
    else this.errorText = "";
    return (
      <section
        className="loginbg-d"
        style={{ backgroundImage: "url(" + loginpattern + ")" }}
      >
        <div className="col-lg-7 col-md-6 col-sm-6 col-xs-12 padzero">
          <div className="sportsimg-d show-w">
            <img src={sportsimg} alt="" className="img-responsive" />
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 padzero">
          <div className="loginform">
            <div className="logoshield">
              <Link to={"/"} className="loginlogo_text">
                <img src={logoandshield} alt="logo" />
              </Link>
            </div>
            <div className="loginhead_div">
              <div className="loginhead">
                <label>A platform to optimize your sports activities</label>
                <p>
                  to have more fun, build a better career, do better business.{" "}
                </p>
              </div>
              <form name="loginForm" onSubmit={this.handleSubmit}>
                <ul className="loginul_form">
                  <li>
                    <div className="commaninputdiv">
                      <ReactFlagsSelect
                        countries={this.state.countryCode}
                        customLabels={{ IN: "India" }}
                        showOptionLabel={true}
                        defaultCountry="IN"
                        placeholder="Select Country"
                        className="question"
                        name="country"
                        onChange={this.handleChange}
                        searchable={true}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        "commaninputdiv" +
                        (submitted && !username ? " has-error" : "")
                      }
                    >
                      <input
                        type="number"
                        className="question-d"
                        name="username"
                        required="false"
                        value={username}
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                      <label htmlFor="">
                        {" "}
                        <img
                          className="inputicon-d"
                          src={loginphone}
                          alt="mobile"
                        />
                        <span>Contact Number</span>
                      </label>
                      {submitted && !username && (
                        <div className="help-block">
                          Contact number is required
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div
                      className={
                        "commaninputdiv" +
                        (submitted && !password ? " has-error" : "")
                      }
                    >
                      <input
                        type="password"
                        className="question-d"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        required="false"
                        autoComplete="off"
                      />
                      <label htmlFor="">
                        {" "}
                        <img
                          className="inputiconlock-d"
                          src={lockimginput}
                          alt="lock"
                        />
                        <span>Password</span>
                      </label>
                      {submitted && !password && (
                        <div className="help-block">Password is required</div>
                      )}
                    </div>
                  </li>
                  {this.errorText}
                  <li className="loginforgot">
                    <Link to={"/forgotPassword"}>Forgot Password</Link>
                    <button>
                      SIGN IN{" "}
                      <img className="signinicon-d" src={signinicon} alt="" />
                    </button>
                  </li>
                </ul>
              </form>
              <ul className="twobtn-d">
                <li className="facebookbtn">
                  {/* <FacebookLogin

                    appId="511061079806171" //APP ID NOT CREATED YET
                    fields="name,email,picture"
                    callback={responseFacebook}

                    cssClass="facebookbtn-d"
                    icon={googlebtnicon}

                  /> */}
                  <FacebookLogin
                    appId="511061079806171"
                    // appId="418284239500234"
                    icon="fa-facebook"
                    callback={responseFacebook}
                    cssClass="facebookbtn-d"
                    textButton="LOGIN WITH FACEBOOK"
                  />
                  {/* <button className="facebookbtn-d"><img src={facebookbtnicon} alt="" />LOGIN WITH FACEBOOK</button> */}
                </li>
                <li className="googlebtn">
                  {/* <button className="googlebtn-d"> <img src={googlebtnicon} alt="" />LOGIN WITH GOOGLE</button> */}
                  <GoogleLogin
                    // clientId="116191936712-ldt7908rfkc0b9mbvhkj0tetbns40lda.apps.googleusercontent.com"
                    clientId="385669889254-h1dqdilrf0p7q280v7ngqfggs4tusrnf.apps.googleusercontent.com"
                    buttonText="LOGIN WITH GOOGLE"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className="googlebtn-d"
                      >
                        {" "}
                        <img src={googlebtnicon} alt="" />
                        LOGIN WITH GOOGLE
                      </button>
                    )}
                  />
                </li>
              </ul>
              <div className="signuptag">
                <p>
                  Dont Have Account ?
                  <span>
                    {" "}
                    <Link to={"/Registration"}> SIGN UP</Link>
                  </span>
                </p>
              </div>
              <div className="storeimg-d">
                <img
                  className="googleplayimg"
                  onClick={() =>
                    (window.location.href =
                      "https://play.google.com/store/apps/details?id=com.sportsapp.ssp")
                  }
                  src={playstoreimg}
                  alt=""
                />
                <img
                  className="appleimg"
                  onClick={() =>
                    (window.location.href =
                      "https://apps.apple.com/app/id1437392923")
                  }
                  src={applestoreimg}
                  alt=""
                />
              </div>
              <div className="sportsimg-d hide-w">
                <img src={sportsimg} alt="" className="img-responsive" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LoginPage;
