import React, { Component } from "react";
import Header from "./Header";
import SectionRight from "./SectionRight";
import userMaleIcon from "./img/male.png";
import profilebg from "./img/profilebg.png";
import broadcastbg from "./img/broadcastbg.png";
import team from "./img/team.png";
import play from "./img/play.png";
import userFemaleIcon from "./img/female.png";
import sad from './img/sad.png';
import { trackPromise } from "react-promise-tracker";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Buddies from "./Buddies";
import BuddiesTeams from './BuddiesTeams';
import Posts from './Posts';
import Following from "./Following";
import Follower from "./Follower";
import Broadcasts from './Broadcasts';
import ViewAbout from './ViewAbout';
import { Link } from 'react-router-dom';
import ConfigUrl from './ConfigUrl';
import camera from './img/camera.png';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const videoUrl = ConfigUrl('videoUrl');
let nNum;
class BuddiesProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            visible: 10,
            sr: 0,
            error: false,
            user: "",
            users: [],
            posts: [],
            broadcasts: [],
            followings: "",
            followers: "",
            buddies: [],
            sports: [],
            buddyCount: false,
            buddiesDetails: false,
            teamsDetails: false,
            broadcastsDetails: false,
            postsDetails: false,
            userData: [],
            teamsCount: false,
            broadcastsCount: false,
            viewAbout: false,
            postsCount: false,
            showFollowing: false,
            showFollower: false,
            isbuddy: false,
            buddyNumber: '',
            buddyProfile: [],
            textRequest: 'Send Request'
        };
        this.loadsports = this.loadsports.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem("result"));
        this.isBuddy = this.isBuddy.bind(this);

    }
    // static getDerivedStateFromProps(nextProps, prevState) {   
    //     nNum = nextProps.location.search;
    //     return nNum;  
    // }    
    sendRequest = () => {
        const that = this;
        fetch(userApi + "users/send-buddy-request", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.buddyNumber,
                    userId: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ textRequest: 'Requested' });
                that.loadMore();
            });
    }
    isBuddy = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let buddyNumber = urlParams.get('id');
        buddyNumber = "+".concat(buddyNumber.trim());
        const that = this;
        fetch(userApi + "users/get-buddies", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                if (result.data === "No buddies found") {

                }
                else {
                    result.data.map(x => {
                        if (x.contactNo === buddyNumber) {
                            that.setState({ isbuddy: true, buddyNumber: buddyNumber });
                        }
                    });
                }
            });
    }
    loadMore = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let buddyNumber = urlParams.get('id');

        buddyNumber = "+".concat(buddyNumber.trim());
        const that = this;
        fetch(userApi + "users/buddy-profile", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: buddyNumber,
                    userId: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (!result.error) {
                    if (result.data.teams.length < 1) {
                        that.setState({ teamsCount: true });
                    }
                    if (result.data.buddies.length < 1) {
                        that.setState({ buddyCount: true });
                    }
                    if (result.data.broadcasts.length < 1) {
                        that.setState({ broadcastsCount: true });
                    }
                    that.setState({
                        buddyProfile: result.data,
                        buddyNumber: buddyNumber,
                        posts: result.data.post.posts,
                        users: result.data.post.users,
                        sports: result.data.sports,
                        teams: result.data.teams,
                        buddies: result.data.buddies,
                        broadcasts: result.data.broadcasts,
                        followings: result.data.followings,
                        followers: result.data.followers,
                        userData: result.data.user,
                        postsDetails: true,

                    });
                    if (result.data.post.posts.length === 0) {
                        that.setState({ postsDetails: false, postsCount: true });
                    }
                }
            });
    };
    loadsports = () => {
        fetch(userApi + "sports/get-sports", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    minimal: true
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
            });
    };
    componentDidMount() {
        this.loadMore();
        this.loadsports();
        this.isBuddy();
    }
    changeAcceptReject = (e) => {
        if (e.target.value === 'respond') {

        }
        else {
            fetch(userApi + "users/react-buddy-request", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    data: {
                        buddyId: this.state.buddyNumber,
                        userId: this.state.user.contactNo,
                        type: e.target.value
                    }
                })
            }).then(function (response) {
                return trackPromise(response.json());
            }).then(function (result) {
                console.log(result);
            });
        }
    }
    openBroadcast = (id) => {
        const url = videoUrl + id + ".mp4";
        confirmAlert({
            customUI: () => {
                return (
                    <div className="postdata">
                        <iframe title="broadcast" id="video1" src={url} frameborder="0" allowtransparency="true" allowfullscreen></iframe>
                        <p></p>
                    </div>
                );
            }
        });
    };

    render() {
        var buddyNumber = this.state.buddyNumber;
        this.profileData = (
            <section
                className="profilesection"
                style={{ backgroundImage: "url(" + profilebg + ")" }}
            >
                {/* <button className="uploadbanner"><span>Upload Banner</span><img src={camera} alt="" />
                </button> */}
                <span className="overlayprofie"></span>
                <div className="container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="profilesec">
                            <div className="profilesec_inner">
                                {(() => {
                                    if (this.state.userData.profilePic === null) {
                                        switch (this.state.userData.gender) {
                                            case "Male":
                                                return (
                                                    <span
                                                        className="profilespan"
                                                        style={{
                                                            backgroundImage: "url(" + userMaleIcon + ")"
                                                        }}
                                                    >
                                                        {" "}
                                                    </span>

                                                );
                                            case "Female":
                                                return (
                                                    <span
                                                        className="profilespan"
                                                        style={{
                                                            backgroundImage: "url(" + userFemaleIcon + ")"
                                                        }}
                                                    >
                                                        {" "}
                                                    </span>
                                                );
                                            default: return '';
                                        }
                                    }
                                    else {
                                        return (
                                            <span
                                                className="profilespan"
                                                style={{
                                                    backgroundImage: "url(" + this.state.userData.profilePic + ")"
                                                }}
                                            >
                                                {" "}
                                            </span>
                                        );
                                    }
                                })()}

                            </div>
                            <label className="profusername">{this.state.userData.name}</label>
                            <ul className="followingul" style={{ 'cursor': 'pointer' }}>
                                <li  >
                                    <span onClick={event => this.setState({ showFollowing: true, viewAbout: false, buddiesDetails: false, postsDetails: false, teamsDetails: false, broadcastsDetails: false, showFollower: false, postsCount: false })}>
                                        <label>{this.state.followings}</label>
                                        <span>Followings</span>
                                    </span>
                                </li>
                                <li>
                                    <span onClick={event => this.setState({ showFollower: true, showFollowing: false, viewAbout: false, buddiesDetails: false, postsDetails: false, teamsDetails: false, broadcastsDetails: false, postsCount: false })}>
                                        <label>{this.state.followers}</label>
                                        <span>Followers</span>
                                    </span>
                                </li>
                                {/* <li>
                  <a>
                    <label>{this.state.buddyCount}</label>
                    <span>Buddies</span>
                  </a>
                </li> */}
                            </ul>

                            {(() => {
                                switch (this.state.buddyProfile.isBuddy) {
                                    case true:
                                        return <button className="changesports" >Buddy</button>;
                                    case false:
                                        switch (this.state.buddyProfile.isRequested) {
                                            case true:
                                                return <button className="changesports" >Requested</button>;
                                            case false:
                                                switch (this.state.buddyProfile.toAccept) {
                                                    case true:
                                                        return <select className="changesports" onChange={(e) => this.changeAcceptReject(e)} >
                                                            <option value="respond">Respond</option>
                                                            <option value="accept"> Accepted</option>
                                                            <option value="reject">Rejected</option>
                                                        </select>;
                                                    case false:
                                                        return <button className="changesports" onClick={event => this.sendRequest()} >Send Request</button>;
                                                }

                                        }
                                    default:
                                        return '';
                                }
                            })()}
                        </div>
                        <div>
                            <li>
                                <span></span>
                            </li>
                        </div>
                    </div>
                </div>
            </section>
        );

        this.favouriteSports = (
            <section>
                <div className="container">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  pad7">
                        <div className="skills">
                            <ul>
                                {this.state.sports.map(x => {
                                    return (
                                        <li key={x.sportsId}>
                                            <Link to={'#'}>
                                                <span
                                                    style={{ backgroundImage: "url(" + x.icon + ")" }}
                                                    className="skillsimg"
                                                ></span>
                                                <span className="sportsname">{x.name}</span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );

        this.leftSection = (
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7 leftsecprof userleft">
                <section className="d_leftsec useraccount">
                    <div className="commanbox ">
                        <h3 className="commanhead">
                            About Us <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: false, postsDetails: false, broadcastsDetails: false, viewAbout: true, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>
                        </h3>
                        <div className="aboutleftdata">
                            <p>{this.state.userData.email}</p>
                            <p>{this.state.userData.contactNo}</p>
                        </div>

                        <h3 className="commanhead">
                            Buddies {this.state.buddyCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: true, postsDetails: false, broadcastsDetails: false, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
                        </h3>
                        {this.state.buddyCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                            <label > Sorry you don't have any buddies</label><br />
                            {/* <Link style={{ color: "#52af4a" }}> Invite Now</Link> */}

                        </div> :
                            <ul className="aboutbuddies">
                                {this.state.buddies.map(x => {
                                    return (() => {
                                        if (x.profilePic === null) {
                                            switch (x.gender) {
                                                case "Male":
                                                    return (
                                                        <li key={x.contactNo}>
                                                            <a href={`/buddies?id=${x.contactNo}`}
                                                                style={{
                                                                    backgroundImage: "url(" + userMaleIcon + ")"
                                                                }}
                                                            >
                                                                {" "}
                                                            </a>
                                                            <span>{x.name}</span>
                                                        </li>
                                                    );
                                                case "Female":
                                                    return (
                                                        <li key={x.contactNo}>
                                                            <a href={`/buddies?id=${x.contactNo}`}
                                                                style={{
                                                                    backgroundImage: "url(" + userFemaleIcon + ")"
                                                                }}
                                                            >
                                                                {" "}
                                                            </a>
                                                            <span>{x.name}</span>
                                                        </li>
                                                    );
                                                default: return '';
                                            }
                                        }
                                        else {
                                            return (
                                                <li key={x.contactNo}>
                                                    <a href={`/buddies?id=${x.contactNo}`}
                                                        style={{
                                                            backgroundImage: "url(" + x.profilePic + ")"
                                                        }}
                                                    >
                                                        {" "}
                                                    </a>
                                                    <span>{x.name}</span>
                                                </li>
                                            );
                                        }
                                    })();
                                })}
                            </ul>
                        }
                        <h3 className="commanhead">
                            Teams{this.state.teamsCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: true, buddiesDetails: false, postsDetails: false, broadcastsDetails: false, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
                        </h3>
                        {this.state.teamsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                            <label > Sorry you don't have any teams</label><br />
                            {/* <Link style={{ color: "#52af4a" }}> create Team</Link> */}

                        </div> :
                            <ul className="aboutteamsul">
                                {this.state.teams.map(function (x) {
                                    if (x.teamIcon === null) {
                                        return (
                                            <li key={x.teamName}>
                                                <a href={"/teamprofilepage/index.html?id=" + x.teamId} style={{ backgroundImage: "url(" + team + ")" }}></a>
                                                <span>{x.teamName}</span>
                                            </li>
                                        );
                                    }
                                    else {
                                        return (
                                            <li key={x.teamName}>
                                                <a href={"/teamprofilepage/index.html?id=" + x.teamId} style={{ backgroundImage: "url(" + x.teamIcon + ")" }}></a>
                                                <span>{x.teamName}</span>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        }
                        <h3 className="commanhead">
                            Broadcast {this.state.broadcastsCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: false, postsDetails: false, broadcastsDetails: true, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
                        </h3>
                        {this.state.broadcastsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                            <label > Sorry you don't have any broadcasts</label><br />
                            {/* <Link style={{ color: "#52af4a" }}> create now</Link> */}

                        </div> :
                            <ul className="aboutbroadcast" >
                                {this.state.broadcasts.map((x) => {
                                    if (x.icon === null) {
                                        return (
                                            <li key={x.broadcastId} onClick={() => this.openBroadcast(x.broadcastId)} >
                                                <Link style={{ backgroundImage: "url(" + broadcastbg + ")" }}>
                                                    <img src={play} alt="" />
                                                </Link>
                                            </li>
                                        );
                                    }
                                    else {
                                        return (
                                            <li key={x.broadcastId}>
                                                <Link style={{ backgroundImage: "url(" + x.icon + ")" }}>
                                                    <img src={play} alt="" />
                                                </Link>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        }

                    </div>
                </section>
            </div>
        );
        return (
            <div>
                <Header />
                {this.profileData}
                {this.favouriteSports}
                <div className="container profilecontainer">
                    {this.leftSection}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            {this.state.showFollower ? <Follower userbuddy={buddyNumber} /> : <div></div>}
                            {this.state.showFollowing ? <Following userbuddy={buddyNumber} /> : <div></div>}
                            {this.state.viewAbout ? <ViewAbout userbuddy={buddyNumber} /> : <div></div>}
                            {this.state.buddiesDetails ? <Buddies userbuddy={buddyNumber} permission={false} /> : <div></div>}
                            {this.state.teamsDetails ? <BuddiesTeams userbuddy={buddyNumber} permission={false} /> : <div></div>}
                            {this.state.postsDetails ? <Posts userbuddy={buddyNumber} permission={false} /> : <div></div>}
                            {this.state.postsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                                <label > Sorry you don't have any posts</label><br />
                                {/* <label style={{ color: "#52af4a" }}> create posts</label> */}

                            </div> : <div></div>}
                            {this.state.broadcastsDetails ? <Broadcasts userbuddy={buddyNumber} broadcasts={this.state.broadcasts} permission={false} /> : <div></div>}

                            {this.editFavouriteSports}
                        </section>
                    </div>
                    <SectionRight />
                </div>
            </div>
        );
    }
}

export default BuddiesProfile;
