import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;


export default class Tournament_d107_Archery_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            rounds: [],
        };

    }
    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);

        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    user: result.data.users
                })
                if (result.data.score !== null) {
                    if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== undefined) {
                        if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                            that.setState({ bySetWon: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points.reduce(function (acc, val) { return acc + val; }, 0), byWinner: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.winner });

                        }
                        else {
                            that.setState({ byWinner: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.winner });

                        }
                        that.setState({ matchStatus: true });
                    }
                    if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== undefined) {
                        if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                            that.setState({ toSetWon: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points.reduce(function (acc, val) { return acc + val; }, 0), toWinner: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.winner });

                        }
                        else {
                            that.setState({ toWinner: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.winner });

                        }
                        that.setState({ matchStatus: true });
                    }


                    that.setState({ rounds: result.data.score.d107ArcheryScoreCard.rounds, scorecard: result.data.score.d107ArcheryScoreCard })

                    // that.props.profileUsers.map(user => {
                    that.state.user.map(user => {
                        if (user.contactNo === result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id) {
                            that.setState({ byArcheryScoreCard: user.name });
                        }
                        if (user.contactNo === result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id) {
                            that.setState({ toArcheryScoreCard: user.name });
                        }
                    })

                }
            });
    }
    render() {
        return (
            <div>
                hey
            </div>
        )
    }

    render() {
        //   const backLinks = "/challengeprofile/index.html?id=5eb27aa34706de77e7c002b9";
        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;
        if (this.state.matchStatus) {
            return (
                <React.Fragment>


                    <section className="d_midsec createmidsec broadcast_box">
                        <div className="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>

                                                <th>Score</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byArcheryScoreCard}</td>
                                                <td>{this.state.bySetWon}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toArcheryScoreCard}</td>
                                                <td>{this.state.toSetWon}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h4 className="scorehead">Match Score</h4>
                            <div className="tableresponsive matchscore_table ">
                                <div className="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Players</th>
                                                <th>Board 1</th>
                                                <th>Board 2</th>
                                                <th>Board 3</th>
                                                <th>Board 4</th>
                                                <th>Board 5</th>
                                                <th>Total</th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>{this.state.byArcheryScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0 && i < 6) {
                                                        return <td> {k.challengedByPoints} </td>
                                                    }

                                                })}
                                                <td>{this.state.bySetWon}</td>
                                            </tr>
                                            <tr>
                                                <td>{this.state.toArcheryScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0 && i < 6) {
                                                        return <td> {k.challengedToPoints} </td>
                                                    }

                                                })}
                                                <td>{this.state.toSetWon}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div></section>

                </React.Fragment>
            );
        }
    }
}






