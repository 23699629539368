import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import filter from '../../img/filter.png';
import NoTeam from "../../img/mytournament.svg";
import tour from '../../img/tournamentprofileimg.jpg';
import location from '../../img/location.png';
import DTournamentList from '../../DemoTournament/DTournamentList';
import CreateTournamentPage from '../../CreateTournamentPage';
import ConfigUrl from '../../ConfigUrl';
import Modal from 'react-bootstrap/Modal';
import Header from '../../Header';
import SectionLeft from '../../SectionLeft';
import SectionRight from '../../SectionRight';
import inter from '../../img/inter.svg';

const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

class CreatePageTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournamentList: [],
            tournamentListNew: [],
            participating: [],
            Organizing: [],
            showCreatePart: true,
            showListPart: false,
            showListDemo: false,
            openForChooseChalengeType: true,
            pageTournamentType: null,
        }
        this.myRef = React.createRef()
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    componentDidUpdate() {
        this.handleScroll();
        // const { match } = this.props;
        // console.log(match.params.pageId)

    }
    componentDidMount() {

        const getAllTourList = rshApi + "/tournaments/get-tournaments";
        const that = this;
        var Organizing = [], participating = [];
        fetch(getAllTourList, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "type": "both",
                    "contactNo": this.state.user.contactNo
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result);
                result.data.map(x => {
                    //console.log(x);
                    if (x.type === 'Organizing') {
                        Organizing.push(x);
                    }
                    if (x.type === "participating") {
                        participating.push(x);
                    }
                    that.setState({
                        data: "Both", tournamentList: result.data, tournamentListNew: result.data,
                    });
                })
                that.setState({
                    Organizing: Organizing, participating: participating,
                });
                //console.log(that.state.Organizing);
                //console.log(that.state.participating);
                //console.log(that.state.tournamentList);
            });
    }
    createTornament = () => {
        this.setState({ showCreatePart: true, showListPart: false, showListDemo: false });
    }

    createDemoTornament = () => {
        this.setState({ showCreatePart: false, showListPart: false, showListDemo: true });
    }

    changeFilter = (type) => {
        if (type === 'Organizing') {
            console.log(this.state.Organizing)
            this.setState({
                data: 'Organizing', tournamentList: this.state.Organizing
            })
        }
        if (type === 'participating') {
            console.log(this.state.participating)
            this.setState({
                data: 'participating', tournamentList: this.state.participating,
            })
        }
        if (type === 'Both') {
            this.setState({
                data: 'Both', tournamentList: this.state.tournamentListNew,
            })
        }
    }
    handleScroll = () => {
        const { index, selected } = this.props

        if (index === selected) {
            setTimeout(() => {
                this.myRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1)
        }
    }
    render() {
        return (
            <><Modal
                size="md"
                show={this.state.openForChooseChalengeType}
                onHide={this.closeLocation}
                animation={false}
                aria-labelledby="example-modal-sizes-title-md"
            >

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{
                    padding: '0', marginTop: '10px'
                }}
                >

                    <div class="intra-inters" >
                        <div class="part-white">

                        </div>
                        <div class="part-green">

                        </div>
                        <div class="img-txtdiv">
                            <img src={inter} class="col-lg-6 col-md-6 col-sm-12 col-xs-12" />
                            <div class="intra-txtdiv col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h3>INTRA</h3>
                                <p>Create a Tournament which will be only visible with in your page and your page team can only participate</p>
                                <button onClick={() => this.setState({ pageTournamentType: 'intra', openForChooseChalengeType: false })} className="intra-enterbtn">go with Intra</button>
                            </div>
                        </div>
                    </div>


                    <div class="intra-inters btm-spcnone" >
                        <div class="part-offwhite">

                        </div>
                        <div class="part-green">

                        </div>
                        <div class="img-txtdiv">

                            <div class="inter-txtdiv intra-txtdiv col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <h3>INTER</h3>
                                <p>Create open Tournament where anybody on our playdiator plateform can participate</p>
                                <button onClick={() => this.setState({ pageTournamentType: 'inter', openForChooseChalengeType: false })} className="intra-enterbtn">go with Inter</button>
                            </div>
                            <img src={inter} class="col-lg-6 col-md-6 col-sm-12 col-xs-12 interimg" />
                        </div>
                    </div>

                </div>
            </Modal>
                <Header />
                <div className="container containerbottom non-session-d" ref={this.myRef}>
                    {this.state.user ? <SectionLeft /> : ''}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            <React.Fragment>
                                {this.state.showListPart ? <div className="commanbox">
                                    <h3 className="commanhead">Tournament List</h3>
                                    <ul className="challangebutton">
                                        <li onClick={() => this.createTornament()}><Link to={'#'} className="cb_link">Create Tournament</Link></li>
                                        <li ><Link to={'/demo-tournament-list'} className="cb_link">Demo Tournament</Link></li>
                                        <li>
                                            <div className="dropdown ct_drop">
                                                <Link to={'#'} className=" dropdown-toggle" data-toggle="dropdown"><img src={filter} alt="" /> </Link>
                                                <ul className="dropdown-menu">
                                                    <li><Link onClick={() => this.changeFilter('Both')} to={'#'}>Both</Link></li>
                                                    <li><Link onClick={() => this.changeFilter('Organizing')} to={'#'}>Organizing</Link></li>
                                                    <li><Link onClick={() => this.changeFilter('participating')} to={"#"}>Participating</Link></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="cl_box_div">
                                        <ul className="cl_box_ul">
                                            {this.state.tournamentList.length ? this.state.tournamentList.map(item => {
                                                if (item.tournamentIcon !== null) {
                                                    switch (this.state.data) {
                                                        case "Both":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + item.tournamentIcon + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        case "Organizing":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + item.tournamentIcon + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        case "participating":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + item.tournamentIcon + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        default:
                                                            return '';
                                                    }
                                                }
                                                else {
                                                    switch (this.state.data) {
                                                        case "Both":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + tour + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        case "Organizing":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + tour + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        case "participating":
                                                            return <li key={item.tournamentId}>
                                                                <Link to={"/tournamentprofile/index.html?id=" + item.tournamentId}>
                                                                    <div className="cl_info_div">
                                                                        <span className="cl_userbg" style={{ backgroundImage: "url(" + tour + ")" }}></span>
                                                                        <label className="cl_title">{item.tournamentName}</label>
                                                                        <span className="cl_time"><span className="tourdate">Start Date : </span>{item.startDate}</span>
                                                                        <span className="cl_type"><span>Type : </span>{item.type}</span>
                                                                    </div>
                                                                    <div className="cl_location_div">
                                                                        <p><img src={location} alt="" />{item.location}</p>
                                                                        <span><span>Sports : </span>{item.sportsName}</span>
                                                                    </div>
                                                                </Link>
                                                            </li>

                                                        default:
                                                            return '';
                                                    }
                                                }
                                            }) : <div className="broadcast_video">
                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                                <h3>Sorry you don't have any Tournaments.</h3>
                                            </div>}
                                        </ul>
                                    </div>
                                </div> : ""}
                                {this.state.showCreatePart ?
                                    <CreateTournamentPage {...this.props} pageTournamentType={this.state.pageTournamentType} />
                                    : ""}

                                {this.state.showListDemo ?
                                    <DTournamentList />
                                    : ""}

                            </React.Fragment>
                        </section>
                    </div>
                    <SectionRight />

                </div>
            </>
        )
    }
}

export default CreatePageTournament;