import React, { Component } from "react";
import team from "../../img/team.png";
import NoTeam from "../../img/mytournament.svg";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from '../../ConfigUrl';
import Header from '../../Header';
import filter from '../../img/filter.png'
import SectionLeft from '../../SectionLeft';
import SectionRight from '../../SectionRight';
import explaner2 from "../../img/playdiator-explaner-complete.jpg";
import TeamProfile from '../../TeamProfile';
import { Link } from "react-router-dom";
import { } from 'prismic-reactjs';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

class TeamList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            teamsNew: [],
            owner: [],
            member: [],
            showTeamProfile: false,
            teamId: '',
            showTeamList: true,
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    openTeamProfile = (teamId) => {
        this.setState({ showTeamProfile: true, teamId: teamId, showTeamList: false })
    }
    loadMore = () => {
        const that = this;
        const { match } = this.props;
        console.log(match)
        let pageId = match.params.pageId;

        var owner = [], member = [];
        fetch(userApi + "teams/get-page-teams", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    pageId: pageId,
                    index: 0,
                    itemsPerIndex: 10
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== undefined) {
                    result.data.map(x => {
                        if (x.createdBy === that.state.user.contactNo) {
                            owner.push(x);
                        }
                        if (x.createdBy !== that.state.user.contactNo) {
                            member.push(x);
                        }
                        that.setState({
                            data: 'Both',
                            teams: result.data,
                            teamsNew: result.data,
                        });
                    })
                    that.setState({
                        owner: owner,
                        member: member
                    });
                }
            });
    };
    componentDidMount() {
        this.loadMore();
    }
    getCreatedTime = (createdOn) => {
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(createdOn);
        return formattedTimestamp;
    }

    changeFilter = (type) => {
        if (type === 'Owner') {
            this.setState({
                data: "Owner", teams: this.state.owner,
            })
        }
        if (type === 'Member') {
            this.setState({
                data: "Member", teams: this.state.member,
            })
        }
        if (type === 'Both') {
            this.setState({
                data: "Both", teams: this.state.teamsNew,
            })
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="container containerbottom non-session-d">
                    {this.state.user ? <SectionLeft /> : ''}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            {this.state.user ? '' :
                                <React.Fragment>
                                    <div className="postdata">
                                        <iframe allowfullscreen="yes" style={{ 'width': '100%', 'height': '360px' }} className="youtube-player" id="player" type="text/html" src="https://www.youtube.com/embed/nqdZs6m1IDo?autoplay=1&mute=1">&lt;br /&gt;</iframe>

                                    </div>
                                    <img className="explanerImg" src={explaner2} />
                                    <div className="center-d">
                                        <div className="downloaddiv-d1">
                                            <button onClick={() => this.openApp()} className="download-d1" type="button">Download App</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                            {this.state.showTeamList ? <div className="commanbox" id="profilebuddies">
                                <h3 className="commanhead">Teams</h3>
                                <ul className="challangebutton">
                                    <li></li>
                                    <li></li>
                                    <li>
                                        <div className="dropdown ct_drop">
                                            <Link to={'#'} className=" dropdown-toggle" data-toggle="dropdown">
                                                <img src={filter} alt="" />
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li><Link onClick={() => this.changeFilter('Both')} to={'#'}>Both</Link></li>
                                                <li><Link onClick={() => this.changeFilter('Owner')} to={'#'}>Owner</Link></li>
                                                <li><Link onClick={() => this.changeFilter('Member')} to={'#'}>Member</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div className="cl_box_div">
                                    <ul className="at_ul">
                                        {this.state.teams.length ? this.state.teams.map(x => {
                                            let disTeamsLI = <li className="disableddTeam">
                                                <a className="at_link">
                                                    <span className="at_teamimg" style={{
                                                        backgroundImage: x.teamIcon ? "url(" + x.teamIcon + ")" : "url(" + team + ")"
                                                    }}></span>
                                                    <label className="at_name">{x.teamName}</label>
                                                    <p className="at_sportsname">{x.sportsName}</p>
                                                    <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                    <div className="at_teamowner">
                                                        <span className="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                        {this.state.user.contactNo === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                    </div>
                                                </a>
                                            </li>
                                            let enbTeamsLI = <li>
                                                <Link to={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                    <span className="at_teamimg" style={{
                                                        backgroundImage: x.teamIcon ? "url(" + x.teamIcon + ")" : "url(" + team + ")"
                                                    }}></span>
                                                    <label className="at_name">{x.teamName}</label>
                                                    <p className="at_sportsname">{x.sportsName}</p>
                                                    <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                    <div className="at_teamowner">
                                                        <span className="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                        {this.state.user.contactNo === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                    </div>
                                                </Link>
                                            </li>
                                            return (() => {
                                                if (x.active === false) {
                                                    switch (this.state.data) {
                                                        case 'Owner':
                                                            return (
                                                                disTeamsLI
                                                            );

                                                        case 'Member':
                                                            return (
                                                                disTeamsLI
                                                            );

                                                        default:
                                                            return (
                                                                disTeamsLI
                                                            );
                                                    }
                                                }
                                                if (x.active) {
                                                    switch (this.state.data) {
                                                        case 'Owner':
                                                            return (
                                                                enbTeamsLI
                                                            );

                                                        case 'Member':
                                                            return (
                                                                enbTeamsLI
                                                            );

                                                        default:
                                                            return (
                                                                enbTeamsLI
                                                            );
                                                    }
                                                }
                                            })();
                                        }) : <div className="broadcast_video">
                                            <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                            <h3>Sorry you don't have any teams.</h3>
                                        </div>}
                                    </ul>
                                </div>
                            </div>
                                : <TeamProfile teamId={this.state.teamId} />}
                        </section>
                    </div>
                    <SectionRight />

                </div>
            </>
        );
    }
}
export default TeamList;
