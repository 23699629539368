import React, { Component } from 'react'
import ConfigUrl from '..//..//ConfigUrl';
import Modal from 'react-modal';
import { trackPromise } from "react-promise-tracker";
import Modal1 from 'react-bootstrap/Modal'
import ModalSet from 'react-bootstrap/Modal'
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import finallogo from '..//..//img/finallogo.png';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import play from '..//..//img/play.png';
import noBroadcast from '..//..//img/noBroadcast.jpg';
import logo from '..//..//img/finallogo.png';
import male from '..//..//img/finallogo.png';
import teamIcons from '..//..//img/finallogo.png';
var scoreCardD101s = [];
var d107ScoreCard = {}, score, points;
var maxWeights = [];
var byPic, toPic;
var toName, byName;

const challengeAction = "ended";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
class DemoUpdate_d107_tennis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            tiebreaker: false,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107TennisScoreCard: {},
            isByPlusDisable: false,
            isToPlusDisable: false,
            selectset: false,
            NoOfSetFirst: false,
            EndEventSet: false

        }
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);

    }
    makePoint(type) {
        var d107TennisScoreCard = this.state.d107TennisScoreCard;
        var toAdd = this.state.d107TennisScoreCard.rounds.filter(round => {
            return round.position === d107TennisScoreCard.setNo
        })
        let stopMatch = false;
        if (d107TennisScoreCard.noOfSets === 3) {
            if (d107TennisScoreCard.byTennisScoreCard.setsWon) {
                if (d107TennisScoreCard.byTennisScoreCard.setsWon.length === 2) {
                    stopMatch = true;
                    var byLen, toLen;
                    if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                        byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
                    }
                    else {
                        byLen = 0
                    }
                    if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                        toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
                    }
                    else {
                        toLen = 0
                    }
                    if (byLen > toLen) {
                        d107TennisScoreCard.byTennisScoreCard.winner = true;
                        this.setState({ byWinner: true, toWinner: false, winner: d107TennisScoreCard.byTennisScoreCard.id })
                    }
                    else {
                        d107TennisScoreCard.toTennisScoreCard.winner = true;
                        this.setState({ toWinner: true, byWinner: false, winner: d107TennisScoreCard.toTennisScoreCard.id })

                    }
                    var score = {
                        challengeId: this.props.challenge.challengeId,
                        matchId: this.props.challenge.challengeId,
                        d107TennisScoreCard: d107TennisScoreCard,
                    }
                    this.setState({ winnerModalOpen: true })
                    DemoUpdateChallengeScore(score).then(success => {
                        setTimeout(() => {
                            this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                        }, 500)

                    });
                    this.setState({ d107TennisScoreCard: d107TennisScoreCard });
                    this.setState({ winnerModalOpen: true })
                }
            }
            if (d107TennisScoreCard.toTennisScoreCard.setsWon) {
                if (d107TennisScoreCard.toTennisScoreCard.setsWon.length === 2) {
                    stopMatch = true;
                    var byLen, toLen;
                    if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                        byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
                    }
                    else {
                        byLen = 0
                    }
                    if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                        toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
                    }
                    else {
                        toLen = 0
                    }
                    if (byLen > toLen) {
                        d107TennisScoreCard.byTennisScoreCard.winner = true;
                        this.setState({ byWinner: true, toWinner: false, winner: d107TennisScoreCard.byTennisScoreCard.id })
                    }
                    else {
                        d107TennisScoreCard.toTennisScoreCard.winner = true;
                        this.setState({ toWinner: true, byWinner: false, winner: d107TennisScoreCard.toTennisScoreCard.id })

                    }
                    var score = {
                        challengeId: this.props.challenge.challengeId,
                        matchId: this.props.challenge.challengeId,
                        d107TennisScoreCard: d107TennisScoreCard,
                    }
                    this.setState({ winnerModalOpen: true })
                    DemoUpdateChallengeScore(score).then(success => {
                        setTimeout(() => {
                            this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                        }, 500)

                    });
                    this.setState({ d107TennisScoreCard: d107TennisScoreCard });
                    this.setState({ winnerModalOpen: true })
                }
            }
        }
        else if (d107TennisScoreCard.noOfSets === 5) {
            if (d107TennisScoreCard.byTennisScoreCard.setsWon) {
                if (d107TennisScoreCard.byTennisScoreCard.setsWon.length === 3) {
                    stopMatch = true;
                    var byLen, toLen;
                    if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                        byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
                    }
                    else {
                        byLen = 0
                    }
                    if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                        toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
                    }
                    else {
                        toLen = 0
                    }
                    if (byLen > toLen) {
                        d107TennisScoreCard.byTennisScoreCard.winner = true;
                        this.setState({ byWinner: true, toWinner: false, winner: d107TennisScoreCard.byTennisScoreCard.id })
                    }
                    else {
                        d107TennisScoreCard.toTennisScoreCard.winner = true;
                        this.setState({ toWinner: true, byWinner: false, winner: d107TennisScoreCard.toTennisScoreCard.id })

                    }
                    var score = {
                        challengeId: this.props.challenge.challengeId,
                        matchId: this.props.challenge.challengeId,
                        d107TennisScoreCard: d107TennisScoreCard,
                    }
                    this.setState({ winnerModalOpen: true })
                    DemoUpdateChallengeScore(score).then(success => {
                        setTimeout(() => {
                            this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                        }, 500)

                    });
                    this.setState({ d107TennisScoreCard: d107TennisScoreCard });
                    this.setState({ winnerModalOpen: true })
                }
            }
            if (d107TennisScoreCard.toTennisScoreCard.setsWon) {
                if (d107TennisScoreCard.toTennisScoreCard.setsWon.length === 3) {
                    stopMatch = true;
                    var byLen, toLen;
                    if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                        byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
                    }
                    else {
                        byLen = 0
                    }
                    if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                        toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
                    }
                    else {
                        toLen = 0
                    }
                    if (byLen > toLen) {
                        d107TennisScoreCard.byTennisScoreCard.winner = true;
                        this.setState({ byWinner: true, toWinner: false, winner: d107TennisScoreCard.byTennisScoreCard.id })
                    }
                    else {
                        d107TennisScoreCard.toTennisScoreCard.winner = true;
                        this.setState({ toWinner: true, byWinner: false, winner: d107TennisScoreCard.toTennisScoreCard.id })

                    }
                    var score = {
                        challengeId: this.props.challenge.challengeId,
                        matchId: this.props.challenge.challengeId,
                        d107TennisScoreCard: d107TennisScoreCard,
                    }
                    this.setState({ winnerModalOpen: true })
                    DemoUpdateChallengeScore(score).then(success => {
                        setTimeout(() => {
                            this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                        }, 500)

                    });
                    this.setState({ d107TennisScoreCard: d107TennisScoreCard });
                    this.setState({ winnerModalOpen: true })
                }
            }
        }
        if (!stopMatch) {
            var toRemove = [...toAdd];
            var stroke = d107TennisScoreCard.strokeNo;
            var undoList = {
                byNo: "0",
                points: 1,
                strokeNo: 1,
                toNo: "0",
                type: "challengedBy",
            }
            if (type === 'by') {
                this.setState({ isByPlusDisable: true, isToPlusDisable: false, })
                undoList.strokeNo = stroke + 1;
                undoList.byNo = toAdd[0].challengedByNo ? toAdd[0].challengedByNo : "0";
                if (toAdd[0].challengedToNo === "") {
                    undoList.toNo = "";
                }
                else {
                    undoList.toNo = toAdd[0].challengedToNo ? toAdd[0].challengedToNo : "0";
                }
                if ((toAdd[0].challengedToPoints === 6) && (toAdd[0].challengedByPoints === 6)) {
                    this.setState({ tiebreaker: true })
                    toAdd[0].tieBreaker = true;
                    undoList.type = "challengedBy";
                    undoList.byNo = '' + toAdd[0].challengedByTieBreakerPoints + '';
                    undoList.toNo = '' + toAdd[0].challengedToTieBreakerPoints + '';
                    toAdd[0].challengedByTieBreakerPoints = toAdd[0].challengedByTieBreakerPoints + 1;

                    if ((toAdd[0].challengedByTieBreakerPoints > 6) && ((toAdd[0].challengedByTieBreakerPoints - toAdd[0].challengedToTieBreakerPoints) > 1)) {
                        toAdd[0].challengedByPoints = 7;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        this.setState({ timepickerset: true, setWinnerName: this.state.byName })

                        if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                            d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                        }
                        else {
                            d107TennisScoreCard.byTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                        }
                        d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                        this.setState({ tiebreaker: false });
                    }
                }
                else {
                    undoList.type = "challengedBy";
                    if (toAdd[0].challengedByNo === '' && toAdd[0].challengedToNo === 'Adv') {
                        toAdd[0].challengedByNo = "40";
                        toAdd[0].challengedToNo = "40";
                    }
                    else if (toAdd[0].challengedByNo === null) {
                        toAdd[0].challengedByNo = "15";

                    }
                    else if (toAdd[0].challengedByNo === '15') {
                        toAdd[0].challengedByNo = "30";

                    }
                    else if (toAdd[0].challengedByNo === '30') {
                        toAdd[0].challengedByNo = "40";

                    }
                    else if (toAdd[0].challengedByNo === '40') {
                        if (toAdd[0].challengedToNo === "40") {

                            toAdd[0].challengedByNo = "Adv";
                            toAdd[0].challengedToNo = "";
                        }
                        else {
                            this.setState({ setWinnerName: this.state.byName, setNumberForWinner: d107TennisScoreCard.setNo });


                            toAdd[0].challengedByNo = null;
                            toAdd[0].challengedToNo = null;

                            if (toAdd[0].challengedByPoints === 6) {

                                if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                                    this.setState({ timepickerset: true })
                                }
                                toAdd[0].saved = true;
                                toAdd[0].winnerId = toAdd[0].challengedById;
                                if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                                    d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                                }
                                else {
                                    d107TennisScoreCard.byTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                                }
                                d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                            }
                            toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;


                        }
                    }
                    else if (toAdd[0].challengedByNo === 'Adv') {
                        toAdd[0].challengedByNo = null;
                        toAdd[0].challengedToNo = null;

                        if (toAdd[0].challengedByPoints === 6) {
                            if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                                this.setState({ timepickerset: true })
                            }
                            //show Alert    
                            toAdd[0].saved = true;
                            toAdd[0].winnerId = toAdd[0].challengedById;
                            if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                                d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                            }
                            else {
                                d107TennisScoreCard.byTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                            }
                            d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                        }
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                    }
                }

            }
            if (type === 'to') {
                this.setState({ isByPlusDisable: false, isToPlusDisable: true })
                undoList.strokeNo = stroke + 1;
                if (toAdd[0].challengedByNo === "") {
                    undoList.byNo = "";
                }
                else {
                    undoList.byNo = toAdd[0].challengedByNo ? toAdd[0].challengedByNo : "0";
                }
                undoList.toNo = toAdd[0].challengedToNo ? toAdd[0].challengedToNo : "0";
                if ((toAdd[0].challengedToPoints === 6) && (toAdd[0].challengedByPoints === 6)) {
                    this.setState({ tiebreaker: true });
                    toAdd[0].tieBreaker = true;
                    undoList.type = "challengedTo";
                    undoList.byNo = '' + toAdd[0].challengedByTieBreakerPoints + '';
                    undoList.toNo = '' + toAdd[0].challengedToTieBreakerPoints + '';

                    toAdd[0].challengedToTieBreakerPoints = toAdd[0].challengedToTieBreakerPoints + 1;

                    if ((toAdd[0].challengedToTieBreakerPoints > 6) && ((toAdd[0].challengedToTieBreakerPoints - toAdd[0].challengedByTieBreakerPoints) > 1)) {
                        toAdd[0].challengedToPoints = 7;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        this.setState({ timepickerset: true, setWinnerName: this.state.toName })
                        if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                            d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                        }
                        else {
                            d107TennisScoreCard.toTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                        }
                        if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                            this.setState({ timepickerset: true })
                        }
                        d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                    }
                }
                else {
                    undoList.type = "challengedTo";
                    if (toAdd[0].challengedToNo === '' && toAdd[0].challengedByNo === 'Adv') {
                        toAdd[0].challengedByNo = "40";
                        toAdd[0].challengedToNo = "40";
                    }
                    else if (toAdd[0].challengedToNo === null) {
                        toAdd[0].challengedToNo = "15";
                    }
                    else if (toAdd[0].challengedToNo === '15') {
                        toAdd[0].challengedToNo = "30";
                    }
                    else if (toAdd[0].challengedToNo === '30') {
                        toAdd[0].challengedToNo = "40";
                    }
                    else if (toAdd[0].challengedToNo === '40') {
                        if (toAdd[0].challengedByNo === "40") {
                            toAdd[0].challengedByNo = "";
                            toAdd[0].challengedToNo = "Adv";
                        }
                        else {
                            this.setState({ setWinnerName: this.state.toName, setNumberForWinner: d107TennisScoreCard.setNo });
                            toAdd[0].challengedByNo = null;
                            toAdd[0].challengedToNo = null;
                            if (toAdd[0].challengedToPoints === 6) {
                                if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                                    this.setState({ timepickerset: true })
                                }

                                //show Alert
                                toAdd[0].saved = true;
                                toAdd[0].winnerId = toAdd[0].challengedToId;
                                if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                                    d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                                }
                                else {
                                    d107TennisScoreCard.toTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                                }
                                d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                            }
                            toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                        }
                    }
                    else if (toAdd[0].challengedToNo === 'Adv') {
                        toAdd[0].challengedByNo = null;
                        toAdd[0].challengedToNo = null;

                        if (toAdd[0].challengedToPoints === 6) {
                            this.setState({ timepickerset: true })
                            //show Alert
                            toAdd[0].saved = true;
                            toAdd[0].winnerId = toAdd[0].challengedToId;
                            if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                                d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                            }
                            else {
                                d107TennisScoreCard.toTennisScoreCard.setsWon = [d107TennisScoreCard.setNo];
                            }
                            d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                        }
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                        this.setState({ tiebreaker: false });
                    }
                }

            }
            if (toAdd[0].toUndoList !== null) {
                toAdd[0].toUndoList.push(undoList);

            }
            else {
                toAdd[0].toUndoList = [undoList];
            }
            d107TennisScoreCard.strokeNo = stroke + 1;

            if (d107TennisScoreCard.noOfSets < d107TennisScoreCard.setNo) {
                d107TennisScoreCard.setNo = (d107TennisScoreCard.setNo - 1)
                var byLen, toLen;
                if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null && d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                    byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
                }
                else {
                    byLen = 0
                }
                if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null && d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                    toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
                }
                else {
                    toLen = 0
                }
                if (byLen > toLen) {
                    d107TennisScoreCard.byTennisScoreCard.winner = true;
                    this.setState({ byWinner: true, toWinner: false, winner: d107TennisScoreCard.byTennisScoreCard.id })
                }
                else {
                    d107TennisScoreCard.toTennisScoreCard.winner = true;
                    this.setState({ toWinner: true, byWinner: false, winner: d107TennisScoreCard.toTennisScoreCard.id })

                }
                var score = {
                    challengeId: this.props.challenge.challengeId,
                    matchId: this.props.challenge.challengeId,
                    d107TennisScoreCard: d107TennisScoreCard,
                }
                this.setState({ winnerModalOpen: true })
                DemoUpdateChallengeScore(score).then(success => {
                    setTimeout(() => {
                        this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                    }, 500)

                });
                this.setState({ d107TennisScoreCard: d107TennisScoreCard });
            }
            else {
                var score = {
                    challengeId: this.props.challenge.challengeId,
                    matchId: this.props.challenge.challengeId,
                    d107TennisScoreCard: d107TennisScoreCard,
                }
                DemoUpdateChallengeScore(score).then(success => {
                    setTimeout(() => {
                        this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                    }, 500)

                });
                console.log(d107TennisScoreCard);
                this.setState({ d107TennisScoreCard: d107TennisScoreCard });

            }

        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = this.state.winner;
        let that = this;
        const apiUrl = rshApi + "challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ winnerModalOpen: false });
                window.location = "/dchallengeprofile/index.html?id=" + that.props.challenge.challengeId;
            });
    }

    TennisUndoList = () => {
        this.setState({ winnerModalOpen: false });
        let d107TennisScoreCard = this.state.d107TennisScoreCard;
        d107TennisScoreCard.byTennisScoreCard.winner = false;
        d107TennisScoreCard.toTennisScoreCard.winner = false;

        let tosetWons = d107TennisScoreCard.toTennisScoreCard.setsWon;
        let bysetWons = d107TennisScoreCard.byTennisScoreCard.setsWon;

        if (d107TennisScoreCard.setNo >= 1 && d107TennisScoreCard.strokeNo >= 0) {
            let toAdd = d107TennisScoreCard.rounds.filter(round => {
                return round.position === d107TennisScoreCard.setNo
            })
            let toRemove = [...toAdd];
            let stroke = d107TennisScoreCard.strokeNo;
            let TypeMode;
            let toUndo;
            let toUndoList = [];

            if (!toAdd[0].toUndoList || toAdd[0].toUndoList.length > 0) {
                toUndoList = toAdd[0].toUndoList;
                if (toUndoList) {
                    if (toUndoList.length === 0) {
                        if (d107TennisScoreCard.setNo > 1) {

                            d107TennisScoreCard.setNo = d107TennisScoreCard.setNo - 1;

                            toAdd = d107TennisScoreCard.rounds.filter(round => {
                                return round.position === d107TennisScoreCard.setNo
                            })
                        }
                    }
                    else {
                        toUndoList.map(list => {
                            if (list.strokeNo === stroke) {
                                TypeMode = list.type;
                                toUndo = list;
                            }
                        })
                    }

                }
                else {
                    if (d107TennisScoreCard.setNo > 1) {

                        d107TennisScoreCard.setNo = d107TennisScoreCard.setNo - 1;
                        toAdd = d107TennisScoreCard.rounds.filter(round => {
                            return round.position === d107TennisScoreCard.setNo
                        })
                        toUndoList = toAdd[0].toUndoList;
                        toUndoList.map(list => {
                            if (list.strokeNo === stroke) {
                                TypeMode = list.type;
                                toUndo = list;
                            }
                        })
                    }
                    else if (toAdd[0].toUndoList.length == 0) {

                    }
                }
            }
            else {
                if (d107TennisScoreCard.setNo > 1) {

                    d107TennisScoreCard.setNo = d107TennisScoreCard.setNo - 1;

                    toAdd = d107TennisScoreCard.rounds.filter(round => {
                        return round.position === d107TennisScoreCard.setNo
                    })
                    toUndoList = toAdd[0].toUndoList;
                    console.log(toAdd[0])
                    toUndoList.map(list => {
                        if (list.strokeNo === stroke) {
                            TypeMode = list.type;
                            toUndo = list;
                        }
                    })
                }
            }
            if (TypeMode === "challengedBy") {

                if (toUndoList.length > 0) {
                    if ((toAdd[0].challengedByTieBreakerPoints === 0) && (toAdd[0].challengedToTieBreakerPoints === 0)) {
                        toAdd[0].tieBreaker = false;
                    }
                    if (toAdd[0].tieBreaker) {
                        if (bysetWons) {
                            bysetWons.map(sw => {
                                if (sw === d107TennisScoreCard.setNo) {
                                    if (toAdd[0].challengedByPoints > 0) {
                                        toAdd[0].challengedByPoints = --toAdd[0].challengedByPoints;
                                    }
                                    const index = bysetWons.indexOf(sw);
                                    if (index > -1) {
                                        bysetWons.splice(index, 1);
                                    }
                                }
                            })
                        }
                        toAdd[0].challengedByTieBreakerPoints = parseInt(toUndo.byNo);
                        toAdd[0].challengedToTieBreakerPoints = parseInt(toUndo.toNo);
                        toUndoList.pop();
                    }
                    else {
                        if (bysetWons) {
                            bysetWons.map(sw => {
                                if (sw === d107TennisScoreCard.setNo) {

                                    const index = bysetWons.indexOf(sw);
                                    if (index > -1) {
                                        bysetWons.splice(index, 1);
                                    }
                                }
                            })
                        }
                        if (toAdd[0].challengedByNo) {
                            if (toUndo.byNo === "0") {
                                toAdd[0].challengedByNo = null;
                                if (toUndo.toNo === '0') {
                                    toAdd[0].challengedToNo = null;
                                }
                                else {
                                    toAdd[0].challengedToNo = toUndo.toNo;
                                }
                                toUndoList.pop();
                            }
                            else {
                                toAdd[0].challengedByNo = toUndo.byNo;
                                if (toUndo.toNo === '0') {
                                    toAdd[0].challengedToNo = null;
                                } else {
                                    toAdd[0].challengedToNo = toUndo.toNo;
                                }
                                toUndoList.pop();
                            }
                        }
                        else {

                            if (toAdd[0].challengedByPoints > 0) {
                                toAdd[0].challengedByPoints = --toAdd[0].challengedByPoints;
                            }
                            if (toUndo.byNo === '0') {
                                toAdd[0].challengedByNo = null;
                            } else {
                                toAdd[0].challengedByNo = toUndo.byNo;
                            }
                            if (toUndo.toNo === '0') {
                                toAdd[0].challengedToNo = null;
                            } else {
                                toAdd[0].challengedToNo = toUndo.toNo;
                            }
                            toUndoList.pop();
                        }
                    }
                    d107TennisScoreCard.strokeNo = --stroke;
                }


            }
            else if (TypeMode === "challengedTo") {

                if (toUndoList.length > 0) {
                    if ((toAdd[0].challengedByTieBreakerPoints === 0) && (toAdd[0].challengedToTieBreakerPoints === 0)) {
                        toAdd[0].tieBreaker = false;
                    }
                    if (toAdd[0].tieBreaker) {
                        if (tosetWons) {
                            tosetWons.map(sw => {

                                if (sw === d107TennisScoreCard.setNo) {
                                    if (toAdd[0].challengedToPoints > 0) {

                                        toAdd[0].challengedToPoints = --toAdd[0].challengedToPoints;
                                    }
                                    const index = tosetWons.indexOf(sw);
                                    if (index > -1) {
                                        tosetWons.splice(index, 1);
                                    }
                                }
                            })
                        }
                        toAdd[0].challengedByTieBreakerPoints = parseInt(toUndo.byNo);
                        toAdd[0].challengedToTieBreakerPoints = parseInt(toUndo.toNo);
                        toUndoList.pop();

                    }
                    else {
                        if (tosetWons) {
                            tosetWons.map(sw => {
                                if (sw === d107TennisScoreCard.setNo) {

                                    const index = tosetWons.indexOf(sw);
                                    if (index > -1) {
                                        tosetWons.splice(index, 1);
                                    }
                                }
                            })
                        }
                        if (toAdd[0].challengedToNo) {
                            if (toUndo.toNo === "0") {

                                toAdd[0].challengedToNo = null;
                                if (toUndo.byNo === '0') {
                                    toAdd[0].challengedByNo = null;
                                }
                                else {
                                    toAdd[0].challengedByNo = toUndo.byNo;
                                }

                                toUndoList.pop();
                            }
                            else {
                                toAdd[0].challengedToNo = toUndo.toNo;
                                if (toUndo.byNo === '0') {
                                    toAdd[0].challengedByNo = null;
                                }
                                else {
                                    toAdd[0].challengedByNo = toUndo.byNo;
                                }

                                toUndoList.pop();
                            }
                        }
                        else {
                            if (toAdd[0].challengedToPoints > 0) {
                                toAdd[0].challengedToPoints = --toAdd[0].challengedToPoints;
                            }
                            if (toUndo.byNo === '0') {
                                toAdd[0].challengedByNo = null;
                            } else {
                                toAdd[0].challengedByNo = toUndo.byNo;
                            }
                            if (toUndo.toNo === '0') {
                                toAdd[0].challengedToNo = null;
                            } else {
                                toAdd[0].challengedToNo = toUndo.toNo;
                            }

                            toUndoList.pop();
                        }
                    }

                    d107TennisScoreCard.strokeNo = --stroke;
                }
            }
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107TennisScoreCard: d107TennisScoreCard,
            }
            DemoUpdateChallengeScore(score);
            this.setState({ d107TennisScoreCard: d107TennisScoreCard })
            console.log(d107TennisScoreCard);
        }
    }




    componentDidMount() {
        console.log(this.props.challenge);
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        that.setState({ selectset: true })

                        console.log(that.props.challenge)
                        console.log(that.props.profileTeams)
                        that.props.profileUsers.map(user => {
                            if (that.props.challenge.accepted[0] === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.props.challenge.accepted[1] === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                        that.setState({ byName: byName, toName: toName });
                        that.props.profileTeams.map(user => {
                            if (that.props.challenge.accepted[0] === user.teamId) {

                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.props.challenge.accepted[1] === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;

                            }
                            that.setState({ byName: byName, toName: toName });
                        })
                    }
                    else {
                        that.setState({ d107TennisScoreCard: result.data.score.d107TennisScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107TennisScoreCard.rounds, numberOfSet: result.data.score.d107TennisScoreCard.noOfSets, setNo: result.data.score.d107TennisScoreCard.setNo, strokeNo: result.data.score.d107TennisScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107TennisScoreCard.byTennisScoreCard.id === user.contactNo) {
                                    byName = user.name
                                }
                                if (result.data.score.d107TennisScoreCard.toTennisScoreCard.id === user.contactNo) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            result.data.teams.map(user => {
                                if (result.data.score.d107TennisScoreCard.byTennisScoreCard.id === user.teamId) {
                                    byName = user.teamName
                                }
                                if (result.data.score.d107TennisScoreCard.toTennisScoreCard.id === user.teamId) {
                                    toName = user.teamName
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                    }
                });
        }
    }
    saveSet = () => {
        if (this.state.numberOfSet !== null) {
            var i = 0;
            var numberOfSet = parseInt(this.state.numberOfSet);
            var header = [{
                tableHeader: true,
                position: 0,
            }];
            var round = [];
            for (i = 1; i <= numberOfSet; i++) {
                var roundData = {
                    position: i,
                    tableHeader: false,
                    tieBreaker: false,
                    challengedById: this.props.challenge.accepted[0],
                    challengedByPoints: 0,
                    challengedByNo: null,
                    challengedByTieBreakerPoints: 0,
                    challengedToTieBreakerPoints: 0,
                    challengedToId: this.props.challenge.accepted[1],
                    challengedToPoints: 0,
                    challengedToNo: null,
                    toUndoList: null,

                }

                round.push(roundData);
            }
            var rounds = header.concat(round);
            const that = this;
            d107ScoreCard = {
                noOfSets: numberOfSet,
                strokeNo: 0,
                setNo: 1,
                byTennisScoreCard: {
                    id: this.props.challenge.accepted[0],
                    winner: false
                },
                toTennisScoreCard: {
                    id: this.props.challenge.accepted[1],
                    winner: false
                },
                rounds
            }
            score = {
                challengeId: that.props.challenge.challengeId,
                matchId: that.props.challenge.challengeId,
                d107TennisScoreCard: d107ScoreCard,
            }
            console.log(score);
            that.setState({ rounds: rounds, d107TennisScoreCard: d107ScoreCard, });
            DemoUpdateChallengeScore(score);
            $("#myModal .close").click();
            this.setState({ selectset: false })
        }
        else {
            this.setState({ setError: true });
        }
        // $("#myModal .close").click();
        this.setState({ selectset: false })
    }
    backState = () => {
        // $("#myModal .close").click();
        this.setState({ selectset: false })
        this.props.stateRefresh();
    }
    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, numberOfSet: selectedValue, NoOfSetFirst: true });
        }
    }
    editWinner = () => {
        this.TennisUndoList();
        // $("#setWon .close").click();
        this.setState({ timepickerset: false })
    }
    saveWinner = () => {
        console.log(this.state.d107TennisScoreCard)
        // $("#setWon .close").click();
        this.setState({ timepickerset: false })
    }

    selectsetHandel = () => {
        this.setState({ selectset: true })
    }

    timepickersetHandel = () => {
        this.setState({ timepickerset: false })
    }
    EndEvent = () => {
        this.setState({ EndEventSet: true })
    }
    selectsetHandel1 = () => {
        this.setState({ EndEventSet: true });
    };
    backState1 = () => {
        this.setState({ EndEventSet: false });
    };


    render() {
        var subtitle;
        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSet); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        var actionPoints = {};
        this.state.rounds.map(round => {
            if (this.state.d107TennisScoreCard.setNo === round.position) {
                actionPoints = round
            }
            return true;
        })

        if ((actionPoints.challengedByNo === 6) && (actionPoints.challengedToNo === 6)) {
            this.state.tiebreaker = true;
        }
        return (
            <React.Fragment>


                <ModalSet
                    size="md"
                    show={this.state.EndEventSet}
                    onHide={() => this.selectsetHandel1()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content carrominputdiv-d">

                        <div ref={subtitle => this.subtitle = subtitle}> <h3>Score should be complete to end game </h3></div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                </div>
                                <button
                                    type="button"
                                    className="Setscoreback-d"
                                    onClick={() => this.backState1()}
                                >
                                    Back
                </button>

                            </div>
                        </div>
                    </div>
                </ModalSet>








                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                    backdrop="static"
                    keyboard={false}
                >

                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}><br></br>{this.state.bysetWons}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} ><br></br>{this.state.toSetWons}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            {/* <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a> */}
                            <a class="cmnbutton" onClick={() => this.TennisUndoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>


                <Modal1
                    size="md"
                    show={this.state.timepickerset}
                    onHide={() => this.timepickersetHandel()}
                    animation={false}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Confirmation !</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>
                            </div>
                        </div>
                    </div>
                </Modal1>

                {/* <button style={{ display: 'none' }} id="setWonClick" type="button" data-toggle="modal" data-target="#setWon"></button> */}

                <ModalSet
                    size="md"
                    show={this.state.selectset}
                    onHide={() => this.selectsetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">No. of Sets</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <div className="styled-input-single-d">
                                        <input type="radio" name="Sets" id="3set" onClick={this.changeData.bind(this)} value="3" />
                                        <label htmlFor="3set">3 Sets</label>
                                    </div>
                                    <div className="styled-input-single-d">
                                        <input type="radio" name="Sets" id="5set" onClick={this.changeData.bind(this)} value="5" />
                                        <label htmlFor="5set">5 Sets</label>
                                    </div>
                                    {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                        <span >please Select Set</span>
                                    </div> : ''}
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                {this.state.NoOfSetFirst ? <button type="button" className="Setscoresave-d" onClick={() => this.saveSet()}>Save</button> : null}

                            </div>
                        </div>
                    </div>

                </ModalSet>

                {/* <button style={{ display: 'none' }} id="openDp3" type="button" data-toggle="modal" data-target="#myModal"></button> */}
                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " style={{ backgroundImage: 'url(' + noBroadcast + ')' }}>
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>Points</th>
                                            <th>Action</th>
                                        </tr>

                                    </thead>
                                    {actionPoints.tieBreaker ?
                                        <tbody>
                                            <tr>
                                                <td>{this.state.byName}</td>

                                                <td><input value={actionPoints.challengedByTieBreakerPoints ? actionPoints.challengedByTieBreakerPoints : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('by')} type="button" class="addpointtennis">+</button></td>

                                            </tr>
                                            <tr>
                                                <td>{this.state.toName}</td>
                                                <td><input value={actionPoints.challengedToTieBreakerPoints ? actionPoints.challengedToTieBreakerPoints : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('to')} type="button" class="addpointtennis">+</button></td>
                                            </tr>
                                        </tbody>
                                        : <tbody>
                                            <tr>
                                                <td>{this.state.byName}</td>

                                                <td><input value={actionPoints.challengedByNo ? actionPoints.challengedByNo : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('by')} type="button" class="addpointtennis">+</button></td>

                                            </tr>
                                            <tr>
                                                <td>{this.state.toName}</td>
                                                <td><input value={actionPoints.challengedToNo ? actionPoints.challengedToNo : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('to')} type="button" class="addpointtennis">+</button></td>
                                            </tr>
                                        </tbody>}

                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}

                                        </tr>
                                    </thead>


                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" /></td>
                                            })}


                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" /></td>
                                            })}
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.TennisUndoList()}>undo</button>
                            <a class="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal">End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}
export default DemoUpdate_d107_tennis;


