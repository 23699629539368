import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import { trackPromise } from "react-promise-tracker";
import * as myFeedUtilclass from '../TournamentsUtil';
import * as TournamentsUtil from '../TournamentsUtil';
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import male from "../img/male.png";
import teamIcons from '../img/team.png';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Modal1 from 'react-modal'
import logo from '../img/finallogo.png';
import * as myUtilclass from '../OrganiseMatchUtil';
var score;
let pairObj;
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winner = 29;
var d107ScoreCard = {}, score, points;

var selectedWinners = undefined;
let byPic, toPic;

var d107CarromScoreCard;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournament_d107_Carrom_tt23 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            setError: false,
            teams: [],
            boards: [],
            position: [],
            saved: false,
            tableHeader: true,
            scoreNull: false,
            pickPoint: false,
            topickPoint: false,
            bypickPoint: false,
            winnerModalOpen: false,
            modalIsOpen: false,
            selectWinnerModal: false,
            bytotalPoints: 0,
            tototalPoints: 0,
            addboardDisable: false,
            winnerModalOpen1: false,
            tournament: null,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {

        var winner = this.state.winner
        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winners !== undefined) {
            winner = this.state.winners;
        }

        let d107CarromScoreCard = this.state.d107CarromScoreCard;
        if (this.state.byWinner) {
            d107CarromScoreCard.byCarromScoreCard.winner = true;
            d107CarromScoreCard.toCarromScoreCard.winner = false;
        }
        if (this.state.toWinner) {
            d107CarromScoreCard.toCarromScoreCard.winner = true;
            d107CarromScoreCard.byCarromScoreCard.winner = false;
        }

        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: this.state.d107CarromScoreCard,
        }




        if (this.props.fType === 3) {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                var moment = require('moment');
                var now = moment().format();
                var timpstampData = new Date(now).valueOf();
                var tournament = that.props.tournament;
                delete tournament.id;
                tournament.status = "completed";
                tournament.modifiedOn = timpstampData;
                tournament.tournamentWinnerId = that.state.winner;
                TournamentsUtil.ttWinnerFeeds(that.props.tournament.tournamentId, that.props.matchId, winner).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                    })
                })
            })

        }
        else {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
            })
        }


    }

    getScore() {
        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                var score = result.data.score;
                if (result.data.score !== null) {
                    that.setState({ d107CarromScoreCard: result.data.score.d107CarromScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, boards: result.data.score.d107CarromScoreCard.boards });
                    let byName; let toName;
                    if (result.data.mode === 'buddy') {
                        result.data.users.map(user => {
                            if (that.state.matchPairObj.firstId === user.contactNo) {
                                byName = user.name
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.state.matchPairObj.secondId === user.contactNo) {
                                toName = user.name
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    }
                    else {
                        result.data.teams.map(user => {

                            if (that.state.matchPairObj.firstId === user.teamId) {
                                byName = user.name
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === user.teamId) {
                                toName = user.name
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    }
                    if (result.data.score.d107CarromScoreCard.byCarromScoreCard.totalPoints) {
                        that.setState({ bytotalPoints: result.data.score.d107CarromScoreCard.byCarromScoreCard.totalPoints })
                    }
                    if (result.data.score.d107CarromScoreCard.toCarromScoreCard.totalPoints) {
                        that.setState({ tototalPoints: result.data.score.d107CarromScoreCard.toCarromScoreCard.totalPoints })
                    }
                    that.setState({ boardno: result.data.score.d107CarromScoreCard.boardNo })

                }
                else {
                    that.IntialState();
                    let byName; let toName;
                    if (that.props.tournament.tournamentMode === 'team') {
                        that.state.teams.map(team => {
                            if (that.state.matchPairObj.firstId === team.teamId) {
                                byName = team.teamName;
                                byPic = team.teamIcon ? team.teamIcon : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === team.teamId) {
                                toName = team.teamName;
                                toPic = team.teamIcon ? team.teamIcon : teamIcons;
                            }
                        })
                    }
                    else {
                        that.state.users.map(user => {
                            if (that.state.matchPairObj.firstId === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.state.matchPairObj.secondId === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                    }

                    d107CarromScoreCard = {
                        "byCarromScoreCard": {
                            "totalPoints": 0,
                            "id": that.state.matchPairObj.firstId,
                            "type": "buddy",
                            "winner": false
                        },
                        "toCarromScoreCard": {
                            "totalPoints": 0,
                            "id": that.state.matchPairObj.secondId,
                            "type": "buddy",
                            "winner": false
                        },
                        "boardNo": 0,
                        "boards": [
                            {
                                "position": 0,
                                "challengedById": null,
                                "challengedByPoints": 0,
                                "challengedToId": null,
                                "challengedToPoints": 0,
                                "tableHeader": true,
                                "saved": false
                            }
                        ]
                    }

                    var score = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107CarromScoreCard: d107CarromScoreCard,
                    }

                    UpdateTournamentsScore(score);
                    that.setState({ d107CarromScoreCard: d107CarromScoreCard, })

                }
            });
    }


    IntialState = () => {

        // pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        // this.setState({ matchPairObj: pairObj });

        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }
        this.setState({ byName: byName, toName: toName, score: false });
    }


    addboard = (score) => {
        var matchIdSpilit = this.props.matchId;
        var ar = matchIdSpilit.split('-'); // split string on comma space
        console.log(ar[1])
        var D107 = this.state.d107CarromScoreCard;
        let boards = this.state.d107CarromScoreCard.boards
        const that = this;
        let checkMaxScore = false;
        if (this.state.bytotalPoints >= 29) {
            checkMaxScore = true;
            this.setState({ byWinner: true, winner: this.state.d107CarromScoreCard.byCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
            d107CarromScoreCard.byCarromScoreCard.winner = true;
            d107CarromScoreCard.toCarromScoreCard.winner = false;
        }
        if (this.state.tototalPoints >= 29) {
            checkMaxScore = true;
            this.setState({ toWinner: true, winner: this.state.d107CarromScoreCard.toCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
            d107CarromScoreCard.byCarromScoreCard.winner = false;
            d107CarromScoreCard.toCarromScoreCard.winner = true;
        }

        if (boards.length === 1) {
            var D107 = this.state.d107CarromScoreCard;
            if (D107 !== null) {
                var round = [];
                var boardLen = this.state.d107CarromScoreCard.boards.length;
                var boardData = this.state.d107CarromScoreCard.boards;
                var roundData = {
                    position: boardLen,
                    tableHeader: false,
                    challengedById: this.state.d107CarromScoreCard.byCarromScoreCard.id,
                    challengedByPoints: 0,
                    challengedToId: this.state.d107CarromScoreCard.toCarromScoreCard.id,
                    challengedToPoints: 0,
                }
                round.push(roundData);
                var board = boardData.concat(round);
                const that = this;
                d107ScoreCard = {
                    byCarromScoreCard: {
                        id: ar[1],
                        winner: false,
                        totalPoints: 0,
                        type: this.state.mode,
                    },
                    toCarromScoreCard: {
                        id: ar[2],
                        winner: false,
                        totalPoints: 0,
                        type: this.state.mode,
                    },
                    boards: board,
                    boardNo: boardLen,
                }
                this.setState({ d107CarromScoreCard: d107ScoreCard, boards: board })
            }
            else {
                this.setState({ setError: true });
            }
            score = {
                tournamentId: that.props.tournament.tournamentId,
                matchId: that.props.matchId,
                d107CarromScoreCard: d107ScoreCard,
            }
        }
        else {
            if (!checkMaxScore) {
                var D107 = this.state.d107CarromScoreCard;
                if (D107 !== null) {
                    var round = [];
                    var boardLen = this.state.d107CarromScoreCard.boards.length;
                    var boardData = this.state.d107CarromScoreCard.boards;
                    var roundData = {
                        position: boardLen,
                        tableHeader: false,
                        challengedById: this.state.d107CarromScoreCard.byCarromScoreCard.id,
                        challengedByPoints: 0,
                        challengedToId: this.state.d107CarromScoreCard.toCarromScoreCard.id,
                        challengedToPoints: 0,
                    }
                    round.push(roundData);
                    var board = boardData.concat(round);
                    const that = this;

                    D107.boards = board;
                    D107.boardNo = boardLen;

                    this.setState({ d107CarromScoreCard: d107ScoreCard, boards: board })
                }
                else {
                    this.setState({ setError: true });
                }
                score = {
                    tournamentId: this.props.tournament.tournamentId,
                    matchId: this.props.matchId,
                    d107CarromScoreCard: D107,
                }
            }

        }

        UpdateTournamentsScore(score);
        this.setState({ d107CarromScoreCard: d107ScoreCard })
        // this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
    }



    bypickPoint = (event) => {
        this.setState({
            clickedIndex: event.target.id
        });
        if (event.target.value > 0) {

        }
        else {
            let boardLenIndex = this.state.d107CarromScoreCard.boards.length;
            boardLenIndex = parseInt(boardLenIndex) - 1;
            if (this.state.boards[boardLenIndex].challengedToPoints == 0) {
                this.setState({ pickPoint: true, bypickPoint: true, topickPoint: false })
            }
        }
    }

    topickPoint = (event) => {
        this.setState({
            clickedIndex: event.target.id
        });
        if (event.target.value > 0) {

        }
        else {
            let boardLenIndex = this.state.d107CarromScoreCard.boards.length;
            boardLenIndex = parseInt(boardLenIndex) - 1;
            if (this.state.boards[boardLenIndex].challengedByPoints == 0) {
                this.setState({ pickPoint: true, topickPoint: true, bypickPoint: false })
            }
        }
    }



    endEvent() {

        let byTotal = this.state.d107CarromScoreCard.byCarromScoreCard.totalPoints;
        let toTotal = this.state.d107CarromScoreCard.toCarromScoreCard.totalPoints;

        if ((byTotal < 28) && (toTotal < 28)) {
            this.setState({ winnerModalOpen1: true })
        }


        if (byTotal > 28) {
            this.setState({ byWinner: true, winner: this.state.d107CarromScoreCard.byCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal > 28) {
            this.setState({ toWinner: true, winner: this.state.d107CarromScoreCard.toCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
        }
        if (byTotal === toTotal) {
            this.setState({ winnerModalOpen1: true })
        }

    }


    carromValue = (value) => {
        let d107 = this.state.d107CarromScoreCard;
        var byCarromScoreCard = this.state.bytotalPoints;
        var toCarromScoreCard = this.state.tototalPoints;
        this.setState({ pickPoint: false })
        let newIndex = parseInt(this.state.clickedIndex);
        newIndex = newIndex;
        var boards = this.state.boards;
        boards.map(board => {
            if (board.position == newIndex) {
                if (this.state.topickPoint) {
                    board.challengedToPoints = parseInt(value);
                }
                else {
                    board.challengedByPoints = parseInt(value);
                }
            }
        })

        console.log(boards)
        if (this.state.topickPoint) {
            d107.byCarromScoreCard.totalPoints = byCarromScoreCard;
            d107.toCarromScoreCard.totalPoints = toCarromScoreCard + parseInt(value);
        }
        else {
            d107.toCarromScoreCard.totalPoints = toCarromScoreCard;
            d107.byCarromScoreCard.totalPoints = byCarromScoreCard + parseInt(value);
        }
        this.setState({ boards: boards, d107CarromScoreCard: d107, bytotalPoints: d107.byCarromScoreCard.totalPoints, tototalPoints: d107.toCarromScoreCard.totalPoints })

        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107,
        }
        UpdateTournamentsScore(score);
        console.log(d107)
        this.setState({ d107CarromScoreCard: d107 })
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
    }

    ToundoList = () => {
        var d107 = this.state.d107CarromScoreCard;
        console.log(this.state.boards)
        console.log(d107)
        var toUndo = [];
        if (d107.byCarromScoreCard !== null && d107.toCarromScoreCard !== null) {
            d107.boards.map(board => {
                if (board.position > 0) {
                    if (board.position === d107.boardNo)
                        toUndo = board;
                }
            })
        }

        var toUndoList = d107.boards.filter((x) => x != toUndo)
        d107.boards = toUndoList;
        var byCarromScoreCard = d107.byCarromScoreCard;
        var toCarromScoreCard = d107.toCarromScoreCard;
        if (d107.byCarromScoreCard.totalPoints !== 0) {
            byCarromScoreCard.totalPoints = byCarromScoreCard.totalPoints - toUndo.challengedByPoints;
        }

        if (d107.toCarromScoreCard.totalPoints !== 0) {
            toCarromScoreCard.totalPoints = toCarromScoreCard.totalPoints - toUndo.challengedToPoints;
        }
        d107.byCarromScoreCard = byCarromScoreCard;
        d107.toCarromScoreCard = toCarromScoreCard;
        if (d107.boardNo > 0) {
            d107.boardNo = (d107.boardNo -= 1);
        }

        this.setState({ boards: toUndoList })
        this.setState({ bytotalPoints: byCarromScoreCard.totalPoints })
        this.setState({ tototalPoints: toCarromScoreCard.totalPoints })
        this.setState({ d107CarromScoreCard: d107 })
        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107,
        }
        UpdateTournamentsScore(score);

    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }

    pickPointHandel = () => {
        this.setState({ pickPoint: false })
    }


    componentDidMount() {
        console.log(this.props)
        var tt23Matches, formatType = -1;
        const apiUrl = rshApi + "/tournaments/get-tournament";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.tt23Matches.finals) {
                    that.setState({ finals: result.data.finals, tournament: result.data.tournament, users: result.data.users, ttType: result.data.tt23Matches.ttType })
                }
                if (result.data.tt23Matches.ttType === 3) {

                }
                if (result.data.tournament.tournamentMode === 'team') {
                    that.setState({ teams: result.data.teams })
                }
                that.setState({ tt23Matches: result.data.tt23Matches })

                if (result.data.tournament.matchType === 'Final') {
                    that.setState({ formatType: that.props.fType })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Quarter Final') {
                    that.setState({ formatType: that.props.fType, quarterFinals: result.data.tt23Matches.quarterFinals })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Semi final') {
                    that.setState({ formatType: that.props.fType, semiFinals: result.data.tt23Matches.semiFinals })
                    formatType = that.props.fType;
                }
                pairObj = myUtilclass.getMatchPair(result.data.tournament, result.data.tt23Matches, true, formatType, that.props.matchId);
                //console.log(pairObj)
                that.setState({ matchPairObj: pairObj }, () => {
                    that.getScore();
                });
            });
    }





    render() {

        var actionPoints = {};
        this.state.boards.map(round => {
            if (this.state.d107CarromScoreCard.boardNo === round.position) {
                actionPoints = round
            }
            return true;
        })
        if ((actionPoints.challengedByPoints === 0) && (actionPoints.challengedToPoints === 0)) {
            this.state.addboardDisable = true;
            if (this.state.d107CarromScoreCard.boardNo === 0) {
                this.state.addboardDisable = false;
            }
        }
        else {
            this.state.addboardDisable = false;
        }

        return (
            <React.Fragment>
                <Modal1
                    isOpen={this.state.winnerModalOpen1}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                >

                    <div ref={subtitle => this.subtitle = subtitle}> <h3> Teams/Players Must Have Different Scores !<br></br>
                    Atleast You Have To Score 29 Points for Win the Game !
                    </h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ winnerModalOpen1: false })} >Ok</button></div>
                </Modal1>
                <Modal1
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.bytotalPoints}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.tototalPoints}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal1>

                <Modal

                    size="md"
                    show={this.state.pickPoint}
                    onHide={() => this.pickPointHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    {/* <div data-backdrop="static" id="pickPoint" className="modal fade reslutmodel" role="dialog"> */}
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Score</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <ul class="carromscore-d">
                                        <a>
                                            <li onClick={() => this.carromValue("1")} class="carromtapnum-d">1</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("2")} class="carromtapnum-d">2</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("3")} class="carromtapnum-d">3</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("4")} class="carromtapnum-d">4</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("5")} class="carromtapnum-d">5</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("6")} class="carromtapnum-d">6</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("7")} class="carromtapnum-d">7</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("8")} class="carromtapnum-d">8</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("9")} class="carromtapnum-d">9</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("10")} class="carromtapnum-d">10</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("11")} class="carromtapnum-d">11</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("12")} class="carromtapnum-d">12</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("13")} class="carromtapnum-d">13</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("14")} class="carromtapnum-d">14</li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}
                </Modal>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " style={{ backgboardImage: 'url(' + noBroadcast + ')' }}>
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>score</th>
                                            <th>status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.bytotalPoints}</td>
                                            <td>
                                                {this.state.addboardDisable ? <button onClick={() => this.setState({ showfillcheckerError: true })} type="button" class="addboard-d">Add board</button> : <button onClick={() => this.addboard()} type="button" class="addboard-d">Add board</button>}
                                            </td>


                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.tototalPoints}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {this.state.boards.map((k, index) => {
                                                if (index > 0)
                                                    return <th>board{index} </th>
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {this.state.boards.map((k, bindex) => {
                                                let points;

                                                {/* this.state.boards.map(board => {
                                                    if (bindex + 1 === board.position) {
                                                        points = board.challengedByPoints   
                                                    }
                                                    return true;
                                                }) */}
                                                if (bindex > 0) {
                                                    let boarLen = this.state.boards.length - 1;
                                                    let isRead = true;
                                                    if (boarLen == bindex) {
                                                        isRead = false;
                                                    }
                                                    else {
                                                        isRead = true;
                                                    }
                                                    points = k.challengedByPoints;
                                                    return <td key={bindex}><input id={bindex} value={points} class="inputsize-d" onClick={(event) => this.bypickPoint(event)} disabled={isRead} readOnly /></td>
                                                }
                                            })}
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {this.state.boards.map((k, index) => {
                                                let points;
                                                {/* this.state.boards.map(board => {
                                                    if (index + 1 === board.position) {
                                                        points = board.challengedToPoints


                                                    }
                                                    return true;
                                                }) */}

                                                if (index > 0) {
                                                    points = k.challengedToPoints;
                                                    let boarLen = this.state.boards.length - 1;
                                                    let isRead = true;
                                                    if (boarLen == index) {
                                                        isRead = false;
                                                    }
                                                    else {
                                                        isRead = true;
                                                    }
                                                    return <td key={index}><input id={index} value={points} class="inputsize-d" onClick={(event) => this.topickPoint(event)} disabled={isRead} readOnly /></td>
                                                }
                                            })}

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                        <span >please wait</span>
                    </div> : ''}
                    <div class="table_actions winner_actions">
                        <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>undo</button>
                        <a class="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.endEvent()}>End Event</a>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}



