import React, { Component } from 'react';
import './DefaultStyle.css';
import './IndexStyle.css';
import Header from './Header';


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div>
                <Header />
                <div className="container containerbottom">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad7">
                        <section className="d_midsec">
                            <div className="divpost">
                                <div className="postdata">
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Privacy Policy</h4>
                                    <p>At Playdiator, we care about your personal data. This document about our Privacy Policy explains how we collect, use and share it and your rights and obligations in relation to your personal data.</p>
                                    <p>Playdiator, Inc., a company registered at Danish House, 216- A, Zone-I, Maharana Pratap Nagar, Bhopal, Madhya Pradesh 462023, India is the data controller for the purposes of the General Data Protection Regulation (“GDPR”) and any respective “local laws” for data protection.</p>
                                    <p>Danish Computer Technologies Pvt. Ltd. built the Playdiator app as a Free app. This SERVICE is provided by Danish Computer Technologies Pvt. Ltd. at no cost and is intended for use as is.</p>
                                    <p>By using the Service, you agree to the terms of this Privacy Policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                                    <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Playdiator unless otherwise defined in this Privacy Policy.</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Why do we need your personal data?</h4>
                                    <p>We need certain personal data in order to deliver the Services to you.</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Your consent</h4>
                                    <p>By registering with us or submitting personal data through our Services, you expressly consent to the collection, use and disclosure of your personal data in accordance with this Privacy Policy. We will process your personal data in accordance with applicable data protection and privacy laws. When you register with us, you are required to consent to our Privacy Policy. This consent provides us with a legal basis under applicable law to process your personal data. You maintain the right to withdraw such consent at any time.</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}> PhoneCall Authentication</h4>
                                    <p>To verify user's phone number , we simply make a request call to ensure that provided phone number is valid. For this, we request user to allow phone call permission CALL_PHONE in android</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>What kinds of information do we collect?</h4>
                                    <ul style={{ paddingLeft: '50px' }} >
                                        <li style={{ listStyleType: 'square' }}>Information you provide us</li>
                                        <li style={{ listStyleType: 'square' }}>Information about how you use the site</li>
                                        <li style={{ listStyleType: 'square' }}>Information about your device or network</li>
                                        <li style={{ listStyleType: 'square' }}>Information from third parties</li>
                                    </ul>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Protecting the personal information</h4>
                                    <p>We are committed to protecting our users’ personal data. We implement appropriate technical and organisational measures to help protect the security of your personal data; however, please note that no system is ever completely secure. We have implemented various policies including pseudonymisation, encryption, access, and retention policies to guard against unauthorised access and unnecessary retention of personal data in our systems. Your password protects your user account, so we encourage you to use a unique and strong password, limit access to your computer and browser, and log out after having used the Playdiator Service.</p>

                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Children’s privacy</h4>
                                    <p>Playdiator does not knowingly collect information from children under the age of 13. Furthermore, we do not target our service to children under these ages nor do we knowingly communicate with children under 13. When we work with teams and groups with children under 13, the parents/guardians of the children are responsible for entering and managing their children’s information.</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Security</h4>
                                    <p>We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Cookies</h4>
                                    <p>The Playdiator site uses cookies whose purpose is to facilitate navigation on the site, to execute the service provided by Playdiator, to measure the site’s audience and personalise the User experience.</p>
                                    <h5 style={{ paddingLeft: '15px', paddingTop: '15px' }}>The cookies necessary for navigation on the site</h5>
                                    <p>These cookies are necessary for the functioning of the playdiator.com site. They allow the main features of the site to be used. Without these cookies, Users can not login and use the site normally.</p>
                                    <h5 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Functional cookies</h5>
                                    <p>These cookies make it possible to personalise the User experience.</p>

                                    <h5 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Analytical cookies</h5>
                                    <p>We may display advertisements from third parties and other content that links to third-party websites. We cannot control or be held responsible for third parties’ privacy practices and content. If you click on a third-party advertisement or link, please understand that you are leaving the Playdiator Service and any personal data you provide will not be covered by this Policy. </p>
                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>DMCA – Copyright infringement</h4>
                                    <p>Playdiator is in compliance with 17 U.S.C. § 512 and in the Digital Millennium Copyright Act (“DMCA”). It is our policy to respond to any infringement notices and take appropriate actions under the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws.</p>
                                    <p>If your copyrighted material has been posted on Playdiator or if hyperlinks to your copyrighted material are returned through our search engine and you want this material removed from this site, then you must provide a written communication that details the information listed in the following section. Kindly be aware that you will be liable for damages (including costs and attorneys’ fees) if you misrepresent information listed on our site that is infringing on your any copyrights. We suggest you that first contact an attorney for legal assistance on this matter.</p>
                                    <p>The following elements must be included in your copyright infringement claim:</p>

                                    <ol style={{ paddingLeft: '50px' }}>
                                        <li style={{ listStyleType: 'square' }}>Provide evidence of the authorized person to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                        <li style={{ listStyleType: 'square' }}>Provide sufficient contact information so that we may contact you. You must also include a valid email address.</li>
                                        <li style={{ listStyleType: 'square' }}>You must identify in sufficient detail the copyrighted work claimed to have been infringed and including at least one search term under which the material appears in Playdiator’ search results.</li>
                                        <li style={{ listStyleType: 'square' }}>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
                                        <li style={{ listStyleType: 'square' }}>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                        <li style={{ listStyleType: 'square' }}>Must be signed by the authorized person to act on behalf of the owner of an exclusive right that is allegedly being infringed.</li>
                                    </ol>
                                    <p>Send the written infringement notice to the following address and an email notification to below address. </p>
                                    <p><b>Email: </b><a href={"mailto:info@playdiator.com"} target="_blank" >info@playdiator.com</a></p>
                                    <p>Please allow 2-3 business days for an email response. Note that emailing your complaint to other parties such as our Internet Service Provider will not expedite your request and may result in a delayed response due the complaint not properly being filed.</p>

                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Modification of the Privacy Policy</h4>
                                    <p>From time to time, we may develop new or offer additional services. If the introduction of these new or additional services results in any change to the way we collect or process your personal data we will provide you with more information and additional terms or policies. Unless stated otherwise when we introduce these new or additional services, they will be subject to this Policy.</p>
                                    <p>Playdiator reserves the right to change this Privacy Policy to comply with changes in the applicable laws and regulations.</p>
                                    <p>The Users shall be notified of any changes made to this policy via our website or by email at least thirty days prior, when possible, to their entry into force.</p>


                                    <h4 style={{ paddingLeft: '15px', paddingTop: '15px' }}>Contact us</h4>
                                    <p>Thank you for reading our Privacy Policy. Any questions about Playdiator’s Privacy Policy can be directed by email to <a href={"mailto:info@playdiator.com"} target="_blank" >info@playdiator.com </a>
                                        <br /> We hope that you'll enjoy Playdiator!</p>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
