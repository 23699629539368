import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import femaleIcon from "../img/female.png";
import challengebg from "../img/challenegbg.png";
import challengevs from '../img/challengevs.png';
import noBroadcast from '../img/noBroadcast.jpg';
import maleIcon from "../img/male.png";
import openchallenge from "../img/openchallenge.png";
import challengefill from "../img/challengefill.png";
import userMaleIcon from "../img/male.png";
import play from '../img/play.png';
import teamIcon from '../img/team.png';
import location from "../img/location.png";
import matchbg from '../img/matchbg.png';
import winnerlogo from '../img/winnerlogo.png';
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const userApi = ConfigApiUrl;
var userPic1;
var userName1;
var userPic;
var userName;


export default class Feed_d107cricket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            userData1: [],
            teamData: [],
            acceptedChallenge: [],
            challengeData: {},
            scoreCardD104s: [],
            d107CricketScoreCard: {},
            bySetWon: [],

            byCricketScoreCard: {},
            toCricketScoreCard: {},
            firstInningScoreCard: {},
            secondInningScoreCard: {},
            showApply: true,

        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }

    loadMore = () => {

        let that = this;
        var apiUrl = userApi + '/challenges/get-challenge';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    challengeId: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)
            if (result.error == false) {
                that.setState({ challengeData: result.data.challenge, teamData: result.data.teams, userData: result.data.users });
                if (result.data.challenge.createdBy === that.state.user.contactNo) {
                    that.setState({ showApply: false });
                }
                if (result.data.challenge.hasOwnProperty('oppositions') === true) {
                    if (result.data.challenge.oppositions !== null) {
                        that.setState({ oppositions: result.data.challenge.oppositions });
                        result.data.challenge.oppositions.map(op => {
                            if (op === that.state.user.contactNo) {
                                that.setState({ showApply: false });
                            }
                        })
                    }
                }
                if (result.data.challenge.hasOwnProperty('accepted') === true) {
                    if (result.data.challenge.accepted !== null) {
                        that.setState({ acceptedChallenge: result.data.challenge.accepted });
                    }
                }
            }

        });


        var apiUrl = userApi + 'users/buddy-profile';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.user.contactNo,
                    userId: this.state.user.contactNo,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {

            that.setState({ profileTeam: result.data.teams });
        });
    }
    componentDidMount() {


        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {

                    "challengeId": that.props.challengeData.challengeId,
                    "matchId": that.props.challengeData.challengeId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

                if (result.data.score !== null) {

                    that.setState({ byCricketScoreCard: result.data.score.d107CricketScoreCard.byCricketScoreCard, toCricketScoreCard: result.data.score.d107CricketScoreCard.toCricketScoreCard, teamData: result.data.teams })

                    that.setState({ rounds: result.data.score.d107CricketScoreCard.rounds, scorecard: result.data.score.d107CricketScoreCard })

                    that.setState({ score: result.data.score })

                    if (result.data.score.d107CricketScoreCard !== null) {
                        if (result.data.score.d107CricketScoreCard.firstInningScoreCard) {
                            that.setState({ firsttotal: result.data.score.d107CricketScoreCard.firstInningScoreCard.totalRun ? result.data.score.d107CricketScoreCard.firstInningScoreCard.totalRun : 0 })
                        } else {
                            that.setState({ firsttotal: 0 })
                        }

                        if (result.data.score.d107CricketScoreCard.secondInningScoreCard !== null) {
                            that.setState({ secondtotal: result.data.score.d107CricketScoreCard.secondInningScoreCard.totalRun ? result.data.score.d107CricketScoreCard.secondInningScoreCard.totalRun : 0 })
                        } else {
                            that.setState({ secondtotal: 0 })
                        }

                        if (result.data.score.d107CricketScoreCard.firstInningScoreCard !== null) {
                            that.setState({ firstwicket: result.data.score.d107CricketScoreCard.firstInningScoreCard.wicketFall ? result.data.score.d107CricketScoreCard.firstInningScoreCard.wicketFall : 0 })
                        } else {
                            that.setState({ firstwicket: 0 })
                        }
                        if (result.data.score.d107CricketScoreCard.secondInningScoreCard !== null) {
                            that.setState({ secondwicket: result.data.score.d107CricketScoreCard.secondInningScoreCard.wicketFall ? result.data.score.d107CricketScoreCard.secondInningScoreCard.wicketFall : 0 })
                        } else {
                            that.setState({ secondwicket: 0 })
                        }
                        if (result.data.score.d107CricketScoreCard.firstInningScoreCard !== null) {
                            that.setState({ byOver: result.data.score.d107CricketScoreCard.firstInningScoreCard ? result.data.score.d107CricketScoreCard.firstInningScoreCard.overCompleted : 0 })
                        } else {
                            that.setState({ byOver: 0 })
                        }
                        if (result.data.score.d107CricketScoreCard.secondInningScoreCard !== null) {
                            that.setState({ toOver: result.data.score.d107CricketScoreCard.secondInningScoreCard ? result.data.score.d107CricketScoreCard.secondInningScoreCard.overCompleted : 0 })
                        } else {
                            that.setState({ toOver: 0 })
                        }
                        if (result.data.score.d107CricketScoreCard.firstInningScoreCard !== null) {
                            that.setState({ byballNo: result.data.score.d107CricketScoreCard.firstInningScoreCard.ballNo ? result.data.score.d107CricketScoreCard.firstInningScoreCard.ballNo : 0 })
                        } else {
                            that.setState({ byballNo: 0 })
                        }
                        if (result.data.score.d107CricketScoreCard.secondInningScoreCard !== null) {
                            that.setState({ toballNo: result.data.score.d107CricketScoreCard.secondInningScoreCard.ballNo ? result.data.score.d107CricketScoreCard.secondInningScoreCard.ballNo : 0 })
                        } else {
                            that.setState({ toballNo: 0 })
                        }
                    }

                }
                else {
                    that.setState({ secondInningScoreCard: 0, firstInningScoreCard: 0 })
                }

                // if (result.data.score.d107CricketScoreCard == null) {
                //     that.setState({ toballNo: 0, byballNo: 0 })
                // }
            });


        that.setState({ challengeData: that.props.challengeData, acceptedChallenge: that.props.challengeData.accepted })

    }
    openChallengeProfile = (cid) => {
        window.location.href = "/challengeprofile/index.html?id=" + cid;
    }

    applyChallenge = (cId) => {
        let that = this;
        const reports = userApi + "/challenges/react-on-open-challenge";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": cId,
                    "id": that.state.user.contactNo,
                    "action": "applied"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data === 'success') {
                    that.loadMore();
                    that.setState({ showApply: false })
                }
            });
    }
    render() {
        console.log(this.props.teamData)
        console.log(this.props.challengeData)

        var user, user1, pic, pic1, winners = false, winners1 = false;
        if (this.props.challengeData.challengeType == 'team') {
            if (this.state.score) {
                if (this.state.score.d107CricketScoreCard) {
                    if (this.state.score.d107CricketScoreCard.firstBattingTeam === "challengedBy") {
                        this.props.teamData.map(team => {
                            if (team.teamId === this.props.challengeData.accepted[0]) {
                                user = team.teamName;
                                pic = team.teamIcon ? team.teamIcon : teamIcon;
                                if (this.props.challengeData.challengeWinnerId == team.teamId) {
                                    winners = true;
                                }
                            }
                            if (team.teamId === this.props.challengeData.accepted[1]) {
                                user1 = team.teamName;
                                pic1 = team.teamIcon ? team.teamIcon : teamIcon;
                                if (this.props.challengeData.challengeWinnerId == team.teamId) {
                                    winners1 = true;
                                }
                            }
                        })
                    }
                    else {
                        this.props.teamData.map(team => {
                            if (team.teamId === this.props.challengeData.accepted[1]) {
                                user = team.teamName;
                                pic = team.teamIcon ? team.teamIcon : teamIcon;
                                if (this.props.challengeData.challengeWinnerId == team.teamId) {
                                    winners = true;
                                }
                            }
                            if (team.teamId === this.props.challengeData.accepted[0]) {
                                user1 = team.teamName;
                                pic1 = team.teamIcon ? team.teamIcon : teamIcon;
                                if (this.props.challengeData.challengeWinnerId == team.teamId) {
                                    winners1 = true;
                                }
                            }
                        })
                    }
                }
            }
        }




        var OpenChallengeAccess = false;
        if ((this.state.challengeData.challengeAction === 'requested') && (this.state.challengeData.openChallenge === true)) {
            OpenChallengeAccess = true;
        }


        var acceptedUser = false;
        var oppositions = this.state.challengeData.oppositions ? this.state.challengeData.oppositions : [];
        oppositions.map(c => {
            if (this.state.user.contactNo === c) {
                acceptedUser = true;
            }
        })

        if (this.state.challengeData.openChallenge === true) {


            return (<React.Fragment>
                <div class="openchallenge-d">
                    <h5>Open Challenge</h5>
                    <img class="open_challengeimg" src={openchallenge} />
                    <p> {userName} has created an open challenge</p>
                    <span>Sports-{this.state.challengeData.sportsName}</span>

                    {this.state.showApply ? <button onClick={() => this.applyChallenge(this.state.challengeData.challengeId)} id="openchallenge-btn" type="btn" class="openchallenge-btn"> Accept Now</button> : acceptedUser ? <button id="openchallenge-btn" type="btn" class="openchallenge-btn"> Applied </button> : ''}

                </div>
            </React.Fragment>)
        }


        return (

            <React.Fragment>

                <div class="teampost_inner" style={{
                    backgroundImage: "url(" + challengebg + ")"
                }}>
                    <label class="teamhead">Challenge - {this.props.challengeData.sportsName}</label>
                    <ul class="postteam">

                        <li>
                            {winners ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic + ")"
                            }}></span>
                            <label class="teamname">{user}</label>
                            {this.state.score ?
                                <label class="matchscore">{this.state.firsttotal}-{this.state.firstwicket} ({this.state.byOver}.{this.state.byballNo})</label> : null}

                            {winners ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>

                        <li>
                            <img src={challengevs} class="vsimg" alt="" />
                            <span class="matchscore_text">Match Score</span>
                        </li>

                        <li>
                            {winners1 ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic1 + ")"
                            }}></span>
                            <label class="teamname">{user1}</label>
                            {this.state.score ?
                                <label class="matchscore">{this.state.secondtotal}-{this.state.secondwicket} ({this.state.toOver}.{this.state.toballNo})</label> : null}

                            {winners1 ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>

                    </ul>

                    <label class="matchdate"><span>Match Date : </span>{this.state.challengeData.challengeDate} <span>Time : {this.state.challengeData.challengeTime}</span></label>
                    <p class="teamlocation"><img src={location} alt="" />{this.state.challengeData.location}</p>

                    <div class="actiondiv">
                        <button onClick={() => this.openChallengeProfile(this.state.challengeData.challengeId)}>View Score</button>
                    </div>
                </div>

            </React.Fragment>



        );
    }
}

