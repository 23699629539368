import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
export const googleApiKey = "AIzaSyCsa_uDEWxG1XQbxJ_RR7FfunPCjS2-hpA";
export const googleMapUrl = "https://maps.googleapis.com/maps/api/js?key=" + googleApiKey + "&libraries=places";
const user = JSON.parse(localStorage.getItem("result"));
export const getHobbies = async function () {
    var apiUrl = userApi + '/core/get-hobbies';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "ids": null,

            }
        })
    });
    let hob = await response.json();

    return hob;
}


export const getDateYYYYmmdd = function (date) {
    var d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
}

export const getTimeFromDate = function (date) {
    var d = date;
    var hours = d.getHours();
    var minutes = d.getMinutes();
    // Check whether AM or PM 
    var newformat = hours >= 12 ? 'PM' : 'AM';
    // To display "0" as "12" 
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes;
}

export const getTimeFromDateAmPm = function (date) {
    var d = date;
    var hours = d.getHours();
    var minutes = d.getMinutes();
    // Check whether AM or PM 
    var newformat = hours >= 12 ? ' pm' : ' am';
    // To display "0" as "12" 
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + newformat;
}

// export const userUpdate = async function (userDetails) {
//     var apiUrl = userApi + '/users/update-user';
//     let response = await fetch(apiUrl, {
//         method: "post",
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             data: {
//                 "user": userDetails,
//             }
//         })
//     });
//     let user = await response.json();
//     return user;
// }
