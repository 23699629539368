import React from 'react';
import ReactDOM from 'react-dom';
import TimeLine from './Timeline';
import * as serviceWorker from './serviceWorker';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

// import { useClearCache } from "react-clear-cache";
const loaderStyle = {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

const LoadingIndicator = props => {
    // const { isLatestVersion, emptyCacheStorage } = useClearCache();
    // if (!isLatestVersion) {
    //     emptyCacheStorage();
    // }
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div style={loaderStyle}>
            <Loader type="ThreeDots" color="#2BAD60" Height="100" Width="100" />
        </div>
    );
};

ReactDOM.render(<div>

    <TimeLine />
    <LoadingIndicator />

</div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
