
import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class D107VueKabaddi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchSatus: false,
            rounds: [],
            //users: [],
            teams: [],
        };

    }
    componentDidMount() {
        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {


                    // "challengeId":"5ec421124706de77be5e100b",
                    // "matchId":"5ec421124706de77be5e100b",
                    "challengeId": that.props.challenge.challengeId,
                    "matchId": that.props.challenge.challengeId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    //users:result.data.users
                    teams: result.data.teams
                })
                if (result.data.score !== null) {
                    if (result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points !== undefined) {
                        if (result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points !== null) {
                            that.setState({ bysetNo: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points.length, byWinner: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.winner });

                        }
                        else {
                            that.setState({ bySetWons: 0, byWinner: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }
                    if (result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points !== undefined) {
                        if (result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points !== null) {
                            that.setState({ topoints: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points.length, toWinner: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.winner });

                        }
                        else {
                            that.setState({ toSetWon: 0, toWinner: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }
                    that.setState({ pointsby: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points })
                    that.setState({ pointsto: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points })


                    that.state.teams.map(team => {
                        if (team.teamId === result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.teamId) {
                            that.setState({ byKabaddiScoreCard: team.teamName });
                        }
                        if (team.teamId === result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.teamId) {
                            that.setState({ toKabaddiScoreCard: team.teamName });
                        }
                    })

                }
                else {
                    let byName; let toName;
                    that.props.profileUsers.map(user => {
                        if (that.props.challenge.accepted[0] === user.contactNo) {
                            byName = user.name;
                        }
                        if (that.props.challenge.accepted[1] === user.contactNo) {
                            toName = user.name;
                        }
                    })
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    that.props.profileTeams.map(user => {
                        if (that.props.challenge.accepted[0] === user.teamId) {
                            byName = user.teamName;
                        }
                        if (that.props.challenge.accepted[1] === user.teamId) {
                            toName = user.teamName;
                        }
                    })
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                }
            });
        if (that.props.challenge.challengeAction === 'ended') {
            that.setState({ matchSatus: true });
        }

    }
    render() {
        // const backLinks = "/challengeprofile/index.html?id=5ec421124706de77be5e100b";
        const backLinks = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
        if (this.state.matchSatus) {
            return (
                <React.Fragment>
                    <section className="d_midsec createmidsec broadcast_box">
                        <div className="commanbox cp_profile">
                            <div className="broadcast_video" style={{ backgroundImage: 'url(img/news.png)' }}>
                                <span>09:33</span>
                                <a className="tour_broadcast_update"><img src="img/play.png" /></a>
                            </div>
                            <div className="tableresponsive">
                                <div className="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>
                                                <th>Score</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tr>
                                            <td>{this.state.byKabaddiScoreCard}</td>
                                            <td>{this.state.pointsby}</td>
                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                        </tr>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.toKabaddiScoreCard}</td>
                                                <td>{this.state.pointsto}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}

                                            </tr>
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment >
            );
        }
    }
}
