import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import Modal from 'react-bootstrap/Modal'
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
var scoreCardD104 = [];
var d104ScoreCard = {}, score, scoreCardD104s = {};
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var maxWeights = [];
var cricketApi = require("../cricketApi.js");

export default class Challenge_d104 extends Component {
    constructor(props) {
        super(props);
        this.props.challenge.accepted.map(x => {
            scoreCardD104.push({ 'userId': x, 'points': 0.0, 'winner': false });
        });
        this.state = {
            scoreCardD104s: scoreCardD104,
            playerPoint: '',
            errorShow: false,
            maxPoint: [],
            checked: true,
            timepickerset: false,
            timeInput: 0,
            hr: 0,
            min: 0,
            sec: 0,
            ms: 0,
            playerTime: '',
        };
        this.changeTime = this.changeTime.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);

    }
    increment(type) {
        if (type === 'hr') {
            if (this.state.hr < 99) {
                this.setState({ hr: this.state.hr + 1 })
            }
        }
        if (type === 'min') {

            if (this.state.min < 59) {
                this.setState({ min: this.state.min + 1 })
            }
        }
        if (type === 'sec') {

            if (this.state.sec < 59) {
                this.setState({ sec: this.state.sec + 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms < 999) {
                this.setState({ ms: this.state.ms + 1 })
            }
        }
    }
    decrement(type) {
        if (type === 'hr') {
            if (this.state.hr > 0) {
                this.setState({ hr: this.state.hr - 1 })
            }
        }
        if (type === 'min') {
            if (this.state.min > 0) {
                this.setState({ min: this.state.min - 1 })
            }
        }
        if (type === 'sec') {
            if (this.state.sec > 0) {
                this.setState({ sec: this.state.sec - 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms > 0) {
                this.setState({ ms: this.state.ms - 1 })
            }
        }
    }
    changeTime(e) {

        if (e.target.name === 'hr') {
            if (parseInt(e.target.value) > 99) {
                this.setState({ [e.target.name]: 99 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'min') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'sec') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'ms') {
            if (parseInt(e.target.value) > 999) {
                this.setState({ [e.target.name]: 999 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    doneMatch() {
        var winner;
        if (this.state.selectWinner === undefined) {
            winner = selectedWinner;
        }
        else {
            winner = this.state.selectWinner;
        }

        let scoreCardD104 = this.state.scoreCardD104s
        if (scoreCardD104[0].userId === winner) {
            scoreCardD104[0].winner = true;
            scoreCardD104[1].winner = false;
        }
        else {
            scoreCardD104[1].winner = true;
            scoreCardD104[0].winner = false;
        }
        scoreCardD104s = {
            scoreCardD104s: scoreCardD104
        }
        score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d104ScoreCard: scoreCardD104s
        }
        this.setState({ scoreCardD104s: scoreCardD104 })
        UpdateChallengeScore(score)


        console.log(score)
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winner;
        let that = this;
        const apiUrl = rshApi + "challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({ winnerModalOpen: false, selectWinnerModal: false });
                window.location = "/challengeprofile/index.html?id=" + that.props.challenge.challengeId;

            });
    }
    disqualify() {
        this.setState({ timepickerset: false })
        let that = this;
        scoreCardD104.map((point, index) => {
            if (index === this.state.setIndex) {
                point.disqualified = true;
                point.points = 0;
            }
        });

        this.setState({ timepickerset: false })
        this.setState({ scoreCardD104s: scoreCardD104 });
        scoreCardD104s = {
            scoreCardD104s: scoreCardD104
        }
        score = {
            challengeId: that.props.challenge.challengeId,
            matchId: that.props.challenge.challengeId,
            d104ScoreCard: scoreCardD104s
        }
        UpdateChallengeScore(score);
    }
    savePoint() {
        this.setState({ timepickerset: false })
        var hr, min, sec, ms;
        if (this.state.hr < 10) {
            hr = '0' + this.state.hr;
        }
        else {
            hr = this.state.hr;
        }
        if (this.state.min < 10) {
            min = '0' + this.state.min;
        }
        else {
            min = this.state.min;
        }
        if (this.state.sec < 10) {
            sec = '0' + this.state.sec;
        }
        else {
            sec = this.state.sec;
        }
        if (this.state.ms < 10) {
            ms = '00' + this.state.ms;
        }
        else if ((this.state.ms >= 10) && (this.state.ms < 100)) {
            ms = '0' + this.state.ms;
        }
        else {
            ms = this.state.ms;
        }
        var a = parseInt(hr);
        var b = 24;
        if (a > 23) {
            var y = a / b;
            var z = a % b;
            z = '0' + z;
            console.log(y)
            console.log(z)
            // var t = (parseInt(y) + " " + hr)

            let milis = 86400000 * parseInt(y);

            var playerTime = z + ':' + min + ':' + sec + '.' + ms;

            var unixTimeZero = Date.parse('01 Jan 1970 ' + playerTime + 'UTC');
            console.log(unixTimeZero)
            unixTimeZero = unixTimeZero + milis;

        } else {

            var playerTime = hr + ':' + min + ':' + sec + '.' + ms;
            var unixTimeZero = Date.parse('01 Jan 1970 ' + playerTime + ' UTC');
            console.log(playerTime)
            console.log(unixTimeZero)
        }
        let that = this;
        console.log(scoreCardD104)
        scoreCardD104.map((point, index) => {
            if (index === this.state.setIndex) {
                point.points = unixTimeZero;
                point.disqualified = false;
            }
        });

        this.setState({ timepickerset: false })
        this.setState({ scoreCardD104s: scoreCardD104 });
        scoreCardD104s = {
            scoreCardD104s: scoreCardD104
        }
        score = {
            challengeId: that.props.challenge.challengeId,
            matchId: that.props.challenge.challengeId,
            d104ScoreCard: scoreCardD104s
        }
        UpdateChallengeScore(score);
    }
    timepickersetHandel = () => {
        this.setState({ timepickerset: true })
    }
    endEvent() {

        var showFillModal = false;
        this.state.scoreCardD104s.map(point => {
            if (point.points < 1 && point.disqualified === false) {
                d104ScoreCard[0].winner = true;
                d104ScoreCard[1].winner = false;
                showFillModal = true;

            }
        });
        if (showFillModal) {
            // $('#myModalOpen').click();
            this.setState({ timepickerset: true })
        }
        else {
            maxWeights = [];
            const max = this.state.scoreCardD104s.reduce((prev, current) => (prev.points < current.points) ? prev : current)
            this.state.scoreCardD104s.map((x, index) => {
                if (x.points === max.points) {
                    maxWeights.push(x);
                }
            });

            this.setState({ maxPoint: maxWeights });

            $('#myModalOpen1').click();
        }
    }
    componentDidMount() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {

                    }
                    else {
                        if (result.data.score.d104ScoreCard.scoreCardD104s) {
                            that.setState({ scoreCardD104s: result.data.score.d104ScoreCard.scoreCardD104s });
                            scoreCardD104 = result.data.score.d104ScoreCard.scoreCardD104s;
                        }
                    }

                });
        }
    }

    render() {
        const backLinks = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
        return (
            <React.Fragment>
                <div id="myModal1" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Enter Point</h4>
                            </div>
                            <div className="modal-body">
                                <center>
                                    <h5>Please Fill Score Point</h5></center>
                            </div>
                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen" data-toggle="modal" data-target="#myModal1" />

                <div id="myModal2" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Challange Winner</h4>
                            </div>
                            <div className="modal-body">
                                <div className="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul className="resultul">
                                    {this.state.maxPoint.map((weight, index) => {

                                        var userName, userPic, point;
                                        selectedWinner = weight.userId;
                                        this.props.profileUsers.map(user => {

                                            if (weight.userId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic;
                                            }
                                        })
                                        point = cricketApi.msToTime1(weight.points);

                                        return <li style={{ 'display': 'flex' }}>
                                            <a className="winnerselect">
                                                <span className="resuserimg" style={{ backgroundImage: "url(" + userPic + ")" }}></span>
                                                <div className="resinfo">
                                                    <label>{userName} </label>
                                                    <small>Score:{point} </small>
                                                </div>

                                                <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                    {/* <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} /> */}
                                                    <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                    <label htmlFor={index}></label>
                                                </div>
                                            </a>
                                        </li>
                                    })}

                                </ul>

                            </div>

                            <div className="modal-footer table_actions">
                                <a data-dismiss="modal" className="cmnbutton editbtn">Edit</a>
                                <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />

                <Modal
                    size="md"
                    show={this.state.timepickerset}
                    onHide={() => this.timepickersetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-header">
                        <button onClick={() => this.setState({ timepickerset: false })} type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Time Picker</h4>
                    </div>
                    <div className="modal-body">

                        <div className="master">
                            <div className="number">
                                <span className="minus" name="hr" onClick={() => this.decrement('hr')}>-</span>
                                <input class="d104Input" type="number" min="0" max="200" name="hr" onChange={this.changeTime} value={this.state.hr} maxLength={2} readonly="readonly" />
                                <span className="plus" name="hr" onClick={() => this.increment('hr')}>+</span>
                                <label className="heading">Hr</label>
                            </div>
                            <div className="number">
                                <span className="minus" name="min" onClick={() => this.decrement('min')}>-</span>
                                <input class="d104Input" min="0" max="59" type="number" name="min" onChange={this.changeTime} value={this.state.min} maxLength={2} readonly="readonly" />
                                <span className="plus" name="min" onClick={() => this.increment('min')}>+</span>
                                <label className="heading">min</label>
                            </div>
                            <div className="number">
                                <span className="minus" name="sec" onClick={() => this.decrement('sec')}>-</span>
                                <input class="d104Input" min="0" max="59" type="number" name="sec" onChange={this.changeTime} value={this.state.sec} maxLength={2} readonly="readonly" />
                                <span className="plus" name="sec" onClick={() => this.increment('sec')}>+</span>
                                <label className="heading">sec</label>
                            </div>
                            <div className="number">
                                <span className="minus" name="ms" onClick={() => this.decrement('ms')}>-</span>
                                <input class="d104Input" min="0" max="999" type="number" name="ms" onChange={this.changeTime} value={this.state.ms} maxLength={2} readonly="readonly" />
                                <span className="plus" name="ms" onClick={() => this.increment('ms')}>+</span>
                                <label className="heading">ms</label>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer table_actions">
                        <a className="cmnbutton" onClick={() => this.disqualify()}>disqualify</a>

                        <button href={backLinks} className="cmnbutton backbtn fr" type="button" onClick={() => this.setState({ timepickerset: false })}>cancel</button>
                        <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.savePoint()} >Save</button>
                    </div>

                </Modal>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video">
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul className="winnerdropdown_ul">
                            {this.state.scoreCardD104s.map((accepted, index) => {
                                var name, contactNo, point, pointColor;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;
                                        point = cricketApi.msToTime1(point);
                                    }
                                    if (accepted.disqualified === true) {
                                        pointColor = { color: 'red' };
                                        point = 'disqualify';
                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" readOnly style={pointColor} value={point} data-toggle="modal" data-target="#myModal" onClick={() => this.setState({ setIndex: index, errorShow: false, hr: 0, min: 0, sec: 0, ms: 0, timepickerset: true })} />
                                </li>
                            })}
                        </ul>
                        <div className="table_actions winner_actions">
                            <button onClick={event => window.location.href = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId} className="cmnbutton backbtn fl">Back</button>
                            <a className="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

