import React, { Component } from "react";
import Header from "./Header";
// import DownloadAppPlatform from "./DownloadAppPlatform";
// import CricketFootballAlert from "./CricketFootballAlert";
import SectionRight from "./SectionRight";
import userMaleIcon from "./img/male.png";
import profilebg from "./img/profilebg.png";
import broadcastbg from "./img/broadcastbg.png";
import team from "./img/team.png";
import play from "./img/play.png";
import userFemaleIcon from "./img/female.png";
import { trackPromise } from "react-promise-tracker";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Buddies from "./Buddies";
import Teams from './Teams';
import Posts from './Posts';
import Broadcasts from './Broadcasts';
import ViewAbout from './ViewAbout';
import { Link } from 'react-router-dom';
import ConfigUrl from './ConfigUrl';
import sad from './img/sad.png';
import camera from './img/camera.png';
import Following from "./Following";
import Follower from "./Follower";
import ImgDropAndCrop from './ImgDropAndCrop';
// import NumberAttempt from './NumberAttempt';
import SectionLeft from './SectionLeft';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const videoUrl = ConfigUrl('videoUrl');
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
      visible: 10,
      sr: 0,
      error: false,
      user: "",
      users: [],
      posts: [],
      broadcasts: [],
      followings: "",
      followers: "",
      buddies: [],
      sports: [],
      imagePreviewUrl: '',
      previewImg: false,
      buddiesDetails: false,
      teamsDetails: false,
      broadcastsDetails: false,
      postsDetails: true,
      viewAbout: false,
      postsCount: false,
      buddyCount: false,
      teamsCount: false,
      broadcastsCount: false,
      allSports: [],
      optionsChecked: [],
      checked: true,
      showStore: false,
      showCreate: false,
      showFollowing: false,
      showFollower: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.loadsports = this.loadsports.bind(this);
    this.state.user = JSON.parse(localStorage.getItem("result"));
    this.liRefs = [React.createRef()];
  }
  changeData(event) {
    let checkedArray = this.state.optionsChecked;
    let selectedValue = event.target.value;

    if (event.target.checked === true) {
      checkedArray.push(selectedValue);
      this.setState({
        optionsChecked: checkedArray
      });
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({
        optionsChecked: checkedArray
      });
    }
  }
  changeEvent(event) {
    let checkedArray = this.state.optionsChecked;
    let selectedValue = event.target.value;
    if (event.target.checked === true) {
      checkedArray.push(selectedValue);
      this.setState({
        optionsChecked: checkedArray
      });
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({
        optionsChecked: checkedArray
      });
    }
  }
  //submit pic
  handleLanguage = (langValue) => {
    this.setState({ imageCrop: langValue });
  }
  submitPicture = () => {
    let that = this;
    let userD = { ...this.state.user };
    const apiUrl = userApi + "upload-file";
    let dataurl = that.state.imageCrop;
    console.log(dataurl)
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    var file = dataURLtoFile(dataurl, 'image.jpg');
    var timestamp = Math.floor(Date.now());
    if (file !== undefined) {
      var data = {
        "data": {
          "folder": "posts/" + this.state.user.contactNo,
          "fileName": "IMG_" + timestamp
        }
      };
      var form = new FormData();
      form.append("file", file);
      form.append("data", JSON.stringify(data));
      // let downloadUrl = '';     
      fetch(apiUrl, {
        method: 'post',
        "async": true,
        "crossDomain": true,
        "processData": false,
        'Content-Type': 'false',
        body: form
      })
        .then(function (response) {
          return trackPromise(response.json());
        })
        .then(function (result) {
          uploadSuggestedPostData(result.data.downloadUrl);
          userD.profilePic = result.data.downloadUrl
          that.setState({ user: allDaata });
        });
      const allDaata = this.state.user;
      delete allDaata.id;
      function uploadSuggestedPostData(data) {
        allDaata.profilePic = data;
        var that = this;
        fetch(userApi + "users/update-user", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: {
              user: allDaata,
            }
          })
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            console.log(result)
            localStorage.setItem('result', JSON.stringify(allDaata));
          });
      }
    }
  }
  loadMore = () => {
    const that = this;
    fetch(userApi + "users/buddy-profile", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          buddyId: this.state.user.contactNo,
          userId: this.state.user.contactNo
        }
      })
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        if (result.data.teams.length < 1) {
          that.setState({ teamsCount: true });
        }
        if (result.data.buddies.length < 1) {
          that.setState({ buddyCount: true });
        }
        if (result.data.broadcasts.length < 1) {
          that.setState({ broadcastsCount: true });
        }
        var sportsID = [];
        result.data.sports.map(x => {
          sportsID.push(x.sportsId);
          return true;
        });
        that.setState({ optionsChecked: sportsID });
        that.setState({
          posts: result.data.post.posts,
          users: result.data.post.users,
          sports: result.data.sports,
          teams: result.data.teams,
          buddies: result.data.buddies,
          broadcasts: result.data.broadcasts,
          followings: result.data.followings,
          followers: result.data.followers
        });
        if (result.data.post.posts.length === 0) {
          that.setState({ postsDetails: false, postsCount: true });
        }
      });
  };
  loadsports = () => {
    const that = this;
    fetch(userApi + "sports/get-sports", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          minimal: true
        }
      })
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {


        that.setState({
          allSports: result.data,
        });
      });

  };
  componentDidMount() {
    console.log(this.state.user)
    if (this.props.location.pathname === '/my-account/following') {
      this.setState({ postsDetails: false, showFollowing: true })
    }
    if (this.props.location.pathname === '/my-account/followers') {
      this.setState({ postsDetails: false, showFollower: true })
    }
    if (this.props.location.pathname === '/my-account/buddies') {
      this.setState({ postsDetails: false, buddiesDetails: true })
    }
    console.log(this.props.location.pathname)
    this.loadsports();
    this.loadMore();
  }
  captureImg = () => {
    confirmAlert({
      customUI: () => {
        return (
          <div>
            <input id="Upload" type="file" onChange={this.onImageChange} name="_photos" />

          </div>
        );
      }
    });

  }
  changeSports = (e) => {
    if (this.state.optionsChecked.length > 0) {
      const that = this;
      var userdata = this.state.user;

      userdata.sportIds = this.state.optionsChecked;
      delete userdata.id;
      fetch(userApi + "/users/update-user", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          data: {
            user: userdata,
          }
        })
      })
        .then(function (response) {
          return trackPromise(response.json());
        })
        .then(function (result) {
          that.loadMore();
        });

    }
    else {
      this.setState({ showStore: true })
      const that = this;
      window.setTimeout(function () {
        that.setState({ showStore: false })
      }, 2000);
    }
  }

  openBroadcast = (id) => {
    const url = videoUrl + id + ".mp4";
    confirmAlert({
      customUI: () => {
        return (
          <div className="postdata">
            <iframe title="broadcast" id="video1" src={url} frameborder="0" allowtransparency="true" allowfullscreen></iframe>
            <p></p>
          </div>
        );
      }
    });
  };

  render() {



    this.imageModal = (<div id="openCropModal" className="modal fade" role="dialog">
      <div className="modal-dialog">

        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">&times;</button>
            <h4 className="modal-title">Upload Image</h4>
          </div>

          <div className="modal-body">
            <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
          </div>

          <div className="modal-footer">
            <button onClick={() => this.submitPicture()} type="button" className="cmnbutton" data-dismiss="modal">Crop & Upload</button>
          </div>
        </div>
      </div>
    </div>)
    this.changeSportsData = (

      <div id="myModal" className="modal fade" role="dialog" >
        <div className="modal-dialog">
          <div className="modal-content modal-sports">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">&times;</button>
              <h4 className="modal-title">Edit sports skills</h4>
            </div>
            <div className="alert alert-info" style={{ display: this.state.showStore ? 'block' : 'none', textAlign: 'center' }} id="messageShow">
              Please Select atleast one sports
              </div>
            <div className="modal-body ">
              <div>
                <div className="sportsdiv sportsdiv_profile">
                  <ul className="sportsul">
                    {this.state.allSports.map((x, index) => {
                      var sportId;
                      this.state.sports.map((y) => {
                        if (y.sportsId === x.sportsId) {
                          sportId = x.sportsId;

                        }
                        return true;

                      })
                      if (sportId === x.sportsId) {

                        return (
                          <li key={x.name} onClick={(e) => this.liRefs[x.sportsId].click()}>
                            <div className="styled-input-single">
                              <input type="checkbox" name={x.name} id={"checkbox-example-" + index} value={x.sportsId} onClick={this.changeData.bind(this)} defaultChecked={this.state.checked} ref={el => this.liRefs[x.sportsId] = el} />
                              <label htmlFor={"checkbox-example-" + index} onClick={(e) => this.liRefs[x.sportsId].click()} ></label>
                            </div>
                            <span className="ss_spansportsimg"  ><img src={x.icon} alt="" /></span>
                            <label className="ss_sportstile" >{x.name}</label>
                          </li>
                        );
                      }
                      else {
                        return (
                          <li key={x.name} onClick={(e) => this.liRefs[x.sportsId].click()}>
                            <div className="styled-input-single">
                              <input type="checkbox" name={x.name} id={"checkbox-example-" + index} value={x.sportsId} onChange={this.changeEvent.bind(this)} ref={el => this.liRefs[x.sportsId] = el} />
                              <label htmlFor={"checkbox-example-" + index} onClick={(e) => this.liRefs[x.sportsId].click()}></label>
                            </div>
                            <span className="ss_spansportsimg" ><img src={x.icon} alt="" /></span>
                            <label className="ss_sportstile" >{x.name}</label>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="modal-footer modalbottom">
                <button type="button" onClick={() => this.changeSports()} className="cmnbutton" data-dismiss="modal" >Done</button>
              </div>

            </div>

          </div>
        </div>

      </div>
    );



    this.profileData = (
      <section
        className="profilesection"
        style={{ backgroundImage: "url(" + profilebg + ")" }}
      >
        {/* <button className="uploadbanner">Upload Banner</button> */}
        <span className="overlayprofie"></span>
        <div className="container">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="profilesec">
              <div className="profilesec_inner">

                {(() => {
                  if (this.state.user.profilePic === null) {
                    switch (this.state.user.gender) {
                      case "Male":
                        return (
                          <span
                            className="profilespan"
                            style={{
                              backgroundImage: "url(" + userMaleIcon + ")"
                            }}
                          >
                            {" "}
                          </span>
                        );
                      case "Female":
                        return (
                          <span
                            className="profilespan"
                            style={{
                              backgroundImage: "url(" + userFemaleIcon + ")"
                            }}
                          >
                            {" "}
                          </span>
                        );
                      default: return '';
                    }
                  }
                  else {
                    return (
                      <span
                        className="profilespan"
                        style={{
                          backgroundImage: "url(" + this.state.user.profilePic + ")"
                        }}
                      >
                        {" "}
                      </span>
                    );
                  }
                })()}
                <button id="cameraClick" data-toggle="modal" data-target="#openCropModal"><img src={camera} alt="" /></button>
                {/* <button id="cameraClick" onClick={(e) => this.captureImg()}><img src={camera} alt="" /></button> */}
                {/* <input id="Upload" type="file" onChange={this.onImageChange} name="_photos" style={{ 'display': 'none' }} /> */}

                {/* */}

              </div>
              <label className="profusername">{this.state.user.name}</label>
              {/* <label className="profusername">{this.state.user.name}</label> */}
              <ul className="followingul" style={{ 'cursor': 'pointer' }}>
                <li  >
                  <span onClick={event => this.setState({ showFollowing: true, viewAbout: false, buddiesDetails: false, postsDetails: false, teamsDetails: false, broadcastsDetails: false, showFollower: false, postsCount: false })}>
                    <label>{this.state.followings}</label>
                    <span>Followings</span>
                  </span>
                </li>
                <li>
                  <span onClick={event => this.setState({ showFollower: true, showFollowing: false, viewAbout: false, buddiesDetails: false, postsDetails: false, teamsDetails: false, broadcastsDetails: false, postsCount: false })}>
                    <label>{this.state.followers}</label>
                    <span>Followers</span>
                  </span>
                </li>

                {/* <li>
                  <a>
                    <label>{this.state.buddyCount}</label>
                    <span>Buddies</span>
                  </a>
                </li> */}
              </ul>
              <button className="changesports" data-toggle="modal" data-target="#myModal">Change Sports Skills</button>
            </div>
            <div>
              <li>
                <span></span>
              </li>
            </div>
          </div>
        </div>
      </section>
    );

    this.favouriteSports = (
      <section>
        <div className="container">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"></div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12  pad7">
            <div className="skills skills-scrollbar">
              <ul>
                {this.state.sports.map(x => {
                  return (
                    <li key={x.name}>
                      <Link to="#">
                        <span
                          style={{ backgroundImage: "url(" + x.icon + ")" }}
                          className="skillsimg"
                        ></span>
                        <span className="sportsname">{x.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );

    this.leftSection = (
      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7 leftsecprof userleft">
        <section className="d_leftsec useraccount">
          <div className="commanbox ">
            <h3 className="commanhead">
              About Us <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: false, postsDetails: false, broadcastsDetails: false, viewAbout: true, postsCount: false, showFollowing: false, showFollower: false })} >View All</button>
            </h3>

            <div className="aboutleftdata">
              <p>{this.state.user.email}</p>
              <p>{this.state.user.contactNo}</p>
            </div>

            <h3 className="commanhead">
              Buddies {this.state.buddyCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: true, postsDetails: false, broadcastsDetails: false, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
            </h3>
            {this.state.buddyCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
              <label > sorry you don't have any buddies</label><br />
              <Link style={{ color: "#52af4a" }}> Invite Now</Link>

            </div> :
              <ul className="aboutbuddies">
                {this.state.buddies.map(x => {
                  return (() => {
                    if (x.profilePic === null) {
                      switch (x.gender) {
                        case "Male":
                          return (
                            <li key={x.contactNo}>
                              <a href={"/buddies?id=" + x.contactNo}
                                style={{
                                  backgroundImage: "url(" + userMaleIcon + ")"
                                }}
                              >
                                {" "}
                              </a>
                              <span>{x.name}</span>
                            </li>
                          );
                        case "Female":
                          return (
                            <li key={x.contactNo}>
                              <a href={"/buddies?id=" + x.contactNo}
                                style={{
                                  backgroundImage: "url(" + userFemaleIcon + ")"
                                }}
                              >
                                {" "}
                              </a>
                              <span>{x.name}</span>
                            </li>
                          );
                        default: return '';
                      }
                    }
                    else {
                      return (
                        <li key={x.contactNo}>
                          <a href={"/buddies?id=" + x.contactNo}
                            style={{
                              backgroundImage: "url(" + x.profilePic + ")"
                            }}
                          >
                            {" "}
                          </a>
                          <span>{x.name}</span>
                        </li>
                      );
                    }
                  })();
                })}
              </ul>
            }
            <h3 className="commanhead">
              Teams {this.state.teamsCount ? <div></div> : <Link to={'/teamslist'}>View All</Link>}
            </h3>
            {/* <h3 className="commanhead">
              Teams {this.state.teamsCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: true, buddiesDetails: false, postsDetails: false, broadcastsDetails: false, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
            </h3> */}
            {this.state.teamsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
              <label > sorry you don't have any teams</label><br />
              <Link style={{ color: "#52af4a" }}> create Team</Link>

            </div> :
              <ul className="aboutteamsul">
                {this.state.teams.map(function (x) {
                  if (x.teamIcon === null) {
                    return (
                      <li key={x.teamName}>
                        <a style={{ backgroundImage: "url(" + team + ")" }} href={"/teamprofilepage/index.html?id=" + x.teamId}> </a>
                        {/* <Link style={{ backgroundImage: "url(" + team + ")" }}></Link> */}
                        <span>{x.teamName}</span>
                      </li>
                    );
                  }
                  else {
                    return (
                      <li key={x.teamName}>
                        <a style={{ backgroundImage: "url(" + team + ")" }} href={"/teamprofilepage/index.html?id=" + x.teamId}> </a>
                        <span>{x.teamName}</span>
                      </li>
                    );
                  }
                })}
              </ul>
            }
            <h3 className="commanhead">
              Broadcast {this.state.broadcastsCount ? <div></div> : <button onClick={() => this.setState({ teamsDetails: false, buddiesDetails: false, postsDetails: false, broadcastsDetails: true, viewAbout: false, postsCount: false, showFollowing: false, showFollower: false })}>View All</button>}
            </h3>
            {this.state.broadcastsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
              <label > sorry you don't have any broadcasts</label><br />
              <Link to="#" style={{ color: "#52af4a" }}> create now</Link>

            </div> :
              <ul className="aboutbroadcast" >
                {this.state.broadcasts.map((x) => {
                  if (x.icon === null) {
                    return (
                      <li key={x.broadcastId} onClick={() => this.openBroadcast(x.broadcastId)} >
                        <Link to="#" style={{ backgroundImage: "url(" + broadcastbg + ")" }}>
                          <img src={play} alt="" />
                        </Link>
                      </li>
                    );
                  }
                  else {
                    return (
                      <li key={x.broadcastId}>
                        <Link to="#" style={{ backgroundImage: "url(" + x.icon + ")" }}>
                          <img src={play} alt="" />
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            }
          </div>
        </section>
      </div>
    );

    return (
      <div>
        <Header />
        {this.profileData}
        {this.changeSportsData}

        {this.favouriteSports}
        <div className="container profilecontainer">
          {/* <SectionLeft /> */}
          {this.leftSection}
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
            <section className="d_midsec">
              {/* <NumberAttempt /> */}
              {this.state.showFollower ? <Follower userbuddy={this.state.user.contactNo} /> : <div></div>}
              {this.state.showFollowing ? <Following userbuddy={this.state.user.contactNo} /> : <div></div>}
              {this.state.viewAbout ? <ViewAbout userbuddy={this.state.user.contactNo} /> : <div></div>}
              {this.state.buddiesDetails ? <Buddies userbuddy={this.state.user.contactNo} permission={true} /> : <div></div>}
              {this.state.teamsDetails ? <Teams userbuddy={this.state.user.contactNo} permission={true} /> : <div></div>}
              {this.state.postsDetails ? <Posts userbuddy={this.state.user.contactNo} permission={true} /> : <div></div>}
              {this.state.postsCount ? <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                <label > sorry you don't have any posts</label><br />
                <Link to={"/"} style={{ color: "#52af4a" }}> create posts</Link>

              </div> : <div></div>}
              {this.state.broadcastsDetails ? <Broadcasts userbuddy={this.state.user.contactNo} broadcasts={this.state.broadcasts} permission={true} /> : <div></div>}
              {this.editFavouriteSports}
              {this.imageModal}
            </section>
          </div>
          <SectionRight />
        </div>
      </div>
    );
  }
}

export default MyAccount;
