import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import play from '../img/play.png';
import logo from '../img/finallogo.png';
import ConfigUrl from '../ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d107_Baseball_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: false,
            byTotalHits: '',
            toTotalHits: '',
            byTotalRuns: '',
            toTotalRuns: '',
            numberofSets: '',
            rounds: [],
        };
    }

    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);
        console.log(this.props.matchId);

        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    users: result.data.users,
                    teams: result.data.teams
                })

                if (result.data.score !== null) {

                    if (result.data.score.d107BaseBallScoreCard !== null) {

                        if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== null) {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: 0, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }

                        if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== null) {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: 0, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }

                        //storing set numbers and rounds
                        that.setState({ rounds: result.data.score.d107BaseBallScoreCard.rounds, numberofSets: result.data.score.d107BaseBallScoreCard.noOfSets })

                        //storing byteam and toteam
                        that.props.profileTeams.map(team => {
                            if (team.teamId === result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.teamId) {
                                that.setState({ byTeamName: team.teamName });
                            }
                            if (team.teamId === result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.teamId) {
                                that.setState({ toTeamName: team.teamName });
                            }
                        })

                    }

                }

            });

        if (that.props.challenge.challengeAction === 'ended') {
            that.setState({ matchStatus: true });
        }

    }

    render() {
        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;

        return (
            <React.Fragment>
                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video" style={{ backgroundImage: "url(" + noBroadcast + ")" }}>
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <thead>
                                        <tr>
                                            <th>Teams</th>
                                            <th>Score</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{this.state.byTeamName}</td>
                                            <td>{this.state.byTotalRuns}</td>
                                            {this.state.byWinner ? <td><img src={logo} className="winnerimg_td" alt="" /> Winner</td> : ''}
                                        </tr>
                                        <tr>
                                            <td>{this.state.toTeamName}</td>
                                            <td>{this.state.toTotalRuns}</td>
                                            {this.state.toWinner ? <td><img src={logo} className="winnerimg_td" alt="" /> Winner</td> : ''}
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <h4 className="scorehead">Match Score</h4>

                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <thead>
                                        <tr>
                                            <th>Teams</th>
                                            {this.state.rounds.map((p, i = 1) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <th>R{i}</th>
                                                }
                                            })}
                                            <th>Total Hits</th>
                                            <th>Total Runs</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{this.state.byTeamName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input disabled placeholder={p.challengedByInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.byTotalHits}</td>
                                            <td>{this.state.byTotalRuns}</td>
                                        </tr>

                                        <tr>
                                            <td>{this.state.toTeamName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input disabled placeholder={p.challengedToInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.toTotalHits}</td>
                                            <td>{this.state.toTotalRuns}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );

    }
}