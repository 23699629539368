import React, { Component } from "react";
import userMaleIcon from "./img/male.png";
import likedark from "./img/likedark.png";
import share from "./img/share.png";
import comment from "./img/comment.png";
import { trackPromise } from "react-promise-tracker";
import userFemaleIcon from "./img/female.png";
import more from "./img/more.png";
import likebox from "./img/likebox.png";
import likegreen from "./img/likegreen.png"
import { } from 'prismic-reactjs';
import { Link, Redirect } from 'react-router-dom';
import happiness from './img/happiness.png'
import ConfigUrl from './ConfigUrl';
import LikeList from './LikeList';
import { confirmAlert } from 'react-confirm-alert';
import CommentList from "./CommentList";
// import { unstable_renderSubtreeIntoContainer } from "react-dom";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buddies: [],
            posts: [],
            users: [],
            showLikes: false,
            showComments: false,
            itemsPerIndex: 0,
            postsData: true,
            commentData: '',
            commentList: false,
            data: [],
            isProfileRedirection: false,
        };
        this.loadMore = this.loadMore.bind(this);
    }

    nowDelete = (gotDelete) => {
        const notiDeleted = userApi + "/posts/delete-post";
        fetch(notiDeleted, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "postId": gotDelete,
                }
            })
        })
        setTimeout(() => {
            this.loadMore()
        }, 500)



    }

    loadMore = () => {
        var index = parseInt(this.state.itemsPerIndex);
        index = index + 5;
        this.setState({ itemsPerIndex: index });

        const that = this;
        fetch(userApi + "users/get-buddies", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.props.userbuddy,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    buddies: result.data,
                });
            });
        fetch(userApi + "posts/get-posts", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.props.userbuddy,
                    index: 0,
                    itemsPerIndex: index,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result.data);
                if (result.data.posts !== undefined) {
                    that.setState({
                        posts: result.data.posts,
                        users: result.data.users,
                    });
                }
            });

    };
    componentDidMount() {
        const that = this;
        this.loadMore();



    }
    showMorePost = () => {
        this.loadMore();
    }
    showAllComments = (postId) => {
        let reactedBuddies = [];
        this.state.posts.map(x => {
            if (x.postId === postId) {
                reactedBuddies = x.reactedBuddies;
            }
            return true;
        });
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.users,
        }
        console.log(data);
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (postId) => {
        let reactedBuddies = [];
        this.state.posts.map(x => {
            if (x.postId === postId) {
                reactedBuddies = x.reactedBuddies;
            }
            return reactedBuddies;
        });
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.users,
        }

        confirmAlert({
            customUI: () => {
                return (
                    <div>
                        <LikeList data={data} />
                    </div>
                );
            }
        })

    }
    commentFunction = (postId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        // this.setState({ user: JSON.parse(localStorage.getItem("result")) });
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        const posts = this.state.posts;
        var postAll = [];
        posts.map(x => {
            if (x.postId === postId) {
                if (x.reactedBuddies === null) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    }];
                    delete x.id;   //Always use this in update case 
                    x.reactedBuddies = reactedBuddies;
                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": x,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            console.log(result);
                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    };
                    x.reactedBuddies.push(reactedBuddies);
                    delete x.id; //Always use this in update case 
                    let that = this;
                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": x,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            that.setState({ commentData: '' });
                        });

                }
            }
            postAll.push(x);
            return true;
        });
        this.setState({ posts: postAll });
        // this.loadComponent();
    }
    likeFunction = (postId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        // this.setState({ user: JSON.parse(localStorage.getItem("result")) });
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        const posts = this.state.posts;
        var postAll = [];
        posts.map(x => {
            if (x.postId === postId) {
                if (x.reactedBuddies === null) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "reactedType": "cheer",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": null,
                    }];
                    delete x.id;   //Always use this in update case 
                    x.reactedBuddies = reactedBuddies;
                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": x,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            console.log(result);
                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "reactedType": "cheer",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": null,
                    };
                    x.reactedBuddies.push(reactedBuddies);
                    delete x.id; //Always use this in update case 
                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": x,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            console.log(result);
                        });
                }
            }
            postAll.push(x);
            return true;
        });
        this.setState({ posts: postAll });
        // this.loadComponent();
    }


    profileRedirection = (cNo) => {
        // this.setState({redirectonContacNu:cNo,isProfileRedirection:true})
        window.location = "/buddies?id=" + cNo;
    }


    render() {
        let hideLocation = false;
        if (this.props.for === 'Page') {
            hideLocation = true;
        }
        if (this.state.isProfileRedirection) {
            return <Redirect
                to={{
                    pathname: "/buddies?id=" + this.state.redirectonContacNu
                }}
            />

        }


        const user = JSON.parse(localStorage.getItem("result"));
        const contactNumber = user.contactNo;
        const width100 = {
            width: "100%"
        };

        return (
            <div className="commanbox" id="profilebuddies" >
                {hideLocation ? null : <h3 className="commanhead" >Posts {this.props.permission ? <Link to="/" className="headbutton">Create Posts</Link> : <div></div>}</h3>}
                {
                    this.state.posts.map(x => {
                        var buttonDisable = false;
                        var likeCount = 0; var commentCount = 0; var buddyName = null;
                        if (x.reactedBuddies !== null) {
                            x.reactedBuddies.map(z => {
                                if (z.reactedType === "cheer") {
                                    if (z.buddyId === contactNumber) {
                                        buttonDisable = true;
                                    }
                                    this.state.buddies.map((buddy) => {
                                        if (buddy.contactNo === z.buddyId) {
                                            buddyName = buddy.name;
                                        }
                                        return buddyName;
                                    })
                                    likeCount++;
                                }
                                if (z.reactedType === "comment") {
                                    commentCount++;
                                }
                                return likeCount;
                            });
                            if (buddyName === null) {
                                x.reactedBuddies.map((z) => {
                                    this.state.users.map((user) => {
                                        if (user.contactNo === z.buddyId) {
                                            buddyName = user.name;

                                        }
                                        return buddyName;
                                    })
                                    return true;
                                })
                            }
                        }
                        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "2-digit",
                            second: "2-digit"
                        }).format(x.timestamp);

                        return (
                            <div key={x.postId} className="divpost sharepostdiv" id="accordion">
                                <div className="userdetail">
                                    {this.state.users.map((y) => {
                                        if (y.contactNo === x.createdBy) {
                                            if (y.profilePic === null) {
                                                if (y.gender === 'Male') {
                                                    return <ul key={x.createdBy} className="postuserul">
                                                        <li className="p_userli">
                                                            <span onClick={() => this.profileRedirection(x.createdBy)} className="p_userimg" style={{
                                                                backgroundImage: "url(" + userMaleIcon + ")"
                                                            }}> </span>
                                                            <label className="cursorPointer" onClick={() => this.profileRedirection(x.createdBy)} >{y.name}</label>
                                                            <p className="p_usertime myprofile-post">{formattedTimestamp}</p><span className="p_usertime myprofile-post">{x.location}</span></li>
                                                        <li className="p_usermore">
                                                            {contactNumber === y.contactNo ? <div class="dropdown dropaccount messagedrop">
                                                                <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" />
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li onClick={() => this.nowDelete(x.postId)}><Link to={'#'}>Delete</Link></li>
                                                                </ul>
                                                            </div> : null}

                                                        </li>
                                                    </ul>
                                                }
                                                else {
                                                    return <ul key={x.createdBy} className="postuserul">
                                                        <li className="p_userli">
                                                            <span onClick={() => this.profileRedirection(x.createdBy)} className="p_userimg" style={{
                                                                backgroundImage: "url(" + userFemaleIcon + ")"
                                                            }}> </span>
                                                            <label className="cursorPointer" onClick={() => this.profileRedirection(x.createdBy)} >{y.name}</label>
                                                            <p className="p_usertime myprofile-post">{formattedTimestamp}</p><span className="p_usertime myprofile-post">{x.location}</span></li>
                                                        <li className="p_usermore">
                                                            {contactNumber === y.contactNo ? <div class="dropdown dropaccount messagedrop">
                                                                <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" />
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li onClick={() => this.nowDelete(x.postId)}><Link to={'#'}>Delete</Link></li>
                                                                </ul>
                                                            </div> : null}
                                                        </li>
                                                    </ul>
                                                }
                                            }
                                            else {
                                                return <ul key={x.createdBy} className="postuserul">
                                                    <li className="p_userli">
                                                        <span onClick={() => this.profileRedirection(x.createdBy)} className="p_userimg" style={{
                                                            backgroundImage: "url(" + y.profilePic + ")"
                                                        }}> </span>
                                                        <label className="cursorPointer" onClick={() => this.profileRedirection(x.createdBy)} >{y.name}</label>
                                                        <p className="p_usertime myprofile-post">{formattedTimestamp}</p><span className="p_usertime myprofile-post">{x.location}</span> </li>
                                                    <li className="p_usermore">
                                                        {contactNumber === y.contactNo ? <div class="dropdown dropaccount messagedrop">
                                                            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" />
                                                            </button>
                                                            <ul class="dropdown-menu">
                                                                <li onClick={() => this.nowDelete(x.postId)}><Link to={'#'}>Delete</Link></li>
                                                            </ul>
                                                        </div> : null}
                                                    </li>
                                                </ul>
                                            }
                                        }
                                        return true;
                                    })}

                                </div>
                                {
                                    (() => {
                                        switch (x.mediaType) {
                                            case 'text':
                                                return <div className="postdata">
                                                    <p>{x.text}</p>
                                                </div>;
                                            case 'image':
                                                return <div className="postdata">
                                                    <p>{x.text}</p>
                                                    <img style={{ 'width': '100%' }} src={x.mediaUrl} className="img-responsive" alt="" />
                                                </div>;
                                            case 'video':
                                                if (x.youtubeId !== null) {
                                                    return <div className="postdata">
                                                        <p>{x.text}</p>
                                                        <iframe style={{ 'width': '100%', 'height': '200px' }} src={'https://www.youtube.com/embed/' + x.youtubeId} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" title="myFrame" ></iframe>
                                                    </div>;
                                                }
                                                else {
                                                    return <div className="postdata">
                                                        <p>{x.text}</p>
                                                        <iframe title="myFrame" style={{ 'width': '100%', 'height': '200px' }} id="video1" src={x.mediaUrl} frameBorder="0" allowtransparency="true"  ></iframe>
                                                    </div>;
                                                }

                                            default: return '';
                                        }
                                    })()
                                }
                                <div className="postlikediv">
                                    {(() => {
                                        switch (likeCount) {
                                            case 1:
                                                switch (commentCount) {
                                                    case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span >   liked this </span>
                                                    </span>;
                                                    default: return <span className="userlikeinner"> <span ><img src={likebox} alt="" /> <b>{buddyName}</b> liked this</span><span key={x.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(x.postId)} data-toggle="collapse" data-parent="#accordion" href={'#' + x.postId} > {commentCount} comments</span>
                                                    </span>
                                                }
                                            case 0:
                                                switch (commentCount) {
                                                    case 0: return '';
                                                    default: return <span className="userlikeinner"><span key={x.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(x.postId)}> {commentCount} comments</span>
                                                    </span>;
                                                }
                                            default:
                                                switch (commentCount) {
                                                    case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(x.postId)}> and {likeCount - 1}more liked this </span>
                                                    </span>;
                                                    default: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(x.postId)}> and {likeCount - 1}more liked this</span><span key={x.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(x.postId)} data-toggle="collapse" data-parent="#accordion" href={'#' + x.postId}> {commentCount} comments</span>
                                                    </span>
                                                }
                                        }
                                    })()}

                                    <ul className="commentul">
                                        {buttonDisable ? <li className="commentli commentinner">
                                            <img src={likegreen} className="likeuser" alt="" />
                                            <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount}</span></span>
                                        </li> : <li className="commentli commentinner" onClick={(e) => this.likeFunction(x.postId)}>
                                            <img src={likedark} className="likeuser" alt="" />
                                            <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount} </span></span>
                                        </li>}
                                        {/* <li className="commentli commentinner" onClick={(e) => this.likeFunction(x.postId)}  >
                                            <img src={likedark} className="likeuser" alt="" />
                                            <span className="likespan" >Like<span className="spancount">{likeCount}</span></span>
                                        </li> */}
                                        <li key={x.postId} className="commentinner commentinner2" id="comment1">
                                            <img src={comment} className="commentimg" alt="" />
                                            <span data-toggle="collapse" data-parent="#accordion" href={'#' + x.postId} onClick={(e) => this.setState({ commentList: false })}>Comment<span className=" spancount">{commentCount}</span></span> </li>
                                        <li className="commentinner commentinner3" id="" >
                                            <Link to="#" type="" className="" data-toggle="modal" data-target="#myModal"><img src={share} className="commentimg" alt="" />
                                                <span>Share<span className="spancount"></span></span></Link> </li>
                                    </ul>
                                    <div className="postcomment" id={x.postId}> <span className="" style={{
                                        backgroundImage: "url(" + userMaleIcon + ")"
                                    }}></span>
                                        {this.state.commentList ? <CommentList data={this.state.data} /> : <div></div>}
                                        <div key={x.postId} className="postcomment_div" style={{ marginTop: '10px' }}> <Link to="#"><img src={happiness} alt="" /></Link>
                                            <textarea id={x.postId} value={this.state.commentData} onChange={event => this.setState({ commentData: event.target.value })}></textarea>
                                            <button onClick={(e) => this.commentFunction(x.postId)}>Post</button>
                                        </div>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                <button onClick={() => this.showMorePost()}
                    type="button"
                    className="btn btn-success"
                    style={width100}>
                    Load more
                </button>
            </div>
        );

    }
}
export default Posts;