import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import male from "../img/male.png";
import female from "../img/female.png";
import searchnew from "../img/searchnew.png";
import * as myUtilclass from '../DemoTournament/DOrganiseMatchUtil';
//import * as myFeedUtilclass from '../DemoTournament/DTournamentsUtil';
var score;
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournaments_d107_tt2tt3_kabaddi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107KabbadiScoreCard: {},
            byMemberModalOpen: false,
            byMemberModalOpen: false,
            byteamIds: '',
            byteamIds: '',
            score: false,
            bypoints: '',
            topoints: '',
            byPlayerScores: [],
            toPlayerScores: [],
            tieWinner: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }

    doneMatch() {
        let d107KabbadiScoreCard = this.state.d107KabbadiScoreCard;
        if (this.state.byWinner) {
            d107KabbadiScoreCard.byKabaddiScoreCard.winner = true;
        }
        if (this.state.toWinner) {
            d107KabbadiScoreCard.toKabaddiScoreCard.winner = true;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107KabbadiScoreCard: d107KabbadiScoreCard,
        }

        DUpdateTournamentsScore(score);

        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var tournament = this.props.tournament;
        console.log(this.props.tournament)
        delete tournament.id;
        tournament.tournamentAction = tournamentAction;
        tournament.modifiedOn = timpstampData;
        tournament.tournamentWinnerId = this.state.winner;
        let that = this;
        that.setState({ winnerModalOpen: false });

        let nrr1 = 0.0;
        let nrr2 = 0.0;
        let organiseMatchObj = that.state.tt23Matches;
        delete organiseMatchObj.id;
        let matchPair = that.state.matchPairObj;
        matchPair.ended = true;
        matchPair.winnerId = that.state.winner;
        let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
        declareWinner.then(result => {
            console.log(result)
            // var updateOrganize = myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);
            // updateOrganize.then(result => {

            // });
            window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId;

        })

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }
    backState = () => {
        $("#setAttemptModal .close").click();

        window.location = "/dtournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }

    endEvent() {

        let byTotal = this.state.d107KabbadiScoreCard.byKabaddiScoreCard.points;
        let toTotal = this.state.d107KabbadiScoreCard.toKabaddiScoreCard.points;
        if (byTotal > toTotal) {
            this.setState({ byWinner: true, winner: this.props.tournament.accepted[0] })
        }
        else if (toTotal > byTotal) {
            this.setState({ toWinner: true, winner: this.props.tournament.accepted[1] })
        }
        else {
            this.setState({ tieWinner: true })
        }
        this.setState({ winnerModalOpen: true })

    }
    getScore() {
        console.log(this.props.tournament)
        console.log(this.props.profileTeams)
        console.log(this.props.profileUsers)
        console.log(this.props.matchId)
        console.log(this.props.tournament.tournamentModes)
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        let byName; let toName;
                        if (that.props.tournament.tournamentMode === 'buddy') {

                            that.props.profileUsers.map(user => {


                                if (that.props.tournament.accepted[0] === user.contactNo) {
                                    byName = user.name
                                }
                                if (that.props.tournament.accepted[1] === user.contactNo) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                        }
                        else {

                            that.props.profileTeams.map(user => {

                                if (that.props.tournament.accepted[0] === user.teamId) {
                                    byName = user.teamName
                                }
                                if (that.props.tournament.accepted[1] === user.teamId) {
                                    toName = user.teamName
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        that.setState({ byName: byName, toName: toName, score: false });
                    }

                    else {
                        if (result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard !== null) {
                            that.setState({ bypoints: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points });
                        }

                        if (result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard !== null) {
                            that.setState({ topoints: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points });
                        }

                        that.setState({
                            score: true, d107KabbadiScoreCard: result.data.score.d107KabbadiScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams,
                            // strokeNo: result.data.score.d107KabbadiScoreCard.strokeNo
                        });

                        let byName; let toName;
                        console.log(that.props.profileUsers)
                        if (that.props.tournament.tournamentMode === 'buddy') {

                            that.props.profileUsers.map(user => {

                                if (that.props.tournament.accepted[0] === user.contactNo) {
                                    byName = user.name
                                }
                                if (that.props.tournament.accepted[1] === user.contactNo) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                        }
                        else {
                            that.props.profileTeams.map(user => {
                                if (that.props.tournament.accepted[0] === user.teamId) {
                                    byName = user.teamName
                                }
                                if (that.props.tournament.accepted[1] === user.teamId) {
                                    toName = user.teamName
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                    }
                });
        }
    }


    selectPlayerPoint(type, val) {
        var pointValue = val;
        var type = type;
        if (this.state.score) {
            if (type === 'challengedBy') {

                var d107 = this.state.d107KabbadiScoreCard;
                if (d107.byKabaddiScoreCard !== null) {

                    var byUndoScoresList = [];
                    byUndoScoresList = d107.byKabaddiScoreCard.toUndos;
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    d107.strokeNo = d107.strokeNo + 1;
                    byUndoScoresList.push(pscoreObj);
                    d107.byKabaddiScoreCard.toUndos = byUndoScoresList;
                    d107.byKabaddiScoreCard.points = d107.byKabaddiScoreCard.points + pointValue;
                    let teamGoal = d107.byKabaddiScoreCard.points;
                    this.setState({ bypoints: teamGoal });

                }
                else {
                    var byUndoScoresList = [];
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    byUndoScoresList.push(pscoreObj);
                    d107.strokeNo = d107.strokeNo + 1;
                    d107.byKabaddiScoreCard = {
                        points: pointValue,
                        teamId: this.props.tournament.accepted[0],
                        winner: false,
                        toUndos: byUndoScoresList,
                    };
                    let teamGoal = pointValue;
                    this.setState({ bypoints: teamGoal });
                }
            }

            if (type === 'challengedTo') {
                var d107 = this.state.d107KabbadiScoreCard;

                if (d107.toKabaddiScoreCard !== null) {
                    var toUndoScoresList = [];
                    toUndoScoresList = d107.toKabaddiScoreCard.toUndos;
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    d107.strokeNo = d107.strokeNo + 1;
                    toUndoScoresList.push(pscoreObj);
                    d107.toKabaddiScoreCard.toUndos = toUndoScoresList;
                    d107.toKabaddiScoreCard.points = d107.toKabaddiScoreCard.points + pointValue;
                    let teamGoal = d107.toKabaddiScoreCard.points;
                    this.setState({ topoints: teamGoal });

                }
                else {
                    var toUndoScoresList = [];
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    toUndoScoresList.push(pscoreObj);

                    d107.strokeNo = d107.strokeNo + 1;
                    d107.toKabaddiScoreCard = {
                        points: pointValue,
                        teamId: this.props.tournament.accepted[1],
                        winner: false,
                        toUndos: toUndoScoresList,
                    };

                    let teamGoal = pointValue;
                    this.setState({ topoints: teamGoal });
                }
            }
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107KabbadiScoreCard: d107,
            }
            this.setState({ d107KabbadiScoreCard: d107 });
            // setTimeout(() => { console.log(this.state.d107KabbadiScoreCard) }, 1000);

            DUpdateTournamentsScore(score);
            // this.getScore();
        }

        else {

            pointValue = val;
            var toUndoScoresList = [];
            var pscoreObj = {
                strokeNo: 1,
                points: pointValue,
                type: type,
            }
            toUndoScoresList.push(pscoreObj);
            const that = this;
            if (type === 'challengedBy') {

                var d107KabbadiScoreCard = {
                    strokeNo: 1,
                    byKabaddiScoreCard: {
                        points: pointValue,
                        teamId: this.props.tournament.accepted[0],
                        winner: false,
                        toUndos: toUndoScoresList,
                    },
                    toKabaddiScoreCard: null
                }
                let teamGoal = pointValue;
                this.setState({ bypoints: teamGoal });
            }
            else {

                var d107KabbadiScoreCard = {
                    strokeNo: 1,
                    byKabaddiScoreCard: null,
                    toKabaddiScoreCard: {
                        points: pointValue,
                        teamId: this.props.tournament.accepted[1],
                        winner: false,
                        toUndos: toUndoScoresList,
                    }
                }
                let teamGoal = pointValue;
                this.setState({ topoints: teamGoal });
            }
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107KabbadiScoreCard: d107KabbadiScoreCard,
            }
            this.setState({ d107KabbadiScoreCard: d107KabbadiScoreCard, score: true });
            console.log(score);
            DUpdateTournamentsScore(score);
            // this.getScore();
        }
    }
    byMemberCloseModal() {
        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
    }


    ToundoList = () => {
        var d107 = this.state.d107KabbadiScoreCard;
        var strokeNo = d107.strokeNo;
        var toUndo;
        var bygoalPoints;
        var togoalPoints;
        var bystokeMatched = false;
        var tostokeMatched = false;
        if (d107.byKabaddiScoreCard !== null) {
            d107.byKabaddiScoreCard.toUndos.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    bystokeMatched = true;
                    bygoalPoints = list.points;
                }
            })
            if (bystokeMatched) {
                var toUndoList = d107.byKabaddiScoreCard.toUndos.filter((x) => x != toUndo)
                d107.strokeNo = strokeNo - 1;
                if (d107.byKabaddiScoreCard.points !== 0) {
                    d107.byKabaddiScoreCard.points = d107.byKabaddiScoreCard.points - bygoalPoints;
                    //d107.byKabaddiScoreCard.position = d107.byKabaddiScoreCard.position - 1;
                }
                d107.byKabaddiScoreCard.toUndos = toUndoList;
                this.setState({ byPlayerScores: toUndoList, bypoints: d107.byKabaddiScoreCard.points })
            }
        }
        if (d107.toKabaddiScoreCard !== null) {
            d107.toKabaddiScoreCard.toUndos.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    tostokeMatched = true;
                    togoalPoints = list.points;
                }
            })
            if (tostokeMatched) {
                var toUndoList = d107.toKabaddiScoreCard.toUndos.filter((x) => x != toUndo)
                d107.strokeNo = strokeNo - 1;
                if (d107.toKabaddiScoreCard.points !== 0) {
                    d107.toKabaddiScoreCard.points = d107.toKabaddiScoreCard.points - togoalPoints;
                    //d107.toKabaddiScoreCard.position = d107.toKabaddiScoreCard.position - 1;
                }
                d107.toKabaddiScoreCard.toUndos = toUndoList;
                this.setState({ toPlayerScores: toUndoList, topoints: d107.toKabaddiScoreCard.points })
            }
        }
        this.setState({ d107KabbadiScoreCard: d107 });
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107KabbadiScoreCard: d107,
        }
        DUpdateTournamentsScore(score);
    }
    editWinner = () => {
        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {
        var tt23Matches, formatType = -1;
        const apiUrl = rshApi + "/demo-tournaments/get-tournament";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.tt23Matches.finals) {
                    that.setState({ finals: result.data.finals, tournament: result.data.tournament, users: result.data.users, ttType: result.data.tt23Matches.ttType })
                }
                if (result.data.tt23Matches.ttType === 3) {

                }
                if (result.data.tournament.tournamentMode === 'team') {
                    that.setState({ teams: result.data.teams })
                }
                that.setState({ tt23Matches: result.data.tt23Matches })

                if (result.data.tournament.matchType === 'final') {
                    that.setState({ formatType: that.props.fType })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Quarter final') {
                    that.setState({ formatType: that.props.fType, quarterFinals: result.data.tt23Matches.quarterFinals })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Semi final') {
                    that.setState({ formatType: that.props.fType, semiFinals: result.data.tt23Matches.semiFinals })
                    formatType = that.props.fType;
                }
                var matchPairObj = myUtilclass.getMatchPair(result.data.tournament, result.data.tt23Matches, false, formatType, that.props.matchId);
                that.setState({ matchPairObj: matchPairObj });
            });
        this.getScore();
    }

    render() {
        var byteamList = [], toteamList = [];
        var byTeamId, toTeamId;
        this.props.profileTeams.map(pt => {
            if (pt.teamId === this.props.tournament.accepted[0]) {
                byteamList = pt.teamMembers;
                byTeamId = pt.teamId;
            }
            if (pt.teamId === this.props.tournament.accepted[1]) {
                toteamList = pt.teamMembers;
                toTeamId = pt.teamId;
            }
        })

        var userName;
        var userPic;
        var userGender;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td>{this.state.byName}</td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.toName}</td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>


                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Points</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.bypoints ? this.state.bypoints : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("challengedBy", 1)}>[+]</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.topoints ? this.state.topoints : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("challengedTo", 1)}>[+]</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
