
import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import noBroadcast from '../img/noBroadcast.jpg';
import challengebg from "../img/challenegbg.png";
import openchallenge from "../img/openchallenge.png";
import challengefill from "../img/challengefill.png";
import userMaleIcon from "../img/male.png";
import play from '../img/play.png';
import teamIcon from '../img/team.png';
import location from "../img/location.png";
import matchbg from '../img/matchbg.png';
import winnerlogo from '../img/winnerlogo.png';
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
import challengevs from '../img/challengevs.png';
import $ from 'jquery';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const userApi = ConfigApiUrl;
var userPic1;
var userName1;
var userPic;
var userName;
const that = this;

export default class Feed_d107baseball extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            userData1: [],
            teamData: [],
            acceptedChallenge: [],
            challengeData: {},
            scoreCardD104s: [],
            d107BaseBallScoreCard: {},
            bySetWon: [],
            toSetWon: [],
            teamOpen: [],
            showApply: true,

            //challengeWinner: false,

        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    componentDidMount() {

        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.props.challengeData.challengeId,
                    "matchId": that.props.challengeData.challengeId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.score !== null) {
                    that.setState({ d107BaseBallScoreCard: result.data.score.d107BaseBallScoreCard, userData1: result.data.users, teamData: result.data.teams, byBaseballScoreCard: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard, toBaseballScoreCard: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard })

                    if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard) {
                        that.setState({ bySetWon: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns ? result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns : 0 })
                    }
                    if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard) {
                        that.setState({ toSetWon: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns ? result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns : 0 })
                    }
                }
                else {
                    that.setState({ toSetWon: 0, bySetWon: 0 })

                }
            });

        that.setState({ challengeData: that.props.challengeData, acceptedChallenge: that.props.challengeData.accepted })
        that.loadMore();
    }
    openChallengeProfile = (cid) => {
        window.location.href = "/challengeprofile/index.html?id=" + cid;
    }
    loadMore = () => {

        let that = this;
        var apiUrl = userApi + '/challenges/get-challenge';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    challengeId: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)
            if (result.error == false) {
                that.setState({ challengeData: result.data.challenge, teamData: result.data.teams, userData: result.data.users });
                if (result.data.challenge.createdBy === that.state.user.contactNo) {
                    that.setState({ showApply: false });
                }
                if (result.data.challenge.hasOwnProperty('oppositions') === true) {
                    if (result.data.challenge.oppositions !== null) {
                        that.setState({ oppositions: result.data.challenge.oppositions });
                        result.data.challenge.oppositions.map(op => {
                            if (op === that.state.user.contactNo) {
                                that.setState({ showApply: false });
                            }
                        })
                    }
                }
                if (result.data.challenge.hasOwnProperty('accepted') === true) {
                    if (result.data.challenge.accepted !== null) {
                        that.setState({ acceptedChallenge: result.data.challenge.accepted });
                    }
                }
            }

        });


        var apiUrl = userApi + 'users/buddy-profile';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.user.contactNo,
                    userId: this.state.user.contactNo,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {

            that.setState({ profileTeam: result.data.teams });
        });
    }
    selectTeamForAccept = (tid) => {

        let that = this;
        const reports = userApi + "/challenges/react-on-open-challenge";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.state.challengeIdForTeam,
                    "id": tid,
                    "action": "applied",
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data === 'success') {
                    that.setState({ showApply: false })
                    $("#openChallengeTea .close").click();
                    that.loadMore();
                }
            });
    }
    applyTeamChallenge = (challenge) => {

        $('#openChallengeTeam12').click();

        this.setState({ challengeIdForTeam: challenge.challengeId });
        const reports = userApi + "/teams/get-teams-for-challenge";
        let that = this;
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "sportsId": challenge.sportsId,
                    "gender": challenge.gender,
                    "contactNo": that.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.length > 0) {

                    console.log(result.data)
                    that.setState({ noTeam: false, teamOpen: result.data });

                }
                else {
                    that.setState({ noTeam: true });

                }

            });

    }
    applyChallenge = (cId) => {
        let that = this;
        const reports = userApi + "/challenges/react-on-open-challenge";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": cId,
                    "id": that.state.user.contactNo,
                    "action": "applied"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data === 'success') {
                    that.loadMore();
                    that.setState({ showApply: false })
                }
            });

    }
    render() {
        var user, user1, pic, pic1, winners = false, winners1 = false;


        if (this.props.challengeData.challengeType === 'team') {
            this.props.teamData.map(team => {
                if (this.props.challengeData.accepted) {
                    if (team.teamId === this.props.challengeData.accepted[0]) {
                        user = team.teamName;
                        pic = team.teamIcon ? team.teamIcon : teamIcon;

                    }
                    if (team.teamId === this.props.challengeData.accepted[1]) {
                        user1 = team.teamName;
                        pic1 = team.teamIcon ? team.teamIcon : teamIcon;

                    }
                }
            })
        }
        else {
            this.props.userData.map(use => {
                if (this.props.challengeData.accepted) {
                    if (use.contactNo === this.props.challengeData.accepted[0]) {
                        user = use.name;

                        pic = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                    if (use.contactNo === this.props.challengeData.accepted[1]) {
                        user1 = use.name;
                        pic1 = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                }

            })
        }






        var OpenChallengeAccess = false;
        if ((this.state.challengeData.challengeAction === 'requested') && (this.state.challengeData.openChallenge === true)) {
            OpenChallengeAccess = true;
        }

        var acceptedUser = false;
        var oppositions = this.state.challengeData.oppositions ? this.state.challengeData.oppositions : [];
        oppositions.map(c => {
            if (this.state.userData1.contactNo === c) {
                acceptedUser = true;
            }
        })

        if (this.props.challengeData.challengeAction === 'requested') {

            if (this.props.challengeData.challengeType === 'buddy') {
                return (<React.Fragment>
                    <div class="openchallenge-d">
                        <h5>Open Challenge</h5>
                        <img class="open_challengeimg" src={openchallenge} />
                        <p> {userName} has created an open challenge</p>
                        <span>Sports-{this.props.challengeData.sportsName}</span>

                        {this.state.showApply ? <button onClick={() => this.applyChallenge(this.props.challengeData.challengeId)} id="openchallenge-btn" type="btn" class="openchallenge-btn"> Accept Now</button> : acceptedUser ? <button id="openchallenge-btn" type="btn" class="openchallenge-btn"> Applied </button> : ''}
                        <label class="matchdate"><span>Match Date : </span>{this.props.challengeData.challengeDate} <span>Time : {this.props.challengeData.challengeTime}</span></label>
                        <p class="teamlocation"><img src={location} alt="" />{this.props.challengeData.location}</p>
                    </div>
                </React.Fragment>)
            }
            else {

                return (<React.Fragment>
                    <div id="openChallengeTea" className="modal fade reslutmodel" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    <h4 className="modal-title">Select Team</h4>
                                </div>

                                <div className="modal-body">

                                    {this.state.noTeam ? <center>
                                        <h5>You cannot accept this challenge, seems you don't have Team</h5></center> :
                                        <ul className="playerlist">

                                            {this.state.teamOpen.map(appliedTeam => {

                                                return <li key={appliedTeam.teamId} onClick={() => this.selectTeamForAccept(appliedTeam.teamId)}>
                                                    <span className="assignimg" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                    <label className="ac_name">{appliedTeam.teamName}</label>
                                                </li>
                                            })}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <input style={{ display: 'none' }} id="openChallengeTeam12" data-toggle="modal" data-target="#openChallengeTea" />
                    <div class="openchallenge-d">
                        <h5>Open Challenge</h5>
                        <img class="open_challengeimg" src={openchallenge} />
                        <p> {userName} has created an open challenge</p>
                        <span>Sports-{this.props.challengeData.sportsName}</span>

                        {this.state.showApply ? <button onClick={() => this.applyTeamChallenge(this.props.challengeData)} id="openchallenge-btn" type="btn" class="openchallenge-btn"> Accept Now</button> : acceptedUser ? <button id="openchallenge-btn" type="btn" class="openchallenge-btn"> Applied </button> : ''}
                        <label class="matchdate"><span>Match Date : </span>{this.props.challengeData.challengeDate} <span>Time : {this.props.challengeData.challengeTime}</span></label>
                        <p class="teamlocation"><img src={location} alt="" />{this.props.challengeData.location}</p>
                    </div>
                </React.Fragment>)
            }

        }







        return (
            <React.Fragment>

                <div class="teampost_inner" style={{
                    backgroundImage: "url(" + challengebg + ")"
                }}>
                    <label class="teamhead">Challenge - {this.props.challengeData.sportsName}</label>
                    <ul class="postteam">
                        {this.state.teamData.map(u => {
                            if (this.state.byBaseballScoreCard) {
                                if (this.state.byBaseballScoreCard.teamId === u.teamId) {
                                    user = u.teamName;
                                    if (this.state.byBaseballScoreCard.winner === true) {
                                        winners = true;
                                    }
                                    if (u.teamIcon !== null) {
                                        pic = u.teamIcon;
                                    }
                                    else {
                                        if (teamIcon === null) {
                                            pic = teamIcon;
                                        }
                                        else {
                                            pic = teamIcon;
                                        }
                                    }
                                }
                            }

                            if (this.state.toBaseballScoreCard) {
                                if (this.state.toBaseballScoreCard.teamId === u.teamId) {
                                    user1 = u.teamName;
                                    if (this.state.toBaseballScoreCard.winner === true) {
                                        winners1 = true;
                                    }
                                    if (u.teamIcon !== null) {
                                        pic1 = u.teamIcon;
                                    }
                                    else {
                                        if (teamIcon === null) {
                                            pic1 = teamIcon;
                                        }
                                        else {
                                            pic1 = teamIcon;
                                        }
                                    }
                                }
                            }

                        })}
                        <li>
                            {winners ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic + ")"
                            }}></span>
                            <label class="teamname">{user}</label>

                            {winners ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>
                        <li>
                            <img src={challengevs} class="vsimg" alt="" />
                            <span class="matchscore_text">Match Score</span><label class="matchscore">{this.state.bySetWon}-{this.state.toSetWon}</label>
                        </li>
                        <li>
                            {winners1 ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic1 + ")"
                            }}></span>
                            <label class="teamname">{user1}</label>

                            {winners1 ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>

                    </ul>

                    <label class="matchdate"><span>Match Date : </span>{this.state.challengeData.challengeDate} <span>Time : {this.state.challengeData.challengeTime}</span></label>
                    <p class="teamlocation"><img src={location} alt="" />{this.state.challengeData.location}</p>

                    <div class="actiondiv">
                        <button onClick={() => this.openChallengeProfile(this.state.challengeData.challengeId)}>View Score</button>
                    </div>
                </div>

            </React.Fragment>




        );
    }
}

