import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import ConfigUrl from './ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
// import camera from './img/camera.png';

class ImgDropAndCrop extends PureComponent {
    state = {
        src: null,
        crop: {
            // unit: 'px', // default, can be 'px' or '%'
            // x: 130,
            // y: 130,
            // width: 200,
            // height: 200
            unit: '%',
            width: 50,
            height: 50,
            aspect: 1,
            x: 25,
            y: 25
        },
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });

    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            // this.setState({ imageUrl: this.state.imageUrl });
        }
        var lang = this.state.imageUrl;
        this.props.onSelectLanguage(lang);
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL('image/jpeg', 1.0));
            this.setState({ imageUrl: canvas.toDataURL('image/jpeg', 1.0) })
        });
    }



    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div className="ImgUp">
                <div>
                    {/* <button><img src={camera} alt="" /></button> */}

                    <input type="file" accept="image/*" onChange={this.onSelectFile} />

                </div>
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}

                    />
                )}

                {croppedImageUrl && (

                    <div>
                        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />

                    </div>
                )}
            </div>
        );
    }
}

export default ImgDropAndCrop;
