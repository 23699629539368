import React from 'react';
import Header from './Header';
import DownloadAppPlatform from './DownloadAppPlatform';
import SectionRight from './SectionRight';
import Menu from './Menu';
import { Link } from "react-router-dom";
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SectionLeft from './SectionLeft';
import SectionLeftGuest from './SectionLeftGuest';
class ScoreCard extends React.Component {
  constructor() {
    super();
    this.loadData = this.loadData.bind(this);
    this.state.user = JSON.parse(localStorage.getItem("result"));
  }
  state = {
    inningimg1: true, inningimg2: true, inningimg3: true, inningimg4: true,
    teama: '', teamb: '', teamaScore: '', teambScore: '', teamaUrl: '', teambUrl: '',
    loading: true,
    person1: [],
    inningData: '',
    extras: '',
    didNotBat: [],
    bowlers: [],
    fallOfWickets: [],
    loading1: true,
    person2: [],
    inningData1: '',
    extras1: '',
    didNotBat1: [],
    bowlers1: [],
    fallOfWickets1: [],
    loading2: true,
    person3: [],
    inningData2: '',
    extras2: '',
    didNotBat2: [],
    bowlers2: [],
    fallOfWickets2: [],

    loading3: true,
    person4: [],
    inningData3: '',
    extras3: '',
    didNotBat3: [],
    bowlers3: [],
    fallOfWickets3: [],
  }

  async loadData() {
    const apiurl = "https://rest.entitysport.com/v2/matches/" + this.props.match.params.scoreId + "/scorecard?token=7172e8628aaf3155484181f32a328f58";
    const response = await fetch(apiurl);
    const data = await response.json();
    console.log(data);
    const sizeD = data.response.innings.length;
    const result = data.response.result_type;
    if (result === 2) {
      this.setState({ result: data.response.result, loading: false });
    }
    this.setState({ matchVs: data.response.short_title, loading: false });
    this.setState({ matchTitle: data.response.competition.title, loading: false });
    this.setState({ matchDateTime: data.response.date_start, loading: false });
    this.setState({ teama: data.response.teama.name, loading: false });
    this.setState({ teamaUrl: data.response.teama.logo_url, loading: false });
    this.setState({ teamb: data.response.teamb.name, loading: false });
    this.setState({ teambUrl: data.response.teamb.logo_url, loading: false });
    this.setState({ teamaScore: data.response.teama.scores_full, loading: false });
    this.setState({ teambScore: data.response.teamb.scores_full, loading: false });

    if (sizeD >= 1) {
      if (data.response.teama.team_id === data.response.innings[0].batting_team_id) {
        this.setState({ inningimg1: false });
      }
      this.setState({ person1: data.response.innings[0].batsmen, loading: false });
      this.setState({ inningData: data.response.innings[0] });
      this.setState({ extras: data.response.innings[0].extra_runs });
      this.setState({ didNotBat: data.response.innings[0].did_not_bat });
      this.setState({ bowlers: data.response.innings[0].bowlers });
      this.setState({ fallOfWickets: data.response.innings[0].fows });
    }
    if (sizeD >= 2) {
      if (data.response.teama.team_id === data.response.innings[1].batting_team_id) {
        this.setState({ inningimg2: false });
      }
      this.setState({ person2: data.response.innings[1].batsmen, loading1: false });
      this.setState({ inningData1: data.response.innings[1] });
      this.setState({ extras1: data.response.innings[1].extra_runs });
      this.setState({ didNotBat1: data.response.innings[1].did_not_bat });
      this.setState({ bowlers1: data.response.innings[1].bowlers });
      this.setState({ fallOfWickets1: data.response.innings[1].fows });
    }
    if (sizeD >= 3) {
      if (data.response.teama.team_id === data.response.innings[2].batting_team_id) {
        this.setState({ inningimg3: false });
      }
      this.setState({ person3: data.response.innings[2].batsmen, loading2: false });
      this.setState({ inningData2: data.response.innings[2] });
      this.setState({ extras2: data.response.innings[2].extra_runs });
      this.setState({ didNotBat2: data.response.innings[2].did_not_bat });
      this.setState({ bowlers2: data.response.innings[2].bowlers });
      this.setState({ fallOfWickets2: data.response.innings[2].fows });
    }
    if (sizeD >= 4) {
      if (data.response.teama.team_id === data.response.innings[3].batting_team_id) {
        this.setState({ inningimg4: false });
      }
      this.setState({ person4: data.response.innings[3].batsmen, loading3: false });
      this.setState({ inningData3: data.response.innings[3] });
      this.setState({ extras3: data.response.innings[3].extra_runs });
      this.setState({ didNotBat3: data.response.innings[3].did_not_bat });
      this.setState({ bowlers3: data.response.innings[3].bowlers });
      this.setState({ fallOfWickets3: data.response.innings[3].fows });
    }
  }
  componentDidMount() {
    this.loadData()

  }
  loginRedirect = () => {
    confirmAlert({
      customUI: () => {
        return (
          <DownloadAppPlatform />
        );
      }
    });
  };
  render() {
    return <div>  <Header />
      <div className="container containerbottom">
        {this.state.user ? <SectionLeft /> : <SectionLeftGuest />}
        {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
        <section className="d_leftsec">
          <div className="commanbox ">
            <h3 className="commanhead">Action</h3>
            <Menu />
          </div>
        </section>
        <section className="d_leftsec">
          <div className="commanbox ">
            <h3 className="commanhead hidden-xs">Activities</h3>
            <ul className="activityul hidden-xs">
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={teamFillIcon} />Team</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeFillIcon} />Challange</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
            </ul>
            <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</Link></li>
              <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</Link></li>
            </ul>
          </div>
        </section>
      </div> */}
        {this.state.loading || !this.state.person1 ? <div>  </div> : <div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
            <section className="d_midsec">
              <div className="commanbox commanboxlivescore ">
                <h3 className="commanhead commanheadlive">{this.state.matchVs}, {this.state.matchTitle}</h3>
                <div className="ls_datetime"><b>Match Date & Time</b> : {this.state.matchDateTime}</div>
                <ul className="team_match">
                  <li>
                    <div className="scoreflag_div">
                      <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teamaUrl})` }} > </span>
                      <span className="score_flagtext">{this.state.teama}</span>
                    </div>
                    <label className="teamscore">{this.state.teamaScore}</label>
                  </li>
                  <li>
                    <div className="scoreflag_div">
                      <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teambUrl})` }} ></span>
                      <span className="score_flagtext">{this.state.teamb}</span>
                    </div>
                    <label className="teamscore">{this.state.teambScore}</label>
                  </li>
                </ul>
                <label className="teamwin">{this.state.result}</label>
                <div className="panel-group" id="accordion">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                          <ul className="team_match team_match_acc">
                            <li>
                              <div className="scoreflag_div">{this.state.inningimg1 ? <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teambUrl})` }}  ></span> : <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teamaUrl})` }}  ></span>}

                                <span className="score_flagtext">{this.state.inningData.name}</span>
                              </div>
                              <label className="teamscore">{this.state.inningData.scores_full}</label>
                            </li>
                          </ul>
                        </a>
                      </h4>
                    </div>
                    <div id="collapse1" className="panel-collapse collapse in">
                      <div className="panelnew">
                        <div className="table-responsive scoretable">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Batsman</th>

                                <th>R</th>
                                <th>B</th>
                                <th>4s</th>
                                <th>6s</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.person1.map(function (x) {
                                  return <tr key={x.batsman_id}><td>{x.name}<br></br><span>{x.how_out}</span></td><td>{x.runs}</td><td>{x.balls_faced}</td><td>{x.fours}</td><td>{x.sixes}</td>
                                  </tr>;
                                })
                              }

                            </tbody>

                          </table>
                          <div className="matchtotle">
                            <ul>
                              <li><b>Extra</b> <span style={{ float: 'right' }}>B {this.state.extras.byes},LB {this.state.extras.legbyes},W {this.state.extras.wides},NB {this.state.extras.noballs} ({this.state.extras.total})</span></li>
                              <li><b>Total</b> <label>{this.state.inningData.scores_full}</label></li>
                              <li><b>Did not Bat</b><br></br> <span> {
                                this.state.didNotBat.map(function (x) {
                                  return <span>{x.name}</span>
                                }
                                )
                              }  </span></li>

                            </ul>
                          </div>
                        </div>
                        <div className="table-responsive scoretable">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Bowler</th>
                                <th>O</th>
                                <th>M</th>
                                <th>R</th>
                                <th>W</th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                this.state.bowlers.map(function (x) {
                                  return <tr key={x.bowler_id}><td>{x.name}</td><td>{x.overs}</td><td>{x.maidens}</td><td>{x.runs_conceded}</td><td>{x.wickets}</td>
                                  </tr>;
                                })
                              }

                            </tbody>
                          </table>
                        </div>

                        <div className="table-responsive scoretable">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Fall Of Wickets</th>
                                <th>Score</th>
                                <th>Over </th>
                              </tr>
                            </thead>
                            <tbody>

                              {
                                this.state.fallOfWickets.map(function (x) {
                                  return <tr key={x.batsman_id}><td>{x.name}</td><td>{x.score_at_dismissal}</td><td>{x.overs_at_dismissal}</td>
                                  </tr>;
                                })
                              }

                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                  {this.state.loading1 || !this.state.person2 ? <div>  </div> : <div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                            <ul className="team_match team_match_acc">
                              <li>
                                <div className="scoreflag_div">
                                  {this.state.inningimg2 ? <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teambUrl})` }}  ></span> : <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teamaUrl})` }}  ></span>}
                                  <span className="score_flagtext">{this.state.inningData1.name}</span>
                                </div>
                                <label className="teamscore">{this.state.inningData1.scores_full}</label>
                              </li>
                            </ul>


                          </a>
                        </h4>
                      </div>
                      <div id="collapse2" className="panel-collapse collapse">
                        <div className="panelnew">
                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Batsman</th>

                                  <th>R</th>
                                  <th>B</th>
                                  <th>4s</th>
                                  <th>6s</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.person2.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}<br></br><span>{x.how_out}</span></td><td>{x.runs}</td><td>{x.balls_faced}</td><td>{x.fours}</td><td>{x.sixes}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                            <div className="matchtotle">
                              <ul>
                                <li><b>Extra</b> <span style={{ float: 'right', }}>B {this.state.extras.byes},LB {this.state.extras1.legbyes},W {this.state.extras1.wides},NB {this.state.extras1.noballs} ({this.state.extras1.total})</span></li>
                                <li><b>Total</b> <label>{this.state.inningData1.scores_full}</label></li>
                                <li><b>Did not Bat</b><br></br> <span> {
                                  this.state.didNotBat1.map(function (x) {
                                    return <span>{x.name}</span>
                                  }
                                  )
                                }  </span></li>

                              </ul>
                            </div>

                          </div>


                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Bowler</th>
                                  <th>O</th>
                                  <th>M</th>
                                  <th>R</th>
                                  <th>W</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  this.state.bowlers1.map(function (x) {
                                    return <tr key={x.bowler_id}><td>{x.name}</td><td>{x.overs}</td><td>{x.maidens}</td><td>{x.runs_conceded}</td><td>{x.wickets}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Fall Of Wickets</th>
                                  <th>Score</th>
                                  <th>Over </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.fallOfWickets1.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}</td><td>{x.score_at_dismissal}</td><td>{x.overs_at_dismissal}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>}

                  {this.state.loading2 || !this.state.person3 ? <div>  </div> : <div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                            <ul className="team_match team_match_acc">
                              <li>
                                <div className="scoreflag_div">
                                  <span className="score_flagimg" >
                                    {this.state.inningimg3 ? <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teambUrl})` }}  ></span> : <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teamaUrl})` }}  ></span>}
                                  </span>
                                  <span className="score_flagtext">{this.state.inningData2.name}</span>
                                </div>
                                <label className="teamscore">{this.state.inningData2.scores_full}</label>
                              </li>
                            </ul>


                          </a>
                        </h4>
                      </div>
                      <div id="collapse3" className="panel-collapse collapse">
                        <div className="panelnew">
                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Batsman</th>

                                  <th>R</th>
                                  <th>B</th>
                                  <th>4s</th>
                                  <th>6s</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.person3.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}<br></br><span>{x.how_out}</span></td><td>{x.runs}</td><td>{x.balls_faced}</td><td>{x.fours}</td><td>{x.sixes}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                            <div className="matchtotle">
                              <ul>
                                <li><b>Extra</b> <span style={{ float: 'right', }}>B {this.state.extras2.byes},LB {this.state.extras2.legbyes},W {this.state.extras2.wides},NB {this.state.extras2.noballs} ({this.state.extras2.total})</span></li>
                                <li><b>Total</b> <label>{this.state.inningData2.scores_full}</label></li>
                                <li><b>Did not Bat</b><br></br> <span> {
                                  this.state.didNotBat1.map(function (x) {
                                    return <span>{x.name}</span>
                                  }
                                  )
                                }  </span></li>

                              </ul>
                            </div>

                          </div>


                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Bowler</th>
                                  <th>O</th>
                                  <th>M</th>
                                  <th>R</th>
                                  <th>W</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  this.state.bowlers2.map(function (x) {
                                    return <tr key={x.bowler_id}><td>{x.name}</td><td>{x.overs}</td><td>{x.maidens}</td><td>{x.runs_conceded}</td><td>{x.wickets}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Fall Of Wickets</th>
                                  <th>Score</th>
                                  <th>Over </th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  this.state.fallOfWickets2.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}</td><td>{x.score_at_dismissal}</td><td>{x.overs_at_dismissal}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>}

                  {this.state.loading3 || !this.state.person4 ? <div>  </div> : <div>
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                            <ul className="team_match team_match_acc">
                              <li>
                                <div className="scoreflag_div">
                                  <span className="score_flagimg" >
                                    {this.state.inningimg4 ? <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teambUrl})` }}  ></span> : <span className="score_flagimg" style={{ backgroundImage: `url(${this.state.teamaUrl})` }}  ></span>}
                                  </span>
                                  <span className="score_flagtext">{this.state.inningData3.name}</span>
                                </div>
                                <label className="teamscore">{this.state.inningData3.scores_full}</label>
                              </li>
                            </ul>


                          </a>
                        </h4>
                      </div>
                      <div id="collapse4" className="panel-collapse collapse">
                        <div className="panelnew">
                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Batsman</th>

                                  <th>R</th>
                                  <th>B</th>
                                  <th>4s</th>
                                  <th>6s</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.person4.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}<br></br><span>{x.how_out}</span></td><td>{x.runs}</td><td>{x.balls_faced}</td><td>{x.fours}</td><td>{x.sixes}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                            <div className="matchtotle">
                              <ul>
                                <li><b>Extra</b> <span style={{ float: 'right', }}>B {this.state.extras3.byes},LB {this.state.extras3.legbyes},W {this.state.extras3.wides},NB {this.state.extras3.noballs} ({this.state.extras3.total})</span></li>
                                <li><b>Total</b> <label>{this.state.inningData3.scores_full}</label></li>
                                <li><b>Did not Bat</b><br></br> <span> {
                                  this.state.didNotBat1.map(function (x) {
                                    return <span>{x.name}</span>
                                  }
                                  )
                                }  </span></li>

                              </ul>
                            </div>

                          </div>


                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Bowler</th>
                                  <th>O</th>
                                  <th>M</th>
                                  <th>R</th>
                                  <th>W</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  this.state.bowlers3.map(function (x) {
                                    return <tr key={x.bowler_id}><td>{x.name}</td><td>{x.overs}</td><td>{x.maidens}</td><td>{x.runs_conceded}</td><td>{x.wickets}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                          <div className="table-responsive scoretable">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Fall Of Wickets</th>
                                  <th>Score</th>
                                  <th>Over </th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  this.state.fallOfWickets3.map(function (x) {
                                    return <tr key={x.batsman_id}><td>{x.name}</td><td>{x.score_at_dismissal}</td><td>{x.overs_at_dismissal}</td>
                                    </tr>;
                                  })
                                }

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>}

                </div>
              </div>

            </section>
          </div>



        </div>
        }

        <SectionRight />
      </div>
    </div>
  }
}
export default ScoreCard;  