import { trackPromise } from "react-promise-tracker";
import ConfigUrl from '../ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
async function DemoUpdateChallengeScore(score) {
    console.log(score);
    const apiUrl = rshApi + "/demo-challenges/score";
    fetch(apiUrl, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "data": {
                "challengeScore": score,
            }
        })
    })
        .then(function (response) {
            return trackPromise(response.json());
        })
        .then(function (result) {

            console.log(result);
        });
}

export default DemoUpdateChallengeScore; 