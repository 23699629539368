import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d107_Basketball_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchSatus: false,
            rounds: [],
            byPlayerScores: [],
            toPlayerScores: [],
        };

    }
    componentDidMount() {
        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.score !== null) {
                    if (result.data.score.d107BasketballScoreCard.byBasketballScoreCard.playerScores !== undefined) {

                        if (result.data.score.d107BasketballScoreCard.byBasketballScoreCard.playerScores !== null) {
                            that.setState({ byPlayerScores: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.playerScores });
                            that.setState({ bySetWon: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.teamGoals, byWinner: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.winner });

                        }
                        else {

                            that.setState({ bySetWon: 0, byWinner: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }
                    if (result.data.score.d107BasketballScoreCard.toBasketballScoreCard.playerScores !== undefined) {

                        if (result.data.score.d107BasketballScoreCard.toBasketballScoreCard.playerScores !== null) {
                            that.setState({ toPlayerScores: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.playerScores });
                            that.setState({ toSetWon: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.teamGoals, toWinner: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.winner });

                        }
                        else {

                            that.setState({ toSetWon: 0, toWinner: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }


                    that.setState({ rounds: result.data.score.d107BasketballScoreCard.rounds, scorecard: result.data.score.d107BasketballScoreCard })

                    // that.props.profileUsers.map(user => {
                    //     if (user.contactNo === result.data.score.d107BasketballScoreCard.byBasketballScoreCard.id) {
                    //         that.setState({ byBasketballScoreCard: user.name });
                    //     }
                    //     if (user.contactNo === result.data.score.d107BasketballScoreCard.toBasketballScoreCard.id) {
                    //         that.setState({ toBasketballScoreCard: user.name });
                    //     }
                    // })
                    result.data.teams.map(team => {
                        if (team.teamId === result.data.score.d107BasketballScoreCard.byBasketballScoreCard.teamId) {
                            that.setState({ byBasketballScoreCard: team.teamName });
                        }
                        if (team.teamId === result.data.score.d107BasketballScoreCard.toBasketballScoreCard.teamId) {
                            that.setState({ toBasketballScoreCard: team.teamName });
                        }
                    })

                }
            });
        if (that.props.challenge.challengeAction === 'ended') {

            that.setState({ matchSatus: true });
        }
    }

    render() {
        var teamMemberName, teamMemberPic;
        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
        if (this.state.matchSatus) {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>
                                                <th>Score</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byBasketballScoreCard}</td>
                                                <td>{this.state.bySetWon}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toBasketballScoreCard}</td>
                                                <td>{this.state.toSetWon}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div class="tableresponsive">
                                <div class="tableinner" style={{
                                    display: 'flex'
                                }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> {this.state.byBasketballScoreCard} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.byPlayerScores.map(score => {
                                                this.props.profileUsers.map(user => {
                                                    if (user.contactNo === score.userId) {
                                                        teamMemberName = user.name;
                                                        teamMemberPic = user.profilePic;
                                                    }
                                                })
                                                return <tr>
                                                    <td>
                                                        <div>
                                                            {teamMemberName}
                                                        </div>
                                                        <p>
                                                            <span> Points:</span>    {score.goalPoint}
                                                        </p>

                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> {this.state.toBasketballScoreCard}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.toPlayerScores.map(score => {
                                                this.props.profileUsers.map(user => {
                                                    if (user.contactNo === score.userId) {
                                                        teamMemberName = user.name;
                                                        teamMemberPic = user.profilePic;
                                                    }
                                                })
                                                return <tr>
                                                    <td>
                                                        <div>
                                                            {teamMemberName}

                                                        </div>
                                                        <p>
                                                            <span> Points:</span>    {score.goalPoint}
                                                        </p>

                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <h4 class="scorehead">Match Score</h4>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    {/* <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>

                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <th> Set{i} </th>
                                                    }

                                                })}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.byBasketballScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedByPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toBasketballScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedToPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                        </tbody>
                                    </table> */}

                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
    }
}
