import React, { useState } from 'react';
import TimeKeeper from 'react-timekeeper';
//import PropTypes from 'prop-types';

export default function TimeClock(props) {
    var times = new Date().toLocaleString('en-US', { hour12: true })
    times = times.split(',');
    times = times[1].split(' ');
    console.log(times);
    var amPm = times[2];
    times = times[1].split(':');
    times = times[0] + ":" + times[1] + ' ' + amPm;
    const [time, setTime] = useState(times)
    const [showTime, setShowTime] = useState(false)
    const [setcount] = useState()
    return (
        <div>
            {
                showTime &&
                <TimeKeeper
                    time={time}
                    onChange={(newTime) => setTime(newTime.formatted12)}
                    onDoneClick={() => setShowTime(false, props.parentCallback(time))}
                    switchToMinuteOnHourSelect
                />
            }

            {
                !showTime &&
                <input className="question" id="clockTime" value={time} onClick={() => setShowTime(true)} />
            }
        </div>
    )
}

/*
import React, { useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import PropTypes from 'prop-types';

export default function TimeClock(props) {
    var times = new Date().toLocaleString();
    times = times.split(',');
    times = times[1].split(' ');
    var amPm = times[2];
    times = times[1].split(':');
    times = times[0] + ":" + times[1] + ' ' + amPm;
    const [time, setTime] = useState(times)
    const [showTime, setShowTime] = useState(false)
    const [showInput, showInputTime] = useState(false)
    const [setcount] = useState()
    return (
        <div>
            {
                showTime &&
                <TimeKeeper
                    time={time}
                    onChange={(newTime) => setTime(newTime.formatted12)}
                    onDoneClick={() => setShowTime(false, props.parentCallback(time))}
                    switchToMinuteOnHourSelect
                />
            }

            {
                !showTime &&
                    showInput ? <input className="question" id="clockTime" onClick={() => setShowTime(true)} /> : <input className="question" id="clockTime" value={time} onClick={() => setShowTime(true)} />
            }
        </div>
    )
}
*/