import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import finallogo from '../img/finallogo.png';
import { Redirect } from 'react-router-dom';
var numberOfAttempt;
var scoreCardD105s = [];
var d105ScoreCard = {}, score, points;
var maxWeights = [];
const challengeAction = "ended";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
export default class Tournament_d105view extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD105State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
            checked: true,
        };
        this.doneMatch = this.doneMatch.bind(this);
        this.endGame = this.endGame.bind(this);
        this.done = this.done.bind(this);
        this.disqualify = this.disqualify.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.props.challenge.accepted.map(x => {
            scoreCardD105s.push({ 'userId': x, 'index': 0, 'winner': false, 'maxDistanceAchieved': 0.0, 'points': null });
        });
        //   console.log(scoreCardD105s);
        this.state.scoreCardD105State = scoreCardD105s;
        //  console.log(this.state.scoreCardD105State);
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, setModalIsOpen: false, fillScoreModalOpen: false, winnerModalOpen: false });
    }
    doneMatch() {
        var winner;
        if (this.state.selectWinner === undefined) {
            winner = selectedWinner;
        }
        else {
            winner = this.state.selectWinner;
        }
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winner;
        let that = this;
        const apiUrl = rshApi + "challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ winnerModalOpen: false });
                window.location = "/challengeprofile/index.html?id=" + that.props.challenge.challengeId;
                // return <Redirect to='/challengeprofile/index.html?id=5e4f88784706de33c05de6ca' />
            });
    }
    endGame() {
        var showModalForDataFill = false;
        this.state.scoreCardD105State.map((x, index) => {
            if (x.points !== null) {
                if (x.points.length !== numberOfAttempt) {
                    showModalForDataFill = true;
                }
            }
            else {
                showModalForDataFill = true;
            }
        });
        if (showModalForDataFill === true) {
            this.setState({ fillScoreModalOpen: true });
        }
        else {
            maxWeights = [];
            console.log(this.state.scoreCardD105State);
            const max = this.state.scoreCardD105State.reduce((prev, current) => (prev.maxDistanceAchieved > current.maxDistanceAchieved) ? prev : current)
            this.state.scoreCardD105State.map((x, index) => {
                if (x.maxDistanceAchieved === max.maxDistanceAchieved) {
                    maxWeights.push(x);
                }
            });
            console.log(maxWeights);
            this.setState({ winnerModalOpen: true });
        }
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    changeData(event) {
        let checkedArray = this.state.optionsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            numberOfAttempt = selectedValue;
        }
    }
    disqualify(event) {
        this.setState({ modalIsOpen: false, setModalIsOpen: false });

        var weight = 0;
        this.state.scoreCardD105State.map((x, index) => {
            if (index === this.state.row) {
                if (x.points !== null) {
                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: true };
                    }
                    else {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: true };
                    }
                }
                else {
                    x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: true };
                }
                this.numberOfAttempts();
            }
        });
    }
    done(event) {

        this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var kilo = document.getElementsByName("meter")[0].value;
        var gram = document.getElementsByName("cm")[0].value;
        var weight = kilo + '.' + gram;

        weight = parseFloat(weight);

        this.state.scoreCardD105State.map((x, index) => {

            if (index === this.state.row) {

                var max;
                if (x.points !== null) {
                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: false };
                    }
                    else {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: false };
                    }
                }
                else {
                    x.points = [{ attempt: this.state.setIndex + 1, distanceAchieved: weight, disqualified: false }];
                    x.maxDistanceAchieved = weight;
                }

                //this.numberOfAttempts();
                max = x.points.reduce((prev, current) => (prev.distanceAchieved > current.distanceAchieved) ? prev : current);
                if (max.distanceAchieved < weight) {
                    x.maxDistanceAchieved = weight;
                } else {
                    x.maxDistanceAchieved = max.distanceAchieved;
                }


                this.numberOfAttempts();
            }
        });

    }
    openFunc = (index, ind) => {
        this.setState({ row: index, setIndex: ind });
        this.state.scoreCardD105State.map((x, index) => {
            if (x.points === null) {
                if (ind === 0) {

                    this.setState({ setModalIsOpen: true });
                }
            }
            if (x.points !== null) {
                if ((x.points.length + 1) > ind) {

                    this.setState({ setModalIsOpen: true });
                }
            }
        });
    }
    componentDidMount() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.challenge.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        that.setState({ modalIsOpen: false });
                    }
                    else {
                        that.setState({ scoreCardD105State: result.data.score.d105ScoreCard.scoreCardD105s });
                        numberOfAttempt = result.data.score.d105ScoreCard.attempts;
                        that.numberOfAttempts();
                    }
                });

        }
    }
    numberOfAttempts = () => {

        //  console.log(this.state.scoreCardD105State);
        numberOfAttempt = parseInt(numberOfAttempt);
        const that = this;
        d105ScoreCard = {
            attempts: numberOfAttempt,
            scoreCardD105s: this.state.scoreCardD105State,
        }
        score = {
            challengeId: that.props.challenge.challengeId,
            matchId: that.props.challenge.challengeId,
            d105ScoreCard: d105ScoreCard,
        }
        console.log(score)
        UpdateChallengeScore(score);
        this.setState({ modalIsOpen: false });
    }
    render() {
        var sets = [];
        for (var p = 0; p < parseInt(numberOfAttempt); p++) {
            sets.push(p);
        }
        var setNumber = 1;

        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
        return (
            <React.Fragment>
                <section className="d_midsec createmidsec broadcast_box" style={{ zIndex: 'auto' }}>
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            {/* <span>09:33</span> */}
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <h4 class="scorehead">Match Score</h4>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr id="heading">
                                            <th> Players/Attempts </th>

                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.scoreCardD105State.map((x, index) => {
                                            var userName;
                                            this.props.profileUsers.map(user => {
                                                if (x.userId === user.contactNo) {
                                                    userName = user.name
                                                }
                                            })
                                            if (x.points === null) {
                                                return <tr>
                                                    <td>{userName}</td>
                                                    {sets.map((k, inde) => {
                                                        return <td key={k}><input placeholder="M.cm" /></td>
                                                    })}
                                                </tr>;
                                            }
                                            else {
                                                var pointL = x.points.length;
                                                var point;
                                                return <tr>
                                                    <td>{userName}</td>
                                                    {sets.map((k, ind) => {
                                                        var pointColor;
                                                        if (x.points[ind] === undefined) {
                                                            point = '';
                                                        }
                                                        else {
                                                            if (x.points[ind].disqualified === false) {
                                                                if (Number.isInteger(x.points[ind].distanceAchieved) === true) {
                                                                    point = x.points[ind].distanceAchieved;
                                                                    point = point + '.00' + ' M.cm';
                                                                } else {
                                                                    point = x.points[ind].distanceAchieved + ' M.cm';
                                                                }
                                                            }
                                                            else {
                                                                pointColor = { color: 'red' };
                                                                point = 'Disqualify';
                                                            }
                                                        }
                                                        return <td key={k}><input style={pointColor} placeholder="M.cm" value={point} /></td>
                                                    })}
                                                </tr>;
                                            }
                                        })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.winnerModalOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            {/* <div ref={subtitle => this.subtitle = subtitle}>
                            <h3>   winner </h3>
                            {maxWeights.map(weight => {
                                return <h3> {weight.userId} is  winner </h3>
                            })}
                        </div> */}
                            <div ref={subtitle => this.subtitle = subtitle}></div>
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">Match Result</h4>
                                </div>
                                <div class="modal-body">
                                    <div class="resultlogo">
                                        <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                        <label>Winner</label>
                                    </div>

                                    <ul class="resultul">

                                        {maxWeights.map((weight, index) => {
                                            var userName;
                                            selectedWinner = weight.userId;
                                            this.props.profileUsers.map(user => {

                                                if (weight.userId === user.contactNo) {
                                                    userName = user.name
                                                }
                                            })
                                            return <li style={{ 'display': 'flex' }}>
                                                <a class="winnerselect">
                                                    <div class="resinfo">
                                                        <label>{userName} </label>
                                                        <small>{weight.maxDistanceAchieved} M</small>
                                                    </div>

                                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                        <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                        <label htmlFor={index}></label>
                                                    </div>
                                                </a>
                                            </li>
                                        })}
                                    </ul>
                                </div>

                                <div class="modal-footer table_actions">
                                    <a class="cmnbutton" onClick={this.closeModal}>Back</a>
                                    <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                                </div>

                            </div>
                        </Modal>
                        <Modal
                            isOpen={this.state.fillScoreModalOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div ref={subtitle => this.subtitle = subtitle}> <h3> Please Fill All Score Field</h3></div>



                        </Modal>
                        <Modal isOpen={this.state.setModalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal">  <div ref={subtitle => this.subtitle = subtitle}>
                                <div ref={subtitle => this.subtitle = subtitle}> <h3>Enter Distance (M/cm)</h3></div>
                                <NumericInput mobile min={0}

                                    max={500}
                                    name="meter"
                                    disabled={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoFocus={false}
                                    form="some-form"
                                    placeholder="Enter Distance in Meter"
                                    required
                                    size={40}
                                    value={50}
                                    spellcheck="false"
                                    tabindex="2" style={{
                                        input: {
                                            padding: '10px',
                                        }
                                    }}
                                /> <br></br>
                                <NumericInput mobile min={0}
                                    max={99}
                                    name="cm"
                                    disabled={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    size={40}
                                    value={0}
                                    autoFocus={false}
                                    form="some-form"
                                    placeholder="Enter Distance in CM"
                                    required

                                    spellcheck="false"
                                    tabindex="2" style={{
                                        input: {
                                            padding: '10px',
                                        }
                                    }} />
                            </div>
                            <br></br>
                            <div className="button_div" >
                                <button className="btn btn-danger" type="button" onClick={this.disqualify} style={{ 'margin': '2px' }}>Disqualify</button>
                                <button className="btn btn-inverse" onClick={this.closeModal} style={{ 'margin': '2px' }}>Cancel</button>
                                <button className="cmnbutton" type="button" onClick={this.done} style={{ 'margin': '2px' }}>Done</button>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div ref={subtitle => this.subtitle = subtitle}> <h3> Number of Attempts</h3></div>


                            <ul className="loginul_form editprofile ">
                                <li>
                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                        <input type="radio" name="numberOfAttempt" id="radio-example-1" onClick={this.changeData.bind(this)} value="3" />
                                        <label htmlFor="radio-example-1"></label>
                                    </div>

                                    <label className="ss_sportstile">3 Attempt</label>
                                </li>
                                <li>
                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                        <input type="radio" name="numberOfAttempt" id="radio-example-2" onClick={this.changeData.bind(this)} value="4" />
                                        <label htmlFor="radio-example-2"></label>
                                    </div>

                                    <label className="ss_sportstile">4 Attempt</label>
                                </li>
                                <li>
                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                        <input type="radio" name="numberOfAttempt" id="radio-example-3" onClick={this.changeData.bind(this)} value="5" />
                                        <label htmlFor="radio-example-3"></label>
                                    </div>

                                    <label className="ss_sportstile">5 Attempt</label>
                                </li>
                                <li>
                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                        <input type="radio" name="numberOfAttempt" id="radio-example-4" onClick={this.changeData.bind(this)} value="6" />
                                        <label htmlFor="radio-example-4"></label>
                                    </div>

                                    <label className="ss_sportstile">6 Attempt</label>
                                </li>
                                <li>
                                    <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                        <input type="radio" name="numberOfAttempt" id="radio-example-5" onClick={this.changeData.bind(this)} value="7" />
                                        <label htmlFor="radio-example-5"></label>
                                    </div>

                                    <label className="ss_sportstile">7 Attempt</label>
                                </li>
                            </ul>
                            <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.numberOfAttempts} >Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>

                        </Modal>

                        <div className="table_actions">
                            <a href={backLinks} className="cmnbutton">Back</a>
                            {/* <button className="cmnbutton" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.winnerDone()}>End Games</button> */}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
