import React, { Component } from 'react';
import Newfeeds from './Newfeeds';
import LoginPage from './LoginPage';
import PrivacyPolicy from './PrivacyPolicy';
import LiveScore from './LiveScore';
import LiveFootballScore from './LiveFootballScore';
import UpcomingCric from './UpcomingCric';
import CompletedCric from './CompletedCric';
import LiveFoot from './LiveFoot';
import CompletedFoot from './CompletedFoot';
import UpcomingFoot from './UpcomingFoot';
import ScoreCardFoot from './ScoreCardFoot';
import SharePost from './SharePost';
import PlaydiatorAdmin from './PlaydiatorAdmin';
import ScoreCard from './ScoreCard';
import MyAccount from './MyAccount';
import ForgotPassword from './forgetPassword';
import CheckProfileComplete from './CheckProfileComplete';
import BuddiesProfile from './BuddiesProfile';
import Teams from './Teams';
import TeamCreation from './TeamCreation';
import TeamProfilePage from './TeamProfilePage';
import DemoTeamProfilePage from './DemoTeamProfilePage';
import DownloadAppPage from './DownloadAppPage';
import DTournamentList from './DemoTournament/DTournamentList';
// import forgetPassword from './forgetPassword';
import Registration from './Registartion';
import Aboutus from './landing/Aboutus';
import AllNotificationPage from './AllNotificationPage';
import Contactus from './landing/Contactus';
import Privacypolicy from './landing/Privacypolicy';
import Faq from './landing/Faq';
import LandingPage from './landing/LandingPage';
import TournamentList from './TournamentList';
import ScheduleMatches from './ScheduleMatches';
import { Redirect } from "react-router-dom";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ChallengeProfile from './ChallengeProfile';
import DChallengeProfile from './DemoChallenge/DChallengeProfile'
import ChallengeList from './ChallengeList'
import DChallengeList from './DemoChallenge/DChallengeList'
import TournamentProfile from './TournamentProfile';
import DTournamentProfile from './DemoTournament/DTournamentProfile';
import TeamsDemo from './TeamsDemo';
import CreateDemoTeam from './CreateDemoTeam'

import PageList from './PageConcept/PageList';
import Followers from './PageConcept/Followers';
import CreatePage from './PageConcept/CreatePage';
import PageProfile from './PageConcept/PageProfile';
import ChatResp from './ChatResp';
import CreatePageTeam from './PageConcept/Teams/CreatePageTeam';
import CreatePageChallenge from './PageConcept/Challenge/ChallengeList';
import ChallengeListForPage from './PageConcept/Challenge/ChallengeList';
import CreatePageTournament from './PageConcept/Tournament/CreatePageTournament';
import TournamentListForPage from './PageConcept/Tournament/TournamentList';
import TeamList from './PageConcept/Teams/TeamList';

class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        if (localStorage.getItem('logedIn')) {
            if (localStorage.getItem('logedIn') === 'yes') {

                this.state = {
                    user: JSON.parse(localStorage.getItem('result')),
                    logedIn: true
                };
            }
        }
        else {
            this.state = {
                logedIn: false,
            }
        }
    }

    render() {
        if (this.state.logedIn) {
            var urlParams = new URLSearchParams(window.location.search);
            if (window.location.pathname === '/buddies') {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route path="/:contactNumber" component={BuddiesProfile} />
                        </Switch>
                    </BrowserRouter>
                );
            }
            if (window.location.pathname === '/teamprofilepage') {
                return (
                    <BrowserRouter>
                        <Switch>
                            <Route path="/:id" exact component={TeamProfilePage} />
                        </Switch>
                    </BrowserRouter>
                );
            }
            else {
                return (
                    <BrowserRouter>

                        <Switch>
                            <Route path="/" exact component={Newfeeds} />
                            <Route path="/completeProfile" component={CheckProfileComplete} />
                            {/* <Route path="/forgotPassword" component={forgetPassword} /> */}
                            <Route path="/chat" component={ChatResp} />
                            {/* <Route path="/privacy-policy" component={PrivacyPolicy} /> */}
                            <Route path="/live-score" component={LiveScore} />
                            <Route path="/live-football-score" component={LiveFootballScore} />
                            <Route path="/completed-cricket" component={CompletedCric} />
                            <Route path="/upcoming-cricket" component={UpcomingCric} />
                            <Route path="/live-football" component={LiveFoot} />
                            <Route path="/completed-football" component={CompletedFoot} />
                            <Route path="/upcoming-football" component={UpcomingFoot} />
                            <Route path="/scorecard-football/:scoreId" component={ScoreCardFoot} />
                            <Route path="/shared/post/?id=postId" component={SharePost} />
                            <Route path="/v2/admin" component={PlaydiatorAdmin} />
                            <Route path="/scorecard/:scoreId" component={ScoreCard} />
                            {/* <Route path="/newsFeedsLink" component={Newfeeds} /> */}

                            <Route path="/my-account" component={MyAccount} />
                            <Route path="/my-account/following" component={MyAccount} />
                            <Route path="/my-account/followers" component={MyAccount} />
                            <Route path="/my-account/buddies" component={MyAccount} />

                            <Route path="/about-us" component={Aboutus} />
                            <Route path="/notifications" component={AllNotificationPage} />
                            <Route path="/:download-app-page" exact component={DownloadAppPage} />

                            <Route path="/teamslist" component={Teams} />
                            <Route path="/teamcreation" component={TeamCreation} />
                            <Route path="/teamprofilepage/:teamid" component={TeamProfilePage} />


                            <Route path="/demo-teamlist/" component={TeamsDemo} />
                            <Route path="/createdemoteam/" component={CreateDemoTeam} />
                            <Route path="/demoTeamProfilePage/:teamid" component={DemoTeamProfilePage} />


                            <Route path="/challengelist" component={ChallengeList} />
                            <Route path="/demo-challenge-list" component={DChallengeList} />

                            {/* <Route path="/create-challenge" component={ChallengeList} /> 
                            <Route path="/demo-create-challenge" component={ChallengeList} /> */}

                            <Route path="/challengeprofile/:challengedid" component={ChallengeProfile} />
                            <Route path="/dchallengeprofile/:challengedid" component={DChallengeProfile} />



                            <Route path="/tournamentlist" component={TournamentList} />
                            <Route path="/demo-tournament-list" component={DTournamentList} />

                            {/* <Route path="/create-tournament" component={ChallengeList} />
                            <Route path="/demo-create-tournament" component={ChallengeList} /> */}

                            <Route path="/tournamentprofile/:tournamentid" component={TournamentProfile} />
                            <Route path="/dtournamentprofile/:tournamentid" component={DTournamentProfile} />


                            <Route path="/page" exact component={PageList} />
                            <Route path="/page/create" component={CreatePage} />
                            <Route path="/page/followers/:pageId" component={Followers} />
                            <Route path="/page/team/create/:pageId" component={CreatePageTeam} />
                            <Route path="/page/team/profile/:pageId?" component={TeamProfilePage} />
                            <Route path="/page/teamslist/:pageId" component={TeamList} />

                            <Route path="/page/challenge/create/:pageId" component={CreatePageChallenge} />
                            <Route path="/page/challenge/list/:pageId" component={ChallengeListForPage} />



                            <Route path="/page/tournament/create/:pageId" component={CreatePageTournament} />

                            <Route path="/page/tournament/list/:pageId" component={TournamentListForPage} />
                            {/* <Route path="/page/tournamentprofile/:tournamentid" component={TournamentProfile} /> */}
                            <Route path="/page/profile/:pageId" component={PageProfile} />


                            <Route path="/schedule-matches" component={ScheduleMatches} />

                        </Switch>
                    </BrowserRouter>
                );
            }

        }
        else {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={LandingPage} />
                        <Route path="/guest" component={Newfeeds} />
                        <Route path="/completeProfile" component={CheckProfileComplete} />
                        <Route path="/about-us" component={Aboutus} />
                        <Route path="/contact-us" component={Contactus} />
                        <Route path="/privacy-policy" component={Privacypolicy} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/Registration" component={Registration} />
                        <Route path="/forgotPassword" component={ForgotPassword} />
                        <Route
                            render={otherProps => (
                                <Redirect
                                    to={{
                                        pathname: "/",

                                    }}
                                />
                            )} />
                        {/* <Route path="/privacy-policy" component={Privacy} />
                        <Route path="/faq" component={Faq} />
                        <Route
                            render={otherProps => (
                                <Redirect
                                    to={{
                                        pathname: "/",

                                    }}
                                />
                            )}
                        /> */}
                    </Switch>

                </BrowserRouter>
            );
        }
    }
}



export default TimeLine;
