import React from 'react';
import Header from './Header';
import DownloadAppPlatform from './DownloadAppPlatform';
import SectionRight from './SectionRight';
import FootballMenu from './FootballMenu';
import { Link } from "react-router-dom";
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import challengefill from './img/challengefill.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Moment from 'react-moment';
import 'moment-timezone';
class LiveFoot extends React.Component {     
 
  constructor() {      
    super();   
    this.loadData = this.loadData.bind(this);       
  } 

  share(val) {             
  this.props.history.push("/ScoreCardFoot/"+val);  
 // this.setState({matchId:val,showScore:false});   
  }
     
 state={
   matchId:'',
   showScore:true,
   loading:true,
   person:[],  
   paged:1,
   page:5,
 }     
 async loadData() { 
  this.setState({page : (this.state.page + 5)});    
//   const apiurl= "https://rest.entitysport.com/v2/matches/?status=3&token=7172e8628aaf3155484181f32a328f58&per_page="+this.state.page+"&&paged="+this.state.paged+"";
  const apiurl= "https://rest.entitysport.com/soccer/matches?token=18c151e1b17cd8b2908614405b946f14&status=3&per_page="+this.state.page+"&paged="+this.state.paged+"";        
  const response = await fetch(apiurl);   
  const data = await response.json();   
  console.log(data);
  this.setState({person:data.response.items,loading:false});   
}
 componentDidMount(){   
  this.loadData();     
}
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform/>
                );
            }
        });
    };
render(){   
  return<div>      <Header />
      <div className="container containerbottom">
          <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
              <section className="d_leftsec">
                  <div className="commanbox ">
                      <h3 className="commanhead">Action</h3>
                      <FootballMenu />
                  </div>
              </section>
              <section className="d_leftsec">
                  <div className="commanbox ">
                      <h3 className="commanhead hidden-xs">Activities</h3>
                      <ul className="activityul hidden-xs">
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={teamFillIcon} />Team</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeFillIcon} />Challange</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                      </ul>
                      <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</Link></li>
                      </ul>
                  </div>
              </section>
          </div>
 {this.state.loading || !this.state.person  ? <div>  </div>:<div>    
   <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7" >  
    <section className="d_midsec">
      <div className="commanbox commanboxlivescore ">
        <h3 className="commanhead commanheadlive">Live Football Match</h3>
        <ul className="scoreboxul" >
        {       
         this.state.person.map((x)=>{   
           	
        
         return  <li className="teamdi" key={x.mid}>
            <h3>{x.competition.cname} </h3>
            <p className="datentime_para">  <Moment unix tz="America/Los_Angeles">
                {x.timestampstart}
            </Moment></p>   
            <div className="teamdiv"   id={x.mid}  onClick={() => this.share(x.mid)} > 
              <ul  className="live_teamul" >
                  <li>
                  <span className="lt_img" style={{ backgroundImage: `url(${x.teams.away.logo})` }}></span>    
                  <label className="it_flgname">{x.teams.away.tname}</label>
                  <label className="it_flgscore"></label>
                </li>
                <li><img className="vsicon" src={challengefill} alt=""></img></li>  
                <li>
                  <span className="lt_img"  style={{ backgroundImage: `url(${x.teams.home.logo})`}}></span>
                  <label className="it_flgname">{x.teams.home.tname}</label>     
                  <label className="it_flgscore active"></label>   
                </li>       
              </ul>
              <p className="datentime_para">{x.venue.name},{x.venue.location}</p>     
              <p className="scoreupdate_para"> </p>
            </div>
          </li>  
        })
        }   
       </ul>   
       <button type="button" onClick={() => this.loadData()} className="btn btn-success btn-block">Load More ....</button>
      </div>

    </section>
  </div>   
  </div>
}

          <SectionRight/>
      </div>
</div> 
}   
}    
export default LiveFoot;