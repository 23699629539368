import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import { trackPromise } from "react-promise-tracker";
import * as myFeedUtilclass from '../DemoTournament/DTournamentsUtil';
import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Modal1 from 'react-modal';
import logo from '../img/finallogo.png';
import * as myUtilclass from '../DemoTournament/DFixtureUtil';
import * as myTournamentUtilclass from '../DemoTournament/DTournamentsUtil';
var score;
let pairObj;
var d107CarromScoreCard = {};
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winner = 29;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournament_d107_tt1Carrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107CarromScoreCard: {},
            boards: [],
            finals: {},
            quarterFinals: null,
            semiFinals: null,
            ttType: null,
            tt2Matches: [],
            tt3Matches: [],
            teams: [],
            users: [],
            formatType: -1,
            bytotalPoints: 0,
            tototalPoints: 0,
            pickPoint: false,
            topickPoint: false,
            bypickPoint: false,
            tournament: null,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        // this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {
        let d107CarromScoreCard = this.state.d107CarromScoreCard;
        if (this.state.byWinner) {
            d107CarromScoreCard.byCarromScoreCard.winner = true;
        }
        if (this.state.toWinner) {
            d107CarromScoreCard.toCarromScoreCard.winner = true;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107CarromScoreCard,
        }
        DUpdateTournamentsScore(score);
        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }
        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }

    }

    getScore() {

        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

                if (result.data.score === null) {
                    that.IntialState();
                    let byName; let toName;
                    d107CarromScoreCard = {
                        "byCarromScoreCard": {
                            "totalPoints": 0,
                            "id": that.state.matchPairObj.firstId,
                            "type": "buddy",
                            "winner": false
                        },
                        "toCarromScoreCard": {
                            "totalPoints": 0,
                            "id": that.state.matchPairObj.secondId,
                            "type": "buddy",
                            "winner": false
                        },
                        "boardNo": 1,
                        "boards": [
                            {
                                "position": 0,
                                "challengedById": null,
                                "challengedByPoints": 0,
                                "challengedToId": null,
                                "challengedToPoints": 0,
                                "tableHeader": true,
                                "saved": false
                            },
                            {
                                "position": 1,
                                "challengedById": that.state.matchPairObj.firstId,
                                "challengedByPoints": 14,
                                "challengedToId": that.state.matchPairObj.secondId,
                                "challengedToPoints": 0,
                                "tableHeader": false,
                                "saved": true
                            }
                        ]
                    }

                    var score = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107CarromScoreCard: d107CarromScoreCard,
                    }
                    DUpdateTournamentsScore(score);

                    that.setState({ d107CarromScoreCard: d107CarromScoreCard });
                    if (result.data.score !== null) {
                        that.setState({ d107CarromScoreCard: result.data.score.d107CarromScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, boards: result.data.score.d107CarromScoreCard.boards });
                    }
                    that.props.profileTeams.map(user => {
                        if (that.state.matchPairObj.firstId === user.teamId) {
                            byName = user.teamName
                        }
                        if (that.state.matchPairObj.secondId === user.teamId) {
                            toName = user.teamNameCarrom
                        }
                    })
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    if (result.data.score.d107CarromScoreCard) {
                        that.setState({ d107CarromScoreCard: result.data.score.d107CarromScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, boards: result.data.score.d107CarromScoreCard.boards, boardno: result.data.score.d107CarromScoreCard.boardNo });
                    }
                }
                else {
                    if (result.data.score.d107CarromScoreCard.byCarromScoreCard.totalPoints) {
                        that.setState({ bytotalPoints: result.data.score.d107CarromScoreCard.byCarromScoreCard.totalPoints })
                    }
                    if (result.data.score.d107CarromScoreCard.toCarromScoreCard.totalPoints) {
                        that.setState({ tototalPoints: result.data.score.d107CarromScoreCard.toCarromScoreCard.totalPoints })
                    }
                    that.setState({
                        score: true, d107CarromScoreCard: result.data.score.d107CarromScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams,

                    });

                    let byName; let toName;

                    if (result.data.mode === 'buddy') {
                        result.data.users.map(user => {
                            if (result.data.score.d107CarromScoreCard.byCarromScoreCard.id === user.contactNo) {
                                byName = user.name
                            }
                            if (result.data.score.d107CarromScoreCard.toCarromScoreCard.id === user.contactNo) {
                                toName = user.name
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                    }
                    else {
                        that.props.profileTeams.map(user => {
                            if (that.state.matchPairObj.firstId === user.teamId) {
                                byName = user.teamName
                            }
                            if (that.state.matchPairObj.secondId === user.teamId) {
                                toName = user.teamName
                            }
                        })

                    }
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    that.setState({ boardno: result.data.score.d107CarromScoreCard.boardNo })
                    that.setState({ d107CarromScoreCard: result.data.score.d107CarromScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, boards: result.data.score.d107CarromScoreCard.boards });
                }
            });
    }
    IntialState = () => {
        console.log(this.props.tt1)
        pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);


        this.setState({ matchPairObj: pairObj });
        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }

        this.setState({ byName: byName, toName: toName, score: false });
    }
    addboard = () => {
        var round = [];
        var boardLen = this.state.d107CarromScoreCard.boards.length;
        var boardData = this.state.d107CarromScoreCard.boards;
        var roundData = {
            position: boardLen,
            tableHeader: false,
            challengedById: this.state.d107CarromScoreCard.byCarromScoreCard.id,
            challengedByPoints: 0,
            challengedToId: this.state.d107CarromScoreCard.toCarromScoreCard.id,
            challengedToPoints: 0,
        }

        round.push(roundData);



        var board = boardData.concat(round);
        const that = this;
        var d107ScoreCard = {
            byCarromScoreCard: {
                id: this.state.d107CarromScoreCard.byCarromScoreCard.id,
                winner: false,
                totalPoints: 0,
                type: this.state.mode,


            },
            toCarromScoreCard: {
                id: this.state.d107CarromScoreCard.toCarromScoreCard.id,
                winner: false,
                totalPoints: 0,
                type: this.state.mode,
            },
            boards: board,
            boardNo: boardLen,

        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107ScoreCard,
        }
        console.log(score)
        DUpdateTournamentsScore(score);
        this.setState({ d107CarromScoreCard: d107ScoreCard, boards: board })
    }
    bypickPoint = (event) => {
        this.setState({
            clickedIndex: event.target.id
        });
        if (event.target.value > 0) {

        }
        else {
            let boardLenIndex = this.state.d107CarromScoreCard.boards.length;
            boardLenIndex = parseInt(boardLenIndex) - 1;
            if (this.state.boards[boardLenIndex].challengedToPoints == 0) {
                this.setState({ pickPoint: true, bypickPoint: true, topickPoint: false })
            }

        }
    }
    topickPoint = (event) => {
        this.setState({
            clickedIndex: event.target.id
        });

        if (event.target.value > 0) {

        }
        else {
            let boardLenIndex = this.state.d107CarromScoreCard.boards.length;
            boardLenIndex = parseInt(boardLenIndex) - 1;
            if (this.state.boards[boardLenIndex].challengedByPoints == 0) {
                this.setState({ pickPoint: true, topickPoint: true, bypickPoint: false })
            }
        }

    }

    endEvent() {

        let byTotal = this.state.d107CarromScoreCard.byCarromScoreCard.totalPoints;

        let toTotal = this.state.d107CarromScoreCard.toCarromScoreCard.totalPoints;

        if (byTotal >= winner) {

            this.setState({ byWinner: true, winner: this.state.d107CarromScoreCard.byCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
        }
        else if (toTotal >= winner) {
            this.setState({ toWinner: true, winner: this.state.d107CarromScoreCard.toCarromScoreCard.id })
            this.setState({ winnerModalOpen: true })
        }
        else {
            this.setState({ winnerModalOpen: false })

        }


    }
    carromValue = (value) => {

        let d107 = this.state.d107CarromScoreCard;
        var byCarromScoreCard = this.state.bytotalPoints;
        var toCarromScoreCard = this.state.tototalPoints;

        this.setState({ pickPoint: false })
        let newIndex = parseInt(this.state.clickedIndex);
        newIndex = newIndex + 1;
        var boards = this.state.boards;
        boards.map(board => {

            // if (board.challengedToPoints === 0 && board.challengedByPoints === 0) {
            //     this.setState({ addboardDisable: true })

            // }
            if (board.position == newIndex) {
                if (this.state.topickPoint) {
                    board.challengedToPoints = parseInt(value);
                }
                else {
                    board.challengedByPoints = parseInt(value);
                }
            }
        })

        if (this.state.topickPoint) {
            d107.byCarromScoreCard.totalPoints = byCarromScoreCard;
            d107.toCarromScoreCard.totalPoints = toCarromScoreCard + parseInt(value);
        }
        else {
            d107.toCarromScoreCard.totalPoints = toCarromScoreCard;
            d107.byCarromScoreCard.totalPoints = byCarromScoreCard + parseInt(value);
        }
        console.log(d107)
        console.log(boards)
        this.setState({ boards: boards, d107CarromScoreCard: d107, bytotalPoints: d107.byCarromScoreCard.totalPoints, tototalPoints: d107.toCarromScoreCard.totalPoints })

        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107,
        }
        console.log(score)
        DUpdateTournamentsScore(score);

    }

    ToundoList = () => {
        var d107 = this.state.d107CarromScoreCard;
        var toUndo;
        this.state.boards.map(board => {
            if (board.position === this.state.d107CarromScoreCard.boardNo)
                toUndo = board;
        })
        var toUndoList = this.state.boards.filter((x) => x != toUndo)
        this.setState({ boards: toUndoList })
        d107.boards = toUndoList;

        var byCarromScoreCard = this.state.d107CarromScoreCard.byCarromScoreCard;
        var toCarromScoreCard = this.state.d107CarromScoreCard.toCarromScoreCard;
        byCarromScoreCard.totalPoints = byCarromScoreCard.totalPoints - toUndo.challengedByPoints;
        toCarromScoreCard.totalPoints = toCarromScoreCard.totalPoints - toUndo.challengedToPoints;
        d107.byCarromScoreCard = byCarromScoreCard;
        d107.toCarromScoreCard = toCarromScoreCard;
        console.log(d107)
        d107.boardNo = (d107.boardNo - 1);
        this.setState({ d107CarromScoreCard: d107 })
        this.setState({ bytotalPoints: byCarromScoreCard.totalPoints })
        this.setState({ tototalPoints: toCarromScoreCard.totalPoints })
        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CarromScoreCard: d107,
        }
        DUpdateTournamentsScore(score);

    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }

    pickPointHandel = () => {
        this.setState({ pickPoint: false })
    }
    componentDidMount() {
        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName, toName;
        if (this.props.tournament.tournamentMode === 'teams') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }

    render() {
        return (
            <React.Fragment>

                <Modal1
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td>{this.state.byName}</td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.toName}</td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal1>
                <Modal

                    size="md"
                    show={this.state.pickPoint}
                    onHide={() => this.pickPointHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    {/* <div data-backdrop="static" id="pickPoint" className="modal fade reslutmodel" role="dialog"> */}
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Score</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <ul class="carromscore-d">
                                        <a>
                                            <li onClick={() => this.carromValue("1")} class="carromtapnum-d">1</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("2")} class="carromtapnum-d">2</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("3")} class="carromtapnum-d">3</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("4")} class="carromtapnum-d">4</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("5")} class="carromtapnum-d">5</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("6")} class="carromtapnum-d">6</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("7")} class="carromtapnum-d">7</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("8")} class="carromtapnum-d">8</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("9")} class="carromtapnum-d">9</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("10")} class="carromtapnum-d">10</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("11")} class="carromtapnum-d">11</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("12")} class="carromtapnum-d">12</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("13")} class="carromtapnum-d">13</li>
                                        </a>
                                        <a>
                                            <li onClick={() => this.carromValue("14")} class="carromtapnum-d">14</li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* </div> */}
                </Modal>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " style={{ backgboardImage: 'url(' + noBroadcast + ')' }}>
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>score</th>
                                            <th>status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.bytotalPoints}</td>
                                            <td><button onClick={() => this.addboard()} type="button" class="addboard-d">Add board</button></td>
                                            {/* disabled={!this.state.addboardDisable} */}
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.tototalPoints}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {this.state.boards.map((k, index) => {
                                                if (index > 0)
                                                    return <th>board{index} </th>
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {this.state.boards.map((k, bindex) => {
                                                let points;
                                                let boardValue = false;
                                                this.state.boards.map(board => {
                                                    if (bindex + 1 === board.position) {
                                                        points = board.challengedByPoints
                                                        boardValue = true

                                                    }
                                                    return true;
                                                })
                                                if (boardValue)
                                                    return <td key={bindex}><input id={bindex} value={points} class="inputsize-d" onClick={(event) => this.bypickPoint(event)} /></td>
                                            })}

                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {this.state.boards.map((k, index) => {
                                                let points;
                                                let boardValue = false;
                                                this.state.boards.map(board => {
                                                    if (index + 1 === board.position) {
                                                        points = board.challengedToPoints
                                                        boardValue = true
                                                    }
                                                    return true;
                                                })
                                                if (boardValue)
                                                    return <td key={index}><input id={index} value={points} class="inputsize-d" onClick={(event) => this.topickPoint(event)} /></td>

                                            })}

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="table_actions winner_actions">
                        <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>undo</button>
                        <a class="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.endEvent()}>End Event</a>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}



