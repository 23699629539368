import React, { Component } from 'react';
import Header from './Header';
import DownloadAppPlatform from './DownloadAppPlatform';
import CricketFootballAlert from './CricketFootballAlert';
import SectionRight from './SectionRight';
import SectionLeft from './SectionLeft';
import $ from "jquery";
import SectionLeftGuest from './SectionLeftGuest';
import userMaleIcon from './img/male.png';
import userFemaleIcon from './img/female.png';
import userImgIcon from './img/userimg.jpg';
import likeDarkIcon from './img/likedark.png';
import commentIcon from './img/comment.png';
import tvIcon from './img/tv.png';
import Following from "./Following";
import Follower from "./Follower";
import Buddies from "./Buddies";
// import explaner2 from "./img/playdiator-explaner2.jpg";
import explaner2 from "./img/playdiator-explaner-complete.jpg";
import ViewAbout from "./ViewAbout";
import Teams from './Teams';
import Map from './Map';
import ChatResp from './ChatResp';

/*import shareIcon from './img/share22x22.png';*/
import { Date } from 'prismic-reactjs';
import { animateScroll as scroll } from 'react-scroll';
import { trackPromise } from 'react-promise-tracker';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Iframe from 'react-iframe';
import { Link, Redirect } from "react-router-dom";
import CreatePost from './CreatePost';
import { isAndroid, isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player';
/*import { FacebookShareCount, PinterestShareCount, VKShareCount, OKShareCount, RedditShareCount, TumblrShareCount, FacebookShareButton, LinkedinShareButton, TwitterShareButton, PinterestShareButton, VKShareButton, OKShareButton, TelegramShareButton, WhatsappShareButton, RedditShareButton, EmailShareButton, TumblrShareButton, LivejournalShareButton, MailruShareButton, ViberShareButton, WorkplaceShareButton, LineShareButton, WeiboShareButton, PocketShareButton, InstapaperShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, PinterestIcon, VKIcon, OKIcon, TelegramIcon, WhatsappIcon, RedditIcon, TumblrIcon, MailruIcon, EmailIcon, LivejournalIcon, ViberIcon, WorkplaceIcon, LineIcon, PocketIcon, InstapaperIcon, } from 'react-share';
import exampleImage from './img/finallogo.png';*/

import ConfigUrl from './ConfigUrl';
import Loginfeeds from './LoginFeeds';
import ScheduleMatches from './ScheduleMatches';
import TournamentPage from './TournamentPage';
import ChallengePage from './ChallengePage';
import ProfileComplete from './CheckProfileComplete';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

class Newfeeds extends Component {
    constructor(props) {
        super(props);


        this.state = {
            posts: [],
            visible: 10,
            sr: 0,
            error: false,
            user: '',
            showFollowing: false,
            showFeeds: true,
            showFollower: false,
            showBuddies: false,
            viewProfile: false,
            loged: false,
            scheduleShow: false,
            showCreatePost: true,
            nonSessionUserName: '',
            showProfileComplete: false,
            genderSection: false,
            passwordSection: false,
            genderSection: false,
            hideLoadMore: false,

        };

        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    imageUpdateC = (user) => {
        // console.log(user)
        // this.setState({ userObj: user })
    }
    loadMore = () => {

        this.fetchFirst("sp", 0, 10 + this.state.posts.length);
        scroll.scrollMore(500);
    };
    showComponent(type) {

        if (JSON.parse(localStorage.getItem('nonSessionUserData'))) {
            var nonSessionUserData = JSON.parse(localStorage.getItem('nonSessionUserData'));
            if (nonSessionUserData.gender === "NA" || nonSessionUserData.address === "NA") {
                //  this.setState({ genderSection: true })
                window.location = '/completeProfile';
            }
            else if (nonSessionUserData.password === "NA" || nonSessionUserData.mobileVerified === false) {
                //  this.setState({ passwordSection: true, genderSection: false })
                window.location = '/completeProfile';

            }
            else if (nonSessionUserData.name === "NA" || nonSessionUserData.email === "NA") {
                // this.setState({ emailSection: true, passwordSection: false, genderSection: false })
                window.location = '/completeProfile';

            }
            else {
                // Redirect To Login Page
            }
            console.log(nonSessionUserData);
        }
        else {
            if (type === 'Followings') {
                this.setState({ showBuddies: false, showFollowing: true, showFollower: false, showFeeds: false, viewProfile: false, loged: false, tournamentPage: false, challengePage: false, teamPage: false });
            }
            if (type === 'Followers') {
                this.setState({ showBuddies: false, showFollower: true, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, tournamentPage: false, challengePage: false, teamPage: false });
            }
            if (type === 'Buddies') {
                this.setState({ showBuddies: true, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, tournamentPage: false, challengePage: false, teamPage: false });
            }
            if (type === 'viewProfile') {
                this.setState({ showBuddies: false, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: true, loged: false, tournamentPage: false, challengePage: false, teamPage: false });
            }
            if (type === 'scheduleMatches') {
                this.setState({ scheduleShow: true, showBuddies: false, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, showCreatePost: false, tournamentPage: false, challengePage: false, teamPage: false })
            }
            if (type === 'tournamentPage') {
                this.setState({ scheduleShow: false, showBuddies: false, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, showCreatePost: false, tournamentPage: true, challengePage: false, teamPage: false })
            }
            if (type === 'challengePage') {
                this.setState({ scheduleShow: false, showBuddies: false, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, showCreatePost: false, tournamentPage: false, challengePage: true, teamPage: false })
            }
            if (type === 'teamPage') {
                this.setState({ scheduleShow: false, showBuddies: false, showFollower: false, showFollowing: false, showFeeds: false, viewProfile: false, loged: false, showCreatePost: false, tournamentPage: false, challengePage: false, teamPage: true })
            }
        }



    }
    fetchFirst(url, index, items) {
        const that = this;

        var logedIn = localStorage.getItem('logedIn');
        if (logedIn === null) {
            var apiUrl = userApi + 'global/exposed-' + url;
            if (url) {
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: {
                            index: index,
                            itemsPerIndex: items
                        }
                    })
                }).then(function (response) {
                    return trackPromise(response.json());
                }).then(function (result) {
                    if (result.data) {
                        if (result.data.posts) {
                            if (result.data.posts.length % 10 !== 0) {
                                that.setState({ hideLoadMore: true });
                            }
                        }
                    }
                    that.setState({ posts: result.data.posts, users: result.data.users, counts: result.data.count });
                });
            }
        }
        else {
            this.setState({ loged: true });
            if (this.props.location.state !== undefined) {


                if (this.props.location.state.name === 'tournamentPage') {
                    this.setState({ showCreatePost: false, challengePage: false, loged: false, tournamentPage: true, teamPage: false, ChatResp: false });
                }
                if (this.props.location.state.name === 'challengePage') {
                    this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: true, teamPage: false, ChatResp: false });
                }
                if (this.props.location.state.name === 'home') {
                    this.setState({ showCreatePost: true, tournamentPage: false, loged: true, challengePage: false, teamPage: false, ChatResp: false });
                }
                if (this.props.location.state.name === 'teamPage') {
                    this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: true, ChatResp: false });
                }

                if (this.props.location.state.name === 'ChatResp') {
                    this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: false, ChatResp: true });
                }
            }
            // var apiUrl = userApi + '/newfeeds/get-feeds';     
            // fetch(apiUrl, {
            //     method: "post",
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         data: {
            //             userId: this.state.user.contactNo,
            //             index: index,
            //             itemsPerIndex: items
            //         }
            //     })
            // }).then(function (response) {
            //     return trackPromise(response.json());
            // }).then(function (result) {
            //     console.log(result);   
            // });          
        }
    }
    componentDidMount() {

        this.fetchFirst("sp", 0, 10);
    }
    openApp() {

        if (isAndroid) {
            window.open("https://play.google.com/store/apps/details?id=com.sportsapp.ssp");
        }
        else if (isIOS) {
            window.open("https://apps.apple.com/app/id1437392923");
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.sportsapp.ssp");
        }


    }
    componentWillReceiveProps(props) {

        if (props.location.state !== undefined) {
            if (props.location.state.name === 'tournamentPage') {
                this.setState({ showCreatePost: false, challengePage: false, loged: false, tournamentPage: true, teamPage: false, ChatResp: false, scheduleShow: false, showBuddies: false });
            }
            if (props.location.state.name === 'challengePage') {
                this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: true, teamPage: false, ChatResp: false, scheduleShow: false, showBuddies: false });
            }
            if (props.location.state.name === 'home') {
                this.setState({ showCreatePost: true, tournamentPage: false, loged: true, challengePage: false, teamPage: false, ChatResp: false, scheduleShow: false, showBuddies: false });
            }
            if (props.location.state.name === 'teamPage') {
                this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: true, ChatResp: false, scheduleShow: false, showBuddies: false });
            }
            if (props.location.state.name === 'ChatResp') {
                this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: false, ChatResp: true, scheduleShow: false, showBuddies: false });
            }
            if (props.location.state.name === 'buddies') {
                this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: false, ChatResp: false, showBuddies: true, scheduleShow: false });
            }
            if (props.location.state.name === 'schedule') {
                this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: false, ChatResp: false, scheduleShow: true, showBuddies: false });
            }
            // if (props.location.state.name === 'broadcast') {
            //     this.setState({ showCreatePost: false, tournamentPage: false, loged: false, challengePage: false, teamPage: false, ChatResp: true });
            // }
        }
    }
    fetchUserDetails(cno, type) {
        var userDetails = [];
        this.state.users.forEach(function (result) {
            if (result.contactNo === cno) {
                userDetails.push({ userName: result.name, userPic: result.profilePic, userGender: result.gender });
            }
        });
        if (type === 'username') {
            return userDetails[0].userName;
        } else if (type === 'userpic') {
            if (userDetails[0].userPic) {
                return userDetails[0].userPic;
            } else {
                if (userDetails[0].userGender === "Male") {
                    return userMaleIcon;
                } else {
                    return userFemaleIcon;
                }
            }
        }
    }
    fetchCheerCounts(buddiesList) {
        const buddiesCount = [];
        buddiesList.forEach(function (res) {
            if (res.reactedType === 'cheer') {
                buddiesCount.push({ buddyId: res.buddyId, type: res.reactedType });
            }
        });
        return buddiesCount;
    }
    fetchCommentCounts(buddiesList) {
        var commentCount = [];
        buddiesList.forEach(function (res) {
            if (res.reactedType === 'comment') {
                commentCount.push(res.reactedType);
            }
        });
        return commentCount.length;
    }
    cheer = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };
    comment = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };
    /*share = (postId) => {
        confirmAlert({
            customUI: () => {
                const shareUrl = "https://www.playdiator.com/post/"+postId;
                const title = "Playdiator Post Title";
                return (
                    <div className="custom-ui">
                        <h5>Share Now</h5>
                        <div className="Demo__container">
                            <div className="Demo__some-network">
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={title}
                                    className="Demo__some-network__share-button">
                                    <FacebookIcon
                                        size={32}
                                        round />
                                </FacebookShareButton>
    
                                <FacebookShareCount
                                    url={shareUrl}
                                    className="Demo__some-network__share-count">
                                    {count => count}
                                </FacebookShareCount>
                            </div>
    
                            <div className="Demo__some-network">
                                <TwitterShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <TwitterIcon
                                        size={32}
                                        round />
                                </TwitterShareButton>
    
                                <div className="Demo__some-network__share-count">
                                    &nbsp;
                                </div>
                            </div>
    
                            <div className="Demo__some-network">
                                <TelegramShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <TelegramIcon size={32} round />
                                </TelegramShareButton>
    
                                <div className="Demo__some-network__share-count">
                                    &nbsp;
                                </div>
                            </div>
    
                            <div className="Demo__some-network">
                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={title}
                                    separator=":: "
                                    className="Demo__some-network__share-button">
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
    
                                <div className="Demo__some-network__share-count">
                                    &nbsp;
                                </div>
                            </div>
    
                            <div className="Demo__some-network">
                                <LinkedinShareButton
                                    url={shareUrl}
                                    windowWidth={750}
                                    windowHeight={600}
                                    className="Demo__some-network__share-button">
                                    <LinkedinIcon
                                        size={32}
                                        round />
                                </LinkedinShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <PinterestShareButton
                                    url={String(window.location)}
                                    media={`${String(window.location)}/${exampleImage}`}
                                    windowWidth={1000}
                                    windowHeight={730}
                                    className="Demo__some-network__share-button">
                                    <PinterestIcon size={32} round />
                                </PinterestShareButton>
    
                                <PinterestShareCount url={shareUrl}
                                                     className="Demo__some-network__share-count" />
                            </div>
    
                            <div className="Demo__some-network">
                                <VKShareButton
                                    url={shareUrl}
                                    image={`${String(window.location)}/${exampleImage}`}
                                    windowWidth={660}
                                    windowHeight={460}
                                    className="Demo__some-network__share-button">
                                    <VKIcon
                                        size={32}
                                        round />
                                </VKShareButton>
    
                                <VKShareCount url={shareUrl}
                                              className="Demo__some-network__share-count" />
                            </div>
    
                            <div className="Demo__some-network">
                                <OKShareButton
                                    url={shareUrl}
                                    image={`${String(window.location)}/${exampleImage}`}
                                    className="Demo__some-network__share-button">
                                    <OKIcon
                                        size={32}
                                        round />
                                </OKShareButton>
    
                                <OKShareCount url={shareUrl}
                                              className="Demo__some-network__share-count" />
                            </div>
    
                            <div className="Demo__some-network">
                                <RedditShareButton
                                    url={shareUrl}
                                    title={title}
                                    windowWidth={660}
                                    windowHeight={460}
                                    className="Demo__some-network__share-button">
                                    <RedditIcon
                                        size={32}
                                        round />
                                </RedditShareButton>
    
                                <RedditShareCount url={shareUrl}
                                                  className="Demo__some-network__share-count" />
                            </div>
    
                            <div className="Demo__some-network">
                                <TumblrShareButton
                                    url={shareUrl}
                                    title={title}
                                    windowWidth={660}
                                    windowHeight={460}
                                    className="Demo__some-network__share-button">
                                    <TumblrIcon
                                        size={32}
                                        round />
                                </TumblrShareButton>
    
                                <TumblrShareCount url={shareUrl}
                                                  className="Demo__some-network__share-count" />
                            </div>
    
                            <div className="Demo__some-network">
                                <LivejournalShareButton
                                    url={shareUrl}
                                    title={title}
                                    description={shareUrl}
                                    className="Demo__some-network__share-button"
                                >
                                    <LivejournalIcon size={32} round />
                                </LivejournalShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <MailruShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <MailruIcon
                                        size={32}
                                        round />
                                </MailruShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <EmailShareButton
                                    url={shareUrl}
                                    subject={title}
                                    body="body"
                                    className="Demo__some-network__share-button">
                                    <EmailIcon
                                        size={32}
                                        round />
                                </EmailShareButton>
                            </div>
                            <div className="Demo__some-network">
                                <ViberShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <ViberIcon
                                        size={32}
                                        round />
                                </ViberShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <WorkplaceShareButton
                                    url={shareUrl}
                                    quote={title}
                                    className="Demo__some-network__share-button">
                                    <WorkplaceIcon
                                        size={32}
                                        round />
                                </WorkplaceShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <LineShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <LineIcon
                                        size={32}
                                        round />
                                </LineShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <WeiboShareButton
                                    url={shareUrl}
                                    title={title}
                                    image={`${String(window.location)}/${exampleImage}`}
                                    className="Demo__some-network__share-button">
                                    <img className="Demo__some-network__custom-icon" src="http://icons.iconarchive.com/icons/martz90/circle-addon2/512/weibo-icon.png" alt="Weibo share button" />
                                </WeiboShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <PocketShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <PocketIcon
                                        size={32}
                                        round />
                                </PocketShareButton>
                            </div>
    
                            <div className="Demo__some-network">
                                <InstapaperShareButton
                                    url={shareUrl}
                                    title={title}
                                    className="Demo__some-network__share-button">
                                    <InstapaperIcon
                                        size={32}
                                        round />
                                </InstapaperShareButton>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };*/
    liveScore = () => {

        confirmAlert({
            customUI: () => {
                return (
                    <CricketFootballAlert />
                );
            }
        });
    };

    handleCallbacktoParent = (childData) => {
        if (childData === 'openSidebar') {
            $(".d_midsec").css("position", "fixed");
        }
        if (childData === 'closeSidebar') {
            $(".d_midsec").css("position", "unset");
        }
    }

    render() {
        if (this.state.ChatResp) {
            return <Redirect to="/chat" />
        }
        const userImgStyle1 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '8px'
        };
        const userImgStyle2 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '16px'
        };
        const userImgStyle3 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '24px'
        };
        const userImgStyle4 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '32px'
        };
        const userImgStyle5 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '40px'
        };
        const userImgStyle6 = {
            backgroundImage: 'url(' + userImgIcon + ')',
            left: '48px'
        };
        const left1 = {
            left: '16px'
        };
        const left2 = {
            left: '24px'
        };
        const left3 = {
            left: '32px'
        };
        const left4 = {
            left: '40px'
        };
        const left5 = {
            left: '48px'
        };
        const left6 = {
            left: '56px'
        };
        const userLikeInnerMarginLeft1 = {
            marginLeft: '60px',
            marginTop: '-2px'
        };
        const userLikeInnerMarginLeft2 = {
            marginLeft: '70px',
            marginTop: '-2px'
        };
        const userLikeInnerMarginLeft3 = {
            marginLeft: '80px',
            marginTop: '-2px'
        };
        const userLikeInnerMarginLeft4 = {
            marginLeft: '90px',
            marginTop: '-2px'
        };
        const userLikeInnerMarginLeft5 = {
            marginLeft: '100px',
            marginTop: '-2px'
        };
        const width100 = {
            width: '100%'
        };
        const cursorPointer = {
            cursor: 'pointer'
        };
        const formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit"/*,
            second: "2-digit"*/
        });
        const youtubeEmbed = "https://www.youtube.com/embed/";
        //const youtubeAutoplayYes = "?autoplay=1";
        const youtubeAutoplayYes = "";
        const iframeId = "video";
        if (this.state.posts.length > this.state.visible) {
            this.items = this.state.posts.slice(0, this.state.posts.length).map((item, key) =>
                <div key={item.id} className="divpost">
                    <div className="userdetail">
                        <ul className="postuserul">
                            <li className="p_userli">
                                <span className="p_userimg" style={{
                                    backgroundImage: "url(" + this.fetchUserDetails(item.createdBy, 'userpic') + ")"
                                }}></span>
                                <label>{this.fetchUserDetails(item.createdBy, 'username')}</label>
                                <span className="p_usertime">{formattedTimestamp.format(Date(item.timestamp))}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="postdata">
                        <p>{item.text}</p>
                        {(() => {
                            switch (item.youtubeId) {
                                case null:
                                    switch (item.mediaType) {
                                        case "image":
                                            return <img alt="" src={item.mediaUrl} style={width100} />;
                                        case "video":
                                            return "My Video";
                                        case "text":
                                            return "My Text";
                                        default:
                                            return "";
                                    }
                                default:
                                    return <Iframe url={youtubeEmbed + item.youtubeId + youtubeAutoplayYes}
                                        height="353px" width="100%" id={iframeId + (key + 1)} frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen="yes" />;
                            }
                        })()}
                    </div>
                    <div className="postdata">
                        {(() => {
                            switch (item.reactedBuddies) {
                                case null:
                                    return '';
                                default:
                                    switch (this.fetchCheerCounts(item.reactedBuddies).length) {
                                        case 1:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span className="likecount"
                                                        style={left1}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft1}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b></span>
                                            </div>;
                                        case 2:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span className="likecount"
                                                            style={left2}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft2}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 3:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            className="likecount"
                                                            style={left3}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft3}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 4:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span className="likecount"
                                                                style={left4}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft4}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 5:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                className="likecount"
                                                                style={left5}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft5}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 6:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                style={userImgStyle6}></span><span className="likecount"
                                                                    style={left6}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner"><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        default:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                style={userImgStyle6}></span><span className="likecount"
                                                                    style={left6}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                            </div>;
                                    }
                            }
                        })()}
                    </div>
                    <div className="postlikediv">
                        <ul className="commentul">
                            <li className="commentli">
                                <img alt="" src={likeDarkIcon} className="likeuser" style={cursorPointer}
                                    onClick={this.cheer} />
                                <span className="likespan" style={cursorPointer} onClick={this.cheer}>Cheer</span>
                            </li>
                            <li className="commentinner">
                                <img alt="" src={commentIcon} className="commentimg" style={cursorPointer}
                                    onClick={this.comment} />
                                <span style={cursorPointer} onClick={this.comment}>Comment<span className="spancount">
                                    {(() => {
                                        switch (item.reactedBuddies) {
                                            case null:
                                                return '';
                                            default:
                                                return this.fetchCommentCounts(item.reactedBuddies);
                                        }
                                    })()}
                                </span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else {
            this.items = this.state.posts.slice(0, this.state.visible).map((item, key) =>
                <div key={item.id} className="divpost">
                    <div className="userdetail">
                        <ul className="postuserul">
                            <li className="p_userli">
                                <span className="p_userimg" style={{
                                    backgroundImage: "url(" + this.fetchUserDetails(item.createdBy, 'userpic') + ")"
                                }}></span>
                                <label>{this.fetchUserDetails(item.createdBy, 'username')}</label>
                                <span className="p_usertime">{formattedTimestamp.format(Date(item.timestamp))}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="postdata">
                        <p>{item.text}</p>
                        {(() => {
                            switch (item.youtubeId) {
                                case null:
                                    switch (item.mediaType) {
                                        case "image":
                                            return <img alt="" src={item.mediaUrl} style={width100} />;
                                        case "video":
                                            return "My Video";
                                        case "text":
                                            return "My Text";
                                        default:
                                            return "";
                                    }
                                default:
                                    return <Iframe url={youtubeEmbed + item.youtubeId + youtubeAutoplayYes}
                                        height="353px" width="100%" id={iframeId + (key + 1)} frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen="yes" />;
                            }
                        })()}
                    </div>
                    <div className="postdata">
                        {(() => {
                            switch (item.reactedBuddies) {
                                case null:
                                    return '';
                                default:
                                    switch (this.fetchCheerCounts(item.reactedBuddies).length) {
                                        case 1:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span className="likecount"
                                                        style={left1}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft1}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b></span>
                                            </div>;
                                        case 2:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span className="likecount"
                                                            style={left2}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft2}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 3:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            className="likecount"
                                                            style={left3}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft3}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 4:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span className="likecount"
                                                                style={left4}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft4}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 5:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                className="likecount"
                                                                style={left5}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner" style={userLikeInnerMarginLeft5}><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        case 6:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                style={userImgStyle6}></span><span className="likecount"
                                                                    style={left6}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                                <span className="userlikeinner"><b
                                                    className="text-capitalize">{this.fetchUserDetails(this.fetchCheerCounts(item.reactedBuddies)[0].buddyId, 'username')}</b> and {this.fetchCheerCounts(item.reactedBuddies).length - 1}
                                                    more liked </span></div>;
                                        default:
                                            return <div className="likeinner">
                                                <ul>
                                                    <li><span style={userImgStyle1}></span><span
                                                        style={userImgStyle2}></span><span style={userImgStyle3}></span><span
                                                            style={userImgStyle4}></span><span style={userImgStyle5}></span><span
                                                                style={userImgStyle6}></span><span className="likecount"
                                                                    style={left6}>{this.fetchCheerCounts(item.reactedBuddies).length}</span>
                                                    </li>
                                                </ul>
                                            </div>;
                                    }
                            }
                        })()}
                    </div>
                    <div className="postlikediv">
                        <ul className="commentul">
                            <li className="commentli">
                                <img alt="" src={likeDarkIcon} className="likeuser" style={cursorPointer}
                                    onClick={this.cheer} />
                                <span className="likespan" style={cursorPointer} onClick={this.cheer}>Cheer</span>
                            </li>
                            <li className="commentinner">
                                <img alt="" src={commentIcon} className="commentimg" style={cursorPointer}
                                    onClick={this.comment} />
                                <span style={cursorPointer} onClick={this.comment}>Comment<span className="spancount">
                                    {(() => {
                                        switch (item.reactedBuddies) {
                                            case null:
                                                return '';
                                            default:
                                                return this.fetchCommentCounts(item.reactedBuddies);
                                        }
                                    })()}
                                </span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
        if (this.state.user) {

            this.userCreatePost = <CreatePost />
        } else {
            this.userCreatePost = <div className="commanbox createpostdiv">
                <h3 className="commanhead">&nbsp; <Link to={"#"} className="livescore" data-toggle="tooltip" title="" data-placement="top" data-original-title="View Live Score" onClick={this.liveScore}><img src={tvIcon} alt="" /> Live Score </Link></h3>
            </div>

        }
        return (
            <>
                <Header parentCallbackfromChild={this.handleCallbacktoParent} />

                <div className="container containerbottom non-session-d">
                    {this.state.user ? <SectionLeft parentCallbackfromChild2={this.handleCallbacktoParent} data={{ showComponent: this.showComponent.bind(this) }} imageUpdateP={this.imageUpdateC} /> : <SectionLeftGuest parentCallbackfromChild2={this.handleCallbacktoParent} data={{ showComponent: this.showComponent.bind(this) }} />}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            {this.state.user ? '' :
                                <React.Fragment>
                                    <div className="postdata">
                                        <iframe allowfullscreen="yes" style={{ 'width': '100%', 'height': '360px' }} className="youtube-player" id="player" type="text/html" src="https://www.youtube.com/embed/nqdZs6m1IDo?autoplay=1&mute=1">&lt;br /&gt;</iframe>

                                    </div>
                                    <img className="explanerImg" src={explaner2} />
                                    <div className="center-d">
                                        <div className="downloaddiv-d1">
                                            <button onClick={() => this.openApp()} className="download-d1" type="button">Download App</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {this.state.genderSection ? <ProfileComplete genderSection={this.state.genderSection} /> : ''}
                            {this.state.passwordSection ? <ProfileComplete passwordSection={this.state.passwordSection} /> : ''}
                            {this.state.emailSection ? <ProfileComplete emailSection={this.state.emailSection} /> : ''}
                            {/* {this.state.genderSection ? <Map parentCallback={this.callbackFunction} /> : ''} */}
                            {this.state.showCreatePost ? this.userCreatePost : ''}
                            {this.state.scheduleShow ? <ScheduleMatches /> : ''}
                            {this.state.showFollowing ? <Following userbuddy={this.state.user.contactNo} /> : <div></div>}
                            {this.state.showFollower ? <Follower userbuddy={this.state.user.contactNo} /> : <div></div>}
                            {this.state.showBuddies ? <Buddies userbuddy={this.state.user.contactNo} /> : <div></div>}
                            {this.state.viewProfile ? <ViewAbout userbuddy={this.state.user.contactNo} /> : <div></div>}
                            {this.state.showFeeds ? this.items : ''}
                            {this.state.loged ? <Loginfeeds /> : ''}
                            {/* {this.state.ChatResp ? <ChatResp /> : ''} */}
                            {this.state.tournamentPage ? <TournamentPage /> : ''}
                            {this.state.challengePage ? <ChallengePage /> : ''}
                            {this.state.teamPage ? <Teams userbuddy={this.state.user.contactNo} permission={true} /> : ''}


                            {this.state.hideLoadMore ? < button onClick={this.loadMore} type="button" className="btn btn-success" style={width100}>Load more</button> : null}
                        </section>
                    </div>
                    <SectionRight />

                </div>

            </>
        );
    }
}

export default Newfeeds;
