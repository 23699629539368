import React, { Component } from 'react'
import $ from 'jquery';
import { Link } from "react-router-dom";
// import '../css/animate.min.css';
// import '../css/bootstrap.min.css';
import '../css/font-awesome.min.css';
import '../css/style-landing.css';
import '../css/responsive-landing.css';
import email from '../img/landing/email.svg';
import loginphone from '../img/landing/loginphone.svg';
import contact from '../img/landing/contact.svg';
import message from '../img/landing/message.svg';
import logo from '../img/landing/la-logo.png';
export default class Contactus extends Component {
    componentDidMount() {
        if ($('.main-navigation .navigation-box').length) {
            var subMenu = $('.main-navigation .sub-menu');
            subMenu.parent('li').children('a').append(function () {
                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
            });
            var mainNavToggler = $('.header-navigation .menu-toggler');
            var subNavToggler = $('.main-navigation .sub-nav-toggler');
            mainNavToggler.on('click', function () {
                var Self = $(this);
                var menu = Self.data('target');
                $(menu).slideToggle();
                $(menu).toggleClass('showen');
                return false;
            });
            subNavToggler.on('click', function () {
                var Self = $(this);
                Self.parent().parent().children('.sub-menu').slideToggle();
                return false;
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div class="page-wrapper">
                    <header class="site-header site-header__header-one ">
                        <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                            <div class="container clearfix">

                                <div class="logo-box clearfix">
                                    <button class="menu-toggler" data-target=".main-navigation">
                                        <span class="fa fa-bars"></span>
                                    </button>
                                    <a class="navbar-brand" href="/">
                                        <img src={logo} class="main-logo" alt="Awesome Image" />
                                    </a>
                                </div>
                                <div class="main-navigation">
                                    <ul class=" one-page-scroll-menu navigation-box">
                                        <li className="scrollToLink">
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/about-us"}>About Us</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/privacy-policy"}>Privacy Policy</Link>

                                        </li>

                                        <li className="current scrollToLink">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/faq"}>FAQ </Link>


                                        </li>
                                    </ul>
                                </div>
                                <div class="right-side-box">
                                    <Link class="thm-btn header__cta-btn" to={"/login"}><span>Login</span></Link>
                                </div>
                            </div>

                        </nav>
                    </header>
                    <section class="contactus_sec">
                        <div class="contactbg"></div>

                        <div class="respad15">
                            <div class="container containerbottom containercontact">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                    <h2 class="getintouch">Get In Touch</h2>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 contactleft">
                                        <ul class="loginul_form">

                                            <li>
                                                <div class="commaninputdiv">
                                                    <input type="text" name="name" class="question-d" id="" required autocomplete="off" />
                                                    <label for=""><img class="inputname-d" src={contact} alt="" /> <span>Full Name</span></label>
                                                </div>

                                            </li>


                                            <li>
                                                <div class="commaninputdiv">
                                                    <input type="text" name="name" class="question-d" id="" required autocomplete="off" />
                                                    <label for=""> <img class="inputicon-d" src={loginphone} alt="" /> <span>Contact Number</span></label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="commaninputdiv">
                                                    <input type="text" name="name" class="question-d" id="" required autocomplete="off" />
                                                    <label for="">  <img class="inputemail-d" src={email} alt="" /> <span>Email</span></label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="commaninputdiv">
                                                    <input type="text" name="name" class="question-d" id="" required autocomplete="off" />
                                                    <label for="">  <img class="inputemail-d" src={message} alt="" /> <span>Message</span></label>
                                                </div>
                                            </li>

                                        </ul>
                                        <div class="right-side-box submitcustom-d">
                                            <a class="thm-btn formbtn-d" href=""><span>Submit</span></a>
                                        </div>

                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 contactright">
                                        <ul class="contactinfo">
                                            <li><a><i class="fa fa-envelope"></i>Info@playdiator.com</a></li>
                                            {/* <li><a><i class="fa fa-phone" style={{ fontSize: '23px!important' }}></i>Info@playdiator.com</a></li> */}
                                            {/* <li><a><i class="fa fa-map-marker" style={{ fontSize: '25px!important' }}></i>Danish house 213-A, Zone - 1, Behind Jyoti Cinema, M.P. Nagar Bhopal - India</a>
                                            </li> */}
                                        </ul>
                                        <ul class="c_socialul">
                                            <li><a class="fa fa-facebook"></a></li>
                                            <li><a class="fa fa-linkedin"></a></li>
                                            <li><a class="fa fa-twitter"></a></li>
                                        </ul>

                                        {/* <iframe class="contactifream" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3666.265850067163!2d77.43162781542559!3d23.233411014244286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%3EDasnish%20house%20213-A%2C%20Zone%20-%201%2C%20Behind%20Jyoti%20Cinema%2C%20M.P.%20Nagar%20Bhopal%20-%20India!5e0!3m2!1sen!2sin!4v1576053151153!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: '0' }} allowfullscreen=""></iframe> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>






                </div>


                <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
            </React.Fragment>
        )
    }
}
