import React, { Component } from "react";
// import './DefaultStyle.css';
// import './IndexStyle.css';
import guestLogo from "./img/male.png";
import guestFemaleLogo from "./img/female.png";
import finalLogo from "./img/finallogo.png";
import finalText from "./img/logotext.png";
import menuLeftIcon from "./img/menuleft.png";
import arrowDownIcon from "./img/arrowdown.png";
import newFeedsLine from "./img/newfeedsline.png";
import newFeedFill from "./img/newfeedfill.png";
import teamIcon from "./img/team.png";
import teamBlack from "./img/teamBlack.png";
import teamFillIcon from "./img/teamfill.png";
import challengeIcon from "./img/challenge.png";
import challengeFillIcon from "./img/challengefill.png";
import fixtureIcon from "./img/fixture.png";
import fixtureFillIcon from "./img/fixturefill.png";
import tournamentprofileimg from "./img/tournamentprofileimg.jpg";
import broacast8 from "./img/broacast8.png";
import buddiesIcon from "./img/buddies.png";
import buddiesFillIcon from "./img/buddiesfill.png";
import broadcastIcon from "./img/broadcast.png";
import broadcastFillIcon from "./img/broadcastfill.png";
import liveScoreIcon from "./img/livescore.png";
import pageIconM from "./img/pageIconD.png";
import scheduleMatchesGrayIcon from "./img/schedulematchesgray.png";
import scheduleMatchesIcon from "./img/schedulematches.png";
import chatGrayIcon from "./img/chatgray.png";
import chatFillIcon from "./img/chatfill.png";
import searchIcon from "./img/search.png";
import searchNewIcon from "./img/searchnew.png";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import ConfigUrl from "./ConfigUrl";
import { confirmAlert } from "react-confirm-alert";
import CricketFootballAlert from "./CricketFootballAlert";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

const ConfigApiUrl = ConfigUrl("apiUrl");
const videoUrl = ConfigUrl("videoUrl");
const rshApi = ConfigApiUrl;
/*import { Media } from 'reactstrap';*/

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      allBuddies: [],
      search: "",
      searchLen: "",
      filterSearch: [],
      filterTeam: [],
      filterTournament: [],
      filterBroadcast: [],
      showLogin: false,
      homeSelected: true,
      teamSelected: false,
      challengeSelected: false,
      tournamentSelected: false,
      searchFor: "buddies",
    };
    //this.setState({ user: JSON.parse(localStorage.getItem('result')) });
    this.state.user = JSON.parse(localStorage.getItem("result"));
    this.state.nonSessionUser = JSON.parse(
      localStorage.getItem("nonSessionUserData")
    );
    //console.log(this.state.user);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  logOutNonSession() {
    localStorage.clear();
    window.location = "/guest";
  }
  componentWillUnmount() {
    this.setState({ teamSelected: false, homeSelected: false });
  }
  changeSelectFor(type) {
    this.setState({ searchFor: type, search: "" });
    if (type === "teams") {
      this.setState({ filterTeam: [] });
    }
    if (type === "buddies") {
      this.setState({ filterSearch: [] });
    }
    if (type === "tournaments") {
      this.setState({ filterTournament: [] });
    }
    if (type === "broadcast") {
      this.setState({ filterBroadcast: [] });
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    if (window.location.pathname === "/teamslist") {
      this.setState({
        teamSelected: true,
        homeSelected: false,
        challengeSelected: false,
        tournamentSelected: false,
      });
    } else if (window.location.pathname === "/tournamentlist") {
      this.setState({
        teamSelected: false,
        homeSelected: false,
        challengeSelected: false,
        tournamentSelected: true,
      });
    } else if (window.location.pathname === "/challengelist") {
      this.setState({
        challengeSelected: true,
        tournamentSelected: false,
        teamSelected: false,
        homeSelected: false,
      });
    } else {
      this.setState({
        tournamentSelected: false,
        challengeSelected: false,
        homeSelected: true,
        teamSelected: false,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  shareDa(val) {
    //this.props.history.push("/newsFeads/" + val);
  }
  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    const that = this;
    var logedIn = localStorage.getItem("logedIn");
    if (logedIn != null) {
      if (this.state.user) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          document.getElementById("searchdiv").style.display = "none";
          that.setState({
            search: "",
          });
        }
      }
    }
  }

  ToggleClass = () => {
    this.setState({ isActive: !this.state.isActive });
    // $(".d_leftsec").toggleClass("d_leftsec_width");
  };

  updateSearch = (event) => {
    document.getElementById("searchdiv").style = "block";
    const that = this;
    that.setState({
      search: event.target.value,
      searchLen: event.target.value.length,
    });
    console.log(that.state.search);
    console.log(that.state.searchLen);
    if (event.target.value.length > 2) {
      if (this.state.searchFor === "teams") {
        const globalTeams = rshApi + "/global/teams";
        fetch(globalTeams, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              name: this.state.search,
              minimal: true,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              filterTeam: result.data,
            });
            console.log(that.state.filterTeam);
          });
      }
      if (this.state.searchFor === "tournaments") {
        const globalTour = rshApi + "/global/tournaments";
        fetch(globalTour, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              name: this.state.search,
              minimal: true,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              filterTournament: result.data,
            });
            console.log(that.state.filterTournament);
          });
      }

      if (this.state.searchFor === "broadcasts") {
        const globalBroad = rshApi + "/global/broadcasts";
        fetch(globalBroad, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              name: this.state.search,
              minimal: true,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              filterBroadcast: result.data,
            });
            console.log(that.state.filterBroadcast);
          });
      }
      if (this.state.searchFor === "buddies") {
        const globalUsers = rshApi + "/global/users";
        fetch(globalUsers, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              name: this.state.search,
              minimal: true,
            },
          }),
        })
          .then(function (response) {
            return trackPromise(response.json());
          })
          .then(function (result) {
            that.setState({
              filterSearch: result.data,
            });
            console.log(that.state.filterSearch);
          });

        // const getAllBuddsfromUser = rshApi + "/users/get-buddies";
        // fetch(getAllBuddsfromUser, {
        //     method: "post",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify({
        //         "data": {
        //             "buddyId": this.state.user.contactNo
        //         }
        //     })
        // })
        //     .then(function (response) {
        //         return trackPromise(response.json());
        //     })
        //     .then(function (result) {
        //         console.log(result);
        //         if (result.data == "No buddies found") {

        //         }
        //         else {
        //             that.setState({
        //                 allBuddies: result.data,
        //             });
        //         }
        //     });
      }
    }
  };

  openmenu = () => {
    $(".d_leftsec").toggleClass("d_leftsec_width");
    this.props.parentCallbackfromChild("openSidebar");
  }

  closemenu = () => {
    $(".d_leftsec").toggleClass("d_leftsec_width");
    this.props.parentCallbackfromChild("closeSidebar");
  }

  liveScore = () => {
    confirmAlert({
      customUI: () => {
        return <CricketFootballAlert />;
      },
    });
  };
  openBroadcast = (bId) => {
    const url = videoUrl + bId.broadcastId + ".mp4";
    this.setState({ showBroadcast: true, playing: true, bUrl: url });
    console.log(url);
  };
  playerClose = () => {
    this.setState({ showBroadcast: false, playing: false });
  };
  render() {
    // console.log(this.props.userObj)
    let hideShowTheNotifications = true;
    if (this.props.hideShowNotifications === false) {
      hideShowTheNotifications = false;
    }
    else if (window.location.pathname === "/notifications") {
      hideShowTheNotifications = false;
    }

    const userImgStyle = {
      backgroundImage: "url(" + guestLogo + ")",
    };
    if (this.state.user) {
      this.items = (
        <div className="container reswidthzero containertop">
          <div className="col-lg-3 col-md-3 col-sm-2 col-xs-1">
            <div className="logodiv">
              {/* <Link to={"/"}>
                            <img alt="" src={finalLogo} className="logoicon hidden-xs" />
                            <span className={(this.state.isActive) ? 'leftmenuopen' : 'leftmenuopen d_leftsec_width'} onClick={this.openmenu}>
                                <img src={menuLeftIcon} className="hidden-lg hidden-md hidden-sm menuicon" alt="" />
                            </span>
                            <img alt="" className="logotext" src={finalText} />
                        </Link> */}
              <a href={"#"}>
                <img alt="" src={finalLogo} className="logoicon hidden-xs" />
                <span
                  className={
                    this.state.isActive
                      ? "leftmenuopen"
                      : "leftmenuopen d_leftsec_width"
                  }
                >
                  <img
                    src={menuLeftIcon}
                    className="hidden-lg hidden-md hidden-sm menuicon"
                    alt=""
                    onClick={this.openmenu}
                  />
                </span>

                <a href={"/"}>
                  <img alt="" className="logotext" src={finalText} />
                </a>
              </a>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-9">
            <div className="topsearch">
              <input
                onChange={this.updateSearch.bind(this)}
                value={this.state.search}
                type="text"
                placeholder="Search Buddies, Teams and Tournaments.."
              />
              <img src={searchIcon} className="hidden-xs" alt="" />{" "}
              <img src={searchNewIcon} alt="" />
              <div
                className="srachdiv_top"
                id="searchdiv"
                style={{ display: "none" }}
              >
                <div className="pad15 srachdiv_bg">
                  <ul className="nav nav-tabs uni_search">
                    <li
                      className="active"
                      onClick={() => this.changeSelectFor("buddies")}
                    >
                      <Link data-toggle="tab" to={"#Buddies"}>
                        Buddies
                      </Link>
                    </li>
                    <li onClick={() => this.changeSelectFor("teams")}>
                      <Link to={"#Team"} data-toggle="tab">
                        {" "}
                        Teams
                      </Link>
                    </li>
                    <li onClick={() => this.changeSelectFor("tournaments")}>
                      {" "}
                      <Link data-toggle="tab" to={"#Tournament"}>
                        Tournaments
                      </Link>
                    </li>
                    <li onClick={() => this.changeSelectFor("broadcast")}>
                      <Link data-toggle="tab" to={"#Broadcast"}>
                        Broadcasts
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="Buddies" className="tab-pane fade in active">
                      <ul className="srachbuddyul">
                        {this.state.filterSearch.map((item) => {
                          var disableData = false;
                          this.state.allBuddies.map((item2) => {
                            if (item2.contactNo === item.contactNo) {
                              disableData = true;
                            }
                            return true;
                          });
                          if (disableData === true) {
                            if (
                              item.profilePic === null &&
                              this.state.searchLen > 2
                            ) {
                              switch (item.gender) {
                                case "Male":
                                  return (
                                    <a href={"/buddies?id=" + item.contactNo}>
                                      {" "}
                                      <li>
                                        <span
                                          className="sb_bgimg"
                                          style={{
                                            backgroundImage:
                                              "url(" + guestLogo + ")",
                                          }}
                                        ></span>
                                        <div className="sb_innerdiv">
                                          <label>{item.name}</label>
                                          <span></span>
                                        </div>
                                      </li>
                                    </a>
                                  );

                                default:
                                  return (
                                    <a href={"/buddies?id=" + item.contactNo}>
                                      {" "}
                                      <li>
                                        <span
                                          className="sb_bgimg"
                                          style={{
                                            backgroundImage:
                                              "url(" + guestFemaleLogo + ")",
                                          }}
                                        ></span>
                                        <div className="sb_innerdiv">
                                          <label>{item.name}</label>
                                          <span></span>
                                        </div>
                                      </li>
                                    </a>
                                  );
                              }
                            }
                            if (
                              item.profilePic !== null &&
                              this.state.searchLen > 2
                            ) {
                              return (
                                <a href={"/buddies?id=" + item.contactNo}>
                                  <li>
                                    <span
                                      className="sb_bgimg"
                                      style={{
                                        backgroundImage:
                                          "url(" + item.profilePic + ")",
                                      }}
                                    ></span>
                                    <div className="sb_innerdiv">
                                      <label>{item.name}</label>
                                      <span></span>
                                    </div>
                                  </li>
                                </a>
                              );
                            }
                            return "";
                          } else {
                            if (item.contactNo === this.state.user.contactNo) {
                              if (
                                item.profilePic === null &&
                                this.state.searchLen > 2
                              ) {
                                switch (item.gender) {
                                  case "Male":
                                    return (
                                      <a href={"/buddies?id=" + item.contactNo}>
                                        {" "}
                                        <li>
                                          <span
                                            className="sb_bgimg"
                                            style={{
                                              backgroundImage:
                                                "url(" + guestLogo + ")",
                                            }}
                                          ></span>
                                          <div className="sb_innerdiv">
                                            <label>{item.name}</label>
                                            <span></span>
                                          </div>
                                          <span
                                            style={{
                                              color: "#248e3d",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            It's You!
                                          </span>
                                        </li>
                                      </a>
                                    );

                                  default:
                                    return (
                                      <a href={"/buddies?id=" + item.contactNo}>
                                        <li>
                                          <span
                                            className="sb_bgimg"
                                            style={{
                                              backgroundImage:
                                                "url(" + guestFemaleLogo + ")",
                                            }}
                                          ></span>
                                          <div className="sb_innerdiv">
                                            <label>{item.name}</label>
                                            <span></span>
                                          </div>
                                          <span
                                            style={{
                                              color: "#248e3d",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            It's You!
                                          </span>
                                        </li>
                                      </a>
                                    );
                                }
                              }
                              if (
                                item.profilePic !== null &&
                                this.state.searchLen > 2
                              ) {
                                return (
                                  <a href={"/buddies?id=" + item.contactNo}>
                                    <li>
                                      <span
                                        className="sb_bgimg"
                                        style={{
                                          backgroundImage:
                                            "url(" + item.profilePic + ")",
                                        }}
                                      ></span>
                                      <div className="sb_innerdiv">
                                        <label>{item.name}</label>
                                        <span></span>
                                      </div>
                                      <span
                                        style={{
                                          color: "#248e3d",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        It's You!
                                      </span>
                                    </li>
                                  </a>
                                );
                              }
                            } else {
                              if (
                                item.profilePic === null &&
                                this.state.searchLen > 2
                              ) {
                                switch (item.gender) {
                                  case "Male":
                                    return (
                                      <a href={"/buddies?id=" + item.contactNo}>
                                        {" "}
                                        <li>
                                          <span
                                            className="sb_bgimg"
                                            style={{
                                              backgroundImage:
                                                "url(" + guestLogo + ")",
                                            }}
                                          ></span>
                                          <div className="sb_innerdiv">
                                            <label>{item.name}</label>
                                            <span></span>
                                          </div>
                                          {/* <button className="btnadd">Add buddy</button> */}
                                        </li>
                                      </a>
                                    );

                                  default:
                                    return (
                                      <a href={"/buddies?id=" + item.contactNo}>
                                        <li>
                                          <span
                                            className="sb_bgimg"
                                            style={{
                                              backgroundImage:
                                                "url(" + guestFemaleLogo + ")",
                                            }}
                                          ></span>
                                          <div className="sb_innerdiv">
                                            <label>{item.name}</label>
                                            <span></span>
                                          </div>
                                          {/* <button className="btnadd">Add buddy</button> */}
                                        </li>
                                      </a>
                                    );
                                }
                              }
                              if (
                                item.profilePic !== null &&
                                this.state.searchLen > 2
                              ) {
                                return (
                                  <a href={"/buddies?id=" + item.contactNo}>
                                    <li>
                                      <span
                                        className="sb_bgimg"
                                        style={{
                                          backgroundImage:
                                            "url(" + item.profilePic + ")",
                                        }}
                                      ></span>
                                      <div className="sb_innerdiv">
                                        <label>{item.name}</label>
                                        <span></span>
                                      </div>
                                      {/* <button className="btnadd">Add buddy</button> */}
                                    </li>
                                  </a>
                                );
                              }
                            }
                            return "";
                          }
                        })}
                      </ul>
                    </div>
                    <div id="Team" className="tab-pane fade">
                      <ul className="srachbuddyul">
                        {this.state.filterTeam.map((item) => {
                          if (
                            item.teamIcon === null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <a href={"/teamprofilepage/index.html?id=" + item.teamId}>
                                <li>
                                  <span
                                    className="sb_bgimg"
                                    style={{
                                      backgroundImage: "url(" + teamIcon + ")",
                                    }}
                                  ></span>
                                  <div className="sb_innerdiv">
                                    <label>{item.teamName}</label>
                                    <span className="teallocation_das">
                                      <img src="img/locationgray.png" alt="" />
                                      {item.location}
                                    </span>
                                  </div>
                                </li>
                              </a>
                            );
                          }
                          if (
                            item.teamIcon !== null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <a href={"/teamprofilepage/index.html?id=" + item.teamId}>
                                <li>
                                  <span
                                    className="sb_bgimg"
                                    style={{
                                      backgroundImage:
                                        "url(" + item.teamIcon + ")",
                                    }}
                                  ></span>
                                  <div className="sb_innerdiv">
                                    <label>{item.teamName}</label>
                                    <span className="teallocation_das">
                                      <img src="img/locationgray.png" alt="" />
                                      {item.location}
                                    </span>
                                  </div>
                                </li>
                              </a>
                            );
                          }
                          return "";
                        })}
                      </ul>
                    </div>
                    <div id="Tournament" className="tab-pane fade">
                      <ul className="srachbuddyul">
                        {this.state.filterTournament.map((item) => {
                          if (
                            item.tournamentIcon === null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <a
                                href={
                                  "/tournamentprofile?id=" + item.tournamentId
                                }
                              >
                                <li>
                                  <span
                                    className="sb_bgimg"
                                    style={{
                                      backgroundImage:
                                        "url(" + tournamentprofileimg + ")",
                                    }}
                                  ></span>
                                  <div className="sb_innerdiv">
                                    <label>{item.tournamentName}</label>
                                    <span className="teallocation_das">
                                      <img src="img/locationgray.png" alt="" />
                                      {item.location}
                                    </span>
                                  </div>
                                </li>
                              </a>
                            );
                          }
                          if (
                            item.tournamentIcon !== null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <a
                                href={
                                  "/tournamentprofile?id=" + item.tournamentId
                                }
                              >
                                <li>
                                  <span
                                    className="sb_bgimg"
                                    style={{
                                      backgroundImage:
                                        "url(" + item.tournamentIcon + ")",
                                    }}
                                  ></span>
                                  <div className="sb_innerdiv">
                                    <label>{item.tournamentName}</label>
                                    <span className="teallocation_das">
                                      <img src="img/locationgray.png" alt="" />
                                      {item.location}
                                    </span>
                                  </div>
                                </li>
                              </a>
                            );
                          }
                          return "";
                        })}
                      </ul>
                    </div>
                    <div id="Broadcast" className="tab-pane fade">
                      <ul className="srachbuddyul">
                        {this.state.filterBroadcast.map((item) => {
                          if (
                            item.snapshot === null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <li onClick={() => this.openBroadcast(item)}>
                                <span
                                  className="sb_bgimg"
                                  style={{
                                    backgroundImage: "url(" + broacast8 + ")",
                                  }}
                                ></span>
                                <div className="sb_innerdiv">
                                  <label>{item.title}</label>
                                  <span className="teallocation_das">
                                    <img src="img/locationgray.png" alt="" />
                                    {item.location}
                                  </span>
                                </div>
                              </li>
                            );
                          }
                          if (
                            item.snapshot !== null &&
                            this.state.searchLen > 2
                          ) {
                            return (
                              <li
                                onClick={() =>
                                  this.openBroadcast(item.broadcastId)
                                }
                              >
                                <span
                                  className="sb_bgimg"
                                  style={{
                                    backgroundImage:
                                      "url(" + item.snapshot + ")",
                                  }}
                                ></span>
                                <div className="sb_innerdiv">
                                  <label>{item.title}</label>
                                  <span className="teallocation_das">
                                    <img src="img/locationgray.png" alt="" />
                                    {item.location}
                                  </span>
                                </div>
                              </li>
                            );
                          }
                          return "";
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-5 col-xs-2">
            <div className="dropdiv">
              {hideShowTheNotifications ? <Notification /> : null}

              <div className="dropdown dropaccount">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {(() => {
                    switch (this.state.user.gender) {
                      case "Male":
                        return (
                          <span
                            className="t_userimg"
                            style={{
                              backgroundImage:
                                "url(" +
                                `${this.state.user.profilePic
                                  ? this.state.user.profilePic
                                  : guestLogo
                                }` +
                                ")",
                            }}
                          >
                            {" "}
                          </span>
                        );
                      case "Female":
                        return (
                          <span
                            className="t_userimg"
                            style={{
                              backgroundImage:
                                "url(" +
                                `${this.state.user.profilePic
                                  ? this.state.user.profilePic
                                  : guestFemaleLogo
                                }` +
                                ")",
                            }}
                          >
                            {" "}
                          </span>
                        );
                      default:
                        return "";
                    }
                  })()}
                  <span className="text-capitalize">
                    Hi, {this.state.user.name}
                  </span>
                  <img alt="" className="t_accarrow" src={arrowDownIcon} />
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a href="/my-account">My Account</a>
                  </li>
                  <li>
                    <Link onClick={() => this.logOutNonSession()}>Logout</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      this.items = (
        <div className="container reswidthzero containertop">
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
            <div className="logodiv">
              {/* <Link to={"/"}>
                            <img alt="" src={finalLogo} className="logoicon hidden-xs" />
                            <span className={(this.state.isActive) ? 'leftmenuopen' : 'leftmenuopen d_leftsec_width'} onClick={this.ToggleClass}>
                                <img src={menuLeftIcon} className="hidden-lg hidden-md hidden-sm menuicon" alt="" />
                            </span>
                            <img alt="" className="logotext" src={finalText} />
                        </Link> */}
              <Link to={"#"}>
                <img alt="" src={finalLogo} className="logoicon hidden-xs" />
                <span
                  className={
                    this.state.isActive
                      ? "leftmenuopen"
                      : "leftmenuopen d_leftsec_width"
                  }
                >
                  <img
                    src={menuLeftIcon}
                    className="hidden-lg hidden-md hidden-sm menuicon"
                    alt=""
                    onClick={this.openmenu}
                  />
                </span>

                <Link to={"/"}>
                  <img alt="" className="logotext" src={finalText} />
                </Link>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <div className="dropdiv">
              <div className="dropdown dropaccount">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <span className="t_userimg" style={userImgStyle}>
                    {" "}
                  </span>
                  <span>
                    Hi,{" "}
                    {this.state.nonSessionUser
                      ? this.state.nonSessionUser.name
                      : "Guest"}
                  </span>
                  <img alt="" className="t_accarrow" src={arrowDownIcon} />
                </button>
                <ul className="dropdown-menu">
                  {this.state.nonSessionUser ? (
                    <li>
                      <a onClick={() => this.logOutNonSession()}>Logout</a>
                    </li>
                  ) : (
                    <React.Fragment>
                      <li>
                        <a href="/login">Registration</a>
                      </li>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <Modal
          show={this.state.showBroadcast}
          onHide={() => this.playerClose()}
          animation={false}
        >
          <div className="modal-content vd_gal_content">
            <ReactPlayer
              controls={true}
              className="react-player"
              playing={this.state.playing}
              url={this.state.bUrl}
              width="100%"
              height="100%"
            />
          </div>
        </Modal>
        <div ref={this.setWrapperRef}>
          {" "}
          {this.props.children}
          <header className="topbar" data-spy="affix" data-offset-top="100">
            {this.items}
          </header>
          <section className="Smartactionstop hidden-md hidden-sm hidden-lg">
            <div className="st_div">
              {this.state.user ? (
                <ul>
                  <li>
                    {this.state.homeSelected ? (
                      <Link to={"/"} className="active">
                        <img alt="" src={newFeedsLine} className="smartline" />{" "}
                        <img alt="" className="smartfill" src={newFeedFill} />{" "}
                        Home
                      </Link>
                    ) : (
                      <Link to={"/"}>
                        <img alt="" src={newFeedsLine} className="smartline" />{" "}
                        <img alt="" className="smartfill" src={newFeedFill} />{" "}
                        Home
                      </Link>
                    )}
                  </li>
                  <li>
                    {this.state.teamSelected ? (
                      <Link to={"/teamslist"} className="active">
                        <img alt="" src={teamBlack} className="smartline" />{" "}
                        <img alt="" className="smartfill" src={teamFillIcon} />{" "}
                        Team
                      </Link>
                    ) : (
                      <Link to={"/teamslist"}>
                        <img alt="" src={teamBlack} className="smartline" />{" "}
                        <img alt="" className="smartfill" src={teamFillIcon} />{" "}
                        Team
                      </Link>
                    )}
                  </li>
                  <li>
                    {this.state.challengeSelected ? (
                      <Link to={"/challengelist"} className="active">
                        <img alt="" src={challengeIcon} className="smartline" />{" "}
                        <img
                          alt=""
                          className="smartfill"
                          src={challengeFillIcon}
                        />{" "}
                        Challange
                      </Link>
                    ) : (
                      <Link to={"/challengelist"} className="">
                        <img alt="" src={challengeIcon} className="smartline" />{" "}
                        <img
                          alt=""
                          className="smartfill"
                          src={challengeFillIcon}
                        />{" "}
                        Challange
                      </Link>
                    )}
                  </li>
                  <li>
                    {this.state.tournamentSelected ? (
                      <Link className="active" to={"/tournamentlist"}>
                        <img alt="" src={fixtureIcon} className="smartline" />{" "}
                        <img
                          alt=""
                          className="smartfill"
                          src={fixtureFillIcon}
                        />{" "}
                        Tournament
                      </Link>
                    ) : (
                      <Link to={"/tournamentlist"}>
                        <img alt="" src={fixtureIcon} className="smartline" />{" "}
                        <img
                          alt=""
                          className="smartfill"
                          src={fixtureFillIcon}
                        />{" "}
                        Tournament
                      </Link>
                    )}
                  </li>
                </ul>
              ) : this.state.nonSessionUser ? (
                <ul>
                  <li>
                    <Link to={"/completeProfile"} className="active">
                      <img alt="" src={newFeedsLine} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={newFeedFill} />{" "}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={teamBlack} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={teamFillIcon} />{" "}
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={challengeIcon} className="smartline" />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={challengeFillIcon}
                      />{" "}
                      Challange
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={fixtureIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={fixtureFillIcon} />{" "}
                      Tournament
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to={"/login"} className="active">
                      <img alt="" src={newFeedsLine} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={newFeedFill} />{" "}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={teamBlack} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={teamFillIcon} />{" "}
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={challengeIcon} className="smartline" />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={challengeFillIcon}
                      />{" "}
                      Challange
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={fixtureIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={fixtureFillIcon} />{" "}
                      Tournament
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </section>
          <section className="Smartactionsbottom hidden-md hidden-sm hidden-lg">
            <div className="st_div">
              {this.state.user ? (
                <ul>
                  <li>
                    <Link to={{ pathname: "/", state: { name: "buddies" } }}>
                      <img alt="" src={buddiesIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={buddiesFillIcon} />{" "}
                      Buddies
                    </Link>
                  </li>
                  <li>
                    <Link to={{ pathname: "/", state: { name: "broadcast" } }}>
                      <img alt="" src={broadcastIcon} className="smartline" />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={broadcastFillIcon}
                      />{" "}
                      Broadcast
                    </Link>
                  </li>
                  {/* <li>
                                    <Link to={"./live-score"} className="livescorelink"><img alt="" src={liveScoreIcon} className="smartline" />Live Score</Link>
                                </li> */}
                  <li>
                    <Link to={"/page"} className="livescorelink">
                      <img alt="" src={pageIconM} className="smartline" />
                      Page
                    </Link>
                  </li>
                  <li>
                    <Link to={"/schedule-matches"}>
                      <img
                        alt=""
                        src={scheduleMatchesGrayIcon}
                        className="smartline"
                      />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={scheduleMatchesIcon}
                      />{" "}
                      Schedule
                    </Link>
                  </li>
                  {/* <li>
                                    <Link to={{ pathname: '/', state: { name: 'schedule' } }}><img alt="" src={scheduleMatchesGrayIcon} className="smartline" /> <img alt="" className="smartfill" src={scheduleMatchesIcon} /> Schedule</Link>
                                </li> */}
                  <li>
                    {/* <Link to={{ pathname: '/', state: { name: 'ChatResp' } }} ><img alt="" src={chatGrayIcon} className="smartline" /> <img alt="" className="smartfill" src={chatFillIcon} /> Chat</Link> */}
                    <Link>
                      <img alt="" src={chatGrayIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={chatFillIcon} />{" "}
                      Chat
                    </Link>
                  </li>
                </ul>
              ) : this.state.nonSessionUser ? (
                <ul>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={buddiesIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={buddiesFillIcon} />{" "}
                      Buddies
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={broadcastIcon} className="smartline" />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={broadcastFillIcon}
                      />{" "}
                      Broadcast
                    </Link>
                  </li>
                  <li>
                    <Link to={"./live-score"} className="livescorelink">
                      <img alt="" src={liveScoreIcon} className="smartline" />
                      Live Score
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img
                        alt=""
                        src={scheduleMatchesGrayIcon}
                        className="smartline"
                      />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={scheduleMatchesIcon}
                      />{" "}
                      Schedule
                    </Link>
                  </li>
                  <li>
                    <Link to={"/completeProfile"}>
                      <img alt="" src={chatGrayIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={chatFillIcon} />{" "}
                      Chat
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={buddiesIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={buddiesFillIcon} />{" "}
                      Buddies
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={broadcastIcon} className="smartline" />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={broadcastFillIcon}
                      />{" "}
                      Broadcast
                    </Link>
                  </li>
                  <li>
                    <Link to={"./live-score"} className="livescorelink">
                      <img alt="" src={liveScoreIcon} className="smartline" />
                      Live Score
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img
                        alt=""
                        src={scheduleMatchesGrayIcon}
                        className="smartline"
                      />{" "}
                      <img
                        alt=""
                        className="smartfill"
                        src={scheduleMatchesIcon}
                      />{" "}
                      Schedule
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <img alt="" src={chatGrayIcon} className="smartline" />{" "}
                      <img alt="" className="smartfill" src={chatFillIcon} />{" "}
                      Chat
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Header;
