import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import '../css/animate.min.css';
// import '../css/bootstrap.min.css';
import '../css/font-awesome.min.css';
import '../css/style-landing.css';
import '../css/responsive-landing.css';
import preloader from '../img/landing/preloader.png';
import video1 from '../img/landing/video-1-1.jpg';
import timelineResponsive from '../img/landing/timeline-responsive.png';
import img1 from '../img/landing/img-01.png';
import img3 from '../img/landing/img-03.png';
import img4 from '../img/landing/img-04.png';
import cBall from '../img/landing/c-ball.gif';
import ss from '../img/landing/ss.png';
import ss2 from '../img/landing/ss-02.png';
import timeline from '../img/landing/timeline.png';
import logo from '../img/landing/la-logo.png';
import ctaonebg from '../img/landing/cta-one-bg.png';

export default class LandingPage extends Component {
    downloadApp = () => {
        if (/Android/i.test(navigator.userAgent)) {
            window.open("https://play.google.com/store/apps/details?id=com.sportsapp.ssp");
        }
        else if (/Ios/i.test(navigator.userAgent)) {
            window.open("https://apps.apple.com/app/id1437392923");
        }
        else {
            window.open("https://www.playdiator.com");
        }

    }
    componentDidMount() {
        if ($('.main-navigation .navigation-box').length) {
            var subMenu = $('.main-navigation .sub-menu');
            subMenu.parent('li').children('a').append(function () {
                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
            });
            var mainNavToggler = $('.header-navigation .menu-toggler');
            var subNavToggler = $('.main-navigation .sub-nav-toggler');
            mainNavToggler.on('click', function () {
                var Self = $(this);
                var menu = Self.data('target');
                $(menu).slideToggle();
                $(menu).toggleClass('showen');
                return false;
            });
            subNavToggler.on('click', function () {
                var Self = $(this);
                Self.parent().parent().children('.sub-menu').slideToggle();
                return false;
            });
        }
    }
    render() {
        return (
            <div>
                {/* <div class="preloader">
                    <img src={preloader} class="preloader__image" alt=""/>
    </div> */}
                <div className="page-wrapper">
                    <header className="site-header site-header__header-one ">
                        <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
                            <div className="container clearfix">

                                <div className="logo-box clearfix">
                                    <button className="menu-toggler" data-target=".main-navigation">
                                        <span className="fa fa-bars"></span>
                                    </button>
                                    <a className="navbar-brand" href="/">
                                        <img src={logo} className="main-logo" alt="Awesome Image" />
                                    </a>
                                </div>
                                <div className="main-navigation">
                                    <ul className=" one-page-scroll-menu navigation-box">
                                        <li className="current scrollToLink">
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/about-us"}>About Us</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/privacy-policy"}>Privacy Policy</Link>

                                        </li>

                                        <li className="scrollToLink">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/faq"}>FAQ </Link>


                                        </li>
                                    </ul>
                                </div>
                                <div className="right-side-box">
                                    <Link className="thm-btn header__cta-btn"
                                        to={"/login"}><span>Login</span></Link>
                                </div>
                            </div>

                        </nav>
                    </header>
                    <div className="col-lg-6 col-md-6 col-sm-8 offset-sm-2">
                        <div className="banner-one__content hide">
                            <h3 className="banner-one__title">
                                Unlimited
                    <br></br><span> Social Sports</span><br></br> Management App</h3>
                            <Link to={"/guest"} className="banner-one__btn thm-btn" target="_blank"><span>Read
                        More</span></Link>
                        </div>
                    </div>

                    <section className="banner-one" id="banner">
                        <span className="banner-one__shape-1"><img src={img1} alt="Image01" /></span>
                        <span className="banner-one__shape-2"><img src={img3} /></span>
                        <span className="banner-one__shape-3"><img src={cBall} alt="Image03" /></span>
                        <span className="banner-one__shape-4"><img src={img4} alt="Image04" /></span>
                        <div className="container">
                            <div className="banner-one__moc">
                                <img src={ss} alt="top banner" />
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-8">
                                    <div className="banner-one__content show">
                                        <h3 className="banner-one__title">
                                            Unlimited <br></br><span style={{ fontWeight: '600' }}> Social Sports</span><br></br> Management App</h3>

                                        <br></br><Link to={"/guest"} className="banner-one__btn thm-btn "
                                            target="_blank"><span>Read More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="video-one" id="video">

                        <div className="container wow fadeInUp" data-wow-duration="1500ms">

                            <div className="video-one__box ">
                                <img src={video1} alt="" />
                                <div className="video-one__content">
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/nqdZs6m1IDo?controls=0"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>


                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pricing-one" id="pricing">
                        <div className="block-title text-center">
                            <h2 className="block-title__title">Lets Start <br></br> with <span style={{ fontSize: '50px' }}>Playdiator</span></h2>

                        </div>

                        <div className="timeline">
                            <img src={timeline} className="img-responsive show" alt="timeline" />
                            <img src={timelineResponsive} className="img-responsive hide" alt="timeline" />
                        </div>

                    </section>

                    <section className="cta-one">
                        <img src={ctaonebg} className="cta-one__bg" alt="Awesome Image" />
                        <div className="container">
                            <img src={ss2} className="cta-one__moc" alt="Awesome Image" />
                            <div className="row justify-content-lg-end">
                                <div className="col-lg-6" style={{ float: 'right' }}>
                                    <div className="cta-one__content">
                                        <div className="block-title text-left">
                                            <h2 className="block-title__title">Are you looking for an app which can do it
                                    <span style={{ paddingLeft: '10px' }}>FREE?</span> </h2>
                                            <br></br>
                                            <h2 className="block-title__title">Then you have come to right place!</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mailchimp-one">
                        <div className="container">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="block-title">
                                    <h2 className="block-title__title l-text">Make everything <br></br> <span className="small-text">transparent
                                & fair for everyone</span>
                                        <br></br><span className="show"><a href="https://www.playdiator.com/login"
                                            className="download_btn">REGISTER NOW</a></span>
                                        <br></br><span className="hide"><a href="#" onClick="downloadApp()" className="download_btn">DOWNLOAD APP
                                    NOW</a></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


                <a href="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></a>
            </div>
        )
    }
}
