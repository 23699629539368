import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import userMaleIcon from "./img/male.png";
import likedark from "./img/likedark.png";
import share from "./img/share.png";
import comment from "./img/comment.png";
import CommentList from "./CommentList";
import userFemaleIcon from "./img/female.png";
import more from "./img/more.png";
import likebox from "./img/likebox.png";
import likegreen from "./img/likegreen.png";
import { } from 'prismic-reactjs';
import { Link, Redirect } from 'react-router-dom';
import LikeList from './LikeList';
import Emoji from './Emoji';
import { confirmAlert } from 'react-confirm-alert';
import happiness from './img/happiness.png';
import danger from './img/danger.png';
import ConfigUrl from './ConfigUrl';
import { Modal } from 'bootstrap';
import Modal1 from 'react-bootstrap/Modal';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
var dynamicFeedsid;

class LoginFeedsPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            postdata: {},
            feedReport: false,
            commentData: '',
            isShowEmoji: false,
            userData: [],
            reasonSelection: '',
            showingReportAlert: false,
            isProfileRedirection: false
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }

    componentDidMount() {
        this.loadMore();
    }

    //report feed coding
    newId = (id) => {
        this.setState({ feedReport: true })
        dynamicFeedsid = id;
    }
    childEmojicallback = (emojiObj) => {

        let input = this.state.commentData;
        input += emojiObj.emoji;
        this.setState({ commentData: input });


    }
    submitReportPost = () => {
        this.setState({ feedReport: false })
        const that = this;
        if (this.state.reasonSelection !== '') {
            const reports = userApi + "/newfeeds/toggle-report-feed";
            fetch(reports, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "reportedBy": that.state.user.contactNo,
                        "newFeedsId": dynamicFeedsid,
                        "cause": that.state.reasonSelection,
                        "reported": true,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {

                    that.props.pReportCB(dynamicFeedsid);
                    if (result.data === 'success') {
                        that.setState({
                            showingReportAlert: true
                        });
                        setTimeout(() => {
                            that.setState({
                                showingReportAlert: false
                            });
                        }, 2000);
                    }
                });
        }
    }

    undoReport = () => {

        const reports = userApi + "/newfeeds/toggle-report-feed";
        let that = this;
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "reportedBy": this.state.user.contactNo,
                    "newFeedsId": this.props.newFeedsId,
                    "cause": this.state.reasonSelection,
                    "reported": false,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

                that.props.pReportCB(that.props.newFeedsId);

            });
    }

    //reason selection coding
    clearInputModal = () => {
        this.setState({ feedReport: false })
        document.getElementById('reportForm').reset();
        this.setState({
            reasonSelection: ''
        });
    }

    reasonSelection = (e) => {
        let reasonSelection = e.target.value;
        if (e.target.checked === true) {
            switch (reasonSelection) {
                case 'scam':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                case 'offensive':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                case 'violence':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                case 'prohibited':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                case 'spam':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                case 'falsenews':
                    this.setState({
                        reasonSelection: reasonSelection
                    });

                    break

                default:
                    this.setState({
                        reasonSelection: 'others'
                    });
            }

        }
    }


    commentFunction = (postId) => {
        this.setState({ isShowEmoji: false })
        if (this.state.commentData) {
            this.state.user = JSON.parse(localStorage.getItem("result"));
            let dummyUser = {
                contactNo: this.state.user.contactNo,
                gender: this.state.user.gender,
                name: this.state.user.name,
                profilePic: this.state.user.profilePic,
            }
            this.setState({ userData: [...this.state.userData, dummyUser] })
            const contactNumber = this.state.user.contactNo;
            var timestamp = Math.floor(Date.now());
            var postData = this.state.postdata;
            if (postData.postId === postId) {
                if (postData.reactedBuddies === null) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    }];
                    delete postData.id;   //Always use this in update case
                    let that = this;
                    postData.reactedBuddies = reactedBuddies;

                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": postData,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {

                            that.setState({ commentData: '', postData: postData }, () => {
                                that.showAllComments(postId)
                            });

                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    };
                    postData.reactedBuddies.push(reactedBuddies);
                    delete postData.id; //Always use this in update case
                    let that = this;
                    fetch(userApi + "/posts/update-post", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "post": postData,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            that.setState({ commentData: '', postData: postData });
                            that.showAllComments(postId)
                        });

                }
            }
            //postAll.push(postData);
            this.setState({ postData: postData });
            // this.loadComponent();
        }
    }
    likeFunction = (postId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        // this.setState({ user: JSON.parse(localStorage.getItem("result")) });
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        var postAll = [];
        var postData = this.state.postdata;

        if (postData.postId === postId) {
            if (postData.reactedBuddies === null) {
                var reactedBuddies = [{
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                }];
                delete postData.id;   //Always use this in update case 
                postData.reactedBuddies = reactedBuddies;
                fetch(userApi + "/posts/update-post", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "post": postData,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                    });
            }
            else {
                reactedBuddies = {
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                };
                postData.reactedBuddies.push(reactedBuddies);
                delete postData.id; //Always use this in update case 
                // let that = this;
                fetch(userApi + "/posts/update-post", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "post": postData,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                        // that.loadMore();
                    });
            }
        }

        postAll.push(postData);
        // return true;

        this.setState({ postdata: postData });
        // this.loadComponent();
    }
    showAllComments = (postId) => {
        let reactedBuddies = [];
        console.log(this.state.postdata)

        if (this.state.postdata.postId === postId) {
            reactedBuddies = this.state.postdata.reactedBuddies;
        }
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (postId) => {
        let reactedBuddies = [];

        if (this.state.postdata.postId === postId) {
            reactedBuddies = this.state.postdata.reactedBuddies;
        }


        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }

        confirmAlert({
            customUI: () => {
                return (
                    <div>
                        <LikeList data={data} />
                    </div>
                );
            }
        })
    }
    feedReportHandel = () => {
        this.setState({ feedReport: false })
    }
    loadMore = () => {

        let that = this;
        var apiUrl = userApi + '/posts/get-post';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    postId: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)
            that.setState({ postdata: result.data.post, userData: result.data.users });
        });
    }
    profileRedirection = (cNo) => {
        this.setState({ redirectonContacNu: cNo })
        window.location = "/buddies?id=" + cNo;
    }
    render() {
        // if (this.state.isProfileRedirection) {
        //     return <Redirect
        //         to={{
        //             pathname: "/buddies?id=" + this.state.redirectonContacNu
        //         }}
        //     />

        // }
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(this.state.postdata.timestamp);
        var res = formattedTimestamp.split(",");
        var feadsDate = res[0] + "," + res[1];

        var todayDate = new Date(new Date().setDate(new Date().getDate()));
        todayDate = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(todayDate);
        var res1 = todayDate.split(",");
        todayDate = res1[0] + "," + res1[1];

        var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterdayDate = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(yesterdayDate);
        var res2 = yesterdayDate.split(",");
        yesterdayDate = res2[0] + "," + res2[1];

        if (feadsDate === todayDate) {
            feadsDate = "Today" + "," + res[2];
        }
        else if (feadsDate === yesterdayDate) {
            feadsDate = "Yesterday" + "," + res[2];
        }
        else {
            feadsDate = feadsDate + "," + res[2];
        }

        var userIcon = false; var textData = false; var youtubeData = false; var imageData = false; var videoData = false;

        var reportValue = this.props.reported;
        if (reportValue === false) {

            return (
                <React.Fragment>
                    {/* Start Report Abuse */}
                    <Modal1
                        size="md"
                        show={this.state.feedReport}
                        onHide={() => this.feedReportHandel()}
                        animation={false}
                        aria-labelledby="example-modal-sizes-title-md"
                    >
                        {/* <div id="reportabusePost" className="modal fade" role="dialog"> */}
                        {/* <div className="modal-dialog"> */}
                        {/* Modal content Report Feeds */}
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={() => this.clearInputModal()} type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Report this feeds</h4>
                            </div>
                            <div className="modal-body">
                                <label className="reportlabel">Take a moment and tell us what's wrong with this post</label>
                                <form id="reportForm">
                                    <ul className="reportul">
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost1" value="scam" />
                                                <label htmlFor="resportPost1">Scam</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost2" value="offensive" />
                                                <label htmlFor="resportPost2">Offensive </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost3" value="violence" />
                                                <label htmlFor="resportPost3">Violence </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost4" value="others" />
                                                <label htmlFor="resportPost4">Others</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost5" value="spam" />
                                                <label htmlFor="resportPost5">Spam</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost6" value="falsenews" />
                                                <label htmlFor="resportPost6">False-news</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection.bind(this)} type="radio" name="fieldset-1" id="resportPost7" value="prohibited" />
                                                <label htmlFor="resportPost7">Prohibited </label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <p id="reportedPost" style={{ display: 'none' }} className={`alert alert-success ${this.state.showingReportAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                    <strong>Feed Reported!</strong>
                                </p>
                                <button onClick={() => this.submitReportPost()} type="button" className="cmnbutton">Submit</button>
                                <button onClick={() => this.clearInputModal()} type="button" className="btndark cmnbutton" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        {/* End Report Abuse */}
                    </Modal1>
                    {
                        this.state.userData.map((userData) => {
                            var buttonDisable = false;
                            var likeCount = 0; var commentCount = 0; var buddyName = null;
                            if (this.state.postdata.reactedBuddies !== null) {
                                this.state.postdata.reactedBuddies.map(z => {
                                    if (z.reactedType === "cheer") {
                                        if (z.buddyId === this.state.user.contactNo) {
                                            buttonDisable = true;
                                        }
                                        this.state.userData.map((buddy) => {
                                            if (buddy.contactNo === z.buddyId) {
                                                buddyName = buddy.name;
                                            }
                                            return buddyName;
                                        })
                                        likeCount++;
                                    }
                                    if (z.reactedType === "comment") {
                                        commentCount++;
                                    }
                                    return likeCount;
                                });
                                if (buddyName === null) {
                                    this.state.postdata.reactedBuddies.map((z) => {
                                        this.state.userData.map((user) => {
                                            if (user.contactNo === z.buddyId) {
                                                buddyName = user.name;

                                            }
                                            return buddyName;
                                        })
                                        return true;
                                    })
                                }
                            }
                            if (userData.contactNo === this.state.postdata.createdBy) {
                                if (userData.profilePic === null) {
                                    if (userData.gender === "Male") {
                                        userIcon = userMaleIcon;
                                    }
                                    else {
                                        userIcon = userFemaleIcon;
                                    }
                                }
                                else {
                                    userIcon = userData.profilePic;
                                }

                                if (this.state.postdata.mediaType === 'text') {
                                    if (this.state.postdata.youtubeId) {
                                        youtubeData = true;
                                    } else {
                                        textData = true;
                                    }


                                }
                                else if (this.state.postdata.mediaType === "image") {
                                    imageData = true;
                                }
                                else if (this.state.postdata.mediaType === 'video') {
                                    if (this.state.postdata.youtubeId !== null) {
                                        youtubeData = true;
                                    }
                                    else {
                                        videoData = true;
                                    }
                                }

                                {/* checking more button on logged in user */ }
                                if (this.state.user.contactNo !== this.props.sentBy) {
                                    this.moreButton = <button className="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" /></button>;
                                }
                                return <div className="divpost sharepostdiv" id="accordion" key={this.state.postdata.postId}><div className="userdetail">
                                    <ul className="postuserul" >
                                        <li className="p_userli">
                                            <span onClick={() => this.profileRedirection(userData.contactNo)} className="p_userimg" style={{
                                                backgroundImage: "url(" + userIcon + ")"
                                            }}>
                                            </span>
                                            <label className="cursorPointer" onClick={() => this.profileRedirection(userData.contactNo)}>{this.state.postdata.name}{userData.name}</label>

                                            <p className="p_usertime">{feadsDate}</p>

                                            <span className="p_usertime">{this.state.postdata.location}</span>
                                        </li>
                                        <li className="p_usermore">
                                            <div className="dropdown dropaccount">
                                                {this.moreButton}
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link onClick={(e) => this.newId(this.props.newFeedsId)} data-toggle="modal" > Report Abuse </Link>

                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                    {
                                        textData ? <div className="postdata">
                                            <p>{this.state.postdata.text}</p>
                                        </div> : ''
                                    }
                                    {
                                        imageData ? <div className="postdata">
                                            <p>{this.state.postdata.text}</p>
                                            <img style={{ 'width': '100%' }} src={this.state.postdata.mediaUrl} className="img-responsive" alt="" />

                                        </div> : ''
                                    }
                                    {
                                        youtubeData ? <div className="postdata">
                                            <p>{this.state.postdata.text}</p>
                                            <iframe allowfullscreen="allowfullscreen" style={{ 'width': '100%', 'height': '200px' }} src={'https://www.youtube.com/embed/' + this.state.postdata.youtubeId} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" title="myFrame" ></iframe>

                                        </div> : ''
                                    }


                                    {
                                        videoData ? <div className="postdata">
                                            <p>{this.state.postdata.text}</p>
                                            <video id="video1" style={{ 'width': '100%', 'height': '200px' }} controls>
                                                <source src={this.state.postdata.mediaUrl} type="video/mp4" />
                                            </video>
                                        </div> : ''
                                    }

                                    <div className="postlikediv">
                                        {(() => {
                                            switch (likeCount) {
                                                case 1:
                                                    switch (commentCount) {
                                                        case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span >   liked this </span>
                                                        </span>;
                                                        default: return <span className="userlikeinner"> <span ><img src={likebox} alt="" /> <b>{buddyName}</b> liked this</span><span key={this.state.postdata.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.postdata.postId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.postdata.postId} > {commentCount} comments</span>
                                                        </span>
                                                    }
                                                case 0:
                                                    switch (commentCount) {
                                                        case 0: return '';
                                                        default: return <span className="userlikeinner"><span key={this.state.postdata.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.postdata.postId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.postdata.postId}> {commentCount} comments</span>
                                                        </span>;
                                                    }
                                                default:
                                                    switch (commentCount) {
                                                        case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.postdata.postId)}> and {likeCount - 1}more liked this </span>
                                                        </span>;
                                                        default: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.postdata.postId)}> and {likeCount - 1}more liked this</span><span key={this.state.postdata.postId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.postdata.postId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.postdata.postId}> {commentCount} comments</span>
                                                        </span>
                                                    }
                                            }
                                        })()}
                                        <ul className="commentul">
                                            {buttonDisable ? <li className="commentli commentinner">
                                                <img src={likegreen} className="likeuser" alt="" />
                                                <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount}</span></span>
                                            </li> : <li className="commentli commentinner" onClick={(e) => this.likeFunction(this.state.postdata.postId)}>
                                                <img src={likedark} className="likeuser" alt="" />
                                                <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount} </span></span>
                                            </li>}
                                            <li key={this.state.postdata.postId} className="commentinner commentinner2" id="comment1">
                                                <img src={comment} className="commentimg" alt="" />
                                                <span data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.postdata.postId} onClick={(e) => this.setState({ commentList: false })}>Comment<span className=" spancount">{commentCount}</span></span> </li>
                                            {/* <li className="commentinner commentinner3" id="" >
                                                <Link to="#" className="" data-toggle="modal" data-target="#myModal"><img src={share} className="commentimg" alt="" />
                                                    <span>Share<span className="spancount"></span></span></Link> </li> */}
                                        </ul>
                                        <div className="postcomment" id={this.state.postdata.postId}> <span className="" style={{
                                            backgroundImage: "url(" + userMaleIcon + ")"
                                        }}></span>
                                            {this.state.commentList ? <CommentList data={this.state.data} /> : <div></div>}
                                            <div key={this.state.postdata.postId} className="postcomment_div" style={{ marginTop: '10px' }}> <Link to="#"><img onClick={() => this.setState({ isShowEmoji: !this.state.isShowEmoji })} src={happiness} alt="" /></Link>
                                                <textarea id={this.state.postdata.postId} value={this.state.commentData} onChange={event => this.setState({ commentData: event.target.value })}></textarea>
                                                <button onClick={(e) => this.commentFunction(this.state.postdata.postId)}>Post</button>
                                            </div>
                                            {this.state.isShowEmoji ? <Emoji parentEmojiCallback={this.childEmojicallback} /> : ''}
                                        </div>
                                    </div>
                                </div>
                            }
                            return true;
                        })
                    }
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className="divpost">
                        <ul className="reportfeed">
                            <li className="reportli1"><img src={danger} /></li>
                            <li className="reportli2">
                                <label>Feeds Reported</label>
                                <p>We would not show you this post again</p>
                            </li>
                            <li className="reportli3"><button onClick={() => this.undoReport()}>Undo</button></li>
                        </ul>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default LoginFeedsPosts;