import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import $ from 'jquery';
import Header from "../Header";
import SectionLeft from "../SectionLeft";
import AddCourse from "../AddCourse";
import * as myTournamentsUtil from '../DemoTournament/DTournamentsUtil';
import { Select } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { message } from 'antd';
import location from "../img/location.png";
import 'antd/dist/antd.css';
import * as myUtilclass from '../DemoTournament/DOrganiseMatchUtil';


import DTournaments_d104_tt5 from '../DemoscoringTournaments/DTournaments_d104_tt5';
import DTournaments_d107_tt5 from '../DemoscoringTournaments/DTournaments_d107_tt5';
import DTournaments_d107_tt2_badminton from '../DemoscoringTournaments/DTournament_d107_badminton_tt23';
import DTournaments_d107_tt2_TableTennis from '../DemoscoringTournaments/DTournament_d107_TableTennis_tt23';
import DTournaments_d107_tt2_Tennis from '../DemoscoringTournaments/DTournament_d107_Tennis_tt23';
import DTournament_d107_Sqaush_tt23 from '../DemoscoringTournaments/DTournament_d107_Sqaush_tt23'
import DTournament_d107_VolleyBall_tt23 from '../DemoscoringTournaments/DTournament_d107_VolleyBall_tt23'
import DTournament_d107_BeachVolleyball_tt23 from '../DemoscoringTournaments/DTournament_d107_BeachVolleyball_tt23'
import DTournament_d107_Carrom_tt23 from '../DemoscoringTournaments/DTournament_d107_Carrom_tt23'
import DTournament_d107_badminton_view from '../DemoscoringTournaments/DTournament_d107_badminton_view'
import DTournament_d107_Carrom_view from '../DemoscoringTournaments/DTournament_d107_Carrom_view'
import DTournament_d107_Tabletennis_view from '../DemoscoringTournaments/DTournament_d107_Table Tennis_view'
import DTournament_d107_tennis_view from '../DemoscoringTournaments/DTournament_d107_tennis_view'
import DTournament_d107_Squash_view from '../DemoscoringTournaments/DTournament_d107_Squash_view';
import DTournament_d107_Volleyball_view from '../DemoscoringTournaments/DTournament_d107_Volleyball_view'
import DTournament_d107_beachVolleyBall_view from '../DemoscoringTournaments/DTournament_d107_beachVolleyBall_view'
import DTournament_d101_view from '../DemoscoringTournaments/DTournament_d101_view'
import DRoundSelection from '../DemoscoringTournaments/DRoundSelection';
import DTournament_d107_Rugby_view from '../DemoscoringTournaments/DTournament_d107_Rugby_view';
import DTournament_d102view from '../DemoscoringTournaments/DTournament_d102view';
import DTournament_d104view from '../DemoscoringTournaments/DTournament_d104view';
import DTournament_d105view from '../DemoscoringTournaments/DTournament_d105view';
import DTournament_d103_view from '../DemoscoringTournaments/DTournament_d103_view'
import DTournament_d106view from '../DemoscoringTournaments/DTournament_d106view';
import DTournament_d107_Baseball_view from '../DemoscoringTournaments/DTournament_d107_Baseball_view';
import DTournament_d107_Basketball_view from '../DemoscoringTournaments/DTournament_d107_Basketball_view';
import DTournaments_d107_tt2tt3_kabaddi from '../DemoscoringTournaments/DTournaments_d107_tt2tt3_kabaddi';
import DTournaments_d107_tt2tt3_baseball from '../DemoscoringTournaments/DTournaments_d107_tt2tt3_baseball'
import DTournament_basketball_tt23 from '../DemoscoringTournaments/DTournament_basketball_tt23'
import DTournament_rugby_tt23 from '../DemoscoringTournaments/DTournament_rugby_tt23'
import DTournament_d107_Kabbadi_view from '../DemoscoringTournaments/DTournament_d107_Kabbadi_view';
import DTournament_d107_cricket_view from '../DemoscoringTournaments/DTournament_d107_cricket_view';
import DTournament_d107_Archery_view from '../DemoscoringTournaments/DTournament_d107_Archery_view'
import DTournament_d107_Golf_view from '../DemoscoringTournaments/DTournament_d107_Golf_view'
import DTournament_d106_tt2 from '../DemoscoringTournaments/DTournament_d106_tt2';



import DTournament_d106_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_d106_tt1';
import DTournament_d107_tt1Badminton from '../DemoTT1ScoreViewUpdate/DTournament_d107_tt1Badminton'
import DTournament_d107_tt1BeachVolleyBall from '../DemoTT1ScoreViewUpdate/DTournament_d107_tt1BeachVolleyBall'
import DTournament_d107_tt1Squash from '../DemoTT1ScoreViewUpdate/DTournament_d107_tt1Squash'
import DTournament_d107_tt1VolleyBall from '../DemoTT1ScoreViewUpdate/DTournament_d107_tt1VolleyBall'
import DTournament_d107_tt1Carrom from '../DemoTT1ScoreViewUpdate/DTournament_d107_tt1Carrom'
import DTournament_d102_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_d102_tt1'
import DTournament_archery_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_archery_tt1';
import DTournament_Baseball_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_Baseball_tt1';
import DTournament_kabaddi_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_kabaddi_tt1';
import DTournament_basketball_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_basketball_tt1';
import DTournament_tennis_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_tennis_tt1';
import DTournament_tabletennis_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_tabletennis_tt1';
import DTournament_rugby_tt1 from '../DemoTT1ScoreViewUpdate/DTournament_rugby_tt1';


import DTournament_d101_view_TT4 from '../Demott4ScoreViewUpdate/DTournament_d101_view'
import DTournament_d101_update_TT4 from '../Demott4ScoreViewUpdate/DTournament_d101_Update'
import DTournament_d104_Update_tt4 from '../Demott4ScoreViewUpdate/DTournament_d104_Update_tt4'
import DTournament_d103_Update_tt4 from '../Demott4ScoreViewUpdate/DTournament_d103_Update_tt4'
import DTournament_d105_Update_tt4 from '../Demott4ScoreViewUpdate/DTournament_d105_Update_tt4'
import DTournament_d103_view_TT4 from '../Demott4ScoreViewUpdate/DTournament_d103_view_TT4'
import DTournament_d104_view_TT4 from '../Demott4ScoreViewUpdate/DTournament_d104_view_TT4'
import DTournament_d105_view_TT4 from '../Demott4ScoreViewUpdate/DTournament_d105_view_TT4'


import winnerlogo from "../img/winnerlogo.png";
import challengefill from "../img/challengefill.png";
import cancelred from "../img/cancelred.png";
import scheduleMatches from "../img/schedulematches.png";
import confirmation from "../img/confirmation.png";
import challenegbg from "../img/challenegbg.png";
import teamIcon from "../img/team.png";
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import ConfigUrl from '../ConfigUrl';


import DOrganiseMatchCreate from '../DemoTournament/DOrganiseMatchCreate';
import DOrganiseMatchTT1Create from '../DemoTournament/DOrganiseMatchTT1Create';
import DOrganiseMatchTT1List from '../DemoTournament/DOrganiseMatchTT1List';
import DOrganiseMatchTT3Create from '../DemoTournament/DOrganiseMatchTT3Create';
import DOrganiseMatchTT4Create from '../DemoTournament/DOrganiseMatchTT4Create';
import DOrganiseMatchTT4List from '../DemoTournament/DOrganiseMatchTT4List';


import { EmailShareButton } from 'react-share';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectRound;
var scoresArr = [], scoresArr1 = [];
let name, pic;
const { Option } = Select;
class DTournamentProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scoresArr: [],
            scoresArr1: [],
            byScores: '',
            toScores: '',
            thePointsTable: [],
            tt2Matches: [],
            tt3Matches: [],
            pool1Table: [],
            pool2Table: [],
            pool1Matches: [],
            pool2Matches: [],
            tournamentfixture: [],
            tournamentData: {},
            tournamentMode: '',
            usersInTournament: [],
            teamsInTournament: [],

            teamsForInviting: [],
            usersForInviting: [],
            multiTeams: [],
            tickCount: [],
            isTournament_d104_tt5: false,
            isTournament_d107_tt5: false,
            isMiddlesection: true,

            ShowAddCourseForm: false,
            courses: [],
            showRounds: false,
            courseMessage: false,
            roundMessage: false,
            multiTeamsNext: [],
            tickCountForNext: [],
            multiTeamsNextError: null,
            userTeamPoint: [],
            afterOrganiseList: false,
            organiseList: false,

            //TT3
            organiseListTT3: false,
            organiseListTT4: false,
            showOrganisedButton: true,

            tt23Matches: {},
            fType: null,
            matchId: '',
            hideUesrTeamForNextMatch: false,
            showUesrTeamForNextMatch: false,
            matchType: null,
            ShowNextRoundButton: false,
            optionFor: '',

            dropDownUserTeam: [],
            semiBDropDown: [],
            semiCDropDown: [],
            semiDDropDown: [],


            semiAFirstId: '',
            semiASecondId: '',
            semiBFirstId: '',
            semiBSecondId: '',
            semiAFirstName: '',
            semiASecondName: '',
            semiBFirstName: '',
            semiBSecondName: '',

            ShowSaveSemi: false,


            // update score states
            isTournament_d106_tt2: false,
            isTournament_d104_tt5: false,
            isTournament_d107_tt5: false,
            isTournament_d107_tt2_badminton: false,
            isTournament_d107_tt2_Squash: false,
            isTournament_d107_VolleyBall_tt23: false,
            isTournament_d107_BeachVolleyball_tt23: false,
            isTournament_d107_Carrom_tt23: false,
            Tournaments_d107_tt2tt3_kabaddi: false,
            isTournament_d107_tt2_tableTennis: false,
            isTournament_d107_tt2_Tennis: false,

            // view score states


            Tournament_d107_Rugby_view: false,
            Tournament_d106view: false,
            Tournament_d107_Baseball_view: false,
            Tournament_d107_Kabbadi_view: false,
            Tournament_d107_cricket_view: false,
            Tournament_d107_Archery_view: false,
            Tournament_d102view: false,
            Tournament_d104view: false,
            Tournament_d105view: false,

            Tournament_d107_badminton_view: false,
            Tournament_d107_Carrom_view: false,
            Tournament_d107_Tabletennis_view: false,
            Tournament_d107_tennis_view: false,
            Tournament_d107_Squash_view: false,
            Tournament_d107_Volleyball_view: false,
            Tournament_d107_beachVolleyBall_view: false,
            Tournament_d101_view: false,
            Tournament_d103_view: false,
            Tournament_d107_Golf_view: false,

            showQuarterFinal: false,
            showSemiFinal: false,
            showFinal: false,

        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }

    componentDidMount() {
        this.getTourProfile();
        this.getPointsTable();
    }

    getPointsTable = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        const getTour = rshApi + "/demo-tournaments/get-points-table";
        const that = this;

        fetch(getTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== "No points table found for given tournamentId") {
                    that.setState({
                        thePointsTable: result.data.pointsList,
                    });
                }

            });
    }
    checkSportName = (c_ID, TType, matchId, fType) => {
        this.setState({ matchId: matchId, fType: fType })
        if (TType === 4) {
            if (c_ID === 'D101') {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d101_update_TT4: true, matchTypeClicked: fType, clickedIndex: matchId });
            }
            if (c_ID === 'D103') {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d103_Update_tt4: true, matchTypeClicked: fType, clickedIndex: matchId });
            }
            if (c_ID === 'D104') {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d104_Update_tt4: true, matchTypeClicked: fType, clickedIndex: matchId });
            }

            if (c_ID === 'D105') {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d105_Update_tt4: true, matchTypeClicked: fType, clickedIndex: matchId });
            }

        }

        if (TType === 5) {
            if (c_ID === 'D104') {
                this.setState({ isMiddlesection: false, isDTournament_d104_tt5: true })
            }
            if (c_ID === 'D107') {
                this.setState({ isMiddlesection: false, isDTournament_d107_tt5: true })
            }
        }
        if (TType === 2) {
            if (c_ID === 'D107') {
                if (this.state.sportsName === "Table Tennis") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_tableTennis: true })
                }
                if (this.state.sportsName === "Kabbadi") {
                    this.setState({ isMiddlesection: false, DTournaments_d107_tt2tt3_kabaddi: true })
                }
                if (this.state.sportsName === "Baseball") {
                    this.setState({ isMiddlesection: false, DTournaments_d107_tt2tt3_baseball: true })
                }

                if (this.state.sportsName === "Badminton") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_badminton: true })
                }

                if (this.state.sportsName === "Squash") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_Squash: true })

                }
                if (this.state.sportsName === "Volleyball") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_VolleyBall_tt23: true })

                }
                if (this.state.sportsName === "Beach Volleyball") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_BeachVolleyball_tt23: true })

                }
                if (this.state.sportsName === "Carrom Board") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_Carrom_tt23: true })
                }
                if (this.state.sportsName === "Rugby") {
                    this.setState({ isMiddlesection: false, DTournament_rugby_tt23: true })
                }
                if (this.state.sportsName === "Basketball") {
                    this.setState({ isMiddlesection: false, DTournament_basketball_tt23: true })
                }

            }
            if (c_ID === 'D106') {
                this.setState({ isMiddlesection: false, isDTournament_d106_tt2: true, })
            }
        }
        if (TType === 3) {
            if (c_ID === 'D107') {
                if (this.state.sportsName === "Table Tennis") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_tableTennis: true })
                }
                if (this.state.sportsName === "Badminton") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_badminton: true })
                }
                if (this.state.sportsName === "Basketball") {
                    this.setState({ isMiddlesection: false, DTournament_basketball_tt23: true })
                }
                if (this.state.sportsName === "Rugby") {
                    this.setState({ isMiddlesection: false, DTournament_rugby_tt23: true })
                }
                if (this.state.sportsName === "Volleyball") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_VolleyBall_tt23: true })
                }
                if (this.state.sportsName === "Beach Volleyball") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_BeachVolleyball_tt23: true })
                }
                if (this.state.sportsName === "Squash") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_tt2_Squash: true })
                }
                if (this.state.sportsName === "Kabbadi") {
                    this.setState({ isMiddlesection: false, DTournaments_d107_tt2tt3_kabaddi: true })
                }
                if (this.state.sportsName === "Baseball") {
                    this.setState({ isMiddlesection: false, DTournaments_d107_tt2tt3_baseball: true })
                }
                if (this.state.sportsName === "Carrom Board") {
                    this.setState({ isMiddlesection: false, isDTournament_d107_Carrom_tt23: true })
                }

            }
            if (c_ID === 'D106') {
                this.setState({ isMiddlesection: false, isDTournament_d106_tt2: true, })
            }
        }
        if (TType === 1) {

            if (c_ID === 'D106') {
                this.setState({ isMiddlesection: false, isDTournament_d106_tt1: true, })
            }
            if (c_ID === 'D107') {
                if (this.state.sportsName === "Archery") {
                    this.setState({ isMiddlesection: false, isDTournament_archery_tt1: true })
                }
                if (this.state.sportsName === "Baseball") {
                    this.setState({ isMiddlesection: false, isDTournament_Baseball_tt1: true })
                }
                if (this.state.sportsName === "Kabbadi") {
                    this.setState({ isMiddlesection: false, isDTournament_kabaddi_tt1: true })
                }
                if (this.state.sportsName === "Basketball") {
                    this.setState({ isMiddlesection: false, isDTournament_basketball_tt1: true })
                }
                if (this.state.sportsName === "Tennis") {
                    this.setState({ isMiddlesection: false, isDTournament_tennis_tt1: true })
                }
                if (this.state.sportsName === "Table Tennis") {
                    this.setState({ isMiddlesection: false, isDTournament_tabletennis_tt1: true })
                }
                if (this.state.sportsName === "Rugby") {
                    this.setState({ isMiddlesection: false, isDTournament_rugby_tt1: true })
                }





            }


            if (c_ID === "D102") {
                this.setState({ isMiddlesection: false, DTournament_d102_tt1: true, })
            }
            if (c_ID === 'D107') {
                if (this.state.sportsName === "Badminton") {
                    this.setState({ isMiddlesection: false, DTournament_d107_tt1Badminton: true, })
                }
                if (this.state.sportsName === "Volleyball") {
                    this.setState({ isMiddlesection: false, DTournament_d107_tt1VolleyBall: true, })

                }
                if (this.state.sportsName === "Beach Volleyball") {
                    this.setState({ isMiddlesection: false, DTournament_d107_tt1BeachVolleyBall: true, })

                }
                if (this.state.sportsName === "Squash") {
                    this.setState({ isMiddlesection: false, DTournament_d107_tt1Squash: true, })

                }
                if (this.state.sportsName === "Carrom Board") {
                    this.setState({ isMiddlesection: false, DTournament_d107_tt1Carrom: true, })

                }

            }

        }
    }

    //showing scores in completed matches
    getScoreShowInCompleteMatches = () => {
        const that = this;

        if (that.state.tournamentData.tournamentType === 2) {

            that.state.tt2Matches.map(i => {

                var urlParams = new URLSearchParams(window.location.search);
                let tourid = urlParams.get("id");
                const scores = rshApi + "/demo-tournaments/get-score";

                fetch(scores, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "tournamentId": tourid,
                            "matchId": i.matchId
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        //console.log(result);
                        if (result.data.score !== null) {
                            if (result.data.score.matchId === i.matchId) {
                                that.setState({ byIDs: result.data.score.matchId })
                                if (that.state.tournamentData.categoryId === "D106") {

                                    if (result.data.score.d106ScoreCard.byTeamScoreCard !== null) {
                                        that.setState({
                                            byScores: result.data.score.d106ScoreCard.byTeamScoreCard.teamGoals,
                                            byIDs: result.data.score.matchId
                                        })
                                    }

                                    var scoresObj = {
                                        byGol: that.state.byScores,
                                        byId: that.state.byIDs
                                    }
                                    scoresArr.push(scoresObj);
                                    that.setState({ scoresArr: scoresArr })

                                    if (result.data.score.d106ScoreCard.toTeamScoreCard !== null) {
                                        that.setState({
                                            toScores: result.data.score.d106ScoreCard.toTeamScoreCard.teamGoals,
                                            toIDs: result.data.score.matchId
                                        })
                                    }

                                    var scoresObj1 = {
                                        toGol: that.state.toScores,
                                        toId: that.state.toIDs
                                    }
                                    scoresArr1.push(scoresObj1);
                                    that.setState({ scoresArr1: scoresArr1 })
                                }

                            }
                        }
                    });

            })

        }

    }
    oganizedMatch = () => {
        const getTour = rshApi + "/demo-tournaments/get-tournament";
        const that = this;
        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        fetch(getTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({ tournament: result.data.tournament })
                if (result.data.tournament.tournamentType === 2) {
                    if (result.data.tournament.accepted !== null) {
                        if (result.data.tournament.accepted.length > 4) {
                            that.setState({ organiseList: true, afterOrganiseList: false });
                        }
                    }
                }
                else if (result.data.tournament.tournamentType === 3) {
                    if (result.data.tournament.accepted !== null) {
                        if (result.data.tournament.accepted.length > 5) {
                            that.setState({ organiseListTT3: true, afterOrganiseList: false });
                        }
                    }
                }
                else if (result.data.tournament.tournamentType === 4) {
                    if (result.data.tournament.accepted !== null) {

                        if (result.data.tournament.sportsName == '4x400 meter relay' || result.data.tournament.sportsName == 'Swimming' || result.data.tournament.sportsName == '4x100 m Hurdle' || result.data.tournament.sportsName == '200 meter' || result.data.tournament.sportsName == '400 meter' || result.data.tournament.sportsName == '110 meters hurdle' || result.data.tournament.sportsName == '100 meters hurdle' || result.data.tournament.sportsName == '100 meter' || result.data.tournament.sportsName == 'Roller Skating') {
                            if (result.data.tournament.accepted.length < 12) {
                                message.info('Minimum 12 players/teams required for organise TT4');
                            }
                            else if (result.data.tournament.accepted.length > 16 && result.data.tournament.accepted.length < 24) {
                                message.info('Insufficient players for 4 pools and ' + (result.data.tournament.accepted.length - 16) + 'players Extra for 2 pools to organise matches');
                            }
                            else if (result.data.tournament.accepted.length > 32 && result.data.tournament.accepted.length < 48) {
                                message.info('Insufficient players for 8 pools and ' + (result.data.tournament.accepted.length - 32) + 'players Extra for 4 pools to organise matches');
                            }
                            else {
                                that.setState({ organiseListTT4: true, afterOrganiseList: false });
                            }
                        }
                        else {
                            if (result.data.tournament.accepted.length < 10) {
                                message.info('Insufficient players to organise matches.');
                            }
                            else if (result.data.tournament.accepted.length > 160) {
                                message.info('160 players is max limit to organise matches.');
                            }
                            else {
                                that.setState({ organiseListTT4: true, afterOrganiseList: false });
                            }
                        }
                    }
                }
                else if (result.data.tournament.tournamentType === 1) {
                    if (result.data.tournament.accepted !== null) {
                        that.setState({ organiseListTT4: true, afterOrganiseList: false });
                    }
                }
            });
    }

    afterOrganiseList = () => {
        this.setState({ afterOrganiseList: true, organiseList: false })
        this.getTourProfile();
    }

    viewTourScore = (categoryId, ttType, mid) => {

        this.setState({ matchId: mid, byIDs: mid });
        if (categoryId === "D107") {
            if (this.state.sportsName === "Badminton") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_badminton_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Carrom Board") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Carrom_view: true });
            }
        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Table Tennis") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Tabletennis_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Squash") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Squash_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Tennis") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_tennis_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Volleyball") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Volleyball_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Beach Volleyball") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_beachVolleyBall_view: true });
            }

        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Golf") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Golf_view: true });
            }

        }

        if (this.state.afterOrganiseListForTT4) {
            if (categoryId === "D101") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d101_view_TT4: true, matchTypeClicked: mid, clickedIndex: ttType });
            }
            if (categoryId === "D103") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d103_view_TT4: true, matchTypeClicked: mid, clickedIndex: ttType });
            }
            if (categoryId === "D104") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d104_view_TT4: true, matchTypeClicked: mid, clickedIndex: ttType });
            }
            if (categoryId === "D105") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d105_view_TT4: true, matchTypeClicked: mid, clickedIndex: ttType });
            }
        } else {
            if (categoryId === "D101") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d101_view: true });
            }
            if (categoryId === "D103") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d103_view: true });
            }
            if (categoryId === "D104") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d104view: true });
            }
            if (categoryId === "D105") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d105view: true });
            }
        }


        if (categoryId === "D107") {
            if (this.state.sportsName === "Rugby") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Rugby_view: true });
            }

        }

        if (categoryId === "D102") {

            this.setState({ isMiddlesection: false });
            this.setState({ DTournament_d102view: true });
        }

        if (categoryId === "D106") {

            this.setState({ isMiddlesection: false });
            this.setState({ DTournament_d106view: true });
        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Baseball") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Baseball_view: true });
            }
        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Basketball") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Basketball_view: true });
            }
        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Kabbadi") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Kabbadi_view: true });
            }
        }
        if (categoryId === "D107") {
            if (this.state.sportsName === "Cricket") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_cricket_view: true });
            }
        }

        if (categoryId === "D107") {
            if (this.state.sportsName === "Archery") {
                this.setState({ isMiddlesection: false });
                this.setState({ DTournament_d107_Archery_view: true });
            }

        }
    }
    openOptionForNextRound() {
        let point = [...this.state.thePointsTable];
        let user = [...this.state.usersInTournament];
        let userTeamPoint = [];
        point.map(point => {
            let pointDetail = {};
            user.map(user => {
                if (point.teamId === user.contactNo) {
                    pointDetail.name = user.name;
                    pointDetail.userId = user.contactNo;
                    pointDetail.name = user.name;
                    pointDetail.gender = user.gender;
                    pointDetail.contactNo = user.contactNo;
                    pointDetail.profilePic = user.profilePic;
                    pointDetail.totalPoints = point.totalPoints;
                }
            })
            userTeamPoint.push(pointDetail)
        })
        this.setState({ userTeamPoint: userTeamPoint })


        if (this.state.optionFor === 'Final') {
            this.setState({ showUesrTeamForNextMatch: true })

        }
        if (this.state.optionFor === 'Semi final') {
            this.setState({ showUesrTeamForNextMatch: true, ShowSaveSemi: true })

        }
    }
    getTourProfile = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        const getTour = rshApi + "/demo-tournaments/get-tournament";
        const that = this;

        fetch(getTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.score !== null) {
                    that.setState({
                        tt23Matches: result.data.tt23Matches,
                        tournamentfixture: result.data.fixture,
                        tournamentData: result.data.tournament,
                        tournamentMode: result.data.tournament.tournamentMode,
                        usersInTournament: result.data.users,
                        teamsInTournament: result.data.teams,
                        sportsName: result.data.tournament.sportsName,

                    });
                } else {

                }

                if (result.data.tt23Matches) {
                    if (result.data.tt23Matches.ttType === 2) {
                        that.setState({ tt2Matches: result.data.tt23Matches.tt2Matches, matchType: result.data.tournament.matchType })
                        var resL = myUtilclass.getStatusOfNextRound(1, result.data.tt23Matches.tt2Matches)

                        if (result.data.tournament.matchType === 'Quarter final') {

                        }
                        if (result.data.tournament.matchType === 'Semi final') {
                            var resF = myUtilclass.getStatusOfNextRound(3, result.data.tt23Matches.semiFinals)

                            if (resL === true) {
                                if (resF === false) {
                                    that.setState({ ShowNextRoundButton: true, optionFor: 'Semi final' });
                                }
                                else {
                                    var resF = myUtilclass.getStatusOfNextRound(4, result.data.tt23Matches.finals)
                                    if (resF) {
                                        that.setState({ showSemiFinal: true, showFinal: true });
                                    }
                                    else {
                                        that.setState({ showSemiFinal: true, ShowNextRoundButton: true, optionFor: 'Final' });
                                    }

                                }
                            }
                        }
                        if (result.data.tournament.matchType === 'Final') {
                            var resF = myUtilclass.getStatusOfNextRound(4, result.data.tt23Matches.finals)
                            if (resL === true) {
                                if (resF === false) {
                                    that.setState({ ShowNextRoundButton: true, optionFor: 'Final' });
                                }
                            }
                        }
                        that.getScoreShowInCompleteMatches();
                    }

                    if (result.data.tt23Matches.ttType === 3) {
                        that.setState({ tt3Matches: result.data.tt23Matches.tt3Matches })
                        that.state.tt3Matches.map(p => {
                            if (p.poolNo === 1) {
                                that.setState({ pool1Table: p.pointsTable.pointsList, pool1Matches: p.matches })
                            }
                            if (p.poolNo === 2) {
                                that.setState({ pool2Table: p.pointsTable.pointsList, pool2Matches: p.matches })
                            }
                        })
                    }


                    if (result.data.tt23Matches.tt3Matches) {
                        that.setState({ afterOrganiseListForTT3: true })
                    }
                    if (result.data.tt23Matches === null) {
                        that.setState({ afterOrganiseList: false })
                    }
                    else {
                        that.setState({ afterOrganiseList: true })
                    }
                }

                if (result.data.tournament.tournamentType === 1) {
                    if (result.data.fixture) {
                        that.setState({
                            afterOrganiseListForTT1: true, tournamentMode: result.data.tournament.tournamentMode,
                            usersInTournament: result.data.users,
                            teamsInTournament: result.data.teams, tt1Matches: result.data.fixture, showOrganisedButton: false
                        })
                    }
                    else {
                        that.setState({
                            showCreateForTT1: true, tournamentMode: result.data.tournament.tournamentMode,
                            usersInTournament: result.data.users,
                            teamsInTournament: result.data.teams, tt1Matches: result.data.fixture, showOrganisedButton: false
                        })
                    }
                }
                if (result.data.tt4Matches) {
                    that.setState({ afterOrganiseListForTT4: true, tt4Matches: result.data.tt4Matches, showOrganisedButton: false })
                }
            });
    }

    //invite teams API
    inviteTeams = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        const getTour = rshApi + "/demo-tournaments/get-invites";
        const that = this;

        fetch(getTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result);

                that.setState({
                    teamsForInviting: result.data.teams,
                    usersForInviting: result.data.users,
                });
            });
    }

    //code to select for team invite
    saveUserTeamForNext = (event) => {
        let checkedArray = this.state.multiTeamsNext;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                multiTeamsNext: checkedArray,
                tickCountForNext: checkedArray.length,
            });

        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiTeamsNext: checkedArray,
                tickCountForNext: checkedArray.length,
            });
        }
    }
    inviteTeamsChecking = (event) => {
        let checkedArray = this.state.multiTeams;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                multiTeams: checkedArray,
                tickCount: checkedArray.length,
            });

        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiTeams: checkedArray,
                tickCount: checkedArray.length,
            });
        }
        console.log(this.state.multiTeams)
    }

    childTTcallback = () => {
        this.setState({ isMiddlesection: true, isTournament_d104_tt5: false })
    }

    //sending selected teams for invitation
    sendTeamsInvitation = (mode) => {
        // var tourData = that.state.tournamentData;
        // var newlyArray = tourData.invited.concat(that.state.multiTeams);
        // tourData.invited = newlyArray;

        const that = this;
        var tourData = that.state.multiTeams;
        console.log(tourData)

        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        const updateTour = rshApi + "/demo-tournaments/tournament-invites";
        fetch(updateTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid,
                    "type": mode,
                    "inviteIds": tourData
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result);
                that.getTourProfile();
            });
    }

    //code to take confirmation details about deleting Team/users
    takeRemoveDetail = (takeId, takeName) => {
        const that = this;
        that.setState({
            takeId: takeId,
            takeName: takeName,
        });
    }

    //code to delete accepted teams/users
    removeAccepted = (removeId) => {
        // var tourData = that.state.tournamentData;
        // const newlyArray = tourData.accepted.indexOf(removeId);
        // if (newlyArray > -1) {
        //     var extractedArray = tourData.accepted.splice(newlyArray, 1);
        // }
        // tourData.accepted = extractedArray;
        const that = this;
        console.log(removeId)

        //delete tourData.id;

        var urlParams = new URLSearchParams(window.location.search);
        let tourid = urlParams.get("id");
        const updateTour = rshApi + "/demo-tournaments/remove-accepted";
        fetch(updateTour, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tourid,
                    "idToRemove": removeId
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.getTourProfile();
            });
    }


    chooseCourse(courseID) {
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var tournament = this.state.tournamentData;
        delete tournament.id;
        tournament.courseId = courseID;
        tournament.courseSelected = true;
        tournament.modifiedOn = timpstampData;

        let that = this;
        const apiUrl = rshApi + "/demo-tournaments/update-tournament";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournament": tournament,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)

                that.setState({ CourseSelected: false, showRounds: true, showCoursesList: false });

            });
    }
    showRound = () => {
        this.setState({ showRounds: true, isMiddlesection: false, });
    }
    childRoundCall = (obj) => {
        this.setState({ tournamentData: obj, showRounds: false, selectRound: false, isMiddlesection: true });
    }
    childCourseCall = (obj) => {
        let courseList = [...this.state.courses];
        courseList.push(obj);
        this.setState({ ShowAddCourseForm: false, isMiddlesection: false, showCoursesList: true })
        this.setState({ courses: courseList });
    }

    selectCourse() {
        this.setState({ ShowAddCourseForm: false, showCoursesList: true, isMiddlesection: false });
        const apiUrl = rshApi + "/global/get-courses";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    createdBy: that.state.user.contactNo,
                },
            }),
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== null) {
                    that.setState({ courses: result.data });
                }
            })

    }

    AddCourse() {
        this.setState({ showCoursesList: false, challengeProfileCenter: false, ShowAddCourseForm: true });
    }
    saveTeamUserForMatch() {

        let dropDownUserTeam = [];


        if (this.state.tournamentData.tournamentMode === 'team') {
            this.state.multiTeamsNext.map(select => {
                this.state.teamsInTournament.map(team => {
                    if (select === team.teamId) {
                        let dropDownUserTeamObj = {};
                        dropDownUserTeamObj.id = team.teamId;
                        dropDownUserTeamObj.name = team.teamName;
                        dropDownUserTeam.push(dropDownUserTeamObj);
                    }
                })
            })
        }
        else {
            console.log(this.state.usersInTournament);
            console.log(this.state.multiTeamsNext);
            this.state.multiTeamsNext.map(ids => {
                this.state.usersInTournament.map(user => {
                    if (ids === user.contactNo) {
                        let dropDownUserTeamObj = {};
                        dropDownUserTeamObj.id = user.contactNo;
                        dropDownUserTeamObj.name = user.name;
                        dropDownUserTeam.push(dropDownUserTeamObj);
                    }

                })

            })
        }
        dropDownUserTeam = [{ id: 'TBD', name: 'TBD' }, ...dropDownUserTeam]
        this.setState({ dropDownUserTeam: dropDownUserTeam });


        if (this.state.optionFor === 'Final') {
            let multiTeamsNext = [...this.state.multiTeamsNext];
            let tt23Matches = this.state.tt23Matches;
            delete tt23Matches.id;
            tt23Matches.finals.firstId = multiTeamsNext[0];
            tt23Matches.finals.secondId = multiTeamsNext[1];

            if (multiTeamsNext.length === 2) {
                this.setState({ multiTeamsNextError: null });
                var updateOrganize = myTournamentsUtil.updateOrganizeTT23(this.state.tournamentData.tournamentId, tt23Matches);
                updateOrganize.then(result => {
                    console.log(result)
                    this.setState({ showUesrTeamForNextMatch: false, showFinal: true, ShowNextRoundButton: false });
                });
            }
            else {
                this.setState({ multiTeamsNextError: "please Select 2 Player only" });
            }
        }
        if (this.state.optionFor === 'Semi final') {
            let multiTeamsNext = [...this.state.multiTeamsNext];
            let tt23Matches = this.state.tt23Matches;
            delete tt23Matches.id;
            // tt23Matches.finals.firstId = multiTeamsNext[0];
            // tt23Matches.finals.secondId = multiTeamsNext[1];

            if (multiTeamsNext.length === 4) {
                this.setState({ multiTeamsNextError: null, showUesrTeamForNextMatch: false, showSemiFinal: true, ShowNextRoundButton: false });
                // var updateOrganize = myTournamentsUtil.updateOrganizeTT23(this.state.tournamentData.tournamentId, tt23Matches);
                // updateOrganize.then(result => {
                //      console.log(result)
                //   });
            }
            else {
                this.setState({ multiTeamsNextError: "please Select 4 Player only" });
            }
        }
    }

    semi1Dropdown = (event) => {
        let dropDownlist = [...this.state.dropDownUserTeam];

        if (event.target.value !== 'TBD') {
            this.setState({ semiAFirstId: event.target.value })
            var filtered = dropDownlist.filter(function (el) { return el.id != event.target.value; });
            this.setState({ semiBDropDown: filtered })
        }
        else {
            this.setState({ semiBDropDown: [], semiCDropDown: [], semiDDropDown: [] })
        }

    }
    semi2Dropdown = (event) => {
        let dropDownlist = [...this.state.semiBDropDown];
        if (event.target.value !== 'TBD') {
            this.setState({ semiASecondId: event.target.value })
            var filtered = dropDownlist.filter(function (el) { return el.id != event.target.value; });
            this.setState({ semiCDropDown: filtered })
        }
        else {
            this.setState({ semiCDropDown: [], semiDDropDown: [] })
        }

    }
    semi3Dropdown = (event) => {
        let dropDownlist = [...this.state.semiCDropDown];
        if (event.target.value !== 'TBD') {
            this.setState({ semiBFirstId: event.target.value })
            var filtered = dropDownlist.filter(function (el) { return el.id != event.target.value; });
            this.setState({ semiDDropDown: filtered })
        }
        else {
            this.setState({ semiDDropDown: [] })
        }

    }
    semi4Dropdown = (event) => {
        let dropDownlist = [...this.state.semiDDropDown];
        this.setState({ semiBSecondId: event.target.value })
        // if (event.target.value !== 'TBD') {
        //     var filtered = dropDownlist.filter(function (el) { return el.id != event.target.value; });
        //     this.setState({ semiBDropDown: filtered })
        // }
        // else {
        //     this.setState({ semiDDropDown: [] })
        // }

    }

    saveSemiFinal = () => {
        let organise = this.state.tt23Matches;
        organise.semiFinals[0].firstId = this.state.semiAFirstId;
        organise.semiFinals[0].secondId = this.state.semiASecondId;
        organise.semiFinals[1].firstId = this.state.semiBFirstId;
        organise.semiFinals[1].secondId = this.state.semiBSecondId;
        organise.semiFinals[0].matchId = this.state.tournamentData.tournamentId + '-' + this.state.semiAFirstId + '-' + this.state.semiASecondId + '--2';
        organise.semiFinals[1].matchId = this.state.tournamentData.tournamentId + '-' + this.state.semiBFirstId + '-' + this.state.semiBSecondId + '--2';

        let updateOrganize = myTournamentsUtil.updateOrganizeTT23(this.state.tournamentData.tournamentId, organise);
        updateOrganize.then(result => {
            console.log(result)
            this.setState({ showUesrTeamForNextMatch: false, showSemiFinal: true, ShowSaveSemi: false });
        });

    }



    // this.closeTeamUserForMatch();

    closeTeamUserForMatch() {
        this.setState({
            showUesrTeamForNextMatch: false, tickCountForNext: 0, multiTeamsNext: []
        });
    }

    viewTT4Child = (obj) => {
        this.viewTourScore(obj.cId, obj.ttType, obj.mid)

    }
    updateTT4Child = (obj) => {
        this.checkSportName(obj.c_ID, obj.TType, obj.matchId, obj.fType)
    }
    viewTT1Child = (obj) => {
        this.viewTourScore(obj.cId, obj.ttType, obj.mid)
        this.setState({ matchId: obj.mid, byIDs: obj.mid })
    }
    updateTT1Child = (obj) => {
        this.checkSportName(obj.c_ID, obj.TType, obj.matchId, obj.fType)
    }
    render() {
        //console.log(this.state.tournamentData)
        const acceptedList = this.state.tournamentData.accepted ? this.state.tournamentData.accepted : [];
        const invitedList = this.state.tournamentData.invited ? this.state.tournamentData.invited : [];

        var CourseSelected = false;
        var RoundSelected = false;

        if (this.state.tournamentData.categoryId === 'D107') {
            if (this.state.tournamentData.sportsName === "Golf") {
                if (this.state.tournamentData.courseSelected) {
                    if (this.state.tournamentData.courseRound !== 0) {

                        RoundSelected = false;
                    }
                    else {
                        if (this.state.tournamentData.createdBy === this.state.user.contactNo) {

                            RoundSelected = true;
                        }

                    }
                }
                else {
                    if (this.state.tournamentData.createdBy === this.state.user.contactNo) {
                        CourseSelected = true;

                    }

                }
            }
        }
        let semiAFirstName, semiASecondName, semiBFirstName, semiBSecondName, semiAEnded = true, semiBEnded = true, semiAmatchId, semiBmatchId;
        if (this.state.showSemiFinal) {
            if (this.state.tt23Matches.semiFinals[0].ended === false) {
                semiAEnded = false;
            }
            if (this.state.tt23Matches.semiFinals[1].ended === false) {
                semiBEnded = false;
            }
            if (this.state.tt23Matches.semiFinals[0].firstId !== null) {
                semiAmatchId = this.state.tt23Matches.semiFinals[0].matchId;
                if (this.state.tournamentData.tournamentMode === 'team') {
                    this.state.teamsInTournament.map(b => {
                        if (this.state.tt23Matches.semiFinals[0].firstId === b.teamId) {
                            semiAFirstName = b.teamName
                        }
                        if (this.state.tt23Matches.semiFinals[0].secondId === b.teamId) {
                            semiASecondName = b.teamName
                        }
                        if (this.state.tt23Matches.semiFinals[1].firstId === b.teamId) {
                            semiBFirstName = b.teamName
                        }
                        if (this.state.tt23Matches.semiFinals[1].secondId === b.teamId) {
                            semiBSecondName = b.teamName
                        }
                    })

                }
                else {
                    semiBmatchId = this.state.tt23Matches.semiFinals[1].matchId;
                    this.state.usersInTournament.map(b => {
                        if (this.state.tt23Matches.semiFinals[0].firstId === b.contactNo) {
                            semiAFirstName = b.name
                        }
                        if (this.state.tt23Matches.semiFinals[0].secondId === b.contactNo) {
                            semiASecondName = b.name
                        }
                        if (this.state.tt23Matches.semiFinals[1].firstId === b.contactNo) {
                            semiBFirstName = b.name
                        }
                        if (this.state.tt23Matches.semiFinals[1].secondId === b.contactNo) {
                            semiBSecondName = b.name
                        }
                    })
                }
            }
        }
        let finalFirstName, finalSecondName, finalEnded = true, finalmatchId;
        if (this.state.showFinal) {
            if (this.state.tt23Matches.finals.ended === false) {
                finalEnded = false;
            }

            if (this.state.tt23Matches.finals.firstId !== null) {
                finalmatchId = this.state.tt23Matches.finals.matchId;
                if (this.state.tournamentData.tournamentMode === 'team') {
                    this.state.teamsInTournament.map(b => {
                        if (this.state.tt23Matches.finals.firstId === b.teamId) {
                            finalFirstName = b.teamName
                        }
                        if (this.state.tt23Matches.finals.secondId === b.teamId) {
                            finalSecondName = b.teamName
                        }
                    })

                }
                else {
                    finalmatchId = this.state.tt23Matches.finals.matchId;
                    this.state.usersInTournament.map(b => {
                        if (this.state.tt23Matches.finals.firstId === b.contactNo) {
                            finalFirstName = b.name
                        }
                        if (this.state.tt23Matches.finals.secondId === b.contactNo) {
                            finalSecondName = b.name
                        }

                    })
                }
            }


        }







        return (
            <React.Fragment>

                {/* Modal For Next Round Match  */}
                <Modal
                    size="md"
                    show={this.state.showUesrTeamForNextMatch}
                    animation={false}
                    onHide={() => this.closeTeamUserForMatch()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeTeamUserForMatch()}>&times;</button>
                        <Modal.Title id="example-modal-sizes-title-md">
                            Select Buddies
                    </Modal.Title>
                    </Modal.Header>
                    <div className="modal-body">

                        <div className="ct_searchbuddies">

                        </div>

                        {(() => {
                            if (this.state.tournamentData.tournamentMode === 'buddy') {
                                return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCountForNext} Buddies</label>
                                    <label>{this.state.multiTeamsNextError} </label>
                                </label>
                            }
                            else {
                                return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCountForNext} Teams</label>
                                    <label>{this.state.multiTeamsNextError} </label>
                                </label>
                            }
                        })()}

                        <ul className="buddieslist_ul ct_buddies">

                            {
                                this.state.userTeamPoint.map((items, index2) => {
                                    let pic = items.profilePic ? items.profilePic : items.gender ? maleIcon : femaleIcon;
                                    var disableData1 = false;
                                    invitedList.map(i => {
                                        if (i === items.teamId) {
                                            disableData1 = true;
                                        }
                                        return true;
                                    })
                                    return <li className="deselect" key={index2}>
                                        <a href="">
                                            <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                            <label>{items.name}</label>
                                            <label>point- {items.totalPoints}</label>
                                        </a>
                                        <div className="styled-input-single">
                                            <input onChange={this.saveUserTeamForNext.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                            <label htmlFor={"checkBox" + index2}></label>
                                        </div>
                                    </li>
                                })
                            }

                        </ul>
                        <div className="modal-footer">
                            <button type="button" onClick={() => this.saveTeamUserForMatch()} className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>




                {/* Modal content to add team/players */}
                <div id="team_addplayers" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Add Teams</h4>
                            </div>

                            <div className="modal-body">

                                <div className="ct_searchbuddies">
                                    {/* <form>
                                        <input onChange={this.searchHandler1} value={term1} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form> */}
                                </div>

                                {(() => {
                                    if (this.state.tournamentData.tournamentMode === 'buddy') {
                                        return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCount} Buddies</label></label>
                                    }
                                    else {
                                        return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCount} Teams</label></label>
                                    }
                                })()}

                                <ul className="buddieslist_ul ct_buddies">
                                    {(this.state.tournamentMode === 'buddy') ? <React.Fragment>
                                        {
                                            this.state.usersForInviting.map((items, index2) => {

                                                var disableData1 = false;
                                                invitedList.map(i => {
                                                    if (i === items.contactNo) {
                                                        disableData1 = true;
                                                    }
                                                    return true;
                                                })
                                                if (disableData1 === true) {
                                                    if (items.profilePic === null) {
                                                        if (items.gender === 'Male') {
                                                            return <li className="deselect" key={index2}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                    <label>{items.name}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input disabled onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                    <label htmlFor={"checkBox" + index2}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                        else {
                                                            return <li className="deselect" key={index2}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                    <label>{items.name}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input disabled onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                    <label htmlFor={"checkBox" + index2}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    }
                                                    else {
                                                        return <li className="deselect" key={index2}>
                                                            <Link to={"#"}>
                                                                <span style={{ backgroundImage: "url(" + items.profilePic + ")" }}></span>
                                                                <label>{items.name}</label>
                                                            </Link>
                                                            <div className="styled-input-single">
                                                                <input disabled onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                <label htmlFor={"checkBox" + index2}></label>
                                                            </div>
                                                        </li>
                                                    }
                                                }
                                                else {
                                                    if (items.profilePic === null) {
                                                        if (items.gender === 'Male') {
                                                            return <li className="deselect" key={index2}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                    <label>{items.name}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                    <label htmlFor={"checkBox" + index2}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                        else {
                                                            return <li className="deselect" key={index2}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                    <label>{items.name}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                    <label htmlFor={"checkBox" + index2}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    }
                                                    else {
                                                        return <li className="deselect" key={index2}>
                                                            <Link to={"#"}>
                                                                <span style={{ backgroundImage: "url(" + items.profilePic + ")" }}></span>
                                                                <label>{items.name}</label>
                                                            </Link>
                                                            <div className="styled-input-single">
                                                                <input onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.contactNo} />

                                                                <label htmlFor={"checkBox" + index2}></label>
                                                            </div>
                                                        </li>
                                                    }
                                                }
                                            })
                                        }
                                    </React.Fragment> : <React.Fragment>
                                            {
                                                this.state.teamsForInviting.map((item, index1) => {

                                                    var disableData = false;
                                                    invitedList.map(i => {
                                                        if (i === item.teamId) {
                                                            disableData = true;
                                                        }
                                                        return true;
                                                    })
                                                    if (disableData === true) {
                                                        if (item.teamIcon === null) {
                                                            return <li className="deselect" key={index1}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                    <label>{item.teamName}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input disabled onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.teamId} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                        else {
                                                            return <li className="deselect" key={index1}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + item.teamIcon + ")" }}></span>
                                                                    <label>{item.teamName}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input disabled onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.teamId} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    }
                                                    else {
                                                        if (item.teamIcon === null) {
                                                            return <li className="deselect" key={index1}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                    <label>{item.teamName}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.teamId} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                        else {
                                                            return <li className="deselect" key={index1}>
                                                                <Link to={"#"}>
                                                                    <span style={{ backgroundImage: "url(" + item.teamIcon + ")" }}></span>
                                                                    <label>{item.teamName}</label>
                                                                </Link>
                                                                <div className="styled-input-single">
                                                                    <input onChange={this.inviteTeamsChecking.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.teamId} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    }
                                                })
                                            }
                                        </React.Fragment>}
                                </ul>

                            </div>

                            <div className="modal-footer">
                                <button type="button" onClick={() => this.sendTeamsInvitation(this.state.tournamentMode)} className="cmnbutton" data-dismiss="modal">Done</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal content to delete team/players*/}
                <div id="deleteplayer1" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Delete Players</h4>
                            </div>

                            <div className="modal-body">
                                <div className="confirmationdiv">
                                    <img src={confirmation} alt="" />
                                    <label>Do your really want to remove {this.state.takeName} ?</label>

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button onClick={() => this.removeAccepted(this.state.takeId)} type="button" className="cmnbutton" data-dismiss="modal">Yes Remove</button>
                                <button type="button" className="cmnbutton btnclose" data-dismiss="modal">Cancel</button>

                            </div>
                        </div>

                    </div>
                </div>

                <Header />
                <div className="container containerbottom non-session-d">
                    <SectionLeft />
                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 pad7">
                        <div className="d_midsec">


                            {this.state.isMiddlesection ? <div className="d_midsec">
                                <div className="divpost teamprofile_topdiv">
                                    <span className="teamprofile_span">Demo Tournament Profile</span>
                                    <div className="createteam_div" style={{ backgroundImage: "url(" + challenegbg + ")" }}>
                                        <div className="uploadimg_div" >
                                            {(() => {
                                                switch (this.state.tournamentData.tournamentIcon) {
                                                    case null:
                                                        return <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                    default:
                                                        return <span style={{ backgroundImage: "url(" + this.state.tournamentData.tournamentIcon + ")" }}></span>
                                                }
                                            })()}

                                        </div>

                                        <span className="tourament_name">{this.state.tournamentData.sportsName}</span>

                                        {CourseSelected ? <div className="cp_profilediv_new">
                                            <button
                                                className="requested"
                                                onClick={() =>
                                                    this.selectCourse(
                                                        this.state.tournamentData
                                                    )
                                                }
                                            >
                                                Select Course
                                        </button>
                                        </div>
                                            : ''}
                                        {RoundSelected ? <div className="cp_profilediv_new">
                                            <button
                                                className="requested"
                                                onClick={() =>
                                                    this.showRound()
                                                }
                                            >
                                                Select Round
                                        </button>
                                        </div> : ''}

                                        {CourseSelected ? '' : RoundSelected ? '' : <div className="cp_profilediv_new">
                                            <button
                                                className="requested"
                                                onClick={() =>
                                                    this.checkSportName(
                                                        this.state.tournamentData.categoryId,
                                                        this.state.tournamentData.tournamentType,
                                                        this.state.tournamentData.tournamentId,
                                                        null
                                                    )
                                                }
                                            >
                                                Update
                                        </button>
                                        </div>}


                                    </div>

                                    <div className="createteamform tourinfo_div">

                                        <ul className="nav nav-tabs teamprofiletab">
                                            <li className="active"><a data-toggle="tab" href="#info" aria-expanded="true">Info</a></li>
                                            <li><a data-toggle="tab" href="#invite">Invited</a></li>
                                            <li><a data-toggle="tab" href="#accept" aria-expanded="true">Accepted</a></li>
                                            {(() => {
                                                if ((this.state.tournamentData.tournamentType === 1)) {
                                                    return <li><a data-toggle="tab" href="#schedule">Schedule</a></li>
                                                }
                                            })()}


                                            {(() => {
                                                if ((this.state.tournamentData.tournamentType === 2) || (this.state.tournamentData.tournamentType === 3) || (this.state.tournamentData.tournamentType === 4)) {
                                                    return <li><a data-toggle="tab" href="#matchess">Matches</a></li>
                                                }
                                            })()}

                                            {(() => {
                                                if ((this.state.tournamentData.tournamentType === 1) || (this.state.tournamentData.tournamentType === 2) || (this.state.tournamentData.tournamentType === 3)) {
                                                    return <li><a data-toggle="tab" href="#completedmatches">Completed Matches</a></li>
                                                }
                                            })()}

                                            {(() => {
                                                if ((this.state.tournamentData.tournamentType === 2) || (this.state.tournamentData.tournamentType === 3)) {
                                                    return <li><a data-toggle="tab" href="#pointsTable">Points Table</a></li>
                                                }
                                            })()}
                                        </ul>
                                    </div>

                                    <div className="broadcast_tabul">
                                        <div className="tab-content tp_content">
                                            <div id="info" className="tab-pane fade in active">
                                                <div className=" cp_profile tourprofile">
                                                    <div className="teambox">
                                                        <h3>Registration</h3>
                                                        <ul className="">
                                                            <li> <span>Last Date of Registration</span>
                                                                <label>{this.state.tournamentData.regDate}</label>
                                                            </li>
                                                            <li> <span></span>
                                                                <label></label>
                                                            </li>
                                                            <li> <span>Start Date</span>
                                                                <label>{this.state.tournamentData.startDate}</label>
                                                            </li>
                                                            <li> <span>End Date</span>
                                                                <label>{this.state.tournamentData.endDate}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="teambox">
                                                        <h3>Demo Tournament Profile</h3>
                                                        <ul className="">
                                                            <li> <span>Tournament Name</span>
                                                                <label>{this.state.tournamentData.tournamentName}</label>
                                                            </li>
                                                            <li> <span>Location</span>
                                                                <label>{this.state.tournamentData.location}</label>
                                                            </li>
                                                            <li> <span>Organizer Name</span>
                                                                <label>{this.state.tournamentData.creatorName}</label>
                                                            </li>
                                                            <li> <span>Entry Fees</span>
                                                                <label>{this.state.tournamentData.entryFees} per team</label>
                                                            </li>
                                                            <li> <span>Price</span>
                                                                <label>Rs.{this.state.tournamentData.prize}</label>
                                                            </li>
                                                            <li> <span>Level</span>
                                                                <label>{this.state.tournamentData.tournamentLevel}</label>
                                                            </li>
                                                            <li> <span>Type</span>
                                                                <label>{this.state.tournamentData.tournamentMode}</label>
                                                            </li>
                                                            <li className="fullwidth"> <span>Rules</span>
                                                                <label>{this.state.tournamentData.rules}</label>
                                                            </li>
                                                            <li className="fullwidth"> <span>Description</span>
                                                                <label>{this.state.tournamentData.description}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="invite" className="tab-pane fade in">
                                                <div className=" cp_profile">
                                                    <div className="invite_tournament">
                                                        {(() => {
                                                            switch (this.state.user.contactNo === this.state.tournamentData.createdBy) {
                                                                case true:
                                                                    return <React.Fragment>
                                                                        {(() => {
                                                                            if (this.state.tournamentData.tournamentMode === 'buddy') {
                                                                                return <React.Fragment>
                                                                                    <label className="addbuddies_count">
                                                                                        <label>You can invite more players</label>
                                                                                    </label>
                                                                                    <button onClick={() => this.inviteTeams()} className="cmnbutton" data-toggle="modal" data-target="#team_addplayers">Invite Buddies</button>
                                                                                </React.Fragment>
                                                                            }
                                                                            else {
                                                                                return <React.Fragment>
                                                                                    <label className="addbuddies_count">
                                                                                        <label>You can invite more teams</label>
                                                                                    </label>
                                                                                    <button onClick={() => this.inviteTeams()} className="cmnbutton" data-toggle="modal" data-target="#team_addplayers">Invite Teams</button>
                                                                                </React.Fragment>
                                                                            }
                                                                        })()}
                                                                    </React.Fragment>
                                                                default:
                                                                    return '';
                                                            }
                                                        })()}
                                                    </div>
                                                    {(this.state.tournamentMode === 'buddy') ? <React.Fragment>
                                                        <ul className="buddieslist_ul ct_buddies">
                                                            {this.state.usersInTournament.map(t => {
                                                                if ((t.profilePic === null) && (t.contactNo !== this.state.user.contactNo)) {
                                                                    if (t.gender === 'Male') {
                                                                        return <li> <a> <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                            <label>{t.name}</label>
                                                                        </a> </li>
                                                                    }
                                                                    else {
                                                                        return <li> <a> <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                            <label>{t.name}</label>
                                                                        </a> </li>
                                                                    }
                                                                }
                                                                if ((t.profilePic !== null) && (t.contactNo !== this.state.user.contactNo)) {
                                                                    return <li> <a> <span style={{ backgroundImage: "url(" + t.profilePic + ")" }}></span>
                                                                        <label>{t.name}</label>
                                                                    </a> </li>
                                                                }
                                                            })}
                                                        </ul>
                                                    </React.Fragment> : <React.Fragment>
                                                            <ul className="buddieslist_ul ct_buddies">
                                                                {this.state.teamsInTournament.map(t => {
                                                                    if (t.teamIcon === null) {
                                                                        return <li> <a> <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                            <label>{t.teamName}</label>
                                                                        </a> </li>
                                                                    }
                                                                    else {
                                                                        return <li> <a> <span style={{ backgroundImage: "url(" + t.teamIcon + ")" }}></span>
                                                                            <label>{t.teamName}</label>
                                                                        </a> </li>
                                                                    }
                                                                })}
                                                            </ul>
                                                        </React.Fragment>}
                                                </div>
                                            </div>

                                            <div id="accept" className="tab-pane fade in">
                                                <div className=" cp_profile">
                                                    {(() => {
                                                        if (this.state.user.contactNo === this.state.tournamentData.createdBy) {
                                                            return <React.Fragment>
                                                                <div className="invite_tournament">

                                                                    {(() => {
                                                                        if (this.state.tournamentData.tournamentMode === 'buddy') {
                                                                            return <label className="addbuddies_count">
                                                                                Total : <label>{(this.state.tournamentData.accepted !== null) ? this.state.tournamentData.length : 0} Users</label> accepted your invitation.
                                                                        </label>
                                                                        }
                                                                        else {
                                                                            return <label className="addbuddies_count">
                                                                                Total : <label>{(this.state.tournamentData.accepted !== null) ? this.state.tournamentData.length : 0} Teams</label> accepted your invitation.
                                                                        </label>
                                                                        }
                                                                    })()}
                                                                </div>
                                                                <ul className="buddieslist_ul ct_buddies">
                                                                    {(this.state.tournamentMode === 'buddy') ? <React.Fragment>
                                                                        {this.state.usersInTournament.map(a => {
                                                                            var onlyAccepted = false;
                                                                            acceptedList.map(b => {
                                                                                if (a.contactNo === b) {
                                                                                    onlyAccepted = true;
                                                                                }
                                                                            })
                                                                            if (onlyAccepted === true) {
                                                                                if (a.profilePic === null) {
                                                                                    if (a.gender === 'Male') {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <i onClick={() => this.takeRemoveDetail(a.contactNo, a.name)} data-toggle="modal" data-target="#deleteplayer1" className="tp_removebuddy">
                                                                                                    <img src={cancelred} alt="" />
                                                                                                </i>
                                                                                                <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                                <label>{a.name}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    else {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <i onClick={() => this.takeRemoveDetail(a.contactNo, a.name)} data-toggle="modal" data-target="#deleteplayer1" className="tp_removebuddy">
                                                                                                    <img src={cancelred} alt="" />
                                                                                                </i>
                                                                                                <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                                <label>{a.name}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    return <li>
                                                                                        <a>
                                                                                            <i onClick={() => this.takeRemoveDetail(a.contactNo, a.name)} data-toggle="modal" data-target="#deleteplayer1" className="tp_removebuddy">
                                                                                                <img src={cancelred} alt="" />
                                                                                            </i>
                                                                                            <span style={{ backgroundImage: "url(" + a.profilePic + ")" }}></span>
                                                                                            <label>{a.name}</label>
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            }
                                                                        })}
                                                                    </React.Fragment> : <React.Fragment>
                                                                            {this.state.teamsInTournament.map(a => {
                                                                                var onlyAccepted = false;
                                                                                acceptedList.map(b => {
                                                                                    if (a.teamId === b) {
                                                                                        onlyAccepted = true;
                                                                                    }
                                                                                })
                                                                                if (onlyAccepted === true) {
                                                                                    if (a.teamIcon === null) {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <i onClick={() => this.takeRemoveDetail(a.teamId, a.teamName)} data-toggle="modal" data-target="#deleteplayer1" className="tp_removebuddy">
                                                                                                    <img src={cancelred} alt="" />
                                                                                                </i>
                                                                                                <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                <label>{a.teamName}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    else {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <i onClick={() => this.takeRemoveDetail(a.teamId, a.teamName)} data-toggle="modal" data-target="#deleteplayer1" className="tp_removebuddy">
                                                                                                    <img src={cancelred} alt="" />
                                                                                                </i>
                                                                                                <span style={{ backgroundImage: "url(" + a.teamIcon + ")" }}></span>
                                                                                                <label>{a.teamName}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                }
                                                                            })}
                                                                        </React.Fragment>}
                                                                </ul>
                                                            </React.Fragment>
                                                        }
                                                        else {
                                                            return <React.Fragment>
                                                                <ul className="buddieslist_ul ct_buddies">
                                                                    {(this.state.tournamentMode === 'buddy') ? <React.Fragment>
                                                                        {this.state.usersInTournament.map(a => {
                                                                            var onlyAccepted = false;
                                                                            this.state.tournamentData.accepted.map(b => {
                                                                                if (a.contactNo === b) {
                                                                                    onlyAccepted = true;
                                                                                }
                                                                            })
                                                                            if (onlyAccepted === true) {
                                                                                if (a.profilePic === null) {
                                                                                    if (a.gender === 'Male') {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                                <label>{a.name}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    else {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                                <label>{a.name}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    return <li>
                                                                                        <a>
                                                                                            <span style={{ backgroundImage: "url(" + a.profilePic + ")" }}></span>
                                                                                            <label>{a.name}</label>
                                                                                        </a>
                                                                                    </li>
                                                                                }
                                                                            }
                                                                        })}
                                                                    </React.Fragment> : <React.Fragment>
                                                                            {this.state.teamsInTournament.map(a => {
                                                                                var onlyAccepted = false;
                                                                                this.state.tournamentData.accepted.map(b => {
                                                                                    if (a.teamId === b) {
                                                                                        onlyAccepted = true;
                                                                                    }
                                                                                })
                                                                                if (onlyAccepted === true) {
                                                                                    if (a.teamIcon === null) {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                <label>{a.teamName}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                    else {
                                                                                        return <li>
                                                                                            <a>
                                                                                                <span style={{ backgroundImage: "url(" + a.teamIcon + ")" }}></span>
                                                                                                <label>{a.teamName}</label>
                                                                                            </a>
                                                                                        </li>
                                                                                    }
                                                                                }
                                                                            })}
                                                                        </React.Fragment>}
                                                                </ul>
                                                            </React.Fragment>
                                                        }
                                                    })()}
                                                </div>
                                            </div>

                                            <div id="schedule" className="tab-pane fade in">
                                                {this.state.afterOrganiseListForTT1 ?

                                                    <DOrganiseMatchTT1List tournamentData={this.state.tournamentData} tt1Matches={this.state.tt1Matches} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} viewTT1Parrent={this.viewTT1Child} updateTT1Parrent={this.updateTT1Child} />

                                                    : ''}
                                                {this.state.showCreateForTT1 ? <DOrganiseMatchTT1Create tournament={this.state.tournamentData} tt1Matches={this.state.tt1Matches} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} viewTT1Parrent={this.viewTT1Child} updateTT1Parrent={this.updateTT1Child} /> : ''}
                                            </div>


                                            <div id="completedmatches" className="tab-pane fade in">
                                                <div className=" cp_profile">
                                                    <div className="invite_tournament pointstable_tour">
                                                        {(() => {
                                                            if (this.state.tournamentData.tournamentType === 3) {
                                                                return <React.Fragment>
                                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                        <div className="completedmatches">
                                                                            <ul className="scoreboxul tourcompleted_match">
                                                                                {this.state.pool1Matches.map(m => {
                                                                                    var completed = false;
                                                                                    var teamName1, teamName2;

                                                                                    if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                        this.state.teamsInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.teamId) {
                                                                                                    teamName1 = n.teamName;
                                                                                                }
                                                                                                if (m.secondId === n.teamId) {
                                                                                                    teamName2 = n.teamName;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        this.state.usersInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.contactNo) {
                                                                                                    teamName1 = n.name;
                                                                                                }
                                                                                                if (m.secondId === n.contactNo) {
                                                                                                    teamName2 = n.name;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (completed === true) {
                                                                                        return <li>
                                                                                            <div className="teamdiv">
                                                                                                <span className="matchdatetime">Date: {m.date} Time: {m.time}</span>
                                                                                                <ul className="live_teamul live_teamul1">
                                                                                                    <li>
                                                                                                        {m.winnerId === m.firstId ? <img src={winnerlogo} alt="" className="tour_matchwinner" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName1}</label>
                                                                                                        <span className="scoretour_match">18 - 0</span>

                                                                                                        {m.winnerId === m.firstId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <img className="vsicon" src={challengefill} alt="" />
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        {m.winnerId === m.secondId ? <img src={winnerlogo} alt="" className="tour_matchwinner2" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName2}</label>
                                                                                                        <span className="scoretour_match">18 - 0</span>

                                                                                                        {m.winnerId === m.secondId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                </ul>

                                                                                            </div>
                                                                                        </li>
                                                                                    }
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                        <div className="completedmatches">
                                                                            <ul className="scoreboxul tourcompleted_match">
                                                                                {this.state.pool2Matches.map(m => {
                                                                                    var completed = false;
                                                                                    var teamName1, teamName2;

                                                                                    if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                        this.state.teamsInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.teamId) {
                                                                                                    teamName1 = n.teamName;
                                                                                                }
                                                                                                if (m.secondId === n.teamId) {
                                                                                                    teamName2 = n.teamName;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        this.state.usersInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.contactNo) {
                                                                                                    teamName1 = n.name;
                                                                                                }
                                                                                                if (m.secondId === n.contactNo) {
                                                                                                    teamName2 = n.name;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (completed === true) {
                                                                                        return <li>
                                                                                            <div className="teamdiv">
                                                                                                <span className="matchdatetime">Date: {m.date} Time: {m.time}</span>
                                                                                                <ul className="live_teamul live_teamul1">
                                                                                                    <li>
                                                                                                        {m.winnerId === m.firstId ? <img src={winnerlogo} alt="" className="tour_matchwinner" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName1}</label>
                                                                                                        <span className="scoretour_match">18 - 0</span>

                                                                                                        {m.winnerId === m.firstId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <img className="vsicon" src={challengefill} alt="" />
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        {m.winnerId === m.secondId ? <img src={winnerlogo} alt="" className="tour_matchwinner2" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName2}</label>
                                                                                                        <span className="scoretour_match">18 - 0</span>

                                                                                                        {m.winnerId === m.secondId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                </ul>

                                                                                            </div>
                                                                                        </li>
                                                                                    }
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        })()}

                                                        {(() => {
                                                            if (this.state.tournamentData.tournamentType === 2) {
                                                                return <React.Fragment>
                                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                        <div className="completedmatches">
                                                                            <ul className="scoreboxul tourcompleted_match">
                                                                                {this.state.tt2Matches.map(m => {
                                                                                    var completed = false;
                                                                                    var teamName1, teamName2;
                                                                                    var bygols, togols;

                                                                                    this.state.scoresArr.map(g => {
                                                                                        if (g.byId === m.matchId) {
                                                                                            bygols = g.byGol;
                                                                                        }
                                                                                    })
                                                                                    this.state.scoresArr1.map(h => {
                                                                                        if (h.toId === m.matchId) {
                                                                                            togols = h.toGol;
                                                                                        }
                                                                                    })

                                                                                    if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                        this.state.teamsInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.teamId) {
                                                                                                    teamName1 = n.teamName;
                                                                                                }
                                                                                                if (m.secondId === n.teamId) {
                                                                                                    teamName2 = n.teamName;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        this.state.usersInTournament.map(n => {
                                                                                            if (m.ended === true) {
                                                                                                completed = true;
                                                                                                if (m.firstId === n.contactNo) {
                                                                                                    teamName1 = n.name;
                                                                                                }
                                                                                                if (m.secondId === n.contactNo) {
                                                                                                    teamName2 = n.name;
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (completed === true) {
                                                                                        return <li>
                                                                                            <div className="teamdiv">
                                                                                                <span className="matchdatetime">Date: {m.date} Time: {m.time}</span>
                                                                                                <ul className="live_teamul live_teamul1">
                                                                                                    <li>
                                                                                                        {m.winnerId === m.firstId ? <img src={winnerlogo} alt="" className="tour_matchwinner" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName1}</label>

                                                                                                        <span className="scoretour_match">{bygols}</span>

                                                                                                        {m.winnerId === m.firstId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <img className="vsicon" src={challengefill} alt="" />
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        {m.winnerId === m.secondId ? <img src={winnerlogo} alt="" className="tour_matchwinner2" /> : ''}

                                                                                                        <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                                        <label className="it_flgname">{teamName2}</label>

                                                                                                        <span className="scoretour_match">{togols}</span>

                                                                                                        {m.winnerId === m.secondId ? <label className="it_flgscore">Winner</label> : ''}
                                                                                                    </li>
                                                                                                </ul>

                                                                                            </div>
                                                                                        </li>
                                                                                    }
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    </div>


                                                                </React.Fragment>
                                                            }
                                                        })()}

                                                        {(() => {
                                                            if (this.state.tournamentData.tournamentType === 1) {
                                                                return <React.Fragment>

                                                                </React.Fragment>
                                                            }
                                                        })()}

                                                    </div>
                                                </div>
                                            </div>

                                            <div id="matchess" className="tab-pane fade in">

                                                {this.state.afterOrganiseListForTT4 ?

                                                    <DOrganiseMatchTT4List tournamentData={this.state.tournamentData} tt4Matches={this.state.tt4Matches} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} viewTT4Parrent={this.viewTT4Child} updateTT4Parrent={this.updateTT4Child} />

                                                    : ''}




                                                {this.state.afterOrganiseListForTT3 ?
                                                    <div class="back_white">
                                                        {this.state.tt3Matches.map(a => {
                                                            var team1, team2, venue, dateplace, timeplace;
                                                            var ended, matchId = [];
                                                            var matches = a.matches.map((match, index) => {
                                                                if (this.state.tournamentMode === 'team') {
                                                                    this.state.teamsInTournament.map(b => {
                                                                        if (match.firstId === b.teamId) {
                                                                            team1 = b.teamName;
                                                                            venue = match.location;
                                                                            dateplace = match.date;
                                                                            timeplace = match.time;
                                                                            matchId.push(match.matchId);
                                                                            if (match.ended === false) {
                                                                                ended = false;
                                                                            }
                                                                            else {
                                                                                ended = true;
                                                                            }
                                                                        }
                                                                        if (match.secondId === b.teamId) {
                                                                            team2 = b.teamName;
                                                                        }
                                                                    })
                                                                }
                                                                else {
                                                                    this.state.usersInTournament.map(b => {
                                                                        if (match.firstId === b.contactNo) {
                                                                            team1 = b.name;
                                                                            venue = match.location;
                                                                            dateplace = match.date;
                                                                            timeplace = match.time;
                                                                            matchId.push(match.matchId);
                                                                            if (match.ended === false) {
                                                                                ended = false;
                                                                            }
                                                                            else {
                                                                                ended = true;
                                                                            }
                                                                        }
                                                                        if (match.secondId === b.contactNo) {
                                                                            team2 = b.name;
                                                                        }
                                                                    })
                                                                }


                                                                return <li class="leauge_matchdiv">
                                                                    <div class="teamdiv">
                                                                        <ul class="live_teamul live_teamul1 straight_ul">
                                                                            <li>
                                                                                <span class="lt_img"
                                                                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                <label class=" straight_player">{team1}</label>
                                                                            </li>
                                                                            <li>
                                                                                <img class="vsicon" src={challengefill} alt="" />
                                                                            </li>
                                                                            <li>
                                                                                <span class="lt_img"
                                                                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                <label class="straight_player">{team2}</label>
                                                                            </li>
                                                                        </ul>
                                                                        <div class="straight_inputs">
                                                                            <div class="fixture_date">
                                                                                <input readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                                                                <img src={scheduleMatches} alt="" />
                                                                            </div>
                                                                            <div class="fixture_date fixture_location">
                                                                                <input readOnly placeholder={"Venue:- " + venue} />
                                                                                <img src={location} alt="" />
                                                                            </div>
                                                                            {ended === false
                                                                                ?
                                                                                <button onClick={() => this.checkSportName(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, matchId[index], -2)} className="cmnbuttonup1">Update Score</button>
                                                                                :
                                                                                <button onClick={() => this.viewTourScore(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, matchId[index])} className="cmnbuttonup">View Score</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            })
                                                            return <>
                                                                <div class="pool-number">
                                                                    <label> POOL -{a.poolNo}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="completedmatches">
                                                                        <ul class="scoreboxul tourcompleted_match">
                                                                            {matches}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}
                                                    </div>
                                                    : ''}
                                                {this.state.afterOrganiseList === true ? <React.Fragment>

                                                    {this.state.tt2Matches.map(a => {
                                                        var team1, team2, venue, dateplace, timeplace;
                                                        var ended, matchId;

                                                        if (this.state.tournamentData.tournamentMode === 'team') {
                                                            this.state.teamsInTournament.map(b => {
                                                                if (a.firstId === b.teamId) {

                                                                    team1 = b.teamName;
                                                                    venue = a.location;
                                                                    dateplace = a.date;
                                                                    timeplace = a.time;
                                                                    matchId = a.matchId;
                                                                    if (a.ended === false) {
                                                                        ended = false;
                                                                    }
                                                                    else {
                                                                        ended = true;
                                                                    }
                                                                }
                                                                if (a.secondId === b.teamId) {
                                                                    team2 = b.teamName;
                                                                }
                                                            })
                                                        }

                                                        else {
                                                            this.state.usersInTournament.map(b => {
                                                                if (a.firstId === b.contactNo) {

                                                                    team1 = b.name;
                                                                    venue = a.location;
                                                                    dateplace = a.date;
                                                                    timeplace = a.time;
                                                                    matchId = a.matchId;
                                                                    if (a.ended === false) {
                                                                        ended = false;
                                                                    }
                                                                    else {
                                                                        ended = true;
                                                                    }
                                                                }
                                                                if (a.secondId === b.contactNo) {
                                                                    team2 = b.name;
                                                                }
                                                            })
                                                        }

                                                        return <div class="back_white">

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="completedmatches">
                                                                    <ul class="scoreboxul tourcompleted_match">

                                                                        <li class="leauge_matchdiv">
                                                                            <div class="teamdiv">
                                                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                                                    <li>
                                                                                        <span class="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label class=" straight_player">{team1}</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <img class="vsicon" src={challengefill} alt="" />
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label class="straight_player">{team2}</label>
                                                                                    </li>
                                                                                </ul>
                                                                                <div class="straight_inputs">
                                                                                    <div class="fixture_date">
                                                                                        <input readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                                                                        <img src={scheduleMatches} alt="" />
                                                                                    </div>
                                                                                    <div class="fixture_date fixture_location">
                                                                                        <input readOnly placeholder={"Venue:- " + venue} />
                                                                                        <img src={location} alt="" />
                                                                                    </div>
                                                                                    {ended === false
                                                                                        ?
                                                                                        <button onClick={() => this.checkSportName(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, matchId, -1)} className="cmnbuttonup1">Update Score</button>
                                                                                        :
                                                                                        <button onClick={() => this.viewTourScore(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentTyp, matchId)} className="cmnbuttonup">View Score</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    })}



                                                    {this.state.showSemiFinal ? <React.Fragment>
                                                        <div className="semi_headingdiv">
                                                            <label >Semi Final</label>
                                                        </div>

                                                        <div className="back_white">

                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="completedmatches">
                                                                    <ul className="scoreboxul tourcompleted_match">

                                                                        <li className="leauge_matchdiv">
                                                                            <div className="teamdiv">
                                                                                <ul className="live_teamul live_teamul1 straight_ul">
                                                                                    <li>
                                                                                        <span className="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label className=" straight_player">NICE</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <img className="vsicon" src={challengefill} alt="" />
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label className="straight_player">PARISSG</label>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="straight_inputs">
                                                                                    {this.state.tt23Matches.semiFinals[0].firstId ? <div className="teamUserSelection">
                                                                                        <select className="select1" >
                                                                                            <option >{semiAFirstName}</option>
                                                                                        </select>
                                                                                        <select className="select2" >
                                                                                            <option>{semiASecondName}</option>
                                                                                        </select>
                                                                                    </div> : <div className="teamUserSelection">
                                                                                            <select className="select1" onChange={(event) => this.semi1Dropdown(event)}>
                                                                                                {this.state.dropDownUserTeam.map(drp => {
                                                                                                    return <option value={drp.id}>{drp.name}</option>
                                                                                                })}

                                                                                            </select>
                                                                                            <select className="select2" onChange={(event) => this.semi2Dropdown(event)}>
                                                                                                {this.state.semiBDropDown.map(drp => {
                                                                                                    return <option value={drp.id}>{drp.name}</option>
                                                                                                })}
                                                                                            </select>
                                                                                        </div>}

                                                                                </div>

                                                                                <div className="straight_inputs">

                                                                                    <div className="fixture_date">
                                                                                        <input readOnly placeholder={"Date:- " + this.state.tt23Matches.semiFinals[0].date + " Time:- " + this.state.tt23Matches.semiFinals[0].time} />
                                                                                        <img src={scheduleMatches} alt="" />
                                                                                    </div>
                                                                                    <div className="fixture_date fixture_location">
                                                                                        <input readOnly placeholder={"Venue:- " + this.state.tt23Matches.semiFinals[0].location} />
                                                                                        <img src={location} alt="" />
                                                                                    </div>
                                                                                    {semiAEnded === false
                                                                                        ?
                                                                                        <button onClick={() => this.checkSportName(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, semiAmatchId, 2)} className="cmnbuttonup1">Update Score</button>
                                                                                        :
                                                                                        <button onClick={() => this.viewTourScore(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType)} className="cmnbuttonup">View Score</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="completedmatches">
                                                                    <ul className="scoreboxul tourcompleted_match">

                                                                        <li className="leauge_matchdiv">
                                                                            <div className="teamdiv">
                                                                                <ul className="live_teamul live_teamul1 straight_ul">
                                                                                    <li>
                                                                                        <span className="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label className=" straight_player">NICE</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <img className="vsicon" src={challengefill} alt="" />
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label className="straight_player">PARISSG</label>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="straight_inputs">
                                                                                    {this.state.tt23Matches.semiFinals[0].firstId ? <div className="teamUserSelection">
                                                                                        <select className="select1" >
                                                                                            <option >{semiBFirstName}</option>
                                                                                        </select>
                                                                                        <select className="select2" >
                                                                                            <option>{semiBSecondName}</option>
                                                                                        </select>
                                                                                    </div> : <div className="teamUserSelection">
                                                                                            <select className="select1" onChange={(event) => this.semi3Dropdown(event)}>
                                                                                                {this.state.semiCDropDown.map(drp => {
                                                                                                    return <option value={drp.id}>{drp.name}</option>
                                                                                                })}
                                                                                            </select>
                                                                                            <select className="select2" onChange={(event) => this.semi4Dropdown(event)}> {this.state.semiDDropDown.map(drp => {
                                                                                                return <option value={drp.id}>{drp.name}</option>
                                                                                            })}</select>
                                                                                        </div>}

                                                                                </div>
                                                                                <div className="straight_inputs">
                                                                                    <div className="fixture_date">
                                                                                        <input readOnly placeholder={"Date:- " + this.state.tt23Matches.semiFinals[1].date + " Time:- " + this.state.tt23Matches.semiFinals[0].time} />
                                                                                        <img src={scheduleMatches} alt="" />
                                                                                    </div>
                                                                                    <div className="fixture_date fixture_location">
                                                                                        <input readOnly placeholder={"Venue:- " + this.state.tt23Matches.semiFinals[1].location} />
                                                                                        <img src={location} alt="" />
                                                                                    </div>
                                                                                    {semiBEnded === false
                                                                                        ?
                                                                                        <button onClick={() => this.checkSportName(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, semiBmatchId, 2)} className="cmnbuttonup1">Update Score</button>
                                                                                        :
                                                                                        <button onClick={() => this.viewTourScore(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType)} className="cmnbuttonup">View Score</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </React.Fragment> : ''}

                                                    {this.state.showFinal ? <React.Fragment>
                                                        <div class="semi_headingdiv">
                                                            <label >Final</label>
                                                        </div>

                                                        <div class="back_white">

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="completedmatches">
                                                                    <ul class="scoreboxul tourcompleted_match">

                                                                        <li class="leauge_matchdiv">
                                                                            <div class="teamdiv">
                                                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                                                    <li>
                                                                                        <span class="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label class=" straight_player">{finalFirstName}</label>
                                                                                    </li>
                                                                                    <li>
                                                                                        <img class="vsicon" src={challengefill} alt="" />
                                                                                    </li>
                                                                                    <li>
                                                                                        <span class="lt_img"
                                                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                                        <label class="straight_player">{finalSecondName}</label>
                                                                                    </li>
                                                                                </ul>
                                                                                <div class="straight_inputs">
                                                                                    <div class="fixture_date">
                                                                                        <input readOnly placeholder={"Date:- " + this.state.tt23Matches.finals.date + " Time:- " + this.state.tt23Matches.finals.time} />
                                                                                        <img src={scheduleMatches} alt="" />
                                                                                    </div>
                                                                                    <div class="fixture_date fixture_location">
                                                                                        <input readOnly placeholder={"Venue:- " + this.state.tt23Matches.finals.location} />
                                                                                        <img src={location} alt="" />
                                                                                    </div>
                                                                                    {finalEnded === false
                                                                                        ?
                                                                                        <button onClick={() => this.checkSportName(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType, finalmatchId, 3)} className="cmnbuttonup1">Update Score</button>
                                                                                        :
                                                                                        <button onClick={() => this.viewTourScore(this.state.tournamentData.categoryId, this.state.tournamentData.tournamentType)} className="cmnbuttonup">View Score</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </React.Fragment> : ''}
                                                    {this.state.ShowSaveSemi ? <button className="cmnbuttonup" onClick={() => this.saveSemiFinal()}>Save Semifinal Details</button> : ''}
                                                    {this.state.ShowNextRoundButton ? <button className="cmnbuttonup" onClick={() => this.openOptionForNextRound()}>Next Round Qualification</button> : ''}
                                                </React.Fragment> : <React.Fragment>
                                                        {this.state.showOrganisedButton ? <div class="organized_tour-btn">
                                                            <button onClick={() => this.oganizedMatch()}>Organized matches</button>
                                                        </div> : ''}

                                                        {this.state.organiseList ?
                                                            <DOrganiseMatchCreate tournamentData={this.state.tournamentData} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} afterOrganiseList={this.afterOrganiseList} />
                                                            : ""}

                                                        {this.state.organiseListTT3 ?
                                                            <DOrganiseMatchTT3Create tournamentData={this.state.tournamentData} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} afterOrganiseList={this.afterOrganiseList} />
                                                            : ""}
                                                    </React.Fragment>}
                                                {this.state.organiseListTT4 ? <DOrganiseMatchTT4Create tournamentData={this.state.tournamentData} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} afterOrganiseList={this.afterOrganiseList} /> : ""}
                                                {this.state.organiseListTT1 ? <DOrganiseMatchTT1Create tournamentData={this.state.tournamentData} tournamentMode={this.state.tournamentMode} usersInTournament={this.state.usersInTournament} teamsInTournament={this.state.teamsInTournament} afterOrganiseList={this.afterOrganiseList} /> : ""}
                                            </div>

                                            {this.state.tournamentData.tournamentType === 2 ? <React.Fragment>
                                                <div id="pointsTable" className="tab-pane fade in">
                                                    <div className=" cp_profile">
                                                        <div className="invite_tournament pointstable_tour">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                {/* <label className="poollabel">Pool 1</label> */}
                                                                <div className="panelnew pooltable_tour">
                                                                    <div className="table-responsive scoretable">
                                                                        <table className="table table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Teams</th>
                                                                                    <th>Played</th>
                                                                                    <th>Won</th>
                                                                                    <th>Lost</th>
                                                                                    <th>Draw</th>
                                                                                    <th>Total</th>
                                                                                    <th>NRR</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.thePointsTable.map(p => {
                                                                                    var displayData = false;
                                                                                    var naam;

                                                                                    if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                        this.state.teamsInTournament.map(q => {
                                                                                            if (p.teamId === q.teamId) {
                                                                                                displayData = true;
                                                                                                naam = q.teamName;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        this.state.usersInTournament.map(q => {
                                                                                            if (p.teamId === q.contactNo) {
                                                                                                displayData = true;
                                                                                                naam = q.name;
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    if (displayData) {
                                                                                        return <tr key={p.teamId}>
                                                                                            <td><span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>{naam}</td>
                                                                                            <td>{p.matchesPlayed}</td>
                                                                                            <td>{p.matchesWon}</td>
                                                                                            <td>{p.matchesLost}</td>
                                                                                            <td>{p.matchesDraw}</td>
                                                                                            <td>{p.totalPoints}</td>
                                                                                            <td>{p.netRunRate}</td>
                                                                                        </tr>
                                                                                    }
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment> : <React.Fragment>
                                                    <div id="pointsTable" className="tab-pane fade in">
                                                        <div className=" cp_profile">
                                                            <div className="invite_tournament pointstable_tour">

                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label className="poollabel">Pool 1</label>
                                                                    <div className="panelnew pooltable_tour">
                                                                        <div className="table-responsive scoretable">
                                                                            <table className="table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Teams</th>
                                                                                        <th>Played</th>
                                                                                        <th>Won</th>
                                                                                        <th>Lost</th>
                                                                                        <th>Draw</th>
                                                                                        <th>Total</th>
                                                                                        <th>NRR</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.pool1Table.map(p => {
                                                                                        var displayData = false;
                                                                                        var naam;

                                                                                        if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                            this.state.teamsInTournament.map(q => {
                                                                                                if (p.teamId === q.teamId) {
                                                                                                    displayData = true;
                                                                                                    naam = q.teamName;
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            this.state.usersInTournament.map(q => {
                                                                                                if (p.teamId === q.contactNo) {
                                                                                                    displayData = true;
                                                                                                    naam = q.name;
                                                                                                }
                                                                                            })
                                                                                        }

                                                                                        if (displayData) {
                                                                                            return <tr key={p.teamId}>
                                                                                                <td><span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>{naam}</td>
                                                                                                <td>{p.matchesPlayed}</td>
                                                                                                <td>{p.matchesWon}</td>
                                                                                                <td>{p.matchesLost}</td>
                                                                                                <td>{p.matchesDraw}</td>
                                                                                                <td>{p.totalPoints}</td>
                                                                                                <td>{p.netRunRate}</td>
                                                                                            </tr>
                                                                                        }
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <label className="poollabel">Pool 2</label>

                                                                    <div className="panelnew pooltable_tour">
                                                                        <div className="table-responsive scoretable">
                                                                            <table className="table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Teams</th>
                                                                                        <th>Played</th>
                                                                                        <th>Won</th>
                                                                                        <th>Lost</th>
                                                                                        <th>Draw</th>
                                                                                        <th>Total</th>
                                                                                        <th>NRR</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.pool2Table.map(p => {
                                                                                        var displayData = false;
                                                                                        var naam;

                                                                                        if (this.state.tournamentData.tournamentMode === 'team') {
                                                                                            this.state.teamsInTournament.map(q => {
                                                                                                if (p.teamId === q.teamId) {
                                                                                                    displayData = true;
                                                                                                    naam = q.teamName;
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            this.state.usersInTournament.map(q => {
                                                                                                if (p.teamId === q.contactNo) {
                                                                                                    displayData = true;
                                                                                                    naam = q.name;
                                                                                                }
                                                                                            })
                                                                                        }

                                                                                        if (displayData) {
                                                                                            return <tr key={p.teamId}>
                                                                                                <td><span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>{naam}</td>
                                                                                                <td>{p.matchesPlayed}</td>
                                                                                                <td>{p.matchesWon}</td>
                                                                                                <td>{p.matchesLost}</td>
                                                                                                <td>{p.matchesDraw}</td>
                                                                                                <td>{p.totalPoints}</td>
                                                                                                <td>{p.netRunRate}</td>
                                                                                            </tr>
                                                                                        }
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>}

                                        </div>
                                    </div>
                                </div>
                            </div> : ''}

                            {this.state.showCoursesList ? <div class="commanbox">
                                <h3 class="commanhead add_course_icon-d">Select Course
                                <i class="fa fa-plus" style={{ cursor: 'pointer' }} onClick={() => this.AddCourse()}></i>
                                </h3>
                                <div class="cl_box_div select_courselist-d">
                                    <ul class="cl_box_ul">
                                        {this.state.courses.map(course => {
                                            return <li onClick={() => this.chooseCourse(course.courseId)}>
                                                <div class="cl_info_div select_course-d">
                                                    <span class="cl_userbg course_locationbg" ></span>
                                                    <p>Holes: {course.holeType}</p>
                                                    <span class="course_location-d">{course.courseName} </span>
                                                </div>
                                            </li>
                                        })}

                                    </ul>
                                </div>
                            </div> : ''}

                            {this.state.showRounds ? <DRoundSelection tournament={this.state.tournamentData} BaseRoundCall={this.childRoundCall} /> : ''}

                            {this.state.ShowAddCourseForm ? <AddCourse BaseCourseCall={this.childCourseCall} challenge={this.state.tournamentData} /> : ''}

                            {/* ================================== update score states on and off ================================ */}
                            {this.state.isDTournament_d106_tt1 ? <DTournament_d106_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}

                            {this.state.isDTournament_archery_tt1 ? <DTournament_archery_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}

                            {this.state.isDTournament_Baseball_tt1 ? <DTournament_Baseball_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}

                            {this.state.isDTournament_kabaddi_tt1 ? <DTournament_kabaddi_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}

                            {this.state.isDTournament_basketball_tt1 ? <DTournament_basketball_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.isDTournament_tennis_tt1 ? <DTournament_tennis_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}

                            {this.state.isDTournament_tabletennis_tt1 ? <DTournament_tabletennis_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.isDTournament_rugby_tt1 ? <DTournament_rugby_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}








                            {this.state.DTournament_d107_tt1Badminton ? <DTournament_d107_tt1Badminton
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.DTournament_d107_tt1Squash ? <DTournament_d107_tt1Squash
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.DTournament_d107_tt1BeachVolleyBall ? <DTournament_d107_tt1BeachVolleyBall
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.DTournament_d107_tt1VolleyBall ? <DTournament_d107_tt1VolleyBall
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.DTournament_d107_tt1Carrom ? <DTournament_d107_tt1Carrom
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}
                            {this.state.DTournament_d102_tt1 ? <DTournament_d102_tt1
                                tournament={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                parrentTTcallback={this.childTTcallback}
                                matchId={this.state.matchId}
                                fType={this.state.fType}
                                tt1={this.state.tt1Matches}
                            /> : ''}


                            {this.state.DTournament_d101_update_TT4 ? (
                                <DTournament_d101_update_TT4
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    tt4={this.state.tt4Matches}
                                    matchType={this.state.matchTypeClicked}
                                    clickedIndex={this.state.clickedIndex}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d103_Update_tt4 ? (
                                <DTournament_d103_Update_tt4
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    tt4={this.state.tt4Matches}
                                    matchType={this.state.matchTypeClicked}
                                    clickedIndex={this.state.clickedIndex}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d105_Update_tt4 ? (
                                <DTournament_d105_Update_tt4
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    tt4={this.state.tt4Matches}
                                    matchType={this.state.matchTypeClicked}
                                    clickedIndex={this.state.clickedIndex}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournament_d104_Update_tt4 ? (
                                <DTournament_d104_Update_tt4
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    tt4={this.state.tt4Matches}
                                    matchType={this.state.matchTypeClicked}
                                    clickedIndex={this.state.clickedIndex}
                                />
                            ) : (
                                    ""
                                )}


                            {this.state.isDTournament_d104_tt5 ? (
                                <DTournaments_d104_tt5
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.isDTournament_d107_tt5 ? (
                                <DTournaments_d107_tt5
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.isDTournament_d106_tt2 ? (
                                <DTournament_d106_tt2
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    fType={this.state.fType}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.isDTournament_d107_tt2_badminton ?
                                <DTournaments_d107_tt2_badminton
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.isDTournament_d107_tt2_tableTennis ?
                                <DTournaments_d107_tt2_TableTennis
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}

                            {this.state.isDTournament_d107_tt2_Tennis ?
                                <DTournaments_d107_tt2_Tennis
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.isDTournament_d107_tt2_Squash ?
                                <DTournament_d107_Sqaush_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}

                            {this.state.isDTournament_d107_VolleyBall_tt23 ?
                                <DTournament_d107_VolleyBall_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.isDTournament_d107_BeachVolleyball_tt23 ?
                                <DTournament_d107_BeachVolleyball_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.isDTournament_d107_Carrom_tt23 ?
                                <DTournament_d107_Carrom_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}



                            {/* ================= view score states on and off =============== */}
                            {this.state.DTournament_d107_badminton_view ? (
                                <DTournament_d107_badminton_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Carrom_view ? (
                                <DTournament_d107_Carrom_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Tabletennis_view ? (
                                <DTournament_d107_Tabletennis_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_tennis_view ? (
                                <DTournament_d107_tennis_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Squash_view ? (
                                <DTournament_d107_Squash_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Volleyball_view ? (
                                <DTournament_d107_Volleyball_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_beachVolleyBall_view ? (
                                <DTournament_d107_beachVolleyBall_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournaments_d107_tt2tt3_kabaddi ?
                                <DTournaments_d107_tt2tt3_kabaddi
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.DTournaments_d107_tt2tt3_baseball ?
                                <DTournaments_d107_tt2tt3_baseball
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}
                            {this.state.DTournament_basketball_tt23 ?
                                <DTournament_basketball_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}

                            {this.state.DTournament_rugby_tt23 ?
                                <DTournament_rugby_tt23
                                    tournament={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    parrentTTcallback={this.childTTcallback}
                                    matchId={this.state.matchId}
                                    poolId={0}
                                    fType={this.state.fType}

                                /> : ""}


                            {this.state.DTournament_d101_view_TT4 ? <DTournament_d101_view_TT4
                                challenge={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                matchId={this.state.byIDs}
                                tt4={this.state.tt4Matches}
                                matchType={this.state.matchTypeClicked}
                                clickedIndex={this.state.clickedIndex}
                            /> : ''}
                            {this.state.DTournament_d103_view_TT4 ? <DTournament_d103_view_TT4
                                challenge={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                matchId={this.state.byIDs}
                                tt4={this.state.tt4Matches}
                                matchType={this.state.matchTypeClicked}
                                clickedIndex={this.state.clickedIndex}
                            /> : ''}
                            {this.state.DTournament_d104_view_TT4 ? <DTournament_d104_view_TT4
                                challenge={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                matchId={this.state.byIDs}
                                tt4={this.state.tt4Matches}
                                matchType={this.state.matchTypeClicked}
                                clickedIndex={this.state.clickedIndex}
                            /> : ''}

                            {this.state.DTournament_d105_view_TT4 ? < DTournament_d105_view_TT4
                                challenge={this.state.tournamentData}
                                profileTeams={this.state.teamsInTournament}
                                profileUsers={this.state.usersInTournament}
                                matchId={this.state.byIDs}
                                tt4={this.state.tt4Matches}
                                matchType={this.state.matchTypeClicked}
                                clickedIndex={this.state.clickedIndex}
                            /> : ''}
                            {this.state.DTournament_d101_view ? (
                                <DTournament_d101_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d103_view ? (
                                <DTournament_d103_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournament_d107_BeachVolleyball_tt23 ? (
                                <DTournament_d107_BeachVolleyball_tt23
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournament_d107_Golf_view ? (
                                <DTournament_d107_Golf_view challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {/* ================= view score states on and off =============== */}


                            {this.state.DTournament_d107_Rugby_view ? (
                                <DTournament_d107_Rugby_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d106view ? (
                                <DTournament_d106view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Baseball_view ? (
                                <DTournament_d107_Baseball_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Basketball_view ? (
                                <DTournament_d107_Basketball_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Kabbadi_view ? (
                                <DTournament_d107_Kabbadi_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_cricket_view ? (
                                <DTournament_d107_cricket_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}
                            {this.state.DTournament_d107_Archery_view ? (
                                < DTournament_d107_Archery_view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}



                            {this.state.DTournament_d102view ? (
                                <DTournament_d102view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournament_d104view ? (
                                <DTournament_d104view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}

                            {this.state.DTournament_d105view ? (
                                <DTournament_d105view
                                    challenge={this.state.tournamentData}
                                    profileTeams={this.state.teamsInTournament}
                                    profileUsers={this.state.usersInTournament}
                                    matchId={this.state.byIDs}
                                />
                            ) : (
                                    ""
                                )}





                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default DTournamentProfile;