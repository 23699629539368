
import React, { Component } from "react";
import userMaleIcon from "./img/male.png";
import teamImage from "./img/team.png";
import tournamentprofileimg from './img/tournamentprofileimg.jpg';
import userFemaleIcon from "./img/female.png";
import challengefill from './img/challengefill.png';
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
import { } from 'prismic-reactjs';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const userData = [];
class ScheduleTournamentSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersDetails: [],
            count: '',
            teamDetails: [],
        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.loadUser = this.loadUser.bind(this);
        this.loadTeam = this.loadTeam.bind(this);
    }
    loadUser = () => {
        const accepted = this.props.accepted;
        let that = this;
        this.props.accepted.map(x => {
            fetch(userApi + "/users/get-user", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    data: {
                        contactNo: x,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (!result.error) {
                        const data1 = that.state.usersDetails.concat(result.data);
                        const unique = [];
                        data1.map(x => unique.filter(a => a.contactNo == x.contactNo).length > 0 ? null : unique.push(x));
                        that.setState({ usersDetails: unique });
                    }

                });
        })
    }
    loadTeam = () => {
        const accepted = this.props.accepted;

        let that = this;

        this.props.accepted.map(x => {

            fetch(userApi + "/teams/get-team", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    data: {
                        teamId: x,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    if (!result.error) {
                        const data1 = that.state.teamDetails.concat(result.data.team);
                        const unique = [];
                        data1.map(x => unique.filter(a => a.teamId == x.teamId).length > 0 ? null : unique.push(x));
                        that.setState({ teamDetails: unique });
                    }
                });
        })


    };
    componentDidMount() {
        console.log(this.props)
        if (this.props.challengeType === 'buddy') {
            this.loadUser();
        }
        else {
            this.loadTeam();
        }
    }
    render() {
        var teamIcon, userIcon;
        if (this.props.scheduleType === 'tournament-tt1') {
            if (this.props.challengeType === 'buddy') {
                return (<ul className="sm_ul multi_plyr_ul">
                    {
                        this.state.usersDetails.map((x, index) => {
                            if (this.state.usersDetails.length === index + 1) {
                                this.showMessage = <li style={{ 'display': 'none' }}>
                                    <img className="sm_vs" src={challengefill} alt="" />
                                    <label> Challenge</label>
                                </li>
                            }
                            else {
                                this.showMessage = <li>

                                    <label>{this.props.tournamentName}</label><br></br>
                                    <label>{this.props.sportsName}</label><br></br>
                                    <label>{this.props.scheduleText}</label>
                                </li>
                            }
                            return <React.Fragment key={index}>
                                <li key={index}>
                                    <span className="sm_user" style={{ backgroundImage: 'url(' + x.profilePic + ')' }}></span>
                                    <label>{x.name}</label>
                                </li>
                                {this.showMessage}
                            </React.Fragment>

                        })
                    }
                </ul>
                );
            }
            else {
                return (<ul className="sm_ul multi_plyr_ul" >
                    {
                        this.state.teamDetails.map((team, index) => {
                            if (team.teamIcon == null) {
                                teamIcon = teamImage;
                            }
                            else {
                                teamIcon = team.teamIcon;
                            }

                            if (this.state.teamDetails.length === index + 1) {
                                this.showMessage = <li style={{ 'display': 'none' }}>
                                    <img className="sm_vs" src={challengefill} alt="" />
                                    <label> Challenge</label>
                                </li>
                            }
                            else {
                                this.showMessage = <li>
                                    <label>{this.props.tournamentName}</label><br></br>
                                    <label>{this.props.sportsName}</label><br></br>
                                    <label>{this.props.scheduleText}</label>
                                </li>
                            }
                            return <React.Fragment key={index}>
                                <li key={index}>
                                    <span className="sm_user" style={{ backgroundImage: 'url(' + teamIcon + ')' }}></span>
                                    <label>{team.teamName}</label>
                                </li>
                                {this.showMessage}
                            </React.Fragment>
                        })
                    }
                </ul>
                );
            }
        }
        else if (this.props.scheduleType === 'tournament-tt4') {
            if (this.props.tournamentIcon == null) {
                teamIcon = tournamentprofileimg;
            }
            else {
                teamIcon = this.props.tournamentIcon;
            }
            return (<ul className="sm_ul multi_plyr_ul" >
                {
                    <React.Fragment>

                        {/* <li >
                            <span className="sm_user" style={{ backgroundImage: 'url(' + teamIcon + ')' }}></span>

                        </li> */}
                        <li>
                            <label>{this.props.tournamentName}</label><br></br>
                            <label>{this.props.sportsName}</label><br></br>
                            <label>{this.props.scheduleText}</label>
                        </li>
                    </React.Fragment>
                }
            </ul>
            );
        }
        else if (this.props.scheduleType === 'tournament-tt5') {
            if (this.props.tournamentIcon == null) {
                teamIcon = tournamentprofileimg;
            }
            else {
                teamIcon = this.props.tournamentIcon;
            }
            return (<ul className="sm_ul multi_plyr_ul" >
                {
                    <React.Fragment>

                        {/* <li >
                            <span className="sm_user" style={{ backgroundImage: 'url(' + teamIcon + ')' }}></span>

                        </li> */}
                        <li>
                            <label>{this.props.tournamentName}</label><br></br>
                            <label>{this.props.sportsName}</label><br></br>
                            <label>{this.props.scheduleText}</label>
                        </li>
                    </React.Fragment>
                }
            </ul>
            );
        }
    }
}
export default ScheduleTournamentSection;   