import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import userMaleIcon from "./img/male.png";
import likedark from "./img/likedark.png";
import share from "./img/share.png";
import comment from "./img/comment.png";
import CommentList from "./CommentList";
import LikeList from './LikeList';
import { confirmAlert } from 'react-confirm-alert';
import danger from './img/danger.png';
import userFemaleIcon from "./img/female.png";
import DownloadAppPlatform from './DownloadAppPlatform';
import { Link, Redirect } from 'react-router-dom';
import more from "./img/more.png";
import likebox from "./img/likebox.png";
import Modal1 from 'react-bootstrap/Modal';
import Emoji from './Emoji';
import likegreen from "./img/likegreen.png";
import happiness from './img/happiness.png';
import tournamentprofileimg from './img/tournamentprofileimg.jpg';
import winnerlogo from './img/winnerlogo.png';
import challenegbg from './img/challenegbg.png';
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
var cid;
var dynamicFeedsid5;
class LoginFeedsTournamentBest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            feedReport: false,
            tornamentCreatedData: {},
            tournamentAction: {},
            userData: [],
            commentData: '',
            reasonSelection3: '',
            isShowEmoji: false,
            isProfileRedirection: false
        };
        this.loadMore = this.loadMore.bind(this);
        //   this.getReactedBuddies = this.getReactedBuddies.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
        //const { history } = this.props;
    }
    componentDidMount() {
        this.loadMore();
    }
    childEmojicallback = (emojiObj) => {

        let input = this.state.commentData;
        input += emojiObj.emoji;
        this.setState({ commentData: input });


    }
    newId5 = (id) => {
        this.setState({ feedReport: true })
        dynamicFeedsid5 = id;
    }
    commentFunction = (postId) => {
        this.setState({ isShowEmoji: false })
        this.state.user = JSON.parse(localStorage.getItem("result"));
        if (this.state.commentData) {
            let dummyUser = {
                contactNo: this.state.user.contactNo,
                gender: this.state.user.gender,
                name: this.state.user.name,
                profilePic: this.state.user.profilePic,
            }
            this.setState({ likeListUser: [dummyUser] })
            const contactNumber = this.state.user.contactNo;
            var timestamp = Math.floor(Date.now());
            var tournamentAction = this.state.tournamentAction;

            if (tournamentAction.tournamentId === postId) {
                if (this.state.tournamentAction.hasOwnProperty('reactedBuddies') === false) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "userType": null,
                        "reactedType": "comment",
                        "comment": this.state.commentData,
                        "timestamp": timestamp,
                    }];
                    //  delete tournamentAction.id;   //Always use this in update case 
                    let that = this;
                    tournamentAction.reactedBuddies = reactedBuddies;

                    fetch(userApi + "/tournaments/action", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "tournamentAction": tournamentAction,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {

                            that.setState({ commentData: '', tournamentAction: tournamentAction });
                            that.showAllComments(postId)
                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "userType": null,
                        "reactedType": "comment",
                        "comment": this.state.commentData,
                        "timestamp": timestamp,
                    };

                    delete tournamentAction.id; //Always use this in update case 
                    let that = this;
                    tournamentAction.reactedBuddies.push(reactedBuddies);

                    fetch(userApi + "/tournaments/action", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "tournamentAction": tournamentAction,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {

                            that.setState({ commentData: '', tournamentAction: tournamentAction });
                            that.showAllComments(postId)
                        });
                }
            }

            this.setState({ tournamentAction: tournamentAction });
            // this.loadComponent();
        }
    }
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };

    likeFunction = (postId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        // this.setState({ user: JSON.parse(localStorage.getItem("result")) });
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        // var postAll = [];
        var matchId = this.props.subModuleId;
        var tournamentAction = this.state.tournamentAction;
        if (this.state.tornamentCreatedData.tournamentId === postId) {
            if (this.state.tournamentAction.hasOwnProperty('reactedBuddies') === false) {
                var reactedBuddies = [{
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                }];
                delete tournamentAction.id;   //Always use this in update case 
                tournamentAction.reactedBuddies = reactedBuddies;
                let that = this;
                fetch(userApi + "/tournaments/action", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "tournamentAction": tournamentAction,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        fetch(userApi + "/tournaments/add-chaining", {
                            method: "post",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                data: {
                                    "matchId": matchId,
                                    "tournamentId": that.state.tornamentCreatedData.tournamentId,
                                    "subModuleName": that.props.tournamentCreated,
                                    "contactNo": that.state.user.contactNo,
                                    "action": 'reacted',
                                }
                            })
                        })
                            .then(function (response) {
                                return trackPromise(response.json());
                            })
                            .then(function (res) {


                            });
                    });
            }
            else {
                reactedBuddies = {
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                };

                delete tournamentAction.id; //Always use this in update case 
                //   tournamentAction.reactedBuddies = reactedBuddies;

                tournamentAction.reactedBuddies.push(reactedBuddies);
                let that = this;
                fetch(userApi + "/tournaments/action", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "tournamentAction": tournamentAction,

                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        fetch(userApi + "/tournaments/add-chaining", {
                            method: "post",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                data: {
                                    "matchId": matchId,
                                    "tournamentId": that.state.tornamentCreatedData.tournamentId,
                                    "subModuleName": that.props.tournamentCreated,
                                    "contactNo": that.state.user.contactNo,
                                    "action": 'reacted',
                                }
                            })
                        })
                            .then(function (response) {
                                return trackPromise(response.json());
                            })
                            .then(function (res) {
                            });

                    });
            }
        }
        // return true;   
        this.setState({ tournamentAction: tournamentAction });
        // this.loadComponent();
    }
    showAllComments = (postId) => {

        let reactedBuddies = [];

        reactedBuddies = this.state.tournamentAction.reactedBuddies;

        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (postId) => {
        let reactedBuddies = [];
        reactedBuddies = this.state.tournamentAction.reactedBuddies;
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }
        confirmAlert({
            customUI: () => {
                return (
                    <div>
                        <LikeList data={data} />
                    </div>
                );
            }
        })
    }
    loadMore = () => {
        let that = this;
        var apiUrl = userApi + '/tournaments/get-tournament-only';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    tournamentId: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            that.loadValue(result.data.tournament.createdBy);

            that.setState({ tornamentCreatedData: result.data.tournament, userData: result.data.users });
        });
    }
    feedReportHandel = () => {
        this.setState({ feedReport: false })
    }
    submitReport5 = () => {
        this.setState({ feedReport: false })
        const that = this;
        if (this.state.reasonSelection3 !== '') {
            const reports = userApi + "/newfeeds/toggle-report-feed";
            fetch(reports, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "reportedBy": that.state.user.contactNo,
                        "newFeedsId": dynamicFeedsid5,
                        "cause": that.state.reasonSelection3,
                        "reported": true,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {

                    that.props.pReportCB(dynamicFeedsid5);
                    if (result.data === 'success') {
                        //document.getElementById('reportedTC').style = 'block';
                        that.setState({
                            showingReportAlert5: true
                        });
                        setTimeout(() => {
                            that.setState({
                                showingReportAlert5: false
                            });
                        }, 2000);
                    }
                });
        }
    }

    undoReport5 = () => {
        let that = this;
        const reports = userApi + "/newfeeds/toggle-report-feed";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "reportedBy": this.state.user.contactNo,
                    "newFeedsId": this.props.newFeedsId,
                    "cause": this.state.reasonSelection3,
                    "reported": false,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.props.pReportCB(that.props.newFeedsId);

            });
    }

    //reason selection coding
    clearInputModal5 = () => {
        this.setState({ feedReport: false })
        document.getElementById('reportForm5').reset();
        this.setState({
            reasonSelection3: ''
        });
    }

    reasonSelection3 = (e) => {
        let reasonSelection3 = e.target.value;
        if (e.target.checked === true) {
            switch (reasonSelection3) {
                case 'scam':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                case 'offensive':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                case 'violence':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                case 'prohibited':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                case 'spam':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                case 'falsenews':
                    this.setState({
                        reasonSelection3: reasonSelection3
                    });

                    break

                default:
                    this.setState({
                        reasonSelection3: 'others'
                    });
            }

        }
    }
    loadValue(createdBy) {
        var matchId = this.props.subModuleId;
        let that = this;
        var apiUrl = userApi + '/tournaments/get-actions';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    tournamentId: that.props.moduleId,
                    matchId: matchId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            if (result.statusDetails.code === 200) {
                const data1 = that.state.userData.concat(result.data.users);
                const unique = [];
                data1.map(x => unique.filter(a => a.contactNo == x.contactNo).length > 0 ? null : unique.push(x));
                that.setState({ userData: unique });
                that.setState({ tournamentAction: result.data.tournamentAction });
            }
            else {
                var tournamentAction = {
                    tournamentId: that.props.moduleId,
                    matchId: matchId,
                }
                that.setState({ tournamentAction: tournamentAction });
            }
        });
    }


    profileRedirection = (cNo) => {
        // this.setState({redirectonContacNu:cNo,isProfileRedirection:true})
        window.location = "/buddies?id=" + cNo;
    }



    render() {

        if (this.state.isProfileRedirection) {
            return <Redirect
                to={{
                    pathname: "/buddies?id=" + this.state.redirectonContacNu
                }}
            />

        }

        var userIcon, tournamentprofile, playerIcon, playerName;
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(this.state.tornamentCreatedData.createdOn);
        var res = formattedTimestamp.split(",");
        var feadsDate = res[0] + "," + res[1];
        var todayDate = new Date(new Date().setDate(new Date().getDate()));
        todayDate = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(todayDate);
        var res1 = todayDate.split(",");
        todayDate = res1[0] + "," + res1[1];
        var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
        yesterdayDate = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        }).format(yesterdayDate);
        var res2 = yesterdayDate.split(",");
        yesterdayDate = res2[0] + "," + res2[1];

        if (feadsDate === todayDate) {
            feadsDate = "Today" + "," + res[2];
        }
        else if (feadsDate === yesterdayDate) {
            feadsDate = "Yesterday" + "," + res[2];
        }
        else {
            feadsDate = feadsDate + "," + res[2];
        }
        var reportValue = this.props.reported;
        if (reportValue === false) {
            return <React.Fragment>
                {/* Start Report Abuse */}
                <Modal1
                    size="md"
                    show={this.state.feedReport}
                    onHide={() => this.feedReportHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    {/* <div id="reportabusePost" className="modal fade" role="dialog"> */}
                    {/* <div className="modal-dialog"> */}
                    {/* Modal content Report Feeds */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={() => this.clearInputModal5()} type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Report this feeds</h4>
                        </div>
                        <div className="modal-body">
                            <label className="reportlabel">Take a moment and tell us what's wrong with this post</label>
                            <form id="reportForm">
                                <ul className="reportul">
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost1" value="scam" />
                                            <label htmlFor="resportPost1">Scam</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost2" value="offensive" />
                                            <label htmlFor="resportPost2">Offensive </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost3" value="violence" />
                                            <label htmlFor="resportPost3">Violence </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost4" value="others" />
                                            <label htmlFor="resportPost4">Others</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost5" value="spam" />
                                            <label htmlFor="resportPost5">Spam</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost6" value="falsenews" />
                                            <label htmlFor="resportPost6">False-news</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="">
                                            <input onChange={this.reasonSelection3.bind(this)} type="radio" name="fieldset-1" id="resportPost7" value="prohibited" />
                                            <label htmlFor="resportPost7">Prohibited </label>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <p id="reportedPost" style={{ display: 'none' }} className={`alert alert-success ${this.state.showingReportAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                <strong>Feed Reported!</strong>
                            </p>
                            <button onClick={() => this.submitReport5()} type="button" className="cmnbutton">Submit</button>
                            <button onClick={() => this.clearInputModal5()} type="button" className="btndark cmnbutton" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                    {/* End Report Abuse */}
                </Modal1>
                {this.state.userData.map(userData => {

                    var awardFor;
                    if (this.props.tournamentBest === 'tournamentBestBatsman') {
                        awardFor = 'Best Batsman';
                        if (userData.contactNo === this.state.tornamentCreatedData.bestBatsman) {
                            if (userData.profilePic === null) {
                                if (userData.gender === "Male") {
                                    playerIcon = userMaleIcon;
                                }
                                else {
                                    playerIcon = userFemaleIcon;
                                }
                            }
                            else {
                                playerIcon = userData.profilePic;

                            }
                            playerName = userData.name;
                        }
                    }
                    if (this.props.tournamentBest === 'tournamentBestBowler') {
                        awardFor = 'Best Bowler';
                        if (userData.contactNo === this.state.tornamentCreatedData.bestBowler) {
                            if (userData.profilePic === null) {
                                if (userData.gender === "Male") {
                                    playerIcon = userMaleIcon;
                                }
                                else {
                                    playerIcon = userFemaleIcon;
                                }
                            }
                            else {
                                playerIcon = userData.profilePic;

                            }
                            playerName = userData.name;
                        }
                    }
                    if (this.props.tournamentBest === 'tournamentBestFielder') {
                        awardFor = 'Best Fielder';
                        if (userData.contactNo === this.state.tornamentCreatedData.bestFielder) {
                            if (userData.profilePic === null) {
                                if (userData.gender === "Male") {
                                    playerIcon = userMaleIcon;
                                }
                                else {
                                    playerIcon = userFemaleIcon;
                                }
                            }
                            else {
                                playerIcon = userData.profilePic;
                            }
                            playerName = userData.name;
                        }
                    }
                    if (this.props.tournamentBest === 'tournamentMos') {
                        awardFor = 'Man Of The Series';
                        if (userData.contactNo === this.state.tornamentCreatedData.manOfTheSeries) {
                            if (userData.profilePic === null) {
                                if (userData.gender === "Male") {
                                    playerIcon = userMaleIcon;
                                }
                                else {
                                    playerIcon = userFemaleIcon;
                                }
                            }
                            else {
                                playerIcon = userData.profilePic;
                            }
                            playerName = userData.name;
                        }
                    }
                    var buttonDisable = false;
                    var likeCount = 0; var commentCount = 0; var buddyName = null;
                    if (this.state.tournamentAction.hasOwnProperty('reactedBuddies')) {
                        if (this.state.tournamentAction.reactedBuddies !== null) {
                            this.state.tournamentAction.reactedBuddies.map((z) => {
                                if (z.reactedType === "cheer") {
                                    if (z.buddyId === this.state.user.contactNo) {
                                        buttonDisable = true;
                                    }
                                    this.state.userData.map((buddy) => {
                                        if (buddy.contactNo === z.buddyId) {
                                            buddyName = buddy.name;
                                        }
                                        return buddyName;
                                    })
                                    likeCount++;
                                }
                                if (z.reactedType === "comment") {
                                    commentCount++;
                                }
                                return likeCount;
                            });
                            if (buddyName === null) {
                                this.state.tournamentAction.reactedBuddies.map((z) => {
                                    this.state.userData.map((user) => {
                                        if (user.contactNo === z.buddyId) {
                                            buddyName = user.name;

                                        }
                                        return buddyName;
                                    })
                                    return true;
                                })
                            }
                        }
                    }
                    if (this.state.tornamentCreatedData.tournamentIcon !== null) {
                        tournamentprofile = this.state.tornamentCreatedData.tournamentIcon;
                    }
                    else {
                        tournamentprofile = tournamentprofileimg;
                    }

                    if (userData.contactNo === this.state.tornamentCreatedData.createdBy) {
                        if (userData.profilePic === null) {
                            if (userData.gender === "Male") {
                                userIcon = userMaleIcon;
                            }
                            else {
                                userIcon = userFemaleIcon;
                            }
                        }
                        else {
                            userIcon = userData.profilePic;
                        }
                        if (this.state.user.contactNo !== this.props.sentBy) {
                            this.moreButton = <button className="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" /></button>;
                        }
                        return <div key={userData.name} className="divpost">
                            <div className="userdetail">
                                <ul className="postuserul">
                                    <li onClick={() => this.profileRedirection(this.state.tornamentCreatedData.createdBy)} className="p_userli"> <span className="p_userimg" style={{
                                        backgroundImage: "url(" + userIcon + ")"
                                    }}> </span>
                                        <label className="cursorPointer" onClick={() => this.profileRedirection(this.state.tornamentCreatedData.createdBy)} >{this.state.tornamentCreatedData.creatorName}</label>
                                        <label><span>Tournaments </span></label>
                                    </li>
                                    <p className="broadcast_date">{feadsDate}</p>
                                    <li className="p_usermore">

                                        <div className="dropdown dropaccount">
                                            {this.moreButton}

                                            <ul className="dropdown-menu">
                                                <li>
                                                    <Link onClick={(e) => this.newId5(this.props.newFeedsId)} data-toggle="modal" data-target="#reportabuse5"> Report Abuse </Link>

                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="postdata">
                                <div className="teampost_inner tour_inner_feed" style={{
                                    backgroundImage: "url(" + + ")"
                                }}>
                                    <label className="teamhead">  {this.state.tornamentCreatedData.tournamentName + " " + this.state.tornamentCreatedData.sportsName}</label>
                                    <div className="tournament_winner_div">

                                        <h4>Congratulations !</h4>
                                    </div>
                                    <ul className="postteam">
                                        <img src={winnerlogo} className="imgwinner tour_win_logo" alt="" />
                                        <li>
                                            <span className="teamimg" style={{
                                                backgroundImage: "url(" + playerIcon + ")"
                                            }} ></span>
                                        </li>
                                    </ul>

                                    <label className="teamname">{playerName}</label>
                                    <label className="teamname_winner"> <h4>{awardFor}</h4></label>
                                </div>
                            </div>
                            <div className="postlikediv">
                                {(() => {
                                    switch (likeCount) {
                                        case 1:
                                            switch (commentCount) {
                                                case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span > liked this </span>
                                                </span>;
                                                default: return <span className="userlikeinner"> <span ><img src={likebox} alt="" /> <b>{buddyName}</b> liked this</span><span key={this.state.tornamentCreatedData.tournamentId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.tornamentCreatedData.tournamentId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.props.newFeedsId} > {commentCount} comments</span>
                                                </span>
                                            }
                                        case 0:
                                            switch (commentCount) {
                                                case 0: return '';
                                                default: return <span className="userlikeinner"><span key={this.state.tornamentCreatedData.tournamentId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.tornamentCreatedData.tournamentId)}> {commentCount} comments</span>
                                                </span>;
                                            }
                                        default:
                                            switch (commentCount) {
                                                case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.tornamentCreatedData.tournamentId)}> and {likeCount - 1}more liked this </span>
                                                </span>;
                                                default: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.tornamentCreatedData.tournamentId)}> and {likeCount - 1}more liked this</span><span key={this.state.tornamentCreatedData.tournamentId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.tornamentCreatedData.tournamentId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.props.newFeedsId}> {commentCount} comments</span>
                                                </span>
                                            }
                                    }
                                })()}
                                <ul className="commentul">
                                    {buttonDisable ? <li className="commentli commentinner">
                                        <img src={likegreen} className="likeuser" alt="" />
                                        <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount}</span></span>
                                    </li> : <li className="commentli commentinner" onClick={(e) => this.likeFunction(this.state.tornamentCreatedData.tournamentId)}>
                                        <img src={likedark} className="likeuser" alt="" />
                                        <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount} </span></span>
                                    </li>}
                                    <li key={this.state.tornamentCreatedData.tournamentId} className="commentinner commentinner2" id="comment1">
                                        <img src={comment} className="commentimg" alt="" />
                                        <span data-toggle="collapse" data-parent="#accordion" href={'#' + this.props.newFeedsId} onClick={(e) => this.setState({ commentList: false })}>Comment<span className=" spancount">{commentCount}</span></span> </li>
                                    <li className="commentinner commentinner3" id="" >
                                        <Link to="#" className="" data-toggle="modal" data-target="#myModal"><img src={share} className="commentimg" alt="" />
                                            <span>Share<span className="spancount"></span></span></Link> </li>
                                </ul>
                                <div className="postcomment" id={this.props.newFeedsId}> <span className="" style={{
                                    backgroundImage: "url(" + userMaleIcon + ")"
                                }}></span>
                                    {this.state.commentList ? <CommentList data={this.state.data} /> : <div></div>}
                                    <div key={this.props.newFeedsId} className="postcomment_div" style={{ marginTop: '10px' }}> <Link to="#"><img onClick={() => this.setState({ isShowEmoji: !this.state.isShowEmoji })} src={happiness} alt="" /></Link>
                                        <textarea id={this.props.newFeedsId} value={this.state.commentData} onChange={event => this.setState({ commentData: event.target.value })}></textarea>
                                        <button onClick={(e) => this.commentFunction(this.state.tornamentCreatedData.tournamentId)}>Post</button>
                                    </div>
                                    {this.state.isShowEmoji ? <Emoji parentEmojiCallback={this.childEmojicallback} /> : ''}
                                </div>
                            </div>
                        </div>

                    }
                })
                }
            </React.Fragment>
        }
        else {
            return (
                <React.Fragment>
                    <div className="divpost">
                        <ul className="reportfeed">
                            <li className="reportli1"><img src={danger} /></li>
                            <li className="reportli2">
                                <label>Feeds Reported</label>
                                <p>We would not show you this post again</p>
                            </li>
                            <li className="reportli3"><button onClick={() => this.undoReport5()}>Undo</button></li>
                        </ul>
                    </div>
                </React.Fragment>
            );
        }

    }
}

export default LoginFeedsTournamentBest;