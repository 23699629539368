import React, { Component, createRef } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '..//..//ConfigUrl';
import Modal1 from 'react-bootstrap/Modal'
import { trackPromise } from "react-promise-tracker";
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import finallogo from '..//..//img/finallogo.png';
import NumericInput from 'react-numeric-input';
import broadcastbg from '..//..//img/broadcastbg.png';
import play from '..//..//img/play.png';
import $ from 'jquery';
import logo from '..//..//img/finallogo.png';
var score;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};
export default class DemoUpdate_d107Golf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tt5GolfScoreCard: {},
            golfScoreCards: [],
            teams: [],
            users: [],
            holes: [],
            selectset: false,
            holeType: 9,
            course: {},
        };
        this.done = this.done.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.getScore = this.getScore.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.state.user = JSON.parse(localStorage.getItem("result"));
    }
    getCourse() {
        const apiUrl = rshApi + "/global/get-course";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "courseId": that.props.challenge.courseId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({ course: result.data, holes: result.data.holes, holeType: result.data.holeType })
            });
    }

    getScore() {

        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {
                        that.saveIntialSet();
                    }
                    else {
                        if (result.data.mode === 'buddy') {
                            that.setState({
                                golfScoreCards: result.data.score.tt5GolfScoreCard.golfScoreCards, tt5GolfScoreCard: result.data.score.tt5GolfScoreCard,
                                users: result.data.users
                            })
                        }
                        else {
                            that.setState({
                                golfScoreCards: result.data.score.tt5GolfScoreCard.golfScoreCards, tt5GolfScoreCard: result.data.score.tt5GolfScoreCard,
                                teams: result.data.teams
                            })
                        }
                    }
                });
        }
    }
    saveIntialSet = () => {
        this.setState({ teams: this.props.profileTeams, users: this.props.profileUsers });
        var rounds = [];
        let HoleType = this.state.holeType;
        var scoreArray = [];
        for (let i = 1; i <= HoleType; i++) {
            scoreArray.push({ holeNo: i, value: 0, oldValue: 0, oldStokeNo: 0, strokeNo: 0 })
        }

        var newRound = {
            roundNo: 1,
            saved: false,
            scores: scoreArray,
            total: 0
        }

        rounds.push(newRound);
        var golfScoreCards = [];
        this.props.challenge.accepted.map(accepted => {
            golfScoreCards.push({
                allRoundCompleted: false,
                round1Total: 0,
                round2Total: 0,
                round3Total: 0,
                round4Total: 0,
                rounds: rounds,
                strokeNo: 0,
                totalScore: 0,
                userId: accepted,
                winner: false,
            })
        })
        var tt5GolfScoreCard = {
            broadcastIds: null,
            date: null,
            golfScoreCards: golfScoreCards,
            matchId: null,
            time: null
        }
        this.setState({ golfScoreCards: golfScoreCards, tt5GolfScoreCard: tt5GolfScoreCard });
        var score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            tt5GolfScoreCard: tt5GolfScoreCard,
        }
        DemoUpdateChallengeScore(score);
        this.getScore();
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false, setModalIsOpen: false });
    }
    cahngeScoreValue(uid, roundNo, holeNO) {

        this.setState({ LocalUid: uid, LocalRoundNo: roundNo, LocalHoleNO: holeNO })
        if (this.state.user.contactNo === uid) {

            var golfScoreCards = this.state.golfScoreCards;
            golfScoreCards.map(golfScore => {
                if (golfScore.userId === uid) {
                    if (golfScore.allRoundCompleted === true) {

                    }
                    else {
                        this.setState({ setModalIsOpen: true });
                    }
                }
            })

        }
    }
    done(event) {
        this.setState({ setModalIsOpen: false });
        var golfPoint = parseInt(document.getElementsByName("point")[0].value);
        this.setState({ golfPoint: golfPoint });
        if (this.state.LocalHoleNO !== this.state.holeType) {

            var golfPoint = parseInt(document.getElementsByName("point")[0].value);
            var strokeNo;
            var tt5GolfScoreCard = this.state.tt5GolfScoreCard;
            var golfScoreCards = this.state.golfScoreCards;
            golfScoreCards.map(golfScore => {
                if (golfScore.userId === this.state.LocalUid) {
                    strokeNo = golfScore.strokeNo;
                    if (this.state.LocalRoundNo === 1) {
                        golfScore.round1Total = golfScore.round1Total + golfPoint;
                    }
                    if (this.state.LocalRoundNo === 2) {
                        golfScore.round2Total = golfScore.round2Total + golfPoint;
                    }
                    if (this.state.LocalRoundNo === 3) {
                        golfScore.round3Total = golfScore.round3Total + golfPoint;
                    }
                    if (this.state.LocalRoundNo === 4) {
                        golfScore.round4Total = golfScore.round4Total + golfPoint;
                    }
                    golfScore.rounds.map(round => {
                        if (round.roundNo === this.state.LocalRoundNo) {
                            round.total = round.total + golfPoint;
                            round.scores.map(score => {
                                if (score.holeNo === this.state.LocalHoleNO) {
                                    score.value = golfPoint;
                                    score.strokeNo = strokeNo + 1;
                                }
                            })
                            if (this.state.LocalHoleNO === this.state.holeType) {
                                round.saved = true;
                            }
                        }
                    })

                    golfScore.strokeNo = strokeNo + 1;

                } console.log(golfScore)
            })
            tt5GolfScoreCard.golfScoreCards = golfScoreCards;
            this.setState({ golfScoreCards: golfScoreCards, tt5GolfScoreCard: tt5GolfScoreCard });
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                tt5GolfScoreCard: tt5GolfScoreCard,
            }
            DemoUpdateChallengeScore(score);
        }
        else {
            this.setState({ selectset: true })
        }
    }
    saveRound() {
        this.setState({ selectset: false })
        let parSum = this.state.holes.reduce((a, b) => a + b['par'], 0)
        let parTotal = parSum * this.props.challenge.courseRound;
        var golfPoint = this.state.golfPoint;
        var strokeNo;
        var tt5GolfScoreCard = this.state.tt5GolfScoreCard;
        var golfScoreCards = this.state.golfScoreCards;
        golfScoreCards.map(golfScore => {
            if (golfScore.userId === this.state.LocalUid) {
                strokeNo = golfScore.strokeNo;
                if (this.state.LocalRoundNo === 1) {
                    golfScore.round1Total = golfScore.round1Total + golfPoint;
                    // if (parSum < golfScore.round1Total) {
                    golfScore.totalScore = golfScore.round1Total - parSum;
                    // }

                }
                if (this.state.LocalRoundNo === 2) {
                    golfScore.round2Total = golfScore.round2Total + golfPoint;
                    // if (parSum < golfScore.round1Total) {
                    golfScore.totalScore = golfScore.totalScore + golfScore.round2Total - parSum;
                    // }
                }
                if (this.state.LocalRoundNo === 3) {
                    golfScore.round3Total = golfScore.round3Total + golfPoint;
                    // if (parSum < golfScore.round1Total) {
                    golfScore.totalScore = golfScore.totalScore + golfScore.round3Total - parSum;
                    // }
                }
                if (this.state.LocalRoundNo === 4) {
                    golfScore.round4Total = golfScore.round4Total + golfPoint;
                    // if (parSum < golfScore.round1Total) {
                    golfScore.totalScore = golfScore.totalScore + golfScore.round4Total - parSum;
                    // }
                }
                golfScore.rounds.map(round => {
                    if (round.roundNo === this.state.LocalRoundNo) {
                        round.total = round.total + golfPoint;
                        round.scores.map(score => {
                            if (score.holeNo === this.state.LocalHoleNO) {
                                score.value = golfPoint;
                                score.strokeNo = strokeNo + 1;
                            }
                        })
                        if (this.state.LocalHoleNO === this.state.holeType) {
                            round.saved = true;
                        }
                    }
                })
                golfScore.strokeNo = strokeNo + 1;
                if (this.state.LocalHoleNO === this.state.holeType) {
                    golfScore.strokeNo = 0;
                    if (this.props.challenge.courseRound === this.state.LocalRoundNo) {
                        golfScore.allRoundCompleted = true;
                    }
                    else {
                        let HoleType = this.state.holeType;
                        var scoreArray = [];
                        for (let i = 1; i <= HoleType; i++) {
                            scoreArray.push({ holeNo: i, value: 0, oldValue: 0, oldStokeNo: 0, strokeNo: 0 })
                        }
                        let newLocalRound = this.state.LocalRoundNo;
                        var newRound = {
                            roundNo: newLocalRound + 1,
                            saved: false,
                            scores: scoreArray,
                            total: 0
                        }

                        golfScore.rounds.push(newRound)
                    }
                }
            }
        })
        this.setState({ selectset: false })

        let complete = false;
        golfScoreCards.map(golfScore => {
            if (golfScore.allRoundCompleted === true) {
                complete = true;
            }
            else {
                complete = false;
            }
        })
        if (complete === true) {
            var max = golfScoreCards.reduce(function (prev, curr) {
                return prev.totalScore > curr.totalScore ? prev : curr;
            });
            golfScoreCards.map(golfScore => {
                if (golfScore.userId === max.userId) {
                    golfScore.winner = true;
                }
            })

            this.setState({ winnerId: max.userId });

            tt5GolfScoreCard.golfScoreCards = golfScoreCards;
            this.setState({ golfScoreCards: golfScoreCards, tt5GolfScoreCard: tt5GolfScoreCard });
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                tt5GolfScoreCard: tt5GolfScoreCard,
            }
            DemoUpdateChallengeScore(score);
            this.doneMatch(max.userId);
        }
        else {
            tt5GolfScoreCard.golfScoreCards = golfScoreCards;
            this.setState({ golfScoreCards: golfScoreCards, tt5GolfScoreCard: tt5GolfScoreCard });
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                tt5GolfScoreCard: tt5GolfScoreCard,
            }
            DemoUpdateChallengeScore(score);
        }
    }
    doneMatch(winnerid) {
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winnerid;

        let that = this;
        const apiUrl = rshApi + "/demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                window.location = "/dchallengeprofile/index.html?id=" + that.props.challenge.challengeId;

            });
    }
    editRound() {
        this.setState({ selectset: false })
    }

    ToundoList = () => {
        let userId = this.state.user.userId;
        let strokeNo, value;
        let roundNo, totalValue;
        var tt5GolfScoreCard = this.state.tt5GolfScoreCard;
        var golfScoreCards = this.state.golfScoreCards;
        golfScoreCards.map(gScore => {
            if (gScore.userId === userId) {
                strokeNo = gScore.strokeNo;
                if (gScore.allRoundCompleted === false) {
                    gScore.rounds.map(round => {
                        if (round.saved === false) {
                            round.scores.map(score => {
                                if (score.strokeNo === strokeNo) {
                                    value = score.value;
                                    score.value = 0;
                                    score.strokeNo = 0;
                                }
                            });
                            round.total = round.total - value;
                            totalValue = round.total;
                            roundNo = round.roundNo;
                        }
                    })
                    if (roundNo === 1) {
                        gScore.round1Total = totalValue;
                    }
                    if (roundNo === 2) {
                        gScore.round2Total = totalValue;
                    }
                    if (roundNo === 3) {
                        gScore.round3Total = totalValue;
                    }
                    if (roundNo === 4) {
                        gScore.round4Total = totalValue;
                    }

                    gScore.strokeNo = strokeNo - 1;

                }
            }
        })

        tt5GolfScoreCard.golfScoreCards = golfScoreCards;
        this.setState({ golfScoreCards: golfScoreCards, tt5GolfScoreCard: tt5GolfScoreCard });
        var score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            tt5GolfScoreCard: tt5GolfScoreCard,
        }
        DemoUpdateChallengeScore(score);
    }


    componentDidMount() {
        this.getCourse();
        this.getScore();
    }

    render() {
        var parSum;
        var parValue = [];
        var parSum = this.state.holes.reduce((a, b) => a + b['par'], 0)
        var parTotal = parSum * this.props.challenge.courseRound;

        for (var i = 1; i <= this.props.challenge.courseRound; i++) {
            parValue.push(parSum);
        }
        return (
            <React.Fragment>
                <Modal isOpen={this.state.setModalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">  <div ref={subtitle => this.subtitle = subtitle}>
                        <div ref={subtitle => this.subtitle = subtitle}> <h3> Golf Stroke Picker</h3></div>
                        <NumericInput mobile min={0}

                            max={50}
                            name="point"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Point"
                            required
                            size={25}
                            value={5}
                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }}
                        /> <br></br>

                    </div>
                    <br></br>
                    <div className="button_div">
                        <button className="btn btn-inverse" onClick={this.closeModal} style={{ 'margin': '2px' }}>Cancel</button>
                        <button className="cmnbutton" type="button" onClick={this.done} style={{ 'margin': '2px' }}>Done</button>
                    </div>
                </Modal>
                <Modal1
                    size="md"
                    show={this.state.selectset}
                    onHide={() => this.selectsetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Confirmation !</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <p>Are you Sure Want To Save Round ? </p>
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.editRound()}>Edit</button>
                                <button type="button" className="Setscoresave-d" onClick={() => this.saveRound()}>Save</button>

                            </div>
                        </div>
                    </div>
                </Modal1>


                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " >
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>Over All Total</th>
                                            <th>Current Round</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.golfScoreCards.map(gScore => {
                                            var GScoreRound = gScore.rounds.reduce(function (prev, curr) {


                                                return prev.roundNo > curr.roundNo ? prev : curr;

                                            });

                                            let userName;
                                            let userData = false;
                                            this.state.users.map(user => {
                                                if (user.contactNo === gScore.userId) {
                                                    userName = user.name;
                                                    userData = true;
                                                }
                                            });
                                            if (userData) {
                                                return <tr key={gScore.userId}>
                                                    <td>{userName}</td>
                                                    <td><input value={gScore.totalScore} class="inputsize-d" /></td>
                                                    <td><input value={GScoreRound.roundNo} class="inputsize-d" /></td>
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>Holes</td>
                                            {this.state.holes.map(hole => {
                                                return <td><label class="holesno_golf-d">{hole.holeNo}</label></td>
                                            })}

                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>PAR</td>
                                            {this.state.holes.map(hole => {
                                                return <td>{hole.par}</td>
                                            })}


                                        </tr>

                                        <React.Fragment>
                                            {this.state.golfScoreCards.map(gScore => {
                                                var valueTd = [];
                                                var GScoreRound = gScore.rounds.reduce(function (prev, curr) {

                                                    return prev.roundNo > curr.roundNo ? prev : curr;

                                                });
                                                GScoreRound.scores.map(score => {
                                                    valueTd.push(<td><input onClick={() => this.cahngeScoreValue(gScore.userId, GScoreRound.roundNo, score.holeNo)} value={score.value} class="inputsize-d" /></td>);

                                                })
                                                let userName;
                                                let userData = false;
                                                this.state.users.map(user => {
                                                    if (user.contactNo === gScore.userId) {
                                                        userName = user.name;
                                                        userData = true;
                                                    }
                                                });
                                                return <tr><td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>{userName}</td>

                                                    {valueTd}
                                                </tr>

                                            })}
                                        </React.Fragment>

                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {parValue.map((v, index) => {
                                                return <th>Round {index + 1}</th>
                                            })}

                                            <th>Total</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        <tr>
                                            <td>PAR</td>
                                            {parValue.map(v => {
                                                return <td>{v}</td>
                                            })}
                                            <td>{parTotal}</td>
                                            <td>-</td>

                                        </tr>

                                        <React.Fragment>
                                            {this.state.golfScoreCards.map(gScore => {
                                                var valueTd = [];
                                                if (gScore.rounds.length === parValue.length) {
                                                    gScore.rounds.map(round => {
                                                        valueTd.push(<td>{round.total}</td>);
                                                    });
                                                }
                                                else {
                                                    gScore.rounds.map(round => {
                                                        valueTd.push(<td>{round.total}</td>);
                                                    });
                                                    let len = parValue.length - gScore.rounds.length;
                                                    for (let i = 0; i < len; i++) {
                                                        valueTd.push(<td>0</td>);
                                                    }
                                                }
                                                let userName;
                                                let userData = false;
                                                this.state.users.map(user => {
                                                    if (user.contactNo === gScore.userId) {
                                                        userName = user.name;
                                                        userData = true;
                                                    }
                                                });
                                                return <tr><td >{userName}</td>

                                                    {valueTd}
                                                    <td>{gScore.round1Total + gScore.round2Total + gScore.round3Total + gScore.round4Total}</td>
                                                    <td>{gScore.totalScore}</td>

                                                </tr>


                                            })}
                                        </React.Fragment>

                                    </tbody>
                                </table>
                            </div>
                        </div>



                        <div class="table_actions winner_actions">
                            {/* <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>undo</button> */}
                            <a class="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal">End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
