//THIS COMPONENT HAS CHALLENGE LIST AS WELL AS IT HAS CREATE CHALLENGE CODE IN IT.
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import TimeClock from '../../TimeClock';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GetDateFormat from '../../GetDateFormat';
import filter from '../../img/filter.png';
import challengefill from '../../img/challengefill.png';
import schedulematches from '../../img/schedulematches.png';
import location from '../../img/location.png';
import searchnew from '../../img/searchnew.png';
import maleIcon from '../../img/male.png';
import femaleIcon from '../../img/female.png';
import DChallengeList from '../../DemoChallenge/DChallengeList'
import showDefaultTeam from '../../img/team.png';
import GetCurrentTimestamp from '../../GetCurrentTimestamp';
import NoTeam from "../../img/mytournament.svg";
import ConfigUrl from '../../ConfigUrl';
import teamIcon from '../../img/team.png';
import $, { now } from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Map from '../../Map';
import inter from '../../img/inter.svg';



import Header from '../../Header';
import SectionLeft from '../../SectionLeft';
import SectionRight from '../../SectionRight';


const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const currentTimeStamp = GetCurrentTimestamp();

const Dummychallenge = {
    sportsId: null,
    sportsName: null,
    categoryId: null,
    challengeType: null,
    challengeLevel: null,
    city: null,
    state: null,
    country: null,
    location: null,
    latitude: null,
    longitude: null,
    challengePrivacy: 'public',
    challengeDate: null,
    challengeTime: null,
    createdOn: currentTimeStamp,
    modifiedOn: currentTimeStamp,
    active: true,
    createdBy: null,
    challengeAction: 'requested',
    gender: null,
    openChallenge: null,
    sdType: null,
    invited: null,
    openForChooseChalengeType: true

}


let errorMessage = null;
function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}
function searchingFor1(term1) {
    return function (x) {
        return x.name.toLowerCase().includes(term1.toLowerCase()) || !term1;
    }
}
function searchingFor2(term2) {
    return function (x) {
        return x.teamName.toLowerCase().includes(term2.toLowerCase()) || !term2;
    }
}

class ChallengeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            challengeList: [],
            challengeListNew: [],
            thrown: [],
            received: [],
            teamFirstPart: true, //hide and show divs
            term: '', //for searchbar
            term1: '', //for searchbar
            term2: '', //for searchbar

            showliDistance: false,
            showliWeight: false,
            showliOvers: false,
            showLis: false,
            showliGender: false,
            showliSelectOppo: false,
            showliSelectTeam: false,
            typeOfInput: "radio",
            optionsChecked: [],
            optionsChecked1: [],

            date1: new Date(),
            // date1: '',
            date2: GetDateFormat(new Date()),
            allSports: [],
            takeOnlySport: '',
            takeOnlySportIds: '',
            takeOnlyCategory: '',
            genderSelection: 'Male',
            challengeTO: '',
            showOppo: 'City',

            finalFilterData: [],
            finalFilterDataTeam: [],
            finalFilterDataOrg: [],
            invitedBudd: [],
            invitedBudNo: [],
            invitedBudNo1: null,
            invitedBudNo2: null,
            invitedTeamNo1: null,
            invitedTeamNo2: null,
            mySelectedTeam: [],
            mySelectedTeamId: [],
            openChallenge: false,
            appliedUser: [],
            noUserTeam: false,
            openChallengeTeam: false,
            showCreatePart: false,
            showListPart: true,
            defaultDisableForSingle: false,
            defaultDisableForTeam: false,
            defaultCheckedS: false,
            defaultCheckedT: false,
            defaultCheckedBuddyTo: false,
            defaultCheckedTeamTo: false,
            defaultCheckedBuddyOpenTo: false,
            defaultCheckedTeamOpenTo: false,
            defaultCheckedMale: true,
            defaultCheckedFemale: false,
            defaultCheckedMix: false,
            defaultCheckedCity: true,
            defaultCheckedState: false,
            defaultCheckedCountry: false,
            openForChooseChalengeType: true,
            modalOpentForTeamInvite: false,
            modalOpentForInvite: false,
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.searchHandler = this.searchHandler.bind(this);
        this.searchHandler1 = this.searchHandler1.bind(this);
        this.searchHandler2 = this.searchHandler2.bind(this);
        this.liRefs = [React.createRef()];
    }

    static getDerivedStateFromProps(nextProps, state) {

        if (nextProps.match.path === '/page/challenge/create/:pageId') {
            return { teamFirstPart: false };
        }
        if (nextProps.match.path === '/page/challenge/list/:pageId') {
            return { teamFirstPart: true, openForChooseChalengeType: false };
        }
        else return null;
    }



    callbackFunction1 = (childData) => {
        if (childData.city) {
            if (childData.state) {
                this.setState({ mapData: childData, openLocation: false })
                let user = { ...this.state.user };
                user.address = childData.address;
                user.latitude = childData.latValue;
                user.longitude = childData.lngValue;
                user.city = childData.city;
                user.state = childData.state;
                user.country = childData.country;
                this.setState({ address: childData.address, latitude: childData.latValue, longitude: childData.lngValue, city: childData.city, state: childData.state, country: childData.country, user: user })
            }
        }
    }
    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }
    searchHandler1(event) {
        this.setState({ term1: event.target.value })
    }
    searchHandler2(event) {
        this.setState({ term2: event.target.value })
    }

    selectSingle = () => {
        this.setState({ sdType: 'singles', single: true, doubleTeam: false, defaultCheckedS: true, defaultCheckedT: false, defaultCheckedBuddyTo: true, defaultCheckedTeamTo: false, showliGender: false, defaultCheckedTeamTo: false, defaultCheckedTeamOpenTo: false, showliSelectOppo: true, openChallenge: false, showliSelectTeam: false, genderSelection: 'Select Gender', challengeTO: 'buddy' })

    }
    selectDoubleTeams = () => {
        this.setState({ sdType: 'doubles', single: false, doubleTeam: true, defaultCheckedS: false, defaultCheckedT: true, defaultCheckedBuddyTo: false, defaultCheckedTeamTo: true, challengeTO: 'team', showliGender: true, showliSelectOppo: true, showliSelectTeam: true, genderSelection: 'Male', openChallenge: true })
    }
    selectTeamsOption = () => {
        this.setState({ sdType: null, single: false, doubleTeam: true, defaultCheckedS: false, defaultCheckedT: true, defaultCheckedBuddyTo: false, defaultCheckedTeamTo: true, defaultCheckedTeamOpenTo: false, challengeTO: 'team', showliGender: true, showliSelectOppo: true, showliSelectTeam: true, genderSelection: 'Male', openChallenge: true })
    }
    openChallange = (event) => {
        if (event.target.checked === true) {
            this.setState({ openChallenge: true, showliSelectOppo: false });
            if (this.state.single) {
                this.setState({ defaultCheckedBuddyTo: false, defaultCheckedBuddyOpenTo: true });
            }
            else {
                this.setState({ defaultCheckedTeamTo: false, defaultCheckedTeamOpenTo: true, showliSelectTeam: true });
            }
        }
    }


    createDemochallenge = () => {
        this.setState({ showCreatePart: false, showListPart: false, showListDemo: true });
    }
    //Calendar Data
    startDate = (date) => {
        this.setState({
            date1: date,
            date2: GetDateFormat(date)
        });
        console.log(this.state.date2)
    }

    openChallengeRedirect = (challange) => {
        if (challange.challengeType === 'buddy') {
            if (challange.users === null || challange.users.length < 1) {
                $('#openChallengeModal1').click();
                this.setState({ noUserTeam: true });
            }
            else {
                this.setState({ appliedUser: challange.users, openChallangeId: challange.challengeId });
                $('#openChallengeModal1').click();
            }
        }
        else {
            if (challange.teams === null || challange.teams.length < 1) {
                $('#openChallengeModal1').click();
                this.setState({ noUserTeam: true, openChallengeTeam: true });
            }
            else {
                this.setState({ appliedTeam: challange.teams, openChallangeId: challange.challengeId, openChallengeTeam: true });
                $('#openChallengeModal1').click();
            }
        }
    }

    acceptUserOpenChallenge = (user) => {

        const apiUrl = rshApi + "/challenges/react-on-open-challenge";
        let that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    challengeId: this.state.openChallangeId,
                    id: user,
                    action: "accepted",
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                $("#openChallengeModal .close").click();
                window.location.href = "/challengeprofile/index.html?id=" + that.state.openChallangeId;
            });

    }
    //Clock Data
    callbackFunction = (childData) => {

        childData = childData.split(' ');
        if (childData[1].toLowerCase() === 'pm') {
            childData = childData[0].split(':');
            childData = (12 + parseInt(childData[0])) + ":" + childData[1]
            this.setState({ timeByProp: childData })
        }
        if (childData[1].toLowerCase() === 'am') {
            this.setState({ timeByProp: childData[0] })
        }
        console.log(this.state.timeByProp)
    }

    componentDidMount() {
        var times = new Date().toLocaleString('en-US', { hour12: true })
        times = times.split(',');
        times = times[1].split(' ');
        var amPm = times[2];
        times = times[1].split(':');
        times = times[0] + ":" + times[1] + ' ' + amPm;
        var childData = times.split(' ');
        if (childData[1].toLowerCase() === 'pm') {
            childData = childData[0].split(':');
            childData = (12 + parseInt(childData[0])) + ":" + childData[1]
            this.setState({ timeByProp: childData })
        }
        if (childData[1].toLowerCase() === 'am') {
            this.setState({ timeByProp: childData[0] })
        }

        this.getSportsFunction();

        this.setState({ address: this.state.user.address })

        //challenge list API called from here
        const getAllChallengeList = rshApi + "pages/get-challenges";
        const that = this;
        const { match } = this.props;

        let pageId = match.params.pageId;
        var thrown = [], received = [];
        fetch(getAllChallengeList, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "pageId": pageId,
                    "index": 0,
                    itemsPerIndex: 10
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== undefined) {
                    result.data.challenges.map(x => {
                        if (x.action === 'thrown') {
                            thrown.push(x);
                        }
                        if (x.action === "received") {
                            received.push(x);
                        }
                        that.setState({
                            data: 'Both',
                            challengeList: result.data.challenges,
                            challengeListNew: result.data.challenges,
                        });
                    })
                    that.setState({
                        thrown: thrown,
                        received: received
                    });
                }
                //console.log(that.state.thrown)
                //console.log(that.state.received)
                console.log(that.state.challengeList)
            });
    }

    handleSubmit = e => {

        e.preventDefault();
        if (!this.state.takeOnlySport) {
            errorMessage = "Please Select Sports Name";
            this.setState({ showingPopup: true, showErrorMessage: true });
            setTimeout(() => {
                this.setState({ showingPopup: false, showErrorMessage: false });
            }, 3000)

        }
        else {
            if (this.state.takeOnlySport == 'Cricket') {
                if (!this.state.overS) {
                    errorMessage = "Please Select Overs";
                    this.setState({ showingPopup: true, showErrorMessage: true });
                    setTimeout(() => {
                        this.setState({ showingPopup: false, showErrorMessage: false });
                    }, 2000)
                }
                else {
                    this.createChallenge();
                }
            }
            else {
                this.createChallenge();
            }
        }
    }

    //create challenge function
    createChallenge = () => {
        const { match } = this.props;
        let pageId = match.params.pageId;
        const apiUrl = rshApi + "/challenges/create-challenge";
        if (this.state.openChallenge === true) {
            let accepted;
            let challenge = {
                sportsId: this.state.takeOnlySportIds,
                sportsName: this.state.takeOnlySport,
                categoryId: this.state.takeOnlyCategory,
                challengeType: this.state.challengeTO,
                challengeLevel: this.state.showOppo,
                city: this.state.user.city,
                state: this.state.user.state,
                country: this.state.user.country,
                location: this.state.user.address,
                latitude: this.state.user.latitude,
                longitude: this.state.user.longitude,
                challengePrivacy: 'public',
                challengeDate: this.state.date2,
                challengeTime: this.state.timeByProp,
                createdOn: currentTimeStamp,
                modifiedOn: currentTimeStamp,
                active: true,
                createdBy: this.state.user.contactNo,
                challengeAction: 'requested',
                gender: this.state.genderSelection,
                openChallenge: this.state.openChallenge,
                sdType: this.state.sdType,
                invited: null,
                challengeFrom: 'page',
                pageId: pageId,
                levelType: this.state.challengeType
            }

            if ((this.state.eventName !== '') && (this.state.distanceNum !== '')) {
                challenge.eventName = this.state.eventName;
                challenge.distance = this.state.distanceNum;
                challenge.unit = 'Meters(m)';
            }

            else if (this.state.weightNum !== '') {
                challenge.eventName = null;
                challenge.distance = this.state.weightNum;
                challenge.unit = this.state.weightMeasure;
            }

            else if (this.state.overS !== '') {
                challenge.eventName = null;
                challenge.distance = this.state.overS;
                challenge.unit = 'Overs';
            }

            else if (this.state.eventName === '' || this.state.distanceNum === '' || this.state.weightNum === '' || this.state.overS === '') {
                challenge.eventName = null;
                challenge.distance = null;
                challenge.unit = null;
            }

            if ((this.state.sdType === 'singles') || (this.state.sdType === 'doubles')) {
                accepted = [this.state.user.contactNo];
                challenge.accepted = accepted;
                console.log(challenge)
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            challenge
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        console.log(result);
                    });
            }

            else {
                accepted = this.state.mySelectedTeamId;
                challenge.accepted = accepted;
                console.log(challenge)
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            challenge
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        window.location.href = "/challengeprofile/index.html?id=" + result.data.challengeId;
                    });
            }
        }

        else if ((this.state.eventName !== '') && (this.state.distanceNum !== '')) {
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challenge": {
                            //challengeId: null,
                            eventName: this.state.eventName,
                            distance: this.state.distanceNum,
                            unit: 'Meters(m)',
                            sportsId: this.state.takeOnlySportIds,
                            sportsName: this.state.takeOnlySport,
                            categoryId: this.state.takeOnlyCategory,
                            challengeType: this.state.challengeTO,
                            challengeLevel: this.state.showOppo,
                            city: this.state.user.city,
                            state: this.state.user.state,
                            country: this.state.user.country,
                            location: this.state.user.address,
                            latitude: this.state.user.latitude,
                            longitude: this.state.user.longitude,
                            challengePrivacy: 'public',
                            challengeDate: this.state.date2,
                            challengeTime: this.state.timeByProp,
                            createdOn: currentTimeStamp,
                            modifiedOn: currentTimeStamp,
                            active: true,
                            createdBy: this.state.user.contactNo,
                            challengeAction: 'requested',
                            gender: this.state.genderSelection,
                            openChallenge: this.state.openChallenge,
                            sdType: this.state.sdType,
                            //challengeWinnerId: null,
                            //sentTo: null,
                            //challengedBy: null,
                            //challengedTo: null,
                            //courseSelected: null, 
                            //courseId: null,
                            //courseRound: null,
                            invited: this.state.invitedBudNo,
                            accepted: [this.state.user.contactNo],
                            challengeFrom: 'page',
                            pageId: pageId,
                            levelType: this.state.challengeType
                            //medias: null,
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = "/challengeprofile/index.html?id=" + result.data.challengeId;
                });
        }

        else if (this.state.weightNum !== '') {
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challenge": {
                            //challengeId: null,
                            eventName: null,
                            distance: this.state.weightNum,
                            unit: this.state.weightMeasure,
                            sportsId: this.state.takeOnlySportIds,
                            sportsName: this.state.takeOnlySport,
                            categoryId: this.state.takeOnlyCategory,
                            challengeType: this.state.challengeTO,
                            challengeLevel: this.state.showOppo,
                            city: this.state.user.city,
                            state: this.state.user.state,
                            country: this.state.user.country,
                            location: this.state.user.address,
                            latitude: this.state.user.latitude,
                            longitude: this.state.user.longitude,
                            challengePrivacy: 'public',
                            challengeDate: this.state.date2,
                            challengeTime: this.state.timeByProp,
                            createdOn: currentTimeStamp,
                            modifiedOn: currentTimeStamp,
                            active: true,
                            createdBy: this.state.user.contactNo,
                            challengeAction: 'requested',
                            gender: this.state.genderSelection,
                            openChallenge: this.state.openChallenge,
                            sdType: this.state.sdType,
                            //challengeWinnerId: null,
                            //sentTo: null,
                            //challengedBy: null,
                            //challengedTo: null,
                            //courseSelected: null, 
                            //courseId: null,
                            //courseRound: null,
                            invited: this.state.invitedBudNo,
                            accepted: [this.state.user.contactNo],
                            challengeFrom: 'page',
                            pageId: pageId,
                            levelType: this.state.challengeType
                            //medias: null,
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = "/challengeprofile/index.html?id=" + result.data.challengeId;
                });
        }

        else if (this.state.overS !== '') {
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challenge": {
                            //challengeId: null,
                            eventName: null,
                            distance: this.state.overS,
                            unit: 'Overs',
                            sportsId: this.state.takeOnlySportIds,
                            sportsName: this.state.takeOnlySport,
                            categoryId: this.state.takeOnlyCategory,
                            challengeType: this.state.challengeTO,
                            challengeLevel: this.state.showOppo,
                            city: this.state.user.city,
                            state: this.state.user.state,
                            country: this.state.user.country,
                            location: this.state.user.address,
                            latitude: this.state.user.latitude,
                            longitude: this.state.user.longitude,
                            challengePrivacy: 'public',
                            challengeDate: this.state.date2,
                            challengeTime: this.state.timeByProp,
                            createdOn: currentTimeStamp,
                            modifiedOn: currentTimeStamp,
                            active: true,
                            createdBy: this.state.user.contactNo,
                            challengeAction: 'requested',
                            gender: this.state.genderSelection,
                            openChallenge: this.state.openChallenge,
                            sdType: this.state.sdType,
                            //challengeWinnerId: null,
                            //sentTo: null,
                            //challengedBy: null,
                            //challengedTo: null,
                            //courseSelected: null, 
                            //courseId: null,
                            //courseRound: null,
                            invited: this.state.invitedBudNo,
                            accepted: this.state.mySelectedTeamId,
                            challengeFrom: 'page',
                            pageId: pageId,
                            levelType: this.state.challengeType
                            //medias: null,
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = "/challengeprofile/index.html?id=" + result.data.challengeId;
                });
        }

        else if (this.state.eventName === '' || this.state.distanceNum === '' || this.state.weightNum === '' || this.state.overS === '') {
            var accepted;
            if (this.state.challengeTO === 'team') {
                accepted = this.state.mySelectedTeamId;
            }
            else {
                accepted = [this.state.user.contactNo];
            }

            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challenge": {
                            //challengeId: null,
                            eventName: null,
                            distance: null,
                            unit: null,
                            sportsId: this.state.takeOnlySportIds,
                            sportsName: this.state.takeOnlySport,
                            categoryId: this.state.takeOnlyCategory,
                            challengeType: this.state.challengeTO,
                            challengeLevel: this.state.showOppo,
                            city: this.state.user.city,
                            state: this.state.user.state,
                            country: this.state.user.country,
                            location: this.state.user.address,
                            latitude: this.state.user.latitude,
                            longitude: this.state.user.longitude,
                            challengePrivacy: 'public',
                            challengeDate: this.state.date2,
                            challengeTime: this.state.timeByProp,
                            createdOn: currentTimeStamp,
                            modifiedOn: currentTimeStamp,
                            active: true,
                            createdBy: this.state.user.contactNo,
                            challengeAction: 'requested',
                            gender: this.state.genderSelection,
                            openChallenge: this.state.openChallenge,
                            sdType: this.state.sdType,
                            //challengeWinnerId: null,
                            //sentTo: null,
                            //challengedBy: null,
                            //challengedTo: null,
                            //courseSelected: null, 
                            //courseId: null,
                            //courseRound: null,
                            invited: this.state.invitedBudNo,
                            accepted: accepted,
                            challengeFrom: 'page',
                            pageId: pageId,
                            levelType: this.state.challengeType
                            //medias: null,
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = "/challengeprofile/index.html?id=" + result.data.challengeId;
                });
        }

        this.setState({
            showingPopup: true,
            showingAlert1: true
        });

        setTimeout(() => {
            this.setState({
                showingPopup: false,
                showingAlert1: false,
            });
        }, 2000);
        document.getElementById('disableSuccess').disabled = true;
    }

    closingPopup = () => {
        this.setState({
            showingPopup: false,
        });
    }

    showSportsError() {
        if (!this.state.takeOnlySport) {
            errorMessage = "Please Select Sports Name";
            this.setState({ showingPopup: true, showErrorMessage: true });
            setTimeout(() => {
                this.setState({ showingPopup: false, showErrorMessage: false });
            }, 3000)
        }
    }

    //filtered data API is called from here
    getFilteredData = (playerTeam) => {

        this.setState({ whichPlayerTeam: playerTeam })
        const that = this;
        if (this.state.challengeType === 'inter') {
            if (this.state.genderSelection !== '') {
                const apiUrl = rshApi + "/challenges/get-invites";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "type": that.state.challengeTO,
                            "userId": that.state.user.contactNo,
                            "challengeLevel": that.state.showOppo,
                            "gender": that.state.genderSelection,
                            "sportsId": that.state.takeOnlySportIds,
                            "index": 0,
                            "itemsPerIndex": 10,
                            "onlyMyTeam": false
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        if (that.state.finalFilterDataOrg.length > 0) {

                            let budd1 = that.state.invitedBudNo1;
                            let budd2 = that.state.invitedBudNo2;
                            if (playerTeam === 'player1') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd2)
                                });
                            } else if (playerTeam === 'player2') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd1)
                                });
                            }
                        }
                        else {
                            that.setState({
                                finalFilterData: result.data.users,
                                finalFilterDataOrg: result.data.users,
                            });
                        }

                        setTimeout(() => {
                            that.setState({
                                modalOpentForInvite: true,
                            });
                        }, 800)
                        console.log(that.state.finalFilterData)
                    });
            }
            else {
                const apiUrl = rshApi + "/challenges/get-invites";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "type": that.state.challengeTO, //"unknown",
                            "userId": that.state.user.contactNo, //"+918989005630",
                            "challengeLevel": that.state.showOppo, //"state",
                            "gender": '', //"Male",
                            "sportsId": that.state.takeOnlySportIds, //"68rTgJiw3UQcIg33tQ0l",
                            "index": 0,
                            "itemsPerIndex": 10,
                            "onlyMyTeam": false
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        if (that.state.finalFilterData.length > 0) {
                            let budd1 = that.state.invitedBudNo1;
                            let budd2 = that.state.invitedBudNo2;
                            if (playerTeam === 'player1') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo !== budd1)
                                });
                            } else if (playerTeam === 'player2') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo !== budd2)
                                });
                            }
                        }
                        else {
                            that.setState({
                                finalFilterData: result.data.users,
                                finalFilterDataOrg: result.data.users,
                            });
                        }

                        setTimeout(() => {
                            that.setState({
                                modalOpentForInvite: true,
                            });
                        }, 800)
                        console.log(that.state.finalFilterData)
                    });
            }
        }
        else {
            const { match } = this.props;
            let pageId = match.params.pageId;
            if (this.state.genderSelection !== '') {
                const apiUrl = rshApi + "/pages/get-followers";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            pageId: pageId
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        console.log(result)
                        if (that.state.finalFilterData.length > 0) {
                            let budd1 = that.state.invitedBudNo1;
                            let budd2 = that.state.invitedBudNo2;
                            if (playerTeam === 'player1') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd2)
                                });
                            } else if (playerTeam === 'player2') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd1)
                                });
                            }
                        }
                        else {
                            that.setState({
                                finalFilterData: result.data.users,
                                finalFilterDataOrg: result.data.users,
                            });
                        }

                        setTimeout(() => {
                            that.setState({
                                modalOpentForInvite: true,
                            });
                        }, 800)
                        console.log(that.state.finalFilterData)
                    });
            }
            else {
                const apiUrl = rshApi + "/pages/get-followers";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            pageId: pageId
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        console.log(result)
                        if (that.state.finalFilterData.length > 0) {
                            let budd1 = that.state.invitedBudNo1;
                            let budd2 = that.state.invitedBudNo2;
                            if (playerTeam === 'player1') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd2)
                                });
                            } else if (playerTeam === 'player2') {
                                that.setState({
                                    finalFilterData: that.state.finalFilterDataOrg.filter(us => us.contactNo != budd1)
                                });
                            }
                        }
                        else {
                            that.setState({
                                finalFilterData: result.data.users,
                                finalFilterDataOrg: result.data.users,
                            });
                        }

                        setTimeout(() => {
                            that.setState({
                                modalOpentForInvite: true,
                            });
                        }, 800)
                        console.log(that.state.finalFilterData)
                    });
            }
        }

    }

    getFilteredDataa = (playerTeam) => {
        const { match } = this.props;
        let pageId = match.params.pageId;
        if (this.state.challengeType === 'intra') {
            this.setState({ whichPlayerTeam: playerTeam })
            const that = this;
            const apiUrl = rshApi + "/teams/get-page-teams-for-challenge";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "pageId": pageId,
                        "gender": that.state.genderSelection, //"Male",
                        "sportsId": that.state.takeOnlySportIds, //"68rTgJiw3UQcIg33tQ0l",

                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)
                    that.setState({
                        finalFilterDataTeam: result.data,
                        finalFilterDataTeamOrg: result.data,
                    }, () => {
                        let team1 = that.state.invitedTeamNo1;
                        let team2 = that.state.invitedTeamNo2;
                        if (playerTeam === 'team1') {
                            that.setState({
                                finalFilterDataTeam: that.state.finalFilterDataTeamOrg.filter(us => us.teamId != team2)
                            });
                        } else if (playerTeam === 'team2') {
                            that.setState({
                                finalFilterDataTeam: that.state.finalFilterDataTeamOrg.filter(us => us.teamId != team1)
                            });
                        }
                        that.setState({ modalOpentForTeamInvite: true })
                    });
                    console.log(that.state.finalFilterDataTeam)
                });
        }
        else {
            this.setState({ whichPlayerTeam: playerTeam })
            if (this.state.challengeType === 'inter' && playerTeam === 'team1') {
                const apiUrl = rshApi + "/challenges/get-invites";
                const that = this;
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "type": 'buddy', //"unknown",
                            "userId": that.state.user.contactNo, //"+918989005630",
                            "challengeLevel": that.state.showOppo, //"state",
                            "gender": '', //"Male",
                            "sportsId": that.state.takeOnlySportIds, //"68rTgJiw3UQcIg33tQ0l",
                            "index": 0,
                            "itemsPerIndex": 10,
                            "onlyMyTeam": false
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        console.log(result)
                        that.setState({
                            finalFilterData: result.data.users,
                            finalFilterDataOrg: result.data.users,
                        });
                        setTimeout(() => {
                            that.setState({
                                modalOpentForInvite: true,
                            });
                        }, 800)
                        console.log(that.state.finalFilterData)
                    });
            }
            else if (this.state.challengeType === 'inter' && playerTeam === 'team2') {
                this.setState({ whichPlayerTeam: playerTeam })
                const that = this;
                const apiUrl = rshApi + "/teams/get-page-teams-for-challenge";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "pageId": pageId,
                            "gender": that.state.genderSelection, //"Male",
                            "sportsId": that.state.takeOnlySportIds, //"68rTgJiw3UQcIg33tQ0l",

                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        console.log(result)
                        that.setState({
                            finalFilterDataTeam: result.data,
                            finalFilterDataTeamOrg: result.data,
                        }, () => {
                            let team1 = that.state.invitedTeamNo1;
                            let team2 = that.state.invitedTeamNo2;
                            if (playerTeam === 'team1') {
                                that.setState({
                                    finalFilterDataTeam: that.state.finalFilterDataTeamOrg.filter(us => us.teamId != team2)
                                });
                            } else if (playerTeam === 'team2') {
                                that.setState({
                                    finalFilterDataTeam: that.state.finalFilterDataTeamOrg.filter(us => us.teamId != team1)
                                });
                            }
                            that.setState({ modalOpentForTeamInvite: true })
                        });
                        console.log(that.state.finalFilterDataTeam)
                    });
            }
            else {
                this.setState({ whichPlayerTeam: playerTeam })
                const that = this;
                const apiUrl = rshApi + "/challenges/get-invites";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "type": that.state.challengeTO, //"unknown",
                            "userId": that.state.user.contactNo, //"+918989005630",
                            "challengeLevel": that.state.showOppo, //"city",
                            "gender": that.state.genderSelection, //"Male",
                            "sportsId": that.state.takeOnlySportIds, //"68rTgJiw3UQcIg33tQ0l",
                            "index": 0,
                            "itemsPerIndex": 10,
                            "onlyMyTeam": true
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        that.setState({
                            finalFilterDataTeam: result.data.teams,
                            finalFilterDataTeamOrg: result.data.teams,
                        });
                        console.log(that.state.finalFilterDataTeam)
                    });
            }

        }

    }

    getSportsFunction = () => {
        const apiUrl = rshApi + "/sports/get-sports";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "minimal": "false",
                    "isGroupGame": "isGroupGame"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    allSports: result.data
                });

            });
    }

    selectSports = () => {
        this.setState({
            openSports: true,
            showliSelectOppo: true,
            showliSelectTeam: false,
            showliGender: false,
            genderSelection: 'Select Gender',
            invitedBudd: [],
            mySelectedTeam: [],
            eventName: '',
            distanceNum: '',
            showliWeight: '',
            overS: '',
        }, () => {
            this.testInput && this.testInput.focus()
        });
    }

    //code to take sports
    chooseSport = (takeOnlySport, takeOnlySportIds, takeOnlyCategory) => {
        this.state.allSports.map(item => {
            if (item.teamMode === 0 && item.name === takeOnlySport) {
                this.setState({
                    challengeTO: 'buddy',
                    showOppo: 'city',
                    genderSelection: 'Select Gender',
                })
            }
            if (item.teamMode === 1 && item.name === takeOnlySport) {
                this.setState({
                    genderSelection: 'Male',
                    challengeTO: 'team',
                    showOppo: 'city',
                    showliGender: true,
                    showliSelectOppo: false,
                    showliSelectTeam: true,
                });
            }
            if (item.teamMode === 2 && item.name === takeOnlySport) {
                this.setState({
                    challengeTO: 'buddy',
                    showOppo: 'city',
                    genderSelection: 'Select Gender',
                })
            }
            if ((takeOnlyCategory === "D101") || (takeOnlyCategory === "D103") || (takeOnlyCategory === "D104") || (takeOnlyCategory === "D105")) {
                this.setState({
                    typeOfInput: 'checkbox'
                });
            }
        })

        if ((takeOnlySport === "Badminton") || (takeOnlySport === "Carrom Board") || (takeOnlySport === "Squash") || (takeOnlySport === "Table Tennis") || (takeOnlySport === "Tennis")) {
            //only single & double based
            this.setState({ showLis: true, single: true, doubleTeam: false, showSingleDouble: true, showSingleTeams: false, sdType: 'singles', defaultDisableForSingle: false, defaultDisableForTeam: false, defaultCheckedS: true, defaultCheckedT: false, defaultCheckedBuddyTo: true, defaultCheckedTeamTo: false, challengeTO: 'buddy', })
        }
        else {
            //only single based
            if ((takeOnlyCategory === "D101") || (takeOnlyCategory === "D102") || (takeOnlyCategory === "D103") || (takeOnlyCategory === "D104") || (takeOnlyCategory === "D105") || (takeOnlySport === "Golf")) {
                this.setState({ sdType: 'singles', showLis: true, single: true, doubleTeam: false, showSingleDouble: false, showSingleTeams: true, defaultDisableForSingle: false, defaultDisableForTeam: true, defaultCheckedS: true, defaultCheckedT: false, defaultCheckedBuddyTo: true, defaultCheckedTeamTo: false, challengeTO: 'buddy' })
            }
            //both single and team based in D107
            else if (takeOnlySport === 'Archery') {
                this.setState({ sdType: 'singles', showLis: true, single: true, doubleTeam: false, showSingleDouble: false, showSingleTeams: true, defaultDisableForSingle: false, defaultDisableForTeam: false, defaultCheckedS: true, defaultCheckedT: false, defaultCheckedBuddyTo: true, defaultCheckedTeamTo: false, challengeTO: 'buddy', openChallenge: false, defaultCheckedBuddyTo: true, defaultCheckedBuddyOpenTo: false, defaultCheckedTeamTo: false, defaultCheckedTeamOpenTo: false })
            }
            //only teams based in D106 and D107
            else {
                this.setState({ sdType: null, showLis: true, single: false, doubleTeam: true, showSingleDouble: false, showSingleTeams: true, defaultDisableForSingle: true, defaultDisableForTeam: false, defaultCheckedS: false, defaultCheckedT: true, defaultCheckedBuddyTo: false, defaultCheckedTeamTo: true, challengeTO: 'team' })
            }
        }

        if (takeOnlySport === 'Archery' || takeOnlySport === 'Cycling' || takeOnlySport === 'Cycling Marathon' || takeOnlySport === 'Marathon' || takeOnlySport === 'Rowing' || takeOnlySport === 'Shooting' || takeOnlySport === 'Swimming') {
            this.setState({
                showliWeight: false,
                showliOvers: false,
                showliDistance: true,
                weightNum: '',
                oversS: '',
            });
        }
        else if (takeOnlySport === 'Boxing' || takeOnlySport === 'Judo' || takeOnlySport === 'Taekwondo' || takeOnlySport === 'Weightlifting' || takeOnlySport === 'Wrestling') {
            this.setState({
                showliDistance: false,
                showliOvers: false,
                showliWeight: true,
                eventName: '',
                distanceNum: '',
                oversS: '',
                weightMeasure: 'Kilograms(Kg)',
            });
        }
        else if (takeOnlySport === 'Cricket') {
            this.setState({
                showliDistance: false,
                showliWeight: false,
                showliOvers: true,
                eventName: '',
                distanceNum: '',
                weightNum: '',
            });
        }
        else {
            this.setState({
                showliDistance: false,
                showliWeight: false,
                showliOvers: false,
                eventName: '',
                distanceNum: '',
                weightNum: '',
                oversS: '',
            });
        }
        this.setState({
            takeOnlySport: takeOnlySport,
            takeOnlySportIds: takeOnlySportIds,
            takeOnlyCategory: takeOnlyCategory,
            openSports: false,
            // showliSelectTeam: true
        });
    }

    //gender selection
    genderSelection = (e) => {
        let genderSelection = e.target.value;
        if (e.target.checked === true) {
            switch (genderSelection) {
                case 'Male':
                    this.setState({
                        genderSelection: genderSelection,
                        showliSelectOppo: true,
                        showliSelectTeam: true,
                        defaultCheckedMale: true,
                        defaultCheckedFemale: false,
                        defaultCheckedMix: false,
                    });

                    break

                case 'Female':
                    this.setState({
                        genderSelection: genderSelection,
                        showliSelectOppo: true,
                        showliSelectTeam: true,
                        defaultCheckedMale: false,
                        defaultCheckedFemale: true,
                        defaultCheckedMix: false,
                    });

                    break

                default:
                    this.setState({
                        genderSelection: genderSelection,
                        showliSelectOppo: true,
                        showliSelectTeam: true,
                        defaultCheckedMale: false,
                        defaultCheckedFemale: false,
                        defaultCheckedMix: true,
                    });
            }
        }
    }

    //radio buttons code for Challenge To
    challengeTO = (event) => {
        if (event.target.checked === true) {
            this.setState({ openChallenge: false });
            if (this.state.single) {
                this.setState({ defaultCheckedBuddyTo: true, defaultCheckedBuddyOpenTo: false, defaultCheckedTeamTo: false, defaultCheckedTeamOpenTo: false, showliSelectOppo: true, showliSelectTeam: false, genderSelection: 'Select Gender', challengeTO: 'buddy' });
            }
            else {
                this.setState({ defaultCheckedTeamTo: true, defaultCheckedTeamOpenTo: false, defaultCheckedBuddyTo: false, defaultCheckedBuddyOpenTo: false, showliSelectOppo: true, showliSelectTeam: true, genderSelection: 'Male', challengeTO: 'team' });
            }
        }

    }



    showOppo = (event) => {
        let showOppo = event.target.value;
        // if (event.target.checked === true && this.state.takeOnlySport !== '') {
        if (event.target.checked === true) {
            switch (showOppo) {
                case 'city':
                    this.setState({
                        showOppo: showOppo,
                        genderSelection: this.state.genderSelection === 'Male' || 'Female' || 'Mix' ? this.state.genderSelection : 'Select Gender',

                        defaultCheckedCity: true,
                        defaultCheckedState: false,
                        defaultCheckedCountry: false,
                    });

                    break
                case 'state':
                    this.setState({
                        showOppo: showOppo,
                        genderSelection: this.state.genderSelection === 'Male' || 'Female' || 'Mix' ? this.state.genderSelection : 'Select Gender',

                        defaultCheckedCity: false,
                        defaultCheckedState: true,
                        defaultCheckedCountry: false,
                    });

                    break

                default:
                    this.setState({
                        showOppo: showOppo,
                        genderSelection: this.state.genderSelection === 'Male' || 'Female' || 'Mix' ? this.state.genderSelection : 'Select Gender',

                        defaultCheckedCity: false,
                        defaultCheckedState: false,
                        defaultCheckedCountry: true,
                    });
            }
        }
    }

    //code to select buddies and select my team
    invitedBudd = (takeOnlyName, takeOnlyNumber, event) => {

        if (this.state.typeOfInput !== 'radio') {
            let checkedArray = this.state.optionsChecked;
            let checkedArray1 = this.state.optionsChecked1;
            let selectedValue = event.target.value;

            if (event.target.checked === true) {
                checkedArray.push(takeOnlyName);
                checkedArray1.push(takeOnlyNumber);
                this.setState({
                    optionsChecked: checkedArray,
                    optionsChecked1: checkedArray1,
                });
            }
            else {
                let valueIndex = checkedArray.indexOf(selectedValue);
                let valueIndex1 = checkedArray1.indexOf(selectedValue);
                checkedArray.splice(valueIndex, 1);
                checkedArray1.splice(valueIndex1, 1);
                this.setState({
                    optionsChecked: checkedArray,
                    optionsChecked1: checkedArray1,
                });
            }
            if (this.state.whichPlayerTeam === 'team1') {
                this.setState({
                    invitedTeam1: this.state.optionsChecked,

                });
            }
            if (this.state.whichPlayerTeam === 'player1') {
                let finalFilterData = [...this.state.finalFilterData];

                this.setState({ finalFilterData: finalFilterData.filter(user => user.contactNo != this.state.optionsChecked) });
                this.setState({
                    invitedBudd1: this.state.optionsChecked,

                });
            }
            else {
                this.setState({
                    invitedBudd2: this.state.optionsChecked,

                });
            }
            this.setState({
                invitedBudd: this.state.optionsChecked,
                invitedBudNo: this.state.optionsChecked1,
            });
        }

        else {
            var shortArr = [takeOnlyName],
                newShortArr = shortArr.splice(0, 1)
            var shortArr1 = [takeOnlyNumber],
                newShortArr1 = shortArr1.splice(0, 1)
            if (this.state.whichPlayerTeam === 'team1') {
                this.setState({
                    invitedTeam1: newShortArr,
                    invitedTeamNo1: newShortArr1,
                });
            }
            if (this.state.whichPlayerTeam === 'player1') {
                // let finalFilterData = [...this.state.finalFilterData];

                // this.setState({ finalFilterData: finalFilterData.filter(user => user.contactNo != newShortArr1) });
                this.setState({
                    invitedBudd1: newShortArr,
                    invitedBudNo1: newShortArr1,

                });
            }
            else if (this.state.whichPlayerTeam === 'player2') {
                // let finalFilterData = [...this.state.finalFilterData]
                // this.setState({ finalFilterData: finalFilterData.filter(user => user.userId != newShortArr1) });
                this.setState({
                    invitedBudd2: newShortArr,
                    invitedBudNo2: newShortArr1,
                });
            }
            this.setState({
                invitedBudd: newShortArr,
                invitedBudNo: newShortArr1,
            });
        }
    }

    mySelectedTeam = (takeOnlyName, takeOnlyNumber) => {
        var shortArr = [takeOnlyName],
            newShortArr = shortArr.splice(0, 1)
        var shortArr1 = [takeOnlyNumber],
            newShortArr1 = shortArr1.splice(0, 1)
        if (this.state.whichPlayerTeam === 'team1') {
            this.setState({
                invitedTeam1: newShortArr,
                invitedTeamNo1: newShortArr1,
            });
        }
        else {
            this.setState({
                invitedTeam2: newShortArr,
                invitedTeamNo2: newShortArr1,
            });
        }
        this.setState({
            mySelectedTeam: newShortArr,
            mySelectedTeamId: newShortArr1,
        });

    }

    //in relation to showing challenge list 
    changeFilter = (type) => {
        if (type === 'Thrown') {
            this.setState({
                data: "Thrown", challengeList: this.state.thrown,
            })
        }
        if (type === 'Received') {
            this.setState({
                data: "Received", challengeList: this.state.received,
            })
        }
        if (type === 'Both') {
            this.setState({
                data: "Both", challengeList: this.state.challengeListNew,
            })
        }
    }

    closeSports = () => {
        this.setState({ openSports: false })
    }
    openLocation = () => {
        this.setState({ openLocation: true })
    }
    closeLocation = () => {
        this.setState({ openLocation: false })
    }
    closeModalOpentForInvite = () => {

        let finalFilterData = [...this.state.finalFilterData]
        this.setState({ finalFilterData: finalFilterData.filter(user => user.contactNo != this.state.invitedBudNo1 ? this.state.invitedBudNo1 : this.state.invitedBudNo2) }, () => {
            console.log(this.state.finalFilterData)
        });
        this.setState({ modalOpentForInvite: false })
    }
    closeModalOpentForTeamInvite = () => {

        // let finalFilterData = [...this.state.finalFilterData]
        // this.setState({ finalFilterData: finalFilterData.filter(user => user.contactNo != this.state.invitedBudNo1 ? this.state.invitedBudNo1 : this.state.invitedBudNo2) }, () => {
        //     console.log(this.state.finalFilterData)
        // });
        this.setState({ modalOpentForTeamInvite: false })
    }
    render() {
        const { term, allSports } = this.state;
        const { term1, finalFilterData } = this.state;
        const { term2, finalFilterDataTeam } = this.state;
        return (
            <>
                <Modal
                    size="md"
                    show={this.state.openForChooseChalengeType}
                    onHide={this.closeLocation}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >

                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{
                        padding: '0', marginTop: '10px'
                    }}
                    >

                        <div class="intra-inters" >
                            <div class="part-white">

                            </div>
                            <div class="part-green">

                            </div>
                            <div class="img-txtdiv">
                                <img src={inter} class="col-lg-6 col-md-6 col-sm-12 col-xs-12" />
                                <div class="intra-txtdiv col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <h3>INTRA</h3>
                                    <p>Create a challenge which will be only visible with in your page and your page team can only participate</p>
                                    <button onClick={() => this.setState({ challengeType: 'intra', openForChooseChalengeType: false })} className="intra-enterbtn">go with Intra</button>
                                </div>
                            </div>
                        </div>


                        <div class="intra-inters btm-spcnone" >
                            <div class="part-offwhite">

                            </div>
                            <div class="part-green">

                            </div>
                            <div class="img-txtdiv">

                                <div class="inter-txtdiv intra-txtdiv col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <h3>INTER</h3>
                                    <p>Create open challenge where anybody on our playdiator plateform can participate</p>
                                    <button onClick={() => this.setState({ challengeType: 'inter', openForChooseChalengeType: false })} className="intra-enterbtn">go with Inter</button>
                                </div>
                                <img src={inter} class="col-lg-6 col-md-6 col-sm-12 col-xs-12 interimg" />
                            </div>
                        </div>

                    </div>
                </Modal>
                <Header />
                <div className="container containerbottom non-session-d">
                    {this.state.user ? <SectionLeft /> : ''}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            <React.Fragment>

                                <Modal
                                    size="md"
                                    show={this.state.openLocation}
                                    onHide={this.closeLocation}
                                    animation={false}
                                    aria-labelledby="example-modal-sizes-title-md"
                                >

                                    <div className="">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal"></button>
                                            <h4 className="modal-title">Select Location</h4>
                                        </div>

                                        <div className="modal-body modal-bodyD">
                                            <div className="modaldiv-d">
                                                <div className="setspopup-d">
                                                    <span id="searchLocation" style={{ "borderBottom": '1px' }}>
                                                        <Map parentCallback={this.callbackFunction1} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>

                                <div id="openChallengeModal" className="modal fade reslutmodel" role="dialog">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                                <h4 className="modal-title">Selection</h4>
                                            </div>
                                            <div className="modal-body">
                                                {this.state.noUserTeam ? <center>
                                                    <h5>No players/teams applied to accept this challenge</h5></center> : this.state.openChallengeTeam ?
                                                        <ul className="playerlist">
                                                            {this.state.appliedTeam.map(appliedTeam => {
                                                                return <li key={appliedTeam.teamId} onClick={() => this.acceptUserOpenChallenge(appliedTeam.teamId)}>
                                                                    <span className="assignimg" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                                    <label className="ac_name">{appliedTeam.teamName}</label>
                                                                </li>
                                                            })}
                                                        </ul>
                                                        :
                                                        <ul className="playerlist">
                                                            {this.state.appliedUser.map(applieduser => {
                                                                return <li key={applieduser.contactNo} onClick={() => this.acceptUserOpenChallenge(applieduser.contactNo)}>
                                                                    <span className="assignimg" style={{ backgroundImage: "url(" + applieduser.profilePic + ")" }}></span>
                                                                    <label className="ac_name">{applieduser.name}</label>
                                                                </li>
                                                            })}
                                                        </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <input style={{ display: 'none' }} id="openChallengeModal1" data-toggle="modal" data-target="#openChallengeModal" />
                                {/* Challenge List CODE */}
                                {
                                    this.state.teamFirstPart ? <div className="commanbox">
                                        {
                                            this.state.showListPart ? <React.Fragment>
                                                <h3 className="commanhead">Challenge List</h3>
                                                <ul className="challangebutton">
                                                    <li>
                                                        {/* <Link to={'#'} onClick={() => this.setState({ teamFirstPart: false })} className="cb_link">Create Challenge</Link> */}
                                                    </li>

                                                    <li>
                                                        <div className="dropdown ct_drop">
                                                            <Link to={'#'} className=" dropdown-toggle" type="" data-toggle="dropdown"><img src={filter} alt="" /> </Link>
                                                            <ul className="dropdown-menu">
                                                                <li><Link onClick={() => this.changeFilter('Both')} to={'#'}>Both</Link></li>
                                                                <li><Link onClick={() => this.changeFilter('Thrown')} to={'#'}>Thrown</Link></li>
                                                                <li><Link onClick={() => this.changeFilter('Received')} to={'#'}>Received</Link></li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <div className="cl_box_div">
                                                    <ul className="cl_box_ul">

                                                        {this.state.challengeList.length ? this.state.challengeList.map(item => {


                                                            if (item.challengeAction === "requested" && item.openChallenge === true) {
                                                                switch (this.state.data) {
                                                                    case "Both":
                                                                        return <li key={item.challengeId} onClick={() => this.openChallengeRedirect(item)}>

                                                                            <div className="cl_info_div">
                                                                                <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                <label className="cl_title">{item.title}</label>
                                                                                <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                            </div>
                                                                            <div className="cl_location_div">
                                                                                <p><img src={location} alt="" /> {item.location}</p>
                                                                                <span><span>Type : </span>{item.sportsName}</span>
                                                                            </div>

                                                                        </li>

                                                                    case "Thrown":
                                                                        return <li key={item.challengeId} onClick={() => this.openChallengeRedirect(item)}>

                                                                            <div className="cl_info_div">
                                                                                <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                <label className="cl_title">{item.title}</label>
                                                                                <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                            </div>
                                                                            <div className="cl_location_div">
                                                                                <p><img src={location} alt="" /> {item.location}</p>
                                                                                <span><span>Type : </span>{item.sportsName}</span>
                                                                            </div>

                                                                        </li>

                                                                    case "Received":
                                                                        return <li key={item.challengeId} onClick={() => this.openChallengeRedirect(item)}>

                                                                            <div className="cl_info_div">
                                                                                <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                <label className="cl_title">{item.title}</label>
                                                                                <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                            </div>
                                                                            <div className="cl_location_div">
                                                                                <p><img src={location} alt="" /> {item.location}</p>
                                                                                <span><span>Type : </span>{item.sportsName}</span>
                                                                            </div>

                                                                        </li>
                                                                }
                                                            }
                                                            else {
                                                                switch (this.state.data) {
                                                                    case "Both":
                                                                        return <li key={item.challengeId}>
                                                                            <Link to={"/challengeprofile/index.html?id=" + item.challengeId}>
                                                                                <div className="cl_info_div">
                                                                                    <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                    <label className="cl_title">{item.title}</label>
                                                                                    <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                    <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                                </div>
                                                                                <div className="cl_location_div">
                                                                                    <p><img src={location} alt="" /> {item.location}</p>
                                                                                    <span><span>Type : </span>{item.sportsName}</span>
                                                                                </div>
                                                                            </Link>
                                                                        </li>

                                                                    case "Thrown":
                                                                        return <li key={item.challengeId}>
                                                                            <Link to={"/challengeprofile/index.html?id=" + item.challengeId}>
                                                                                <div className="cl_info_div">
                                                                                    <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                    <label className="cl_title">{item.title}</label>
                                                                                    <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                    <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                                </div>
                                                                                <div className="cl_location_div">
                                                                                    <p><img src={location} alt="" /> {item.location}</p>
                                                                                    <span><span>Type : </span>{item.sportsName}</span>
                                                                                </div>
                                                                            </Link>
                                                                        </li>

                                                                    case "Received":
                                                                        return <li key={item.challengeId}>
                                                                            <Link to={"/challengeprofile/index.html?id=" + item.challengeId}>
                                                                                <div className="cl_info_div">
                                                                                    <span className="cl_userbg" style={{ backgroundImage: "url(" + challengefill + ")" }}></span>
                                                                                    <label className="cl_title">{item.title}</label>
                                                                                    <span className="cl_time"><img src={schedulematches} alt="" />{item.challengeDate}, {item.challengeTime}</span>
                                                                                    <span className="cl_type"><span>Status : </span>{item.challengeAction}</span>
                                                                                </div>
                                                                                <div className="cl_location_div">
                                                                                    <p><img src={location} alt="" /> {item.location}</p>
                                                                                    <span><span>Type : </span>{item.sportsName}</span>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                }
                                                            }

                                                        }) : <div className="broadcast_video">
                                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                                                <h3>Sorry you don't have any Challenge.</h3>
                                                            </div>}
                                                    </ul>
                                                </div>
                                            </React.Fragment> : ""}
                                        {this.state.showListDemo ?
                                            <DChallengeList />
                                            : ""}

                                    </div>
                                        /* Create Challenge CODE */
                                        : <div className="commanbox">
                                            <h3 className="commanhead">Create Challenge</h3>
                                            <div className="createteamform">
                                                <form onSubmit={this.handleSubmit} >
                                                    <ul className="createbroadcast_ul createchallange">
                                                        <li>
                                                            <div className="commaninputdiv">
                                                                <input required="true" readonly onClick={() => this.selectSports()} value={this.state.takeOnlySport} type="text" name="name" className="question" autoComplete="off" />
                                                                <label htmlFor=""><span>Select Sports</span></label>
                                                            </div>
                                                        </li>

                                                        {/* for distance based */}
                                                        {this.state.showliDistance ? <div>
                                                            <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" type="text" name="name" className="question" autoComplete="off" value={this.state.eventName} onChange={event => this.setState({ eventName: event.target.value })} />
                                                                    <label htmlFor=""><span>Event Name</span></label>
                                                                </div>
                                                            </li>
                                                            <li className="cc_datetimeli">
                                                                <div className="commaninputdiv ccdatetime1">
                                                                    <input required="true" type="number" name="name" className="question" autoComplete="off" value={this.state.distanceNum} onChange={event => this.setState({ distanceNum: event.target.value })} />
                                                                    <label htmlFor=""><span>Distance</span></label>
                                                                </div>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" type="text" name="name" className="question" autoComplete="off" placeholder="" disabled />
                                                                    <label htmlFor=""><span>meters(m)</span></label>
                                                                </div>
                                                            </li> </div> : ''}

                                                        {/* for weight based */}
                                                        {this.state.showliWeight ? <div>
                                                            <li className="cc_datetimeli">
                                                                <div className="commaninputdiv ccdatetime1">
                                                                    <input required="true" type="number" name="name" className="question" id="" autoComplete="off" value={this.state.weightNum} onChange={event => this.setState({ weightNum: event.target.value })} />
                                                                    <label htmlFor=""><span>Weight Category</span></label>
                                                                </div>
                                                                {console.log(this.state.weightNum)}
                                                                <div className="commaninputdiv">
                                                                    <select name="name" className="question" value={this.state.weightMeasure} onChange={event => this.setState({ weightMeasure: event.target.value })} >
                                                                        <option value="Kilograms(Kg)">Kilograms(Kg)</option>
                                                                        <option value="Pound(lbs)">Pound(lbs)</option>
                                                                    </select>
                                                                    {console.log(this.state.weightMeasure)}
                                                                </div>
                                                            </li> </div> : ''}

                                                        {/* for cricket based */}
                                                        {this.state.showliOvers ? <div>
                                                            <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" type="number" name="name" className="question" autoComplete="off" value={this.state.overS} onChange={event => this.setState({ overS: event.target.value })} />
                                                                    <label htmlFor=""><span>Overs to be played</span></label>
                                                                </div>
                                                            </li>
                                                        </div> : ''}


                                                        {this.state.showLis ? <li className="cc_box">
                                                            <div className="cc_inner_box" >
                                                                <label className="cc_box-head">Challenge Mode</label>
                                                                {this.state.showSingleDouble ? <div className="commaninputdiv createredio" style={{ display: 'flex' }}>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedS} disabled={this.state.defaultDisableForSingle} type="radio" onClick={() => this.selectSingle()} name="fieldset-5" id="checkbox-example-1" value="buddy" />
                                                                        <label htmlFor="checkbox-example-1">Single</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedT} disabled={this.state.defaultDisableForTeam} type="radio" name="fieldset-5" id="checkbox-example-2" value="buddy" onClick={() => this.selectDoubleTeams()} />
                                                                        <label htmlFor="checkbox-example-2">Double</label>
                                                                    </div>
                                                                </div> : ''}


                                                                {this.state.showSingleTeams ? <div className="commaninputdiv createredio" style={{ display: 'flex' }}>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedS} disabled={this.state.defaultDisableForSingle} type="radio" name="fieldset-8" id="checkbox-example-7" value="single" onClick={() => this.selectSingle()} />
                                                                        <label htmlFor="checkbox-example-7">Single</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedT} disabled={this.state.defaultDisableForTeam} type="radio" name="fieldset-8" id="checkbox-example-8" value="double" onClick={() => this.selectTeamsOption()} />
                                                                        <label htmlFor="checkbox-example-8">Teams</label>
                                                                    </div>
                                                                </div> : ''}
                                                            </div>
                                                        </li> : ''}

                                                        {this.state.showLis ? <li className="cc_box">
                                                            <div className="cc_inner_box" >
                                                                <label className="cc_box-head">Challenge To</label>
                                                                {this.state.single ? <div className="commaninputdiv createredio" style={{ display: 'flex' }}>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedBuddyTo} type="radio" name="fieldset-6" id="checkbox-example-3" value="buddy" onClick={this.challengeTO.bind(this)} />
                                                                        <label htmlFor="checkbox-example-3">My buddy</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input disabled="true" checked={this.state.defaultCheckedBuddyOpenTo} type="radio" name="fieldset-6" id="checkbox-example-4" value="" onClick={this.openChallange.bind(this)} />
                                                                        <label htmlFor="checkbox-example-4">Open Challenge</label>
                                                                    </div>
                                                                </div> : ''}

                                                                {this.state.doubleTeam ? <div className="commaninputdiv createredio" style={{ display: 'flex' }}>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedTeamTo} type="radio" name="fieldset-7" id="checkbox-example-5" value="team" onClick={this.challengeTO.bind(this)} />
                                                                        <label htmlFor="checkbox-example-5"> Teams</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedTeamOpenTo} type="radio" name="fieldset-7" id="checkbox-example-6" value="" disabled="true" onClick={this.openChallange.bind(this)} />
                                                                        <label htmlFor="checkbox-example-6">Open Challenge</label>
                                                                    </div>
                                                                </div> : ''}
                                                            </div>
                                                        </li> : ''}

                                                        {this.state.showliGender ? <div>
                                                            <li className="cc_genderli">
                                                                <div className="commaninputdiv creategender">
                                                                    <label>Team Gender</label>

                                                                    <div className="creategenderdiv">
                                                                        <div className="styled-input-single createinput male">
                                                                            <input checked={this.state.defaultCheckedMale} onChange={this.genderSelection.bind(this)} type="radio" name="fieldset-9" id="genmale" value="Male" />
                                                                            <label className="gender-d" htmlFor="genmale"></label>
                                                                        </div>
                                                                        <div className="styled-input-single createinput female">
                                                                            <input checked={this.state.defaultCheckedFemale} onChange={this.genderSelection.bind(this)} type="radio" name="fieldset-9" id="genfemale" value="Female" />
                                                                            <label className="gender-d" htmlFor="genfemale"></label>
                                                                        </div>
                                                                        <div className="styled-input-single createinput mix">
                                                                            <input checked={this.state.defaultCheckedMix} onChange={this.genderSelection.bind(this)} type="radio" name="fieldset-9" id="genmix" value="Mix" />
                                                                            <label className="gender-d" htmlFor="genmix"></label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        </div> : ''}

                                                        <li className="cc_box">
                                                            <div className="cc_inner_box  opponent_inner">
                                                                <label className="cc_box-head">Show opponent within my</label>
                                                                <div className="commaninputdiv createredio">
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedCity} onChange={this.showOppo.bind(this)} type="radio" name="fieldset-10" id="checkbox-example-10" value="city" />
                                                                        <label htmlFor="checkbox-example-10">City</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedState} onChange={this.showOppo.bind(this)} type="radio" name="fieldset-10" id="checkbox-example-11" value="state" />
                                                                        <label htmlFor="checkbox-example-11">State</label>
                                                                    </div>
                                                                    <div className="styled-input-single createinput">
                                                                        <input checked={this.state.defaultCheckedCountry} onChange={this.showOppo.bind(this)} type="radio" name="fieldset-10" id="checkbox-example-12" value="country" />
                                                                        <label htmlFor="checkbox-example-12">Country</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        {this.state.takeOnlySport ? this.state.openChallenge ? null : <div>
                                                            {/* <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" onClick={() => this.getFilteredData()} value={this.state.invitedBudd} type="text" name="name" className="question" autoComplete="off" />
                                                                    <label htmlFor=""><span>Select opponent you want to challenge</span></label>
                                                                </div>
                                                                <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                            </li> */}
                                                            <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" onClick={() => this.getFilteredData('player1')} value={this.state.invitedBudd1} type="text" name="name" className="question" autoComplete="off" />
                                                                    <label htmlFor=""><span>Select Player1</span></label>
                                                                </div>
                                                                <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                            </li>
                                                            <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" onClick={() => this.getFilteredData('player2')} value={this.state.invitedBudd2} type="text" name="name" className="question" autoComplete="off" />
                                                                    <label htmlFor=""><span>Select Player2</span></label>
                                                                </div>
                                                                <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                            </li>



                                                        </div> : <div>
                                                                <li>
                                                                    <div className="commaninputdiv">
                                                                        <input required="true" onClick={() => this.showSportsError()} type="text" name="name" className="question" autoComplete="off" />
                                                                        <label htmlFor=""><span>Select opponent you want to challenge</span></label>
                                                                    </div>
                                                                </li>
                                                            </div>}

                                                        {this.state.showliSelectTeam
                                                            ? <div>
                                                                {/* <li>
                                                                <div className="commaninputdiv">
                                                                    <input required="true" onClick={() => this.getFilteredDataa()} value={this.state.mySelectedTeam} data-toggle="modal" data-target="#assignMySelectedTeam" type="text" name="name" className="question" autoComplete="off" />
                                                                    <label htmlFor=""><span>Select your team</span></label>
                                                                </div>
                                                            </li> */}
                                                                {this.state.challengeType === 'inter' ? <li>
                                                                    <div className="commaninputdiv">
                                                                        <input required="true" onClick={() => this.getFilteredDataa('team1')} value={this.state.invitedTeam1} type="text" name="name" className="question" autoComplete="off" />
                                                                        <label htmlFor=""><span> Select Team1</span></label>
                                                                    </div>
                                                                    <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                                </li> : <li>
                                                                        <div className="commaninputdiv">
                                                                            <input required="true" onClick={() => this.getFilteredDataa('team1')} value={this.state.invitedTeam1} type="text" name="name" className="question" autoComplete="off" data-toggle="modal" data-target="#assignMySelectedTeam" />
                                                                            <label htmlFor=""><span> Select Team1</span></label>
                                                                        </div>
                                                                        <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                                    </li>}

                                                                <li>
                                                                    <div className="commaninputdiv">
                                                                        <input required="true" onClick={() => this.getFilteredDataa('team2')} value={this.state.invitedTeam2} type="text" name="name" className="question" autoComplete="off" />
                                                                        <label htmlFor=""><span> Select Team2</span></label>
                                                                    </div>
                                                                    <input style={{ display: "none" }} id="modalForOppo" data-toggle="modal" data-target="#assignInviteBudd" />
                                                                </li>
                                                            </div> : ''}

                                                        <li className="cc_datetimeli">
                                                            <div className="commaninputdiv ccdatetime1">
                                                                <label htmlFor=""><span><b>Date</b></span></label>
                                                                <DatePicker
                                                                    selected={this.state.date1}
                                                                    onChange={this.startDate.bind(this)}
                                                                    minDate={new Date()}
                                                                    placeholderText="Pick Date"
                                                                />
                                                                <label htmlFor=""><b><i><span>tap to change</span></i></b></label>
                                                            </div>

                                                            <div className="commaninputdiv">
                                                                <label htmlFor=""><span><b>Time</b></span></label>
                                                                <TimeClock parentCallback={this.callbackFunction}
                                                                />
                                                                <label htmlFor=""><b><i><span>tap to change</span></i></b></label>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="commaninputdiv">
                                                                <input type="text" name="name" placeholder="location (i.e city or state or country)" className="question" autoComplete="off" value={this.state.address} onClick={this.openLocation} />
                                                                <label htmlFor=""><span>Challenge At</span></label>
                                                            </div>
                                                        </li>

                                                        <li className="createbroadcast_ul">
                                                            <button type="submit" id="disableSuccess" className="cmnbutton btncroadcast">Create Challenge</button>
                                                        </li>
                                                    </ul>
                                                </form>
                                            </div>
                                        </div>
                                }

                                <Modal
                                    size="md"
                                    show={this.state.showingPopup}
                                    onHide={this.closingPopup}
                                    animation={false}
                                    centered
                                    aria-labelledby="contained-modal-title-vcenter"
                                >
                                    <div className="modal-body modal-bodyErrorPopup">
                                        {this.state.showingAlert1 ? <p className="alert alert-success">
                                            <strong>Success!</strong> Challenge Created! </p> : ''}
                                        {this.state.showErrorMessage ? <p className="alert alert-danger"> {errorMessage} </p> : ''}
                                    </div>
                                </Modal>

                                <Modal
                                    size="md"
                                    show={this.state.openSports}
                                    onHide={this.closeSports}
                                    animation={false}
                                    aria-labelledby="example-modal-sizes-title-md"
                                >
                                    <div className="">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal"></button>
                                            <h4 className="modal-title">Select Sports</h4>
                                        </div>

                                        <div className="modal-body modal-bodyD modal-bodySport">
                                            <div className="createselect_team">
                                                <div className="createselect_search">
                                                    <form>
                                                        <input ref={(input) => { this.testInput = input; }} onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                                    </form>
                                                </div>
                                                <ul className="ct_selectsports">
                                                    {
                                                        allSports.filter(searchingFor(term)).map(item =>
                                                            <div key={item.name}>
                                                                <li className="li_selectsports">
                                                                    <span onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId)} className="assignimg" style={{ backgroundImage: "url(" + item.icon + ")" }}></span>
                                                                    <p onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId)}>{item.name}</p>
                                                                </li>
                                                            </div>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </Modal>

                                {/* Modal content to show opponents */}
                                {/* <div id="assignInviteBudd" className="modal fade" role="dialog">
                                    <div className="modal-dialog"> */}
                                <Modal
                                    size="md"
                                    show={this.state.modalOpentForInvite}
                                    animation={false}
                                    aria-labelledby="example-modal-sizes-title-md"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={() => this.closeModalOpentForInvite()}>&times;</button>
                                            <h4 className="modal-title">Invite Buddies</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="ac_search">
                                                <form>
                                                    <input onChange={this.searchHandler1} value={term1} type="search" placeholder="Search buddies" />
                                                    <Link to={"#"}><img src={searchnew} alt="true" /></Link>
                                                </form>
                                            </div>
                                            <ul className="playerlist">
                                                {
                                                    finalFilterData.filter(searchingFor1(term1)).map((item, index) => {
                                                        if (item.profilePic === null) {
                                                            switch (item.gender) {
                                                                case 'Male':
                                                                    return <li onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} key={item.name}>
                                                                        <span onClick={(e) => this.liRefs[item.contactNo].click()} className="assignimg" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                        <label onClick={(e) => this.liRefs[item.contactNo].click()} className="ac_name">{item.name}</label>
                                                                        <div className="styled-input-single createinput">
                                                                            <input ref={el => this.liRefs[item.contactNo] = el} type={this.state.typeOfInput} name="fieldset-15" id={"checkbox-example" + index} onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} />
                                                                            <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkbox-example" + index}></label>
                                                                        </div>
                                                                    </li>
                                                                default:
                                                                    return <li onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} key={item.name}>
                                                                        <span onClick={(e) => this.liRefs[item.contactNo].click()} className="assignimg" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                        <label onClick={(e) => this.liRefs[item.contactNo].click()} className="ac_name">{item.name}</label>
                                                                        <div className="styled-input-single createinput">
                                                                            <input ref={el => this.liRefs[item.contactNo] = el} type={this.state.typeOfInput} name="fieldset-15" id={"checkbox-example" + index} onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} />
                                                                            <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkbox-example" + index}></label>
                                                                        </div>
                                                                    </li>
                                                            }
                                                        }
                                                        else {
                                                            return <li onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} key={item.name}>
                                                                <span onClick={(e) => this.liRefs[item.contactNo].click()} className="assignimg" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                <label onClick={(e) => this.liRefs[item.contactNo].click()} className="ac_name">{item.name}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input ref={el => this.liRefs[item.contactNo] = el} type={this.state.typeOfInput} name="fieldset-15" id={"checkbox-example" + index} onClick={(evt) => this.invitedBudd(item.name, item.contactNo, evt)} />
                                                                    <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkbox-example" + index}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="modal-footer">
                                            <button /*onClick={() => this.invitedBuddSend(this.state.invitedBudd)}*/ type="button" className="cmnbutton" onClick={() => this.closeModalOpentForInvite()}>Done</button>
                                        </div>
                                    </div>
                                </Modal>


                                {/* Modal content to show my team for selection */}

                                <Modal
                                    size="md"
                                    show={this.state.modalOpentForTeamInvite}
                                    animation={false}
                                    aria-labelledby="example-modal-sizes-title-md"
                                >
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={() => this.closeModalOpentForTeamInvite()}>&times;</button>
                                            <h4 className="modal-title">Invite Buddies</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="ac_search">
                                                <form>
                                                    <input onChange={this.searchHandler2} value={term2} type="search" placeholder="Search teams" />
                                                    <Link to={"#"}><img src={searchnew} alt="true" /></Link>
                                                </form>
                                            </div>
                                            <ul className="playerlist">
                                                {
                                                    finalFilterDataTeam.filter(searchingFor2(term2)).map((item, index) => {
                                                        if (item.teamIcon === null) {
                                                            return <li key={item.teamName} onClick={() => this.mySelectedTeam(item.teamName, item.teamId)}>
                                                                <span onClick={(e) => this.liRefs[item.teamId].click()} className="assignimg" style={{ backgroundImage: "url(" + showDefaultTeam + ")" }}></span>
                                                                <label onClick={(e) => this.liRefs[item.teamId].click()} className="ac_name">{item.teamName}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input ref={el => this.liRefs[item.teamId] = el} type="radio" name="fieldset-14" id={"checkbox-example21" + index} onClick={() => this.mySelectedTeam(item.teamName, item.teamId)} />
                                                                    <label onClick={(e) => this.liRefs[item.teamId].click()} htmlFor={"checkbox-example21" + index}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                        else {
                                                            return <li key={item.teamName} onClick={() => this.mySelectedTeam(item.teamName, item.teamId)}>
                                                                <span onClick={(e) => this.liRefs[item.teamId].click()} className="assignimg" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                <label onClick={(e) => this.liRefs[item.teamId].click()} className="ac_name">{item.teamName}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input ref={el => this.liRefs[item.teamId] = el} type="radio" name="fieldset-14" id={"checkbox-example21" + index} onClick={() => this.mySelectedTeam(item.teamName, item.teamId)} />
                                                                    <label onClick={(e) => this.liRefs[item.teamId].click()} htmlFor={"checkbox-example21" + index}></label>
                                                                </div>
                                                            </li>
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="modal-footer">
                                            <button /*onClick={() => this.invitedBuddSend(this.state.invitedBudd)}*/ type="button" className="cmnbutton" onClick={() => this.closeModalOpentForTeamInvite()}>Done</button>
                                        </div>
                                    </div>
                                </Modal>

                            </React.Fragment>
                        </section>
                    </div>
                    <SectionRight />

                </div>
            </>
        )
    }
}

export default ChallengeList;