import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem("result"));


export const tt123MatchFeeds = async function (tID, mId, tMode, fId, sId, creatorId) {
    var apiUrl = userApi + '/tournaments/tt123-match-feeds';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tID,
                "matchId": mId,
                "type": tMode,
                "firstId": fId,
                "secondId": sId,
                "creatorId": creatorId,
            }
        })
    });
    let matchFeedsResponse = await response.json();
    return matchFeedsResponse;
}
export const ttWinnerFeeds = async function (tID, mId, wId) {
    var apiUrl = userApi + '/tournaments/winner-feeds';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tID,
                "matchId": mId,
                "winnerId": wId,
            }
        })
    });
    let matchFeedsResponse = await response.json();
    return matchFeedsResponse;
}

export const tt45MatchFeeds = async function () {
    var apiUrl = userApi + '/tournaments/tt45-match-feeds';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": null,
                "matchId": null,
                "tournamentMode": null,
                "firstId": null,
                "creatorId": null,

            }
        })
    });
    let matchFeedsResponse = await response.json();
    return matchFeedsResponse;
}
export const updatePointTable = async (tId, fId, sId, nrr1, nrr2, winnerId) => {

    var apiUrl = userApi + '/tournaments/update-points-table';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId,
                "winnerId": winnerId,
                "firstTeam": {
                    "teamId": fId,
                    "netRunRate": nrr1
                },
                "secondTeam": {
                    "teamId": sId,
                    "netRunRate": nrr2
                },
            }
        })
    });
    let pointTableResponse = await response.json();
    return pointTableResponse;

}

export const updateOrganizeTT23 = async (tId, organiseMatchObj) => {

    var apiUrl = userApi + "/tournaments/update-organize-tt23";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId,
                "organizeMatch": organiseMatchObj,
            }
        })
    });
    let updateOrganizeResponse = await response.json();
    return updateOrganizeResponse;
}
export const updateOrganizeTT4 = async (tId, organiseMatchObj) => {

    var apiUrl = userApi + "/tournaments/update-organize-tt4";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId,
                "organizeMatch": organiseMatchObj,
            }
        })
    });
    let updateOrganizeResponse = await response.json();
    return updateOrganizeResponse;
}

// export const updateOrganizeTT4 = async (tId, organiseMatchObj) => {
//
//     var apiUrl = userApi + "/tournaments/update-organize-tt4";
//     let response = await fetch(apiUrl, {
//         method: "post",
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             data: {
//                 "tournamentId": tId,
//                 "organizeMatch": organiseMatchObj,
//             }
//         })
//     });
//     let updateOrganizeResponse = await response.json();
//     return updateOrganizeResponse;
// }
export const updateTournament = async (tournament) => {

    var apiUrl = userApi + "/tournaments/update-tournament";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournament": tournament,
            }
        })
    });
    let updateOrganizeResponse = await response.json();
    return updateTournament;
}

export const updateFixture = async (tId, fixture) => {

    var apiUrl = userApi + "/tournaments/update-fixture";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId,
                "fixture": fixture,
            }
        })
    });
    let updateFixture = await response.json();
    return updateFixture;
}


export const createFixture = async (tId, fixture) => {

    var apiUrl = userApi + "/tournaments/create-fixture";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId,
                "fixture": fixture,
            }
        })
    });
    let createFixture = await response.json();
    return createFixture;
}


export const getTournamentObject = async (tId) => {

    var apiUrl = userApi + "/tournaments/get-tournament";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                "tournamentId": tId
            }
        })
    });
    let tournament = await response.json();
    return tournament;
}



