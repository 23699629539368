import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d107_tennis_view extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchSatus: false,
            rounds: [],
        };

    }
    componentDidMount() {
        // console.log(this.props.challenge);
        // console.log(this.props.profileTeams);
        // console.log(this.props.profileUsers);

        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": this.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    //users:result.data.users
                    teams: result.data.teams
                })
                if (result.data.score !== null) {
                    if (result.data.score.d107TennisScoreCard.byTennisScoreCard.setsWon !== undefined) {
                        if (result.data.score.d107TennisScoreCard.byTennisScoreCard.setsWon !== null) {
                            that.setState({ bySetWon: result.data.score.d107TennisScoreCard.byTennisScoreCard.setsWon.length, byWinner: result.data.score.d107TennisScoreCard.byTennisScoreCard.winner });

                        }
                        else {
                            that.setState({ bySetWon: 0, byWinner: result.data.score.d107TennisScoreCard.byTennisScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }
                    if (result.data.score.d107TennisScoreCard.toTennisScoreCard.setsWon !== undefined) {
                        if (result.data.score.d107TennisScoreCard.toTennisScoreCard.setsWon !== null) {
                            that.setState({ toSetWon: result.data.score.d107TennisScoreCard.toTennisScoreCard.setsWon.length, toWinner: result.data.score.d107TennisScoreCard.toTennisScoreCard.winner });

                        }
                        else {
                            that.setState({ toSetWon: 0, toWinner: result.data.score.d107TennisScoreCard.toTennisScoreCard.winner });

                        }
                        that.setState({ matchSatus: true });
                    }


                    that.setState({ rounds: result.data.score.d107TennisScoreCard.rounds, scorecard: result.data.score.d107TennisScoreCard })

                    that.props.profileUsers.map(user => {
                        if (user.contactNo === result.data.score.d107TennisScoreCard.byTennisScoreCard.id) {
                            that.setState({ byTennisScoreCard: user.name });
                        }
                        if (user.contactNo === result.data.score.d107TennisScoreCard.toTennisScoreCard.id) {
                            that.setState({ toTennisScoreCard: user.name });
                        }
                    })
                    that.props.profileTeams.map(team => {
                        if (team.teamId === result.data.score.d107TennisScoreCard.byTennisScoreCard.id) {
                            that.setState({ byTennisScoreCard: team.teamName });
                        }
                        if (team.teamId === result.data.score.d107TennisScoreCard.toTennisScoreCard.id) {
                            that.setState({ toTennisScoreCard: team.teamName });
                        }
                    })
                }
            });
        if (that.props.challenge.status === 'ended') {

            that.setState({ matchSatus: true });
        }
    }

    render() {
        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;
        if (this.state.matchSatus) {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>
                                                <th>Sets/Won</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byTennisScoreCard}</td>
                                                <td>{this.state.bySetWon}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toTennisScoreCard}</td>
                                                <td>{this.state.toSetWon}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h4 class="scorehead">Match Score</h4>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>

                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <th> Set{i} </th>
                                                    }

                                                })}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.byTennisScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedByPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toTennisScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0) {
                                                        return <td> {k.challengedToPoints} </td>
                                                    }

                                                })}
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment >
            );
        }
    }
}
