import React from 'react';
import Header from './Header'
import SectionLeft from './SectionLeft'
import TeamProfile from './TeamProfile'
import SectionRight from './SectionRight'
import $ from "jquery";
import BuddyRequest from './BuddyRequest';
import ChatonRight from './ChatonRight';

function TeamProfilePage() {
    const handleCallbacktoParent = (childData) => {
        if (childData === 'openSidebar') {
            $(".d_midsec").css("position", "fixed");
        }
        if (childData === 'closeSidebar') {
            $(".d_midsec").css("position", "unset");
        }
    }
    return (
        <div>
            <Header parentCallbackfromChild={handleCallbacktoParent} />
            <div className="container containerbottom non-session-d">
                <SectionLeft parentCallbackfromChild2={handleCallbacktoParent} />

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <section className="d_midsec">
                        <TeamProfile />
                    </section>
                </div>

                <SectionRight />

            </div>
        </div>
    );
}

export default TeamProfilePage;
