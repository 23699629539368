import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import GetCurrentTimestamp from '../GetCurrentTimestamp';
import { Link } from 'react-router-dom';
import team from '../img/team.png';
import challenegbg from '../img/challenegbg.png';
import searchnew from '../img/searchnew.png';
import camera from '../img/camera.png';
import ConfigUrl from '../ConfigUrl';
import Map from '../Map';
import { trackPromise } from "react-promise-tracker";
import $ from 'jquery';

import * as myConstclass from '../ConstFile';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const currentTimeStamp = GetCurrentTimestamp();

function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}
export default class CreateDemoTournamentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            touched: {},
            formSubmitted: false,
            allSports: [],
            term: '',
            ShowSingle: false,
            ShowTeam: false,
            tType: [],
            showLeagueOption: false,
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));

        this.changeTLevel = this.changeTLevel.bind(this);
        this.changeTType = this.changeTType.bind(this);
        this.getSportsFunction = this.getSportsFunction.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.selectGender = this.selectGender.bind(this);
        this.createTForm = this.createTForm.bind(this);
        this.selectTmode = this.selectTmode.bind(this);
    }
    selectGender(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleRegDate = (date) => {
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tRegDate'] = dateFormate;

        this.setState({
            regDate: date, fields, formSubmitted: false
        });
    };
    handleStartDate = (date) => {
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tStartDate'] = dateFormate;

        this.setState({
            startDate: date, fields, formSubmitted: false
        });
    };
    handleEndDate = (date) => {

        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tEndDate'] = dateFormate;
        this.setState({
            endDate: date, fields, formSubmitted: false
        });
    };
    selectTmode(e) {
        let fields = this.state.fields;
        fields['tMode'] = e.target.value
        this.setState({
            fields
        });
    }
    changeTLevel(e) {
        let fields = this.state.fields;
        fields['tLevel'] = e.target.value
        this.setState({
            fields
        });
    }
    changeTType(e) {
        let fields = this.state.fields;
        fields['tType'] = parseInt(e.target.value);
        this.setState({
            fields
        });
        if (e.target.value === "2" || e.target.value === "3") {
            this.setState({ showLeagueOption: true });
        }
        else {
            this.setState({ showLeagueOption: false });
        }

    }
    componentDidMount() {
        this.getSportsFunction();
    }
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }
    selectSports = () => {

        document.getElementById('hideSports').style = "block";

    }
    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        let fields = this.state.fields;
        fields['location'] = childData.address;
        fields['city'] = childData.city;
        fields['state'] = childData.state;
        fields['country'] = childData.country;
        fields['latitude'] = childData.latValue;
        fields['longitude'] = childData.lngValue;
        this.setState({
            fields
        });
    }

    chooseSport = (takeOnlySport, takeOnlySportIds, takeOnlyCategory, tType) => {

        this.setState({ tType: tType });
        let fields = this.state.fields;
        fields['sportId'] = takeOnlySportIds;
        fields['sportsName'] = takeOnlySport;
        fields['categoryId'] = takeOnlyCategory;
        this.setState({
            fields
        });
        if (takeOnlyCategory === "D101" || takeOnlyCategory === "D102" || takeOnlyCategory === "D103"
            || takeOnlyCategory === "D105") {
            this.setState({ ShowSingle: true });
            let fields = this.state.fields;
            fields['tMode'] = "buddy"
            this.setState({
                fields
            });
        }
        else if (takeOnlyCategory === "D106") {
            this.setState({ ShowTeam: true });
            let fields = this.state.fields;
            fields['tMode'] = "team"
            this.setState({
                fields
            });
        }
        else if (takeOnlyCategory === "D107") {
            if (takeOnlySport === 'Baseball' || takeOnlySport === 'Softball' || takeOnlySport === 'Cricket' || takeOnlySport === 'Rugby' || takeOnlySport === 'Beach Volleyball' || takeOnlySport === 'Volleyball' || takeOnlySport === 'Basketball' || takeOnlySport === 'Kabaddi') {
                this.setState({ ShowTeam: true });
                let fields = this.state.fields;
                fields['tMode'] = "team"
                this.setState({
                    fields
                });
            }
            else if (takeOnlySport === 'Golf' || takeOnlySport === 'Softball' || takeOnlySport === 'Cricket') {

                this.setState({ ShowSingle: true });
                let fields = this.state.fields;
                fields['tMode'] = "buddy"
                this.setState({
                    fields
                });
            }
        }
        else if (takeOnlyCategory === "D104") {
            if (takeOnlySport === '4*100 Meter relay' || takeOnlySport === '4*400 Meter Relay' || takeOnlySport === '4*100 Meter Hurdle') {
                this.setState({ ShowTeam: true });
                let fields = this.state.fields;
                fields['tMode'] = "team"
                this.setState({
                    fields
                });
            }
            else {
                this.setState({ ShowSingle: true });
                let fields = this.state.fields;
                fields['tMode'] = "buddy"
                this.setState({
                    fields
                });
            }
        }
        this.setState({
            takeOnlySport: takeOnlySport,
            takeOnlySportIds: takeOnlySportIds,
            takeOnlyCategory: takeOnlyCategory,
        });
        document.getElementById('hideSports').style.visibility = "hidden";
    }
    getSportsFunction() {
        const apiUrl = rshApi + "/sports/get-sports";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "minimal": "false",
                    "isGroupGame": "isGroupGame"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    allSports: result.data
                });
                console.log(that.state.allSports)
            });
    }
    handleTouch(e) {
        let { touched } = this.state;
        if (e.target.name && touched[e.target.name] != true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            });
        }
    }
    createTForm(e) {
        e.preventDefault();
        this.setState({
            formSubmitted: true
        });
        if (this.validateForm()) {


            var tournament = {
                tournamentName: this.state.fields.tName,
                tournamentIcon: null,
                sportsId: this.state.fields.sportId,
                sportsName: this.state.fields.sportsName,
                categoryId: this.state.fields.categoryId,
                createdOn: currentTimeStamp,
                modifiedOn: currentTimeStamp,
                createdBy: this.state.user.contactNo,
                creatorName: this.state.user.name,
                tournamentMode: this.state.fields.tMode,
                gender: this.state.fields.gender,
                city: this.state.fields.city,
                state: this.state.fields.state,
                country: this.state.fields.country,
                location: this.state.fields.location,
                latitude: this.state.fields.latitude,
                longitude: this.state.fields.longitude,
                regDate: this.state.fields.tRegDate,
                startDate: this.state.fields.tStartDate,
                endDate: this.state.fields.tEndDate,
                tournamentLevel: this.state.fields.tLevel,
                entryFees: this.state.fields.eFees ? this.state.fields.eFees : "",
                prize: this.state.fields.price ? this.state.fields.price : "",
                rules: this.state.fields.rules ? this.state.fields.rules : "",
                description: this.state.fields.description ? this.state.fields.rules : "",
                courseSelected: false,
                courseId: null,
                courseRound: 0,
                poolType: null,
                tournamentType: this.state.fields.tType,
                winnerPoints: this.state.fields.winner ? parseInt(this.state.fields.winner) : 0,
                drawPoints: this.state.fields.draw ? parseInt(this.state.fields.draw) : 0,
                looserPoints: this.state.fields.points ? parseInt(this.state.fields.points) : 0,
                matchType: null,
                invited: null,
                accepted: null,
                status: null,
                tournamentWinnerId: null,
                scheduledTournament: false,
                manOfTheSeries: null,
                bestBatsman: null,
                bestBowler: null,
                bestFielder: null
            }


            const apiUrl = rshApi + "/demo-tournaments/create-tournament";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        tournament
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    $('#createTornament').show();
                    $('#createTornament').delay(1000).fadeOut('slow');
                    setTimeout(() => {
                        window.location = "/dtournamentprofile/index.html?id=" + result.data.id
                    }, 2000);

                }).catch(function (error) {

                })
            let fields = {};
            fields["tName"] = "";
            fields["tRegDate"] = "";
            fields["tStartDate"] = "";
            fields["tEndDate"] = "";
            fields["location"] = "";
            fields["city"] = "";
            fields["state"] = "";
            fields["country"] = "";
            fields["latitude"] = "";
            fields["longitude"] = "";
            fields["eFees"] = "";
            fields["price"] = "";
            fields["rules"] = "";
            fields["description"] = "";
            that.setState({ fields: fields });
        }
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["tName"]) {
            formIsValid = false;
            errors["tName"] = "*Please Enter Tournament Name.";
        }
        if (!fields["location"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["city"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["state"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["country"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["latitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["longitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["tRegDate"]) {
            formIsValid = false;
            errors["tRegDate"] = "*Please select Tournament Registration Date";
        }
        if (!fields["tStartDate"]) {
            formIsValid = false;
            errors["tStartDate"] = "*Please select Tournament Start Date";
        }
        if (!fields["tEndDate"]) {
            formIsValid = false;
            errors["tEndDate"] = "*Please select Tournament End Date";
        }
        if (fields["tType"] === 2 || fields["tType"] === 3) {
            if (!fields["winner"]) {
                formIsValid = false;
                errors["winner"] = "*Please Fill Winner Point";
            }
            if (!fields["draw"]) {
                formIsValid = false;
                errors["draw"] = "*Please Fill Draw Point";
            }
            if (!fields["points"]) {
                formIsValid = false;
                errors["points"] = "*Please Fill Looser Point";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    render() {

        var TOption;
        // this.state.tType.map((tType) => {
        //     var idOpt = "ttOption" + tType;
        //     var tOp = <React.Fragment>
        //         <label class="cc_box-head">Tournament Type</label>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id={idOpt} value={tType} />
        //             <label for={idOpt}>Knockout</label>
        //         </div>
        //     </React.Fragment>
        //     TOption.push(tOp);
        // })
        if (this.state.tType.length === 1) {
            if (this.state.tType == 5) {
                TOption = <React.Fragment>
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption5" value="5" />
                        <label for="ttOption5">Straight final</label>
                    </div></React.Fragment>
            }
            if (this.state.tType == 1) {
                TOption = <React.Fragment>
                    <label class="cc_box-head">Tournament Type</label>
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
                        <label for="ttOption1">Knockout</label>
                    </div>
                </React.Fragment>
            }
            if (this.state.tType == 4) {
                TOption = <React.Fragment>
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption4" value="4" />
                        <label for="ttOption4">Pool based knockout</label>
                    </div>
                </React.Fragment>
            }
        }
        // if (this.state.tType.length === 2) {
        //     TOption = <React.Fragment>
        //         <label class="cc_box-head">Tournament Type</label>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
        //             <label for="ttOption1">Knockout</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption2" value="2" />
        //             <label for="ttOption2">Straight league</label>
        //         </div>
        //     </React.Fragment>
        // }
        if (this.state.tType.length === 3) {
            TOption = <React.Fragment>
                <label class="cc_box-head">Tournament Type</label>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
                    <label for="ttOption1">Knockout</label>
                </div>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption2" value="2" />
                    <label for="ttOption2">Straight league</label>
                </div>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption3" value="3" />
                    <label for="ttOption3">Pool based league</label>
                </div>

            </React.Fragment>
        }
        // if (this.state.tType.length === 4) {
        //     TOption = <React.Fragment>
        //         <label class="cc_box-head">Tournament Type</label>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
        //             <label for="ttOption1">Knockout</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption2" value="2" />
        //             <label for="ttOption2">Straight league</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption3" value="3" />
        //             <label for="ttOption3">Pool based league</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption4" value="4" />
        //             <label for="ttOption4">Pool based knockout</label>
        //         </div>
        //     </React.Fragment>
        // }
        // if (this.state.tType.length === 5) {
        //     TOption = <React.Fragment>
        //         <label class="cc_box-head">Tournament Type</label>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
        //             <label for="ttOption1">Knockout</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption2" value="2" />
        //             <label for="ttOption2">Straight league</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption3" value="3" />
        //             <label for="ttOption3">Pool based league</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption4" value="4" />
        //             <label for="ttOption4">Pool based knockout</label>
        //         </div>
        //         <div class="styled-input-single createinput">
        //             <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption5" value="5" />
        //             <label for="ttOption5">Straight final</label>
        //         </div></React.Fragment>
        // }


        const { term, allSports } = this.state;
        return (
            <section class="d_midsec createmidsec">
                <div class="divpost">
                    <span class="teamprofile_span">Create Demo  Tournament</span>
                    <div class="createteam_div" style={{ backgroundImage: "url(" + challenegbg + ")" }}>
                        <button>Change Picture</button>
                        <div class="uploadimg_div" ><span style={{ backgroundImage: "url(" + team + ")" }}></span><a><img src={camera} /></a></div>
                    </div>
                </div>
                <form onSubmit={this.createTForm}>
                    <div class="createteamform tournamentform">
                        <ul>
                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" name="tName" class="question" id="" autocomplete="off" onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} />

                                    <label for=""><span>Tournament Name</span></label>
                                    {
                                        this.state.formSubmitted || this.state.touched.tName
                                            ?
                                            <div className="errorMsg1">{this.state.errors.tName}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="commaninputdiv">
                                    <input type="text" name="name" className="question" autoComplete="off" onClick={() => this.selectSports()} value={this.state.takeOnlySport} />
                                    <label htmlFor=""><span>Select Sports</span></label>

                                    <div id="hideSports" style={{ display: 'none' }} className="createselect_team">
                                        <div className="createselect_search">
                                            <form>
                                                <input onChange={this.searchHandler} type="search" placeholder="search" value={term} />
                                                <Link to={"#"}><img src={searchnew} alt="true" /></Link>
                                            </form>
                                        </div>
                                        <ul className="ct_selectsports">
                                            {allSports.filter(searchingFor(term)).map(item =>
                                                <div key={item.name}>
                                                    <li>
                                                        <span onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)} className="assignimg" style={{ backgroundImage: "url(" + item.icon + ")" }}></span>
                                                        <p onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)}>{item.name}</p>
                                                    </li>
                                                </div>
                                            )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="cc_box">
                                <div class="cc_inner_box  opponent_inner">
                                    <label class="cc_box-head">Select Team</label>
                                    <div class="commaninputdiv createredio">
                                        <div class="styled-input-single createinput">

                                            {this.state.ShowSingle ? <input type="radio" name="tMode" id="single" onChange={this.selectTmode} defaultChecked={true} value="buddy" /> : <input type="radio" name="tMode" id="single" value="buddy" onChange={this.selectTmode} />}
                                            <label for="single">Single</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            {this.state.ShowTeam ? <input name="tMode" type="radio" id="team" onChange={this.selectTmode} defaultChecked={true} value="team" /> : <input type="radio" name="tMode" id="team" onChange={this.selectTmode} value="team" />}

                                            <label for="team">Team</label>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv creategender">
                                    <label>Gender</label>

                                    <div class="creategenderdiv">
                                        <div class="styled-input-single createinput male">
                                            <input type="radio" name="gender" id="male" onChange={this.selectGender} value="Male" />
                                            <label className="gender-d" for="male"></label>
                                        </div>
                                        <div class="styled-input-single createinput female">
                                            <input type="radio" name="gender" id="female" onChange={this.selectGender} value="Female" />
                                            <label className="gender-d" for="female"></label>
                                        </div>
                                        <div class="styled-input-single createinput mix">
                                            <input type="radio" name="gender" id="mix" onChange={this.selectGender} value="Both" />
                                            <label className="gender-d" for="mix"></label>
                                        </div>
                                    </div>

                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" autocomplete="off" /> */}
                                    <Map parentCallback={this.callbackFunction} />

                                    {/* <label for=""><span>Location</span>
                                </label> */}


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.location}</div>
                                        :
                                        ''
                                }
                            </li>

                            <li class="" >
                                <div class="commaninputdiv ccdatetime1" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" placeholder="" autocomplete="off" /> */}
                                    <span>Reg Date</span>
                                    <DatePicker
                                        name="tRegDate"
                                        selected={this.state.regDate}
                                        onChange={this.handleRegDate}
                                        minDate={new Date()}
                                    />


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tRegDate}</div>
                                        :
                                        ''
                                }

                                <div class="commaninputdiv ccdatetime1" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" placeholder="" autocomplete="off" />
                                <label for=""><span>Start Date</span></label>
                                 */}
                                    <span>Start Date</span>
                                    <DatePicker
                                        name="tStartDate"
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDate}
                                        minDate={new Date()}
                                    />


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tStartDate}</div>
                                        :
                                        ''
                                }
                                <div class="commaninputdiv" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" autocomplete="off" />
                                <label for=""><span>End Time</span></label> */}
                                    <span>End Date</span>
                                    <DatePicker

                                        name="tEndDate"
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDate}
                                        minDate={this.state.startDate}
                                    />

                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tEndDate}</div>
                                        :
                                        ''
                                }

                            </li>

                            <li>
                                <div class="cc_inner_box  opponent_inner">
                                    <label class="cc_box-head">Tournament Level</label>
                                    <div class="commaninputdiv createredio">
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="local" value="local" />
                                            <label for="local">Local</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="state" value="state" />
                                            <label for="state">State</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="national" value="national" />
                                            <label for="national">National</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="cc_inner_box  opponent_inner">

                                    {TOption}
                                </div>
                            </li>
                            {this.state.showLeagueOption ? <li class="cc_datetimeli cc_box">
                                <div class="commaninputdiv ccdatetime1">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="winner" class="question" id="" required="" placeholder="points" autocomplete="off" />
                                    <label for=""><span>Winner</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.winner}</div>
                                            :
                                            ''
                                    }
                                </div>


                                <div class="commaninputdiv ccdatetime1">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="draw" class="question" id="" required="" placeholder="points" autocomplete="off" />
                                    <label for=""><span>Draw</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.draw}</div>
                                            :
                                            ''
                                    }
                                </div>

                                <div class="commaninputdiv">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="points" class="question" id="" required="" autocomplete="off" placeholder="points" />
                                    <label for=""><span>Looser</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.points}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </li> : ''}

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="eFees" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Entry Fees</span></label>
                                </div>
                            </li>
                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="price" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Price</span></label>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="rules" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Rules</span></label>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="description" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Description</span></label>
                                </div>
                            </li>

                        </ul>
                        <button class="createnext" type="submit">Next</button>
                    </div>
                    <div id="createTornament" style={{ display: 'none', margin: '5px' }} className="alert alert-success " role="alert">
                        <strong>Tornament Created SuccesFully!</strong>
                    </div>
                </form>
            </section>
        )
    }
}
