import React, { Component } from 'react'
import challengefill from "./img/challengefill.png";
import teamIcon from "./img/team.png";
import schedulematches from "./img/schedulematches.png";
import location from "./img/location.png";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import Map from './Map';
import male from "./img/male.png";
import * as myConstclass from './ConstFile';
import ConfigUrl from './ConfigUrl';
import { trackPromise } from "react-promise-tracker";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

const MATCH_TYPE_QUARTER_FINAL = "Quarter final";
const MATCH_TYPE_SEMI_FINAL = "Semi final";
const MATCH_TYPE_FINAL = "Final";

const quarterFinals = [
    {
        pairId: 1, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 3, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 4, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const semiFinals = [
    {
        pairId: 1, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2, matchId: null,
        firstId: null, secondId: null,
        ended: false, draw: false,
        date: null, time: null,
        winnerId: null, rankId: 0,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const final = {
    pairId: 1, matchId: null,
    firstId: null, secondId: null,
    ended: false, draw: false,
    date: null, time: null,
    winnerId: null, rankId: 0,
    location: null, city: null,
    state: null, country: null,
    latitude: null, longitude: null
};
const organizeMatch = {
    ttType: null,
    tt2Matches: null,
    tt3Matches: null,
    quarterFinals: null,
    semiFinals: null,
    finals: null,
}
export default class OrganiseMatchCreate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDateTime: false,
            tDateTime: [new Date()],
            dateDate: myConstclass.getDateYYYYmmdd(new Date()),
            dateTime: myConstclass.getTimeFromDate(new Date()),
            fields: {},
            errors: {},
            touched: {},
            acceptedPairState: [],
            showQuarter: false,
            showQuarterList: false,
            showSemi: true,
            showSemiList: true,
            defaultQuarter: true,
            defaultSemi: true,
            FinaldateObj: null,
            locationType: '',

        }
        this.semiORfinal = this.semiORfinal.bind(this);
        this.quarterORsemi = this.quarterORsemi.bind(this);
    };
    componentDidMount() {
        console.log(this.props.tournamentData);
        // console.log(this.props.tournamentMode);
        // console.log(this.props.usersInTournament);
        // console.log(this.props.teamsInTournament);
        if (this.props.tournamentData.accepted.length > 11) {
            this.setState({ showQuarter: true, showQuarterList: true, showSemi: false })
        }
        else {
            this.setState({ showSemi: true })
        }
        let acceptedPair = [];
        Array.prototype.pairs = function (func) {
            for (var i = 0; i < this.length - 1; i++) {
                for (var j = i; j < this.length - 1; j++) {
                    func([this[i], this[j + 1]]);
                }
            }
        }
        this.props.tournamentData.accepted.pairs(function (pair) {
            let data = {
                firstId: pair[0],
                secondId: pair[1],
                dateObj: null,
            }
            acceptedPair.push(data);
        });
        console.log(acceptedPair)
        this.setState({ acceptedPairState: acceptedPair });

    }
    quarterORsemi(e) {
        if (e.target.value === 'quarter-final') {
            this.setState({ showQuarterList: true, showSemiList: true })
        }
        if (e.target.value === 'semi-final') {
            this.setState({ showQuarterList: false })
        }
    }
    semiORfinal(e) {
        if (e.target.value === 'semi-final') {
            this.setState({ showSemiList: true })
        }
        if (e.target.value === 'final') {
            this.setState({ showSemiList: false })
        }
    }
    setTdateTime = (date) => {

        let alldata = this.state.acceptedPairState;
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        alldata[this.state.Dateindex].dateObj = date;
        alldata[this.state.Dateindex].date = dateFormate;
        alldata[this.state.Dateindex].time = dateTime;
        this.setState({
            acceptedPairState: alldata
        });
        this.setState({
            dateDate: dateFormate, dateTime: dateTime
        });
    };
    setTdateTimeFinal = (date) => {
        this.setState({ FinaldateObj: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        final.date = dateFormate;
        final.time = dateTime;
    }
    setTdateTimeSemiFinal1 = (date) => {
        this.setState({ SemiFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[0].date = dateFormate;
        semiFinals[0].time = dateTime;
    }
    setTdateTimeSemiFinal2 = (date) => {
        this.setState({ SemiFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[1].date = dateFormate;
        semiFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal1 = (date) => {
        this.setState({ QuarterFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[0].date = dateFormate;
        quarterFinals[0].time = dateTime;
    }
    setTdateTimeQuarterFinal2 = (date) => {
        this.setState({ QuarterFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[1].date = dateFormate;
        quarterFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal3 = (date) => {
        this.setState({ QuarterFinaldateObj3: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[2].date = dateFormate;
        quarterFinals[2].time = dateTime;
    }
    setTdateTimeQuarterFinal4 = (date) => {
        this.setState({ QuarterFinaldateObj4: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[3].date = dateFormate;
        quarterFinals[3].time = dateTime;
    }
    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        if (this.state.locationType === 'final') {
            final.location = childData.address;
            final.city = childData.city;
            final.state = childData.address;
            final.country = childData.country;
            final.latitude = childData.latValue;
            final.longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'semi-final') {

            semiFinals[this.state.Locationindex].location = childData.address;
            semiFinals[this.state.Locationindex].city = childData.city;
            semiFinals[this.state.Locationindex].state = childData.address;
            semiFinals[this.state.Locationindex].country = childData.country;
            semiFinals[this.state.Locationindex].latitude = childData.latValue;
            semiFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'quarter-final') {

            quarterFinals[this.state.Locationindex].location = childData.address;
            quarterFinals[this.state.Locationindex].city = childData.city;
            quarterFinals[this.state.Locationindex].state = childData.address;
            quarterFinals[this.state.Locationindex].country = childData.country;
            quarterFinals[this.state.Locationindex].latitude = childData.latValue;
            quarterFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else {
            let alldata = this.state.acceptedPairState;
            alldata[this.state.Locationindex].location = childData.address;
            alldata[this.state.Locationindex].city = childData.city;
            alldata[this.state.Locationindex].state = childData.address;
            alldata[this.state.Locationindex].country = childData.country;
            alldata[this.state.Locationindex].latitude = childData.latValue;
            alldata[this.state.Locationindex].longitude = childData.lngValue;
        }
    }
    datepicker = (id) => {
        this.setState({ Dateindex: id })
    }

    location = (id, type) => {
        this.setState({ Locationindex: id, locationType: type })
    }
    closeInSuffcient() {
        this.setState({ showInSufficentBuddy: false })
    }
    organiseTournament = () => {
        var league = [...this.state.acceptedPairState];

        league.map((acceptedPair, index) => {
            acceptedPair.pairId = index + 1;
            acceptedPair.matchId = this.props.tournamentData.tournamentId + "-" + acceptedPair.firstId + "-" + acceptedPair.secondId;
            acceptedPair.ended = null;
            acceptedPair.draw = null;
            acceptedPair.winnerId = null;
            delete acceptedPair.dateObj;
        })
        let formLeagueError = false;
        let formQuarterError = false;
        let formSemiError = false;
        let formFinalError = false;
        league.map(league => {
            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })
        if (this.state.showQuarterList) {
            quarterFinals.map(qf => {
                if (qf.date !== undefined && qf.date !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
                if (qf.city !== undefined && qf.city !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
            })
        }
        if (this.state.showSemiList) {
            semiFinals.map(sf => {
                if (sf.date !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
                if (sf.city !== undefined && sf.city !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
            })
        }
        if (final.date !== null) {
            formFinalError = false;
        }
        else {

            formFinalError = true;
        }
        if (final.city !== undefined && final.city !== null) {
            formFinalError = false;
        }
        else {
            formFinalError = true;
        }

        if (formLeagueError == true || formQuarterError == true || formSemiError || formFinalError == true) {
            $('#organiseTornamentError').show();
            $('#organiseTornamentError').delay(2000).fadeOut('slow');
        }
        else {
            let quarterData;
            if (this.state.showQuarterList) {
                quarterData = [...quarterFinals];
            }
            else {
                quarterData = null;
            }
            let semiData;
            if (this.state.showSemiList) {
                semiData = [...semiFinals];
            }
            else {
                semiData = null;
            }

            organizeMatch.ttType = 2;
            organizeMatch.tt2Matches = league;
            organizeMatch.tt3Matches = null;
            organizeMatch.quarterFinals = quarterData;
            organizeMatch.semiFinals = semiData;
            organizeMatch.finals = final;

            const apiUrl = rshApi + "/tournaments/organize-tt23";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        tournamentId: that.props.tournamentData.tournamentId,
                        organizeMatch: organizeMatch,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)
                    $('#organiseTornamentSuccess').show();
                    $('#organiseTornamentSuccess').delay(2000).fadeOut('slow');

                    // this.props.afterOrganiseList();

                }).catch(function (error) {

                })

            var tournament = that.props.tournamentData;
            let matchType;
            if (that.state.showQuarterList) {
                matchType = "Quarter final";
            }
            else if (that.state.showSemiList) {
                matchType = "Semi final";
            }
            else {
                matchType = "Final";
            }
            tournament.matchType = matchType;
            tournament.scheduledTournament = true;
            delete tournament.id;
            var apiUrl1 = rshApi + "tournaments/update-tournament";
            fetch(apiUrl1, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournament": tournament,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    that.props.afterOrganiseList();
                });


        }
    }
    render() {
        let datetime = this.state.tDateTime;
        let firstName, secondName, firstPic, secondPic;
        let datePickerId = 0;
        var organiseList = [];
        this.state.acceptedPairState.map((pair, index) => {
            datePickerId++;
            let newId = pair.firstId + pair.secondId
            if (this.props.tournamentMode === 'team') {
                this.props.teamsInTournament.map(team => {
                    if (team.teamId === pair.firstId) {
                        firstName = team.teamName;
                        firstPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === pair.secondId) {
                        secondName = team.teamName;
                        secondPic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                });
            }
            else {
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === pair.firstId) {
                        firstName = user.name;
                        firstPic = user.profilePic ? user.profilePic : male;
                    }
                    if (user.contactNo === pair.secondId) {
                        secondName = user.name;
                        secondPic = user.profilePic ? user.profilePic : male;
                    }
                });
            }
            this.props.usersInTournament.map(user => {
                if (user.contactNo === pair.firstId) {
                    firstName = user.name;
                    firstPic = user.profilePic ? user.profilePic : male;
                }
                if (user.contactNo === pair.secondId) {
                    secondName = user.name;
                    secondPic = user.profilePic ? user.profilePic : male;
                }
            });

            var organise = <li class="leauge_matchdiv" key={index} >
                <div class="teamdiv">
                    <ul class="live_teamul live_teamul1 straight_ul">
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + firstPic + ")" }}></span>
                            <label class=" straight_player">{firstName}</label>
                        </li>
                        <li>
                            <img class="vsicon" src={challengefill} alt="" />
                        </li>
                        <li>
                            <span class="lt_img"
                                style={{ backgroundImage: "url(" + secondPic + ")" }}></span>
                            <label class="straight_player">{secondName}</label>
                        </li>
                    </ul>
                    <div class="straight_inputs" >
                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }} onClick={() => this.datepicker(index, "pool")}>
                            <DatePicker
                                selected={pair.dateObj}
                                onChange={this.setTdateTime}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Click to select a date"
                            />

                            <img src={schedulematches} alt="" />
                        </div>
                        <div class="fixture_date fixture_location" onClick={() => this.location(index, 'league')}>
                            <Map parentCallback={this.callbackFunction} />
                            <img src={location} alt="" />
                        </div>
                    </div>
                </div>
            </li>
            organiseList.push(organise)

        })

        return (
            <React.Fragment>
                <div class="back_white">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                {organiseList}
                            </ul>
                        </div>
                    </div>

                </div>

                <div class="schedule_pad straight_pad">
                    <div class="cc_inner_box">
                        <label class="cc_box-head">Format</label>
                        <div class="schedulebox">
                            {this.state.showQuarter ?
                                <React.Fragment>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="quarter-final" id="quarter-final" defaultChecked={this.state.defaultQuarter} onChange={this.quarterORsemi} />
                                        <label for="quarter-final">Quarter Final</label>
                                    </div>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="semi-final" id="semi-final" onChange={this.quarterORsemi} />
                                        <label for="semi-final">Semi Final</label>
                                    </div>
                                </React.Fragment>
                                : ''}
                            {this.state.showSemi ?
                                <React.Fragment>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="semi-final" id="semi-final" defaultChecked={this.state.defaultSemi} onChange={this.semiORfinal} />
                                        <label for="semi-final">Semi Final</label>
                                    </div>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="final" id="final" onChange={this.semiORfinal} />
                                        <label for="final">Final</label>
                                    </div>
                                </React.Fragment> : ""}
                        </div>
                    </div>
                </div>


                {this.state.showQuarterList ?
                    <React.Fragment>
                        <div class="semi_headingdiv quater-final">
                            <label >Quarter Final</label>
                        </div>
                        <div class="back_white">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date">
                                                        <DatePicker
                                                            selected={this.state.QuarterFinaldateObj1}
                                                            onChange={this.setTdateTimeQuarterFinal1}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(0, 'quarter-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date">
                                                        <DatePicker
                                                            selected={this.state.QuarterFinaldateObj2}
                                                            onChange={this.setTdateTimeQuarterFinal2}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(1, 'quarter-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="back_white">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date">
                                                        <DatePicker
                                                            selected={this.state.QuarterFinaldateObj3}
                                                            onChange={this.setTdateTimeQuarterFinal3}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(2, 'quarter-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date">
                                                        <DatePicker
                                                            selected={this.state.QuarterFinaldateObj4}
                                                            onChange={this.setTdateTimeQuarterFinal4}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(3, 'quarter-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </React.Fragment> : ""}

                {this.state.showSemiList ?
                    <React.Fragment>
                        <div class="semi_headingdiv semi-final">
                            <label >Semi Final</label>
                        </div>

                        <div class="back_white">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                        <DatePicker
                                                            selected={this.state.SemiFinaldateObj1}
                                                            onChange={this.setTdateTimeSemiFinal1}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(0, 'semi-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="scoreboxul tourcompleted_match">

                                        <li class="leauge_matchdiv">
                                            <div class="teamdiv">
                                                <ul class="live_teamul live_teamul1 straight_ul">
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class=" straight_player">To Be Decided</label>
                                                    </li>
                                                    <li>
                                                        <img class="vsicon" src={challengefill} alt="" />
                                                    </li>
                                                    <li>
                                                        <span class="lt_img"
                                                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                        <label class="straight_player">To Be Decided</label>
                                                    </li>
                                                </ul>
                                                <div class="straight_inputs">
                                                    <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                        <DatePicker
                                                            selected={this.state.SemiFinaldateObj2}
                                                            onChange={this.setTdateTimeSemiFinal2}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={15}
                                                            timeCaption="time"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={new Date()}
                                                            placeholderText="Click to select a date"
                                                        />
                                                        <img src={schedulematches} alt="" />
                                                    </div>
                                                    <div class="fixture_date fixture_location" onClick={() => this.location(1, 'semi-final')}>
                                                        <Map parentCallback={this.callbackFunction} />
                                                        <img src={location} alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : ""}
                <div class="semi_headingdiv final">
                    <label >Final</label>
                </div>

                <div class="back_white">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="completedmatches">
                            <ul class="scoreboxul tourcompleted_match">

                                <li class="leauge_matchdiv">
                                    <div class="teamdiv">
                                        <ul class="live_teamul live_teamul1 straight_ul">
                                            <li>
                                                <span class="lt_img"
                                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                <label class=" straight_player">To Be Decided</label>
                                            </li>
                                            <li>
                                                <img class="vsicon" src={challengefill} alt="" />
                                            </li>
                                            <li>
                                                <span class="lt_img"
                                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                <label class="straight_player">To Be Decided</label>
                                            </li>
                                        </ul>
                                        <div class="straight_inputs">
                                            <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                <DatePicker
                                                    selected={this.state.FinaldateObj}
                                                    onChange={this.setTdateTimeFinal}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    minDate={new Date()}
                                                    placeholderText="Click to select a date"
                                                />
                                                <img src={schedulematches} alt="" />
                                            </div>
                                            <div class="fixture_date fixture_location" onClick={() => this.location(1, 'final')} >
                                                <Map parentCallback={this.callbackFunction} />
                                                <img src={location} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div>
                    <center>
                        <button type="button" onClick={() => this.organiseTournament()} class="createnext" type="submit">Organise Tournament</button>
                    </center>
                    <div id="organiseTornamentSuccess" style={{ display: 'none', margin: '5px' }} className="alert alert-success " role="alert">
                        <strong>Tournament Organised successfully !</strong>
                    </div>
                    <div id="organiseTornamentError" style={{ display: 'none', margin: '5px' }} className="alert alert-danger " role="alert">
                        <strong>please Fill All information !</strong>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
