
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem("result"));
export const getPageProfile = async function (pageId) {
    // var apiUrl = userApi + 'pages/page-profile';
    var apiUrl = userApi + 'pages/page-feed';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                pageId: pageId,
                contactNo: user.contactNo

            }
        })
    });
    let hob = await response.json();

    return hob;
}

export const pageToggleFollow = async function (pageId, followStatus) {
    var apiUrl = userApi + 'pages/toggle-follow';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                contactNo: user.contactNo,
                pageId: pageId,
                follow: !followStatus
            }
        })
    });
    let followRes = await response.json();

    return followRes;
}

export const pageToggleLike = async function (pageId, likeStatus) {
    console.log(pageId)
    var apiUrl = userApi + 'pages/toggle-likes';
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                contactNo: user.contactNo,
                pageId: pageId,
                like: !likeStatus

            }
        })
    });
    let likeRes = await response.json();

    return likeRes;
}







export function getDateByTimeStamp(timeStamp) {
    var formattedTimestamp = Intl.DateTimeFormat('en-US', {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
    }).format(timeStamp);
    var res = formattedTimestamp.split(",");
    var feadsDate = res[0] + "," + res[1];

    var todayDate = new Date(new Date().setDate(new Date().getDate()));
    todayDate = Intl.DateTimeFormat('en-US', {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
    }).format(todayDate);
    var res1 = todayDate.split(",");
    todayDate = res1[0] + "," + res1[1];

    var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
    yesterdayDate = Intl.DateTimeFormat('en-US', {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
    }).format(yesterdayDate);
    var res2 = yesterdayDate.split(",");
    yesterdayDate = res2[0] + "," + res2[1];

    if (feadsDate === todayDate) {
        feadsDate = "Today" + "," + res[2];
    }
    else if (feadsDate === yesterdayDate) {
        feadsDate = "Yesterday" + "," + res[2];
    }
    else {
        feadsDate = feadsDate + "," + res[2];
    }
    return feadsDate;
}