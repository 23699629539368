import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import broadcastbg from "./img/broadcastbg.png";
import play from "./img/play.png";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
import { Link } from 'react-router-dom';
const ConfigApiurl = ConfigUrl('apiUrl');
const videoUrl = ConfigUrl('videoUrl');
const userApi = ConfigApiurl;
class Broadcasts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            broadcasts: [],
            itemsPerIndex: 5,
        };
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore = () => {
        const that = this;
        fetch(userApi + "broadcasts/get-user-broadcasts", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.props.userbuddy,
                    index: 0,
                    itemsPerIndex: this.state.itemsPerIndex,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    broadcasts: result.data,
                });
            });
    };
    componentDidMount() {
        this.loadMore();
    }
    openBroadcast = (id) => {
        const url = videoUrl + id + ".mp4";
        confirmAlert({
            customUI: () => {
                return (
                    <div className="postdata">
                        <iframe title="broadcast" id="video1" src={url} frameborder="0" allowtransparency="true" allowfullscreen></iframe>
                        <p></p>
                    </div>
                );
            }
        });
    };
    render() {
        return (
            <div className="commanbox" id="profilebuddies">
                <h3 className="commanhead">Broadcasts  {this.props.permission ? <button className="headbutton">Create Broadcasts</button> : <div></div>}</h3>
                <ul className="aboutbroadcast">
                    {this.state.broadcasts.map((x) => {
                        if (x.icon === null) {
                            return (
                                <li onClick={() => this.openBroadcast(x.broadcastId)}>
                                    <Link style={{ backgroundImage: "url(" + broadcastbg + ")" }}>
                                        <img src={play} alt="" />
                                    </Link>
                                </li>
                            );
                        }
                        else {
                            return (
                                <li onClick={() => this.openBroadcast(x.broadcastId)}>
                                    <Link style={{ backgroundImage: "url(" + broadcastbg + ")" }}>
                                        <img src={play} alt="" />
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        );
    }
}
export default Broadcasts;  