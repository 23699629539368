import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '..//..//ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import noBroadcast from '..//..//img/noBroadcast.jpg';
import play from '..//..//img/play.png';
import matchnotstarted from '..//..//img/play.png';
import NumericInput from 'react-numeric-input';
var numberOfAttempt;
var scoreCardD101s = [];
var d101ScoreCard = {}, score, points;
var maxWeights = [];
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class DemoChallenge_d101Vue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD101State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
        this.endGame = this.endGame.bind(this);
        this.done = this.done.bind(this);
        this.disqualify = this.disqualify.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.props.challenge.accepted.map((x) => {
            scoreCardD101s.push({
                userId: x,
                index: 0,
                winner: false,
                maxWeightLifted: 0.0,
                points: null,
            });
        });
        this.state.scoreCardD101State = scoreCardD101s;
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = "#2cae4a";
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            setModalIsOpen: false,
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        });
    }
    endGame() {
        var showModalForDataFill = false;
        this.state.scoreCardD101State.map((x, index) => {
            if (x.points.length !== numberOfAttempt) {
                showModalForDataFill = true;
            }
        });
        if (showModalForDataFill === true) {
            this.setState({ fillScoreModalOpen: true });
        } else {
            maxWeights = [];
            const max = this.state.scoreCardD101State.reduce((prev, current) =>
                prev.maxWeightLifted > current.maxWeightLifted ? prev : current
            );
            this.state.scoreCardD101State.map((x, index) => {
                if (x.maxWeightLifted === max.maxWeightLifted) {
                    maxWeights.push(x);
                }
            });
            this.setState({ winnerModalOpen: true });
        }
    }
    changeData(event) {
        let checkedArray = this.state.optionsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            numberOfAttempt = selectedValue;
        }
    }
    disqualify(event) {
        this.setState({ modalIsOpen: false, setModalIsOpen: false });

        var weight = 0;
        this.state.scoreCardD101State.map((x, index) => {
            if (index === this.state.row) {
                if (x.points !== null) {
                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = {
                            attempt: this.state.setIndex + 1,
                            weightLifted: weight,
                            disqualified: true,
                        };
                    } else {
                        x.points[this.state.setIndex] = {
                            attempt: this.state.setIndex + 1,
                            weightLifted: weight,
                            disqualified: true,
                        };
                    }
                } else {
                    x.points[this.state.setIndex] = {
                        attempt: this.state.setIndex + 1,
                        weightLifted: weight,
                        disqualified: true,
                    };
                }
                this.numberOfAttempts();
            }
        });
    }
    done(event) {
        this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var kilo = document.getElementsByName("kilo")[0].value;
        var gram = document.getElementsByName("gram")[0].value;
        var weight = kilo + "." + gram;
        this.state.scoreCardD101State.map((x, index) => {
            if (index === this.state.row) {
                var max;
                if (x.points !== null) {
                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = {
                            attempt: this.state.setIndex + 1,
                            weightLifted: weight,
                            disqualified: false,
                        };
                    } else {
                        x.points[this.state.setIndex] = {
                            attempt: this.state.setIndex + 1,
                            weightLifted: weight,
                            disqualified: false,
                        };
                    }
                } else {
                    x.points = [
                        {
                            attempt: this.state.setIndex + 1,
                            weightLifted: weight,
                            disqualified: false,
                        },
                    ];
                    x.maxWeightLifted = weight;
                }
                this.numberOfAttempts();
                max = x.points.reduce((prev, current) =>
                    prev.weightLifted > current.weightLifted ? prev : current
                );
                if (max.weightLifted < weight) {
                    x.maxWeightLifted = weight;
                } else {
                    x.maxWeightLifted = max.weightLifted;
                }
                this.numberOfAttempts();
            }
        });
    }
    openFunc = (index, ind) => {
        this.setState({ row: index, setIndex: ind });
        this.state.scoreCardD101State.map((x, index) => {
            if (x.points === null) {
                if (ind === 0) {
                    this.setState({ setModalIsOpen: true });
                }
            }
            if (x.points !== null) {
                if (x.points.length + 1 > ind) {
                    this.setState({ setModalIsOpen: true });
                }
            }
        });
    };
    componentDidMount() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    data: {
                        challengeId: that.props.challenge.challengeId,
                        matchId: that.props.challenge.challengeId,
                    },
                }),
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        that.setState({ modalIsOpen: false });
                    } else {
                        that.setState({
                            scoreCardD101State:
                                result.data.score.d101ScoreCard.scoreCardD101s,
                        });
                        numberOfAttempt = result.data.score.d101ScoreCard.attempts;
                        that.numberOfAttempts();
                    }
                });
        }
        if (this.props.challenge.challengeAction === "ended") {
            this.setState({ matchSatus: true });
        }
    }
    numberOfAttempts = () => {
        numberOfAttempt = parseInt(numberOfAttempt);
        const that = this;
        d101ScoreCard = {
            attempts: numberOfAttempt,
            scoreCardD101s: this.state.scoreCardD101State,
        };
        score = {
            challengeId: that.props.challenge.challengeId,
            matchId: that.props.challenge.challengeId,
            d101ScoreCard: d101ScoreCard,
        };

        this.setState({ modalIsOpen: false });
    };
    render() {
        const backLinks =
            "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
        if (this.state.matchSatus) {
            var sets = [];
            for (var p = 0; p < parseInt(numberOfAttempt); p++) {
                sets.push(p);
            }
            var setNumber = 1;
            return (
                <React.Fragment>
                    <div
                        className="broadcast_video "
                        style={{
                            backgroundImage: "url(" + noBroadcast + ")",
                        }}
                    >
                        <a className="tour_broadcast_update">
                            <img src={play} alt="" />
                        </a>
                    </div>
                    <div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr id="heading">
                                            <th> Players/Attempts </th>

                                            {sets.map((k) => {
                                                return <th> Set{setNumber++} </th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.scoreCardD101State.map((x, index) => {
                                            var userName;
                                            this.props.profileUsers.map((user) => {
                                                if (x.userId === user.contactNo) {
                                                    userName = user.name;
                                                }
                                            });
                                            if (x.points === null) {
                                                return (
                                                    <tr>
                                                        <td>{userName}</td>
                                                        {sets.map((k, inde) => {
                                                            return (
                                                                <td key={k}>
                                                                    <input readOnly placeholder="kg.g" />
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            } else {
                                                var pointL = x.points.length;
                                                var point;
                                                return (
                                                    <tr>
                                                        <td>{userName}</td>
                                                        {sets.map((k, ind) => {
                                                            var pointColor;
                                                            if (x.points[ind] === undefined) {
                                                                point = "";
                                                            } else {
                                                                if (x.points[ind].disqualified === false) {
                                                                    if (
                                                                        Number.isInteger(
                                                                            x.points[ind].weightLifted
                                                                        ) === true
                                                                    ) {
                                                                        point = x.points[ind].weightLifted;
                                                                        point = point + ".00" + " Kg";
                                                                    } else {
                                                                        point = x.points[ind].weightLifted + " Kg";
                                                                    }
                                                                } else {
                                                                    pointColor = { color: "red" };
                                                                    point = "Disqualify";
                                                                }
                                                            }
                                                            return (
                                                                <td key={k}>
                                                                    <input
                                                                        readOnly
                                                                        style={pointColor}
                                                                        placeholder="kg.g"
                                                                        value={point}
                                                                    />
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.winnerModalOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div ref={(subtitle) => (this.subtitle = subtitle)}>
                                {" "}
                                <h3> player1 is winner </h3>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={this.state.fillScoreModalOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div ref={(subtitle) => (this.subtitle = subtitle)}>
                                {" "}
                                <h3> Please Fill All Score Field</h3>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={this.state.setModalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            {" "}
                            <div ref={(subtitle) => (this.subtitle = subtitle)}>
                                <div ref={(subtitle) => (this.subtitle = subtitle)}>
                                    {" "}
                                    <h3> Enter of Weight value</h3>
                                </div>
                                <NumericInput
                                    mobile
                                    min={0}
                                    max={200}
                                    name="kilo"
                                    disabled={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoFocus={false}
                                    form="some-form"
                                    placeholder="Enter Weight In Kg"
                                    required
                                    size={25}
                                    value={50}
                                    spellcheck="false"
                                    tabindex="2"
                                    style={{
                                        input: {
                                            padding: "10px",
                                        },
                                    }}
                                />{" "}
                                <br></br>
                                <NumericInput
                                    mobile
                                    min={0}
                                    max={999}
                                    name="gram"
                                    disabled={false}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    size={25}
                                    value={0}
                                    autoFocus={false}
                                    form="some-form"
                                    placeholder="Enter Weight In Gram"
                                    required
                                    spellcheck="false"
                                    tabindex="2"
                                    style={{
                                        input: {
                                            padding: "10px",
                                        },
                                    }}
                                />
                            </div>
                            <br></br>
                            <div className="button_div">
                                <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={this.disqualify}
                                    style={{ margin: "2px" }}
                                >
                                    Disqualify
                </button>
                                <button
                                    className="btn btn-inverse"
                                    onClick={this.closeModal}
                                    style={{ margin: "2px" }}
                                >
                                    Cancel
                </button>
                                <button
                                    className="cmnbutton"
                                    type="button"
                                    onClick={this.done}
                                    style={{ margin: "2px" }}
                                >
                                    Done
                </button>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div ref={(subtitle) => (this.subtitle = subtitle)}>
                                {" "}
                                <h3> Number of Attempts</h3>
                            </div>

                            <ul className="loginul_form editprofile ">
                                <li>
                                    <div
                                        className="styled-input-single"
                                        style={{ position: "relative" }}
                                    >
                                        <input
                                            type="radio"
                                            name="numberOfAttempt"
                                            id="radio-example-1"
                                            onClick={this.changeData.bind(this)}
                                            value="3"
                                        />
                                        <label htmlFor="radio-example-1"></label>
                                    </div>

                                    <label className="ss_sportstile">3 Attempt</label>
                                </li>
                                <li>
                                    <div
                                        className="styled-input-single"
                                        style={{ position: "relative" }}
                                    >
                                        <input
                                            type="radio"
                                            name="numberOfAttempt"
                                            id="radio-example-2"
                                            onClick={this.changeData.bind(this)}
                                            value="5"
                                        />
                                        <label htmlFor="radio-example-2"></label>
                                    </div>

                                    <label className="ss_sportstile">5 Attempt</label>
                                </li>
                                <li>
                                    <div
                                        className="styled-input-single"
                                        style={{ position: "relative" }}
                                    >
                                        <input
                                            type="radio"
                                            name="numberOfAttempt"
                                            id="radio-example-3"
                                            onClick={this.changeData.bind(this)}
                                            value="7"
                                        />
                                        <label htmlFor="radio-example-3"></label>
                                    </div>

                                    <label className="ss_sportstile">7 Attempt</label>
                                </li>
                                <li>
                                    <div
                                        className="styled-input-single"
                                        style={{ position: "relative" }}
                                    >
                                        <input
                                            type="radio"
                                            name="numberOfAttempt"
                                            id="radio-example-4"
                                            onClick={this.changeData.bind(this)}
                                            value="9"
                                        />
                                        <label htmlFor="radio-example-4"></label>
                                    </div>

                                    <label className="ss_sportstile">9 Attempt</label>
                                </li>
                            </ul>
                            <div className="button_div">
                                <button
                                    className="cmnbutton"
                                    type="button"
                                    onClick={this.numberOfAttempts}
                                >
                                    Done
                </button>
                                <button className="btn btn-inverse" onClick={this.closeModal}>
                                    Close
                </button>
                            </div>
                        </Modal>
                        <div class="table_actions winner_actions">
                            <a class="cmnbutton backbtn fl" href={backLinks}>
                                Back
              </a>
                        </div>
                    </div>
                    {/* <button className="btn btn-danger" onClick={this.endGame}>End Event</button> */}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a>
                                    <img
                                        style={{ height: "auto", width: "250px" }}
                                        className="matchnotstartedImg"
                                        src={matchnotstarted}
                                    />
                                </a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">
                                    Back
                </a>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
    }
}
