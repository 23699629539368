import React from 'react';
import './MenuStyle.css';
import { Link } from "react-router-dom";
const Menu = () => {
    return (<div>
        {/* <ul className="activityul">
            <li><Link to={"/live-score"}>Live</Link></li>
            <li><Link to={"/completed-cricket"}>Completed</Link></li>
            <li><Link to={"/upcoming-cricket"}>Upcoming</Link></li>       
        </ul> */}


        <div className="commanbox ">
            <label className="commanhead"><Link to={"/live-score"}>Live</Link></label>
            <label className="commanhead">   <Link to={"/completed-cricket"}>Completed</Link></label>
            <label className="commanhead">   <Link to={"/upcoming-cricket"}>Upcoming</Link></label>
        </div>
    </div>
    )
}
export default Menu;
