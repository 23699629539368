import React from 'react';
import Header from './Header'
import SectionLeft from './SectionLeft'
import DemoTeamprofile from './DemoTeamprofile'
import SectionRight from './SectionRight'
import BuddyRequest from './BuddyRequest';
import ChatonRight from './ChatonRight';

function DemoTeamProfilePage() {
    return (
        <div>
            <Header />
            <div className="container containerbottom non-session-d">
                <SectionLeft />

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <section className="d_midsec">
                        <DemoTeamprofile />
                    </section>
                </div>

                <SectionRight />
                {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                    <section className="d_rightsec">
                        <BuddyRequest />
                       
                        <ChatonRight />
                    </section>
                </div> */}
            </div>
        </div>
    );
}

export default DemoTeamProfilePage;
