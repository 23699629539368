import React, { Component } from 'react';
import cricketIcon from './img/cricket-live-score.png';
import footballIcon from './img/football-live-score.jpg';

class CricketFootballAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <div className="custom-ui" style={{ display: 'inline-block' }}>
                <div className="blink_me text-center" style={{ padding: '3px 12px', border: '1px solid #2cae4a', color: '#2cae4a', fontSize: '16px', margin: '4px', backgroundColor: 'rgba(203, 236, 210, 0.35)' }}>Live Score - Click To View</div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ cursor: 'pointer' }}>
                    <a href="./live-score"><img alt="" src={cricketIcon} className="img-responsive cricket" style={{ width: '200px', height: '200px' }} /></a>
                    <figcaption style={{ textAlign: 'center', color: 'rgb(131, 20, 0)', fontWeight: '600', position: 'relative', top: '4px' }}>Cricket Live Score</figcaption>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{ cursor: 'pointer' }}>
                    <a href="./live-football"><img alt="" src={footballIcon} className="img-responsive football" style={{ width: '168px', height: '168px', borderRadius: '100%', position: 'relative', top: '24px' }} /></a>
                    <figcaption style={{ textAlign: 'center', color: 'rgb(42, 120, 3)', fontWeight: '600', position: 'relative', top: '36px' }}>Football Live Score</figcaption>
                </div>
            </div>
        );
    }
}

export default CricketFootballAlert;
