import React from 'react';
import './MenuStyle.css';    
import { Link } from "react-router-dom";
const Menu = () => {
    return(<div>
        <ul className="activityul">
            <li><Link to={"/live-football"}>Live</Link></li>  
            <li><Link to={"/completed-football"}>Completed</Link></li>
            <li><Link to={"/upcoming-football"}>Upcoming</Link></li>
        </ul>
        </div>
    )
}
export default Menu;
