import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;


export default class D107Vue_Cricket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            rounds: [],
            teams: [],
            Byruns: [],
            firstInningScoreCard: [],
            batsmen: [],
            bollers: [],
            batsmen1: [],
            bollers1: [],

        };

    }
    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);

        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    users: result.data.users,
                    teams: result.data.teams
                })

                if (result.data.score !== null) {

                    if (result.data.score.d107CricketScoreCard !== null) {

                        if (result.data.score.d107CricketScoreCard.byCricketScoreCard !== undefined) {

                            if (result.data.score.d107CricketScoreCard.byCricketScoreCard !== null) {
                                that.setState({ Byruns: result.data.score.d107CricketScoreCard.firstInningScoreCard.totalRun, });
                                that.setState({ Bywicket: result.data.score.d107CricketScoreCard.firstInningScoreCard.wicketFall, });
                                that.setState({ byover: result.data.score.d107CricketScoreCard.overs })
                                that.setState({ batsmen: result.data.score.d107CricketScoreCard.firstInningScoreCard.batsmen })
                                that.setState({ bollers: result.data.score.d107CricketScoreCard.firstInningScoreCard.bowlers })
                            }
                            else {
                                that.setState({ byTotalHits: result.data.score.d107CricketScoreCard.byCricketScoreCard.totalHits, byTotalRuns: 0, byWinner: result.data.score.d107CricketScoreCard.byCricketScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }

                        if (result.data.score.d107CricketScoreCard.toCricketScoreCard !== undefined) {

                            if (result.data.score.d107CricketScoreCard.toCricketScoreCard !== null) {
                                that.setState({ Byruns1: result.data.score.d107CricketScoreCard.secondInningScoreCard.totalRun, });
                                that.setState({ Bywicket1: result.data.score.d107CricketScoreCard.secondInningScoreCard.wicketFall, });
                                that.setState({ byover1: result.data.score.d107CricketScoreCard.overs })
                                that.setState({ batsmen1: result.data.score.d107CricketScoreCard.secondInningScoreCard.batsmen })
                                that.setState({ bollers1: result.data.score.d107CricketScoreCard.secondInningScoreCard.bowlers })




                            }
                            else {
                                that.setState({ toTotalHits: result.data.score.d107CricketScoreCard.toCricketScoreCard.totalHits, toTotalRuns: 0, toWinner: result.data.score.d107CricketScoreCard.toCricketScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }

                        //storing set numbers and rounds
                        that.setState({ rounds: result.data.score.d107CricketScoreCard.rounds, numberofSets: result.data.score.d107CricketScoreCard.noOfSets })

                        //storing byteam and toteam
                        that.props.profileTeams.map(team => {
                            if (team.teamId === result.data.score.d107CricketScoreCard.byCricketScoreCard.teamId) {
                                that.setState({ byTeamName: team.teamName });
                            }
                            if (team.teamId === result.data.score.d107CricketScoreCard.toCricketScoreCard.teamId) {
                                that.setState({ toTeamName: team.teamName });
                            }
                        })

                    }

                }

            });
    }
    render() {
        console.log(this.state.batsmen1);

        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;

        let batsmen = [];
        let bollers = [];

        if (this.state.matchStatus) {
            return (
                <React.Fragment>

                    <div className="well welltab2-d">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                        <ul className="team_match team_match_acc">
                                            <li>
                                                <div className="scoreflag_div">
                                                    <span className="score_flagimg" style={{ backgroundImage: 'url(./img/oman.png)' }} />
                                                    <span className="score_flagtext">{this.state.byTeamName}</span>
                                                </div>
                                                <label className="teamscore">{this.state.Byruns}/{this.state.Bywicket}({this.state.byover})</label>
                                            </li>
                                        </ul>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse in">
                                <div className="panelnew">
                                    <div className="table-responsive scoretable">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Batsman</th>

                                                    <th>R</th>
                                                    <th>B</th>
                                                    <th>4s</th>
                                                    <th>6s</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {this.state.batsmen.map((item, key) => {
                                                        return <div>
                                                            <td>{item.batsmanName}</td>
                                                            <td>{item.runs}</td>
                                                            <td>{item.balls}</td>
                                                            <td>{item.boundries}</td>
                                                            <td>{item.sixes}</td>



                                                        </div>
                                                    })}
                                                </tr>


                                            </tbody>
                                        </table>
                                        <div className="matchtotle">
                                            <ul>

                                                <li><b>Total</b> <label>{this.state.Byruns}/{this.state.Bywicket}({this.state.byover})</label></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="table-responsive scoretable">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Bowler</th>
                                                    <th>O</th>
                                                    <th>M</th>
                                                    <th>R</th>
                                                    <th>W</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <tr>
                                                        {this.state.bollers.map((item, key) => {
                                                            return <div>
                                                                <td>{item.bowlerName}</td>
                                                                <td>{item.completedOver}</td>
                                                                <td>{item.balls}</td>
                                                                <td>{item.runs}</td>
                                                                <td>{item.siwicketsxes}</td>



                                                            </div>
                                                        })}
                                                    </tr>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h4 className="panel-title">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                        <ul className="team_match team_match_acc">
                                            <li>
                                                <div className="scoreflag_div">
                                                    <span className="score_flagimg" style={{ backgroundImage: 'url(./img/oman.png)' }} />
                                                    <span className="score_flagtext">{this.state.toTeamName}</span>
                                                </div>
                                                <label className="teamscore">{this.state.toruns}/{this.state.towicket}({this.state.toover})</label>
                                            </li>
                                        </ul>
                                    </a>
                                </h4>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse in">
                                <div className="panelnew">
                                    <div className="table-responsive scoretable">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Batsman</th>
                                                    <th />
                                                    <th>R</th>
                                                    <th>B</th>
                                                    <th>4s</th>
                                                    <th>6s</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {this.state.batsmen1.map((item, key) => {
                                                        return <div>
                                                            <td>{item.batsmanName}</td>
                                                            <td>{item.runs}</td>
                                                            <td>{item.balls}</td>
                                                            <td>{item.boundries}</td>
                                                            <td>{item.sixes}</td>



                                                        </div>
                                                    })}
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="matchtotle">
                                            <ul>

                                                <li><b>Total</b> <label>{this.state.Byruns1}/{this.state.Bywicket1}({this.state.byover1})</label></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="table-responsive scoretable">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Bowler</th>
                                                    <th>O</th>
                                                    <th>M</th>
                                                    <th>R</th>
                                                    <th>W</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {this.state.bollers1.map((item, key) => {
                                                        return <div>
                                                            <td>{item.bowlerName}</td>
                                                            <td>{item.completedOver}</td>
                                                            <td>{item.balls}</td>
                                                            <td>{item.runs}</td>
                                                            <td>{item.siwicketsxes}</td>

                                                        </div>
                                                    })}
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }
}
