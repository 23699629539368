import React, { Component } from 'react';
import DownloadAppPlatform from './DownloadAppPlatform';
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import cancelMobileIcon from './img/cancelmobileicon.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import playdiator from './img/playdiator.png';
import PL from './img/PL.png';
import chatgreen from './img/chatgreen.png';
import camera from './img/camera.png';
import { Link } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import { confirmAlert } from 'react-confirm-alert';
import ConfigUrl from './ConfigUrl';
import $ from "jquery";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
var name;
class SectionLeft extends Component {
    constructor(props) {
        super(props);
        if (JSON.parse(localStorage.getItem('result'))) {
            const loggedUser = JSON.parse(localStorage.getItem('result'));
            this.state = {
                name: '',

            };

        } else {
            this.state = {

                nonSession: false,
            };
        }




        //var nonSessionUser = JSON.parse(localStorage.getItem('nonSessionUserData'));
        //  console.log(nonSessionUser);
    }
    componentDidMount() {
        let that = this;
        if (JSON.parse(localStorage.getItem('nonSessionUserData'))) {
            var nonSessionUser = JSON.parse(localStorage.getItem('nonSessionUserData'));
            name = nonSessionUser.name;
            that.setState({ nonSession: true });
        }

    }
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };

    closemenu = () => {
        $(".d_leftsec").toggleClass("d_leftsec_width");
        this.props.parentCallbackfromChild2("closeSidebar");
    }

    render() {
        this.userItems = <div className="commanbox profileleft playdiatorprof">
            <Link to={"#"} className="closemenu hidden-md hidden-sm hidden-lg" onClick={this.closemenu}><img
                src={cancelMobileIcon} alt="" /></Link>
            <div className="profilediv playdiatorprof">
                <div className="profileig_inner"> <span className="profilespan"
                    style={{
                        backgroundImage: "url(" + playdiator + ")"
                    }}></span> <Link to={"#"}><img src={camera} alt="" /></Link> <span
                        className="prof_span"></span> </div>
            </div>
            {/* <label className="pro_username">Playdiator</label> */}
            <label className="pro_username">{name ? name : 'Guest'}</label><br></br>
            <label className="commanhead"> {name ? '' : <Link to={"/login"}>  Login </Link>}</label>
            <ul className="profileul aboutul">
                <li> <Link to={"#"}> <span>20</span> Follower </Link> </li>
                <li> <Link to={"#"}> <span><img src={chatgreen} alt="" /></span> Send Message </Link> </li>
            </ul>
        </div>


        return (
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                <section className="d_leftsec">
                    {this.userItems}

                    <div className="commanbox ">
                        <h3 className="commanhead hidden-xs">Activities</h3>
                        {this.state.nonSession ? <ul className="activityul hidden-xs">
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('teamPage')}><img alt="" src={teamFillIcon} />Team</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('challengePage')}><img alt="" src={challengeFillIcon} />Challenge</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('tournamentPage')}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                            <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('scheduleMatches')}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                        </ul> : <ul className="activityul hidden-xs">
                            <li><Link to={"/login"} ><img alt="" src={teamFillIcon} />Team</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={challengeFillIcon} />Challenge</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                        </ul>}
                        <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
                            <li><Link to={"/about-us"} ><img alt="" src={aboutIcon} />About Playdiator</Link></li>
                            {/* <li><Link to={"/download-app-page"} ><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                            <li><Link to={"/download-app-page"} ><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li> */}
                            <li><Link to={"/login"} ><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
                            <li><Link to={"/login"}  ><img alt="" src={myTeamIcon} />My Team</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={tournamentIcon} />My Tournament</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={scheduleIcon} />My Schedule</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
                            <li><Link to={"/login"} ><img alt="" src={changePasswordIcon} />Change Password</Link></li>

                        </ul>
                    </div>
                </section>
            </div>
        );
    }
}

export default SectionLeft;
