import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import noBroadcast from '../img/noBroadcast.jpg';
import challengebg from "../img/challenegbg.png";
import openchallenge from "../img/openchallenge.png";
import challengefill from "../img/challengefill.png";
import userMaleIcon from "../img/male.png";
import play from '../img/play.png';
import teamIcon from '../img/team.png';
import maleIcon from "../img/male.png";
import femaleIcon from "../img/male.png";
import location from "../img/location.png";
import challengevs from '../img/challengevs.png';
import matchbg from '../img/matchbg.png';
import winnerlogo from '../img/winnerlogo.png';
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const userApi = ConfigApiUrl;
const that = this;
var winner;

export default class Feed_d101 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchStatus: true,
            userData1: [],
            teamData: [],
            acceptedChallenge: [],
            challengeData: {},
            scoreCardD101s: [],
        };
        this.state.user = JSON.parse(localStorage.getItem('result'));

    }
    componentDidMount() {


        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {



                    "challengeId": that.props.challengeData.challengeId,
                    "matchId": that.props.challengeData.challengeId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.score !== null) {
                    that.setState({ d101ScoreCard: result.data.score.d101ScoreCard, userData1: result.data.users, teamData: result.data.teams, scoreCardD101s: result.data.score.d101ScoreCard.scoreCardD101s })


                }
            });
        that.setState({ challengeData: this.props.challengeData, acceptedChallenge: this.props.challengeData.accepted, challengeWinnerId: this.props.challengeData.challengeWinnerId })
    }
    openChallengeProfile = (cid) => {
        window.location.href = "/challengeprofile/index.html?id=" + cid;
    }

    render() {
        var user, user1, pic, pic1, winners = false, winners1 = false, maxby, maxto;

        if (this.props.challengeData.challengeType === 'team') {
            this.props.teamData.map(team => {
                if (this.props.challengeData.accepted) {
                    if (team.teamId === this.props.challengeData.accepted[0]) {
                        user = team.teamName;
                        pic = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                    if (team.teamId === this.props.challengeData.accepted[1]) {
                        user1 = team.teamName;
                        pic1 = team.teamIcon ? team.teamIcon : teamIcon;
                    }
                }
            })
        }
        else {
            this.props.userData.map(use => {
                if (this.props.challengeData.accepted) {
                    if (use.contactNo === this.props.challengeData.accepted[0]) {
                        user = use.name;

                        pic = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                    if (use.contactNo === this.props.challengeData.accepted[1]) {
                        user1 = use.name;
                        pic1 = use.profilePic ? use.profilePic : userMaleIcon;
                    }
                }

            })
        }




        var OpenChallengeAccess = false;
        if ((this.state.challengeData.challengeAction === 'requested') && (this.state.challengeData.openChallenge === true)) {
            OpenChallengeAccess = true;
        }

        var userName
        var acceptedUser = false;
        var oppositions = this.state.challengeData.oppositions ? this.state.challengeData.oppositions : [];
        oppositions.map(c => {
            if (this.state.user.contactNo === c) {
                acceptedUser = true;
            }
        })

        if (this.state.challengeData.openChallenge === true) {


            return (<React.Fragment>
                <div class="openchallenge-d">
                    <h5>Open Challenge</h5>
                    <img class="open_challengeimg" src={openchallenge} />
                    <p> {userName} has created an open challenge</p>
                    <span>Sports-{this.state.challengeData.sportsName}</span>

                    {this.state.showApply ? <button onClick={() => this.applyChallenge(this.state.challengeData.challengeId)} id="openchallenge-btn" type="btn" class="openchallenge-btn"> Accept Now</button> : acceptedUser ? <button id="openchallenge-btn" type="btn" class="openchallenge-btn"> Applied </button> : ''}

                </div>


            </React.Fragment>)
        }


        return (
            <React.Fragment>

                <div class="teampost_inner" style={{
                    backgroundImage: "url(" + challengebg + ")"
                }}>
                    <label class="teamhead">Challenge - {this.props.challengeData.sportsName}</label>
                    <ul class="postteam">
                        {this.state.userData1.map(u => {

                            if (this.state.scoreCardD101s[0].userId === u.contactNo) {
                                user = u.name;
                                maxby = this.state.scoreCardD101s[0].maxWeightLifted;
                                console.log(maxby)
                                if (this.state.scoreCardD101s[0].userId === this.state.challengeWinnerId) {
                                    winners = true;
                                }
                                if (u.profilePic !== null) {
                                    pic = u.profilePic;
                                }
                                else {
                                    if (u.profilePic === null) {
                                        pic = femaleIcon;
                                    }
                                    else {
                                        pic = maleIcon;
                                    }
                                }
                            }

                            if (this.state.scoreCardD101s[1].userId === u.contactNo) {
                                user1 = u.name;
                                maxto = this.state.scoreCardD101s[1].maxWeightLifted;
                                if (this.state.scoreCardD101s[1].userId === this.state.challengeWinnerId) {
                                    winners1 = true;
                                }
                                if (u.profilePic !== null) {
                                    pic1 = u.profilePic;
                                }
                                else {
                                    if (u.profilePic === null) {
                                        pic1 = femaleIcon;
                                    }
                                    else {
                                        pic1 = maleIcon;
                                    }
                                }
                            }
                        })}
                        <li>
                            {winners ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic + ")"
                            }}></span>
                            <label class="teamname">{user}</label>

                            {winners ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>
                        <li>
                            <img src={challengevs} class="vsimg" alt="" />
                            <span class="matchscore_text">Match Score</span><label class="matchscore">{maxby}-{maxto}</label>
                        </li>
                        <li>
                            {winners1 ? <React.Fragment>
                                <img src={winnerlogo} class="imgwinner" alt="" />
                            </React.Fragment> : ''}

                            <span class="teamimg" style={{
                                backgroundImage: "url(" + pic1 + ")"
                            }}></span>
                            <label class="teamname">{user1}</label>

                            {winners1 ? <React.Fragment>
                                <label class="teamname_winner">WINNER</label>
                            </React.Fragment> : ''}

                        </li>

                    </ul>

                    <label class="matchdate"><span>Match Date : </span>{this.state.challengeData.challengeDate} <span>Time : {this.state.challengeData.challengeTime}</span></label>
                    <p class="teamlocation"><img src={location} alt="" />{this.state.challengeData.location}</p>

                    <div class="actiondiv">
                        <button onClick={() => this.openChallengeProfile(this.state.challengeData.challengeId)}>View Score</button>
                    </div>
                </div>



            </React.Fragment>
        );
    }
}


