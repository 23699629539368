import React, { Component } from "react";
import userMaleIcon from "./img/male.png";
import userFemaleIcon from "./img/female.png";
import { trackPromise } from "react-promise-tracker";
import sad from './img/sad.png';
import searchnew from './img/searchnew.png';
import NoTeam from "./img/mytournament.svg";
import { Link } from "react-router-dom";
import ConfigUrl from './ConfigUrl';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}

class Following extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buddies: [],
            followingCount: true,
            term: ''
        };
        this.loadMore = this.loadMore.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
    }

    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }

    loadMore = () => {
        const that = this;
        fetch(userApi + "users/get-followings", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.props.userbuddy,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.length === 0) {
                    console.log(result.data.length);
                    that.setState({ followerCount: false });
                }
                else if (result.data === 'No buddies found') {
                    that.setState({ followerCount: false });
                }
                else {
                    that.setState({
                        buddies: result.data,
                    });
                }
            });
    };

    componentDidMount() {
        this.loadMore();
    }

    render() {
        const { term, buddies } = this.state;
        return (
            <div className="commanbox" id="profilebuddies">
                <h3 className="commanhead">Followings  {this.props.permission ? <button className="headbutton">Invite Buddies</button> : <div></div>} </h3>
                <div className="ct_searchbuddies">
                    <form>
                        <input onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                    </form>
                </div>
                {this.state.followingCount ? <ul className="buddieslist_ul">
                    {buddies.filter(searchingFor(term)).length ? buddies.filter(searchingFor(term)).map(x => {
                        return (() => {
                            if (x.profilePic === null) {
                                switch (x.gender) {
                                    case "Male":
                                        return (
                                            <li key={x.contactNo}>
                                                <a href={"/buddies?id=" + x.contactNo}>
                                                    <span style={{
                                                        backgroundImage: "url(" + userMaleIcon + ")"
                                                    }}></span>
                                                    <label>{x.name}</label>
                                                </a>
                                            </li>
                                        );
                                    case "Female":
                                        return (
                                            <li key={x.contactNo}>
                                                <a href={"/buddies?id=" + x.contactNo}>

                                                    <span style={{
                                                        backgroundImage: "url(" + userFemaleIcon + ")"
                                                    }}></span>
                                                    <label>{x.name}</label>
                                                </a>
                                            </li>
                                        );
                                    default: return '';
                                }
                            }
                            else {
                                return (
                                    <li key={x.contactNo}>
                                        <a href={"/buddies?id=" + x.contactNo}>

                                            <span style={{
                                                backgroundImage: "url(" + x.profilePic + ")"
                                            }}></span>
                                            <label>{x.name}</label>

                                        </a>
                                    </li>
                                );
                            }
                        })();
                    }) : <div className="broadcast_video">
                            <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                            <h3>No buddy found with this name.</h3>
                        </div>}


                </ul> : <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                        <label> Sorry you don't have any followings</label><br />
                    </div>
                }
            </div>
        );
    }
}
export default Following;   
