import React, { Component } from 'react'
import ConfigUrl from '..//..//ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import broadcastbg from '..//..//img/broadcastbg.png';
import play from '..//..//img/play.png';
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';

const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const challengeAction = "ended";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
export default class DemoChallenge_d102 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };

        this.closeModal = this.closeModal.bind(this);

        this.playerName = this.props.challenge.accepted.map(accepted => {
            this.props.profileUsers.map(user => {
                if (user.contactNo === accepted) {
                    return <option value={user.contactNo}>{user.name}</option>
                }
            })
        })
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    winnerDone = () => {
        if (this.state.winner == "notselected") {
            this.setState({ modalIsOpen: false });
        }
        else if (this.state.winner === undefined) {
            this.setState({ modalIsOpen: false });
        }
        else {
            this.setState({ modalIsOpen: true });
        }

    }
    matchDone() {


        let that = this;
        var D102ScoreCard = {
            winnerId: this.state.winner,
            ids: this.props.challenge.accepted,
        }
        var score = {
            challengeId: that.props.challenge.challengeId,
            matchId: that.props.challenge.challengeId,
            d102ScoreCard: D102ScoreCard,
        }
        DemoUpdateChallengeScore(score);
        var winner;
        winner = this.state.winner;
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winner;

        const apiUrl = rshApi + "/demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                window.location = "/dchallengeprofile/index.html?id=" + that.props.challenge.challengeId;
            });
    }
    componentDidMount() {
    }
    render() {
        const backLinks = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
        return (
            <React.Fragment>

                {this.state.modalIsOpen ? <div id="myModal" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">End Event</h4>
                            </div>
                            <div class="modal-body">
                                <label class="alertmsg">
                                    <i class="fa fa-exclamation-circle"></i>
                                    End event declear the winner and you will no longer be available to edit
                         </label>

                            </div>
                            <div class="modal-footer table_actions">
                                <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.matchDone()} >Confirm</button>
                            </div>
                        </div>
                    </div>
                </div> :

                    <div id="myModal" class="modal fade reslutmodel" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">End Event</h4>
                                </div>
                                <div class="modal-body">
                                    <label class="alertmsg">
                                        <i class="fa fa-exclamation-circle"></i>
                                        Please Select a player First
                         </label>

                                </div>
                                {/* <div class="modal-footer table_actions">
                                    <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                    <button class="cmnbutton btnsubmitright" type="button" >Confirm</button>
                                </div> */}
                            </div>
                        </div>
                    </div>}

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video " style={{ backgroundImage: "url(" + news + ")" }} > */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                        <ul className="winnerdropdown_ul">
                            <li>
                                <label>Winner</label>
                                <select onChange={event => this.setState({ winner: event.target.value })}>
                                    <option value={"notselected"}  >Select Winner</option>
                                    {this.props.challenge.accepted.map(accepted => {
                                        var name, contactNo;
                                        this.props.profileUsers.map(user => {
                                            if (user.contactNo === accepted) {
                                                name = user.name;
                                                contactNo = user.contactNo;
                                            }
                                        })
                                        return <option value={contactNo}>{name}</option>
                                    })}
                                </select>
                            </li>
                        </ul>

                        <div className="table_actions">
                            <a href={backLinks} className="cmnbutton">Back</a>
                            <button className="cmnbutton" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.winnerDone()}>End Games</button>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}
