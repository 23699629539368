import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import '../css/bootstrap.min.css';
import '../css/animate.min.css';
import $ from 'jquery';
import '../css/font-awesome.min.css';
import '../css/style-landing.css';
import '../css/responsive-landing.css';
import logo from '../img/landing/la-logo.png';
import aboutimg2 from '../img/landing/aboutimg2.svg';
import aboutimg3 from '../img/landing/aboutimg3.svg';
import about01 from '../img/landing/about-01.svg';
import ctaonebg from '../img/landing/cta-one-bg.png';
export default class Aboutus extends Component {
    componentDidMount() {
        if ($('.main-navigation .navigation-box').length) {
            var subMenu = $('.main-navigation .sub-menu');
            subMenu.parent('li').children('a').append(function () {
                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
            });
            var mainNavToggler = $('.header-navigation .menu-toggler');
            var subNavToggler = $('.main-navigation .sub-nav-toggler');
            mainNavToggler.on('click', function () {
                var Self = $(this);
                var menu = Self.data('target');
                $(menu).slideToggle();
                $(menu).toggleClass('showen');
                return false;
            });
            subNavToggler.on('click', function () {
                var Self = $(this);
                Self.parent().parent().children('.sub-menu').slideToggle();
                return false;
            });
        }
    }
    render() {
        return (
            <div>

                <div class="page-wrapper">
                    <header class="site-header site-header__header-one ">
                        <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                            <div class="container clearfix">

                                <div class="logo-box clearfix">
                                    <button class="menu-toggler" data-target=".main-navigation">
                                        <span class="fa fa-bars"></span>
                                    </button>
                                    <a class="navbar-brand" href="/">
                                        <img src={logo} class="main-logo" alt="Awesome Image" />
                                    </a>
                                </div>

                                <div class="main-navigation">
                                    <ul class=" one-page-scroll-menu navigation-box">
                                        <li className=" scrollToLink">
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="current scrollToLink">
                                            <Link to={"/about-us"}>About Us</Link>
                                        </li>
                                        <li className="scrollToLink">
                                            <Link to={"/privacy-policy"}>Privacy Policy</Link>

                                        </li>

                                        <li className="scrollToLink">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li>

                                        <li class="scrollToLink">
                                            <Link to={"/faq"}>FAQ </Link>
                                            {/* <ul class="sub-menu">
                                                <li><a href="#">News Page</a></li>
                                                <li><a href="#">News Details</a></li>
                                            </ul> */}
                                        </li>
                                    </ul>
                                </div>
                                <div class="right-side-box">
                                    <Link class="thm-btn header__cta-btn" to={"/login"}><span>Login</span></Link>
                                </div>
                            </div>

                        </nav>
                    </header>
                    <section class="aboutbanner-d col-lg-12 col-md-12 col-sm-12">
                        <div class="container">
                            <img src={about01} alt="img" />
                            <h4>About Playdiator</h4>
                            <p>Welcome to PLAYDIATOR, an App for Sports Connection & Sports Management where you can log in as a Player,
                            Educational Institute, Corporate Organization & Sports Club. You can create your own sports profile and
                            invite your sports buddies to join PLAYDIATOR - Sports App.
                            Now you can Create Teams, Challenge your buddy for your favorite sports,
                            Organize Tournaments with Live Score updates & Broadcast. With the motive
            of spreading the sports vibe, We assist you in staying fit and active</p>

                        </div>
                    </section>

                    <section class="keyfeatures-d  col-lg-12 col-md-12 col-sm-12">
                        <div class="container">
                            <h4>Key Features</h4>
                            <ul class="keylist-d">
                                <li>
                                    Create your own Sports Teams for all your favorite sports & manage all the team
                                    activities, effortlessly.
                </li>
                                <li>
                                    Throw a Challenge of your favorite sports to your buddy, fix the date & time and
                                    Bang On! Meet them on the field.
                </li>
                                <li>
                                    Update the Live Scores of the matches with the Live Broadcast, to show your friends
                                    and family your sports skills
                </li>
                                <li>
                                    Chat with your buddy teammates in an in-app messenger & formulate your
                                    Game plan.
                </li>
                                <li>
                                    Organize Tournaments like Straight league, Pool based league, Knockout on just
                                    a few clicks.
                </li>
                                <li>
                                    Keep Your own track records and share your sports stories with your buddies.
                </li>
                            </ul>
                        </div>
                    </section>

                    <section class="sports_activity-d">
                        <div class="container">
                            <div class="col-lg-6 col-md-6 col-sm-12 activity_img-d">
                                <img src={aboutimg3} alt="img" class="img-responsive" />

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 activity-d">
                                <h4>Sports & Activities</h4>
                                <p>There are 72 plus sports in the app, for which you can organize challenges like:</p>
                                <p>Cricket, Football, Basketball, Volleyball, Badminton, Athletics track and fields, Gymnast, Hockey, Swimming, Tennis, Table Tennis, Rugby, Kabaddi, Squash, Cycling, Water Sports, Horse Riding, Golf, Snooker, Pool, Bowling, Taekwondo, Kickboxing, Boxing, Archery, Shooting, Chess & many other.
                </p>
                                <p>So be assured your favorite sports are there in this sports management app.</p>
                            </div>

                        </div>
                    </section>

                    <section class="keyfeatures-d educationsport-d  col-lg-12 col-md-12 col-sm-12">
                        <div class="container education_sports-d">
                            <h4>For Educational Institute :</h4>
                            <p>Organize your school/university level matches on the sports management app. It's a great platform for streamlining your processes.
                    Institutes can have their own profiles & can create their own teams on the app itself.</p>
                            <h4>Add your students on the app to share information regarding sports events :</h4>
                            <p>Institutes can also challenge other Institutes for matches on this sports management app.</p>
                            <h4>Add your students on the app to share information regarding sports events :</h4>
                            <p>Corporate Organization & Sports Club can have their own profile on the app. Here is the tool for you
                    to conduct/manage sports events in a much more organized fashion.</p>
                        </div>
                    </section>



                    <section class="cta-one">
                        <img src={ctaonebg} class="cta-one__bg" alt="Awesome Image" />
                        <div class="container">
                            <img src={aboutimg2} class="cta-one__moc" alt="Awesome Image" />
                            <div class="row justify-content-lg-end">
                                <div class="col-lg-6 aboutlasttext-d">
                                    <div class="cta-one__content">
                                        <div class="block-title text-left">
                                            <h4>Create sports challenges on the app and get free from the hassles of recording scores on paper :</h4>

                                            <p>Create own sports teams, organize challenges, & update sports live scores with the live broadcast of the matches on a single platform.</p>
                                            <p>So basically, "PLAYDIATOR" is an amazing platform for all the sports enthusiast out there for showcasing their sports talent & manage sports activity be a part of sports networking & management App.
                    </p>
                                            <p>Its sports social app for sharing your sports skills, achievements, the results of the matches you have played or your thoughts & opinion related to different sports. With the aim to positively and actively influence society with only positive messages regarding sports, Join the most sought-after Sports Networking App now!
                    </p>
                                            <p>Let's build the sports community together & start networking with the other sports enthusiast on the PLAYDIATOR - Sports App</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <section class="mailchimp-one">
                        <div class="container">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="block-title">
                                    <h2 class="block-title__title l-text">Make everything <br></br> <span class="small-text">transparent & fair for everyone</span>
                                        <br></br><span class="show"><a href="#" class="download_btn">REGISTER NOW</a></span>
                                        <br></br><span class="hide"><a href="#" class="download_btn">DOWNLOAD APP NOW</a></span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


                <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
            </div>
        )
    }
}
