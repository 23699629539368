import React, { Component } from 'react'
import challengefill from "../img/challengefill.png";
import teamIcon from "../img/team.png";
import schedulematches from "../img/schedulematches.png";
import location from "../img/location.png";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import Map from '../Map';
import * as myConstclass from '../ConstFile';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-bootstrap/Modal';
import { message } from 'antd';
import 'antd/dist/antd.css';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const MATCH_TYPE_QUARTER_FINAL = "Quarter final";
const MATCH_TYPE_SEMI_FINAL = "Semi final";
const MATCH_TYPE_FINAL = "Final";
const quarterFinals = [
    {
        pairId: 1,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 3,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 4,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const semiFinals = [
    {
        pairId: 1,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }, {
        pairId: 2,
        ended: false, draw: false,
        date: null, time: null,
        location: null, city: null,
        state: null, country: null,
        latitude: null, longitude: null
    }];
const final = {
    poolNo: 1,
    poolId: 1,
    ended: false,
    date: null, time: null,
    location: null, city: null,
    state: null, country: null,
    latitude: null, longitude: null
};

const organizeMatch = {
    ttType: null,
    finals: null,
}

const Pool1 = [], Pool2 = [], Pool3 = [], Pool4 = [], Pool5 = [], Pool6 = [], Pool7 = [], Pool8 = [];
var poolData = [];
let PoolA, PoolB, PoolC, PoolD, PoolE, PoolF, PoolG, PoolH;
let datePickerId = 0;
let name, pic;
var organiseList1 = [];
var organiseList2 = [];
var organiseList3 = [];
var organiseList4 = [];
var acceptedList = [];
let TotalPool;
export default class DOrganiseMatchTT4Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDateTime: false,
            tDateTime: [new Date()],
            dateDate: myConstclass.getDateYYYYmmdd(new Date()),
            dateTime: myConstclass.getTimeFromDate(new Date()),
            fields: {},
            errors: {},
            touched: {},
            acceptedPairState: [],
            showQuarter: false,
            showQuarterList: false,
            showSemi: false,
            showSemiList: false,
            showFinal: true,
            defaultQuarter: false,
            defaultSemi: false,
            FinaldateObj: null,
            locationType: '',
            showPoolBasedLeagueModal: false,
            showOrganiseCreateButton: true,
            showEvenBuddy: false,
            showOddBuddy: false,
            tickCountForEven: 0,
            selectForEven: [],
            NumberOfPool: 0,
            PlayerPerPool: 0,
            filterSelection: [],
            showReshuffle: true,
            poolData: [],
        }
        this.semiORfinal = this.semiORfinal.bind(this);
        this.quarterORsemi = this.quarterORsemi.bind(this);
        this.handleClose = this.handleClose.bind(this);
    };
    reShuffle = () => {
        while (poolData.length > 0) {
            poolData.pop();
        }
        while (Pool1.length > 0) {
            Pool1.pop();
        }
        while (Pool2.length > 0) {
            Pool2.pop();
        }
        while (Pool3.length > 0) {
            Pool3.pop();
        }
        while (Pool4.length > 0) {
            Pool4.pop();
        }
        while (Pool5.length > 0) {
            Pool5.pop();
        }

        let players = [...acceptedList];
        players.sort(() => Math.random() - 0.5);
        players.forEach((tAccept, index) => {
            if (TotalPool == 2 || TotalPool == 3 || TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 0) {
                    Pool1.push(tAccept);
                }
                else if (index % parseInt(TotalPool) == 1) {
                    Pool2.push(tAccept);
                }
            }
            if (TotalPool == 3 || TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 2) {
                    Pool3.push(tAccept)
                }
            }
            if (TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 3) {
                    Pool4.push(tAccept)
                }
            }
            if (TotalPool == 8) {
                if (index % parseInt(TotalPool) == 4) {
                    Pool5.push(tAccept)
                }
                else if (index % parseInt(TotalPool) == 5) {
                    Pool6.push(tAccept)
                }
                else if (index % parseInt(TotalPool) == 6) {
                    Pool7.push(tAccept)
                }
                else {
                    Pool8.push(tAccept)
                }
            }
        })
        for (let i = 0; i < TotalPool; i++) {
            let poolDataObj = {
                poolNo: null,
                poolId: null,
                date: null,
                time: null,
                ended: false,
                attempts: 0,
                matchesList: [],
                location: null,
                city: null,
                state: null,
                country: null,
                latitude: null,
                longitude: null
            };
            if (i == 0) {
                poolDataObj.poolNo = 1;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool1.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 1, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 1) {
                poolDataObj.poolNo = 2;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 2, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 3) {
                poolDataObj.poolNo = 3;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 3, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 4) {
                poolDataObj.poolNo = 4;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 4, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            setTimeout(() => { this.setState({ poolData: poolData }) },
                600);
        }
    }
    componentDidMount() {

        if (this.props.tournamentData.sportsName == '4x400 meter relay' || this.props.tournamentData.sportsName == 'Swimming' || this.props.tournamentData.sportsName == '4x100 m Hurdle' || this.props.tournamentData.sportsName == '200 meter' || this.props.tournamentData.sportsName == '400 meter' || this.props.tournamentData.sportsName == '110 meters hurdle' || this.props.tournamentData.sportsName == '100 meters hurdle' || this.props.tournamentData.sportsName == '100 meter' || this.props.tournamentData.sportsName == 'Roller Skating') {
            if (this.props.tournamentData.accepted.length >= 12 && this.props.tournamentData.accepted.length <= 16) {
                TotalPool = 2;
                this.setState({ totalPool: 2, type: "8" });
            }
            else if (this.props.tournamentData.accepted.length >= 24 && this.props.tournamentData.accepted.length <= 32) {
                TotalPool = 4;
                this.setState({ totalPool: 4, type: "8" });
            }
            else if (this.props.tournamentData.accepted.length >= 48 && this.props.tournamentData.accepted.length <= 64) {
                TotalPool = 8;
                this.setState({ totalPool: 8, type: "8" });
            }
        }
        else {
            if (this.props.tournamentData.accepted.length >= 10 && this.props.tournamentData.accepted.length < 20) {
                TotalPool = 2;
                this.setState({ showEvenBuddy: true, totalPool: 2, type: "UD" });
            }
            else if (this.props.tournamentData.accepted.length >= 20 && this.props.tournamentData.accepted.length < 40) {
                TotalPool = 2;
                this.setState({ totalPool: 2 });
            }
            else if (this.props.tournamentData.accepted.length == 40) {
                TotalPool = 3;
                this.setState({ totalPool: 3 });
            }
            else if (this.props.tournamentData.accepted.length > 40 && this.props.tournamentData.accepted.length <= 80) {
                TotalPool = 2;
                this.setState({ totalPool: 2 });
            }
            else if (this.props.tournamentData.accepted.length > 80 && this.props.tournamentData.accepted.length <= 160) {
                TotalPool = 8;
                this.setState({ totalPool: 8, type: "UD" });
            }
        }
        let matchList = [], matchObj = {};
        acceptedList = this.props.tournamentData.accepted;
        acceptedList.forEach((tAccept, index) => {
            if (TotalPool == 2 || TotalPool == 3 || TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 0) {

                    Pool1.push(tAccept);

                }
                else if (index % parseInt(TotalPool) == 1) {
                    Pool2.push(tAccept);
                }
            }
            if (TotalPool == 3 || TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 2) {
                    Pool3.push(tAccept)
                }
            }
            if (TotalPool == 4 || TotalPool == 8) {
                if (index % parseInt(TotalPool) == 3) {
                    Pool4.push(tAccept)
                }
            }
            if (TotalPool == 8) {
                if (index % parseInt(TotalPool) == 4) {
                    Pool5.push(tAccept)
                }
                else if (index % parseInt(TotalPool) == 5) {
                    Pool6.push(tAccept)
                }
                else if (index % parseInt(TotalPool) == 6) {
                    Pool7.push(tAccept)
                }
                else {
                    Pool8.push(tAccept)
                }
            }
        })
        for (let i = 0; i < TotalPool; i++) {
            let poolDataObj = {
                poolNo: null,
                poolId: null,
                date: null,
                time: null,
                ended: false,
                attempts: 0,
                matchesList: [],
                location: null,
                city: null,
                state: null,
                country: null,
                latitude: null,
                longitude: null
            };
            if (i == 0) {
                poolDataObj.poolNo = 1;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool1.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 1, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 1) {
                poolDataObj.poolNo = 2;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 2, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 3) {
                poolDataObj.poolNo = 3;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 3, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            if (i == 4) {
                poolDataObj.poolNo = 4;
                poolDataObj.poolId = this.props.tournamentData.tournamentId + '-' + poolDataObj.poolNo + '--2';
                Pool2.forEach((number, index) => {
                    poolDataObj.matchesList.push({ "playerId": number, "matchId": this.props.tournamentData.tournamentId + '-' + number, "poolNo": 4, "index": 0 })
                })
                poolData.push(poolDataObj)
            }
            this.setState({ poolData: poolData })

        }

        if (this.props.tournamentData.tournamentMode === 'buddy') {
            let user = [...this.props.usersInTournament];
            console.log(user)
            let userArray = [];
            user.forEach(user => {
                this.props.tournamentData.accepted.forEach(accepted => {
                    if (accepted === user.contactNo) {
                        let userList = {};
                        userList.name = user.name;
                        userList.Id = user.contactNo;
                        userList.Img = user.profilePic;
                        userArray = [...userArray, userList];
                    }
                });
            })
            console.log(userArray)
            this.setState({ userTeamList: userArray })
        }
        else {
            let team = [...this.props.teamsInTournament];
            let teamArray = [];
            team.map(team => {
                this.props.tournamentData.accepted.forEach(accepted => {
                    if (accepted === team.teamId) {
                        let teamList = {};
                        teamList.name = team.teamName;
                        teamList.Id = team.teamId;
                        teamList.Img = team.teamIcon;
                        teamArray.push(teamList)
                    }
                });
            });
            this.setState({ userTeamList: teamArray })
        }
    }
    handleClose() {
        this.setState({ showOddBuddySelection: false })
    }
    savePlayerForEven(e) {
    }
    quarterORsemi(e) {
        if (e.target.value === 'quarter-final') {
            this.setState({ showQuarterList: true, showSemiList: true })
        }
        if (e.target.value === 'semi-final') {
            this.setState({ showQuarterList: false })
        }
    }
    semiORfinal(e) {
        if (e.target.value === 'semi-final') {
            this.setState({ showSemiList: true })
        }
        if (e.target.value === 'final') {
            this.setState({ showSemiList: false })
        }
    }
    setTdateTime = (date) => {

        let alldata = this.state.acceptedPairState;
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        poolData.map((pd, index) => {
            if (index === this.state.Dateindex) {
                pd.dateObj = date;
                pd.date = dateFormate;
                pd.time = dateTime;
            }
        })


        this.setState({
            dateDate: dateFormate, dateTime: dateTime, poolData: poolData
        });
    };
    setTdateTimeFinal = (date) => {
        this.setState({ FinaldateObj: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        final.date = dateFormate;
        final.time = dateTime;
    }
    setTdateTimeSemiFinal1 = (date) => {
        this.setState({ SemiFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[0].date = dateFormate;
        semiFinals[0].time = dateTime;
    }
    setTdateTimeSemiFinal2 = (date) => {
        this.setState({ SemiFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        semiFinals[1].date = dateFormate;
        semiFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal1 = (date) => {
        this.setState({ QuarterFinaldateObj1: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[0].date = dateFormate;
        quarterFinals[0].time = dateTime;
    }
    setTdateTimeQuarterFinal2 = (date) => {
        this.setState({ QuarterFinaldateObj2: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[1].date = dateFormate;
        quarterFinals[1].time = dateTime;
    }
    setTdateTimeQuarterFinal3 = (date) => {
        this.setState({ QuarterFinaldateObj3: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[2].date = dateFormate;
        quarterFinals[2].time = dateTime;
    }
    setTdateTimeQuarterFinal4 = (date) => {
        this.setState({ QuarterFinaldateObj4: date });
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDateAmPm(date);
        quarterFinals[3].date = dateFormate;
        quarterFinals[3].time = dateTime;
    }
    callbackFunction = (childData) => {

        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        if (this.state.locationType === 'final') {
            final.location = childData.address;
            final.city = childData.city;
            final.state = childData.address;
            final.country = childData.country;
            final.latitude = childData.latValue;
            final.longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'semi-final') {

            semiFinals[this.state.Locationindex].location = childData.address;
            semiFinals[this.state.Locationindex].city = childData.city;
            semiFinals[this.state.Locationindex].state = childData.address;
            semiFinals[this.state.Locationindex].country = childData.country;
            semiFinals[this.state.Locationindex].latitude = childData.latValue;
            semiFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else if (this.state.locationType === 'quarter-final') {

            quarterFinals[this.state.Locationindex].location = childData.address;
            quarterFinals[this.state.Locationindex].city = childData.city;
            quarterFinals[this.state.Locationindex].state = childData.address;
            quarterFinals[this.state.Locationindex].country = childData.country;
            quarterFinals[this.state.Locationindex].latitude = childData.latValue;
            quarterFinals[this.state.Locationindex].longitude = childData.lngValue;
        }
        else {
            poolData.map((pd, index) => {
                if (index === this.state.Locationindex) {
                    pd.location = childData.address;
                    pd.city = childData.city;
                    pd.state = childData.address;
                    pd.country = childData.country;
                    pd.latitude = childData.latValue;
                    pd.longitude = childData.lngValue;
                }
            })
            this.setState({ poolData: poolData })
        }
    }
    datepicker = (id, poolNo) => {

        this.setState({ Dateindex: id, clickPoolNo: poolNo })
    }

    location = (id, poolNo) => {
        this.setState({ Locationindex: id, clickPoolNo: poolNo })
    }
    locationType = (id, type) => {
        this.setState({ Locationindex: id, locationType: type })
    }
    organiseTournament = () => {
        console.log(poolData)
        poolData.map(pd => {
            delete pd.dateObj;
        })
        let formLeagueError = false;
        let formQuarterError = false;
        let formSemiError = false;
        let formFinalError = false;

        let pool = [];
        final.poolId = this.props.tournamentData.tournamentId + '-1--3';
        poolData.map(league => {

            if (league.date !== undefined && league.date !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
            if (league.city !== undefined && league.city !== null) {
                formLeagueError = false;
            }
            else {
                formLeagueError = true;
            }
        })

        if (this.state.showQuarterList) {
            quarterFinals.map(qf => {
                if (qf.date !== undefined && qf.date !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
                if (qf.city !== undefined && qf.city !== null) {
                    formQuarterError = false;
                }
                else {
                    formQuarterError = true;
                }
            })
        }
        if (this.state.showSemiList) {
            semiFinals.map(sf => {
                if (sf.date !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
                if (sf.city !== undefined && sf.city !== null) {
                    formSemiError = false;
                }
                else {
                    formSemiError = true;
                }
            })
        }
        if (final.date !== null) {
            formFinalError = false;
        }
        else {

            formFinalError = true;
        }
        if (final.city !== undefined && final.city !== null) {
            formFinalError = false;
        }
        else {
            formFinalError = true;
        }

        if (formLeagueError == true || formQuarterError == true || formSemiError || formFinalError == true) {
            $('#organiseTornamentError').show();
            $('#organiseTornamentError').delay(2000).fadeOut('slow');
        }
        else {
            let quarterData;
            if (this.state.showQuarterList) {
                quarterData = [...quarterFinals];
            }
            else {
                quarterData = null;
            }
            let semiData;
            if (this.state.showSemiList) {
                semiData = [...semiFinals];
            }
            else {
                semiData = null;
            }
            if (this.state.type === 'UD') {
                organizeMatch.ttType = 5;
            }
            else {
                organizeMatch.ttType = 4;
            }
            organizeMatch.tt4Matches = poolData;
            if (quarterData !== null) {
                organizeMatch.quarterFinals = quarterData;
            }
            if (semiData !== null) {
                organizeMatch.semiFinals = semiData;
            }
            organizeMatch.finals = final;
            console.log(organizeMatch)
            const that = this;
            const apiUrl = rshApi + "/tournaments/organize-tt4";

            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        tournamentId: that.props.tournamentData.tournamentId,
                        organizeMatch: organizeMatch,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)
                    $('#organiseTornamentSuccess').show();
                    $('#organiseTornamentSuccess').delay(2000).fadeOut('slow');

                    // this.props.afterOrganiseList();

                }).catch(function (error) {

                })

            var tournament = that.props.tournamentData;
            let matchType;
            if (that.state.showQuarterList) {
                matchType = "Quarter final";
            }
            else if (that.state.showSemiList) {
                matchType = "Semi final";
            }
            else {
                matchType = "final";
            }
            tournament.matchType = matchType;
            tournament.scheduledTournament = true;
            delete tournament.id;
            var apiUrl1 = rshApi + "tournaments/update-tournament";
            fetch(apiUrl1, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournament": tournament,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    that.props.afterOrganiseList();
                });
        }
    }


    render() {
        let matchListArray = [];
        let datetime = this.state.tDateTime;

        organiseList1 = [];

        this.state.poolData.map((pool, index) => {
            console.log(pool)
            datePickerId++;
            matchListArray = [];
            pool.matchesList.map(ml => {
                if (this.props.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(team => {
                        if (team.teamId === ml.playerId) {
                            name = team.teamName;
                            pic = team.teamIcon;
                        }
                    });
                }
                else {
                    this.props.usersInTournament.map(user => {
                        if (user.contactNo === ml.playerId) {
                            name = user.name;
                            pic = user.profilePic;
                        }
                    });
                }
                let matchList = <li><a> <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                    <label>{name}</label>
                </a></li>
                matchListArray.push(matchList);
            })


            var organise = <div class="back_white">
                <div class="pool-number">
                    <label> POOL -{index + 1}</label>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="completedmatches">
                        <ul class="buddieslist_ul ct_buddies"><div class="straight_inputs">
                            <div class="fixture_date" onClick={() => this.datepicker(index, index + 1)}>
                                <DatePicker
                                    selected={pool.dateObj}
                                    onChange={this.setTdateTime}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    minDate={new Date()}
                                    placeholderText="Click to select a date"
                                />
                                <img src={schedulematches} alt="" />
                            </div>
                            <div class="fixture_date fixture_location" onClick={() => this.location(index, index + 1)}>
                                <Map parentCallback={this.callbackFunction} />
                                <img src={location} alt="" />
                            </div>
                        </div>{matchListArray} </ul>
                    </div>
                </div>
            </div>
            organiseList1.push(organise);
        })
        // }
        return (
            <React.Fragment>

                {this.state.showReshuffle ? <div class="">
                    <button className="createnext" onClick={() => this.reShuffle()}>Re-Shuffle</button>
                </div> : ""}



                {organiseList1}

                {this.state.showQuarter ?
                    <div class="schedule_pad straight_pad">
                        <div class="cc_inner_box">
                            <label class="cc_box-head">Format</label>
                            <div class="schedulebox">
                                <React.Fragment>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="quarter-final" id="quarter-final" defaultChecked={this.state.defaultQuarter} onChange={this.quarterORsemi} />
                                        <label for="quarter-final">Quarter Final</label>
                                    </div>
                                    <div class="styled-input-single createinput">
                                        <input type="radio" name="matchType" value="semi-final" id="semi-final" onChange={this.quarterORsemi} />
                                        <label for="semi-final">Semi Final</label>
                                    </div>

                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                    : ''
                }
                {
                    this.state.showQuarterList ?
                        <React.Fragment>
                            <div class="semi_headingdiv quater-final">
                                <label >Quarter Final</label>
                            </div>
                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj1}
                                                                onChange={this.setTdateTimeQuarterFinal1}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(0, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj2}
                                                                onChange={this.setTdateTimeQuarterFinal2}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">
                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj3}
                                                                onChange={this.setTdateTimeQuarterFinal3}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(2, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date">
                                                            <DatePicker
                                                                selected={this.state.QuarterFinaldateObj4}
                                                                onChange={this.setTdateTimeQuarterFinal4}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(3, 'quarter-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment> : ""
                }
                {
                    this.state.showSemi ?
                        <div class="schedule_pad straight_pad">
                            <div class="cc_inner_box">
                                <label class="cc_box-head">Format</label>
                                <div class="schedulebox">
                                    <React.Fragment>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="semi-final" id="semi-final" defaultChecked={this.state.defaultSemi} onChange={this.semiORfinal} />
                                            <label for="semi-final">Semi Final</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="matchType" value="final" id="final" onChange={this.semiORfinal} />
                                            <label for="final">Final</label>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div> : ""
                }
                {
                    this.state.showSemiList ?
                        <React.Fragment>
                            <div class="semi_headingdiv semi-final">
                                <label >Semi Final</label>
                            </div>

                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.SemiFinaldateObj1}
                                                                onChange={this.setTdateTimeSemiFinal1}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(0, 'semi-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.SemiFinaldateObj2}
                                                                onChange={this.setTdateTimeSemiFinal2}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'semi-final')}>
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : ""
                }
                {
                    this.state.showFinal ?
                        <React.Fragment>
                            <div class="semi_headingdiv final">
                                <label >Final</label>
                            </div>

                            <div class="back_white">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="completedmatches">
                                        <ul class="scoreboxul tourcompleted_match">

                                            <li class="leauge_matchdiv">
                                                <div class="teamdiv">
                                                    <ul class="live_teamul live_teamul1 straight_ul">
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class=" straight_player">To Be Decided</label>
                                                        </li>
                                                        <li>
                                                            <img class="vsicon" src={challengefill} alt="" />
                                                        </li>
                                                        <li>
                                                            <span class="lt_img"
                                                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            <label class="straight_player">To Be Decided</label>
                                                        </li>
                                                    </ul>
                                                    <div class="straight_inputs">
                                                        <div class="fixture_date" style={{ borderBottom: '2px solid #2cae4a' }}>
                                                            <DatePicker
                                                                selected={this.state.FinaldateObj}
                                                                onChange={this.setTdateTimeFinal}
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                minDate={new Date()}
                                                                placeholderText="Click to select a date"
                                                            />
                                                            <img src={schedulematches} alt="" />
                                                        </div>
                                                        <div class="fixture_date fixture_location" onClick={() => this.locationType(1, 'final')} >
                                                            <Map parentCallback={this.callbackFunction} />
                                                            <img src={location} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </React.Fragment> : ""
                }
                <div>

                    {this.state.showOrganiseCreateButton ? <center>
                        <button type="button" onClick={() => this.organiseTournament()} class="createnext" type="submit">Organise Tournament</button>
                    </center> : ""}
                    <div id="organiseTornamentSuccess" style={{ display: 'none', margin: '5px' }} className="alert alert-success " role="alert">
                        <strong>Tournament Organised successfully !</strong>
                    </div>
                    <div id="organiseTornamentError" style={{ display: 'none', margin: '5px' }} className="alert alert-danger " role="alert">
                        <strong>please Fill All information !</strong>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
