import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { trackPromise } from "react-promise-tracker";
import challengefill from "../img/challengefill.png";
import challenegbg from "../img/challenegbg.png";
import cancelred from "../img/cancelred.png";
import postgallery from "../img/login/post-gallery.png";
import teamIcon from "../img/team.png";
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import matchbg from "../img/matchbg.png";
import winnerlogo from "../img/winnerlogo.png";
import Header from "../Header";
import SectionLeft from "../SectionLeft";
import SectionRight from "../SectionRight";
import Modal from 'react-bootstrap/Modal';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import DemoChallenge_d101 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d101";
import DemoChallenge_d101Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d101Vue";
import DemoChallenge_d102 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d102";
import DemoChallenge_d102Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d102Vue";
import DemoChallenge_d103 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d103";
import DemoChallenge_d103Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d103Vue";
import DemoChallenge_d104 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d104";
import DemoChallenge_d104Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d104Vue";
import DemoChallenge_d105 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d105";
import DemoChallenge_d105Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d105Vue";
import DemoChallenge_d106 from "../DemoChallenge/DscoringChallenge/DemoChallenge_d106";
import DemoChallenge_d106Vue from "../DemoChallenge/DscoringChallenge/DemoChallenge_d106Vue";
import DemoUpdate_d107_tennis from "../DemoChallenge/DscoringChallenge/DemoUpdate_d107_tennis";
import DemoUpdate_d107TableTennis from "../DemoChallenge/DscoringChallenge/DemoUpdate_d107TableTennis";
import Demo107Vue_tennis from "../DemoChallenge/DscoringChallenge/Demo107Vue_tennis";
import Demo107Vue_badminton from "../DemoChallenge/DscoringChallenge/Demo107Vue_badminton";
import DemoD107Vue_tableTenis from "../DemoChallenge/DscoringChallenge/Demo107Vue_tableTenis";
import DemoUpdate_D107squash from "../DemoChallenge/DscoringChallenge/DemoUpdate_D107squash";
import DemoUpdate_D107Archary from "../DemoChallenge/DscoringChallenge/DemoUpdate_D107Archary";
import Demo107vue_Archary from '../DemoChallenge/DscoringChallenge/Demo107vue_Archary';
import DemoUpdateD107badminton from "../DemoChallenge/DscoringChallenge/DemoUpdateD107badminton";
import Demo107Vue_squash from "../DemoChallenge/DscoringChallenge/Demo107Vue_squash";
import Demo107Vue_basketball from "../DemoChallenge/DscoringChallenge/Demo107Vue_basketball";
import DemoUpdated107Basketball from "../DemoChallenge/DscoringChallenge/DemoUpdated107Basketball";
import DemoUpdate_D107Vollyball from "../DemoChallenge/DscoringChallenge/DemoUpdate_D107Vollyball";
import Demo107Vue_vollyball from '../DemoChallenge/DscoringChallenge/Demo107Vue_vollyball';
import DemoUpdate_D107BeachVollyball from "../DemoChallenge/DscoringChallenge/DemoUpdate_D107BeachVollyball";
import Demo107Vue_BeachVollyball from "../DemoChallenge/DscoringChallenge/Demo107Vue_BeachVollyball";
import DemoUpdate_d107bs from "../DemoChallenge/DscoringChallenge/DemoUpdate_d107bs";
import Demo107Vue_bs from "../DemoChallenge/DscoringChallenge/Demo107Vue_bs";
import Demo107Vue_Cricket from '../DemoChallenge/DscoringChallenge/Demo107Vue_Cricket';
import DemoUpdate_d107_Rugby from '../DemoChallenge/DscoringChallenge/DemoUpdate_d107_Rugby'
import DemoUpdate_d107Cricket from '../DemoChallenge/DscoringChallenge/DemoUpdate_d107Cricket';
import AddCourse from "../AddCourse";
import DemoRoundSelection from "../DemoChallenge/DscoringChallenge/DemoRoundSelection";
import DemoUpdate_D107_kabaddi from "../DemoChallenge/DscoringChallenge/DemoUpdate_D107_kabaddi";
import Demo107Vue_kabaddi from "../DemoChallenge/DscoringChallenge/Demo107Vue_kabaddi";
import Demo107Vue_Rugby from '../DemoChallenge/DscoringChallenge/Demo107Vue_Rugby'
import DemoUpdate_d107_Carrom from '../DemoChallenge/DscoringChallenge/DemoUpdate_d107_Carrom'
import Demo107Vue_carrom from '../DemoChallenge/DscoringChallenge/Demo107Vue_carrom'
import DemoUpdate_d107Golf from "../DemoChallenge/DscoringChallenge/DemoUpdate_d107Golf";

import Demo107Vue_Golf from "../DemoChallenge/DscoringChallenge/Demo107Vue_Golf";


import ConfigUrl from "../ConfigUrl";
const ConfigApiUrl = ConfigUrl("apiUrl");
const rshApi = ConfigApiUrl;
var buttonHide = true,
    buttonHide1 = true;
var selectRound;
class DChallengeProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileAllData: {},
            profileChallenge: {},
            profileUsers: [],
            profileTeams: [],
            sportName: "",
            buttonText: "Accept",
            buttonText1: "Reject",
            challengeWinner: false,
            isMiddlesection: true,
            isChallenge_d101: false,
            isChallenge_d101Vue: false,
            isChallenge_d102: false,
            isChallenge_d102Vue: false,
            isChallenge_d103: false,
            isChallenge_d103Vue: false,
            isChallenge_d104: false,
            isChallenge_d104Vue: false,
            isChallenge_d105: false,
            isChallenge_d105Vue: false,
            isChallenge_d106: false,
            isChallenge_d106Vue: false,
            isUpdate_d107_tennis: false,
            isUpdate_d107_Rugby: false,
            isUpdate_d107TableTennis: false,
            isUpdateArchery: false,
            isChallenge_d107Vue_tennis: false,
            isUpdateBadminton: false,
            isChallenge_d107Vue_badminton: false,
            isChallenge_d107Vue_tableTennis: false,
            isUpdate_d107Squash: false,
            isChallenge_d107Vue_squash: false,
            isChallenge_d107Vue_basketball: false,
            isUpdate_d107Basketball: false,
            isUpdate_d107Vollyball: false,
            isUpdate_d107BeachVollyball: false,
            isChallenge_d107Vue_BeachVollyball: false,
            isUpdate_D107_kabaddi: false,
            isUpdate_D107_Golf: false,
            isChallenge_D107Vue_kabaddi: false,
            isUpdate_d107_Carrom: false,
            isUpdateD107Bs: false,
            isChallengeD107BsVue: false,
            isChallenge_D107vue_Archary: false,
            isChallenge_D107Vue_vollyball: false,
            isChallenge_d107Vue_Rugby: false,
            isChallenge_d107Vue_Football: false,
            isChallenge_d107Vue_carrom: false,
            isChallenge_Demo107Vue_Golf: false,
            isUpdate_d107Cricket: false,
            isChallenge_D107Vue_Cricket: false,
            profileChallengeinvited: 0,
            courses: [],
            challengeProfileCenter: true,
            ShowAddCourseForm: false,
            showRounds: false,
            courseMessage: false,
            roundMessage: false,
            uploading: false,
            isOpen: false,
            CheckDelete: false,

        };

        this.state.user = JSON.parse(localStorage.getItem("result"));
    }
    childCourseCall = (obj) => {
        let courseList = [...this.state.courses];
        courseList.push(obj);
        this.setState({ ShowAddCourseForm: false, challengeProfileCenter: false, showCoursesList: true })
        this.setState({ courses: courseList });
    }
    showRound = () => {
        this.setState({ showRounds: true, challengeProfileCenter: false, });
    }
    childRoundCall = (obj) => {
        this.setState({ profileChallenge: obj, showRounds: false, selectRound: false, challengeProfileCenter: true });
    }

    gallPicker = (event) => {
        this.setState({ uploading: true })
        if (event.target.value) {
            let file = event.target.files[0];
            let allDaata = this.state.profileChallenge;

            let timestamp = Math.floor(Date.now());
            let urlParams = new URLSearchParams(window.location.search);
            let challengeid = urlParams.get("id");
            const apiUrl = rshApi + "upload-file";
            var data = {
                "data": {
                    "folder": "challenge/" + challengeid,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            // let downloadUrl = '';     
            let that = this;
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {

                    console.log(result)


                    that.setState({ uploading: false })

                    uploadSuggestedPostData(result.data.downloadUrl).then(res => {
                        that.setState({ profileChallenge: allDaata })
                    })

                }).catch(error => {
                    console.log(error)
                })

            async function uploadSuggestedPostData(data) {
                delete allDaata.id;
                let media = {
                    url: data,
                    mediaType: 'image',
                    timestamp: timestamp,
                }
                allDaata.medias ? allDaata.medias.push(media) : allDaata.medias = [media];

                fetch(rshApi + "demo-challenges/update-challenge", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            challenge: allDaata,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {


                    });
            }
        }
    }

    ImageVeiw = (url) => {

        this.setState({ isOpen: true, galImageUrl: url })

    }


    deleteGallPic = (event) => {
        event.stopPropagation();
        let url = event.target.id;
        let challenge = this.state.profileChallenge;
        delete challenge.id;
        let med = challenge.medias.filter(md => md.url !== url);
        challenge.medias = med;
        if (challenge.medias.length == 0) {
            challenge.medias = null
        }
        let that = this;

        fetch(rshApi + "demo-challenges/update-challenge", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    challenge: challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ profileChallenge: challenge });
            });

    }







    chooseCourse(courseID) {

        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.state.profileChallenge;
        delete challenge.id;
        challenge.courseId = courseID;
        challenge.courseSelected = true;
        challenge.modifiedOn = timpstampData;

        let that = this;
        const apiUrl = rshApi + "demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({ selectRound: true, showCoursesList: false });
            });
    }
    componentDidMount() {
        this.getChallengeProfile();
    }

    stateRefresh = () => {
        this.setState({ isMiddlesection: true, isUpdate_d107_tennis: false });
    };

    //challenges profile data
    getChallengeProfile = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let challengeid = urlParams.get("id");
        const apiUrl = rshApi + "/demo-challenges/get-challenge";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    challengeId: challengeid,
                },
            }),
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.challenge) {
                    if (result.data.challenge.challengeAction === "ended") {
                        that.setState({ challengeWinner: true });
                    }

                    if (result.data.challenge.invited === null) {
                        that.setState({
                            profileChallengeinvited: 0,
                        });
                    } else {
                        that.setState({
                            profileChallengeinvited: result.data.challenge.invited.length,
                        });
                    }

                    that.setState({
                        profileAllData: result.data,
                        profileChallenge: result.data.challenge,
                        profileChallengeaccepted: result.data.challenge.accepted.length,
                        profileUsers: result.data.users,
                        profileTeams: result.data.teams,
                        sportName: result.data.challenge.sportsName,
                    });
                }
            });
    };
    updateGolfByother = (ciD) => {

        if (this.state.profileChallenge.courseId === null) {
            this.setState({ courseMessage: true })
            $('#messageClick').click();
        }
        else if (this.state.profileChallenge.courseRound === 0) {
            this.setState({ roundMessage: true })
            $('#messageClick').click();
        }
        else {
            this.checkSportName(ciD)

        }

    }
    closeMessage = () => {
        $("#courseRoundMessage .close").click();
    }
    changeAction = (type) => {
        if (type === "accepted") {
            const apiUrl = rshApi + "/demo-challenges/react-on-challenge";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    data: {
                        challengeId: this.state.profileChallenge.challengeId,
                        action: type,
                        teamId: null,
                        userId: this.state.user.contactNo,
                    },
                }),
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                });
            this.setState({
                buttonText: "Accepted",
            });
            buttonHide1 = false;
        } else {
            this.setState({
                buttonText1: "Rejected",
            });
            buttonHide = false;
        }
    };

    checkSportName = (categoryId) => {
        if (categoryId === "D101") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d101: true,
                isMiddlesection: false,
                isChallenge_d102: false,
                isChallenge_d103: false,
                isChallenge_d104: false,
                isChallenge_d105: false,
                isChallenge_d106: false,
                isUpdate_d107Basketball: false,
                isUpdateD107Bs: false,
                isUpdate_d107_Rugby: false,
                isUpdate_d107Cricket: false,
            });
        }
        if (categoryId === "D102") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d102: true,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d103: false,
                isChallenge_d104: false,
                isChallenge_d105: false,
                isChallenge_d106: false,
                isUpdate_d107Basketball: false,
                isUpdate_D107_kabaddi: false,
                isUpdateD107Bs: false,
                isUpdate_d107Cricket: false,
                isUpdate_d107_Rugby: false,
            });
        }
        if (categoryId === "D103") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d103: true,
                isChallenge_d102: false,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d104: false,
                isChallenge_d105: false,
                isChallenge_d106: false,
                isUpdate_d107Basketball: false,
                isUpdate_D107_kabaddi: false,
                isUpdateD107Bs: false,
                isUpdate_d107Cricket: false,
                isUpdate_d107_Rugby: false,
            });
        }
        if (categoryId === "D104") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d104: true,
                isChallenge_d103: false,
                isChallenge_d102: false,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d105: false,
                isChallenge_d106: false,
                isUpdate_d107Basketball: false,
                isUpdate_D107_kabaddi: false,
                isUpdateD107Bs: false,
                isUpdate_d107Cricket: false,
                isUpdate_d107_Rugby: false,
            });
        }
        if (categoryId === "D105") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d105: true,
                isChallenge_d104: false,
                isChallenge_d103: false,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d102: false,
                isChallenge_d106: false,
                isUpdate_d107Basketball: false,
                isUpdate_D107_kabaddi: false,
                isUpdateD107Bs: false,
                isUpdate_d107_Rugby: false,
                isUpdate_d107Cricket: false,
            });
        }
        if (categoryId === "D106") {
            this.setState({
                isUpdateArchery: false,
                isUpdateBadminton: false,
                isUpdate_d107Squash: false,
                isUpdate_d107TableTennis: false,
                isChallenge_d106: true,
                isChallenge_d105: false,
                isChallenge_d104: false,
                isChallenge_d103: false,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d102: false,
                isUpdate_d107Basketball: false,
                isUpdate_D107_kabaddi: false,
                isUpdate_d107Cricket: false,
                isUpdate_d107_Rugby: false,
                isUpdateD107Bs: false,
            });
        }
        if (categoryId === "D107") {
            if ((this.state.profileChallenge.sportsName === "Baseball") || (this.state.profileChallenge.sportsName === "Softball")) {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdateD107Bs: true,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Tennis") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: true,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Table Tennis") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: true,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Squash") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: true,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Badminton") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: true,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Archery") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: true,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: false,
                    isUpdate_d107_Rugby: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Basketball") {
                this.setState({
                    isUpdate_d107Vollyball: false,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    isUpdate_d107Basketball: true,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: false,
                    isUpdate_d107Cricket: false,
                });
            }

            if (this.state.profileChallenge.sportsName === "Volleyball") {
                this.setState({
                    isUpdate_d107Vollyball: true,
                    isUpdate_d107BeachVollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    isUpdate_d107Basketball: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: false,
                    isUpdate_d107_Rugby: false,

                });
            }

            if (this.state.profileChallenge.sportsName === "Beach Volleyball") {
                this.setState({
                    isUpdate_d107BeachVollyball: true,
                    isUpdate_d107Vollyball: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    isUpdate_d107Basketball: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdate_D107_Golf: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: false,
                    isUpdate_d107_Rugby: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Kabbadi") {
                this.setState({
                    isUpdate_D107_Golf: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: true,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: false,
                    isUpdate_d107_Rugby: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Golf") {
                this.setState({
                    isUpdate_D107_Golf: true,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Cricket") {
                this.setState({
                    isUpdate_D107_Golf: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107Cricket: true,
                    isUpdate_d107_Rugby: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Carrom Board") {
                this.setState({
                    isUpdate_D107_Golf: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Carrom: true,
                    isUpdate_d107_Rugby: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Rugby") {
                this.setState({
                    isUpdate_D107_Golf: false,
                    isUpdateArchery: false,
                    isUpdateBadminton: false,
                    isUpdate_d107Squash: false,
                    isUpdate_d107TableTennis: false,
                    isUpdate_d107_tennis: false,
                    sChallenge_d104: false,
                    isChallenge_d103: false,
                    isChallenge_d102: false,
                    isMiddlesection: false,
                    isChallenge_d101: false,
                    isChallenge_d105: false,
                    isChallenge_d106: false,
                    isUpdate_d107Basketball: false,
                    isUpdate_D107_kabaddi: false,
                    isUpdateD107Bs: false,
                    isUpdate_d107_Rugby: true,
                });
            }
        }
    };

    viewChallengeScore = (categoryId) => {
        if (categoryId === "D101") {
            this.setState({
                isChallenge_d103Vue: false,
                isChallenge_d101Vue: true,
                isMiddlesection: false,
                isChallenge_d102Vue: false,
                isChallenge_d107Vue_tennis: false,
                isChallenge_d104Vue: false,
                isChallenge_d105Vue: false,
                isChallenge_d106Vue: false,
                isChallengeD107BsVue: false,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,

            });
        }
        if (categoryId === "D102") {
            this.setState({
                isChallenge_d103Vue: false,
                isChallenge_d102Vue: true,
                isMiddlesection: false,
                isChallenge_d101Vue: false,
                isChallenge_d107Vue_tennis: false,
                isChallenge_d104Vue: false,
                isChallenge_d105Vue: false,
                isChallenge_d106Vue: false,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107Vue_kabaddi: false,
                isChallengeD107BsVue: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,
            });
        }
        if (categoryId === "D103") {
            this.setState({
                isChallenge_d103Vue: true,
                isChallenge_d102Vue: false,
                isMiddlesection: false,
                isChallenge_d101Vue: false,
                isChallenge_d107Vue_tennis: false,
                isChallenge_d104Vue: false,
                isChallenge_d105Vue: false,
                isChallenge_d106Vue: false,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107Vue_kabaddi: false,
                isChallengeD107BsVue: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,
            });
        }
        if (categoryId === "D104") {
            this.setState({
                isChallenge_d104Vue: true,
                isChallenge_d102Vue: false,
                isMiddlesection: false,
                isChallenge_d101Vue: false,
                isChallenge_d107Vue_tennis: false,
                isChallenge_d103Vue: false,
                isChallenge_d105Vue: false,
                isChallenge_d106Vue: false,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107Vue_kabaddi: false,
                isChallengeD107BsVue: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,
            });
        }
        if (categoryId === "D105") {
            this.setState({
                isChallenge_d105Vue: true,
                isChallenge_d103Vue: false,
                isChallenge_d104Vue: false,
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d102: false,
                isChallenge_d106Vue: false,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107Vue_kabaddi: false,
                isChallengeD107BsVue: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,
            });
        }
        if (categoryId === "D106") {
            this.setState({
                isMiddlesection: false,
                isChallenge_d101: false,
                isChallenge_d102: false,
                isChallenge_d103Vue: false,
                isChallenge_d104Vue: false,
                isChallenge_d105Vue: false,
                isChallenge_d106Vue: true,
                isChallenge_d107Vue_BeachVollyball: false,
                isChallenge_D107Vue_kabaddi: false,
                isChallengeD107BsVue: false,
                isChallenge_D107vue_Archary: false,
                isChallenge_D107Vue_vollyball: false,
                isChallenge_d107Vue_Rugby: false,
                isChallenge_d107Vue_Football: false,
                isChallenge_d107Vue_carrom: false,
                isChallenge_D107Vue_Cricket: false,
                isChallenge_Demo107Vue_Golf: false,
            });
        }
        if (categoryId === "D107") {
            if ((this.state.profileChallenge.sportsName === "Baseball") || (this.state.profileChallenge.sportsName === "Softball")) {
                this.setState({
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallengeD107BsVue: true,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Tennis") {
                this.setState({
                    isChallenge_d107Vue_tennis: true,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Badminton") {
                this.setState({
                    isChallenge_d107Vue_badminton: true,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallengeD107BsVue: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Table Tennis") {
                this.setState({
                    isChallenge_d107Vue_tableTennis: true,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Squash") {
                this.setState({
                    isChallenge_d107Vue_squash: true,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallengeD107BsVue: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Basketball") {
                this.setState({
                    isChallenge_d107Vue_basketball: true,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }

            if (this.state.profileChallenge.sportsName === "Beach Volleyball") {
                this.setState({
                    isChallenge_d107Vue_BeachVollyball: true,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Kabbadi") {
                this.setState({
                    isChallenge_D107Vue_kabaddi: true,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Rugby") {
                this.setState({
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_d107Vue_Rugby: true,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Football") {
                this.setState({
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: true,
                    isChallenge_d107Vue_carrom: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Carrom Board") {
                this.setState({
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_d107Vue_Rugby: false,
                    isChallenge_d107Vue_Football: false,
                    isChallenge_d107Vue_carrom: true,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Archery") {
                this.setState({
                    isChallenge_D107vue_Archary: true,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_vollyball: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Volleyball") {
                this.setState({
                    isChallenge_D107Vue_vollyball: true,
                    isChallenge_D107vue_Archary: false,
                    isChallenge_D107Vue_kabaddi: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,

                });
            }

            if (this.state.profileChallenge.sportsName === "Beach Volleyball") {
                this.setState({
                    isChallenge_d107Vue_BeachVollyball: true,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }

            if (this.state.profileChallenge.sportsName === "Cricket") {
                this.setState({
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107Vue_Cricket: true,
                    isChallenge_Demo107Vue_Golf: false,
                });
            }
            if (this.state.profileChallenge.sportsName === "Golf") {
                this.setState({
                    isChallenge_d107Vue_BeachVollyball: false,
                    isChallenge_d107Vue_basketball: false,
                    isChallenge_d107Vue_squash: false,
                    isChallenge_d107Vue_tableTennis: false,
                    isChallenge_d107Vue_badminton: false,
                    isChallenge_d107Vue_tennis: false,
                    isChallenge_d104Vue: false,
                    isChallenge_d102Vue: false,
                    isMiddlesection: false,
                    isChallenge_d101Vue: false,
                    isChallenge_d103Vue: false,
                    isChallenge_d105Vue: false,
                    isChallenge_d106Vue: false,
                    isChallengeD107BsVue: false,
                    isChallenge_D107Vue_Cricket: false,
                    isChallenge_Demo107Vue_Golf: true,
                });
            }
        }
    };

    selectCourse() {
        this.setState({ ShowAddCourseForm: false, showCoursesList: true, challengeProfileCenter: false });
        const apiUrl = rshApi + "/global/get-courses";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    createdBy: that.state.user.contactNo,
                },
            }),
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== null) {
                    that.setState({ courses: result.data });
                }
            })

    }
    AddCourse() {
        this.setState({ showCoursesList: false, challengeProfileCenter: false, ShowAddCourseForm: true });
    }




    render() {
        const { isOpen } = this.state;

        // -------------------------------------------------------------------------------------
        var eventShow = false,
            distances = false,
            measures = false,
            overs = false;
        var requested = false,
            rejected = false,
            accepted = false,
            ended = false, selectCourse = false, selectCourseForOther = false;
        var action;
        if (this.state.profileChallenge.challengeAction === "invited") {
            action = true;
        } else {
            action = false;
        }

        if (this.state.profileChallenge.eventName === null) {
            eventShow = false;
        } else {
            eventShow = true;
        }

        if (this.state.profileChallenge.unit === "Meters(m)") {
            distances = true;
        } else {
            distances = false;
        }

        if (
            this.state.profileChallenge.unit === "Kilograms(Kg)" ||
            this.state.profileChallenge.unit === "Pound(lbs)"
        ) {
            measures = true;
        } else {
            measures = false;
        }

        if (this.state.profileChallenge.unit === "Overs") {
            overs = true;
        } else {
            overs = false;
        }
        // ---------------------------------------------------------------------------------------
        if (this.state.profileChallenge.challengeAction === "requested") {
            requested = true;
        }
        if (this.state.profileChallenge.challengeAction === "rejected") {
            rejected = true;
        }
        if (this.state.profileChallenge.challengeAction === "accepted") {

            if (this.state.profileChallenge.sportsName === "Golf") {

                if (this.state.profileChallenge.courseSelected) {

                    selectCourse = false;
                    if (this.state.profileChallenge.courseRound === null || this.state.profileChallenge.courseRound === 0) {
                        if (this.state.profileChallenge.createdBy === this.state.user.contactNo) {
                            selectCourse = true;
                            selectRound = true;

                        }

                    }
                    if (this.state.profileChallenge.createdBy !== this.state.user.contactNo) {
                        selectCourseForOther = true;

                    }

                }
                else {
                    if (this.state.profileChallenge.createdBy === this.state.user.contactNo) {
                        selectCourse = true;

                    }
                    else {

                        selectCourseForOther = true;
                    }

                }
            }
            accepted = true;
        }
        if (this.state.profileChallenge.challengeAction === "ended") {
            ended = true;
        }
        this.middleSection = (
            <section className="d_midsec">
                <div data-backdrop="static" id="courseRoundMessage" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        {this.state.courseMessage ? <p>The course of this challenge is not decided by the creator yet.You can not update score</p> : ''}
                                        {this.state.roundMessage ? <p>The round of this challenge is not decided by the creator yet.You can not update score</p> : ''}
                                    </div>

                                    <button type="button" className="Setscoresave-d" onClick={() => this.closeMessage()}>ok</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} id="messageClick" type="button" data-toggle="modal" data-target="#courseRoundMessage"></button>
                <div className="commanbox cp_profile">
                    <div className="divpost">
                        <span className="teamprofile_span">Demo Challenge Profile</span>
                        <div
                            className="createteam_div cp_header"
                            style={{ backgroundImage: "url(" + challenegbg + ")" }}
                        >
                            <ul className="sm_ul">
                                {/* {this.state.profileUsers.length > 2 ? <React.Fragment>
                                    {this.state.profileUsers.map(item => {
                                        if (item.profilePic === null) {
                                            switch (item.gender) {
                                                case 'Male':
                                                    return <li>
                                                        <span className="sm_user" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                        <label>{item.name}</label>
                                                    </li>
                                                default:
                                                    return <li>
                                                        <span className="sm_user" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                        <label>{item.name}</label>
                                                    </li>
                                            }
                                        }
                                        else {
                                            return <li>
                                                <span className="sm_user" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                <label>{item.name}</label>
                                            </li>
                                        }
                                    })}
                                </React.Fragment>  */}

                                {this.state.profileChallenge.challengeType === "team" ? (
                                    <React.Fragment>
                                        {this.state.profileTeams.map((item) => {
                                            if (item.teamIcon === null) {
                                                switch (item.gender) {
                                                    case "Male":
                                                        return (
                                                            <li>
                                                                <span
                                                                    className="sm_user"
                                                                    style={{
                                                                        backgroundImage: "url(" + teamIcon + ")",
                                                                    }}
                                                                ></span>
                                                                <label>{item.teamName}</label>
                                                            </li>
                                                        );
                                                    default:
                                                        return (
                                                            <li>
                                                                <span
                                                                    className="sm_user"
                                                                    style={{
                                                                        backgroundImage: "url(" + teamIcon + ")",
                                                                    }}
                                                                ></span>
                                                                <label>{item.teamName}</label>
                                                            </li>
                                                        );
                                                }
                                            } else {
                                                return (
                                                    <li>
                                                        <span
                                                            className="sm_user"
                                                            style={{
                                                                backgroundImage: "url(" + item.teamIcon + ")",
                                                            }}
                                                        ></span>
                                                        <label>{item.teamName}</label>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </React.Fragment>
                                ) : action ? (
                                    this.state.profileChallengeinvited
                                ) : this.state.profileChallengeaccepted > 1 ? (
                                    this.state.profileUsers.map((item, index) => {
                                        var contactTrue = false;
                                        if (action === true) {
                                            if (
                                                item.contactNo === this.state.profileChallenge.createdBy
                                            ) {
                                                if (item.profilePic === null) {
                                                    switch (item.gender) {
                                                        case "Male":
                                                            return (
                                                                <li key={index}>
                                                                    <span
                                                                        className="sm_user"
                                                                        style={{
                                                                            backgroundImage: "url(" + maleIcon + ")",
                                                                        }}
                                                                    ></span>
                                                                    <label>{item.name}</label>
                                                                </li>
                                                            );
                                                        default:
                                                            return (
                                                                <li key={index}>
                                                                    <span
                                                                        className="sm_user"
                                                                        style={{
                                                                            backgroundImage:
                                                                                "url(" + femaleIcon + ")",
                                                                        }}
                                                                    ></span>
                                                                    <label>{item.name}</label>
                                                                </li>
                                                            );
                                                    }
                                                } else {
                                                    return (
                                                        <li key={index}>
                                                            <span
                                                                className="sm_user"
                                                                style={{
                                                                    backgroundImage:
                                                                        "url(" + item.profilePic + ")",
                                                                }}
                                                            ></span>
                                                            <label>{item.name}</label>
                                                        </li>
                                                    );
                                                }
                                            }
                                        }
                                        {
                                            action
                                                ? this.state.profileChallenge.invited.map((invited) => {
                                                    if (item.contactNo === invited) {
                                                        return (contactTrue = true);
                                                    }
                                                })
                                                : this.state.profileChallenge.accepted.map(
                                                    (invited) => {
                                                        if (item.contactNo === invited) {
                                                            return (contactTrue = true);
                                                        }
                                                    }
                                                );
                                        }
                                        if (contactTrue) {
                                            if (item.profilePic === null) {
                                                switch (item.gender) {
                                                    case "Male":
                                                        return (
                                                            <li key={index}>
                                                                <span
                                                                    className="sm_user"
                                                                    style={{
                                                                        backgroundImage: "url(" + maleIcon + ")",
                                                                    }}
                                                                ></span>
                                                                <label>{item.name}</label>
                                                            </li>
                                                        );
                                                    default:
                                                        return (
                                                            <li key={index}>
                                                                <span
                                                                    className="sm_user"
                                                                    style={{
                                                                        backgroundImage: "url(" + femaleIcon + ")",
                                                                    }}
                                                                ></span>
                                                                <label>{item.name}</label>
                                                            </li>
                                                        );
                                                }
                                            } else {
                                                return (
                                                    <li key={index}>
                                                        <span
                                                            className="sm_user"
                                                            style={{
                                                                backgroundImage: "url(" + item.profilePic + ")",
                                                            }}
                                                        ></span>
                                                        <label>{item.name}</label>
                                                    </li>
                                                );
                                            }
                                        }
                                    })
                                ) : (
                                                <React.Fragment>
                                                    {this.state.profileUsers.slice(0, 1).map((item, index) => {
                                                        if (item.profilePic === null) {
                                                            switch (item.gender) {
                                                                case "Male":
                                                                    return (
                                                                        <li key={index}>
                                                                            <span
                                                                                className="sm_user"
                                                                                style={{
                                                                                    backgroundImage: "url(" + maleIcon + ")",
                                                                                }}
                                                                            ></span>
                                                                            <label>{item.name}</label>
                                                                        </li>
                                                                    );
                                                                default:
                                                                    return (
                                                                        <li key={index}>
                                                                            <span
                                                                                className="sm_user"
                                                                                style={{
                                                                                    backgroundImage: "url(" + femaleIcon + ")",
                                                                                }}
                                                                            ></span>
                                                                            <label>{item.name}</label>
                                                                        </li>
                                                                    );
                                                            }
                                                        } else {
                                                            return (
                                                                <li key={index}>
                                                                    <span
                                                                        className="sm_user"
                                                                        style={{
                                                                            backgroundImage: "url(" + item.profilePic + ")",
                                                                        }}
                                                                    ></span>
                                                                    <label>{item.name}</label>
                                                                </li>
                                                            );
                                                        }
                                                    })}

                                                    <li>
                                                        <img className="sm_vs" src={challengefill} alt="" />
                                                        <label className="multiplelabe">
                                                            {this.state.profileChallenge.sportsName}
                                                        </label>
                                                    </li>

                                                    {this.state.profileUsers.slice(1, 2).map((item, index) => {
                                                        if (item.profilePic === null) {
                                                            switch (item.gender) {
                                                                case "Male":
                                                                    return (
                                                                        <li key={index}>
                                                                            <span
                                                                                className="sm_user"
                                                                                style={{
                                                                                    backgroundImage: "url(" + maleIcon + ")",
                                                                                }}
                                                                            ></span>
                                                                            <label>{item.name}</label>
                                                                        </li>
                                                                    );
                                                                default:
                                                                    return (
                                                                        <li key={index}>
                                                                            <span
                                                                                className="sm_user"
                                                                                style={{
                                                                                    backgroundImage: "url(" + femaleIcon + ")",
                                                                                }}
                                                                            ></span>
                                                                            <label>{item.name}</label>
                                                                        </li>
                                                                    );
                                                            }
                                                        } else {
                                                            return (
                                                                <li key={index}>
                                                                    <span
                                                                        className="sm_user"
                                                                        style={{
                                                                            backgroundImage: "url(" + item.profilePic + ")",
                                                                        }}
                                                                    ></span>
                                                                    <label>{item.name}</label>
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </React.Fragment>
                                            )}
                            </ul>
                            {requested ? (
                                <React.Fragment>
                                    {this.state.profileChallenge.createdBy ===
                                        this.state.user.contactNo ? (
                                            <div className="cp_profilediv_new">
                                                <button className="requested">Requested</button>
                                            </div>
                                        ) : (
                                            <div className="cp_profilediv_new">
                                                {buttonHide ? (
                                                    <button
                                                        className="requested"
                                                        onClick={() => this.changeAction("accepted")}
                                                    >
                                                        {this.state.buttonText}
                                                    </button>
                                                ) : (
                                                        ""
                                                    )}
                                                {buttonHide1 ? (
                                                    <button
                                                        className="requested"
                                                        onClick={() => this.changeAction("rejected")}
                                                    >
                                                        {this.state.buttonText1}
                                                    </button>
                                                ) : (
                                                        ""
                                                    )}
                                            </div>
                                        )}
                                </React.Fragment>
                            ) : (
                                    ""
                                )}

                            {rejected ? (
                                <React.Fragment>
                                    {this.state.profileChallenge.createdBy ===
                                        this.state.user.contactNo ? (
                                            <div className="cp_profilediv_new">
                                                <button className="requested">Rejected</button>
                                            </div>
                                        ) : (
                                            <div className="cp_profilediv_new">
                                                <button className="requested">Rejected</button>
                                            </div>
                                        )}
                                </React.Fragment>
                            ) : (
                                    ""
                                )}



                            {accepted ?
                                selectCourse ?
                                    selectRound ? <React.Fragment>
                                        <div className="cp_profilediv_new">
                                            <button
                                                className="requested"
                                                onClick={() =>
                                                    this.showRound()
                                                }
                                            >
                                                Select Round
                      </button>
                                        </div>
                                    </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="cp_profilediv_new">
                                                <button
                                                    className="requested"
                                                    onClick={() =>
                                                        this.selectCourse(
                                                            this.state.profileChallenge
                                                        )
                                                    }
                                                >
                                                    Select Course
                      </button>
                                            </div>
                                        </React.Fragment>
                                    :
                                    (
                                        <React.Fragment>
                                            {this.state.profileChallenge.createdBy ===
                                                this.state.user.contactNo ? (
                                                    <div className="cp_profilediv_new">
                                                        <button
                                                            className="requested"
                                                            onClick={() =>
                                                                this.checkSportName(
                                                                    this.state.profileChallenge.categoryId
                                                                )
                                                            }
                                                        >
                                                            Update
                      </button>
                                                        <button
                                                            className="requested"
                                                            onClick={() =>
                                                                this.viewChallengeScore(
                                                                    this.state.profileChallenge.categoryId
                                                                )
                                                            }
                                                        >
                                                            View Score
                      </button>
                                                    </div>
                                                ) : selectCourseForOther ? <div className="cp_profilediv_new">
                                                    <button
                                                        className="requested"
                                                        onClick={() =>
                                                            this.updateGolfByother(
                                                                this.state.profileChallenge.categoryId
                                                            )
                                                        }
                                                    >
                                                        update
                      </button>
                                                </div> : (
                                                        <div className="cp_profilediv_new">
                                                            <button
                                                                className="requested"
                                                                onClick={() =>
                                                                    this.viewChallengeScore(
                                                                        this.state.profileChallenge.categoryId
                                                                    )
                                                                }
                                                            >
                                                                View Score
                      </button>
                                                            <button className="requested">Chat</button>
                                                        </div>
                                                    )}
                                        </React.Fragment>
                                    ) : (
                                    ""
                                )}

                            {ended ? (
                                <React.Fragment>
                                    {this.state.profileChallenge.createdBy ===
                                        this.state.user.contactNo ? (
                                            <div className="cp_profilediv_new">
                                                <button
                                                    className="requested"
                                                    onClick={() =>
                                                        this.viewChallengeScore(
                                                            this.state.profileChallenge.categoryId
                                                        )
                                                    }
                                                >
                                                    View Score
                      </button>
                                            </div>
                                        ) : (
                                            <div className="cp_profilediv_new">
                                                <button
                                                    className="requested"
                                                    onClick={() =>
                                                        this.viewChallengeScore(
                                                            this.state.profileChallenge.categoryId
                                                        )
                                                    }
                                                >
                                                    View Score
                      </button>
                                                <button className="requested">Chat</button>
                                            </div>
                                        )}
                                </React.Fragment>
                            ) : (
                                    ""
                                )}
                        </div>
                    </div>

                    {this.state.challengeWinner ? (
                        <React.Fragment>
                            {this.state.profileChallenge.challengeType === "buddy" ? <React.Fragment>
                                {this.state.profileUsers.map((item) => {
                                    var disableData = false;
                                    if (
                                        item.contactNo ===
                                        this.state.profileChallenge.challengeWinnerId
                                    ) {
                                        disableData = true;
                                    }
                                    if (disableData === true) {
                                        if (item.profilePic === null) {
                                            switch (item.gender) {
                                                case "Male":
                                                    return (
                                                        <div
                                                            className="teambox winnerbox"
                                                            style={{
                                                                backgroundImage: "url(" + matchbg + ")",
                                                            }}
                                                        >
                                                            <ul className="winnerul">
                                                                <li className="winner_user">
                                                                    <img src={winnerlogo} alt="" />
                                                                    <span
                                                                        style={{
                                                                            backgroundImage: "url(" + maleIcon + ")",
                                                                        }}
                                                                    ></span>
                                                                </li>
                                                                <li>
                                                                    <label className="winnername">
                                                                        {item.name}
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <span className="winnertext">Winner</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    );

                                                default:
                                                    return (
                                                        <div
                                                            className="teambox winnerbox"
                                                            style={{
                                                                backgroundImage: "url(" + matchbg + ")",
                                                            }}
                                                        >
                                                            <ul className="winnerul">
                                                                <li className="winner_user">
                                                                    <img src={winnerlogo} alt="" />
                                                                    <span
                                                                        style={{
                                                                            backgroundImage: "url(" + femaleIcon + ")",
                                                                        }}
                                                                    ></span>
                                                                </li>
                                                                <li>
                                                                    <label className="winnername">
                                                                        {item.name}
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <span className="winnertext">Winner</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    );
                                            }
                                        } else {
                                            return (
                                                <div
                                                    className="teambox winnerbox"
                                                    style={{
                                                        backgroundImage: "url(" + matchbg + ")",
                                                    }}
                                                >
                                                    <ul className="winnerul">
                                                        <li className="winner_user">
                                                            <img src={winnerlogo} alt="" />
                                                            <span
                                                                style={{
                                                                    backgroundImage: "url(" + item.profilePic + ")",
                                                                }}
                                                            ></span>
                                                        </li>
                                                        <li>
                                                            <label className="winnername">{item.name}</label>
                                                        </li>
                                                        <li>
                                                            <span className="winnertext">Winner</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            );
                                        }
                                    }
                                })}
                            </React.Fragment> : <React.Fragment>
                                    {this.state.profileTeams.map((item) => {
                                        console.log(this.state.profileChallenge)

                                        var disableData = false;
                                        if (
                                            item.teamId ===
                                            this.state.profileChallenge.challengeWinnerId
                                        ) {
                                            disableData = true;
                                        }
                                        if (disableData === true) {
                                            if (item.teamIcon === null) {
                                                switch (item.gender) {
                                                    case "Male":
                                                        return (
                                                            <div
                                                                className="teambox winnerbox"
                                                                style={{
                                                                    backgroundImage: "url(" + matchbg + ")",
                                                                }}
                                                            >
                                                                <ul className="winnerul">
                                                                    <li className="winner_user">
                                                                        <img src={winnerlogo} alt="" />
                                                                        <span
                                                                            style={{
                                                                                backgroundImage: "url(" + maleIcon + ")",
                                                                            }}
                                                                        ></span>
                                                                    </li>
                                                                    <li>
                                                                        <label className="winnername">
                                                                            {item.name}
                                                                        </label>
                                                                    </li>
                                                                    <li>
                                                                        <span className="winnertext">Winner</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        );

                                                    default:
                                                        return (
                                                            <div
                                                                className="teambox winnerbox"
                                                                style={{
                                                                    backgroundImage: "url(" + matchbg + ")",
                                                                }}
                                                            >
                                                                <ul className="winnerul">
                                                                    <li className="winner_user">
                                                                        <img src={winnerlogo} alt="" />
                                                                        <span
                                                                            style={{
                                                                                backgroundImage: "url(" + femaleIcon + ")",
                                                                            }}
                                                                        ></span>
                                                                    </li>
                                                                    <li>
                                                                        <label className="winnername">
                                                                            {item.name}
                                                                        </label>
                                                                    </li>
                                                                    <li>
                                                                        <span className="winnertext">Winner</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        );
                                                }
                                            } else {
                                                return (
                                                    <div
                                                        className="teambox winnerbox"
                                                        style={{
                                                            backgroundImage: "url(" + matchbg + ")",
                                                        }}
                                                    >
                                                        <ul className="winnerul">
                                                            <li className="winner_user">
                                                                <img src={winnerlogo} alt="" />
                                                                <span
                                                                    style={{
                                                                        backgroundImage: "url(" + item.teamIcon + ")",
                                                                    }}
                                                                ></span>
                                                            </li>
                                                            <li>
                                                                <label className="winnername">{item.name}</label>
                                                            </li>
                                                            <li>
                                                                <span className="winnertext">Winner</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                );
                                            }
                                        }
                                    })}
                                </React.Fragment>}
                        </React.Fragment>
                    ) : (
                            ""
                        )}
                    {this.state.showCoursesList ? <div class="commanbox">
                        <h3 class="commanhead add_course_icon-d">Select Course
           <i class="fa fa-plus" style={{ cursor: 'pointer' }} onClick={() => this.AddCourse()}></i>
                        </h3>
                        <div class="cl_box_div select_courselist-d">
                            <ul class="cl_box_ul">
                                {this.state.courses.map(course => {
                                    return <li onClick={() => this.chooseCourse(course.courseId)}>
                                        <div class="cl_info_div select_course-d">
                                            <span class="cl_userbg course_locationbg" ></span>
                                            <p>Holes: {course.holeType}</p>
                                            <span class="course_location-d">{course.courseName} </span>
                                        </div>
                                    </li>
                                })}

                            </ul>
                        </div>
                    </div> : ''}
                    {this.state.showRounds ? <DemoRoundSelection challenge={this.state.profileChallenge} BaseRoundCall={this.childRoundCall} challenge={this.state.profileChallenge} /> : ''}
                    {this.state.ShowAddCourseForm ? <AddCourse BaseCourseCall={this.childCourseCall} challenge={this.state.profileChallenge} /> : ''}
                    {this.state.challengeProfileCenter ? <div className="teamprofile_div">
                        <div className="teambox">
                            <h3>Match Description</h3>
                            <ul className="">
                                <li>
                                    <span>Sports Name</span>
                                    <label>{this.state.profileChallenge.sportsName}</label>
                                </li>

                                <li>
                                    <span>Match On</span>
                                    <label>
                                        {this.state.profileChallenge.challengeDate},{" "}
                                        {this.state.profileChallenge.challengeTime}
                                    </label>
                                </li>

                                {eventShow ? (
                                    <li>
                                        <span>Event Name</span>
                                        <label>{this.state.profileChallenge.eventName}</label>
                                    </li>
                                ) : (
                                        ""
                                    )}

                                {distances ? (
                                    <li>
                                        <span>Distance</span>
                                        <label>
                                            {this.state.profileChallenge.distance}{" "}
                                            {this.state.profileChallenge.unit}
                                        </label>
                                    </li>
                                ) : (
                                        ""
                                    )}

                                {measures ? (
                                    <li>
                                        <span>Weight</span>
                                        <label>
                                            {this.state.profileChallenge.distance}{" "}
                                            {this.state.profileChallenge.unit}
                                        </label>
                                    </li>
                                ) : (
                                        ""
                                    )}

                                {overs ? (
                                    <li>
                                        <span>Overs</span>
                                        <label>
                                            {this.state.profileChallenge.distance}{" "}
                                            {this.state.profileChallenge.unit}
                                        </label>
                                    </li>
                                ) : (
                                        ""
                                    )}

                                <li>
                                    <span>Location</span>
                                    <label>{this.state.profileChallenge.location}</label>
                                </li>
                            </ul>
                        </div>

                        {(accepted = true) || (ended = true) ? (
                            <div className="teambox cpg_gallery">
                                <h3>Gallery</h3>
                                {/* onClick={() => this.setState({ isOpen: true, galImageUrl: md.url })} */}
                                {this.state.profileChallenge.medias ? this.state.profileChallenge.medias.map(md => {
                                    return <span onClick={() => this.ImageVeiw(md.url)}
                                        className="cpg_span"
                                        style={{ backgroundImage: "url(" + md.url + ")" }}
                                    >
                                        <a>
                                            <img src={cancelred} alt="" onClick={(e) => this.deleteGallPic(e)} id={md.url} />
                                        </a>
                                    </span>
                                }) : <span
                                    className="cpg_span"
                                    style={{ backgroundImage: "url(" + postgallery + ")" }}
                                >

                                    </span>}

                                <div className="cp_uploadimg_div">
                                    <label className="btn-bs-file cmnbutton">
                                        {this.state.uploading ? 'Uploading...' : 'Upload image or videos'}
                                        <input disabled={this.state.uploading} type="file" onChange={(e) => this.gallPicker(e)} accept="image/*,video/*" />
                                    </label>
                                    <p>
                                        {this.state.profileChallenge.medias ? null : 'There are no image and media for this challenge, upload now.'}
                                    </p>
                                </div>
                            </div>
                        ) : (
                                ""
                            )}
                    </div> : ''}
                </div>

            </section>
        );

        return (
            <React.Fragment>

                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.galImageUrl}

                        onCloseRequest={() => this.setState({ isOpen: false })}


                    />
                )}




                <Header />
                <div className="container containerbottom non-session-d">
                    <SectionLeft />
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        {this.state.isMiddlesection ? this.middleSection : ""}

                        {this.state.isChallenge_d101 ? (
                            <DemoChallenge_d101
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d101Vue ? (
                            <DemoChallenge_d101Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d102 ? (
                            <DemoChallenge_d102
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d102Vue ? (
                            <DemoChallenge_d102Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d103 ? (
                            <DemoChallenge_d103
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d103Vue ? (
                            <DemoChallenge_d103Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d104 ? (
                            <DemoChallenge_d104
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d104Vue ? (
                            <DemoChallenge_d104Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d105 ? (
                            <DemoChallenge_d105
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d105Vue ? (
                            <DemoChallenge_d105Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d106 ? (
                            <DemoChallenge_d106
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d106Vue ? (
                            <DemoChallenge_d106Vue
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isUpdate_d107_Rugby ? (
                            <DemoUpdate_d107_Rugby
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                                stateRefresh={() => this.stateRefresh()}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107_tennis ? (
                            <DemoUpdate_d107_tennis
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                                stateRefresh={() => this.stateRefresh()}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_tennis ? (
                            <Demo107Vue_tennis
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107_Carrom ? (
                            <DemoUpdate_d107_Carrom
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_badminton ? (
                            <Demo107Vue_badminton
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_tableTennis ? (
                            <DemoD107Vue_tableTenis
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107TableTennis ? (
                            <DemoUpdate_d107TableTennis
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_Rugby ? (
                            <Demo107Vue_Rugby
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_carrom ? (
                            <Demo107Vue_carrom
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107Squash ? (
                            <DemoUpdate_D107squash
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_d107Vue_squash ? (
                            <Demo107Vue_squash
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_D107Vue_kabaddi ? (
                            <Demo107Vue_kabaddi
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_D107vue_Archary ? (
                            <Demo107vue_Archary
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_D107Vue_vollyball ? (
                            <Demo107Vue_vollyball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d107Vue_basketball ? (
                            <Demo107Vue_basketball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107Basketball ? (
                            <DemoUpdated107Basketball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdateBadminton ? (
                            <DemoUpdateD107badminton
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdateArchery ? (
                            <DemoUpdate_D107Archary
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107Vollyball ? (
                            <DemoUpdate_D107Vollyball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_d107BeachVollyball ? (
                            <DemoUpdate_D107BeachVollyball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_d107Vue_BeachVollyball ? (
                            <Demo107Vue_BeachVollyball
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdate_D107_kabaddi ? (
                            <DemoUpdate_D107_kabaddi
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}

                            />
                        ) : (
                                ""
                            )}
                        {this.state.isUpdateD107Bs ? (
                            <DemoUpdate_d107bs
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallengeD107BsVue ? (
                            <Demo107Vue_bs
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isUpdate_d107Cricket ? (
                            <DemoUpdate_d107Cricket
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isChallenge_D107Vue_Cricket ? (
                            <Demo107Vue_Cricket
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}
                        {this.state.isChallenge_Demo107Vue_Golf ? (
                            <Demo107Vue_Golf
                                challenge={this.state.profileChallenge}
                                profileTeams={this.state.profileTeams}
                                profileUsers={this.state.profileUsers}
                            />
                        ) : (
                                ""
                            )}

                        {this.state.isUpdate_D107_Golf ? <DemoUpdate_d107Golf challenge={this.state.profileChallenge} profileTeams={this.state.profileTeams} profileUsers={this.state.profileUsers} /> : ''}

                    </div>
                    <SectionRight />
                </div>

            </React.Fragment>
        );
    }
}

export default DChallengeProfile;
