import React, { Component, createRef } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '..//..//ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import finallogo from '..//..//img/finallogo.png';
import NumericInput from 'react-numeric-input';
import broadcastbg from '..//..//img/broadcastbg.png';
import play from '..//..//img/play.png';
import $ from 'jquery';
import logo from '..//..//img/finallogo.png';
var score;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};
export default class Demo107Vue_Golf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tt5GolfScoreCard: {},
            golfScoreCards: [],
            teams: [],
            users: [],
            holes: [],
            holeType: 9,
            course: {},
        };
        this.getScore = this.getScore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem("result"));
    }
    getCourse() {
        const apiUrl = rshApi + "/global/get-course";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "courseId": that.props.challenge.courseId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({ course: result.data, holes: result.data.holes, holeType: result.data.holeType })
            });
    }

    getScore() {

        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {

                    }
                    else {
                        if (result.data.mode === 'buddy') {
                            that.setState({
                                golfScoreCards: result.data.score.tt5GolfScoreCard.golfScoreCards, tt5GolfScoreCard: result.data.score.tt5GolfScoreCard,
                                users: result.data.users
                            })
                        }
                        else {
                            that.setState({
                                golfScoreCards: result.data.score.tt5GolfScoreCard.golfScoreCards, tt5GolfScoreCard: result.data.score.tt5GolfScoreCard,
                                teams: result.data.teams
                            })
                        }

                    }
                });
        }
    }



    componentDidMount() {
        this.getCourse();
        this.getScore();
    }

    render() {
        const backLinks = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
        var parSum;
        var parValue = [];
        var parSum = this.state.holes.reduce((a, b) => a + b['par'], 0)
        var parTotal = parSum * 2;

        for (var i = 1; i <= this.props.challenge.courseRound; i++) {
            parValue.push(parSum);
        }
        return (
            <React.Fragment>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " >
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>Over All Total</th>
                                            <th>Current Round</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.golfScoreCards.map(gScore => {
                                            var GScoreRound = gScore.rounds.reduce(function (prev, curr) {

                                                {/* if (prev.saved === true) { */ }
                                                return prev.roundNo > curr.roundNo ? prev : curr;
                                                {/* } */ }
                                            });
                                            let userName;
                                            let userData = false;
                                            this.state.users.map(user => {
                                                if (user.contactNo === gScore.userId) {
                                                    userName = user.name;
                                                    userData = true;
                                                }
                                            });
                                            if (userData) {
                                                return <tr key={gScore.userId}>
                                                    <td>{userName}</td>
                                                    <td><input value={gScore.totalScore} class="inputsize-d" /></td>
                                                    <td><input value={GScoreRound.roundNo} class="inputsize-d" /></td>
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>

                                    <tbody>

                                        <tr>
                                            <td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>Holes</td>
                                            {this.state.holes.map(hole => {
                                                return <td><label class="holesno_golf-d">{hole.holeNo}</label></td>
                                            })}


                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>PAR</td>
                                            {this.state.holes.map(hole => {
                                                return <td>{hole.par}</td>
                                            })}


                                        </tr>

                                        <React.Fragment>
                                            {this.state.golfScoreCards.map(gScore => {
                                                var valueTd = [];
                                                var GScoreRound = gScore.rounds.reduce(function (prev, curr) {
                                                    {/* if (prev.saved === true) { */ }
                                                    return prev.roundNo > curr.roundNo ? prev : curr;
                                                    {/* } */ }
                                                });
                                                GScoreRound.scores.map(score => {
                                                    valueTd.push(<td><input value={score.value} class="inputsize-d" /></td>);

                                                })
                                                let userName;
                                                let userData = false;
                                                this.state.users.map(user => {
                                                    if (user.contactNo === gScore.userId) {
                                                        userName = user.name;
                                                        userData = true;
                                                    }
                                                });
                                                return <tr><td style={{ backgroundColor: '#2cae4a', color: '#fff', padding: '0px 40px', borderBottom: '1px solid #ffffff' }}>{userName}</td>

                                                    {valueTd}
                                                </tr>


                                            })}
                                        </React.Fragment>








                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {parValue.map((v, index) => {
                                                return <th>Round {index + 1}</th>
                                            })}

                                            <th>Total</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        <tr>
                                            <td>PAR</td>
                                            {parValue.map(v => {
                                                return <td>{v}</td>
                                            })}
                                            <td>{parTotal}</td>
                                            <td>-</td>

                                        </tr>

                                        <React.Fragment>
                                            {this.state.golfScoreCards.map(gScore => {
                                                var valueTd = [];
                                                if (gScore.rounds.length === parValue.length) {
                                                    gScore.rounds.map(round => {
                                                        valueTd.push(<td>{round.total}</td>);
                                                    });
                                                }
                                                else {
                                                    gScore.rounds.map(round => {
                                                        valueTd.push(<td>{round.total}</td>);
                                                    });
                                                    let len = parValue.length - gScore.rounds.length;
                                                    for (let i = 0; i < len; i++) {
                                                        valueTd.push(<td>0</td>);
                                                    }
                                                }


                                                let userName;
                                                let userData = false;
                                                this.state.users.map(user => {
                                                    if (user.contactNo === gScore.userId) {
                                                        userName = user.name;
                                                        userData = true;
                                                    }
                                                });
                                                return <tr><td >{userName}</td>

                                                    {valueTd}
                                                    <td>{gScore.round1Total + gScore.round2Total + gScore.round3Total + gScore.round4Total}</td>
                                                    <td>{gScore.totalScore}</td>

                                                </tr>


                                            })}
                                        </React.Fragment>
                                        {/* <tr>
                                            <td>Player 1</td>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>2</td>
                                            <td>3</td>
                                        </tr> */}




                                    </tbody>
                                </table>
                            </div>
                        </div>



                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backbtn fl">Back</a>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
