import React, { Component } from 'react'
import maleIcon from "./img/male.png";
import femaleIcon from "./img/female.png";
import matchbg from "./img/matchbg.png";
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var storeIds = null, storeTypes = null;

export default class Awards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAwardButtons: false,
            mom: true,
            bbat: true,
            bbow: true,
            bf: true,
            allCompleted: false,
            openAwardsModal: false,
            confirmAwardModalOpen: false,
            both: false,
            showBat: false,
            showBowl: false,
            showFielder: false,
            storeBatsman: [],
            storeBowlers: [],
            storeFielders: [],
            togleButton: true,
            togleButton1: true
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.closeAwardsModal = this.closeAwardsModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openAwardsModal(type) {
        this.setState({ openAwardsModal: true })

        if (type === 'both') {
            this.setState({ both: true, showBat: true, showBowl: true, showFielder: false })
        }
        else if (type === 'bat') {
            this.setState({ both: true, showBat: true, showBowl: false, showFielder: false })
        }
        else if (type === 'bowl') {
            this.setState({ both: true, showBat: false, showBowl: true, showFielder: false })
        }
        else {
            this.setState({ both: true, showBat: false, showBowl: false, showFielder: true })
        }
    }

    closeAwardsModal() {
        this.setState({ openAwardsModal: false, togleButton: true, togleButton1: true })
    }

    confirmAwardModalOpen(takeIds, takeType) {
        this.setState({ confirmAwardModalOpen: true })
        storeIds = takeIds;
        storeTypes = takeType;
    }

    closeModal() {
        this.setState({ confirmAwardModalOpen: false, togleButton: true, togleButton1: true });
    }

    giveAwards() {
        const apiUrl = rshApi + "/tournaments/declare-best";
        const that = this;
        let data = {
            "type": storeTypes,
            "tournamentId": that.props.tournament.tournamentId,
            "userId": storeIds
        }

        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ openAwardsModal: false, confirmAwardModalOpen: false })

                that.props.pAwardCB(data)
                if (data.type === 'batsman') {
                    that.setState({ bbat: false })
                }
                if (data.type === 'bowler') {
                    that.setState({ bbow: false })
                }
                if (data.type === 'fielder') {
                    that.setState({ bf: false })
                }
                if (data.type === 'mos') {
                    that.setState({ mom: false })
                }
            });
    }

    getStats() {
        const apiUrl = rshApi + "/tournaments/get-mos-stats";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                if (result.data.batting) {
                    that.setState({ storeBatsman: result.data.batting })
                }
                if (result.data.bowling) {
                    that.setState({ storeBowlers: result.data.bowling })
                }
                if (result.data.users) {
                    that.setState({ storeFielders: result.data.users })
                }
            });
    }

    componentDidMount() {
        if (this.props.tournament.sportsName === "Cricket" && this.props.tournament.tournamentWinnerId && this.state.user.contactNo === this.props.tournament.createdBy) {
            this.setState({ showAwardButtons: true })

            if (this.props.tournament.manOfTheSeries) {
                this.setState({ mom: false })
            }
            if (this.props.tournament.bestBatsman) {
                this.setState({ bbat: false })
            }
            if (this.props.tournament.bestBowler) {
                this.setState({ bbow: false })
            }
            if (this.props.tournament.bestFielder) {
                this.setState({ bf: false })
            }
            if (this.props.tournament.manOfTheSeries && this.props.tournament.bestBatsman && this.props.tournament.bestBowler && this.props.tournament.bestFielder) {
                this.setState({ allCompleted: true })
            }

            this.getStats();
        }

        else {
            this.setState({ showAwardButtons: false })
        }
    }

    render() {
        this.props.profileUsers.map(p => {
            let name, gender, pic;
            if (this.props.tournament.manOfTheSeries === p.contactNo) {
                name = p.name;
                gender = p.gender;
                pic = p.profilePic;
                this.mom = <MySubComponent name={name} gender={gender} pic={pic} award={'Man Of The Series'} />
            }
            if (this.props.tournament.bestBatsman === p.contactNo) {
                name = p.name;
                gender = p.gender;
                pic = p.profilePic;
                this.bbat = <MySubComponent name={name} gender={gender} pic={pic} award={'Best Batsman'} />
            }
            if (this.props.tournament.bestBowler === p.contactNo) {
                name = p.name;
                gender = p.gender;
                pic = p.profilePic;
                this.bbow = <MySubComponent name={name} gender={gender} pic={pic} award={'Best Bowler'} />
            }
            if (this.props.tournament.bestFielder === p.contactNo) {
                name = p.name;
                gender = p.gender;
                pic = p.profilePic;
                this.bf = <MySubComponent name={name} gender={gender} pic={pic} award={'Best Fielder'} />
            }
        })

        return (
            <>
                <Modal
                    size="md"
                    show={this.state.confirmAwardModalOpen}
                    animation={false}
                    onHide={() => this.closeModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeModal()}>&times;</button>
                        <Modal.Title>
                            Confirm Award Selection
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <p>Confirm to Proceed.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cmnbutton" onClick={() => this.closeModal()}>Cancel</button>
                            <button onClick={() => this.giveAwards()} type="button" className="cmnbutton">Confirm</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.openAwardsModal}
                    onHide={() => this.closeAwardsModal()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={() => this.closeAwardsModal()} type="button" className="close">X</button>
                            {(() => {
                                if (this.state.both && this.state.showBat && !this.state.showBowl && !this.state.showFielder) {
                                    return <h4 className="modal-title">Declare Best Batsman</h4>
                                }
                                else if (this.state.both && this.state.showBowl && !this.state.showBat && !this.state.showFielder) {
                                    return <h4 className="modal-title">Declare Best Bowler</h4>
                                }
                                else if (this.state.both && !this.state.showBat && !this.state.showBowl && this.state.showFielder) {
                                    return <h4 className="modal-title">Declare Best Fielder</h4>
                                }
                                else if (this.state.both && this.state.showBat && this.state.showBowl && !this.state.showFielder) {
                                    return <h4 className="modal-title">Declare Player Of The Series</h4>
                                }
                            })()}
                        </div>
                        <div className="modal-body">
                            {(() => {
                                if (this.state.both && this.state.showBat && !this.state.showBowl && !this.state.showFielder) {
                                    return <>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    <div className="table-responsive scoretable">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'rgb(83 175 74)', color: '#fff' }} onClick={() => this.setState({ togleButton: !this.state.togleButton })}>
                                                                    <th>Batsman
                                                                    <span style={{ float: 'right', fontWeight: '800', fontSize: '20px' }}>{this.state.togleButton ? '✚' : '━'}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div id="collapse1" className="panel-collapse collapse in">
                                                        <div className="panelnew">
                                                            <div className="table-responsive scoretable">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Batsman</th>
                                                                            <th>R</th>
                                                                            <th>B</th>
                                                                            <th>4s</th>
                                                                            <th>6s</th>
                                                                            <th>SR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.storeBatsman.map(bats => {
                                                                            let name = null;
                                                                            this.props.profileUsers.map(users => {
                                                                                if (users.contactNo === bats.userId) {
                                                                                    name = users.name;
                                                                                }
                                                                            })
                                                                            return <>
                                                                                <tr onClick={() => this.confirmAwardModalOpen(bats.userId, 'batsman')}>
                                                                                    <td>{name} [Team Name: {bats.teamName}]</td>
                                                                                    <td>{bats.runs}</td>
                                                                                    <td>{bats.balls}</td>
                                                                                    <td>{bats.fours}</td>
                                                                                    <td>{bats.sixes}</td>
                                                                                    <td>{bats.strikeRate}</td>
                                                                                </tr>
                                                                            </>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </>
                                }
                                else if (this.state.both && this.state.showBowl && !this.state.showBat && !this.state.showFielder) {
                                    return <>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    <div className="table-responsive scoretable">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'rgb(83 175 74)', color: '#fff' }} onClick={() => this.setState({ togleButton: !this.state.togleButton })}>
                                                                    <th>Bowler
                                                                    <span style={{ float: 'right', fontWeight: '800', fontSize: '20px' }}>{this.state.togleButton ? '✚' : '━'}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div id="collapse1" className="panel-collapse collapse in">
                                                        <div className="panelnew">
                                                            <div className="table-responsive scoretable">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Bowler</th>
                                                                            <th>R</th>
                                                                            <th>B</th>
                                                                            <th>W</th>
                                                                            <th>M</th>
                                                                            <th>ER</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.storeBowlers.map(bowl => {
                                                                            let name = null;
                                                                            this.props.profileUsers.map(users => {
                                                                                if (users.contactNo === bowl.userId) {
                                                                                    name = users.name;
                                                                                }
                                                                            })
                                                                            return <>
                                                                                <tr onClick={() => this.confirmAwardModalOpen(bowl.userId, 'bowler')}>
                                                                                    <td>{name} [Team Name: {bowl.teamName}]</td>
                                                                                    <td>{bowl.runs}</td>
                                                                                    <td>{bowl.balls}</td>
                                                                                    <td>{bowl.wicket}</td>
                                                                                    <td>{bowl.maiden}</td>
                                                                                    <td>{bowl.economyRate}</td>
                                                                                </tr>
                                                                            </>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </>
                                }
                                else if (this.state.both && !this.state.showBat && !this.state.showBowl && this.state.showFielder) {
                                    return <>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    <div className="table-responsive scoretable">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'rgb(83 175 74)', color: '#fff' }} onClick={() => this.setState({ togleButton: !this.state.togleButton })}>
                                                                    <th>Fielder <span style={{ float: 'right', fontWeight: '800', fontSize: '20px' }}>{this.state.togleButton ? '✚' : '━'}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div id="collapse1" className="panel-collapse collapse in">
                                                        <div className="panelnew">
                                                            <div className="table-responsive scoretable">
                                                                <table className="table table-hover">
                                                                    <tbody>
                                                                        {this.state.storeFielders.map(fild => {
                                                                            return <>
                                                                                <tr onClick={() => this.confirmAwardModalOpen(fild.contactNo, 'fielder')}>
                                                                                    <th>{fild.name}</th>
                                                                                </tr>
                                                                            </>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </>
                                }
                                else if (this.state.both && this.state.showBat && this.state.showBowl && !this.state.showFielder) {
                                    return <>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    <div className="table-responsive scoretable">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'rgb(83 175 74)', color: '#fff' }} onClick={() => this.setState({ togleButton: !this.state.togleButton })}>
                                                                    <th>Batsman <span style={{ float: 'right', fontWeight: '800', fontSize: '20px' }}>{this.state.togleButton ? '✚' : '━'}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div id="collapse1" className="panel-collapse collapse in">
                                                        <div className="panelnew">
                                                            <div className="table-responsive scoretable">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Batsman</th>
                                                                            <th>R</th>
                                                                            <th>B</th>
                                                                            <th>4s</th>
                                                                            <th>6s</th>
                                                                            <th>SR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.storeBatsman.map(bats => {
                                                                            let name = null;
                                                                            this.props.profileUsers.map(users => {
                                                                                if (users.contactNo === bats.userId) {
                                                                                    name = users.name;
                                                                                }
                                                                            })
                                                                            return <>
                                                                                <tr onClick={() => this.confirmAwardModalOpen(bats.userId, 'mos')}>
                                                                                    <td>{name} [Team Name: {bats.teamName}]</td>
                                                                                    <td>{bats.runs}</td>
                                                                                    <td>{bats.balls}</td>
                                                                                    <td>{bats.fours}</td>
                                                                                    <td>{bats.sixes}</td>
                                                                                    <td>{bats.strikeRate}</td>
                                                                                </tr>
                                                                            </>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>

                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                                    <div className="table-responsive scoretable">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr style={{ backgroundColor: 'rgb(83 175 74)', color: '#fff' }} onClick={() => this.setState({ togleButton1: !this.state.togleButton1 })}>
                                                                    <th>Bowler <span style={{ float: 'right', fontWeight: '800', fontSize: '20px' }}>{this.state.togleButton1 ? '✚' : '━'}</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1">
                                                    <div id="collapse1" className="panel-collapse collapse in">
                                                        <div className="panelnew">
                                                            <div className="table-responsive scoretable">
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Bowler</th>
                                                                            <th>R</th>
                                                                            <th>B</th>
                                                                            <th>W</th>
                                                                            <th>M</th>
                                                                            <th>ER</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.storeBowlers.map(bowl => {
                                                                            let name = null;
                                                                            this.props.profileUsers.map(users => {
                                                                                if (users.contactNo === bowl.userId) {
                                                                                    name = users.name;
                                                                                }
                                                                            })
                                                                            return <>
                                                                                <tr onClick={() => this.confirmAwardModalOpen(bowl.userId, 'mos')}>
                                                                                    <td>{name} [Team Name: {bowl.teamName}]</td>
                                                                                    <td>{bowl.runs}</td>
                                                                                    <td>{bowl.balls}</td>
                                                                                    <td>{bowl.wicket}</td>
                                                                                    <td>{bowl.maiden}</td>
                                                                                    <td>{bowl.economyRate}</td>
                                                                                </tr>
                                                                            </>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </>
                                }
                            })()}

                        </div>
                    </div>
                </Modal>


                {this.state.showAwardButtons ? <div className="award-main">
                    <p>Award Distribution</p>
                    {this.state.mom ? <button onClick={() => this.openAwardsModal('both')} className="award_btn">Player Of The Series</button> : this.state.allCompleted ? null : <button disabled className="award_btn greyout">Player Of The Series</button>}
                    {this.state.bbat ? <button onClick={() => this.openAwardsModal('bat')} className="award_btn">Best Batsman</button> : this.state.allCompleted ? null : <button disabled className="award_btn greyout">Best Batsman</button>}
                    {this.state.bbow ? <button onClick={() => this.openAwardsModal('bowl')} className="award_btn">Best Bowler</button> : this.state.allCompleted ? null : <button disabled className="award_btn greyout">Best Bowler</button>}
                    {this.state.bf ? <button onClick={() => this.openAwardsModal('fielder')} className="award_btn">Best Fielder</button> : this.state.allCompleted ? null : <button disabled className="award_btn greyout">Best Fielder</button>}

                    {!this.state.mom ? this.mom : null}
                    {!this.state.bbat ? this.bbat : null}
                    {!this.state.bbow ? this.bbow : null}
                    {!this.state.bf ? this.bf : null}
                </div> : ''}
            </>
        )
    }
}

const MySubComponent = (props) => {
    const { award, name, gender, pic } = props;
    return <div className="teambox winnerbox" style={{ backgroundImage: "url(" + matchbg + ")", }}>
        <ul className="winnerul">
            <li>
                <label className="winnername">{award}</label>
            </li>
            <li className="winner_user">
                <span style={{ backgroundImage: pic ? "url(" + pic + ")" : gender === 'Male' ? "url(" + maleIcon + ")" : "url(" + femaleIcon + ")", }}></span>
            </li>
            <li>
                <span className="winnertext">{name}</span>
            </li>
        </ul>
    </div>
}
