import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DatePicker from "react-datepicker";
import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
import * as myConstclass from '../ConstFile';
var scoreCardD104 = [];
var d104ScoreCard = {}, score, tt5ScoreCard = {};
const challengeAction = "Completed";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var maxWeights = [];
var cricketApi = require("../cricketApi.js");

export default class Tournaments_d104_tt5 extends Component {
    constructor(props) {
        super(props);
        this.props.challenge.accepted.map(x => {
            scoreCardD104.push({ disqualified: false, rank: 0, 'userId': x, score: "00:00:00.000", 'points': 0.0, 'winner': false });
        });
        this.state = {
            marathonScoreCard: scoreCardD104,
            playerPoint: '',
            errorShow: false,
            maxPoint: [],
            checked: true,
            timeInput: 0,
            hr: 0,
            min: 0,
            sec: 0,
            ms: 0,
            playerTime: '',
            showDateTime: false,
            tDateTime: new Date(),
            dateDate: myConstclass.getDateYYYYmmdd(new Date()),
            dateTime: myConstclass.getTimeFromDate(new Date()),
        };
        this.changeTime = this.changeTime.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.getScore = this.getScore.bind(this);


    }
    increment(type) {
        if (type === 'hr') {
            if (this.state.hr < 99) {
                this.setState({ hr: this.state.hr + 1 })
            }
        }
        if (type === 'min') {

            if (this.state.min < 59) {
                this.setState({ min: this.state.min + 1 })
            }
        }
        if (type === 'sec') {

            if (this.state.sec < 59) {
                this.setState({ sec: this.state.sec + 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms < 999) {
                this.setState({ ms: this.state.ms + 1 })
            }
        }
    }
    decrement(type) {
        if (type === 'hr') {
            if (this.state.hr > 0) {
                this.setState({ hr: this.state.hr - 1 })
            }
        }
        if (type === 'min') {
            if (this.state.min > 0) {
                this.setState({ min: this.state.min - 1 })
            }
        }
        if (type === 'sec') {
            if (this.state.sec > 0) {
                this.setState({ sec: this.state.sec - 1 })
            }
        }
        if (type === 'ms') {
            if (this.state.ms > 0) {
                this.setState({ ms: this.state.ms - 1 })
            }
        }
    }
    changeTime(e) {

        if (e.target.name === 'hr') {
            if (parseInt(e.target.value) > 99) {
                this.setState({ [e.target.name]: 99 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'min') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'sec') {
            if (parseInt(e.target.value) > 59) {
                this.setState({ [e.target.name]: 59 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
        if (e.target.name === 'ms') {
            if (parseInt(e.target.value) > 999) {
                this.setState({ [e.target.name]: 999 });
            }
            else {
                this.setState({ [e.target.name]: parseInt(e.target.value) });
            }
        }
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    doneMatch() {
        this.setState({ winnerModalOpen: false });
        var winner;
        if (this.state.selectWinner === undefined) {
            winner = selectedWinner;
        }
        else {
            winner = this.state.selectWinner;
        }
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var tournament = this.props.challenge;
        delete tournament.id;
        tournament.status = challengeAction;
        tournament.modifiedOn = timpstampData;
        tournament.tournamentWinnerId = winner;
        let that = this;
        const apiUrl = rshApi + "/demo-tournaments/update-tournament";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournament": tournament,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

            });

        const apiUrl1 = rshApi + "tournaments/winner-feeds";
        fetch(apiUrl1, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": tournament.tournamentId,
                    "matchId": tournament.tournamentId,
                    "winnerId": winner,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                window.location = "/dtournamentprofile/index.html?id=" + that.props.challenge.tournamentId;
            }).catch(function (error) {
                console.log(error)
            });
    }
    disqualify() {
        $("#myModal .close").click();
        let that = this;
        scoreCardD104.map((point, index) => {
            if (index === this.state.setIndex) {
                point.disqualified = true;
                point.points = 0;
            }
        });

        $("#myModal .close").click();
        this.setState({ marathonScoreCard: scoreCardD104 });
        tt5ScoreCard = {
            marathonScoreCard: scoreCardD104
        }
        score = {
            tournamentId: that.props.challenge.tournamentId,
            matchId: that.props.challenge.tournamentId,
            tt5ScoreCard: tt5ScoreCard
        }
        let updated = DUpdateTournamentsScore(score);
        updated.then(result => {
            console.log(result)
        }).catch(error => {
            console.log(error)
        })



    }
    saveIntial = () => {

        let tt5ScoreCard = {
            date: this.state.dateDate,
            marathonScoreCard: this.state.marathonScoreCard,
            matchId: this.props.challenge.tournamentId,
            time: this.state.dateTime
        }
        score = {
            tournamentId: this.props.challenge.tournamentId,
            matchId: this.props.challenge.tournamentId,
            tt5ScoreCard: tt5ScoreCard
        }
        let updated = DUpdateTournamentsScore(score);
        let that = this;
        updated.then(result => {
            that.setState({ showDateTime: false, marathonScoreCard: this.state.marathonScoreCard });
        }).catch(error => {
            console.log(error)
        })

        scoreCardD104 = this.state.marathonScoreCard;
        console.log(score);
        // this.getScore();

    }
    savePoint() {
        var hr, min, sec, ms;
        if (this.state.hr < 10) {
            hr = '0' + this.state.hr;
        }
        else {
            hr = this.state.hr;
        }
        if (this.state.min < 10) {
            min = '0' + this.state.min;
        }
        else {
            min = this.state.min;
        }
        if (this.state.sec < 10) {
            sec = '0' + this.state.sec;
        }
        else {
            sec = this.state.sec;
        }
        if (this.state.ms < 10) {
            ms = '00' + this.state.ms;
        }
        else if ((this.state.ms >= 10) && (this.state.ms < 100)) {
            ms = '0' + this.state.ms;
        }
        else {
            ms = this.state.ms;
        }
        // this.setState({ playerTime: hr + ':' + min + ':' + sec + '.' + ms })
        var playerTime = hr + ':' + min + ':' + sec + '.' + ms;
        var unixTimeZero = Date.parse('01 Jan 1970 ' + playerTime + ' UTC');
        let that = this;
        scoreCardD104.map((point, index) => {
            if (index === this.state.setIndex) {
                point.points = unixTimeZero;
                point.disqualified = false;
            }
        });

        $("#myModal .close").click();
        this.setState({ marathonScoreCard: scoreCardD104 });
        tt5ScoreCard = {
            marathonScoreCard: scoreCardD104
        }
        score = {
            tournamentId: that.props.challenge.tournamentId,
            matchId: that.props.challenge.tournamentId,
            tt5ScoreCard: tt5ScoreCard
        }
        console.log(score)
        let updated = DUpdateTournamentsScore(score);
        updated.then(result => {
            console.log(result)
        }).catch(error => {
            console.log(error)
        })
    }

    endEvent() {

        // var showFillModal = false;
        // this.state.marathonScoreCard.map(point => {
        //     if (point.points < 1 && point.disqualified === false) {

        //         showFillModal = true;

        //     }
        // });
        // if (showFillModal) {
        //     $('#myModalOpen').click();
        // }
        // else {

        //     //  console.log(this.state.scoreCardD103s);
        //     maxWeights = [];
        //     const max = this.state.marathonScoreCard.reduce((prev, current) => (prev.points < current.points) ? prev : current)
        //     this.state.marathonScoreCard.map((x, index) => {
        //         if (x.points === max.points) {
        //             maxWeights.push(x);
        //         }
        //     });
        //     console.log(maxWeights);
        //     this.setState({ maxPoint: maxWeights });

        //     $('#myModalOpen1').click();
        // }

    }
    getScore() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.challenge.tournamentId,
                        "matchId": that.props.challenge.tournamentId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {
                        that.setState({ showDateTime: true });
                    }
                    else {
                        if (result.data.score.tt5ScoreCard.marathonScoreCard) {
                            that.setState({ marathonScoreCard: result.data.score.tt5ScoreCard.marathonScoreCard });
                            scoreCardD104 = result.data.score.tt5ScoreCard.marathonScoreCard;
                        }
                    }

                });
        }
    }
    componentDidMount() {
        this.getScore();
    }
    handleClose = () => {

        this.setState({ showDateTime: false });
        this.props.parrentTTcallback();

    }
    setTdateTime = (date) => {

        this.setState({ tDateTime: date })
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let dateTime = myConstclass.getTimeFromDate(date);
        this.setState({
            dateDate: dateFormate, dateTime: dateTime
        });
    };
    render() {

        return (
            <React.Fragment>

                <Modal show={this.state.showDateTime} animation={false} onHide={this.handleClose} dialogClassName="reslutmodel">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={this.handleClose}>&times;</button>
                            <h4 className="modal-title">Please Select Date Time of Tournament</h4>
                        </div>
                        <div className="modal-body">

                            <div class="createteamform tournamentform" style={{ height: '230px' }}>
                                <ul>
                                    <li>
                                        <div class="commaninputdiv ccdatetime1" style={{ borderBottom: '1px solid #2cae4a' }}>
                                            <DatePicker
                                                selected={this.state.tDateTime}
                                                onChange={this.setTdateTime}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="modal-footer table_actions">
                                <a onClick={this.handleClose} className="cmnbutton editbtn">Cancel</a>
                                <button onClick={this.saveIntial} className="cmnbutton btnsubmitright" type="button"  >Save</button>
                            </div>


                        </div>
                    </div>

                </Modal>


                <div id="myModal1" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Enter Point</h4>
                            </div>
                            <div className="modal-body">
                                <center>
                                    <h5>Please Fill Score Point</h5></center>
                            </div>
                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen" data-toggle="modal" data-target="#myModal1" />

                <div id="myModal2" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Challange Winner</h4>
                            </div>
                            <div className="modal-body">
                                <div className="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul className="resultul">
                                    {this.state.maxPoint.map((weight, index) => {

                                        var userName, userPic, point;
                                        selectedWinner = weight.userId;
                                        this.props.profileUsers.map(user => {

                                            if (weight.userId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic;
                                            }
                                        })
                                        point = cricketApi.msToTime(weight.points);

                                        return <li style={{ 'display': 'flex' }}>
                                            <a className="winnerselect">
                                                <span className="resuserimg" style={{ backgroundImage: "url(" + userPic + ")" }}></span>
                                                <div className="resinfo">
                                                    <label>{userName} </label>
                                                    <small>Score:{point} </small>
                                                </div>

                                                <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                    {/* <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} /> */}
                                                    <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                    <label htmlFor={index}></label>
                                                </div>
                                            </a>
                                        </li>
                                    })}

                                </ul>

                            </div>

                            <div className="modal-footer table_actions">
                                <a data-dismiss="modal" className="cmnbutton editbtn">Edit</a>
                                <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />


                <div id="myModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Time Picker</h4>
                            </div>
                            <div className="modal-body">

                                <div className="master">
                                    <div className="number">
                                        <span className="minus" name="hr" onClick={() => this.decrement('hr')}>-</span>
                                        <input class="d104Input" type="number" min="0" max="200" name="hr" onChange={this.changeTime} value={this.state.hr} maxLength={2} />
                                        <span className="plus" name="hr" onClick={() => this.increment('hr')}>+</span>
                                        <label className="heading">Hr</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="min" onClick={() => this.decrement('min')}>-</span>
                                        <input class="d104Input" min="0" max="59" type="number" name="min" onChange={this.changeTime} value={this.state.min} maxLength={2} />
                                        <span className="plus" name="min" onClick={() => this.increment('min')}>+</span>
                                        <label className="heading">min</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="sec" onClick={() => this.decrement('sec')}>-</span>
                                        <input class="d104Input" min="0" max="59" type="number" name="sec" onChange={this.changeTime} value={this.state.sec} maxLength={2} />
                                        <span className="plus" name="sec" onClick={() => this.increment('sec')}>+</span>
                                        <label className="heading">sec</label>
                                    </div>
                                    <div className="number">
                                        <span className="minus" name="ms" onClick={() => this.decrement('ms')}>-</span>
                                        <input class="d104Input" min="0" max="999" type="number" name="ms" onChange={this.changeTime} value={this.state.ms} maxLength={2} />
                                        <span className="plus" name="ms" onClick={() => this.increment('ms')}>+</span>
                                        <label className="heading">ms</label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer table_actions">
                                <a className="cmnbutton" onClick={() => this.disqualify()}>disqualify</a>

                                <button className="cmnbutton backbtn fr" type="button" data-dismiss="modal" >cancel</button>
                                <button className="cmnbutton btnsubmitright" type="button" onClick={() => this.savePoint()} >Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video">
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul className="winnerdropdown_ul">
                            {this.state.marathonScoreCard.map((accepted, index) => {
                                var name, contactNo, point, pointColor;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;
                                        point = cricketApi.msToTime(point);
                                    }
                                    if (accepted.disqualified === true) {
                                        pointColor = { color: 'red' };
                                        point = 'disqualify';
                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" readOnly style={pointColor} value={point} data-toggle="modal" data-target="#myModal" onClick={() => this.setState({ setIndex: index, errorShow: false, hr: 0, min: 0, sec: 0, ms: 0 })} />
                                </li>
                            })}
                        </ul>
                        <div className="table_actions winner_actions">
                            <button className="cmnbutton backbtn fl">Back</button>
                            <a className="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

