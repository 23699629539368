import React, { Component } from 'react';
import downloadAppIcon from './img/download-app.png';

class DownloadAppPlatform extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="custom-ui" style={{ padding: '0', borderRadius: '0' }}>
                <img src={downloadAppIcon} alt="Workplace" useMap="#workmap" className="img-responsive" />
                <map name="workmap">
                    <area shape="rect" coords="77,455,145,476" alt="Android" href="https://play.google.com/store/apps/details?id=com.sportsapp.ssp" target="_blank" />
                    <area shape="rect" coords="161,454,231,475" alt="iOs" href="https://apps.apple.com/app/id1437392923" target="_blank" />
                </map>
            </div>
        );
    }
}

export default DownloadAppPlatform;
