import React, { Component } from "react";
import team from "./img/team.png";
import NoTeam from "./img/mytournament.svg";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
import filter from './img/filter.png';
import DemoTeamprofile from './DemoTeamprofile';
import { Link } from "react-router-dom";
import { } from 'prismic-reactjs';
import Header from './Header';
import SectionLeft from './SectionLeft';
import SectionRight from './SectionRight';
import explaner2 from "./img/playdiator-explaner-complete.jpg";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

class TeamsDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            showTeamProfile: false,
            teamId: '',
            showTeamList: true,
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    openTeamProfile = (teamId) => {
        this.setState({ showTeamProfile: true, teamId: teamId, showTeamList: false })
    }
    loadMore = () => {
        const that = this;
        fetch(userApi + "demo-teams/get-teams", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.state.user.contactNo,
                    type: 'both',
                    minimul: true
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    teams: result.data,
                });
            });
    };
    componentDidMount() {
        this.loadMore();
    }
    getCreatedTime = (createdOn) => {
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(createdOn);
        return formattedTimestamp;
    }
    render() {

        return (
            <>
                <Header />
                <div className="container containerbottom non-session-d">
                    {this.state.user ? <SectionLeft /> : ''}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            {this.state.user ? '' :
                                <React.Fragment>
                                    <div className="postdata">
                                        <iframe allowfullscreen="yes" style={{ 'width': '100%', 'height': '360px' }} className="youtube-player" id="player" type="text/html" src="https://www.youtube.com/embed/nqdZs6m1IDo?autoplay=1&mute=1">&lt;br /&gt;</iframe>

                                    </div>
                                    <img className="explanerImg" src={explaner2} />
                                    <div className="center-d">
                                        <div className="downloaddiv-d1">
                                            <button onClick={() => this.openApp()} className="download-d1" type="button">Download App</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }



                            {this.state.showTeamList ? <div className="commanbox" id="profilebuddies">

                                <h3 className="commanhead">Teams</h3>
                                <ul className="challangebutton">
                                    <li> <Link to={'/createdemoteam'} className="cb_link">Create Demo Team</Link></li>
                                    <li>
                                        <div className="dropdown ct_drop">
                                            {/* <Link to={'#'} className=" dropdown-toggle" type="button" data-toggle="dropdown">
                                <img src={filter} alt="" />
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link onClick={() => this.changeFilter('Both')} to={'#'}>Both</Link></li>
                                <li><Link onClick={() => this.changeFilter('Thrown')} to={'#'}>Thrown</Link></li>
                                <li><Link onClick={() => this.changeFilter('Received')} to={'#'}>Received</Link></li>
                            </ul> */}
                                        </div>
                                    </li>
                                </ul>
                                <div className="cl_box_div">
                                    <ul class="at_ul">
                                        {this.state.teams.length ? this.state.teams.map(x => {
                                            return (() => {
                                                if (x.teamIcon === null) {
                                                    return (
                                                        <li>
                                                            <Link to={"/demoTeamProfilePage/index.html?id=" + x.teamId} class="at_link">
                                                                <span class="at_teamimg" style={{
                                                                    backgroundImage: "url(" + team + ")"
                                                                }}></span>
                                                                <label class="at_name">{x.teamName}</label>
                                                                <p class="at_sportsname">{x.sportsName}</p>
                                                                <p class="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                                <div class="at_teamowner">
                                                                    <span class="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                    {this.state.user.contactNo === x.createdBy ? <span class="at_owner">Owner</span> : <span class="at_owner">Team Member</span>}
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <li>
                                                            <Link to={"/demoTeamProfilePage/index.html?id=" + x.teamId} class="at_link">
                                                                <span class="at_teamimg" style={{
                                                                    backgroundImage: "url(" + x.teamIcon + ")"
                                                                }}></span>
                                                                <label class="at_name">{x.teamName}</label>
                                                                <p class="at_sportsname">{x.sportsName}</p>
                                                                <p class="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                                <div class="at_teamowner">
                                                                    <span class="at_buddy">Buddies: {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                    {this.state.user.contactNo === x.createdBy ? <span class="at_owner">Owner</span> : <span class="at_owner">Team Member</span>}
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            })();
                                        }) : <div className="broadcast_video">
                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                                <h3>Sorry you don't have any Demo teams.</h3>
                                            </div>}
                                    </ul>
                                </div>
                            </div>
                                : <DemoTeamprofile teamId={this.state.teamId} />}
                        </section>
                    </div>
                    <SectionRight />

                </div>
            </>
        );

    }
}
export default TeamsDemo;