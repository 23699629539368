import React, { Component } from 'react'
import challengefill from "../img/challengefill.png";
import teamIcon from "../img/team.png";
import scheduleMatches from "../img/schedulematches.png";
import shuffle from "../img/shuffle.png";
import location from "../img/location.png";
import DatePicker from "react-datepicker";
import $ from 'jquery';
import Map from '../Map';
import * as myConstclass from '../ConstFile';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
let fixture1Ui, fixture2Ui1, fixture2Ui2, fixture2Ui3, fixture2Ui4, fixture2UiSemiFinal, fixture2UiFinal;
let poolLength;
let poolList = [];
export default class DOrganiseMatchTT1List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
        }
    };
    componentDidMount() {
        console.log(this.props.tt1Matches)
        if (this.props.tt1Matches.fixtureType1) {
            this.setState({ type: 'fixture1', showFixture1: true });
        }
        else if (this.props.tt1Matches.fixtureType2) {
            poolLength = this.props.tt1Matches.fixtureType2.fixturePoolList.length;
            if (poolLength == 2) {
                this.setState({ type: 'fixture2', showFixture2For2: true, showFixture2Final: true });
            }
            else if (poolLength == 4) {
                this.setState({ type: 'fixture2', showFixture2For4: true, showFixture2SemiFinal: true, showFixture2Final: true });
            }

        }
    }
    viewTourScore = (categoryId, ttType, mid) => {
        let passObj = { cId: categoryId, ttType: ttType, mid: mid }
        this.props.viewTT1Parrent(passObj)
    }
    checkSportName = (c_ID, TType, matchId, fType) => {
        let passObj = { c_ID: c_ID, TType: TType, matchId: matchId, fType: fType }
        this.props.updateTT1Parrent(passObj)
    }
    getName = (id) => {
        if (this.props.tournamentMode === 'team') {
            let team = this.props.teamsInTournament.find(team => {
                if (id === team.teamId) {
                    return team;
                }
            })
            return team;
        }
        else {
            let user = this.props.usersInTournament.find(user => {
                if (id === user.contactNo) {
                    return user;
                }
            })
            return user;
        }
    }

    render() {
        if (this.state.type === 'fixture1') {
            fixture1Ui = this.props.tt1Matches.fixtureType1.fixtureList.map((fl, index) => {
                let bracketNumber = index + 1;
                let classOfBracket = 'bracket bracket-' + bracketNumber;
                return <ul className={classOfBracket}>
                    {fl.fixturePairs.map((fp, ind) => {
                        let firstNameObj = this.getName(fp.firstId);
                        let secondNameObj = this.getName(fp.secondId);
                        let firstName;
                        let secondName;
                        if (this.props.tournamentMode === 'team') {
                            firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                        }
                        else {
                            firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                        }
                        let dateplace = fp.date;
                        let timeplace = fp.time;
                        return <li className="team-item">
                            <div className="fixture_team"><span
                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                <label>{firstName}</label>
                            </div>
                            <div className="fixture_team"><span
                                style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                <label>{secondName}</label>
                            </div>
                            <div className="fixture_date">
                                <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                <img src={scheduleMatches} alt="" />
                            </div>
                            <div className="fixture_date fixture_location">
                                <input placeholder="Match location" value={fp.location} />
                                <img src={location} alt="" />
                            </div>
                            {fp.ended
                                ?
                                <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, fp.matchId)} className="cmnbuttonup">View Score</button>
                                : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, -2)} className="cmnbuttonup1">Update Score</button>
                            }
                        </li>
                    })}
                </ul>
            })
        }
        else {

            if (poolLength == 2 || poolLength == 4) {


                fixture2Ui1 = this.props.tt1Matches.fixtureType2.fixturePoolList[0].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date">
                                    <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location">
                                    <input placeholder="Match location" value={fp.location} />
                                    <img src={location} alt="" />
                                </div>
                                {fp.ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, fp.matchId)} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, "A")} className="cmnbuttonup1">Update Score</button>
                                }
                            </li>
                        })}
                    </ul>
                })


                fixture2Ui2 = this.props.tt1Matches.fixtureType2.fixturePoolList[1].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date">
                                    <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location">
                                    <input placeholder="Match location" value={fp.location} />
                                    <img src={location} alt="" />
                                </div>
                                {fp.ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, fp.matchId)} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, "B")} className="cmnbuttonup1">Update Score</button>
                                }
                            </li>
                        })}
                    </ul>
                })

            }
            if (poolLength == 4) {
                fixture2Ui3 = this.props.tt1Matches.fixtureType2.fixturePoolList[2].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date">
                                    <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location">
                                    <input placeholder="Match location" value={fp.location} />
                                    <img src={location} alt="" />
                                </div>
                                {fp.ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, "C")} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, fp.matchId)} className="cmnbuttonup1">Update Score</button>
                                }
                            </li>
                        })}
                    </ul>
                })

                fixture2Ui4 = this.props.tt1Matches.fixtureType2.fixturePoolList[3].fixtureList.map((fl, index) => {
                    let bracketNumber = index + 1;
                    let classOfBracket = 'bracket bracket-' + bracketNumber;
                    return <ul className={classOfBracket}>
                        {fl.fixturePairs.map((fp, ind) => {
                            let firstNameObj = this.getName(fp.firstId);
                            let secondNameObj = this.getName(fp.secondId);
                            let firstName;
                            let secondName;
                            if (this.props.tournamentMode === 'team') {
                                firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                            }
                            else {
                                firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                                secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                            }
                            let dateplace = fp.date;
                            let timeplace = fp.time;
                            return <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date">
                                    <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location">
                                    <input placeholder="Match location" value={fp.location} />
                                    <img src={location} alt="" />
                                </div>
                                {fp.ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, "D")} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, fp.matchId)} className="cmnbuttonup1">Update Score</button>
                                }
                            </li>
                        })}
                    </ul>
                })

            }
            if (this.props.tt1Matches.fixtureType2) {
                if (this.props.tt1Matches.fixtureType2.semifinalList) {
                    fixture2UiSemiFinal = this.props.tt1Matches.fixtureType2.semifinalList.map((fp, ind) => {
                        let firstNameObj = this.getName(fp.firstId);
                        let secondNameObj = this.getName(fp.secondId);
                        let firstName;
                        let secondName;
                        let bracketNumber = ind + 1;
                        let classOfBracket = 'bracket bracket-' + bracketNumber;
                        if (this.props.tournamentMode === 'team') {
                            firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                        }
                        else {
                            firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                            secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                        }
                        let dateplace = fp.date;
                        let timeplace = fp.time;
                        return <ul className="tourcompleted_match">
                            <li className="team-item">
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{firstName}</label>
                                </div>
                                <div className="fixture_team"><span
                                    style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                    <label>{secondName}</label>
                                </div>
                                <div className="fixture_date">
                                    <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div className="fixture_date fixture_location">
                                    <input placeholder="Match location" value={fp.location} />
                                    <img src={location} alt="" />
                                </div>
                                {fp.ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, ind, fp.matchId)} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, "C")} className="cmnbuttonup1">Update Score</button>
                                }
                            </li>
                        </ul>
                    })
                }

            }

            if (this.props.tt1Matches.fixtureType2) {
                let fp = this.props.tt1Matches.fixtureType2.finalMatch;
                let bracketNumber = 1;
                let classOfBracket = 'bracket bracket-' + bracketNumber;
                let firstNameObj = this.getName(fp.firstId);
                let secondNameObj = this.getName(fp.secondId);
                let firstName;
                let secondName;
                if (this.props.tournamentMode === 'team') {
                    firstName = firstNameObj ? firstNameObj.teamName : 'To Be Decided';
                    secondName = secondNameObj ? secondNameObj.teamName : 'To Be Decided';
                }
                else {
                    firstName = firstNameObj ? firstNameObj.name : 'To Be Decided';
                    secondName = secondNameObj ? secondNameObj.name : 'To Be Decided';
                }
                let dateplace = fp.date;
                let timeplace = fp.time;
                fixture2UiFinal = <ul className="tourcompleted_match">
                    <li className="team-item">
                        <div className="fixture_team"><span
                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                            <label>{firstName}</label>
                        </div>
                        <div className="fixture_team"><span
                            style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                            <label>{secondName}</label>
                        </div>
                        <div className="fixture_date">
                            <input placeholder="Match Date Time" readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                            <img src={scheduleMatches} alt="" />
                        </div>
                        <div className="fixture_date fixture_location">
                            <input placeholder="Match location" value={fp.location} />
                            <img src={location} alt="" />
                        </div>
                        {fp.ended
                            ?
                            <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, 1, fp.matchId)} className="cmnbuttonup">View Score</button>
                            : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, fp.matchId, "F")} className="cmnbuttonup1">Update Score</button>
                        }
                    </li>
                </ul>
            }


        }

        return (
            <React.Fragment>
                <div className=" cp_profile">
                    <div className="invite_tournament">
                        <label className="addbuddies_count tourpop_count">Create Fixture</label>
                    </div>

                    {/* <div className="sche_tourbtn">
                        <button className="cmnbutton"><img src={shuffle} alt="" />Re-Shuffle</button>
                        <button className="cmnbutton">Create</button>
                    </div> */}



                    {this.state.showFixture1 ? <div class="tournament-container scrollbar" id="style-4">
                        <div class="tournament-brackets">
                            {fixture1Ui}
                        </div>
                    </div> : ''}

                    {this.state.showFixture2For2 ? <>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 1</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui1}
                            </div>
                        </div>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 2</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui2}
                            </div>
                        </div>
                    </> : ''}
                    {this.state.showFixture2For4 ? <>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 1</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui1}
                            </div>
                        </div>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 2</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui2}
                            </div>
                        </div>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 3</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui3}
                            </div>
                        </div>
                        <div class="semi_headingdiv semi-final">
                            <label >Pool 4</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="tournament-brackets">
                                {fixture2Ui4}
                            </div>
                        </div>

                    </> : ''}

                    {this.state.showFixture2SemiFinal ? <>
                        <div class="semi_headingdiv semi-final">
                            <label >Semi Final</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="completedmatches">
                                {fixture2UiSemiFinal}
                            </div>
                        </div>
                    </> : ''}
                    {this.state.showFixture2Final ? <>
                        <div class="semi_headingdiv semi-final">
                            <label >Final</label>
                        </div>
                        <div class="tournament-container scrollbar" id="style-4">
                            <div class="completedmatches">
                                {fixture2UiFinal}
                            </div>
                        </div>
                    </> : ''}
                </div>
            </React.Fragment>
        )
    }
}
