import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import ConfigUrl from './ConfigUrl';
import LoginFeedsPosts from './LoginFeedsPosts';
import LoginFeedsCricket from './LoginFeedsCricket';
import LoginFeedsBroadcast from "./LoginFeedsBroadcast";
import LoginFeedsTournamentMatch from './LoginFeedsTournamentMatch';
import LoginFeedsTournamentMatchTT4 from './LoginFeedsTournamentMatchTT4';
import LoginFeedsNews from './LoginFeedsNews';
import LoginFeedsTournamentCreated from './LoginFeedsTournamentCreated';
import LoginFeedsTournamentWinner from './LoginFeedsTournamentWinner';
import LoginFeedsTournamentBest from './LoginFeedsTournamentBest';
import LoginFeedsChallengeN from './LoginFeedsChallengeN';
import PageFeed from './PageFeed';
import commingsoon from './img/commingsoon.png';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

class LoginFeeds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            posts: [],
            user: [],
            index: 0,
            tournamentMatchList: [],
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    componentDidMount() {
        this.loadMore();
    }
    cReportCB = (feedId) => {
        let newFeeds = [...this.state.newsFeeds];
        this.setState({
            newFeeds: newFeeds.map(nf => {
                if (nf.newFeedsId === feedId) {
                    nf.reported = !nf.reported;
                }
            })
        })
    }
    loadData = () => {
        var indexData = this.state.index;
        indexData = indexData + 1;
        this.setState({ index: indexData });
        let that = this;
        var apiUrl = userApi + '/newfeeds/get-feeds';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    userId: this.state.user.contactNo,
                    index: indexData,
                    itemsPerIndex: 10,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)
            const data1 = that.state.newsFeeds.concat(result.data);
            that.setState({ newsFeeds: data1 }, () => {
                console.log(data1)
            });
        });
    }
    loadMore = () => {
        let that = this;
        var apiUrl = userApi + '/newfeeds/get-feeds';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    userId: this.state.user ? this.state.user.contactNo : null,
                    index: this.state.index,
                    itemsPerIndex: 10
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)
            if (!result.error) {
                that.setState({

                    newsFeeds: result.data
                });

            }
        });
    }
    render() {
        console.log(this.state.newsFeeds)
        const width100 = {
            width: '100%'
        };
        //var postData;
        return <div className=" sharepostdiv" id="accordion">
            {this.state.newsFeeds.map(x => {
                if (x.moduleName === "posts") {
                    return <LoginFeedsPosts key={x.newFeedsId} posts="posts" moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                }
                if (x.moduleName === "liveCricket") {
                    return <LoginFeedsCricket key={x.newFeedsId} liveCricket="liveCricket" moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                }
                if (x.moduleName === "broadcast") {
                    return <LoginFeedsBroadcast key={x.newFeedsId} broadcast="broadcast" moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                }
                if (x.moduleName === "news") {
                    return <LoginFeedsNews key={x.newFeedsId} news="news" moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                }
                if (x.moduleName === 'challenges') {
                    return <LoginFeedsChallengeN key={x.newFeedsId} moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                }
                if (x.moduleName === "tournaments") {
                    if (x.subModuleName === "tournamentCreated") {
                        return <LoginFeedsTournamentCreated key={x.newFeedsId} tournamentCreated="tournamentCreated" moduleId={x.moduleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentWinner") {
                        return <LoginFeedsTournamentWinner key={x.newFeedsId} tournamentWinner="tournamentWinner" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentBestBatsman") {
                        return <LoginFeedsTournamentBest key={x.newFeedsId} tournamentBest="tournamentBestBatsman" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentBestBowler") {
                        return <LoginFeedsTournamentBest key={x.newFeedsId} tournamentBest="tournamentBestBowler" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentBestFielder") {
                        return <LoginFeedsTournamentBest key={x.newFeedsId} tournamentBest="tournamentBestFielder" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentMos") {
                        return <LoginFeedsTournamentBest key={x.newFeedsId} tournamentBest="tournamentMos" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentMatch") {
                        return <LoginFeedsTournamentMatch key={x.newFeedsId} tournamentMatch="tournamentMatch" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                    if (x.subModuleName === "tournamentMatchTT4") {
                        return <LoginFeedsTournamentMatchTT4 key={x.newFeedsId} tournamentBest="tournamentMatchTT4" moduleId={x.moduleId} moduleName={x.moduleName} subModuleId={x.subModuleId} newFeedsId={x.newFeedsId} reported={x.reported} sentBy={x.sentBy} pReportCB={this.cReportCB} />
                    }
                }
                if ((x.moduleName === "page"))
                    return <PageFeed feeds={x} />

            })}
            <button onClick={this.loadData} type="button" className="btn btn-success" style={width100}>Load more</button>
        </div>;
    }
}

export default LoginFeeds;