import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Challenge_d103 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchSatus: false,
            matchPoint: [],
        };

    }
    componentDidMount() {
        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.props.challenge.challengeId,
                    "matchId": that.props.challenge.challengeId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if(result.data.score){
                    that.setState({ matchPoint: result.data.score.d103ScoreCard.scoreCardD103s })
                    result.data.score.d103ScoreCard.scoreCardD103s.map((point, index) => {
                        if (point.points > 0) {
                            that.setState({ matchSatus: true });
                        }
                    })
                }
               
            });
        if (that.props.challenge.challengeAction === 'ended') {

            that.setState({ matchSatus: true });
        }
    }

    render() {
        const backLinks = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
        return (
            <React.Fragment>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        {this.state.matchSatus ? <ul class="winnerdropdown_ul">
                            {this.state.matchPoint.map(point => {
                                var userName;
                                this.props.profileUsers.map(user => {
                                    if (point.userId === user.contactNo) {
                                        userName = user.name;
                                    }
                                })
                                return <li>
                                    <label>{userName}</label>
                                    <input placeholder="Enter Points" readonly disabled value={point.points} />
                                </li>
                            })}

                        </ul> : <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>}
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>

                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
