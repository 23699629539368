import React from 'react';
import Header from './Header';
import { Link } from "react-router-dom";
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import liveFootballErrorIcon from './img/live-football-error.jpg';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class LiveScore extends React.Component {
    constructor() {
        super();
        this.loadData = this.loadData.bind(this);
    }

    state={
        loading:true,
        person:[],
    }

    async loadData() {
        const apiurl= "https://rest.entitysport.com/v2/matches/?status=3&token=7172e8628aaf3155484181f32a328f58";
        const response = await fetch(apiurl);
        const data = await response.json();
        const sizeD =data.response.items.length;
        console.log(data);
        if(sizeD>0){
            this.setState({person:data.response.items,loading:false});
        }
    }

    componentDidMount(){
        this.loadData()
        //setInterval((this.loadData), 3000);
    }
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <div className="custom-ui">
                        <h5>To view details you must be Login or Register yourself</h5>
                        <a href="/live-score" className="btn btn-warning btn-xs">Cancel</a>
                        <a href="/login" className="btn btn-success btn-xs">Proceed</a>
                    </div>
                );
            }
        });
    };

    render(){
        return<div><Header />
            <div className="container containerbottom">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                    <section className="d_leftsec">
                        <div className="commanbox ">
                            <h3 className="commanhead hidden-xs">Activities</h3>
                            <ul className="activityul hidden-xs">
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={teamFillIcon} />Team</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeFillIcon} />Challange</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                            </ul>
                            <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</Link></li>
                                <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <section className="d_midsec">
                        <div className="commanbox commanboxlivescore">
                            <img src={liveFootballErrorIcon} className="img-responsive" alt="" />
                        </div>
                    </section>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                    <section className="d_rightsec">
                        <div className="commanbox ">
                            <h3 className="commanhead">Help</h3>
                            <ul className="helpul">
                                <li><Link to={"#"}>About Us</Link></li>
                                <li><Link to={"#"}>Help Center</Link></li>
                                <li><Link to={"/end-user-license-agreement"}>Privacy & Terms</Link></li>
                                <li><Link to={"#"}>Advertise</Link></li>
                                <li><Link to={"#"}>Get Playdiator App</Link></li>
                                <li><Link to={"#"}>More</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    }
}
export default LiveScore;