import React, { Component } from "react";
import ModalSet from "react-bootstrap/Modal";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from "../ConfigUrl";
import userMaleIcon from "../img/male.png";
const ConfigApiurl = ConfigUrl("apiUrl");
const userApi = ConfigApiurl;

export default class InviteQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQrModel: true,
      invitedQR: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.state.user = JSON.parse(localStorage.getItem("result"));
    let userInfo = this.state.user
    console.log(userInfo);
  }

  // QrModelhide = () => {
  //   this.setState({ openQrModel: false });
  // };

  componentDidMount() {
    if (!this.state.user.qrCode) {
      this.GetQrCode();
    }
    else {
      this.setState({ qrCode: this.state.user.qrCode });
    }
  }

  GetQrCode = () => {
    const that = this;
    fetch(userApi + "/qr/user-qr", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          userId: this.state.user.id,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        that.setState({ qrCode: result.data.qrCode });
        let newUser = that.state.user;
        newUser.qrCode = result.data.qrCode;
        localStorage.setItem("result", JSON.stringify(newUser));
      });
  };

  closeModal() {
    this.setState({ openQrModel: false });
    this.props.closeQr();
  }

  render() {
    let icon = this.state.user.profilePic ? this.state.user.profilePic : userMaleIcon;

    return (
      <ModalSet
        size="lg"
        show={this.state.openQrModel}
        // onHide={() => this.QrModelhide()}
        animation={false}
        onRequestClose={this.closeModal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="Qrmodel-width"
      >
        {/* <div id="QRmodel" className="modal fade" role="dialog">
          <div className="modal-dialog Qrmodel-width"> */}
        {/* Modal content*/}
        <div className="modal-content modal-sports qrcode-model">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={this.closeModal}
              data-dismiss="modal"
            >
              ×
            </button>
            <h4 className="modal-title">Scan QR</h4>
          </div>
          <div className="modal-body  Qr-model-body">
            <div className="col-lg-7 col-md-7 qr-left">
              <div className="barcode-info">
                <img src={icon} />
                <label className="QrinfoLable">{this.state.user.name}
                  <p>Playdiator User</p>
                </label>
              </div>
              <label className="qr-location">
                Location
                <p>{this.state.user.address}</p>
              </label>
              <label className="qr-creator">
                {/* Playdiator User
                <p style={{ color: "#000" }}>{this.state.user.name}</p> */}
              </label>
              <div className="qr-downloadbtn">
                <p className="heading-download">
                  Click the below links for download the App{" "}
                </p>
                <div className="flexss social-btns">
                  <a
                    className="app_btnn blu flexss vert"
                    onClick={() =>
                    (window.location.href =
                      "https://apps.apple.com/app/id1437392923")
                    }
                  >
                    <i className="fa fa-apple" />
                    <p>
                      Get it on <br />{" "}
                      <span className="big-txt">App Store</span>
                    </p>
                  </a>
                  <a
                    className="app_btnn blu flexss vert"
                    onClick={() =>
                    (window.location.href =
                      "https://play.google.com/store/apps/details?id=com.sportsapp.ssp")
                    }
                  >
                    <i className="fa fa-google" />
                    <p>
                      Get it on <br />{" "}
                      <span className="big-txt">Google Play</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 qr-right">
              <div className="qrblock">
                <p className="Qr-sec-headng">
                  Scan the QR code for adding team, buddies, challenge &amp;
                  tournament
                </p>
                <img src={this.state.qrCode} />
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </ModalSet>
    );
  }
}
