import React, { Component } from "react";
import userMaleIcon from "./img/male.png";
import userFemaleIcon from "./img/female.png";
import challengefill from './img/challengefill.png';
import schedulematches from './img/schedulematches.svg';
import schedulrclosed from './img/schedulrclosed.svg';
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
import ScheduleMiddleSection from './ScheduleMiddleSection';
import ScheduleTournamentSection from './ScheduleTournamentSection';
import Countdown from "react-countdown-now";
import GetRemainTime from './GetRemainTime';
import { } from 'prismic-reactjs';

import Header from './Header';
import SectionLeft from './SectionLeft';
import SectionRight from './SectionRight';

const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
class ScheduleMatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buddies: [],
            buddiesCount: true,
            tournaments: [],
            schedules: [],
            challenges: [],
            remainingTime: [],
            dates: [],
        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.loadMore = this.loadMore.bind(this);
    }
    loadMore = () => {
        let that = this;
        fetch(userApi + "/schedules/get-schedules", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);

                if (result.data.hasOwnProperty('tournaments')) {
                    that.setState({
                        tournaments: result.data.tournaments, schedules: result.data.schedules, challenges: result.data.challenges,
                    })
                }
            });
    };
    componentDidMount() {
        this.loadMore();
    }
    render() {
        return (
            <>
                <Header />
                <div className="container containerbottom non-session-d">
                    {this.state.user ? <SectionLeft /> : ''}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            <div className="commanbox">
                                <h3 className="commanhead">Schedule</h3>
                                <div className="createteamform">
                                    <div className="broadcastdiv">
                                        <ul className="nav nav-tabs teamprofiletab">
                                            <li className="active"><a data-toggle="tab" href="#upcomingMatches" aria-expanded="true">Upcoming Matches</a></li>
                                            <li><a data-toggle="tab" href="#completedMatches">Completed Matches</a></li>
                                        </ul>
                                    </div>
                                    <div className="broadcast_tabul">
                                        <div className="tab-content tp_content">
                                            <div id="upcomingMatches" className="tab-pane fade in active">
                                                <div className="" id="upcommingClass">
                                                    <ul className="upcommingmatches" id="upcomming">
                                                        {this.state.schedules.length ? this.state.schedules.map(sch => {
                                                            console.log(sch)
                                                            let scheduleTimpstamp, todayTimpstamp, scheduleDate, showDateTime;
                                                            showDateTime = sch.scheduleDate + " " + sch.scheduleTime;
                                                            if (sch.scheduleDate) {
                                                                scheduleDate = sch.scheduleDate.split('-');
                                                                scheduleDate = scheduleDate[1] + "/" + scheduleDate[0] + "/" + scheduleDate[2] + " " + sch.scheduleTime;
                                                            }

                                                            scheduleTimpstamp = new Date(scheduleDate).getTime();

                                                            todayTimpstamp = new Date().getTime();
                                                            if (scheduleTimpstamp > todayTimpstamp) {
                                                                let remaintimer = Date.now() + (scheduleTimpstamp - todayTimpstamp);
                                                                let that = this;
                                                                {/* let remainingTime = GetRemainTime(scheduleTimpstamp); */ }
                                                                if (sch.scheduleType === "challenge") {
                                                                    this.state.challenges.map(challenge => {
                                                                        if (sch.moduleId === challenge.challengeId) {
                                                                            if ((challenge.categoryId === "D101") || (challenge.categoryId === "D103") || (challenge.categoryId === "D104") || (challenge.categoryId === "D105") || (challenge.categoryId === "GOLF")) {

                                                                                this.scheduleData1 = <li key={challenge.challengeId}>
                                                                                    <div className="sm_maindiv">
                                                                                        <h3>{challenge.sportsName} Challange</h3>
                                                                                        <ScheduleMiddleSection accepted={challenge.accepted} challengeType={challenge.challengeType} />
                                                                                        <div className="sm_timeinfo">
                                                                                            <span className="sm_timeleft">{showDateTime}</span>
                                                                                            <span className="sm_starttime"><Countdown date={scheduleTimpstamp} /></span>


                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                            else {

                                                                                this.scheduleData1 = <li key={challenge.challengeId}>
                                                                                    <div className="sm_maindiv">
                                                                                        <h3>{challenge.sportsName} Challange</h3>
                                                                                        <ScheduleMiddleSection accepted={challenge.accepted} challengeType={challenge.challengeType} />
                                                                                        <div className="sm_timeinfo">
                                                                                            <span className="sm_timeleft">{showDateTime}</span>
                                                                                            <span className="sm_starttime" >  <Countdown date={remaintimer} /></span>


                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;
                                                                }
                                                                if (sch.scheduleType === "tournament-tt1") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={"tournament-tt1"} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  <Countdown date={remaintimer} /></span>


                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;

                                                                }
                                                                if (sch.scheduleType === "tournament-tt4") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={sch.scheduleType} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  <Countdown date={remaintimer} /></span>


                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;

                                                                }
                                                                if (sch.scheduleType === "tournament-tt5") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={sch.scheduleType} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  <Countdown date={remaintimer} /></span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;

                                                                }

                                                            }

                                                        }
                                                        ) : <div className="broadcast_video">
                                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={schedulematches} /></a>
                                                                <h3>No matches scheduled yet.</h3>
                                                            </div>
                                                        }


                                                    </ul>
                                                </div>
                                            </div>

                                            <div id="completedMatches" className="tab-pane fade broadmap">
                                                <div className="" id="upcommingClass">
                                                    <ul className="upcommingmatches" id="completed">
                                                        {this.state.schedules.length ? this.state.schedules.map(sch => {
                                                            let scheduleTimpstamp, todayTimpstamp, scheduleDate, showDateTime;
                                                            showDateTime = sch.scheduleDate + " " + sch.scheduleTime;
                                                            if (sch.scheduleDate) {
                                                                scheduleDate = sch.scheduleDate.split('-');
                                                                scheduleDate = scheduleDate[1] + "/" + scheduleDate[0] + "/" + scheduleDate[2] + " " + sch.scheduleTime;
                                                            }

                                                            scheduleTimpstamp = new Date(scheduleDate).getTime();
                                                            todayTimpstamp = new Date().getTime();
                                                            if (scheduleTimpstamp < todayTimpstamp) {
                                                                const remainingTimed = '00:00:00';
                                                                if (sch.scheduleType === "challenge") {
                                                                    this.state.challenges.map(challenge => {

                                                                        if (sch.moduleId === challenge.challengeId) {
                                                                            if ((challenge.categoryId === "D101") || (challenge.categoryId === "D103") || (challenge.categoryId === "D104") || (challenge.categoryId === "D105") || (challenge.categoryId === "GOLF")) {
                                                                                this.scheduleData = <li key={challenge.challengeId}>
                                                                                    <div className="sm_maindiv">
                                                                                        <h3>{challenge.sportsName} Challange</h3>
                                                                                        <ScheduleMiddleSection accepted={challenge.accepted} challengeType={challenge.challengeType} />
                                                                                        <div className="sm_timeinfo">
                                                                                            <span className="sm_timeleft">{showDateTime}</span>
                                                                                            <span className="sm_starttime">{remainingTimed}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                            else {
                                                                                this.scheduleData = <li key={challenge.challengeId}>
                                                                                    <div className="sm_maindiv">
                                                                                        <h3>{challenge.sportsName}  Challange</h3>
                                                                                        <ScheduleMiddleSection accepted={challenge.accepted} challengeType={challenge.challengeType} />
                                                                                        <div className="sm_timeinfo">
                                                                                            <span className="sm_timeleft">{showDateTime}</span>
                                                                                            <span className="sm_starttime">{remainingTimed}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        }
                                                                    });
                                                                    return this.scheduleData;
                                                                }
                                                                if (sch.scheduleType === "tournament-tt1") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={"tournament-tt1"} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  {remainingTimed}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;
                                                                }
                                                                if (sch.scheduleType === "tournament-tt4") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={sch.scheduleType} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  {remainingTimed}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;

                                                                }
                                                                if (sch.scheduleType === "tournament-tt5") {
                                                                    var accepted = [sch._firstId, sch._secondId];
                                                                    this.state.tournaments.map(tournament => {
                                                                        if (sch.moduleId === tournament.tournamentId) {
                                                                            this.scheduleData1 = <li key={tournament.tournamentId}>
                                                                                <div className="sm_maindiv">
                                                                                    <h3>{tournament.sportsName} Tounaments</h3>
                                                                                    <ScheduleTournamentSection accepted={accepted} challengeType={tournament.tournamentMode} scheduleType={sch.scheduleType} scheduleText={sch.text} sportsName={tournament.sportsName} tournamentName={tournament.tournamentName} />
                                                                                    <div className="sm_timeinfo">
                                                                                        <span className="sm_timeleft">{showDateTime}</span>
                                                                                        <span className="sm_starttime" >  {remainingTimed}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                    });
                                                                    return this.scheduleData1;
                                                                }
                                                            }
                                                            {/* else {
                                            return <div className="broadcast_video">
                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={schedulrclosed} /></a>
                                                <h3>No completed events available.</h3>
                                            </div>
                                        } */}
                                                        }) : <div className="broadcast_video">
                                                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={schedulrclosed} /></a>
                                                                <h3>No completed events available.</h3>
                                                            </div>}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <SectionRight />

                </div>
            </>
        );
    }
}
export default ScheduleMatches;   