

import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import * as TournamentsUtil from '../TournamentsUtil';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import male from "../img/male.png";
import teamIcons from '../img/team.png';
import female from "../img/female.png";
import searchnew from "../img/searchnew.png";
import * as myUtilclass from '../OrganiseMatchUtil';
import * as myTournamentUtilclass from '../TournamentsUtil';
let pairObj;
var score;
var d107RugbyScoreCard = {};
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
var selectedWinners = undefined;
let byPic, toPic;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournament_rugby_tt1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            toWinner: false,
            byWinner: false,
            d107RugbyScoreCard: {},
            byMemberModalOpen: false,
            byMemberModalOpen: false,
            selectWinnerModal: false,
            pointValue: '',
            score: false,
            byTeamGoals: '',
            toTeamGoals: '',
            byPlayerScores: [],
            toPlayerScores: [],
            tieWinner: false,
            matchPairObj: {},
        };
        this.closeModal = this.closeModal.bind(this);
        this.byMemberCloseModal = this.byMemberCloseModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ winnerModalOpen: false });
    }

    doneMatch() {

        var winner;
        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winners !== undefined) {
            winner = this.state.winners;
        }


        let d107RugbyScoreCard = this.state.d107RugbyScoreCard;
        if (this.state.byWinner) {
            d107RugbyScoreCard.byRugbyScoreCard.winner = true;
            d107RugbyScoreCard.toRugbyScoreCard.winner = false;
        }
        if (this.state.toWinner) {
            d107RugbyScoreCard.toRugbyScoreCard.winner = true;
            d107RugbyScoreCard.byRugbyScoreCard.winner = false;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107RugbyScoreCard: d107RugbyScoreCard,
        }

        if (this.props.fType === 3) {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                var moment = require('moment');
                var now = moment().format();
                var timpstampData = new Date(now).valueOf();
                var tournament = that.props.tournament;
                delete tournament.id;
                tournament.status = "completed";
                tournament.modifiedOn = timpstampData;
                tournament.tournamentWinnerId = that.state.winner;
                TournamentsUtil.ttWinnerFeeds(that.props.tournament.tournamentId, that.props.matchId, winner).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                    })
                })
            })

        }
        else {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
            })
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }
    backState = () => {
        $("#setAttemptModal .close").click();

        window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }

    endEvent() {

        let byTotal = this.state.d107RugbyScoreCard.byRugbyScoreCard.teamGoals;
        let toTotal = this.state.d107RugbyScoreCard.toRugbyScoreCard.teamGoals;
        if (byTotal > toTotal) {
            this.setState({ byWinner: true, winner: this.state.matchPairObj.firstId })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal > byTotal) {
            this.setState({ toWinner: true, winner: this.state.matchPairObj.secondId })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal === byTotal) {
            this.setState({ selectWinnerModal: true })
        }

    }
    getScore() {
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {

                        let byName; let toName;
                        d107RugbyScoreCard = {
                            "byRugbyScoreCard": {
                                "teamGoals": 0,
                                "teamId": that.state.matchPairObj.firstId,
                                "winner": false,
                                "playerScores": [],
                                "position": 0
                            },
                            "toRugbyScoreCard": {
                                "teamGoals": 0,
                                "teamId": that.state.matchPairObj.secondId,
                                "winner": false,
                                "playerScores": [],
                                "position": 0
                            },
                            "strokeNo": 0
                        }
                        var score = {
                            tournamentId: that.props.tournament.tournamentId,
                            matchId: that.props.matchId,
                            d107RugbyScoreCard: d107RugbyScoreCard,
                        }


                        UpdateTournamentsScore(score);
                        that.setState({ d107RugbyScoreCard: d107RugbyScoreCard });



                        that.props.profileUsers.map(user => {
                            if (that.state.matchPairObj.firstId === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : teamIcons;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        that.props.profileTeams.map(user => {
                            if (that.state.matchPairObj.firstId === user.teamId) {
                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                    }
                    else {

                        if (result.data.score.d107RugbyScoreCard.byRugbyScoreCard !== null) {
                            that.setState({ byTeamGoals: result.data.score.d107RugbyScoreCard.byRugbyScoreCard.teamGoals, byPlayerScores: result.data.score.d107RugbyScoreCard.byRugbyScoreCard.playerScores });
                        }

                        if (result.data.score.d107RugbyScoreCard.toRugbyScoreCard !== null) {
                            that.setState({ toTeamGoals: result.data.score.d107RugbyScoreCard.toRugbyScoreCard.teamGoals, toPlayerScores: result.data.score.d107RugbyScoreCard.toRugbyScoreCard.playerScores });
                        }
                        that.setState({ score: true, d107RugbyScoreCard: result.data.score.d107RugbyScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, strokeNo: result.data.score.d107RugbyScoreCard.strokeNo });
                        let byName; let toName;

                        if (result.data.mode === 'buddy') {


                        }

                        else {
                            that.props.profileTeams.map(user => {
                                if (that.state.matchPairObj.firstId === user.teamId) {
                                    byName = user.teamName
                                    byPic = user.teamIcon ? user.teamIcon : teamIcons;

                                }
                                if (that.state.matchPairObj.secondId === user.teamId) {
                                    toName = user.teamName
                                    toPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        if (result.data.score.d107RugbyScoreCard.byRugbyScoreCard !== null) {
                            that.setState({ byTeamGoals: result.data.score.d107RugbyScoreCard.byRugbyScoreCard.teamGoals });
                        }
                        if (result.data.score.d107RugbyScoreCard.toRugbyScoreCard !== null) {
                            that.setState({ toTeamGoals: result.data.score.d107RugbyScoreCard.toRugbyScoreCard.teamGoals });
                        }
                    }

                });
        }
    }
    selectPlayerPoint(type, val) {
        this.setState({ pointValue: val })
        if (type === 'by') {
            this.setState({ byMemberModalOpen: true, toMemberModalOpen: false })
        }
        else {
            this.setState({ byMemberModalOpen: false, toMemberModalOpen: true })
        }
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
    }
    byMemberCloseModal() {

        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
    }

    makePoint(memberID) {
        var pointValue = this.state.pointValue;
        var type = this.state.byMemberModalOpen ? 'by' : 'to'
        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
        if (this.state.score) {
            if (type === 'by') {
                var d106 = this.state.d107RugbyScoreCard;
                if (d106.byRugbyScoreCard !== null) {
                    if (d106.byRugbyScoreCard.playerScores !== null) {

                        var playerScoresList = [];
                        playerScoresList = d106.byRugbyScoreCard.playerScores;
                        var pscoreObj = {
                            goalPoint: pointValue,
                            strokeNo: d106.strokeNo + 1,
                            userId: memberID,
                            //cardType: 0
                        }
                        d106.strokeNo = d106.strokeNo + 1;
                        playerScoresList.push(pscoreObj);
                        d106.byRugbyScoreCard.playerScores = playerScoresList;
                        d106.byRugbyScoreCard.teamGoals = d106.byRugbyScoreCard.teamGoals + pointValue;
                        let teamGoal = d106.byRugbyScoreCard.teamGoals;
                        this.setState({ byPlayerScores: d106.byRugbyScoreCard.playerScores });
                        this.setState({ byTeamGoals: teamGoal });
                    }
                    else {

                        var playerScores = [];
                        var pscoreObj = {
                            goalPoint: pointValue,
                            strokeNo: d106.strokeNo + 1,
                            userId: memberID,
                            //cardType: 0
                        }

                        d106.strokeNo = d106.strokeNo + 1;
                        playerScores.push(pscoreObj);

                        d106.byRugbyScoreCard = {
                            playerScores,
                            teamGoals: pointValue,
                            teamId: this.state.matchPairObj.firstId,
                            winner: false
                        }

                        let teamGoal = pointValue;
                        this.setState({ byTeamGoals: teamGoal });
                        this.setState({ byPlayerScores: d106.byRugbyScoreCard.playerScores });
                    }

                }
                else {

                    var playerScores = [];
                    var pscoreObj = {
                        goalPoint: pointValue,
                        strokeNo: d106.strokeNo + 1,
                        userId: memberID,
                        //cardType: 0
                    }

                    d106.strokeNo = d106.strokeNo + 1;
                    playerScores.push(pscoreObj);

                    d106.byRugbyScoreCard = {
                        playerScores,
                        teamGoals: pointValue,
                        teamId: this.state.matchPairObj.firstId,
                        winner: false
                    }
                    let teamGoal = pointValue;
                    this.setState({ byTeamGoals: teamGoal });
                    this.setState({ byPlayerScores: d106.byRugbyScoreCard.playerScores });
                }
            }

            if (type === 'to') {
                var d106 = this.state.d107RugbyScoreCard;
                if (d106.toRugbyScoreCard !== null) {
                    if (d106.toRugbyScoreCard.playerScores !== null) {

                        var playerScoresList = [];
                        playerScoresList = d106.toRugbyScoreCard.playerScores;
                        var pscoreObj = {
                            goalPoint: pointValue,
                            userId: memberID,
                            strokeNo: d106.strokeNo + 1,
                            //cardType: 0
                        }
                        d106.strokeNo = d106.strokeNo + 1;
                        playerScoresList.push(pscoreObj);
                        d106.toRugbyScoreCard.playerScores = playerScoresList;
                        d106.toRugbyScoreCard.teamGoals = d106.toRugbyScoreCard.teamGoals + pointValue;
                        let teamGoal = d106.toRugbyScoreCard.teamGoals;
                        this.setState({ toPlayerScores: d106.toRugbyScoreCard.playerScores });
                        this.setState({ toTeamGoals: teamGoal });
                    }
                    else {

                        var playerScores = [];
                        var pscoreObj = {
                            goalPoint: pointValue,
                            strokeNo: d106.strokeNo + 1,
                            userId: memberID,
                            // cardType: 0
                        }

                        d106.strokeNo = d106.strokeNo + 1;
                        playerScores.push(pscoreObj);

                        d106.toRugbyScoreCard = {
                            playerScores,
                            teamGoals: pointValue,
                            teamId: this.state.matchPairObj.secondId,
                            winner: false
                        }

                        let teamGoal = pointValue;
                        this.setState({ toTeamGoals: teamGoal });
                        this.setState({ toPlayerScores: d106.toRugbyScoreCard.playerScores });
                    }

                }
                else {

                    var playerScores = [];
                    var pscoreObj = {
                        goalPoint: pointValue,
                        strokeNo: d106.strokeNo + 1,
                        userId: memberID,
                        //cardType: 0
                    }

                    d106.strokeNo = d106.strokeNo + 1;
                    playerScores.push(pscoreObj);

                    d106.toRugbyScoreCard = {
                        playerScores,
                        teamGoals: pointValue,
                        teamId: this.state.matchPairObj.secondId,
                        winner: false
                    }
                    let teamGoal = pointValue;
                    this.setState({ toTeamGoals: teamGoal });
                    this.setState({ toPlayerScores: d106.toRugbyScoreCard.playerScores });

                }

            }
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107RugbyScoreCard: d106,
            }
            this.setState({ d107RugbyScoreCard: d106 });
            UpdateTournamentsScore(score);
        }
        else {


            var playerScores = [];
            let d107KabbadiScoreCard1 = { ...d107RugbyScoreCard };
            var pscoreObj = {
                goalPoint: pointValue,
                strokeNo: 1,
                userId: memberID,
                //cardType: 0
            }
            playerScores.push(pscoreObj);

            if (type === 'by') {

                var d107RugbyScoreCard = {
                    strokeNo: 1,
                    byRugbyScoreCard: {
                        playerScores,
                        teamGoals: pointValue,
                        teamId: this.state.matchPairObj.firstId,
                        winner: false
                    },
                    toRugbyScoreCard: null
                }
                let teamGoal = pointValue;
                this.setState({ byTeamGoals: teamGoal });
                this.setState({ byPlayerScores: d107RugbyScoreCard.byRugbyScoreCard.playerScores });
            }

            else {

                var d107RugbyScoreCard = {
                    strokeNo: 1,
                    byRugbyScoreCard: null,
                    toRugbyScoreCard: {
                        playerScores,
                        teamGoals: pointValue,
                        teamId: this.state.matchPairObj.secondId,
                        winner: false
                    }
                }
                let teamGoal = pointValue;
                this.setState({ toTeamGoals: teamGoal });
                this.setState({ toPlayerScores: d107RugbyScoreCard.toRugbyScoreCard.playerScores });
            }


            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107RugbyScoreCard: d107RugbyScoreCard,
            }
            this.setState({ d107RugbyScoreCard: d107RugbyScoreCard, score: score });

            UpdateTournamentsScore(score);
            this.getScore();
        }
    }



    ToundoList = () => {
        var d106 = this.state.d107RugbyScoreCard;
        var strokeNo = d106.strokeNo;
        var toUndo;
        var bygoalPoint;
        var togoalPoint;
        var bystokeMatched = false;
        var tostokeMatched = false;
        if (d106.byRugbyScoreCard !== null) {
            d106.byRugbyScoreCard.playerScores.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    bystokeMatched = true;
                    bygoalPoint = list.goalPoint;
                }
            })
            if (bystokeMatched) {
                var toUndoList = d106.byRugbyScoreCard.playerScores.filter((x) => x != toUndo)
                d106.strokeNo = strokeNo - 1;
                if (d106.byRugbyScoreCard.teamGoals !== 0) {
                    d106.byRugbyScoreCard.teamGoals = d106.byRugbyScoreCard.teamGoals - bygoalPoint;
                    //d106.byRugbyScoreCard.position = d106.byRugbyScoreCard.position - 1;
                }
                d106.byRugbyScoreCard.playerScores = toUndoList;
                this.setState({ byPlayerScores: toUndoList, byTeamGoals: d106.byRugbyScoreCard.teamGoals })
            }
        }
        if (d106.toRugbyScoreCard !== null) {
            d106.toRugbyScoreCard.playerScores.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    tostokeMatched = true;
                    togoalPoint = list.goalPoint;
                }
            })
            if (tostokeMatched) {
                var toUndoList = d106.toRugbyScoreCard.playerScores.filter((x) => x != toUndo)
                d106.strokeNo = strokeNo - 1;
                if (d106.toRugbyScoreCard.teamGoals !== 0) {
                    d106.toRugbyScoreCard.teamGoals = d106.toRugbyScoreCard.teamGoals - togoalPoint;
                    //d106.toRugbyScoreCard.position = d106.toRugbyScoreCard.position - 1;
                }
                d106.toRugbyScoreCard.playerScores = toUndoList;
                this.setState({ toPlayerScores: toUndoList, toTeamGoals: d106.toRugbyScoreCard.teamGoals })
            }
        }
        this.setState({ d107RugbyScoreCard: d106 });
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107RugbyScoreCard: d106,
        }
        UpdateTournamentsScore(score);
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
    }
    editWinner = () => {
        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {
        console.log(this.props)
        var tt23Matches, formatType = -1;
        const apiUrl = rshApi + "/tournaments/get-tournament";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.tt23Matches.finals) {
                    that.setState({ finals: result.data.finals, tournament: result.data.tournament, users: result.data.users, ttType: result.data.tt23Matches.ttType })
                }
                if (result.data.tt23Matches.ttType === 3) {

                }
                if (result.data.tournament.tournamentMode === 'team') {
                    that.setState({ teams: result.data.teams })
                }
                that.setState({ tt23Matches: result.data.tt23Matches })

                if (result.data.tournament.matchType === 'Final') {
                    that.setState({ formatType: that.props.fType })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Quarter Final') {
                    that.setState({ formatType: that.props.fType, quarterFinals: result.data.tt23Matches.quarterFinals })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Semi final') {
                    that.setState({ formatType: that.props.fType, semiFinals: result.data.tt23Matches.semiFinals })
                    formatType = that.props.fType;
                }
                console.log(that.props)
                pairObj = myUtilclass.getMatchPair(result.data.tournament, result.data.tt23Matches, true, formatType, that.props.matchId);
                //console.log(pairObj)
                that.setState({ matchPairObj: pairObj }, () => {
                    that.getScore();
                });
            });
    }

    render() {

        var byteamList = [], toteamList = [];
        this.props.profileTeams.map(pt => {
            if (pt.teamId === this.state.matchPairObj.firstId) {
                byteamList = pt.teamMembers
            }
            if (pt.teamId === this.state.matchPairObj.secondId) {
                toteamList = pt.teamMembers
            }
        })
        // byteamList = this.props.profileTeams[0].teamMembers;
        // toteamList = this.props.profileTeams[1].teamMembers;
        var userName;
        var userPic;
        var userGender;

        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.toMemberModalOpen}
                    onRequestClose={this.byMemberCloseModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div class="modal-dialog" style={{ width: '320px' }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" onClick={() => this.byMemberCloseModal()}>&times;</button>
                                <h4 class="modal-title">Goal By</h4>
                            </div>
                            <div class="replydiv likedbuddy">

                                <div class="modal-body">
                                    <div class="ac_search">
                                        {/* <input placeholder="Search buddies" />
                                        <a><img src={searchnew} alt="" /></a> */}
                                    </div>
                                    <ul class="playerlist">
                                        {toteamList.map(rb => {
                                            let userShow = false;
                                            this.props.profileUsers.map(user => {
                                                if (rb === user.contactNo) {
                                                    userShow = true;
                                                    userName = user.name;
                                                    userPic = user.profilePic;
                                                    userGender = user.gender;


                                                } return true;
                                            })
                                            if (userShow)
                                                if (userPic !== null) {
                                                    return (

                                                        <li key={rb} onClick={() => this.makePoint(rb)}>
                                                            <a class="assignimg" href="#"
                                                                style={{
                                                                    backgroundImage: "url(" + userPic + ")"
                                                                }}
                                                            >

                                                            </a>
                                                            <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (userGender === 'Male') {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + male + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + female + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                }

                                        })
                                        }
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={this.state.byMemberModalOpen}
                    onRequestClose={this.byMemberCloseModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div class="modal-dialog" style={{ width: '320px' }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" onClick={() => this.byMemberCloseModal()}>&times;</button>
                                <h4 class="modal-title">Goal By</h4>
                            </div>
                            <div class="replydiv likedbuddy">

                                <div class="modal-body">
                                    <div class="ac_search">
                                        {/* <input placeholder="Search buddies" />
                                        <a><img src={searchnew} alt="" /></a> */}
                                    </div>
                                    <ul class="playerlist">
                                        {byteamList.map(rb => {
                                            let userShow = false;
                                            this.props.profileUsers.map(user => {
                                                if (rb === user.contactNo) {
                                                    userShow = true;
                                                    userName = user.name;
                                                    userPic = user.profilePic;
                                                    userGender = user.gender;

                                                } return true;
                                            })
                                            if (userShow)
                                                if (userPic !== null) {
                                                    return (

                                                        <li key={rb} onClick={() => this.makePoint(rb)}>
                                                            <a class="assignimg" href="#"
                                                                style={{
                                                                    backgroundImage: "url(" + userPic + ")"
                                                                }}
                                                            >

                                                            </a>
                                                            <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (userGender === 'Male') {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + male + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + female + ")"
                                                                    }}
                                                                >
                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                }

                                        })
                                        }
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Point of Both Teams Must Not Be Same Score !</h3></div>


                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ selectWinnerModal: false })} >Ok</button></div>
                </Modal>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Teams</th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.byTeamGoals}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toTeamGoals}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false, toWinner: false, byWinner: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>


                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>


                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Goal</th>
                                            <th>Point Set</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.byTeamGoals ? this.state.byTeamGoals : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 2)}>2</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 3)}>3</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 5)}>5</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.toTeamGoals ? this.state.toTeamGoals : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 2)}>2</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 3)}>3</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 5)}>5</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="divborder-d">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamehead-d"><p>{this.state.byName}</p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamehead-d"><p>{this.state.toName}</p>
                                </div>
                                <div class="flex-container">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamelist-d column ">
                                        {this.state.byPlayerScores.map(ps => {
                                            let memberName, memberPic;
                                            this.props.profileUsers.map(puser => {
                                                if (puser.contactNo === ps.userId) {
                                                    memberName = puser.name;
                                                    memberPic = puser.profilePic;
                                                }
                                            })
                                            return <div key={ps.userId}>
                                                <div class="set">
                                                    <img src={memberPic ? memberPic : male} alt="" />
                                                    <p>{memberName}</p>
                                                </div>
                                                <ul>
                                                    <li> points: <p class="colorgreen-d">{ps.goalPoint}</p></li>
                                                </ul>
                                            </div>
                                        })}


                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamelist-d column bg-alt ">
                                        {this.state.toPlayerScores.map(ps => {
                                            let memberName, memberPic;
                                            this.props.profileUsers.map(puser => {
                                                if (puser.contactNo === ps.userId) {
                                                    memberName = puser.name;
                                                    memberPic = puser.profilePic;
                                                }
                                            })
                                            return <div key={ps.userId}>
                                                <div class="set">
                                                    <img src={memberPic ? memberPic : male} alt="" />
                                                    <p>{memberName}</p>
                                                </div>
                                                <ul>
                                                    <li> points: <p class="colorgreen-d">{ps.goalPoint}</p></li>
                                                </ul>
                                            </div>
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
