import React from 'react'
import teamIcon from '../img/team.png';
import playIcon from '../img/play.png';
import sad from '../img/sad.png';
import { Link } from 'react-router-dom';
import broadcastbg from '../img/broadcastbg.png';
import challengefill from '../img/challengefill.png';
import Posts from '../Posts';
import tournamentprofileimg from '../img/tournamentprofileimg.jpg'
import imageIcon from '../img/images.png';
import videosIcon from '../img/videos.png';
import addlocationIcon from '../img/addlocation.png';
import tagIcon from '../img/tag.png';
import comment from '../img/comment.png';
import likedark from '../img/likedark.png';

export default function PageLeftSection(props) {
    const user = JSON.parse(localStorage.getItem('result'));
    let UserData = [];
    return (
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 left-page-sec">
            <section className="d_leftsec useraccount">
                <div className="commanbox ">
                    <h3 className="commanhead">About Us</h3>
                    <div className="aboutleftdata">
                        <p className="page-info-left"><label>Email Id :</label>Not Provided</p>
                        <p><label>Contact : </label> Not Provided</p>
                        <p><label>Sports : </label>{props.sportsName ? props.sportsName.toString() : null}</p>
                    </div>
                    <h3 className="commanhead pageupper-space">Followers <Link to={`/page/followers/${props.pageId}`}>View All</Link></h3>
                    <ul className="aboutbuddies page-aboutbuddies">
                        {props.pageFollowers.length ? props.pageFollowers.map(pf => {
                            return <li className="page-foll-img" key={pf.contactNo}>
                                <a href={'/buddies?id=' + pf.contactNo} style={{ backgroundImage: 'url(' + pf.profilePic + ')' }} ></a>
                                <span className="page-foll-name">{pf.name} </span>
                            </li>
                        }) : <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                                <label> sorry you don't have any followers</label><br />
                                {/* <Link style={{ color: "#52af4a" }}> Invite Now</Link> */}

                            </div>}

                    </ul>

                    <h3 className="commanhead pageupper-space">Teams
                                    {props.pageTeams.length ? <Link to={`/page/teamslist/${props.pageId}`}>View All</Link>
                            :
                            props.createdByUserNo === user.contactNo ? <Link to={`/page/team/create/${props.pageId}`}>Create Now!</Link> : null
                        }</h3>

                    <ul className="aboutteamsul  page-aboutbuddies">
                        {props.pageTeams.length ? <li className="page-team-li">
                            <Link className="add-team-page" to={`/page/team/create/${props.pageId}`}>

                                <svg className="add-icon-page"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 341.4 341.4"

                                >
                                    <path
                                        fill="#2cae4a"
                                        data-original="#000000"
                                        d="M192 149.4V0h-42.6v149.4H0V192h149.4v149.4H192V192h149.4v-42.6z"
                                        xmlns="http://www.w3.org/2000/svg"
                                    />
                                </svg>
                            </Link>
                            <span className="page-foll-name add-teamtxt-d md-top">Add </span>
                        </li> : null}
                        {props.pageTeams.length ? props.pageTeams.map(pt => {
                            let teamImage = pt.teamIcon ? pt.teamIcon : teamIcon;
                            return <li className="page-team-li">
                                <Link to={`/page/team/profile?id=${pt.teamId}`} style={{ backgroundImage: 'url(' + teamImage + ')' }} >
                                </Link>
                                <span className="page-foll-name">{pt.teamName} </span>
                            </li>
                        }) :
                            <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                                <label> sorry you don't have any teams</label><br />
                            </div>}

                    </ul>

                    <h3 className="commanhead pageupper-space">Challenge {props.pageChallenge.length ? <Link to={`/page/challenge/list/${props.pageId}`} >View All</Link> : props.createdByUserNo === user.contactNo ? <Link to={`/page/challenge/create/${props.pageId}`} >Create Now!</Link> : null}
                    </h3>
                    <ul className="aboutteamsul  page-aboutbuddies">
                        {props.pageChallenge.length ? <li className="page-team-li">
                            <Link className="add-team-page" to={`/page/challenge/create/${props.pageId}`} className="add-team-page">
                                <svg className="add-icon-page"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 341.4 341.4"

                                >
                                    <path
                                        fill="#2cae4a"
                                        data-original="#000000"
                                        d="M192 149.4V0h-42.6v149.4H0V192h149.4v149.4H192V192h149.4v-42.6z"
                                        xmlns="http://www.w3.org/2000/svg"
                                    />
                                </svg>

                            </Link>
                            <span className="page-foll-name add-teamtxt-d md-top">Add </span>
                        </li> : ''}

                        {props.pageChallenge.length > 0 ? props.pageChallenge.splice(0, 1).map(pt => {
                            pt.accepted.map(acc => {
                                let UserDataObj = {
                                    name: null,
                                    pic: null,
                                    gen: null,
                                    id: null
                                }
                                props.challengeUsers.map(ch => {
                                    if (ch.contactNo === acc) {
                                        UserDataObj.id = acc;
                                        UserDataObj.pic = ch.profilePic;
                                        UserDataObj.name = ch.name;
                                        UserDataObj.gen = ch.gender;
                                        UserData.push(UserDataObj)
                                    }
                                })
                            })
                            UserData = [...new Map(UserData.map(item => [item.name, item])).values()];
                            if (UserData.length === 1) {
                                UserData = [...UserData, {
                                    id: 'NA',
                                    name: "NA",
                                    pic: null,
                                    gen: null,

                                }]
                            }
                            console.log(UserData)

                            UserData = UserData.filter((us, index) => UserData.indexOf(us) === index);
                            return <ul class="chall-view_show" >
                                <li class="page-team-li" key={UserData[0].id}>
                                    <Link to={`/challengeprofile/index.html?id=${pt.challengeId}`} style={{ backgroundImage: 'url(' + UserData[0].pic + ')' }}></Link>
                                    <span class="page-foll-name md-top">{UserData[0].name}</span>
                                </li>
                                <li class="page-team-li">
                                    <Link to={`/challengeprofile/index.html?id=${pt.challengeId}`} style={{ backgroundImage: 'url(' + challengefill + ')' }} class="challng-img-view"></Link>
                                    <span class="page-foll-name md-top"></span>
                                </li>
                                <li class="page-team-li" key={UserData[1].id}>
                                    <Link to={`/challengeprofile/index.html?id=${pt.challengeId}`} style={{ backgroundImage: 'url(' + UserData[1].pic + ')' }}></Link>
                                    <span class="page-foll-name md-top">{UserData[1].name}</span>
                                </li>
                            </ul>
                        }) :
                            <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                                <label> sorry you don't have any teams</label><br />


                            </div>}

                    </ul>
                    <h3 className="commanhead pageupper-space">Tournaments {props.pageTournaments.length ? <Link to={`/page/tournament/list/${props.pageId}`} >View All</Link> : props.createdByUserNo === user.contactNo ? <Link to={`/page/tournament/create/${props.pageId}`}>Create Now!</Link> : null}</h3>
                    <ul className="aboutteamsul  page-aboutbuddies">
                        {props.pageTournaments.length ? <li className="page-team-li">
                            <Link to={`/page/tournament/create/${props.pageId}`} className="add-team-page">
                                <svg className="add-icon-page"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 341.4 341.4"

                                >
                                    <path
                                        fill="#2cae4a"
                                        data-original="#000000"
                                        d="M192 149.4V0h-42.6v149.4H0V192h149.4v149.4H192V192h149.4v-42.6z"
                                        xmlns="http://www.w3.org/2000/svg"
                                    />
                                </svg>

                            </Link>
                            <span className="page-foll-name add-teamtxt-d md-top">Add </span>
                        </li> : ''}
                        {/* <ul className="chall-view_show"> */}
                        {props.pageTournaments.length ? props.pageTournaments.splice(0, 1).map(pt => {
                            let ticon = pt.icon ? pt.icon : tournamentprofileimg;
                            return <li className="page-team-li" key={pt.tournamentId}>
                                <Link to={`/tournamentprofile/index.html?id=${pt.tournamentId}`} style={{ backgroundImage: 'url(' + ticon + ')' }} />
                                <span className="page-foll-name">{pt.tournamentName} </span>
                            </li>
                        }) : <div style={{ textAlign: "center" }}>  <img src={sad} alt="" /><br />
                                <label> sorry you don't have any Tournament</label><br />


                            </div>}


                        {/* </ul> */}
                    </ul>
                </div>
            </section>
        </div>

    )
}
