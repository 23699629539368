import { trackPromise } from "react-promise-tracker";
import ConfigUrl from '../ConfigUrl';
import * as myFeedUtilclass from '../DemoTournament/DTournamentsUtil';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem("result"));
export const getMatchPair = (tournament, tt23, feed, fType, matchId) => {

    var matchPairObj = null;
    var ttype = tt23.ttType;
    var tt2 = tt23.tt2Matches;
    var tt3 = tt23.tt3Matches;
    var sf = tt23.semiFinals;
    if (ttype === 2) {
        var matchPairArray;
        if (fType === -1) {
            matchPairArray = tt2.filter(function (obj) {
                return obj.matchId === matchId;
            });
            if (matchPairArray.length > 0) {
                matchPairObj = matchPairArray[0];
            }

        }
        if (fType === 1) {
            if (matchPairObj === null) {
                tt23.quarterFinals.filter(function (obj) {
                    return obj.matchId === matchId;
                });
                if (matchPairArray.length > 0) {
                    matchPairObj = matchPairArray[0];
                }
            }

        }
        else if (fType === 2) {
            if (matchPairObj === null) {
                matchPairArray = tt23.semiFinals.filter(function (obj) {
                    return obj.matchId === matchId;
                });
                if (matchPairArray.length > 0) {
                    matchPairObj = matchPairArray[0];
                }
            }

        }
        else if (fType === 3) {
            if (matchPairObj === null) {
                if (Object.values(tt23.finals).indexOf(matchId) > -1) {
                    matchPairObj = tt23.finals;
                }
            }
        }
    }
    else {
        console.log(matchId)
        var matchPairArray = [];
        if (fType === -2) {
            tt3.forEach(tt3 => {
                console.log(tt3.matches)
                tt3.matches.filter(match => {
                    if (match.matchId === matchId) {
                        matchPairArray.push(match);
                    }
                })
                // matchPairArray = tt3.matches.filter(function (obj) {
                //     return obj.matchId === matchId;

                // })
            })
            console.log(matchPairArray)
            // matchPairArray = tt3.filter(function (obj) {
            //     return obj.matchId === matchId;
            // });
            if (matchPairArray.length > 0) {
                matchPairObj = matchPairArray[0];
            }
            console.log(matchPairObj)
        }
        if (fType === 1) {
            if (matchPairObj === null) {

            }
        }
        else if (fType === 2) {
            if (matchPairObj === null) {

            }
        }
        else if (fType === 3) {
            if (matchPairObj === null) {

            }
        }
    }
    if (feed) {
        // var tmatchFeed = myFeedUtilclass.tt123MatchFeeds(tournament.tournamentId, matchId, tournament.tournamentMode, matchPairObj.firstId, matchPairObj.secondId, tournament.createdBy);
        // tmatchFeed.then(tmatchFeed => {

        // });
        return matchPairObj;
    }
    else {
        return matchPairObj;
    }
}
export const declareMatchWinner = async (fType, organiseMatchObj, matchPairObj, winnerId, nrr1, nrr2) => {
    if (fType === -1) {
        var updatePointTable = await myFeedUtilclass.updatePointTable(organiseMatchObj.tournamentId, matchPairObj.firstId, matchPairObj.secondId, nrr1, nrr2, winnerId);
        var updateOrganize = await myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);

    }
    if (fType === -2) {



        var tournament = await getTournament(organiseMatchObj.tournamentId);
        let winnerPoints = tournament.data.tournament.winnerPoints;
        let drawPoints = tournament.data.tournament.drawPoints;
        let looserPoints = tournament.data.tournament.looserPoints;
        let winnerId = matchPairObj.winnerId;
        let firstId = matchPairObj.firstId;
        let secondId = matchPairObj.secondId;


        organiseMatchObj.tt3Matches.forEach(tt3 => {
            delete tt3.pointsTable.id;
            delete tt3.pointsTable.tournamentId;
            tt3.pointsTable.pointsList.forEach(pl => {

                if (pl.teamId === firstId) {
                    if (winnerId) {
                        if (firstId === winnerId) {
                            pl.matchesPlayed = pl.matchesPlayed + 1;
                            pl.totalPoints = pl.totalPoints + winnerPoints;
                            pl.matchesWon = pl.matchesWon + 1;

                        }
                        else {
                            pl.matchesPlayed = pl.matchesPlayed + 1;
                            pl.matchesLost = pl.matchesLost + 1;
                            pl.totalPoints = pl.totalPoints + looserPoints;
                            pl.matchesPlayed = 1;
                        }
                    }
                    else {
                        pl.matchesPlayed = pl.matchesPlayed + 1;
                        pl.totalPoints = pl.totalPoints + drawPoints;
                        pl.matchesDraw = pl.matchesDraw + 1;
                    }

                }
                if (pl.teamId === secondId) {
                    if (winnerId) {
                        if (secondId === winnerId) {
                            pl.matchesPlayed = pl.matchesPlayed + 1;
                            pl.totalPoints = pl.totalPoints + winnerPoints;
                            pl.matchesWon = pl.matchesWon + 1;

                        }
                        else {
                            pl.matchesPlayed = pl.matchesPlayed + 1;
                            pl.matchesLost = pl.matchesLost + 1;
                            pl.totalPoints = pl.totalPoints + looserPoints;
                            pl.matchesPlayed = 1;
                        }
                    }
                    else {
                        pl.matchesPlayed = pl.matchesPlayed + 1;
                        pl.totalPoints = pl.totalPoints + drawPoints;
                        pl.matchesDraw = pl.matchesDraw + 1;
                    }
                }
            })
        })

        var updateOrganize = await myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);
    }
    if (fType === 1) {

        var updateOrganize = await myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);

    }
    if (fType === 2) {

        var updateOrganize = await myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);

    }
    if (fType === 3) {

        var updateOrganize = await myFeedUtilclass.updateOrganizeTT23(organiseMatchObj.tournamentId, organiseMatchObj);
        // var updateOrganize = await myFeedUtilclass.ttWinnerFeeds(organiseMatchObj.tournamentId, matchPairObj.matchId, winnerId);
    }

}


export const getStatusOfNextRound = (type, list) => {

    if (type === 1) //leauge
    {
        let data = true;
        list.map(list => {
            if (list.ended === false) {
                data = false;
            }
        })
        return data;
    }
    if (type === 2) //quarterFinal
    {
        let data = true;
        list.map(list => {
            if (list.ended === false) {
                data = false;
            }
        })
        return data;
    }
    if (type === 3) //semiFinal
    {
        let data = true;
        list.map(list => {
            if (list.firstId === null && list.secondId === null) {
                data = false;
            }
        })
        return data;
    }
    if (type === 4) //final
    {
        console.log(list)
        let data = true;
        if (list.firstId === null && list.secondId === null) {
            data = false;
        }

        return data;
    }
}


const getTournament = async (tId) => {
    var apiUrl = userApi + "/demo-tournaments/get-tournament";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "data": {
                "tournamentId": tId,
            }
        })
    });
    let tournamentResponse = await response.json();
    return tournamentResponse;
}



