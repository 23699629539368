import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '..//..//ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import finallogo from '..//..//img/finallogo.png';
import broadcastbg from '..//..//img/broadcastbg.png';
import play from '..//..//img/play.png';
import $ from 'jquery';
import logo from '..//..//img/finallogo.png';
import male from "..//..//img/male.png";
import female from "..//..//img/female.png";
import searchnew from "..//..//img/searchnew.png";
import teamIcons from '..//..//img/team.png';

var score;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var d107BasketballScoreCard;
const winningScore = 11;
let byPic, toPic;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class DemoUpdated107Basketball extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107BasketballScoreCard: {},
            selectWinnerModal: false,
            byMemberModalOpen: false,
            byMemberModalOpen: false,
            pointValue: '',
            score: false,
            byTeamGoals: '',
            toTeamGoals: '',
            byPlayerScores: [],
            toPlayerScores: [],
            tieWinner: false,
            byWinner: false,
            toWinner: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.byMemberCloseModal = this.byMemberCloseModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ winnerModalOpen: false });
    }

    doneMatch() {
        let d107ScoreCard = this.state.d107BasketballScoreCard;
        if (this.state.byWinner) {
            d107ScoreCard.byBasketballScoreCard.winner = true;
        }
        if (this.state.toWinner) {
            d107ScoreCard.toBasketballScoreCard.winner = true;
        }
        score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d107BasketballScoreCard: d107ScoreCard,
        }


        DemoUpdateChallengeScore(score);
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = this.state.winner;
        let that = this;
        const apiUrl = rshApi + "/demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ winnerModalOpen: false });
                window.location = "/dchallengeprofile/index.html?id=" + that.props.challenge.challengeId;

            });
    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }

    backState = () => {
        $("#setAttemptModal .close").click();

        window.location = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
    }

    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }
    endEvent() {

        let byTotal = this.state.d107BasketballScoreCard.byBasketballScoreCard.teamGoals;
        let toTotal = this.state.d107BasketballScoreCard.toBasketballScoreCard.teamGoals;
        if (byTotal > toTotal) {
            this.setState({ byWinner: true, winner: this.props.challenge.accepted[0] })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal > byTotal) {
            this.setState({ toWinner: true, winner: this.props.challenge.accepted[1] })
            this.setState({ winnerModalOpen: true })
        }
        if (byTotal === toTotal) {
            this.setState({ selectWinnerModal: true })
        }


    }
    getScore() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        let byName; let toName;
                        d107BasketballScoreCard = {
                            "byBasketballScoreCard": {
                                "teamGoals": 0,
                                "teamId": that.props.challenge.accepted[0],
                                "winner": false,
                                "playerScores": [],
                                "position": 1
                            },
                            "toBasketballScoreCard": {
                                "teamGoals": 0,
                                "teamId": that.props.challenge.accepted[1],
                                "winner": false,
                                "playerScores": [],
                                "position": 1
                            },
                            "strokeNo": 0
                        }
                        var score = {
                            challengeId: that.props.challenge.challengeId,
                            matchId: that.props.challenge.matchId,
                            d107BasketballScoreCard: d107BasketballScoreCard,
                        }
                        that.setState({ d107BasketballScoreCard: d107BasketballScoreCard });
                        DemoUpdateChallengeScore(score);

                        that.props.profileTeams.map(user => {
                            if (that.props.challenge.accepted[0] === user.teamId) {
                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.props.challenge.accepted[1] === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                    }
                    else {
                        d107BasketballScoreCard = result.data.score.d107BasketballScoreCard;
                        if (result.data.score.d107BasketballScoreCard.byBasketballScoreCard !== null) {
                            that.setState({ byTeamGoals: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.teamGoals, byPlayerScores: result.data.score.d107BasketballScoreCard.byBasketballScoreCard.playerScores });
                        }
                        if (result.data.score.d107BasketballScoreCard.toBasketballScoreCard !== null) {
                            that.setState({ toTeamGoals: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.teamGoals, toPlayerScores: result.data.score.d107BasketballScoreCard.toBasketballScoreCard.playerScores });
                        }
                        that.setState({ score: true, d107BasketballScoreCard: result.data.score.d107BasketballScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, strokeNo: result.data.score.d107BasketballScoreCard.strokeNo });
                        let byName; let toName;

                        that.props.profileTeams.map(user => {
                            if (that.props.challenge.accepted[0] === user.teamId) {
                                byName = user.teamName
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.props.challenge.accepted[1] === user.teamId) {
                                toName = user.teamName
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })

                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    }
                });
        }
    }
    selectPlayerPoint(type, val) {
        this.setState({ pointValue: val })
        if (type === 'by') {
            this.setState({ byMemberModalOpen: true, toMemberModalOpen: false })
        }
        else {
            this.setState({ byMemberModalOpen: false, toMemberModalOpen: true })
        }
    }
    byMemberCloseModal() {
        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
    }

    makePoint(memberID) {
        var pointValue = this.state.pointValue;
        var type = this.state.byMemberModalOpen ? 'by' : 'to'
        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
        if (this.state.score) {
            if (type === 'by') {
                if (d107BasketballScoreCard.byBasketballScoreCard !== null) {
                    var playerScoresList = d107BasketballScoreCard.byBasketballScoreCard.playerScores;
                    var pscoreObj = {
                        goalPoint: pointValue,
                        position: d107BasketballScoreCard.byBasketballScoreCard.position,
                        strokeNo: d107BasketballScoreCard.strokeNo + 1,
                        userId: memberID,
                    }
                    d107BasketballScoreCard.strokeNo = d107BasketballScoreCard.strokeNo + 1;
                    playerScoresList.push(pscoreObj);
                    d107BasketballScoreCard.byBasketballScoreCard.playerScores = playerScoresList;
                    d107BasketballScoreCard.byBasketballScoreCard.position = d107BasketballScoreCard.byBasketballScoreCard.position + 1;
                    d107BasketballScoreCard.byBasketballScoreCard.teamGoals = d107BasketballScoreCard.byBasketballScoreCard.teamGoals + pointValue;
                    let teamGoal = d107BasketballScoreCard.byBasketballScoreCard.teamGoals;
                    this.setState({ byPlayerScores: d107BasketballScoreCard.byBasketballScoreCard.playerScores });
                    this.setState({ byTeamGoals: teamGoal });
                }
                else {
                    var pscoreObj = {
                        goalPoint: pointValue,
                        position: 0,
                        strokeNo: d107BasketballScoreCard.strokeNo + 1,
                        userId: memberID,
                    }
                    d107BasketballScoreCard.strokeNo = d107BasketballScoreCard.strokeNo + 1;
                    let byBasketballScoreCard = {
                        playerScores: [pscoreObj]
                    }
                    d107BasketballScoreCard.byBasketballScoreCard = byBasketballScoreCard;
                    d107BasketballScoreCard.byBasketballScoreCard.position = 1;
                    d107BasketballScoreCard.byBasketballScoreCard.teamGoals = pointValue;
                    d107BasketballScoreCard.toBasketballScoreCard.teamId = this.props.challenge.accepted[0];
                    d107BasketballScoreCard.toBasketballScoreCard.winner = false;
                    let teamGoal = pointValue;
                    this.setState({ byPlayerScores: d107BasketballScoreCard.byBasketballScoreCard.playerScores });
                    this.setState({ byTeamGoals: teamGoal });
                }

            }
            if (type === 'to') {
                if (d107BasketballScoreCard.toBasketballScoreCard !== null) {
                    var pscoreObj = {
                        goalPoint: pointValue,
                        position: d107BasketballScoreCard.toBasketballScoreCard.position,
                        strokeNo: d107BasketballScoreCard.strokeNo + 1,
                        userId: memberID,
                    }
                    d107BasketballScoreCard.toBasketballScoreCard.playerScores = [...d107BasketballScoreCard.toBasketballScoreCard.playerScores, pscoreObj];
                    d107BasketballScoreCard.strokeNo = d107BasketballScoreCard.strokeNo + 1;
                    d107BasketballScoreCard.toBasketballScoreCard.position = d107BasketballScoreCard.toBasketballScoreCard.position + 1;
                    d107BasketballScoreCard.toBasketballScoreCard.teamGoals = d107BasketballScoreCard.toBasketballScoreCard.teamGoals + pointValue;
                    let teamGoal = d107BasketballScoreCard.toBasketballScoreCard.teamGoals;
                    this.setState({ toTeamGoals: teamGoal, toPlayerScores: d107BasketballScoreCard.toBasketballScoreCard.playerScores });
                }
                else {
                    var pscoreObj = {
                        goalPoint: pointValue,
                        position: 0,
                        strokeNo: d107BasketballScoreCard.strokeNo + 1,
                        userId: memberID,
                    }
                    d107BasketballScoreCard.strokeNo = d107BasketballScoreCard.strokeNo + 1;
                    let toBasketballScoreCard = {
                        playerScores: [pscoreObj]
                    }
                    d107BasketballScoreCard.toBasketballScoreCard = toBasketballScoreCard;
                    d107BasketballScoreCard.toBasketballScoreCard.position = 1;
                    d107BasketballScoreCard.toBasketballScoreCard.teamGoals = pointValue;
                    d107BasketballScoreCard.toBasketballScoreCard.teamId = this.props.challenge.accepted[1];
                    d107BasketballScoreCard.toBasketballScoreCard.winner = false;
                    let teamGoal = pointValue;
                    this.setState({ toTeamGoals: teamGoal, toPlayerScores: d107BasketballScoreCard.toBasketballScoreCard.playerScores });
                }
            }
            score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107BasketballScoreCard: d107BasketballScoreCard,
            }
            this.setState({ d107BasketballScoreCard: d107BasketballScoreCard });

            DemoUpdateChallengeScore(score);
        }
        else {
            var playerScores = [];
            var pscoreObj = {
                goalPoint: pointValue,
                position: 0,
                strokeNo: 1,
                userId: memberID,
            }
            playerScores.push(pscoreObj);
            const that = this;
            if (type === 'by') {
                d107BasketballScoreCard = {
                    strokeNo: 1,
                    byBasketballScoreCard: {
                        playerScores,
                        position: 1,
                        teamGoals: pointValue,
                        teamId: this.props.challenge.accepted[0],
                        winner: false
                    },
                    toBasketballScoreCard: null
                }
                let teamGoal = pointValue;
                this.setState({ byTeamGoals: teamGoal });
                this.setState({ byPlayerScores: d107BasketballScoreCard.byBasketballScoreCard.playerScores });
            }
            else {
                d107BasketballScoreCard = {
                    strokeNo: 1,
                    byBasketballScoreCard: null,
                    toBasketballScoreCard: {
                        playerScores,
                        position: 1,
                        teamGoals: pointValue,
                        teamId: this.props.challenge.accepted[1],
                        winner: false
                    }
                }
                let teamGoal = pointValue;
                this.setState({ toTeamGoals: teamGoal });
                this.setState({ toPlayerScores: d107BasketballScoreCard.toBasketballScoreCard.playerScores });
            }

            score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107BasketballScoreCard: d107BasketballScoreCard,
            }
            this.setState({ d107BasketballScoreCard: d107BasketballScoreCard, score: true });
            DemoUpdateChallengeScore(score);
        }
    }



    ToundoList = () => {
        // var d107 = this.state.d107BasketballScoreCard;
        var strokeNo = d107BasketballScoreCard.strokeNo;
        var toUndo;
        var bygoalPoints;
        var togoalPoints;
        var bystokeMatched = false;
        var tostokeMatched = false;
        if (d107BasketballScoreCard.byBasketballScoreCard !== null) {
            d107BasketballScoreCard.byBasketballScoreCard.playerScores.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    bystokeMatched = true;
                    bygoalPoints = list.goalPoint;
                }
            })
            if (bystokeMatched) {
                var toUndoList = d107BasketballScoreCard.byBasketballScoreCard.playerScores.filter((x) => x != toUndo)
                d107BasketballScoreCard.strokeNo = strokeNo - 1;
                if (d107BasketballScoreCard.byBasketballScoreCard.teamGoals !== 0) {
                    d107BasketballScoreCard.byBasketballScoreCard.teamGoals = d107BasketballScoreCard.byBasketballScoreCard.teamGoals - bygoalPoints;
                    d107BasketballScoreCard.byBasketballScoreCard.position = d107BasketballScoreCard.byBasketballScoreCard.position - 1;
                }
                d107BasketballScoreCard.byBasketballScoreCard.playerScores = toUndoList;
                this.setState({ byPlayerScores: toUndoList, byTeamGoals: d107BasketballScoreCard.byBasketballScoreCard.teamGoals })
            }
        }
        if (d107BasketballScoreCard.toBasketballScoreCard !== null) {
            d107BasketballScoreCard.toBasketballScoreCard.playerScores.map(list => {
                if (list.strokeNo === strokeNo) {
                    toUndo = list;
                    tostokeMatched = true;
                    togoalPoints = list.goalPoint;
                }
            })
            if (tostokeMatched) {
                var toUndoList = d107BasketballScoreCard.toBasketballScoreCard.playerScores.filter((x) => x != toUndo)
                d107BasketballScoreCard.strokeNo = strokeNo - 1;
                if (d107BasketballScoreCard.toBasketballScoreCard.teamGoals !== 0) {
                    d107BasketballScoreCard.toBasketballScoreCard.teamGoals = d107BasketballScoreCard.toBasketballScoreCard.teamGoals - togoalPoints;
                    d107BasketballScoreCard.toBasketballScoreCard.position = d107BasketballScoreCard.toBasketballScoreCard.position - 1;
                }
                d107BasketballScoreCard.toBasketballScoreCard.playerScores = toUndoList;
                this.setState({ toPlayerScores: toUndoList, toTeamGoals: d107BasketballScoreCard.toBasketballScoreCard.teamGoals })
            }
        }
        this.setState({ d107BasketballScoreCard: d107BasketballScoreCard });

        score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d107BasketballScoreCard: d107BasketballScoreCard,
        }
        DemoUpdateChallengeScore(score);
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
    }


    editWinner = () => {
        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {
        this.getScore();
    }




    render() {

        var byteamList = [], toteamList = [];
        this.props.profileTeams.map(pt => {
            if (pt.teamId === this.props.challenge.accepted[0]) {
                byteamList = pt.teamMembers
            }
            if (pt.teamId === this.props.challenge.accepted[1]) {
                toteamList = pt.teamMembers
            }
        })
        // byteamList = this.props.profileTeams[0].teamMembers;
        // toteamList = this.props.profileTeams[1].teamMembers;
        var userName;
        var userPic;
        var userGender;

        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Point of Both  Teams Must Not Be Same Score !</h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ selectWinnerModal: false })} >Ok</button></div>
                </Modal>




                <Modal
                    isOpen={this.state.toMemberModalOpen}
                    onRequestClose={this.byMemberCloseModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div class="modal-dialog" style={{ width: '320px' }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" onClick={() => this.byMemberCloseModal()}>&times;</button>
                                <h4 class="modal-title">Goal By</h4>
                            </div>
                            <div class="replydiv likedbuddy">

                                <div class="modal-body">
                                    <div class="ac_search">
                                        {/* <input placeholder="Search buddies" />
                                        <a><img src={searchnew} alt="" /></a> */}
                                    </div>
                                    <ul class="playerlist">
                                        {toteamList.map(rb => {
                                            let userShow = false;
                                            this.props.profileUsers.map(user => {
                                                if (rb === user.contactNo) {
                                                    userShow = true;
                                                    userName = user.name;
                                                    userPic = user.profilePic;
                                                    userGender = user.gender;


                                                } return true;
                                            })
                                            if (userShow)
                                                if (userPic !== null) {
                                                    return (

                                                        <li key={rb} onClick={() => this.makePoint(rb)}>
                                                            <a class="assignimg" href="#"
                                                                style={{
                                                                    backgroundImage: "url(" + userPic + ")"
                                                                }}
                                                            >

                                                            </a>
                                                            <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (userGender === 'Male') {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + male + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + female + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                }

                                        })
                                        }
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={this.state.byMemberModalOpen}
                    onRequestClose={this.byMemberCloseModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div class="modal-dialog" style={{ width: '320px' }}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" onClick={() => this.byMemberCloseModal()}>&times;</button>
                                <h4 class="modal-title">Goal By</h4>
                            </div>
                            <div class="replydiv likedbuddy">

                                <div class="modal-body">
                                    <div class="ac_search">
                                        {/* <input placeholder="Search buddies" />
                                        <a><img src={searchnew} alt="" /></a> */}
                                    </div>
                                    <ul class="playerlist">
                                        {byteamList.map(rb => {
                                            let userShow = false;
                                            this.props.profileUsers.map(user => {
                                                if (rb === user.contactNo) {
                                                    userShow = true;
                                                    userName = user.name;
                                                    userPic = user.profilePic;
                                                    userGender = user.gender;

                                                } return true;
                                            })
                                            if (userShow)
                                                if (userPic !== null) {
                                                    return (

                                                        <li key={rb} onClick={() => this.makePoint(rb)}>
                                                            <a class="assignimg" href="#"
                                                                style={{
                                                                    backgroundImage: "url(" + userPic + ")"
                                                                }}
                                                            >

                                                            </a>
                                                            <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                        </li>
                                                    );
                                                }
                                                else {
                                                    if (userGender === 'Male') {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + male + ")"
                                                                    }}
                                                                >

                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li key={rb.timestamp} onClick={() => this.makePoint(rb)}>
                                                                <a class="assignimg" href="#"
                                                                    style={{
                                                                        backgroundImage: "url(" + female + ")"
                                                                    }}
                                                                >
                                                                </a>
                                                                <span class="ac_name" style={{ fontWeight: '800' }}>{userName}</span>

                                                            </li>
                                                        );
                                                    }
                                                }

                                        })
                                        }
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Teams</th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.byTeamGoals}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toTeamGoals}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false, toWinner: false, byWinner: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>


                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Goal</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.byTeamGoals ? this.state.byTeamGoals : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 1)}>1</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 2)}> 2</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("by", 3)}> 3</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.toTeamGoals ? this.state.toTeamGoals : 0}</td>
                                            <td>
                                                <div>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 1)}>1</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 2)}>2</span></a>
                                                    <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("to", 3)}> 3</span></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="divborder-d">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamehead-d"><p>{this.state.byName}</p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamehead-d"><p>{this.state.toName}</p>
                                </div>
                                <div class="flex-container">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamelist-d column ">
                                        {this.state.byPlayerScores.map(ps => {
                                            let memberName, memberPic;
                                            this.props.profileUsers.map(puser => {
                                                if (puser.contactNo === ps.userId) {
                                                    memberName = puser.name;
                                                    memberPic = puser.profilePic;
                                                }
                                            })
                                            return <div key={ps.userId}>
                                                <div class="set">
                                                    <img src={memberPic ? memberPic : male} alt="" />
                                                    <p>{memberName}</p>
                                                </div>
                                                <ul>
                                                    <li> Points: <p class="colorgreen-d">{ps.goalPoint}</p></li>
                                                </ul>
                                            </div>
                                        })}


                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 teamnamelist-d column bg-alt ">
                                        {this.state.toPlayerScores.map(ps => {
                                            let memberName, memberPic;
                                            this.props.profileUsers.map(puser => {
                                                if (puser.contactNo === ps.userId) {
                                                    memberName = puser.name;
                                                    memberPic = puser.profilePic;
                                                }
                                            })
                                            return <div key={ps.userId}>
                                                <div class="set">
                                                    <img src={memberPic ? memberPic : male} alt="" />
                                                    <p>{memberName}</p>
                                                </div>
                                                <ul>
                                                    <li> Points: <p class="colorgreen-d">{ps.goalPoint}</p></li>
                                                </ul>
                                            </div>
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}