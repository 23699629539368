import React, { Component } from 'react'
import Modal from 'react-modal';
import Modal1 from 'react-bootstrap/Modal'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import male from "../img/male.png";
import $ from 'jquery';
var scoreCardD103 = [];
var d103ScoreCard = {}, score, scoreCardD103s = {};
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var maxWeights = [];

export default class Challenge_d103 extends Component {
    constructor(props) {
        super(props);
        this.props.challenge.accepted.map(x => {
            scoreCardD103.push({ 'userId': x, 'points': 0.0, 'winner': false });
        });
        this.state = {
            scoreCardD103s: scoreCardD103,
            playerPoint: '',
            errorShow: false,
            maxPoint: [],
            checked: true,
            timepickerset: false,
        };
        this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    doneMatch() {
        var winner;
        if (this.state.selectWinner === undefined) {
            winner = selectedWinner;
        }
        else {
            winner = this.state.selectWinner;
        }
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winner;
        let that = this;
        const apiUrl = rshApi + "challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ winnerModalOpen: false });
                window.location = "/challengeprofile/index.html?id=" + that.props.challenge.challengeId;

            });
    }

    savePoint() {
        if (this.state.playerPoint === '') {
            //  $('#errorId').show();
            this.setState({ errorShow: true });
        }
        else {
            let that = this;
            scoreCardD103.map((point, index) => {
                if (index === this.state.setIndex) {
                    point.points = parseFloat(this.state.playerPoint);
                }
            });

            //$("#myModal .close").click();
            this.setState({ timepickerset: false })
            this.setState({ playerPoint: '', scoreCardD103s: scoreCardD103 });
            scoreCardD103s = {
                scoreCardD103s: scoreCardD103
            }
            score = {
                challengeId: that.props.challenge.challengeId,
                matchId: that.props.challenge.challengeId,
                d103ScoreCard: scoreCardD103s
            }
            UpdateChallengeScore(score);
        }
    }

    inputPoint(event) {
        if (event.target.value.length <= 6) {
            console.log(event.target.value.length)
            this.setState({ playerPoint: event.target.value, errorShow: false });
        }
        if (event.target.value.length === 0) {
            console.log(event.target.value)
            this.setState({ errorShow: true });
        }
    }

    endEvent() {
        var showFillModal = false;
        this.state.scoreCardD103s.map(point => {
            if (point.points < 1) {

                showFillModal = true;

            }
        });
        if (showFillModal) {
            // $('#myModalOpen').click();
            this.setState({ timepickerset: true })
        }
        else {
            maxWeights = [];
            const max = this.state.scoreCardD103s.reduce((prev, current) => (prev.points > current.points) ? prev : current)
            this.state.scoreCardD103s.map((x, index) => {
                if (x.points === max.points) {
                    maxWeights.push(x);
                }
            });
            this.setState({ maxPoint: maxWeights });
            $('#myModalOpen1').click();
        }

    }
    componentDidMount() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {
                        that.setState({ modalIsOpen: true });
                    }
                    if (result.data.score !== null) {
                        if (result.data.score.d103ScoreCard) {
                            if (result.data.score.d103ScoreCard.scoreCardD103s !== null) {
                                that.setState({ scoreCardD103s: result.data.score.d103ScoreCard.scoreCardD103s });
                                scoreCardD103 = result.data.score.d103ScoreCard.scoreCardD103s;
                            }
                        }
                    }
                    else {
                    }
                });
        }
    }

    timepickersetHandel = () => {
        this.setState({ timepickerset: true })
    }

    render() {
        const backLinks = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
        return (
            <React.Fragment>
                <div id="myModal1" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Enter Point</h4>
                            </div>
                            <div class="modal-body">
                                <center>
                                    <h5>Please Fill Score Point</h5></center>
                            </div>
                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen" data-toggle="modal" data-target="#myModal1" />

                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Challange Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul class="resultul">
                                    {this.state.maxPoint.map((weight, index) => {

                                        var userName, userPic;
                                        selectedWinner = weight.userId;
                                        this.props.profileUsers.map(user => {

                                            if (weight.userId === user.contactNo) {
                                                userName = user.name;
                                                userPic = user.profilePic ? user.profilePic : male;
                                                
                                            }
                                        })

                                        return <li style={{ 'display': 'flex' }}>
                                            <a class="winnerselect">
                                                <span class="resuserimg" style={{ backgroundImage: "url(" + userPic + ")" }}></span>
                                                <div class="resinfo">
                                                    <label>{userName} </label>
                                                    <small>Score:{weight.points} </small>
                                                </div>

                                                <div className="styled-input-single" style={{ 'position': 'relative' }}>

                                                    <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                    <label htmlFor={index}></label>
                                                </div>
                                            </a>
                                        </li>
                                    })}

                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                <a data-dismiss="modal" class="cmnbutton editbtn">Edit</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />
                <Modal1
                    size="md"
                    show={this.state.timepickerset}
                    onHide={() => this.timepickersetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Enter Point</h4>
                        </div>
                        <div class="modal-body">
                            <ul class="winnerdropdown_ul">
                                <li>
                                    <input min="1" step="0.01" onKeyDown={e => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} type="number" onChange={this.inputPoint} placeholder="Enter Points" value={this.state.playerPoint} />
                                    {this.state.errorShow ? <h6 id="errorId" style={{ color: 'red', float: 'left', marginTop: '5px' }} className="errorClass"> *Please Enter Point First</h6> : ''}
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" data-dismiss="modal" onClick={() => this.setState({ timepickerset: false })}>Decline</a>
                            <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.savePoint()} >Save</button>
                        </div>
                    </div>
                </Modal1>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        {/* <div class="broadcast_video">
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul class="winnerdropdown_ul">
                            {this.state.scoreCardD103s.map((accepted, index) => {
                                var name, contactNo, point;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;
                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" value={point} data-toggle="modal" onClick={() => this.setState({ setIndex: index, errorShow: false, timepickerset: true })} />
                                </li>
                            })}
                        </ul>
                        <div class="table_actions winner_actions">
                            <a class="cmnbutton backbtn fl" href={backLinks}>Back</a>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
