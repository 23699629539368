import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import ModalWinner from 'react-bootstrap/Modal'
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import logo from '../img/finallogo.png';
import ConfigUrl from '../ConfigUrl';
import * as myUtilclass from '../FixtureUtil';
import * as myTournamentUtilclass from '../TournamentsUtil';
import teamIcons from '../img/team.png';
let pairObj;


let byName; let toName;
let byPic, toPic;
let toWinner = false, byWinner = false;
var score;
const challengeAction = "ended";
var inngs = 0;
var strokeNo;
var teamBatFirst;
var selectedWinners = undefined;
var toAdd = [];
var toUndos = [];
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class Tournament_Baseball_tt1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            byTotalHits: 0,
            toTotalHits: 0,
            byTotalRuns: 0,
            toTotalRuns: 0,
            setNumber: 1,
            numberofSets: 9,
            batFirstTeam: '',
            rounds: [],
            d107BaseBallScoreCard: {},
            hits: 0,
            runs: 0,
            wickets: 0,
            selectSetWon: false,
            modalIsOpen: false,
            selectWinnerModal: false,
            winners: undefined,
            byWinner: false,
            toWinner: false,
            isByPlusDisable: false,
            isToPlusDisable: false,
            isyoPlusDisable: false,
            isundoDisable: false,
            errorBats: false,
            matchPairObj: {},
        };
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, selectSetWon: false });
        window.location.reload(false);
    }

    doneMatch() {
        let d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
        if (selectedWinners) {
            if (byWinner) {
                d107BaseBallScoreCard.byBaseballScoreCard.winner = true;
                d107BaseBallScoreCard.toBaseballScoreCard.winner = false;
            }
            else {
                d107BaseBallScoreCard.toBaseballScoreCard.winner = true;
                d107BaseBallScoreCard.byBaseballScoreCard.winner = false;
            }
            var winner;
            if (selectedWinners !== undefined) {
                winner = selectedWinners;
            }
            if (this.state.winner == undefined) {
                winner = this.state.winners;
            }

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107BaseBallScoreCard: d107BaseBallScoreCard,
            }
            UpdateTournamentsScore(score);
            let fixture;
            if (this.props.tt1.fixtureType1) {
                fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
            }
            else {
                let poolNo;
                if (this.props.fType === 'A') {
                    poolNo = 0;
                }
                if (this.props.fType === 'B') {
                    poolNo = 1;
                }
                if (this.props.fType === 'C') {
                    poolNo = 2;
                }
                if (this.props.fType === 'D') {
                    poolNo = 3;
                }
                if (this.props.fType === 'F') {
                    poolNo = 6;
                }
                fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
            }

            if (fixture.finalWinner) {
                let tournament = this.props.tournament;
                tournament.status = "completed";
                tournament.tournamentWinnerId = this.state.winner;
                let tId = fixture.tournamentId;
                delete fixture.id;
                delete fixture.finalWinner;
                let that = this;
                delete tournament.id;
                myTournamentUtilclass.updateTournament(tournament).then(data => {
                    myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                        myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                            window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                        })
                    });
                })
            }
            else {
                let tId = fixture.tournamentId;
                delete fixture.id;
                let that = this;
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                    window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                });
            }


        }
        else {

            var winner;
            if (selectedWinners !== undefined) {
                winner = selectedWinners;
            }
            if (this.state.winner == undefined) {
                winner = this.state.winners;
            }

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107BaseBallScoreCard: d107BaseBallScoreCard,
            }
            UpdateTournamentsScore(score);
            let fixture;
            if (this.props.tt1.fixtureType1) {
                fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
            }
            else {
                let poolNo;
                if (this.props.fType === 'A') {
                    poolNo = 0;
                }
                if (this.props.fType === 'B') {
                    poolNo = 1;
                }
                if (this.props.fType === 'C') {
                    poolNo = 2;
                }
                if (this.props.fType === 'D') {
                    poolNo = 3;
                }
                if (this.props.fType === 'F') {
                    poolNo = 6;
                }
                fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
            }

            if (fixture.finalWinner) {
                let tournament = this.props.tournament;
                tournament.status = "completed";
                tournament.tournamentWinnerId = this.state.winner;
                let tId = fixture.tournamentId;
                delete fixture.id;
                delete fixture.finalWinner;
                let that = this;
                delete tournament.id;
                myTournamentUtilclass.updateTournament(tournament).then(data => {
                    myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                        myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                            window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                        })
                    });
                })
            }
            else {
                let tId = fixture.tournamentId;
                delete fixture.id;
                let that = this;
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                    window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                });
            }
        }

    }

    componentDidMount() {

        if (this.props.tt1.fixtureType1) {
            console.log(this.props.tt1)
            console.log(this.props.matchId)
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
            console.log(pairObj)
        }

        this.setState({ matchPairObj: pairObj });

        let byName, toName;
        if (this.props.tournament.tournamentMode === 'teams') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }

        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }

    getScore() {
        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    users: result.data.users,
                    teams: result.data.teams
                })

                if (result.data.score !== null) {

                    if (result.data.score.d107BaseBallScoreCard !== null) {

                        if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== null) {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: 0, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                        }

                        if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== null) {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: 0, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                        }

                        teamBatFirst = result.data.score.d107BaseBallScoreCard.batFirstTeam;

                        //storing set numbers, rounds ... etc 
                        that.setState({ d107BaseBallScoreCard: result.data.score.d107BaseBallScoreCard, rounds: result.data.score.d107BaseBallScoreCard.rounds, setNumber: result.data.score.d107BaseBallScoreCard.setNo, numberofSets: result.data.score.d107BaseBallScoreCard.noOfSets, batFirstTeam: result.data.score.d107BaseBallScoreCard.batFirstTeam })
                        //storing byteam and toteam

                        console.log(that.props.tournament)
                        var matchIdSpilit = that.props.matchId;
                        var ar = matchIdSpilit.split('-'); // split string on comma space
                        console.log(ar)

                        if (that.props.tournament.tournamentMode == 'buddy') {

                        }
                        else {
                            that.props.profileTeams.map(user => {
                                if (that.state.matchPairObj.firstId === user.teamId) {
                                    byName = user.teamName
                                    byPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                                if (that.state.matchPairObj.secondId === user.teamId) {
                                    toName = user.teamName
                                    toPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }





                        //storing pre-defined hits runs wickets & last bat
                        that.state.rounds.map(r => {

                            if (that.state.setNumber === r.position) {
                                if (r.toUndoList) {
                                    if (r.toUndoList.length > 0) {

                                        var last = [];
                                        last = r.toUndoList[r.toUndoList.length - 1];

                                        if (last.type === that.state.batFirstTeam) {
                                            if (r.noOfInnings === 0) {
                                                that.setState({ batFirstTeam: 'challengedBy' })
                                            }
                                            else {
                                                that.setState({ batFirstTeam: 'challengedTo' })
                                            }
                                        }
                                        else {
                                            if (r.noOfInnings === 1) {
                                                that.setState({ batFirstTeam: 'challengedTo' })
                                            }
                                            else {
                                                that.setState({ batFirstTeam: 'challengedBy' })
                                            }
                                        }
                                    }

                                    else {

                                    }

                                    that.setState({
                                        hits: r.challengedHits,
                                        runs: r.challengedRun,
                                        wickets: r.challengedWickets
                                    })
                                }


                            }

                        })

                    }

                }
                if (result.data.score === null) {
                    that.saveIntialSet();
                    that.setState({ modalIsOpen: true });
                    that.props.profileTeams.map(team => {
                        if (team.teamId === that.state.matchPairObj.firstId) {
                            that.setState({ byName: team.teamName });
                        }
                        else if (team.teamId === that.state.matchPairObj.secondId) {
                            that.setState({ toName: team.teamName });
                        }
                    })
                }

            });
    }
    saveIntialSet = () => {
        if (this.props.tt1.fixtureType1) {
            myUtilclass.getMatchPair(this.props.tt1, null, null, true, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            myUtilclass.getMatchPair(this.props.tt1, poolNo, null, true, this.props.matchId);
        }
        //   this.setState({ matchPairObj: pairObj });

        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }
        this.setState({ byName: byName, toName: toName, score: false });
    }



    changeDataa(event) {
        let d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            if (selectedValue === 'challengedBy') {
                selectedWinners = d107BaseBallScoreCard.byBaseballScoreCard.teamId;
                toWinner = false;
                byWinner = true;
            }
            else {
                selectedWinners = d107BaseBallScoreCard.toBaseballScoreCard.teamId;
                toWinner = true;
                byWinner = false;
            }
            console.log(selectedWinners)
        }

    }

    changeData(event) {

        this.setState({ errorBats: true })
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            teamBatFirst = selectedValue;
            this.setState({ batFirstTeam: teamBatFirst })
        }
    }

    selectBatFirstTeam = () => {
        var rounds = [];
        var roundsDataObj = {};
        for (var i = 0; i <= 11; i++) {
            if (i === 0) {
                roundsDataObj = {
                    position: i,
                    challengedById: null,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: null,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 0,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
            if ((i > 0) && (i < 10)) {
                roundsDataObj = {
                    position: i,
                    challengedById: this.state.matchPairObj.firstId,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: this.state.matchPairObj.secondId,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 1,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
            if ((i >= 10) && (i <= 11)) {
                roundsDataObj = {
                    position: i,
                    challengedById: null,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: null,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 2,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
        }

        const that = this;
        var d107BaseBallScoreCard = {
            byBaseballScoreCard: {
                totalHits: 0,
                totalRuns: 0,
                teamId: this.state.matchPairObj.firstId,
                winner: false
            },
            toBaseballScoreCard: {
                totalHits: 0,
                totalRuns: 0,
                teamId: this.state.matchPairObj.secondId,
                winner: false
            },
            setNo: 1,
            noOfSets: 9,
            strokeNo: 0,
            batFirstTeam: teamBatFirst,
            rounds
        }
        var score = {
            tournamentId: that.props.tournament.tournamentId,
            matchId: that.props.matchId,
            d107BaseBallScoreCard: d107BaseBallScoreCard,
        }
        that.setState({ rounds: rounds, d107BaseBallScoreCard: d107BaseBallScoreCard })
        UpdateTournamentsScore(score);
        this.setState({ modalIsOpen: false });

    }

    ToundoList2 = () => {
        if (teamBatFirst === 'challengedBy') {
            this.setState({ selectSetWon: false, batFirstTeam: 'challengedTo', byWinner: false, toWinner: false })
            var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
            d107BaseBallScoreCard.byBaseballScoreCard.winner = false;
            d107BaseBallScoreCard.toBaseballScoreCard.winner = false;
            var toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                return round.position === d107BaseBallScoreCard.setNo
            })
            console.log(toAdd)

            var strokes = d107BaseBallScoreCard.strokeNo;
            var typeMode;
            var toUndosList = [];
            var toUndosObj = {};

            if (toAdd[0].toUndoList !== null) {
                toUndosList = toAdd[0].toUndoList;
            }
            console.log(toUndosList)

            toUndosList.map(list => {
                if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                    toUndosObj = list;
                    typeMode = list.type;
                }
            })
            //console.log(toUndosObj)

            if (toUndosObj.actionType === 0) {
                console.log(d107BaseBallScoreCard)
                if (d107BaseBallScoreCard.setNo > 1) {
                    toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                        return round.position === d107BaseBallScoreCard.setNo
                    })
                    toUndosList = toAdd[0].toUndoList;
                    if (toUndosList) {
                        toUndosList.map(list => {
                            if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                                toUndosObj = list;
                                typeMode = list.type;
                            }
                        })
                    }

                    toAdd[0].challengedWickets = toUndosObj.wickets;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    toAdd[0].challengedToInningScore = toUndosObj.inningScore;
                    toAdd[0].noOfInnings = 1;
                    this.setState({
                        wickets: toAdd[0].challengedWickets,
                        hits: toAdd[0].challengedHits,
                        runs: toAdd[0].challengedRun,
                        batFirstTeam: 'challengedTo'
                    })
                    inngs += 1;
                }
            }
        }
        else {
            this.setState({ selectSetWon: false, batFirstTeam: 'challengedBy', byWinner: false, toWinner: false })
            var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
            d107BaseBallScoreCard.byBaseballScoreCard.winner = false;
            d107BaseBallScoreCard.toBaseballScoreCard.winner = false;
            var toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                return round.position === d107BaseBallScoreCard.setNo
            })
            console.log(toAdd)

            var strokes = d107BaseBallScoreCard.strokeNo;
            var typeMode;
            var toUndosList = [];
            var toUndosObj = {};

            if (toAdd[0].toUndoList !== null) {
                toUndosList = toAdd[0].toUndoList;
            }
            console.log(toUndosList)

            toUndosList.map(list => {
                if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                    toUndosObj = list;
                    typeMode = list.type;
                }
            })
            //console.log(toUndosObj)

            if (toUndosObj.actionType === 0) {
                console.log(d107BaseBallScoreCard)
                if (d107BaseBallScoreCard.setNo > 1) {
                    toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                        return round.position === d107BaseBallScoreCard.setNo
                    })
                    toUndosList = toAdd[0].toUndoList;
                    if (toUndosList) {
                        toUndosList.map(list => {
                            if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                                toUndosObj = list;
                                typeMode = list.type;
                            }
                        })
                    }

                    toAdd[0].challengedWickets = toUndosObj.wickets;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    toAdd[0].challengedByInningScore = toUndosObj.inningScore;
                    toAdd[0].noOfInnings = 1;
                    this.setState({
                        wickets: toAdd[0].challengedWickets,
                        hits: toAdd[0].challengedHits,
                        runs: toAdd[0].challengedRun,
                        batFirstTeam: 'challengedBy'
                    })
                    inngs += 1;
                }
            }
        }

        strokes -= 1;

        if (toUndosList) {
            toUndosList = toUndosList.filter((x) => x != toUndosObj)
        }

        toAdd[0].toUndoList = toUndosList;
        d107BaseBallScoreCard.strokeNo = strokes;

        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107BaseBallScoreCard: d107BaseBallScoreCard,
        }
        UpdateTournamentsScore(score).then(success => {
            // setTimeout(() => {
            //     this.setState({ isundoDisable: false, })
            // }, 500)
        });

        this.setState({ setNumber: d107BaseBallScoreCard.setNo, d107BaseBallScoreCard: d107BaseBallScoreCard });
    }

    ToundoList = () => {
        this.setState({ selectSetWon: false })
        var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
        var toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
            return round.position === d107BaseBallScoreCard.setNo
        })
        console.log(toAdd)

        var strokes = d107BaseBallScoreCard.strokeNo;
        var typeMode;
        var toUndosList = [];
        var toUndosObj = {};

        if (toAdd[0].toUndoList !== null) {
            toUndosList = toAdd[0].toUndoList;
        }
        console.log(toUndosList)

        toUndosList.map(list => {
            if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                toUndosObj = list;
                typeMode = list.type;
            }
        })
        //console.log(toUndosObj)

        if (teamBatFirst === 'challengedBy') {
            if (typeMode === 'challengedBy') {
                if (toUndosObj.actionType === 0) {
                    if (toUndosObj.wickets == 2) {
                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        toAdd[0].challengedHits = toUndosObj.hits;
                        toAdd[0].challengedRun = toUndosObj.runs;
                        toAdd[0].challengedByInningScore = toUndosObj.inningScore;
                        toAdd[0].noOfInnings = 0;
                        this.setState({
                            wickets: toAdd[0].challengedWickets,
                            hits: toAdd[0].challengedHits,
                            runs: toAdd[0].challengedRun,
                            batFirstTeam: 'challengedBy'
                        })
                        inngs = 0;
                    }
                    else {
                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        this.setState({
                            wickets: toAdd[0].challengedWickets
                        })
                    }
                }

                if (toUndosObj.actionType === 1) {
                    d107BaseBallScoreCard.byBaseballScoreCard.totalHits = d107BaseBallScoreCard.byBaseballScoreCard.totalHits - 1;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    this.setState({
                        byTotalHits: this.state.byTotalHits - 1,
                        hits: toAdd[0].challengedHits
                    })
                }

                if (toUndosObj.actionType === 2) {
                    d107BaseBallScoreCard.byBaseballScoreCard.totalRuns = d107BaseBallScoreCard.byBaseballScoreCard.totalRuns - 1;
                    toAdd[0].challengedByInningScore = toUndosObj.inningScore;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    this.setState({
                        byTotalRuns: this.state.byTotalRuns - 1,
                        runs: toAdd[0].challengedRun
                    })
                }
                strokes -= 1;
            }
            else {
                if (Object.keys(toUndosList).length === 0) {
                    if (d107BaseBallScoreCard.setNo > 1) {
                        d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo - 1;

                        toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                            return round.position === d107BaseBallScoreCard.setNo
                        })
                        toUndosList = toAdd[0].toUndoList;
                        if (toUndosList) {
                            toUndosList.map(list => {
                                if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                                    toUndosObj = list;
                                    typeMode = list.type;
                                }
                            })
                        }

                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        toAdd[0].challengedHits = toUndosObj.hits;
                        toAdd[0].challengedRun = toUndosObj.runs;
                        toAdd[0].challengedToInningScore = toUndosObj.inningScore;
                        toAdd[0].noOfInnings = 1;
                        this.setState({
                            wickets: toAdd[0].challengedWickets,
                            hits: toAdd[0].challengedHits,
                            runs: toAdd[0].challengedRun,
                            batFirstTeam: 'challengedTo'
                        })
                        inngs += 1;
                    }
                }

                if (toUndosObj.actionType === 0) {
                    toAdd[0].challengedWickets = toUndosObj.wickets;
                    this.setState({
                        wickets: toAdd[0].challengedWickets
                    })

                }

                if (toUndosObj.actionType === 1) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalHits = d107BaseBallScoreCard.toBaseballScoreCard.totalHits - 1;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    this.setState({
                        toTotalHits: this.state.toTotalHits - 1,
                        hits: toAdd[0].challengedHits
                    })

                }

                if (toUndosObj.actionType === 2) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalRuns = d107BaseBallScoreCard.toBaseballScoreCard.totalRuns - 1;
                    toAdd[0].challengedToInningScore = toUndosObj.inningScore;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    this.setState({
                        toTotalRuns: this.state.toTotalRuns - 1,
                        runs: toAdd[0].challengedRun
                    })

                }
                if (strokes > 1) {
                    strokes -= 1;
                }
            }
        }
        else {
            if (typeMode === 'challengedTo') {
                if (toUndosObj.actionType === 0) {
                    if (toUndosObj.wickets == 2) {
                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        toAdd[0].challengedHits = toUndosObj.hits;
                        toAdd[0].challengedRun = toUndosObj.runs;
                        toAdd[0].challengedToInningScore = toUndosObj.inningScore;
                        toAdd[0].noOfInnings = 0;
                        this.setState({
                            wickets: toAdd[0].challengedWickets,
                            hits: toAdd[0].challengedHits,
                            runs: toAdd[0].challengedRun,
                            batFirstTeam: 'challengedTo'
                        })
                        inngs = 0;
                    }
                    else {
                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        this.setState({
                            wickets: toAdd[0].challengedWickets
                        })
                    }
                }

                if (toUndosObj.actionType === 1) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalHits = d107BaseBallScoreCard.toBaseballScoreCard.totalHits - 1;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    this.setState({
                        toTotalHits: this.state.toTotalHits - 1,
                        hits: toAdd[0].challengedHits
                    })
                }

                if (toUndosObj.actionType === 2) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalRuns = d107BaseBallScoreCard.toBaseballScoreCard.totalRuns - 1;
                    toAdd[0].challengedToInningScore = toUndosObj.inningScore;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    this.setState({
                        toTotalRuns: this.state.toTotalRuns - 1,
                        runs: toAdd[0].challengedRun
                    })
                }
                strokes -= 1;
            }
            else {
                if (Object.keys(toUndosList).length === 0) {
                    if (d107BaseBallScoreCard.setNo > 1) {
                        d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo - 1;

                        toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
                            return round.position === d107BaseBallScoreCard.setNo
                        })
                        toUndosList = toAdd[0].toUndoList;
                        if (toUndosList) {
                            toUndosList.map(list => {
                                if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                                    toUndosObj = list;
                                    typeMode = list.type;
                                }
                            })
                        }

                        toAdd[0].challengedWickets = toUndosObj.wickets;
                        toAdd[0].challengedHits = toUndosObj.hits;
                        toAdd[0].challengedRun = toUndosObj.runs;
                        toAdd[0].challengedByInningScore = toUndosObj.inningScore;
                        toAdd[0].noOfInnings = 1;
                        this.setState({
                            wickets: toAdd[0].challengedWickets,
                            hits: toAdd[0].challengedHits,
                            runs: toAdd[0].challengedRun,
                            batFirstTeam: 'challengedBy'
                        })
                        inngs += 1;
                    }
                }

                if (toUndosObj.actionType === 0) {
                    toAdd[0].challengedWickets = toUndosObj.wickets;
                    this.setState({
                        wickets: toAdd[0].challengedWickets
                    })

                }

                if (toUndosObj.actionType === 1) {
                    d107BaseBallScoreCard.byBaseballScoreCard.totalHits = d107BaseBallScoreCard.byBaseballScoreCard.totalHits - 1;
                    toAdd[0].challengedHits = toUndosObj.hits;
                    this.setState({
                        byTotalHits: this.state.byTotalHits - 1,
                        hits: toAdd[0].challengedHits
                    })

                }

                if (toUndosObj.actionType === 2) {
                    d107BaseBallScoreCard.byBaseballScoreCard.totalRuns = d107BaseBallScoreCard.byBaseballScoreCard.totalRuns - 1;
                    toAdd[0].challengedByInningScore = toUndosObj.inningScore;
                    toAdd[0].challengedRun = toUndosObj.runs;
                    this.setState({
                        byTotalRuns: this.state.byTotalRuns - 1,
                        runs: toAdd[0].challengedRun
                    })

                }
                if (strokes > 1) {
                    strokes -= 1;
                }
            }
        }



        if (toUndosList) {
            toUndosList = toUndosList.filter((x) => x != toUndosObj)
        }

        toAdd[0].toUndoList = toUndosList;
        d107BaseBallScoreCard.strokeNo = strokes;

        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107BaseBallScoreCard: d107BaseBallScoreCard,
        }
        UpdateTournamentsScore(score).then(success => {
            // setTimeout(() => {
            //     this.setState({ isundoDisable: false, })
            // }, 500)
        });
        console.log(d107BaseBallScoreCard)
        this.setState({ setNumber: d107BaseBallScoreCard.setNo, d107BaseBallScoreCard: d107BaseBallScoreCard });
    }

    updateUserPoints(typess) {
        var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
        this.state.rounds.map(r => {
            if (r.position === this.state.setNumber) {
                toAdd = r;
                toUndos = toAdd.toUndoList;
                if (toUndos == null) {
                    toUndos = [];
                }
            }
        })

        if (this.state.setNumber <= d107BaseBallScoreCard.noOfSets) {
            if (this.state.setNumber === d107BaseBallScoreCard.setNo) {
                strokeNo = d107BaseBallScoreCard.strokeNo++;
                if (this.state.batFirstTeam === 'challengedBy') {
                    if (typess === 'W') {
                        // this.setState({ isByPlusDisable: false, isToPlusDisable: false,isyoPlusDisable: true  })
                        if (this.state.wickets < 2) {
                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: toAdd.challengedByInningScore,
                            }
                            toUndos.push(toUndoListObj)
                            this.setState({ wickets: this.state.wickets + 1 })
                            toAdd.challengedWickets = this.state.wickets + 1;
                        }
                        else {
                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: toAdd.challengedByInningScore,
                            }
                            toUndos.push(toUndoListObj)
                            this.setState({ hits: 0, runs: 0, wickets: 0, batFirstTeam: 'challengedTo' })
                            toAdd.challengedWickets = 0;
                            toAdd.challengedHits = 0;
                            toAdd.challengedRun = 0;
                            toAdd.noOfInnings = 1;
                            inngs++;
                            if (teamBatFirst === 'challengedBy') {

                            }
                            else {
                                d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo + 1;
                            }
                        }

                    }


                    if (typess === 'H') {
                        // this.setState({ isByPlusDisable: true, isToPlusDisable: false, isyoPlusDisable: false, })
                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 1,
                            type: this.state.batFirstTeam,
                            inningScore: toAdd.challengedByInningScore,
                        }
                        toUndos.push(toUndoListObj)
                        this.setState({ hits: this.state.hits + 1, byTotalHits: this.state.byTotalHits + 1 })
                        d107BaseBallScoreCard.byBaseballScoreCard.totalHits = this.state.byTotalHits + 1;
                        toAdd.challengedHits = this.state.hits + 1;
                    }

                    if (typess === 'R') {
                        // this.setState({ isByPlusDisable: false, isToPlusDisable: true , isyoPlusDisable: false })
                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 2,
                            type: this.state.batFirstTeam,
                            inningScore: toAdd.challengedByInningScore,
                        }
                        toUndos.push(toUndoListObj)

                        this.setState({ runs: this.state.runs + 1, byTotalRuns: this.state.byTotalRuns + 1 })

                        d107BaseBallScoreCard.byBaseballScoreCard.totalRuns = this.state.byTotalRuns + 1;

                        toAdd.challengedByInningScore = this.state.runs + 1;
                        toAdd.challengedRun = this.state.runs + 1;

                    }
                }

                if (this.state.batFirstTeam === 'challengedTo') {

                    if (typess === 'W') {
                        // this.setState({ isByPlusDisable: false, isToPlusDisable: false,isyoPlusDisable: true  })
                        if (this.state.wickets < 2) {
                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: toAdd.challengedToInningScore,
                            }
                            toUndos.push(toUndoListObj)

                            this.setState({ wickets: this.state.wickets + 1 })
                            toAdd.challengedWickets = this.state.wickets + 1;
                        }

                        else {
                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: toAdd.challengedToInningScore,
                            }
                            toUndos.push(toUndoListObj)
                            this.setState({ hits: 0, runs: 0, wickets: 0, batFirstTeam: 'challengedBy' })
                            toAdd.challengedWickets = 0;
                            toAdd.challengedHits = 0;
                            toAdd.challengedRun = 0;
                            toAdd.noOfInnings = 1;
                            inngs++;
                            if (teamBatFirst === 'challengedBy') {
                                d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo + 1;
                            }
                            else {

                            }

                        }

                    }

                    if (typess === 'H') {
                        // this.setState({ isByPlusDisable: true, isToPlusDisable: false, isyoPlusDisable: false, })
                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 1,
                            type: this.state.batFirstTeam,
                            inningScore: toAdd.challengedToInningScore,
                        }
                        toUndos.push(toUndoListObj)
                        this.setState({ hits: this.state.hits + 1, toTotalHits: this.state.toTotalHits + 1 })
                        d107BaseBallScoreCard.toBaseballScoreCard.totalHits = this.state.toTotalHits + 1;
                        toAdd.challengedHits = this.state.hits + 1;
                    }

                    if (typess === 'R') {
                        // this.setState({ isByPlusDisable: false, isToPlusDisable: true , isyoPlusDisable: false })
                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 2,
                            type: this.state.batFirstTeam,
                            inningScore: toAdd.challengedToInningScore,
                        }
                        toUndos.push(toUndoListObj)
                        this.setState({ runs: this.state.runs + 1, toTotalRuns: this.state.toTotalRuns + 1 })
                        d107BaseBallScoreCard.toBaseballScoreCard.totalRuns = this.state.toTotalRuns + 1;
                        toAdd.challengedToInningScore = this.state.runs + 1;
                        toAdd.challengedRun = this.state.runs + 1;
                    }
                }

            }

        }

        else {
            return '';
        }

        var matchIdSpilit = this.props.matchId;
        var ar = matchIdSpilit.split('-'); // split string on comma space
        console.log(ar)

        toAdd.toUndoList = toUndos;
        var rounds = this.state.rounds;
        var indx = parseInt(this.state.setNumber);
        rounds.slice(indx, 1, toAdd);

        if (inngs <= 1) {
            if (this.state.setNumber <= 9) {
                const that = this;
                var d107BaseBallScoreCard1 = {
                    byBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                        teamId: this.state.matchPairObj.firstId,
                        winner: false
                    },
                    toBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                        teamId: this.state.matchPairObj.secondId,
                        winner: false
                    },
                    setNo: that.state.setNumber,
                    noOfSets: d107BaseBallScoreCard.noOfSets,
                    strokeNo: strokeNo + 1,
                    batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                    rounds
                }
                var score1 = {
                    tournamentId: that.props.tournament.tournamentId,
                    matchId: that.props.matchId,
                    d107BaseBallScoreCard: d107BaseBallScoreCard1,
                }

                that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds })
                UpdateTournamentsScore(score1).then(success => {
                    // setTimeout(() => {
                    //     this.setState({ isByPlusDisable: false, isToPlusDisable: false,isyoPlusDisable: false  })
                    // }, 500)
                });
            }
        }

        if (inngs >= 2) {
            if (this.state.setNumber < 9) {
                var changeSet = d107BaseBallScoreCard.setNo;
                this.setState({ setNumber: this.state.setNumber + 1 })
                const that = this;
                var d107BaseBallScoreCard1 = {
                    byBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                        teamId: this.state.matchPairObj.firstId,
                        winner: false
                    },
                    toBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                        teamId: this.state.matchPairObj.secondId,
                        winner: false
                    },
                    setNo: changeSet,
                    noOfSets: d107BaseBallScoreCard.noOfSets,
                    strokeNo: strokeNo + 1,
                    batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                    rounds
                }
                var score1 = {
                    tournamentId: that.props.tournament.tournamentId,
                    matchId: that.props.matchId,
                    d107BaseBallScoreCard: d107BaseBallScoreCard1,
                }
                that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds })
                inngs = 0;
                UpdateTournamentsScore(score1).then(success => {
                    // setTimeout(() => {
                    //     this.setState({ isByPlusDisable: false, isToPlusDisable: false, isyoPlusDisable: false })
                    // }, 500)
                });
            }

            if (this.state.setNumber === 9) {

                if (this.state.byTotalRuns > this.state.toTotalRuns) {
                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.firstId,
                            winner: true
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.secondId,
                            winner: false
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }
                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, selectSetWon: true, byWinner: true, winners: d107BaseBallScoreCard.byBaseballScoreCard.teamId })

                    inngs = 0;
                    UpdateTournamentsScore(score1).then(success => {
                        // setTimeout(() => {
                        //     this.setState({ isByPlusDisable: false, isToPlusDisable: false ,isyoPlusDisable: false  })
                        // }, 500)
                    });

                }

                if (this.state.byTotalRuns < this.state.toTotalRuns) {

                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.firstId,
                            winner: false
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.secondId,
                            winner: true
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }

                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, selectSetWon: true, toWinner: true, winners: d107BaseBallScoreCard.toBaseballScoreCard.teamId })
                    inngs = 0;
                    UpdateTournamentsScore(score1).then(success => {
                        // setTimeout(() => {
                        //     this.setState({ isByPlusDisable: false, isToPlusDisable: false, isyoPlusDisable: false })
                        // }, 500)
                    });
                }

                if (this.state.byTotalRuns === this.state.toTotalRuns) {
                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.firstId,
                            winner: false
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: this.state.matchPairObj.secondId,
                            winner: false
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }
                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, selectWinnerModal: true })
                    inngs = 0;
                    UpdateTournamentsScore(score1).then(success => {
                        // setTimeout(() => {
                        //     this.setState({ isByPlusDisable: false, isToPlusDisable: false , isyoPlusDisable: false })
                        // }, 500)
                    });
                }
            }
        }
        console.log(d107BaseBallScoreCard)
    }

    selectSetWonHandel = () => {
        this.setState({ selectSetWon: true })
    }
    reload = () => {
        window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }

    render() {
        const backLinks = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;


        return (
            <React.Fragment>

                <ModalWinner
                    size="md"
                    show={this.state.selectSetWon}
                    onHide={() => this.selectSetWonHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={logo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.byTotalRuns}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toTotalRuns}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList2()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </ModalWinner>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Select Bat First</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-1" onClick={this.changeData.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byName}</label>
                        </li>

                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-2" onClick={this.changeData.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toName}</label>
                        </li>
                    </ul>
                    <div className="button_div" >
                        {this.state.errorBats ? <button className="cmnbutton" type="button" onClick={this.selectBatFirstTeam}>Done</button> : null}

                        <button className="btn btn-inverse" onClick={this.reload}>Close</button></div>
                </Modal>

                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Scores Are Same Please Choose The Winner!</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-1" onClick={this.changeDataa.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-2" onClick={this.changeDataa.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toName}</label>
                        </li>
                    </ul>
                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.doneMatch}>Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>
                </Modal>



                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video" style={{ backgroundImage: "url(" + noBroadcast + ")" }}>
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <tbody>
                                        <tr>
                                            {(this.state.batFirstTeam === 'challengedBy') ? <td>{this.state.byName}</td> : <td>{this.state.toName}</td>}
                                            <td><input placeholder={this.state.hits} class="inputsize-d" readOnly="readOnly" /></td>
                                            <td><input placeholder={this.state.runs} class="inputsize-d" readOnly="readOnly" /></td>
                                            <td><input placeholder={this.state.wickets} class="inputsize-d" readOnly="readOnly" /></td>
                                        </tr>
                                        <tr>
                                            <td>Innings {this.state.setNumber}</td>
                                            {this.state.isByPlusDisable ? <td><span style={{ backgroundColor: 'grey' }} class="baseballviolet-d">Hits</span></td> : <td><span onClick={() => this.updateUserPoints('H')} class="baseballviolet-d">Hits</span></td>}

                                            {this.state.isToPlusDisable ? <td><span style={{ backgroundColor: 'grey' }} class="baseballgreen-d">Runs</span></td> : <td><span onClick={() => this.updateUserPoints('R')} class="baseballgreen-d">Runs</span></td>}

                                            {this.state.isyoPlusDisable ? <td><span style={{ backgroundColor: 'grey' }} class="baseballred-d">Wicket</span></td> : <td><span onClick={() => this.updateUserPoints('W')} class="baseballred-d">Wicket</span></td>}
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <h4 className="scorehead">Match Score</h4>

                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <thead>
                                        <tr>
                                            <th>Teams</th>
                                            {this.state.rounds.map((p, i = 1) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <th>R{i}</th>
                                                }
                                            })}
                                            <th>Total Hits</th>
                                            <th>Total Runs</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input readonly="true" placeholder={p.challengedByInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.byTotalHits}</td>
                                            <td>{this.state.byTotalRuns}</td>
                                        </tr>

                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input readonly="true" placeholder={p.challengedToInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.toTotalHits}</td>
                                            <td>{this.state.toTotalRuns}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>
                            {/* {this.state.isundoDisable?   <a class="cmnbutton btred fr" type="button" style={{ backgroundColor: 'grey' }} >Undo</a> :    */}
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.ToundoList()}>Undo</a>
                            {/* }  */}


                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}