import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfigUrl from './ConfigUrl';
import Map from './Map';
import GetCurrentTimestamp from './GetCurrentTimestamp';
import { } from 'prismic-reactjs';
const ConfigApiurl = ConfigUrl('apiUrl');
const currentTimeStamp = GetCurrentTimestamp();

const userApi = ConfigApiurl;
class ViewAbout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: [],
            editData: true,
            editPermission: false,
            startDate: '',
            name: '',
            email: '',
            contactNo: '',
            address: '',
            aboutMe: '',
            quotation: '',
            website: '',
            interests: '',
            gender: '',
            emailPrivacy: '',
            contactNoPrivacy: '',
            dateOfBirthPrivacy: '',
            addressPrivacy: '',
            websitePrivacy: '',
            interestsPrivacy: '',
            quotationPrivacy: '',
            aboutMePrivacy: '',
            showUpdate: false,
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem("result"));
    }
    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData })
        this.setState({ address: childData.address, latitude: childData.latValue, longitude: childData.lngValue, city: childData.city, state: childData.state, country: childData.country })
    }
    handleSubmit = e => {
        e.preventDefault();
        if (this.state.startDate !== '') {
            function formatDate(string) {
                let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                return new Date(string).toLocaleDateString([], options);
            }
            const dob = (formatDate(this.state.startDate));
            var dateOfBirth = dob.replace("/", "-");
            dateOfBirth = dateOfBirth.replace("/", "-");
        }
        if (this.state.name === '') {
            this.setState({ name: null });
        }
        if (this.state.aboutMe === '') {
            this.setState({ aboutMe: null });
        }
        if (this.state.address === '') {
            this.setState({ address: null });
        }
        if (this.state.quotation === '') {
            this.setState({ quotation: null });
        }
        if (this.state.contactNo === '') {
            this.setState({ contactNo: null });
        }
        if (this.state.website === '') {
            this.setState({ website: null });
        }
        if (this.state.interests === '') {
            this.setState({ interests: null });
        }
        let newState = Object.assign({}, this.state);
        if (this.state.dateOfBirth === '') {
        }
        else {
            newState.userDetails.dateOfBirth = dateOfBirth;
        }
        newState.userDetails.name = this.state.name;
        newState.userDetails.aboutMe = this.state.aboutMe;
        newState.userDetails.email = this.state.email;
        newState.userDetails.address = this.state.address;
        newState.userDetails.city = this.state.city;
        newState.userDetails.state = this.state.state;
        newState.userDetails.country = this.state.country;
        newState.userDetails.latitude = this.state.latitude;
        newState.userDetails.longitude = this.state.longitude;
        newState.userDetails.quotation = this.state.quotation;
        newState.userDetails.website = this.state.website;
        newState.userDetails.interests = this.state.interests;
        newState.userDetails.emailPrivacy = parseInt(this.state.emailPrivacy);
        newState.userDetails.contactNoPrivacy = parseInt(this.state.contactNoPrivacy);
        newState.userDetails.dateOfBirthPrivacy = parseInt(this.state.dateOfBirthPrivacy);
        newState.userDetails.addressPrivacy = parseInt(this.state.addressPrivacy);
        newState.userDetails.aboutMePrivacy = parseInt(this.state.aboutMePrivacy);
        newState.userDetails.quotationPrivacy = parseInt(this.state.quotationPrivacy);
        newState.userDetails.websitePrivacy = parseInt(this.state.websitePrivacy);
        newState.userDetails.interestsPrivacy = parseInt(this.state.interestsPrivacy);
        this.setState(newState);
        const allDaata = this.state.userDetails;
        const that = this;
        // console.log(allDaata);
        fetch(userApi + "/users/update-user", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    user: allDaata,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ showUpdate: true })

                window.setTimeout(function () {
                    that.setState({ showUpdate: false })
                }, 2000);
            });



    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    loadMore = () => {
        if (this.state.user.contactNo === this.props.userbuddy) {
            this.setState({
                editPermission: true,
            })
        }
        const that = this;
        fetch(userApi + "users/get-user", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.props.userbuddy,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.dateOfBirth !== null) {
                    var dob = result.data.dateOfBirth.split("-");
                    dob = new Date(dob[2], dob[1] - 1, dob[0]);
                    that.setState({
                        userDetails: result.data, startDate: dob,
                    });
                }
                else {
                    that.setState({
                        userDetails: result.data, startDate: null,
                    });
                }
                that.setState({
                    name: result.data.name,
                    email: result.data.email,
                    contactNo: result.data.contactNo,
                    address: result.data.address,
                    aboutMe: result.data.aboutMe,
                    quotation: result.data.quotation,
                    website: result.data.website,
                    interests: result.data.interests,
                    gender: result.data.gender,
                    emailPrivacy: result.data.emailPrivacy,
                    contactNoPrivacy: result.data.contactNoPrivacy,
                    dateOfBirthPrivacy: result.data.dateOfBirthPrivacy,
                    addressPrivacy: result.data.addressPrivacy,
                    aboutMePrivacy: result.data.aboutMePrivacy,
                    quotationPrivacy: result.data.quotationPrivacy,
                    websitePrivacy: result.data.websitePrivacy,
                    interestsPrivacy: result.data.interestsPrivacy,
                    latitude: result.data.latitude,
                    longitude: result.data.longitude,
                    city: result.data.city,
                    state: result.data.state,
                    country: result.data.country,
                });
            });
    };
    componentDidMount() {
        this.loadMore();
    }
    render() {
        return (
            <div className="commanbox" id="editprofile">
                <h3 className="commanhead">About {this.state.editPermission ? <button onClick={() => this.setState({ editData: false })} className="headbutton">Edit Profile</button> : <div></div>}</h3>
                {this.state.editData ? <ul className="loginul_form editprofile">
                    <li>
                        <div className="commaninputdiv editcomman_first">
                            <label>Name</label>
                            <input type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.name} />

                        </div>
                    </li>
                    <li>
                        <div className="commaninputdiv">
                            <div className="selectdiv">

                            </div>
                            <label><span>Email Address</span></label>
                            <input type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.userDetails.email} />


                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>Contact Number</span></label>
                            <input type="text" readOnly className="question" autoComplete="off" value={this.state.userDetails.contactNo} />

                        </div>
                    </li>
                    <li>
                        <div className="commaninputdiv">
                            <label ><span>Gender</span></label>
                            <input type="text" readOnly className="question" autoComplete="off" value={this.state.userDetails.gender} />

                        </div>
                    </li>
                    <li>
                        <div className="commaninputdiv">
                            <label ><span>Date of Birth</span></label>
                            <input type="text" readOnly className="question" autoComplete="off" value={this.state.userDetails.dateOfBirth} />

                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>Location</span></label>
                            <textarea type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.userDetails.address} ></textarea>

                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>About Me</span></label>
                            <input type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.userDetails.aboutMe} />

                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>Quotation</span></label>
                            <input type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.userDetails.quotation} />

                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>Website</span></label>
                            <input type="text" readOnly name="" className="question" id="" autoComplete="off" value={this.state.userDetails.website} />

                        </div>
                    </li>
                    <li>
                        <div className="selectdiv">

                        </div>
                        <div className="commaninputdiv">
                            <label ><span>Interest</span></label>
                            <input type="text" readOnly name="interests" className="question" id="interests" autoComplete="off" value={this.state.userDetails.interests} />

                        </div>
                    </li>
                </ul> :
                    <form onSubmit={this.handleSubmit}>
                        <ul className="loginul_form editprofile">
                            <li>
                                <div className="commaninputdiv editcomman_first">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" className="question" value={this.state.name} onChange={event => this.setState({ name: event.target.value })} />

                                </div>
                            </li>
                            <li>
                                <div className="commaninputdiv">
                                    <div className="selectdiv">
                                        <select id="emailPrivacy" name="emailPrivacy" value={this.state.emailPrivacy} onChange={event => this.setState({ emailPrivacy: event.target.value })}>

                                            <option value="0">Public</option>
                                            <option value="1">Private</option>
                                            <option value="2">Only Me</option>
                                        </select>
                                        <i></i>
                                    </div>
                                    <label><span>Email Address</span></label>
                                    <input type="text" name="email" className="question" id="email" autoComplete="off" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} />



                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="contactNoPrivacy" name="contactNoPrivacy" value={this.state.contactNoPrivacy} onChange={event => this.setState({ contactNoPrivacy: event.target.value })} >

                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Contact Number</span></label>
                                    <input type="text" name="contactNo" className="question" id="contactNo" autoComplete="off" value={this.state.contactNo} />
                                    {/* onChange={event => this.setState({ contactNo: event.target.value })} */}

                                </div>
                            </li>
                            <li>
                                <div className="commaninputdiv">
                                    <label ><span>Gender</span></label>
                                    <div className="selectdiv">
                                        <select id="gender" name="gender" value={this.state.gender}>

                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <i></i>
                                    </div>


                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="dateOfBirthPrivacy" name="dateOfBirthPrivacy" value={this.state.dateOfBirthPrivacy} onChange={event => this.setState({ dateOfBirthPrivacy: event.target.value })} >

                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Date of Birth</span></label>
                                    <DatePicker autoComplete="off"
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                    />


                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="addressPrivacy" name="addressPrivacy" value={this.state.addressPrivacy} onChange={event => this.setState({ addressPrivacy: event.target.value })}>
                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Location</span></label>
                                    <textarea type="text" name="address" className="question" id="address" autoComplete="off" value={this.state.address}  >

                                    </textarea>
                                    <span id="searchLocation">
                                        <Map parentCallback={this.callbackFunction} /></span>

                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="aboutMePrivacy" name="aboutMePrivacy" value={this.state.aboutMePrivacy} onChange={event => this.setState({ aboutMePrivacy: event.target.value })}>
                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>About Me</span></label>
                                    <input type="text" name="aboutMe" className="question" id="aboutMe" autoComplete="off" value={this.state.aboutMe} onChange={event => this.setState({ aboutMe: event.target.value })} />

                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="quotationPrivacy" name="quotationPrivacy" value={this.state.quotationPrivacy} onChange={event => this.setState({ quotationPrivacy: event.target.value })}>
                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Quotation</span></label>
                                    <input type="text" name="quotation" className="question" id="quotation" autoComplete="off" value={this.state.quotation} onChange={event => this.setState({ quotation: event.target.value })} />
                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="websitePrivacy" name="websitePrivacy" value={this.state.websitePrivacy} onChange={event => this.setState({ websitePrivacy: event.target.value })}>
                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Website</span></label>
                                    <input type="text" name="website" className="question" id="website" autoComplete="off" value={this.state.website} onChange={event => this.setState({ website: event.target.value })} />

                                </div>
                            </li>
                            <li>
                                <div className="selectdiv">
                                    <select id="interestsPrivacy" name="interestsPrivacy" value={this.state.interestsPrivacy} onChange={event => this.setState({ interestsPrivacy: event.target.value })} >
                                        <option value="0">Public</option>
                                        <option value="1">Private</option>
                                        <option value="2">Only Me</option>
                                    </select>
                                    <i></i>
                                </div>
                                <div className="commaninputdiv">
                                    <label ><span>Interest</span></label>
                                    <input type="text" name="interests" className="question" id="interests" autoComplete="off" value={this.state.interests} onChange={event => this.setState({ interests: event.target.value })} />
                                </div>
                            </li>
                        </ul>
                        <div className="editbutton_div"><button type="submit" className="cmnbutton">Update</button></div>
                        <div className="alert alert-success" style={{ display: this.state.showUpdate ? 'block' : 'none', textAlign: 'center' }} id="messageShow">
                            updated Successfully
              </div>
                    </form>}
            </div>
        );
    }
}
export default ViewAbout;   