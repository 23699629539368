import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';

import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import logo from '../img/finallogo.png';
import ConfigUrl from '../ConfigUrl';
import * as myUtilclass from '../DemoTournament/DFixtureUtil';
import * as myTournamentUtilclass from '../DemoTournament/DTournamentsUtil';
const tournamentAction = "ended";
var score;
let pairObj;
var inngs = 0;
var strokeNo;
var teamBatFirst;
var selectedWinners = undefined;
var toAdd = [];
var toUndos = [];
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class Tournament_Baseball_tt1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            byTotalHits: 0,
            toTotalHits: 0,
            byTotalRuns: 0,
            toTotalRuns: 0,
            setNumber: 1,
            numberofSets: 9,
            batFirstTeam: '',
            rounds: [],
            d107BaseBallScoreCard: {},
            hits: 0,
            runs: 0,
            wickets: 0,
            winnerModalOpen: false,
            modalIsOpen: false,
            selectWinnerModal: false,
            winners: undefined,
            currentArchaeryPointBy: 0,
            currentArchaeryPointTo: 0,
            byPoints: [0],
            toPoints: [0],
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
        };
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, winnerModalOpen: false });
        window.location.reload(false);
    }

    doneMatch() {
        var winner;
        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winners !== undefined) {
            winner = this.state.winners;
        }

        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }

        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }




    }



    componentDidMount() {
        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName, toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }


    saveIntialSet = () => {
        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }
        this.setState({ byName: byName, toName: toName, score: false });
    }


    getScore() {

        const apiUrl = rshApi + "/demo-tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    users: result.data.users,
                    teams: result.data.teams
                })

                if (result.data.score !== null) {
                    that.saveIntialSet();

                    if (result.data.score.d107BaseBallScoreCard !== null) {

                        if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== null) {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: 0, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
                            }
                        }

                        if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== undefined) {

                            if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== null) {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                            else {
                                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: 0, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
                            }
                        }

                        //storing set numbers, rounds ... etc 
                        that.setState({ d107BaseBallScoreCard: result.data.score.d107BaseBallScoreCard, rounds: result.data.score.d107BaseBallScoreCard.rounds, setNumber: result.data.score.d107BaseBallScoreCard.setNo, numberofSets: result.data.score.d107BaseBallScoreCard.noOfSets, batFirstTeam: result.data.score.d107BaseBallScoreCard.batFirstTeam })

                        //storing byteam and toteam
                        that.props.profileTeams.map(team => {
                            if (team.teamId === result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.teamId) {
                                that.setState({ byTeamName: team.teamName, byTeamid: team.teamId });
                            }
                            if (team.teamId === result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.teamId) {
                                that.setState({ toTeamName: team.teamName, toTeamid: team.teamId });
                            }
                        })

                        //storing pre-defined hits runs wickets & last bat
                        that.state.rounds.map(r => {


                            if (that.state.setNumber === r.position) {

                                if (r.toUndoList.length > 0) {

                                    var last = [];
                                    last = r.toUndoList[r.toUndoList.length - 1];

                                    if (last.type === that.state.batFirstTeam) {
                                        if (r.noOfInnings === 0) {
                                            that.setState({ batFirstTeam: 'challengedBy' })
                                        }
                                        else {
                                            that.setState({ batFirstTeam: 'challengedTo' })
                                        }
                                    }
                                    else {
                                        if (r.noOfInnings === 1) {
                                            that.setState({ batFirstTeam: 'challengedTo' })
                                        }
                                        else {
                                            that.setState({ batFirstTeam: 'challengedBy' })
                                        }
                                    }
                                }

                                else {

                                }

                                that.setState({
                                    hits: r.challengedHits,
                                    runs: r.challengedRun,
                                    wickets: r.challengedWickets
                                })

                            }

                        })

                    }

                }

                if (result.data.score === null) {
                    that.setState({ modalIsOpen: true });
                    that.props.profileTeams.map(team => {
                        if (team.teamId === that.state.matchPairObj.firstId) {
                            that.setState({ byTeamName: team.teamName });
                        }
                        else {
                            that.setState({ toTeamName: team.teamName });
                        }
                    })
                }

            });
    }

    changeDataa(event) {

        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            if (selectedValue === 'challengedBy') {
                selectedWinners = this.state.byTeamid;
            }
            else {
                selectedWinners = this.state.toTeamid;
            }
            console.log(selectedWinners)
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            teamBatFirst = selectedValue;
            this.setState({ batFirstTeam: teamBatFirst })
        }
    }

    selectBatFirstTeam = () => {

        var rounds = [];
        var roundsDataObj = {};

        for (var i = 0; i <= 11; i++) {
            if (i === 0) {
                roundsDataObj = {
                    position: i,
                    challengedById: null,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: null,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 0,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
            if ((i > 0) && (i < 10)) {
                roundsDataObj = {
                    position: i,
                    challengedById: pairObj.firstId,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: pairObj.secondId,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 1,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
            if ((i >= 10) && (i <= 11)) {
                roundsDataObj = {
                    position: i,
                    challengedById: null,
                    challengedByInningScore: 0,
                    challengedHits: 0,
                    challengedRun: 0,
                    challengedWickets: 0,
                    challengedToId: null,
                    challengedToInningScore: 0,
                    noOfInnings: 0,
                    tableViewType: 2,
                    winnerId: null,
                    toUndoList: null,
                    saved: false
                }
                rounds.push(roundsDataObj);
            }
        }

        const that = this;
        var d107BaseBallScoreCard = {
            byBaseballScoreCard: {
                totalHits: 0,
                totalRuns: 0,
                teamId: pairObj.firstId,
                winner: false
            },
            toBaseballScoreCard: {
                totalHits: 0,
                totalRuns: 0,
                teamId: pairObj.firstId,
                winner: false
            },
            setNo: 1,
            noOfSets: 9,
            strokeNo: 0,
            batFirstTeam: teamBatFirst,
            rounds
        }
        var score = {
            tournamentId: that.props.tournament.tournamentId,
            matchId: that.props.matchId,
            d107BaseBallScoreCard: d107BaseBallScoreCard,
        }

        that.setState({ rounds: rounds, d107BaseBallScoreCard: d107BaseBallScoreCard })
        DUpdateTournamentsScore(score);
        this.setState({ modalIsOpen: false });

    }

    ToundoList = () => {
        this.setState({ winnerModalOpen: false });

        var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;
        var toAdd = this.state.d107BaseBallScoreCard.rounds.filter(round => {
            return round.position === d107BaseBallScoreCard.setNo
        })

        var strokes = d107BaseBallScoreCard.strokeNo;
        var typeMode;
        var toUndosList = [];
        var toUndosObj = {};

        if (toAdd[0].toUndoList !== null) {
            toUndosList = toAdd[0].toUndoList;
        }
        console.log(toUndosList);

        toUndosList.map(list => {
            if (list.strokeNo === d107BaseBallScoreCard.strokeNo) {
                toUndosObj = list;
                typeMode = list.type;
            }
        })

        console.log(toUndosObj)

        if (typeMode === 'challengedBy') {

            if (toAdd[0].noOfInnings === 1 && toAdd[0].challengedWickets === 0 && toUndosObj.actionType === 0) {
                toAdd[0].noOfInnings = 0;
                this.setState({ batFirstTeam: 'challengedBy' })

            }

            else if (toAdd[0].noOfInnings === 0 && toUndosObj.wickets === 2 && d107BaseBallScoreCard.setNo > 1) {

                d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo - 1;

            }
            toAdd[0].challengedByInningScore = toUndosObj.runs;
            toAdd[0].challengedHits = toUndosObj.hits;
            toAdd[0].challengedRun = toUndosObj.runs;
            toAdd[0].challengedWickets = toUndosObj.wickets;

            this.setState({
                wickets: toAdd[0].challengedWickets,
                runs: toAdd[0].challengedRun,
                hits: toAdd[0].challengedHits
            })

            if (toUndosObj.actionType === 1) {
                d107BaseBallScoreCard.byBaseballScoreCard.totalHits = d107BaseBallScoreCard.byBaseballScoreCard.totalHits - 1;
                this.setState({
                    byTotalHits: this.state.byTotalHits - 1
                })
            }

            if (toUndosObj.actionType === 2) {
                d107BaseBallScoreCard.byBaseballScoreCard.totalRuns = d107BaseBallScoreCard.byBaseballScoreCard.totalRuns - 1;
                this.setState({
                    byTotalRuns: this.state.byTotalRuns - 1
                })
            }

            strokes -= 1;

        }

        else {

            if (toAdd[0].noOfInnings === 1 && toAdd[0].challengedWickets === 0 && toUndosObj.actionType === 0) {
                toAdd[0].noOfInnings = 0;
                this.setState({ batFirstTeam: 'challengedTo' })

            }

            else if (toAdd[0].noOfInnings === 0 && toUndosObj.wickets === 2 && d107BaseBallScoreCard.setNo > 1) {

                d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo - 1;

                toAdd[0].challengedToInningScore = toUndosObj.runs;
                toAdd[0].challengedHits = toUndosObj.hits;
                toAdd[0].challengedRun = toUndosObj.runs;
                toAdd[0].challengedWickets = toUndosObj.wickets;

                this.setState({
                    wickets: toAdd[0].challengedWickets,
                    runs: toAdd[0].challengedRun,
                    hits: toAdd[0].challengedHits
                })

                if (toUndosObj.actionType === 1) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalHits = d107BaseBallScoreCard.toBaseballScoreCard.totalHits - 1;
                    this.setState({
                        toTotalHits: this.state.toTotalHits - 1
                    })
                }

                if (toUndosObj.actionType === 2) {
                    d107BaseBallScoreCard.toBaseballScoreCard.totalRuns = d107BaseBallScoreCard.toBaseballScoreCard.totalRuns - 1;
                    this.setState({
                        toTotalRuns: this.state.toTotalRuns - 1
                    })
                }

                strokes -= 1;

            }

        }

        toUndosList = toUndosList.filter((x) => x != toUndosObj)
        toAdd[0].toUndoList = toUndosList;
        d107BaseBallScoreCard.strokeNo = strokes;
        if (toAdd[0].toUndoList.length === 0) {
            if (d107BaseBallScoreCard.setNo > 1) {
                d107BaseBallScoreCard.setNo -= 1;
                d107BaseBallScoreCard.setNo = d107BaseBallScoreCard.setNo;
            }
        }

        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107BaseBallScoreCard: d107BaseBallScoreCard,
        }
        DUpdateTournamentsScore(score);
        this.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard });
        console.log(d107BaseBallScoreCard);

    }

    updateUserPoints(typess) {
        var d107BaseBallScoreCard = this.state.d107BaseBallScoreCard;

        this.state.rounds.map(r => {
            if (r.position === this.state.setNumber) {
                toAdd = r;
                toUndos = toAdd.toUndoList;
                if (toUndos == null) {
                    toUndos = [];
                }
            }
        })

        if (this.state.setNumber <= d107BaseBallScoreCard.noOfSets) {

            if (this.state.setNumber === d107BaseBallScoreCard.setNo) {

                strokeNo = d107BaseBallScoreCard.strokeNo++;

                if (this.state.batFirstTeam === 'challengedBy') {

                    if (typess === 'W') {

                        if (this.state.wickets < 2) {

                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: 0,
                            }
                            toUndos.push(toUndoListObj)
                            this.setState({ wickets: this.state.wickets + 1 })
                            toAdd.challengedWickets = this.state.wickets + 1;

                        }

                        else {

                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: 0,
                            }
                            toUndos.push(toUndoListObj)

                            this.setState({ hits: 0, runs: 0, wickets: 0, batFirstTeam: 'challengedTo' })

                            toAdd.challengedWickets = 0;
                            toAdd.challengedHits = 0;
                            toAdd.challengedRun = 0;
                            toAdd.noOfInnings = 1;
                            inngs++;
                        }

                    }


                    if (typess === 'H') {

                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 1,
                            type: this.state.batFirstTeam,
                            inningScore: 0,
                        }
                        toUndos.push(toUndoListObj)

                        this.setState({ hits: this.state.hits + 1, byTotalHits: this.state.byTotalHits + 1 })

                        d107BaseBallScoreCard.byBaseballScoreCard.totalHits = this.state.byTotalHits + 1;

                        toAdd.challengedHits = this.state.hits + 1;

                    }

                    if (typess === 'R') {

                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 2,
                            type: this.state.batFirstTeam,
                            inningScore: 0,
                        }
                        toUndos.push(toUndoListObj)

                        this.setState({ runs: this.state.runs + 1, byTotalRuns: this.state.byTotalRuns + 1 })

                        d107BaseBallScoreCard.byBaseballScoreCard.totalRuns = this.state.byTotalRuns + 1;

                        toAdd.challengedByInningScore = this.state.runs + 1;
                        toAdd.challengedRun = this.state.runs + 1;

                    }
                    console.log(toUndos)
                }

                if (this.state.batFirstTeam === 'challengedTo') {

                    if (typess === 'W') {

                        if (this.state.wickets < 2) {

                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: 0,
                            }
                            toUndos.push(toUndoListObj)

                            this.setState({ wickets: this.state.wickets + 1 })
                            toAdd.challengedWickets = this.state.wickets + 1;

                        }

                        else {

                            toUndoListObj = {
                                strokeNo: strokeNo + 1,
                                hits: toAdd.challengedHits,
                                runs: toAdd.challengedRun,
                                wickets: toAdd.challengedWickets,
                                actionType: 0,
                                type: this.state.batFirstTeam,
                                inningScore: 0,
                            }
                            toUndos.push(toUndoListObj)

                            this.setState({ hits: 0, runs: 0, wickets: 0, batFirstTeam: 'challengedBy' })

                            toAdd.challengedWickets = 0;
                            toAdd.challengedHits = 0;
                            toAdd.challengedRun = 0;
                            toAdd.noOfInnings = 1;
                            inngs++;
                        }

                    }

                    if (typess === 'H') {

                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 1,
                            type: this.state.batFirstTeam,
                            inningScore: 0,
                        }
                        toUndos.push(toUndoListObj)

                        this.setState({ hits: this.state.hits + 1, toTotalHits: this.state.toTotalHits + 1 })

                        d107BaseBallScoreCard.toBaseballScoreCard.totalHits = this.state.toTotalHits + 1;

                        toAdd.challengedHits = this.state.hits + 1;

                    }

                    if (typess === 'R') {

                        var toUndoListObj = {
                            strokeNo: strokeNo + 1,
                            hits: toAdd.challengedHits,
                            runs: toAdd.challengedRun,
                            wickets: toAdd.challengedWickets,
                            actionType: 2,
                            type: this.state.batFirstTeam,
                            inningScore: 0,
                        }
                        toUndos.push(toUndoListObj)

                        this.setState({ runs: this.state.runs + 1, toTotalRuns: this.state.toTotalRuns + 1 })

                        d107BaseBallScoreCard.toBaseballScoreCard.totalRuns = this.state.toTotalRuns + 1;

                        toAdd.challengedToInningScore = this.state.runs + 1;
                        toAdd.challengedRun = this.state.runs + 1;

                    }
                    console.log(toUndos)
                }

            }

        }

        else {
            return '';
        }

        toAdd.toUndoList = toUndos;
        var rounds = this.state.rounds;
        var indx = parseInt(this.state.setNumber);
        rounds.slice(indx, 1, toAdd);

        if (inngs <= 1) {

            if (this.state.setNumber <= 9) {

                const that = this;
                var d107BaseBallScoreCard1 = {
                    byBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                        teamId: that.state.matchPairObj.firstId,
                        winner: false
                    },
                    toBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                        teamId: that.state.matchPairObj.secondId,
                        winner: false
                    },
                    setNo: that.state.setNumber,
                    noOfSets: d107BaseBallScoreCard.noOfSets,
                    strokeNo: strokeNo + 1,
                    batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                    rounds
                }
                var score1 = {
                    tournamentId: that.props.tournament.tournamentId,
                    matchId: that.props.matchId,
                    d107BaseBallScoreCard: d107BaseBallScoreCard1,
                }

                that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds })
                console.log(score1)
                console.log(inngs)
                DUpdateTournamentsScore(score1);

            }

        }

        if (inngs >= 2) {

            if (this.state.setNumber < 9) {

                var changeSet = d107BaseBallScoreCard.setNo + 1;
                this.setState({ setNumber: this.state.setNumber + 1 })

                const that = this;
                var d107BaseBallScoreCard1 = {
                    byBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                        teamId: that.state.matchPairObj.firstId,
                        winner: false
                    },
                    toBaseballScoreCard: {
                        totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                        totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                        teamId: that.state.matchPairObj.secondId,
                        winner: false
                    },
                    setNo: changeSet,
                    noOfSets: d107BaseBallScoreCard.noOfSets,
                    strokeNo: strokeNo + 1,
                    batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                    rounds
                }
                var score1 = {
                    tournamentId: that.props.tournament.tournamentId,
                    matchId: that.props.matchId,
                    d107BaseBallScoreCard: d107BaseBallScoreCard1,
                }
                that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds })
                console.log(score1)
                inngs = 0;
                console.log(inngs)
                DUpdateTournamentsScore(score1);

            }

            if (this.state.setNumber === 9) {

                if (this.state.byTotalRuns > this.state.toTotalRuns) {

                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.firstId,
                            winner: true
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.secondId,
                            winner: false
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }

                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, winnerModalOpen: true, byWinner: true, winner: this.state.matchPairObj.firstId })
                    console.log(score1)
                    inngs = 0;
                    console.log(inngs)
                    DUpdateTournamentsScore(score1);

                }

                if (this.state.byTotalRuns < this.state.toTotalRuns) {

                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.firstId,
                            winner: false
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.secondId,
                            winner: true
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }

                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, winnerModalOpen: true, toWinner: true, winner: this.state.matchPairObj.secondId })
                    console.log(score1)
                    inngs = 0;
                    console.log(inngs)
                    DUpdateTournamentsScore(score1);

                }

                if (this.state.byTotalRuns === this.state.toTotalRuns) {

                    const that = this;
                    var d107BaseBallScoreCard1 = {
                        byBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.byBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.byBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.firstId,
                            winner: false
                        },
                        toBaseballScoreCard: {
                            totalHits: d107BaseBallScoreCard.toBaseballScoreCard.totalHits,
                            totalRuns: d107BaseBallScoreCard.toBaseballScoreCard.totalRuns,
                            teamId: that.state.matchPairObj.secondId,
                            winner: false
                        },
                        setNo: that.state.setNumber,
                        noOfSets: d107BaseBallScoreCard.noOfSets,
                        strokeNo: strokeNo + 1,
                        batFirstTeam: d107BaseBallScoreCard.batFirstTeam,
                        rounds
                    }
                    var score1 = {
                        tournamentId: that.props.tournament.tournamentId,
                        matchId: that.props.matchId,
                        d107BaseBallScoreCard: d107BaseBallScoreCard1,
                    }

                    that.setState({ d107BaseBallScoreCard: d107BaseBallScoreCard1, rounds: rounds, selectWinnerModal: true })
                    console.log(score1)
                    inngs = 0;
                    console.log(inngs)
                    DUpdateTournamentsScore(score1);

                }

            }

        }

    }

    render() {
        const backLinks = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={logo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td>{this.state.byTeamName}</td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.toTeamName}</td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Select Bat First</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-1" onClick={this.changeData.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byTeamName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-2" onClick={this.changeData.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toTeamName}</label>
                        </li>
                    </ul>
                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.selectBatFirstTeam}>Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>
                </Modal>

                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Scores are tied please choose the winner!</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-1" onClick={this.changeDataa.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byTeamName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-2" onClick={this.changeDataa.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toTeamName}</label>
                        </li>
                    </ul>
                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.doneMatch}>Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>
                </Modal>



                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video" style={{ backgroundImage: "url(" + noBroadcast + ")" }}>
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <tbody>
                                        <tr>
                                            {(this.state.batFirstTeam === 'challengedBy') ? <td>{this.state.byTeamName}</td> : <td>{this.state.toTeamName}</td>}
                                            <td><input placeholder={this.state.hits} class="inputsize-d" readOnly="readOnly" /></td>
                                            <td><input placeholder={this.state.runs} class="inputsize-d" readOnly="readOnly" /></td>
                                            <td><input placeholder={this.state.wickets} class="inputsize-d" readOnly="readOnly" /></td>
                                        </tr>
                                        <tr>
                                            <td>Innings {this.state.setNumber}</td>
                                            <td><span onClick={() => this.updateUserPoints('H')} class="baseballviolet-d">Hits</span></td>
                                            <td><span onClick={() => this.updateUserPoints('R')} class="baseballgreen-d">Runs</span></td>
                                            <td><span onClick={() => this.updateUserPoints('W')} class="baseballred-d">Wicket</span></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>

                        <h4 className="scorehead">Match Score</h4>

                        <div className="tableresponsive">
                            <div className="tableinner">
                                <table>

                                    <thead>
                                        <tr>
                                            <th>Teams</th>
                                            {this.state.rounds.map((p, i = 1) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <th>R{i}</th>
                                                }
                                            })}
                                            <th>Total Hits</th>
                                            <th>Total Runs</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{this.state.byTeamName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input placeholder={p.challengedByInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.byTotalHits}</td>
                                            <td>{this.state.byTotalRuns}</td>
                                        </tr>

                                        <tr>
                                            <td>{this.state.toTeamName}</td>
                                            {this.state.rounds.map((p, i) => {
                                                if ((i > 0) && (i < 10)) {
                                                    return <td><input placeholder={p.challengedToInningScore} className="inputsize-d" /></td>
                                                }
                                            })}
                                            <td>{this.state.toTotalHits}</td>
                                            <td>{this.state.toTotalRuns}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.ToundoList()}>Undo</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
