import React, { Component } from 'react'
import ConfigUrl from './ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import userMaleIcon from "./img/male.png";
import userFemaleIcon from "./img/female.png";
import send from "./img/send.png"
import attach from "./img/attach.png"
import happiness from './img/happiness.png';
import Emoji from './Emoji';
import teamIcon from "./img/team.png";
import $ from 'jquery';
import chaticon from './img/chaticon.png';
import GetCurrentTimestamp from './GetCurrentTimestamp';
const ConfigApiUrl = ConfigUrl('apiUrl');
let chatR = [];
let chatR1 = [];


const rshApi = ConfigApiUrl;
const dummyMessage = {
    type: null,
    teamId: null,
    senderId: null,
    receiverId: null,
    message: {
        defaultInfo: false,
        delivered: false,
        message: null,
        messageType: null,
        read: false,
        sent: false,
        sentBy: null,
        timestamp: null,

    }
}

const dummyMessage1 = {
    type: null,
    teamId: null,
    senderId: null,
    receiverId: null,
    message: {
        defaultInfo: false,
        delivered: false,
        message: null,
        messageType: null,
        read: false,
        sent: false,
        sentBy: null,
        timestamp: null,

    }
}

const messageType = [{
    type: "text"
}, {
    type: "image"
}, {
    type: "video"
}
]

class ChatRecent1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            rounds: [],
            chatRecent: [],
            user1: [],
            memberName: [],
            chatData: [],
            chatData1: [],
            users: [],
            userContactNo: [],
            showMe: false,
            showMe1: true,
            buddies: [],
            fullName: '',
            value: '',
            value1: '',
            showChatBuddiesTab: false,
            youtubeUrl: '',
        };
        this.state.user = JSON.parse(localStorage.getItem("result"));
        this.openchat = this.openchat.bind(this);
        this.openchatbox1 = this.openchatbox1.bind(this);
        this.openchatbox2 = this.openchatbox2.bind(this);
        this.openmenu = this.openmenu.bind(this);
        this.closemenu = this.closemenu.bind(this);
        this.chatconversation = this.chatconversation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleSubmit1 = this.handleSubmit1.bind(this);
    }
    componentDidMount() {
        const that = this;
        that.chatRecent();

        that.Userdata();
        setTimeout(function () { that.buddies(); }, 100);
    }

    // ************************Send Team Messege**********************

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        let timestamp = GetCurrentTimestamp();
        if (this.state.value) {

            if (this.state.chatType === 'buddy') {
                dummyMessage.type = this.state.chatType;
                dummyMessage.teamId = null;
                dummyMessage.senderId = this.state.userContactNo;
                dummyMessage.receiverId = this.state.chatId;
                dummyMessage.message.delivered = true;
                dummyMessage.message.message = this.state.value;
                dummyMessage.message.messageType = messageType[0].type;
                dummyMessage.message.sent = true;
                dummyMessage.message.sentBy = this.state.user.contactNo;
                dummyMessage.message.timestamp = timestamp;
                chatR = [...this.state.chatData];
            }
            else {
                dummyMessage.type = this.state.chatType;
                dummyMessage.teamId = this.state.chatId;
                dummyMessage.senderId = null;
                dummyMessage.receiverId = null;
                dummyMessage.message.delivered = true;
                dummyMessage.message.message = this.state.value;
                dummyMessage.message.messageType = messageType[0].type;
                dummyMessage.message.sent = true;
                dummyMessage.message.sentBy = this.state.user.contactNo;
                dummyMessage.message.timestamp = timestamp;
                chatR = [...this.state.chatData];
            }

            let data = dummyMessage;
            const that = this;
            const apiUrl = rshApi + "/conversation/send-message";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    data
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    chatR = [...chatR, dummyMessage.message]
                    that.setState({ chatData: chatR, value: '' })

                }).catch(function (error) {
                    console.log(error)
                })
            this.chatRecent();




        }
    }

    imageChange(e) {
        let timestamp = GetCurrentTimestamp();
        if (this.state.chatType === 'buddy') {
            dummyMessage.type = this.state.chatType;
            dummyMessage.teamId = null;
            dummyMessage.senderId = this.state.userContactNo;
            dummyMessage.receiverId = this.state.chatId;
            dummyMessage.message.delivered = true;
            dummyMessage.message.message = this.state.value;
            dummyMessage.message.messageType = messageType[0].type;
            dummyMessage.message.sent = true;
            dummyMessage.message.sentBy = this.state.user.contactNo;
            dummyMessage.message.timestamp = timestamp;
            chatR = [...this.state.chatData];
        }
        else {
            dummyMessage.type = this.state.chatType;
            dummyMessage.teamId = this.state.chatId;
            dummyMessage.senderId = null;
            dummyMessage.receiverId = null;
            dummyMessage.message.delivered = true;
            dummyMessage.message.message = this.state.value;
            dummyMessage.message.messageType = messageType[0].type;
            dummyMessage.message.sent = true;
            dummyMessage.message.sentBy = this.state.user.contactNo;
            dummyMessage.message.timestamp = timestamp;
            chatR = [...this.state.chatData];
        }

        const apiUrl = rshApi + "/upload-file";
        const _imageValidFileExtensions = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        const _videoValidFileExtensions = ["video/m4v", "video/avi", "video/mpg", "video/mp4"];
        let fileType = null;
        let fileData = e.target.files[0];
        _imageValidFileExtensions.forEach(img => {
            if (fileData.type === img) {
                fileType = 'image';
            }
        })
        _videoValidFileExtensions.forEach(vid => {
            if (fileData.type === vid) {
                fileType = 'video';
            }
        })
        let that = this;
        chatR = [...this.state.chatData];
        if (fileType === "image") {
            dummyMessage.message.messageType = messageType[1].type;
            var data = {
                "data": {
                    "folder": "conversations/" + this.state.chatId,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", fileData);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    // console.log(result)
                    uploadSuggestedPostData(result.data.downloadUrl);
                    chatR = [...chatR, dummyMessage.message];
                    // console.log(that.state.chatData)
                    //  console.log(chatR)
                    that.setState({ chatData: chatR });
                });
            function uploadSuggestedPostData(fileUrl) {
                dummyMessage.message.message = fileUrl;
                let data = dummyMessage;
                fetch(rshApi + "/conversation/send-message", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                    });
            }
        }
        if (fileType === "video") {
            dummyMessage.message.messageType = messageType[1].type;
            var data = {
                "data": {
                    "folder": "conversations/" + this.state.chatId,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", fileData);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    //  console.log(result)
                    uploadSuggestedPostData(result.data.downloadUrl);
                    chatR = [...chatR, dummyMessage.message];
                    // console.log(that.state.chatData)
                    // console.log(chatR)
                    that.setState({ chatData: chatR });
                });
            function uploadSuggestedPostData(fileUrl) {
                dummyMessage.message.message = fileUrl;
                let data = dummyMessage;
                fetch(rshApi + "/conversation/send-message", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                    });
            }
        }
    }

    //*************************************************************
    // ************************Send buddy Messege**********************



    handleChange1(event) {
        this.setState({ value1: event.target.value });
    }

    handleSubmit1(event) {
        if (this.state.value) {
            let timestamp = GetCurrentTimestamp();
            dummyMessage1.type = this.state.chatType;
            dummyMessage1.teamId = null;
            dummyMessage1.senderId = this.state.userContactNo;
            dummyMessage1.receiverId = this.state.currentObj.contactNo;
            dummyMessage1.message.delivered = true;
            dummyMessage1.message.message = this.state.value1;
            dummyMessage1.message.messageType = messageType[0].type;
            dummyMessage1.message.sent = true;
            dummyMessage1.message.sentBy = this.state.user.contactNo;
            dummyMessage1.message.timestamp = timestamp;
            chatR = [...this.state.chatData];
            let data = dummyMessage1;
            // console.log(data)
            const that = this;
            const apiUrl = rshApi + "/conversation/send-message";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    data
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    chatR1 = [...chatR1, dummyMessage1.message]
                    that.setState({ chatData1: chatR1, value1: '' })
                }).catch(function (error) {
                    console.log(error)
                })
            this.chatconversation1(this.state.currentObj);
        }
    }

    imageChange1(e) {
        var timestamp = Math.floor(Date.now());
        dummyMessage1.type = this.state.chatType;
        dummyMessage1.teamId = null;
        dummyMessage1.senderId = this.state.userContactNo;
        dummyMessage1.receiverId = this.state.currentObj.contactNo;
        dummyMessage1.message.delivered = true;
        dummyMessage1.message.message = this.state.value1;
        dummyMessage1.message.sent = true;
        dummyMessage1.message.sentBy = this.state.user.contactNo;
        dummyMessage1.message.timestamp = timestamp;
        const apiUrl = rshApi + "/upload-file";
        const _imageValidFileExtensions = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"];
        const _videoValidFileExtensions = ["video/m4v", "video/avi", "video/mpg", "video/mp4"];
        let fileType = null;
        let fileData = e.target.files[0];
        _imageValidFileExtensions.forEach(img => {
            if (fileData.type === img) {
                fileType = 'image';
            }
        })
        _videoValidFileExtensions.forEach(vid => {
            if (fileData.type === vid) {
                fileType = 'video';
            }
        })
        let that = this;
        chatR = [...this.state.chatData];
        if (fileType === "image") {
            dummyMessage1.message.messageType = messageType[1].type;
            var data = {
                "data": {
                    "folder": "conversations/" + this.state.chatId,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", fileData);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    // console.log(result)
                    uploadSuggestedPostData(result.data.downloadUrl);
                    chatR = [...chatR1, dummyMessage1.message];
                    // console.log(that.state.chatData1)
                    //  console.log(chatR1)
                    that.setState({ chatData1: chatR1 });
                });
            function uploadSuggestedPostData(fileUrl) {
                dummyMessage1.message.message = fileUrl;
                let data = dummyMessage1;
                fetch(rshApi + "/conversation/send-message", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                    });
            }
        }
        if (fileType === "video") {
            dummyMessage1.message.messageType = messageType[1].type;
            var data = {
                "data": {
                    "folder": "conversations/" + this.state.chatId,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", fileData);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    // console.log(result)
                    uploadSuggestedPostData(result.data.downloadUrl);
                    chatR1 = [...chatR1, dummyMessage1.message];
                    //  console.log(that.state.chatData1)
                    // console.log(chatR1)
                    that.setState({ chatData1: chatR1 });
                });
            function uploadSuggestedPostData(fileUrl) {
                dummyMessage1.message.message = fileUrl;
                let data = dummyMessage1;
                fetch(rshApi + "/conversation/send-message", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                    });
            }
        }
    }


    buddies() {
        const apiUrl = rshApi + "users/get-buddies";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "buddyId": this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //  console.log(result)
                //  console.log(result.data.messages)
                that.setState({
                    buddies: result.data,
                });
            });
    }



    chatRecent = () => {
        const apiUrl = rshApi + "conversation/recent";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "contactNo": this.state.user.contactNo,
                    "type": this.state.chatType,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //  console.log(result)
                if (result.error !== "Internal Server Error") {
                    that.setState({
                        chatRecent: result.data,
                    });
                }

            });
    }






    chatTeam = (id) => {
        const apiUrl = rshApi + "/teams/get-team";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "teamId": id,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                // console.log(result)
                that.setState({
                    memberName: result.data.users
                });
            });
    }

    chatconversation = () => {
        if (this.state.chatType === 'team') {
            const apiUrl = rshApi + "/conversation/get-conversation";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "teamId": this.state.chatId,
                        "buddyId": this.state.buddyId,
                        "type": this.state.chatType,
                        "userId": this.state.user.contactNo,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    //  console.log(result)
                    // console.log(result.data.messages)
                    that.setState({
                        chatData: result.data.messages
                    });
                    that.setState({
                        teamId: result.data.teamId
                    });
                });
            setInterval(that.chatData, 1000)
        }
        if (this.state.chatType === 'buddy') {
            const apiUrl = rshApi + "/conversation/get-conversation";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "teamId": this.state.chatId,
                        "buddyId": this.state.buddyId,
                        "type": this.state.chatType,
                        "userId": this.state.user.contactNo,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    // console.log(result)
                    //  console.log(result.data.messages)
                    if (result.data.messages !== null) {
                        that.setState({
                            chatData: result.data.messages
                        });
                    }

                });
            setInterval(that.chatData, 1000)
        }
    }



    Userdata = (userId) => {
        const apiUrl = rshApi + "users/buddy-profile";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.user.contactNo,
                    userId: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //  console.log(result)
                that.setState({
                    userContactNo: result.data.user.contactNo,
                });
            });
    }

    chatconversation1 = (obj) => {
        let state
        const apiUrl = rshApi + "/conversation/get-conversation";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "buddyId": obj.contactNo,
                    "type": 'buddy',
                    "userId": this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                console.log(result.data.messages)
                that.setState({
                    chatData1: result.data.messages
                });
                that.setState({
                    profilePic: obj.profilePic
                });
                that.setState({
                    buddyName: obj.name
                });
            });
        $("#userchatdiv2").css("transform", "scale(1)");
        setInterval(that.chatData1, 1000)
    }


    openchat = () => {
        this.setState({ showChatBuddiesTab: !this.state.showChatBuddiesTab })
        $(".chatdiv").toggleClass("chatdivopen");
        $(".chatopen").toggleClass("chatopenimg");
    }
    closechat = () => {
        $("#userchatdiv1").css("transform", "scale(0)");
    }

    openchatbox1 = (obj) => {
        $(document).ready(function () {
            $('#hey1').animate({ scrollTop: 100000 }, 800)
        });
        // console.log(obj)
        this.setState({ headername: obj.name })
        this.setState({ headericon: obj.icon })
        if (obj.type === 'team') {
            this.setState({ chatType: obj.type, chatId: obj.id, })
            this.chatTeam(obj.id)
            this.setState({ headername: obj.name })
            this.setState({ headericon: obj.icon })
        }
        else {
            let buddies = this.state.buddies;
            let forBuddy = buddies.filter(budd => {
                if (budd.contactNo === obj.id) {
                    return budd;
                }
            })
            this.setState({ chatType: obj.type, chatId: obj.id })
            this.setState({ buddyId: obj.id, memberName: forBuddy })
            //console.log(obj.id)
        }
        let that = this;
        setTimeout(function () { that.chatconversation(obj) }, 600);
        $("#userchatdiv1").css("transform", "scale(1)");
        setInterval(that.chatconversation, 1000)

    }




    /// *******************************Buddies Chat Element****************************************
    openchatbox2 = (obj) => {


        $('#hey').animate({ scrollTop: 100000 }, 800)


        this.setState({ currentObj: obj, chatType: 'buddy', chatId: obj.id })
        // this.chatTeam(obj.id)
        let that = this;
        setTimeout(function () { that.chatconversation1(obj) }, 1000);
        $("#userchatdiv2").css("transform", "scale(1)");
        setInterval(that.chatconversation1, 1000)
    }

    closechat1 = () => {
        $("#userchatdiv2").css("transform", "scale(0)");
    }
    openmenu = () => {
        $(".d_leftsec").toggleClass("d_leftsec_width");
    }
    closemenu = () => {
        $(".d_leftsec").toggleClass("d_leftsec_width");
    }

    textHandler = event => {
        //  console.log(event.target.name[0]);
    }

    operation = () => {
        this.setState({
            showMe: true, showMe1: false
        })
    }
    operation1 = () => {
        this.setState({
            showMe1: true, showMe: false
        })
    }


    checkUrl() {
        var res = this.state.youtubeUrl.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/);
        if (res !== null) {
            var youtube_video_id = this.state.youtubeUrl.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
            this.setState({ showyoutubePreview: true, youtubePreview: '//img.youtube.com/vi/' + youtube_video_id + '/0.jpg' });
            this.setState({ youtubeId: youtube_video_id });

            document.getElementById('postVideo').value = '';
            this.setState({ showImagePreview: false });
        }
        else {
            //show error message  youtube url is not right
        }
    }

    childEmojicallback = (emojiObj) => {
        let input = this.state.value;
        input += emojiObj.emoji;
        this.setState({ value: input });
    }




    render() {


        const { fullName } = this.state
        const that = this;
        let icon = this.state.headericon ? this.state.headericon : userMaleIcon;
        let icon1 = this.state.profilePic ? this.state.profilePic : userMaleIcon;
        return (
            <React.Fragment>
                <section className="chatsec">
                    <div className="chatdiv ">
                        {this.state.showChatBuddiesTab ? <div className="btn-pref btn-group btn-group-justified btn-group-lg chatside_tab" role="group" aria-label="...">
                            <div className="btn-group" role="group">
                                <button onClick={() => this.operation1()} type="button" id="stars" className="btn btn-primary tabsbtn-d" href="#tab1" data-toggle="tab">
                                    <div>Chat</div>
                                </button>
                            </div>
                            <div className="btn-group" role="group">
                                <button onClick={() => this.operation()} type="button" id="favorites" className="btn btn-primary tabsbtn-d" href="#tab2" data-toggle="tab">
                                    <div>Buddies</div>
                                </button>
                            </div>
                        </div> : ''}
                        {/* ************************** Chat Header Recent*********************************** */}
                        <ul className="chatul" id="tab1">
                            {
                                this.state.showMe1 ?
                                    <>
                                        {
                                            this.state.chatRecent.map(x => {
                                                return (() => {
                                                    if (x.icon === null) {
                                                        switch (x.gender) {

                                                            case "Male":
                                                                return (<li><a onClick={() => this.openchatbox1(x)}><span style={{ backgroundImage: "url(" + userMaleIcon + ")" }} />
                                                                    <div className="namechat_side">
                                                                        <p className="namechat">{x.name}</p>
                                                                        <p className="chatmsg_show">{x.lastMsgType}</p>
                                                                    </div>
                                                                </a></li>);
                                                            case "Female":
                                                                return (<li><a onClick={() => this.openchatbox1(x)}><span style={{ backgroundImage: "url(" + userFemaleIcon + ")" }} />
                                                                    <div className="namechat_side">
                                                                        <p className="namechat">{x.name}</p>
                                                                        <p className="chatmsg_show">{x.lastMsgType}</p>
                                                                    </div>
                                                                </a></li>);
                                                            case "mix":
                                                                return (<li><a onClick={() => this.openchatbox1(x)}><span style={{ backgroundImage: "url(" + userMaleIcon + ")" }} />
                                                                    <div className="namechat_side">
                                                                        <p className="namechat">{x.name}</p>
                                                                        <p className="chatmsg_show">{x.lastMsgType}</p>
                                                                    </div>
                                                                </a></li>);
                                                        }
                                                    } else {

                                                        return (<li><a onClick={() => this.openchatbox1(x)}><span style={{ backgroundImage: "url(" + x.icon + ")" }} />
                                                            <div className="namechat_side">
                                                                <p className="namechat">{x.name}</p>
                                                                <p className="chatmsg_show">{x.lastMsgType}</p>
                                                            </div>
                                                        </a></li>);
                                                    }
                                                })();
                                            })
                                        }
                                    </>
                                    : ''
                            }
                            {/* ************************** Chat Header Buddy****************************** */}
                            {
                                this.state.showMe ?
                                    <>
                                        {
                                            this.state.buddies.map(x => {
                                                return (() => {
                                                    if (x.profilePic === null) {
                                                        switch (x.gender) {
                                                            case "Male":
                                                                return (<li><a onClick={() => this.openchatbox2(x)}><span style={{ backgroundImage: "url(" + userMaleIcon + ")" }} />
                                                                    <div className="namechat_side">
                                                                        <p className="namechat">{x.name}</p>
                                                                        <p className="chatmsg_show">Buddy.</p>
                                                                    </div>
                                                                </a></li>);
                                                            case "Female":
                                                                return (<li><a onClick={() => this.openchatbox2(x)}><span style={{ backgroundImage: "url(" + userFemaleIcon + ")" }} />
                                                                    <div className="namechat_side">
                                                                        <p className="namechat">{x.name}</p>
                                                                        <p className="chatmsg_show">Buddy.</p>
                                                                    </div>
                                                                </a></li>);
                                                        }
                                                    } else {
                                                        return (<li><a onClick={() => this.openchatbox2(x)}><span style={{ backgroundImage: "url(" + x.profilePic + ")" }} />
                                                            <div className="namechat_side">
                                                                <p className="namechat">{x.name}</p>
                                                                <p className="chatmsg_show">Buddy.</p>
                                                            </div>
                                                        </a></li>);
                                                    }
                                                })();
                                            })
                                        }
                                    </>
                                    : ''
                            }
                        </ul>
                        <a className="chatopen" onClick={this.openchat}><img src={chaticon} alt="" /></a>
                    </div>

                    {/**********************************BuddyChat********************************/}

                    <div className="userchatdiv" id="userchatdiv2">
                        <div className="chathead">
                            <span className="ch_userimg" style={{
                                backgroundImage: "url(" + icon1 + ")"
                            }} />
                            <label>{this.state.buddyName} <span className="active">Active 16 minutes ago</span></label>
                            <a className="chatclose fa fa-close" id="closechat2" onClick={this.closechat1} />
                        </div>
                        <div className="chatdetails">
                            {this.state.chatData1.map(z => {
                                let defaultmsg, defaulttimestamp;
                                if (z.defaultInfo === true) {
                                    var formattedTimestamp = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(z.timestamp);
                                    var res = formattedTimestamp.split(",");
                                    var feadsDate = res[0] + "," + res[1];
                                    var todayDate = new Date(new Date().setDate(new Date().getDate()));
                                    todayDate = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(todayDate);
                                    var res1 = todayDate.split(",");
                                    todayDate = res1[0] + "," + res1[1];
                                    var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
                                    yesterdayDate = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(yesterdayDate);
                                    var res2 = yesterdayDate.split(",");
                                    yesterdayDate = res2[0] + "," + res2[1];

                                    if (feadsDate === todayDate) {
                                        feadsDate = "Today" + "," + res[2];
                                    }
                                    else if (feadsDate === yesterdayDate) {
                                        feadsDate = "Yesterday" + "," + res[2];
                                    }
                                    else {
                                        feadsDate = feadsDate + "," + res[2];
                                    }
                                    defaultmsg = z.message;
                                    defaulttimestamp = z.timestamp;
                                }
                                return (
                                    <React.Fragment>
                                        {defaultmsg ? <><label className="infotag_chat">{defaultmsg}</label>
                                            <label className="infotag_chat">{feadsDate}</label></> : ''}
                                    </React.Fragment>
                                );
                            })}
                            <ul className="chatul_box" id="hey" >
                                {this.state.chatData1.map(z => {
                                    let message, messageType;
                                    this.state.buddies.map(y => {
                                        if (z.sentBy === y.contactNo) {
                                            if (z.defaultInfo !== true) {
                                                message = z.message;
                                                messageType = z.messageType;
                                            }
                                        }
                                    })
                                    return (() => {
                                        switch (messageType) {
                                            case "text":
                                                return (<li className="chatuserli">
                                                    <p>{message}</p>
                                                </li>);
                                            case "image":
                                                return (<li className="chatuserli">
                                                    <p><img className="imgchatme" src={message} /></p>
                                                </li>);
                                            case "video":
                                                return (<li className="chatuserli">
                                                    <p><img className="imgchatme" src={message} /></p>
                                                </li>);
                                        }
                                    })();
                                })}
                                {this.state.chatData1.map(z => {
                                    let message1, messageType;
                                    this.state.buddies.map(y => {
                                        if (z.sentBy === this.state.userContactNo) {
                                            if (z.defaultInfo !== true) {
                                                message1 = z.message
                                                messageType = z.messageType
                                            } else {

                                            }

                                        }
                                    })

                                    return (() => {
                                        switch (messageType) {
                                            case "text":
                                                return (<li className="chatmeli">
                                                    <p>{message1}</p>

                                                </li>);
                                            case "image":
                                                return (<li className="chatmeli" ><p><img className="imgchatme" src={message1} /></p></li>);
                                            case "video":
                                                return (<li className="chatmeli"><p><img className="imgchatme" src={message1} /></p></li>);
                                        }
                                    })();
                                })}
                            </ul>
                        </div>
                        <div className="chatsend">
                            <input type="text" className="inputTextTosend" value={this.state.value1} onChange={this.handleChange1} />
                            {/* <button class="sent_plane" ><img src={send} type="submit" /></button> */}
                            <a class="sent_plane" ><img src={send} onClick={this.handleSubmit1} /></a>
                            <label className="attachment_xl" >
                                <input id="imgFile" onChange={(e) => { this.imageChange1(e) }} style={{ display: "none" }} type="file" />
                                <img class="w3-round" src={attach} />
                            </label>
                        </div>
                    </div>

                    {/**********************************RecentChat************************************/}
                    <div className="userchatdiv" id="userchatdiv1">
                        <div className="chathead">
                            <span className="ch_userimg" style={{
                                backgroundImage: "url(" + icon + ")"
                            }} />
                            <label className="chatHeader_TeamName" >{this.state.headername}</label>
                            <a className="chatclose fa fa-close" id="closechat2" onClick={this.closechat} />
                        </div>

                        <div className="chatdetails">

                            {this.state.chatData.map(z => {
                                let defaulttimestamp, defaultmsg;
                                if (z.defaultInfo === true) {
                                    var formattedTimestamp = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(z.timestamp);
                                    var res = formattedTimestamp.split(",");
                                    var feadsDate = res[0] + "," + res[1];
                                    var todayDate = new Date(new Date().setDate(new Date().getDate()));
                                    todayDate = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(todayDate);
                                    var res1 = todayDate.split(",");
                                    todayDate = res1[0] + "," + res1[1];
                                    var yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
                                    yesterdayDate = Intl.DateTimeFormat('en-US', {
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "2-digit",
                                        second: "2-digit",
                                    }).format(yesterdayDate);
                                    var res2 = yesterdayDate.split(",");
                                    yesterdayDate = res2[0] + "," + res2[1];

                                    if (feadsDate === todayDate) {
                                        feadsDate = "Today" + "," + res[2];
                                    }
                                    else if (feadsDate === yesterdayDate) {
                                        feadsDate = "Yesterday" + "," + res[2];
                                    }
                                    else {
                                        feadsDate = feadsDate + "," + res[2];
                                    }
                                    defaultmsg = z.message;
                                    defaulttimestamp = z.timestamp;
                                }
                                return (
                                    <React.Fragment>
                                        {defaultmsg ? <><label className="infotag_chat">{defaultmsg}</label>
                                            <label className="infotag_chat">{feadsDate}</label></> : ''}
                                    </React.Fragment>
                                );
                            })}
                            <ul className="chatul_box" id="hey1" >
                                {this.state.chatData.map(z => {
                                    let message, profilePic, messageType;
                                    this.state.memberName.map(y => {
                                        if (z.sentBy === y.contactNo) {
                                            if (z.sentBy !== this.state.userContactNo) {
                                                message = z.message;
                                                profilePic = y.profilePic ? y.profilePic : userMaleIcon;
                                                messageType = z.messageType;
                                            } else {

                                            }
                                        }
                                    })
                                    return (() => {
                                        switch (messageType) {
                                            case "text":
                                                return (<li className="chatuserli">
                                                    <span style={{ backgroundImage: "url(" + profilePic + ")" }} />
                                                    <p>{message}</p>
                                                </li>);
                                            case "image":
                                                return (<li className="chatuserli">
                                                    <span style={{ backgroundImage: "url(" + profilePic + ")" }} />
                                                    <p><img className="imgchatme" src={message} alt='' /></p>
                                                </li>);
                                            case "video":
                                                return (<li className="chatuserli">
                                                    <span style={{ backgroundImage: "url(" + profilePic + ")" }} />
                                                    <p><img className="imgchatme" src={message} /></p>
                                                </li>);
                                        }
                                    })();
                                })}
                                {this.state.chatData.map(z => {
                                    let message1, messageType;
                                    if (z.sentBy === this.state.userContactNo) {
                                        if (z.defaultInfo !== true) {
                                            message1 = z.message
                                            messageType = z.messageType
                                        } else {

                                        }
                                    }
                                    return (() => {
                                        switch (messageType) {
                                            case "text":
                                                return (<li className="chatmeli">
                                                    <p>{message1}</p>
                                                </li>);
                                            case "image":
                                                return (<li className="chatmeli" ><p><img className="imgchatme" src={message1} /></p></li>);
                                            case "video":
                                                return (<li className="chatmeli" ><p><img className="imgchatme" src={message1} /></p></li>);
                                        }
                                    })();
                                })}
                            </ul>
                        </div>
                        <div className="chatsend">
                            <input type="text" className="inputTextTosend" value={this.state.value} onChange={this.handleChange} />
                            <img className="Emogi123" onClick={() => this.setState({ isShowEmoji: !this.state.isShowEmoji })} src={happiness} alt="" />
                            <label className="attachment_xl" >
                                <input id="imgFile" onChange={(e) => { this.imageChange(e) }} style={{ display: "none" }} type="file" />
                                <img class="w3-round" src={attach} />
                            </label>
                            <a class="sent_plane" ><img src={send} onClick={this.handleSubmit} /></a>
                        </div>
                        {this.state.isShowEmoji ? <Emoji parentEmojiCallback={this.childEmojicallback} /> : ''}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ChatRecent1;
