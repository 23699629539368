import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import matchbg from '../img/matchbg.png';
import winnerlogo from '../img/winnerlogo.png';
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
export default class Tournament_d102view extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };

        this.closeModal = this.closeModal.bind(this);

        this.playerName = this.props.challenge.accepted.map(accepted => {
            this.props.profileUsers.map(user => {
                if (user.contactNo === accepted) {
                    return <option value={user.contactNo}>{user.name}</option>
                }
            })
        })
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    winnerDone = () => {
        if (this.state.winner == "notselected") {
            this.setState({ modalIsOpen: false });
        }
        else if (this.state.winner === undefined) {
            this.setState({ modalIsOpen: false });
        }
        else {
            this.setState({ modalIsOpen: true });
        }

    }
    matchDone() {
        let that = this;
        var D102ScoreCard = {
            winnerId: this.state.winner,
            ids: this.props.challenge.accepted,
        }
        var score = {
            challengeId: that.props.challenge.tournamentData,
            matchId: that.props.byIDs,
            D102ScoreCard: D102ScoreCard,
        }
        UpdateChallengeScore(score);
        var winner;
        winner = this.state.winner;
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        //challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = winner;

        const apiUrl = rshApi + "/demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                window.location = "/challengeprofile/index.html?id=" + that.props.challenge.challengeId;
            });

    }
    componentDidMount() {

    }
    render() {
        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
        var name;
        var profilePic;
        return (
            <React.Fragment>

                {this.state.modalIsOpen ? <div id="myModal" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">End Event</h4>
                            </div>
                            <div class="modal-body">
                                <label class="alertmsg">
                                    <i class="fa fa-exclamation-circle"></i>
                                    End event declare the winner and you will no longer be available to edit
                         </label>

                            </div>
                            <div class="modal-footer table_actions">
                                <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.matchDone()} >Confirm</button>
                            </div>
                        </div>
                    </div>
                </div> :

                    <div id="myModal" class="modal fade reslutmodel" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">End Event</h4>
                                </div>
                                <div class="modal-body">
                                    <label class="alertmsg">
                                        <i class="fa fa-exclamation-circle"></i>
                                        Please Select a player First
                         </label>

                                </div>
                                {/* <div class="modal-footer table_actions">
                                    <a class="cmnbutton" data-dismiss="modal">Decline</a>
                                    <button class="cmnbutton btnsubmitright" type="button" >Confirm</button>
                                </div> */}
                            </div>
                        </div>
                    </div>}

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            {/* <span>09:33</span> */}
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                        {this.props.challenge.challengeAction !== 'ended' ?
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            : <React.Fragment>
                                <ul className="winnerdropdown_ul">
                                    <li>
                                        {
                                            this.props.profileUsers.map(user => {
                                                if (user.contactNo === this.props.challenge.challengeWinnerId) {
                                                    name = user.name;
                                                    profilePic = user.profilePic;
                                                }
                                                return ''
                                            })
                                        }
                                        <label>Winner</label>
                                        <select class="" disabled>
                                            <option>{name}</option>
                                        </select>
                                    </li>
                                </ul>
                                <div className="winnerbox_view">
                                    <div className="teambox winnerbox" style={{ backgroundImage: "url(" + matchbg + ")" }}>
                                        <ul className="winnerul">
                                            <li class="winner_user">
                                                <img src={winnerlogo} alt="" />
                                                <span style={{ backgroundImage: "url(" + profilePic + ")" }}></span>
                                            </li>
                                            <li><label class="winnername">{name}</label></li>
                                            <li><span class="winnertext">Winner</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>}
                        <div className="table_actions">
                            <a href={backLinks} className="cmnbutton">Back</a>
                            {/* <button className="cmnbutton" type="button" data-toggle="modal" data-target="#myModal" onClick={() => this.winnerDone()}>End Games</button> */}
                        </div>


                    </div>
                </section>
            </React.Fragment>

        );
    }
}
