import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import play from '../img/play.png';
import logo from '../img/finallogo.png';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
export default class D107vue_Archary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            matchSatus: false,
            rounds: [],
            byWinner: false,
            toWinner: false,
            users: [],
            teams: [],
            score: [],
        };
    }

    componentDidMount() {
        const apiUrl = rshApi + "/challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.props.challenge.challengeId,
                    "matchId": that.props.challenge.challengeId,

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    user: result.data.users
                })
                if (result.data.score !== null) {
                    if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== undefined) {
                        if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                            that.setState({ bySetWon: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points.reduce(function (acc, val) { return acc + val; }, 0), byWinner: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.winner });
                        }
                        else {
                            that.setState({ byWinner: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.winner });
                        }
                        that.setState({ matchSatus: true });
                    }
                    if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== undefined) {
                        if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                            that.setState({ toSetWon: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points.reduce(function (acc, val) { return acc + val; }, 0), toWinner: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.winner });
                        }
                        else {
                            that.setState({ toWinner: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.winner });
                        }
                        that.setState({ matchSatus: true });
                    }
                    that.setState({ rounds: result.data.score.d107ArcheryScoreCard.rounds, scorecard: result.data.score.d107ArcheryScoreCard })
                    that.props.profileUsers.map(user => {
                        if (user.contactNo === result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id) {
                            that.setState({ byArcheryScoreCard: user.name });
                        }
                        if (user.contactNo === result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id) {
                            that.setState({ toArcheryScoreCard: user.name });
                        }
                    })
                    that.props.profileTeams.map(team => {
                        if (team.teamId === result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.teamId) {
                            that.setState({ byArcheryScoreCard: team.teamName });
                        }
                        if (team.teamId === result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.teamId) {
                            that.setState({ toArcheryScoreCard: team.teamName });
                        }
                    })
                }
                else {
                    let byName; let toName;
                    that.props.profileUsers.map(user => {
                        if (that.props.challenge.accepted[0] === user.contactNo) {
                            byName = user.name;
                        }
                        if (that.props.challenge.accepted[1] === user.contactNo) {
                            toName = user.name;
                        }
                    })
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    that.props.profileTeams.map(user => {
                        if (that.props.challenge.accepted[0] === user.teamId) {
                            byName = user.teamName;
                        }
                        if (that.props.challenge.accepted[1] === user.teamId) {
                            toName = user.teamName;
                        }
                    })
                    that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                }
            });

        if (that.props.challenge.challengeAction === 'ended') {
            that.setState({ matchSatus: true });
        }
    }

    render() {
        const backLinks = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
        if (this.state.matchSatus) {

            return (
                <React.Fragment>
                    <section className="d_midsec createmidsec broadcast_box">
                        <div className="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> Players/Attempts </th>

                                                <th>Score</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byArcheryScoreCard}</td>
                                                <td>{this.state.bySetWon}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : null}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toArcheryScoreCard}</td>
                                                <td>{this.state.toSetWon}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : null}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <h4 className="scorehead">Match Score</h4>
                            <div className="tableresponsive matchscore_table ">
                                <div className="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Players</th>
                                                <th>Set 1</th>
                                                <th>Set 2</th>
                                                <th>Set 3</th>
                                                <th>Set 4</th>
                                                <th>Set 5</th>

                                                <th>Total</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="padding12" >{this.state.byArcheryScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0 && i < 6) {
                                                        return <td> {k.challengedByPoints} </td>

                                                    }
                                                })}
                                                <td>{this.state.bySetWon}</td>
                                            </tr>
                                            <tr>
                                                <td className="padding12">{this.state.toArcheryScoreCard}</td>
                                                {this.state.rounds.map((k, i) => {
                                                    if (i > 0 && i < 6) {
                                                        return <td> {k.challengedToPoints} </td>
                                                    }

                                                })}
                                                <td>{this.state.toSetWon}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>
                            </div>
                        </div></section>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>

                            </div>
                        </div>
                    </section>
                </React.Fragment >
            );
        }
    }
}
