import React from 'react';
import Header from './Header';
import DownloadAppPlatform from './DownloadAppPlatform';
import SectionRight from './SectionRight';
import FootballMenu from './FootballMenu';
import { Link } from "react-router-dom";
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Moment from 'react-moment';
import 'moment-timezone';
class ScoreCardFoot extends React.Component {
  constructor() { 
    super();       
    this.loadData = this.loadData.bind(this);    
  }
  state={
       loading:true,     
        person:[],  
        p1Away:'',p1Home:'',p2Away:'',p2Home:'',ftAway:'',ftHome:'',   
  }     

async loadData() {              
  const apiurl= "https://rest.entitysport.com/soccer/matches/"+this.props.match.params.scoreId+"/statsv2?token=18c151e1b17cd8b2908614405b946f14";
  const response = await fetch(apiurl);   
  const data = await response.json();  
  if(data.response.items.match_info[0].periods !== 'undefined')    
  { 
    if(data.response.items.match_info[0].periods.p1 !== 'undefined')
    {
      
      this.setState({p1Away:data.response.items.match_info[0].periods.p1.away});  
      this.setState({p1Home:data.response.items.match_info[0].periods.p1.home});  
    }
    if(data.response.items.match_info[0].periods.p2 !== 'undefined')
    {
      this.setState({p2Away:data.response.items.match_info[0].periods.p2.away});  
      this.setState({p2Home:data.response.items.match_info[0].periods.p2.home});  
    }
    if(data.response.items.match_info[0].periods.ft !== 'undefined')
    {
      this.setState({ftAway:data.response.items.match_info[0].periods.ft.away});  
      this.setState({ftHome:data.response.items.match_info[0].periods.ft.home});  
    }   
  }
  this.setState({person:data.response.items,loading:false});                 
}    
 componentDidMount(){         
  this.loadData()              
}
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform/>
                );
            }
        });
    };
 render(){  
   return<div>   <Header />
     <div className="container containerbottom">
       <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
         <section className="d_leftsec">
           <div className="commanbox ">
             <h3 className="commanhead">Action</h3>
             <FootballMenu />
           </div>
         </section>
         <section className="d_leftsec">
           <div className="commanbox ">
             <h3 className="commanhead hidden-xs">Activities</h3>
             <ul className="activityul hidden-xs">
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={teamFillIcon} />Team</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeFillIcon} />Challange</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
             </ul>
             <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</Link></li>
               <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</Link></li>
             </ul>
           </div>
         </section>
       </div>
  {this.state.loading || !this.state.person  ? <div>  </div>:<div>       
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
    <section className="d_midsec">
      <div className="commanbox commanboxlivescore ">
        <h3 className="commanhead commanheadlive">&nbsp;</h3>
        
        <ul className="scoreboxul scoreboxul_football">
          
          <li>
            <h3>{this.state.person.match_info[0].competition.cname} </h3>
            <div className="teamdiv">
             <span className="matchdatetime"><Moment unix tz="America/Los_Angeles">
             {this.state.person.match_info[0].timestampstart}
            </Moment></span>    
              <ul className="live_teamul live_teamul1">
                <li>
                  <span className="lt_img" style={{ backgroundImage: `url(${this.state.person.match_info[0].teams.away.logo})` }}></span>
                  <label className="it_flgname">{this.state.person.match_info[0].teams.away.tname}</label><br />
                  <label className="it_flgscore">GOALS - {this.state.person.match_info[0].result.away}</label>
                </li>
                <li><img className="vsicon" src="img/challengefill.png" alt=""></img></li>
                <li>
                  <span className="lt_img" style={{ backgroundImage: `url(${this.state.person.match_info[0].teams.home.logo})`}}></span>      
                  <label className="it_flgname">{this.state.person.match_info[0].teams.home.tname}</label><br/>     
                  <label className="it_flgscore">GOALS - {this.state.person.match_info[0].result.home}</label>
                </li>
              </ul>
              <p className="datentime_para">{this.state.person.match_info[0].venue.name},{this.state.person.match_info[0].venue.location}</p>
              <p className="scoreupdate_para"></p>
            </div>
          </li>
       </ul>  


       



<div className="panel-group" id="accordion">
  <div className="panel panel-default">
    <div className="panel-heading">
      <h4 className="panel-title">
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" in>
          <ul className="team_match team_match_acc team_match_acc1">
          <li>
              <div className="scoreflag_div">
              <span className="score_flagtext">Goals</span>
              </div>
          </li>
        </ul>
        </a>
      </h4>
    </div>
    <div id="collapse1" className="panel-collapse collapse in">
      <div className="panelnew">
       <div className="table-responsive scoretable">
        <table className="table table-hover">
          <thead>
        <tr>
          <th>Team Name</th>
          <th>1st half</th>
          <th>2nd half</th>
          <th>Total</th>
        </tr></thead>
          <tbody>
        {/* <tr>
          <td>{this.state.person.match_info[0].teams.away.tname}</td>
          <td>{this.state.person.match_info[0].periods.p1.away}</td>
          <td>{this.state.person.match_info[0].periods.p2.away}</td>
          <td>{this.state.person.match_info[0].periods.ft.away}</td>   
        </tr>
        <tr>
          <td>{this.state.person.match_info[0].teams.home.tname}</td>
          <td>{this.state.person.match_info[0].periods.p1.home}</td>
          <td>{this.state.person.match_info[0].periods.p2.home}</td>
          <td>{this.state.person.match_info[0].periods.ft.home}</td>       
        </tr> */}
        <tr>
          <td>{this.state.person.match_info[0].teams.away.tname}</td>
          <td>{this.state.p1Away}</td>
          <td>{this.state.p2Away}</td>
          <td>{this.state.ftAway}</td>   
        </tr>
        <tr>
          <td>{this.state.person.match_info[0].teams.home.tname}</td>
          <td>{this.state.p1Home}</td>
          <td>{this.state.p2Home}</td>
          <td>{this.state.ftHome}</td>       
        </tr>
        </tbody>          
        </table>
      </div>
     </div>
    </div>
   </div>
  <div className="panel panel-default">
    <div className="panel-heading">
      <h4 className="panel-title">
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
        <ul className="team_match team_match_acc">
          <li>
              <div className="scoreflag_div">
              <span className="score_flagtext">Match Statistic</span>
              </div>
          </li>
        </ul>


        </a>
      </h4>
    </div>
    <div id="collapse2" className="panel-collapse collapse in">
      <div className="panelnew">
       <div className="table-responsive scoretable">
        <table className="table table-hover">
      <thead>
        <tr>
          <th>Statistic</th>
          <th>{this.state.person.match_info[0].teams.away.tname}</th>
          <th>{this.state.person.match_info[0].teams.home.tname}</th>
        </tr>
      </thead>
      <tbody>
      {       
         this.state.person.statistics.map((x)=>{  
       return <tr>
          <td>{x.name}</td>
          <td>{x.away}</td>
          <td>{x.home}</td>
        </tr>
            })    
        } 
      </tbody>
    </table>
  </div>
     </div>
    </div>
  </div>
  
</div>

      </div>

    </section>
  </div>
    
    </div>}
         <SectionRight/>
       </div>
</div>
 }
}
export default ScoreCardFoot;  