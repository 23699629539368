import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import userMaleIcon from "./img/male.png";
import likedark from "./img/likedark.png";
import share from "./img/share.png";
import comment from "./img/comment.png";
import CommentList from "./CommentList";
import userFemaleIcon from "./img/female.png";
import more from "./img/more.png";
import likebox from "./img/likebox.png";
import likegreen from "./img/likegreen.png";
import { } from 'prismic-reactjs';
import { Link, Redirect } from 'react-router-dom';
import LikeList from './LikeList';
import { confirmAlert } from 'react-confirm-alert';
import Modal1 from 'react-bootstrap/Modal';
import happiness from './img/happiness.png';
import ConfigUrl from './ConfigUrl';
import play from './img/play.png';
import Emoji from './Emoji';
import broadcastbg from './img/broadcastbg.png';
import locationyellow from './img/locationyellow.png';
import danger from './img/danger.png';
// import { Player, ControlBar } from 'video-react';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

var dynamicFeedsid1;

class LoginFeedsBroadcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsFeeds: [],
            braodcastdata: {},
            userData: [],
            feedReport: false,
            isShowEmoji: false,
            commentData: '',
            reasonSelection1: '',
            showingReportAlert1: false,
            isProfileRedirection: false
        };
        this.loadMore = this.loadMore.bind(this);
        this.state.user = JSON.parse(localStorage.getItem('result'));
    }
    componentDidMount() {
        this.loadMore();
    }

    //report feed coding
    newId1 = (id) => {
        this.setState({ feedReport: true })
        dynamicFeedsid1 = id;
    }

    submitReport1 = () => {
        this.setState({ feedReport: false })
        const that = this;
        if (this.state.reasonSelection1 !== '') {
            const reports = userApi + "/newfeeds/toggle-report-feed";
            fetch(reports, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "reportedBy": that.state.user.contactNo,
                        "newFeedsId": dynamicFeedsid1,
                        "cause": that.state.reasonSelection1,
                        "reported": true,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    that.props.pReportCB(dynamicFeedsid1);
                    if (result.data === 'success') {
                        // document.getElementById('reportedBroadcast').style = 'block';
                        that.setState({
                            showingReportAlert1: true
                        });
                        setTimeout(() => {
                            that.setState({
                                showingReportAlert1: false
                            });
                        }, 2000);
                    }
                });
        }
    }
    childEmojicallback = (emojiObj) => {

        let input = this.state.commentData;
        input += emojiObj.emoji;
        this.setState({ commentData: input });


    }

    undoReport1 = () => {
        let that = this;
        const reports = userApi + "/newfeeds/toggle-report-feed";
        fetch(reports, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "reportedBy": this.state.user.contactNo,
                    "newFeedsId": this.props.newFeedsId,
                    "cause": this.state.reasonSelection1,
                    "reported": false,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.props.pReportCB(that.props.newFeedsId);
            });
    }

    //reason selection coding
    clearInputModal1 = () => {
        this.setState({ feedReport: false })
        document.getElementById('reportForm1').reset();
        this.setState({
            reasonSelection1: ''
        });
    }
    feedReportHandel = () => {
        this.setState({ feedReport: false })
    }
    reasonSelection1 = (e) => {
        let reasonSelection1 = e.target.value;
        if (e.target.checked === true) {
            switch (reasonSelection1) {
                case 'scam':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                case 'offensive':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                case 'violence':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                case 'prohibited':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                case 'spam':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                case 'falsenews':
                    this.setState({
                        reasonSelection1: reasonSelection1
                    });

                    break

                default:
                    this.setState({
                        reasonSelection1: 'others'
                    });
            }

        }
    }

    showAllComments = (broadcastId) => {
        let reactedBuddies = [];

        if (this.state.braodcast.broadcastId === broadcastId) {
            reactedBuddies = this.state.braodcast.reactedBuddies;
        }
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.likeListUser,
        }
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (broadcastId) => {
        let reactedBuddies = [];
        if (this.state.braodcast.broadcastId === broadcastId) {
            reactedBuddies = this.state.braodcast.reactedBuddies;
        }

        var data = {
            reactBudd: reactedBuddies,
            users: this.state.likeListUser,
        }
    }
    commentFunction = (broadcastId) => {
        this.setState({ isShowEmoji: false })
        if (this.state.commentData) {
            this.state.user = JSON.parse(localStorage.getItem("result"));
            let dummyUser = {
                contactNo: this.state.user.contactNo,
                gender: this.state.user.gender,
                name: this.state.user.name,
                profilePic: this.state.user.profilePic,
            }
            this.setState({ likeListUser: [dummyUser] })
            const contactNumber = this.state.user.contactNo;
            var timestamp = Math.floor(Date.now());

            var braodcast = this.state.braodcastdata;
            if (braodcast.broadcastId === broadcastId) {
                if (braodcast.reactedBuddies === null) {
                    var reactedBuddies = [{
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    }];
                    //delete braodcast.id;   //Always use this in update case 
                    braodcast.reactedBuddies = reactedBuddies;
                    let that = this;
                    fetch(userApi + "/broadcasts/update-broadcast", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "broadcast": braodcast,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {

                            that.setState({ commentData: '', braodcastdata: braodcast });
                            that.showAllComments(broadcastId)
                        });
                }
                else {
                    reactedBuddies = {
                        "buddyId": contactNumber,
                        "reactedType": "comment",
                        "timestamp": timestamp,
                        "userType": null,
                        "comment": this.state.commentData,
                    };

                    delete braodcast.id; //Always use this in update case 
                    braodcast.reactedBuddies.push(reactedBuddies);
                    let that = this;
                    fetch(userApi + "/broadcasts/update-broadcast", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            data: {
                                "broadcast": braodcast,
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            that.setState({ commentData: '' });
                            that.showAllComments(broadcastId)
                        });

                }
            }

            this.setState({ braodcastdata: braodcast });
        }
        // this.loadComponent();
    }
    likeFunction = (broadcastId) => {
        this.state.user = JSON.parse(localStorage.getItem("result"));
        const contactNumber = this.state.user.contactNo;
        var timestamp = Math.floor(Date.now() / 1000);
        var braodcast = this.state.braodcastdata;
        if (braodcast.broadcastId === broadcastId) {
            if (braodcast.reactedBuddies === null) {
                var reactedBuddies = [{
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                }];
                delete braodcast.id;   //Always use this in update case 
                braodcast.reactedBuddies = reactedBuddies;
                fetch(userApi + "/broadcasts/update-broadcast", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "broadcast": braodcast,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                    });
            }
            else {
                reactedBuddies = {
                    "buddyId": contactNumber,
                    "reactedType": "cheer",
                    "timestamp": timestamp,
                    "userType": null,
                    "comment": null,
                };
                braodcast.reactedBuddies.push(reactedBuddies);
                delete braodcast.id; //Always use this in update case 
                // let that = this;
                fetch(userApi + "/broadcasts/update-broadcast", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            "broadcast": braodcast,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                    });
            }
        }


        this.setState({ braodcastdata: braodcast });

    }
    showAllComments = (broadcastId) => {
        let reactedBuddies = [];

        if (this.state.braodcastdata.broadcastId === broadcastId) {
            reactedBuddies = this.state.braodcastdata.reactedBuddies;
        }
        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }
        this.setState({ data: data });
        this.setState({ commentList: true })
    }
    showAllLike = (broadcastId) => {
        let reactedBuddies = [];

        if (this.state.braodcastdata.broadcastId === broadcastId) {
            reactedBuddies = this.state.braodcastdata.reactedBuddies;
        }


        var data = {
            reactBudd: reactedBuddies,
            users: this.state.userData,
        }

        confirmAlert({
            customUI: () => {
                return (
                    <div>
                        <LikeList data={data} />
                    </div>
                );
            }
        })
    }
    loadMore = () => {
        let that = this;
        var apiUrl = userApi + '/broadcasts/get-broadcast';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    broadcastId: this.props.moduleId,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            console.log(result)

            that.setState({ braodcastdata: result.data.broadcast, userData: result.data.users });
        });
    }
    openBroadcast = (id) => {
        var videoUrl = ConfigUrl('videoUrl') + id + ".mp4";
        confirmAlert({
            customUI: () => {
                return (
                    <div className="postdata">
                        <video width="400" height="500" controls>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                        <p></p>
                    </div>
                );
            }
        });
    };

    profileRedirection = (cNo) => {
        // this.setState({redirectonContacNu:cNo,isProfileRedirection:true})
        window.location = "/buddies?id=" + cNo;
    }



    render() {

        if (this.state.isProfileRedirection) {
            return <Redirect
                to={{
                    pathname: "/buddies?id=" + this.state.redirectonContacNu
                }}
            />
        }

        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(this.state.braodcastdata.timestamp);
        var userIcon = false;

        var reportValue = this.props.reported;
        if (reportValue === false) {
            return (
                <React.Fragment >

                    <Modal1
                        size="md"
                        show={this.state.feedReport}
                        onHide={() => this.feedReportHandel()}
                        animation={false}
                        aria-labelledby="example-modal-sizes-title-md"
                    >

                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={() => this.clearInputModal1()} type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Report this feeds</h4>
                            </div>
                            <div className="modal-body">
                                <label className="reportlabel">Take a moment and tell us what's wrong with this post</label>
                                <form id="reportForm">
                                    <ul className="reportul">
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost1" value="scam" />
                                                <label htmlFor="resportPost1">Scam</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost2" value="offensive" />
                                                <label htmlFor="resportPost2">Offensive </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost3" value="violence" />
                                                <label htmlFor="resportPost3">Violence </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost4" value="others" />
                                                <label htmlFor="resportPost4">Others</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost5" value="spam" />
                                                <label htmlFor="resportPost5">Spam</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost6" value="falsenews" />
                                                <label htmlFor="resportPost6">False-news</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="">
                                                <input onChange={this.reasonSelection1.bind(this)} type="radio" name="fieldset-1" id="resportPost7" value="prohibited" />
                                                <label htmlFor="resportPost7">Prohibited </label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <p id="reportedPost" style={{ display: 'none' }} className={`alert alert-success ${this.state.showingReportAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                    <strong>Feed Reported!</strong>
                                </p>
                                <button onClick={() => this.submitReport1()} type="button" className="cmnbutton">Submit</button>
                                <button onClick={() => this.clearInputModal1()} type="button" className="btndark cmnbutton" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </Modal1>
                    {/* End Report Abuse */}
                    {this.state.userData.map((userData) => {
                        var buttonDisable = false;
                        var likeCount = 0; var commentCount = 0; var buddyName = null;
                        if (this.state.braodcastdata.reactedBuddies !== null) {
                            this.state.braodcastdata.reactedBuddies.map((z) => {
                                if (z.reactedType === "cheer") {
                                    if (z.buddyId === this.state.user.contactNo) {
                                        buttonDisable = true;
                                    }
                                    this.state.userData.map((buddy) => {
                                        if (buddy.contactNo === z.buddyId) {
                                            buddyName = buddy.name;
                                        }
                                        return buddyName;
                                    })
                                    likeCount++;
                                }
                                if (z.reactedType === "comment") {
                                    commentCount++;
                                }
                                return likeCount;
                            });
                            if (buddyName === null) {
                                this.state.braodcastdata.reactedBuddies.map((z) => {
                                    this.state.userData.map((user) => {
                                        if (user.contactNo === z.buddyId) {
                                            buddyName = user.name;

                                        }
                                        return buddyName;
                                    })
                                    return true;
                                })
                            }
                        }
                        if (userData.contactNo === this.state.braodcastdata.createdBy) {
                            if (userData.profilePic === null) {
                                if (userData.gender === "Male") {
                                    userIcon = userMaleIcon;
                                }
                                else {
                                    userIcon = userFemaleIcon;
                                }
                            }
                            else {
                                userIcon = userData.profilePic;
                            }


                            {/* checking more button on logged in user */ }
                            if (this.state.user.contactNo !== this.props.sentBy) {
                                this.moreButton = <button className="btn dropdown-toggle" type="button" data-toggle="dropdown"> <img src={more} alt="" /></button>;
                            }

                            return <div className="divpost sharepostdiv" id="accordion" key={this.state.braodcastdata.broadcastId}><div className="userdetail">
                                <ul className="postuserul" >
                                    <li className="p_userli">
                                        <span onClick={() => this.profileRedirection(this.state.braodcastdata.createdBy)}  className="p_userimg" style={{
                                            backgroundImage: "url(" + userIcon + ")"
                                        }}> </span>
                                        <label className="cursorPointer" onClick={() => this.profileRedirection(this.state.braodcastdata.createdBy)} >{userData.name}</label>
                                        
                                        <p className="p_usertime">Broadcast</p></li>
                                    <li className="p_usermore">
                                        <p className="broadcast_date">{formattedTimestamp}</p>
                                    </li>
                                    {/* <span className="p_usertime">{formattedTimestamp}</span> <br /> */}
                                    {/* <span className="p_usertime">{userData.name}</span><br />
                                         <span className="p_usertime">{this.state.braodcastdata.location}</span> */}

                                    <li className="p_usermore">
                                        <div className="dropdown dropaccount">
                                            {this.moreButton}
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <Link onClick={(e) => this.newId1(this.props.newFeedsId)} data-toggle="modal" data-target="#reportabuse1"> Report Abuse </Link>
                                                    {/*onClick={(e) => this.newId1(this.props.newFeedsId)}*/}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                                <div className="postdata">
                                    <div className="videopost broadcast_video">

                                        <div className="broadcast_maininfo" style={{
                                            backgroundImage: "url(" + broadcastbg + ")"
                                        }}>
                                            {/* {(() => {
                                                switch (this.state.braodcastdata.live) {
                                                    case false:

                                                        return <video height="auto" controls="controls" autoPlay>
                                                            <source type="video/mp4" src="vid.mp4" />
                                                        </video>
                                                    case true:
                                                        return <div><span class="label label-danger">live</span> <div id="playerElement" style={{ 'width': '100%', 'height': '0', 'padding': '0 0 56.25% 0' }}></div>  </div>
                                                }
                                            })()} */}
                                            <div className="broad_info">
                                                <label className="broadcast_title">{this.state.braodcastdata.title}</label>
                                                <p className="broadcast_decp">{this.state.braodcastdata.description}</p>
                                                <p className="broadcast_location"><img src={locationyellow} alt="" />{this.state.braodcastdata.location}</p>
                                            </div>
                                        </div>
                                        <div className="vidplaydiv" onClick={() => this.openBroadcast(this.state.braodcastdata.broadcastId)}><a><img src={play} className="vidplay" alt="" /></a></div>

                                    </div>
                                </div>

                                {/* <div className="postdata">
                                    <p id="postText">{this.state.braodcastdata.description}</p>
                                    {(() => {
                                        switch (this.state.braodcastdata.live) {
                                            case false:
                                                return <iframe style={{ 'width': '100%', 'height': '50%', 'padding': '0 0 0 0' }} id="video1" src={videoUrl} frameborder="0" allowtransparency="true" allowfullscreen></iframe>
                                            case true:
                                                return <div><span class="label label-danger">live</span> <div id="playerElement" style={{ 'width': '100%', 'height': '0', 'padding': '0 0 56.25% 0' }}></div>  </div>
                                        }
                                    })()}

                                </div>  */}

                                <div className="postlikediv">
                                    {(() => {
                                        switch (likeCount) {
                                            case 1:
                                                switch (commentCount) {
                                                    case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span > liked this </span>
                                                    </span>;
                                                    default: return <span className="userlikeinner"> <span ><img src={likebox} alt="" /> <b>{buddyName}</b> liked this</span><span key={this.state.braodcastdata.broadcastId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.braodcastdata.broadcastId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.braodcastdata.broadcastId} > {commentCount} comments</span>
                                                    </span>
                                                }
                                            case 0:
                                                switch (commentCount) {
                                                    case 0: return '';
                                                    default: return <span className="userlikeinner"><span key={this.state.braodcastdata.broadcastId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.braodcastdata.broadcastId)}> {commentCount} comments</span>
                                                    </span>;
                                                }
                                            default:
                                                switch (commentCount) {
                                                    case 0: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.braodcastdata.broadcastId)}> and {likeCount - 1}more liked this </span>
                                                    </span>;
                                                    default: return <span className="userlikeinner"><img src={likebox} alt="" /> <b>{buddyName}</b> <span onClick={(e) => this.showAllLike(this.state.braodcastdata.broadcastId)}> and {likeCount - 1}more liked this</span><span key={this.state.braodcastdata.broadcastId} style={{ float: 'right' }} onClick={(e) => this.showAllComments(this.state.braodcastdata.broadcastId)} data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.braodcastdata.broadcastId}> {commentCount} comments</span>
                                                    </span>
                                                }
                                        }
                                    })()}
                                    <ul className="commentul">
                                        {buttonDisable ? <li className="commentli commentinner">
                                            <img src={likegreen} className="likeuser" alt="" />
                                            <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount}</span></span>
                                        </li> : <li className="commentli commentinner" onClick={(e) => this.likeFunction(this.state.braodcastdata.broadcastId)}>
                                                <img src={likedark} className="likeuser" alt="" />
                                                <span className="likespan" >Like{buttonDisable}<span className="spancount">{likeCount} </span></span>
                                            </li>}
                                        <li key={this.state.braodcastdata.broadcastId} className="commentinner commentinner2" id="comment1">
                                            <img src={comment} className="commentimg" alt="" />
                                            <span data-toggle="collapse" data-parent="#accordion" href={'#' + this.state.braodcastdata.broadcastId} onClick={(e) => this.setState({ commentList: false })}>Comment<span className=" spancount">{commentCount}</span></span> </li>
                                        <li className="commentinner commentinner3" id="" >
                                            <Link to="#" className="" data-toggle="modal" data-target="#myModal"><img src={share} className="commentimg" alt="" />
                                                <span>Share<span className="spancount"></span></span></Link> </li>
                                    </ul>
                                    <div className="postcomment" id={this.state.braodcastdata.broadcastId}> <span className="" style={{
                                        backgroundImage: "url(" + userMaleIcon + ")"
                                    }}></span>
                                        {this.state.commentList ? <CommentList data={this.state.data} /> : <div></div>}
                                        <div key={this.state.braodcastdata.broadcastId} className="postcomment_div" style={{ marginTop: '10px' }}> <Link to="#"><img onClick={() => this.setState({ isShowEmoji: !this.state.isShowEmoji })} src={happiness} alt="" /></Link>
                                            <textarea id={this.state.braodcastdata.broadcastId} value={this.state.commentData} onChange={event => this.setState({ commentData: event.target.value })}></textarea>
                                            <button onClick={(e) => this.commentFunction(this.state.braodcastdata.broadcastId)}>Post</button>
                                        </div>
                                        {this.state.isShowEmoji ? <Emoji parentEmojiCallback={this.childEmojicallback} /> : ''}
                                    </div>
                                </div>
                            </div>
                        }
                        return true;
                    })
                    }
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className="divpost">
                        <ul className="reportfeed">
                            <li className="reportli1"><img src={danger} /></li>
                            <li className="reportli2">
                                <label>Feeds Reported</label>
                                <p>We would not show you this post again</p>
                            </li>
                            <li className="reportli3"><button onClick={() => this.undoReport1()}>Undo</button></li>
                        </ul>
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default LoginFeedsBroadcast;