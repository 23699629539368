import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import Modal1 from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import * as TournamentsUtil from '../TournamentsUtil';
import * as myUtilclass from '../OrganiseMatchUtil';
import NumericInput from 'react-numeric-input';
import $, { nodeName } from 'jquery';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import logo from '../img/finallogo.png';
import teamIcons from '../img/team.png';
import ConfigUrl from '../ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const tournamentAction = "ended";
let byPic, toPic;
let pairObj;
var formatType;
var teamMembersBatfirst = [], teamMembersBolfirst = [];
var batFirstId;
var batSecondId;
var teamBatFirst;
let inThisOver = [];
let currOver, overList, bowlerList, currentOverObj = {}, currentBowllerObj = {};
let currentBallNo, currentBatsmanId;
let striker = null, NonStriker = null, selectedBowler = null;
let selectedHelper = null, selectedNewBatsmen = null;
let wikType = null, helper = null, newBattsman = null;
let onlyWicket = false, legbyesWicket = false, byesWicket = false, noBallWicket = false, noByesWicket = false, noLegByesWicket = false, wideWicket = false;
var d107CricketScoreCard = {};
var selectedWinners = undefined;
let winners = null, toWinner = false, byWinner = false;
let tournamentData = null;
const types = ['WD', "NB", "LB", "BYE", "W"];

const extras = {
    "type": null,
    "run": null,
}

const addBallsObj = {
    "ballNo": null,
    "extras": null,
    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
    "strokeNo": null,
    "total": null,
}

const addOversObj = {
    "overNo": null,
    "bowlerID": null,
    "balls": [
        addBallsObj
    ]
}

const batsmen = {
    "batsmanId": null,
    "batsmanName": null,
    "runs": 0,
    "balls": 0,
    "boundries": 0,
    "sixes": 0,
    "striker": null,
    "fallOfWicket": null
}

const bowlers = {
    "bowlerId": null,
    "bowlerName": null,
    "runs": 0,
    "balls": 0,
    "completedOver": 0,
    "wickets": 0
}

const overs = {
    "overNo": null,
    "bowlerID": null,
    "balls": null
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999
    }
};

export default class Tournaments_d107_tt2tt3_cricket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAlert1: false,
            byTeamName: '',
            byTeamNameId: '',
            toTeamName: '',
            toTeamNameid: '',
            showMe: true,
            showMe1: false,
            tosses: false,

            matchId: null,
            d107CricketScoreCard: null,
            byCricketScoreCard: {},
            toCricketScoreCard: {},
            firstInningScoreCard: {},
            secondInningScoreCard: {},
            originalOvers: '',
            firstBattingTeam: '',
            currentInning: '',

            setMakeRuns: null,
            selectWinnerModal: false,
            winnerModalOpen: false,
            showingNextInningsModal: false,
            showingWicketModal: false,
            showingWicketModal2: false,
            showingWicketModal3: false,
            modalIsOpen: false,
            showingModal0: false,
            showingModal: false,
            wide: '',
            nb: '',
            byes: '',
            lb: '',
            wicket: '',

            showHideCurrOver: true,
            winners: undefined,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
        };
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closingModal0 = this.closingModal0.bind(this);
        this.closingModal = this.closingModal.bind(this);
    }

    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, winnerModalOpen: false });
        window.location.reload(false);
    }

    closingModal0() {
        this.setState({ showingModal0: false })
        window.location.reload(false);
    }

    closingModal() {
        this.setState({ showingModal: false, showingWicketModal: false, showingWicketModal2: false, showingWicketModal3: false, showingNextInningsModal: false })
    }

    doneMatch = () => {
        console.log(byWinner, this.state.byTeamNameid);
        console.log(toWinner, this.state.toTeamNameid);

        if (selectedWinners) {
            if (byWinner) {
                d107CricketScoreCard.byCricketScoreCard.winner = true;
            }
            else {
                d107CricketScoreCard.toCricketScoreCard.winner = true;
            }

            let that = this;
            let score = {
                tournamentId: that.props.tournament.tournamentId,
                matchId: that.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }

            var winner;
            if (selectedWinners !== undefined) {
                winner = selectedWinners;
            }
            if (winners !== null) {
                winner = winners;
            }

            if (this.props.fType === 3) {
                UpdateTournamentsScore(score);
                let that = this;
                let nrr1 = 0.0;
                let nrr2 = 0.0;
                let organiseMatchObj = that.state.tt23Matches;
                delete organiseMatchObj.id;
                let matchPair = that.state.matchPairObj;
                matchPair.ended = true;
                matchPair.winnerId = winner;
                let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, winner, nrr1, nrr2);
                declareWinner.then(result => {
                    var moment = require('moment');
                    var now = moment().format();
                    var timpstampData = new Date(now).valueOf();
                    var tournament = that.props.tournament;
                    delete tournament.id;
                    tournament.status = "completed";
                    tournament.modifiedOn = timpstampData;
                    tournament.tournamentWinnerId = winner;
                    TournamentsUtil.ttWinnerFeeds(that.props.tournament.tournamentId, that.props.matchId, winner).then(data => {
                        console.log(data)
                        TournamentsUtil.updateTournament(tournament).then(data1 => {
                            window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                        })
                    })
                })
                console.log(d107CricketScoreCard)
            }

            else {
                UpdateTournamentsScore(score);
                let that = this;
                let nrr1 = 0.0;
                let nrr2 = 0.0;
                let organiseMatchObj = that.state.tt23Matches;
                delete organiseMatchObj.id;
                let matchPair = that.state.matchPairObj;
                matchPair.ended = true;
                matchPair.winnerId = winner;
                let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, winner, nrr1, nrr2);
                declareWinner.then(result => {
                    window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                })
                console.log(d107CricketScoreCard)
            }
        }

        else {
            let completeMatch = false;
            if (byWinner) {
                d107CricketScoreCard.byCricketScoreCard.winner = true;
                completeMatch = true;
            }
            else if (toWinner) {
                d107CricketScoreCard.toCricketScoreCard.winner = true;
                completeMatch = true;
            }
            else {
                document.getElementById('showTieError').style.display = 'block';
            }
            if (completeMatch) {
                if (byWinner) {
                    d107CricketScoreCard.byCricketScoreCard.winner = true;
                }
                else {
                    d107CricketScoreCard.toCricketScoreCard.winner = true;
                }

                let that = this;
                let score = {
                    tournamentId: that.props.tournament.tournamentId,
                    matchId: that.state.matchId,
                    d107CricketScoreCard: d107CricketScoreCard,
                }

                let winner;
                if (selectedWinners !== undefined) {
                    winner = selectedWinners;
                }
                if (winners !== null) {
                    winner = winners;
                }

                if (this.props.fType === 3) {
                    UpdateTournamentsScore(score);
                    let that = this;
                    let nrr1 = 0.0;
                    let nrr2 = 0.0;
                    let organiseMatchObj = that.state.tt23Matches;
                    delete organiseMatchObj.id;
                    let matchPair = that.state.matchPairObj;
                    matchPair.ended = true;
                    matchPair.winnerId = winner;
                    let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, winner, nrr1, nrr2);
                    declareWinner.then(result => {
                        var moment = require('moment');
                        var now = moment().format();
                        var timpstampData = new Date(now).valueOf();
                        var tournament = that.props.tournament;
                        delete tournament.id;
                        tournament.status = "completed";
                        tournament.modifiedOn = timpstampData;
                        tournament.tournamentWinnerId = winner;
                        TournamentsUtil.ttWinnerFeeds(that.props.tournament.tournamentId, that.props.matchId, winner).then(data => {
                            console.log(data)
                            TournamentsUtil.updateTournament(tournament).then(data1 => {
                                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                            })
                        })
                    })
                    console.log(d107CricketScoreCard)
                }

                else {
                    UpdateTournamentsScore(score);
                    let that = this;
                    let nrr1 = 0.0;
                    let nrr2 = 0.0;
                    let organiseMatchObj = that.state.tt23Matches;
                    delete organiseMatchObj.id;
                    let matchPair = that.state.matchPairObj;
                    matchPair.ended = true;
                    matchPair.winnerId = winner;
                    let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, winner, nrr1, nrr2);
                    declareWinner.then(result => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                    })
                    console.log(d107CricketScoreCard)
                }
            }
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $(".btn-pref .btn").click(function () {
                $(".btn-pref .btn").removeClass("btn-default").addClass("btn-primary");
                $(this).removeClass("btn-primary").addClass("btn-default");
            });
        });

        formatType = -1;
        const apiUrl = rshApi + "/tournaments/get-tournament";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                // console.log(result)
                if (result.data.tt23Matches.finals) {
                    that.setState({ finals: result.data.finals, tournament: result.data.tournament, users: result.data.users, ttType: result.data.tt23Matches.ttType })
                }
                if (result.data.tt23Matches.ttType === 3) {

                }
                if (result.data.tournament.tournamentMode === 'team') {
                    that.setState({ teams: result.data.teams })
                }
                that.setState({ tt23Matches: result.data.tt23Matches })

                if (result.data.tournament.matchType === 'Final') {
                    that.setState({ formatType: that.props.fType })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Quarter Final') {
                    that.setState({ formatType: that.props.fType, quarterFinals: result.data.tt23Matches.quarterFinals })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Semi final') {
                    that.setState({ formatType: that.props.fType, semiFinals: result.data.tt23Matches.semiFinals })
                    formatType = that.props.fType;
                }
                // console.log(that.props)
                pairObj = myUtilclass.getMatchPair(result.data.tournament, result.data.tt23Matches, true, formatType, that.props.matchId);
                console.log(pairObj)
                that.setState({ matchPairObj: pairObj }, () => {
                    that.getScore();
                });
            });
    }

    makeRuns = (takeRuns) => {
        this.setState({ showHideCurrOver: true })
        let strokeNoG = d107CricketScoreCard.strokeNo;
        var wide = 1, nb = 1, byes = 0, lb = 0;

        //1st Inngs
        if (this.state.currentInning === 1) {

            currentBallNo = parseInt(this.state.d107CricketScoreCard.firstInningScoreCard.ballNo);

            let firstInningScoreCard = { ...this.state.firstInningScoreCard };

            let curBatObj = this.state.firstInningScoreCard.batsmen.find(bat => {
                if (bat.striker) {
                    return bat;
                }
            })
            currentBatsmanId = curBatObj.batsmanId;

            if (firstInningScoreCard.overCompleted === this.state.originalOvers || firstInningScoreCard.wicketFall === 10) {
                this.setState({ showingNextInningsModal: true })
            }

            else {
                //wide-case
                if ((this.state.wide === 'wide') && (this.state.wicket !== 'wicket')) {

                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + wide + parseInt(takeRuns);
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "WD",
                                "run": wide + parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + wide + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        var addExtras = [
                            {
                                "type": "WD",
                                "run": wide + parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + wide + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //only wicket case
                if ((this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    onlyWicket = true;
                }

                //wide and wicket case
                if ((this.state.wide === 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket')) {

                    this.setState({ showingWicketModal2: true, setMakeRuns: takeRuns })
                    wideWicket = true;
                }

                //no ball and wicket
                if ((this.state.nb === 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noBallWicket = true;
                }

                //no ball, byes and wicket
                if ((this.state.nb === 'nb') && (this.state.byes === 'byes') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.lb !== 'lb')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noByesWicket = true;
                }

                //no ball, leg byes and wicket
                if ((this.state.nb === 'nb') && (this.state.lb === 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.byes !== 'byes')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noLegByesWicket = true;
                }

                //byes and wicket
                if ((this.state.nb !== 'nb') && (this.state.byes === 'byes') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.lb !== 'lb')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    byesWicket = true;
                }

                //leg byes and wicket case
                if ((this.state.nb !== 'nb') && (this.state.lb === 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.byes !== 'byes')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    legbyesWicket = true;
                }

                //no ball
                if ((this.state.nb === 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket')) {

                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.boundries = curBatObj.boundries + 1;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.sixes = curBatObj.sixes + 1;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //no ball and byes only
                if ((this.state.nb === 'nb') && (this.state.byes === 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide')) {

                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "BYE",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "BYE",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //no ball and leg byes only
                if ((this.state.nb === 'nb') && (this.state.lb === 'lb') && (this.state.byes !== 'byes') && (this.state.wide !== 'wide') && (this.state.wicket !== 'wicket')) {

                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "LB",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "LB",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //byes case
                if ((this.state.byes === 'byes') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.lb !== 'lb')) {
                    if (currentBallNo < 5) {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);
                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);;
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = 0;
                        firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                        firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                        d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }

                    }
                }

                //lb case
                if ((this.state.lb === 'lb') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes')) {
                    if (currentBallNo < 5) {
                        firstInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = 0;
                        firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                        firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                        d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }
                    }
                }

                //real ball
                if ((this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket')) {
                    if (currentBallNo < 5) {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = 0;
                        firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                        firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                        d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;


                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }
                    }
                }

                d107CricketScoreCard.strokeNo = strokeNoG + 1;
                this.setState({ firstInningScoreCard: firstInningScoreCard, d107CricketScoreCard: d107CricketScoreCard })
                console.log(d107CricketScoreCard);

                let score = {
                    tournamentId: this.props.tournament.tournamentId,
                    matchId: this.props.matchId,
                    d107CricketScoreCard: d107CricketScoreCard,
                }
                UpdateTournamentsScore(score);
                //checking 1st inning end on run time
                if (firstInningScoreCard.overCompleted === this.state.originalOvers || firstInningScoreCard.wicketFall === 10) {
                    this.setState({ showingNextInningsModal: true })
                }
            }

        }

        //2nd Innings 
        if (this.state.currentInning === 2) {

            currentBallNo = parseInt(this.state.d107CricketScoreCard.secondInningScoreCard.ballNo);

            let secondInningScoreCard = { ...this.state.secondInningScoreCard };

            let curBatObj = this.state.secondInningScoreCard.batsmen.find(bat => {
                if (bat.striker) {
                    return bat;
                }
            })
            currentBatsmanId = curBatObj.batsmanId;

            if ((secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                this.setState({ selectWinnerModal: true })
            }

            else if ((secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                this.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                byWinner = true;
            }

            else if (secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || secondInningScoreCard.overCompleted === this.state.originalOvers || secondInningScoreCard.wicketFall === 10) {
                this.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                toWinner = true;
            }

            else {

                //wide-case
                if ((this.state.wide === 'wide') && (this.state.wicket !== 'wicket')) {

                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + wide + parseInt(takeRuns);
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "WD",
                                "run": wide + parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + wide + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        var addExtras = [
                            {
                                "type": "WD",
                                "run": wide + parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = wide + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + wide + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //only wicket case
                if ((this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    onlyWicket = true;
                }

                //wide and wicket case
                if ((this.state.wide === 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket')) {

                    this.setState({ showingWicketModal2: true, setMakeRuns: takeRuns })
                    wideWicket = true;
                }

                //no ball and wicket
                if ((this.state.nb === 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noBallWicket = true;
                }

                //no ball, byes and wicket
                if ((this.state.nb === 'nb') && (this.state.byes === 'byes') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.lb !== 'lb')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noByesWicket = true;
                }

                //no ball, leg byes and wicket
                if ((this.state.nb === 'nb') && (this.state.lb === 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.byes !== 'byes')) {

                    this.setState({ showingWicketModal3: true, setMakeRuns: takeRuns })
                    noLegByesWicket = true;
                }

                //byes and wicket
                if ((this.state.nb !== 'nb') && (this.state.byes === 'byes') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.lb !== 'lb')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    byesWicket = true;
                }

                //leg byes and wicket case
                if ((this.state.nb !== 'nb') && (this.state.lb === 'lb') && (this.state.wicket === 'wicket') && (this.state.wide !== 'wide') && (this.state.byes !== 'byes')) {

                    this.setState({ showingWicketModal: true, setMakeRuns: takeRuns })
                    legbyesWicket = true;
                }

                //no ball
                if ((this.state.nb === 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket')) {

                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.boundries = curBatObj.boundries + 1;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.sixes = curBatObj.sixes + 1;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = parseInt(takeRuns);
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //no ball and byes only
                if ((this.state.nb === 'nb') && (this.state.byes === 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide')) {

                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "BYE",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "BYE",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //no ball and leg byes only
                if ((this.state.nb === 'nb') && (this.state.lb === 'lb') && (this.state.byes !== 'byes') && (this.state.wide !== 'wide') && (this.state.wicket !== 'wicket')) {

                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + nb + parseInt(takeRuns);
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = currentBallNo + 0;
                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "LB",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                        this.swapBatsman();
                    }
                    else {
                        if (takeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (takeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": "NB",
                                "run": nb
                            },
                            {
                                "type": "LB",
                                "run": parseInt(takeRuns)
                            }
                        ]

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = nb + parseInt(takeRuns);
                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + nb + parseInt(takeRuns);
                        currentBowllerObj.balls = currentBowllerObj.balls + 0;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }

                //byes case
                if ((this.state.byes === 'byes') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.lb !== 'lb')) {
                    if (currentBallNo < 5) {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);
                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = 0;
                        secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                        secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                        d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "BYE",
                                    "run": byes + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + byes + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }
                    }
                }

                //lb case
                if ((this.state.lb === 'lb') && (this.state.wicket !== 'wicket') && (this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes')) {
                    if (currentBallNo < 5) {
                        secondInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = 0;
                        secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                        secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                        d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "LB",
                                    "run": lb + parseInt(takeRuns)
                                }
                            ]

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.extras = addExtras;
                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = 0;
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + lb + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }
                    }
                }

                //real ball
                if ((this.state.wide !== 'wide') && (this.state.nb !== 'nb') && (this.state.byes !== 'byes') && (this.state.lb !== 'lb') && (this.state.wicket !== 'wicket')) {
                    if (currentBallNo < 5) {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + parseInt(takeRuns);
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = 0;
                        secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                        secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                        d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;


                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (takeRuns == 1 || takeRuns == 3 || takeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (takeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (takeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + parseInt(takeRuns);
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                addBallsObj.playedBy.batsmanId = currentBatsmanId;
                                addBallsObj.playedBy.run = parseInt(takeRuns);
                                addBallsObj.strokeNo = strokeNoG + 1;
                                addBallsObj.total = parseInt(takeRuns);

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + parseInt(takeRuns);
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                            this.swapBatsman();
                        }
                        selectedBowler = null;
                        if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                            this.setState({ showingModal: true });
                        }
                    }
                }

                d107CricketScoreCard.strokeNo = strokeNoG + 1;
                this.setState({ secondInningScoreCard: secondInningScoreCard, d107CricketScoreCard: d107CricketScoreCard })
                console.log(d107CricketScoreCard);

                let score = {
                    tournamentId: this.props.tournament.tournamentId,
                    matchId: this.props.matchId,
                    d107CricketScoreCard: d107CricketScoreCard,
                }
                UpdateTournamentsScore(score);
                //checking winner on run time
                if (d107CricketScoreCard.firstBattingTeam === "challengedBy") {
                    if ((secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                        this.setState({ selectWinnerModal: true })
                    }

                    else if ((secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                        this.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                        winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                        byWinner = true;
                    }

                    else if (secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || secondInningScoreCard.overCompleted === this.state.originalOvers || secondInningScoreCard.wicketFall === 10) {
                        this.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                        winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                        toWinner = true;
                    }
                }

                else {
                    if ((secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                        this.setState({ selectWinnerModal: true })
                    }

                    else if ((secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                        this.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                        winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                        toWinner = true;
                    }

                    else if (secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || secondInningScoreCard.overCompleted === this.state.originalOvers || secondInningScoreCard.wicketFall === 10) {
                        this.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                        winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                        byWinner = true;
                    }
                }

            }

        }

        //unchecking after make runs completes
        if (this.state.wide === 'wide') {
            document.getElementById('wide').checked = false;
            this.setState({ wide: '' })
        }
        if (this.state.nb === 'nb') {
            document.getElementById('nb').checked = false;
            this.setState({ nb: '' })
        }
        if (this.state.byes === 'byes') {
            document.getElementById('byes').checked = false;
            this.setState({ byes: '' })
        }
        if (this.state.lb === 'lb') {
            document.getElementById('lb').checked = false;
            this.setState({ lb: '' })
        }
        if (this.state.wicket === 'wicket') {
            document.getElementById('wicket').checked = false;
            this.setState({ wicket: '' })
        }
    }

    selectwicketType = (e) => {
        if (e.target.id === 'wik') {
            wikType = e.target.value;
            if (wikType === 'catchout' || wikType === 'stumping' || wikType === 'runouts' || wikType === 'runoutns') {
                document.getElementById('helperShow').style.display = "block";
            }
            if (wikType === 'bowled' || wikType === 'lbw' || wikType === 'hitwicket') {
                document.getElementById('helperShow').style.display = "none";
            }
        }
    }
    selectHelper = (e) => {
        if (e.target.id === 'help') {
            selectedHelper = e.target.value;
        }
    }
    selectNewBatsmen = (e) => {
        if (e.target.id === 'newBat') {
            selectedNewBatsmen = e.target.value;
        }
    }
    fallOfWicket = () => {
        let strokeNoG = d107CricketScoreCard.strokeNo;

        if (this.state.currentInning === 1) {
            var firstInningScoreCard = { ...this.state.firstInningScoreCard };
            let Inning1BatLen = firstInningScoreCard.batsmen.length;
            currentBallNo = parseInt(this.state.d107CricketScoreCard.firstInningScoreCard.ballNo);
            let curBatObj = firstInningScoreCard.batsmen.find(bat => {
                if (bat.striker) {
                    return bat;
                }
            })
            currentBatsmanId = curBatObj.batsmanId;
            let setMakeRuns = parseInt(this.state.setMakeRuns);

            if (wikType) {

            }
            else {
                if (wideWicket) {
                    wikType = 'stumping';

                }
                else if (noBallWicket || noByesWicket || noLegByesWicket) {
                    wikType = 'runouts';

                }
                else {
                    wikType = 'bowled';

                }
            }

            if (wikType === 'catchout' || wikType === 'stumping' || wikType === 'runouts' || wikType === 'runoutns') {
                if (selectedHelper) {

                }
                else {
                    selectedHelper = teamMembersBolfirst[0];
                }
            }

            if (selectedNewBatsmen) {

            }
            else {
                var officersIds = [];
                d107CricketScoreCard.firstInningScoreCard.batsmen.forEach(function (officer) {
                    officersIds.push(officer.batsmanId);
                });
                var newBatsmanList = teamMembersBatfirst.filter(function (obj) { return officersIds.indexOf(obj) == -1; });
                selectedNewBatsmen = newBatsmanList[0];
            }

            let chosenHelperName = null, chosenBatsmenName = null;
            this.props.profileUsers.map(u => {
                if (u.contactNo === selectedHelper) {
                    chosenHelperName = u.name;
                }
                if (u.contactNo === selectedNewBatsmen) {
                    chosenBatsmenName = u.name;
                }
            })

            let wide = 1, nb = 1;
            let setWick = null;
            if (wikType === 'catchout') {
                setWick = "c " + chosenHelperName + " b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'stumping') {
                setWick = "st " + chosenHelperName + " b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'runouts') {
                setWick = "Run Out Striker " + " (" + chosenHelperName + ")";
            }
            else if (wikType === 'runoutns') {
                setWick = "Run Out Non Striker " + " (" + chosenHelperName + ")";
            }
            else if (wikType === 'lbw') {
                setWick = "lbw b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'hitwicket') {
                setWick = "hit wicket b " + currentBowllerObj.bowlerName;
            }
            else {
                setWick = "b " + currentBowllerObj.bowlerName;
            }

            if (onlyWicket && !wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {
                if (wikType === 'runoutns' || wikType === 'runouts') {

                    if (currentBallNo < 5) {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + setMakeRuns;
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + setMakeRuns;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;

                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]
                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;

                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                    }
                    else {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = 0;
                        firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                        firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                        d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                        d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + setMakeRuns;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = 0;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = 0;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        selectedBowler = null;
                        if (firstInningScoreCard.wicketFall == 10) {

                        }
                        else {
                            if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                                this.setState({ showingModal: true });
                            } // this is for to select next over bowler
                        }
                    }
                }
                // in case of without runouts
                else {

                    if (currentBallNo < 5) {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                        firstInningScoreCard.ballNo = 0;
                        firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                        firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                        firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                        d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                        d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            firstInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning1BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            firstInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            firstInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        selectedBowler = null;
                        if (firstInningScoreCard.wicketFall == 10) {

                        }
                        else {
                            if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                                this.setState({ showingModal: true });
                            } // this is for to select next over bowler
                        }
                    }
                }
            }

            //wide wicket
            else if (!onlyWicket && wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {

                if (wikType === 'stumping') {
                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + wide + 0;
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                }
                else {
                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + wide + setMakeRuns;
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                }

                firstInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + 0;
                    curBatObj.balls = curBatObj.balls + 1;

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })

                    let addExtras = null;
                    if (wikType === 'stumping') {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }
                    else {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide + setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = 0;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    if (wikType === 'stumping') {
                        addBallsObj.total = wide;
                    }
                    else {
                        addBallsObj.total = wide + setMakeRuns;
                    }

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + wide + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    if (wikType === 'runoutns' || wikType === 'runouts') {

                    }
                    else {
                        currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                    }

                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.boundries = curBatObj.boundries + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.sixes = curBatObj.sixes + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })

                    let addExtras = null;
                    if (wikType === 'stumping') {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }
                    else {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide + setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = 0;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    if (wikType === 'stumping') {
                        addBallsObj.total = wide;
                    }
                    else {
                        addBallsObj.total = wide + setMakeRuns;
                    }

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })
                    currentBowllerObj.runs = currentBowllerObj.runs + wide + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    if (wikType === 'runoutns' || wikType === 'runouts') {

                    }
                    else {
                        currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                    }

                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }

            //byes wicket or legbyes wicket
            else if (byesWicket || legbyesWicket && !onlyWicket && !wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket) {

                if (currentBallNo < 5) {
                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + setMakeRuns;
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = currentBallNo + 1;
                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                    d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        if (Inning1BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = currentBowllerObj.balls + 1;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })

                    }
                    else {
                        if (setMakeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (setMakeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        if (Inning1BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = currentBowllerObj.balls + 1;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }
                else {
                    firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + 0;
                    d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                    firstInningScoreCard.ballNo = 0;
                    firstInningScoreCard.currentOver = firstInningScoreCard.currentOver + 1;
                    firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted + 1;

                    d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                    firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                    d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                    d107CricketScoreCard.firstInningScoreCard.currentOver = firstInningScoreCard.currentOver;

                    d107CricketScoreCard.firstInningScoreCard.overCompleted = firstInningScoreCard.overCompleted;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })

                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        if (Inning1BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = 0;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                    else {
                        if (setMakeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (setMakeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        firstInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })

                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        if (Inning1BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        firstInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = 0;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                        firstInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })

                    }
                    selectedBowler = null;
                    if (firstInningScoreCard.overCompleted !== this.state.originalOvers) {
                        this.setState({ showingModal: true });
                    } // this is for to select next over bowler
                }
            }

            //no ball with byes wicket OR leg byes wicket
            else if (noByesWicket || noLegByesWicket && !onlyWicket && !wideWicket && !noBallWicket && !byesWicket && !legbyesWicket) {

                firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + nb + setMakeRuns;
                d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                firstInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + 0;
                    curBatObj.balls = curBatObj.balls + 1;

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": noByesWicket ? "BYE" : "LB",
                            "run": setMakeRuns
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.boundries = curBatObj.boundries + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.sixes = curBatObj.sixes + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": noByesWicket ? "BYE" : "LB",
                            "run": setMakeRuns
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }

            //no ball wicket
            if (!onlyWicket && !wideWicket && noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {

                firstInningScoreCard.totalRun = firstInningScoreCard.totalRun + nb + setMakeRuns;
                d107CricketScoreCard.firstInningScoreCard.totalRun = firstInningScoreCard.totalRun;

                firstInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.firstInningScoreCard.ballNo = firstInningScoreCard.ballNo;

                firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.firstInningScoreCard.wicketFall = firstInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + setMakeRuns;
                    curBatObj.balls = curBatObj.balls + 1;

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.boundries = curBatObj.boundries + 1;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.sixes = curBatObj.sixes + 1;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    firstInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning1BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    firstInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    firstInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }


            //new batsman entry
            if (wikType === 'runoutns') {
                let curBatObjj = firstInningScoreCard.batsmen.filter(bat => {
                    if (bat.fallOfWicket === null) {
                        return bat;
                    }
                })
                let strikerFalse = curBatObjj.find(change => {
                    if (!change.striker) {
                        return change;
                    }
                })
                strikerFalse.striker = false;
                strikerFalse.fallOfWicket = setWick;

                firstInningScoreCard.batsmen.map(ex => {
                    if (ex.batsmanId === strikerFalse.batsmanId) {
                        ex = strikerFalse;
                    }
                })

                var newBatEntry = {
                    "batsmanId": selectedNewBatsmen,
                    "batsmanName": chosenBatsmenName,
                    "runs": 0,
                    "balls": 0,
                    "boundries": 0,
                    "sixes": 0,
                    "striker": false,
                    "fallOfWicket": null
                }
                if (Inning1BatLen < 11) {
                    firstInningScoreCard.batsmen = [...firstInningScoreCard.batsmen, { ...newBatEntry }];
                }
            }
            else {
                let curBatObjj = firstInningScoreCard.batsmen.filter(bat => {
                    if (bat.fallOfWicket === null) {
                        return bat;
                    }
                })
                let strikerTrue = curBatObjj.find(change => {
                    if (change.striker) {
                        return change;
                    }
                })

                strikerTrue.striker = false;
                strikerTrue.fallOfWicket = setWick;
                firstInningScoreCard.batsmen.map(ex => {
                    if (ex.batsmanId === strikerTrue.batsmanId) {
                        ex = strikerTrue;
                    }
                })

                var newBatEntry = {
                    "batsmanId": selectedNewBatsmen,
                    "batsmanName": chosenBatsmenName,
                    "runs": 0,
                    "balls": 0,
                    "boundries": 0,
                    "sixes": 0,
                    "striker": true,
                    "fallOfWicket": null
                }
                if (Inning1BatLen < 11) {
                    firstInningScoreCard.batsmen = [...firstInningScoreCard.batsmen, { ...newBatEntry }];
                }
            }

            d107CricketScoreCard.firstInningScoreCard = firstInningScoreCard;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({ firstInningScoreCard: firstInningScoreCard, d107CricketScoreCard: d107CricketScoreCard, showingWicketModal: false, showingWicketModal2: false, showingWicketModal3: false, setMakeRuns: null })
        }

        if (this.state.currentInning === 2) {
            var secondInningScoreCard = { ...this.state.secondInningScoreCard };
            let Inning2BatLen = secondInningScoreCard.batsmen.length;
            currentBallNo = parseInt(this.state.d107CricketScoreCard.secondInningScoreCard.ballNo);
            let curBatObj = secondInningScoreCard.batsmen.find(bat => {
                if (bat.striker) {
                    return bat;
                }
            })
            currentBatsmanId = curBatObj.batsmanId;
            let setMakeRuns = parseInt(this.state.setMakeRuns);

            if (wikType) {

            }
            else {
                if (wideWicket) {
                    wikType = 'stumping';

                }
                else if (noBallWicket || noByesWicket || noLegByesWicket) {
                    wikType = 'runouts';

                }
                else {
                    wikType = 'bowled';

                }
            }

            if (wikType === 'catchout' || wikType === 'stumping' || wikType === 'runouts' || wikType === 'runoutns') {
                if (selectedHelper) {

                }
                else {
                    selectedHelper = teamMembersBolfirst[0];
                }
            }

            if (selectedNewBatsmen) {

            }
            else {
                var officersIds = [];
                d107CricketScoreCard.secondInningScoreCard.batsmen.forEach(function (officer) {
                    officersIds.push(officer.batsmanId);
                });
                var newBatsmanList = teamMembersBatfirst.filter(function (obj) { return officersIds.indexOf(obj) == -1; });
                selectedNewBatsmen = newBatsmanList[0];
            }

            let chosenHelperName = null, chosenBatsmenName = null;
            this.props.profileUsers.map(u => {
                if (u.contactNo === selectedHelper) {
                    chosenHelperName = u.name;
                }
                if (u.contactNo === selectedNewBatsmen) {
                    chosenBatsmenName = u.name;
                }
            })

            let wide = 1, nb = 1;
            let setWick = null;
            if (wikType === 'catchout') {
                setWick = "c " + chosenHelperName + " b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'stumping') {
                setWick = "st " + chosenHelperName + " b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'runouts') {
                setWick = "Run Out Striker " + " (" + chosenHelperName + ")";
            }
            else if (wikType === 'runoutns') {
                setWick = "Run Out Non Striker " + " (" + chosenHelperName + ")";
            }
            else if (wikType === 'lbw') {
                setWick = "lbw b " + currentBowllerObj.bowlerName;
            }
            else if (wikType === 'hitwicket') {
                setWick = "hit wicket b " + currentBowllerObj.bowlerName;
            }
            else {
                setWick = "b " + currentBowllerObj.bowlerName;
            }

            if (onlyWicket && !wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {
                if (wikType === 'runoutns' || wikType === 'runouts') {

                    if (currentBallNo < 5) {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + setMakeRuns;
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + setMakeRuns;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;

                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]
                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;

                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                    }
                    else {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = 0;
                        secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                        secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                        d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                        d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + setMakeRuns;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = 0;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.boundries = curBatObj.boundries + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.sixes = curBatObj.sixes + 1;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + setMakeRuns;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = setMakeRuns;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = setMakeRuns;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                            currentBowllerObj.balls = 0;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        selectedBowler = null;
                        if (secondInningScoreCard.wicketFall == 10) {

                        }
                        else {
                            if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                                this.setState({ showingModal: true });
                            } // this is for to select next over bowler
                        }
                    }
                }
                // in case of without runouts
                else {

                    if (currentBallNo < 5) {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = currentBallNo + 1;
                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })
                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })

                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = currentBowllerObj.balls + 1;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })
                        }
                    }
                    else {
                        secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + 0;
                        d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                        secondInningScoreCard.ballNo = 0;
                        secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                        secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                        d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                        secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                        d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                        d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;

                        let addBallsObj = {
                            "ballNo": null,
                            "extras": null,
                            "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                            "strokeNo": null,
                            "total": null,
                        }

                        if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        else {
                            if (setMakeRuns == 4) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.boundries = curBatObj.boundries + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else if (setMakeRuns == 6) {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.sixes = curBatObj.sixes + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }
                            else {
                                curBatObj.runs = curBatObj.runs + 0;
                                curBatObj.balls = curBatObj.balls + 1;
                            }

                            secondInningScoreCard.batsmen.map(batt => {
                                if (batt.batsmanId === currentBatsmanId) {
                                    batt = curBatObj;
                                }
                            })

                            let addExtras = [
                                {
                                    "type": "OUT",
                                    "run": 0
                                }
                            ]

                            addBallsObj.extras = addExtras;
                            addBallsObj.playedBy.batsmanId = currentBatsmanId;
                            if (Inning2BatLen < 11) {
                                addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                            }

                            addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                            addBallsObj.playedBy.run = 0;
                            addBallsObj.strokeNo = strokeNoG + 1;
                            addBallsObj.total = 0;

                            if (currentOverObj.balls) {
                                let ballsArrayLen = currentOverObj.balls.length;
                                addBallsObj.ballNo = ballsArrayLen + 1;

                                currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                            }
                            else {
                                addBallsObj.ballNo = 1;

                                currentOverObj.balls = [{ ...addBallsObj }];
                            }
                            secondInningScoreCard.overs.map(over => {
                                if (over.overNo === currentOverObj.overNo) {
                                    over = currentOverObj;
                                }
                            })
                            currentBowllerObj.runs = currentBowllerObj.runs + 0;
                            currentBowllerObj.balls = 0;
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;

                            currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                            secondInningScoreCard.bowlers.map(bowls => {
                                if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                    bowls = currentBowllerObj;
                                }
                            })

                        }
                        selectedBowler = null;
                        if (secondInningScoreCard.wicketFall == 10) {

                        }
                        else {
                            if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                                this.setState({ showingModal: true });
                            } // this is for to select next over bowler
                        }
                    }
                }
            }

            //wide wicket
            else if (!onlyWicket && wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {

                if (wikType === 'stumping') {
                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + wide + 0;
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                }
                else {
                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + wide + setMakeRuns;
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                }

                secondInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + 0;
                    curBatObj.balls = curBatObj.balls + 1;

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })

                    let addExtras = null;
                    if (wikType === 'stumping') {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }
                    else {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide + setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = 0;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    if (wikType === 'stumping') {
                        addBallsObj.total = wide;
                    }
                    else {
                        addBallsObj.total = wide + setMakeRuns;
                    }

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + wide + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    if (wikType === 'runoutns' || wikType === 'runouts') {

                    }
                    else {
                        currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                    }

                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.boundries = curBatObj.boundries + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.sixes = curBatObj.sixes + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })

                    let addExtras = null;
                    if (wikType === 'stumping') {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }
                    else {
                        addExtras = [
                            {
                                "type": "WD",
                                "run": wide + setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]
                    }

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = 0;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    if (wikType === 'stumping') {
                        addBallsObj.total = wide;
                    }
                    else {
                        addBallsObj.total = wide + setMakeRuns;
                    }

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })
                    currentBowllerObj.runs = currentBowllerObj.runs + wide + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    if (wikType === 'runoutns' || wikType === 'runouts') {

                    }
                    else {
                        currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                    }

                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }

            //byes wicket
            else if (byesWicket || legbyesWicket && !onlyWicket && !wideWicket && !noBallWicket && !noByesWicket && !noLegByesWicket) {

                if (currentBallNo < 5) {
                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + setMakeRuns;
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = currentBallNo + 1;
                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                    d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        if (Inning2BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = currentBowllerObj.balls + 1;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })

                    }
                    else {
                        if (setMakeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (setMakeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })
                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        if (Inning2BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })

                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = currentBowllerObj.balls + 1;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                }
                else {
                    secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + 0;
                    d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                    secondInningScoreCard.ballNo = 0;
                    secondInningScoreCard.currentOver = secondInningScoreCard.currentOver + 1;
                    secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted + 1;

                    d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                    secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                    d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                    d107CricketScoreCard.secondInningScoreCard.currentOver = secondInningScoreCard.currentOver;

                    d107CricketScoreCard.secondInningScoreCard.overCompleted = secondInningScoreCard.overCompleted;

                    let addBallsObj = {
                        "ballNo": null,
                        "extras": null,
                        "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                        "strokeNo": null,
                        "total": null,
                    }

                    if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })

                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        if (Inning2BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = 0;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })
                    }
                    else {
                        if (setMakeRuns == 4) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.boundries = curBatObj.boundries + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else if (setMakeRuns == 6) {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.sixes = curBatObj.sixes + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }
                        else {
                            curBatObj.runs = curBatObj.runs + 0;
                            curBatObj.balls = curBatObj.balls + 1;
                        }

                        secondInningScoreCard.batsmen.map(batt => {
                            if (batt.batsmanId === currentBatsmanId) {
                                batt = curBatObj;
                            }
                        })

                        let addExtras = [
                            {
                                "type": byesWicket ? "BYE" : "LB",
                                "run": setMakeRuns
                            },
                            {
                                "type": "OUT",
                                "run": 0
                            }
                        ]

                        addBallsObj.extras = addExtras;
                        addBallsObj.playedBy.batsmanId = currentBatsmanId;
                        if (Inning2BatLen < 11) {
                            addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                        }

                        addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                        addBallsObj.playedBy.run = 0;
                        addBallsObj.strokeNo = strokeNoG + 1;
                        addBallsObj.total = setMakeRuns;

                        if (currentOverObj.balls) {
                            let ballsArrayLen = currentOverObj.balls.length;
                            addBallsObj.ballNo = ballsArrayLen + 1;

                            currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                        }
                        else {
                            addBallsObj.ballNo = 1;

                            currentOverObj.balls = [{ ...addBallsObj }];
                        }
                        secondInningScoreCard.overs.map(over => {
                            if (over.overNo === currentOverObj.overNo) {
                                over = currentOverObj;
                            }
                        })
                        currentBowllerObj.runs = currentBowllerObj.runs + setMakeRuns;
                        currentBowllerObj.balls = 0;
                        if (wikType === 'runoutns' || wikType === 'runouts') {

                        }
                        else {
                            currentBowllerObj.wickets = currentBowllerObj.wickets + 1;
                        }

                        currentBowllerObj.completedOver = currentBowllerObj.completedOver + 1;
                        secondInningScoreCard.bowlers.map(bowls => {
                            if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                                bowls = currentBowllerObj;
                            }
                        })

                    }
                    selectedBowler = null;
                    if (secondInningScoreCard.overCompleted !== this.state.originalOvers) {
                        this.setState({ showingModal: true });
                    } // this is for to select next over bowler
                }
            }

            //no ball with byes wicket OR leg byes wicket
            else if (noByesWicket || noLegByesWicket && !onlyWicket && !wideWicket && !noBallWicket && !byesWicket && !legbyesWicket) {

                secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + nb + setMakeRuns;
                d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                secondInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + 0;
                    curBatObj.balls = curBatObj.balls + 1;

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": noByesWicket ? "BYE" : "LB",
                            "run": setMakeRuns
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.boundries = curBatObj.boundries + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.sixes = curBatObj.sixes + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + 0;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": noByesWicket ? "BYE" : "LB",
                            "run": setMakeRuns
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }

            //no ball wicket
            if (!onlyWicket && !wideWicket && noBallWicket && !noByesWicket && !noLegByesWicket && !byesWicket && !legbyesWicket) {

                secondInningScoreCard.totalRun = secondInningScoreCard.totalRun + nb + setMakeRuns;
                d107CricketScoreCard.secondInningScoreCard.totalRun = secondInningScoreCard.totalRun;

                secondInningScoreCard.ballNo = currentBallNo + 0;
                d107CricketScoreCard.secondInningScoreCard.ballNo = secondInningScoreCard.ballNo;

                secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall + 1;
                d107CricketScoreCard.secondInningScoreCard.wicketFall = secondInningScoreCard.wicketFall;

                let addBallsObj = {
                    "ballNo": null,
                    "extras": null,
                    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
                    "strokeNo": null,
                    "total": null,
                }

                if (setMakeRuns == 1 || setMakeRuns == 3 || setMakeRuns == 5) {
                    curBatObj.runs = curBatObj.runs + setMakeRuns;
                    curBatObj.balls = curBatObj.balls + 1;

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })

                }
                else {
                    if (setMakeRuns == 4) {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.boundries = curBatObj.boundries + 1;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else if (setMakeRuns == 6) {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.sixes = curBatObj.sixes + 1;
                        curBatObj.balls = curBatObj.balls + 1;
                    }
                    else {
                        curBatObj.runs = curBatObj.runs + setMakeRuns;
                        curBatObj.balls = curBatObj.balls + 1;
                    }

                    secondInningScoreCard.batsmen.map(batt => {
                        if (batt.batsmanId === currentBatsmanId) {
                            batt = curBatObj;
                        }
                    })
                    let addExtras = [
                        {
                            "type": "NB",
                            "run": nb
                        },
                        {
                            "type": "OUT",
                            "run": 0
                        }
                    ]

                    addBallsObj.extras = addExtras;
                    addBallsObj.playedBy.batsmanId = currentBatsmanId;
                    if (Inning2BatLen < 11) {
                        addBallsObj.playedBy.newBatsmanId = selectedNewBatsmen;
                    }

                    addBallsObj.playedBy.lastBatsmanId = currentBatsmanId;
                    addBallsObj.playedBy.run = setMakeRuns;
                    addBallsObj.strokeNo = strokeNoG + 1;
                    addBallsObj.total = nb + setMakeRuns;

                    if (currentOverObj.balls) {
                        let ballsArrayLen = currentOverObj.balls.length;
                        addBallsObj.ballNo = ballsArrayLen + 1;

                        currentOverObj.balls = [...currentOverObj.balls, { ...addBallsObj }];
                    }
                    else {
                        addBallsObj.ballNo = 1;

                        currentOverObj.balls = [{ ...addBallsObj }];
                    }
                    secondInningScoreCard.overs.map(over => {
                        if (over.overNo === currentOverObj.overNo) {
                            over = currentOverObj;
                        }
                    })

                    currentBowllerObj.runs = currentBowllerObj.runs + nb + setMakeRuns;
                    currentBowllerObj.balls = currentBowllerObj.balls + 0;
                    secondInningScoreCard.bowlers.map(bowls => {
                        if (bowls.bowlerId === currentBowllerObj.bowlerId) {
                            bowls = currentBowllerObj;
                        }
                    })
                }
            }


            //new batsman entry
            if (wikType === 'runoutns') {
                let curBatObjj = secondInningScoreCard.batsmen.filter(bat => {
                    if (bat.fallOfWicket === null) {
                        return bat;
                    }
                })
                let strikerFalse = curBatObjj.find(change => {
                    if (!change.striker) {
                        return change;
                    }
                })
                strikerFalse.striker = false;
                strikerFalse.fallOfWicket = setWick;

                secondInningScoreCard.batsmen.map(ex => {
                    if (ex.batsmanId === strikerFalse.batsmanId) {
                        ex = strikerFalse;
                    }
                })

                var newBatEntry = {
                    "batsmanId": selectedNewBatsmen,
                    "batsmanName": chosenBatsmenName,
                    "runs": 0,
                    "balls": 0,
                    "boundries": 0,
                    "sixes": 0,
                    "striker": false,
                    "fallOfWicket": null
                }
                if (Inning2BatLen < 11) {
                    secondInningScoreCard.batsmen = [...secondInningScoreCard.batsmen, { ...newBatEntry }];
                }
            }
            else {
                let curBatObjj = secondInningScoreCard.batsmen.filter(bat => {
                    if (bat.fallOfWicket === null) {
                        return bat;
                    }
                })
                let strikerTrue = curBatObjj.find(change => {
                    if (change.striker) {
                        return change;
                    }
                })

                strikerTrue.striker = false;
                strikerTrue.fallOfWicket = setWick;
                secondInningScoreCard.batsmen.map(ex => {
                    if (ex.batsmanId === strikerTrue.batsmanId) {
                        ex = strikerTrue;
                    }
                })

                var newBatEntry = {
                    "batsmanId": selectedNewBatsmen,
                    "batsmanName": chosenBatsmenName,
                    "runs": 0,
                    "balls": 0,
                    "boundries": 0,
                    "sixes": 0,
                    "striker": true,
                    "fallOfWicket": null
                }
                if (Inning2BatLen < 11) {
                    secondInningScoreCard.batsmen = [...secondInningScoreCard.batsmen, { ...newBatEntry }];
                }
            }

            d107CricketScoreCard.secondInningScoreCard = secondInningScoreCard;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({ secondInningScoreCard: secondInningScoreCard, d107CricketScoreCard: d107CricketScoreCard, showingWicketModal: false, showingWicketModal2: false, showingWicketModal3: false, setMakeRuns: null })
        }

        //emptying global variables
        wikType = null;
        selectedHelper = null;
        selectedNewBatsmen = null;
        selectedBowler = null;

        onlyWicket = false;
        wideWicket = false;
        noBallWicket = false;
        noByesWicket = false;
        noLegByesWicket = false;
        byesWicket = false;
        legbyesWicket = false;

        // checking win status on 10 wicket in the first & second innings
        if (this.state.currentInning === 1) {
            if (firstInningScoreCard.overCompleted === this.state.originalOvers || firstInningScoreCard.wicketFall === 10) {
                this.setState({ showingNextInningsModal: true })
            }
        }

        if (this.state.currentInning === 2) {
            if (d107CricketScoreCard.firstBattingTeam === "challengedBy") {
                if ((secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                    this.setState({ selectWinnerModal: true })
                }

                else if ((secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                    this.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                    winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                    byWinner = true;
                }

                else if (secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || secondInningScoreCard.overCompleted === this.state.originalOvers || secondInningScoreCard.wicketFall === 10) {
                    this.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                    winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                    toWinner = true;
                }
            }

            else {
                if ((secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                    this.setState({ selectWinnerModal: true })
                }

                else if ((secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.overCompleted === this.state.originalOvers) || (secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (secondInningScoreCard.wicketFall === 10)) {
                    this.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                    winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                    toWinner = true;
                }

                else if (secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || secondInningScoreCard.overCompleted === this.state.originalOvers || secondInningScoreCard.wicketFall === 10) {
                    this.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                    winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                    byWinner = true;
                }
            }
        }
    }

    chooseNextBowler = (e) => {
        if (e.target.id === 'bowlls') {
            selectedBowler = e.target.value;
        }
    }

    bowlerAfterOver = () => {
        this.setState({ showHideCurrOver: false })
        console.log(d107CricketScoreCard)
        if (this.state.currentInning === 1) {
            let firstInningScoreCard = { ...d107CricketScoreCard.firstInningScoreCard };

            if (selectedBowler) {

            }
            else {

                let officersIds = [];
                let last_over = overList[overList.length - 1];
                console.log(last_over)
                officersIds.push(last_over.bowlerID);
                var newBowlerList = teamMembersBolfirst.filter(function (obj) { return officersIds.indexOf(obj) == -1; });
                selectedBowler = newBowlerList[0];

            }

            let chosenBowlerName;
            this.props.profileUsers.map(u => {
                if (u.contactNo === selectedBowler) {
                    chosenBowlerName = u.name;
                }
            })

            let bowlerss = {
                "bowlerId": selectedBowler,
                "bowlerName": chosenBowlerName,
                "runs": 0,
                "balls": 0,
                "completedOver": 0,
                "wickets": 0
            }

            let isExistingBowler = false;
            firstInningScoreCard.bowlers.map(bowls => {
                if (bowls.bowlerId === selectedBowler) {
                    isExistingBowler = true;
                }
            })

            if (!isExistingBowler) {
                firstInningScoreCard.bowlers = [...firstInningScoreCard.bowlers, { ...bowlerss }];
            }

            let overss = {
                "overNo": firstInningScoreCard.currentOver,
                "bowlerID": selectedBowler,
                "balls": null
            }
            firstInningScoreCard.overs = [...firstInningScoreCard.overs, { ...overss }];

            d107CricketScoreCard.firstInningScoreCard = firstInningScoreCard;
            this.setState({ firstInningScoreCard: firstInningScoreCard, d107CricketScoreCard: d107CricketScoreCard, showingModal: false })

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);
        }

        else {
            let secondInningScoreCard = { ...d107CricketScoreCard.secondInningScoreCard };

            if (selectedBowler) {

            }
            else {

                let officersIds = [];
                let last_over = overList[overList.length - 1];
                console.log(last_over)
                officersIds.push(last_over.bowlerID);
                var newBowlerList = teamMembersBolfirst.filter(function (obj) { return officersIds.indexOf(obj) == -1; });
                selectedBowler = newBowlerList[0];

            }

            let chosenBowlerName;
            this.props.profileUsers.map(u => {
                if (u.contactNo === selectedBowler) {
                    chosenBowlerName = u.name;
                }
            })

            let bowlerss = {
                "bowlerId": selectedBowler,
                "bowlerName": chosenBowlerName,
                "runs": 0,
                "balls": 0,
                "completedOver": 0,
                "wickets": 0
            }

            let isExistingBowler = false;
            secondInningScoreCard.bowlers.map(bowls => {
                if (bowls.bowlerId === selectedBowler) {
                    isExistingBowler = true;
                }
            })

            if (!isExistingBowler) {
                secondInningScoreCard.bowlers = [...secondInningScoreCard.bowlers, { ...bowlerss }];
            }

            let overss = {
                "overNo": secondInningScoreCard.currentOver,
                "bowlerID": selectedBowler,
                "balls": null
            }
            secondInningScoreCard.overs = [...secondInningScoreCard.overs, { ...overss }];

            d107CricketScoreCard.secondInningScoreCard = secondInningScoreCard;
            this.setState({ secondInningScoreCard: secondInningScoreCard, d107CricketScoreCard: d107CricketScoreCard, showingModal: false })

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);
        }
    }

    signal = (e) => {
        let signal = e.target.value;
        if (e.target.checked === true) {
            switch (signal) {
                case 'wide':
                    this.setState({
                        wide: signal,
                    });
                    if (this.state.nb === 'nb') {
                        document.getElementById('nb').checked = false;
                        this.setState({ nb: '' })
                    }
                    if (this.state.byes === 'byes') {
                        document.getElementById('byes').checked = false;
                        this.setState({ byes: '' })
                    }
                    if (this.state.lb === 'lb') {
                        document.getElementById('lb').checked = false;
                        this.setState({ lb: '' })
                    }
                    break

                case 'nb':
                    this.setState({
                        nb: signal,
                    });
                    if (this.state.wide === 'wide') {
                        document.getElementById('wide').checked = false;
                        this.setState({ wide: '' })
                    }
                    break

                case 'byes':
                    this.setState({
                        byes: signal,
                    });
                    if (this.state.wide === 'wide') {
                        document.getElementById('wide').checked = false;
                        this.setState({ wide: '' })
                    }
                    if (this.state.lb === 'lb') {
                        document.getElementById('lb').checked = false;
                        this.setState({ lb: '' })
                    }
                    break

                case 'lb':
                    this.setState({
                        lb: signal,
                    });
                    if (this.state.wide === 'wide') {
                        document.getElementById('wide').checked = false;
                        this.setState({ wide: '' })
                    }
                    if (this.state.byes === 'byes') {
                        document.getElementById('byes').checked = false;
                        this.setState({ byes: '' })
                    }
                    break

                default:
                    this.setState({
                        wicket: signal,
                    });
            }
        }
        if (e.target.checked === false) {
            switch (signal) {
                case 'wide':
                    this.setState({ wide: '' });
                    break

                case 'nb':
                    this.setState({ nb: '' });
                    break

                case 'byes':
                    this.setState({ byes: '' });
                    break

                case 'lb':
                    this.setState({ lb: '' });
                    break

                default:
                    this.setState({ wicket: '' });
            }
        }
    }

    closeOverModal = () => {
        this.setState({ openOverModal: false })
    }
    overSelection = () => {
        this.setState({ openOverModal: false })
        let overs = document.getElementsByName("overs")[0].value;
        let tournament = this.props.tournament;
        d107CricketScoreCard = {
            "byCricketScoreCard": {
                "teamId": null,
                "winner": false,
            },
            "toCricketScoreCard": {
                "teamId": null,
                "winner": false,
            },
            "firstInningScoreCard": null,
            "secondInningScoreCard": null,
            "strokeNo": 0,
            "overs": parseInt(overs),
            "currentInning": 0,
            "firstBattingTeam": null,
            "manOfTheMatch": null,
        }

        pairObj = myUtilclass.getMatchPair(this.props.tournament, this.props.tt23Obj, true, formatType, this.props.matchId);
        this.setState({ matchPairObj: pairObj })

        d107CricketScoreCard.byCricketScoreCard.teamId = pairObj.firstId;
        d107CricketScoreCard.toCricketScoreCard.teamId = pairObj.secondId;
        let score = {
            tournamentId: tournament.tournamentId,
            matchId: this.props.matchId,
            d107CricketScoreCard: d107CricketScoreCard,
        }
        UpdateTournamentsScore(score).then((success) => {
            this.getScore();
        })
        this.setState({ d107CricketScoreCard: d107CricketScoreCard, originalOvers: parseInt(overs) })
    }

    getScore() {
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)

                    if (result.data.score !== null) {
                        if (result.data.score.d107CricketScoreCard !== null) {
                            d107CricketScoreCard = result.data.score.d107CricketScoreCard;
                            that.setState({
                                d107CricketScoreCard: d107CricketScoreCard,
                                firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard,
                                secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard,
                                originalOvers: d107CricketScoreCard.overs,
                                firstBattingTeam: d107CricketScoreCard.firstBattingTeam,
                                currentInning: d107CricketScoreCard.currentInning,
                            })

                            //storing batTeam and bowlTeam...
                            if (d107CricketScoreCard.currentInning === 0) {
                                let tournament = that.props.tournament;
                                d107CricketScoreCard.byCricketScoreCard.teamId = pairObj.firstId;
                                d107CricketScoreCard.toCricketScoreCard.teamId = pairObj.secondId;

                                let score = {
                                    tournamentId: that.props.tournament.tournamentId,
                                    matchId: that.props.matchId,
                                    d107CricketScoreCard: d107CricketScoreCard,
                                }
                                UpdateTournamentsScore(score);

                                if (d107CricketScoreCard.firstInningScoreCard === null) {
                                    that.setState({ modalIsOpen: true, d107CricketScoreCard: d107CricketScoreCard });
                                    that.props.profileTeams.map(team => {
                                        if (team.teamId === pairObj.firstId) {
                                            that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                                            byPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        }
                                        if (team.teamId === pairObj.secondId) {
                                            that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                                            toPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        }
                                    })
                                }
                            }

                            if (d107CricketScoreCard.currentInning === 1) {

                                that.props.profileTeams.map(team => {
                                    if (team.teamId === d107CricketScoreCard.firstInningScoreCard.battingTeamId) {
                                        that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                                        byPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        teamMembersBatfirst = team.teamMembers;

                                    }
                                    if (team.teamId === d107CricketScoreCard.firstInningScoreCard.bowlingTeamId) {
                                        that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                                        toPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        teamMembersBolfirst = team.teamMembers;
                                    }
                                })

                                if (d107CricketScoreCard.firstBattingTeam === null) {

                                    that.setState({ modalIsOpen: true, d107CricketScoreCard: d107CricketScoreCard });
                                    that.props.profileTeams.map(team => {
                                        if (team.teamId === pairObj.firstId) {
                                            that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                                            byPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        }
                                        if (team.teamId === pairObj.secondId) {
                                            that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                                            toPic = team.teamIcon ? team.teamIcon : teamIcons;
                                        }
                                    })
                                }

                                if (d107CricketScoreCard.firstBattingTeam !== null) {

                                    if (d107CricketScoreCard.firstInningScoreCard.currentOver === 0) {
                                        that.props.profileTeams.map(sel => {
                                            if (d107CricketScoreCard.firstInningScoreCard.battingTeamId === sel.teamId) {
                                                teamMembersBatfirst = sel.teamMembers;
                                            }
                                            if (d107CricketScoreCard.firstInningScoreCard.bowlingTeamId === sel.teamId) {
                                                teamMembersBolfirst = sel.teamMembers;
                                            }
                                        })
                                        striker = teamMembersBatfirst[0];
                                        NonStriker = teamMembersBatfirst[0];
                                        selectedBowler = teamMembersBolfirst[0];
                                        d107CricketScoreCard.currentInning = 1;

                                        that.setState({ showingModal0: true, d107CricketScoreCard: d107CricketScoreCard })
                                    }

                                    if (d107CricketScoreCard.firstInningScoreCard.overCompleted === that.state.originalOvers || d107CricketScoreCard.firstInningScoreCard.wicketFall === 10) {
                                        that.setState({ showingNextInningsModal: true })
                                    }
                                    //show next inngs else show next bowler modal 
                                    else {
                                        if (d107CricketScoreCard.firstInningScoreCard.overs) {
                                            if (d107CricketScoreCard.firstInningScoreCard.overCompleted === d107CricketScoreCard.firstInningScoreCard.overs.length) {
                                                that.setState({ showingModal: true });
                                            }
                                        }
                                    }

                                }
                            }

                            if (d107CricketScoreCard.currentInning === 2) {

                                if (d107CricketScoreCard.firstBattingTeam !== null) {

                                    that.props.profileTeams.map(sel => {
                                        if (d107CricketScoreCard.firstInningScoreCard.bowlingTeamId === sel.teamId) {
                                            teamMembersBatfirst = sel.teamMembers;
                                            that.setState({ toTeamName: sel.teamName, toTeamNameid: sel.teamId });
                                            toPic = sel.teamIcon ? sel.teamIcon : teamIcons;
                                        }
                                        if (d107CricketScoreCard.firstInningScoreCard.battingTeamId === sel.teamId) {
                                            teamMembersBolfirst = sel.teamMembers;
                                            that.setState({ byTeamName: sel.teamName, byTeamNameid: sel.teamId });
                                            byPic = sel.teamIcon ? sel.teamIcon : teamIcons;
                                        }
                                    })

                                    if (d107CricketScoreCard.secondInningScoreCard.currentOver === 0 && d107CricketScoreCard.secondInningScoreCard.overs === null) {

                                        that.props.profileTeams.map(sel => {
                                            if (d107CricketScoreCard.firstInningScoreCard.bowlingTeamId === sel.teamId) {
                                                teamMembersBatfirst = sel.teamMembers;
                                                that.setState({ toTeamName: sel.teamName, toTeamNameid: sel.teamId });
                                                toPic = sel.teamIcon ? sel.teamIcon : teamIcons;
                                            }
                                            if (d107CricketScoreCard.firstInningScoreCard.battingTeamId === sel.teamId) {
                                                teamMembersBolfirst = sel.teamMembers;
                                                that.setState({ byTeamName: sel.teamName, byTeamNameid: sel.teamId });
                                                byPic = sel.teamIcon ? sel.teamIcon : teamIcons;
                                            }
                                        })
                                        striker = teamMembersBatfirst[0];
                                        NonStriker = teamMembersBatfirst[0];
                                        selectedBowler = teamMembersBolfirst[0];
                                        d107CricketScoreCard.currentInning = 2;

                                        that.setState({ showingModal0: true, d107CricketScoreCard: d107CricketScoreCard })
                                    }

                                    //show winner else next bowler modal
                                    if (d107CricketScoreCard.firstBattingTeam === "challengedBy") {
                                        if ((d107CricketScoreCard.secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers) || (d107CricketScoreCard.secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.wicketFall === 10)) {
                                            that.setState({ selectWinnerModal: true })
                                        }

                                        else if ((d107CricketScoreCard.secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers) || (d107CricketScoreCard.secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.wicketFall === 10)) {
                                            that.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                                            winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                                            byWinner = true;
                                        }

                                        else if (d107CricketScoreCard.secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers || d107CricketScoreCard.secondInningScoreCard.wicketFall === 10) {
                                            that.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                                            winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                                            toWinner = true;
                                        }

                                        else {
                                            if (d107CricketScoreCard.secondInningScoreCard.overs) {
                                                if (d107CricketScoreCard.secondInningScoreCard.overCompleted === d107CricketScoreCard.secondInningScoreCard.overs.length) {
                                                    that.setState({ showingModal: true });
                                                }
                                            }
                                        }
                                    }

                                    else {
                                        if ((d107CricketScoreCard.secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers) || (d107CricketScoreCard.secondInningScoreCard.totalRun === d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.wicketFall === 10)) {
                                            that.setState({ selectWinnerModal: true })
                                        }

                                        else if ((d107CricketScoreCard.secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers) || (d107CricketScoreCard.secondInningScoreCard.totalRun < d107CricketScoreCard.firstInningScoreCard.totalRun) && (d107CricketScoreCard.secondInningScoreCard.wicketFall === 10)) {
                                            that.setState({ toWinner: true, winners: d107CricketScoreCard.toCricketScoreCard.teamId, winnerModalOpen: true })
                                            winners = d107CricketScoreCard.toCricketScoreCard.teamId;
                                            toWinner = true;
                                        }

                                        else if (d107CricketScoreCard.secondInningScoreCard.totalRun > d107CricketScoreCard.firstInningScoreCard.totalRun || d107CricketScoreCard.secondInningScoreCard.overCompleted === that.state.originalOvers || d107CricketScoreCard.secondInningScoreCard.wicketFall === 10) {
                                            that.setState({ byWinner: true, winners: d107CricketScoreCard.byCricketScoreCard.teamId, winnerModalOpen: true })
                                            winners = d107CricketScoreCard.byCricketScoreCard.teamId;
                                            byWinner = true;
                                        }

                                        else {
                                            if (d107CricketScoreCard.secondInningScoreCard.overs) {
                                                if (d107CricketScoreCard.secondInningScoreCard.overCompleted === d107CricketScoreCard.secondInningScoreCard.overs.length) {
                                                    that.setState({ showingModal: true });
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                    if (result.data.score === null) {
                        that.setState({ openOverModal: true });
                    }

                });
        }
    }

    secondInnings = () => {
        if (d107CricketScoreCard.secondInningScoreCard === null) {

            var secondInningScoreCard = {
                "battingTeamId": d107CricketScoreCard.firstInningScoreCard.bowlingTeamId,
                "bowlingTeamId": d107CricketScoreCard.firstInningScoreCard.battingTeamId,
                "totalRun": 0,
                "overCompleted": 0,
                "currentOver": 0,
                "ballNo": 0,
                "wicketFall": 0,
                "batsmen": null,
                "bowlers": null,
                "overs": null
            }
            d107CricketScoreCard.secondInningScoreCard = secondInningScoreCard;
            d107CricketScoreCard.currentInning = 2;
            d107CricketScoreCard.strokeNo = 0;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({
                d107CricketScoreCard: d107CricketScoreCard,
                secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard,
                currentInning: d107CricketScoreCard.currentInning,
                showingNextInningsModal: false,
                showingModal0: true
            });
            this.props.profileTeams.map(sel => {
                if (d107CricketScoreCard.firstInningScoreCard.bowlingTeamId === sel.teamId) {
                    teamMembersBatfirst = sel.teamMembers;
                    this.setState({ toTeamName: sel.teamName, toTeamNameid: sel.teamId });
                }
                if (d107CricketScoreCard.firstInningScoreCard.battingTeamId === sel.teamId) {
                    this.setState({ byTeamName: sel.teamName, byTeamNameid: sel.teamId });
                    teamMembersBolfirst = sel.teamMembers;
                }
            })
            striker = teamMembersBatfirst[0];
            NonStriker = teamMembersBatfirst[0];
            selectedBowler = teamMembersBolfirst[0];
        }
        console.log(d107CricketScoreCard)
    }

    selectBatFirstTeam = () => {
        if (d107CricketScoreCard.firstInningScoreCard === null) {
            this.props.profileTeams.map(t => {
                if (batFirstId !== pairObj.firstId) {
                    batSecondId = pairObj.firstId;
                }
                else {
                    batSecondId = pairObj.secondId;
                }
            })

            var firstInningScoreCard = {
                "battingTeamId": batFirstId,
                "bowlingTeamId": batSecondId,
                "totalRun": 0,
                "overCompleted": 0,
                "currentOver": 0,
                "ballNo": 0,
                "wicketFall": 0,
                "batsmen": null,
                "bowlers": null,
                "overs": null
            }
            d107CricketScoreCard.firstInningScoreCard = firstInningScoreCard;
            d107CricketScoreCard.firstBattingTeam = teamBatFirst;
            d107CricketScoreCard.currentInning = 1;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({
                d107CricketScoreCard: d107CricketScoreCard,


                firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard,
                secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard,
                // originalOvers: d107CricketScoreCard.overs,
                firstBattingTeam: d107CricketScoreCard.firstBattingTeam,
                currentInning: d107CricketScoreCard.currentInning,
                modalIsOpen: false,
                showingModal0: true
            });
            this.props.profileTeams.map(sel => {
                if (batFirstId === sel.teamId) {
                    teamMembersBatfirst = sel.teamMembers;
                    this.setState({ byTeamName: sel.teamName })
                }
                if (batSecondId === sel.teamId) {
                    teamMembersBolfirst = sel.teamMembers;
                    this.setState({ toTeamName: sel.teamName })
                }
            })
            striker = teamMembersBatfirst[0];
            NonStriker = teamMembersBatfirst[0];
            selectedBowler = teamMembersBolfirst[0];

        }
    }

    openingSelection = () => {
        this.setState({ showHideCurrOver: false })
        if (this.state.currentInning === 1) {
            if (striker === NonStriker) {
                document.getElementById('delays').style = 'block';
                this.setState({
                    showingAlert1: true
                });
                setTimeout(() => {
                    this.setState({
                        showingAlert1: false
                    });
                }, 2000);
            }
            else {
                var strikerName, nsName, startingBowler;
                this.props.profileUsers.map(u => {
                    if (u.contactNo === striker) {
                        strikerName = u.name;
                    }
                    if (u.contactNo === NonStriker) {
                        nsName = u.name;
                    }
                    if (u.contactNo === selectedBowler) {
                        startingBowler = u.name;
                    }
                })

                let batsmanObjStriker = { ...batsmen };
                let batsmanObjNonStriker = { ...batsmen };
                batsmanObjStriker.batsmanId = striker;
                batsmanObjStriker.batsmanName = strikerName;
                batsmanObjStriker.striker = true;

                batsmanObjNonStriker.batsmanId = NonStriker;
                batsmanObjNonStriker.batsmanName = nsName;
                batsmanObjNonStriker.striker = false;


                let bowlersObj = { ...bowlers };
                bowlersObj.bowlerId = selectedBowler;
                bowlersObj.bowlerName = startingBowler;


                let oversObjBlock = { ...overs };
                oversObjBlock.overNo = 1;
                oversObjBlock.bowlerID = selectedBowler;


                d107CricketScoreCard.firstInningScoreCard.currentOver = 1;
                d107CricketScoreCard.firstInningScoreCard.batsmen = [{ ...batsmanObjStriker }, { ...batsmanObjNonStriker }];
                d107CricketScoreCard.firstInningScoreCard.bowlers = [{ ...bowlersObj }];
                d107CricketScoreCard.firstInningScoreCard.overs = [{ ...oversObjBlock }];
                this.setState({
                    d107CricketScoreCard: d107CricketScoreCard,


                    firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard,
                    secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard,
                    // originalOvers: d107CricketScoreCard.overs,
                    firstBattingTeam: d107CricketScoreCard.firstBattingTeam,
                    currentInning: d107CricketScoreCard.currentInning,

                    modalIsOpen: false,
                    showingModal0: false,
                });

                var score = {
                    tournamentId: this.props.tournament.tournamentId,
                    matchId: this.props.matchId,
                    d107CricketScoreCard: d107CricketScoreCard,
                }
                UpdateTournamentsScore(score);

                this.setState({ showingModal0: false });
            }
        }

        else {
            if (striker === NonStriker) {
                document.getElementById('delays').style = 'block';
                this.setState({
                    showingAlert1: true
                });
                setTimeout(() => {
                    this.setState({
                        showingAlert1: false
                    });
                }, 2000);
            }
            else {
                var strikerName, nsName, startingBowler;
                this.props.profileUsers.map(u => {
                    if (u.contactNo === striker) {
                        strikerName = u.name;
                    }
                    if (u.contactNo === NonStriker) {
                        nsName = u.name;
                    }
                    if (u.contactNo === selectedBowler) {
                        startingBowler = u.name;
                    }
                })

                let batsmanObjStriker = { ...batsmen };
                let batsmanObjNonStriker = { ...batsmen };
                batsmanObjStriker.batsmanId = striker;
                batsmanObjStriker.batsmanName = strikerName;
                batsmanObjStriker.striker = true;

                batsmanObjNonStriker.batsmanId = NonStriker;
                batsmanObjNonStriker.batsmanName = nsName;
                batsmanObjNonStriker.striker = false;


                let bowlersObj = { ...bowlers };
                bowlersObj.bowlerId = selectedBowler;
                bowlersObj.bowlerName = startingBowler;


                let oversObjBlock = { ...overs };
                oversObjBlock.overNo = 1;
                oversObjBlock.bowlerID = selectedBowler;


                d107CricketScoreCard.secondInningScoreCard.currentOver = 1;
                d107CricketScoreCard.secondInningScoreCard.batsmen = [{ ...batsmanObjStriker }, { ...batsmanObjNonStriker }];
                d107CricketScoreCard.secondInningScoreCard.bowlers = [{ ...bowlersObj }];
                d107CricketScoreCard.secondInningScoreCard.overs = [{ ...oversObjBlock }];
                this.setState({
                    d107CricketScoreCard: d107CricketScoreCard,


                    firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard,
                    secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard,
                    // originalOvers: d107CricketScoreCard.overs,
                    firstBattingTeam: d107CricketScoreCard.firstBattingTeam,
                    currentInning: d107CricketScoreCard.currentInning,

                    modalIsOpen: false,
                    showingModal0: false,
                });

                var score = {
                    tournamentId: this.props.tournament.tournamentId,
                    matchId: this.props.matchId,
                    d107CricketScoreCard: d107CricketScoreCard,
                }
                UpdateTournamentsScore(score);

                this.setState({ showingModal0: false });
            }
        }
    }

    changeDataa(event) {

        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            if (selectedValue === 'challengedBy') {
                selectedWinners = this.state.byTeamNameid;
                toWinner = false;
                byWinner = true;
            }
            else {
                selectedWinners = this.state.toTeamNameid;
                byWinner = false;
                toWinner = true;
            }
            console.log(selectedWinners)
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            teamBatFirst = selectedValue;
            if (teamBatFirst === 'challengedBy') {
                batFirstId = this.state.byTeamNameid;
            }
            if (teamBatFirst === 'challengedTo') {
                batFirstId = this.state.toTeamNameid;
            }
            this.setState({ tosses: true })
            console.log(teamBatFirst)
            console.log(batFirstId)
        }
    }

    selectStrikerNon = (e) => {
        if (e.target.id === 's') {
            striker = e.target.value;
        }
        else {
            NonStriker = e.target.value;
        }
    }
    selectCurrentBowler = (e) => {
        if (e.target.id === 'bwl') {
            selectedBowler = e.target.value;
        }
    }

    swapBatsman() {
        console.log(currentBatsmanId)
        if (this.state.d107CricketScoreCard.currentInning === 1) {
            let firstInningScoreCard = { ...d107CricketScoreCard.firstInningScoreCard };
            let curBatObj = firstInningScoreCard.batsmen.filter(bat => {
                if (bat.fallOfWicket === null) {
                    return bat;
                }
            })
            let strikerTrue = curBatObj.find(change => {
                if (change.striker) {
                    return change;
                }
            })
            let strikerFalse = curBatObj.find(change => {
                if (!change.striker) {
                    return change;
                }
            })
            strikerTrue.striker = false;
            strikerFalse.striker = true;

            firstInningScoreCard.batsmen.map(ex => {
                if (ex.batsmanId === strikerTrue.batsmanId) {
                    ex = strikerTrue;
                }
                if (ex.batsmanId === strikerFalse.batsmanId) {
                    ex = strikerFalse;
                }
            })
            d107CricketScoreCard.firstInningScoreCard = firstInningScoreCard;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({ firstInningScoreCard: firstInningScoreCard, d107CricketScoreCard: d107CricketScoreCard })
        }

        else {
            let secondInningScoreCard = { ...d107CricketScoreCard.secondInningScoreCard };
            let curBatObj = secondInningScoreCard.batsmen.filter(bat => {
                if (bat.fallOfWicket === null) {
                    return bat;
                }
            })
            let strikerTrue = curBatObj.find(change => {
                if (change.striker) {
                    return change;
                }
            })
            let strikerFalse = curBatObj.find(change => {
                if (!change.striker) {
                    return change;
                }
            })
            strikerTrue.striker = false;
            strikerFalse.striker = true;

            secondInningScoreCard.batsmen.map(ex => {
                if (ex.batsmanId === strikerTrue.batsmanId) {
                    ex = strikerTrue;
                }
                if (ex.batsmanId === strikerFalse.batsmanId) {
                    ex = strikerFalse;
                }
            })
            d107CricketScoreCard.secondInningScoreCard = secondInningScoreCard;

            let score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107CricketScoreCard: d107CricketScoreCard,
            }
            UpdateTournamentsScore(score);

            this.setState({ secondInningScoreCard: secondInningScoreCard, d107CricketScoreCard: d107CricketScoreCard })
        }
    }

    operation = () => {
        this.setState({
            showMe: true, showMe1: false
        })
    }
    operation1 = () => {
        this.setState({
            showMe1: true, showMe: false
        })
    }

    undoRuns = () => {
        let currentInning = this.state.currentInning;
        let currentOver;
        let ballNo;
        let undoBatsmanId;
        let undoRun;
        let ballType;
        let undoTotalRun;
        let currentBowllerId;

        if (currentInning === 1) {
            let DontUndo = false;
            if (d107CricketScoreCard.firstInningScoreCard.overs[0].balls != null) {
                if (d107CricketScoreCard.firstInningScoreCard.overs[0].balls.length > 0) {
                    DontUndo = false;
                }
                else {
                    DontUndo = true;
                }
            }
            else {
                DontUndo = true;
            }
            if (!DontUndo) {
                currentOver = d107CricketScoreCard.firstInningScoreCard.currentOver;
                ballNo = d107CricketScoreCard.firstInningScoreCard.ballNo;
                if (currentOver >= 1 && ballNo > 0) {


                    let holdingLastballIndex = currentOverObj.balls.length - 1
                    let getLastBallObjfromBallsArray = currentOverObj.balls[currentOverObj.balls.length - 1];
                    undoBatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                    undoRun = getLastBallObjfromBallsArray.playedBy.run;
                    undoTotalRun = getLastBallObjfromBallsArray.total;
                    currentBowllerId = currentBowllerObj.bowlerId;
                    if (!getLastBallObjfromBallsArray.extras) {
                        ballType = 'real';
                    }
                    // decrease ball and run from current bowler 


                    //cases - real run
                    if (ballType == 'real') {

                        if (undoRun % 2 == 1) {

                            if (undoRun == undoTotalRun) {
                                //  console.log(d107CricketScoreCard)
                                d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                    if (bm.batsmanId == undoBatsmanId) {
                                        bm.runs = bm.runs - undoRun;
                                        bm.balls = bm.balls - 1;

                                        bm.striker = true;
                                    }
                                    else {
                                        bm.striker = false;
                                    }
                                })




                                /*TEST*/

                                if (currentOver > 1) {
                                    let coT = currentOver - 1;
                                    let currentOverObjT = overList.find(over => {
                                        if (over.overNo == coT) {
                                            return over;
                                        }
                                    })


                                    let bowlersT = [...d107CricketScoreCard.firstInningScoreCard.bowlers];
                                    bowlersT.map(bow => {
                                        if (currentOverObjT.bowlerID === bow.bowlerId) {
                                            if (bow.completedOver > 0) {
                                                return bow.completedOver = bow.completedOver - 1;
                                            }
                                        }
                                    });
                                    console.log(bowlersT)
                                    d107CricketScoreCard.firstInningScoreCard.bowlers = bowlersT;
                                }










                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {

                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {

                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                                console.log(bow)
                                            }

                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            //  bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })
                            }





                        }
                        if (undoRun % 2 == 0) {
                            if (undoRun == undoTotalRun) {
                                d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                    if (bm.batsmanId == undoBatsmanId) {
                                        bm.runs = bm.runs - undoRun;
                                        bm.balls = bm.balls - 1;
                                        bm.striker = true;
                                    }
                                })

                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {

                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })
                            }
                        }
                        // decreese first inningTotal ballNo

                        d107CricketScoreCard.firstInningScoreCard.ballNo = ballNo - 1;

                        let currentOverIndex = currentOver - 1;
                        // decreese first inningTotal Run
                        d107CricketScoreCard.firstInningScoreCard.totalRun = d107CricketScoreCard.firstInningScoreCard.totalRun - undoTotalRun;
                        // pop ball object
                        if (d107CricketScoreCard.firstInningScoreCard.ballNo !== 0) {
                            d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();
                        }

                        if (d107CricketScoreCard.firstInningScoreCard.ballNo == 0) {
                            //decrease current over and complete over
                            if ((d107CricketScoreCard.firstInningScoreCard.overCompleted - 1) != -1) {
                                d107CricketScoreCard.firstInningScoreCard.currentOver = currentOver - 1;

                                d107CricketScoreCard.firstInningScoreCard.overCompleted = d107CricketScoreCard.firstInningScoreCard.overCompleted - 1;

                                //delete over during ball == 0

                                d107CricketScoreCard.firstInningScoreCard.overs.pop();
                                d107CricketScoreCard.firstInningScoreCard.ballNo = 6;
                            }
                            else {
                                d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();
                            }
                        }

                        //  d107CricketScoreCard.firstInningScoreCard.bowlers
                        //console.log(d107CricketScoreCard);
                    }
                    // with extra thing like. NB,OUT,LB,BYE,WIDE
                    else {
                        // console.log(getLastBallObjfromBallsArray)
                        let currentOverIndex = currentOver - 1;
                        let WD = false; let NB = false; let BYE = false; let LB = false; let OUT = false;
                        let lastOutbatsmanId;
                        let isStrikeTrue = false;
                        let onlyOut = false;
                        lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                        getLastBallObjfromBallsArray.extras.map((extra) => {

                            if (extra.type === 'OUT') {
                                OUT = true;
                                lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.lastBatsmanId;
                            }
                            if (extra.type === "WD") {
                                WD = true;
                            }
                            if (extra.type === "NB") {
                                NB = true;
                            }
                            if (extra.type === "BYE") {
                                BYE = true;
                            }
                            if (extra.type === "LB") {
                                LB = true;
                            }
                            if (d107CricketScoreCard.firstInningScoreCard.ballNo !== 0) {
                                d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();
                            }
                            d107CricketScoreCard.firstInningScoreCard.totalRun = d107CricketScoreCard.firstInningScoreCard.totalRun - undoTotalRun;
                        })
                        if (OUT) {
                            if (WD === true || NB === true) {
                                let runs = undoTotalRun - 1;
                                if (runs % 2 == 1) {
                                    isStrikeTrue = true
                                }
                            }
                            else {
                                onlyOut = true;
                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {
                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })

                                d107CricketScoreCard.firstInningScoreCard.ballNo = ballNo - 1;
                            }

                            d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == lastOutbatsmanId) {
                                    if (isStrikeTrue) {
                                        bm.striker = true;
                                    }
                                    if (onlyOut) {
                                        bm.striker = true;
                                    }

                                    bm.fallOfWicket = null;
                                    bm.runs = bm.runs - undoRun;
                                    //  bm.balls = bm.balls - 1;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })
                            d107CricketScoreCard.firstInningScoreCard.batsmen.pop();
                            d107CricketScoreCard.firstInningScoreCard.wicketFall = d107CricketScoreCard.firstInningScoreCard.wicketFall - 1;
                        }
                        if (NB || WD || BYE || LB) {
                            let runs = undoTotalRun - 1;
                            if (runs % 2 == 1) {
                                isStrikeTrue = true
                            }

                            d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == lastOutbatsmanId) {

                                    if (isStrikeTrue) {
                                        bm.striker = true;
                                    }
                                    if (onlyOut) {
                                        bm.striker = true;
                                    }

                                    bm.fallOfWicket = null;
                                    bm.runs = bm.runs - undoRun;
                                    bm.balls = bm.balls - 1;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })

                            if (BYE || LB) {

                                // decrease ball
                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {
                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })


                                // decrease global ball also
                                d107CricketScoreCard.firstInningScoreCard.ballNo = ballNo - 1;

                            }
                            else if (NB || WD) {
                                // no changes in balls
                            }
                        }

                    }
                    console.log(d107CricketScoreCard);
                    this.setState({ d107CricketScoreCard: d107CricketScoreCard, firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard })
                }
                else {

                    if (d107CricketScoreCard.firstInningScoreCard.overs[0].overNo > 1) {
                        d107CricketScoreCard.firstInningScoreCard.overs.pop();
                        d107CricketScoreCard.firstInningScoreCard.bowlers.pop();
                        d107CricketScoreCard.firstInningScoreCard.overCompleted = d107CricketScoreCard.firstInningScoreCard.overCompleted - 1;
                        d107CricketScoreCard.firstInningScoreCard.currentOver = d107CricketScoreCard.firstInningScoreCard.currentOver - 1;

                    }
                    currOver = d107CricketScoreCard.firstInningScoreCard.currentOver;
                    currentOver = d107CricketScoreCard.firstInningScoreCard.currentOver
                    overList = d107CricketScoreCard.firstInningScoreCard.overs;
                    bowlerList = d107CricketScoreCard.firstInningScoreCard.bowlers;
                    currentOverObj = overList.find(over => {
                        if (over.overNo == currOver) {
                            return over;
                        }
                    })
                    currentBowllerObj = bowlerList.find(bowler => {
                        if (bowler.bowlerId == currentOverObj.bowlerID) {
                            return bowler;
                        }
                    })


                    if (!currentOverObj.balls) {
                        //d107CricketScoreCard.firstInningScoreCard.bowlers.pop();
                        d107CricketScoreCard.firstInningScoreCard.overs.pop();
                        d107CricketScoreCard.firstInningScoreCard.currentOver = d107CricketScoreCard.firstInningScoreCard.currentOver - 1;
                        currOver = d107CricketScoreCard.firstInningScoreCard.currentOver;
                        currentOver = d107CricketScoreCard.firstInningScoreCard.currentOver;
                        d107CricketScoreCard.firstInningScoreCard.overCompleted = d107CricketScoreCard.firstInningScoreCard.overCompleted - 1;
                        this.setState({ showHideCurrOver: true });
                        currentOverObj = overList.find(over => {
                            if (over.overNo == currOver) {
                                return over;
                            }
                        })
                        currentBowllerObj = bowlerList.find(bowler => {
                            if (bowler.bowlerId == currentOverObj.bowlerID) {
                                return bowler;
                            }
                        })
                    }
                    console.log(d107CricketScoreCard)
                    let holdingLastballIndex = currentOverObj.balls.length - 1
                    let getLastBallObjfromBallsArray = currentOverObj.balls[currentOverObj.balls.length - 1];
                    undoBatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                    undoRun = getLastBallObjfromBallsArray.playedBy.run;
                    undoTotalRun = getLastBallObjfromBallsArray.total;
                    currentBowllerId = currentBowllerObj.bowlerId;
                    if (!getLastBallObjfromBallsArray.extras) {
                        ballType = 'real';
                    }
                    if (ballType == 'real') {

                        if (undoRun % 2 == 1) {
                            if (undoRun == undoTotalRun) {
                                d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                    if (bm.batsmanId == undoBatsmanId) {
                                        bm.runs = bm.runs - undoRun;
                                        bm.balls = bm.balls - 1;

                                        bm.striker = true;
                                    }
                                    else {
                                        bm.striker = false;
                                    }
                                })

                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {

                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {

                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                                console.log(bow)

                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {

                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })
                            }


                        }
                        if (undoRun % 2 == 0) {
                            if (undoRun == undoTotalRun) {
                                d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                    if (bm.batsmanId == undoBatsmanId) {
                                        bm.runs = bm.runs - undoRun;
                                        bm.balls = bm.balls - 1;
                                        bm.striker = true;
                                    }
                                })

                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {

                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })
                            }
                        }
                        // decreese first inningTotal ballNo

                        d107CricketScoreCard.firstInningScoreCard.ballNo = 5;


                        let currentOverIndex = currentOver - 1;
                        // decrease first inningTotal Run
                        d107CricketScoreCard.firstInningScoreCard.totalRun = d107CricketScoreCard.firstInningScoreCard.totalRun - undoTotalRun;
                        // pop ball object
                        if (d107CricketScoreCard.firstInningScoreCard.ballNo !== 0) {
                            d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();
                        }


                        if (d107CricketScoreCard.firstInningScoreCard.ballNo == 0) {
                            //decrease current over and complete over
                            d107CricketScoreCard.firstInningScoreCard.currentOver = currentOver - 1;
                            d107CricketScoreCard.firstInningScoreCard.overCompleted = d107CricketScoreCard.firstInningScoreCard.overCompleted - 1;

                            //delete over during ball == 0

                            d107CricketScoreCard.firstInningScoreCard.overs.pop();
                            d107CricketScoreCard.firstInningScoreCard.ballNo = 6;

                        }

                        //d107CricketScoreCard.firstInningScoreCard.bowlers
                        //console.log(d107CricketScoreCard);
                    }
                    // with extra thing like. NB,OUT,LB,BYE,WIDE
                    else {

                        // console.log(getLastBallObjfromBallsArray)
                        let currentOverIndex = currentOver - 1;
                        let WD = false; let NB = false; let BYE = false; let LB = false; let OUT = false;
                        let lastOutbatsmanId;
                        let isStrikeTrue = false;
                        let onlyOut = false;
                        getLastBallObjfromBallsArray.extras.map((extra) => {
                            if (extra.type === 'OUT') {
                                OUT = true;
                                lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.lastBatsmanId;
                            }
                            if (extra.type === "WD") {
                                WD = true;
                            }
                            if (extra.type === "NB") {
                                NB = true;
                            }
                            if (extra.type === "BYE") {
                                BYE = true;
                            }
                            if (extra.type === "LB") {
                                LB = true;
                            }
                            if (d107CricketScoreCard.firstInningScoreCard.ballNo !== 0) {
                                d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();
                            }
                            d107CricketScoreCard.firstInningScoreCard.totalRun = d107CricketScoreCard.firstInningScoreCard.totalRun - undoTotalRun;
                        })

                        if (OUT) {
                            if (WD === true || NB === true) {

                                let runs = undoTotalRun - 1;
                                if (runs % 2 == 1) {
                                    isStrikeTrue = true
                                }

                            }
                            else {
                                onlyOut = true;
                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {
                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })

                                d107CricketScoreCard.firstInningScoreCard.ballNo = 6 - 1;
                            }

                            d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == lastOutbatsmanId) {
                                    if (isStrikeTrue) {
                                        bm.striker = true;
                                    }
                                    if (onlyOut) {
                                        bm.striker = true;
                                    }

                                    bm.fallOfWicket = null;
                                    bm.runs = bm.runs - undoRun;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })
                            d107CricketScoreCard.firstInningScoreCard.batsmen.pop();
                            d107CricketScoreCard.firstInningScoreCard.wicketFall = d107CricketScoreCard.firstInningScoreCard.wicketFall - 1;
                        }
                        if (NB || WD || BYE || LB) {

                            let runs = undoTotalRun - 1;
                            if (runs % 2 == 1) {
                                isStrikeTrue = true
                            }
                            d107CricketScoreCard.firstInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == lastOutbatsmanId) {
                                    if (isStrikeTrue) {
                                        bm.striker = true;
                                    }
                                    if (onlyOut) {
                                        bm.striker = true;
                                    }

                                    bm.fallOfWicket = null;
                                    bm.runs = bm.runs - undoRun;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })
                            if (BYE || LB) {

                                // decrease ball
                                d107CricketScoreCard.firstInningScoreCard.bowlers.map(bow => {
                                    if (bow.bowlerId == currentBowllerId) {
                                        if (bow.balls !== 0) {
                                            bow.runs = bow.runs - undoTotalRun;
                                            if (bow.balls == 0) {

                                            }
                                            else {
                                                bow.balls = bow.balls - 1;
                                            }
                                        }
                                        //change current bowler to previous bowler of his last ball
                                        else {
                                            bow.balls = 5;
                                            bow.completedOver = bow.completedOver - 1;
                                        }
                                    }
                                })

                            }
                            else if (NB || WD) {

                                d107CricketScoreCard.firstInningScoreCard.overs[currentOverIndex].balls.pop();

                            }

                        }

                    }
                    console.log(d107CricketScoreCard);
                    this.setState({ d107CricketScoreCard: d107CricketScoreCard, firstInningScoreCard: d107CricketScoreCard.firstInningScoreCard })
                }
            }

        }

        //2nd Inngs Undo
        else {

            currentOver = d107CricketScoreCard.secondInningScoreCard.currentOver;
            ballNo = d107CricketScoreCard.secondInningScoreCard.ballNo;
            if (currentOver >= 1 && ballNo > 0) {


                let holdingLastballIndex = currentOverObj.balls.length - 1
                let getLastBallObjfromBallsArray = currentOverObj.balls[currentOverObj.balls.length - 1];
                undoBatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                undoRun = getLastBallObjfromBallsArray.playedBy.run;
                undoTotalRun = getLastBallObjfromBallsArray.total;
                currentBowllerId = currentBowllerObj.bowlerId;
                if (!getLastBallObjfromBallsArray.extras) {
                    ballType = 'real';
                }
                // decrease ball and run from current bowler 


                //cases - real run
                if (ballType == 'real') {

                    if (undoRun % 2 == 1) {

                        if (undoRun == undoTotalRun) {
                            //  console.log(d107CricketScoreCard)
                            d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == undoBatsmanId) {
                                    bm.runs = bm.runs - undoRun;
                                    bm.balls = bm.balls - 1;

                                    bm.striker = true;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {

                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {

                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                            console.log(bow)

                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })
                        }





                    }
                    if (undoRun % 2 == 0) {
                        if (undoRun == undoTotalRun) {
                            d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == undoBatsmanId) {
                                    bm.runs = bm.runs - undoRun;
                                    bm.balls = bm.balls - 1;
                                    bm.striker = true;
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {

                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })
                        }
                    }
                    // decrease first inningTotal ballNo

                    d107CricketScoreCard.secondInningScoreCard.ballNo = ballNo - 1;

                    let currentOverIndex = currentOver - 1;
                    // decreese first inningTotal Run
                    d107CricketScoreCard.secondInningScoreCard.totalRun = d107CricketScoreCard.secondInningScoreCard.totalRun - undoTotalRun;
                    // pop ball object
                    if (d107CricketScoreCard.secondInningScoreCard.ballNo !== 0) {
                        d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();
                    }

                    if (d107CricketScoreCard.secondInningScoreCard.ballNo == 0) {
                        //decrease current over and complete over
                        if ((d107CricketScoreCard.secondInningScoreCard.overCompleted - 1) != -1) {
                            d107CricketScoreCard.secondInningScoreCard.currentOver = currentOver - 1;

                            d107CricketScoreCard.secondInningScoreCard.overCompleted = d107CricketScoreCard.secondInningScoreCard.overCompleted - 1;

                            //delete over during ball == 0

                            d107CricketScoreCard.secondInningScoreCard.overs.pop();
                            d107CricketScoreCard.secondInningScoreCard.ballNo = 6;
                        }
                        else {
                            d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();
                        }
                    }

                    //d107CricketScoreCard.secondInningScoreCard.bowlers
                    //console.log(d107CricketScoreCard);
                }
                // with extra thing like. NB,OUT,LB,BYE,WIDE
                else {
                    // console.log(getLastBallObjfromBallsArray)
                    let currentOverIndex = currentOver - 1;
                    let WD = false; let NB = false; let BYE = false; let LB = false; let OUT = false;
                    let lastOutbatsmanId;
                    let isStrikeTrue = false;
                    let onlyOut = false;
                    lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                    getLastBallObjfromBallsArray.extras.map((extra) => {

                        if (extra.type === 'OUT') {
                            OUT = true;
                            lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.lastBatsmanId;
                        }
                        if (extra.type === "WD") {
                            WD = true;
                        }
                        if (extra.type === "NB") {
                            NB = true;
                        }
                        if (extra.type === "BYE") {
                            BYE = true;
                        }
                        if (extra.type === "LB") {
                            LB = true;
                        }
                        if (d107CricketScoreCard.secondInningScoreCard.ballNo !== 0) {
                            d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();
                        }
                        d107CricketScoreCard.secondInningScoreCard.totalRun = d107CricketScoreCard.secondInningScoreCard.totalRun - undoTotalRun;
                    })
                    if (OUT) {
                        if (WD === true || NB === true) {
                            let runs = undoTotalRun - 1;
                            if (runs % 2 == 1) {
                                isStrikeTrue = true
                            }
                        }
                        else {
                            onlyOut = true;
                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {
                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.ballNo = ballNo - 1;
                        }

                        d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                            if (bm.batsmanId == lastOutbatsmanId) {
                                if (isStrikeTrue) {
                                    bm.striker = true;
                                }
                                if (onlyOut) {
                                    bm.striker = true;
                                }

                                bm.fallOfWicket = null;
                                bm.runs = bm.runs - undoRun;
                                //  bm.balls = bm.balls - 1;
                            }
                            else {
                                bm.striker = false;
                            }
                        })
                        d107CricketScoreCard.secondInningScoreCard.batsmen.pop();
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = d107CricketScoreCard.secondInningScoreCard.wicketFall - 1;
                    }
                    if (NB || WD || BYE || LB) {
                        let runs = undoTotalRun - 1;
                        if (runs % 2 == 1) {
                            isStrikeTrue = true
                        }

                        d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                            if (bm.batsmanId == lastOutbatsmanId) {

                                if (isStrikeTrue) {
                                    bm.striker = true;
                                }
                                if (onlyOut) {
                                    bm.striker = true;
                                }

                                bm.fallOfWicket = null;
                                bm.runs = bm.runs - undoRun;
                                bm.balls = bm.balls - 1;
                            }
                            else {
                                bm.striker = false;
                            }
                        })

                        if (BYE || LB) {

                            // decrease ball
                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {
                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })


                            // decrease global ball also
                            d107CricketScoreCard.secondInningScoreCard.ballNo = ballNo - 1;

                        }
                        else if (NB || WD) {
                            // no changes in balls
                        }
                    }

                }
                console.log(d107CricketScoreCard);
                this.setState({ d107CricketScoreCard: d107CricketScoreCard, secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard })
            }
            else {

                if (d107CricketScoreCard.secondInningScoreCard.overs[0].overNo > 1) {
                    d107CricketScoreCard.secondInningScoreCard.overs.pop();
                    d107CricketScoreCard.secondInningScoreCard.bowlers.pop();
                    d107CricketScoreCard.secondInningScoreCard.overCompleted = d107CricketScoreCard.secondInningScoreCard.overCompleted - 1;
                    d107CricketScoreCard.secondInningScoreCard.currentOver = d107CricketScoreCard.secondInningScoreCard.currentOver - 1;

                }
                currOver = d107CricketScoreCard.secondInningScoreCard.currentOver;
                currentOver = d107CricketScoreCard.secondInningScoreCard.currentOver
                overList = d107CricketScoreCard.secondInningScoreCard.overs;
                bowlerList = d107CricketScoreCard.secondInningScoreCard.bowlers;
                currentOverObj = overList.find(over => {
                    if (over.overNo == currOver) {
                        return over;
                    }
                })
                currentBowllerObj = bowlerList.find(bowler => {
                    if (bowler.bowlerId == currentOverObj.bowlerID) {
                        return bowler;
                    }
                })

                console.log(d107CricketScoreCard)

                let holdingLastballIndex = currentOverObj.balls.length - 1
                let getLastBallObjfromBallsArray = currentOverObj.balls[currentOverObj.balls.length - 1];
                undoBatsmanId = getLastBallObjfromBallsArray.playedBy.batsmanId;
                undoRun = getLastBallObjfromBallsArray.playedBy.run;
                undoTotalRun = getLastBallObjfromBallsArray.total;
                currentBowllerId = currentBowllerObj.bowlerId;
                if (!getLastBallObjfromBallsArray.extras) {
                    ballType = 'real';
                }
                if (ballType == 'real') {

                    if (undoRun % 2 == 1) {
                        if (undoRun == undoTotalRun) {
                            d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == undoBatsmanId) {
                                    bm.runs = bm.runs - undoRun;
                                    bm.balls = bm.balls - 1;

                                    bm.striker = true;
                                }
                                else {
                                    bm.striker = false;
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {

                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {

                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                            console.log(bow)

                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {

                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })
                        }


                    }
                    if (undoRun % 2 == 0) {
                        if (undoRun == undoTotalRun) {
                            d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                                if (bm.batsmanId == undoBatsmanId) {
                                    bm.runs = bm.runs - undoRun;
                                    bm.balls = bm.balls - 1;
                                    bm.striker = true;
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {

                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })
                        }
                    }
                    // decreese first inningTotal ballNo

                    d107CricketScoreCard.secondInningScoreCard.ballNo = 5;


                    let currentOverIndex = currentOver - 1;
                    // decreese first inningTotal Run
                    d107CricketScoreCard.secondInningScoreCard.totalRun = d107CricketScoreCard.secondInningScoreCard.totalRun - undoTotalRun;
                    // pop ball object
                    if (d107CricketScoreCard.secondInningScoreCard.ballNo !== 0) {
                        d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();
                    }


                    if (d107CricketScoreCard.secondInningScoreCard.ballNo == 0) {
                        //decrease current over and complete over
                        d107CricketScoreCard.secondInningScoreCard.currentOver = currentOver - 1;
                        d107CricketScoreCard.secondInningScoreCard.overCompleted = d107CricketScoreCard.secondInningScoreCard.overCompleted - 1;

                        //delete over during ball == 0

                        d107CricketScoreCard.secondInningScoreCard.overs.pop();
                        d107CricketScoreCard.secondInningScoreCard.ballNo = 6;

                    }

                    //  d107CricketScoreCard.secondInningScoreCard.bowlers
                    //console.log(d107CricketScoreCard);
                }
                // with extra thing like. NB,OUT,LB,BYE,WIDE
                else {

                    // console.log(getLastBallObjfromBallsArray)
                    let currentOverIndex = currentOver - 1;
                    let WD = false; let NB = false; let BYE = false; let LB = false; let OUT = false;
                    let lastOutbatsmanId;
                    let isStrikeTrue = false;
                    let onlyOut = false;
                    getLastBallObjfromBallsArray.extras.map((extra) => {
                        if (extra.type === 'OUT') {
                            OUT = true;
                            lastOutbatsmanId = getLastBallObjfromBallsArray.playedBy.lastBatsmanId;
                        }
                        if (extra.type === "WD") {
                            WD = true;
                        }
                        if (extra.type === "NB") {
                            NB = true;
                        }
                        if (extra.type === "BYE") {
                            BYE = true;
                        }
                        if (extra.type === "LB") {
                            LB = true;
                        }
                        if (d107CricketScoreCard.secondInningScoreCard.ballNo !== 0) {
                            d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();
                        }
                        d107CricketScoreCard.secondInningScoreCard.totalRun = d107CricketScoreCard.secondInningScoreCard.totalRun - undoTotalRun;
                    })

                    if (OUT) {
                        if (WD === true || NB === true) {

                            let runs = undoTotalRun - 1;
                            if (runs % 2 == 1) {
                                isStrikeTrue = true
                            }

                        }
                        else {
                            onlyOut = true;
                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {
                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })

                            d107CricketScoreCard.secondInningScoreCard.ballNo = 6 - 1;
                        }

                        d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                            if (bm.batsmanId == lastOutbatsmanId) {
                                if (isStrikeTrue) {
                                    bm.striker = true;
                                }
                                if (onlyOut) {
                                    bm.striker = true;
                                }

                                bm.fallOfWicket = null;
                                bm.runs = bm.runs - undoRun;
                            }
                            else {
                                bm.striker = false;
                            }
                        })
                        d107CricketScoreCard.secondInningScoreCard.batsmen.pop();
                        d107CricketScoreCard.secondInningScoreCard.wicketFall = d107CricketScoreCard.secondInningScoreCard.wicketFall - 1;
                    }
                    if (NB || WD || BYE || LB) {

                        let runs = undoTotalRun - 1;
                        if (runs % 2 == 1) {
                            isStrikeTrue = true
                        }
                        d107CricketScoreCard.secondInningScoreCard.batsmen.map(bm => {
                            if (bm.batsmanId == lastOutbatsmanId) {
                                if (isStrikeTrue) {
                                    bm.striker = true;
                                }
                                if (onlyOut) {
                                    bm.striker = true;
                                }

                                bm.fallOfWicket = null;
                                bm.runs = bm.runs - undoRun;
                            }
                            else {
                                bm.striker = false;
                            }
                        })
                        if (BYE || LB) {

                            // decrease ball
                            d107CricketScoreCard.secondInningScoreCard.bowlers.map(bow => {
                                if (bow.bowlerId == currentBowllerId) {
                                    if (bow.balls !== 0) {
                                        bow.runs = bow.runs - undoTotalRun;
                                        if (bow.balls == 0) {

                                        }
                                        else {
                                            bow.balls = bow.balls - 1;
                                        }
                                    }
                                    //change current bowler to previous bowler of his last ball
                                    else {
                                        bow.balls = 5;
                                        bow.completedOver = bow.completedOver - 1;
                                    }
                                }
                            })

                        }
                        else if (NB || WD) {

                            d107CricketScoreCard.secondInningScoreCard.overs[currentOverIndex].balls.pop();

                        }

                    }

                }
                console.log(d107CricketScoreCard);
                this.setState({ d107CricketScoreCard: d107CricketScoreCard, secondInningScoreCard: d107CricketScoreCard.secondInningScoreCard })
            }
        }
        let score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107CricketScoreCard: d107CricketScoreCard,
        }
        UpdateTournamentsScore(score);
    }

    render() {
        const backLinks = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
        var over = '', overBall = '';
        var currentBowler = {};
        var last_over = null;

        if (this.state.d107CricketScoreCard) {
            // 1st Inn
            if (this.state.d107CricketScoreCard.currentInning === 1) {

                if (this.state.firstInningScoreCard !== null) {
                    over = d107CricketScoreCard.firstInningScoreCard.overCompleted;

                    overBall = d107CricketScoreCard.firstInningScoreCard.ballNo;
                    if (overBall == 6) {
                        over = d107CricketScoreCard.firstInningScoreCard.currentOver;
                        overBall = 0;
                    }

                }
                if (this.state.firstInningScoreCard.batsmen !== null) {
                    var firstInnBatsmen = d107CricketScoreCard.firstInningScoreCard.batsmen;
                    var scoreDetail = firstInnBatsmen.map(f => {
                        var displayData = false, striker = null, batsmanName = '', runs = '', balls = '', fours = '', sixes = '';
                        if (f.fallOfWicket === null) {
                            displayData = true;
                            batsmanName = f.batsmanName;
                            runs = f.runs;
                            balls = f.balls;
                            fours = f.boundries;
                            sixes = f.sixes;
                            striker = f.striker;
                        }
                        if (displayData) {
                            if (striker) {
                                return <tr key={0}>
                                    <td>{batsmanName}*</td>
                                    <td>{runs}</td>
                                    <td>{balls}</td>
                                    <td>{fours}</td>
                                    <td>{sixes}</td>
                                </tr>
                            }
                            if (!striker) {
                                return <tr key={1}>
                                    <td>{batsmanName}</td>
                                    <td>{runs}</td>
                                    <td>{balls}</td>
                                    <td>{fours}</td>
                                    <td>{sixes}</td>
                                </tr>
                            }
                        }
                    })
                }
                if (this.state.firstInningScoreCard.overs !== null && this.state.firstInningScoreCard.bowlers !== null) {

                    currOver = d107CricketScoreCard.firstInningScoreCard.currentOver;
                    overList = d107CricketScoreCard.firstInningScoreCard.overs;
                    bowlerList = d107CricketScoreCard.firstInningScoreCard.bowlers;

                    if (overList) {
                        last_over = overList[overList.length - 1];

                    }

                    console.log(currentOverObj)
                    currentOverObj = overList.find(over => {
                        if (over.overNo == currOver) {
                            return over;
                        }
                    })
                    if (currentOverObj) {
                        if (Object.keys(currentOverObj).length) {
                            currentBowllerObj = bowlerList.find(bowler => {
                                if (bowler.bowlerId == currentOverObj.bowlerID) {
                                    return bowler;
                                }
                            })
                            if (currentOverObj.balls !== null) {
                                inThisOver = currentOverObj.balls;
                                //console.log(inThisOver)
                            }
                            if (this.state.firstInningScoreCard.bowlers !== null) {
                                currentBowler = currentBowllerObj;

                            }
                        }
                    }
                }
            }

            //2nd Inn
            if (this.state.d107CricketScoreCard.currentInning === 2) {
                if (this.state.secondInningScoreCard !== null) {
                    over = this.state.secondInningScoreCard.overCompleted;
                    overBall = this.state.secondInningScoreCard.ballNo;

                    if (overBall == 6) {
                        over = d107CricketScoreCard.secondInningScoreCard.currentOver;
                        overBall = 0;
                    }
                }
                if (this.state.secondInningScoreCard.batsmen !== null) {
                    var secondInnBatsmen = this.state.secondInningScoreCard.batsmen;
                    var scoreDetails = secondInnBatsmen.map(f => {
                        var displayData = false, striker = null, batsmanName = '', runs = '', balls = '', fours = '', sixes = '';
                        if (f.fallOfWicket === null) {
                            displayData = true;
                            batsmanName = f.batsmanName;
                            runs = f.runs;
                            balls = f.balls;
                            fours = f.boundries;
                            sixes = f.sixes;
                            striker = f.striker;
                        }
                        if (displayData) {
                            if (striker) {
                                return <tr key={0}>
                                    <td>{batsmanName}*</td>
                                    <td>{runs}</td>
                                    <td>{balls}</td>
                                    <td>{fours}</td>
                                    <td>{sixes}</td>
                                </tr>
                            }
                            if (!striker) {
                                return <tr key={1}>
                                    <td>{batsmanName}</td>
                                    <td>{runs}</td>
                                    <td>{balls}</td>
                                    <td>{fours}</td>
                                    <td>{sixes}</td>
                                </tr>
                            }
                        }
                    })
                }
                if (this.state.secondInningScoreCard.overs !== null && this.state.secondInningScoreCard.bowlers !== null) {
                    currOver = this.state.secondInningScoreCard.currentOver;
                    overList = this.state.secondInningScoreCard.overs;
                    bowlerList = this.state.secondInningScoreCard.bowlers;

                    if (overList) {
                        last_over = overList[overList.length - 1];
                    }

                    console.log(currentOverObj)
                    currentOverObj = overList.find(over => {
                        if (over.overNo == currOver) {
                            return over;
                        }
                    })

                    if (currentOverObj) {
                        if (Object.keys(currentOverObj).length) {
                            currentBowllerObj = bowlerList.find(bowler => {
                                if (bowler.bowlerId == currentOverObj.bowlerID) {
                                    return bowler;
                                }
                            })
                            if (currentOverObj.balls !== null) {
                                inThisOver = currentOverObj.balls;
                                //console.log(inThisOver)
                            }
                            if (this.state.secondInningScoreCard.bowlers !== null) {
                                currentBowler = currentBowllerObj;

                            }
                        }
                    }
                }
            }
        }


        return (
            <React.Fragment>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")",
                        }}>
                            <span>{this.props.tournament.tournamentTime}</span>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                        <div className="btn-pref btn-group btn-group-justified btn-group-lg" role="group" aria-label="...">
                            <div className="btn-group" role="group">
                                <button onClick={() => this.operation()} type="button" id="stars" className="btn btn-default tabsbtn-d" href="#tab1" data-toggle="tab">
                                    <div>Manage Innings</div>
                                </button>
                            </div>
                            <div className="btn-group" role="group">
                                <button onClick={() => this.operation1()} type="button" id="favorites" className="btn btn-primary tabsbtn-d" href="#tab2" data-toggle="tab">
                                    <div>Score Board</div>
                                </button>
                            </div>
                        </div>
                        {this.state.showMe ? <div className="well well-d">
                            <div className="tab-content">
                                <div className="scorebox-d">
                                    {this.state.currentInning === 1 ? <span className="teamname-d">{this.state.byTeamName}</span> : <span className="teamname-d">{this.state.toTeamName}</span>}
                                    <span className="teamscore-d">Score</span>
                                    <div className=".inningsscore-d">
                                        {this.state.currentInning === 1 ? <label className="innings-d">1st Inning</label> : this.state.currentInning === 2 ? <label className="innings-d">2nd Inning</label> : ''}
                                        {this.state.currentInning === 1 ? <label className="score-d">{this.state.firstInningScoreCard !== null ? this.state.firstInningScoreCard.totalRun : ''}/{this.state.firstInningScoreCard !== null ? this.state.firstInningScoreCard.wicketFall : ''} ({over}.{overBall})</label> : <label className="score-d">{this.state.secondInningScoreCard !== null ? this.state.secondInningScoreCard.totalRun : ''}/{this.state.secondInningScoreCard !== null ? this.state.secondInningScoreCard.wicketFall : ''} ({over}.{overBall})</label>}
                                    </div>
                                </div>
                                <div className="rundiv-d">
                                    <label>Run This Over</label>
                                    <div className="allballsover">
                                        {this.state.showHideCurrOver ?

                                            inThisOver.map(c => {
                                                var type = '', r = null;
                                                if (c.extras !== null) {
                                                    if (c.extras.length === 2) {
                                                        c.extras.map(e => {
                                                            type += e.type;
                                                            r = e.run;
                                                        })
                                                        if (r === 0) {
                                                            return <span key={c.strokeNo} className="runball-d">{c.playedBy.run}<label className="thisOver">{type}</label></span>
                                                        }
                                                        else {
                                                            return <span key={c.strokeNo} className="runball-d">{c.playedBy.run}<label className="thisOver">{r}{type}</label></span>
                                                        }
                                                    }
                                                    else {
                                                        c.extras.map(e => {
                                                            type = e.type;
                                                            r = e.run;
                                                        })
                                                        if (r > 0) {
                                                            if (type === "OUT") {
                                                                return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver">{type}</label></span>
                                                            }
                                                            else {
                                                                if (type === "LB") {
                                                                    return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver">{r}LB</label></span>
                                                                }
                                                                else if (type === "NB") {
                                                                    return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver">NB</label></span>
                                                                }
                                                                else {
                                                                    return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver">{r}{type}</label></span>
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver">{type}</label></span>
                                                        }
                                                    }
                                                }
                                                else {
                                                    return <span key={c.strokeNo} className="runball-d">{c.playedBy.run} <label className="thisOver"></label></span>
                                                }
                                            })

                                            : null}

                                    </div>

                                </div>
                                <div className="scoreinput-d">
                                    <div className="commaninputdiv createredio marg-d">
                                        <div className="styled-input-single-d createinput">
                                            <input type="checkbox" name="Wide" id="wide" value="wide" onChange={this.signal.bind(this)} />
                                            <label htmlFor="wide">Wide</label>
                                        </div>
                                        <div className="styled-input-single-d createinput">
                                            <input type="checkbox" name="NB" id="nb" value="nb" onChange={this.signal.bind(this)} />
                                            <label htmlFor="nb">NB</label>
                                        </div>
                                        <div className="styled-input-single-d createinput">
                                            <input type="checkbox" name="Byes" id="byes" value="byes" onChange={this.signal.bind(this)} />
                                            <label htmlFor="byes">Byes</label>
                                        </div>
                                        <div className="styled-input-single-d createinput">
                                            <input type="checkbox" name="LB" id="lb" value="lb" onChange={this.signal.bind(this)} />
                                            <label htmlFor="lb">LB</label>
                                        </div>
                                        <div className="styled-input-single-d createinput">
                                            <input type="checkbox" name="Wicket" id="wicket" value="wicket" onChange={this.signal.bind(this)} />
                                            <label htmlFor="wicket">Wicket</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 btnbatsman-d">
                                        <button onClick={() => this.swapBatsman()} type="button" className="swapbtn-d">Swap Batsman</button>
                                        {/* <button onClick={() => this.undoRuns()} type="button" className="undobtn-d">Undo</button> */}
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 btnrun-d">
                                        <a title=""> <span onClick={() => this.makeRuns('0')} className="runinput-d">0</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('1')} className="runinput-d">1</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('2')} className="runinput-d">2</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('3')} className="runinput-d">3</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('4')} className="runinput-d">4</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('5')} className="runinput-d">5</span></a>
                                        <a title=""> <span onClick={() => this.makeRuns('6')} className="runinput-d">6</span></a>
                                    </div>
                                </div>
                            </div>

                            <div className="tableresponsive tablepad-d">
                                <div className="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Batsman</th>
                                                <th>R</th>
                                                <th>B</th>
                                                <th>4s</th>
                                                <th>6s</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.currentInning === 1 ? scoreDetail : scoreDetails}

                                            <tr>
                                                <th>Bowler</th>
                                                <th>O</th>
                                                <th>M</th>
                                                <th>R</th>
                                                <th>W</th>
                                            </tr>

                                            <tr>
                                                <td>{currentBowler.bowlerName}</td>
                                                <td>{currentBowler.completedOver}.{currentBowler.balls}</td>
                                                <td>0</td>
                                                <td>{currentBowler.runs}</td>
                                                <td>{currentBowler.wickets}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> : ""}

                        {this.state.showMe1 ? <div className="tab-pane fade in">
                            <div className="well welltab2-d">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                                <ul className="team_match team_match_acc">
                                                    <li>
                                                        <div className="scoreflag_div">
                                                            <span className="score_flagimg" style={{
                                                                backgroundImage: "url(" + logo + ")",
                                                            }}></span>
                                                            {this.state.currentInning === 1 ? <span className="score_flagtext">{this.state.byTeamName}</span> : <span className="score_flagtext">{this.state.byTeamName}</span>}
                                                        </div>
                                                        {this.state.firstInningScoreCard !== null ? <label className="teamscore">{this.state.firstInningScoreCard.totalRun}/{this.state.firstInningScoreCard.wicketFall} ({this.state.firstInningScoreCard.overCompleted}.{this.state.firstInningScoreCard.ballNo}) ({this.state.originalOvers})</label> : ''}
                                                    </li>
                                                </ul>
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse1" className="panel-collapse collapse in">
                                        <div className="panelnew">
                                            <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Batsman</th>
                                                            <th></th>
                                                            <th>R</th>
                                                            <th>B</th>
                                                            <th>4s</th>
                                                            <th>6s</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.firstInningScoreCard !== null ?
                                                            this.state.firstInningScoreCard.batsmen.map(fi => {
                                                                if (fi.fallOfWicket !== null) {
                                                                    return <tr>
                                                                        <td>{fi.batsmanName}</td>
                                                                        <td>({fi.fallOfWicket})</td>
                                                                        <td>{fi.runs}</td>
                                                                        <td>{fi.balls}</td>
                                                                        <td>{fi.boundries}</td>
                                                                        <td>{fi.sixes}</td>
                                                                    </tr>
                                                                }
                                                                else {
                                                                    return <tr>
                                                                        <td>{fi.batsmanName}</td>
                                                                        <td>(not out)</td>
                                                                        <td>{fi.runs}</td>
                                                                        <td>{fi.balls}</td>
                                                                        <td>{fi.boundries}</td>
                                                                        <td>{fi.sixes}</td>
                                                                    </tr>
                                                                }
                                                            })
                                                            : ''
                                                        }
                                                    </tbody>
                                                </table>
                                                {/* <div className="matchtotle">
                                                    <ul>
                                                        <li><b>Extra</b> <label>2 (b 0, w 2, nb 0, lb 0)</label></li>
                                                        <li><b>Total</b> <label>94/10 (15.3)</label></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Bowler</th>
                                                            <th>O</th>
                                                            <th>M</th>
                                                            <th>R</th>
                                                            <th>W</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.firstInningScoreCard !== null ? this.state.firstInningScoreCard.bowlers.map(fs => {
                                                            return <tr>
                                                                <td>{fs.bowlerName}</td>
                                                                <td>{fs.completedOver}.{fs.balls}</td>
                                                                <td>0</td>
                                                                <td>{fs.runs}</td>
                                                                <td>{fs.wickets}</td>
                                                            </tr>
                                                        }) : ''
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Fall Of Wickets</th>
                                                            <th>Score</th>
                                                            <th>Over </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Tobias Visee (WK)</td>
                                                            <td>21</td>
                                                            <td>3.1</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                                <ul className="team_match team_match_acc">
                                                    <li>
                                                        <div className="scoreflag_div">
                                                            <span className="score_flagimg" style={{
                                                                backgroundImage: "url(" + logo + ")",
                                                            }}></span>
                                                            {this.state.currentInning === 2 ? <span className="score_flagtext">{this.state.toTeamName}</span> : <span className="score_flagtext">{this.state.toTeamName}</span>}
                                                        </div>
                                                        {(this.state.secondInningScoreCard !== null) ? <label className="teamscore">{this.state.secondInningScoreCard.totalRun}/{this.state.secondInningScoreCard.wicketFall} ({this.state.secondInningScoreCard.overCompleted}.{this.state.secondInningScoreCard.ballNo}) ({this.state.originalOvers})</label> : ''}
                                                    </li>
                                                </ul>
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse2" className="panel-collapse collapse in">
                                        <div className="panelnew">
                                            <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Batsman</th>
                                                            <th></th>
                                                            <th>R</th>
                                                            <th>B</th>
                                                            <th>4s</th>
                                                            <th>6s</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.secondInningScoreCard !== null) ?
                                                            this.state.secondInningScoreCard.batsmen.map(si => {
                                                                if (si.fallOfWicket !== null) {
                                                                    return <tr>
                                                                        <td>{si.batsmanName}</td>
                                                                        <td>({si.fallOfWicket})</td>
                                                                        <td>{si.runs}</td>
                                                                        <td>{si.balls}</td>
                                                                        <td>{si.boundries}</td>
                                                                        <td>{si.sixes}</td>
                                                                    </tr>
                                                                }
                                                                else {
                                                                    return <tr>
                                                                        <td>{si.batsmanName}</td>
                                                                        <td>(not out)</td>
                                                                        <td>{si.runs}</td>
                                                                        <td>{si.balls}</td>
                                                                        <td>{si.boundries}</td>
                                                                        <td>{si.sixes}</td>
                                                                    </tr>
                                                                }
                                                            })
                                                            : ''}
                                                    </tbody>
                                                </table>
                                                {/* <div className="matchtotle">
                                                    <ul>
                                                        <li><b>Extra</b> <label>2 (b 0, w 2, nb 0, lb 0)</label></li>
                                                        <li><b>Total</b> <label>94/10 (15.3)</label></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Bowler</th>
                                                            <th>O</th>
                                                            <th>M</th>
                                                            <th>R</th>
                                                            <th>W</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.secondInningScoreCard !== null) ? this.state.secondInningScoreCard.bowlers.map(ss => {
                                                            return <tr>
                                                                <td>{ss.bowlerName}</td>
                                                                <td>{ss.completedOver}.{ss.balls}</td>
                                                                <td>0</td>
                                                                <td>{ss.runs}</td>
                                                                <td>{ss.wickets}</td>
                                                            </tr>
                                                        }) : ''}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="table-responsive scoretable">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Fall Of Wickets</th>
                                                            <th>Score</th>
                                                            <th>Over </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Tobias Visee (WK)</td>
                                                            <td>21</td>
                                                            <td>3.1</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}

                        <div className="table_actions winner_actions">
                            <a href={backLinks} className="cmnbutton backbtn fl">Back</a>
                            <a className="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal">End Event</a>
                        </div>

                    </div>
                </section>

                {/* next innings */}
                <Modal
                    size="md"
                    show={this.state.showingNextInningsModal}
                    animation={false}
                    onHide={() => this.closeModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeModal()}>&times;</button>
                        <Modal.Title>
                            First Inning Score
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <p>{this.state.byTeamName} {this.state.firstInningScoreCard ? this.state.firstInningScoreCard.totalRun + '/' + this.state.firstInningScoreCard.wicketFall : ''}</p>
                            <p>{this.state.toTeamName} is required {this.state.firstInningScoreCard ? this.state.firstInningScoreCard.totalRun + 1 : ''} runs to win this match.</p>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.closeModal()} type="button" className="cmnbutton">Cancel</button>
                            <button onClick={() => this.secondInnings()} type="button" className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>

                {/* new bowler 1st Inngs + 2nd Inngs*/}
                <Modal
                    size="md"
                    show={this.state.showingModal}
                    animation={false}
                    onHide={() => this.closeModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeModal()}>&times;</button>
                        <Modal.Title>
                            Choose Bowler
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <p>Bowler</p>
                            <label htmlFor="bowlls"></label>
                            <select name="bowlerss" id="bowlls" onChange={(e) => this.chooseNextBowler(e)}>
                                {teamMembersBolfirst.map(k => {
                                    var bol1st = false, bolIDs = '', bolNames = '';
                                    this.props.profileUsers.map(j => {
                                        if (j.contactNo === k) {
                                            bol1st = true;
                                            bolIDs = j.contactNo;
                                            bolNames = j.name;
                                        }
                                    })
                                    if (bol1st) {
                                        if (overList) {
                                            if (last_over) {
                                                if (bolIDs !== last_over.bowlerID) {
                                                    return <option value={bolIDs}>{bolNames}</option>
                                                }
                                            }
                                        }
                                    }
                                })}
                            </select>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="cmnbutton" onClick={() => this.bowlerAfterOver()}>Done</button>
                        </div>
                    </div>
                </Modal>

                {/* wicket fell modal (only in case of wickets)*/}
                <Modal
                    size="md"
                    show={this.state.showingWicketModal}
                    animation={false}
                    onHide={() => this.closingModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closingModal()}>&times;</button>
                        <Modal.Title>
                            Fall Of Wicket
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <label className="fallwickt">Wickets</label>
                            <p>How Wicket Fell</p>
                            <label htmlFor="wik"></label>
                            <select name="wikkket" id="wik" onChange={(e) => this.selectwicketType(e)}>
                                <option value="bowled">bowled</option>
                                <option value="catchout">catch out</option>
                                <option value="lbw">LBW</option>
                                <option value="hitwicket">hit wicket</option>
                                <option value="stumping">stumping</option>
                                <option value="runouts">run out striker</option>
                                <option value="runoutns">run out non striker</option>
                            </select>
                        </div>

                        <div style={{ display: 'none' }} id="helperShow" className="helper">
                            <label className="helpman">Helper</label>
                            <p>Who Helped</p>
                            <label htmlFor="help"></label>
                            <select name="helper" id="help" onChange={(e) => this.selectHelper(e)}>
                                {
                                    teamMembersBolfirst.map(k => {
                                        var bol1st = false, bolIds = '', bolNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bol1st = true;
                                                bolIds = j.contactNo;
                                                bolNames = j.name;
                                            }
                                        })
                                        if (bol1st) {
                                            return <option value={bolIds}>{bolNames}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="helper">
                            <label className="helpman">Batsman</label>
                            <p>New Batsman</p>
                            <label htmlFor="newBat"></label>
                            <select name="newBattssman" id="newBat" onChange={(e) => this.selectNewBatsmen(e)}>
                                {
                                    teamMembersBatfirst.map(k => {
                                        var bat1st = false, batIDs = '', batNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bat1st = true;
                                                batIDs = j.contactNo;
                                                batNames = j.name;
                                            }
                                        })
                                        if (d107CricketScoreCard.currentInning === 1) {
                                            if (d107CricketScoreCard.firstInningScoreCard.batsmen) {
                                                var valCheck = d107CricketScoreCard.firstInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                            }
                                        }
                                        else {
                                            if (d107CricketScoreCard.secondInningScoreCard) {
                                                if (d107CricketScoreCard.secondInningScoreCard.batsmen) {
                                                    var valCheck = d107CricketScoreCard.secondInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                                }
                                            }
                                        }


                                        if (bat1st) {
                                            if (valCheck) {
                                                {/* return <option disabled>{batNames}</option> */ }
                                            }
                                            else {
                                                return <option value={batIDs}>{batNames}</option>
                                            }
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="modal-footer">
                            <button onClick={() => this.fallOfWicket()} type="button" className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>
                {/* modal wicket part 2 */}
                <Modal
                    size="md"
                    show={this.state.showingWicketModal2}
                    animation={false}
                    onHide={() => this.closingModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closingModal()}>&times;</button>
                        <Modal.Title>
                            Fall Of Wicket
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <label className="fallwickt">Wickets</label>
                            <p>How Wicket Fell</p>
                            <label htmlFor="wik"></label>
                            <select name="wikkket" id="wik" onChange={(e) => this.selectwicketType(e)}>
                                <option value="stumping">stumping</option>
                                <option value="runouts">run out striker</option>
                                <option value="runoutns">run out non striker</option>
                            </select>
                        </div>

                        <div id="helperShow" className="helper">
                            <label className="helpman">Helper</label>
                            <p>Who Helped</p>
                            <label htmlFor="help"></label>
                            <select name="helper" id="help" onChange={(e) => this.selectHelper(e)}>
                                {
                                    teamMembersBolfirst.map(k => {
                                        var bol1st = false, bolIds = '', bolNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bol1st = true;
                                                bolIds = j.contactNo;
                                                bolNames = j.name;
                                            }
                                        })
                                        if (bol1st) {
                                            return <option value={bolIds}>{bolNames}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="helper">
                            <label className="helpman">Batsman</label>
                            <p>New Batsman</p>
                            <label htmlFor="newBat"></label>
                            <select name="newBattssman" id="newBat" onChange={(e) => this.selectNewBatsmen(e)}>
                                {
                                    teamMembersBatfirst.map(k => {
                                        var bat1st = false, batIDs = '', batNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bat1st = true;
                                                batIDs = j.contactNo;
                                                batNames = j.name;
                                            }
                                        })
                                        if (d107CricketScoreCard.currentInning === 1) {
                                            if (d107CricketScoreCard.firstInningScoreCard.batsmen) {
                                                var valCheck = d107CricketScoreCard.firstInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                            }
                                        }
                                        else {
                                            if (d107CricketScoreCard.secondInningScoreCard) {
                                                if (d107CricketScoreCard.secondInningScoreCard.batsmen) {
                                                    var valCheck = d107CricketScoreCard.secondInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                                }
                                            }
                                        }

                                        if (bat1st) {
                                            if (valCheck) {
                                                {/* return <option disabled>{batNames}</option> */ }
                                            }
                                            else {
                                                return <option value={batIDs}>{batNames}</option>
                                            }
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="modal-footer">
                            <button onClick={() => this.fallOfWicket()} type="button" className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>
                {/* modal wicket part 3 */}
                <Modal
                    size="md"
                    show={this.state.showingWicketModal3}
                    animation={false}
                    onHide={() => this.closingModal()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closingModal()}>&times;</button>
                        <Modal.Title>
                            Fall Of Wicket
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <label className="fallwickt">Wickets</label>
                            <p>How Wicket Fell</p>
                            <label htmlFor="wik"></label>
                            <select name="wikkket" id="wik" onChange={(e) => this.selectwicketType(e)}>
                                <option value="runouts">run out striker</option>
                                <option value="runoutns">run out non striker</option>
                            </select>
                        </div>

                        <div id="helperShow" className="helper">
                            <label className="helpman">Helper</label>
                            <p>Who Helped</p>
                            <label htmlFor="help"></label>
                            <select name="helper" id="help" onChange={(e) => this.selectHelper(e)}>
                                {
                                    teamMembersBolfirst.map(k => {
                                        var bol1st = false, bolIds = '', bolNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bol1st = true;
                                                bolIds = j.contactNo;
                                                bolNames = j.name;
                                            }
                                        })
                                        if (bol1st) {
                                            return <option value={bolIds}>{bolNames}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="helper">
                            <label className="helpman">Batsman</label>
                            <p>New Batsman</p>
                            <label htmlFor="newBat"></label>
                            <select name="newBattssman" id="newBat" onChange={(e) => this.selectNewBatsmen(e)}>
                                {
                                    teamMembersBatfirst.map(k => {
                                        var bat1st = false, batIDs = '', batNames = '';
                                        this.props.profileUsers.map(j => {
                                            if (j.contactNo === k) {
                                                bat1st = true;
                                                batIDs = j.contactNo;
                                                batNames = j.name;
                                            }
                                        })
                                        if (d107CricketScoreCard.currentInning === 1) {
                                            if (d107CricketScoreCard.firstInningScoreCard.batsmen) {
                                                var valCheck = d107CricketScoreCard.firstInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                            }
                                        }
                                        else {
                                            if (d107CricketScoreCard.secondInningScoreCard) {
                                                if (d107CricketScoreCard.secondInningScoreCard.batsmen) {
                                                    var valCheck = d107CricketScoreCard.secondInningScoreCard.batsmen.some(person => person.batsmanId === k);
                                                }
                                            }
                                        }

                                        if (bat1st) {
                                            if (valCheck) {
                                                {/* return <option disabled>{batNames}</option> */ }
                                            }
                                            else {
                                                return <option value={batIDs}>{batNames}</option>
                                            }
                                        }
                                    })
                                }
                            </select>
                        </div>

                        <div className="modal-footer">
                            <button onClick={() => this.fallOfWicket()} type="button" className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>

                {/* opening selection */}
                <Modal
                    size="md"
                    show={this.state.showingModal0}
                    animation={false}
                    onHide={() => this.closingModal0()}
                >
                    <Modal.Header>
                        <button type="button" className="close" onClick={() => this.closingModal0()}>&times;</button>
                        <Modal.Title>
                            Select Opening Players
                        </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body">
                        <div className="wicketfallof">
                            <label className="fallwickt">Batting</label>
                            <p>Striker</p>
                            <label htmlFor="s"></label>
                            <select style={{ 'margin-bottom': '20px' }} name="striker" id="s" onChange={(e) => this.selectStrikerNon(e)}>
                                {teamMembersBatfirst.map(k => {
                                    var bat1st = false, batIDs = '', batNames = '';
                                    this.props.profileUsers.map(j => {
                                        if (j.contactNo === k) {
                                            bat1st = true;
                                            batIDs = j.contactNo;
                                            batNames = j.name;
                                        }
                                    })
                                    if (bat1st) {
                                        return <option value={batIDs}>{batNames}</option>
                                    }
                                })}
                            </select>

                            <p>Non Striker</p>
                            <label htmlFor="ns"></label>
                            <select name="nonstriker" id="ns" onChange={(e) => this.selectStrikerNon(e)} >
                                {teamMembersBatfirst.map(k => {
                                    var bat1st = false, batIDs = '', batNames = '';
                                    this.props.profileUsers.map(j => {
                                        if (j.contactNo === k) {
                                            bat1st = true;
                                            batIDs = j.contactNo;
                                            batNames = j.name;
                                        }
                                    })
                                    if (bat1st) {
                                        return <option value={batIDs}>{batNames}</option>
                                    }
                                })}
                            </select>
                        </div>

                        <br />

                        <div className="helper">
                            <label className="helpman">Bowling</label>
                            <p>Opening Bowler</p>
                            <label htmlFor="bwl"></label>
                            <select name="bowler" id="bwl" onChange={(e) => this.selectCurrentBowler(e)}>
                                {teamMembersBolfirst.map(k => {
                                    var bol1st = false, bolIDs = '', bolNames = '';
                                    this.props.profileUsers.map(j => {
                                        if (j.contactNo === k) {
                                            bol1st = true;
                                            bolIDs = j.contactNo;
                                            bolNames = j.name;
                                        }
                                    })
                                    if (bol1st) {
                                        return <option value={bolIDs}>{bolNames}</option>
                                    }
                                })}
                            </select>
                        </div>

                        <div className="modal-footer">
                            <p id="delays" style={{ display: 'none' }} className={`alert alert-danger ${this.state.showingAlert1 ? 'alert-shown' : 'alert-hidden'}`}> <strong>Opening Batsman cannot be same</strong></p>
                            <button type="button" className="cmnbutton" onClick={this.openingSelection}>Done</button>
                        </div>
                    </div>
                </Modal>

                {/* choosing teams */}
                <Modal1
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Select team to bat first</h3></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-1" onClick={this.changeData.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byTeamName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="teamBatFirst" id="radio-example-2" onClick={this.changeData.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toTeamName}</label>
                        </li>
                    </ul>
                    <div className="button_div"> {this.state.tosses ? <button className="cmnbutton" type="button" onClick={this.selectBatFirstTeam}>Done</button> : null}<button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>
                </Modal1>

                {/* winner select modal */}
                <Modal1
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" onClick={this.closeModal} className="close" data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Match Result</h4>
                        </div>
                        <div className="modal-body">
                            <div className="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={logo} />
                                <label>Winner</label>
                            </div>

                            <ul className="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a className="winnerselect">
                                        <div className="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div className="tableresponsive">
                                            <div className="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        {d107CricketScoreCard ? d107CricketScoreCard.firstBattingTeam ? d107CricketScoreCard.firstBattingTeam === 'challengedBy' ? <>
                                                            <tr>
                                                                <td><img src={byPic} className="winnerimg_td" />{this.state.byTeamName}</td>

                                                                {this.state.byWinner ? <td><img src={logo} className="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                            </tr>
                                                            <tr>
                                                                <td><img src={toPic} className="winnerimg_td" />{this.state.toTeamName}</td>

                                                                {this.state.toWinner ? <td><img src={logo} className="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                            </tr>
                                                        </> : <>
                                                            <tr>
                                                                <td><img src={byPic} className="winnerimg_td" />{this.state.toTeamName}</td>

                                                                {this.state.byWinner ? <td><img src={logo} className="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                            </tr>
                                                            <tr>
                                                                <td><img src={toPic} className="winnerimg_td" />{this.state.byTeamName}</td>

                                                                {this.state.toWinner ? <td><img src={logo} className="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                            </tr>
                                                        </> : null : null}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="modal-footer table_actions">
                            {/* <a className="cmnbutton" onClick={() => this.undoRuns()}>Edit</a> */}
                            <button onClick={this.doneMatch} className="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal1>

                {/*open Over Modal*/}
                <Modal
                    size="sm"
                    show={this.state.openOverModal}
                    animation={false}
                    onHide={() => this.closeOverModal()}
                >
                    <Modal.Header>
                        <button type="button" className="close" onClick={() => this.closeOverModal()}>&times;</button>
                        <Modal.Title>
                            Select Overs
                        </Modal.Title>
                    </Modal.Header>
                    <div className="modal-body">

                        <NumericInput mobile min={1}
                            max={50}
                            name="overs"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Overs"
                            required
                            size={25}
                            value={5}
                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }}
                        />
                        <div className="modal-footer">
                            <p id="delays" style={{ display: 'none' }} className={`alert alert-danger ${this.state.showingAlert1 ? 'alert-shown' : 'alert-hidden'}`}> <strong>Opening Batsman cannot be same</strong></p>
                            <button type="button" className="cmnbutton" onClick={this.overSelection}>submit</button>
                        </div>
                    </div>
                </Modal>

                {/* tie winner select modal */}
                <Modal1
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Scores are tied please choose the winner!</h3></div>
                    <div style={{ display: 'none', textAlign: 'center' }} id="showTieError"> <h5>Please choose the winning team!</h5></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-1" onClick={this.changeDataa.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byTeamName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-2" onClick={this.changeDataa.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toTeamName}</label>
                        </li>
                    </ul>

                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.doneMatch}>Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>
                </Modal1>

            </React.Fragment>
        );
    }
}