import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import bannerimg from '../img/page/bannerimg.jpg';
import userIcon from '../img/team.png';
import camera from '../img/camera.png';
import more from '../img/more.png';
import Header from '../Header';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import CreatePost from '../CreatePost';
import CreatePageTeam from './Teams/CreatePageTeam';
import LoginFeedsPosts from '../LoginFeedsPosts';
import * as pageApi from '../ApiUtil';
import { array } from 'prop-types';
import PageLeftSection from './PageLeftSection';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

const user = JSON.parse(localStorage.getItem('result'));
export default class PageProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageData: null,
            sports: null,
            pageFollowers: [],
            pageTeams: [],
            pageBroadcasts: [],
            pagePosts: [],
            pageTournaments: [],
            pageChallenge: [],
            pageOpen: 'createPost',
            showProfileBanner: true,
            showCreateTeam: false,
            challengeUsers: [],
            showForCropImage: false,
            imageUrl: null,
            imageUrlforPage: null,
            setImageFor: null,
            showNextSpinner: false,
            src: null,
            crop: {
                unit: '100%',
                width: 50,
                height: 50,
                aspect: 16 / 9,
                x: 100,
                y: 100
            }
        }
        this.myRef = React.createRef()
        this.myInputFile = React.createRef();
        this.myCamFile = React.createRef();
    }
    componentDidMount() {

        const { match } = this.props;
        let pageId = match.params.pageId;
        this.getPageData(pageId);
    }
    componentDidUpdate() {
        this.handleScroll()
    }
    getPageData = (pageId) => {
        const apiUrl = rshApi + "pages/page-profile";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    pageId: pageId,
                    contactNo: user.userId
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result.data)
                // console.log(result.data.page.createdBy)
                that.setState({ pageData: result.data.page, sports: result.data.sports, pageFollowers: result.data.pageFollowers, pageBroadcasts: result.data.broadcasts, pageTeams: result.data.teams, pagePosts: result.data.posts, pageTournaments: result.data.tournaments, pageChallenge: result.data.challenge.challenges, createdByUserNo: result.data.page.createdBy, challengeUsers: result.data.challenge.users })
            }).catch(error => {

            });
    }
    setToggleForFollowers = () => {
        let pageData = this.state.pageData;
        console.log(pageData)



        if (pageData.followers) {
            if (pageData.followers.includes(user.contactNo)) {
                let pageId = pageData.id;
                pageData.followers = pageData.followers.filter(item => item !== user.contactNo)
                pageApi.pageToggleFollow(pageId, true).then(res => {
                    this.setState({ pageData: pageData }, () => {

                    });
                });
            }
            else {

                pageData.followers.push(user.contactNo)
                pageApi.pageToggleFollow(pageData.id, false).then(res => {
                    this.setState({ pageData: pageData }, () => {

                    });
                });
            }
        }
        else {
            pageData.followers = [user.contactNo];
            pageApi.pageToggleFollow(pageData.id, false).then(res => {
                this.setState({ pageData: pageData }, () => {
                });
            });
        }

    }
    setToggleForLikes = () => {
        let pageData = this.state.pageData;
        if (pageData.likes) {
            if (pageData.likes.includes(user.contactNo)) {
                let pageId = pageData.id;
                pageData.likes = pageData.likes.filter(item => item !== user.contactNo)
                pageApi.pageToggleLike(pageId, true).then(res => {
                    this.setState({ pageData: pageData }, () => {

                    });
                });
            }
            else {
                pageData.likes.push(user.contactNo)
                pageApi.pageToggleLike(pageData.id, false).then(res => {
                    this.setState({ pageData: pageData }, () => {

                    });
                });
            }
        }
        else {
            pageData.likes = [user.contactNo];
            pageApi.pageToggleLike(pageData.id, true).then(res => {
                this.setState({ pageData: pageData }, () => {

                });
            });
        }
    }

    static getDerivedStateFromProps(nextProps, state) {

        if (nextProps.match.path === '/page/team/create/:pageId') {
            return { pageOpen: 'createTeam', showProfileBanner: false, showCreateTeam: true };
        }
        if (nextProps.match.path === '/page/profile/:pageId') {
            return { pageOpen: 'createTeam', showProfileBanner: true };
        }
        else return null;
    }
    handleScroll = () => {
        const { index, selected } = this.props

        if (index === selected) {
            setTimeout(() => {
                this.myRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 1)
        }
    }
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });

    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );

        }

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL('image/jpeg', 1.0));
            if (this.state.setImageFor === 'timeline') {
                let that = this;
                this.setState({ imageUrl: canvas.toDataURL('image/jpeg', 1.0) }, () => {
                    that.uploadFiles('timeline').then(res => {
                        if (res.error === false) {
                            let iconUrl = res.data.downloadUrl;
                            that.finalPartEditPage(iconUrl, 'timeline');
                        }

                    })
                })
            }
            if (this.state.setImageFor === 'pageProfile') {
                let that = this;
                that.setState({ imageUrlforPage: canvas.toDataURL('image/jpeg', 1.0) }, () => {
                    that.uploadFiles('pageProfile').then(res => {
                        if (res.error === false) {
                            let iconUrl = res.data.downloadUrl;
                            that.finalPartEditPage(iconUrl, 'pageProfile');
                        }

                    })
                })

            }

        });
    }
    onImageChange = e => {
        this.setState({ showForCropImage: true, setImageFor: 'timeline' });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    onImageChangePageProfile = e => {
        this.setState({ showForCropImage: true, setImageFor: 'pageProfile' });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }

    }
    closeCropModel() {

        this.setState({ imageUrl: null, imageUrlforPage: null, showForCropImage: false });
    }

    saveCropSelection() {
        this.setState({ showForCropImage: false });
    }
    finalPartEditPage = (iconUrl, type) => {
        let icon = null;
        let timeline = null;
        if (type == 'timeline') {
            timeline = iconUrl;
        }
        else {
            icon = iconUrl;

        }
        const { match } = this.props;
        let pageId = match.params.pageId;
        const apiUrl = rshApi + "pages/edit-page";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "pageId": pageId,
                    "description": null,
                    "icon": icon,
                    "timeline": timeline,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

            }).catch(error => {

            });
    }

    uploadFiles = async (type) => {
        const apiUrl = rshApi + "upload-file";
        let dataurl = null;
        if (type === 'timeline') {
            dataurl = this.state.imageUrl;
        }
        else {
            dataurl = this.state.imageUrlforPage;
        }

        let that = this;
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
        let timestamp = Math.floor(Date.now());
        var file = dataURLtoFile(dataurl, 'image.jpg');
        if (file !== undefined) {
            var data = {
                "data": {
                    "folder": "page/" + user.contactNo,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));

            let response = await fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            });
            let responseD = await response.json();
            return responseD;
        }
    }




    render() {
        // let hideLocation = false;
        // if (this.props.for === 'Page') {
        //     hideLocation = true;
        // }
        const { match } = this.props;
        let pageId = match.params.pageId;
        let liked = false;
        let followed = false;
        if (this.state.pageData) {
            if (this.state.pageData.likes) {
                this.state.pageData.likes.map(like => {
                    if (like === user.contactNo) {
                        liked = true;
                    }
                })
            }
            if (this.state.pageData.followers) {
                this.state.pageData.followers.map(follow => {
                    if (follow === user.contactNo) {
                        followed = true;
                    }
                })
            }

        }
        let icon = this.state.pageData ? this.state.pageData.icon ? this.state.pageData.icon : userIcon : userIcon;
        let timeline = this.state.pageData ? this.state.pageData.timeline ? this.state.pageData.timeline : bannerimg : bannerimg;
        let sportsName = [];
        if (this.state.sports) {
            this.state.sports.map(sp => {
                sportsName.push(sp.name)
            })
        }
        const { crop, croppedImageUrl, src } = this.state;
        let imgurl = this.state.imageUrl ? this.state.imageUrl : timeline;
        let imgUrlforPage = this.state.imageUrlforPage ? this.state.imageUrlforPage : icon;
        return (
            < >
                <Modal
                    size="md"
                    show={this.state.showForCropImage}
                    onHide={() => this.closeCropModel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div class="modal-body modal-bodyD" >
                        <div className="ImgUp" >
                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}

                                />
                            )}

                            {croppedImageUrl && (

                                <div>
                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />


                                </div>
                            )}
                        </div>
                        <div className="modal-footer">

                            <button onClick={() => this.saveCropSelection()} style={{ backgroundColor: '#6ca722', color: '#FFF' }} type="button" className="btn btn-default updatebtn hvr-ripple-out" >{this.state.showUploadSpinner ? <React.Fragment> <Spinner
                                as="span"
                                animation="border"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> uploading</React.Fragment> : 'Save'}</button>
                            <button type="button" className="btn btn-default cancelbtn hvr-ripple-out" data-dismiss="modal" onClick={() => this.closeCropModel()}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <Header />
                <section className="container banner" ref={this.myRef}>
                    {this.state.showProfileBanner ? <div className="banner">
                        <div className="bannerinner-page" style={{ backgroundImage: 'url(' + imgurl + ')' }} >

                        </div>
                        <button className="uploadbanner-page" onClick={(e) => this.myInputFile.click()}><span>Page Banner</span><img src={camera} /></button>
                        <input
                            style={{ display: 'none' }}
                            type="file" id="timelineImage" onChange={this.onImageChange} accept='image/*'
                            ref={(ref) => this.myInputFile = ref}
                        />
                        <div className="img-info-page">
                            <div className="profilesec_inner pageprofile-img">
                                <span style={{ backgroundImage: 'url(' + imgUrlforPage + ')' }} />
                                <a onClick={(e) => this.myCamFile.click()}><img src={camera} />
                                    <input ref={(ref) => this.myCamFile = ref} style={{ display: 'none' }} type="file" onChange={this.onImageChangePageProfile} accept='image/*' />
                                </a>
                            </div>
                            <div className="page-heading">
                                <h4>{this.state.pageData ? this.state.pageData.name : null} </h4>
                                <label>sports {this.state.pageData ? this.state.pageData.category : null}</label>
                                <p>{this.state.pageData ? this.state.pageData.location : null}</p>
                            </div>
                            <button className="btnlikepage" onClick={this.setToggleForFollowers}>{followed ? 'Followed' : 'Follow'}</button>
                            <button className="btnlikepage" onClick={this.setToggleForLikes}>{liked ? 'Liked' : 'Like'}</button>
                        </div>
                    </div> : null}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 bottomsec-page">
                        <PageLeftSection createdByUserNo={this.state.createdByUserNo} pageFollowers={this.state.pageFollowers} pageTeams={this.state.pageTeams} sportsName={sportsName} pageId={pageId} pageChallenge={this.state.pageChallenge} pageTournaments={this.state.pageTournaments} challengeUsers={this.state.challengeUsers} />
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 right-page-sec">
                            {this.state.showProfileBanner ? <>
                                <CreatePost for={'Page'} pageId={pageId} iconImage={icon} createdByUserNo={this.state.createdByUserNo} />
                                {this.state.pagePosts.map(x => {
                                    return <LoginFeedsPosts key={x.postId} posts="posts" moduleId={x.postId} newFeedsId={x.postId} reported={false} sentBy={user.contactNo} />
                                })}
                            </> : null}

                            {this.state.showCreateTeam ? <CreatePageTeam for={'Page'} pageId={pageId} iconImage={icon} /> : null}

                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 page_of_post">
                            {/* <Posts userbuddy={this.props.pageId} permission={true} for={'Page'} /> */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

