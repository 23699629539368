import React, { Component } from 'react';
import './DefaultStyle.css';
import './IndexStyle.css';
import 'react-flags-select/css/react-flags-select.css';
import 'react-flags-select/scss/react-flags-select.scss';
import ConfigUrl from './ConfigUrl';
import loginphone from './img/loginphone.svg';
import loginBgIcon from './img/loginbg.jpg';
import flagicon from './img/flagicon.svg';
import usericon from './img/user-icon.svg';
import locationicon from './img/location-icon.svg';
import femaleicon from './img/female-icon.svg';
import maleicon from './img/male-icon.svg';
import passwordicon from './img/password-icon.svg';
import emailicon from './img/email-icon.svg';
import Map from './Map';
import ReactFlagsSelect from 'react-flags-select';
import $ from "jquery";
import { trackPromise } from 'react-promise-tracker';
import loginpattern from './img/loginpattern.svg';

const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const userApi = rshApi;
const countryApi = "https://restcountries.eu/rest/v2/";
var nonSessionUserData;
var gender;
class CheckProfileComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData: [],
            country: '',
            username: '',
            password: '',
            submitted: false,
            loading: false,
            alpha2Code: [],
            altSpellings: [],
            countryCode: [],
            countryName: [],
            error: '',
            address: '',
            locationError: false,
            genderSection: false,
            passwordSection: false,
            emailSection: false,
            email: '',
            name: '',
            genderType: 'Male',
            checked: true,
            otp: '',
            showOtp: false,
            callingCode: '',
            setReadonly: false,
            fillError: false,
            showServerError: false,
            errorDetails: '',
            otpError: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    skip = () => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        ;
        var nameData = false, emailData = false;
        if (this.state.address !== '') {
            nonSessionUserData.address = this.state.address;
            nonSessionUserData.gender = this.state.genderType;
            nonSessionUserData.country = this.state.country;
            nonSessionUserData.state = this.state.state;
            nonSessionUserData.city = this.state.city;
            nonSessionUserData.latitude = this.state.latitude;
            nonSessionUserData.longitude = this.state.longitude;
        }
        if (this.state.password !== '') {
            nonSessionUserData.password = this.state.password;

        }
        if (this.state.name !== '') {
            nonSessionUserData.name = this.state.name;
            nameData = true;
        }
        if ((this.state.email !== '') && (re.test(String(this.state.email).toLowerCase()) === true)) {
            nonSessionUserData.email = this.state.email;
            emailData = true;
        }

        delete nonSessionUserData.id;
        fetch(userApi + 'users/update-profile', {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    user: nonSessionUserData,
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {

            if (result.statusDetails.code === 200) {
                localStorage.setItem('nonSessionUserData', JSON.stringify(result.data));
                if (emailData === true && nameData === true) {
                    localStorage.setItem('result', JSON.stringify(nonSessionUserData));
                    localStorage.removeItem("nonSessionUserData");
                    localStorage.setItem('logedIn', "yes");
                    window.location.href = '/';
                }
                else {
                    window.location.href = '/';
                }
            }
            else {
                window.location.href = '/';
            }
        });
    }
    somethingWorng = (type) => {
        if (type === 'otpWrong') {
            this.setState({ otpError: true, errorDetails: 'Please Enter Correct otp' });
        }
        else if (type === 'numberExist') {
            this.setState({ showServerError: true, errorDetails: 'Contact number Allready Exist!' });
        }
        else {
            this.setState({ showServerError: true, errorDetails: 'Please Enter Correct Contact number' });
        }
        let that = this;
        setTimeout(function () { that.setState({ showServerError: false }) }, 3000);

    }
    cancelOtp() {
        this.setState({ showOtp: false, otp: '', setReadonly: false })
    }
    verifyOtp() {
        if (this.state.otp !== '') {

            var password = this.state.password;
            var contactNumber = this.state.contactNumber;
            let that = this;
            const apiUrl = rshApi + "global/verify-otp";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "contactNo": that.state.contactNumber.toString(),
                        "otp": that.state.otp.toString()
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {

                    if (result.statusDetails.code === 200) {
                        nonSessionUserData.password = password;
                        nonSessionUserData.contactNo = contactNumber;
                        nonSessionUserData.mobileVerified = true;
                        delete nonSessionUserData.id;
                        console.log(nonSessionUserData);

                        fetch(userApi + 'users/update-profile', {
                            method: "post",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                data: {
                                    user: nonSessionUserData,
                                }
                            })
                        }).then(function (response) {
                            return trackPromise(response.json());
                        }).then(function (result) {

                            if (result.statusDetails.code === 200) {
                                localStorage.setItem('nonSessionUserData', JSON.stringify(result.data));
                                if (result.data.email !== 'NA' && result.data.name !== 'NA') {
                                    localStorage.setItem('result', JSON.stringify(nonSessionUserData));
                                    localStorage.removeItem("nonSessionUserData");
                                    localStorage.setItem('logedIn', "yes");
                                    window.location.href = '/';
                                }
                                else {
                                    that.setState({ genderSection: false, passwordSection: false, emailSection: true });
                                }
                            }
                            if (result.error === true) {
                                that.cancelOtp();
                                that.somethingWorng('numberExist');
                            }
                        });

                    }
                    else {

                        that.somethingWorng('otpWrong');
                    }
                });
            // var myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            // myHeaders.append("Authorization", "Basic NmIzMTU4OWItNTE0MS00N2FkLThmZWQtNzU3NDFkNzc1NDJjOnM1VkVmNC9ZZ1U2NVIyZmtNTzFsYUE9PQ==");

            // var raw = JSON.stringify({ "method": "sms", "sms": { "code": this.state.otp.toString() } });
            // var requestOptions = {
            //     method: 'PUT',
            //     headers: myHeaders,
            //     body: raw,
            //     redirect: 'follow'
            // };

            // fetch("https://verificationapi-v1.sinch.com/verification/v1/verifications/number/" + contactNumber, requestOptions)
            //     .then(response => response.text())
            //     .then(result => console.log(result))
            //     .catch(error => console.log('error', error));
        }
        else {
            this.setState({ fillError: true });
        }
    }
    handleChange(e) {
        if (e.target.name === 'otp') {
            this.setState({ fillError: false })
            if (parseInt(e.target.value.length) < 5) {
                const { name, value } = e.target;
                this.setState({ [name]: value });
            }
        }
        else if (e.target.name === 'username') {
            if (parseInt(e.target.value.length) < 12) {
                const { name, value } = e.target;
                this.setState({ [name]: value });
            }
        }
        else if (e.target.name === 'password') {
            //if (parseInt(e.target.value.length) < 5) {
            const { name, value } = e.target;
            this.setState({ [name]: value });
            //  }
        }
        else {
            const { name, value } = e.target;
            this.setState({ [name]: value });
        }
    }
    changeData(event) {

        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ genderType: selectedValue });
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        if ((nonSessionUserData.address === 'NA') || (nonSessionUserData.gender === 'NA')) {
            if (this.state.address === '') {
                this.setState({ locationError: true });
            }
            else {
                nonSessionUserData.address = this.state.address;
                nonSessionUserData.gender = this.state.genderType;
                nonSessionUserData.country = this.state.country;
                nonSessionUserData.state = this.state.state;
                nonSessionUserData.city = this.state.city;
                nonSessionUserData.latitude = this.state.latitude;
                nonSessionUserData.longitude = this.state.longitude;
                delete nonSessionUserData.id;
                let that = this;
                fetch(userApi + 'users/update-profile', {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        data: {
                            user: nonSessionUserData,
                        }
                    })
                }).then(function (response) {
                    return trackPromise(response.json());
                }).then(function (result) {
                    console.log(result);
                    if (result.error === true) {
                        that.setState({ error: result.data });
                    } else {
                        localStorage.setItem('nonSessionUserData', JSON.stringify(result.data));
                        // localStorage.setItem('logedIn', "yes");
                        // history.push('/');
                    }
                });
                this.setState({ genderSection: false, passwordSection: true, emailSection: false });
            }
        }
        else if ((nonSessionUserData.mobileVerified === false) || (nonSessionUserData.gender === 'NA')) {
            if ((this.state.username === '') && (this.state.password === '')) {
                const that = this;
                this.setState({ genderSection: false, passwordSection: true, emailSection: false });
            }
            else {

                const that = this;
                this.setState({ submitted: true, setReadonly: true });


                const { username, password } = this.state;
                const { history } = this.props;
                const country = document.getElementsByClassName('country-label')[0].innerHTML;
                const countryCodeUrl = countryApi + "name/" + country + "?fullText=true";
                fetch(countryCodeUrl, {
                    method: "get"
                }).then(function (response) {
                    return trackPromise(response.json());
                }).then(function (result) {
                    that.setState({ callingCode: '+' + result[0].callingCodes[0] });

                    var contactNumber = '+' + result[0].callingCodes[0].concat(that.state.username.trim());
                    that.setState({ contactNumber: contactNumber })

                    const apiUrl = rshApi + "global/send-otp";
                    fetch(apiUrl, {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            "data": {
                                "contactNo": contactNumber.toString(),
                            }
                        })
                    })
                        .then(function (response) {
                            return trackPromise(response.json());
                        })
                        .then(function (result) {
                            console.log(result)
                            if (result.statusDetails.code === 200) {
                                that.setState({ showOtp: true });
                            }
                            else {
                                that.somethingWorng('numberWorng');
                            }
                        });

                });
                // var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                // myHeaders.append("Authorization", "Basic NmIzMTU4OWItNTE0MS00N2FkLThmZWQtNzU3NDFkNzc1NDJjOnM1VkVmNC9ZZ1U2NVIyZmtNTzFsYUE9PQ==");
                // var raw = JSON.stringify({ "identity": { "type": "number", "endpoint": contactNumber.toString() }, "method": "sms" });
                // var requestOptions = {
                //     method: 'POST',
                //     headers: myHeaders,
                //     body: raw,
                //     redirect: 'follow'
                // };
                // fetch("https://verificationapi-v1.sinch.com/verification/v1/verifications", requestOptions)
                //     .then(response => response.text())
                //     .then(result => console.log(result))
                //     .catch(error => console.log('error', error));


            }
        }
        else if ((nonSessionUserData.email === 'NA') || (nonSessionUserData.name === 'NA')) {

            if ((this.state.email === '') && (this.state.name === '')) {
                this.setState({ genderSection: false, passwordSection: false, emailSection: true });
            }

        }

    }
    otpChange = (e) => {
        this.setState({ otp: e.target.value });
    }
    callbackFunction = (childData) => {
        console.log(childData);
        if (childData.address !== null) {
            this.setState({ locationError: false });
        }
        this.setState({ mapData: childData })
        this.setState({ address: childData.address, latitude: childData.latValue, longitude: childData.lngValue, city: childData.city, state: childData.state, country: childData.country })
    }
    componentDidMount() {

        //  const { history } = this.props;
        const that = this;
        const apiUrl = countryApi + 'all';
        fetch(apiUrl, {
            method: "get"
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            that.setState({ countryData: result });
            that.state.countryData.slice(0, that.state.countryData.length).map(
                (item, key) => that.state.alpha2Code.push(item.alpha2Code)
            );
            that.setState({ countryCode: that.state.alpha2Code });
        });

        nonSessionUserData = JSON.parse(localStorage.getItem('nonSessionUserData'));
        console.log(nonSessionUserData)
        if (nonSessionUserData.gender === "NA" || nonSessionUserData.address === "NA") {

            this.setState({ genderSection: true })
        }
        else if (nonSessionUserData.password === "NA" || nonSessionUserData.mobileVerified === false) {
            this.setState({ passwordSection: true, genderSection: false })

        }
        else if (nonSessionUserData.name !== "NA") {
            this.setState({ name: nonSessionUserData.name });

        }
        else if (nonSessionUserData.email !== "NA") {
            this.setState({ email: nonSessionUserData.email });

        }
        else if (nonSessionUserData.name === "NA" || nonSessionUserData.email === "NA") {
            this.setState({ emailSection: true, passwordSection: false, genderSection: false })

        }
        else if (nonSessionUserData.gender !== "NA" && nonSessionUserData.address !== "NA" && nonSessionUserData.password !== "NA" && nonSessionUserData.mobileVerified !== false && nonSessionUserData.name !== "NA" && nonSessionUserData.email !== "NA") {

            localStorage.setItem('result', JSON.stringify(nonSessionUserData));
            localStorage.removeItem("nonSessionUserData");
            localStorage.setItem('logedIn', "yes");
            window.location.href = '/';

        }





    }

    render() {

        const { username, password, email, name, otp, submitted, loading } = this.state;
        const loginBgStyle = {
            backgroundImage: 'url(' + loginBgIcon + ')'
        };
        if (this.state.countryData.length > 0)
            this.items = this.state.countryData.slice(0, this.state.countryData.length).map((item, key) =>
                <option key={key + 1} value={item.callingCodes[0]}>{item.name}</option>
            );
        else
            this.items = this.state.countryData.slice(0, 0).map((item, key) =>
                <option key={key + 1} value={item.callingCodes[0]}>{item.name}</option>
            );
        if (this.state.error)
            this.errorText = <li><div className="form-group"><div className="alert alert-danger login-page-alert"><strong>Error!</strong> {this.state.error}</div></div></li>
        else
            this.errorText = ''
        return (

            <body className="loginbg-d" style={{ margin: '10px', backgroundImage: 'url(' + loginpattern + ')' }}>
                <div className="row" >

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7"></div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7" style={{ border: '3px solid green', borderRadius: '5px', padding: '10px' }}>
                        <h3 style={{
                            textAlign: 'center', color: 'Green'
                        }}>Complete Your Profile</h3>
                        <form style={{ margin: '10px' }} onSubmit={this.handleSubmit}>
                            <div className="form">
                                <ul className="loginul_form">
                                    {this.state.genderSection ?
                                        <React.Fragment>
                                            <li className="commaninputdiv">
                                                <div className="checkininner">
                                                    <span id="searchLocation" style={{ borderBottom: '1px' }}>
                                                        <img style={{ position: 'relative', top: '19px' }} src={locationicon} alt="female"
                                                        /><Map parentCallback={this.callbackFunction} />
                                                    </span>
                                                    {this.state.locationError ? <div className="has-error"> <div className="help-block">Location  is required </div></div>
                                                        : ''}
                                                </div>
                                            </li>

                                            <li>
                                                <div className="checkbox">
                                                    <label>
                                                        <img src={maleicon} alt="male" />
                                                        <input type="radio" className="option-input radio" value="Male" name="example" defaultChecked={this.state.checked} onClick={this.changeData.bind(this)} />
                                                Male
                                            </label>
                                                    <label>
                                                        <img src={femaleicon} alt="female" />
                                                        <input type="radio" value="Female" className="option-input radio" name="example" onClick={this.changeData.bind(this)} />
                                                Female
                                            </label>
                                                </div>
                                            </li></React.Fragment> : ''}
                                    {this.state.passwordSection ?
                                        <React.Fragment>  <li>
                                            <div className="commaninputdiv">
                                                <ReactFlagsSelect
                                                    countries={this.state.countryCode}
                                                    customLabels={{ "IN": "India" }}
                                                    showOptionLabel={true}
                                                    defaultCountry="IN"
                                                    placeholder="Select Country"
                                                    className="question"
                                                    name="country"
                                                    onChange={this.handleChange}
                                                    searchable={true}
                                                />
                                            </div>
                                        </li>
                                            <li>
                                                <div className={'commaninputdiv' + (submitted && !username ? ' has-error' : '')}>
                                                    {this.state.setReadonly ? <input type="number" className="question-d" name="username" value={this.state.username} onChange={this.handleChange} autoComplete="off" placeholder="Contact Number" readOnly /> : <input type="number" className="question-d" name="username" value={this.state.username} onChange={this.handleChange} autoComplete="off" placeholder="Contact Number" />}
                                                    <label htmlFor=""> <img className="inputicon-d" src={loginphone} alt="mobile" /><span></span></label>
                                                    {submitted && !username &&
                                                        <div className="help-block">Contact number is required </div>
                                                    }
                                                </div>
                                            </li>

                                            <li className="commaninputdiv">
                                                <div className={'commaninputdiv' + (submitted && !password ? ' has-error' : '')}>
                                                    {this.state.setReadonly ? <input type="password" name="password" className="question-d" autocomplete="off" placeholder="Password" value={this.state.password} onChange={this.handleChange} readOnly /> : <input type="password" name="password" className="question-d" autocomplete="off" placeholder="Password" value={this.state.password} onChange={this.handleChange} />}
                                                    <label htmlFor=""> <img className="inputicon-d" src={passwordicon} alt="Name" /><span></span></label>
                                                    {submitted && !password &&
                                                        <div className="help-block">Password is required</div>
                                                    }
                                                </div>
                                            </li>
                                            {this.state.showServerError ? <h4 style={{ color: 'red' }}>{this.state.errorDetails}</h4> : ''}
                                            {this.state.showOtp ? <li className="commaninputdiv">
                                                <div style={{ textAlign: "center" }}>
                                                    <h2>OTP</h2>
                                                    <div className={'commaninputdiv' + (submitted && !otp ? ' has-error' : '')}>
                                                        <input style={{ padding: '0px', textAlign: 'center', letterSpacing: '3px' }} type="number" name="otp" maxlength="4" value={this.state.otp} className="question-d" placeholder="Otp" onChange={this.handleChange} />
                                                        <label style={{ margin: '0 auto', width: '25%', textAlign: "center" }} htmlFor=""> </label>
                                                        {this.state.fillError ? <div className="help-block">Otp is required</div> : ''}
                                                    </div>
                                                    <button style={{ marginTop: '5px', borderRadius: '70px', color: 'white', border: 'none', background: "red", padding: '10px 30px' }} onClick={() => this.cancelOtp()}>Cancel </button>
                                                    <button type="button" style={{ marginTop: '5px', borderRadius: '70px', color: 'white', border: 'none', background: "blue", padding: '10px 30px' }} onClick={() => this.verifyOtp()}>Verify </button>
                                                    {this.state.otpError ? <h4 style={{ color: 'red' }}>{this.state.errorDetails}</h4> : ''}
                                                </div>

                                            </li> : ''}



                                        </React.Fragment> : ''}

                                    {this.state.emailSection ?
                                        <React.Fragment>   <li className="commaninputdiv">
                                            <div className={'commaninputdiv' + (submitted && !email ? ' has-error' : '')}>
                                                <input type="text" name="email" className="question-d" autocomplete="off" placeholder="Email" onChange={this.handleChange} />
                                                <label htmlFor=""><img className="inputicon-d" src={emailicon} alt="Country" /><span></span></label>
                                                {submitted && !email &&
                                                    <div className="help-block">Email is required</div>
                                                }
                                            </div>
                                        </li>
                                            <li className="commaninputdiv">
                                                <div className={'commaninputdiv' + (submitted && !name ? ' has-error' : '')}>
                                                    <input type="text" name="name" className="question-d" id="" autocomplete="off" placeholder="Your Name" onChange={this.handleChange} />
                                                    <label htmlFor=""> <img className="inputicon-d" src={usericon} alt="Name" /><span></span></label>
                                                    {submitted && !name &&
                                                        <div className="help-block">name is required</div>
                                                    }
                                                </div>
                                            </li>
                                        </React.Fragment> : ''}
                                    {this.errorText}
                                </ul>
                                <div className="modal-btn">
                                    <button type="button" style={{ float: 'left', border: 'none', background: "grey", padding: '10px 15px' }} onClick={() => this.skip()}>Skip</button>
                                    {this.state.passwordSection ? this.state.showOtp ? '' : <button style={{ border: 'none', padding: '10px 15px' }}>Send OTP </button> : <button style={{ border: 'none', padding: '10px 15px' }}>Next </button>}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7"></div>
                </div>
            </body>

        );
    }
}
export default CheckProfileComplete;




