import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import play from '../img/play.png';
import $ from 'jquery';
var scoreCardD104 = [];
var d104ScoreCard = {}, score, scoreCardD104s = {};
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var maxWeights = [];
var cricketApi = require("../cricketApi.js");

export default class Tournament_d104view extends Component {
    constructor(props) {
        super(props);
        this.props.challenge.accepted.map(x => {
            scoreCardD104.push({ 'userId': x, 'points': 0.0, 'winner': false });
        });
        this.state = {
            scoreCardD104s: scoreCardD104,
            matchSatus: false,
        };


    }

    componentDidMount() {
        console.log(scoreCardD104);
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.challenge.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {

                    }
                    else {
                        if (result.data.score.d104ScoreCard.scoreCardD104s) {
                            that.setState({ scoreCardD104s: result.data.score.d104ScoreCard.scoreCardD104s, matchSatus: true });
                            scoreCardD104 = result.data.score.d104ScoreCard.scoreCardD104s;
                        }
                    }
                    if (that.props.challenge.challengeAction === 'ended') {

                        that.setState({ matchSatus: true });
                    }

                });
        }
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);

    }

    render() {
        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;

        return (
            <React.Fragment>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        {/* <div className="broadcast_video">
                            <span>09:33</span>
                            <a className="tour_broadcast_update"><img src={play} /></a>
                        </div> */}
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        {this.state.matchSatus ? <ul className="winnerdropdown_ul">
                            {this.state.scoreCardD104s.map((accepted, index) => {
                                var name, contactNo, point, pointColor;
                                this.props.profileUsers.map(user => {
                                    if (user.contactNo === accepted.userId) {
                                        name = user.name;
                                        contactNo = user.contactNo;
                                        point = accepted.points;
                                        point = cricketApi.msToTime(point);
                                    }
                                    if (accepted.disqualified === true) {
                                        pointColor = { color: 'red' };
                                        point = 'disqualify';
                                    }
                                })
                                return <li key={contactNo}>
                                    <label>{name}</label>
                                    <input placeholder="Enter Points" readOnly style={pointColor} value={point} />
                                </li>
                            })}
                        </ul> : <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>}
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

