import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import finallogo from '../img/finallogo.png';
import NumericInput from 'react-numeric-input';
import broadcastbg from '../img/broadcastbg.png';
import { message } from 'antd';
import play from '../img/play.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import teamIcons from '../img/team.png';
import male from "../img/male.png";
let byPic, toPic;
var score;
var selectedWinners = undefined;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    }
};
export default class Update_D107Archary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSets: 5,
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107ArcheryScoreCard: {},
            score: false,
            localType: '',
            localSet: '',
            currentArchaeryPointBy: 0,
            currentArchaeryPointTo: 0,
            byPoints: [],
            toPoints: [],
            selectWinnerModal: false,
            byWinner: false,
            toWinner: false,
            errorShow: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);
        this.done = this.done.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false, setModalIsOpen: false });
    }

    done(event) {
        let d107ArcheryScoreCard = { ...this.state.d107ArcheryScoreCard };
        let stroke = d107ArcheryScoreCard.strokeNo;
        var archaryPoint = document.getElementsByName("point")[0].value;
        //console.log(archaryPoint.match)
        if (archaryPoint.match) {
            if (archaryPoint.match(/^([+-]?[1-9]\d*|0)$/)) {
                var roundData = this.state.rounds;
                roundData.map(round => {
                    if (round.position === parseInt(this.state.localSet)) {
                        if (this.state.localType === 'by') {
                            if (round.toUndoList) {
                                let undos = {
                                    strokeNo: stroke += 1,
                                    points: round.challengedByPoints,
                                    type: "challengedBy"
                                }
                                d107ArcheryScoreCard.strokeNo = stroke;
                                round.toUndoList.push(undos);
                            }
                            else {
                                let undos = {
                                    strokeNo: stroke += 1,
                                    points: round.challengedByPoints,
                                    type: "challengedBy"
                                }
                                d107ArcheryScoreCard.strokeNo = stroke;
                                round.toUndoList = [undos];
                            }
                            round.challengedByPoints = parseInt(archaryPoint);
                            d107ArcheryScoreCard.rounds[round.position] = round;
                            this.setState({ currentArchaeryPointBy: parseInt(archaryPoint) })
                        }
                        else if (this.state.localType === 'to') {
                            if (round.toUndoList) {
                                let undos = {
                                    strokeNo: stroke += 1,
                                    points: round.challengedToPoints,
                                    type: "challengedTo"
                                }
                                d107ArcheryScoreCard.strokeNo = stroke;
                                round.toUndoList.push(undos);
                            }
                            else {
                                let undos = {
                                    strokeNo: stroke += 1,
                                    points: round.challengedToPoints,
                                    type: "challengedTo"
                                }
                                d107ArcheryScoreCard.strokeNo = stroke;
                                round.toUndoList = [undos];
                            }
                            round.challengedToPoints = parseInt(archaryPoint);
                            d107ArcheryScoreCard.rounds[round.position] = round;
                            this.setState({ currentArchaeryPointTo: parseInt(archaryPoint) })
                        }
                    }
                })
                console.log(d107ArcheryScoreCard)
                let score = {
                    challengeId: this.props.challenge.challengeId,
                    matchId: this.props.challenge.challengeId,
                    d107ArcheryScoreCard: d107ArcheryScoreCard,
                }
                UpdateChallengeScore(score);
                this.setState({
                    d107ArcheryScoreCard: d107ArcheryScoreCard, rounds: roundData,
                    modalIsOpen: false, setModalIsOpen: false
                })
            }
            else {
                setTimeout(() => {
                    this.setState({
                        errorShow: true
                    });
                }, 200);
            }
        }
    }


    doneMatch() {
        let d107ArcheryScoreCard = { ...this.state.d107ArcheryScoreCard };
        let winner = null;
        if (this.state.byWinner) {
            winner = d107ArcheryScoreCard.byArcheryScoreCard.id;
        }
        else if (this.state.toWinner) {
            winner = d107ArcheryScoreCard.toArcheryScoreCard.id;
        }

        if (winner) {
            score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107ArcheryScoreCard: this.state.d107ArcheryScoreCard,
            }
            UpdateChallengeScore(score);

            var moment = require('moment');
            var now = moment().format();
            var timpstampData = new Date(now).valueOf();
            var challenge = this.props.challenge;
            delete challenge.id;
            challenge.challengeAction = challengeAction;
            challenge.modifiedOn = timpstampData;
            challenge.challengeWinnerId = winner;
            let that = this;
            const apiUrl = rshApi + "challenges/update-challenge";
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challenge": challenge,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result)
                    that.setState({ winnerModalOpen: false, selectWinnerModal: false });
                    window.location = "/challengeprofile/index.html?id=" + that.props.challenge.challengeId;
                });
        }
    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }

    changeDataa = (event) => {
        let d107ArcheryScoreCard = { ... this.state.d107ArcheryScoreCard };
        const that = this;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            if (selectedValue === 'challengedBy') {
                d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
                that.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard, toWinner: false, byWinner: true, winner1: d107ArcheryScoreCard.byArcheryScoreCard.id });
            }
            else {
                d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
                that.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard, toWinner: true, byWinner: false, winner2: d107ArcheryScoreCard.toArcheryScoreCard.id });
            }

        }
    }

    beforedoneMatch = () => {
        const that = this;
        if (that.state.byWinner) {
            that.setState({ selectWinnerModal: false, winnerModalOpen: true });
        }
        else if (that.state.toWinner) {
            that.setState({ selectWinnerModal: false, winnerModalOpen: true });
        }
        else {
            document.getElementById('showTieError').style.display = 'block';
        }
    }


    backState = () => {
        window.location = "/challengeprofile/index.html?id=" + this.props.challenge.challengeId;
    }
    saveIntialSet = () => {
        if (this.state.numberOfSets !== null) {
            var i = 0;
            var numberOfSets = parseInt(this.state.numberOfSets);
            var round = [];
            var header = {
                position: 0,
                challengedById: null,
                challengedByPoints: 0,
                challengedToId: null,
                challengedToPoints: 0,
                saved: false,
                tableViewType: 0,
                toUndoList: null,
                winnerId: null,
            }
            round.push(header);
            for (i = 1; i <= (numberOfSets); i++) {
                var roundData = {
                    position: i,
                    challengedById: this.props.challenge.accepted[0],
                    challengedByPoints: 0,
                    challengedToId: this.props.challenge.accepted[1],
                    challengedToPoints: 0,
                    saved: false,
                    tableViewType: 1,
                    toUndoList: null,
                    winnerId: null,
                }

                round.push(roundData);
            }
            var footer = {
                position: 6,
                challengedById: null,
                challengedByPoints: 0,
                challengedToId: null,
                challengedToPoints: 0,
                saved: false,
                tableViewType: 2,
                toUndoList: null,
                winnerId: null,
            }
            round.push(footer);
            var rounds = round;
            const that = this;
            var d107ScoreCard = {
                noOfSets: numberOfSets,
                setNo: 1,
                strokeNo: 0,
                byArcheryScoreCard: {
                    id: that.props.challenge.accepted[0],
                    points: null,
                    winner: false
                },
                toArcheryScoreCard: {
                    id: that.props.challenge.accepted[1],
                    points: null,
                    winner: false
                },
                rounds
            }
            score = {
                challengeId: that.props.challenge.challengeId,
                matchId: that.props.challenge.challengeId,
                d107ArcheryScoreCard: d107ScoreCard,
            }
            console.log(d107ScoreCard)
            UpdateChallengeScore(score);
            that.setState({ rounds: rounds, d107ArcheryScoreCard: d107ScoreCard, score: true });
        }
        else {
            this.setState({ setError: true });
        }
    }

    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }

    getScore() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {
                        let byName; let toName;
                        that.props.profileUsers.map(user => {
                            if (that.props.challenge.accepted[0] === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.props.challenge.accepted[1] === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        that.props.profileTeams.map(user => {
                            if (that.props.challenge.accepted[0] === user.teamId) {
                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;

                            }
                            if (that.props.challenge.accepted[1] === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;

                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });

                        that.saveIntialSet();
                    }
                    else {
                        // d107ArcheryScoreCard = result.data.score.d107ArcheryScoreCard;
                        that.setState({ d107ArcheryScoreCard: result.data.score.d107ArcheryScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107ArcheryScoreCard.rounds, numberOfSets: result.data.score.d107ArcheryScoreCard.noOfSets, setNo: result.data.score.d107ArcheryScoreCard.setNo, strokeNo: result.data.score.d107ArcheryScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id === user.contactNo) {
                                    byName = user.name
                                    byPic = user.profilePic ? user.profilePic : male;
                                }
                                if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id === user.contactNo) {
                                    toName = user.name
                                    toPic = user.profilePic ? user.profilePic : male;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            result.data.teams.map(user => {
                                if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.id === user.teamId) {
                                    byName = user.name
                                    byPic = user.teamIcon ? user.teamIcon : male;
                                }
                                if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.id === user.teamId) {
                                    toName = user.name
                                    toPic = user.teamIcon ? user.teamIcon : male;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        if (result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                            that.setState({ byPoints: result.data.score.d107ArcheryScoreCard.byArcheryScoreCard.points })
                        }
                        if (result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                            that.setState({ toPoints: result.data.score.d107ArcheryScoreCard.toArcheryScoreCard.points })
                        }
                    }
                });
        }
    }


    endEvent() {
        var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
        let setNo = d107ArcheryScoreCard.setNo;
        if (setNo <= 3) {
            this.setState({ showfillcheckerError: true })
        }
        else if (setNo => 3) {
            let ByPointSum = d107ArcheryScoreCard.byArcheryScoreCard.points.reduce((a, b) => a + b);
            let ToPointSum = d107ArcheryScoreCard.toArcheryScoreCard.points.reduce((a, b) => a + b);

            if (ByPointSum > ToPointSum) {
                this.setState({ winnerModalOpen: true, toWinner: false, byWinner: true, winner1: d107ArcheryScoreCard.byArcheryScoreCard.id });
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
            }
            if (ToPointSum > ByPointSum) {
                this.setState({ winnerModalOpen: true, toWinner: true, byWinner: false, winner2: d107ArcheryScoreCard.toArcheryScoreCard.id });
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
            }
            if (ToPointSum === ByPointSum) {
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.byArcheryScoreCard.winner = false;
                var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
                d107ArcheryScoreCard.toArcheryScoreCard.winner = false;
                this.setState({ selectWinnerModal: true })
            }
        }
    }



    makePoint() {
        var d107ArcheryScoreCard = { ...this.state.d107ArcheryScoreCard };
        var setNo = d107ArcheryScoreCard.setNo;
        let okDetector = false;
        d107ArcheryScoreCard.rounds.map(round => {
            if (round.position === setNo) {
                if (round.challengedByPoints === 0 || round.challengedToPoints === 0) {
                    okDetector = false;
                }
                else {
                    okDetector = true;
                }
            }
        })

        if (!okDetector) {
            this.setState({ showfillcheckerError: true })
        }
        else {
            var toAdd = d107ArcheryScoreCard.rounds.filter(round => {
                return round.position === d107ArcheryScoreCard.setNo
            })
            var toRemove = [...toAdd];

            var stroke = d107ArcheryScoreCard.strokeNo;
            // var undoList = [{
            //     points: 0,
            //     strokeNo: stroke += 1,
            //     type: "challengedBy",
            // }, {
            //     points: 0,
            //     strokeNo: stroke += 1,
            //     type: "challengedTo"
            // }];
            // d107ArcheryScoreCard.strokeNo = stroke;

            if (d107ArcheryScoreCard.rounds[setNo].challengedByPoints > d107ArcheryScoreCard.rounds[setNo].challengedToPoints) {
                let point = 2;
                //undoList[0].points = 2;

                if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.byArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.byArcheryScoreCard.points = [point];
                }
                if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.toArcheryScoreCard.points.push(0);
                }
                else {
                    d107ArcheryScoreCard.toArcheryScoreCard.points = [0];
                }
            }
            else if (d107ArcheryScoreCard.rounds[setNo].challengedToPoints > d107ArcheryScoreCard.rounds[setNo].challengedByPoints) {
                let point = 2;
                //undoList[1].points = 2;

                if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.toArcheryScoreCard.points.push(point);
                }
                else {
                    d107ArcheryScoreCard.toArcheryScoreCard.points = [point];
                }
                if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                    d107ArcheryScoreCard.byArcheryScoreCard.points.push(0);
                }
                else {
                    d107ArcheryScoreCard.byArcheryScoreCard.points = [0];
                }
            }
            else {
                if (d107ArcheryScoreCard.byArcheryScoreCard.points && d107ArcheryScoreCard.toArcheryScoreCard.points) {
                    if (d107ArcheryScoreCard.byArcheryScoreCard.points.length < 5 && d107ArcheryScoreCard.toArcheryScoreCard.points.length < 5) {
                        let point = 1;
                        //undoList[0].points = 1;
                        //undoList[1].points = 1;
                        if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                            d107ArcheryScoreCard.byArcheryScoreCard.points.push(point);
                        }
                        else {
                            d107ArcheryScoreCard.byArcheryScoreCard.points = [point];
                        }
                        if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                            d107ArcheryScoreCard.toArcheryScoreCard.points.push(point);
                        }
                        else {
                            d107ArcheryScoreCard.toArcheryScoreCard.points = [point];
                        }
                    }

                    else if (d107ArcheryScoreCard.byArcheryScoreCard.points.length === 5 &&
                        d107ArcheryScoreCard.toArcheryScoreCard.points.length === 5) {
                        if (setNo === 5) {
                            let ByPointSum = d107ArcheryScoreCard.byArcheryScoreCard.points.reduce((a, b) => a + b);
                            let ToPointSum = d107ArcheryScoreCard.toArcheryScoreCard.points.reduce((a, b) => a + b);

                            if (ByPointSum > ToPointSum) {
                                this.setState({ winnerModalOpen: true, toWinner: false, byWinner: true, winner1: d107ArcheryScoreCard.byArcheryScoreCard.id });
                                d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
                            }
                            if (ToPointSum > ByPointSum) {
                                this.setState({ winnerModalOpen: true, toWinner: true, byWinner: false, winner2: d107ArcheryScoreCard.toArcheryScoreCard.id });
                                d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
                            }
                            if (ToPointSum === ByPointSum) {
                                d107ArcheryScoreCard.byArcheryScoreCard.winner = false;
                                d107ArcheryScoreCard.toArcheryScoreCard.winner = false;
                                this.setState({ selectWinnerModal: true })
                            }
                        }
                        var score = {
                            challengeId: this.props.challenge.challengeId,
                            matchId: this.props.challenge.challengeId,
                            d107ArcheryScoreCard: d107ArcheryScoreCard,
                        }
                        UpdateChallengeScore(score);
                        this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });
                        this.setState({ byPoints: d107ArcheryScoreCard.byArcheryScoreCard.points });
                        this.setState({ toPoints: d107ArcheryScoreCard.toArcheryScoreCard.points })
                        console.log(d107ArcheryScoreCard)
                        return;
                    }
                }

                else {
                    let point = 1;
                    //undoList[0].points = 1;
                    //undoList[1].points = 1;
                    if (d107ArcheryScoreCard.byArcheryScoreCard.points !== null) {
                        d107ArcheryScoreCard.byArcheryScoreCard.points.push(point);
                    }
                    else {
                        d107ArcheryScoreCard.byArcheryScoreCard.points = [point];
                    }
                    if (d107ArcheryScoreCard.toArcheryScoreCard.points !== null) {
                        d107ArcheryScoreCard.toArcheryScoreCard.points.push(point);
                    }
                    else {
                        d107ArcheryScoreCard.toArcheryScoreCard.points = [point];
                    }
                }

            }

            // d107ArcheryScoreCard.rounds.map(round => {
            //     if ((stroke - setNo) > 2) {
            //         //undoList[0].strokeNo = undoList[0].strokeNo - 1;
            //         //undoList[1].strokeNo = undoList[1].strokeNo - 1;
            //         d107ArcheryScoreCard.strokeNo = stroke - 2;
            //     }
            //     if (round.position === d107ArcheryScoreCard.setNo) {
            //         //round.toUndoList = undoList;
            //     }
            // })

            if (setNo < 5) {
                d107ArcheryScoreCard.setNo = setNo + 1;
            }
            if (setNo === 5) {
                let ByPointSum = d107ArcheryScoreCard.byArcheryScoreCard.points.reduce((a, b) => a + b);
                let ToPointSum = d107ArcheryScoreCard.toArcheryScoreCard.points.reduce((a, b) => a + b);

                if (ByPointSum > ToPointSum) {
                    this.setState({ winnerModalOpen: true, toWinner: false, byWinner: true, winner1: d107ArcheryScoreCard.byArcheryScoreCard.id });
                    d107ArcheryScoreCard.byArcheryScoreCard.winner = true;
                }
                if (ToPointSum > ByPointSum) {
                    this.setState({ winnerModalOpen: true, toWinner: true, byWinner: false, winner2: d107ArcheryScoreCard.toArcheryScoreCard.id });
                    d107ArcheryScoreCard.toArcheryScoreCard.winner = true;
                }
                if (ToPointSum === ByPointSum) {
                    d107ArcheryScoreCard.byArcheryScoreCard.winner = false;
                    d107ArcheryScoreCard.toArcheryScoreCard.winner = false;
                    this.setState({ selectWinnerModal: true })
                }
            }
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107ArcheryScoreCard: d107ArcheryScoreCard,
            }
            UpdateChallengeScore(score);
            this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });
            this.setState({ byPoints: d107ArcheryScoreCard.byArcheryScoreCard.points });
            this.setState({ toPoints: d107ArcheryScoreCard.toArcheryScoreCard.points })
            console.log(d107ArcheryScoreCard)
        }
    }

    ToundoList = () => {
        this.setState({ winnerModalOpen: false });
        var d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
        var toAdd = d107ArcheryScoreCard.rounds.filter(round => {
            return round.position === d107ArcheryScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107ArcheryScoreCard.strokeNo;
        var TypeMode;
        var toUndo;
        var toUndoList = [];
        if (toAdd[0].toUndoList !== null) {
            toUndoList = toAdd[0].toUndoList;
        }
        toUndoList.map(list => {
            if (list.strokeNo === stroke) {
                TypeMode = list.type;
                toUndo = list;
            }
        })

        toUndoList = toUndoList.filter((x) => x != toUndo)
        toAdd[0].toUndoList = toUndoList;


        if (TypeMode === undefined) {


            let roundData = this.state.rounds;
            roundData.map(round => {
                if (round.position === parseInt(d107ArcheryScoreCard.setNo)) {
                    round.challengedByPoints = 0;
                    round.challengedToPoints = 0;

                }
            })


            if (toAdd[0].toUndoList.length === 0) {

                if (d107ArcheryScoreCard.setNo > 1) {
                    d107ArcheryScoreCard.setNo -= 1;
                    d107ArcheryScoreCard.setNo = d107ArcheryScoreCard.setNo;
                }
                let roundData = this.state.rounds;

                roundData.map(round => {
                    if (round.position === parseInt(d107ArcheryScoreCard.setNo)) {
                        this.setState({ currentArchaeryPointBy: round.challengedByPoints ? round.challengedByPoints : 0 });
                        this.setState({ currentArchaeryPointTo: round.challengedToPoints ? round.challengedToPoints : 0 });

                    }
                })
                this.setState({ rounds: roundData })

            }

            let setWonsBy = d107ArcheryScoreCard.byArcheryScoreCard.points;
            if (setWonsBy !== null) {
                if (setWonsBy.length > 0) {

                    if (setWonsBy.length == 0) {
                        setWonsBy = null;
                        d107ArcheryScoreCard.byArcheryScoreCard.points = setWonsBy;
                    } else {
                        d107ArcheryScoreCard.byArcheryScoreCard.points.pop();
                    }

                }
            }



            let setWonsTo = d107ArcheryScoreCard.toArcheryScoreCard.points;
            if (setWonsTo !== null) {
                if (setWonsTo.length > 0) {

                    if (setWonsTo.length == 0) {
                        setWonsTo = null;
                        d107ArcheryScoreCard.toArcheryScoreCard.points = setWonsTo;
                    } else {
                        d107ArcheryScoreCard.toArcheryScoreCard.points.pop();
                    }
                }
            }

        }
        else if (TypeMode === "challengedBy") {
            if (toAdd[0].challengedByPoints > 0) {
                toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;

                toAdd[0].challengedByPoints = 0;

                stroke -= 1;

            }
        }
        else if (TypeMode === "challengedTo") {
            if (toAdd[0].challengedToPoints > 0) {
                toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;

                toAdd[0].challengedToPoints = 0;
                stroke -= 1;

            }
        }

        d107ArcheryScoreCard.strokeNo = stroke;

        if (d107ArcheryScoreCard.setNo === d107ArcheryScoreCard.noOfSets) {
            if (d107ArcheryScoreCard.byArcheryScoreCard.points.length === d107ArcheryScoreCard.noOfSets)
                d107ArcheryScoreCard.byArcheryScoreCard.points.pop();

            if (d107ArcheryScoreCard.toArcheryScoreCard.points.length === d107ArcheryScoreCard.noOfSets)
                d107ArcheryScoreCard.toArcheryScoreCard.points.pop();
        }

        var score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d107ArcheryScoreCard: d107ArcheryScoreCard,
        }
        UpdateChallengeScore(score);
        this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });
        console.log(d107ArcheryScoreCard);
    }


    editWinner = () => {

        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {
        this.getScore();
    }
    openFunc = (type, localset) => {
        this.setState({ setModalIsOpen: true, localType: type, localSet: localset });
        this.setState({
            errorShow: false
        });
    }

    tieClose = () => {
        let d107ArcheryScoreCard = this.state.d107ArcheryScoreCard;
        this.setState({ selectWinnerModal: false })
        if (d107ArcheryScoreCard.setNo === d107ArcheryScoreCard.noOfSets) {
            if (d107ArcheryScoreCard.byArcheryScoreCard.points.length === d107ArcheryScoreCard.noOfSets)
                d107ArcheryScoreCard.byArcheryScoreCard.points.pop();

            if (d107ArcheryScoreCard.toArcheryScoreCard.points.length === d107ArcheryScoreCard.noOfSets)
                d107ArcheryScoreCard.toArcheryScoreCard.points.pop();
        }
        var score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d107ArcheryScoreCard: d107ArcheryScoreCard,
        }
        UpdateChallengeScore(score);
        this.setState({ d107ArcheryScoreCard: d107ArcheryScoreCard });
        console.log(d107ArcheryScoreCard)
    }

    render() {
        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSets); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>

                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Scores Are Same Please Choose The Winner!</h3></div>
                    <div style={{ display: 'none', textAlign: 'center' }} id="showTieError"> <h5>Please choose the winning team!</h5></div>
                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-1" onClick={this.changeDataa.bind(this)} value="challengedBy" />
                                <label htmlFor="radio-example-1"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.byName}</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="chooseWinner" id="radio-example-2" onClick={this.changeDataa.bind(this)} value="challengedTo" />
                                <label htmlFor="radio-example-2"></label>
                            </div>
                            <label className="ss_sportstile">{this.state.toName}</label>
                        </li>
                    </ul>
                    <div className="button_div" >
                        <button className="cmnbutton" type="button" onClick={this.beforedoneMatch}>Done</button>
                        <button className="btn btn-inverse" onClick={this.backState} >Close</button>
                    </div>
                </Modal>


                <Modal isOpen={this.state.setModalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">  <div ref={subtitle => this.subtitle = subtitle}>
                        <div ref={subtitle => this.subtitle = subtitle}> <h3> Archery Point picker</h3></div>
                        <NumericInput mobile min={0}
                            max={30}
                            name="point"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Point"
                            required
                            size={25}
                            value={5}
                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }}
                        /> <br></br>

                    </div>
                    {this.state.errorShow ? <h6 id="errorId" style={{ color: 'red' }} className="errorClass"> Please Enter Number only !</h6> : ''}

                    <br></br>
                    <div className="button_div" >
                        {/* <button className="btn btn-danger" type="button" onClick={this.disqualify} style={{ 'margin': '2px' }}>Disqualify</button> */}
                        <button className="btn btn-inverse" onClick={this.closeModal} style={{ 'margin': '2px' }}>Cancel</button>
                        <button className="cmnbutton" type="button" onClick={this.done} style={{ 'margin': '2px' }}>Done</button>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.byPoints.length ? this.state.byPoints.reduce((a, b) => a + b) : 0} </p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toPoints.length ? this.state.toPoints.reduce((a, b) => a + b) : 0} </p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <div data-backdrop="static" id="setWon" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Confirmation !</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} id="setWonClick" type="button" data-toggle="modal" data-target="#setWon"></button>

                <button style={{ display: 'none' }} id="openDp4" type="button" data-toggle="modal" data-target="#winningScoreModal"></button>
                <div data-backdrop="static" id="winningScoreModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Set Winning Score</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="11set" onClick={this.changeData.bind(this)} value="11" />
                                            <label htmlFor="11set">11 Sets</label>
                                        </div>
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="21set" onClick={this.changeData.bind(this)} value="21" />
                                            <label htmlFor="21set">21 sets</label>
                                        </div>
                                        {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                            <span >please Select Set</span>
                                        </div> : ''}
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveSet()}>Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Challange Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>
                                <ul class="resultul">
                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <span class="resuserimg" style={{ backgroundImage: "url(" + finallogo + ")" }}></span>
                                            <div class="resinfo">
                                                <label> </label>
                                                <small>Score: </small>
                                            </div>

                                            <div className="styled-input-single" style={{ 'position': 'relative' }}>

                                                <input type="radio" name="selectWinner" id="" defaultChecked={this.state.checked} />
                                                <label htmlFor=""></label>
                                            </div>
                                        </a>
                                    </li>
                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                {/* <a data-dismiss="modal" class="cmnbutton editbtn" onClick={() => this.ToUndoList()} >Edit</a> */}
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />

                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className="padding12">{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points = null;

                                                let EnableInput = true;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    EnableInput = false;
                                                }
                                                let localSetNo = index + 1;



                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints;
                                                    }
                                                })

                                                return <td key={index}><input value={points} disabled={EnableInput} class="inputsize-d" onClick={() => this.openFunc('by', localSetNo)} /></td>
                                            })}

                                            <td><input value={this.state.byPoints.length ? this.state.byPoints.reduce((a, b) => a + b) : 0} class="inputsize-d" /></td>
                                        </tr>
                                        <tr>
                                            <td className="padding12">{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let localSetNo = index + 1;
                                                let EnableInput = true;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    EnableInput = false;
                                                }
                                                this.state.rounds.map((round, inde) => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input disabled={EnableInput} onClick={() => this.openFunc("to", localSetNo)} value={points} class="inputsize-d" /></td>
                                            })}
                                            <td><input value={this.state.toPoints.length ? this.state.toPoints.reduce((a, b) => a + b) : 0} class="inputsize-d" /></td>
                                        </tr>
                                        <tr>
                                            <td> Action </td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107ArcheryScoreCard.setNo) {
                                                    showAddButton = true;
                                                }

                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })
                                                return <td>
                                                    {showAddButton ? <button onClick={() => this.makePoint()} type="button" class="addpointtennis">OK</button> : <button style={{ backgroundColor: 'grey' }} disable="true" type="button" class="addpointtennis">OK</button>}

                                                </td>
                                            })}
                                            <td></td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

