import React, { Component } from 'react';
import userMaleIcon from "./img/male.png";

class CommentList extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props.data);
    }
    render() {

        var userPic;
        var userGender;
        var userComment;
        return (
            <div className="replydiv" key={this.props.data.users.contactNo}>
                {this.props.data.reactBudd.map(rb => {
                    if (rb.reactedType === 'comment') {
                        this.props.data.users.map(user => {
                            if (rb.buddyId === user.contactNo) {

                                userPic = user.profilePic;
                                userGender = user.gender;
                                userComment = rb.comment;

                            }
                            return true;
                        })
                        if (userPic !== null) {
                            return (
                                <li key={rb.timestamp}>
                                    <div className="usersubcomment"> <span className="postuser_img" style={{
                                        backgroundImage: "url(" + userPic + ")"
                                    }}></span>
                                        <p>{userComment}</p></div>
                                </li>
                            );
                        }
                        else {
                            if (userGender === 'Male') {
                                return (
                                    <li key={rb.timestamp}>
                                        <div className="usersubcomment"> <span className="postuser_img" style={{
                                            backgroundImage: "url(" + userMaleIcon + ")"
                                        }}></span>
                                            <p>{userComment}</p></div>
                                    </li>
                                );
                            }
                            else {
                                return (
                                    <li key={rb.timestamp}>
                                        <div className="usersubcomment"> <span className="postuser_img" style={{
                                            backgroundImage: "url(" + userMaleIcon + ")"
                                        }}></span>
                                            <p>{userComment}</p></div>
                                    </li>
                                );
                            }
                        }

                    }
                    return true;
                })
                }
            </div>
        );
    }
}

export default CommentList;
