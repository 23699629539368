import React, { Component } from 'react'
import noBroadcast from '../img/noBroadcast.jpg';
import matchnotstarted from '../img/matchnotstarted.png';
import { trackPromise } from "react-promise-tracker";
import team from '../img/team.png';
import female from '../img/female.png';
import male from '../img/male.png';
import play from '../img/play.png';
import logo from '../img/finallogo.png';
import ConfigUrl from '../ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d106view extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchStatus: false,
            rounds: [],
            byplayerScores: [],
            toplayerScores: [],
            users: [],
            teams: [],
            score: [],
        };
    }

    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);
        console.log(this.props.matchId);

        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": that.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                that.setState({
                    users: result.data.users,
                    teams: result.data.teams
                })
                if (result.data.score !== null) {
                    if (result.data.score.d106ScoreCard.byTeamScoreCard) {
                        if (result.data.score.d106ScoreCard.byTeamScoreCard.playerScores) {
                            if (result.data.score.d106ScoreCard.byTeamScoreCard.playerScores !== null) {
                                that.setState({ byplayerScores: result.data.score.d106ScoreCard.byTeamScoreCard.playerScores });
                                that.setState({ bySetWon: result.data.score.d106ScoreCard.byTeamScoreCard.teamGoals, byWinner: result.data.score.d106ScoreCard.byTeamScoreCard.winner });
                            }
                            else {
                                that.setState({ bySetWon: 0, byWinner: result.data.score.d106ScoreCard.byTeamScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }
                    }

                    if (result.data.score.d106ScoreCard.toTeamScoreCard) {
                        if (result.data.score.d106ScoreCard.toTeamScoreCard.playerScores !== undefined) {
                            if (result.data.score.d106ScoreCard.toTeamScoreCard.playerScores !== null) {
                                that.setState({ toplayerScores: result.data.score.d106ScoreCard.toTeamScoreCard.playerScores });
                                that.setState({ toSetWon: result.data.score.d106ScoreCard.toTeamScoreCard.teamGoals, toWinner: result.data.score.d106ScoreCard.toTeamScoreCard.winner });
                            }
                            else {
                                that.setState({ toSetWon: 0, toWinner: result.data.score.d106ScoreCard.toTeamScoreCard.winner });
                            }
                            that.setState({ matchStatus: true });
                        }
                    }

                    that.setState({ rounds: result.data.score.d106ScoreCard.rounds, scorecard: result.data.score.d106ScoreCard })

                    that.props.profileUsers.map(user => {
                        if (result.data.score.d106ScoreCard.byTeamScoreCard) {
                            if (user.contactNo === result.data.score.d106ScoreCard.byTeamScoreCard.id) {
                                that.setState({ byTeamScoreCard: user.name });
                            }
                        }

                        if (result.data.score.d106ScoreCard.toTeamScoreCard) {
                            if (user.contactNo === result.data.score.d106ScoreCard.toTeamScoreCard.id) {
                                that.setState({ toTeamScoreCard: user.name });
                            }
                        }
                    })
                    that.props.profileTeams.map(team => {
                        if (result.data.score.d106ScoreCard.byTeamScoreCard) {
                            if (team.teamId === result.data.score.d106ScoreCard.byTeamScoreCard.teamId) {
                                that.setState({ byTeamScoreCard: team.teamName });
                            }
                        }
                        else {
                            let byIDS = result.data.score.matchId.split('-');
                            if (team.teamId === byIDS[1]) {
                                that.setState({ byTeamScoreCard: team.teamName });
                            }
                        }

                        if (result.data.score.d106ScoreCard.toTeamScoreCard) {
                            if (team.teamId === result.data.score.d106ScoreCard.toTeamScoreCard.teamId) {
                                that.setState({ toTeamScoreCard: team.teamName });
                            }
                        }
                        else {
                            let toIDS = result.data.score.matchId.split('-');
                            if (team.teamId === toIDS[2]) {
                                that.setState({ byTeamScoreCard: team.teamName });
                            }
                        }
                    })

                }
            });
        if (that.props.challenge.challengeAction === 'ended') {

            that.setState({ matchStatus: true });
        }
    }

    render() {
        var teamMemberName, teamMemberPic;

        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;
        if (this.state.matchStatus) {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video " style={{
                                backgroundImage: "url(" + noBroadcast + ")"
                            }}>
                                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                            </div>
                            <div class="tableresponsive">
                                <div class="tableinner">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Teams</th>
                                                <th>Goals</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{this.state.byTeamScoreCard}</td>
                                                <td>{this.state.bySetWon ? this.state.bySetWon : 0}</td>
                                                {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>
                                            <tr>
                                                <td>{this.state.toTeamScoreCard}</td>
                                                <td>{this.state.toSetWon ? this.state.toSetWon : 0}</td>
                                                {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <h4 class="scorehead">Match Score</h4>
                            <div class="tableresponsive">
                                <div class="tableinner" style={{
                                    display: 'flex'
                                }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> {this.state.byTeamScoreCard} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.byplayerScores.map(score => {
                                                this.state.users.map(user => {
                                                    if (user.contactNo === score.userId) {
                                                        teamMemberName = user.name;
                                                        teamMemberPic = user.profilePic;
                                                    }
                                                })
                                                return <tr>
                                                    <td>
                                                        <div>
                                                            {teamMemberName}
                                                        </div>
                                                        <p>
                                                            <span> Goals:</span>    {score.goalPoints}
                                                        </p>
                                                    </td>
                                                </tr>
                                            })}

                                        </tbody>
                                    </table>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th> {this.state.toTeamScoreCard}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.toplayerScores.map(score => {
                                                this.state.users.map(user => {
                                                    if (user.contactNo === score.userId) {
                                                        teamMemberName = user.name;
                                                        teamMemberPic = user.profilePic;
                                                    }
                                                })
                                                return <tr>
                                                    <td>
                                                        <div>
                                                            {teamMemberName}
                                                        </div>
                                                        <p>
                                                            <span> Goals:</span>    {score.goalPoints}
                                                        </p>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div class="tableresponsive">
                                <div class="tableinner">
                                </div>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <section class="d_midsec createmidsec broadcast_box">
                        <div class="commanbox cp_profile">
                            <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                                <h3>Match has not started yet.</h3>
                            </div>
                            <div class="table_actions winner_actions">
                                <a href={backLinks} class="cmnbutton backred fl">Back</a>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
    }
}
