import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import GetCurrentTimestamp from './GetCurrentTimestamp';
import Header from './Header';
import SectionLeft from './SectionLeft';
import SectionRight from './SectionRight';
import NoTeam from "./img/mytournament.svg";
import Modal from 'react-bootstrap/Modal';
import BuddyRequest from './BuddyRequest';
import ChatonRight from './ChatonRight';
import challenegbg from './img/challenegbg.png';
import showDefaultTeam from './img/team.png';
import camera from './img/camera.png';
import searchnew from './img/searchnew.png';
import maleIcon from './img/male.png';
import femaleIcon from './img/female.png';
import ImgDropAndCrop from './ImgDropAndCrop';
import ConfigUrl from './ConfigUrl';
import Map from './Map';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
let allBuddies = [], maleUser = [], femaleUser = [], mixUser = [];
let genderSelection = null, inclusion = null;
let takeOnlySportGlobal = null, takeOnlySportIdsGlobal = null

function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}
function searchingFor1(term1) {
    return function (x) {
        return x.name.toLowerCase().includes(term1.toLowerCase()) || !term1;
    }
}

class TeamCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allSports: [],
            minPlayers: '',
            maxPlayers: '',
            allBuddies: [],
            maleUser: [],
            femaleUser: [],
            mixUser: [],
            multiUser: [],
            tickCount: [],
            takeOnlySport: '',
            takeOnlySportIds: '',
            genderSelection: '',
            inclusion: '',
            teamName: '',
            locationName: '',
            teamFirstPart: true,
            timetoStamp: '',
            showingAlert: false,
            showingAlert1: false,
            showingAlert2: false,
            savePic: null,
            term: '', //for searchbar
            term1: '', //for searchbar
            defaultDisableForOwner: false,
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.searchHandler = this.searchHandler.bind(this);
        this.searchHandler1 = this.searchHandler1.bind(this);
        this.liRefs = [React.createRef()];
    }

    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }
    searchHandler1(event) {
        this.setState({ term1: event.target.value })
    }

    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData, openLocation: false })
        this.setState({ address: childData.address, latitude: childData.latValue, longitude: childData.lngValue, city: childData.city, state: childData.state, country: childData.country })
    }

    componentDidMount() {
        this.setState({ address: this.state.user.address, latitude: this.state.user.latValue, longitude: this.state.user.lngValue, city: this.state.user.city, state: this.state.user.state, country: this.state.user.country })
        this.getSportsFunction();
        this.getBuddyFunction();
        this.timetoStamp();
    }

    timetoStamp = () => {
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        this.setState({
            timetoStamp: timpstampData,
        });
        console.log(this.state.timetoStamp)
    }

    //get-buddies API is called from here
    getBuddyFunction = () => {
        const getAllBuddyfromUser = rshApi + "/users/get-buddies";
        const that = this;
        fetch(getAllBuddyfromUser, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "buddyId": this.state.user.contactNo
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result)
                if (!result.error) {
                    maleUser = result.data.filter(user => {
                        return user.gender === 'Male';
                    });
                    femaleUser = result.data.filter(user => {
                        return user.gender === 'Female';
                    });
                    mixUser = result.data.filter(user => {
                        return user.gender === 'Female' || user.gender === 'Male';
                    });
                    allBuddies = result.data;

                    that.setState({
                        allBuddies: result.data,
                        maleUser: maleUser,
                        femaleUser: femaleUser,
                        mixUser: result.data,
                    });
                    // console.log(allBuddies);
                    // console.log(maleUser);
                    // console.log(femaleUser);
                    // console.log(mixUser);
                }

            });
    }

    //get-sports API is called from here
    getSportsFunction = () => {
        const apiUrl = rshApi + "/sports/get-sports";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "minimal": "false",
                    "isGroupGame": "isGroupGame"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result);
                that.setState({
                    allSports: result.data
                });
            });
    }

    //code to add players to team
    takeUsers = (event) => {
        let checkedArray = this.state.multiUser;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                multiUser: checkedArray,
                tickCount: checkedArray.length,
            });

        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiUser: checkedArray,
                tickCount: checkedArray.length,
            });
        }
    }

    //code to take sports
    chooseSport = (takeOnlySport, takeOnlySportIds) => {

        this.state.allSports.map(m => {
            if (m.sportsId === takeOnlySportIds) {
                document.getElementById('displayAlert').style = 'visible'; //alertbox
                let minPlayer = m.minPlayer;
                let maxPlayer = m.maxPlayer;
                this.setState({
                    minPlayers: minPlayer,
                    maxPlayers: maxPlayer,
                })
            }
            return '';
        })

        if (takeOnlySport === 'Baseball' && this.state.user.gender === 'Male') {
            this.setState({
                defaultDisableForOwner: false,
            });
            document.getElementById('checkbox-example-3').checked = true;
            document.getElementById('ctinclude2').checked = true;
            genderSelection = 'Male';
            inclusion = 2;

            allBuddies = [];
            allBuddies = maleUser;

        }
        else if (takeOnlySport === 'Baseball' && this.state.user.gender === 'Female') {
            this.setState({
                defaultDisableForOwner: true,
            });
            document.getElementById('checkbox-example-3').checked = true;
            document.getElementById('ctinclude2').checked = true;
            genderSelection = 'Male';
            inclusion = 2;

            allBuddies = [];
            allBuddies = maleUser;
        }

        else if (takeOnlySport === 'Softball' && this.state.user.gender === 'Female') {
            this.setState({
                defaultDisableForOwner: false,
            });
            document.getElementById('checkbox-example-4').checked = true;
            document.getElementById('ctinclude2').checked = true;
            genderSelection = 'Female';
            inclusion = 2;

            allBuddies = [];
            allBuddies = femaleUser;
        }
        else if (takeOnlySport === 'Softball' && this.state.user.gender === 'Male') {
            this.setState({
                defaultDisableForOwner: true,
            });
            document.getElementById('checkbox-example-4').checked = true;
            document.getElementById('ctinclude2').checked = true;
            genderSelection = 'Female';
            inclusion = 2;

            allBuddies = [];
            allBuddies = femaleUser;
        }

        else {
            this.setState({
                defaultDisableForOwner: false
            });
            document.getElementById('checkbox-example-3').checked = true;
            document.getElementById('ctinclude2').checked = true;

            genderSelection = 'Male';
            inclusion = 2;

            allBuddies = [];
            allBuddies = maleUser;
        }

        this.setState({
            takeOnlySport: takeOnlySport,
            takeOnlySportIds: takeOnlySportIds,
            openSports: false,
        });
        takeOnlySportGlobal = takeOnlySport;
        takeOnlySportIdsGlobal = takeOnlySportIds;
        // console.log(takeOnlySportGlobal);
        // console.log(takeOnlySportIdsGlobal);
        // console.log(genderSelection)
        // console.log(inclusion)
        // console.log(allBuddies)
    }

    //gender selection
    genderSelection = (e) => {
        let genderSelections = e.target.value;
        if (e.target.checked === true) {
            if (genderSelections === 'Male') {
                genderSelection = 'Male';
                allBuddies = [];
                allBuddies = maleUser;
            }
            else if (genderSelections === 'Female') {

                genderSelection = 'Female';
                allBuddies = [];
                allBuddies = femaleUser;
            }
            else {
                genderSelection = 'Mix';
                allBuddies = [];
                allBuddies = mixUser;
            }
            // console.log(genderSelection)
            // console.log(allBuddies)
        }
    }

    //owner or ownerPlayer selection
    inclusion = (event) => {
        let inclusions = event.target.value;
        if (event.target.checked === true) {
            switch (inclusions) {
                case '1':
                    inclusion = 1;
                    break

                default:
                    inclusion = 2;
            }
            // console.log(inclusion)
        }
    }

    //next button code
    showNext = () => {
        let NoError = false;
        if (genderSelection) {
            if (takeOnlySportIdsGlobal) {
                if (this.state.teamName) {
                    NoError = true;
                }
            }
        }
        if (NoError) {
            const apiUrl = rshApi + "/teams/team-exists";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "contactNo": this.state.user.contactNo,

                        "sportsName": takeOnlySportGlobal,

                        "teamName": this.state.teamName,

                        "city": this.state.user.address
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data === "success") {
                        that.setState({ teamFirstPart: false }, () => {
                            that.testInput1 && that.testInput1.focus()
                        });
                    }
                    else {
                        // document.getElementById('already').style = 'block';
                        that.setState({ showingAlert: true });
                        // setTimeout(() => {
                        //     that.setState({ showingAlert: false });
                        // }, 300);
                    }
                });
        }
        else {
            this.setState({
                createTeamNextError: true,
                // showingAlert2: true 
            })
            // setTimeout(() => {
            //     this.setState({ createTeamNextError: false, showingAlert2: false })
            // }, 2000)
        }
    }

    //select buddies & create team 
    sendBud = () => {
        console.log(this.state.multiUser); //console check of buddies who are selected
        console.log(this.state.minPlayers);
        console.log(this.state.maxPlayers)
        console.log(this.state.tickCount);

        if (this.state.minPlayers > this.state.tickCount) {
            // document.getElementById('already2').style = 'block';
            this.setState({
                showingAlert2: true
            });
            // setTimeout(() => {
            //     this.setState({
            //         showingAlert2: false
            //     });
            // }, 2000);
        }

        else {
            if (this.state.minPlayers <= this.state.tickCount) {
                if (inclusion === 1) {
                    this.setState({ multiUser: this.state.multiUser.push(this.state.user.contactNo) })
                }
                let that = this;
                const apiUrl = rshApi + "/teams/create-team";
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "team": {
                                active: true,
                                captain: null,
                                city: this.state.city,
                                country: this.state.country,
                                createdBy: this.state.user.contactNo,
                                createdOn: this.state.timetoStamp,
                                eligible: true,
                                gender: genderSelection,
                                latitude: this.state.latitude,
                                location: this.state.address,
                                longitude: this.state.longitude,
                                modifiedOn: this.state.timetoStamp,
                                ownerPlayingOption: inclusion,
                                selected: false,
                                sportsId: takeOnlySportIdsGlobal,
                                sportsName: takeOnlySportGlobal,
                                state: this.state.state,
                                teamIcon: this.state.savePic ? this.state.savePic : null,
                                teamId: null,
                                teamMembers: this.state.multiUser,
                                teamName: this.state.teamName,
                            }
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                        if (that.state.imageCrop !== undefined) {
                            that.submitPicture(result.data)
                        }
                        else {
                            window.location.href = "/teamprofilepage/index.html?id=" + result.data.teamId;
                        }

                    });
                // document.getElementById('already1').style = 'block';
                // this.setState({
                //     showingAlert1: true
                // });
                // setTimeout(() => {
                //     this.setState({
                //         showingAlert1: false
                //     });
                // }, 2500);
                // document.getElementById('disableSuccess').disabled = true;
            }
        }
    }

    //submit pic code
    handleLanguage = (langValue) => {
        this.setState({ imageCrop: langValue, savePic: langValue });
    }

    submitPicture = (team) => {
        this.setState({ team: team })
        const that = this;
        const apiUrl = rshApi + "upload-file";
        let dataurl = this.state.imageCrop;

        function dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
        var file = dataURLtoFile(dataurl, 'image.jpg');

        if (file !== undefined) {
            const currentTimeStamp = GetCurrentTimestamp();
            var data = {
                "data": {
                    "folder": "teams/" + team.teamId,
                    "fileName": "IMG_" + currentTimeStamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result.data.downloadUrl)
                    that.setState({ savePic: result.data.downloadUrl });
                    uploadPictures(result.data.downloadUrl);
                });
            function uploadPictures(data) {
                delete team.id;
                team.teamIcon = data;

                fetch(rshApi + "/teams/update-team", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            team: team,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        window.location.href = "/teamprofilepage/index.html?id=" + team.teamId;
                    });
            }
        }


    }

    openSports = () => {
        this.setState({ openSports: true }, () => {
            this.testInput && this.testInput.focus()
        })
    }
    closeSports = () => {
        this.setState({ openSports: false, createTeamNextError: false, showingAlert: false, showingAlert2: false })
    }
    openLocation = () => {
        this.setState({ openLocation: true })
    }
    closeLocation = () => {
        this.setState({ openLocation: false })
    }

    render() {
        const { term, allSports } = this.state;
        const { term1, allBuddiesSearch } = this.state;

        return (
            <div>
                <Header />

                <Modal
                    size="md"
                    show={this.state.showingAlert}
                    onHide={this.closeSports}
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body modal-bodyErrorPopup">
                        <p className="alert alert-danger">
                            <strong>Team name already exists please select another team name.</strong>
                        </p>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.showingAlert2}
                    onHide={this.closeSports}
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body modal-bodyErrorPopup">
                        <p className="alert alert-danger">
                            <strong>Please select {this.state.minPlayers} members to make a team.</strong>
                        </p>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.createTeamNextError}
                    onHide={this.closeSports}
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body modal-bodyErrorPopup">
                        <p className="alert alert-danger">
                            <strong>Please Complete Your Team's Information!</strong>
                        </p>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.openLocation}
                    onHide={this.closeLocation}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Location</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <span id="searchLocation" style={{ "borderBottom": '1px' }}>
                                        <Map parentCallback={this.callbackFunction} />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.openSports}
                    onHide={this.closeSports}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Sports</h4>
                        </div>

                        <div className="modal-body modal-bodyD modal-bodySport">
                            <div className="createselect_team">
                                <div className="createselect_search">
                                    <form>
                                        <input ref={(input) => { this.testInput = input; }} onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form>
                                </div>
                                <ul className="ct_selectsports">
                                    {
                                        allSports.filter(searchingFor(term)).map(item => {
                                            if (item.teamMode > 0) {
                                                return <div key={item.name}>
                                                    <li className="li_selectsports">
                                                        <span onClick={() => this.chooseSport(item.name, item.sportsId)} className="assignimg" style={{ backgroundImage: "url(" + item.icon + ")" }}></span>
                                                        <p onClick={() => this.chooseSport(item.name, item.sportsId)}>{item.name}</p>
                                                    </li>
                                                </div>
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </Modal>

                <div className="container containerbottom non-session-d">
                    <SectionLeft />

                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">

                            {/* Modal content UPLOAD AND CROP */}
                            <div id="openCropModalTC" className="modal fade" role="dialog">
                                <div className="modal-dialog">

                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                            <h4 className="modal-title">Upload Image</h4>
                                        </div>

                                        <div className="modal-body">
                                            <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="cmnbutton" data-dismiss="modal">Crop & Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.teamFirstPart ? <div>
                                <div className="divpost">
                                    <div className="createteam_div" style={{ backgroundImage: "url(" + challenegbg + ")" }}>
                                        <button>Change Picture</button>
                                        <div className="uploadimg_div">
                                            <span style={{ backgroundImage: this.state.savePic ? "url(" + this.state.savePic + ")" : "url(" + showDefaultTeam + ")" }}></span>
                                            <Link data-toggle="modal" data-target="#openCropModalTC"><img src={camera} alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="createteamform">
                                    <ul>
                                        <li>
                                            <div className="commaninputdiv">
                                                <input onClick={this.openSports} type="text" name="name" className="question" id="" required="true" autoComplete="off" value={this.state.takeOnlySport} />
                                                <label htmlFor=""><span>Select Sports</span></label>
                                            </div>
                                        </li>

                                        {this.state.allSports.slice(0, 1).map(item => {
                                            switch (this.state.takeOnlySport) {

                                                case 'Baseball':

                                                    return <li className="cc_genderli">
                                                        <div className="commaninputdiv creategender">
                                                            <label>Gender</label>

                                                            <div className="creategenderdiv" onClick={this.genderSelection.bind(this)}>
                                                                <div className="styled-input-single createinput male">
                                                                    <input required type="radio" name="selectusertype" id="checkbox-example-3" value="Male" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-3"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput female">
                                                                    <input disabled required type="radio" name="selectusertype" id="checkbox-example-4" value="Female" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-4"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput mix">
                                                                    <input disabled required type="radio" name="selectusertype" id="checkbox-example-5" value="Mix" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-5"></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>


                                                case 'Softball':

                                                    return <li className="cc_genderli">
                                                        <div className="commaninputdiv creategender">
                                                            <label>Gender</label>

                                                            <div className="creategenderdiv" onClick={this.genderSelection.bind(this)}>
                                                                <div className="styled-input-single createinput male">
                                                                    <input disabled required type="radio" name="selectusertype" id="checkbox-example-3" value="Male" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-3"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput female">
                                                                    <input required type="radio" name="selectusertype" id="checkbox-example-4" value="Female" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-4"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput mix">
                                                                    <input disabled required type="radio" name="selectusertype" id="checkbox-example-5" value="Mix" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-5"></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>

                                                default:

                                                    return <li className="cc_genderli">
                                                        <div className="commaninputdiv creategender">
                                                            <label>Gender</label>

                                                            <div className="creategenderdiv" onClick={this.genderSelection.bind(this)}>
                                                                <div className="styled-input-single createinput male">
                                                                    <input required type="radio" name="selectusertype" id="checkbox-example-3" value="Male" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-3"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput female">
                                                                    <input required type="radio" name="selectusertype" id="checkbox-example-4" value="Female" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-4"></label>
                                                                </div>
                                                                <div className="styled-input-single createinput mix">
                                                                    <input required type="radio" name="selectusertype" id="checkbox-example-5" value="Mix" />
                                                                    <label className="gender-d" htmlFor="checkbox-example-5"></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                            }

                                        })}

                                        <li>
                                            <div className="commaninputdiv team-namespace">
                                                <input type="text" name="name" className="question" id="" required="true" autoComplete="off" value={this.state.teamName} onChange={event => this.setState({ teamName: event.target.value })} />
                                                <label htmlFor=""><span>Team Name</span></label>
                                            </div>
                                        </li>

                                        {this.state.allSports.slice(0, 1).map(item => {
                                            if (genderSelection === this.state.user.gender) {
                                                return <li>
                                                    <div className="commaninputdiv createredio">
                                                        <div className="styled-input-single createinput">
                                                            <input disabled={this.state.defaultDisableForOwner} onClick={this.inclusion.bind(this)} type="radio" name="ct_part" id="ctinclude1" value="1" />
                                                            <label htmlFor="ctinclude1">Include me in the team (Owner and player)</label>
                                                        </div>
                                                        <div className="styled-input-single createinput">
                                                            <input onClick={this.inclusion.bind(this)} type="radio" name="ct_part" id="ctinclude2" value="2" />
                                                            <label htmlFor="ctinclude2">Don't include me in the team (Owner Only)</label>
                                                        </div>
                                                    </div>
                                                </li>
                                            }

                                            if (genderSelection !== this.state.user.gender) {
                                                return <li>
                                                    <div className="commaninputdiv createredio">
                                                        <div className="styled-input-single createinput">
                                                            <input disabled={this.state.defaultDisableForOwner} onClick={this.inclusion.bind(this)} type="radio" name="ct_part" id="ctinclude1" value="1" />
                                                            <label htmlFor="ctinclude1">Include me in the team (Owner and player)</label>
                                                        </div>
                                                        <div className="styled-input-single createinput">
                                                            <input onClick={this.inclusion.bind(this)} type="radio" name="ct_part" id="ctinclude2" value="2" />
                                                            <label htmlFor="ctinclude2">Don't include me in the team (Owner Only)</label>
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                            return '';
                                        })}


                                        <li>
                                            <div className="location_space-d">
                                                <input type="text" name="name" className="question" id="" required="true" autoComplete="off" value={this.state.address} onClick={this.openLocation} />
                                                <label htmlFor=""><span>Select Location</span></label>
                                                {/* <span id="searchLocation">
                                                    <Map parentCallback={this.callbackFunction} />
                                                </span> */}
                                            </div>
                                        </li>
                                    </ul>

                                    <div id="displayAlert" style={{ display: 'none' }} className="addalert">
                                        <Link to={'#'}>
                                            {/* <img src={closePNG} alt="" /> */}
                                        </Link>
                                        <label>Add Members To Team</label>
                                        <p>Add buddies to team requires min players: {this.state.minPlayers}</p>
                                        <p>If you don’t add minimum number of players in a team then the team will not be created.</p>
                                        <p>Max Players: {this.state.maxPlayers}</p>
                                        {/* <p id="already" style={{ display: 'none' }} className={`alert alert-danger ${this.state.showingAlert ? 'alert-shown' : 'alert-hidden'}`}>
                                            <strong>Invaild Details!</strong> OR Team Already Exist!
                                        </p> */}
                                    </div>

                                    <button onClick={this.showNext.bind(this)} className="createnext">Next</button>
                                </div>
                                {/* {this.state.createTeamNextError ? <p className="alert alert-danger">
                                    <strong>Error!</strong> Please Complete Your Team's Information! </p> : null} */}
                            </div>
                                :
                                <div id="part2" >
                                    <div className="commanbox">
                                        <h3 className="commanhead">Buddies</h3>
                                        <div className="ct_searchbuddies">
                                            <form>
                                                <input ref={(input) => { this.testInput1 = input; }} onChange={this.searchHandler1} value={term1} type="search" placeholder="Search" />
                                                <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                            </form>
                                        </div>

                                        <label className="addbuddies_count">You have Selected : <label>{this.state.tickCount} Buddies</label></label>

                                        <ul className="buddieslist_ul ct_buddies">
                                            {allBuddies.length ?
                                                allBuddies.filter(searchingFor1(term1)).map((item, index1) => {
                                                    let defaultChecking = false;
                                                    this.state.multiUser.map(mu => {
                                                        if (mu === item.contactNo) {
                                                            defaultChecking = true;
                                                        }
                                                    })
                                                    if (item.profilePic === null) {
                                                        switch (item.gender) {
                                                            case 'Male':
                                                                return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                                    <Link to={"#"}>
                                                                        <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                        <label>{item.name}</label>
                                                                    </Link>
                                                                    <div className="styled-input-single">
                                                                        <input ref={el => this.liRefs[item.contactNo] = el} onChange={this.takeUsers.bind(this)} checked={defaultChecking} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />
                                                                        <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                                    </div>
                                                                </li>

                                                            default:
                                                                return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                                    <Link to={"#"}>
                                                                        <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                        <label>{item.name}</label>
                                                                    </Link>
                                                                    <div className="styled-input-single">
                                                                        <input ref={el => this.liRefs[item.contactNo] = el} onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} checked={defaultChecking} />
                                                                        <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                                    </div>
                                                                </li>
                                                        }
                                                    }
                                                    else {
                                                        return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                            <Link to={"#"}>
                                                                <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                <label>{item.name}</label>
                                                            </Link>
                                                            <div className="styled-input-single">
                                                                <input ref={el => this.liRefs[item.contactNo] = el} onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} checked={defaultChecking} />
                                                                <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                            </div>
                                                        </li>
                                                    }
                                                })
                                                : <div className="broadefaultCheckingast_video">
                                                    <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                                    <h3>Sorry you don't have any buddies.</h3>
                                                </div>
                                            }
                                        </ul>

                                        <div className="modal-footer">
                                            <p id="already1" style={{ display: 'none' }} className={`alert alert-success ${this.state.showingAlert1 ? 'alert-shown' : 'alert-hidden'}`}>
                                                <strong>Success!</strong> Team Created!
                                            </p>
                                            {/* <p id="already2" style={{ display: 'none' }} className={`alert alert-danger ${this.state.showingAlert2 ? 'alert-shown' : 'alert-hidden'}`}>
                                                <strong>Alert!</strong> Please select minimum players to create a team!
                                            </p> */}

                                            <button id="disableSuccess" onClick={this.sendBud.bind(this)} type="button" className="cmnbutton" data-dismiss="modal">Done</button>
                                        </div>
                                    </div>
                                </div>
                            }


                        </section>
                    </div>

                    {/*<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                       <section className="d_rightsec">
                            <BuddyRequest />

                        </section>

                    </div>*/}
                    <SectionRight />

                </div>
            </div>
        );
    }
}
export default TeamCreation;
