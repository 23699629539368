import React, { Component } from 'react'
import scheduleMatches from "../img/schedulematches.png";
import location from "../img/location.png";
import Modal from 'react-bootstrap/Modal';
import challengefill from "../img/challengefill.png";
import teamIcon from "../img/team.png";
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import * as TournamentsUtil from '../DemoTournament/DTournamentsUtil';
let name, pic;
let finalFirstName, finalSecondName, finalEnded = true, finalmatchId;
let finalMatchArray = [];

let organiseMatchObj = {};
export default class DOrganiseMatchTT4List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMiddlesection: false,
            showNextRound: false,
            teamUserArray: [],
            multiTeamsNext: [],
            showFinal: false,

        }
    }
    viewTourScore = (categoryId, ttType, mid) => {
        let passObj = { cId: categoryId, ttType: ttType, mid: mid }
        this.props.viewTT4Parrent(passObj)
    }
    checkSportName = (c_ID, TType, matchId, fType) => {
        let passObj = { c_ID: c_ID, TType: TType, matchId: matchId, fType: fType }
        this.props.updateTT4Parrent(passObj)
    }
    nextRound = () => {
        let teamUserArray = [];
        this.props.tt4Matches.tt4Matches.map((tt4, mIndex) => {

            tt4.matchesList.map(ml => {
                let teamUserObj = { name: null, pic: null, id: null };
                this.props.usersInTournament.map(user => {
                    if (user.contactNo === ml.playerId) {
                        teamUserObj.name = user.name;
                        teamUserObj.id = user.contactNo;
                        teamUserObj.pic = user.profilePic;
                        teamUserObj.gender = user.gender;
                        teamUserArray.push(teamUserObj)
                    }
                })
            })
        })
        console.log(teamUserArray)

        this.setState({ showUesrTeamForNextMatch: true, teamUserArray: teamUserArray })
    }
    componentDidMount = () => {
        if (this.props.tt4Matches.quarterFinalList) {

        }
        else if (this.props.tt4Matches.semiFinalList) {

        } else {
            let ended = false;
            this.props.tt4Matches.tt4Matches.map(tt4 => {
                ended = tt4.ended;
            })
            if (ended) {
                if (this.props.tt4Matches.finals.attempts > 0) {
                    organiseMatchObj = this.props.tt4Matches;
                    this.setState({ showFinal: true, showNextRound: false });
                }
                else {
                    this.setState({ showNextRound: true })
                }
            }
            else {


            }
        }
    }
    closeTeamUserForMatch() {
        this.setState({ showUesrTeamForNextMatch: false })
    }

    saveUserTeamForNext = (event) => {
        let checkedArray = [...this.state.multiTeamsNext];
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                multiTeamsNext: checkedArray,
                // tickCountForNext: checkedArray.length,
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiTeamsNext: checkedArray,
                // tickCountForNext: checkedArray.length,
            });
        }
    }

    saveTeamUserForMatch() {
        this.setState({ showUesrTeamForNextMatch: false })
        let tId = this.props.tournamentData.tournamentId;
        organiseMatchObj = this.props.tt4Matches;
        let matchListArray = [];
        this.state.multiTeamsNext.map(mTN => {
            let matchListObj = {
                matchId: null,
                playerId: null,
                poolNo: 1,
                index: 0
            }
            matchListObj.playerId = mTN;
            matchListObj.matchId = tId + '-' + mTN;
            matchListArray.push(matchListObj);
        })
        organiseMatchObj.finals.matchesList = matchListArray;
        console.log(organiseMatchObj.finals)
        delete organiseMatchObj.id;

        this.setState({ showFinal: true, showNextRound: false });
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
    }
    render() {
        return (
            <>
                <Modal
                    size="md"
                    show={this.state.showUesrTeamForNextMatch}
                    animation={false}
                    onHide={() => this.closeTeamUserForMatch()}
                >
                    <Modal.Header >
                        <button type="button" className="close" onClick={() => this.closeTeamUserForMatch()}>&times;</button>
                        <Modal.Title id="example-modal-sizes-title-md">
                            Select Buddies
                    </Modal.Title>
                    </Modal.Header>
                    <div className="modal-body">

                        <div className="ct_searchbuddies">

                        </div>
                        {/* {(() => {
                            if (this.state.tournamentData.tournamentMode === 'buddy') {
                                return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCountForNext} Buddies</label>
                                    <label>{this.state.multiTeamsNextError} </label>
                                </label>
                            }
                            else {
                                return <label className="addbuddies_count">You have Selected : <label>{this.state.tickCountForNext} Teams</label>
                                    <label>{this.state.multiTeamsNextError} </label>
                                </label>
                            }
                        })()} */}

                        <ul className="buddieslist_ul ct_buddies">

                            {
                                this.state.teamUserArray.map((items, index2) => {
                                    let pic = items.pic ? items.pic : items.gender ? maleIcon : femaleIcon;
                                    var disableData1 = false;
                                    {/* invitedList.map(i => {
                                        if (i === items.teamId) {
                                            disableData1 = true;
                                        }
                                        return true;
                                    }) */}
                                    return <li className="deselect" key={index2}>
                                        <a href="">
                                            <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                            <label>{items.name}</label>

                                        </a>
                                        <div className="styled-input-single">
                                            <input onChange={this.saveUserTeamForNext.bind(this)} type="checkbox" name="fieldset-6" id={"checkBox" + index2} value={items.id} />

                                            <label htmlFor={"checkBox" + index2}></label>
                                        </div>
                                    </li>
                                })
                            }

                        </ul>
                        <div className="modal-footer">
                            <button type="button" onClick={() => this.saveTeamUserForMatch()} className="cmnbutton">Done</button>
                        </div>
                    </div>
                </Modal>
                <div class="back_white">
                    {this.props.tt4Matches.tt4Matches.map((a, index) => {
                        var matchListArray = [];
                        var venue;
                        var dateplace;
                        var timeplace;
                        var team1, team2;
                        var ended, matchId = [];
                        venue = a.location;
                        dateplace = a.date;
                        timeplace = a.time;
                        if (a.ended === false) {
                            ended = false;
                        }
                        else {
                            ended = true;
                        }
                        var matches = a.matchesList.map(match => {
                            if (this.props.tournamentMode === 'team') {
                                this.props.teamsInTournament.map(b => {
                                    if (match.playerId === b.teamId) {
                                        name = b.teamName;
                                        pic = b.teamIcon;
                                        matchId.push(match.matchId);

                                    }

                                })
                            }
                            else {
                                this.props.usersInTournament.map(b => {
                                    if (match.playerId === b.contactNo) {
                                        name = b.name;
                                        pic = b.profilePic;
                                        matchId.push(match.matchId);

                                    }

                                })
                            }


                            let matchList = <li><a> <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                <label>{name}</label>
                            </a></li>
                            matchListArray.push(matchList);
                        })
                        return <>
                            <div class="pool-number">
                                <label> POOL -{a.poolNo}</label>
                            </div>
                            <div class="straight_inputs">
                                <div class="fixture_date">
                                    <input readOnly placeholder={"Date:- " + dateplace + " Time:- " + timeplace} />
                                    <img src={scheduleMatches} alt="" />
                                </div>
                                <div class="fixture_date fixture_location">
                                    <input readOnly placeholder={"Venue:- " + venue} />
                                    <img src={location} alt="" />
                                </div>
                                {ended
                                    ?
                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, index, -2)} className="cmnbuttonup">View Score</button>
                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, index, -2)} className="cmnbuttonup1">Update Score</button>
                                }
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="completedmatches">
                                    <ul class="buddieslist_ul ct_buddies">
                                        {matchListArray}
                                    </ul>
                                </div>
                            </div>
                        </>
                    })}

                    <div>




                        {this.state.showNextRound ? <center>
                            <button type="button" onClick={() => this.nextRound()} class="createnext" type="submit">Next Round Qualification </button>
                        </center> : ""}

                    </div>

                </div>
                {this.state.showFinal ? <React.Fragment>
                    <div class="semi_headingdiv">
                        <label >Final</label>
                    </div>

                    <div class="back_white">

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="completedmatches">
                                <ul class="scoreboxul tourcompleted_match">

                                    <li class="leauge_matchdiv">
                                        <div class="teamdiv">
                                            <ul class="live_teamul live_teamul1 straight_ul">
                                                <li>
                                                    <span class="lt_img"
                                                        style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                    <label class=" straight_player">{finalFirstName}</label>
                                                </li>
                                                <li>
                                                    <img class="vsicon" src={challengefill} alt="" />
                                                </li>
                                                <li>
                                                    <span class="lt_img"
                                                        style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                    <label class="straight_player">{finalSecondName}</label>
                                                </li>
                                            </ul>
                                            <div class="straight_inputs">
                                                <div class="fixture_date">
                                                    <input readOnly placeholder={"Date:- " + this.props.tt4Matches.finals.date + " Time:- " + this.props.tt4Matches.finals.time} />
                                                    <img src={scheduleMatches} alt="" />
                                                </div>
                                                <div class="fixture_date fixture_location">
                                                    <input readOnly placeholder={"Venue:- " + this.props.tt4Matches.finals.location} />
                                                    <img src={location} alt="" />
                                                </div>
                                                {organiseMatchObj.finals.ended
                                                    ?
                                                    <button onClick={() => this.viewTourScore(this.props.tournamentData.categoryId, 0, -3)} className="cmnbuttonup">View Score</button>
                                                    : <button onClick={() => this.checkSportName(this.props.tournamentData.categoryId, this.props.tournamentData.tournamentType, 0, -3)} className="cmnbuttonup1">Update Score</button>
                                                }
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="completedmatches">
                                                    <ul class="buddieslist_ul ct_buddies">
                                                        {organiseMatchObj.finals.matchesList.map(match => {

                                                            if (this.props.tournamentMode === 'team') {
                                                                this.props.teamsInTournament.map(b => {
                                                                    if (match.playerId === b.teamId) {
                                                                        name = b.teamName;
                                                                        pic = b.teamIcon;
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                this.props.usersInTournament.map(b => {
                                                                    if (match.playerId === b.contactNo) {
                                                                        name = b.name;
                                                                        pic = b.profilePic;
                                                                    }
                                                                })
                                                            }
                                                            return <li key={match.playerId}><a> <span style={{ backgroundImage: "url(" + pic + ")" }}></span>
                                                                <label>{name}</label>
                                                            </a></li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </React.Fragment> : ''}
            </>
        )
    }
}
