import React, { Component } from "react";
import userMaleIcon from "./img/male.png";
import userFemaleIcon from "./img/female.png";
import searchnew from "./img/searchnew.png";
import { trackPromise } from "react-promise-tracker";
import InviteQrcode from "./QrCode/InviteQrcode";
import { Link } from "react-router-dom";
import NoTeam from "./img/mytournament.svg";
import ConfigUrl from "./ConfigUrl";
const ConfigApiurl = ConfigUrl("apiUrl");
const userApi = ConfigApiurl;

function searchingFor(term) {
  return function (x) {
    return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
  };
}

class Buddies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buddies: [],
      term: "",
      showQr: false,
    };
    this.loadMore = this.loadMore.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
  }

  //Searching Bar
  searchHandler(event) {
    this.setState({ term: event.target.value });
  }

  loadMore = () => {
    const that = this;
    fetch(userApi + "users/get-buddies", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          buddyId: this.props.userbuddy,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        if (!result.error) {
          that.setState({
            buddies: result.data,
          });
        }
      });
  };

  componentDidMount() {
    this.loadMore();
  }

  QrCode1 = () => {
    this.setState({ showQr: true });
  };

  QrCode2 = () => {
    this.setState({ showQr: false });
  };

  render() {
    const { term, buddies } = this.state;
    return (
      <div className="commanbox" id="profilebuddies">
        <h3 className="commanhead">
          Buddies{" "}
          {this.props.permission ? (
            <>
              <button onClick={this.QrCode1} className="headbutton">
                Invite Buddies Via QR
              </button>
              {/* <button className="headbutton">Invite Buddies</button> */}
            </>
          ) : (
            <div></div>
          )}{" "}
        </h3>
        {this.state.showQr ? <InviteQrcode closeQr={this.QrCode2} /> : null}
        <div className="ct_searchbuddies">
          <form>
            <input
              onChange={this.searchHandler}
              value={term}
              type="search"
              placeholder="Search"
            />
            <Link to={"#"}>
              <img src={searchnew} alt="true" />
            </Link>
          </form>
        </div>
        <ul className="buddieslist_ul">
          {buddies.filter(searchingFor(term)).length ? (
            buddies.filter(searchingFor(term)).map((x) => {
              return (() => {
                if (x.profilePic === null) {
                  switch (x.gender) {
                    case "Male":
                      return (
                        <li key={x.contactNo}>
                          <a href={"/buddies?id=" + x.contactNo}>
                            <span
                              style={{
                                backgroundImage: "url(" + userMaleIcon + ")",
                              }}
                            ></span>
                            <label>{x.name}</label>
                          </a>
                        </li>
                      );
                    case "Female":
                      return (
                        <li key={x.contactNo}>
                          <a href={"/buddies?id=" + x.contactNo}>
                            <span
                              style={{
                                backgroundImage: "url(" + userFemaleIcon + ")",
                              }}
                            ></span>
                            <label>{x.name}</label>
                          </a>
                        </li>
                      );
                    default:
                      return "";
                  }
                } else {
                  return (
                    <li key={x.contactNo}>
                      <a href={"/buddies?id=" + x.contactNo}>
                        <span
                          style={{
                            backgroundImage: "url(" + x.profilePic + ")",
                          }}
                        ></span>
                        <label>{x.name}</label>
                      </a>
                    </li>
                  );
                }
              })();
            })
          ) : (
            <div className="broadcast_video">
              <a>
                <img
                  style={{ height: "auto", width: "250px" }}
                  className="matchnotstartedImg"
                  src={NoTeam}
                />
              </a>
              <h3>No buddy found with this name.</h3>
            </div>
          )}
        </ul>
      </div>
    );
  }
}

export default Buddies;
