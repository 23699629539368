import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
async function UpdateTournamentsScore(score) {
    console.log(score);
    const apiUrl = rshApi + "tournaments/score";
    let response = await fetch(apiUrl, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "data": {
                "tournamentScore": score,
            }
        })
    })
        .then(function (response) {
            return trackPromise(response.json());
        })
        .then(function (result) {
            console.log(result);
        });
    // let tournamentUpdateScore = await response.json();
    // return tournamentUpdateScore;
}

export default UpdateTournamentsScore; 