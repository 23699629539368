import React, { Component } from 'react'
import teamIcon from "./img/team.png";
import maleIcon from "./img/male.png";
import femaleIcon from "./img/female.png";
import winnerlogo from "./img/winnerlogo.png";
import challengefill from "./img/challengefill.png";
import myNoPointsTournament from "./img/mytournament.svg";
let completed = false;

export default class CompletedMatchForTT1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tt1NormalState: []
        }
    };

    componentDidMount() {
        if (this.props.tt1Data.type == 1) {
            let tt1Normal = [];

            this.props.tt1Data.fixtureType1.fixtureList.map((fl, i, arr) => {
                if (arr.length - 1 === i) {
                    //console.log(fl.fixturePairs[0]) // last one

                    let completedMatchDummyObj = {
                        gender: null,
                        firstId: null,
                        secondId: null,
                        winnerId: null,
                        firstName: null,
                        secondName: null,
                        firstIcon: null,
                        secondIcon: null,
                        matchDate: null,
                        matchTime: null
                    }
                    if (this.props.tournamentData.tournamentMode === 'team') {

                        this.props.teamsInTournament.map(n => {
                            if (fl.fixturePairs[0].ended) {
                                completed = true;
                                if (fl.fixturePairs[0].firstId === n.teamId) {
                                    completedMatchDummyObj.firstName = n.teamName;
                                    completedMatchDummyObj.firstIcon = n.teamIcon;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.firstId = fl.fixturePairs[0].firstId;
                                }
                                if (fl.fixturePairs[0].secondId === n.teamId) {
                                    completedMatchDummyObj.secondName = n.teamName;
                                    completedMatchDummyObj.secondIcon = n.teamIcon;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.secondId = fl.fixturePairs[0].secondId;
                                }
                                completedMatchDummyObj.winnerId = fl.fixturePairs[0].winnerId;
                                completedMatchDummyObj.matchDate = fl.fixturePairs[0].date;
                                completedMatchDummyObj.matchTime = fl.fixturePairs[0].time;
                            }
                        })
                        tt1Normal.push(completedMatchDummyObj)
                    }
                    else {
                        this.props.usersInTournament.map(n => {
                            if (fl.fixturePairs[0].ended) {
                                completed = true;
                                if (fl.fixturePairs[0].firstId === n.contactNo) {
                                    completedMatchDummyObj.firstName = n.name;
                                    completedMatchDummyObj.firstIcon = n.profilePic;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.firstId = fl.fixturePairs[0].firstId;
                                }
                                if (fl.fixturePairs[0].secondId === n.contactNo) {
                                    completedMatchDummyObj.secondName = n.name;
                                    completedMatchDummyObj.secondIcon = n.profilePic;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.secondId = fl.fixturePairs[0].secondId;
                                }
                                completedMatchDummyObj.winnerId = fl.fixturePairs[0].winnerId;
                                completedMatchDummyObj.matchDate = fl.fixturePairs[0].date;
                                completedMatchDummyObj.matchTime = fl.fixturePairs[0].time;
                            }
                        })
                        tt1Normal.push(completedMatchDummyObj)
                    }
                    this.setState({ tt1NormalState: [...tt1Normal] })
                }
                else {
                    //console.log(fl.fixturePairs) // not last one

                    fl.fixturePairs.map(m => {
                        let completedMatchDummyObj = {
                            gender: null,
                            firstId: null,
                            secondId: null,
                            winnerId: null,
                            firstName: null,
                            secondName: null,
                            firstIcon: null,
                            secondIcon: null,
                            matchDate: null,
                            matchTime: null
                        }
                        if (this.props.tournamentData.tournamentMode === 'team') {
                            this.props.teamsInTournament.map(n => {
                                if (m.ended) {
                                    completed = true;
                                    if (m.firstId === n.teamId) {
                                        completedMatchDummyObj.firstName = n.teamName;
                                        completedMatchDummyObj.firstIcon = n.teamIcon;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.firstId = m.firstId;
                                    }
                                    if (m.secondId === n.teamId) {
                                        completedMatchDummyObj.secondName = n.teamName;
                                        completedMatchDummyObj.secondIcon = n.teamIcon;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.secondId = m.secondId;
                                    }
                                    completedMatchDummyObj.winnerId = m.winnerId;
                                    completedMatchDummyObj.matchDate = m.date;
                                    completedMatchDummyObj.matchTime = m.time;
                                }
                            })
                        }
                        else {
                            this.props.usersInTournament.map(n => {
                                if (m.ended) {
                                    completed = true;
                                    if (m.firstId === n.contactNo) {
                                        completedMatchDummyObj.firstName = n.name;
                                        completedMatchDummyObj.firstIcon = n.profilePic;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.firstId = m.firstId;
                                    }
                                    if (m.secondId === n.contactNo) {
                                        completedMatchDummyObj.secondName = n.name;
                                        completedMatchDummyObj.secondIcon = n.profilePic;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.secondId = m.secondId;
                                    }
                                    completedMatchDummyObj.winnerId = m.winnerId;
                                    completedMatchDummyObj.matchDate = m.date;
                                    completedMatchDummyObj.matchTime = m.time;
                                }
                            })
                        }
                        tt1Normal.push(completedMatchDummyObj)
                    })
                    this.setState({ tt1NormalState: [...tt1Normal] })
                }

            })
        }

        else { //knockout pool based
            let tt1Normal = [];

            this.props.tt1Data.fixtureType2.fixturePoolList.map(fpl => {
                fpl.fixtureList.map(fl => {
                    fl.fixturePairs.map(m => {
                        let completedMatchDummyObj = {
                            gender: null,
                            firstId: null,
                            secondId: null,
                            winnerId: null,
                            firstName: null,
                            secondName: null,
                            firstIcon: null,
                            secondIcon: null,
                            matchDate: null,
                            matchTime: null
                        }
                        if (this.props.tournamentData.tournamentMode === 'team') {
                            this.props.teamsInTournament.map(n => {
                                if (m.ended) {
                                    completed = true;
                                    if (m.firstId === n.teamId) {
                                        completedMatchDummyObj.firstName = n.teamName;
                                        completedMatchDummyObj.firstIcon = n.teamIcon;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.firstId = m.firstId;
                                    }
                                    if (m.secondId === n.teamId) {
                                        completedMatchDummyObj.secondName = n.teamName;
                                        completedMatchDummyObj.secondIcon = n.teamIcon;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.secondId = m.secondId;
                                    }
                                    completedMatchDummyObj.winnerId = m.winnerId;
                                    completedMatchDummyObj.matchDate = m.date;
                                    completedMatchDummyObj.matchTime = m.time;
                                }
                            })
                        }
                        else {
                            this.props.usersInTournament.map(n => {
                                if (m.ended) {
                                    completed = true;
                                    if (m.firstId === n.contactNo) {
                                        completedMatchDummyObj.firstName = n.name;
                                        completedMatchDummyObj.firstIcon = n.profilePic;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.firstId = m.firstId;
                                    }
                                    if (m.secondId === n.contactNo) {
                                        completedMatchDummyObj.secondName = n.name;
                                        completedMatchDummyObj.secondIcon = n.profilePic;
                                        completedMatchDummyObj.gender = n.gender;
                                        completedMatchDummyObj.secondId = m.secondId;
                                    }
                                    completedMatchDummyObj.winnerId = m.winnerId;
                                    completedMatchDummyObj.matchDate = m.date;
                                    completedMatchDummyObj.matchTime = m.time;
                                }
                            })
                        }
                        tt1Normal.push(completedMatchDummyObj)
                    })
                    this.setState({ tt1NormalState: [...tt1Normal] })
                })
            })

            if (this.props.tt1Data.fixtureType2.semifinalList) {
                this.props.tt1Data.fixtureType2.semifinalList.map(m => {
                    let completedMatchDummyObj = {
                        gender: null,
                        firstId: null,
                        secondId: null,
                        winnerId: null,
                        firstName: null,
                        secondName: null,
                        firstIcon: null,
                        secondIcon: null,
                        matchDate: null,
                        matchTime: null
                    }
                    if (this.props.tournamentData.tournamentMode === 'team') {
                        this.props.teamsInTournament.map(n => {
                            if (m.ended) {
                                completed = true;
                                if (m.firstId === n.teamId) {
                                    completedMatchDummyObj.firstName = n.teamName;
                                    completedMatchDummyObj.firstIcon = n.teamIcon;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.firstId = m.firstId;
                                }
                                if (m.secondId === n.teamId) {
                                    completedMatchDummyObj.secondName = n.teamName;
                                    completedMatchDummyObj.secondIcon = n.teamIcon;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.secondId = m.secondId;
                                }
                                completedMatchDummyObj.winnerId = m.winnerId;
                                completedMatchDummyObj.matchDate = m.date;
                                completedMatchDummyObj.matchTime = m.time;
                            }
                        })
                    }
                    else {
                        this.props.usersInTournament.map(n => {
                            if (m.ended) {
                                completed = true;
                                if (m.firstId === n.contactNo) {
                                    completedMatchDummyObj.firstName = n.name;
                                    completedMatchDummyObj.firstIcon = n.profilePic;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.firstId = m.firstId;
                                }
                                if (m.secondId === n.contactNo) {
                                    completedMatchDummyObj.secondName = n.name;
                                    completedMatchDummyObj.secondIcon = n.profilePic;
                                    completedMatchDummyObj.gender = n.gender;
                                    completedMatchDummyObj.secondId = m.secondId;
                                }
                                completedMatchDummyObj.winnerId = m.winnerId;
                                completedMatchDummyObj.matchDate = m.date;
                                completedMatchDummyObj.matchTime = m.time;
                            }
                        })
                    }
                    tt1Normal.push(completedMatchDummyObj)
                })
                this.setState({ tt1NormalState: [...tt1Normal] })
            }

            if (this.props.tt1Data.fixtureType2.finalMatch) {
                let completedMatchDummyObj = {
                    gender: null,
                    firstId: null,
                    secondId: null,
                    winnerId: null,
                    firstName: null,
                    secondName: null,
                    firstIcon: null,
                    secondIcon: null,
                    matchDate: null,
                    matchTime: null
                }
                if (this.props.tournamentData.tournamentMode === 'team') {
                    this.props.teamsInTournament.map(n => {
                        if (this.props.tt1Data.fixtureType2.finalMatch.ended) {
                            completed = true;
                            if (this.props.tt1Data.fixtureType2.finalMatch.firstId === n.teamId) {
                                completedMatchDummyObj.firstName = n.teamName;
                                completedMatchDummyObj.firstIcon = n.teamIcon;
                                completedMatchDummyObj.gender = n.gender;
                                completedMatchDummyObj.firstId = this.props.tt1Data.fixtureType2.finalMatch.firstId;
                            }
                            if (this.props.tt1Data.fixtureType2.finalMatch.secondId === n.teamId) {
                                completedMatchDummyObj.secondName = n.teamName;
                                completedMatchDummyObj.secondIcon = n.teamIcon;
                                completedMatchDummyObj.gender = n.gender;
                                completedMatchDummyObj.secondId = this.props.tt1Data.fixtureType2.finalMatch.secondId;
                            }
                            completedMatchDummyObj.winnerId = this.props.tt1Data.fixtureType2.finalMatch.winnerId;
                            completedMatchDummyObj.matchDate = this.props.tt1Data.fixtureType2.finalMatch.date;
                            completedMatchDummyObj.matchTime = this.props.tt1Data.fixtureType2.finalMatch.time;
                        }
                    })
                }
                else {
                    this.props.usersInTournament.map(n => {
                        if (this.props.tt1Data.fixtureType2.finalMatch.ended) {
                            completed = true;
                            if (this.props.tt1Data.fixtureType2.finalMatch.firstId === n.contactNo) {
                                completedMatchDummyObj.firstName = n.name;
                                completedMatchDummyObj.firstIcon = n.profilePic;
                                completedMatchDummyObj.gender = n.gender;
                                completedMatchDummyObj.firstId = this.props.tt1Data.fixtureType2.finalMatch.firstId;
                            }
                            if (this.props.tt1Data.fixtureType2.finalMatch.secondId === n.contactNo) {
                                completedMatchDummyObj.secondName = n.name;
                                completedMatchDummyObj.secondIcon = n.profilePic;
                                completedMatchDummyObj.gender = n.gender;
                                completedMatchDummyObj.secondId = this.props.tt1Data.fixtureType2.finalMatch.secondId;
                            }
                            completedMatchDummyObj.winnerId = this.props.tt1Data.fixtureType2.finalMatch.winnerId;
                            completedMatchDummyObj.matchDate = this.props.tt1Data.fixtureType2.finalMatch.date;
                            completedMatchDummyObj.matchTime = this.props.tt1Data.fixtureType2.finalMatch.time;
                        }
                    })
                }
                tt1Normal.push(completedMatchDummyObj)
                this.setState({ tt1NormalState: [...tt1Normal] })
            }
        }
    }

    render() {
        //console.log(this.state.tt1NormalState)
        return (
            <React.Fragment>
                {completed ?
                    this.state.tt1NormalState.map(tt1 => {
                        if (tt1.winnerId) {
                            return <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="completedmatches">
                                    <ul className="scoreboxul tourcompleted_match">
                                        <li>
                                            <div className="teamdiv">
                                                <span className="matchdatetime">Date: {tt1.matchDate} Time: {tt1.matchTime}</span>
                                                <ul className="live_teamul live_teamul1">
                                                    <li>
                                                        {tt1.winnerId === tt1.firstId ? <img src={winnerlogo} alt="" className="tour_matchwinner" /> : ''}

                                                        {this.props.tournamentData.tournamentMode === 'team' ? tt1.firstIcon ? <span className="lt_img" style={{ backgroundImage: "url(" + tt1.firstIcon + ")" }}></span> : <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            : tt1.firstIcon ? <span className="lt_img" style={{ backgroundImage: "url(" + tt1.firstIcon + ")" }}></span> : tt1.gender === 'Male' ? <span className="lt_img" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span> : <span className="lt_img" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>}

                                                        <label className="it_flgname">{tt1.firstName}</label>

                                                        {/* <span className="scoretour_match">{'dasdas'}</span> */}

                                                        {tt1.winnerId === tt1.firstId ? <label className="it_flgscore">Winner</label> : ''}
                                                    </li>

                                                    <li>
                                                        <img className="vsicon" src={challengefill} alt="" />
                                                    </li>

                                                    <li>
                                                        {tt1.winnerId === tt1.secondId ? <img src={winnerlogo} alt="" className="tour_matchwinner2" /> : ''}

                                                        {this.props.tournamentData.tournamentMode === 'team' ? tt1.secondIcon ? <span className="lt_img" style={{ backgroundImage: "url(" + tt1.secondIcon + ")" }}></span> : <span className="lt_img" style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>
                                                            : tt1.secondIcon ? <span className="lt_img" style={{ backgroundImage: "url(" + tt1.secondIcon + ")" }}></span> : tt1.gender === 'Male' ? <span className="lt_img" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span> : <span className="lt_img" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>}

                                                        <label className="it_flgname">{tt1.secondName}</label>

                                                        {/* <span className="scoretour_match">{'dfd'}</span> */}

                                                        {tt1.winnerId === tt1.secondId ? <label className="it_flgscore">Winner</label> : ''}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    })
                    : <div className="broadcast_video">
                        <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={myNoPointsTournament} /></a>
                        <h3>Matches has not started yet.</h3>
                    </div>}
            </React.Fragment>
        )
    }
}
