import React, { Component } from 'react';
import Header from './Header';
/*import { Redirect } from "react-router-dom";*/

class SharePost extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        //this.setState({ user: JSON.parse(localStorage.getItem('result')) });
        this.state.user = JSON.parse(localStorage.getItem('result'));
        console.log(this.props.match.params.postId);
    }
    render() {
        /*if(this.props.match.params.postId === 'admin'){
            this.items = <Redirect to='/v2/admin' />
        }*/
        return (
            <div>
                <Header/>
                {/*{this.items}*/}
            </div>
    	);
    }
}

export default SharePost;
