
//Rsh  Function 
function GetRemainTime(scheduleDate) {
    var remainingTime;
    var scheduleTimpstamp = new Date(scheduleDate).getTime();
    var todayTimpstamp = new Date().getTime();
    if (scheduleTimpstamp > todayTimpstamp) {
        var s = scheduleTimpstamp - todayTimpstamp;
        function pad(n, z) {
            z = z || 3;
            return ('00' + n).slice(-z);
        }
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
        return remainingTime = pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    }


}


export default GetRemainTime;   