import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

class SharePost extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        //this.setState({ user: JSON.parse(localStorage.getItem('result')) });
        this.state.user = JSON.parse(localStorage.getItem('result'));
        console.log(this.props.match.url);
    }
    render() {
        if(this.props.match.url === '/v2/admin'){
            this.items = <Redirect to='/v2/admin/auth-login.html' />
        }
        return (
            <div>
                {this.items}
            </div>
    	);
    }
}

export default SharePost;
