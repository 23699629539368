import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateChallengeScore from '../UpdateChallengeScore';
import NumericInput from 'react-numeric-input';
var numberOfAttempt;
var scoreCardD101s = [];
var d101ScoreCard = {}, score, points;
var maxWeights = [];
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d101_view extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD101State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
    }


    componentDidMount() {


        let d101ScoreCard = [];
        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {
                if (this.props.clickedIndex === index) {
                    if (tt4.attempts > 0) {
                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d101ScoreCard) {
                                d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points })

                            }
                            else {
                                d101ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                }
            })
        }
        if (this.props.matchType === -3) {
            if (this.props.tt4.finals.attempts > 0) {
                numberOfAttempt = this.props.tt4.finals.attempts;
                this.setState({ modalIsOpen: false });

                this.props.tt4.finals.matchesList.map(ml => {
                    if (ml.d101ScoreCard) {
                        d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points })
                    }
                    else {
                        d101ScoreCard.push({ userId: ml.playerId, points: null })
                    }
                })
            }

        }
        this.setState({ scoreCardD101State: d101ScoreCard })
        console.log(this.state.scoreCardD101State)

    }

    render() {
        var sets = [];
        for (var p = 0; p < parseInt(numberOfAttempt); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>
                <div>
                    <div class="tableresponsive">
                        <div class="tableinner">
                            <table>
                                <thead>
                                    <tr id="heading">
                                        <th> Players/Attempts </th>

                                        {sets.map(k => {
                                            return <th> Set{setNumber++} </th>
                                        })}

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.scoreCardD101State.map((x, index) => {
                                        var userName;
                                        this.props.profileUsers.map(user => {
                                            if (x.userId === user.contactNo) {
                                                userName = user.name
                                            }
                                        })
                                        if (x.points === null) {
                                            return <tr>
                                                <td>{userName}</td>
                                                {sets.map((k, inde) => {
                                                    return <td key={k}> kg.g</td>
                                                })}
                                            </tr>;
                                        }
                                        else {
                                            var pointL = x.points.length;
                                            var point;
                                            return <tr>
                                                <td>{userName}</td>
                                                {sets.map((k, ind) => {
                                                    var pointColor;
                                                    if (x.points[ind] === undefined) {
                                                        point = '';
                                                    }
                                                    else {
                                                        if (x.points[ind].disqualified === false) {
                                                            if (Number.isInteger(x.points[ind].weightLifted) === true) {
                                                                point = x.points[ind].weightLifted;
                                                                point = point + '.00' + ' Kg';
                                                            } else {
                                                                point = x.points[ind].weightLifted + ' Kg';
                                                            }
                                                        }
                                                        else {
                                                            pointColor = { color: 'red' };
                                                            point = 'Disqualify';
                                                        }
                                                    }
                                                    return <td key={k}>{point ? <span style={pointColor}>{point}</span> : 'kg.g'}</td>
                                                })}
                                            </tr>;
                                        }
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
