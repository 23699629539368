import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import noBroadcast from '..//..//img/noBroadcast.jpg';
import matchnotstarted from '..//..//img/matchnotstarted.png';
import play from '..//..//img/play.png';
import logo from '..//..//img/finallogo.png';
import ConfigUrl from '..//..//ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Demo107Vue_bs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matchStatus: false,
      byTotalHits: '',
      toTotalHits: '',
      byTotalRuns: '',
      toTotalRuns: '',
      numberofSets: '',
      rounds: [],
    };
  }

  componentDidMount() {
    const apiUrl = rshApi + "/demo-challenges/get-score";
    const that = this;
    fetch(apiUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "data": {
          "challengeId": that.props.challenge.challengeId,
          "matchId": that.props.challenge.challengeId,
        }
      })
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result)
        that.setState({
          users: result.data.users,
          teams: result.data.teams
        })

        if (result.data.score !== null) {

          if (result.data.score.d107BaseBallScoreCard !== null) {

            if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== undefined) {

              if (result.data.score.d107BaseBallScoreCard.byBaseballScoreCard !== null) {
                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalRuns, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
              }
              else {
                that.setState({ byTotalHits: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.totalHits, byTotalRuns: 0, byWinner: result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.winner });
              }
              that.setState({ matchStatus: true });
            }

            if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== undefined) {

              if (result.data.score.d107BaseBallScoreCard.toBaseballScoreCard !== null) {
                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalRuns, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
              }
              else {
                that.setState({ toTotalHits: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.totalHits, toTotalRuns: 0, toWinner: result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.winner });
              }
              that.setState({ matchStatus: true });
            }

            //storing set numbers and rounds
            that.setState({ rounds: result.data.score.d107BaseBallScoreCard.rounds, numberofSets: result.data.score.d107BaseBallScoreCard.noOfSets })

            //storing byteam and toteam
            that.props.profileTeams.map(team => {
              if (team.teamId === result.data.score.d107BaseBallScoreCard.byBaseballScoreCard.teamId) {
                that.setState({ byTeamName: team.teamName });
              }
              if (team.teamId === result.data.score.d107BaseBallScoreCard.toBaseballScoreCard.teamId) {
                that.setState({ toTeamName: team.teamName });
              }
            })

          } else {
            let byName; let toName;
            that.props.profileUsers.map(user => {
              if (that.props.challenge.accepted[0] === user.contactNo) {
                byName = user.name;
              }
              if (that.props.challenge.accepted[1] === user.contactNo) {
                toName = user.name;
              }
            })
            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
            that.props.profileTeams.map(user => {
              if (that.props.challenge.accepted[0] === user.teamId) {
                byName = user.teamName;
              }
              if (that.props.challenge.accepted[1] === user.teamId) {
                toName = user.teamName;
              }
            })
            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
          }

        }

      });

    if (that.props.challenge.challengeAction === 'ended') {
      that.setState({ matchStatus: true });
    }

  }

  render() {
    const backLinks = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
    if (this.state.matchStatus) {
      return (
        <React.Fragment>
          <section className="d_midsec createmidsec broadcast_box">
            <div className="commanbox cp_profile">
              <div className="broadcast_video" style={{ backgroundImage: "url(" + noBroadcast + ")" }}>
                <span>09:33</span>
                <a className="tour_broadcast_update"><img src={play} alt="" /></a>
              </div>
              <div className="tableresponsive">
                <div className="tableinner">
                  <table>

                    <thead>
                      <tr>
                        <th>Teams</th>
                        <th>Score</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{this.state.byTeamName}</td>
                        <td>{this.state.byTotalRuns}</td>
                        {this.state.byWinner ? <td><img src={logo} className="winnerimg_td" alt="" /> Winner</td> : ''}
                      </tr>
                      <tr>
                        <td>{this.state.toTeamName}</td>
                        <td>{this.state.toTotalRuns}</td>
                        {this.state.toWinner ? <td><img src={logo} className="winnerimg_td" alt="" /> Winner</td> : ''}
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>

              <h4 className="scorehead">Match Score</h4>

              <div className="tableresponsive">
                <div className="tableinner">
                  <table>

                    <thead>
                      <tr>
                        <th>Teams</th>
                        {this.state.rounds.map((p, i = 1) => {
                          if ((i > 0) && (i < 10)) {
                            return <th>R{i}</th>
                          }
                        })}
                        <th>Total Hits</th>
                        <th>Total Runs</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{this.state.byTeamName}</td>
                        {this.state.rounds.map((p, i) => {
                          if ((i > 0) && (i < 10)) {
                            return <td><input disabled placeholder={p.challengedByInningScore} className="inputsize-d" /></td>
                          }
                        })}
                        <td>{this.state.byTotalHits}</td>
                        <td>{this.state.byTotalRuns}</td>
                      </tr>

                      <tr>
                        <td>{this.state.toTeamName}</td>
                        {this.state.rounds.map((p, i) => {
                          if ((i > 0) && (i < 10)) {
                            return <td><input disabled placeholder={p.challengedToInningScore} className="inputsize-d" /></td>
                          }
                        })}
                        <td>{this.state.toTotalHits}</td>
                        <td>{this.state.toTotalRuns}</td>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
              <div class="table_actions winner_actions">
                <a href={backLinks} class="cmnbutton backred fl">Back</a>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <section class="d_midsec createmidsec broadcast_box">
            <div class="commanbox cp_profile">
              <div className="broadcast_video">
                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={matchnotstarted} /></a>
                <h3>Match has not started yet.</h3>
              </div>
              <div class="table_actions winner_actions">
                <a href={backLinks} class="cmnbutton backred fl">Back</a>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}



