import React, { useState, useEffect } from 'react';
import axios from 'axios';
import team from '../img/team.png';
import pageIconD from '../img/pageIconD.png';
import ConfigUrl from '../ConfigUrl';
import Header from '../Header';
import SectionLeft from '../SectionLeft';
import SectionRight from '../SectionRight';
import { useHistory } from "react-router-dom";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem('result'));

const data = {
    contactNo: null,
}
if (user) {
    data.contactNo = user.contactNo;
}

function renderSwitch(param) {
    switch (param) {
        case 'club':
            return 'Sports Club';
        case 'corporate':
            return 'Corporate Organization';
        case 'educational':
            return 'Educational Institutes';
        default:
            return 'NA';
    }
}

function PageList() {
    let history = useHistory();
    const [pages, setPages] = useState([]);
    useEffect(() => {
        axios.post(userApi + 'pages/get-pages', {
            data
        }).then(function (response) {
            setPages(response.data.data)
            console.log(response.data.data)
        })
    }, [])
    function redirectToCreatepage() {
        history.push("/page/create");
    }
    function redirectToPageProfile(id) {
        history.push(`/page/profile/${id}`);
    }
    return (
        <>
            <Header />
            <div className="container containerbottom non-session-d">
                {user ? <SectionLeft /> : ''}
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <div class="commanbox">
                        <h3 class="commanhead">All Pages </h3>
                        <div class="create-page">
                            <button onClick={redirectToCreatepage}>Create Sport Page</button>
                        </div>
                        <h3 class="commanhead">Your Pages </h3>
                        <ul class="at_ul">
                            {pages.map(page => {
                                return <li key={page.id} onClick={() => redirectToPageProfile(page.id)}>
                                    <a class="at_link">
                                        <span class="at_teamimg" style={{ backgroundImage: 'url(' + team + ')' }}></span>
                                        <label class="at_name">{page.name}</label>
                                        <p class="at_sportsname">{renderSwitch(page.category)}</p>
                                    </a>
                                </li>
                            })}
                            {pages.length > 0 ? null : <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={pageIconD} /></a>
                                <h3 className="No_challenge">Sorry you don't have any Pages.</h3>
                            </div>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageList

