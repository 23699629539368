import React, { Component } from 'react';
//import SearchField from 'react-search-field';
import { Link } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import GetCurrentTimestamp from './GetCurrentTimestamp';
import { Date } from 'prismic-reactjs';
import showDefaultTeam from './img/team.png';
import camera from './img/camera.png';
import searchnew from './img/searchnew.png';
import challenegbg from './img/challenegbg.png';
import confirmation from './img/confirmation.png';
import editgreen from './img/editgreen.png';
import moredark from './img/moredark.png';
import cancelred from './img/cancelred.png';
import maleIcon from './img/male.png';
import teamIcon from "./img/team.png";
import femaleIcon from './img/female.png';
//import Modal from 'react-bootstrap/Modal';
//import ModalDialog from 'react-bootstrap/ModalDialog';
import ConfigUrl from './ConfigUrl';
import ImgDropAndCrop from './ImgDropAndCrop';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const currentTimeStamp = GetCurrentTimestamp();
const that = this;
var teamId;
function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}
function searchingFor1(term1) {
    return function (x) {
        return x.name.toLowerCase().includes(term1.toLowerCase()) || !term1;
    }
}

class DemoTeamprofile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            users: [],
            takeOnlyContact: '',
            teamMembers: [],
            teamMemberCount: '',
            allBuddies: [],
            takeContact: '',
            takeName: '',
            multiUser: [],
            tickCount: [],
            theOwnership: '',
            theOwnership2: '',
            disableTeam: '',
            selectBuddyContact: '',
            pictures: '',
            term: '', //for searchbar
            term1: '', //for searchbar
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.searchHandler = this.searchHandler.bind(this);
        this.searchHandler1 = this.searchHandler1.bind(this);
        this.liRefs = [React.createRef()];
    }

    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }
    searchHandler1(event) {
        this.setState({ term1: event.target.value })
    }

    componentDidMount() {
        const apiUrl = rshApi + "demo-teams/get-teams";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: this.state.user.contactNo,
                    type: 'both',
                    "minimal": true
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    teams: result.data
                });
                that.state.teams.map(x => {
                    teamId = x.teamId;
                    //that.setState({ teamId: teamId })
                    that.getTeamFunction(teamId);
                    that.getBuddyFunction();
                })
            });
    }





    getTeamFunction = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let teamid = urlParams.get('id');
        const apiUrl = rshApi + "/demo-teams/get-team";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "teamId": teamid
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    teams: result.data.team,
                    users: result.data.users,
                    teamMembers: result.data.team.teamMembers,
                    teamMemberCount: result.data.team.teamMembers.length,
                });
            });
    }


    //get-buddies API is called from here
    getBuddyFunction = () => {
        const apiUrl = rshApi + "/dummy/get-users";
        const that = this;
        fetch(apiUrl)
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                if (result.error) {

                }
                else {
                    that.setState({
                        allBuddies: result.data,
                    });
                }
            });
    }

    //exit team function
    exitTeam = () => {
        var urlParams = new URLSearchParams(window.location.search);
        let teamid = urlParams.get('id');
        const exitTeam = rshApi + "/demo-teams/exit-team";
        fetch(exitTeam, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "teamId": teamid,
                    "memberId": this.state.user.contactNo // "user who is currently logged in"
                }
            })
        })
    }

    //code to change captain
    becomeCap = (takeOnlyContact) => {
        const that = this;
        that.setState({
            takeOnlyContact: takeOnlyContact,
        });
    }



    becomeCap2 = (contactNo) => {
        var urlParams = new URLSearchParams(window.location.search);
        let teamid = urlParams.get('id');
        const changeCapURL = rshApi + "/demo-teams/assign-team-captain";
        const that = this;
        fetch(changeCapURL, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "teamId": teamid,
                    "captainId": contactNo
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    teamID: result.data.teamId,
                    newCaptain: result.data.captain
                });
                that.getTeamFunction();
            });
    }








    //code to delete Team Members from team 
    takeRemoveDetail = (takeContact, takeName) => {
        const that = this;
        that.setState({
            takeContact: takeContact,
            takeName: takeName,
        });
    }

    deleteTeamMembers = (playerRemoveID) => {
        var urlParams = new URLSearchParams(window.location.search);
        let teamid = urlParams.get('id');
        const removeTeamMem = rshApi + "/demo-teams/remove-player";
        const that = this;
        fetch(removeTeamMem, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {

                    "teamId": teamid,
                    "memberId": playerRemoveID

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                //console.log(result);
                that.setState({
                    teamID: result.data.teamId,
                    newTeamMembers: result.data.teamMembers
                });
                that.getTeamFunction();
            });
    }


    //code to add players to team
    takeUsers = (event) => {
        let checkedArray = this.state.multiUser;
        let selectedValue = event.target.value;

        if (event.target.checked === true) {

            checkedArray.push(selectedValue);
            this.setState({
                multiUser: checkedArray,
                tickCount: checkedArray.length,
            });

        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                multiUser: checkedArray,
                tickCount: checkedArray.length,
            });
        }
    }



    sendUsers = () => {
        if (this.state.multiUser.length > 0) {
            var urlParams = new URLSearchParams(window.location.search);
            let teamid = urlParams.get('id');
            const addTeamMem = rshApi + "/demo-teams/add-to-team";
            const that = this;
            fetch(addTeamMem, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "teamId": teamid,
                        "memberIds": this.state.multiUser
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    //console.log(result);
                    that.setState({
                        teamID: result.data.teamId,
                        latestTeamMembers: result.data.teamMembers
                    });
                    that.getTeamFunction();
                    window.location.href = "/demoTeamProfilePage/index.html?id=" + teamid;
                })
        }
        else {
        }

    }



    //code to change owner 
    becomeOwner = (selectBuddy) => {

        this.setState({
            selectBuddyContact: selectBuddy
        });
        console.log(this.state.selectBuddyContact)
    }

    theOwnership = (e) => {
        document.getElementById('hideBuddies').style.visibility = "visible";
        let ownerShip = e.target.value;

        if (e.target.checked === true && ownerShip === 'ownerAndMember') {
            this.setState({
                theOwnership: ownerShip
            });
        }
    }

    theOwnership2 = (event) => {
        document.getElementById('hideBuddies').style.visibility = "visible";
        let ownerShip2 = event.target.value;

        if (event.target.checked === true && ownerShip2 === 'ownerAndRemove') {
            this.setState({
                theOwnership2: ownerShip2
            });
        }
    }

    disableTeam = (events) => {
        document.getElementById('hideBuddies').style.visibility = "hidden";
        let disabling = events.target.value;

        if (events.target.checked === true && disabling === 'disableTheTeam') {
            this.setState({
                disableTeam: disabling
            });
        }
    }

    becometheOwner = () => {
        var urlParams = new URLSearchParams(window.location.search);
        var teamid = urlParams.get('id');
        if (this.state.theOwnership === 'ownerAndMember') {
            const changeOwnership = rshApi + "/demo-teams/request-new-owner";

            fetch(changeOwnership, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "teamId": teamid,
                        "ownerId": this.state.selectBuddyContact,
                        "overFlowActionValue": "Change the owner/keep me as member"
                    }
                })
            })
        }

        if (this.state.theOwnership2 === 'ownerAndRemove') {
            const changeOwnership2 = rshApi + "/demo-teams/request-new-owner";

            fetch(changeOwnership2, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "teamId": teamid,
                        "ownerId": this.state.selectBuddyContact,
                        "overFlowActionValue": "Change the owner/remove me from team"
                    }
                })
            })
        }
    }


    //submit pic
    handleLanguage = (langValue) => {
        this.setState({ imageCrop: langValue });
    }

    render() {

        let userCopy = [];
        this.state.users.map(user => {
            for (let i = 0; i < this.state.teamMembers.length; i++) {
                if (user.contactNo === this.state.teamMembers[i]) {
                    userCopy.push(user);
                }
            }
        })



        var isTeamMember = false;
        var theOwner = false;

        //showing date
        const timestamp = Date(this.state.teams.createdOn);
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(timestamp);

        const { term, users } = this.state;
        const { term1, allBuddies } = this.state;

        return (
            <div>
                {/* Modal content */}
                <div id="assigncaptain" className="modal fade" role="dialog">
                    <div className="modal-dialog">


                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Caption Selection</h4>
                            </div>
                            <div className="modal-body">
                                <div className="ac_search">
                                    <form>
                                        <input onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form>
                                </div>
                                <ul className="playerlist">
                                    {
                                        userCopy.filter(searchingFor(term)).map((item, index) => {

                                            if (item.contactNo !== this.state.teams.captain) {


                                                if (item.profilePic === null) {
                                                    switch (item.gender) {
                                                        case 'Male':
                                                            return <li key={item.name}>
                                                                <span className="assignimg" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                <label className="ac_name">{item.name}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                                    <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                                </div>
                                                            </li>


                                                        default:
                                                            return <li key={item.name}>
                                                                <span className="assignimg" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                <label className="ac_name">{item.name}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                                    <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                                </div>
                                                            </li>

                                                    }
                                                }
                                                else {
                                                    return <li key={item.name}>
                                                        <span className="assignimg" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                        <label className="ac_name">{item.name}</label>
                                                        <div className="styled-input-single createinput">
                                                            <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                            <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                        </div>
                                                    </li>

                                                }
                                            }



                                            else {
                                                if (item.profilePic === null) {
                                                    switch (item.gender) {
                                                        case 'Male':
                                                            return <li key={item.name}>
                                                                <span className="assignimg" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                <label className="ac_name">{item.name}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                                    <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                                </div>
                                                            </li>


                                                        default:
                                                            return <li key={item.name}>
                                                                <span className="assignimg" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                <label className="ac_name">{item.name}</label>
                                                                <div className="styled-input-single createinput">
                                                                    <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                                    <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                                </div>
                                                            </li>

                                                    }
                                                }
                                                else {
                                                    return <li key={item.name}>
                                                        <span className="assignimg" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                        <label className="ac_name">{item.name}</label>
                                                        <div className="styled-input-single createinput">
                                                            <input type="radio" name="fieldset-5" id={"checkbox-example" + index} />
                                                            <label onClick={() => this.becomeCap(item.contactNo)} htmlFor={"checkbox-example" + index}></label>
                                                        </div>
                                                    </li>

                                                }

                                            }

                                        })
                                    }

                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => this.becomeCap2(this.state.takeOnlyContact)} type="button" className="cmnbutton" data-dismiss="modal">Done</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="">
                    <section className="d_midsec createmidsec">
                        <div className="divpost">
                            <span className="teamprofile_span">Demo Team Profile</span>
                            <div className="createteam_div" style={{ backgroundImage: "url(" + challenegbg + ")" }}>

                                <button id="uploadCover">Change Picture</button>
                                <div className="uploadimg_div">

                                    {(() => {
                                        switch (this.state.teams.teamIcon) {
                                            case null:
                                                return <span style={{ backgroundImage: "url(" + showDefaultTeam + ")" }}></span>
                                            case "":
                                                return <span style={{ backgroundImage: "url(" + teamIcon + ")" }}></span>

                                            default:
                                                return <span style={{ backgroundImage: "url(" + this.state.teams.teamIcon + ")" }}></span>

                                        }
                                    })()}
                                </div>


                                <div>
                                    {(() => {
                                        switch (this.state.teams.gender) {
                                            case 'Male':
                                                return <div>
                                                    <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '6px' }}>{this.state.teams.sportsName} - Men's </p></div>

                                            case 'Female':
                                                return <div>
                                                    <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '6px' }}>{this.state.teams.sportsName} - Women's </p></div>

                                            default:
                                                return <div>
                                                    <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '6px' }}>{this.state.teams.sportsName} - Mix </p></div>
                                        }
                                    })()}
                                </div>

                            </div>
                        </div>

                        {(() => {
                            switch (this.state.user.contactNo === this.state.teams.createdBy) {
                                case true:
                                    return
                                default:
                                    return '';
                            }
                        })()}



                        <div className="createteamform">

                            <ul className="nav nav-tabs teamprofiletab">
                                <li className="active"><a data-toggle="tab" href="#teaminfo">Team Info</a></li>
                                <li><a data-toggle="tab" href="#teamplayers">Team Players</a></li>
                            </ul>

                            <div className="tab-content tp_content">
                                <div id="teaminfo" className="tab-pane fade in active">
                                    <div className="teambox">
                                        <ul>

                                            <li>
                                                <span>Team Name</span>
                                                <label>{this.state.teams.teamName}</label>
                                            </li>

                                            <li>
                                                <span>Sports</span>
                                                <label>{this.state.teams.sportsName}</label>
                                            </li>

                                            <li>
                                                <span>Created On</span>
                                                <label>{formattedTimestamp}</label>
                                            </li>

                                            <li>
                                                {(() => {
                                                    switch (this.state.teams.active) {
                                                        case true:
                                                            return <div>
                                                                <span>Status</span>
                                                                <label>Active</label></div>;
                                                        default:
                                                            return <div>
                                                                <span>Status</span>
                                                                <label>Not Active</label></div>;
                                                    }
                                                })()}
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="teambox">
                                        <ul>

                                            <li>
                                                <span>Manager</span>
                                                <label>{this.state.user.name}</label>


                                            </li>

                                            <li>
                                                <span>Players</span>
                                                <label>{this.state.teamMemberCount}</label>
                                            </li>

                                            <li>
                                                <span>Captain</span>

                                                {this.state.users.slice(0, 1).map(item => {
                                                    if (this.state.user.contactNo === this.state.teams.createdBy) {
                                                        theOwner = true;
                                                    }
                                                    if (theOwner === true) {
                                                        return <div key={item.contactNo}>
                                                            {this.state.teams.captain === null ? (<div>
                                                                <label>Not Yet Assigned</label>
                                                                <span>
                                                                    <button className="assigncaptain" data-toggle="modal" data-target="#assigncaptain">Assign Captain</button>
                                                                </span> </div>
                                                            ) : <div>
                                                                    {this.state.users.map(item => {
                                                                        if (item.contactNo === this.state.teams.captain) {
                                                                            return <div key={item.name}>
                                                                                <label>{item.name}</label>
                                                                                <span>
                                                                                    <button className="assigncaptain" data-toggle="modal" data-target="#assigncaptain" >Change Captain</button>
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                        return '';
                                                                    })}
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    return '';
                                                })}

                                                {this.state.users.map(item => {
                                                    if ((item.contactNo === this.state.user.contactNo) && (theOwner === false)) {
                                                        isTeamMember = true;
                                                    }
                                                    return '';
                                                })}

                                                {isTeamMember ?
                                                    this.state.teams.captain === null ? (<div>
                                                        <label>Not Yet Assigned</label>
                                                        <span>
                                                            <button className="assigncaptain" data-toggle="modal" data-target="#deleteplayer2" >Exit Team</button>
                                                        </span>
                                                    </div>
                                                    ) : (<div>
                                                        {this.state.users.map((item) => {
                                                            if (item.contactNo === this.state.teams.captain) {
                                                                return <div key={item.name}>
                                                                    <label>{item.name}</label>
                                                                    <span>
                                                                        <button className="assigncaptain" data-toggle="modal" data-target="#deleteplayer2" >Exit Team</button>
                                                                    </span>
                                                                </div>;
                                                            }
                                                            else {
                                                                return '';
                                                            }
                                                        })} </div>
                                                        )
                                                    : this.state.teams.captain === null ? (<div>

                                                    </div>
                                                    ) : (<div>
                                                        {this.state.users.map((item) => {
                                                            if (!theOwner) {
                                                                if (item.contactNo === this.state.teams.captain) {
                                                                    return <div key={item.name}>
                                                                        <label>{item.name}</label>
                                                                    </div>;
                                                                }
                                                            }
                                                        })}
                                                    </div>)}

                                            </li>

                                            <li>
                                                <span>Location</span>
                                                <label>{this.state.teams.location}</label>
                                            </li>

                                        </ul>
                                    </div>



                                </div>
                                <div id="teamplayers" className="tab-pane fade">
                                    <div>
                                        <div className="team_addmembers">
                                            <span className="teammember">Total <span>{this.state.teamMemberCount}</span> Team Members</span>

                                            {(() => {
                                                switch (this.state.user.contactNo === this.state.teams.createdBy) {
                                                    case true:
                                                        return <button data-toggle="modal" data-target="#team_addplayers">Add Players</button>
                                                    default:
                                                        return '';
                                                }
                                            })()}

                                        </div>
                                        <div className="">

                                            {(() => {
                                                switch (this.state.user.contactNo === this.state.teams.createdBy) {
                                                    case true:
                                                        return <ul className="buddieslist_ul ct_buddies tp_selected">
                                                            {userCopy.map(item => {

                                                                var theOwner = false;
                                                                if (item.contactNo === this.state.teams.createdBy) {
                                                                    theOwner = true;
                                                                }
                                                                if (theOwner === true) {
                                                                    if (item.contactNo !== this.state.teams.captain) {

                                                                        if (item.profilePic === null) {
                                                                            switch (item.gender) {
                                                                                case 'Male':
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Member</label>
                                                                                        </Link>

                                                                                        {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                    </li>


                                                                                default:
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Member</label>
                                                                                        </Link>

                                                                                        {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                    </li>
                                                                            }
                                                                        }
                                                                        else {
                                                                            return <li key={item.name}>
                                                                                <Link to={"#"}>
                                                                                    <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                    <label>{item.name}</label> <label>Member</label>
                                                                                </Link>

                                                                                {/* <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                            </li>
                                                                        }
                                                                    }

                                                                    else {
                                                                        if (item.profilePic === null) {
                                                                            switch (item.gender) {
                                                                                case 'Male':
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Captain</label>
                                                                                        </Link>

                                                                                        {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                    </li>


                                                                                default:
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Captain</label>
                                                                                        </Link>

                                                                                        {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                    </li>
                                                                            }
                                                                        }
                                                                        else {
                                                                            return <li key={item.name}>
                                                                                <Link to={"#"}>
                                                                                    <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                    <label>{item.name}</label> <label>Captain</label>
                                                                                </Link>
                                                                                {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                            </li>
                                                                        }
                                                                    }
                                                                }




                                                                if (item.contactNo !== this.state.teams.captain) {

                                                                    if (item.profilePic === null) {
                                                                        switch (item.gender) {
                                                                            case 'Male':
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Member</label>
                                                                                    </Link>

                                                                                    <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span>
                                                                                </li>


                                                                            default:
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Member</label>
                                                                                    </Link>

                                                                                    <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span>
                                                                                </li>

                                                                        }
                                                                    }
                                                                    else {
                                                                        return <li key={item.name}>
                                                                            <Link to={"#"}>
                                                                                <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                <label>{item.name}</label> <label>Member</label>
                                                                            </Link>

                                                                            <span onClick={() => this.takeRemoveDetail(item.contactNo, item.name)} className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span>
                                                                        </li>

                                                                    }
                                                                }

                                                                else {
                                                                    if (item.profilePic === null) {
                                                                        switch (item.gender) {
                                                                            case 'Male':
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Captain</label>
                                                                                    </Link>

                                                                                    {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                </li>


                                                                            default:
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Captain</label>
                                                                                    </Link>

                                                                                    {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                                </li>
                                                                        }
                                                                    }
                                                                    else {
                                                                        return <li key={item.name}>
                                                                            <Link to={"#"}>
                                                                                <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                <label>{item.name}</label> <label>Captain</label>
                                                                            </Link>
                                                                            {/* <span onClick={() => this.takeRemoveDetail(item.contactNo,item.name)}  className="deletebuddy" data-toggle="modal" data-target="#deleteplayer1" ><img src={cancelred} alt="" /></span> */}
                                                                        </li>
                                                                    }
                                                                }

                                                            })}
                                                        </ul>
                                                    default:
                                                        return <ul className="buddieslist_ul ct_buddies tp_selected">
                                                            {this.state.users.map(item => {

                                                                var theOwner = false;
                                                                if (item.contactNo === this.state.teams.createdBy) {
                                                                    theOwner = true;
                                                                }
                                                                if (theOwner === true) {
                                                                    if (item.contactNo !== this.state.teams.captain) {

                                                                        if (item.profilePic === null) {
                                                                            switch (item.gender) {
                                                                                case 'Male':
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Member</label>
                                                                                        </Link>
                                                                                    </li>


                                                                                default:
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Member</label>
                                                                                        </Link>
                                                                                    </li>
                                                                            }
                                                                        }
                                                                        else {
                                                                            return <li key={item.name}>
                                                                                <Link to={"#"}>
                                                                                    <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                    <label>{item.name}</label> <label>Member</label>
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                    }

                                                                    else {
                                                                        if (item.profilePic === null) {
                                                                            switch (item.gender) {
                                                                                case 'Male':
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Captain</label>
                                                                                        </Link>
                                                                                    </li>


                                                                                default:
                                                                                    return <li key={item.name}>
                                                                                        <Link to={"#"}>
                                                                                            <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                            <label>{item.name}</label> <label>Captain</label>
                                                                                        </Link>
                                                                                    </li>
                                                                            }
                                                                        }
                                                                        else {
                                                                            return <li key={item.name}>
                                                                                <Link to={"#"}>
                                                                                    <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                    <label>{item.name}</label> <label>Captain</label>
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                    }
                                                                }




                                                                if (item.contactNo !== this.state.teams.captain) {

                                                                    if (item.profilePic === null) {
                                                                        switch (item.gender) {
                                                                            case 'Male':
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Member</label>
                                                                                    </Link>
                                                                                </li>


                                                                            default:
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Member</label>
                                                                                    </Link>
                                                                                </li>

                                                                        }
                                                                    }
                                                                    else {
                                                                        return <li key={item.name}>
                                                                            <Link to={"#"}>
                                                                                <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                <label>{item.name}</label> <label>Member</label>
                                                                            </Link>
                                                                        </li>

                                                                    }
                                                                }

                                                                else {
                                                                    if (item.profilePic === null) {
                                                                        switch (item.gender) {
                                                                            case 'Male':
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Captain</label>
                                                                                    </Link>
                                                                                </li>


                                                                            default:
                                                                                return <li key={item.name}>
                                                                                    <Link to={"#"}>
                                                                                        <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                                        <label>{item.name}</label> <label>Captain</label>
                                                                                    </Link>
                                                                                </li>
                                                                        }
                                                                    }
                                                                    else {
                                                                        return <li key={item.name}>
                                                                            <Link to={"#"}>
                                                                                <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                                                <label>{item.name}</label> <label>Captain</label>
                                                                            </Link>
                                                                        </li>
                                                                    }
                                                                }

                                                            })}
                                                        </ul>
                                                }
                                            })()}



                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </section>




                </div>
                {/* Modal content */}
                <div id="team_addplayers" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">

                            <div className="modal-header">
                                <button type="button" className="close showclose" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Add Players</h4>
                            </div>

                            <div className="modal-body">

                                <div className="ct_searchbuddies">
                                    <form>
                                        <input onChange={this.searchHandler1} value={term1} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form>
                                </div>

                                <label className="addbuddies_count">You have Selected : <label>{this.state.tickCount} Buddies</label></label>

                                <ul className="buddieslist_ul ct_buddies">

                                    {
                                        allBuddies.filter(searchingFor1(term1)).map((item, index1) => {
                                            var disableData = false;
                                            this.state.users.map((item2) => {
                                                if (item2.contactNo === item.contactNo) {
                                                    disableData = true;
                                                }
                                                return true;
                                            })
                                            let defaultChecking = false;
                                            this.state.multiUser.map(mu => {
                                                if (mu === item.contactNo) {
                                                    defaultChecking = true;
                                                }
                                            })

                                            if (disableData === true) {
                                                if (item.profilePic === null) {
                                                    switch (item.gender) {
                                                        case 'Male':
                                                            return <li className="deselect" key={index1}>
                                                                <a>
                                                                    <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                    <label>{item.name}</label>
                                                                </a>
                                                                <div className="styled-input-single">
                                                                    <input disabled checked onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>

                                                        default:
                                                            return <li className="deselect" key={index1}>
                                                                <a>
                                                                    <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                    <label>{item.name}</label>
                                                                </a>
                                                                <div className="styled-input-single">
                                                                    <input disabled checked onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                                    <label htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                    }
                                                }
                                                else {
                                                    return <li className="deselect" key={index1}>
                                                        <a>
                                                            <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                            <label>{item.name}</label>
                                                        </a>
                                                        <div className="styled-input-single">
                                                            <input disabled checked onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                            <label htmlFor={"checkBox" + index1}></label>
                                                        </div>
                                                    </li>
                                                }
                                            }

                                            else {
                                                if (item.profilePic === null) {
                                                    switch (item.gender) {
                                                        case 'Male':
                                                            return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                                <a>
                                                                    <span style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                                    <label>{item.name}</label>
                                                                </a>
                                                                <div className="styled-input-single">
                                                                    <input ref={el => this.liRefs[item.contactNo] = el} checked={defaultChecking} onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                                    <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>

                                                        default:
                                                            return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                                <a>
                                                                    <span style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                                    <label>{item.name}</label>
                                                                </a>
                                                                <div className="styled-input-single">
                                                                    <input ref={el => this.liRefs[item.contactNo] = el} checked={defaultChecking} onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                                    <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                                </div>
                                                            </li>
                                                    }
                                                }
                                                else {
                                                    return <li onClick={(e) => this.liRefs[item.contactNo].click()} key={index1}>
                                                        <a>
                                                            <span style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                            <label>{item.name}</label>
                                                        </a>
                                                        <div className="styled-input-single">
                                                            <input ref={el => this.liRefs[item.contactNo] = el} checked={defaultChecking} onChange={this.takeUsers.bind(this)} type="checkbox" name="fieldset-5" id={"checkBox" + index1} value={item.contactNo} />

                                                            <label onClick={(e) => this.liRefs[item.contactNo].click()} htmlFor={"checkBox" + index1}></label>
                                                        </div>
                                                    </li>
                                                }
                                            }

                                        })
                                    }
                                </ul>

                            </div>

                            <div className="modal-footer">
                                <button type="button" onClick={() => this.sendUsers()} className="cmnbutton" data-dismiss="modal">Done</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal content UPLOAD AND CROP */}
                <div id="openCropModal" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Upload Image</h4>
                            </div>

                            <div className="modal-body">
                                <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
                            </div>


                        </div>
                    </div>
                </div>


                {/* Modal content */}
                <div id="deleteplayer1" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Delete Players</h4>
                            </div>

                            <div className="modal-body">
                                <div className="confirmationdiv">
                                    <img src={confirmation} alt="" />
                                    <label>Do your really want to remove {this.state.takeName} from team ?</label>

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button onClick={() => this.deleteTeamMembers(this.state.takeContact)} type="button" className="cmnbutton" data-dismiss="modal">Yes Remove</button>
                                <button type="button" className="cmnbutton btnclose" data-dismiss="modal">Cancel</button>

                            </div>
                        </div>

                    </div>
                </div>

                {/* Modal content */}
                <div id="deleteplayer2" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Delete Players</h4>
                            </div>

                            <div className="modal-body">
                                <div className="confirmationdiv">
                                    <img src={confirmation} alt="" />
                                    <label>Do your really want to exit from this team ?</label>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button onClick={() => this.exitTeam()} type="button" className="cmnbutton" data-dismiss="modal">Yes Remove</button>
                                <button type="button" className="cmnbutton btnclose" data-dismiss="modal">Cancel</button>

                            </div>
                        </div>

                    </div>
                </div>

                {/* Modal content */}
                <div id="selectoption2" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Select Option</h4>
                            </div>
                            <div className="modal-body">
                                <ul className="selectul">
                                    <li>
                                        <div className="styled-input-single createinput">
                                            <input onChange={this.theOwnership.bind(this)} type="radio" name="fieldset-5" id="checkbox1" value="ownerAndMember" />
                                            <label htmlFor="checkbox1">Change the owner/keep me as member</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="styled-input-single createinput">
                                            <input onChange={this.theOwnership2.bind(this)} type="radio" name="fieldset-5" id="checkbox2" value="ownerAndRemove" />
                                            <label htmlFor="checkbox2">Change the owner/remove me from the team</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="styled-input-single createinput">
                                            <label htmlFor="checkbox3">Deactive the team</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="hideBuddies" className="playerlist">

                                    {this.state.allBuddies.map((item, indexes) => {

                                        if (item.profilePic === null) {
                                            switch (item.gender) {
                                                case 'Male':
                                                    return <li key={item.name}>
                                                        <span className="assignimg" style={{ backgroundImage: "url(" + maleIcon + ")" }}></span>
                                                        <label className="ac_name">{item.name}</label>
                                                        <div className="styled-input-single createinput">
                                                            <input type="radio" name="buddieslist fieldset-5" id={"checkbox-examples" + indexes} />
                                                            <label onClick={() => this.becomeOwner(item.contactNo)} htmlFor={"checkbox-examples" + indexes}></label>
                                                        </div>
                                                    </li>

                                                default:
                                                    return <li key={item.name}>
                                                        <span className="assignimg" style={{ backgroundImage: "url(" + femaleIcon + ")" }}></span>
                                                        <label className="ac_name">{item.name}</label>
                                                        <div className="styled-input-single createinput">
                                                            <input type="radio" name="buddieslist fieldset-5" id={"checkbox-examples" + indexes} />
                                                            <label onClick={() => this.becomeOwner(item.contactNo)} htmlFor={"checkbox-examples" + indexes}></label>
                                                        </div>
                                                    </li>
                                            }
                                        }
                                        else {
                                            return <li key={item.name}>
                                                <span className="assignimg" style={{ backgroundImage: "url(" + item.profilePic + ")" }}></span>
                                                <label className="ac_name">{item.name}</label>
                                                <div className="styled-input-single createinput">
                                                    <input type="radio" name="buddieslist fieldset-5" id={"checkbox-examples" + indexes} />
                                                    <label onClick={() => this.becomeOwner(item.contactNo)} htmlFor={"checkbox-examples" + indexes}></label>
                                                </div>
                                            </li>
                                        }
                                    })}

                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => this.becometheOwner()} type="button" className="cmnbutton" data-dismiss="modal">Done</button>
                                <button type="button" className="cmnbutton btnclose" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        )
    }
}

export default DemoTeamprofile;