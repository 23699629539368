import React from 'react';
import Header from './Header'
import SectionLeft from './SectionLeft'
import TournamentList from './TournamentList'
import SectionRight from './SectionRight'
import BuddyRequest from './BuddyRequest'
import ChatonRight from './ChatonRight'

function TournamentPage() {
    return (
        <TournamentList />
    );
}

export default TournamentPage;