import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from "react-router-dom";
import logo from '../img/landing/la-logo.png';
import '../css/font-awesome.min.css';
import '../css/style-landing.css';
import '../css/responsive-landing.css';
import Screenshotteam from '../img/landing/Screenshotteam.jpg';
import Screenshot2 from '../img/landing/Screenshot2.jpg'
import Screenshot3 from '../img/landing/Screenshot3.jpg'
import Screenshot4 from '../img/landing/Screenshot4.jpg'
import Screenshot5 from '../img/landing/Screenshot5.jpg'
import Screenshot6 from '../img/landing/Screenshot6.jpg'
import Screenshot7 from '../img/landing/Screenshot7.jpg'
import Screenshot8 from '../img/landing/Screenshot8.jpg'
import Screenshot9 from '../img/landing/Screenshot9.jpg'
import Screenshot10 from '../img/landing/Screenshot10.jpg'
import Screenshot11 from '../img/landing/Screenshot11.jpg'
import Screenshot12 from '../img/landing/Screenshot12.jpg'
import Screenshot13 from '../img/landing/Screenshot13.jpg'
import Screenshot14 from '../img/landing/Screenshot14.jpg'
import Screenshot15 from '../img/landing/Screenshot15.jpg'
import Screenshot16 from '../img/landing/Screenshot16.jpg'
import Screenshot17 from '../img/landing/Screenshot17.jpg'
import Screenshot18 from '../img/landing/Screenshot18.jpg'
import Screenshot19 from '../img/landing/Screenshot19.jpg'
import Screenshot20 from '../img/landing/Screenshot20.jpg'
import Screenshot21 from '../img/landing/Screenshot21.jpg'
import Screenshot22 from '../img/landing/Screenshot22.jpg'
import Screenshot23 from '../img/landing/Screenshot23.jpg'
import Screenshot24 from '../img/landing/Screenshot24.jpg'
import Screenshot25 from '../img/landing/Screenshot25.jpg'
import Screenshot26 from '../img/landing/Screenshot26.jpg'
import Screenshot27 from '../img/landing/Screenshot27.jpg'
import Screenshot28 from '../img/landing/Screenshot28.jpg'
import Screenshot29 from '../img/landing/Screenshot29.jpg'
import Screenshot30 from '../img/landing/Screenshot30.jpg'
import Screenshot31 from '../img/landing/Screenshot31.jpg'
import Screenshot32 from '../img/landing/Screenshot32.jpg'
import Screenshot33 from '../img/landing/Screenshot33.jpg'
import Screenshot34 from '../img/landing/Screenshot34.jpg'
import Screenshot35 from '../img/landing/Screenshot35.jpg'
import Screenshot36 from '../img/landing/Screenshot36.jpg'
import team from '../img/landing/team.png'
import challenge from '../img/landing/challenge.png'
import dots from '../img/landing/dots.png'
import align from '../img/landing/align.png'
import bell from '../img/landing/bell.png';
import fixture from '../img/landing/fixture.png';
import broadcast from '../img/landing/broadcast.png';
import chat from '../img/landing/chat.png';
import clip from '../img/landing/clip.png';
import buddies from '../img/landing/buddies.png';
import schedule from '../img/landing/schedule.png';



export default class Faq extends Component {
        componentDidMount() {
                if ($('.main-navigation .navigation-box').length) {
                        var subMenu = $('.main-navigation .sub-menu');
                        subMenu.parent('li').children('a').append(function () {
                                return '<button class="sub-nav-toggler"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>';
                        });
                        var mainNavToggler = $('.header-navigation .menu-toggler');
                        var subNavToggler = $('.main-navigation .sub-nav-toggler');
                        mainNavToggler.on('click', function () {
                                var Self = $(this);
                                var menu = Self.data('target');
                                $(menu).slideToggle();
                                $(menu).toggleClass('showen');
                                return false;
                        });
                        subNavToggler.on('click', function () {
                                var Self = $(this);
                                Self.parent().parent().children('.sub-menu').slideToggle();
                                return false;
                        });
                }
        }
        render() {
                return (
                        <div class="page-wrapper privacy_wrapper">
                                <header class="site-header site-header__header-one ">
                                        <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                                                <div class="container clearfix">

                                                        <div class="logo-box clearfix">
                                                                <button class="menu-toggler" data-target=".main-navigation">
                                                                        <span class="fa fa-bars"></span>
                                                                </button>
                                                                <a class="navbar-brand" href="/">
                                                                        <img src={logo} class="main-logo" alt="Awesome Image" />
                                                                </a>
                                                        </div>
                                                        <div class="main-navigation">
                                                                <ul class=" one-page-scroll-menu navigation-box">
                                                                        <li className="current scrollToLink">
                                                                                <Link to={"/"}>Home</Link>
                                                                        </li>
                                                                        <li className="scrollToLink">
                                                                                <Link to={"/about-us"}>About Us</Link>
                                                                        </li>
                                                                        <li className="scrollToLink">
                                                                                <Link to={"/privacy-policy"}>Privacy Policy</Link>

                                                                        </li>

                                                                        <li className="scrollToLink">
                                                                                <Link to={"/contact-us"}>Contact Us</Link>
                                                                        </li>
                                                                        <li className="current scrollToLink">
                                                                                <Link to={"/faq"}>FAQ </Link>


                                                                        </li>
                                                                </ul>
                                                        </div>
                                                        <div class="right-side-box">
                                                                <Link class="thm-btn header__cta-btn" to={"/login"}><span>Login</span></Link>
                                                        </div>
                                                </div>

                                        </nav>
                                </header>


                                <div class="container privacy_container">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad7">
                                                <section class="d_midsec">
                                                        <div class="divpost">
                                                                <div class="faq_content">
                                                                        <h3>Get Started</h3>
                                                                        <h4>Q. Registration (Create Account)</h4>
                                                                        <p>Launch Playdiator app</p>
                                                                        <p>Tap on Sign Up</p>
                                                                        <p>Register with your contact number & verify using OTP</p>
                                                                        <p>Then,</p>
                                                                        <p>A profile window will appear</p>
                                                                        <p>Fill the details like Name, Email , Password, City  and Select Gender</p>
                                                                        <p>Click on Submit</p>
                                                                        <p>Or user can click on facebook or google button to signup</p>


                                                                        <h4>Q. Select / Choose Your favorite sports ?</h4>
                                                                        <p>After completing Sign up process, a window will appear of</p>
                                                                        <p>Favorite Sports</p>
                                                                        <p>You can choose your favorite sports from the given 72+ sports</p>


                                                                        <h4>Q. how to sign In</h4>
                                                                        <p>Launch Playdiator App</p>
                                                                        <p>If you have an account, you can sign in using credentials</p>
                                                                        <p>Enter Login ID & Password</p>
                                                                        <p> Tap on Sign in</p>

                                                                        <h4>Q. Change / Forget Password ?</h4>
                                                                        <p>Launch Playdiator app</p>
                                                                        <p>If you have already registered with playdiator and you forgot your password</p>
                                                                        <p>Then, </p>
                                                                        <p>In Sign in window,</p>
                                                                        <p> Tap on Forget Password</p>
                                                                        <p>Enter your registered mobile number & verify using OTP</p>
                                                                        <p>Once it is verified, A new window will appear</p>
                                                                        <p>Enter New password and Tap on Change button</p>
                                                                        <p>OR</p>
                                                                        <p>On Home page, Tap on yellow icon on the<img src={align} class="line_img" />left top corner</p>
                                                                        <p> Side menu will open</p>
                                                                        <p> Scroll it , You will be able to see Change Password option</p>
                                                                        <p> Tap on Change Password</p>
                                                                        <p> A new window will appear,</p>
                                                                        <p>Enter your old & New password</p>
                                                                        <p>Tap on Submit Button</p>

                                                                        <h4>Q. Contact Admin for any query</h4>
                                                                        <p>On Home page, Tap on yellow icon<img src={align} class="line_img" />on the left top corner</p>
                                                                        <p>Side menu will open</p>
                                                                        <p>Scroll it , You will be able to see Contact Admin option</p>
                                                                        <p>Tap on Contact Admin</p>
                                                                        <p>Fill in the details and tap on Send</p>


                                                                        <h3>Team</h3>
                                                                        <h4>Q. How can I create a team?</h4>
                                                                        <p>Step 1. Launch Playdiator app</p>
                                                                        <p>Step 2. Select team<img src={team} class="line_img" />
                            Tab at the home screen OR Tap on<img src={align} class="line_img" />yellow icon on the left top corner
                                Select My team</p>
                                                                        <p>step 3. Tap on Create team </p>
                                                                        <a href="assets/images/Screenshot-team.jpg" data-lightbox="Screenshot"><img id="myImg" src={Screenshotteam} class="Screenshot" /></a>
                                                                        <p>Select any Sports for example Archery  </p>
                                                                        <p> Select Gender – Male / Female / Fix </p>
                                                                        <p> Type Team Name</p>
                                                                        <p>Then Choose options Either Include me in the team OR Don’t Include me in the team</p>
                                                                        <p>Pick a Location</p>
                                                                        <p>Tap on Next</p>
                                                                        <p>Select Buddies then click on “Done”</p>


                                                                        <h4>Q What is the max size of a team?</h4>
                                                                        <p>It varies depending upon Sports you choose</p>
                                                                        <p>It gives you alert for the Max or Min Size for the team </p>

                                                                        <h4>Q. How can I delete the team?</h4>
                                                                        <p>You cannot Delete the team Only Owner/ Creator has right to Deactivate the team</p>

                                                                        <h4>Q. How can I change the ownership of the team?</h4>
                                                                        <note>NOTE : the one who created the team can only change the ownership of team</note>
                                                                        <p>Choose a team from Team Section</p>
                                                                        <p>Tap on More button <img src={dots} class="line_img" style={{ width: '6px' }} />“At top right corner”</p>
                                                                        <p>A Popup will appear</p>
                                                                        <a href="assets/images/Screenshot2.jpg" data-lightbox="Screenshot2"><img id="myImg" src={Screenshot2} class="Screenshot" /></a>
                                                                        <p>If You will choose – Change the owner/ keep me as member</p>
                                                                        <p>then</p>
                                                                        <p>A pop will appear of Buddy list and from them you can choose one as Owner.
                            </p>
                                                                        <p>A notification will be sent to selected buddy if he accepts then he will become owner</p>
                                                                        <p>If You will choose –</p>
                                                                        <p>Change the Owner / Remove me from Team
                            </p>
                                                                        <p>Then, Same steps will be followed as mentioned earlier
                            </p>

                                                                        <h4>Q. How to assign/change captain of team?</h4>
                                                                        <p>Choose a team from Team Section<img src={team} class="line_img" /></p>
                                                                        <p>ISelect Team Info tab
                            </p>
                                                                        <p>Then you will be able to see Change Captain Option from there you can change.
                            </p>
                                                                        <a href="assets/images/Screenshot3.jpg" data-lightbox="Screenshot3"><img id="myImg" src={Screenshot3} class="Screenshot" /></a>


                                                                        <h4>Q. How to add/remove players from team?
                            </h4>
                                                                        <note>NOTE : the one who created the team can only have access to any changes for  team
                            </note>
                                                                        <p>Choose a team from Team Section <img src={team} class="line_img" />
                                                                        </p>
                                                                        <p> Select Players tab,
                            </p>
                                                                        <p> Then you will be able to see Add player option
                            </p>
                                                                        <a href="assets/images/Screenshot4.jpg" data-lightbox="Screenshot4"><img id="myImg" src={Screenshot4} class="Screenshot" /></a>
                                                                        <p>Or you can remove players by tap on it </p>



                                                                        <h4>Q. How to change Owner playing options?</h4>
                                                                        <note>NOTE : The one who created the team can only have access to any changes for  team
                            </note>
                                                                        <p>Choose a team from Team Section <img src={team} class="line_img" />
                                                                        </p>
                                                                        <p>Tap on More button “At top right corner”<img src={dots} class="line_img" style={{ width: '6px' }} /></p>
                                                                        <p>A Popup will appear </p>
                                                                        <a href="assets/images/Screenshot5.jpg" data-lightbox="Screenshot5"><img id="myImg" src={Screenshot5} class="Screenshot" /></a>
                                                                        <p>If You will choose – Change the owner/ keep me as member
                            </p>
                                                                        <p>Then,
                            </p>
                                                                        <p>A pop will appear of Buddy list and from them you can one as Owner.
                            </p>
                                                                        <p>A notification will be sent to selected buddy if he accepts then he will become owner
                            </p>
                                                                        <p>If You will choose –
                            </p>
                                                                        <p>Change the Owner / Remove me from Team
                            </p>
                                                                        <p>Then, Same steps will be followed as mentioned earlier
                            </p>

                                                                        <h3>Challenge
                            </h3>
                                                                        <h4>Q. How can I create a challenge?
                            </h4>
                                                                        <p>From Home Page , Tap On Challenge <img src={challenge} class="line_img" />
                                            Tab    OR You can choose option from Side Menu<img src={align} class="line_img" /></p>
                                                                        <p>Tap On Create Challenge
                            </p>
                                                                        <a href="assets/images/Screenshot6.jpg" data-lightbox="Screenshot6"><img id="myImg" src={Screenshot6} class="Screenshot" /></a>
                                                                        <p>Select Sport</p>
                                                                        <p>You can give Event Name
                            </p>
                                                                        <p>Customize Event
                            </p>
                                                                        <p>Select Challenge Type “My Buddies/Challenge Unknown Player/ Team”
                            </p>
                                                                        <p>You can choose a region for the opponents
                            </p>
                                                                        <p>Then Select Opponent you want to challenge
                            </p>
                                                                        <p>Pick Date & Time for the event
                            </p>
                                                                        <p>Select Location
                            </p>
                                                                        <p>Choose Challenge Privacy “Public Or Private”
                            </p>
                                                                        <p>Press Create Challenge Button
                            </p>

                                                                        <h4>Q. How can I accept /Reject a challenge?
                            </h4>
                                                                        <p>From Challenge Section You will get a list of all challenge events
                            </p>
                                                                        <p>You can either accept or reject a challenge by selecting any challenge from the list
                            </p>
                                                                        <p>OR
                            </p>
                                                                        <p>From the Home screen tap on bell icon<img src={bell} class="line_img" />
                                , you will get a notification from Challenger by tapping on it
                            </p>
                                                                        <p>A window will appear
                            </p>
                                                                        <p>You will be able to see the option to accept or reject
                            </p>
                                                                        <a href="assets/images/Screenshot7.jpg" data-lightbox="Screenshot7"><img id="myImg" src={Screenshot7} class="Screenshot" /></a>



                                                                        <h4>Q. How to update/ View score in challenge?
                            </h4>
                                                                        <p>From Home, Select Challenge tab <img src={challenge} class="line_img" />
                                                                        </p>
                                                                        <p>Choose a challenge from a list, a window will appear
                            </p>
                                                                        <p>If you had already accepted the challenge You can tap on Update Score button  to update score
                            </p>
                                                                        <a href="assets/images/Screenshot8.jpg" data-lightbox="Screenshot8"><img id="myImg" src={Screenshot8} class="Screenshot" /></a>
                                                                        <p>Once you update the score for the challenge you can view score by tapping on the View Score button for the future.
                            </p>


                                                                        <h4>Q How to delete a challenge?
                            </h4>
                                                                        <p>There is no option available to delete a challenge.
                            </p>


                                                                        <h4>Q. How can I edit/delete schedule?
                            </h4>
                                                                        <p>There is no option available for now.
                            </p>


                                                                        <h4>Q. How can I get a schedule of upcoming matches?
                            </h4>
                                                                        <p>From the schedule tab <img src={schedule} class="line_img" />on home screen
                            </p>
                                                                        <p>You can get information about upcoming or completed schedules
                            </p>
                                                                        <a href="assets/images/Screenshot9.jpg" data-lightbox="Screenshot9"><img id="myImg" src={Screenshot9} class="Screenshot" /></a>


                                                                        <h3>Chat</h3>
                                                                        <h4>Q. How can I chat with others?
                            </h4>
                                                                        <p>By tapping on Chat tab <img src={chat} class="line_img" />on home screen
                            </p>
                                                                        <p>You will be redirect to Chat Screen where you have option to chat with Buddies
                            </p>
                                                                        <a href="assets/images/Screenshot10.jpg" data-lightbox="Screenshot10"><img id="myImg" src={Screenshot10} class="Screenshot" /></a>


                                                                        <h4>Q. How can I block/ unblock individuals in chat?
                            </h4>
                                                                        <p>In the chat Section <img src={chat} class="line_img" />
                                                                        </p>
                                                                        <p>By selecting the chat
                            </p>
                                                                        <p>Then click on the top right more icon (3 dots) <img src="assets/images/greendots.png" class="line_img" style={{ width: '6px' }} /></p>



                                                                        <h4>Q. How can I create a chat group ?
                            </h4>
                                                                        <p>There is no option to create a chat group
                            </p>
                                                                        <p>But, If you are part of any team so you can chat within the team group
                            </p>

                                                                        <h4>Q. How can I delete a chat group ?
                            </h4>
                                                                        <p>Only team owner has rights to activate or deactivate member of any chat
                            </p>


                                                                        <h4>Q. How can I attach media files in chat ?
                            </h4>
                                                                        <p>In the chat section you can tap on attachment icon  <img src={clip} class="line_img" />
                                then a pop will appear from there you can add media and send
                            </p>


                                                                        <h4>Q. How can I chat with the tournament organizer ?
                            </h4>
                                                                        <p>From Home , Tap on Tournament<img src={fixture} class="line_img" />
                                                                        </p>
                                                                        <p>There you will get a chat option on the top right corner to chat with the organizer
                            </p>
                                                                        <a href="assets/images/Screenshot11.jpg" data-lightbox="Screenshot11"><img id="myImg" src={Screenshot11} class="Screenshot" /></a>


                                                                        <h3>Buddies</h3>
                                                                        <h4>Q. How can I add buddies?
                            </h4>
                                                                        <p>On Home page, Tap on Buddies<img src={buddies} class="line_img" />
                                on Bottom left corner
                            </p>
                                                                        <p>Tap on Sync Buddies so all your mobile contact will show as your buddies from there you
                                                                        can send request to add your contact as buddies
                            </p>
                                                                        <a href="assets/images/Screenshot12.jpg" data-lightbox="Screenshot12"><img id="myImg" src={Screenshot12} class="Screenshot" /></a>
                                                                        <p>From the Home screen tap on the search bar on top of the screen there you can type a name
                                if the name exists in Playdiator user then it will appear.</p>
                                                                        <p>Then profile of the person will appear and you can tap on send request once he accepts he will be added in your buddy list</p>
                                                                        <a href="assets/images/Screenshot13.jpg" data-lightbox="Screenshot13"><img id="myImg" src={Screenshot13} class="Screenshot" /></a>


                                                                        <h4>Q. How can I invite / search buddies?</h4>
                                                                        <p>Search Buddies</p>
                                                                        <p>From the Home screen tap on the search bar on top of the screen there you can type a name
                                if the name exists in Playdiator user then it will appear.</p>
                                                                        <p>Invite Buddies</p>
                                                                        <p>To invite</p>
                                                                        <p>On Home screen tap on buddies section <img src={buddies} class="line_img" />a window will appear</p>
                                                                        <p>Tap on Invite Buddies you can choose multiple platforms to invite</p>
                                                                        <a href="assets/images/Screenshot14.jpg" data-lightbox="Screenshot14"><img id="myImg" src={Screenshot14} class="Screenshot" /></a>


                                                                        <h4>Q. How can I follow / Send requests to buddies?</h4>
                                                                        <p>From the Home screen tap on the search bar on top of the screen there you can type a name
                                if the name exists in Playdiator user then it will appear.</p>
                                                                        <p>Tap on the profile to whom you want to add as your buddy</p>
                                                                        <p>Then profile of the person will appear and you can tap on send request once he accepts he will be added in your buddy list</p>
                                                                        <a href="assets/images/Screenshot15.jpg" data-lightbox="Screenshot15"><img id="myImg" src={Screenshot15} class="Screenshot" /></a>
                                                                        <p>If you are sending request to someone to add in your buddy then automatically you are following him</p>

                                                                        <h4>Q. How can I accept a buddy request?</h4>
                                                                        <p>On Home page, Tap on Buddies<img src={buddies} class="line_img" />on Bottom left corner</p>
                                                                        <p>Then you will be able to see buddy request you can tap on Accept button to accept or You can tap on Reject to reject it</p>
                                                                        <a href="assets/images/Screenshot16.jpg" data-lightbox="Screenshot16"><img id="myImg" src={Screenshot16} class="Screenshot" /></a>



                                                                        <h3>Broadcast</h3>
                                                                        <h4>Q. How can I broadcast a challenge match?</h4>
                                                                        <p>From Home Screen</p>
                                                                        <p>From Home Screen<img src={challenge} class="line_img" />Select challenge for which you want to broadcast</p>
                                                                        <p>Then Challenge Profile window will open</p>
                                                                        <p>Press on Update score button then you have to tap on Green button to start broadcast</p>
                                                                        <a href="assets/images/Screenshot17.jpg" data-lightbox="Screenshot17"><img id="myImg" src={Screenshot17} class="Screenshot" /></a>


                                                                        <h4>Q. How can I broadcast a tournament match?</h4>
                                                                        <p>From Home Screen</p>
                                                                        <p>Tap on tournament<img src={fixture} class="line_img" /></p>
                                                                        <p>Then Tournament Profile window will open</p>
                                                                        <p>Press on Update score button then you have to tap on Green button to start broadcast</p>
                                                                        <a href="assets/images/Screenshot18.jpg" data-lightbox="Screenshot18"><img id="myImg" src={Screenshot18} class="Screenshot" /></a>


                                                                        <h4>Q. How can I make random broadcast?</h4>
                                                                        <p>From Home Screen</p>
                                                                        <p>Tap on Broadcast <img src={broadcast} class="line_img" /></p>
                                                                        <p>Tap on Create Broadcast customize all details then by tapping on Create broadcast ,broadcast will get started</p>
                                                                        <a href="assets/images/Screenshot18.jpg" data-lightbox="Screenshot182"><img id="myImg" src={Screenshot18} class="Screenshot" /></a>


                                                                        <h4>Q. How can I view random broadcast?</h4>
                                                                        <p>From Home Screen</p>
                                                                        <p>Tap on Broadcast <img src={broadcast} class="line_img" />, Tap on View as list</p>
                                                                        <p>You will be able to view broadcast</p>
                                                                        <a href="assets/images/Screenshot19.jpg" data-lightbox="Screenshot19"><img id="myImg" src={Screenshot19} class="Screenshot" /></a>


                                                                        <h4>Q. How can I delete a broadcast?</h4>
                                                                        <p>You can delete only your broadcast list</p>
                                                                        <p>On home screen, Tap on side menu <img src={align} class="line_img" /></p>
                                                                        <p>Tap on Profile picture scroll down ,Tap on View all</p>
                                                                        <a href="assets/images/Screenshot20.jpg" data-lightbox="Screenshot20"><img id="myImg" src={Screenshot20} class="Screenshot" /></a>
                                                                        <p>then you will be able to see list</p>
                                                                        <a href="assets/images/Screenshot21.jpg" data-lightbox="Screenshot21"><img id="myImg" src={Screenshot21} class="Screenshot" /></a>
                                                                        <p>Then you can delete broadcast</p>


                                                                        <h4>Q. How can I search for a broadcast?</h4>
                                                                        <p>From Home Screen</p>
                                                                        <p>Tap on Search bar, Select Broadcast</p>
                                                                        <p>Then search by broadcast title</p>



                                                                        <h3>Tournament </h3>
                                                                        <h4>Q. How can I create a tournament?</h4>
                                                                        <p>On Home screen, Tap on tournament <img src={fixture} class="line_img" /></p>
                                                                        <p>Tap on Create tournament</p>
                                                                        <p>Customize it then tap on Create tournament</p>
                                                                        <a href="assets/images/Screenshot22.jpg" data-lightbox="Screenshot22"><img id="myImg" src={Screenshot22} class="Screenshot" /></a>


                                                                        <h4>Q. How can I delete a tournament?</h4>
                                                                        <p>Not available</p>

                                                                        <h4>Q. How can I change the dates of the tournament?</h4>
                                                                        <p>Once you fix dates of tournament you will not able to change it</p>


                                                                        <h4>Q. How can I create the fixture manually?</h4>
                                                                        <p>From Home page , Tap on Tournament <img src={fixture} class="line_img" /></p>
                                                                        <p>Tap on create tournament, customize the tournament details</p>
                                                                        <a href="assets/images/Screenshot23.jpg" data-lightbox="Screenshot23"><img id="myImg" src={Screenshot23} class="Screenshot" /></a>
                                                                        <p>Select Sports </p>
                                                                        <p>1. Knockout
                                <ul>
                                                                                        <li>Knock out</li>
                                                                                        <li>Pool based knockout</li>
                                                                                </ul>
                                                                        </p>
                                                                        <p>Knock out – To organize knock out type tournament you can choose your buddy or team</p>
                                                                        <p>Minimum Numbers of  – (Team/Player ) – 4 , Multiple of 2 ( 4, 8, 16, 32, 64….)</p>
                                                                        <p>Pool based knock-out - – To organize knock out type tournament you can choose your buddy or team</p>
                                                                        <p>Minimum Numbers of  – (Team/Player ) – 8, Multiple of 2 (  8, 16, 32, 64….)</p>
                                                                        <p>1. In order to organize , there are two options available either you can select team or player
                            </p>
                                                                        <p>2. Invite Buddies</p>
                                                                        <p>3. Invited buddy should accept the invitation</p>
                                                                        <p>4. Select tournament name from the list , tap on schedule</p>
                                                                        <p>5. Tap on schedule matches Select fixture type Either knock out or pool based knock out</p>
                                                                        <p>6. Select players tap on done</p>
                                                                        <p>7. You can reshuffle players if you want to reshuffle players</p>
                                                                        <p>8. Add date & time for all matches</p>
                                                                        <p>9. Tap on create
                            </p>


                                                                        <h4>Q. How to update/view /edit/ Score of tournament matches?</h4>
                                                                        <p>From Home, Select Tournament tab<img src={fixture} class="line_img" /></p>
                                                                        <p>Choose a Tournament from a list, a window will appear</p>
                                                                        <p>If you had already accepted the Tournament You can tap on Update Score button  to update score</p>
                                                                        <p>Once you update the score for the Tournament you can view score by tapping on View Score button for future.</p>
                                                                        <a href="assets/images/Screenshot24.jpg" data-lightbox="Screenshot24"><img id="myImg" src={Screenshot24} class="Screenshot" /></a>



                                                                        <h4>Q. How to promote the winning team / Player to the next round?</h4>
                                                                        <p>NA</p>


                                                                        <h4>Q. How to invite a team /Players to a tournament?</h4>
                                                                        <note>Note: Only team owner has rights to invite team /players to tournament</note>
                                                                        <p>From Home, Select Tournament tab<img src={fixture} class="line_img" /> </p>
                                                                        <p>Choose tournament from the list</p>
                                                                        <p>Tap on invite tab there you can invite your team/players</p>
                                                                        <a href="assets/images/Screenshot25.jpg" data-lightbox="Screenshot25"><img id="myImg" src={Screenshot25} class="Screenshot" /></a>

                                                                        <h4>Q. How to accept / Reject tournament invitations?</h4>
                                                                        <p>From Home page , Tap on bell icon </p>
                                                                        <p>you will find tournament invitation where you can accept or reject the invitation</p>
                                                                        <a href="assets/images/Screenshot26.jpg" data-lightbox="Screenshot26"><img id="myImg" src={Screenshot26} class="Screenshot" /></a>


                                                                        <h4>Q. How to organize tournament matches for</h4>
                                                                        <h4>TT – 1,2,3,4,5,….? Knockout, Straight league, Pool based league, Pool based knockout, Straight final</h4>
                                                                        <p>From Home page , Tap on Tournament</p>
                                                                        <p>Tap on create tournament, customize the </p>
                                                                        <p>Select Sports if tournament type is </p>
                                                                        <p>2. Knockout
                           <ul>
                                                                                        <li>Knock out</li>
                                                                                        <li>Pool based knockout</li>
                                                                                </ul></p>
                                                                        <p>Knock out – To organize knock out type tournament you can choose your buddy or team</p>
                                                                        <p>Minimum Numbers of  – (Team/Player ) – 4 , Multiple of 2 ( 4, 8, 16, 32, 64….)</p>
                                                                        <p>Pool based knockout - – To organize knock out type tournament you can choose your buddy or team </p>
                                                                        <p>Minimum Numbers of  – (Team/Player ) – 8, Multiple of 2 (  8, 16, 32, 64….)</p>
                                                                        <p>X. In order to organize , there are two options available either you can select team or player</p>
                                                                        <p>XI. Invite Buddies</p>
                                                                        <p>XII. Invited buddy should accept the invitation</p>
                                                                        <p>XIII. Select tournament name from the list , tap on schedule.</p>
                                                                        <p>XIV. Tap on schedule matches Select fixture type Either knock out or pool based knock out</p>
                                                                        <p>XV. Select players tap on done</p>
                                                                        <p>XVI. You can reshuffle players if you want to reshuffle players</p>
                                                                        <p>XVII. Add date & time for all matches</p>
                                                                        <p>XVIII. Tap on create</p>
                                                                        <p style={{ backgroundColor: '#2cae4a', color: '#fff' }}>Sports name</p>
                                                                        <p>3. Straight league</p>
                                                                        <p>Straight League – To organize Straight League type tournament you can choose your buddy or team</p>
                                                                        <p>Minimum Numbers of  – (Team/Player ) – 5</p>
                                                                        <p>I. In order to organize , there are two options available either you can select team or player</p>
                                                                        <p>II. Invite Buddies</p>
                                                                        <p>III. Invited buddy should accept the invitation</p>
                                                                        <p>IV. Select tournament name from the list, tap on schedule.</p>
                                                                        <p>V. Tap on schedule matches </p>
                                                                        <p>VI. Select players tap on done</p>
                                                                        <p>VII. Add date & time for all matches</p>
                                                                        <p>VIII. Tap on create</p>
                                                                        <p>4. Pool based league</p>
                                                                        <p>Pool based League – To organize Straight League type tournament you can choose your buddy or team </p>
                                                                        <p>Minimum Numbers of – (Team/Player ) –  6</p>
                                                                        <p>Minimum Numbers of Pool – 2 Maximum number of pools - 4</p>
                                                                        <p>I. In order to organize , there are two options available either you can select team or player</p>
                                                                        <p>II. Invite Buddies</p>
                                                                        <p>III. Invited buddy should accept the invitation</p>
                                                                        <p>IV. Select tournament name from the list, tap on schedule.</p>
                                                                        <p>V. Tap on schedule matches </p>
                                                                        <p>VI. Select players tap on done</p>
                                                                        <p>VII. Add date & time for all matches</p>
                                                                        <p>VIII. Tap on create</p>
                                                                        <p>5. Pool Based KnockOut</p>
                                                                        <p>From Home page , Tap on Tournament</p>
                                                                        <p>Tap on create tournament, customize the</p>
                                                                        <p>Select Sports if tournament type is</p>
                                                                        <p>Pool based knock out - – To organize knock out type tournament you can choose your buddy or team</p>
                                                                        <p>Minimum number of Pool – 2 </p>
                                                                        <p>Maximum number of Pool - 8</p>
                                                                        <p>8 in a row – Minimum Numbers of  – (Team/Player ) – 12 ,</p>
                                                                        <p> For 12 to 16 Players , Number of pools -</p>
                                                                        <p>For 24 to 32 Players , Number of pools -4</p>
                                                                        <p>For 48 to 64 Players , Number of pools -8</p>
                                                                        <p>User Define – Min – 10 Players</p>
                                                                        <p>For 10 to 20  Players , Number of pools -2</p>
                                                                        <p>For 20  to 40 Players , Number of pools – you can choose</p>
                                                                        <p>For 40 to 80 Players , Number of pools –you can choose</p>
                                                                        <p>For 80 to 160 Players , Number of pools - 8</p>
                                                                        <p>19. In order to organize , there are two options available either you can select team or player</p>
                                                                        <p>20. Invite Buddies</p>
                                                                        <p>21. Invited buddy should accept the invitation</p>
                                                                        <p>22. Select tournament name from the list , tap on matches</p>
                                                                        <p>23.  Tap on organize matches </p>
                                                                        <p>24. Select players tap on done</p>
                                                                        <p>25. Add date & time for all matches</p>
                                                                        <p>26. Tap on create</p>
                                                                        <p>6. Straight Final</p>
                                                                        <p>Straight League – To organize Straight League type tournament you can choose your buddy or team</p>
                                                                        <p>I. In order to organize , there are two options available either you can select team or player</p>
                                                                        <p>II. Invite Buddies</p>
                                                                        <p>III. Invited buddy should accept the invitation</p>
                                                                        <p>IV. Select tournament name from the list, tap on schedule.</p>
                                                                        <p>V. Tap on schedule matches </p>
                                                                        <p>VI. Select players tap on done</p>
                                                                        <p>VII. Add date & time for all matches</p>
                                                                        <p>VIII. Tap on create</p>
                                                                        <note>NOTE : Either you can create tournament for test you can use demo tournament</note>


                                                                        <h4>Q. How to view fixtures, completed matches & points table?</h4>
                                                                        <p>Tournament / Challenge </p>
                                                                        <p>Open profile </p>
                                                                        <p>You will be able to see fixtures, Completed matches & point table </p>
                                                                        <a href="assets/images/Screenshot27.jpg" data-lightbox="Screenshot27"><img id="myImg" src={Screenshot27} class="Screenshot" /></a>
                                                                        <a href="assets/images/Screenshot28.jpg" data-lightbox="Screenshot28"><img id="myImg" src={Screenshot28} class="Screenshot" /></a>
                                                                        <a href="assets/images/Screenshot29.jpg" data-lightbox="Screenshot29"><img id="myImg" src={Screenshot29} class="Screenshot" /></a>



                                                                        <h4>Q. How to declare a winner in case the match is drawn / tie?</h4>
                                                                        <p>You can choose winner manually </p>


                                                                        <h4>Q. How to set the venue & date / time of individual matches?</h4>
                                                                        <p>From Home Page , Tap On Challenge/Tournament Tab OR You can choose option from Side Menu
                                <img src={align} class="line_img" /></p>
                                                                        <p>Tap On Create Challenge/Tournament</p>
                                                                        <p>Select Sport</p>
                                                                        <p>You can give Event Name</p>
                                                                        <p>Customize Event</p>
                                                                        <p>Select Challenge/Tournament Type “My Buddies/Challenge Unknown Player/ Team”</p>
                                                                        <p>You can choose region for the opponents</p>
                                                                        <p>Then Select Opponent you want to challenge</p>
                                                                        <p>Pick Date & Time for the event</p>
                                                                        <p>Select Location</p>
                                                                        <p>Choose Challenge/Tournament Privacy “Public Or Private”</p>
                                                                        <p>Press Create Challenge/Tournament Button</p>
                                                                        <a href="assets/images/Screenshot30.jpg" data-lightbox="Screenshot30"><img id="myImg" src={Screenshot30} class="Screenshot" /> </a>

                                                                        <h4>Q. How to assign Player of Series, Player of Match,  Best batsman , Best Bowler ,
                                Best Fielder in case of Cricket ?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>Tap on Challenge / Tournament</p>
                                                                        <p>Choose a challenge / Tournament from the list</p>
                                                                        <p>Tap On Info</p>
                                                                        <p>Then you can assign Player of Series, Player of Match,  Best batsman , Best Bowler ,
                                Best Fielder in case of Cricket</p>
                                                                        <a href="assets/images/Screenshot31.jpg" data-lightbox="Screenshot31"><img id="myImg" src={Screenshot31} class="Screenshot" /></a>
                                                                        <a href="assets/images/Screenshot32.jpg" data-lightbox="Screenshot32"><img id="myImg" src={Screenshot32} class="Screenshot" /></a>
                                                                        <a href="assets/images/Screenshot33.jpg" data-lightbox="Screenshot33"><img id="myImg" src={Screenshot33} class="Screenshot" /></a>


                                                                        <h4>Q. How to upload media of specific matches?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>Tap on Challenge / Tournament</p>
                                                                        <p>You have to choose a tournament/ Challenge from the list</p>
                                                                        <p>Scroll down From gallery you can upload images /videos</p>
                                                                        <a href="assets/images/Screenshot34.jpg" data-lightbox="Screenshot34"><img id="myImg" src={Screenshot34} class="Screenshot" /></a>

                                                                        <h4>Q. How to search for a tournament?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>Tap on Search bar, Select tournament</p>
                                                                        <p>Then search by tournament title</p>


                                                                        <h3>Post </h3>
                                                                        <h4>Q. How to create a new post?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>tap on “ write Something  Here” text</p>
                                                                        <p>a new window will appear here you can create your new post</p>
                                                                        <a href="assets/images/Screenshot35.jpg" data-lightbox="Screenshot35"><img id="myImg" src={Screenshot35} class="Screenshot" /></a>

                                                                        <h4>Q. How to tag buddies, select check in & medias (IMG/ VID) in post ?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>tap on “ write Something  Here” text</p>
                                                                        <p>a new window will appear here you can create your new post</p>
                                                                        <p>You will be able to see option from tag buddies, select check in & medias (IMG/ VID) in post</p>
                                                                        <a href="assets/images/Screenshot35.jpg" data-lightbox="Screenshot352"><img id="myImg" src={Screenshot35} class="Screenshot" /></a>

                                                                        <h4>Q. How to add a youtube view in post?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>tap on “ write Something  Here” text</p>
                                                                        <p>a new window will appear here </p>
                                                                        <p>Tap on videos and you can choose option for youtube link</p>
                                                                        <a href="assets/images/Screenshot35.jpg" data-lightbox="Screenshot353"><img id="myImg" src={Screenshot35} class="Screenshot" /></a>

                                                                        <h4>Q. How to set post privacy in post?</h4>
                                                                        <p>From Home screen</p>
                                                                        <p>tap on “ write Something  Here” text</p>
                                                                        <p>a new window will appear here </p>
                                                                        <p>Then tap on dropdown button</p>
                                                                        <p>here you find privacy option Public, Buddies, Only me</p>
                                                                        <a href="assets/images/Screenshot36.jpg" data-lightbox="Screenshot36"><img id="myImg" src={Screenshot36} class="Screenshot" /></a>

                                                                </div>
                                                        </div>
                                                </section>
                                        </div>
                                </div>

                                <section>

                                </section>
                        </div>
                )
        }
}
