import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import * as TournamentsUtil from '../DemoTournament/DTournamentsUtil';
import NumericInput from 'react-numeric-input';
import { message } from 'antd';
import finallogo from '../img/finallogo.png';
var numberOfAttempt;
var scoreCardD101s = [];
var d101ScoreCard = {}, score, points;
var maxWeights = [];
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
let organiseMatchObj = {};
let tId = null;
var maxWeights = [];
var selectedWinner;
export default class Tournament_d101_Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD101State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,

        };
        this.endGame = this.endGame.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.done = this.done.bind(this);
        this.disqualify = this.disqualify.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false, setModalIsOpen: false, fillScoreModalOpen: false, winnerModalOpen: false });
    }

    doneMatch() {
        let wId = null;
        if (maxWeights.length === 1) {
            wId = maxWeights[0].userId;
        }
        else {
            wId = this.state.selectWinner;
        }
        tId = this.props.challenge.tournamentId;
        let mId = tId + '-' + wId;
        delete organiseMatchObj.id;

        if (organiseMatchObj.finals.ended) {
            var moment = require('moment');
            var now = moment().format();
            var timpstampData = new Date(now).valueOf();
            var tournament = this.props.challenge;
            delete tournament.id;
            tournament.status = "completed";
            tournament.modifiedOn = timpstampData;
            tournament.tournamentWinnerId = wId;

            TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
                console.log(data)
                this.setState({ winnerModalOpen: false })
                TournamentsUtil.ttWinnerFeeds(tId, mId, wId).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        window.location = "/dtournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
                    })
                })
            })
        }
        else {
            // TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => {
            //     this.setState({ winnerModalOpen: false })
            //     window.location = "/tournamentprofile/index.html?id=" + this.props.challenge.tournamentId;
            // })
        }
    }
    endGame() {
        let scoreFill = this.state.scoreCardD101State;
        let allFill = true;
        scoreFill.map(score => {
            if (score.points.length == numberOfAttempt) {
                allFill = true;
            }
            else {
                allFill = false;
            }
        })
        if (allFill) {
            if (this.props.matchType === -2) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)
                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                maxWeights = [];
                maxWeights = this.state.scoreCardD101State;
                organiseMatchObj.tt4Matches.map((tt4, index) => {
                    if (index === this.props.clickedIndex) {
                        tt4.ended = true;
                    }
                });

                console.log(maxWeights)
                this.setState({ winnerModalOpen: true });
            }
            if (this.props.matchType === -3) {
                if (Object.keys(organiseMatchObj).length !== 0) {
                    console.log(organiseMatchObj)
                }
                else {
                    organiseMatchObj = this.props.tt4;
                }
                maxWeights = [];
                // maxWeights = this.state.scoreCardD101State;
                organiseMatchObj.finals.ended = true;
                const max = this.state.scoreCardD101State.reduce((prev, current) => (prev.maxWeightLifted > current.maxWeightLifted) ? prev : current)
                this.state.scoreCardD101State.map((x, index) => {
                    if (x.maxWeightLifted === max.maxWeightLifted) {
                        maxWeights.push(x);
                    }
                });

                console.log(maxWeights)
                this.setState({ winnerModalOpen: true });
            }
        }
        else {
            message.info('Please Fill All Field');
        }
    }
    changeData(event) {
        let checkedArray = this.state.optionsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            numberOfAttempt = selectedValue;
        }
    }

    disqualify(event) {
        this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var weight = 0;

        console.log(this.state.scoreCardD101State);
        let scoreCardD101State = this.state.scoreCardD101State;
        scoreCardD101State.map((x, index) => {
            if (index === this.state.row) {
                if (x.points !== null) {
                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, weightLifted: weight, disqualified: true };
                    }
                    else {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, weightLifted: weight, disqualified: true };
                    }
                }
                else {

                    x.points = [{ attempt: this.state.setIndex + 1, weightLifted: weight, disqualified: true }];
                }
                //   this.numberOfAttempts();
            }
        });
        this.setState({ scoreCardD101State: scoreCardD101State })

        organiseMatchObj = this.props.tt4;
        if (this.props.matchType === -2) {
            organiseMatchObj.tt4Matches.map((tt4, index) => {
                if (index === this.props.clickedIndex) {
                    tt4.matchesList.map((ml, ind) => {
                        if (scoreCardD101State[ind].maxWeightLifted) {
                            ml.index = scoreCardD101State[ind].points.length;
                            ml.d101ScoreCard = {

                                maxWeightLifted: scoreCardD101State[ind].maxWeightLifted, points: scoreCardD101State[ind].points
                            }
                        }
                        else {
                            ml.d101ScoreCard = null
                        }
                    })
                }
            })
        }
        if (this.props.matchType === -3) {

            organiseMatchObj.finals.matchesList.map((ml, ind) => {
                if (scoreCardD101State[ind].maxWeightLifted) {
                    ml.index = scoreCardD101State[ind].points.length;
                    ml.d101ScoreCard = {
                        maxWeightLifted: scoreCardD101State[ind].maxWeightLifted, points: scoreCardD101State[ind].points
                    }
                }
                else {
                    ml.d101ScoreCard = null
                }
            })
        }
        tId = this.props.challenge.tournamentId;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
    }

    done(event) {
        console.log(this.props.tt4);
        this.setState({ modalIsOpen: false, setModalIsOpen: false });
        var kilo = document.getElementsByName("kilo")[0].value;
        var gram = document.getElementsByName("gram")[0].value;
        var weight = kilo + '.' + gram;
        let scoreCardD101State = this.state.scoreCardD101State;


        console.log(scoreCardD101State);
        scoreCardD101State.map((x, index) => {
            if (index === this.state.row) {
                var max;
                if (x.points !== null) {

                    if (x.points[this.state.setIndex] !== undefined) {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, weightLifted: parseFloat(weight), disqualified: false };
                    }
                    else {
                        x.points[this.state.setIndex] = { attempt: this.state.setIndex + 1, weightLifted: parseFloat(weight), disqualified: false };
                    }
                }
                else {
                    console.log(x)
                    x.points = [{ attempt: this.state.setIndex + 1, weightLifted: parseFloat(weight), disqualified: false }];
                    x.maxWeightLifted = parseFloat(weight);

                }
                max = x.points.reduce((prev, current) => (prev.weightLifted > current.weightLifted) ? prev : current);
                if (max.weightLifted < parseFloat(weight)) {
                    x.maxWeightLifted = parseFloat(weight);
                } else {
                    x.maxWeightLifted = parseFloat(max.weightLifted);
                }
            }
        });
        // let cloneScoreCardD101State = [...scoreCardD101State]
        this.setState({ scoreCardD101State: scoreCardD101State })

        organiseMatchObj = this.props.tt4;
        if (this.props.matchType === -2) {
            organiseMatchObj.tt4Matches.map((tt4, index) => {
                if (index === this.props.clickedIndex) {
                    tt4.matchesList.map((ml, ind) => {
                        if (scoreCardD101State[ind].maxWeightLifted) {
                            ml.index = scoreCardD101State[ind].points.length;
                            ml.d101ScoreCard = {

                                maxWeightLifted: scoreCardD101State[ind].maxWeightLifted, points: scoreCardD101State[ind].points
                            }
                        }
                        else {
                            ml.d101ScoreCard = null
                        }
                    })
                }
            })
        }
        if (this.props.matchType === -3) {

            organiseMatchObj.finals.matchesList.map((ml, ind) => {
                if (scoreCardD101State[ind].maxWeightLifted) {
                    ml.index = scoreCardD101State[ind].points.length;
                    ml.d101ScoreCard = {
                        maxWeightLifted: scoreCardD101State[ind].maxWeightLifted, points: scoreCardD101State[ind].points
                    }
                }
                else {
                    ml.d101ScoreCard = null
                }
            })
        }
        tId = this.props.challenge.tournamentId;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
    }
    numberOfAttempts = () => {
        //  console.log(this.state.scoreCardD101State);
        numberOfAttempt = parseInt(numberOfAttempt);

        let tt4Matches = this.props.tt4.tt4Matches;

        let d101ScoreCard = [];
        if (this.props.matchType === -2) {

            tt4Matches.forEach((tt4, index) => {
                tt4.attempts = numberOfAttempt;
                if (this.props.clickedIndex === index) {
                    if (tt4.attempts > 0) {
                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d101ScoreCard) {
                                d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points })
                            }
                            else {
                                d101ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }

                }
            })
        }
        if (this.props.matchType === -3) {
            let tt4F = this.props.tt4;
            tt4F.finals.attempts = numberOfAttempt;
            if (tt4F.finals.attempts > 0) {
                numberOfAttempt = tt4F.finals.attempts;
                this.setState({ modalIsOpen: false });

                tt4F.finals.matchesList.map(ml => {
                    if (ml.d101ScoreCard) {
                        d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points })
                    }
                    else {
                        d101ScoreCard.push({ userId: ml.playerId, points: null })
                    }
                })
            }
        }
        tId = this.props.challenge.tournamentId;
        organiseMatchObj = this.props.tt4;
        delete organiseMatchObj.id;
        TournamentsUtil.updateOrganizeTT4(tId, organiseMatchObj).then(data => console.log(data))
        // console.log(d101ScoreCard)
        this.setState({ scoreCardD101State: d101ScoreCard })



        this.setState({ modalIsOpen: false });
    }
    componentDidMount() {


        let d101ScoreCard = [];
        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {
                if (this.props.clickedIndex === index) {
                    if (tt4.attempts > 0) {
                        numberOfAttempt = tt4.attempts;
                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d101ScoreCard) {
                                d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points, maxWeightLifted: ml.d101ScoreCard.maxWeightLifted })
                            }
                            else {
                                d101ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                    else {
                        this.setState({ modalIsOpen: true });
                    }
                }
            })
        }
        if (this.props.matchType === -3) {
            if (this.props.tt4.finals.attempts > 0) {
                numberOfAttempt = this.props.tt4.finals.attempts;
                this.setState({ modalIsOpen: false });
                this.props.tt4.finals.matchesList.map(ml => {

                    if (ml.d101ScoreCard) {

                        d101ScoreCard.push({ userId: ml.playerId, points: ml.d101ScoreCard.points, maxWeightLifted: ml.d101ScoreCard.maxWeightLifted })
                    }
                    else {

                        d101ScoreCard.push({ userId: ml.playerId, points: null })
                    }
                })
            }
            else {
                this.setState({ modalIsOpen: true });
            }
            console.log(d101ScoreCard)
        }
        this.setState({ scoreCardD101State: d101ScoreCard })
        console.log(this.state.scoreCardD101State)

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    openFunc = (index, ind) => {

        this.setState({ row: index, setIndex: ind });
        this.state.scoreCardD101State.map((x, intr) => {
            if (intr === index) {
                if (x.points === null) {
                    if (ind === 0) {

                        this.setState({ setModalIsOpen: true });
                    }
                }
                if (x.points !== null) {
                    if ((x.points.length + 1) > ind) {

                        this.setState({ setModalIsOpen: true });
                    }
                }
            }

        });
    }

    render() {
        var sets = [];
        for (var p = 0; p < parseInt(numberOfAttempt); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    {/* <div ref={subtitle => this.subtitle = subtitle}>
                            <h3>   winner </h3>
                            {maxWeights.map(weight => {
                                return <h3> {weight.userId} is  winner </h3>
                            })}
                        </div> */}
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">

                                {maxWeights.map((weight, index) => {
                                    var userName;
                                    selectedWinner = weight.userId;
                                    this.props.profileUsers.map(user => {

                                        if (weight.userId === user.contactNo) {
                                            userName = user.name
                                        }
                                    })
                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <div class="resinfo">
                                                <label>{userName} </label>
                                                <small>{weight.maxWeightLifted} Kg</small>
                                            </div>
                                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                                <input type="radio" name="selectWinner" value={weight.userId} id={index} defaultChecked={this.state.checked} onClick={this.selectWinner.bind(this)} />
                                                <label htmlFor={index}></label>
                                            </div>

                                        </a>
                                    </li>
                                })}
                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={this.closeModal}>Back</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3> Number of Attempts</h3></div>


                    <ul className="loginul_form editprofile ">
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="numberOfAttempt" id="radio-example-1" onClick={this.changeData.bind(this)} value="3" />
                                <label htmlFor="radio-example-1"></label>
                            </div>

                            <label className="ss_sportstile">3 Attempt</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="numberOfAttempt" id="radio-example-2" onClick={this.changeData.bind(this)} value="5" />
                                <label htmlFor="radio-example-2"></label>
                            </div>

                            <label className="ss_sportstile">5 Attempt</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="numberOfAttempt" id="radio-example-3" onClick={this.changeData.bind(this)} value="7" />
                                <label htmlFor="radio-example-3"></label>
                            </div>

                            <label className="ss_sportstile">7 Attempt</label>
                        </li>
                        <li>
                            <div className="styled-input-single" style={{ 'position': 'relative' }}>
                                <input type="radio" name="numberOfAttempt" id="radio-example-4" onClick={this.changeData.bind(this)} value="9" />
                                <label htmlFor="radio-example-4"></label>
                            </div>

                            <label className="ss_sportstile">9 Attempt</label>
                        </li>
                    </ul>
                    <div className="button_div" ><button className="cmnbutton" type="button" onClick={this.numberOfAttempts} >Done</button><button className="btn btn-inverse" onClick={this.closeModal}>Close</button></div>

                </Modal>
                <Modal isOpen={this.state.setModalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">  <div ref={subtitle => this.subtitle = subtitle}>
                        <div ref={subtitle => this.subtitle = subtitle}> <h3> Enter  of Weight value</h3></div>
                        <NumericInput mobile min={0}

                            max={200}
                            name="kilo"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Weight In Kg"
                            required
                            size={25}
                            value={50}
                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }}
                        /> <br></br>
                        <NumericInput mobile min={0}
                            max={999}
                            name="gram"
                            disabled={false}
                            autoComplete="off"
                            autoCorrect="off"
                            size={25}
                            value={0}
                            autoFocus={false}
                            form="some-form"
                            placeholder="Enter Weight In Gram"
                            required

                            spellcheck="false"
                            tabindex="2" style={{
                                input: {
                                    padding: '10px',
                                }
                            }} />
                    </div>
                    <br></br>
                    <div className="button_div" >
                        <button className="btn btn-danger" type="button" onClick={this.disqualify} style={{ 'margin': '2px' }}>Disqualify</button>
                        <button className="btn btn-inverse" onClick={this.closeModal} style={{ 'margin': '2px' }}>Cancel</button>
                        <button className="cmnbutton" type="button" onClick={this.done} style={{ 'margin': '2px' }}>Done</button>
                    </div>
                </Modal>
                <div>
                    <div class="tableresponsive">
                        <div class="tableinner">
                            <table>
                                <thead>
                                    <tr id="heading">
                                        <th> Players/Attempts </th>

                                        {sets.map(k => {
                                            return <th> Set{setNumber++} </th>
                                        })}

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.scoreCardD101State.map((x, index) => {
                                        var userName;
                                        this.props.profileUsers.map(user => {
                                            if (x.userId === user.contactNo) {
                                                userName = user.name
                                            }
                                        })
                                        if (x.points === null) {
                                            return <tr>
                                                <td>{userName}</td>
                                                {sets.map((k, inde) => {
                                                    return <td key={k}> <input placeholder="kg.g" onClick={() => this.openFunc(index, inde)} /> </td>
                                                })}
                                            </tr>;
                                        }
                                        else {
                                            var pointL = x.points.length;
                                            var point;
                                            return <tr>
                                                <td>{userName}</td>
                                                {sets.map((k, ind) => {
                                                    var pointColor;
                                                    if (x.points[ind] === undefined) {
                                                        point = '';
                                                    }
                                                    else {
                                                        if (x.points[ind].disqualified === false) {
                                                            if (Number.isInteger(x.points[ind].weightLifted) === true) {
                                                                point = x.points[ind].weightLifted;
                                                                point = point + '.00' + ' Kg';
                                                            } else {
                                                                point = x.points[ind].weightLifted + ' Kg';
                                                            }
                                                        }
                                                        else {
                                                            pointColor = { color: 'red' };
                                                            point = 'Disqualify';
                                                        }
                                                    }
                                                    return <td key={k}><input style={pointColor} placeholder="kg.g" onClick={() => this.openFunc(index, ind)} value={point} /></td>
                                                })}
                                            </tr>;
                                        }
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_actions">
                        <a class="cmnbutton">Back</a>
                        <button class="cmnbutton" type="button" data-toggle="modal" data-target="#myModal" onClick={this.endGame}>End Games</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
