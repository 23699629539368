import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import noBroadcast from '../img/noBroadcast.jpg';
import UpdateChallengeScore from '../UpdateChallengeScore';
import matchnotstarted from '../img/matchnotstarted.png';
import play from '../img/play.png';
import NumericInput from 'react-numeric-input';
var numberOfAttempt;
var scoreCardD103s = [];
var d103ScoreCard = {}, score, points;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

export default class Tournament_d103_view_TT4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            setModalIsOpen: false,
            scoreCardD103State: [],
            fillScoreModalOpen: false,
            winnerModalOpen: false,
        };
    }
    componentDidMount() {


        let d103ScoreCard = [];
        if (this.props.matchType === -2) {
            this.props.tt4.tt4Matches.forEach((tt4, index) => {
                if (this.props.clickedIndex === index) {
                    if (tt4.attempts >= 0) {

                        numberOfAttempt = tt4.attempts;

                        this.setState({ modalIsOpen: false });
                        console.log(tt4)
                        tt4.matchesList.map(ml => {
                            if (ml.d103ScoreCard) {
                                d103ScoreCard.push({ userId: ml.playerId, points: ml.d103ScoreCard.points })

                            }

                            else {
                                d103ScoreCard.push({ userId: ml.playerId, points: null })
                            }
                        })
                    }
                }
            })
        }
        this.setState({ scoreCardD103State: d103ScoreCard })
        console.log(this.state.scoreCardD103State)

    }


    render() {
        const backLinks = "/dtournamentprofile/index.html?id=" + this.props.tt4.tournamentId;
        return (
            <React.Fragment>
                <section class="d_midsec createmidsec broadcast_box">
                    <div class="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <ul class="winnerdropdown_ul">
                            {this.state.scoreCardD103State.map(point => {
                                var userName;

                                this.props.profileUsers.map(user => {
                                    if (point.userId === user.contactNo) {
                                        userName = user.name;
                                    }
                                })
                                return <li>
                                    <label>{userName}</label>
                                    <input placeholder="Enter Points" readonly value={point.points} />
                                </li>
                            })}

                        </ul>
                        <div class="table_actions winner_actions">
                            <a href={backLinks} class="cmnbutton backred fl">Back</a>

                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
