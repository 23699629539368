import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import Header from '../Header';
import { trackPromise } from "react-promise-tracker";
import { Link } from 'react-router-dom';
import SectionLeft from '../SectionLeft';
import searchnew from '../img/searchnew.png';
import team from '../img/team.png';
import challenegbg from '../img/matchbg.png';
import Modal from 'react-bootstrap/Modal';
import camera from '../img/camera.png';
import Map from '../Map';
import ConfigUrl from '../ConfigUrl';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const user = JSON.parse(localStorage.getItem('result'));

function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}

export default class CreatePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allSports: [],
            openLocation: false,
            openSports: false,
            term: '', //for searchbar
            optionsChecked: [],
            fields: {},
            errors: {},
            touched: {},
            formSubmitted: false,
            pageCategory: 'club',
            pageSports: null,
            showForCropImage: false,
            imageUrl: null,
            imageUrlforPage: null,
            setImageFor: null,
            showNextSpinner: false,
            src: null,
            crop: {
                unit: '%',
                width: 50,
                height: 50,
                aspect: 1,
                x: 25,
                y: 25
            }

        }
        this.myInputFile = React.createRef();
        this.myCamFile = React.createRef();
        this.searchHandler = this.searchHandler.bind(this);
        this.liRefs = [React.createRef()];
    }

    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }

    componentDidMount() {
        console.log(this.props)
        this.getSportsFunction();

    }

    chooseSport = (event) => {

        let checkedArray = this.state.optionsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                optionsChecked: checkedArray
            }, () => {
                console.log(this.state.optionsChecked)
            });
            this.setState({
                optionsChecked: this.state.optionsChecked.filter((item, index) => this.state.optionsChecked.indexOf(item) === index)
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                optionsChecked: checkedArray
            }, () => {
                console.log(this.state.optionsChecked)
            });
            this.setState({
                optionsChecked: this.state.optionsChecked.filter((item, index) => this.state.optionsChecked.indexOf(item) === index)
            });
        }
    }

    openLocation = () => {
        this.setState({ openLocation: true })
    }

    closeLocation = () => {
        this.setState({ openLocation: false })
    }

    callbackFunction = (childData) => {

        this.setState({ mapData: childData, openLocation: false })
        this.setState({ address: childData.address, latitude: childData.latValue, longitude: childData.lngValue, city: childData.city, state: childData.state, country: childData.country, pageLocation: childData.address })
        let fields = this.state.fields;
        fields['location'] = childData.address;
        fields['city'] = childData.city;
        fields['state'] = childData.state;
        fields['country'] = childData.country;
        fields['latitude'] = childData.latValue;
        fields['longitude'] = childData.lngValue;
        fields['pageLocation'] = childData.address;
        this.setState({
            fields
        });
    }

    openSports = () => {
        this.setState({ openSports: true })
    }

    closeSports = () => {
        this.setState({ openSports: false, createTeamNextError: false, showingAlert: false, showingAlert2: false })
    }

    getSportsFunction = () => {
        const apiUrl = rshApi + "/sports/get-sports";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "minimal": "false",
                    "isGroupGame": "isGroupGame"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                that.setState({
                    allSports: result.data
                });
            });
    }

    uploadFiles = async (type) => {
        const apiUrl = rshApi + "upload-file";
        let dataurl = null;
        if (type === 'timeline') {
            dataurl = this.state.imageUrl;
        }
        else {
            dataurl = this.state.imageUrlforPage;
        }

        let that = this;
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
        let timestamp = Math.floor(Date.now());
        var file = dataURLtoFile(dataurl, 'image.jpg');
        if (file !== undefined) {
            var data = {
                "data": {
                    "folder": "page/" + user.contactNo,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));

            let response = await fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            });
            let responseD = await response.json();
            return responseD;
        }
    }

    saveSelectSports() {
        let sportsArray = [];
        this.state.allSports.map(as => {
            this.state.optionsChecked.map(oC => {
                if (as.sportsId === oC) {
                    sportsArray.push(as.name)
                }
            })
        });
        this.setState({ pageSports: sportsArray.toString(), openSports: false })
        let fields = this.state.fields;
        fields['pageSports'] = sportsArray.toString();
        this.setState({
            fields
        });
    }


    createPage = () => {
        this.setState({ formSubmitted: true, showNextSpinner: true })
        let timlineUrl = null;
        let pageProfileUrl = null;
        if (this.validateForm()) {

            if (this.state.imageUrl !== null && this.state.imageUrlforPage !== null) {
                this.uploadFiles('timeline').then(res => {
                    if (res.error === false) {
                        timlineUrl = res.data.downloadUrl;
                    }

                    this.uploadFiles('pageProfile').then(res1 => {
                        if (res1.error === false) {
                            pageProfileUrl = res1.data.downloadUrl;
                        }
                        this.finalPartCreatePage(timlineUrl, pageProfileUrl);
                    })
                })
            }
            else if ((this.state.imageUrl !== null) || (this.state.imageUrlforPage !== null)) {
                if (this.state.imageUrl !== null) {
                    this.uploadFiles('timeline').then(res => {
                        if (res.error === false) {
                            timlineUrl = res.data.downloadUrl;
                        }
                        this.finalPartCreatePage(timlineUrl, pageProfileUrl);
                    })

                }
                if (this.state.imageUrlforPage !== null) {
                    this.uploadFiles('pageProfile').then(res1 => {
                        if (res1.error === false) {
                            pageProfileUrl = res1.data.downloadUrl;
                        }
                        this.finalPartCreatePage(timlineUrl, pageProfileUrl);
                    })

                }

            }
            else {

                this.finalPartCreatePage(timlineUrl, pageProfileUrl);
            }
        }
        else {
            this.setState({ showNextSpinner: false })
        }
    }

    finalPartCreatePage = (timlineUrl, pageProfileUrl) => {

        const { history } = this.props;

        const apiUrl = rshApi + "pages/create-page";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "name": this.state.fields.pageName,
                    "icon": pageProfileUrl,
                    "timeline": timlineUrl,
                    "category": this.state.pageCategory,
                    "city": this.state.fields.city,
                    "state": this.state.fields.state,
                    "country": this.state.fields.country,
                    "latitude": this.state.fields.latitude,
                    "longitude": this.state.fields.longitude,
                    "location": this.state.fields.location,
                    "address": this.state.fields.pageAddress,
                    "createdBy": user.contactNo,
                    "sportIds": this.state.optionsChecked

                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {

                history.push(`/page/profile/${result.data.id}`);
                that.setState({ showNextSpinner: false })

            }).catch(error => {
                that.setState({ showNextSpinner: false })
            });
    }
    changeCategory = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["pageName"]) {
            formIsValid = false;
            errors["pageName"] = "*Please provide page name.";
        }
        if (!fields["pageSports"]) {
            formIsValid = false;
            errors["pageSports"] = "*Please select sports name.";
        }
        if (!fields["pageLocation"]) {
            formIsValid = false;
            errors["pageLocation"] = "*Please select page location.";
        }
        if (!fields["pageAddress"]) {
            formIsValid = false;
            errors["pageAddress"] = "*Please provide page Address.";
        }



        if (!fields["city"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["state"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["country"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["latitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["longitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }


        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleTouch(e) {
        let { touched } = this.state;
        if (e.target.name && touched[e.target.name] != true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            });
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });

    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,

        );

        return new Promise((resolve, reject) => {
            resolve(canvas.toDataURL('image/jpeg', 1.0));
            if (this.state.setImageFor === 'timeline') {
                this.setState({ imageUrl: canvas.toDataURL('image/jpeg', 1.0) })
            }
            if (this.state.setImageFor === 'pageProfile') {
                this.setState({ imageUrlforPage: canvas.toDataURL('image/jpeg', 1.0) })
            }

        });
    }
    onImageChange = e => {
        this.setState({ showForCropImage: true, setImageFor: 'timeline' });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            console.log(reader.result)
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    onImageChangePageProfile = e => {
        this.setState({ showForCropImage: true, setImageFor: 'pageProfile' });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            console.log(reader.result)
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    closeCropModel() {

        this.setState({ imageUrl: null, imageUrlforPage: null, showForCropImage: false });
    }

    saveCropSelection() {
        this.setState({ showForCropImage: false });
    }


    render() {
        const { crop, croppedImageUrl, src } = this.state;
        const { term, allSports } = this.state;
        console.log(this.state.imageUrl)
        console.log(this.state.imageUrlforPage)
        let imgurl = this.state.imageUrl ? this.state.imageUrl : challenegbg;
        let imgUrlforPage = this.state.imageUrlforPage ? this.state.imageUrlforPage : team;
        return (
            <>
                <Modal
                    size="md"
                    show={this.state.showForCropImage}
                    onHide={() => this.closeCropModel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div class="modal-body modal-bodyD" >
                        <div className="ImgUp" >
                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}

                                />
                            )}

                            {croppedImageUrl && (

                                <div>
                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />


                                </div>
                            )}
                        </div>
                        <div className="modal-footer">

                            <button onClick={() => this.saveCropSelection()} style={{ backgroundColor: '#6ca722', color: '#FFF' }} type="button" className="btn btn-default updatebtn hvr-ripple-out" >{this.state.showUploadSpinner ? <React.Fragment> <Spinner
                                as="span"
                                animation="border"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> uploading</React.Fragment> : 'Save'}</button>
                            <button type="button" className="btn btn-default cancelbtn hvr-ripple-out" data-dismiss="modal" onClick={() => this.closeCropModel()}>Cancel</button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    size="sm"
                    show={this.state.openSports}
                    onHide={this.closeSports}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Sports</h4>
                        </div>

                        <div className="modal-body modal-bodyD modal-bodySport">
                            <div className="createselect_team">
                                <div className="createselect_search">
                                    <form>
                                        <input onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form>
                                </div>
                                <ul className="locationul">
                                    {
                                        allSports.filter(searchingFor(term)).map((item, i) => {
                                            let checkedD = false;
                                            this.state.optionsChecked.map(oc => {
                                                if (item.sportsId === oc) {
                                                    checkedD = true;
                                                }
                                            })
                                            return <div key={item.name} className="" key={item.sportsId} >
                                                <li className="li_selectsports"  >

                                                    <input type="checkbox" value={item.sportsId} name={item.name} onChange={(e) => this.chooseSport(e)}
                                                        ref={el => this.liRefs[item.sportsId] = el} checked={checkedD} />
                                                    <label htmlFor={item.name} style={{ paddingLeft: '10px' }} onClick={(e) => this.liRefs[item.sportsId].click()}>{item.name}</label>
                                                </li>
                                            </div>
                                        }

                                        )
                                    }
                                </ul>

                            </div>
                        </div>
                        <div className="modal-footer">

                            <button style={{ backgroundColor: '#6ca722', color: '#FFF' }} type="button" className="btn btn-default updatebtn hvr-ripple-out" onClick={() => this.saveSelectSports()}  >Save</button>
                            <button type="button" className="btn btn-default cancelbtn hvr-ripple-out" data-dismiss="modal" onClick={this.closeSports}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="md"
                    show={this.state.openLocation}
                    onHide={this.closeLocation}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Location</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <span id="searchLocation" style={{ "borderBottom": '1px' }}>
                                        <Map parentCallback={this.callbackFunction} />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
                <Header />
                <div className="container containerbottom non-session-d">
                    {user ? <SectionLeft /> : ''}
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section class="d_midsec createmidsec">
                            <div class="divpost">
                                <div class="createPage_div" style={{ backgroundImage: 'url(' + imgurl + ')' }}>
                                    <button onClick={(e) => this.myInputFile.click()}>Upload Timeline Image <input
                                        style={{ display: 'none' }}
                                        type="file" id="timelineImage" onChange={this.onImageChange} accept='image/*'
                                        ref={(ref) => this.myInputFile = ref}
                                    /></button>
                                    <div class="uploadimg_div" ><span style={{ backgroundImage: 'url(' + imgUrlforPage + ')' }}></span>
                                        <a onClick={(e) => this.myCamFile.click()}><img src={camera} />
                                            <input ref={(ref) => this.myCamFile = ref} style={{ display: 'none' }} type="file" onChange={this.onImageChangePageProfile} accept='image/*' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="createteamform pagecreate_ul">
                                <ul>
                                    <li>
                                        <div class="commaninputdiv">
                                            <input type="text" name="pageName" class="question" id="" required="true" maxlength="20" autocomplete="off" onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} />
                                            <label for=""><span>Name of the sport page</span></label>
                                            {
                                                this.state.formSubmitted || this.state.touched.pageName
                                                    ?
                                                    <div className="errorMsg1">{this.state.errors.pageName}</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div class="commaninputdiv">
                                            <input type="text" name="pageSports" class="question" id="" required="true" autocomplete="off" value={this.state.pageSports} onClick={this.openSports} onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} />
                                            <label for=""><span>Select Sports</span></label>
                                            {
                                                this.state.formSubmitted || this.state.touched.pageSports
                                                    ?
                                                    <div className="errorMsg1">{this.state.errors.pageSports}</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div class="commaninputdiv creategender pagecategory">
                                            <label>Select Catogery</label>

                                            <div class="creategenderdiv pageCatSpace">
                                                <div class="styled-input-single createinput male">
                                                    <input type="radio" name="pageCategory" id="checkbox-example-3" defaultChecked onChange={this.changeCategory} value="club" />
                                                    <label for="checkbox-example-3" class="club" ></label>
                                                    <span className="pageCatName">Club</span>
                                                </div>
                                                <div class="styled-input-single createinput female">
                                                    <input type="radio" name="pageCategory" id="checkbox-example-4" onChange={this.changeCategory} value="corporate" />
                                                    <label for="checkbox-example-4" class="corporate"></label>
                                                    <span className="pageCatName">Corporate</span>
                                                </div>
                                                <div class="styled-input-single createinput mix">
                                                    <input type="radio" name="pageCategory" id="checkbox-example-5" onChange={this.changeCategory} value="educational" />
                                                    <label for="checkbox-example-5" class="educational"></label>
                                                    <span className="pageCatName">Educational</span>
                                                </div>
                                            </div>

                                        </div>
                                    </li>


                                    <li>
                                        <div class="commaninputdiv">
                                            <input type="text" name="pageLocation" class="question" id="" required="true" autocomplete="off" onClick={this.openLocation} value={this.state.pageLocation} onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} />
                                            <label for=""><span>Select Location</span></label>
                                            {
                                                this.state.formSubmitted || this.state.touched.pageLocation
                                                    ?
                                                    <div className="errorMsg1">{this.state.errors.pageLocation}</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div class="commaninputdiv">
                                            <input type="text" name="pageAddress" class="question" id="" required="true" autocomplete="off" onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} value={this.state.pageAddress} />
                                            <label for=""><span>Address</span></label>
                                            {
                                                this.state.formSubmitted || this.state.touched.pageAddress
                                                    ?
                                                    <div className="errorMsg1">{this.state.errors.pageAddress}</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </li>
                                </ul>
                                <button class="createnext" onClick={this.createPage} disabled={this.state.showNextSpinner}>{this.state.showNextSpinner ? <React.Fragment>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        variant="light"
                                        size="md"
                                        role="status"
                                        aria-hidden="true"
                                    >
                                        loading... </Spinner></React.Fragment> : 'Next'}</button>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }
}
