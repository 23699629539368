import React, { Component } from 'react';
import ChatRecent1 from './ChatRecent1';
import oneIcon from './img/1.jpg';
import twoIcon from './img/2.jpg';
import threeIcon from './img/3.jpg';
import fourIcon from './img/4.jpg';
import fiveIcon from './img/5.jpg';
import sixIcon from './img/6.jpg';
import sevenIcon from './img/7.jpg';
import eightIcon from './img/8.jpg';
import nineIcon from './img/9.jpg';
import googleIcon from './img/google.png';
import appleIcon from './img/apple.png';
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';


class SectionRight extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        if (localStorage.getItem('logedIn')) {
            this.state = {
                user: JSON.parse(localStorage.getItem('result')),
                logedIn: localStorage.getItem('result'),
            };
        }
        else {
            this.state = {
                logedIn: false,
            }
        }
    }
    render() {
        const params = {
            spaceBetween: 30,
            centeredSlides: true,

            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            // navigation: {
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev'
            // }
        }
        return (
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7 sticky-right">
                <section className="d_rightsec">
                    <div className="commanbox ">
                        <h3 className="commanhead">Download App Now</h3>
                        <Swiper {...params}>
                            <div><img src={oneIcon} alt="" /></div>
                            <div><img src={twoIcon} alt="" /></div>
                            <div><img src={threeIcon} alt="" /></div>
                            <div><img src={fourIcon} alt="" /></div>
                            <div><img src={fiveIcon} alt="" /></div>
                            <div><img src={sixIcon} alt="" /></div>
                            <div><img src={sevenIcon} alt="" /></div>
                            <div><img src={eightIcon} alt="" /></div>
                            <div><img src={nineIcon} alt="" /></div>

                        </Swiper>
                        <div className="downloadapp_div"> <Link to={"#"} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.sportsapp.ssp')}><img src={googleIcon} className="googleplay downloadlink" alt="" /></Link> <Link to={"#"} onClick={() => window.open('https://apps.apple.com/app/id1437392923')}><img src={appleIcon} className="appleplay downloadlink" alt="" /></Link> </div>
                    </div>
                    {/* <div className="commanbox ">
                        <h3 className="commanhead">Help</h3>
                        <ul className="helpul">
                            <li><Link to={"/about-us"}>About Us</Link></li>
                            <li><Link to={"#"}>Help Center</Link></li>
                            <li><Link to={"/privacy-policy"}>Privacy & Terms</Link></li>
                            <li><Link to={"#"}>Advertise</Link></li>
                            <li><Link to={"#"}>Get Playdiator App</Link></li>
                            <li><Link to={"/contact-us"}>Contact Us</Link></li>
                            <li><Link to={"#"}>More</Link></li>
                        </ul>
                    </div> */}
                </section>
                {/* {this.state.logedIn ? <ChatRecent1 /> : ''} */}
            </div>
        );
    }
}

export default SectionRight;
