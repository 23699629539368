import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import imagesIcon from './img/images.png';
import videosIcon from './img/videos.png';
import addLocationIcon from './img/addlocation.png';
import tagIcon from './img/tag.png';
import userMaleIcon from './img/male.png';
import userfemalIcon from './img/female.png';
import close from './img/close.png';
import tvIcon from './img/tv.png';
import video from './img/video.png';
import Map from './Map';
import youtubeicon from './img/youtubeicon.png';
import { Link } from "react-router-dom";
import ConfigUrl from './ConfigUrl';
import CricketFootballAlert from './CricketFootballAlert';
import { confirmAlert } from 'react-confirm-alert';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
class CreatePost extends Component {
    constructor(props) {

        super(props);
        this.state = {
            buddies: [],
            postText: '',
            postImagePreview: '',
            showImagePreview: false,
            address: '',
            user: '',
            optionsChecked: [],
            showTagBuddies: false,
            tagBuddieList: [],
            tagbuddieCount: '',
            buddieCountGreater: false,
            tagBuddieName: '',
            showLocation: true,
            postPrivacy: 1,
            showYoutubeInput: false,
            youtubeUrl: '',
            showyoutubePreview: false,
            youtubePreview: '',
            youtubeId: '',
            successMessage: false,
        };
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.loadMore = this.loadMore.bind(this);

    }

    checkUrl() {
        var res = this.state.youtubeUrl.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/);
        if (res !== null) {
            var youtube_video_id = this.state.youtubeUrl.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
            this.setState({ showyoutubePreview: true, youtubePreview: '//img.youtube.com/vi/' + youtube_video_id + '/0.jpg' });
            this.setState({ youtubeId: youtube_video_id });
            document.getElementById('postImage').value = '';
            document.getElementById('postVideo').value = '';
            this.setState({ showImagePreview: false });
        }
        else {
            //show error message  youtube url is not right
        }
    }
    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData })
        this.setState({ address: childData.address })
        console.log(this.state.address);
    }
    liveScore = () => {

        confirmAlert({
            customUI: () => {
                return (
                    <CricketFootballAlert />
                );
            }
        });
    };
    showLocation(event) {
        this.setState({ showLocation: !this.state.showLocation });
    }
    changeEvent(event) {
        let checkedArray = this.state.optionsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                optionsChecked: checkedArray
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                optionsChecked: checkedArray
            });
        }
    }
    selectBuddiesForTag = (e) => {
        let tagBuddie = [];
        this.state.buddies.map(x => {
            this.state.optionsChecked.map(y => {
                if (y === x.contactNo) {
                    tagBuddie.push(x);
                }
                return true;
            })
            return true;
        })
        if (tagBuddie.length > 1) {
            var tagbuddieCount = tagBuddie.length - 1;
            this.setState({ tagbuddieCount: tagbuddieCount, buddieCountGreater: true, });
        }
        var tagBuddieName = "with " + tagBuddie[0].name;
        this.setState({ tagBuddieName: tagBuddieName });
        this.setState({ tagBuddieList: tagBuddie });
        this.setState({ showTagBuddies: true });
    }
    loadMore = () => {
        const that = this;
        fetch(userApi + "users/get-buddies", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.user.contactNo,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.length === 0) {

                }
                else if (result.data === 'No buddies found') {

                }
                else {
                    that.setState({
                        buddies: result.data,
                    });
                }

            });
    };
    previewClose = e => {
        this.setState({ showImagePreview: false, postImagePreview: '' });
        document.getElementById('postImage').value = '';
    }
    onVideoChange = e => {
        this.setState({ youtubeId: '', showyoutubePreview: false });
        document.getElementById('postImage').value = '';
        let file = document.getElementById('postVideo').files[0];
        var fileReader = new FileReader();
        this.setState({ showImagePreview: true });
        let that = this;

        fileReader.onload = function () {
            var blob = new Blob([fileReader.result], { type: file.type });
            var url = URL.createObjectURL(blob);
            var video = document.createElement('video');
            var timeupdate = function () {
                if (snapImage()) {
                    video.removeEventListener('timeupdate', timeupdate);
                    video.pause();
                }
            };
            video.addEventListener('loadeddata', function () {
                if (snapImage()) {
                    video.removeEventListener('timeupdate', timeupdate);
                }
            });
            var snapImage = function () {
                var canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                var image = canvas.toDataURL();
                var success = image.length > 100000;
                if (success) {

                    that.setState({ postImagePreview: image });
                    URL.revokeObjectURL(url);
                }
                return success;
            };
            video.addEventListener('timeupdate', timeupdate);
            video.preload = 'metadata';
            video.src = url;
            video.muted = true;
            video.playsInline = true;
            video.play();
        };
        fileReader.readAsArrayBuffer(file);
    }
    onImageChange = e => {
        let file = document.getElementById('postImage').files[0];
        var reader = new FileReader();
        this.setState({ showImagePreview: true, showyoutubePreview: false });
        let that = this;
        reader.onload = function (e) {
            that.setState({ postImagePreview: e.target.result });
        }
        reader.readAsDataURL(file);
        document.getElementById('postVideo').value = '';
        this.setState({ youtubeId: '' });
    }
    handleSubmit = e => {
        e.preventDefault();
        var tagList = [];
        this.state.optionsChecked.map(y => {
            tagList.push({
                buddyId: y,
                userType: null
            });
            return true;
        })
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        let file = document.getElementById('postImage').files[0];
        let postImage = document.getElementById('postImage').value;
        let postVideo = document.getElementById('postVideo').value;
        var postPrivacy = this.state.postPrivacy;
        var postText = this.state.postText;
        var address = this.state.address;
        var contactNo = this.state.user.contactNo;
        let postFrom = 'user';
        let pageId = null;

        if (this.props.for === 'Page') {
            postFrom = 'page';
            address = null;
            pageId = this.props.pageId;
        }
        if ((postVideo === '') && (postImage === '') && (postVideo === '') && (this.state.youtubeId === '')) {
            fetch(userApi + "/posts/create-post", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "post": {
                            "postPrivacy": postPrivacy,
                            "text": postText,
                            "mediaType": "text",
                            "mediaUrl": null,
                            "location": address,
                            "status": true,
                            "createdBy": contactNo,
                            "timestamp": timpstampData,
                            "taggedBuddies": tagList,
                            "reactedBuddies": null,
                            "videoRandomCount": 0,
                            "youtubeId": null,
                            "pageId": pageId,
                            "postFrom": postFrom
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = '/';

                });
        }
        if (postVideo !== '') {
            let file = document.getElementById('postVideo').files[0];
            if (file !== undefined) {
                var data = {
                    "data": {
                        "folder": "posts/" + this.state.user.contactNo,
                        "fileName": "VID_" + timpstampData
                    }
                };
            }
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            fetch("https://playdiator.com/apis/upload-file", {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result.data.downloadUrl);
                    // this.setState({ downloadUrl: result.data.downloadUrl });
                    uploadSuggestedPostData(result.data.downloadUrl);
                });

            function uploadSuggestedPostData(data) {

                fetch(userApi + "/posts/create-post", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "post": {
                                "postPrivacy": postPrivacy,
                                "text": postText,
                                "mediaType": "video",
                                "mediaUrl": data,
                                "location": address,
                                "status": true,
                                "createdBy": contactNo,
                                "timestamp": timpstampData,
                                "taggedBuddies": tagList,
                                "reactedBuddies": null,
                                "videoRandomCount": 0,
                                "youtubeId": null,
                                "pageId": pageId,
                                "postFrom": postFrom
                            }
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        window.location.href = '/';
                    });
            }
        }
        if (this.state.youtubeId !== '') {
            var youtube = this.state.youtubeId;
            console.log(youtube);
            fetch(userApi + "/posts/create-post", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "post": {
                            "postPrivacy": postPrivacy,
                            "text": postText,
                            "mediaType": "video",
                            "mediaUrl": "null",
                            "location": address,
                            "status": true,
                            "createdBy": contactNo,
                            "timestamp": timpstampData,
                            "taggedBuddies": tagList,
                            "reactedBuddies": null,
                            "videoRandomCount": 0,
                            "youtubeId": youtube,
                            "pageId": pageId,
                            "postFrom": postFrom
                        }
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    window.location.href = '/';
                });
        }
        if (postImage !== '') {
            if (file !== undefined) {
                data = {
                    "data": {
                        "folder": "posts/" + this.state.user.contactNo,
                        "fileName": "IMG_" + timpstampData
                    }
                };
            }
            form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            fetch("https://playdiator.com/apis/upload-file", {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result.data.downloadUrl);
                    // this.setState({ downloadUrl: result.data.downloadUrl });
                    uploadSuggestedPostData(result.data.downloadUrl);
                });

            function uploadSuggestedPostData(data) {
                console.log(data);

                console.log(timpstampData);
                fetch(userApi + "/posts/create-post", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            "post": {
                                "postPrivacy": postPrivacy,
                                "text": postText,
                                "mediaType": "image",
                                "mediaUrl": data,
                                "location": address,
                                "status": true,
                                "createdBy": contactNo,
                                "timestamp": timpstampData,
                                "taggedBuddies": tagList,
                                "reactedBuddies": null,
                                "videoRandomCount": 0,
                                "youtubeId": null,
                                "pageId": pageId,
                                "postFrom": postFrom
                            }
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        window.location.href = '/';
                    });
            }
        }
        this.setState({ successMessage: true })
        const that = this;
        window.setTimeout(function () {
            that.setState({ successMessage: false })
            that.loadMore();
        }, 3000);

        // showyoutubePreview
        // postText
        // showImagePreview

    }
    componentDidMount() {
        this.loadMore();
        console.log(this.props.for)
    }
    render() {
        let useIcon = this.state.user.profilePic ? this.state.user.profilePic : userMaleIcon;
        if (this.props.for) {
            useIcon = this.props.iconImage;
        }
        let hideLocation = false;
        if (this.props.for === 'Page') {
            hideLocation = true;
        }
        return (
            <div className="commanbox createpostdiv">
                <div id="myvideos" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Upload Video</h4>
                            </div>
                            <div className="modal-body">

                                <div className="videomodel">

                                    <label className="btn-bs-file" >
                                        <img src={video} alt="" /> Video
                                        <input accept="video/*" type="file" id="postVideo" onChange={this.onVideoChange} />
                                    </label>

                                    <span className="youtubelink_span" id="youtubelink" onClick={(event) => this.setState({ showYoutubeInput: !this.state.showYoutubeInput })}><img src={youtubeicon} alt="" />Youtube</span>
                                    {this.state.showYoutubeInput ? <div className="youtubdiv" >
                                        <input id="youtubeurl" placeholder="Enter youtubr URL" type="text" value={this.state.youtubeUrl} onChange={(event => this.setState({ youtubeUrl: event.target.value }))} />
                                        <button className="cmnbutton" onClick={() => this.checkUrl()} >Add</button>
                                    </div> : <div></div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btndark" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="myModal" className="modal fade" role="dialog" >
                    <div className="modal-dialog">
                        <div className="modal-content modal-sports">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Select buddies for tag</h4>
                            </div>
                            <div className="alert alert-info" style={{ display: this.state.showStore ? 'block' : 'none', textAlign: 'center' }} id="messageShow">
                                Please Select atleast one sports
                            </div>
                            <div className="modal-body ">
                                <div>
                                    <div className="sportsdiv sportsdiv_profile">
                                        <ul className="sportsul">
                                            {this.state.buddies.map((x, index) => {
                                                if (x.profilePic === null) {
                                                    if (x.gender === 'Male') {
                                                        return (
                                                            <li key={x.contactNo}>
                                                                <div className="styled-input-single">
                                                                    <input type="checkbox" name={x.name} id={"checkbox-example-" + index} value={x.contactNo} onChange={this.changeEvent.bind(this)} />
                                                                    <label htmlFor={"checkbox-example-" + index}></label>
                                                                </div>
                                                                <span className="ss_spansportsimg"><img src={userMaleIcon} alt="" /></span>
                                                                <label className="ss_sportstile">{x.name}</label>
                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <li key={x.contactNo}>
                                                                <div className="styled-input-single">
                                                                    <input type="checkbox" name={x.name} id={"checkbox-example-" + index} value={x.contactNo} onChange={this.changeEvent.bind(this)} />
                                                                    <label htmlFor={"checkbox-example-" + index}></label>
                                                                </div>
                                                                <span className="ss_spansportsimg"><img src={userfemalIcon} alt="" /></span>
                                                                <label className="ss_sportstile">{x.name}</label>
                                                            </li>
                                                        );
                                                    }
                                                }
                                                else {
                                                    return (
                                                        <li key={x.contactNo}>
                                                            <div className="styled-input-single">
                                                                <input type="checkbox" name={x.name} id={"checkbox-example-" + index} value={x.contactNo} onChange={this.changeEvent.bind(this)} />
                                                                <label htmlFor={"checkbox-example-" + index}></label>
                                                            </div>
                                                            <span className="ss_spansportsimg"><img src={x.profilePic} alt="" /></span>
                                                            <label className="ss_sportstile">{x.name}</label>
                                                        </li>
                                                    );
                                                }

                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="modal-footer modalbottom">
                                    <button type="button" onClick={() => this.selectBuddiesForTag()} className="cmnbutton" data-dismiss="modal" >Done</button>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>


                <form onSubmit={this.handleSubmit}>

                    <h3 className="commanhead">Create {this.props.for} Post </h3><Link to={"#"} className="livescore" data-toggle="tooltip" title="" data-placement="top" data-original-title="View Live Score" onClick={this.liveScore}><img src={tvIcon} alt="" /> Live Score</Link>
                    <div className="postarea_div"> <span className="pa_img" style={{
                        backgroundImage: "url(" + `${useIcon}` + ")"
                    }}></span>
                        <textarea required placeholder="Share what you are thinking..." name="postText" id="postText" autoComplete="off" value={this.state.postText} onChange={event => this.setState({ postText: event.target.value })} >
                        </textarea>
                    </div>
                    <span className="showlocation">{this.state.addressShow ? <b>At {this.state.address}</b> : <div></div>}     {this.state.tagBuddieName}   {this.state.buddieCountGreater ? " and " + this.state.tagbuddieCount + " other" : <div></div>}</span>


                    <ul className="postul_imgvdo">
                        {this.state.showImagePreview ? <li>
                            <div id="postImagePreview" className="uploadimg" style={{
                                backgroundImage: "url(" + this.state.postImagePreview + ")"
                            }}>
                                {/* <span className="editlink"><img src={edit} alt="" /></span> */}
                                <span className="deleteimg" onClick={(e) => this.previewClose()}><img src={close} alt="" /></span>
                                <span className="overlay"></span>
                            </div>
                        </li> : <div></div>}
                        {this.state.showyoutubePreview ? <li>
                            <div id="postImagePreview" className="uploadimg" style={{
                                backgroundImage: "url(" + this.state.youtubePreview + ")"
                            }}>
                                {/* <span className="editlink"><img src={edit} alt="" /></span> */}
                                <span className="deleteimg" onClick={(e) => this.setState({ showyoutubePreview: false, youtubeId: '' })}><img src={close} alt="" /></span>
                                <span className="overlay"></span>
                            </div>
                        </li> : <div></div>}
                    </ul>
                    {
                        this.state.showTagBuddies ? <div id="taguser" className="taguser_div">
                            <img src="img/tagicon.png" className="tagicon" alt="" />
                            <span className="fa fa-close" id="closetag"></span>
                            <ul>
                                {this.state.tagBuddieList.map(x => {
                                    return <li key={x.contactNo}><span>{x.name}</span></li>
                                })
                                }
                            </ul>
                        </div> : <div></div>
                    }
                    {hideLocation ? null : <>
                        {this.state.showLocation ? <div id="checkinuser" className="tagcheckin_div">
                            <img src="" className="checkinicon" alt="" />
                            <span className="fa fa-close closeicn-d" id="closecheckin" onClick={(event) => this.setState({ showLocation: false })}></span>
                            <div className="checkininner">
                                <span id="searchLocation">
                                    <Map parentCallback={this.callbackFunction} /></span>

                            </div>
                        </div> : <div></div>}
                    </>}

                    <div className="postactiondiv">
                        <ul>
                            <li>
                                <span data-toggle="tooltip" title="Add Image" data-placement="top" >
                                    <label className="btn-bs-file">
                                        <img src={imagesIcon} alt="" />
                                        <input accept="image/*" type="file" id="postImage" onChange={this.onImageChange} />
                                    </label>
                                </span>
                            </li>
                            <li>
                                <span className="" title="Add Videos" data-placement="top" data-toggle="modal" data-target="#myvideos" >
                                    <label className="btn-bs-file">
                                        <img src={videosIcon} alt="" />
                                        {/* <input type="file" id="postVideo" /> */}
                                    </label>
                                </span>
                            </li>
                            {hideLocation ? null : <li>
                                <span id="showcheckin" className="checkinlink" title="Check In" data-placement="top" onClick={(e) => this.showLocation()}>
                                    <img src={addLocationIcon} alt="" />
                                </span>
                            </li>}
                            <li>
                                <span className="tagbuddy" id="showtag" data-toggle="modal" data-target="#myModal" title="Tag Buddies" data-placement="top">
                                    <img src={tagIcon} alt="" />
                                </span>
                            </li>
                        </ul>
                        <select className="publicpost" id="postType" value={this.state.postPrivacy} onChange={event => this.setState({ postPrivacy: event.target.value })}>
                            <option value="0">Public</option>
                            <option value="1">Buddies</option>
                            <option value="2">Only Me</option>
                        </select>

                        {this.props.createdByUserNo === this.state.user.contactNo ? <button type="submit" className="">Post</button> : null}

                        {this.props.for !== 'Page' ? <button type="submit" className="">Post</button> : null}

                        <div id="successMessage" style={{ display: this.state.successMessage ? 'block' : 'none', textAlign: 'center' }} className="alert alert-success">success</div>

                    </div>
                </form>
            </div>
        );
    }
}
export default CreatePost;