import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import Modal from 'react-modal';
import noBroadcast from '../img/noBroadcast.jpg';
import play from '../img/play.png';
import matchbg from '../img/matchbg.png';
import maleIcon from "../img/male.png";
import femaleIcon from "../img/female.png";
import winnerlogo from '../img/winnerlogo.png';
import matchnotstarted from '../img/matchnotstarted.png';
import UpdateChallengeScore from '../UpdateChallengeScore';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
export default class Tournament_d102view extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };

        this.closeModal = this.closeModal.bind(this);

        this.playerName = this.props.challenge.accepted.map(accepted => {
            this.props.profileUsers.map(user => {
                if (user.contactNo === accepted) {
                    return <option value={user.contactNo}>{user.name}</option>
                }
            })
        })
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    winnerDone = () => {
        if (this.state.winner == "notselected") {
            this.setState({ modalIsOpen: false });
        }
        else if (this.state.winner === undefined) {
            this.setState({ modalIsOpen: false });
        }
        else {
            this.setState({ modalIsOpen: true });
        }

    }

    componentDidMount() {
        console.log(this.props.challenge);
        console.log(this.props.profileTeams);
        console.log(this.props.profileUsers);

        const apiUrl = rshApi + "/tournaments/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.challenge.tournamentId,
                    "matchId": this.props.matchId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data.score !== null) {
                    if (result.data.score.d102ScoreCard !== null) {
                        if (result.data.score.d102ScoreCard.winnerId !== null) {
                            that.setState({ winner: result.data.score.d102ScoreCard.winnerId });
                        }
                    }
                }



                that.props.profileUsers.map(user => {
                    if (user.contactNo === result.data.score.d102ScoreCard.winnerId) {
                        that.setState({ winnerName: user.name });
                        that.setState({ winnerPic: user.profilePic });

                    }

                })

            });
    }
    render() {
        const backLinks = "/TournamentProfile/index.html?id=" + this.props.challenge.tournamentId;
        var name;
        var profilePic;
        return (
            <React.Fragment>

                <section className="d_midsec createmidsec broadcast_box">
                    <div className="commanbox cp_profile">
                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + noBroadcast + ")"
                        }}>
                            {/* <span>09:33</span> */}
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                    </div>
                </section>
                <React.Fragment>
                    <div
                        className="teambox winnerbox"
                        style={{
                            backgroundImage: "url(" + matchbg + ")",
                        }}
                    >
                        <ul className="winnerul">
                            <li className="winner_user">
                                <img src={winnerlogo} alt="" />
                                <span
                                    style={{
                                        backgroundImage: "url(" + this.state.winnerPic + ")",
                                    }}
                                ></span>
                            </li>

                            <li>
                                <label className="winnername">
                                    {this.state.winnerName}
                                </label>
                            </li>
                            <li>
                                <span className="winnertext">Winner</span>
                            </li>
                        </ul>
                        <div className="table_actions">
                            <a href={backLinks} className="cmnbutton">Back</a>
                        </div>
                    </div>
                </React.Fragment>


            </React.Fragment>

        );
    }
}
