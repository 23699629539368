import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import GetCurrentTimestamp from './GetCurrentTimestamp';
import { Link } from 'react-router-dom';
import team from './img/team.png';
import challenegbg from './img/challenegbg.png';
import searchnew from './img/searchnew.png';
import camera from './img/camera.png';
import ConfigUrl from './ConfigUrl';
import Map from './Map';
import { trackPromise } from "react-promise-tracker";
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import ImgDropAndCrop from './ImgDropAndCrop';
import * as myConstclass from './ConstFile';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const currentTimeStamp = GetCurrentTimestamp();

function searchingFor(term) {
    return function (x) {
        return x.name.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}
export default class CreateTournamentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            touched: {},
            formSubmitted: false,
            allSports: [],
            term: '',
            ShowSingle: false,
            ShowTeam: false,
            tType: [],
            showLeagueOption: false,
            savePic: null,
            imageCrop: null,
            isBuddyCheck: false,
            isTeamCheck: false,
            isBuddyDisable: false,
            isTeamDisable: false,
            isMaleDisable: false,
            isFeMaleDisable: false,
            isMixDisable: false,
            isCheckMale: false,
            isCheckFemale: false,
            isCheckMix: false,
            RequireTT: false,
        }
        this.state.user = JSON.parse(localStorage.getItem('result'));
        this.changeTLevel = this.changeTLevel.bind(this);
        this.changeTType = this.changeTType.bind(this);
        this.getSportsFunction = this.getSportsFunction.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.selectGender = this.selectGender.bind(this);
        this.createTForm = this.createTForm.bind(this);
        this.selectTmode = this.selectTmode.bind(this);
    }

    closingPopup = () => {
        this.setState({
            showingPopup: false,
        });
    }

    //submit pic code
    handleLanguage = (langValue) => {
        this.setState({ imageCrop: langValue, savePic: langValue });
    }

    submitPicture = (tournament) => {
        const that = this;
        const apiUrl = rshApi + "upload-file";
        let dataurl = this.state.imageCrop;
        //console.log(dataurl)
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
        var file = dataURLtoFile(dataurl, 'image.jpg');

        if (file !== undefined) {
            const currentTimeStamp = GetCurrentTimestamp();
            var data = {
                "data": {
                    "folder": "tournaments/" + tournament.tournamentId,
                    "fileName": "IMG_" + currentTimeStamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result.data.downloadUrl)
                    that.setState({ savePic: result.data.downloadUrl });
                    uploadPictures(result.data.downloadUrl);
                });
            function uploadPictures(data) {
                delete tournament.id;
                tournament.tournamentIcon = data;

                fetch(rshApi + "/tournaments/update-tournament", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            tournament: tournament,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        window.location = "/tournamentprofile/index.html?id=" + tournament.tournamentId;
                    });
            }
        }
    }


    selectGender(e) {


        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleRegDate = (date) => {
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tRegDate'] = dateFormate;

        this.setState({
            regDate: date, fields, formSubmitted: false
        });
    };
    handleStartDate = (date) => {
        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tStartDate'] = dateFormate;

        this.setState({
            startDate: date, fields, formSubmitted: false
        });
    };
    handleEndDate = (date) => {

        let dateFormate = myConstclass.getDateYYYYmmdd(date);
        let fields = this.state.fields;
        fields['tEndDate'] = dateFormate;
        this.setState({
            endDate: date, fields, formSubmitted: false
        });
    };
    selectTmode(e) {
        if (e.target.value === 'buddy') {
            this.setState({
                isBuddyCheck: true,
                isTeamCheck: false
            });
        }
        if (e.target.value === 'team') {
            this.setState({
                isBuddyCheck: false,
                isTeamCheck: true
            });
        }
        let fields = this.state.fields;
        fields['tMode'] = e.target.value
        this.setState({
            fields
        });
    }
    changeTLevel(e) {
        let fields = this.state.fields;
        fields['tLevel'] = e.target.value
        this.setState({
            fields
        });
    }
    changeTType(e) {
        let fields = this.state.fields;
        fields['tType'] = parseInt(e.target.value);
        this.setState({
            fields
        });
        if (e.target.value === "2" || e.target.value === "3") {
            this.setState({ showLeagueOption: true });
        }
        else {
            this.setState({ showLeagueOption: false });
        }

    }
    componentDidMount() {
        this.getSportsFunction();

    }
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }
    openSports = () => {
        this.setState({ openSports: true }, () => {
            this.testInput && this.testInput.focus()
        })
    }
    closeSports = () => {
        this.setState({ openSports: false })
    }
    callbackFunction = (childData) => {
        console.log(childData);
        this.setState({ mapData: childData })
        this.setState({ address: childData.address })

        let fields = this.state.fields;
        fields['location'] = childData.address;
        fields['city'] = childData.city;
        fields['state'] = childData.state;
        fields['country'] = childData.country;
        fields['latitude'] = childData.latValue;
        fields['longitude'] = childData.lngValue;
        this.setState({
            fields
        });
    }

    chooseSport = (takeOnlySport, takeOnlySportIds, takeOnlyCategory, tType) => {


        this.setState({ isCheckFemale: false, isBuddyDisable: false, isTeamDisable: false, isMaleDisable: false, isFeMaleDisable: false, isMixDisable: false });
        this.setState({ tType: tType });
        let fields = this.state.fields;
        fields['sportId'] = takeOnlySportIds;
        fields['sportsName'] = takeOnlySport;
        fields['categoryId'] = takeOnlyCategory;
        this.setState({
            fields
        });
        if (takeOnlyCategory === "D101" || takeOnlyCategory === "D102" || takeOnlyCategory === "D103"
            || takeOnlyCategory === "D105") {
            this.setState({ isBuddyCheck: true, isTeamDisable: true });
            let fields = this.state.fields;
            fields['tMode'] = "buddy"
            this.setState({
                fields
            });
        }
        else if (takeOnlyCategory === "D106") {
            this.setState({ isTeamCheck: true, isBuddyDisable: true });
            let fields = this.state.fields;
            fields['tMode'] = "team"
            this.setState({
                fields
            });
        }
        else if (takeOnlyCategory === "D107") {
            if (takeOnlySport === 'Baseball' || takeOnlySport === 'Softball' || takeOnlySport === 'Cricket' || takeOnlySport === 'Rugby' || takeOnlySport === 'Beach Volleyball' || takeOnlySport === 'Volleyball' || takeOnlySport === 'Basketball' || takeOnlySport === 'Kabbadi') {
                this.setState({ isTeamCheck: true, isBuddyCheck: false, isBuddyDisable: true });
                let fields = this.state.fields;
                fields['tMode'] = "team"
                this.setState({
                    fields
                });
                if (takeOnlySport === 'Softball') {
                    let fields = this.state.fields;
                    fields['gender'] = 'Female';
                    this.setState({
                        fields
                    });
                    this.setState({ isCheckFemale: true, isFeMaleDisable: false, isMixDisable: true, isMaleDisable: true });
                }
            }
            else {

                this.setState({ isBuddyCheck: true, isTeamCheck: false });
                let fields = this.state.fields;
                fields['tMode'] = "buddy";
                this.setState({
                    fields
                });
                if (takeOnlySport === 'Golf') {
                    this.setState({ isTeamDisable: true });
                }
            }


        }
        else if (takeOnlyCategory === "D104") {
            if (takeOnlySport === '4x100 meter relay' || takeOnlySport === '4x400 meter relay' || takeOnlySport === '4x100 meters Hurdle') {
                this.setState({ isTeamCheck: true, isBuddyDisable: true });
                let fields = this.state.fields;
                fields['tMode'] = "team"
                this.setState({
                    fields
                });
            }
            else {

                this.setState({ isBuddyCheck: true, isTeamCheck: false, isTeamDisable: true });
                let fields = this.state.fields;
                fields['tMode'] = "buddy"
                this.setState({
                    fields
                });
            }
            if (takeOnlySport === 'Rowing') {
                this.setState({ isBuddyCheck: true, isTeamDisable: false, isBuddyDisable: false });
            }
        }
        this.setState({
            takeOnlySport: takeOnlySport,
            takeOnlySportIds: takeOnlySportIds,
            takeOnlyCategory: takeOnlyCategory,
            openSports: false
        });
        // document.getElementById('hideSports').style.visibility = "hidden";
    }
    getSportsFunction() {
        const apiUrl = rshApi + "/sports/get-sports";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "minimal": "false",
                    "isGroupGame": "isGroupGame"
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({
                    allSports: result.data
                });
                console.log(that.state.allSports)
            });
    }
    handleTouch(e) {
        let { touched } = this.state;
        if (e.target.name && touched[e.target.name] != true) {
            touched[e.target.name] = true;
            this.setState({
                touched
            });
        }
    }
    createTForm(e) {
        e.preventDefault();
        this.setState({
            formSubmitted: true
        });
        if (this.validateForm()) {


            var tournament = {
                tournamentName: this.state.fields.tName,
                tournamentIcon: null,
                sportsId: this.state.fields.sportId,
                sportsName: this.state.fields.sportsName,
                categoryId: this.state.fields.categoryId,
                createdOn: currentTimeStamp,
                modifiedOn: currentTimeStamp,
                createdBy: this.state.user.contactNo,
                creatorName: this.state.user.name,
                tournamentMode: this.state.fields.tMode,
                gender: this.state.fields.gender,
                city: this.state.fields.city,
                state: this.state.fields.state,
                country: this.state.fields.country,
                location: this.state.fields.location,
                latitude: this.state.fields.latitude,
                longitude: this.state.fields.longitude,
                regDate: this.state.fields.tRegDate,
                startDate: this.state.fields.tStartDate,
                endDate: this.state.fields.tEndDate,
                tournamentLevel: this.state.fields.tLevel,
                entryFees: this.state.fields.eFees ? this.state.fields.eFees : "",
                prize: this.state.fields.price ? this.state.fields.price : "",
                rules: this.state.fields.rules ? this.state.fields.rules : "",
                description: this.state.fields.description ? this.state.fields.rules : "",
                courseSelected: false,
                courseId: null,
                courseRound: 0,
                poolType: null,
                tournamentType: this.state.fields.tType,
                winnerPoints: this.state.fields.winner ? parseInt(this.state.fields.winner) : 0,
                drawPoints: this.state.fields.draw ? parseInt(this.state.fields.draw) : 0,
                looserPoints: this.state.fields.points ? parseInt(this.state.fields.points) : 0,
                matchType: null,
                invited: null,
                accepted: null,
                status: null,
                tournamentWinnerId: null,
                scheduledTournament: false,
                manOfTheSeries: null,
                bestBatsman: null,
                bestBowler: null,
                bestFielder: null
            }
            const { match } = this.props;
            if (match) {
                let pageId = match.params.pageId;
                tournament.pageId = pageId;
                tournament.tournamentFrom = 'page';
                tournament.levelType = this.props.pageTournamentType;
            }

            if (this.state.fields.tType) {
                const apiUrl = rshApi + "/tournaments/create-tournament";
                const that = this;
                fetch(apiUrl, {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": {
                            tournament
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {
                        if (that.state.imageCrop) {
                            that.submitPicture(result.data)
                        }
                        else {
                            that.setState({
                                showingPopup: true,
                                showingAlert1: true
                            });
                            document.getElementById('disableSuccess').disabled = true;
                            setTimeout(() => {
                                window.location = "/tournamentprofile/index.html?id=" + result.data.id
                            }, 2000);

                            // $('#createTornament').show();
                            // $('#createTornament').delay(1000).fadeOut('slow');
                            // setTimeout(() => {
                            //     window.location = "/tournamentprofile/index.html?id=" + result.data.id
                            // }, 2000);
                        }
                    }).catch(function (error) {

                    })
                let fields = {};
                fields["tName"] = "";
                fields["tRegDate"] = "";
                fields["tStartDate"] = "";
                fields["tEndDate"] = "";
                fields["location"] = "";
                fields["city"] = "";
                fields["state"] = "";
                fields["country"] = "";
                fields["latitude"] = "";
                fields["longitude"] = "";
                fields["eFees"] = "";
                fields["price"] = "";
                fields["rules"] = "";
                fields["description"] = "";
                that.setState({ fields: fields });
            }
            else {
                this.setState({ RequireTT: true })
            }
        }
    }
    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["tName"]) {
            formIsValid = false;
            errors["tName"] = "*Please Enter Tournament Name.";
        }
        if (!fields["location"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["city"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["state"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["country"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["latitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["longitude"]) {
            formIsValid = false;
            errors["location"] = "*Please select Correct Location.";
        }
        if (!fields["tRegDate"]) {
            formIsValid = false;
            errors["tRegDate"] = "*Please select Tournament Registration Date";
        }
        if (!fields["tStartDate"]) {
            formIsValid = false;
            errors["tStartDate"] = "*Please select Tournament Start Date";
        }
        if (!fields["tEndDate"]) {
            formIsValid = false;
            errors["tEndDate"] = "*Please select Tournament End Date";
        }
        if (fields["tType"] === 2 || fields["tType"] === 3) {
            if (!fields["winner"]) {
                formIsValid = false;
                errors["winner"] = "*Please Fill Winner Point";
            }
            if (!fields["draw"]) {
                formIsValid = false;
                errors["draw"] = "*Please Fill Draw Point";
            }
            if (!fields["points"]) {
                formIsValid = false;
                errors["points"] = "*Please Fill Looser Point";
            }
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    closeRequireTT = () => {
        this.setState({ RequireTT: false })
    }
    render() {

        var TOption;
        if (this.state.tType.length === 1) {
            if (this.state.tType == 5) {
                TOption = <div class="cc_inner_box  opponent_inner">
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption5" value="5" />
                        <label for="ttOption5">Straight final</label>
                    </div></div>
            }
            if (this.state.tType == 1) {
                TOption = <div class="cc_inner_box  opponent_inner">
                    <label class="cc_box-head">Tournament Type</label>
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
                        <label for="ttOption1">Knockout</label>
                    </div>
                </div>
            }
            if (this.state.tType == 4) {
                TOption = <div class="cc_inner_box  opponent_inner">
                    <div class="styled-input-single createinput">
                        <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption4" value="4" />
                        <label for="ttOption4">Pool based knockout</label>
                    </div>
                </div>
            }
        }

        if (this.state.tType.length === 3) {
            TOption = <div class="cc_inner_box  opponent_inner">
                <label class="cc_box-head">Tournament Type</label>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption1" value="1" />
                    <label for="ttOption1">Knockout</label>
                </div>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption2" value="2" />
                    <label for="ttOption2">Straight league</label>
                </div>
                <div class="styled-input-single createinput">
                    <input type="radio" name="ttOption" onChange={this.changeTType} id="ttOption3" value="3" />
                    <label for="ttOption3">Pool based league</label>
                </div>
            </div>
        }


        const { term, allSports } = this.state;
        return (
            <section class="d_midsec createmidsec">
                <Modal
                    size="md"
                    show={this.state.showingPopup}
                    onHide={this.closingPopup}
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body modal-bodyErrorPopup">
                        {this.state.showingAlert1 ? <p className="alert alert-success"> <strong>Success!</strong> Tournament Created! </p> : ''}
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.RequireTT}
                    onHide={this.closeRequireTT}
                    animation={false}
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="modal-body modal-bodyErrorPopup">
                        <p className="alert alert-danger">
                            <strong>Please Select Tournament Type</strong>
                        </p>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.openSports}
                    onHide={this.closeSports}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Select Sports</h4>
                        </div>

                        <div className="modal-body modal-bodyD modal-bodySport">
                            <div className="createselect_team">
                                <div className="createselect_search">
                                    <form>
                                        <input ref={(input) => { this.testInput = input; }} onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                                        <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                                    </form>
                                </div>
                                <ul className="ct_selectsports">
                                    {
                                        allSports.filter(searchingFor(term)).map(item =>
                                            item.name !== "Gully Cricket" ? <div key={item.name}>
                                                <li className="li_selectsports">
                                                    <span onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)} className="assignimg" style={{ backgroundImage: "url(" + item.icon + ")" }}></span>
                                                    <p onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)}>{item.name}</p>
                                                </li>
                                            </div> : null
                                        )
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </Modal>

                <div id="openCropModalTour" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Upload Image</h4>
                            </div>

                            <div className="modal-body">
                                <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="cmnbutton" data-dismiss="modal">Crop & Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divpost">
                    <span class="teamprofile_span">Create Tournament</span>
                    <div class="createteam_div" style={{ backgroundImage: "url(" + challenegbg + ")" }}>
                        <button>Change Picture</button>
                        <div class="uploadimg_div" > <span style={{ backgroundImage: this.state.savePic ? "url(" + this.state.savePic + ")" : "url(" + team + ")" }}></span><Link data-toggle="modal" data-target="#openCropModalTour"><img src={camera} /></Link></div>
                    </div>
                </div>
                <form onSubmit={this.createTForm}>
                    <div class="createteamform tournamentform">
                        <ul>
                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" name="tName" class="question" id="" autocomplete="off" onChange={(e) => { this.handleChange(e); this.validateForm(); }} onBlur={(e) => { this.handleTouch(e); this.validateForm(); }} />

                                    <label for=""><span>Tournament Name</span></label>
                                    {
                                        this.state.formSubmitted || this.state.touched.tName
                                            ?
                                            <div className="errorMsg1">{this.state.errors.tName}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="commaninputdiv">
                                    {/* <input type="text" name="name" className="question" autoComplete="off" onClick={() => this.selectSports()} value={this.state.takeOnlySport} />
                                    <label htmlFor=""><span>Select Sports</span></label> */}

                                    <input onClick={this.openSports} type="text" name="name" className="question" autoComplete="off" value={this.state.takeOnlySport} />
                                    <label htmlFor=""><span>Select Sports</span></label>

                                    {/* <div id="hideSports" style={{ display: 'none' }} className="createselect_team">
                                        <div className="createselect_search">
                                            <form>
                                                <input onChange={this.searchHandler} type="search" placeholder="search" value={term} />
                                                <Link to={"#"}><img src={searchnew} alt="true" /></Link>
                                            </form>
                                        </div>
                                        <ul className="ct_selectsports">
                                            {allSports.filter(searchingFor(term)).map(item =>
                                                <div key={item.name}>
                                                    <li>
                                                        <span onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)} className="assignimg" style={{ backgroundImage: "url(" + item.icon + ")" }}></span>
                                                        <p onClick={() => this.chooseSport(item.name, item.sportsId, item.categoryId, item.sportsType)}>{item.name}</p>
                                                    </li>
                                                </div>
                                            )
                                            }
                                        </ul>
                                    </div> */}
                                </div>
                            </li>
                            <li class="cc_box">
                                <div class="cc_inner_box  opponent_inner">
                                    <label class="cc_box-head">Select Team</label>
                                    <div class="commaninputdiv createredio">

                                        <div class="styled-input-single createinput">
                                            <input type="radio" name="tMode" id="single11" onChange={this.selectTmode} disabled={this.state.isBuddyDisable} checked={this.state.isBuddyCheck} value="buddy" />
                                            <label for="single11">Single</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input name="tMode" type="radio" id="team11" onChange={this.selectTmode} disabled={this.state.isTeamDisable} checked={this.state.isTeamCheck} value="team" />
                                            <label for="team11">Team</label>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv creategender">
                                    <label>Gender</label>

                                    <div class="creategenderdiv">
                                        <div class="styled-input-single createinput male">
                                            <input type="radio" name="gender" id="male" onChange={this.selectGender} value="Male" disabled={this.state.isMaleDisable} />
                                            <label className="gender-d" for="male"></label>
                                        </div>
                                        <div class="styled-input-single createinput female">
                                            {this.state.isCheckFemale ? <input type="radio" name="gender" id="female" onChange={this.selectGender} checked={true} value="Female" disabled={this.state.isFeMaleDisable} /> : <input type="radio" name="gender" id="female" onChange={this.selectGender} value="Female" disabled={this.state.isFeMaleDisable} />}
                                            <label className="gender-d" for="female"></label>
                                        </div>
                                        <div class="styled-input-single createinput mix">
                                            <input type="radio" name="gender" id="mix" onChange={this.selectGender} value="Both" disabled={this.state.isMixDisable} />
                                            <label className="gender-d" for="mix"></label>
                                        </div>
                                    </div>

                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" autocomplete="off" /> */}
                                    <Map parentCallback={this.callbackFunction} />

                                    {/* <label for=""><span>Location</span>
                                </label> */}


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.location}</div>
                                        :
                                        ''
                                }
                            </li>

                            <li class="" >
                                <div class="commaninputdiv ccdatetime1" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" placeholder="" autocomplete="off" /> */}
                                    <span>Reg Date</span>
                                    <DatePicker autoComplete="off"
                                        name="tRegDate"
                                        selected={this.state.regDate}
                                        onChange={this.handleRegDate}
                                        minDate={new Date()}
                                    />


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tRegDate}</div>
                                        :
                                        ''
                                }

                                <div class="commaninputdiv ccdatetime1" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" placeholder="" autocomplete="off" />
                                <label for=""><span>Start Date</span></label>
                                 */}
                                    <span>Start Date</span>
                                    <DatePicker
                                        autoComplete="off"
                                        name="tStartDate"
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDate}
                                        minDate={new Date()}
                                    />


                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tStartDate}</div>
                                        :
                                        ''
                                }
                                <div class="commaninputdiv" style={{ borderBottom: '1px solid #2cae4a' }}>
                                    {/* <input type="text" name="name" class="question" id="" required="" autocomplete="off" />
                                <label for=""><span>End Time</span></label> */}
                                    <span>End Date</span>
                                    <DatePicker
                                        autoComplete="off"
                                        name="tEndDate"
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDate}
                                        minDate={this.state.startDate}
                                    />

                                </div>
                                {
                                    this.state.formSubmitted
                                        ?
                                        <div className="errorMsg1">{this.state.errors.tEndDate}</div>
                                        :
                                        ''
                                }

                            </li>

                            <li>
                                <div class="cc_inner_box  opponent_inner">
                                    <label class="cc_box-head">Tournament Level</label>
                                    <div class="commaninputdiv createredio">
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="local" value="local" />
                                            <label for="local">Local</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="state" value="state" />
                                            <label for="state">State</label>
                                        </div>
                                        <div class="styled-input-single createinput">
                                            <input type="radio" onChange={this.changeTLevel} name="tournamenttype" id="national" value="national" />
                                            <label for="national">National</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                {TOption}
                            </li>
                            {this.state.showLeagueOption ? <li class="cc_datetimeli cc_box">
                                <div class="commaninputdiv ccdatetime1">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="winner" class="question" id="" required="" placeholder="points" autocomplete="off" />
                                    <label for=""><span>Winner</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.winner}</div>
                                            :
                                            ''
                                    }
                                </div>


                                <div class="commaninputdiv ccdatetime1">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="draw" class="question" id="" required="" placeholder="points" autocomplete="off" />
                                    <label for=""><span>Draw</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.draw}</div>
                                            :
                                            ''
                                    }
                                </div>

                                <div class="commaninputdiv">
                                    <input type="number" onChange={(e) => { this.handleChange(e) }} name="points" class="question" id="" required="" autocomplete="off" placeholder="points" />
                                    <label for=""><span>Looser</span></label>
                                    {
                                        this.state.formSubmitted
                                            ?
                                            <div className="errorMsg1">{this.state.errors.points}</div>
                                            :
                                            ''
                                    }
                                </div>
                            </li> : ''}

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="eFees" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Entry Fees</span></label>
                                </div>
                            </li>
                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="price" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Price</span></label>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="rules" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Rules</span></label>
                                </div>
                            </li>

                            <li>
                                <div class="commaninputdiv">
                                    <input type="text" onChange={(e) => { this.handleChange(e) }} name="description" class="question" id="" required="" autocomplete="off" />
                                    <label for=""><span>Description</span></label>
                                </div>
                            </li>

                        </ul>
                        <button id="disableSuccess" class="createnext" type="submit">Create Tournament</button>
                    </div>
                    {/* <div id="createTornament" style={{ display: 'none', margin: '5px' }} className="alert alert-success " role="alert">
                        <strong>Tornament Created SuccesFully!</strong>
                    </div> */}
                </form>
            </section>
        )
    }
}
