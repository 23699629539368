import React, { Component } from 'react'

import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBR25kduFonTZ5oh6vaAHsINicKWah-byY");
Geocode.enableDebug();

class Map extends Component {

    onPlaceSelected = (place) => {
        console.log(place)
        const address = place.formatted_address;
        var addressArray = place.address_components;
        var city = this.getCity(addressArray);
        var country = this.getCountry(addressArray);
        var state = this.getState(addressArray);
        var latValue = place.geometry.location.lat();
        var lngValue = place.geometry.location.lng();
        console.log(address, addressArray, city, country, state, latValue, lngValue);
        this.setState({
            address: (address) ? address : '',
            country: (country) ? country : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            latValue: (latValue) ? latValue : '',
            lngValue: (lngValue) ? lngValue : '',
        })
        var mapData = {
            address: address,
            city: city,
            state: state,
            country: country,
            latValue: latValue,
            lngValue: lngValue,
        }
        this.props.parentCallback(mapData)
    };

    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    getCountry = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    render() {
        return (
            <Autocomplete

                onPlaceSelected={this.onPlaceSelected}
                types={['(regions)']}

            ></Autocomplete>

        )
    }
}
export default Map;