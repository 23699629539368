import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import Modal1 from 'react-modal';
import Modal from 'react-bootstrap/Modal';
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import $, { nodeName } from 'jquery';
import noBroadcast from '..//..//img/noBroadcast.jpg';
import play from '..//..//img/play.png';
import logo from '..//..//img/finallogo.png';
import ConfigUrl from '..//..//ConfigUrl';
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
const challengeAction = "ended";
var teamMembersBatfirst = [], teamMembersBolfirst = [];

var batFirstId;
var batSecondId;
var teamBatFirst;
let inThisOver = [];
let currOver, overList, bowlerList, currentOverObj = {}, currentBowllerObj = {};
let currentBallNo, currentBatsmanId;
let striker = null, NonStriker = null, selectedBowler = null;
let wikType = null, helper = null, newBattsman = null;
var d107CricketScoreCard = {};

const addBallsObj = {
    "ballNo": null,
    "extras": null,
    "playedBy": { "batsmanId": null, "newBatsmanId": null, "lastBatsmanId": null, "run": null },
    "strokeNo": null,
    "total": null,
}
const addOversObj = {
    "overNo": null,
    "bowlerID": null,
    "balls": [
        addBallsObj
    ]
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class Demo107Vue_Cricket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingAlert1: false,
            byTeamName: '',
            byTeamNameId: '',
            toTeamName: '',
            toTeamNameid: '',
            showMe: true,
            showMe1: true,

            d107CricketScoreCard: {},
            byCricketScoreCard: {},
            toCricketScoreCard: {},
            firstInningScoreCard: {},
            secondInningScoreCard: {},
            originalOvers: '',
            strokeNo: '',
            firstBattingTeam: '',
            currentInning: '',

            showingNextInningsModal: false,
            showingWicketModal: false,
            modalIsOpen: false,
            showingModal0: false,
            showingModal: false,
            wide: '',
            nb: '',
            byes: '',
            lb: '',
            wicket: '',
        };
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closingModal0 = this.closingModal0.bind(this);
        this.closingModal = this.closingModal.bind(this);
    }

    afterOpenModal() {
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
        window.location.reload(false);
    }

    closingModal0() {
        this.setState({ showingModal0: false })
        window.location.reload(false);
    }

    closingModal() {
        this.setState({ showingModal: false, showingWicketModal: false, showingNextInningsModal: false })
    }

    componentDidMount() {
        $(document).ready(function () {
            $(".btn-pref .btn").click(function () {
                $(".btn-pref .btn").removeClass("btn-default").addClass("btn-primary");
                $(this).removeClass("btn-primary").addClass("btn-default");
            });
        });
        this.getScore();
    }



    getScore() {
        const apiUrl = rshApi + "/demo-challenges/get-score";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challengeId": that.props.challenge.challengeId,
                    "matchId": that.props.challenge.challengeId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)

                if (result.data.score !== null) {

                    if (result.data.score.d107CricketScoreCard !== null) {

                        that.setState({
                            d107CricketScoreCard: result.data.score.d107CricketScoreCard,
                            byCricketScoreCard: result.data.score.d107CricketScoreCard.byCricketScoreCard,
                            toCricketScoreCard: result.data.score.d107CricketScoreCard.toCricketScoreCard,
                            firstInningScoreCard: result.data.score.d107CricketScoreCard.firstInningScoreCard,
                            secondInningScoreCard: result.data.score.d107CricketScoreCard.secondInningScoreCard,
                            originalOvers: result.data.score.d107CricketScoreCard.overs,
                            strokeNo: result.data.score.d107CricketScoreCard.strokeNo,
                            firstBattingTeam: result.data.score.d107CricketScoreCard.firstBattingTeam,
                            currentInning: result.data.score.d107CricketScoreCard.currentInning,
                        })

                        //storing batTeam and bowlTeam
                        if (result.data.score.d107CricketScoreCard.currentInning === 1) {
                            that.props.profileTeams.map(team => {
                                if (team.teamId === result.data.score.d107CricketScoreCard.firstInningScoreCard.battingTeamId) {
                                    that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });

                                    teamMembersBatfirst = team.teamMembers;

                                }
                                if (team.teamId === result.data.score.d107CricketScoreCard.firstInningScoreCard.bowlingTeamId) {
                                    that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });

                                    teamMembersBolfirst = team.teamMembers;
                                }
                            })
                        }
                        if (result.data.score.d107CricketScoreCard.currentInning === 2) {
                            that.props.profileTeams.map(team => {
                                if (team.teamId === result.data.score.d107CricketScoreCard.secondInningScoreCard.battingTeamId) {
                                    that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                                }
                                if (team.teamId === result.data.score.d107CricketScoreCard.secondInningScoreCard.bowlingTeamId) {
                                    that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                                }
                            })
                        }
                        d107CricketScoreCard = result.data.score.d107CricketScoreCard;
                    }

                    if (result.data.score.d107CricketScoreCard.firstBattingTeam === null) {
                        that.setState({ modalIsOpen: true, d107CricketScoreCard: result.data.score.d107CricketScoreCard });
                        that.props.profileTeams.map(team => {
                            if (team.teamId === that.props.challenge.accepted[0]) {
                                that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                            }
                            else {
                                that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                            }
                        })
                    }

                    if (result.data.score.d107CricketScoreCard.firstBattingTeam !== null) {
                        if (result.data.score.d107CricketScoreCard.firstInningScoreCard.currentOver === 0) {
                            that.props.profileTeams.map(sel => {
                                if (result.data.score.d107CricketScoreCard.firstInningScoreCard.battingTeamId === sel.teamId) {
                                    teamMembersBatfirst = sel.teamMembers;
                                }
                                if (result.data.score.d107CricketScoreCard.firstInningScoreCard.bowlingTeamId === sel.teamId) {
                                    teamMembersBolfirst = sel.teamMembers;
                                }
                            })
                            striker = teamMembersBatfirst[0];
                            NonStriker = teamMembersBatfirst[0];
                            selectedBowler = teamMembersBolfirst[0];
                            d107CricketScoreCard.currentInning = 1;

                            that.setState({ showingModal0: true, d107CricketScoreCard: result.data.score.d107CricketScoreCard });
                        }
                    }
                }

                if (result.data.score === null) {
                    let challenge = that.props.challenge;
                    d107CricketScoreCard = {
                        "byCricketScoreCard": {
                            "teamId": challenge.accepted[0],
                            "winner": false,
                        },
                        "toCricketScoreCard": {
                            "teamId": challenge.accepted[1],
                            "winner": false,
                        },
                        "firstInningScoreCard": null,
                        "secondInningScoreCard": null,
                        "strokeNo": 0,
                        "overs": challenge.distance,
                        "currentInning": 0,
                        "firstBattingTeam": null,
                        "manOfTheMatch": null,
                    }
                    let score = {
                        challengeId: that.props.challenge.challengeId,
                        matchId: that.props.challenge.challengeId,
                        d107CricketScoreCard: d107CricketScoreCard,
                    }
                    DemoUpdateChallengeScore(score);

                    if (d107CricketScoreCard.firstInningScoreCard === null) {
                        that.setState({ modalIsOpen: true, d107CricketScoreCard: d107CricketScoreCard });
                        that.props.profileTeams.map(team => {
                            if (team.teamId === that.props.challenge.accepted[0]) {
                                that.setState({ byTeamName: team.teamName, byTeamNameid: team.teamId });
                            }
                            else {
                                that.setState({ toTeamName: team.teamName, toTeamNameid: team.teamId });
                            }
                        })
                    }
                }

            });
    }









    render() {
        function isEmpty(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        }
        const backLinks = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
        let batsman = [], bowlers = [], batsman2ndIn = [], bowlers2ndin = [];
        if (isEmpty(this.state.firstInningScoreCard)) {

        }
        else {
            batsman = [...this.state.firstInningScoreCard.batsmen];
            bowlers = [...this.state.firstInningScoreCard.bowlers];
        }
        if (isEmpty(this.state.secondInningScoreCard)) {

        }
        else {
            batsman2ndIn = [...this.state.secondInningScoreCard.batsmen];
            bowlers2ndin = [...this.state.secondInningScoreCard.bowlers];
        }




        return (<React.Fragment>

            <section class="d_midsec createmidsec broadcast_box">
                <div class="commanbox cp_profile">
                    <div class="broadcast_video " style={{
                        backgroundImage: "url(" + noBroadcast + ")",
                    }}>
                        <span>{this.props.challenge.challengeTime}</span>
                        <a class="tour_broadcast_update"><img src={play} alt="" /></a>
                    </div>




                    {this.state.showMe1 ? <div class="fade in">
                        <div class="well welltab2-d">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                            <ul class="team_match team_match_acc">
                                                <li>
                                                    <div class="scoreflag_div">
                                                        <span class="score_flagimg" style={{
                                                            backgroundImage: "url(" + logo + ")",
                                                        }}></span>
                                                        {this.state.currentInning === 1 ? <span class="score_flagtext">{this.state.byTeamName}</span> : <span class="score_flagtext">{this.state.toTeamName}</span>}
                                                    </div>
                                                    {this.state.firstInningScoreCard !== null ? <label class="teamscore">{this.state.firstInningScoreCard.totalRun}/{this.state.firstInningScoreCard.wicketFall} ({this.state.firstInningScoreCard.overCompleted}.{this.state.firstInningScoreCard.ballNo}) ({this.state.originalOvers})</label> : ''}
                                                </li>
                                            </ul>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapse1" class="panel-collapse collapse in">
                                    <div class="panelnew">
                                        <div class="table-responsive scoretable">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Batsman</th>
                                                        <th></th>
                                                        <th>R</th>
                                                        <th>B</th>
                                                        <th>4s</th>
                                                        <th>6s</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {batsman.map(fi => {
                                                        if (fi.fallOfWicket !== null) {
                                                            return <tr>
                                                                <td>{fi.batsmanName}</td>
                                                                <td>({fi.fallOfWicket})</td>
                                                                <td>{fi.runs}</td>
                                                                <td>{fi.balls}</td>
                                                                <td>{fi.boundries}</td>
                                                                <td>{fi.sixes}</td>
                                                            </tr>
                                                        }
                                                        else {
                                                            return <tr>
                                                                <td>{fi.batsmanName}</td>
                                                                <td>(not out)</td>
                                                                <td>{fi.runs}</td>
                                                                <td>{fi.balls}</td>
                                                                <td>{fi.boundries}</td>
                                                                <td>{fi.sixes}</td>
                                                            </tr>
                                                        }
                                                    })
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                        <div class="table-responsive scoretable">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Bowler</th>
                                                        <th>O</th>
                                                        <th>M</th>
                                                        <th>R</th>
                                                        <th>W</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bowlers.map(fs => {
                                                        return <tr>
                                                            <td>{fs.bowlerName}</td>
                                                            <td>{fs.completedOver}.{fs.balls}</td>
                                                            <td>0</td>
                                                            <td>{fs.runs}</td>
                                                            <td>{fs.wickets}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                            <ul class="team_match team_match_acc">
                                                <li>
                                                    <div class="scoreflag_div">
                                                        <span class="score_flagimg" style={{
                                                            backgroundImage: "url(" + logo + ")",
                                                        }}></span>
                                                        {this.state.currentInning === 2 ? <span class="score_flagtext">{this.state.byTeamName}</span> : ''}
                                                    </div>
                                                    {(this.state.secondInningScoreCard !== null) ? <label class="teamscore">{this.state.secondInningScoreCard.totalRun}/{this.state.secondInningScoreCard.wicketFall} ({this.state.secondInningScoreCard.overCompleted}.{this.state.secondInningScoreCard.ballNo}) ({this.state.originalOvers})</label> : ''}
                                                </li>
                                            </ul>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapse2" class="panel-collapse collapse in">
                                    <div class="panelnew">
                                        <div class="table-responsive scoretable">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Batsman</th>
                                                        <th></th>
                                                        <th>R</th>
                                                        <th>B</th>
                                                        <th>4s</th>
                                                        <th>6s</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        batsman2ndIn.map(si => {
                                                            if (si.fallOfWicket !== null) {
                                                                return <tr>
                                                                    <td>{si.batsmanName}</td>
                                                                    <td>({si.fallOfWicket})</td>
                                                                    <td>{si.runs}</td>
                                                                    <td>{si.balls}</td>
                                                                    <td>{si.boundries}</td>
                                                                    <td>{si.sixes}</td>
                                                                </tr>
                                                            }
                                                            else {
                                                                return <tr>
                                                                    <td>{si.batsmanName}</td>
                                                                    <td>(not out)</td>
                                                                    <td>{si.runs}</td>
                                                                    <td>{si.balls}</td>
                                                                    <td>{si.boundries}</td>
                                                                    <td>{si.sixes}</td>
                                                                </tr>
                                                            }
                                                        })}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div class="table-responsive scoretable">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Bowler</th>
                                                        <th>O</th>
                                                        <th>M</th>
                                                        <th>R</th>
                                                        <th>W</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bowlers2ndin.map(ss => {
                                                        return <tr>
                                                            <td>{ss.bowlerName}</td>
                                                            <td>{ss.completedOver}.{ss.balls}</td>
                                                            <td>0</td>
                                                            <td>{ss.runs}</td>
                                                            <td>{ss.wickets}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""}

                    <div class="table_actions winner_actions">
                        <a href={backLinks} class="cmnbutton backbtn fl">Back</a>

                    </div>

                </div>
            </section>




        </React.Fragment>
        );
    }
}

