import React, { Component } from "react";
import team from "./img/team.png";
import NoTeam from "./img/mytournament.svg";
import { trackPromise } from "react-promise-tracker";
import ConfigUrl from './ConfigUrl';
import filter from './img/filter.png'
import searchnew from './img/searchnew.png';
import explaner2 from "./img/playdiator-explaner-complete.jpg";
import TeamProfile from './TeamProfile';
import { Link } from "react-router-dom";
import { } from 'prismic-reactjs';
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

function searchingFor(term) {
    return function (x) {
        return x.teamName.toLowerCase().includes(term.toLowerCase()) || !term;
    }
}

class BuddiesTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            teamsNew: [],
            owner: [],
            member: [],
            showTeamProfile: false,
            teamId: '',
            showTeamList: true,
            term: '',
        };
        this.loadMore = this.loadMore.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
    }

    //Searching Bar
    searchHandler(event) {
        this.setState({ term: event.target.value })
    }

    openTeamProfile = (teamId) => {
        this.setState({ showTeamProfile: true, teamId: teamId, showTeamList: false })
    }


    loadMore = () => {
        const that = this;
        var owner = [], member = [];
        fetch(userApi + "teams/get-teams", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                data: {
                    contactNo: that.props.userbuddy,
                    type: 'both',
                    minimul: true
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result);
                if (result.data !== undefined) {
                    result.data.map(x => {
                        if (x.createdBy === that.props.userbuddy) {
                            owner.push(x);
                        }
                        if (x.createdBy !== that.props.userbuddy) {
                            member.push(x);
                        }
                        that.setState({
                            data: 'Both',
                            teams: result.data,
                            teamsNew: result.data,
                        });
                    })
                    that.setState({
                        owner: owner,
                        member: member
                    });
                }
            });
    };

    componentDidMount() {
        this.loadMore();
    }

    getCreatedTime = (createdOn) => {
        var formattedTimestamp = Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        }).format(createdOn);
        return formattedTimestamp;
    }

    changeFilter = (type) => {
        if (type === 'Owner') {
            this.setState({
                data: "Owner", teams: this.state.owner,
            })
        }
        if (type === 'Member') {
            this.setState({
                data: "Member", teams: this.state.member,
            })
        }
        if (type === 'Both') {
            this.setState({
                data: "Both", teams: this.state.teamsNew,
            })
        }
    }

    render() {
        const { term, teams } = this.state;
        return (
            <>
                {/* <div className="container containerbottom non-session-d">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                        <section className="d_midsec">
                            {this.props.userbuddy ? '' :
                                <React.Fragment>
                                    <div className="postdata">
                                        <iframe allowfullscreen="yes" style={{ 'width': '100%', 'height': '360px' }} className="youtube-player" id="player" type="text/html" src="https://www.youtube.com/embed/nqdZs6m1IDo?autoplay=1&mute=1">&lt;br /&gt;</iframe>

                                    </div>
                                    <img className="explanerImg" src={explaner2} />
                                    <div className="center-d">
                                        <div className="downloaddiv-d1">
                                            <button onClick={() => this.openApp()} className="download-d1" type="button">Download App</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            } */}

                {this.state.showTeamList ? <div className="commanbox" id="profilebuddies">
                    <h3 className="commanhead">Teams</h3>
                    <div className="ct_searchbuddies">
                        <form>
                            <input onChange={this.searchHandler} value={term} type="search" placeholder="Search" />
                            <Link to={'#'}><img src={searchnew} alt="true" /></Link>
                        </form>
                    </div>
                    {/* <ul className="challangebutton">
                        <li>
                            <div className="dropdown ct_drop">
                                <Link to={'#'} className=" dropdown-toggle" data-toggle="dropdown">
                                    <img src={filter} alt="" />
                                </Link>
                                <ul className="dropdown-menu">
                                    <li><Link onClick={() => this.changeFilter('Both')} to={'#'}>Both</Link></li>
                                    <li><Link onClick={() => this.changeFilter('Owner')} to={'#'}>Owner</Link></li>
                                    <li><Link onClick={() => this.changeFilter('Member')} to={'#'}>Member</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul> */}
                    <div className="cl_box_div">
                        <ul className="at_ul">
                            {teams.filter(searchingFor(term)).length ? teams.filter(searchingFor(term)).map(x => {
                                return (() => {
                                    if (x.teamIcon === null) {
                                        switch (this.state.data) {
                                            case 'Owner':
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + team + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );

                                            case 'Member':
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + team + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );

                                            default:
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + team + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies:  {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );
                                        }
                                    }
                                    else {
                                        switch (this.state.data) {
                                            case 'Owner':
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + x.teamIcon + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies: {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );

                                            case 'Member':
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + x.teamIcon + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies: {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );

                                            default:
                                                return (
                                                    <li>
                                                        <a href={"/teamprofilepage/index.html?id=" + x.teamId} className="at_link">
                                                            <span className="at_teamimg" style={{
                                                                backgroundImage: "url(" + x.teamIcon + ")"
                                                            }}></span>
                                                            <label className="at_name">{x.teamName}</label>
                                                            <p className="at_sportsname">{x.sportsName}</p>
                                                            <p className="at_date">Created on: {this.getCreatedTime(x.createdOn)}</p>
                                                            <div className="at_teamowner">
                                                                <span className="at_buddy">Buddies: {x.teamMembers ? x.teamMembers.length : 0}</span>
                                                                {this.props.userbuddy === x.createdBy ? <span className="at_owner">Owner</span> : <span className="at_owner">Team Member</span>}
                                                            </div>
                                                        </a>
                                                    </li>
                                                );
                                        }
                                    }
                                })();
                            }) : <div className="broadcast_video">
                                <a><img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /></a>
                                <h3>No team found with this name.</h3>
                            </div>}
                        </ul>
                    </div>
                </div>
                    : <TeamProfile teamId={this.state.teamId} />}
                {/* </section>
                    </div>
                </div> */}
            </>
        );
    }
}
export default BuddiesTeams;
