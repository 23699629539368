import { trackPromise } from "react-promise-tracker";
import ConfigUrl from '../ConfigUrl';

const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem("result"));
let poolNo = 0, PairId = 1;
export const getMatchPair = (tt1, poolNo, pairId, feed, matchId) => {
    if (poolNo) {
        poolNo = poolNo;
    }
    if (pairId) {
        pairId = pairId;
    }
    let pairObj;
    if (tt1.fixtureType1) {
        tt1.fixtureType1.fixtureList.find((fl, index) => {
            pairObj = fl.fixturePairs.find(pair => {
                if (pair.matchId) {
                    // console.log(pair.matchId)
                    // console.log(matchId)
                    if (pair.matchId === matchId) {
                        pair.fixturePosition = index;
                        return pair;
                    }
                }
            })
            return pairObj;
        })
    }
    else {
        if (poolNo == 6) {
            if (tt1.fixtureType2.finalMatch.matchId) {
                if (tt1.fixtureType2.finalMatch.matchId === matchId) {
                    pairObj = tt1.fixtureType2.finalMatch;
                }
            }

            return pairObj;
        }
        else {
            tt1.fixtureType2.fixturePoolList[poolNo].fixtureList.find((fl, index) => {
                pairObj = fl.fixturePairs.find(pair => {
                    if (pair.matchId) {
                        if (pair.matchId === matchId) {
                            pair.fixturePosition = index;
                            return pair;
                        }
                    }
                })
                return pairObj;
            })
        }

    }

    return pairObj;
}

export const declareMatchWinner = (fixture, pairObj, poolNo, pairId, winnerId) => {
    let MatchId = pairObj.matchId;
    let cPairId = pairObj.pairObj;
    let nPairId;
    let nIndex = pairObj.fixturePosition + 1;

    if (fixture.fixtureType1) {
        fixture.fixtureType1.fixtureList[pairObj.fixturePosition].fixturePairs.map((fp, index) => {
            if (index === fp.fixturePosition) {
                nPairId = cPairId / 2 + cPairId % 2;
            }
            if (fp.matchId === MatchId) {
                fp.winnerId = winnerId;
                fp.ended = true;
                delete fp.fixturePosition;
            }
        })
        if (fixture.fixtureType1.fixtureList[nIndex]) {
            fixture.fixtureType1.fixtureList[nIndex].fixturePairs.map((fp, index) => {
                if (fp.secondId) {
                    if (fp.firstId) {

                    }
                    else {
                        fp.firstId = winnerId;
                    }
                }
                else {
                    fp.secondId = winnerId;
                }
                if (fp.secondId !== null && fp.firstId !== null) {
                    fp.matchId = fixture.tournamentId + '-' + fp.firstId + '-' + fp.secondId;
                }
            })
            return fixture;
        }
        else {
            fixture.finalWinner = true;
            return fixture;
        }
    }
    else {
        if (poolNo == 6) {
            if (fixture.fixtureType2.finalMatch.matchId === MatchId) {
                fixture.fixtureType2.finalMatch.winnerId = winnerId;
                fixture.fixtureType2.finalMatch.ended = true;
                delete fixture.fixtureType2.finalMatch.fixturePosition;
            }
            fixture.finalWinner = true;
            return fixture;
        }
        else {
            fixture.fixtureType2.fixturePoolList[poolNo].fixtureList[pairObj.fixturePosition].fixturePairs.map((fp, index) => {
                if (index === fp.fixturePosition) {
                    nPairId = cPairId / 2 + cPairId % 2;
                }
                if (fp.matchId === MatchId) {
                    fp.winnerId = winnerId;
                    fp.ended = true;
                    delete fp.fixturePosition;
                }
            })
            if (fixture.fixtureType2.fixturePoolList[poolNo].fixtureList[nIndex]) {
                fixture.fixtureType2.fixturePoolList[poolNo].fixtureList[nIndex].fixturePairs.map((fp, index) => {
                    if (fp.secondId) {
                        if (fp.firstId) {

                        }
                        else {
                            fp.firstId = winnerId;
                        }
                    }
                    else {
                        fp.secondId = winnerId;
                    }
                    if (fp.secondId !== null && fp.firstId !== null) {
                        fp.matchId = fixture.tournamentId + '-' + fp.firstId + '-' + fp.secondId;
                    }
                })
                return fixture;
            }
            else {
                if (poolNo < 4) {
                    if (fixture.fixtureType2.finalMatch.secondId) {
                        if (fixture.fixtureType2.finalMatch.firstId) {

                        }
                        else {
                            fixture.fixtureType2.finalMatch.firstId = winnerId;
                        }
                    }
                    else {
                        fixture.fixtureType2.finalMatch.secondId = winnerId;
                    }
                    if (fixture.fixtureType2.finalMatch.secondId !== null && fixture.fixtureType2.finalMatch.firstId !== null) {
                        fixture.fixtureType2.finalMatch.matchId = fixture.tournamentId + '-' + fixture.fixtureType2.finalMatch.firstId + '-' + fixture.fixtureType2.finalMatch.secondId;
                    }
                    return fixture;
                }
                if (poolNo == 4) {

                }
                if (poolNo == 5) {

                }
            }
        }
    }

}




// set first id to next match