import React, { Component } from 'react'
import Modal from 'react-modal';
import ModalSet from 'react-bootstrap/Modal'
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import * as myFeedUtilclass from '../TournamentsUtil';
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import * as TournamentsUtil from '../TournamentsUtil';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import play from '../img/play.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import * as myUtilclass from '../OrganiseMatchUtil';
import male from "../img/male.png";
import teamIcons from '../img/team.png';
const tournamentAction = "ended";
let pairObj;
var score;
let byPic, toPic;
let saveSets = false;
let dontDo = false;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
var winningScore = 25;
var selectedWinners = undefined;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournament_d107_VolleyBall_tt23 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107VolleyballScoreCard: {},
            isByPlusDisable: false,
            isToPlusDisable: false,
            isundoDisable: false,
            tournament: null,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
            errorSet: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {

        var winner;
        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winners !== undefined) {
            winner = this.state.winners;
        }




        var d107VolleyballScoreCard = this.state.d107VolleyballScoreCard;
        if (this.state.byWinner) {
            d107VolleyballScoreCard.byVolleyballScoreCard.winner = true;
            d107VolleyballScoreCard.toVolleyballScoreCard.winner = false;
        }
        else {
            d107VolleyballScoreCard.toVolleyballScoreCard.winner = true;
            d107VolleyballScoreCard.byVolleyballScoreCard.winner = false;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107VolleyballScoreCard: d107VolleyballScoreCard,
        }

        if (this.props.fType === 3) {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                var moment = require('moment');
                var now = moment().format();
                var timpstampData = new Date(now).valueOf();
                var tournament = that.props.tournament;
                delete tournament.id;
                tournament.status = "completed";
                tournament.modifiedOn = timpstampData;
                tournament.tournamentWinnerId = that.state.winner;
                TournamentsUtil.ttWinnerFeeds(that.props.tournament.tournamentId, that.props.matchId, winner).then(data => {
                    console.log(data)
                    TournamentsUtil.updateTournament(tournament).then(data1 => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
                    })
                })
            })

        }
        else {
            UpdateTournamentsScore(score);
            let that = this;
            let nrr1 = 0.0;
            let nrr2 = 0.0;
            let organiseMatchObj = that.state.tt23Matches;
            delete organiseMatchObj.id;
            let matchPair = that.state.matchPairObj;
            matchPair.ended = true;
            matchPair.winnerId = that.state.winner;
            let declareWinner = myUtilclass.declareMatchWinner(that.props.fType, organiseMatchObj, matchPair, that.state.winner, nrr1, nrr2);
            declareWinner.then(result => {
                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId;
            })
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ setError: false, [event.target.name]: event.target.value, errorSet: true });
        }
    }

    backState = () => {
        $("#setAttemptModal .close").click();
        window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }

    saveIntialSet = () => {

        var matchIdSpilit = this.props.matchId;
        var ar = matchIdSpilit.split('-'); // split string on comma space
        console.log(ar[1])


        if (this.state.numberOfSets !== null) {
            var i = 0;
            var numberOfSets = parseInt(this.state.numberOfSets);
            var header = [{
                challengedById: null,
                challengedByPoints: 0,
                challengedToId: null,
                challengedToPoints: 0,
                position: 0,
                saved: false,
                tableHeader: true,
                toUndoList: null,
                winnerId: null,
            }];
            var round = [];
            for (i = 1; i <= numberOfSets; i++) {
                var roundData = {
                    position: i,
                    tableHeader: false,
                    challengedById: ar[1],
                    challengedByPoints: 0,
                    challengedToId: ar[2],
                    challengedToPoints: 0,
                    saved: false,
                    toUndoList: null,
                    winnerId: null,
                }

                round.push(roundData);
            }
            var rounds = header.concat(round);
            const that = this;
            var d107ScoreCard = {
                noOfSets: numberOfSets,
                strokeNo: 0,
                setNo: 1,
                byVolleyballScoreCard: {
                    teamId: ar[1],
                    setsWon: null,
                    winner: false
                },
                toVolleyballScoreCard: {
                    teamId: ar[2],
                    setsWon: null,
                    winner: false
                },
                rounds
            }
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107BeachVolleyballScoreCard: d107ScoreCard,
            }

            UpdateTournamentsScore(score);
            that.setState({ rounds: rounds, d107VolleyballScoreCard: d107ScoreCard, score: true });
        }
        else {
            this.setState({ setError: true });
        }
        this.getScore();

    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }
    endEvent() {
        var d107VolleyballScoreCard = this.state.d107VolleyballScoreCard;
        if (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
            if ((d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length === (d107VolleyballScoreCard.noOfSets - 1)) ||
                (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length === 3)) {

                d107VolleyballScoreCard.byVolleyballScoreCard.winner = true;
                this.setState({ byWinner: true, winner: d107VolleyballScoreCard.byVolleyballScoreCard.teamId })
                this.setState({ winnerModalOpen: true })
            } else {
                this.setState({ winnerModalOpen1: true })
            }
        }
        if (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
            if ((d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length === (d107VolleyballScoreCard.noOfSets - 1)) ||
                (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length === 3)) {
                d107VolleyballScoreCard.toVolleyballScoreCard.winner = true;
                this.setState({ toWinner: true, winner: d107VolleyballScoreCard.toVolleyballScoreCard.teamId })
                this.setState({ winnerModalOpen: true })
            } else {
                this.setState({ winnerModalOpen1: true })
            }
        }
        if (d107VolleyballScoreCard.setNo <= 1) {
            this.setState({ winnerModalOpen1: true })
        }
    }

    getScore() {
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        let byName; let toName;
                        that.props.profileUsers.map(user => {
                            if (that.state.matchPairObj.firstId === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.state.matchPairObj.secondId === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        that.props.profileTeams.map(user => {
                            if (that.state.matchPairObj.firstId === user.teamId) {
                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        $('#openDp3').click();
                    }
                    else {
                        that.setState({ d107VolleyballScoreCard: result.data.score.d107VolleyballScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107VolleyballScoreCard.rounds, numberOfSets: result.data.score.d107VolleyballScoreCard.noOfSets, setNo: result.data.score.d107VolleyballScoreCard.setNo, strokeNo: result.data.score.d107VolleyballScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.id === user.contactNo) {
                                    byName = user.name
                                    byPic = user.profilePic ? user.profilePic : male;
                                }
                                if (result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.id === user.contactNo) {
                                    toName = user.name
                                    toPic = user.profilePic ? user.profilePic : male;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            that.props.profileTeams.map(user => {
                                if (result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.teamId === user.teamId) {
                                    byName = user.teamName
                                    byPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                                if (result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.teamId === user.teamId) {
                                    toName = user.teamName
                                    toPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        that.setState({ bySetWon: result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.setsWon ? result.data.score.d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length : 0 });
                        that.setState({ toSetWon: result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.setsWon ? result.data.score.d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length : 0 });
                    }
                });
        }
    }
    makePoint(type) {
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
        var d107VolleyballScoreCard = this.state.d107VolleyballScoreCard;
        var toAdd = this.state.d107VolleyballScoreCard.rounds.filter(round => {
            return round.position === d107VolleyballScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107VolleyballScoreCard.strokeNo;
        var undoList = {
            points: 1,
            strokeNo: 1,
            type: "challengedBy",
        }
        if (d107VolleyballScoreCard.setNo === d107VolleyballScoreCard.noOfSets) {
            winningScore = 15;
        }
        else {
            winningScore = 25;
        }
        if (type === 'by') {
            this.setState({ isByPlusDisable: true, isToPlusDisable: false, })
            undoList.strokeNo = stroke + 1;
            undoList.type = "challengedBy";
            this.setState({ setWinnerName: this.state.byName, setNumberForWinner: d107VolleyballScoreCard.setNo });


            if (toAdd[0].challengedByPoints === (winningScore - 1)) {

                if ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) > 0) {
                    if (d107VolleyballScoreCard.setNo !== d107VolleyballScoreCard.noOfSets) {
                        this.setState({ selectset: true, setWinnerType: 'by' })
                    }
                    if (d107VolleyballScoreCard.setNo === d107VolleyballScoreCard.noOfSets) {
                        this.setState({ selectset: true, setWinnerType: 'by' })
                    }
                    if (saveSets) {
                        this.setState({ selectset: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        if (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
                            d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.push(d107VolleyballScoreCard.setNo);
                        }
                        else {
                            d107VolleyballScoreCard.byVolleyballScoreCard.setsWon = [1];
                        }
                        d107VolleyballScoreCard.setNo = d107VolleyballScoreCard.setNo + 1;
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                    }
                }
                else {
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                }
            }
            else if ((toAdd[0].challengedByPoints > (winningScore - 1)) && ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) > 0)) {
                // if (d107VolleyballScoreCard.setNo !== d107VolleyballScoreCard.noOfSets) {
                //     this.setState({selectset: true})
                // }
                this.setState({ selectset: true })
                if ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) < 2) {
                    this.setState({ setWinnerType: 'by' })
                    if (saveSets) {
                        this.setState({ selectset: false })
                        saveSets = false;

                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        if (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
                            d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.push(d107VolleyballScoreCard.setNo);
                        }
                        else {
                            d107VolleyballScoreCard.byVolleyballScoreCard.setsWon = [1];
                        }
                        d107VolleyballScoreCard.setNo = d107VolleyballScoreCard.setNo + 1;

                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                    }
                }
            }
            else {
                toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                if (toAdd[0].toUndoList !== null) {
                    toAdd[0].toUndoList.push(undoList);
                }
                else {
                    toAdd[0].toUndoList = [undoList];
                }
                d107VolleyballScoreCard.strokeNo = stroke + 1;
            }
        }
        if (type === 'to') {
            this.setState({ isByPlusDisable: false, isToPlusDisable: true })
            undoList.strokeNo = stroke + 1;
            undoList.type = "challengedTo";
            this.setState({ setWinnerName: this.state.toName, setNumberForWinner: d107VolleyballScoreCard.setNo });
            if (toAdd[0].challengedToPoints === (winningScore - 1)) {
                if ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) > 1) {
                    if (d107VolleyballScoreCard.setNo !== d107VolleyballScoreCard.noOfSets) {
                        this.setState({ selectset: true, setWinnerType: 'to' })
                    }
                    if (d107VolleyballScoreCard.setNo === d107VolleyballScoreCard.noOfSets) {
                        this.setState({ selectset: true, setWinnerType: 'to' })
                    }
                    if (saveSets) {
                        this.setState({ selectset: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        if (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
                            d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.push(d107VolleyballScoreCard.setNo);
                        }
                        else {
                            d107VolleyballScoreCard.toVolleyballScoreCard.setsWon = [1];
                        }
                        d107VolleyballScoreCard.setNo = d107VolleyballScoreCard.setNo + 1;
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    }
                }
                else {
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                }
            }
            else if ((toAdd[0].challengedToPoints > (winningScore - 1)) && ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) > 0)) {

                // if (d107VolleyballScoreCard.setNo !== d107VolleyballScoreCard.noOfSets) {
                //     this.setState({selectset: true})
                // }
                this.setState({ selectset: true })
                if ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) < 2) {
                    this.setState({ setWinnerType: 'to' })
                    if (saveSets) {
                        this.setState({ selectset: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        if (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
                            d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.push(d107VolleyballScoreCard.setNo);
                        }
                        else {
                            d107VolleyballScoreCard.toVolleyballScoreCard.setsWon = [1];
                        }
                        d107VolleyballScoreCard.setNo = d107VolleyballScoreCard.setNo + 1;
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    }
                }

            }
            else {
                if (toAdd[0].toUndoList !== null) {
                    toAdd[0].toUndoList.push(undoList);

                }
                else {
                    toAdd[0].toUndoList = [undoList];
                }
                d107VolleyballScoreCard.strokeNo = stroke + 1;
                toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
            }
        }
        if (dontDo === false) {
            if (saveSets) {

            }
            else {

                // }
                // else {
                // toAdd[0].toUndoList = [undoList];
            }

        }
        else {
            if (toAdd[0].toUndoList !== null) {
                toAdd[0].toUndoList.push(undoList);
            }
            else {
                toAdd[0].toUndoList = [undoList];
            }
            d107VolleyballScoreCard.strokeNo = stroke + 1;
            dontDo = false;
        }



        if (d107VolleyballScoreCard.noOfSets < d107VolleyballScoreCard.setNo) {
            d107VolleyballScoreCard.setNo = (d107VolleyballScoreCard.setNo - 1)
            var byLen, toLen;
            if (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
                byLen = d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length;
            }
            else {
                byLen = 0
            }
            if (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
                toLen = d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length;
            }
            else {
                toLen = 0
            }
            if (byLen > toLen) {
                d107VolleyballScoreCard.byVolleyballScoreCard.winner = true;
                this.setState({ byWinner: true, winner: d107VolleyballScoreCard.byVolleyballScoreCard.teamId })
            }
            else {
                d107VolleyballScoreCard.toVolleyballScoreCard.winner = true;
                this.setState({ toWinner: true, winner: d107VolleyballScoreCard.toVolleyballScoreCard.teamId })

            }
            var score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107VolleyballScoreCard: d107VolleyballScoreCard,
            }
            this.setState({ winnerModalOpen: true })
            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 5000)
            });
            this.setState({ d107VolleyballScoreCard: d107VolleyballScoreCard });
        }
        else {
            if (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon !== null) {
                if ((d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length === (d107VolleyballScoreCard.noOfSets - 1)) ||
                    (d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.length === 3)) {

                    d107VolleyballScoreCard.byVolleyballScoreCard.winner = true;
                    this.setState({ byWinner: true, winner: d107VolleyballScoreCard.byVolleyballScoreCard.teamId })
                    this.setState({ winnerModalOpen: true })
                }
            }
            if (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon !== null) {
                if ((d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length === (d107VolleyballScoreCard.noOfSets - 1)) ||
                    (d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.length === 3)) {
                    d107VolleyballScoreCard.toVolleyballScoreCard.winner = true;
                    this.setState({ toWinner: true, winner: d107VolleyballScoreCard.toVolleyballScoreCard.teamId })
                    this.setState({ winnerModalOpen: true })
                }
            }

            var score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107VolleyballScoreCard: d107VolleyballScoreCard,
            }
            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 500)
            });
            this.setState({ d107VolleyballScoreCard: d107VolleyballScoreCard });
        }
    }

    ToundoList = () => {
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
        var d107VolleyballScoreCard = this.state.d107VolleyballScoreCard;
        if (d107VolleyballScoreCard.byVolleyballScoreCard) {
            d107VolleyballScoreCard.byVolleyballScoreCard.winner = false;
        }
        if (d107VolleyballScoreCard.toVolleyballScoreCard) {
            d107VolleyballScoreCard.toVolleyballScoreCard.winner = false;
        }
        var toAdd = this.state.d107VolleyballScoreCard.rounds.filter(round => {
            return round.position === d107VolleyballScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107VolleyballScoreCard.strokeNo;
        var TypeMode;
        var toUndo;
        var toUndoList = [];
        if (stroke > 0) {
            if (toAdd[0].toUndoList !== null) {
                toUndoList = toAdd[0].toUndoList;
            }
            toUndoList.map(list => {
                if (list.strokeNo === stroke) {
                    TypeMode = list.type;
                    toUndo = list;
                }
            })
            if (TypeMode === "challengedBy") {
                this.setState({ isundoDisable: true })
                if (toAdd[0].challengedByPoints > 0) {
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;
                    stroke -= 1;
                    var setWons = d107VolleyballScoreCard.byVolleyballScoreCard.setsWon;
                    if (setWons != null) {
                        if (setWons.length > 0) {
                            if (setWons.indexOf(d107VolleyballScoreCard.setNo) !== -1)
                                var index = setWons.indexOf(d107VolleyballScoreCard.setNo)

                            if (index > -1)
                                d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.splice(index, 1);

                            if (setWons.length == 0) {
                                setWons = null;
                                d107VolleyballScoreCard.byVolleyballScoreCard.setsWon = setWons;
                            }

                        }
                    }
                }
            }
            else {
                if (toAdd[0].challengedToPoints > 0) {
                    this.setState({ isundoDisable: true })
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;
                    stroke -= 1;
                    var setWons = d107VolleyballScoreCard.toVolleyballScoreCard.setsWon;
                    if (setWons != null) {
                        if (setWons.length > 0) {
                            if (setWons.indexOf(d107VolleyballScoreCard.setNo) !== -1) {
                                var index = setWons.indexOf(d107VolleyballScoreCard.setNo)
                                if (index > -1)
                                    d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.splice(index, 1);
                            }
                            if (setWons.length == 0) {
                                setWons = null;
                                d107VolleyballScoreCard.toVolleyballScoreCard.setsWon = setWons;
                            }
                        }
                    }
                }
            }
            toUndoList = toUndoList.filter((x) => x != toUndo)
            toAdd[0].toUndoList = toUndoList;
            d107VolleyballScoreCard.strokeNo = stroke;
            if (toAdd[0].toUndoList.length === 0) {
                if (d107VolleyballScoreCard.setNo > 1) {
                    d107VolleyballScoreCard.setNo -= 1;
                    d107VolleyballScoreCard.setNo = d107VolleyballScoreCard.setNo;
                    this.setState({ isundoDisable: true })

                    let toAdd = this.state.d107VolleyballScoreCard.rounds.filter(round => {
                        return round.position === d107VolleyballScoreCard.setNo
                    })
                    let toUndoList = [];
                    if (toAdd[0].toUndoList !== null) {
                        toUndoList = toAdd[0].toUndoList;
                    }
                    let TypeMode;
                    let toUndo;
                    toUndoList.map(list => {
                        if (list.strokeNo === stroke) {
                            TypeMode = list.type;
                            toUndo = list;
                        }
                    })
                    let winnerId = null;
                    winnerId = toAdd[0].winnerId;

                    if (TypeMode === 'challengedBy') {
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;

                    } else {
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;
                    }
                    d107VolleyballScoreCard.rounds.map(round => {
                        if (round.position === d107VolleyballScoreCard.setNo) {
                            round.toUndoList.pop();
                            round.winnerId = null;
                        }
                    })

                    if (winnerId === d107VolleyballScoreCard.byVolleyballScoreCard.teamId) {
                        d107VolleyballScoreCard.byVolleyballScoreCard.setsWon.pop();
                    }
                    if (winnerId === d107VolleyballScoreCard.toVolleyballScoreCard.teamId) {
                        d107VolleyballScoreCard.toVolleyballScoreCard.setsWon.pop();
                    }

                }
                if (stroke > 0) {
                    d107VolleyballScoreCard.strokeNo = stroke - 1;
                }
            }
            var score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107VolleyballScoreCard: d107VolleyballScoreCard,
            }
            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isundoDisable: false, })
                }, 500)
            });
            this.setState({ d107VolleyballScoreCard: d107VolleyballScoreCard });
        }
    }
    editWinner = () => {
        this.setState({ selectset: false })
    }

    componentDidMount() {
        console.log(this.props)
        var tt23Matches, formatType = -1;
        const apiUrl = rshApi + "/tournaments/get-tournament";
        const that = this;
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "tournamentId": that.props.tournament.tournamentId,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                console.log(result)
                if (result.data.tt23Matches.finals) {
                    that.setState({ finals: result.data.finals, tournament: result.data.tournament, users: result.data.users, ttType: result.data.tt23Matches.ttType })
                }
                if (result.data.tt23Matches.ttType === 3) {

                }
                if (result.data.tournament.tournamentMode === 'team') {
                    that.setState({ teams: result.data.teams })
                }
                that.setState({ tt23Matches: result.data.tt23Matches })

                if (result.data.tournament.matchType === 'Final') {
                    that.setState({ formatType: that.props.fType })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Quarter Final') {
                    that.setState({ formatType: that.props.fType, quarterFinals: result.data.tt23Matches.quarterFinals })
                    formatType = that.props.fType;
                }
                if (result.data.tournament.matchType === 'Semi final') {
                    that.setState({ formatType: that.props.fType, semiFinals: result.data.tt23Matches.semiFinals })
                    formatType = that.props.fType;
                }
                console.log(that.props)
                pairObj = myUtilclass.getMatchPair(result.data.tournament, result.data.tt23Matches, true, formatType, that.props.matchId);
                //console.log(pairObj)
                that.setState({ matchPairObj: pairObj }, () => {
                    that.getScore();
                });
            });
    }
    selectsetHandel = () => {
        this.setState({ selectset: true })
    }
    saveWinner = () => {
        this.setState({ selectset: false }, () => {
            saveSets = true;
            dontDo = true;
            this.makePoint(this.state.setWinnerType);
        })
    }

    render() {
        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSets); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen1}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3> You Have To Score  <br></br>25 Points  for Win the Game !</h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ winnerModalOpen1: false })} >Ok</button></div>
                </Modal>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    // onAfterOpen={this.afterOpenModal}
                    // onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.bySetWon}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.toSetWon}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>




                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>

                <ModalSet
                    size="md"
                    show={this.state.selectset}
                    onHide={() => this.selectsetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >

                    {/* <div data-backdrop="static" id="setWon" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog"> */}
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Confirmation !</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>

                            </div>
                        </div>
                    </div>
                    {/* </div>
                </div> */}


                </ModalSet>

                {/* <button style={{ display: 'none' }} id="setWonClick" type="button" data-toggle="modal" data-target="#setWon"></button> */}
                <div data-backdrop="static" id="setAttemptModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">No. of Sets</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="numberOfSets" id="3set" onClick={this.changeData.bind(this)} value="3" />
                                            <label htmlFor="3set">3 Sets</label>
                                        </div>
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="numberOfSets" id="5set" onClick={this.changeData.bind(this)} value="5" />
                                            <label htmlFor="5set">5 Sets</label>
                                        </div>
                                        {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                            <span >please Select Set</span>
                                        </div> : ''}
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                    {this.state.errorSet ? <button type="button" className="Setscoresave-d" onClick={() => this.saveIntialSet()}>Save</button> : null}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <button style={{ display: 'none' }} id="openDp3" type="button" data-toggle="modal" data-target="#setAttemptModal"></button>
                <button style={{ display: 'none' }} id="openDp4" type="button" data-toggle="modal" data-target="#winningScoreModal"></button>
                <div data-backdrop="static" id="winningScoreModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Set Winning Score</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="11set" onClick={this.changeData.bind(this)} value="11" />
                                            <label htmlFor="11set">11 Sets</label>
                                        </div>
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="winningScore" id="21set" onClick={this.changeData.bind(this)} value="21" />
                                            <label htmlFor="21set">21 sets</label>
                                        </div>
                                        {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                            <span >please Select Set</span>
                                        </div> : ''}
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveSet()}>Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Tournament Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul class="resultul">


                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <span class="resuserimg" style={{ backgroundImage: "url(" + finallogo + ")" }}></span>
                                            <div class="resinfo">
                                                <label> </label>
                                                <small>Score: </small>
                                            </div>

                                            <div className="styled-input-single" style={{ 'position': 'relative' }}>

                                                <input type="radio" name="selectWinner" id="" defaultChecked={this.state.checked} />
                                                <label htmlFor=""></label>
                                            </div>
                                        </a>
                                    </li>


                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                <a data-dismiss="modal" class="cmnbutton editbtn">Edit</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />



                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}

                                        </tr>
                                    </thead>


                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107VolleyballScoreCard.setNo) {
                                                    showAddButton = true;
                                                }
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints
                                                    }


                                                })

                                                return <td key={index}><input value={points} class="inputsize-d" />
                                                    {showAddButton ?
                                                        this.state.isByPlusDisable ? <button style={{ backgroundColor: 'grey' }} type="button" class="addpointtennis" >+</button> :

                                                            <button onClick={() => this.makePoint('by')} type="button" class="addpointtennis">+</button> : ""}

                                                </td>
                                            })}


                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107VolleyballScoreCard.setNo) {
                                                    showAddButton = true;
                                                }
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }

                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" />
                                                    {showAddButton ?
                                                        this.state.isToPlusDisable ? <button style={{ backgroundColor: 'grey' }} type="button" class="addpointtennis" >+</button> :

                                                            <button onClick={() => this.makePoint('to')} type="button" class="addpointtennis">+</button> : ""}

                                                </td>
                                            })}
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">

                            {this.state.isundoDisable ? <button class="cmnbutton1 backbtn fl" type="button">Undo</button> : <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}  >Undo</button>}

                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

