import React, { Component } from 'react'
import Modal from 'react-modal';
import Modal1 from 'react-modal';
import ModalSet from 'react-bootstrap/Modal'
import Modal3 from 'react-bootstrap/Modal'
import ConfigUrl from '..//..//ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import DemoUpdateChallengeScore from '../DemoUpdateChallengeScore';
import finallogo from '..//..//img/finallogo.png';
import broadcastbg from '..//..//img/broadcastbg.png';
import play from '..//..//img/play.png';
import male from "..//..//img/male.png";
import teamIcons from '..//..//img/team.png';
import $ from 'jquery';
import logo from '..//..//img/finallogo.png';
var score;
let saveSets = false;
let dontDo = false;
const challengeAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
let byPic, toPic;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class DemoUpdate_d107squash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            byWinner: false,
            toWinner: false,
            setWinner: false,
            selectset: false,
            score: false,
            isByPlusDisable: false,
            isToPlusDisable: false,
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107SquashScoreCard: {},
        };
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);

    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {
        var d107SquashScoreCard = this.state.d107SquashScoreCard;

        if (this.state.byWinner) {

            d107SquashScoreCard.bySquashScoreCard.winner = true;
            d107SquashScoreCard.toSquashScoreCard.winner = false;

        }
        else {

            d107SquashScoreCard.toSquashScoreCard.winner = true;
            d107SquashScoreCard.bySquashScoreCard.winner = false;
        }
        score = {
            challengeId: this.props.challenge.challengeId,
            matchId: this.props.challenge.challengeId,
            d107SquashScoreCard: d107SquashScoreCard,
        }
        DemoUpdateChallengeScore(score);
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.challengeAction = challengeAction;
        challenge.modifiedOn = timpstampData;
        challenge.challengeWinnerId = this.state.winner;
        let that = this;
        const apiUrl = rshApi + "/demo-challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.setState({ winnerModalOpen: false });
                window.location = "/dchallengeprofile/index.html?id=" + that.props.challenge.challengeId;

            });
    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }

    backState = () => {
        this.setState({ selectset: false })
        // $("#setAttemptModal .close").click();

        window.location = "/dchallengeprofile/index.html?id=" + this.props.challenge.challengeId;
    }
    saveIntialSet = () => {
        this.setState({ selectset: false })
        if (this.state.numberOfSets !== null) {
            var i = 0;
            var numberOfSets = parseInt(this.state.numberOfSets);

            var header = [{
                tableHeader: true,
                position: 0,
            }];
            var round = [];
            for (i = 1; i <= numberOfSets; i++) {
                var roundData = {
                    position: i,
                    tableHeader: false,
                    challengedById: this.props.challenge.accepted[0],
                    challengedByPoints: 0,
                    challengedToId: this.props.challenge.accepted[1],
                    challengedToPoints: 0,
                    toUndoList: null,
                    winnerId: null
                }

                round.push(roundData);
            }
            var rounds = header.concat(round);
            const that = this;
            var d107ScoreCard = {
                noOfSets: numberOfSets,
                strokeNo: 0,

                setNo: 1,
                bySquashScoreCard: {
                    id: that.props.challenge.accepted[0],
                    setsWon: null,
                    winner: false
                },
                toSquashScoreCard: {
                    id: that.props.challenge.accepted[1],
                    setsWon: null,
                    winner: false
                },
                rounds
            }
            score = {
                challengeId: that.props.challenge.challengeId,
                matchId: that.props.challenge.challengeId,
                d107SquashScoreCard: d107ScoreCard,
            }
            that.setState({ rounds: rounds, score: true, d107SquashScoreCard: d107ScoreCard });
            DemoUpdateChallengeScore(score);

        }
        else {
            this.setState({ setError: true });
        }
        //  this.getScore();

    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }
    selectsetHandel = () => {
        this.setState({ selectset: true })
    }
    setWinnerHandel = () => {
        this.setState({ setWinner: true })
    }
    endEvent() {
        this.setState({ winnerModalOpen1: true })

    }
    getScore() {
        if (this.props.challenge.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-challenges/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "challengeId": that.props.challenge.challengeId,
                        "matchId": that.props.challenge.challengeId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    if (result.data.score === null) {
                        let byName; let toName;
                        that.setState({ selectset: true })
                        that.props.profileUsers.map(user => {
                            if (that.props.challenge.accepted[0] === user.contactNo) {
                                byName = user.name;
                                byPic = user.profilePic ? user.profilePic : male;
                            }
                            if (that.props.challenge.accepted[1] === user.contactNo) {
                                toName = user.name;
                                toPic = user.profilePic ? user.profilePic : male;
                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        that.props.profileTeams.map(user => {
                            if (that.props.challenge.accepted[0] === user.teamId) {
                                byName = user.teamName;
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.props.challenge.accepted[1] === user.teamId) {
                                toName = user.teamName;
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;

                            }
                        })
                        that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                    }
                    else {

                        if (result.data.score.d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                            that.setState({ bySetWon: result.data.score.d107SquashScoreCard.bySquashScoreCard.setsWon.length, byWinner: result.data.score.d107SquashScoreCard.bySquashScoreCard.winner });

                        }
                        if (result.data.score.d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                            that.setState({ toSetWon: result.data.score.d107SquashScoreCard.toSquashScoreCard.setsWon.length, toWinner: result.data.score.d107SquashScoreCard.toSquashScoreCard.winner });

                        }
                        that.setState({ d107SquashScoreCard: result.data.score.d107SquashScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107SquashScoreCard.rounds, numberOfSets: result.data.score.d107SquashScoreCard.noOfSets, setNo: result.data.score.d107SquashScoreCard.setNo, strokeNo: result.data.score.d107SquashScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107SquashScoreCard.bySquashScoreCard.id === user.contactNo) {
                                    byName = user.name
                                    byPic = user.profilePic ? user.profilePic : male;
                                }
                                if (result.data.score.d107SquashScoreCard.toSquashScoreCard.id === user.contactNo) {
                                    toName = user.name
                                    toPic = user.profilePic ? user.profilePic : male;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            result.data.teams.map(user => {
                                if (result.data.score.d107SquashScoreCard.bySquashScoreCard.id === user.teamId) {
                                    byName = user.name
                                    byPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                                if (result.data.score.d107SquashScoreCard.toSquashScoreCard.id === user.teamId) {
                                    toName = user.name
                                    toPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                            if (result.data.score.d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                                this.setState({ bySetWon: result.data.score.d107SquashScoreCard.bySquashScoreCard.setsWon.length });
                            }
                            if (result.data.score.d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                                this.setState({ toSetWon: result.data.score.d107SquashScoreCard.toSquashScoreCard.setsWon.length });
                            }
                        }


                    }

                });
        }
    }
    makePoint(type) {
        this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
        var d107SquashScoreCard = this.state.d107SquashScoreCard;
        var toAdd = this.state.d107SquashScoreCard.rounds.filter(round => {
            return round.position === d107SquashScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107SquashScoreCard.strokeNo;
        var undoList = {
            points: 1,
            strokeNo: 1,
            type: "challengedBy",
        }
        if (type === 'by') {
            this.setState({ isByPlusDisable: true, isToPlusDisable: false, })
            undoList.strokeNo = stroke + 1;
            undoList.type = "challengedBy";
            this.setState({ setWinnerName: this.state.byName, setNumberForWinner: d107SquashScoreCard.setNo });
            if (toAdd[0].challengedByPoints === (winningScore - 1)) {

                if ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) > 0) {
                    if (d107SquashScoreCard.setNo !== d107SquashScoreCard.noOfSets) {
                        this.setState({ setWinner: true, setWinnerType: 'by' })
                    }
                    if (d107SquashScoreCard.setNo === d107SquashScoreCard.noOfSets) {
                        this.setState({ setWinner: true, setWinnerType: 'by' })
                    }
                    if (saveSets) {

                        this.setState({ setWinner: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                            d107SquashScoreCard.bySquashScoreCard.setsWon.push(d107SquashScoreCard.setNo);
                        }
                        else {
                            d107SquashScoreCard.bySquashScoreCard.setsWon = [1];
                        }
                        d107SquashScoreCard.setNo = d107SquashScoreCard.setNo + 1;
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                    }
                }
                else {
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                }
            }
            else if ((toAdd[0].challengedByPoints > (winningScore - 1)) && ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) > 0)) {

                // if (d107SquashScoreCard.setNo !== d107SquashScoreCard.noOfSets) {
                //     this.setState({ setWinner: true })
                // }
                this.setState({ setWinner: true })
                if ((toAdd[0].challengedByPoints - toAdd[0].challengedToPoints) < 2) {
                    this.setState({ setWinnerType: 'by' })
                    if (saveSets) {
                        this.setState({ setWinner: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                            d107SquashScoreCard.bySquashScoreCard.setsWon.push(d107SquashScoreCard.setNo);
                        }
                        else {
                            d107SquashScoreCard.bySquashScoreCard.setsWon = [1];
                        }
                        d107SquashScoreCard.setNo = d107SquashScoreCard.setNo + 1;

                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                    }
                }
            }
            else {
                toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                if (toAdd[0].toUndoList !== null) {
                    toAdd[0].toUndoList.push(undoList);
                }
                else {
                    toAdd[0].toUndoList = [undoList];
                }
                d107SquashScoreCard.strokeNo = stroke + 1;
            }
        }
        if (type === 'to') {
            this.setState({ isByPlusDisable: false, isToPlusDisable: true })
            undoList.strokeNo = stroke + 1;
            undoList.type = "challengedTo";
            this.setState({ setWinnerName: this.state.toName, setNumberForWinner: d107SquashScoreCard.setNo });
            if (toAdd[0].challengedToPoints === (winningScore - 1)) {
                if ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) > 1) {
                    if (d107SquashScoreCard.setNo !== d107SquashScoreCard.noOfSets) {
                        this.setState({ setWinner: true, setWinnerType: 'to' })
                    }
                    if (d107SquashScoreCard.setNo === d107SquashScoreCard.noOfSets) {
                        this.setState({ setWinner: true, setWinnerType: 'to' })
                    }
                    if (saveSets) {
                        this.setState({ setWinner: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                            d107SquashScoreCard.toSquashScoreCard.setsWon.push(d107SquashScoreCard.setNo);
                        }
                        else {
                            d107SquashScoreCard.toSquashScoreCard.setsWon = [1];
                        }
                        d107SquashScoreCard.setNo = d107SquashScoreCard.setNo + 1;
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    }
                }
                else {
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                }
            }
            else if ((toAdd[0].challengedToPoints > (winningScore - 1)) && ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) > 0)) {

                // if (d107SquashScoreCard.setNo !== d107SquashScoreCard.noOfSets) {
                //     this.setState({ setWinner: true })
                // }
                this.setState({ setWinner: true })
                if ((toAdd[0].challengedToPoints - toAdd[0].challengedByPoints) < 2) {
                    this.setState({ setWinnerType: 'to' })
                    if (saveSets) {
                        this.setState({ setWinner: false })
                        saveSets = false;
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                            d107SquashScoreCard.toSquashScoreCard.setsWon.push(d107SquashScoreCard.setNo);
                        }
                        else {
                            d107SquashScoreCard.toSquashScoreCard.setsWon = [1];
                        }
                        d107SquashScoreCard.setNo = d107SquashScoreCard.setNo + 1;
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    }
                }
            }
            else {
                if (toAdd[0].toUndoList !== null) {
                    toAdd[0].toUndoList.push(undoList);
                }
                else {
                    toAdd[0].toUndoList = [undoList];
                }
                d107SquashScoreCard.strokeNo = stroke + 1;
                toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
            }
        }

        if (dontDo === false) {
            if (saveSets) {

            }
            else {
                //     toAdd[0].toUndoList.push(undoList);

                // }
                // else {
                // toAdd[0].toUndoList = [undoList];
                // }
            }
        }
        else {
            if (toAdd[0].toUndoList !== null) {
                toAdd[0].toUndoList.push(undoList);
            }
            else {
                toAdd[0].toUndoList = [undoList];
            }
            d107SquashScoreCard.strokeNo = stroke + 1;
            dontDo = false;
        }


        if (d107SquashScoreCard.noOfSets < d107SquashScoreCard.setNo) {
            d107SquashScoreCard.setNo = (d107SquashScoreCard.setNo - 1)
            var byLen, toLen;
            if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                byLen = d107SquashScoreCard.bySquashScoreCard.setsWon.length;
            }
            else {
                byLen = 0
            }
            if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                toLen = d107SquashScoreCard.toSquashScoreCard.setsWon.length;
            }
            else {
                toLen = 0
            }
            if (byLen > toLen) {
                d107SquashScoreCard.bySquashScoreCard.winner = true;
                this.setState({ byWinner: true, winner: d107SquashScoreCard.bySquashScoreCard.id })
            }
            else {
                d107SquashScoreCard.toSquashScoreCard.winner = true;
                this.setState({ toWinner: true, winner: d107SquashScoreCard.toSquashScoreCard.id })
            }
            if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                this.setState({ bySetWon: d107SquashScoreCard.bySquashScoreCard.setsWon.length });
            }
            if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                this.setState({ toSetWon: d107SquashScoreCard.toSquashScoreCard.setsWon.length });
            }

            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107SquashScoreCard: d107SquashScoreCard,
            }

            this.setState({ winnerModalOpen: true })
            DemoUpdateChallengeScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 500)
            });
            this.setState({ d107SquashScoreCard: d107SquashScoreCard });
        }
        else {




            if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {

                if ((d107SquashScoreCard.bySquashScoreCard.setsWon.length === (d107SquashScoreCard.noOfSets - 1)) ||
                    (d107SquashScoreCard.bySquashScoreCard.setsWon.length === 3)) {

                    d107SquashScoreCard.bySquashScoreCard.winner = true;
                    this.setState({ byWinner: true, winner: d107SquashScoreCard.bySquashScoreCard.id })
                    this.setState({ winnerModalOpen: true })
                }
            }
            if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                if ((d107SquashScoreCard.toSquashScoreCard.setsWon.length === (d107SquashScoreCard.noOfSets - 1)) ||
                    (d107SquashScoreCard.toSquashScoreCard.setsWon.length === 3)) {

                    d107SquashScoreCard.toSquashScoreCard.winner = true;
                    this.setState({ toWinner: true, winner: d107SquashScoreCard.toSquashScoreCard.id })
                    this.setState({ winnerModalOpen: true })
                }
            }
            if (d107SquashScoreCard.bySquashScoreCard.setsWon !== null) {
                this.setState({ bySetWon: d107SquashScoreCard.bySquashScoreCard.setsWon.length });

            }
            if (d107SquashScoreCard.toSquashScoreCard.setsWon !== null) {
                this.setState({ toSetWon: d107SquashScoreCard.toSquashScoreCard.setsWon.length });

            }

            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107SquashScoreCard: d107SquashScoreCard,
            }

            DemoUpdateChallengeScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 500)
            });

            this.setState({ d107SquashScoreCard: d107SquashScoreCard });
        }
    }

    ToundoList = () => {
        this.setState({
            winnerModalOpen: false, byWinner: false,
            toWinner: false,
        });
        this.setState({ winnerModalOpen: false });
        var d107SquashScoreCard = this.state.d107SquashScoreCard;

        var toAdd = this.state.d107SquashScoreCard.rounds.filter(round => {
            return round.position === d107SquashScoreCard.setNo
        })
        var toRemove = [...toAdd];
        var stroke = d107SquashScoreCard.strokeNo;
        var TypeMode;
        var toUndo;
        var toUndoList = [];
        if (stroke > 0) {
            if (toAdd[0].toUndoList !== null) {
                toUndoList = toAdd[0].toUndoList;
            }
            toUndoList.map(list => {
                if (list.strokeNo === stroke) {
                    TypeMode = list.type;
                    toUndo = list;
                }
            })
            if (TypeMode === "challengedBy") {
                if (toAdd[0].challengedByPoints > 0) {
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;
                    stroke -= 1;
                    var setWons = d107SquashScoreCard.bySquashScoreCard.setsWon;
                    if (setWons != null) {
                        if (setWons.length > 0) {
                            if (setWons.indexOf(d107SquashScoreCard.setNo) !== -1)
                                var index = setWons.indexOf(d107SquashScoreCard.setNo)

                            if (index > -1)
                                d107SquashScoreCard.bySquashScoreCard.setsWon.splice(index, 1);

                            if (setWons.length == 0) {
                                setWons = null;
                                d107SquashScoreCard.bySquashScoreCard.setsWon = setWons;
                            }

                        }
                    }
                }
            }
            else {
                if (toAdd[0].challengedToPoints > 0) {
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;
                    stroke -= 1;
                    var setWons = d107SquashScoreCard.toSquashScoreCard.setsWon;
                    if (setWons != null) {
                        if (setWons.length > 0) {
                            if (setWons.indexOf(d107SquashScoreCard.setNo) !== -1) {
                                var index = setWons.indexOf(d107SquashScoreCard.setNo)
                                if (index > -1)
                                    d107SquashScoreCard.toSquashScoreCard.setsWon.splice(index, 1);
                            }
                            if (setWons.length == 0) {
                                setWons = null;
                                d107SquashScoreCard.toSquashScoreCard.setsWon = setWons;
                            }
                        }
                    }
                }
            }
            toUndoList = toUndoList.filter((x) => x != toUndo)
            toAdd[0].toUndoList = toUndoList;
            d107SquashScoreCard.strokeNo = stroke;
            if (toAdd[0].toUndoList.length === 0) {
                if (d107SquashScoreCard.setNo > 1) {
                    d107SquashScoreCard.setNo -= 1;
                    d107SquashScoreCard.setNo = d107SquashScoreCard.setNo;
                    let toAdd = this.state.d107SquashScoreCard.rounds.filter(round => {
                        return round.position === d107SquashScoreCard.setNo
                    })
                    let toUndoList = [];
                    if (toAdd[0].toUndoList !== null) {
                        toUndoList = toAdd[0].toUndoList;
                    }
                    let TypeMode;
                    let toUndo;
                    toUndoList.map(list => {
                        if (list.strokeNo === stroke) {
                            TypeMode = list.type;
                            toUndo = list;
                        }
                    })
                    let winnerId = null;
                    winnerId = toAdd[0].winnerId;

                    if (TypeMode === 'challengedBy') {
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;

                    } else {
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;
                    }
                    d107SquashScoreCard.rounds.map(round => {
                        if (round.position === d107SquashScoreCard.setNo) {
                            round.toUndoList.pop();
                            round.winnerId = null;
                        }
                    })
                    if (winnerId === d107SquashScoreCard.bySquashScoreCard.id) {
                        d107SquashScoreCard.bySquashScoreCard.setsWon.pop();
                    }
                    if (winnerId === d107SquashScoreCard.toSquashScoreCard.id) {
                        d107SquashScoreCard.toSquashScoreCard.setsWon.pop();
                    }
                }
                if (stroke > 0) {
                    d107SquashScoreCard.strokeNo = stroke - 1;
                }
            }
            var score = {
                challengeId: this.props.challenge.challengeId,
                matchId: this.props.challenge.challengeId,
                d107SquashScoreCard: d107SquashScoreCard,
            }


            DemoUpdateChallengeScore(score);
            this.setState({ d107SquashScoreCard: d107SquashScoreCard });
            this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })

        }
    }
    editWinner = () => {
        this.setState({ setWinner: false })
        //  this.ToundoList();
    }


    saveWinner = () => {
        this.setState({ setWinner: false }, () => {
            saveSets = true;
            dontDo = true;
            this.makePoint(this.state.setWinnerType);
        })
    }

    componentDidMount() {
        this.getScore();
    }

    render() {

        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSets); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        return (
            <React.Fragment>
                <Modal1
                    isOpen={this.state.winnerModalOpen1}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                    animation={false}
                >

                    <div ref={subtitle => this.subtitle = subtitle}> <h3> Atleast You Have To Score minimun <br></br>11 Points  for Win the Game !</h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ winnerModalOpen1: false })} >Ok</button></div>
                </Modal1>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>player/Teams</th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}><br></br>score-{this.state.bySetWon}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} ><br></br>Score-{this.state.toSetWon}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <Modal3
                    size="md"
                    show={this.state.setWinner}
                    onHide={() => this.setWinnerHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    {/* <div data-backdrop="static" id="setWon" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog"> */}
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">Confirmation !</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>

                            </div>
                        </div>
                    </div>
                    {/* </div>
                </div> */}
                </Modal3>
                <ModalSet
                    size="md"
                    show={this.state.selectset}
                    onHide={() => this.selectsetHandel()}
                    animation={false}
                    aria-labelledby="example-modal-sizes-title-md"
                >
                    <div className="modal-content carrominputdiv-d">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title">No. of Sets</h4>
                        </div>

                        <div className="modal-body modal-bodyD">
                            <div className="modaldiv-d">
                                <div className="setspopup-d">
                                    <div className="styled-input-single-d">
                                        <input type="radio" name="numberOfSets" id="3set" onClick={this.changeData.bind(this)} value="3" />
                                        <label htmlFor="3set">3 Sets</label>
                                    </div>
                                    <div className="styled-input-single-d">
                                        <input type="radio" name="numberOfSets" id="5set" onClick={this.changeData.bind(this)} value="5" />
                                        <label htmlFor="5set">5 Sets</label>
                                    </div>
                                    {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                        <span >please Select Set</span>
                                    </div> : ''}
                                </div>
                                <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                <button type="button" className="Setscoresave-d" onClick={() => this.saveIntialSet()}>Save</button>

                            </div>
                        </div>
                    </div>
                </ModalSet>
                <div id="myModal2" class="modal fade reslutmodel" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h4 class="modal-title">Challange Winner</h4>
                            </div>
                            <div class="modal-body">
                                <div class="resultlogo">
                                    <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                    <label>Winner</label>
                                </div>

                                <ul class="resultul">


                                    return <li style={{ 'display': 'flex' }}>
                                        <a class="winnerselect">
                                            <span class="resuserimg" style={{ backgroundImage: "url(" + finallogo + ")" }}></span>
                                            <div class="resinfo">
                                                <label> </label>
                                                <small>Score: </small>
                                            </div>

                                            <div className="styled-input-single" style={{ 'position': 'relative' }}>

                                                <input type="radio" name="selectWinner" id="" defaultChecked={this.state.checked} />
                                                <label htmlFor=""></label>
                                            </div>
                                        </a>
                                    </li>


                                </ul>

                            </div>

                            <div class="modal-footer table_actions">
                                <a data-dismiss="modal" class="cmnbutton editbtn">Edit</a>
                                <button class="cmnbutton btnsubmitright" type="button" onClick={() => this.doneMatch()} >Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
                <input style={{ display: 'none' }} id="myModalOpen1" data-toggle="modal" data-target="#myModal2" />



                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}

                                        </tr>
                                    </thead>


                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107SquashScoreCard.setNo) {
                                                    showAddButton = true;
                                                }
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints
                                                    }


                                                })

                                                return <td key={index}><input value={points} class="inputsize-d" />
                                                    {showAddButton ?
                                                        this.state.isByPlusDisable ? <button style={{ backgroundColor: 'grey' }} type="button" class="addpointtennis" >+</button> : <button onClick={() => this.makePoint('by')} type="button" class="addpointtennis" >+</button>
                                                        : ""}

                                                </td>
                                            })}


                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                let showAddButton = false;
                                                if ((index + 1) == this.state.d107SquashScoreCard.setNo) {
                                                    showAddButton = true;
                                                }
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }

                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" />
                                                    {showAddButton ?
                                                        this.state.isToPlusDisable ? <button style={{ backgroundColor: 'grey' }} type="button" class="addpointtennis" >+</button> : <button onClick={() => this.makePoint('to')} type="button" class="addpointtennis" >+</button>
                                                        : ""}

                                                </td>
                                            })}
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
