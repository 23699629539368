import React, { Component } from 'react'
import { trackPromise } from "react-promise-tracker";
import $ from "jquery";
import ConfigUrl from "../ConfigUrl";
const ConfigApiUrl = ConfigUrl("apiUrl");
const rshApi = ConfigApiUrl;
export default class RoundSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultChecked: true,
            RoundNumber: 1,
        }
    }
    changeData(event) {
        if (event.target.checked === true) {
            this.setState({ RoundNumber: event.target.value });
        }
    }
    saveRound() {
        var moment = require('moment');
        var now = moment().format();
        var timpstampData = new Date(now).valueOf();
        var challenge = this.props.challenge;
        delete challenge.id;
        challenge.courseRound = this.state.RoundNumber;
        challenge.modifiedOn = timpstampData;

        let that = this;
        const apiUrl = rshApi + "challenges/update-challenge";
        fetch(apiUrl, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "data": {
                    "challenge": challenge,
                }
            })
        })
            .then(function (response) {
                return trackPromise(response.json());
            })
            .then(function (result) {
                that.props.BaseRoundCall(challenge);
            });
    }
    render() {
        return (
            <div className="commanbox">
                <h3 className="commanhead">Select Round</h3>
                <div className="createteamform ">
                    <ul className="createbroadcast_ul createchallange">


                        <li className="cc_box">
                            <div className="cc_inner_box">
                                <label className="cc_box-head">Rounds</label>
                                <div className="commaninputdiv createredio ">
                                    <div className="styled-input-single createinput">
                                        <input type="radio" name="fieldset-5" value={1} onClick={this.changeData.bind(this)} id="checkbox-example-1" defaultChecked={this.state.defaultChecked} />
                                        <label for="checkbox-example-1">1 Round </label>
                                    </div>
                                    <div className="styled-input-single createinput">
                                        <input type="radio" name="fieldset-5" value={2} onClick={this.changeData.bind(this)} id="checkbox-example-2" />
                                        <label for="checkbox-example-2">2 Round </label>
                                    </div>
                                    <div className="styled-input-single createinput">
                                        <input type="radio" name="fieldset-5" value={3} onClick={this.changeData.bind(this)} id="checkbox-example-3" />
                                        <label for="checkbox-example-3">3 Round </label>
                                    </div>
                                    <div className="styled-input-single createinput">
                                        <input type="radio" name="fieldset-5" value={4} onClick={this.changeData.bind(this)} id="checkbox-example-4" />
                                        <label for="checkbox-example-4">4 Round </label>
                                    </div>
                                </div>
                            </div>
                        </li>


                        <li className="createbroadcast_ul">
                            <button type="button" onClick={() => this.saveRound()} className="cmnbutton btncroadcast">Save Round</button>
                        </li>
                    </ul>
                </div>
                <div id="courseSuccess" style={{ display: 'none', margin: '1px', fontSize: '12px' }} classNameName="alert alert-success" role="alert">
                    <strong>Course Created Succesfully </strong>
                </div>
                <div id="courseError" style={{ display: 'none', margin: '1px', fontSize: '12px' }} classNameName="alert alert-danger" role="alert">
                    <strong>please fill Distance or Par value </strong>
                </div>
            </div>
        )
    }
}


