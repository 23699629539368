import React, { Component } from 'react'
import Modal from 'react-modal';
import ConfigUrl from '../ConfigUrl';
import { trackPromise } from "react-promise-tracker";
import UpdateTournamentsScore from '../UpdateTournamentsScore';
import finallogo from '../img/finallogo.png';
import broadcastbg from '../img/broadcastbg.png';
import teamIcons from '../img/team.png';
import play from '../img/play.png';
import $ from 'jquery';
import logo from '../img/finallogo.png';
import male from "../img/male.png";
import female from "../img/female.png";
import searchnew from "../img/searchnew.png";
import * as myUtilclass from '../FixtureUtil';
import * as myTournamentUtilclass from '../TournamentsUtil';
var selectedWinners = undefined;
let pairObj;
var score;
let byPic, toPic;
var d107KabbadiScoreCard = {};
const tournamentAction = "ended";
const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
var selectedWinner;
const winningScore = 11;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
export default class Tournament_kabaddi_tt1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107KabbadiScoreCard: {},
            byMemberModalOpen: false,
            byMemberModalOpen: false,
            byteamIds: '',
            byteamIds: '',
            score: false,
            bypoints: '',
            topoints: '',
            byPlayerScores: [],
            toPlayerScores: [],
            tieWinner: false,
            selectWinnerModal: false,
            byWinner: false,
            toWinner: false,
            tournament: null,
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},
            selectWinnerModal1: false,
            isByPlusDisable: false,
            isToPlusDisable: false,
            isundoDisable: false,
        };
        this.closeModal = this.closeModal.bind(this);
        this.byMemberCloseModal = this.byMemberCloseModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);
        this.getScore = this.getScore.bind(this);
        this.inputPoint = this.inputPoint.bind(this);
    }
    selectWinner(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            this.setState({ selectWinner: selectedValue });
        }
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }

    closeModal() {
        this.setState({ winnerModalOpen: false });
        this.setState({ selectWinnerModal1: false });
    }

    doneMatch() {
        var winner;
        if (selectedWinners !== undefined) {
            winner = selectedWinners;
        }
        if (this.state.winners !== undefined) {
            winner = this.state.winners;
        }


        let d107KabbadiScoreCard = this.state.d107KabbadiScoreCard;
        if (this.state.byWinner) {
            d107KabbadiScoreCard.byKabaddiScoreCard.winner = true;
            d107KabbadiScoreCard.toKabaddiScoreCard.winner = false;
        }
        if (this.state.toWinner) {
            d107KabbadiScoreCard.toKabaddiScoreCard.winner = true;
            d107KabbadiScoreCard.byKabaddiScoreCard.winner = false;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107KabbadiScoreCard: d107KabbadiScoreCard,
        }
        //    UpdateTournamentsScore(score);
        console.log(pairObj)
        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }

        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/tournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }

    }

    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, [event.target.name]: event.target.value });
        }
    }
    backState = () => {
        $("#setAttemptModal .close").click();

        window.location = "/tournamentprofile/index.html?id=" + this.props.tournament.tournamentId;
    }
    inputPoint(event) {
        this.setState({ playerPoint: event.target.value });
    }

    endEvent() {
        let byTotal = this.state.d107KabbadiScoreCard.byKabaddiScoreCard.points ? this.state.d107KabbadiScoreCard.byKabaddiScoreCard.points : 0;
        let toTotal = this.state.d107KabbadiScoreCard.toKabaddiScoreCard.points ? this.state.d107KabbadiScoreCard.toKabaddiScoreCard.points : 0;

        if (byTotal > toTotal) {
            this.setState({ byWinner: true, winner: this.state.matchPairObj.firstId })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal > byTotal) {
            this.setState({ toWinner: true, winner: this.state.matchPairObj.secondId })
            this.setState({ winnerModalOpen: true })
        }
        if (toTotal === byTotal) {
            if (parseInt(toTotal) > 0 || parseInt(byTotal) > 0) {
                this.setState({ selectWinnerModal: true })
            }
            else {
                this.setState({ selectWinnerModal1: true })
            }

        }
    }

    saveIntialSet = () => {
        if (this.props.tt1.fixtureType1) {
            myUtilclass.getMatchPair(this.props.tt1, null, null, true, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            myUtilclass.getMatchPair(this.props.tt1, poolNo, null, true, this.props.matchId);
        }
    }

    getScore() {
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);
                    if (result.data.score === null) {
                        that.saveIntialSet();
                    }

                    if (result.data.score === null) {
                        let byName; let toName;

                        d107KabbadiScoreCard = {
                            "byKabaddiScoreCard": {
                                "points": 0,
                                "teamId": that.state.matchPairObj.firstId,
                                "winner": false,
                                "toUndos": null
                            },
                            "toKabaddiScoreCard": {
                                "points": 0,
                                "teamId": that.state.matchPairObj.secondId,
                                "winner": false,
                                "toUndos": null
                            },
                            "strokeNo": 0
                        }

                        var score = {
                            tournamentId: that.props.tournament.tournamentId,
                            matchId: that.props.matchId,
                            d107KabbadiScoreCard: d107KabbadiScoreCard,
                        }
                        that.setState({ d107KabbadiScoreCard: d107KabbadiScoreCard });

                        //         UpdateTournamentsScore(score);

                        that.props.profileTeams.map(user => {
                            if (that.state.matchPairObj.firstId === user.teamId) {
                                byName = user.teamName
                                byPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                            if (that.state.matchPairObj.secondId === user.teamId) {
                                toName = user.teamName
                                toPic = user.teamIcon ? user.teamIcon : teamIcons;
                            }
                        })
                        that.setState({ byName: byName, toName: toName, score: false });
                        //$('#openDp3').click();

                    }
                    else {
                        if (result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard !== null) {
                            that.setState({ bypoints: result.data.score.d107KabbadiScoreCard.byKabaddiScoreCard.points });
                        }

                        if (result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard !== null) {
                            that.setState({ topoints: result.data.score.d107KabbadiScoreCard.toKabaddiScoreCard.points });
                        }

                        that.setState({
                            score: true, d107KabbadiScoreCard: result.data.score.d107KabbadiScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams,
                            // strokeNo: result.data.score.d107KabbadiScoreCard.strokeNo
                        });

                        let byName; let toName;

                        if (result.data.mode === 'buddy') {

                        }
                        else {
                            that.props.profileTeams.map(user => {
                                if (that.state.matchPairObj.firstId === user.teamId) {
                                    byName = user.teamName
                                    byPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                                if (that.state.matchPairObj.secondId === user.teamId) {
                                    toName = user.teamName
                                    toPic = user.teamIcon ? user.teamIcon : teamIcons;
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                    }
                });
        }
    }

    selectPlayerPoint(type, val) {
        if (this.state.score) {
            if (type === 'challengedBy') {
                this.setState({ isByPlusDisable: true, isToPlusDisable: false, })
                var pointValue = val;
                var d107 = { ...this.state.d107KabbadiScoreCard };
                if (d107.byKabaddiScoreCard !== null) {
                    if (d107.byKabaddiScoreCard.toUndos !== null) {
                        var byUndoScoresList = [];
                        byUndoScoresList = d107.byKabaddiScoreCard.toUndos;
                        var pscoreObj = {
                            strokeNo: d107.strokeNo + 1,
                            points: pointValue,
                            type: type,
                        }
                        d107.strokeNo = d107.strokeNo + 1;
                        byUndoScoresList.push(pscoreObj);
                        d107.byKabaddiScoreCard.toUndos = byUndoScoresList;
                        d107.byKabaddiScoreCard.points = d107.byKabaddiScoreCard.points + pointValue;
                        let teamGoal = d107.byKabaddiScoreCard.points;
                        this.setState({ bypoints: teamGoal });
                    }
                    else {
                        var byUndoScoresList = [];
                        var pscoreObj = {
                            strokeNo: d107.strokeNo + 1,
                            points: pointValue,
                            type: type,
                        }
                        d107.strokeNo = d107.strokeNo + 1;
                        byUndoScoresList.push(pscoreObj);
                        d107.byKabaddiScoreCard.toUndos = byUndoScoresList;
                        d107.byKabaddiScoreCard.points = d107.byKabaddiScoreCard.points + pointValue;
                        let teamGoal = d107.byKabaddiScoreCard.points;
                        this.setState({ bypoints: teamGoal });
                    }
                }
                else {
                    let byKab = {
                        points: null,
                        teamId: this.state.matchPairObj.firstId,
                        toUndos: null,
                        winner: false
                    }
                    d107.byKabaddiScoreCard = byKab;
                    var byUndoScoresList = [];
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    byUndoScoresList.push(pscoreObj);
                    d107.strokeNo = d107.strokeNo + 1;
                    d107.byKabaddiScoreCard.toUndos = byUndoScoresList;
                    d107.byKabaddiScoreCard.points = pointValue;
                    d107.byKabaddiScoreCard.teamId = this.state.matchPairObj.firstId;
                    d107.byKabaddiScoreCard.winner = false;
                    let teamGoal = pointValue;
                    this.setState({ bypoints: teamGoal });
                }
            }

            if (type === 'challengedTo') {
                this.setState({ isByPlusDisable: false, isToPlusDisable: true })
                var pointValue = val;
                var d107 = { ...this.state.d107KabbadiScoreCard };
                if (d107.toKabaddiScoreCard !== null) {
                    if (d107.toKabaddiScoreCard.toUndos !== null) {
                        var toUndoScoresList = [];
                        toUndoScoresList = d107.toKabaddiScoreCard.toUndos;
                        var pscoreObj = {
                            strokeNo: d107.strokeNo + 1,
                            points: pointValue,
                            type: type,
                        }
                        d107.strokeNo = d107.strokeNo + 1;
                        toUndoScoresList.push(pscoreObj);
                        d107.toKabaddiScoreCard.toUndos = toUndoScoresList;
                        d107.toKabaddiScoreCard.points = d107.toKabaddiScoreCard.points + pointValue;
                        let teamGoal = d107.toKabaddiScoreCard.points;
                        this.setState({ topoints: teamGoal });
                    }
                    else {
                        var toUndoScoresList = [];
                        var pscoreObj = {
                            strokeNo: d107.strokeNo + 1,
                            points: pointValue,
                            type: type,
                        }
                        d107.strokeNo = d107.strokeNo + 1;
                        toUndoScoresList.push(pscoreObj);
                        d107.toKabaddiScoreCard.toUndos = toUndoScoresList;
                        d107.toKabaddiScoreCard.points = d107.toKabaddiScoreCard.points + pointValue;
                        let teamGoal = d107.toKabaddiScoreCard.points;
                        this.setState({ topoints: teamGoal });
                    }

                }
                else {
                    let toKab = {
                        points: null,
                        teamId: this.state.matchPairObj.secondId,
                        toUndos: null,
                        winner: false
                    }

                    d107.toKabaddiScoreCard = toKab;
                    var toUndoScoresList = [];
                    var pscoreObj = {
                        strokeNo: d107.strokeNo + 1,
                        points: pointValue,
                        type: type,
                    }
                    toUndoScoresList.push(pscoreObj);

                    d107.strokeNo = d107.strokeNo + 1;
                    d107.toKabaddiScoreCard.toUndos = toUndoScoresList;
                    d107.toKabaddiScoreCard.points = pointValue;
                    d107.toKabaddiScoreCard.teamId = this.state.matchPairObj.secondId;
                    d107.toKabaddiScoreCard.winner = false;
                    let teamGoal = pointValue;
                    this.setState({ topoints: teamGoal });
                }
            }

            d107KabbadiScoreCard = d107;
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107KabbadiScoreCard: d107,
            }
            this.setState({ d107KabbadiScoreCard: d107 });

            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 500)
            });
        }

        else {
            var pointValue = val;
            let d107KabbadiScoreCard1 = { ...this.state.d107KabbadiScoreCard };
            var toUndoScoresList = [];
            var pscoreObj = {
                strokeNo: d107KabbadiScoreCard.strokeNo + 1,
                points: pointValue,
                type: type,
            }
            toUndoScoresList.push(pscoreObj);
            const that = this;
            if (type === 'challengedBy') {
                d107KabbadiScoreCard1 = {
                    strokeNo: 1,
                    byKabaddiScoreCard: {
                        points: pointValue,
                        teamId: this.state.matchPairObj.firstId,
                        winner: false,
                        toUndos: toUndoScoresList,
                    },
                    toKabaddiScoreCard: null
                }
                let teamGoal = pointValue;
                this.setState({ bypoints: teamGoal });
            }
            else {
                d107KabbadiScoreCard1 = {
                    strokeNo: 1,
                    byKabaddiScoreCard: null,
                    toKabaddiScoreCard: {
                        points: pointValue,
                        teamId: this.state.matchPairObj.secondId,
                        winner: false,
                        toUndos: toUndoScoresList,
                    }
                }
                let teamGoal = pointValue;
                this.setState({ topoints: teamGoal });
            }

            d107KabbadiScoreCard = d107KabbadiScoreCard1;
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107KabbadiScoreCard: d107KabbadiScoreCard,
            }
            this.setState({ d107KabbadiScoreCard: d107KabbadiScoreCard, score: true });

            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isByPlusDisable: false, isToPlusDisable: false })
                }, 500)
            });
            // this.getScore();
            this.setState({ toWinner: false, byWinner: false, winnerModalOpen: false, })
        }
    }
    byMemberCloseModal() {
        this.setState({ byMemberModalOpen: false, toMemberModalOpen: false });
    }
    ToundoList = () => {

        var d107 = this.state.d107KabbadiScoreCard;
        if (d107.byKabaddiScoreCard.toUndos == null && d107.toKabaddiScoreCard.toUndos == null) {

        } else if (d107.byKabaddiScoreCard.toUndos == [] && d107.toKabaddiScoreCard.toUndos == []) {

        } else {
            var d107 = this.state.d107KabbadiScoreCard;
            var strokeNo = d107.strokeNo;
            var toUndo;
            var bygoalPoints;
            var togoalPoints;
            var bystokeMatched = false;
            var tostokeMatched = false;
            if (d107.byKabaddiScoreCard !== null) {
                if (d107.byKabaddiScoreCard.toUndos) {
                    this.setState({ isundoDisable: true })
                    d107.byKabaddiScoreCard.toUndos.map(list => {
                        if (list.strokeNo === strokeNo) {
                            toUndo = list;
                            bystokeMatched = true;
                            bygoalPoints = list.points;
                        }
                    })
                }

                if (bystokeMatched) {
                    var toUndoList = d107.byKabaddiScoreCard.toUndos.filter((x) => x != toUndo)
                    d107.strokeNo = strokeNo - 1;
                    if (d107.byKabaddiScoreCard.points !== 0) {
                        d107.byKabaddiScoreCard.points = d107.byKabaddiScoreCard.points - bygoalPoints;
                        //d107.byKabaddiScoreCard.position = d107.byKabaddiScoreCard.position - 1;
                    }
                    d107.byKabaddiScoreCard.toUndos = toUndoList;
                    this.setState({ byPlayerScores: toUndoList, bypoints: d107.byKabaddiScoreCard.points })
                }
            }
            if (d107.toKabaddiScoreCard !== null) {
                this.setState({ isundoDisable: true })
                d107.toKabaddiScoreCard.toUndos.map(list => {
                    if (list.strokeNo === strokeNo) {
                        toUndo = list;
                        tostokeMatched = true;
                        togoalPoints = list.points;
                    }
                })
                if (tostokeMatched) {
                    var toUndoList = d107.toKabaddiScoreCard.toUndos.filter((x) => x != toUndo)
                    d107.strokeNo = strokeNo - 1;
                    if (d107.toKabaddiScoreCard.points !== 0) {
                        d107.toKabaddiScoreCard.points = d107.toKabaddiScoreCard.points - togoalPoints;
                        //d107.toKabaddiScoreCard.position = d107.toKabaddiScoreCard.position - 1;
                    }
                    d107.toKabaddiScoreCard.toUndos = toUndoList;
                    this.setState({ toPlayerScores: toUndoList, topoints: d107.toKabaddiScoreCard.points })
                }
            }
            this.setState({ d107KabbadiScoreCard: d107 });
            score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107KabbadiScoreCard: d107,
            }

            UpdateTournamentsScore(score).then(success => {
                setTimeout(() => {
                    this.setState({ isundoDisable: false, })
                }, 500)
            });
        }

    }

    editWinner = () => {
        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    componentDidMount() {
        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'teams') {
            this.state.teams.map(team => {
                if (this.state.matchPairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (this.state.matchPairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }

        this.setState({ byName: byName, toName: toName, score: false });


        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }

    render() {

        var byteamList = [], toteamList = [];
        var byTeamId, toTeamId;
        this.props.profileTeams.map(pt => {

            if (pt.teamId === this.state.matchPairObj.firstId) {
                byteamList = pt.teamMembers;
                byTeamId = pt.teamId;
            }
            if (pt.teamId === this.state.matchPairObj.secondId) {
                toteamList = pt.teamMembers;
                toTeamId = pt.teamId;
            }

        })

        var userName;
        var userPic;
        var userGender;

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.selectWinnerModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Point of Both  Teams Must Not Be Same Score !</h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ selectWinnerModal: false })} >Ok</button></div>
                </Modal>

                <Modal
                    isOpen={this.state.selectWinnerModal1}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}> <h3>Points of Both Teams must not Be Zero !</h3></div>
                    <div className="button_div" ><button className="btn btn-inverse" onClick={() => this.setState({ selectWinnerModal1: false })} >Ok</button></div>
                </Modal>






                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Teams</th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td><img src={byPic} class="winnerimg_td" /> {this.state.byName}<p style={{ color: "white" }}>score<br></br>{this.state.bypoints}</p></td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td><img src={toPic} class="winnerimg_td" /> {this.state.toName}<p style={{ color: "white" }} >Score<br></br>{this.state.topoints}</p></td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.setState({ winnerModalOpen: false, toWinner: false, byWinner: false })}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>
                    </div>
                </Modal>

                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">

                        <div className="broadcast_video " style={{
                            backgroundImage: "url(" + broadcastbg + ")"
                        }}>
                            <a className="tour_broadcast_update"><img src={play} alt="" /></a>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Points</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{this.state.byName}</td>
                                            <td>{this.state.bypoints ? this.state.bypoints : 0}</td>
                                            <td>
                                                <div>
                                                    {this.state.isByPlusDisable ? <a><span class="score_basketball-d" style={{ backgroundColor: 'grey' }} >[+]</span></a> : <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("challengedBy", 1)}>[+]</span></a>}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            <td>{this.state.topoints ? this.state.topoints : 0}</td>
                                            <td>
                                                <div>
                                                    {this.state.isToPlusDisable ? <a><span class="score_basketball-d" style={{ backgroundColor: 'grey' }} >[+]</span></a> : <a><span class="score_basketball-d" onClick={() => this.selectPlayerPoint("challengedTo", 1)}>[+]</span></a>}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="table_actions winner_actions">

                            {this.state.isundoDisable ? <button class="cmnbutton1 backbtn fl" type="button">Undo</button> : <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>Undo</button>}
                            <a class="cmnbutton btred fr" type="button" onClick={() => this.endEvent()}>End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
