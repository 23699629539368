import React from 'react';
import Header from './Header';
import DownloadAppPlatform from './DownloadAppPlatform';
import SectionRight from './SectionRight';

import Menu from './Menu';
// import { Link } from "react-router-dom";
// import teamFillIcon from './img/teamfill.png';
// import challengeFillIcon from './img/challengefill.png';
// import fixtureFillIcon from './img/fixturefill.png';
// import broadcastFillIcon from './img/broadcastfill.png';
// import scheduleMatchesIcon from './img/schedulematches.png';
// import challengeIcon from './img/challenge.png';
// import demoTournamentIcon from './img/demotournament.png';
// import broadcastIcon from './img/broadcast.png';
// import myTeamIcon from './img/myteam.png';
// import tournamentIcon from './img/tournament.png';
// import scheduleIcon from './img/schedule.png';
// import inviteFriendsIcon from './img/invitefriends.png';
// import contactAdminIcon from './img/contactadmin.png';
// import aboutIcon from './img/about.png';
// import changePasswordIcon from './img/changepassword.png';
import challengefill from './img/challengefill.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SectionLeft from './SectionLeft';
import SectionLeftGuest from './SectionLeftGuest';
var cricketApi = require("./cricketApi.js");
var completedUrl = cricketApi.completed();
class CompletedCric extends React.Component {
    constructor() {
        super();
        this.loadData = this.loadData.bind(this);
        this.state.user = JSON.parse(localStorage.getItem("result"));
    }

    share(val) {
        this.props.history.push("/ScoreCard/" + val);
        // this.setState({matchId:val,showScore:false});
    }

    state = {
        matchId: '',
        showScore: true,
        loading: true,
        person: [],
        paged: 1,
        page: 10,

    }

    async loadData() {
        this.setState({ page: (this.state.page + 10) });
        const apiurl = completedUrl + this.state.page + "&&paged=" + this.state.paged + ""
        const response = await fetch(apiurl);
        const data = await response.json();
        console.log(data);
        this.setState({ person: data.response.items, loading: false });
    }
    componentDidMount() {
        this.loadData();
    }
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };

    render() {
        return <div> <Header />
            <div className="container containerbottom">
                {this.state.user ? <SectionLeft /> : <SectionLeftGuest />}
                {/*  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7">
                    <section className="d_leftsec">
                        <div className="commanbox ">
                            <h3 className="commanhead">Action</h3>
                            <Menu />
                        </div>
                    </section>
                    <section className="d_leftsec">
                  <div className="commanbox ">
                      <h3 className="commanhead hidden-xs">Activities</h3>
                      <ul className="activityul hidden-xs">
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={teamFillIcon} />Team</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeFillIcon} />Challange</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                      </ul>
                      <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</Link></li>
                          <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</Link></li>
                      </ul>
                  </div>
              </section> 
                </div>*/}
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <Menu />
                    <section className="d_midsec">
                        <div className="commanbox commanboxlivescore">

                            {this.state.loading || !this.state.person ? <div>  </div> : <div>

                                <h3 className="commanhead commanheadlive">Completed Cricket  Score   </h3>
                                <ul className="scoreboxul" >
                                    {
                                        this.state.person.map((x) => {
                                            return <li className="teamdi" key={x.match_id}>
                                                <h3>{x.competition.title} </h3>
                                                <div className="teamdiv" id={x.match_id} onClick={() => this.share(x.match_id)} >
                                                    <ul className="live_teamul" >
                                                        <li>
                                                            <span className="lt_img" style={{ backgroundImage: `url(${x.teama.logo_url})` }}></span>
                                                            <label className="it_flgname">{x.teama.name}</label>
                                                            <label className="it_flgscore">{x.teama.scores_full}</label>
                                                        </li>
                                                        <li><img className="vsicon" src={challengefill} alt=""></img></li>
                                                        <li>
                                                            <span className="lt_img" style={{ backgroundImage: `url(${x.teamb.logo_url})` }}></span>
                                                            <label className="it_flgname">{x.teamb.name}</label>
                                                            <label className="it_flgscore active">{x.teamb.scores_full}</label>
                                                        </li>
                                                    </ul>
                                                    <p className="datentime_para">{x.format_str} {x.date_start}</p>
                                                    <p className="scoreupdate_para"> {x.status_note}</p>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <button type="button" onClick={() => this.loadData()} className="btn btn-success btn-block">Load More ....</button>



                            </div>
                            }
                        </div>
                    </section>
                </div>
                <SectionRight />
            </div>
        </div>
    }
}
export default CompletedCric;