import React, { Component } from "react";
import ModalSet from "react-bootstrap/Modal";
import { trackPromise } from "react-promise-tracker";
import userMaleIcon from "../img/male.png";
import ConfigUrl from "../ConfigUrl";
import Loader from "react-loader-spinner";
const ConfigApiurl = ConfigUrl("apiUrl");
const userApi = ConfigApiurl;

const loaderStyle = {
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "131px"
};

export default class InviteTeamQrcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQrModel: true,
      teamQr: null,
    };
    this.state.user = JSON.parse(localStorage.getItem("result"));
  }

  componentDidMount() {
    if (!this.props.teamQr) {
      this.GetQrCode();
    }
  }

  GetQrCode() {
    var urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    let teamid = urlParams.get("id");
    console.log(teamid);
    const that = this;
    fetch(userApi + "qr/team-qr", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          userId: this.state.user.id,
          teamId: teamid,
        },
      }),
    })
      .then(function (response) {
        return trackPromise(response.json());
      })
      .then(function (result) {
        console.log(result);
        if (result.data) {
          that.setState({ teamQr: result.data.qrCode });
          let teamObj = that.props.teamObj;
          teamObj.qrCode = result.data.qrCode;
          that.props.setQr(result.data.qrCode);
        }
      });
  }

  // QrModelhide = () => {
  //   this.setState({ openQrModel: false });
  // };
  closeModal = () => {
    this.setState({ openQrModel: false });
    this.props.closeQr();
  };

  render() {
    let icon = this.state.user.profilePic ? this.state.user.profilePic : userMaleIcon;
    return (
      <>
        <ModalSet
          size="lg"
          show={this.state.openQrModel}
          // onHide={() => this.QrModelhide()}
          animation={false}
          onRequestClose={this.closeModal}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="Qrmodel-width"
        >
          {/* <div id="QRmodel" className="modal fade" role="dialog">
          <div className="modal-dialog Qrmodel-width"> */}
          {/* Modal content*/}
          <div className="modal-content modal-sports qrcode-model">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.closeModal}
                data-dismiss="modal"
              >
                ×
              </button>
              <h4 className="modal-title">Scan QR</h4>
            </div>
            <div className="modal-body  Qr-model-body">
              <div className="col-lg-7 col-md-7 qr-left">
                <div className="barcode-info">
                  <img src={icon} />
                  <label>
                    {this.state.user.name}
                    <p>Sports Name : {this.props.teamObj.sportsName}</p>
                  </label>
                </div>
                <label className="qr-location">
                  Location
                  <p>{this.state.user.address}</p>
                </label>
                <label className="qr-creator">
                  Team Owner
                  <p style={{ color: "#000" }}>{this.state.user.name}</p>
                </label>
                <div className="qr-downloadbtn">
                  <p className="heading-download">
                    Click the below links for download the App{" "}
                  </p>
                  <div className="flexss social-btns">
                    <a
                      className="app_btnn blu flexss vert"
                      onClick={() =>
                      (window.location.href =
                        "https://apps.apple.com/app/id1437392923")
                      }
                    >
                      <i className="fa fa-apple" />
                      <p>
                        Get it on <br />{" "}
                        <span className="big-txt">App Store</span>
                      </p>
                    </a>
                    <a
                      className="app_btnn blu flexss vert"
                      onClick={() =>
                      (window.location.href =
                        "https://play.google.com/store/apps/details?id=com.sportsapp.ssp")
                      }
                    >
                      <i className="fa fa-google" />
                      <p>
                        Get it on <br />{" "}
                        <span className="big-txt">Google Play</span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 qr-right">
                <div className="qrblock">
                  <p className="Qr-sec-headng">
                    Scan the QR code for adding team, buddies, challenge &amp;
                    tournament
                  </p>
                  {this.props.teamQr ? <img src={this.props.teamQr} /> :
                    <div style={loaderStyle}>
                      <Loader type="Puff" color="#2BAD60" Height="100" Width="100" />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* </div>
        </div> */}
        </ModalSet>
      </>
    );
  }
}
