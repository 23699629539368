import React, { useState, useEffect } from 'react';
import axios from 'axios';
import userMaleIcon from '../img/male.png';
import userFemaleIcon from '../img/female.png';
// import userFemaleIcon from '../../src/img/userFemaleIcon.png';
import pageIconD from '../img/pageIconD.png';
import ConfigUrl from '../ConfigUrl';
import Header from '../Header';
import SectionLeft from '../SectionLeft';
import SectionRight from '../SectionRight';
import { useHistory, useParams } from "react-router-dom";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;
const user = JSON.parse(localStorage.getItem('result'));

const data = {
    pageId: null,
}


function renderSwitch(param) {
    switch (param) {
        case 'club':
            return 'Sports Club';
        default:
            return 'NA';
    }
}

function Followers(props) {
    const [followers, setFollowers] = useState([])
    let history = useHistory();
    let { pageId } = useParams();
    data.pageId = pageId;
    useEffect(() => {
        axios.post(userApi + 'pages/get-followers', {
            data
        }).then(function (response) {
            console.log(response.data)
            setFollowers(response.data.data.users)
        })
    }, [])
    function redirectToCreatepage() {
        history.push("/page/create");
    }
    function redirectToPageProfile(id) {
        history.push(`/page/profile/${id}`);
    }
    return (
        <>
            <Header />
            <div className="container containerbottom non-session-d">
                {user ? <SectionLeft /> : ''}
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pad7">
                    <div className="commanbox" id="profilebuddies">
                        <h3 className="commanhead">Page Followers </h3>

                        <ul className="buddieslist_ul">
                            {followers.length > 0 ? followers.map(x => {
                                return (() => {
                                    if (x.profilePic === null) {
                                        switch (x.gender) {
                                            case "Male":
                                                return (
                                                    <li key={x.contactNo}>
                                                        <a href={"/buddies?id=" + x.contactNo}>
                                                            <span style={{
                                                                backgroundImage: "url(" + userMaleIcon + ")"
                                                            }}></span>
                                                            <label>{x.name}</label>
                                                        </a>
                                                    </li>
                                                );
                                            case "Female":
                                                return (
                                                    <li key={x.contactNo}>
                                                        <a href={"/buddies?id=" + x.contactNo}>

                                                            <span style={{
                                                                backgroundImage: "url(" + userFemaleIcon + ")"
                                                            }}></span>
                                                            <label>{x.name}</label>
                                                        </a>
                                                    </li>
                                                );
                                            default: return '';
                                        }
                                    }
                                    else {
                                        return (
                                            <li key={x.contactNo}>
                                                <a href={"/buddies?id=" + x.contactNo}>

                                                    <span style={{
                                                        backgroundImage: "url(" + x.profilePic + ")"
                                                    }}></span>
                                                    <label>{x.name}</label>

                                                </a>
                                            </li>
                                        );
                                    }
                                })();
                            }) : <div className="broadcast_video">
                                <a>
                                    {/* <img style={{ height: 'auto', width: '250px' }} className="matchnotstartedImg" src={NoTeam} /> */}
                                </a>
                                <h3>No Followers found with this name.</h3>
                            </div>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Followers

