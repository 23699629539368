import React, { Component } from 'react';
import DownloadAppPlatform from './DownloadAppPlatform';
import teamFillIcon from './img/teamfill.png';
import challengeFillIcon from './img/challengefill.png';
import fixtureFillIcon from './img/fixturefill.png';
import broadcastFillIcon from './img/broadcastfill.png';
import scheduleMatchesIcon from './img/schedulematches.png';
import pageIconD from './img/pageIconM.png';
import challengeIcon from './img/challenge.png';
import demoTournamentIcon from './img/demotournament.png';
import broadcastIcon from './img/broadcast.png';
import myTeamIcon from './img/myteam.png';
import ImgDropAndCrop from './ImgDropAndCrop';
import tournamentIcon from './img/tournament.png';
import scheduleIcon from './img/schedule.png';
import inviteFriendsIcon from './img/invitefriends.png';
import contactAdminIcon from './img/contactadmin.png';
import aboutIcon from './img/about.png';
import changePasswordIcon from './img/changepassword.png';
import cancelMobileIcon from './img/cancelmobileicon.png';
import cameraIcon from './img/camera.png';
import userMaleIcon from './img/male.png';
import userFemaleIcon from './img/female.png';
import { Link } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import { confirmAlert } from 'react-confirm-alert';

//import ImageUploader from 'react-images-upload';
/*import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
registerPlugin(FilePondPluginImagePreview);*/
import ConfigUrl from './ConfigUrl';
import $ from "jquery";
const ConfigApiurl = ConfigUrl('apiUrl');
const userApi = ConfigApiurl;

//const userApi = "https://playdiator.com/apis/";

class SectionLeft extends Component {
    constructor(props) {
        super(props);
        if (JSON.parse(localStorage.getItem('result'))) {
            const loggedUser = JSON.parse(localStorage.getItem('result'));
            this.state = {
                user: loggedUser,
                profileBuddiesDetails: [],
                profileFollowersDetails: [],
                profileFollowingsDetails: [],
                bId: loggedUser.contactNo,
                uId: loggedUser.contactNo,
                //file: '',imagePreviewUrl: '',
                //pictures: []
            };

            //this.onDrop = this.onDrop.bind(this);
        } else {
            this.state = {
                profileBuddiesDetails: [],
                profileFollowersDetails: [],
                profileFollowingsDetails: []
            };
        }

    }

    handleLanguage = (langValue) => {
        this.setState({ imageCrop: langValue });
    }
    submitPicture = () => {

        let that = this;
        let userD = { ...this.state.user };
        const apiUrl = userApi + "upload-file";
        let dataurl = that.state.imageCrop;
        console.log(dataurl)
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        }
        var file = dataURLtoFile(dataurl, 'image.jpg');
        var timestamp = Math.floor(Date.now());
        if (file !== undefined) {
            var data = {
                "data": {
                    "folder": "posts/" + this.state.user.contactNo,
                    "fileName": "IMG_" + timestamp
                }
            };
            var form = new FormData();
            form.append("file", file);
            form.append("data", JSON.stringify(data));
            // let downloadUrl = '';     
            fetch(apiUrl, {
                method: 'post',
                "async": true,
                "crossDomain": true,
                "processData": false,
                'Content-Type': 'false',
                body: form
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    uploadSuggestedPostData(result.data.downloadUrl);
                    // that.loadMore();
                    userD.profilePic = result.data.downloadUrl

                    that.setState({ user: userD });
                    // that.props.imageUpdateP(userD);
                });
            const allDaata = this.state.user;
            function uploadSuggestedPostData(data) {
                delete allDaata.id;
                allDaata.profilePic = data;
                var that = this;
                fetch(userApi + "users/update-user", {
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        data: {
                            user: allDaata,
                        }
                    })
                })
                    .then(function (response) {
                        return trackPromise(response.json());
                    })
                    .then(function (result) {

                        localStorage.setItem('result', JSON.stringify(allDaata));
                    });
            }
        }
    }


    componentDidMount() {
        const that = this;
        const apiUrl = userApi + 'users/buddy-profile';
        fetch(apiUrl, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    buddyId: this.state.bId,
                    userId: this.state.uId
                }
            })
        }).then(function (response) {
            return trackPromise(response.json());
        }).then(function (result) {
            // console.log(result);
            that.setState({ contactNo: result.data.user.contactNo })
            that.setState({ profileBuddiesDetails: result.data.buddies, profileFollowersDetails: result.data.followers, profileFollowingsDetails: result.data.followings });
        });
    }
    loginRedirect = () => {
        confirmAlert({
            customUI: () => {
                return (
                    <DownloadAppPlatform />
                );
            }
        });
    };
    /*onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.push(picture),
        });
        console.log(this.state.pictures);
    }*/
    /*_handleSubmit(e) {
        e.preventDefault();
        // TODO: do something with -> this.state.file
        console.log('handle uploading-', this.state.file);
    }

    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }*/
    closemenu = () => {
        $(".d_leftsec").toggleClass("d_leftsec_width");
        this.props.parentCallbackfromChild2("closeSidebar");
    }

    render() {
        if (this.state.user) {
            if (this.state.profileBuddiesDetails) {
                if (this.state.profileBuddiesDetails.length > 3) {
                    this.buddiesList = this.state.profileBuddiesDetails.slice(0, this.state.profileBuddiesDetails.length).map((item, key) =>

                        <li key={item.name}>
                            <a href={"/buddies?id=" + item.contactNo}>
                                {(() => {
                                    switch (item.profilePic) {
                                        case null:
                                            switch (item.gender) {
                                                case "Male":
                                                    return <span style={{ backgroundImage: 'url(' + userMaleIcon + ')' }} title={item.name}></span>;

                                                case "Female":
                                                    return <span style={{ backgroundImage: 'url(' + userFemaleIcon + ')' }} title={item.name}></span>;
                                                default:
                                                    return "";
                                            }
                                        default:
                                            return <span style={{ backgroundImage: 'url(' + item.profilePic + ')' }} title={item.name}></span>;
                                    }
                                })()}
                            </a>
                        </li>
                    );
                } else {
                    this.buddiesList = this.state.profileBuddiesDetails.slice(0, 3).map((item, key) =>
                        <li key={item.name}>

                            <a href={"/buddies?id=" + item.contactNo}>
                                {(() => {
                                    switch (item.profilePic) {
                                        case null:
                                            switch (item.gender) {
                                                case "Male":
                                                    return <span style={{ backgroundImage: 'url(' + userMaleIcon + ')' }} title={item.name}></span>;
                                                case "Female":
                                                    return <span style={{ backgroundImage: 'url(' + userFemaleIcon + ')' }} title={item.name}></span>;
                                                default:
                                                    return "";
                                            }
                                        default:
                                            return <span style={{ backgroundImage: 'url(' + item.profilePic + ')' }} title={item.name}></span>;
                                    }
                                })()}
                            </a>
                        </li>
                    );
                }
            }
            this.userItems = <div className="commanbox profileleft">
                <Link to={"#"} className="closemenu hidden-md hidden-sm hidden-lg" ><img src={cancelMobileIcon} alt="" onClick={this.closemenu} /></Link>
                <div className="profilediv">
                    <div className="profileig_inner">
                        {(() => {
                            switch (this.state.user.gender) {
                                case 'Male': return <span className="profilespan" style={{
                                    backgroundImage: "url(" + `${this.state.user.profilePic ? this.state.user.profilePic : userMaleIcon}` + ")"
                                }}> </span>;
                                case 'Female': return <span className="profilespan" style={{ backgroundImage: "url(" + `${this.state.user.profilePic ? this.state.user.profilePic : userFemaleIcon}` + ")" }}> </span>;
                                default: return '';
                            }
                        })()}
                        <Link to={"#"} ><img data-toggle="modal" data-target="#openCropModalSecl" src={cameraIcon} alt="" /></Link>
                        <span className="prof_span"></span>
                    </div>
                </div>
                {/* <Link to={"#"} className="pro_useredit">Edit Profile</Link> */}
                <label className="pro_username text-capitalize">{this.state.user.name}</label>
                <ul className="profileul">
                    <li> <Link to={"/my-account/following"}> <span >{this.state.profileFollowingsDetails}</span> Followings </Link> </li>
                    <li> <Link to={"/my-account/followers"}> <span >{this.state.profileFollowersDetails}</span> Followers </Link> </li>

                </ul>
                <div className="buddieshead">
                    <label>Buddies</label>
                    <Link to={"/my-account/buddies"} > <span >{this.buddiesList}</span> View All</Link>
                </div>
                <ul className="buddiesul">
                    {this.buddiesList}
                </ul>
                <Link to={"/my-account"} className="pro_viewprofile" >View Profile</Link>


                {/* <ul className="profileul">
                    <li> <Link to={"/my-account"}> <span onClick={() => this.props.data.showComponent('Followings')}>{this.state.profileFollowingsDetails}</span> Followings </Link> </li>
                    <li> <Link to={"/my-account"}> <span onClick={() => this.props.data.showComponent('Followers')}>{this.state.profileFollowersDetails}</span> Followers </Link> </li>
                </ul>
                <div className="buddieshead">
                    <label>Buddies</label>
                    <Link to={"my-account"} onClick={() => this.props.data.showComponent('Buddies')}>View All</Link>
                </div>
                <ul className="buddiesul">
                    {this.buddiesList}
                </ul>
                <Link to={"my-account"} className="pro_viewprofile" onClick={() => this.props.data.showComponent('viewProfile')}>View Profile</Link> */}
            </div>;
        } else {
            this.userItems = '';
        }
        /*let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        }*/
        return (
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 pad7 sticky-left">
                <div id="openCropModalSecl" className="modal fade" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Upload Image</h4>
                            </div>

                            <div className="modal-body">
                                <ImgDropAndCrop onSelectLanguage={this.handleLanguage} />
                            </div>

                            <div className="modal-footer">
                                <button onClick={() => this.submitPicture()} type="button" className="cmnbutton" data-dismiss="modal">Crop & Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="d_leftsec">
                    {this.userItems}
                    {/*<ImageUploader
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                    />*/}
                    {/*<div className="previewComponent">
                        <form onSubmit={(e)=>this._handleSubmit(e)}>
                            <input className="fileInput"
                                   type="file"
                                   onChange={(e)=>this._handleImageChange(e)} />
                            <button className="submitButton"
                                    type="submit"
                                    onClick={(e)=>this._handleSubmit(e)}>Upload Image</button>
                        </form>
                        <div className="imgPreview">
                            {$imagePreview}
                        </div>
                    </div>*/}
                    {/*<div>
                        <FilePond/>
                    </div>*/}
                    <div className="commanbox ">
                        <h3 className="commanhead hidden-xs">Activities</h3>
                        {this.state.user ? <ul className="activityul hidden-xs">
                            <li><Link to={"/teamslist"}><img alt="" src={teamFillIcon} />Team</Link></li>
                            <li><Link to={"/challengelist"} ><img alt="" src={challengeFillIcon} />Challenge</Link></li>
                            <li><Link to={"/tournamentlist"} ><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                            <li><Link to={"/schedule-matches"}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li>
                            <li><Link to={"/page"}><img alt="" src={pageIconD} />Page</Link></li>
                            {/* <li><Link to={"#"} onClick={() => this.props.data.showComponent('teamPage')}><img alt="" src={teamFillIcon} />Team</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('challengePage')}><img alt="" src={challengeFillIcon} />Challenge</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('tournamentPage')}><img alt="" src={fixtureFillIcon} />Tournament</Link></li>
                            <li><Link to={"#"} onClick={this.loginRedirect}><img alt="" src={broadcastFillIcon} />Broadcast</Link></li>
                            <li><Link to={"#"} onClick={() => this.props.data.showComponent('scheduleMatches')}><img alt="" src={scheduleMatchesIcon} />Schedule Matches</Link></li> */}
                        </ul> : <ul className="activityul hidden-xs">
                            <li><a href={"/download-app-page"} ><img alt="" src={teamFillIcon} />Team</a></li>
                            <li><a href={"/login"} ><img alt="" src={challengeFillIcon} />Challange</a></li>
                            <li><a href={"/login"} ><img alt="" src={fixtureFillIcon} />Tournament</a></li>
                            {/* <li><a href={"/login"} ><img alt="" src={broadcastFillIcon} />Broadcast</a></li> */}
                            <li><a href={"/login"} ><img alt="" src={scheduleMatchesIcon} />Schedule Matches</a></li>
                        </ul>}
                        <ul className="activityul sidemenuul hidden-sm hidden-md hidden-lg">
                            {this.state.user ? <div>

                                {/* <li><Link to={"/download-app-page"} ><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                                <li><Link to={"/download-app-page"} ><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li>
                                <li><Link to={"/download-app-page"} ><img alt="" src={broadcastIcon} />Live Broadcast</Link></li> */}
                                {/* <li><Link to={"/download-app-page"}  ><img alt="" src={myTeamIcon} />My Team</Link></li>
                                <li><Link to={"/download-app-page"} ><img alt="" src={tournamentIcon} />My Tournament</Link></li>
                                <li><Link to={"/download-app-page"} ><img alt="" src={scheduleIcon} />My Schedule</Link></li>
                               
                                <li><Link to={"/download-app-page"} ><img alt="" src={contactAdminIcon} />Contact Admin</Link></li> */}
                                <li><a href={"/download-app-page"} ><img alt="" src={inviteFriendsIcon} />Invite Friends</a></li>
                                <li><a href={"/about-us"} ><img alt="" src={aboutIcon} />About Playdiator</a></li>
                                {/* <li><Link to={"/download-app-page"} ><img alt="" src={changePasswordIcon} />Change Password</Link></li> */}
                            </div>
                                : <div>
                                    {/* <li><Link to={"/login"} onClick={this.loginRedirect}><img alt="" src={challengeIcon} />Demo Challange</Link></li>
                                    <li><Link to={"/login"} onClick={this.loginRedirect}><img alt="" src={demoTournamentIcon} />Demo Tournament</Link></li> */}
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={broadcastIcon} />Live Broadcast</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={myTeamIcon} />My Team</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={tournamentIcon} />My Tournament</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={scheduleIcon} />My Schedule</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={inviteFriendsIcon} />Invite Friends</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={contactAdminIcon} />Contact Admin</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={aboutIcon} />About Playdiator</a></li>
                                    <li><a href={"/login"} onClick={this.loginRedirect}><img alt="" src={changePasswordIcon} />Change Password</a></li></div>}
                        </ul>


                        {/* <chatRecent contactNo={this.state.contactNo} /> */}
                    </div>
                </section>
            </div>
        );
    }
}

export default SectionLeft;
