import React, { Component } from 'react'
import ConfigUrl from '../ConfigUrl';
import Modal from 'react-modal';
import { trackPromise } from "react-promise-tracker";
import DUpdateTournamentsScore from '../DemoTournament/DUpdateTournamentsScore';
import NumericInput from 'react-numeric-input';
import finallogo from '../img/finallogo.png';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import play from '../img/play.png';
import noBroadcast from '../img/noBroadcast.jpg';
import logo from '../img/finallogo.png';
import * as myUtilclass from '../DemoTournament/DFixtureUtil';
import * as myTournamentUtilclass from '../DemoTournament/DTournamentsUtil';
var score;
let pairObj;
var scoreCardD101s = [];
var d107ScoreCard = {}, score, points;
var maxWeights = [];
var d107TennisScoreCard = {}
const challengeAction = "ended";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const ConfigApiUrl = ConfigUrl('apiUrl');
const rshApi = ConfigApiUrl;
class Tournament_tennis_tt1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setError: false,
            buddymode: false,
            userTeam: [],
            byName: '',
            toName: '',
            rounds: [],
            numberOfSet: '',
            setNo: 0,
            tiebreaker: false,
            saveSetWinner: false,
            setNumberForWinner: '',
            winnerModalOpen: false,
            d107TennisScoreCard: {},
            byPoints: [0],
            toPoints: [0],
            poolId: 0,
            tt23Matches: {},
            matchPairObj: {},


        }
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.doneMatch = this.doneMatch.bind(this);

    }
    makePoint(type) {
        var d107TennisScoreCard = this.state.d107TennisScoreCard;
        var toAdd = this.state.d107TennisScoreCard.rounds.filter(round => {
            return round.position === d107TennisScoreCard.setNo
        })
        console.log(toAdd);
        var toRemove = [...toAdd];

        // var undoList = toAdd[0].toUndoList.filter(list => {
        //     return list.strokeNo === this.state.strokeNo
        // })
        var stroke = d107TennisScoreCard.strokeNo;
        var undoList = {
            byNo: "0",
            points: 1,
            strokeNo: 1,
            toNo: "0",
            type: "challengedBy",
        }
        if (type === 'by') {

            undoList.strokeNo = stroke + 1;
            undoList.byNo = toAdd[0].challengedByNo ? toAdd[0].challengedByNo : "0";
            if (toAdd[0].challengedToNo === "") {
                undoList.toNo = "";
            } else {
                undoList.toNo = toAdd[0].challengedToNo ? toAdd[0].challengedToNo : "0";
            }
            if ((toAdd[0].challengedToPoints === 6) && (toAdd[0].challengedByPoints === 6)) {
                this.setState({ tiebreaker: true })
                toAdd[0].tieBreaker = true;
                undoList.type = "challengedBy";
                undoList.byNo = '' + toAdd[0].challengedByTieBreakerPoints + '';
                undoList.toNo = '' + toAdd[0].challengedToTieBreakerPoints + '';
                toAdd[0].challengedByTieBreakerPoints = toAdd[0].challengedByTieBreakerPoints + 1;

                if ((toAdd[0].challengedByTieBreakerPoints > 6) && ((toAdd[0].challengedByTieBreakerPoints - toAdd[0].challengedToTieBreakerPoints) > 1)) {

                    toAdd[0].challengedByPoints = 7;
                    toAdd[0].saved = true;
                    toAdd[0].winnerId = toAdd[0].challengedById;
                    if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null) {
                        d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                    }
                    else {
                        d107TennisScoreCard.byTennisScoreCard.setsWon = [1];
                    }
                    d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                    this.setState({ tiebreaker: false });
                }
            }
            else {
                undoList.type = "challengedBy";
                if (toAdd[0].challengedByNo === null) {
                    toAdd[0].challengedByNo = "15";

                }
                else if (toAdd[0].challengedByNo === '15') {
                    toAdd[0].challengedByNo = "30";

                }
                else if (toAdd[0].challengedByNo === '30') {
                    toAdd[0].challengedByNo = "40";

                }
                else if (toAdd[0].challengedByNo === '40') {
                    if (toAdd[0].challengedToNo === "40") {

                        toAdd[0].challengedByNo = "Adv";
                        toAdd[0].challengedToNo = "";
                    }
                    else {
                        this.setState({ setWinnerName: this.state.matchPairObj.firstId, setNumberForWinner: d107TennisScoreCard.setNo });


                        toAdd[0].challengedByNo = null;
                        toAdd[0].challengedToNo = null;

                        if (toAdd[0].challengedByPoints === 6) {

                            if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                                $('#setWonClick').click();
                            }
                            toAdd[0].saved = true;
                            toAdd[0].winnerId = toAdd[0].challengedById;
                            if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null) {
                                d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                            }
                            else {
                                d107TennisScoreCard.byTennisScoreCard.setsWon = [1];
                            }
                            d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                        }
                        toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;


                    }
                }
                else if (toAdd[0].challengedByNo === 'Adv') {
                    toAdd[0].challengedByNo = null;
                    toAdd[0].challengedToNo = null;

                    if (toAdd[0].challengedByPoints === 6) {
                        if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                            $('#setWonClick').click();
                        }
                        //show Alert
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedById;
                        if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null) {
                            d107TennisScoreCard.byTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                        }
                        else {
                            d107TennisScoreCard.byTennisScoreCard.setsWon = [1];
                        }
                        d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                    }
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints + 1;
                }
            }

        }
        if (type === 'to') {
            undoList.strokeNo = stroke + 1;
            if (toAdd[0].challengedByNo === "") {
                undoList.byNo = "";
            } else {
                undoList.byNo = toAdd[0].challengedByNo ? toAdd[0].challengedByNo : "0";
            }
            undoList.toNo = toAdd[0].challengedToNo ? toAdd[0].challengedToNo : "0";
            if ((toAdd[0].challengedToPoints === 6) && (toAdd[0].challengedByPoints === 6)) {
                this.setState({ tiebreaker: true });
                toAdd[0].tieBreaker = true;
                undoList.type = "challengedTo";
                undoList.byNo = '' + toAdd[0].challengedByTieBreakerPoints + '';
                undoList.toNo = '' + toAdd[0].challengedToTieBreakerPoints + '';

                toAdd[0].challengedToTieBreakerPoints = toAdd[0].challengedToTieBreakerPoints + 1;

                if ((toAdd[0].challengedToTieBreakerPoints > 6) && ((toAdd[0].challengedToTieBreakerPoints - toAdd[0].challengedByTieBreakerPoints) > 1)) {
                    toAdd[0].challengedToPoints = 7;
                    toAdd[0].saved = true;
                    toAdd[0].winnerId = toAdd[0].challengedToId;
                    if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null) {
                        d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                    }
                    else {
                        d107TennisScoreCard.toTennisScoreCard.setsWon = [1];
                    }
                    if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                        $('#setWonClick').click();
                    }
                    d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;
                }
            }
            else {
                undoList.type = "challengedTo";
                if (toAdd[0].challengedToNo === null) {
                    toAdd[0].challengedToNo = "15";
                }
                else if (toAdd[0].challengedToNo === '15') {
                    toAdd[0].challengedToNo = "30";
                }
                else if (toAdd[0].challengedToNo === '30') {
                    toAdd[0].challengedToNo = "40";
                }
                else if (toAdd[0].challengedToNo === '40') {
                    if (toAdd[0].challengedByNo === "40") {
                        toAdd[0].challengedByNo = "";
                        toAdd[0].challengedToNo = "Adv";
                    }
                    else {
                        toAdd[0].challengedByNo = null;
                        toAdd[0].challengedToNo = null;
                        if (toAdd[0].challengedToPoints === 6) {
                            if (d107TennisScoreCard.setNo !== d107TennisScoreCard.noOfSets) {
                                $('#setWonClick').click();
                            }

                            //show Alert
                            toAdd[0].saved = true;
                            toAdd[0].winnerId = toAdd[0].challengedToId;
                            if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null) {
                                d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                            }
                            else {
                                d107TennisScoreCard.toTennisScoreCard.setsWon = [1];
                            }
                            d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                        }
                        toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    }
                }
                else if (toAdd[0].challengedToNo === 'Adv') {
                    toAdd[0].challengedByNo = null;
                    toAdd[0].challengedToNo = null;

                    if (toAdd[0].challengedToPoints === 6) {
                        $('#setWonClick').click();
                        //show Alert
                        toAdd[0].saved = true;
                        toAdd[0].winnerId = toAdd[0].challengedToId;
                        if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null) {
                            d107TennisScoreCard.toTennisScoreCard.setsWon.push(d107TennisScoreCard.setNo);
                        }
                        else {
                            d107TennisScoreCard.toTennisScoreCard.setsWon = [1];
                        }
                        d107TennisScoreCard.setNo = d107TennisScoreCard.setNo + 1;

                    }
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints + 1;
                    this.setState({ tiebreaker: false });
                }
            }

        }
        if (toAdd[0].toUndoList !== null) {
            toAdd[0].toUndoList.push(undoList);

        }
        else {
            toAdd[0].toUndoList = [undoList];
        }
        // var result = d107TennisScoreCard.rounds.map(round => {
        //     if (round.position === this.state.setNo) {

        //     }
        // })
        d107TennisScoreCard.strokeNo = stroke + 1;

        if (d107TennisScoreCard.noOfSets < d107TennisScoreCard.setNo) {
            d107TennisScoreCard.setNo = (d107TennisScoreCard.setNo - 1)
            var byLen, toLen;
            if (d107TennisScoreCard.byTennisScoreCard.setsWon !== null) {
                byLen = d107TennisScoreCard.byTennisScoreCard.setsWon.length;
            }
            else {
                byLen = 0
            }
            if (d107TennisScoreCard.toTennisScoreCard.setsWon !== null) {
                toLen = d107TennisScoreCard.toTennisScoreCard.setsWon.length;
            }
            else {
                toLen = 0
            }
            if (byLen > toLen) {
                d107TennisScoreCard.byTennisScoreCard.winner = true;
                this.setState({ byWinner: true, winner: d107TennisScoreCard.byTennisScoreCard.id })
            }
            else {
                d107TennisScoreCard.toTennisScoreCard.winner = true;
                this.setState({ toWinner: true, winner: d107TennisScoreCard.toTennisScoreCard.id })

            }
            var score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107TennisScoreCard: d107TennisScoreCard,
            }
            this.setState({ winnerModalOpen: true })
            DUpdateTournamentsScore(score);
            this.setState({ d107TennisScoreCard: d107TennisScoreCard });
        }
        else {
            var score = {
                tournamentId: this.props.tournament.tournamentId,
                matchId: this.props.matchId,
                d107TennisScoreCard: d107TennisScoreCard,
            }
            DUpdateTournamentsScore(score);
            console.log(d107TennisScoreCard);
            this.setState({ d107TennisScoreCard: d107TennisScoreCard });

        }


    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#2cae4a';
    }
    closeModal() {
        this.setState({ winnerModalOpen: false });
    }
    doneMatch() {

        let d107TennisScoreCard = this.state.d107TennisScoreCard;
        if (this.state.byWinner) {
            d107TennisScoreCard.byTennisScoreCard.winner = true;
        }
        if (this.state.toWinner) {
            d107TennisScoreCard.toTennisScoreCard.winner = true;
        }
        score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107TennisScoreCard: d107TennisScoreCard,
        }

        DUpdateTournamentsScore(score);


        let fixture;
        if (this.props.tt1.fixtureType1) {
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, null, pairObj.pairId, this.state.winner);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            fixture = myUtilclass.declareMatchWinner(this.props.tt1, pairObj, poolNo, pairObj.pairId, this.state.winner);
        }

        if (fixture.finalWinner) {
            let tournament = this.props.tournament;
            tournament.status = "completed";
            tournament.tournamentWinnerId = this.state.winner;
            let tId = fixture.tournamentId;
            delete fixture.id;
            delete fixture.finalWinner;
            let that = this;
            delete tournament.id;
            myTournamentUtilclass.updateTournament(tournament).then(data => {
                myTournamentUtilclass.updateFixture(tId, fixture).then(data => {
                    myTournamentUtilclass.ttWinnerFeeds(tId, pairObj.matchId, that.state.winner).then(data => {
                        window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
                    })
                });
            })
        }
        else {
            let tId = fixture.tournamentId;
            delete fixture.id;
            let that = this;
            myTournamentUtilclass.updateFixture(tId, fixture).then(data => {

                window.location = "/dtournamentprofile/index.html?id=" + that.props.tournament.tournamentId
            });
        }

    }
    ToundoList = () => {
        this.setState({ winnerModalOpen: false });
        var d107TennisScoreCard = this.state.d107TennisScoreCard;

        var toAdd = this.state.d107TennisScoreCard.rounds.filter(round => {
            return round.position === d107TennisScoreCard.setNo
        })

        console.log(toAdd);
        var toRemove = [...toAdd];
        var stroke = d107TennisScoreCard.strokeNo;
        var TypeMode;
        var toUndo;
        var toUndoList = [];
        if (toAdd[0].toUndoList !== null) {
            toUndoList = toAdd[0].toUndoList;
        }

        console.log(toUndoList);
        toUndoList.map(list => {
            if (list.strokeNo === stroke) {
                TypeMode = list.type;
                toUndo = list;
            }
        })
        if (TypeMode === "challengedBy") {
            if (toAdd[0].tieBreaker) {

                if (toAdd[0].challengedByTieBreakerPoints > 0)
                    toAdd[0].challengedByTieBreakerPoints = (toAdd[0].challengedByTieBreakerPoints - 1);
                else {
                    toAdd[0].tieBreaker = false;
                    toAdd[0].challengedByPoints = (toAdd[0].challengedByPoints - toUndo.points);

                    if (toUndo.byNo === "0")
                        toAdd[0].challengedByNo = null;
                    else
                        toAdd[0].challengedByNo = toUndo.byNo;

                    if (toUndo.toNo === "0")
                        toAdd[0].challengedToNo = null;
                    else
                        toAdd[0].challengedToNo = toUndo.toNo;
                }

                stroke -= 1;
                var setWons = d107TennisScoreCard.byTennisScoreCard.setsWon;
                if (setWons != null) {
                    if (setWons.length > 0) {
                        if (setWons.indexOf(d107TennisScoreCard.setNo) !== -1)
                            var index = setWons.indexOf(d107TennisScoreCard.setNo)
                        if (index > -1) d107TennisScoreCard.byTennisScoreCard.setsWon.splice(index, 1);
                        if (setWons.length == 0) {
                            setWons = null;
                            d107TennisScoreCard.byTennisScoreCard.setsWon = setWons;
                        }
                    }
                }

            }
            else if (toAdd[0].challengedByPoints > 0 || toAdd[0].challengedByNo !== null) {

                if (toAdd[0].challengedByNo == null)
                    toAdd[0].challengedByPoints = toAdd[0].challengedByPoints - toUndo.points;
                if (toUndo.byNo === "0")
                    toAdd[0].challengedByNo = null;
                else
                    toAdd[0].challengedByNo = toUndo.byNo;

                if (toUndo.toNo === "0")
                    toAdd[0].challengedToNo = null;
                else
                    toAdd[0].challengedToNo = toUndo.toNo;

                stroke -= 1;
                var setWons = d107TennisScoreCard.byTennisScoreCard.setsWon;
                if (setWons != null) {
                    if (setWons.length > 0) {
                        if (setWons.indexOf(d107TennisScoreCard.setNo) !== -1)
                            var index = setWons.indexOf(d107TennisScoreCard.setNo)
                        if (index > -1) d107TennisScoreCard.byTennisScoreCard.setsWon.splice(index, 1);
                        if (setWons.length == 0) {
                            setWons = null;
                            d107TennisScoreCard.byTennisScoreCard.setsWon = setWons;
                        }
                    }
                }
            }
        }
        else {
            if (toAdd[0].tieBreaker) {


                if (toAdd[0].challengedToTieBreakerPoints > 0)
                    toAdd[0].challengedToTieBreakerPoints = (toAdd[0].challengedToTieBreakerPoints - 1);
                else {
                    toAdd[0].tieBreaker = false;
                    toAdd[0].challengedToPoints = (toAdd[0].challengedToPoints - toUndo.points);

                    if (toUndo.byNo === "0")
                        toAdd[0].challengedByNo = null;
                    else
                        toAdd[0].challengedByNo = toUndo.byNo;

                    if (toUndo.toNo === "0")
                        toAdd[0].challengedToNo = null;
                    else
                        toAdd[0].challengedToNo = toUndo.toNo;
                }

                stroke -= 1;
                var setWons = d107TennisScoreCard.toTennisScoreCard.setsWon;
                if (setWons != null) {
                    if (setWons.length > 0) {
                        if (setWons.indexOf(d107TennisScoreCard.setNo) !== -1)
                            var index = setWons.indexOf(d107TennisScoreCard.setNo)
                        if (index !== -1) d107TennisScoreCard.toTennisScoreCard.setsWon.splice(index, 1);
                        if (setWons.length == 0) {
                            setWons = null;
                            d107TennisScoreCard.toTennisScoreCard.setsWon = setWons;
                        }
                    }
                }

            }
            else if (toAdd[0].challengedToPoints > 0 || toAdd[0].challengedToNo !== null) {

                if (toAdd[0].challengedToNo == null)
                    toAdd[0].challengedToPoints = toAdd[0].challengedToPoints - toUndo.points;
                if (toUndo.byNo === "0")
                    toAdd[0].challengedByNo = null;
                else
                    toAdd[0].challengedByNo = toUndo.byNo;

                if (toUndo.toNo === "0")
                    toAdd[0].challengedToNo = null;
                else
                    toAdd[0].challengedToNo = toUndo.toNo;

                stroke -= 1;
                var setWons = d107TennisScoreCard.toTennisScoreCard.setsWon;
                if (setWons != null) {
                    if (setWons.length > 0) {
                        if (setWons.indexOf(d107TennisScoreCard.setNo) !== -1) {
                            var index = setWons.indexOf(d107TennisScoreCard.setNo)
                            if (index !== -1) d107TennisScoreCard.toTennisScoreCard.setsWon.splice(index, 1);


                        }
                        if (setWons.length == 0) {
                            setWons = null;
                            d107TennisScoreCard.toTennisScoreCard.setsWon = setWons;
                        }


                    }

                }
            }
        }

        toUndoList = toUndoList.filter((x) => x != toUndo)
        toAdd[0].toUndoList = toUndoList;
        d107TennisScoreCard.strokeNo = stroke;
        if (toAdd[0].toUndoList.length === 0) {

            if (d107TennisScoreCard.setNo > 1) {
                d107TennisScoreCard.setNo -= 1;
                d107TennisScoreCard.setNo = d107TennisScoreCard.setNo;
            }
        }
        var score = {
            tournamentId: this.props.tournament.tournamentId,
            matchId: this.props.matchId,
            d107TennisScoreCard: d107TennisScoreCard,
        }
        DUpdateTournamentsScore(score);
        this.setState({ d107TennisScoreCard: d107TennisScoreCard });
        console.log(d107TennisScoreCard);
    }


    getScore() {
        console.log(this.props.challenge);
        if (this.props.tournament.accepted.length >= 2) {
            const apiUrl = rshApi + "/demo-tournaments/get-score";
            const that = this;
            fetch(apiUrl, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "data": {
                        "tournamentId": that.props.tournament.tournamentId,
                        "matchId": that.props.matchId,
                    }
                })
            })
                .then(function (response) {
                    return trackPromise(response.json());
                })
                .then(function (result) {
                    console.log(result);

                    if (result.data.score === null) {
                        $('#openDp3').click();



                        d107TennisScoreCard = {
                            "byTennisScoreCard": {
                                "setsWon": null,
                                "id": result.data.score.d107TennisScoreCard.byTennisScoreCard.id,
                                "winner": false
                            },
                            "toTennisScoreCard": {
                                "setsWon": null,
                                "id": result.data.score.d107TennisScoreCard.toTennisScoreCard.id,
                                "winner": false
                            },
                            "strokeNo": 0
                        }
                        var score = {
                            tournamentId: that.props.tournament.tournamentId,
                            matchId: that.props.matchId,
                            d107TennisScoreCard: d107TennisScoreCard,
                        }
                        that.setState({ d107TennisScoreCard: d107TennisScoreCard });

                        DUpdateTournamentsScore(score);






                    }
                    else {
                        that.setState({ d107TennisScoreCard: result.data.score.d107TennisScoreCard, mode: result.data.mode, users: result.data.users, teams: result.data.teams, rounds: result.data.score.d107TennisScoreCard.rounds, numberOfSet: result.data.score.d107TennisScoreCard.noOfSets, setNo: result.data.score.d107TennisScoreCard.setNo, strokeNo: result.data.score.d107TennisScoreCard.strokeNo });
                        let byName; let toName;
                        if (result.data.mode === 'buddy') {
                            result.data.users.map(user => {
                                if (result.data.score.d107TennisScoreCard.byTennisScoreCard.id === user.contactNo) {
                                    byName = user.name
                                }
                                if (result.data.score.d107TennisScoreCard.toTennisScoreCard.id === user.contactNo) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                        else {
                            result.data.teams.map(user => {
                                if (result.data.score.d107TennisScoreCard.byTennisScoreCard.id === user.teamId) {
                                    byName = user.name
                                }
                                if (result.data.score.d107TennisScoreCard.toTennisScoreCard.id === user.teamId) {
                                    toName = user.name
                                }
                            })
                            that.setState({ userTeam: result.data.users, byName: byName, toName: toName });
                        }
                    }
                });
        }
    }


    componentDidMount() {

        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName, toName;
        if (this.props.tournament.tournamentMode === 'teams') {
            this.props.profileTeams.map(t => {
                if (t.teamId === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        else {
            this.props.profileUsers.map(t => {
                if (t.contactNo === this.state.matchPairObj.firstId) {
                    byName = t.teamName;
                }
                if (t.teamId === this.state.matchPairObj.secondId) {
                    toName = t.teamName;
                }
            })
        }
        this.setState({ byName: byName, toName: toName });
        this.setState({ users: [...this.props.profileUsers], teams: [...this.props.profileTeams] })
        this.getScore();
    }

    saveSet = () => {

        if (this.props.tt1.fixtureType1) {
            pairObj = myUtilclass.getMatchPair(this.props.tt1, null, null, false, this.props.matchId);
        }
        else {
            let poolNo;
            if (this.props.fType === 'A') {
                poolNo = 0;
            }
            if (this.props.fType === 'B') {
                poolNo = 1;
            }
            if (this.props.fType === 'C') {
                poolNo = 2;
            }
            if (this.props.fType === 'D') {
                poolNo = 3;
            }
            if (this.props.fType === 'F') {
                poolNo = 6;
            }
            pairObj = myUtilclass.getMatchPair(this.props.tt1, poolNo, null, false, this.props.matchId);
        }
        this.setState({ matchPairObj: pairObj });
        let byName;
        let toName;
        if (this.props.tournament.tournamentMode === 'team') {
            this.state.teams.map(team => {
                if (pairObj.firstId === team.teamId) {
                    byName = team.teamName;
                }
                if (pairObj.secondId === team.teamId) {
                    toName = team.teamName;
                }
            })
        }
        else {
            this.state.users.map(user => {
                if (pairObj.firstId === user.contactNo) {
                    byName = user.name;
                }
                if (pairObj.secondId === user.contactNo) {
                    toName = user.name;
                }
            })
        }
        this.setState({ byName: byName, toName: toName, score: false });


        if (this.state.numberOfSets !== null) {
            //  console.log(this.state.scoreCardD101State);
            var i = 0;
            var numberOfSets = parseInt(this.state.numberOfSets);
            var header = [{
                tableHeader: true,
                position: 0,
            }];
            var round = [];
            for (i = 1; i <= numberOfSets; i++) {
                var roundData = {
                    position: i,
                    tableHeader: false,
                    tieBreaker: false,
                    challengedById: this.state.matchPairObj.firstId,
                    challengedByPoints: 0,
                    challengedByNo: null,
                    challengedByTieBreakerPoints: 0,
                    challengedToId: this.state.matchPairObj.secondIds,
                    challengedToPoints: 0,
                    challengedToNo: null,
                }

                round.push(roundData);
            }
            var rounds = header.concat(round);
            const that = this;
            d107ScoreCard = {
                noOfSets: numberOfSets,
                setNo: 1,
                byTennisScoreCard: {
                    id: this.state.matchPairObj.firstId,

                    winner: false
                },
                toTennisScoreCard: {
                    id: this.state.matchPairObj.secondId,

                    winner: false
                },
                rounds
            }
            score = {
                tournamentId: that.props.tournament.tournamentId,
                matchId: that.props.matchId,
                d107TennisScoreCard: d107ScoreCard,
            }
            console.log(score);
            DUpdateTournamentsScore(score);
            $("#myModal .close").click();
        }
        else {
            this.setState({ setError: true });
        }
        // $("#myModal .close").click();
    }
    backState = () => {
        $("#myModal .close").click();
        this.props.stateRefresh();
    }
    changeData(event) {
        let selectedValue = event.target.value;
        if (event.target.checked === true) {

            this.setState({ setError: false, numberOfSets: selectedValue });
        }
    }
    editWinner = () => {

        this.ToundoList();
        $("#setWon .close").click();
    }
    saveWinner = () => {
        $("#setWon .close").click();

    }
    render() {
        var subtitle;
        var sets = [];
        for (var p = 0; p < parseInt(this.state.numberOfSet); p++) {
            sets.push(p);
        }
        var setNumber = 1;
        var actionPoints = {};
        this.state.rounds.map(round => {
            if (this.state.d107TennisScoreCard.setNo === round.position) {
                actionPoints = round
            }
            return true;
        })

        if ((actionPoints.challengedByNo === 6) && (actionPoints.challengedToNo === 6)) {
            this.state.tiebreaker = true;
        }
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.winnerModalOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <div ref={subtitle => this.subtitle = subtitle}></div>
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" onClick={this.closeModal} class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Match Result</h4>
                        </div>
                        <div class="modal-body">
                            <div class="resultlogo">
                                <img style={{ 'height': '15px', 'Width': '15px' }} src={finallogo} />
                                <label>Winner</label>
                            </div>

                            <ul class="resultul">
                                <li style={{ 'display': 'flex' }}>
                                    <a class="winnerselect">
                                        <div class="resinfo">
                                            <label></label>
                                            <small> </small>
                                        </div>

                                        <div class="tableresponsive">
                                            <div class="tableinner">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Players/Teams </th>

                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: '#FFF' }}>

                                                        <tr>
                                                            <td>{this.state.byName}</td>

                                                            {this.state.byWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>
                                                        <tr>
                                                            <td>{this.state.toName}</td>

                                                            {this.state.toWinner ? <td><img src={logo} class="winnerimg_td" /> Winner </td> : <td>Lost</td>}
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="modal-footer table_actions">
                            <a class="cmnbutton" onClick={() => this.ToundoList()}>Edit</a>
                            <button onClick={this.doneMatch} class="cmnbutton btnsubmitright" type="button">Submit</button>
                        </div>

                    </div>
                </Modal>


                <div data-backdrop="static" id="setWon" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">Confirmation !</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <p>Winner of set {this.state.setNumberForWinner} is {this.state.setWinnerName} </p>
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.editWinner()}>Edit</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveWinner()}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} id="setWonClick" type="button" data-toggle="modal" data-target="#setWon"></button>
                <div data-backdrop="static" id="myModal" className="modal fade reslutmodel" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content carrominputdiv-d">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal"></button>
                                <h4 className="modal-title">No. of Sets</h4>
                            </div>

                            <div className="modal-body modal-bodyD">
                                <div className="modaldiv-d">
                                    <div className="setspopup-d">
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="Sets" id="3set" onClick={this.changeData.bind(this)} value="3" />
                                            <label htmlFor="3set">3 Sets</label>
                                        </div>
                                        <div className="styled-input-single-d">
                                            <input type="radio" name="Sets" id="5set" onClick={this.changeData.bind(this)} value="5" />
                                            <label htmlFor="5set">5 Sets</label>
                                        </div>
                                        {this.state.setError ? <div style={{ marginTop: '10px', color: 'red' }}>
                                            <span >please Select Set</span>
                                        </div> : ''}
                                    </div>
                                    <button type="button" className="Setscoreback-d" onClick={() => this.backState()}>Back</button>
                                    <button type="button" className="Setscoresave-d" onClick={() => this.saveSet()}>Save</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} id="openDp3" type="button" data-toggle="modal" data-target="#myModal"></button>
                <section class="d_midsec createmidsec broadcast_box" style={{ zIndex: 0 }}>
                    <div class="commanbox cp_profile">
                        <div class="broadcast_video " style={{ backgroundImage: 'url(' + noBroadcast + ')' }}>
                            <span>09:33</span>
                            <a class="tour_broadcast_update"><img src={play} /></a>
                        </div>
                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            <th>Points</th>
                                            <th>Action</th>
                                        </tr>

                                    </thead>
                                    {actionPoints.tieBreaker ?
                                        <tbody>
                                            <tr>
                                                <td>{this.state.byName}</td>

                                                <td><input value={actionPoints.challengedByTieBreakerPoints ? actionPoints.challengedByTieBreakerPoints : 0} class="inputsize-d" /> </td>
                                                <td><button onClick={() => this.makePoint('by')} type="button" class="addpointtennis">+</button></td>

                                            </tr>
                                            <tr>
                                                <td>{this.state.toName}</td>
                                                <td><input value={actionPoints.challengedToTieBreakerPoints ? actionPoints.challengedToTieBreakerPoints : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('to')} type="button" class="addpointtennis">+</button></td>
                                            </tr>
                                        </tbody>
                                        : <tbody>
                                            <tr>
                                                <td>{this.state.byName}</td>

                                                <td><input value={actionPoints.challengedByNo ? actionPoints.challengedByNo : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('by')} type="button" class="addpointtennis">+</button></td>

                                            </tr>
                                            <tr>
                                                <td>{this.state.toName}</td>
                                                <td><input value={actionPoints.challengedToNo ? actionPoints.challengedToNo : 0} class="inputsize-d" /></td>
                                                <td><button onClick={() => this.makePoint('to')} type="button" class="addpointtennis">+</button></td>
                                            </tr>
                                        </tbody>}




                                </table>
                            </div>
                        </div>

                        <div class="tableresponsive">
                            <div class="tableinner">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Players</th>
                                            {sets.map(k => {
                                                return <th> Set{setNumber++} </th>
                                            })}

                                        </tr>
                                    </thead>


                                    <tbody>

                                        <tr>
                                            <td>{this.state.byName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedByPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" /></td>
                                            })}


                                        </tr>
                                        <tr>
                                            <td>{this.state.toName}</td>
                                            {sets.map((k, index) => {
                                                let points;
                                                this.state.rounds.map(round => {
                                                    if (index + 1 === round.position) {
                                                        points = round.challengedToPoints
                                                    }
                                                    return true;
                                                })

                                                return <td><input value={points} class="inputsize-d" /></td>
                                            })}
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="table_actions winner_actions">
                            <button class="cmnbutton backbtn fl" type="button" onClick={() => this.ToundoList()}>undo</button>
                            <a class="cmnbutton btred fr" type="button" data-toggle="modal" data-target="#myModal">End Event</a>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        )
    }

}
export default Tournament_tennis_tt1;


